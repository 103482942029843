<template>
  <div class="mx-auto max-w-2xl pb-8">
    <div class="w-full mt-12">
      <Stepper
        v-if="currentStepKey !== AccountSetupKeys.SetupCompleted"
        :modelValue="stepperIndex"
        :steps="accountSetupSteps"
        :prependText="`${$t('Account setup')} - `"
      />
      <router-view
        class="mt-4"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Stepper from '@/components/common/Stepper.vue'
import { accountSetupSteps, AccountSetupKeys } from '@/modules/accounts/utils/accountSetupUtils'

const route = useRoute()

const currentStepKey = computed(() => {
  return route.path.split('/').pop()
})

const stepperIndex = computed(() => {
  return accountSetupSteps.findIndex(step => step.key === currentStepKey.value)
})
</script>
