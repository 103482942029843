<template>
  <BaseTableCell :params="params">
    <div>
      {{ formattedTime }}
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { get } from 'lodash-es';
import { computed } from 'vue';

import { minutesToFormattedTime } from "@/modules/common/utils/timeUtils"
import { formatDate } from "@/modules/common/utils/dateUtils";

const defaultFormatParams = {
  format: "HH:mm",
  valueType: 'm'
}

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const formatParams = computed(() => {
  return columnParams.value?.formatParams || defaultFormatParams
})

const formattedTime = computed(() => {
  const value = get(row.value, colDef.value.field)
  if (!value) {
    return '- -'
  }
  const date = new Date();
  const valueType = formatParams.value.valueType
  if (valueType === 'h') {
    date.setHours(value, 0, 0);
  }
  else if (valueType === 'm') {
    return minutesToFormattedTime(value)
  }
  else if (valueType === 's') {
    date.setHours(0, 0, value);
  }
  
  const timeFormat = formatParams.value.format
  return formatDate(date, timeFormat)
})
</script>
