<template>
  <el-option
    value=""
    disabled
  >
    <div
      class="select-none relative w-full py-2 pt-3 cursor-default text-xs"
      @click="$emit('click')"
    >
      <div class="flex items-center truncate font-normal cursor-pointer group">
        <i class="fa-solid fa-pen-to-square ml-1 mr-2 text-gray-400 group-hover:text-gray-500"></i>
        <span class="capitalize text-gray-400 group-hover:text-gray-500">{{ $t(`Edit ${entity} Statuses`) }}</span>
        <BaseIconTooltip
          :content="$t(`This option is only visible to people with a 'Creator Admin' role.`)"
          class="info-text ml-1 mr-1"
        />
      </div>
    </div>
  </el-option>
</template>
<script>
import { ElOption } from "element-plus";
import i18n from "@/i18n.js";

export default {
  components: {
    ElOption,
  },
  props: {
    entity: {
      type: String,
      default: i18n.t('Task')
    }
  }
}
</script>
