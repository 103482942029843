<template>
  <div class="read-more"
    :class="{
      'is-ellipsis-active': isEllipsisNeeded && !showFull,
      [$attrs.compactclass]: !showFull
    }">
    <div ref="textContainer" :style="style">
      <slot>
        <div
          class="font-sans whitespace-pre-wrap"
          :class="contentClass"
          v-html="text"
        />
      </slot>
    </div>
    <span
      v-if="isEllipsisNeeded"
      @click="showFull = !showFull"
    >
      <slot name="toggle">
        <span
          class="text-xs text-gray-400 border-b border-dashed border-gray-300 hover:border-gray-500 hover:text-gray-500 cursor-pointer">
          {{ showFull ? $t('<< Read less') : $t('Read more >>') }}
        </span> 
      </slot>
    </span>
  </div>
</template>
<script>
import { debounce } from 'lodash-es'

export default {
  name: 'ReadMore',
  props: {
    text: {
      type: String,
      default: ''
    },
    maxLines: {
      type: Number,
      default: 2
    },
    defaultShowFull: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      showFull: this.defaultShowFull,
      isEllipsisNeeded: true
    }
  },
  computed: {
    style() {
      if (this.showFull) {
        return {}
      }

      return {
        'overflow': 'hidden',
        'text-overflow': 'ellipsis',
        'display': '-webkit-box',
        '-webkit-line-clamp': this.maxLines, /* number of lines to show */
        '-webkit-box-orient': 'vertical'
      }
    }
  },
  methods: {
    checkEllipsisNeeded() {
      const el = this.$refs?.textContainer
      if (!el) {
        return
      }
      
      this.isEllipsisNeeded = el.offsetHeight < el.scrollHeight
    }
  },
  watch: {
    text: {
      immediate: true,
      async handler() {
        await this.$nextTick()
      }
    },
    async maxLines(value) {
      this.showFull = (value === -1)

      await this.$nextTick()
      this.checkEllipsisNeeded()
    }
  },
  async mounted() {
    await this.$nextTick()
    this.checkEllipsisNeeded()

    this.checkEllipssisNeededDebounced = debounce(this.checkEllipsisNeeded, 16)
    addEventListener("resize", this.checkEllipssisNeededDebounced)
  },
  unmounted() {
    removeEventListener("resize", this.checkEllipssisNeededDebounced)
  }
}
</script>
<style lang="scss">
.read-more {
  pre[class*=language-] {
    overflow: initial;
  }

  &.is-ellipsis-active {
    pre[class*=language-] {
      padding-bottom: 0;
    }
  }
}
</style>
