import i18n from "@/i18n"
import * as FileUtils from "@/modules/common/utils/fileUtils.js";

export const referenceTypes = {
  Task: 'task',
  Embed: 'embed',
  Note: 'note',
  File: 'file',
  Time: 'time',
  Payment: 'payment',
  Discussion: 'discussion',
}

export const projectReferenceTypes = {
  Task: 'task',
  Project: 'project',
  ProjectEmbed: 'project_embed',
  ProjectNote: 'project_note',
  Media: 'media',
  TimeEntry: 'time_entry',
  Payment: 'payment',
  Discussion: 'discussion',
}

export const getReferenceOptions = (projectTools, target_project_id) => {
  return [
  {
    label: i18n.t('Tasks'),
    value: projectReferenceTypes.Task,
    url: `/restify/tasks`,
    labelKey: 'attributes.name',
    itemUrl: (item) => {
      return getReferencePath({
        target_project_id,
        referenceable_type: projectReferenceTypes.Task,
        referenceable_id: item.id
      })
    },
    renderIf: () => projectTools.includes('Tasks'),
  },
  {
    label: i18n.t('Files'),
    value: projectReferenceTypes.Media,
    url: '/restify/media',
    labelKey: 'attributes.name',
    itemUrl: (item) => {
      return getReferencePath({
        referenceable_type: projectReferenceTypes.Media,
        file: item?.attributes
      })
    },
    renderIf: () => projectTools.includes('Files'),
  },
  {
    label: i18n.t('Embeds'),
    value: projectReferenceTypes.ProjectEmbed,
    url: '/restify/project-embeds',
    labelKey: 'attributes.name',
    itemUrl: (item) => {
      return getReferencePath({
        target_project_id,
        referenceable_type: projectReferenceTypes.ProjectEmbed,
        referenceable_id: item.id
      })
    },
    renderIf: () => projectTools.includes('Embeds'),
  },
  {
    label: i18n.t('Notes'),
    value: projectReferenceTypes.ProjectNote,
    url: '/restify/project-notes',
    labelKey: 'attributes.name',
    itemUrl: (item) => {
      return getReferencePath({
        target_project_id,
        referenceable_type: projectReferenceTypes.ProjectNote,
        referenceable_id: item.id
      })
    },
    renderIf: () => projectTools.includes('Note'),
  },
  {
    label: i18n.t('Discussion'),
    value: projectReferenceTypes.Discussion,
    options: [
      {
        label: i18n.t('Discussion Page'),
        value: i18n.t('Discussion Page'),
      },
    ],
    labelKey: 'value',
    itemUrl: () => {
      return getReferencePath({
        target_project_id,
        referenceable_type: projectReferenceTypes.Discussion,
      })
    },
    renderIf: () => projectTools.includes('Discussion'),
  },
  {
    label: i18n.t('Time'),
    value: projectReferenceTypes.TimeEntry,
    options: [
      {
        label: i18n.t('Time Page'),
        value: i18n.t('Time Page'),
      },
    ],
    labelKey: 'value',
    itemUrl: () => {
      return getReferencePath({
        target_project_id,
        referenceable_type: projectReferenceTypes.TimeEntry,
      })
    },
    renderIf: () => projectTools.includes('Time'),
  },
  {
    label: i18n.t('Payments'),
    value: projectReferenceTypes.Payment,
    options: [
      {
        label: i18n.t('Payments Page'),
        value: i18n.t('Payments Page'),
      },
    ],
    labelKey: 'value',
    itemUrl: () => {
      return getReferencePath({
        target_project_id,
        referenceable_type: projectReferenceTypes.Payment,
      })
    },
    renderIf: () => projectTools.includes('Payments'),
  },
  {
    label: i18n.t('Project'),
    value: projectReferenceTypes.Project,
    labelKey: 'value',
    options: [
      {
        label: i18n.t('Project Dashboard'),
        value: i18n.t('Project Dashboard'),
      },
    ],
    itemUrl: (item) => {
      return getReferencePath({
        target_project_id,
        referenceable_type: projectReferenceTypes.Project,
        referenceable_id: target_project_id
      })
    },
  }
]}


export function getReferencePath(reference) {
  if (reference.url) {
    return reference.url
  }

  if (reference.referenceable_type === projectReferenceTypes.Task) {
    return `/tasks/${reference.referenceable_id}`
  }

  if (reference.referenceable_type === projectReferenceTypes.Project) {
    const projectId = reference.referenceable_id || reference.target_project_id 
    return `/projects/${projectId}`
  }
  
  if (reference.referenceable_type === projectReferenceTypes.Media) {
    return FileUtils.createFileURL(reference.file)
  }

  if (reference.referenceable_type === projectReferenceTypes.ProjectEmbed) {
    return `/projects/${reference.target_project_id}/embeds/${reference.referenceable_id}`
  }

  if (reference.referenceable_type === projectReferenceTypes.ProjectNote) {
    return `/projects/${reference.target_project_id}/notes/${reference.referenceable_id}`
  }

  if (reference.referenceable_type === projectReferenceTypes.Discussion) {
    return `/projects/${reference.target_project_id}/discussion`
  }

  if (reference.referenceable_type === projectReferenceTypes.TimeEntry) {
    return `/projects/${reference.target_project_id}/time/all`
  }

  if (reference.referenceable_type === projectReferenceTypes.Payment) {
    return `/projects/${reference.target_project_id}/payments/list`
  }
}
