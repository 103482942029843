<template>
  <BaseSelect
    v-model="model"
    :options="notificationTypeOptions"
    :placeholder="$t('Select notification type...')"
    :return-object="returnObject"
  />
</template>
<script>
import { notificationTypeOptions } from "@/modules/accounts/utils/notificationUtils"

export default {
  name: "NotificationTypeSelect",
  props: {
    modelValue: {
      type: [Object, String],
      required: true,
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notificationTypeOptions
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
};
</script>
