<template>
  <div class="flex items-center truncate">
    <div class="mr-2">
      <BaseLogo
        :entity="event.extendedProps?.relationships?.project"
        size="sm"
        class="task-logo"
      />
    </div>
    <div class="truncate">
      <div class="truncate flex items-center">
        <span v-if="event.extendedProps?.relationships?.project"
              class="truncate">
          {{ event.extendedProps?.relationships?.project?.attributes?.name }}
        </span>
      </div>
      <div class="truncate flex items-center">
        <i v-if="event.extendedProps?.attributes?.date_type === 'recurring'"
           class="text-white fas fa-repeat mr-1"
        />
        <span class="truncate">{{ event.title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store/index.js";
import router from "@/router/index.js";
import { i18n } from "@/i18n.js";
import globalPlugins from "@/plugins/globalPlugins.js";
import globalComponents from "@/plugins/globalComponents.js";

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  beforeCreate() {
    /**
     * This is a hack to make sure the resource component uses our plugins.
     * The custom slots from full calendar don't share the app context and therefore hve no store, router etc by default.
     */
    this.$.appContext.app
      .use(store)
      .use(router)
      .use(i18n)
      .use(globalPlugins)
      .use(globalComponents)
  }

}
</script>
<style>
.task-logo {
  min-width: 28px;
}
</style>