<template>
  <node-view-wrapper class="code-block">
    <select v-model="selectedLanguage"
            contenteditable="false"
            class="form-select"
    >
      <option :value="null">
        auto
      </option>
      <option disabled>
        —
      </option>
      <option v-for="(language, index) in languages" :value="language" :key="index">
        {{ language }}
      </option>
    </select>
    <pre><code><node-view-content/></code></pre>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  props: nodeViewProps,
  data() {
    return {
      languages: this.extension.options.lowlight.listLanguages(),
    }
  },
  computed: {
    selectedLanguage: {
      get() {
        return this.node.attrs.language
      },
      set(language) {
        this.updateAttributes({ language })
      },
    },
    selectOptions() {
      const options = this.languages.map(language => ({
        label: language,
        value: language,
      }))
      return [
        {
          label: 'auto',
          value: null,
        },
        ...options
      ]
    }
  },
}
</script>

<style lang="scss">
.code-block {
  position: relative;

  select {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    @apply form-input p-1 w-auto;
  }
}
</style>
