<template>
  <BaseTableCell :params="params">
    <Timer
      :row="row"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue"
import Timer from "@/components/table/Timer.vue";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data
})
</script>
