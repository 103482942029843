<template>
  <BaseTableCell :params="params">
    <div class="flex -space-x-1 min-w-max allocations-list">
      <div
        v-if="users.length"
        class="flex -space-x-1 overflow-hidden"
      >
        <UserLogo
          v-for="user of sortedUsers"
          :key="user.id"
          :user="user"
          :disableTooltip="true"
          :actions="userActions"
          class="rounded-lg cursor-pointer"
          size="sm"
          @remove="removeUser(user)"
        />
      </div>
      <div
        v-if="users?.length === 0"
        class="text-xs no-allocations"
      >
        {{ placeholder }}  
      </div>
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
// Components
import UserLogo from '@/components/common/UserLogo.vue'

// Utils
import i18n from '@/i18n';
import { PropType, computed } from "vue"
import { get, orderBy } from 'lodash-es'
import { ICellRendererParams } from '@ag-grid-community/core';
import {
  UserPreviewActions
} from "@/modules/users/util/userUtils";

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams<any>>,
    default: () => ({})
  },
  isCard: {
    type: Boolean,
    default: false
  }
})

const row = computed<any>(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const users = computed(() => {
  if (typeof columnParams.value?.getUsers === 'function') {
    return columnParams.value?.getUsers(row.value)
  }

  const usersList = get(row.value, colDef.value.field, [])

  return usersList
})

const placeholder = computed(() => {
  if (typeof columnParams.value?.getPlaceholder === 'function') {
    return columnParams.value?.getPlaceholder(row.value)
  }

  return i18n.t('No assignees')
})

const sortedUsers = computed(() => {
  return orderBy(users.value, 'id')
})

const canRemoveUsers = computed(() => {
  if (typeof columnParams.value?.canRemoveUsers === 'function') {
    return columnParams.value.canRemoveUsers(row.value)
  }

  return columnParams.value?.canRemoveUsers
})

const userActions = computed(() => {
  if (canRemoveUsers.value) {
    return [UserPreviewActions.View, UserPreviewActions.Remove]
  }

  return [UserPreviewActions.View]
})

function removeUser(user: any) {
  if (typeof columnParams.value?.removeUser != 'function') {
    console.error('removeUser in column definition is not a function')
    return
  }
  
  columnParams.value.removeUser(row.value, user, props.params)
}
</script>
