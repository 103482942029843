<template>
  <NodeViewWrapper
    as="span"
    :draggable="isDraggable"
    :data-drag-handle="isDraggable"
  >
    <component
      :is="componentType"
      v-bind="linkProps"
      class="relative underline cursor-pointer inline-flex items-center space-x-2 px-1 hover:bg-gray-50 rounded-md font-medium editor-reference"
      @click="onReferenceClick"
    >
      <span v-if="icon">
        <i :class="icon" />
      </span>
      <span>
        {{ referenceName }}
      </span>
    </component>
    <TaskDialog
      v-if="showDialog"
      v-model="showDialog"
      :taskId="referenceId"
      :task="task"
      @close="onClose"
    />
  </NodeViewWrapper>
</template>
<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import TaskLoadingCard from "@/modules/tasks/components/TaskLoadingCard.vue";
import TaskCard from "@/modules/tasks/components/TaskCard.vue";
import TaskTextRow from "@/modules/tasks/components/TaskTextRow.vue";
import { referenceTypes } from "@/components/html/util/referenceUtils.js";

export default {
  name: 'ReferenceTemplate',
  components: {
    TaskCard,
    TaskTextRow,
    NodeViewWrapper,
    TaskLoadingCard,
  },
  props: nodeViewProps,
  data() {
    return {
      url: this.node.attrs.url || null,
      showDialog: false,
      task: null,
    }
  },
  computed: {
    referenceId() {
      return this.node?.attrs?.id
    },
    referenceType() {
      return this.node?.attrs?.type
    },
    referenceName() {
      if (this.referenceType === referenceTypes.Task) {
        return this.task?.attributes?.name || this.node?.attrs?.name
      }
      return this.node?.attrs?.name
    },
    isDraggable() {
      return this.node?.attrs?.isDraggable
    },
    icon() {
      const iconMappings = {
        [referenceTypes.File]: 'fal fa-file text-blue-500',
        [referenceTypes.Embed]: 'fal fa-file-code text-gray-500',
        [referenceTypes.Task]: 'fal fa-badge-check text-orange-500',
        [referenceTypes.Time]: 'fal fa-fa-stopwatch text-pink-500',
        [referenceTypes.Payment]: 'fal fa-money-bill text-green-500',
        [referenceTypes.Discussion]: 'fal fa-comments-alt text-green-500',
        [referenceTypes.Note]: 'fal fa-sticky-note text-gray-500',
      }
      return iconMappings[this.referenceType] || ''
    },
    isAbsolute() {
      return this.url?.startsWith('http')
    },
    componentType() {
      if (this.referenceType !== referenceTypes.Task) {
        return this.isAbsolute ? 'a' : 'router-link'
      }
      return 'span'
    },
    linkProps() {
      if (this.referenceType === referenceTypes.Task) {
        return {}
      }
      if (this.isAbsolute) {
        return {
          href: this.url,
          target: '_blank',
          rel: 'noopener'
        }
      }
      return {
        to: this.url,
      }
    }
  },
  methods: {
    async onReferenceClick() {
      if (this.referenceType === referenceTypes.Task) {
        this.showDialog = true
      }
    },
    async getTask() {
      if (this.referenceType !== referenceTypes.Task || !this.referenceId) {
        return
      }

      this.task = await this.$store.dispatch('tasks/getTaskById', {
        id: this.referenceId,
        returnEntity: true,
        silentError: true,
      })
    },
    onClose() {
      this.getTask()
    },
  },
  async mounted() {
    await this.getTask()
  },
}
</script>
