import { loadImage } from "@/modules/common/utils/imageUtils.js";

export async function trackCapTerra() {
  try {
    const url = 'https://ct.capterra.com/capterra_tracker.gif?vid=2114180&vkey=ff88f41bbcd5caf085e2eaa75cf5a34a'
    await loadImage(url)
  } catch (err) {
    console.log('Could not load Captera image')
  }
}

export function trackFirstPromoter({ email, uid }) {
  var t = document.createElement("script");
  t.type = "text/javascript", t.async = !0, t.src = 'https://cdn.firstpromoter.com/fprom.js', t.onload = t.onreadystatechange = function () {
    var t = this.readyState;
    if (!t || "complete" == t || "loaded" == t) {
      try {
        $FPROM.init("2kjz2s50", ".project.co")
        $FPROM.trackSignup({
          email,
          uid,
        })
      } catch (err) {
        console.log('Could not register First Promoter event', err)
      }
    }
    else {
      console.log('Could not register First Promoter event', err)
    }
  };

  var e = document.getElementsByTagName("script")[0];
  e.parentNode.insertBefore(t, e)
}

export async function trackRegisterEvent({ provider, email, uid }) {
  window.gtag('event', 'signup', {
    event_category: 'account',
    event_label: provider,
  })
  
  trackFirstPromoter({ email, uid })

  await trackCapTerra()
}
