<template>
  <UsersTable
    :data="users"
    :loading="isLoading"
    class="flex-1"
  />
</template>
<script lang="ts" setup>
import UsersTable from "@/modules/users/components/UsersTable.vue";
import { computed, ref, watch } from "vue";

import { useStore } from "vuex";
const store = useStore()

const loading = computed(() => {
  return store.state.users.usersLoading ||
    store.state.groups.groupsLoading
})

const isLoading = ref(true)

watch(() => loading.value, (value) => {
  isLoading.value = value
}, { immediate: true })

const users = computed(() => {
  if (isLoading.value) {
    return []
  }

  return store.getters['users/usersWithGroups'] || []
})
</script>
