<template>
  <div class="flex gap-2">
    <BaseSelect
      v-model="currencyName"
      :options="currencyOptions"
      :placeholder="$t('Currency')"
      :return-object="false"
      class="w-40"
      clearable
    />
    <template v-if="minMaxRange">
      <span class="flex flex-col w-full">
        <span
          v-if="label"
          class="block text-sm font-medium text-gray-700 -mt-5"
        >
          {{ `${$t('Min')} ${label}`}}
        </span>
        <input
          v-model="minValue"
          :placeholder="label && `${$t('Enter Min')} ${label}...` || ''"
          type="number"
          class="form-input h-12"
          @blur="validateRange('min')"
        />
      </span>
      <span class="flex flex-col w-full">
        <span
          v-if="label"
          class="block text-sm font-medium text-gray-700 -mt-5"
        >
          {{ `${$t('Max')} ${label}`}}
        </span>
        <input
          v-model="maxValue"
          :placeholder="label && `${$t('Enter Max')} ${label}...` || ''"
          type="number"
          class="form-input h-12"
          @blur="validateRange('min')"
        />
      </span>
    </template>
    <input
      v-else
      v-model="currencyValue"
      :placeholder="placeholder"
      type="number"
      class="form-input h-12"
    />
    <XCircleIcon 
      v-if="modelValue"
      class="h-14 w-14 text-gray-400 cursor-pointer pb-3"
      aria-hidden="true"
      @click.stop="$emit('update:modelValue', null)"
    />
  </div>
</template>
<script>
import { XCircleIcon } from '@heroicons/vue/outline'
export default {
  components: {
    XCircleIcon
  },
  props: {
    modelValue: {
      type: [Object],
      required: true,
    },
    currencyOptions: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    minMaxRange: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    currencyName: {
      get() {
        return this.modelValue?.currencyName;
      },
      set(value) {
        if (value === '' || (typeof value === 'object' && !Object.keys(value).length)) {
          value = null
        }
        this.updateModelValue('currencyName', value)
      },
    },
    currencyValue: {
      get() {
        return this.modelValue?.currencyValue;
      },
      set(value) {
        this.updateModelValue('currencyValue', value)
      },
    },
    minValue: {
      get() {
        return this.modelValue?.min;
      },
      set(value) {
        this.updateModelValue('min', value)
      },
    },
    maxValue: {
      get() {
        return this.modelValue?.max;
      },
      set(value) {
        this.updateModelValue('max', value)
      },
    }
  },
  methods: {
    updateModelValue(prop, value) {
      const modelValue = {
        ...this.modelValue,
        [prop]: value
      }
      
      let hasValue = false
      for (const p in modelValue) {
        if (modelValue[p] !== undefined && modelValue[p] !== null ) {
          hasValue = true
          break;
        }
      }

      this.$emit("update:modelValue", hasValue ? modelValue : null);
    },
    validateRange(changedProp) {
      if (
        [null, ''].includes(this.minValue) || [null, ''].includes(this.maxValue)
        ||
        isNaN(this.minValue) || isNaN(this.maxValue)
      ) {
        return
      }

      if (changedProp === 'max' && +this.maxValue < +this.minValue) {
        this.maxValue = this.minValue
        this.$error('Max value cannot be smaller than Min value')
        return
      }

      if (changedProp === 'min' && +this.minValue > +this.maxValue) {
        this.minValue = this.maxValue
        this.$error('Min value cannot be greater than Max value')
        return
      }
    }
  }
};
</script>
