import { Store } from 'vuex'
import { createStore } from 'vuex-extensions'
import createPersistedState from "vuex-persistedstate";
import { entityDialogTriggers } from "@/modules/common/enum/entityTypes.js";

function getStoreModules() {
  const files = import.meta.globEager('../**/store/*Module.js')
  const fileKeys = Object.keys(files)
  const modules = {}
  fileKeys.forEach(key => {
    if (!files[key]) {
      return
    }

    const storeModule = files[key].default
    const pathParts = key.split('/')
    const fileName = pathParts[pathParts.length - 1]
    const moduleName = fileName.replace('Module.js', '')
    if (storeModule) {
      modules[moduleName] = storeModule
    }
  })
  return modules
}

export default createStore(Store, {
  plugins: [
    createPersistedState({
      paths: [
        'auth',
        'accounts.stats',
        'accounts.starredPages',
        'accounts.subscription',
        'accounts.customBranding',
        'accounts.customFields',
        'accounts.currentProjectCustomFields',
        'accounts.organizationSettings',
        'accounts.speechToTextConfig',
        'accounts.onboardingModel',
        'accounts.gettingStartedState',
        'accounts.trialFeaturesState',
        'filters.filterPresets',
        'filters.columnStates',
        'projects.statuses',
        'tasks.statuses',
        'users.roles',
        'tasks.userScopedOrders',
        'users.defaultViews',
        'users.simulateRole',
        'dashboard.useNewLayout',
        'dashboard.sidebarVisible',
        'dashboard.dashboards',
      ],
    })
  ],
  modules: {
    ...getStoreModules()
  },
  state: {
    entityCreatedCallback: null,
    entityDialogClosedCallback: null,
    entityCreateParams: null,
    appLoading: true,
    topFiltersResized: 1,
    gridDataChangedTrigger: {},
    gridDataInputRowResetTrigger: 1,
    gridTriggerCellRefresh: {},
    isCalendarView: false,
    isTemplateContext: false,
    closeDialogsTrigger: 1
  },
  mutations: {
    setEntityCreatedCallback(state, value) {
      state.entityCreatedCallback = value
    },
    setEntityDialogClosedCallback(state, value) {
      state.entityDialogClosedCallback = value
    },
    setEntityCreateParams(state, value) {
      state.entityCreateParams = value
    },
    setAppLoading(state, value) {
      state.appLoading = value
    },
    setTopFiltersResized(state) {
      state.topFiltersResized++
    },
    triggerGridDataChanged(state, changeData) {
      const count = (state.gridDataChangedTrigger?.count || 0) + 1
      state.gridDataChangedTrigger = {
        id: changeData.id,
        entity: changeData.entity,
        action: changeData.action,
        count
      }
    },
    triggerGridDataInputRowReset(state) {
      state.gridDataInputRowResetTrigger++
    },
    triggerGridCellRefresh(state, refreshParams) {
      state.gridTriggerCellRefresh = refreshParams
    },
    setIsCalendarView(state, value) {
      state.isCalendarView = value
    },
    setIsTemplateContext(state, value) {
      state.isTemplateContext = value
    },
    closeGlobalDialogs(state) {
      state.closeDialogsTrigger++
    }
  },
  actions: {
    triggerEntityCreate({ commit, dispatch }, params) {
      const { entityType, entityCreateParams, events } = params
      const triggerCommit = entityDialogTriggers[entityType]

      commit('setEntityCreateParams', entityCreateParams)

      if (triggerCommit) {
        commit(triggerCommit)
      }

      commit('setEntityDialogClosedCallback', events?.closed)
      commit('setEntityCreatedCallback', events?.created)
    }
  },
  getters: {
    project_id: (state, allGetters, rootState) => {
      // TODO: test & confirm it works ok and it's better to leave it like this (when accessing/leaving project/template context)
      return rootState.projects.currentProjectId
    }
  }
})
