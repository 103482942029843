<template>
  <router-view />
</template>
<script lang="ts" setup>
import { completedTasksFilters } from '@/modules/tasks/utils/taskTableUtils'
import { computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import {
  getDateWithoutTime,
} from "@/modules/common/utils/dateUtils.js";

const store = useStore()

const project_id = computed(() => {
  return store.getters.project_id
})

const apiFilters = computed(() => {
  return store.getters['filters/targetApiFilters']('tasks') || []
})

const completedFilter = computed(() => {
  return apiFilters.value.find((filter: any) => filter.key === 'completed')?.value?.show
})

watch(() => completedFilter.value, (value: boolean) => {
  if (!value || !project_id.value) {
    return
  }

  store.dispatch('tasks/getCompletedTasks', {
    project_id: project_id.value,
  })
}, { immediate: true })

const globalCompletedFilter = computed(() => {
  return apiFilters.value.find((filter: any) => filter.key === 'global-completed-tasks')?.value
})

watch(() => globalCompletedFilter.value, async (filter: completedTasksFilters) => {
  if (project_id.value || !filter || filter === completedTasksFilters.No) {
    return
  }

  const { startDate, endDate } = getDateRangeFromFilter(filter)

  await store.dispatch('tasks/getCompletedTasks', {
    startDate,
    endDate,
  })

}, { immediate: true })

function getDateRangeFromFilter(filter: completedTasksFilters) {
  const today = new Date()
  const last90Days = new Date(new Date().setDate(today.getDate() - 90))
  const last180Days = new Date(new Date().setDate(today.getDate() - 180))
  const lastYear = new Date(new Date().setFullYear(today.getFullYear() - 1))

  const completedTaskRanges: Record<completedTasksFilters, { startDate: string | null, endDate: string | null }> = {
    [completedTasksFilters.No]: {
      startDate: null,
      endDate: null,
    },
    [completedTasksFilters.Last90Days]: {
      startDate: getDateWithoutTime(last90Days),
      endDate: getDateWithoutTime(today),
    },
    [completedTasksFilters.Last180Days]: {
      startDate: getDateWithoutTime(last180Days),
      endDate: getDateWithoutTime(today),
    },
    [completedTasksFilters.LastYear]: {
      startDate: getDateWithoutTime(lastYear),
      endDate: getDateWithoutTime(today),
    },
    [completedTasksFilters.AllTime]: {
      startDate: null,
      endDate: null,
    },
  }

  return completedTaskRanges[filter]
}

async function loadAndBindFiles() {
  if (store.state.files.allFilesLoaded) {
    return
  }
  await store.dispatch("files/getAllFiles")
  store.dispatch('tasks/setTasksRelationships')
}

onMounted(() => {
  store.dispatch('tasks/getAllStatuses')
  loadAndBindFiles()
})
</script>
