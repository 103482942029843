<template>
  <section>
    <div class="max-w-3xl m-auto">
      <WarningAlert
        v-if="isAppSumoSubscription"
        :dismissable="false"
        class="border border-yellow-500"
      >
        {{
          $tc('app sumo plan message', {
            creatorSeats,
            availableStorage,
          })
        }}
        <router-link
          to="/account-settings/code-stacking"
          class="underline"
        >
          {{ $t('Click here to apply them.') }}
        </router-link>
      </WarningAlert>

      <SubscriptionTrialMessage
        v-else-if="isTrialPeriod"
        :dismissable="false"
        class="w-full"
      />

      <WarningAlert
        v-else-if="userPlan.id"
        :dismissable="false"
        class="border border-yellow-500"
      >
        {{ $t(`Your current plan is `) }} {{ getPlanName(userPlan?.plan?.name) }}.
        <button
          class="font-medium underline text-yellow-700 hover:text-yellow-600"
          @click="goToCustomerPortal"
        >
          {{ $t('Click here to view your customer portal.') }}
        </button>
        {{
          $t('This is where you can edit your payment details & view invoices. Alternatively, click an option below to change plan.')
        }}
      </WarningAlert>
      <div class="flex justify-center rounded-md mt-4 mx-auto">
        <div class="relative self-center bg-gray-200 rounded-lg p-0.5 flex">
          <button
            type="button"
            class="relative w-1/2 border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium whitespace-nowrap sm:w-auto sm:px-8"
            :class="{
              'text-gray-900': selectedFrequency !== PlanFrequency.Month,
              'bg-primary-500 text-white' : selectedFrequency === PlanFrequency.Month
            }"
            @click="selectedFrequency = PlanFrequency.Month"
          >
            <span>{{ $t('Monthly') }}</span>
          </button>
          <button
            type="button"
            class="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium whitespace-nowrap sm:w-auto sm:px-8"
            :class="{
              'text-gray-900': selectedFrequency !== PlanFrequency.Year,
              'bg-primary-500 text-white' : selectedFrequency === PlanFrequency.Year
            }"
            @click="selectedFrequency = PlanFrequency.Year"
          >
            <span>{{ $t('Yearly') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="max-w-5xl m-auto grid gap-6 grid-cols-1 lg:grid-cols-2 mt-4 pb-10">
      <!-- NAV ITEM: START -->
      <div
        v-for="plan of displayPlans"
        :key="plan?.id"
        class="max-w-lg w-full min-h-full relative p-8 bg-white border border-gray-200 overflow-hidden rounded-2xl shadow-sm flex flex-col m-auto"
      >
        <div class="flex-1 text-center flex flex-col h-full">
          <div :class="plan.cssClass">
            <span>{{ plan.title }}</span>
            <i v-if="plan?.price > 0" class="fa-solid fa-stars" aria-hidden="true" style="font-size: 20px; vertical-align: text-top;"></i>
          </div>
          <span class="text-4xl font-extrabold tracking-tight">
            <span>
              {{
                $formatPrice(plan?.price || 0, {
                  currencyCode: plan?.currency || CURRENCIES.USD,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })
              }}
            </span>
          </span>
          <span class="text-gray-500">
            <span v-if="!plan.price">
              {{ $t('forever!') }}
            </span>
            <span v-else>
              {{ $t('per creator user') }}
              <span v-if="plan.frequency === PlanFrequency.Year">
                {{ $t('billed annually') }}
              </span>
            </span>
          </span>
          <hr class="-ml-8 -mr-8 mt-4">
          <ul role="list" class="mt-6 space-y-2 w-min">
            <li
              v-for="benefit of plan.benefits"
              :key="benefit.text"
              class="flex w-full items-center whitespace-nowrap"
            >
              <i :class="`fa-solid ${benefit.icon} text-green-500 mr-3`" />
              <span
                :class="{
                  'font-semibold text-primary-500': benefit.bold,
                  'hover:text-gray-600 underline': benefit.link,
                }"
                class="text-gray-500">

                <template v-if="benefit.link">
                  <a :href="benefit.link" target="_blank" rel="noopener">
                    {{ benefit.text  }}
                  </a>
                </template>

                <template v-else>
                  {{ benefit.text }}
                </template>
              </span>
              <BaseTooltip
                v-if="benefit.comingSoon"
                :content="$t('Coming soon')"
              >
                <i class="fa-solid fa-calendar-days ml-1 text-gray-300 text-sm"></i>
              </BaseTooltip>
            </li>
          </ul>
          <span class="flex-1 flex flex-col justify-end rounded-md block w-full shadow-sm">
            <template v-if="userPlan?.plan?.id === plan.id">
              <div class="mt-6 text-white bg-gray-400 rounded-md px-4 py-3 text-center text-sm font-medium cursor-default">
                <i class="fa-solid fa-check mr-2" />
                <span>{{ $t('Your Current Plan') }}</span>
              </div>

              <BaseButton
                v-if="canEditCreatorSeats"
                block
                class="text-sm underline text-primary-500 hover:text-primary-600 absolute -bottom-8 focus:border-none focus:outline-none"
                variant="primary-link"
                @click="choosePlan(plan)"
              >
                <span v-if="isTrialPeriod">
                  {{ $t('End Trial & Buy Subscription') }}
                </span>
                <span v-else>
                  {{ $t('Edit number of creator seats') }}
                </span>
              </BaseButton>
            </template>

            <BaseButton
              v-else
              :loading="loading"
              block
              class="mt-6 py-3"
              @click="choosePlan(plan)"
            >
              <span>{{ $t('Choose plan') }}</span>
            </BaseButton>
          </span>
        </div>
      </div>
    </div>
    
    <TrialManagerCard />
    <PlanPaymentDialog
      v-if="showPlanPaymentDialog"
      v-model="showPlanPaymentDialog"
      :plan="chosenPlan"
      :userPlan="userPlan"
      @close="showPlanPaymentDialog = false"
    />
  </section>
</template>
<script lang="ts">
// Components
import WarningAlert from "@/components/common/WarningAlert.vue";
import PlanPaymentDialog from "@/modules/accounts/components/PlanPaymentDialog.vue";
import SubscriptionTrialMessage from "@/modules/accounts/components/SubscriptionTrialMessage.vue"
import TrialManagerCard from "@/modules/accounts/components/TrialManagerCard.vue"

// Utils
import { groupBy } from "lodash-es"
import { CURRENCIES } from "@/modules/payments/enum/paymentEnums.js";
import {
  PlanDetails,
  PlanFrequency
} from "@/modules/accounts/utils/subscriptionUtils"
import { humanReadableSize } from '@/modules/common/utils/fileUtils';
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { defineComponent } from "vue";
import { Plan } from "@/modules/accounts/types/accountTypes";
const freePlanIdentifier = 'pdc_free_forever_v2'

export default defineComponent({
  components: {
    WarningAlert,
    PlanPaymentDialog,
    SubscriptionTrialMessage,
    TrialManagerCard,
  },
  setup() {
    const {
      accountLimits,
    } = useAccountLimits()
    return {
      accountLimits,
    }
  },
  data() {
    return {
      loading: false,
      showPlanPaymentDialog: false,
      chosenPlan: {} as Plan,
      selectedFrequency: PlanFrequency.Month,
      CURRENCIES,
      PlanFrequency,
    }
  },
  computed: {
    allPlans(): Plan[] {
      return this.$store.state.accounts.plans || []
    },
    availablePlans() {
      const excludedPlans = [
        'pdc_appsumo_lifetime',
        'pdc_monthly',
        'pdc_teams_monthly',
        'pdc_agencies_monthly',
        'pdc_yearly',
        'pdc_annual',
        'pdc_teams_yearly',
        'pdc_agencies_yearly'
      ]

      return this.allPlans.filter(p => !excludedPlans.includes(p.identifier))
    },
    freePlan() {
      return this.availablePlans.find(p => p.price === 0 && p.identifier === freePlanIdentifier)
    },
    paidPlans() {
      return this.availablePlans.filter(p => p.price > 0)
    },
    groupedPlans() {
      return groupBy(this.paidPlans, 'frequency')
    },
    displayPlans() {
      let plans = this.groupedPlans[this.selectedFrequency] || []
      const identifiersToFilterOut = ['pdc_paid_monthly_v2', 'pdc_paid_yearly_v2']
      plans = plans.filter(p => !identifiersToFilterOut.includes(p.identifier))

      if (this.freePlan) {
        plans = [ this.freePlan, ...plans ]
      }

      return plans.map(p => {
        // Remove frequency "-forever|monthly|annual" from nickname to compute details
        const planKey = this.removePlanFrequencyKeywords(p.nickname)

        return {
          ...p,
          ...PlanDetails[planKey]
        }
      })
    },
    subscription() {
      return this.$store.state.accounts.subscription || {}
    },
    userPlan() {
      return this.subscription
    },
    isTrialPeriod() {
      return this.$store.getters['accounts/isTrialPeriod']
    },
    canEditCreatorSeats() {
      return this.userPlan?.plan?.price > 0
    },
    creatorSeats() {
      return this.accountLimits.seats.available
    },
    isAppSumoSubscription() {
      return this.$store.getters['accounts/isAppSumoSubscription']
    },
    availableStorage() {
      return humanReadableSize(this.accountLimits.storage.available, 2, 'GB')
    },
  },
  methods: {
    getPlanName(planName: string) {
      const nameMap: Record<string, string> = {
        'PDC-Free-Forever': this.$t('Free Forever'),
        'PDC-Free-Forever-V2': this.$t('Free Forever'),
        'PDC-Paid-Monthly-V2': this.$t('Pro Monthly'),
        'PDC-Paid-Yearly-V2': this.$t('Pro Yearly'),
      }
      return nameMap[planName] || planName
    },
    removePlanFrequencyKeywords(str: string) {
      const keywords = ["Forever", "Monthly", "Annual", "Yearly"];

      for (const keyword of keywords) {
        str = str.replace(new RegExp(`-${keyword}`, "i"), "");
      }

      return str;
    },
    async goToCustomerPortal() {
      const response = await this.$store.dispatch('accounts/getStripeCustomerPortal', {
        returnUrl: window.location.href,
      })

      if (typeof response.data === 'string') {
        window.location.href = response.data
      } else {
        this.$error(this.$t(`We couldn't generate the stripe customer portal link. Please try again or contact us.`))
      }
    },
    async choosePlan(plan: Plan) {
      this.chosenPlan = plan
      this.showPlanPaymentDialog = true
    },
  },
  watch: {
    allPlans: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        const matchingPlan = this.allPlans.find(p => p.id === this.userPlan?.plan?.id)

        if (matchingPlan && matchingPlan.identifier !== freePlanIdentifier) {
          this.selectedFrequency = matchingPlan.frequency
          this.chosenPlan = matchingPlan
        }
      }
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch("accounts/getPlans"),
      this.$store.dispatch("accounts/getSubscription", { forceFetch: true }),
      this.$store.dispatch('auth/getTenantDetails'),
    ])
  }
})
</script>
<style scoped>
.plan-title {
  font-weight: 900;
  font-size: 60px;
  line-height: 60px;
  color: #431794;
  background: -webkit-linear-gradient(-20deg, #541FAD 35%, #E928A1, #FF9A0B 65%);
  -webkit-background-clip: text;  -webkit-text-fill-color: transparent;
}
.plan-title-free {
  font-weight: 900;
  font-size: 60px;
  line-height: 60px;
  color: #6b7280;
}
</style>
