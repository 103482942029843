<template>
  <BaseEntityForm
    :title="$t(`Upload Files`)"
    width-class="max-w-3xl"
    @submit="onSubmit"
  > 
    <template #default="{ meta, errors}">
      <BaseDndFileUpload
        v-model="model.files"
        :renderList="false"
        class="mb-2"
      />
      <div class="max-h-[250px] overflow-y-scroll">
        <UploadFilePreview
          v-for="(file, index) of model.files"
          :key="file.id || index"
          :project_id="model.project_id"
          :task_id="model.task_id"
          :fileToUpload="file"
          :auto-upload="false"
          @remove="model.files.splice(index, 1)"
        />
      </div>

      <BaseInput
        v-if="!$store.getters.project_id"
        :label="$t('Project')"
        :name="$t('Project')"
        layout="horizontal"
        id="project"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <ProjectSelect
            v-model="model.project_id"
            v-focus="!model.project_id"
            :placeholder="$t('Choose a project to upload files to')"
            rules="required"
            :allow-entity-create="can($actions.CREATE_PROJECTS)"
            :add-entity-label="$t('Quick Create Project')"
            :initialValue="project || folder?.relationships?.project"
            @change="model.task_id = null; model.folder_id = null;"
          />
        </div>
      </BaseInput>
      <BaseInput
        v-if="model.project_id && projectHasFolders"
        :label="$t('Folder')"
        :name="$t('Folder')"
        layout="horizontal"
        id="folder"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <FolderSelect
            v-model="model.folder_id"
            :projectId="model.project_id"
            :placeholder="$t('Choose a folder (optional)...')"
            :initialValue="folder"
          />
        </div>
      </BaseInput>
      <RequiresPermissionTo
        v-if="model.project_id"
        :action="$actions.EDIT_TASKS"
      >
        <BaseInput
          :label="$t('Task')"
          :name="$t('Task')"
          layout="horizontal"
          id="task"
          class="w-full mt-1 sm:mt-0 md:col-span-2"
        >
          <div class="sm:mt-0 sm:col-span-4">
            <TaskSelect
              v-model="model.task_id"
              :disabled="!model.project_id"
              :urlParams="{
                project_id: model.project_id,
              }"
              :placeholder="$t('Choose task (optional)...')"
            />
          </div>
        </BaseInput>
      </RequiresPermissionTo>

      <CustomFieldsEntityForm
        v-model="model.custom_fields"
        :col-span="3"
        :column-count="4"
        entity-type="media"
      />
    </template>
    <template #actions="{ meta, errors}">
      <BaseButton
        variant="white"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        v-if="someFailedUploading"
        class="ml-2"
        @click="$emit('save', model)"
      >
        {{ $t('Close') }}
      </BaseButton>
      <BaseButton
        v-else
        :loading="loading"
        :disabled="!canUploadFiles(meta)"
        type="submit"
        class="ml-2"
      >
        {{ $t('Upload & Save') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
// Libs / Utils
import i18n from '@/i18n'
import { cloneDeep } from 'lodash-es'
import { getCustomFieldValuesStringified } from '@/modules/accounts/utils/modelUtils.js'
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";

// Components
import ProjectSelect from '@/components/selects/ProjectSelect.vue'
import TaskSelect from '@/components/selects/TaskSelect.vue'
import UploadFilePreview from '@/modules/common/components/UploadFilePreview.vue'
import CustomFieldsEntityForm from '@/modules/common/components/CustomFieldsEntityForm.vue'
import FolderSelect from '@/components/selects/FolderSelect.vue'

export default {
  name: 'FileForm',
  components: {
    CustomFieldsEntityForm,
    ProjectSelect,
    TaskSelect,
    FolderSelect,
    UploadFilePreview,
  },
  props: {
    folder: {
      type: Object,
      default: () => null,
    },
    project: {
      type: Object,
      default: () => null,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['save', 'cancel'],
  setup() {
    const {
      checkWillPassStorageLimit
    } = useAccountLimits()

    return {
      checkWillPassStorageLimit
    }
  },
  data() {
    return {
      loading: false,
      model: {
        project_id: this.project?.id || this.folder?.attributes?.project_id || this.$store.getters.project_id || null,
        folder_id: this.folder?.id || null,
        task_id: null,
        files: this.files || [],
        custom_fields: {},
      },
      uploadedFiles: [],
      someFailedUploading: false,
    }
  },
  computed: {
    projectHasFolders() {
      return this.$store.getters['files/foldersTreeData'](this.model.project_id)?.length > 0
    },
  },
  methods: {
    willPassStorageLimit() {
      const size = this.model.files.reduce((acc, file) => acc + file.size, 0)

      return this.checkWillPassStorageLimit(size)
    },
    async onSubmit(emitSave = true) {
      try {
        if (this.willPassStorageLimit()) {
          return
        }

        this.loading = true

        const data = cloneDeep(this.model)
        data.custom_fields = getCustomFieldValuesStringified(data.custom_fields)

        data.files = data.files.filter(file => !file.sizeLimitExceeded)

        this.uploadedFiles = await this.$store.dispatch(
          'files/uploadFiles',
          { data },
        )

        if (data.folder_id) {
          this.$store.commit('triggerGridCellRefresh', {
            rowNodeIds: [`folders_${data.folder_id}`],
            columns: ['options'],
            force: true,
          })
        }

        this.model.files.forEach(file => {
          file.uploaded = !file.sizeLimitExceeded
        })

        this.someFailedUploading = data.files.length !== this.model.files.length

        if (this.someFailedUploading) {
          this.$error(i18n.t('Some files could not be uploaded'))
          return
        }

        this.$success(i18n.t('Files uploaded successfully'))

        if (emitSave) {
          this.$emit('save', this.model)
        }
      } catch (err) {
        if (err.handled) {
          return false
        }

        this.$error(i18n.t('Could not upload files'))
        return false
      } finally {
        this.loading = false
      }
      return true
    },
    onCancel() {
      if (this.someFailedUploading) {
        this.$emit('save', this.model)
        return
      }

      this.$emit('cancel')
    },
    canUploadFiles(meta) {
      return meta?.valid && this.model.project_id && this.model.files?.length
    },
  },
}
</script>
