<template>
  <BaseDialogNew
    v-model="visible"
    :hasBodyMargin="false"
    :appendToBody="true"
    class="max-w-2xl"
    @close="$emit('close')"
  >
    <ProjectLinkForm 
      :link="link"
      :defaultLinkType="defaultLinkType"
      :defaultPinned="defaultPinned"
      @cancel="visible = false"
      @save="onLinkCreated"
    />
  </BaseDialogNew>
</template>
<script>
import ProjectLinkForm from "@/modules/projects/components/ProjectLinkForm.vue";

export default {
  components: {
    ProjectLinkForm,
  },
  props: {
    link: {
      type: Object,
      default: () => ({})
    },
    defaultLinkType: {
      type: String,
      default: ''
    },
    defaultPinned: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
  methods: {
    onLinkCreated(link) {
      this.$emit('save', link);
      this.visible = false
    }
  }
}
</script>
