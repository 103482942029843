<template>
  <div
    class="h-full app"
    :class="{
      'use-new-layout': useNewLayout,
    }"
  >
    <el-config-provider
      :locale="elementLocale"
      :key="appKey"
    >
      <notifications />
      <router-view />
    </el-config-provider>
  </div>
</template>
<script>
// Libs
import { ElConfigProvider } from 'element-plus'
import { getElPlusLocale } from '@/i18n'
import dayjs from 'dayjs'

// Helpers
import AuthService from '@/modules/auth/services/AuthService.js'
import {
  trackNewRegister,
} from "@/modules/auth/utils/registerUtils"

import { isRootApi } from "@/modules/common/config.js";
import {
  applyCustomBackground,
  applyCustomPrimaryColor,
  toggleShadow,
  applyPrimaryColorTools,
  updateFavicons,
  applyCustomBreadCrumbColor,
} from "@/modules/accounts/utils/accountBrandingUtils.js"
import { loadFbPixel } from '@/utils/fbPixel'
import { disableShiftClickSelection } from "@/utils/globalListeners";

export default {
  components: {
    ElConfigProvider
  },
  data() {
    return {
      appKey: 1
    }
  },
  computed:  {
    elementLocale() {
      return getElPlusLocale()
    },
    appLoading: {
      get() {
        return this.$store.state.appLoading
      },
      set(value) {
        this.$store.commit('setAppLoading', value)
      }
    },
    isV1DeleteRunning() {
      return this.$store.getters['accounts/isV1DeleteRunning']
    },
    isV1ImportRunning() {
      return this.$store.getters['accounts/isV1ImportRunning']
    },
    // Custom branding
    isCustomBrandingEnabled() {
      return this.$store.getters['accounts/isCustomBrandingEnabled']
    },
    actionButtonColor() {
      return this.$settings('action_button_color')
    },
    backgroundColorSetting() {
      return this.$settings('background_color')
    },
    backgroundImageSetting() {
      return this.$settings('background_image')
    },
    shadow() {
      return this.$settings('shadow')
    },
    primaryColorTools() {
      return this.$settings('primary_color_tools')
    },
    accountName() {
      return this.$settings('account_name')
    },
    breadcrumbColor() {
      return this.$settings('breadcrumb_color')
    },
    weekStartDay() {
      return this.$settings('week_start_day')
    },
    tempBranding() {
      return this.$store.state.accounts.tempBranding
    },
    currentFavicon() {
      const defaultFavicon = '/favicon.ico'

      if (this.tempBranding) {
        return this.tempBranding.enable_branding
          ? (this.tempBranding.favicon || defaultFavicon)
          : defaultFavicon
      }

      if (!this.isCustomBrandingEnabled) {
        return defaultFavicon
      }

      return this.$settings('favicon') || defaultFavicon
    },
    useNewLayout() {
      return this.$store.state.dashboard.useNewLayout
    }
  },
  methods: {
    async tryTokenLogin() {
      const { loginToken, newAccount } = this.$route.query
      if (!loginToken) {
        return
      }

      const authToken = loginToken

      const ignoredPaths = [
        'register',
        'password',
        'no-organization',
        'version',
      ]

      for (let path of ignoredPaths) {
        if (this.$route.path.includes(path)) {
          await this.clearLoginToken()
          return
        }
      }

      try {
        if (this.$route.query.ap3c) {
          window.ap3c?.setCookie(this.$route.query.ap3c)
        }
        AuthService.setToken(authToken)
        this.$store.commit('auth/setLoggedIn', true);
        await this.$store.dispatch('auth/getProfile')
        await this.clearLoginToken()
        const isShared = await this.checkForSharedTemplate()
        if (isShared) {
          return
        }

        if (newAccount) {
          this.handleNewAccount()
        }

        await this.$router.push({
          path: '/welcome',
          query: {
            newAccount: newAccount ? true : undefined,
            ap3c: ap3c?.getCookie()
          }
        })
      } catch (err) {
        console.warn('Error logging in with token', err)
        await this.clearLoginToken()
      }
    },
    async handleNewAccount() {
      this.$store.commit('auth/setNewAccount', true)
      await this.$store.dispatch('auth/getTenantDetails')
      const tenantInfo = this.$store.state.auth.tenantInfo

      trackNewRegister(this.$user, tenantInfo)
    },
    async checkForSharedTemplate() {
      const query = { ...this.$route.query }
      if (!query.share_template) {
        return
      }
      this.tryStoreShareTemplateId()

      if (isRootApi()) {
        return
      }
      const isSuccessful = await this.$store.dispatch('templates/copySharedTemplate', query.share_template)
      if (!isSuccessful) {
        return
      }
      await this.$router.push('/templates/list')
      return isSuccessful
    },
    tryStoreShareTemplateId() {
      if (!this.$route.query.share_template) {
        return
      }
      this.$store.commit('templates/setTemplateToShare', this.$route.query.share_template)
    },
    async clearLoginToken() {
      const query = { ...this.$route.query }
      delete query.loginToken
      this.$router.replace({ query })
    },
    tryToggleLoading() {
      const requiresAuth = this.$route.matched.some(record => record.meta.requiresAuth);

      if (requiresAuth && !this.$route.path.includes('select-organization')) {
        return
      }

      this.appLoading = false
      document.title = `${this.$route.name} - ${this.accountName || 'Project.co'}`
    },
    async maintenanceCheck() {
      await this.$nextTick()

      const closedForMaintenance = JSON.parse(import.meta.env.VITE_CLOSED_FOR_MAINTENANCE || "false")

      if (closedForMaintenance) {
        return this.$router.push('/maintenance')
      }
    },
  },
  watch: {
    async $route() {
      await this.tryTokenLogin()
      this.tryStoreShareTemplateId()
      this.tryToggleLoading()
    },
    isV1DeleteRunning(value) {
      if (!value) {
        this.appKey++
      }
    },
    isV1ImportRunning(value) {
      if (!value) {
        this.appKey++
      }
    },
    actionButtonColor: {
      immediate: true,
      handler(value) {
        if (!this.isCustomBrandingEnabled) {
          return
        }

        applyCustomPrimaryColor(value)
      }
    },
    shadow: {
      immediate: true,
      handler(value) {
        if (!this.isCustomBrandingEnabled) {
          return
        }

        toggleShadow(value)
      }
    },
    primaryColorTools: {
      immediate: true,
      handler(value) {
        if (!this.isCustomBrandingEnabled) {
          return
        }

        applyPrimaryColorTools(value)
      }
    },
    breadcrumbColor: {
      immediate: true,
      handler(value) {
        if (!this.isCustomBrandingEnabled) {
          return
        }

        applyCustomBreadCrumbColor(value)
      }
    },
    useNewLayout: {
      immediate: true,
      handler(useNewLayout) {
        if (!this.isCustomBrandingEnabled || (!this.backgroundColorSetting && !this.backgroundImageSetting)) {
          return
        }

        if (this.backgroundColorSetting && (useNewLayout || !this.backgroundImageSetting)) {
          applyCustomBackground(this.backgroundColorSetting)
          return
        }

        applyCustomBackground(this.backgroundImageSetting, true)
      }
    },
    backgroundColorSetting: {
      immediate: true,
      handler(value) {
        if (!this.isCustomBrandingEnabled || !value) {
          return
        }

        if (!this.useNewLayout && this.backgroundImageSetting) {
          return
        }

        applyCustomBackground(value)
      }
    },
    currentFavicon: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }

        updateFavicons(value)
      }
    },
    weekStartDay: {
      immediate: true,
      handler(value) {
        value = Number(value || 1)
        dayjs().$locale().weekStart = value;
      }
    },
    // Uncomment for debugging filter/sorting requests
    // '$route.query.filters'(filters) {
    //   if (!filters) {
    //     return
    //   }
    //   console.log('filters', decodeFilters(filters.toString()))
    // },
    // '$route.query.localFilters'(localFilters) {
    //   if (!localFilters) {
    //     return
    //   }
    //   console.log('localFilters', decodeFilters(localFilters.toString()))
    // }
  },
  created() {
    this.maintenanceCheck()
  },
  mounted() {
    loadFbPixel();
    disableShiftClickSelection();
  }
}
</script>
