<template>
  <TasksTable
    :data="data"
    :loading="isLoading"
    class="flex-1"
    :masterDetail="showDetailsView"
  />
</template>
<script lang="ts" setup>
// Components
import TasksTable from "@/modules/tasks/components/TasksTable.vue";

// Utils
import { getEntityArrayForWorker } from "@/modules/common/utils/entityUtils";
import {
  filterOutChildTasksWorker
} from '@/modules/tasks/utils/taskRelationshipUtils'

import {
  completedTasksFilters
} from "@/modules/tasks/utils/taskTableUtils"

import { debounce } from "lodash-es";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore()

const apiFilters = computed(() => {
  return store.getters['filters/targetApiFilters']('tasks') || []
})

const showDetailsView = computed(()=> {
  return apiFilters.value.find((filter: any) => filter.key === 'show-details-view')?.value?.show || false
})

const showGlobalCompletedTasks = computed(() => {
  return apiFilters.value.find((filter: any) => filter.key === 'global-completed-tasks')?.value
})

const showCompletedTasks = computed(() => {
  return apiFilters.value.find((filter: any) => filter.key === 'completed')?.value?.show
})

const loading = computed(() => {
  return store.state.tasks.tasksLoading
})

const completedTasksLoaded =  computed(() => {
  return store.state.tasks.completedTasksLoaded
})

const isLoading = ref(true)
const data = ref<any[]>([])

const syncTaskDataDebounced = debounce(syncTaskData, 100)

watch(() => loading.value, (value) => {
  isLoading.value = value
  if (value) {
    return
  }

  syncTaskData()
}, { immediate: true })

watch(() => showGlobalCompletedTasks.value, syncTasksHandler, { immediate: true })
watch(() => showCompletedTasks.value, syncTasksHandler, { immediate: true })

watch(() => completedTasksLoaded.value, syncTasksHandler, { immediate: true })

watch(() => showDetailsView.value, syncTasksHandler, { immediate: true })

watch(() => store.state.tasks.projectCompletedTasksLoaded, syncTasksHandler, { immediate: true, deep: true })

watch(() => store.state.tasks.completedTaskToggle, syncTasksHandler)

watch(() => store.state.tasks.projectTasksToggle, syncTasksHandler)

watch(() => store.state.tasks.subtasksChangedTrigger, syncTasksHandler)

function syncTasksHandler() {
  if (isLoading.value) {
    return
  }

  syncTaskDataDebounced()
}

async function syncTaskData() {
  if (isLoading.value) {
    data.value = []
    return
  }

  const tasks = store.getters['tasks/currentTasks'] || []
  if (!showDetailsView.value) {
    data.value = tasks
    return
  }

  if (showGlobalCompletedTasks.value !== completedTasksFilters.AllTime) {
    data.value = tasks.filter((task: any) => !task.attributes.parent_id)
    return
  }

  try {
    isLoading.value = true

    const workerTasks = getEntityArrayForWorker(tasks)
    data.value = await filterOutChildTasksWorker(workerTasks)
  }
  catch (error) {
    data.value = tasks
    throw ({
      message: 'Failed to sync tasks master detail view',
      error
    })
  }
  finally {
    isLoading.value = false
  }
}
</script>
