<template>
  <ProjectPeopleForm
    :projectId="projectId"
  />
</template>
<script>
import ProjectPeopleForm from "@/modules/projects/components/ProjectPeopleForm.vue"

export default {
  name: 'ProjectPeople',
  components: {
    ProjectPeopleForm
  },
  computed: {
    projectId() {
      return this.$route.params.id
    },
  },
}
</script>
