<template>
  <BaseNoDataSection
    :title="$t('No Past Exports')"
    iconClass="fa-file-csv"
    :canCreate="can($actions.MANAGE_ACCOUNT_SETTINGS)"
  >
    <template #can-create-section>
      <div>{{ $t('Export data from your account.') }}</div>
      <div>{{ $t('Data can be exported from different entities using the data type select above.') }}</div>
      <div>{{ $t('Your past exports will show here.')  }}</div>

      <BaseTutorialLink
        name="export"
        class="hover:text-primary-500"
      >
        <div>{{ $t('Find out more about exporting data here.') }}</div>
      </BaseTutorialLink>
    </template>
  </BaseNoDataSection>
</template>
<script setup />
