<template>
  <div class="flex flex-col items-center justify-center content-wrapper">
    <div
      class="mx-auto max-w-screen-xl md:min-w-[700px] p-8 md:p-10 bg-white rounded-lg"
      :class="{
        'shadow-md': !$useNewLayout
      }"
    >
      <div class="text-center">
        <p class="text-base font-semibold leading-8 text-primary-500">{{$t('404')}}</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{{$t('This page does not exist')}}</h1>
        <p class="mt-2 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-4 md:text-xl md:max-w-3xl">
          {{ $t(`We couldn't find the page you are looking for`) }}
        </p>
        <div class="mt-8 max-w-md mx-auto sm:space-x-4 flex flex-col sm:flex-row justify-center md:mt-16">
          <BaseButton
            variant="white"
            size="lg"
            block
            class="mb-4 sm:mb-0"
            @click="$router.go(-1)"
          >
            {{ $t('Go back') }}
          </BaseButton>
          <BaseButton
            variant="primary"
            size="lg"
            block
            @click="$router.push('/')"
          >
            {{ $t('Take me Home') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style scoped>
.content-wrapper {
  min-height: calc(100vh - 150px);
}
</style>
