<template>
  <div
    class="w-full flex items-center"
    @click.stop="onClick"
  >
    {{ $t('Status') }}
    <BaseTooltip
      v-if="canEditStatuses"
      :content="$t(`Edit the values for this field.`)"
    >
      <i
        class="fa-solid fa-pen-to-square ml-2 text-gray-300 hover:text-gray-400 cursor-pointer block"
        @click.stop="showDialog = true"
      />
    </BaseTooltip>
    
    <span
      v-if="activeSortForColumn?.sortIndex"
      class="ag-sort-indicator-icon ag-sort-order"
    >
      {{ activeSortForColumn.sortIndex }}
    </span>

    <span
      v-if="isSortAscending"
      class="ag-sort-indicator-icon ag-sort-ascending-icon"
    >
      <span class="ag-icon ag-icon-asc" unselectable="on" role="presentation" />
    </span>
    <span
      v-if="isSortDesc"
      class="ag-sort-indicator-icon ag-sort-descending-icon"
    >
      <span class="ag-icon ag-icon-desc" unselectable="on" role="presentation" />
    </span>

  </div>
  
  <TaskStatusesDialog
    v-if="target === 'tasks'"
    v-model="showDialog"
  />
  <ProjectStatusesDialog
    v-else
    v-model="showDialog"
  />
</template>
<script lang="ts" setup>
// Components
import ProjectStatusesDialog from "@/modules/accounts/components/ProjectStatusesDialog.vue";
import TaskStatusesDialog from "@/modules/accounts/components/TaskStatusesDialog.vue";

// Utils
import { computed, PropType, ref } from 'vue'
import { IHeaderParams } from '@ag-grid-community/core';
import { getActiveSortForColumn } from "@/modules/filters/utils/commonFilterUtils";

// Composables
import useCan from "@/modules/common/composables/useCan.js";
const { can, actions } = useCan()

const props = defineProps({
  params: {
    type: Object as PropType<IHeaderParams & { target: 'tasks' | 'projects' }>,
    default: () => ({})
  },
})

const showDialog = ref(false)

const target = computed(() => {
  return props.params.target
})

const activeSortForColumn = computed(() => {
  return getActiveSortForColumn(target.value, props.params.column?.getColDef()) 
})

const isSortAscending = computed(() => {
  return activeSortForColumn?.value?.order === 'asc'
})

const isSortDesc = computed(() => {
  return activeSortForColumn?.value?.order === 'desc'
})

const canEditStatuses = computed(() => {
  if (target.value === 'tasks') {
    return can(actions.EDIT_TASK_STATUSES)
  }

  return can(actions.EDIT_PROJECT_STATUSES)
})

function onClick(event: MouseEvent) {
  if (!props.params.enableSorting) {
    return
  }

  props.params.progressSort(event.shiftKey)
}
</script>
