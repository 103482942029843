<template>
  <div>
    <BaseInput
      v-model="companyHomepage"
      @update:modelValue="loading = true"
      placeholder="e.g. Project.co"
      name="Clearbit logo search"
      @keyup.enter.stop="selectPhoto"
    />
    <div class="mt-3 text-center text-xs text-gray-400">
      <div>
        {{ $t("Enter a homepage URL and we'll import their logo if it's available.") }}
      </div>
      {{ $t('Powered by') }}
      <a class="underline" :href="CLEARBIT_URL" target="_blank">{{ $t('Clearbit') }}</a>
    </div>
    <div
      v-if="companyHomepage"
      v-loading="loading"
      class="mt-3 grid h-full items-center flex-wrap gap-2 w-full place-items-center"
    >
      <img
        ref="companyLogo"
        :src="imgClearbiturl"
        class="m-auto h-24 w-24 rounded-md object-cover cursor-pointer flex-1"
        crossorigin="anonymous"
        @load="loading = false"
        @click="selectPhoto"
      />
    </div>
  </div>
</template>
<script>
import { imgToFile } from "@/modules/common/utils/imageUtils"
const CLEARBIT_URL = 'https://clearbit.com/'
const CLEARBIT_LOGO_URL = 'http://logo.clearbit.com/'
export default {
  data() {
    return {
      CLEARBIT_URL,
      companyHomepage: '',
      loading: false,
    }
  },
  computed: {
    imgClearbiturl() {
      return `${CLEARBIT_LOGO_URL}${this.companyHomepage}`
    }
  },
  methods: {
    async selectPhoto() {
      if (this.loading) {
        return
      }

      try {
        const file = await imgToFile(this.$refs.companyLogo, this.photoUrl)
        this.$emit('select-logo', file)
      }
      catch {
        this.$emit('select-url', this.imgClearbiturl)
      }
    }
  },
}
</script>
