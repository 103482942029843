<template>
  <BaseDropdown
    :options="availableOptions"
    :slim="slim"
    divide-items
    @action="onAction"
  >
    <template #default>
      <slot />
    </template>
  </BaseDropdown>
</template>
<script>
import { triggerEditCustomField } from "@/modules/accounts/utils/modelUtils"
import i18n from '@/i18n'

export default {
  props: {
    customField: {
      type: Object,
      default: () => ({}),
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit'],
  computed: {
    isDisabled() {
      return this.customField?.attributes?.is_disabled
    },
    project_id() {
      return this.$store.getters.project_id
    },
    options() {
       return [
        {
          label: i18n.t('Edit'),
          action: () => triggerEditCustomField(this.customField),
          enabled: () => {
            return this.can(this.$actions.EDIT_CUSTOM_FIELDS)
              || (this.project_id && this.can(this.$actions.EDIT_CUSTOM_FIELDS_INSIDE_PROJECTS))
          },
        },
        {
          label: this.isDisabled ? i18n.t('Enable') : i18n.t('Disable'),
          action: 'confirmToggleDisabled',
          enabled: () => {
            return this.can(this.$actions.DELETE_CUSTOM_FIELDS)
              || (this.project_id && this.can(this.$actions.DELETE_CUSTOM_FIELDS_INSIDE_PROJECTS))
          },
        },
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          enabled: () => {
            return this.can(this.$actions.DELETE_CUSTOM_FIELDS)
              || (this.project_id && this.can(this.$actions.DELETE_CUSTOM_FIELDS_INSIDE_PROJECTS))
          },
        }
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    },
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.customField)
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t("Delete Custom Field"),
        description: i18n.t(
          "Are you sure? This will permanently delete the custom field along with all of its saved data."
        ),
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch("accounts/deleteCustomField", { customField: this.customField });

        this.$success(i18n.t("Custom Field deleted successfully"));
      } catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(i18n.t("Cannot delete Custom Field"));
        throw err;
      }
    },
    async confirmToggleDisabled() {
      
      if (this.isDisabled) {
        // Need to re-enable without confirmation
        await this.$store.dispatch("accounts/toggleCustomFieldDisabled", {
          customField: this.customField,
          disable: false
        });
        return
      }

      const confirmed = await this.$deleteConfirm({
        title: i18n.t("Disable Custom Field"),
        description: i18n.t(
          "If you disable this custom field it will be hidden from view in the application.  Its data will not be deleted. If you re-enable the custom field it will be visibale again and all data will reappear."
        ),
        buttonText: i18n.t("Disable")
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch("accounts/toggleCustomFieldDisabled", {
          customField: this.customField,
          disable: true
        });
        this.$success(i18n.t("Custom Field disabled successfully"));
      } catch (err) {
        if (err.handled) {
          return;
        }
        console.log(err)
        this.$error(i18n.t("Cannot disable custom field"));
        throw err;
      }
    },
  },
};
</script>
