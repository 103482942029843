import { taskDateTypes } from "@/modules/tasks/utils/modelUtils"
import {
  getCustomFieldValuesStringified
} from "@/modules/accounts/utils/modelUtils"

function preprocessModel(model, isEdit = false) {
  if (!isEdit && !model.parent_id) {
    delete model.parent_id
  }

  if (model.date_type === taskDateTypes.DATE_RANGE) {
    model.start_date = model.date?.start || model.start_date
    model.end_date = model.date?.end || model.end_date
  }

  if (model.date_type !== taskDateTypes.SINGLE_DATE) {
    delete model.date
  }

  if (model.date_type !== taskDateTypes.DATE_RANGE) {
    delete model.start_date
    delete model.end_date
  }

  if (model.date_type !== taskDateTypes.RECURRING) {
    delete model.recurrence
    delete model.recurrence_date
  }

  if (model.date_type !== taskDateTypes.DAYS_FROM_PROJECT_START) {
    delete model.days_from_project_start
  }

  if (model.date_type !== taskDateTypes.WORKING_DAYS_FROM_PROJECT_START) {
    delete model.working_days_from_project_start
  }

  if (!model.notes) {
    delete model.notes
  }

  delete model.project
}

export function taskModelToFormData(model, isEdit = false) {
  preprocessModel(model, isEdit)

  const formData = new FormData();

  for (const prop in model) {
    if (
      [
        'custom_fields',
        'files',
        'follower_ids',
        'group_ids',
        'allocated_ids',
        'notifiable_user_ids',
        'sortable_date',
      ].includes(prop)
    ) {
      continue
    }

    if (Array.isArray(model[prop])) {
      for (const v of model[prop]) {
        formData.append(`${prop}[]`, getFormDataValue(v))
      }
      continue
    }

    const value = getFormDataValue(model[prop])
    formData.append(prop, value)
  }

  formData.append('custom_fields', getCustomFieldValuesStringified(model.custom_fields))

  for (const file of (model.files || [])) {
    if (file.raw) {
      formData.append('files[]', file.raw)
    }
  }

  model.group_ids = model.group_ids || []
  for (const group_id of model.group_ids) {
    formData.append('group_ids[]', +group_id)
  }

  if (!model.group_ids.length) {
    formData.append('group_ids[]', null)
  }

  model.follower_ids = model.follower_ids || []
  for (const follower_id of model.follower_ids) {
    formData.append('follower_ids[]', +follower_id)
  }

  if (!model.follower_ids.length) {
    formData.append('follower_ids[]', null)
  }

  model.allocated_ids = model.allocated_ids || []
  for (const allocated_id of model.allocated_ids) {
    formData.append('allocated_ids[]', +allocated_id)
  }

  if (!model.allocated_ids.length) {
    formData.append('allocated_ids[]', null)
  }

  model.notifiable_user_ids = model.notifiable_user_ids || []
  for (const notifiable_user_id of model.notifiable_user_ids) {
    formData.append('notifiable_user_ids[]', +notifiable_user_id)
  }

  return formData
}

export function processDuplicateTask(data) {
  const fieldsToDelete = ['id', 'created_at', 'completed_at', 'subtasks_count', 'files_count']
  fieldsToDelete.forEach(field => {
    delete data[field]
  })

  if (data.start_date || data.end_date) {
    data.date = {
      start: data.start_date,
      end: data.end_date,
    }
  }
  data.name = `Copy of ${data.name}`

  return taskModelToFormData(data);
}

function getFormDataValue(value) {
  if (value instanceof Date) {
    return getDateWithoutTimezone(value).toISOString()
  }
  return value
}

export function getDateWithoutTimezone(date) {
  return new Date(date.valueOf() - date.getTimezoneOffset() * 60 * 1000);
}
