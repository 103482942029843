<template>
  <div class="h-full relative">
    <WarningAlert
      v-if="!isProjectCreate && !projectLoading && !isValidModel"
      :dismissable="false"
      class="border-yellow-700 border max-w-4xl m-auto mb-4"
    >
      {{ $t('Please complete all required fields labelled with * or the project will not be saved.') }}
    </WarningAlert>
    <ProjectDetailsLoading
      v-if="!isProjectCreate && projectLoading && !$route.query?.newProject"
    />
    <div
      v-else
      :key="$route.fullPath"
      class="project-inline-details h-full"
      :class="{
        'border-gray-200 border shadow mb-6': !isDialog && !useNewLayout,
        'max-w-4xl m-auto rounded-lg bg-white': !useNewLayout
      }"
    >
      <div
        :class="{
          'px-4 pr-4 sm:px-8 pt-4 sm:pt-6 min-h-[calc(100vh-120px)]': useNewLayout
        }"
      >
        <div
          v-if="model.cover_image || model.image"
          class="overflow-hidden"
          :class="{
            'rounded-t-lg': !useNewLayout,
            'rounded-lg': useNewLayout,
          }"
        >
          <img
            v-if="model.cover_image"
            :src="coverImageSrc"
            :key="model.cover_image"
            class="w-full object-cover object-top h-36"
          />

          <div
            v-if="model.image && useNewLayout"
            class="rounded-lg p-1 text-center bg-white"
            :class="{
              'absolute top-12 left-12 sm:top-14 sm:left-14': model.cover_image,
            }"
          >
            <BaseAvatar
              :avatar="projectImageSrc"
              :clickable="false"
              size="xlg sm:xl"
              emojiClass="text-[5rem] leading-none pb-2"
            />
          </div>
        </div>
        <div
          class="bg-white rounded-lg space-y-2"
          :class="{
            'pt-0 sm:pt-0 mt-4': useNewLayout,
            'p-6 sm:p-10 h-full': !useNewLayout,
          }"
        >
          <div
            v-if="model.image && !useNewLayout"
            class="flex relative"
            :class="{
            '-mt-10': model.cover_image && !model.image,
            '-mt-20 sm:-mt-28': model.cover_image && model.image
          }"
          >
            <div class="rounded-lg p-1 text-center bg-white" >
              <BaseAvatar
                :avatar="projectImageSrc"
                :clickable="false"
                size="xlg sm:xl"
                emojiClass="text-[5rem] leading-none"
              />
            </div>
          </div>
          <div class="relative">
            <div class="flex flex-wrap gap-2 items-center mb-3">
              <ProjectImages
                v-if="canEditFields || isSaving"
                v-model:image="model.image"
                v-model:cover_image="model.cover_image"
                @update:image="trySaveProject(false)"
                @update:cover_image="trySaveProject(false)"
                :project="project"
                :disabled="!canEditFields"
                :isProjectCreate="isProjectCreate"
                class="self-start flex flex-wrap gap-2 items-center"
              />
              <!-- Start with a template -->
              <div
                v-if="!isTemplatePath && !project?.id && can($actions.VIEW_AND_USE_PROJECT_TEMPLATES) && canEditFields"
                class="flex items-top bg-white rounded-t-md pr-2 start-with-a-template"
              >
                <BaseCustomActivatorInput>
                  <ProjectSelect
                    v-model="templateId"
                    is-template
                    @raw-change="onTemplateSelect"
                    :allow-entity-create="can($actions.CREATE_PROJECT_TEMPLATES)"
                    :addEntityLabel="$t('View the template gallery')"
                    :addEntityAction="() => triggerEntityCreate(entityTypes.TemplateGallery)"
                  />
                  <template #activator>
                    <div class="start-with-template-label pr-4 text-xs text-gray-300 cursor-pointer">
                      <i class="fa-solid fa-shapes mr-1"></i>
                      {{ $t('Start with a template') }}
                    </div>
                  </template>
                </BaseCustomActivatorInput>
                <div
                  v-if="templateId"
                  class="relative w-7 h-7 remove-template-control"
                >
                  <div
                    class="flex sm:hidden circle-remove rounded-md items-center justify-center cursor-pointer absolute inset-0 bg-red-500 bg-opacity-70"
                    @click="removeSelectedTemplate"
                  >
                    <XCircleIcon class="w-5 h-5 text-white" />
                  </div>
                  <BaseLogo
                    :entity="selectedTemplate"
                    size="sm"
                    class="h-full w-full mx-auto"
                  />
                </div>
              </div>

            </div>

            <UpgradePlanWarning
              v-if="isProjectCreate && !projectLoading && maxActiveProjectsLimitReached && !isTemplatePath"
              :message="$t(' in order to create more projects. You can also mark an active project as complete or delete it.')"
              class="mb-4 m-auto"
              :class="{
                'mt-4': $useNewLayout,
                'max-w-4xl': !$useNewLayout,
              }"
            >
              <template #before-link>
              <span class="mr-1">
                {{
                    $tc('account limit reached', {
                      limit: 'active projects',
                      maxCount: accountLimits.projects.available,
                    })
                  }}
              </span>
              </template>
            </UpgradePlanWarning>
            <div class="flex items-">
              <BaseTooltip
                v-if="isProjectClosed"
                :content="$t('This project is completed. In order to make changes, please reopen it first.')"
              >
                <i class="cursor-pointer fas fa-lock text-2xl mr-2 text-red-600" />
              </BaseTooltip>

              <!-- Project Name -->
              <BaseInlineTextareaEdit
                id="project-dashboard-name"
                v-model="model.name"
                :readonly="!canEditFields"
                :allow-line-breaks="false"
                :autofocus="isProjectCreate"
                auto-resize
                class="text-xl sm:text-3xl font-bold text-gray-900 w-full"
                :placeholder="isTemplatePath ? $t('Name your template...') : $t('Name your project...')"
                @keydown.enter.stop="onNameEnterPressed"
              />

              <BaseButton
                v-if="model.name && isProjectCreate"
                class="whitespace-nowrap"
                :loading="isSaving"
                @click="tryCreateProject"
              >
                {{ $t('Create') }}
              </BaseButton>
            </div>
          </div>

          <!-- Project Properties -->
          <div
            id="project-dashboard-properties"
            class="space-y-2.5 project-properties"
          >
            <div class="flex items-center">
              <div class="text-gray-700 text-sm font-bold mr-2">{{ $t('Status') }}</div>
              <ProjectStatusInlineSelect
                v-model="model.status_id"
                @update:modelValue="onStatusChange"
                :disabled="!canEditFields"
              />
            </div>
            <div class="flex items-center">
              <div class="text-gray-700 text-sm font-bold mr-2">{{ $t('Dates') }}</div>
              <ProjectDates
                class="text-xs text-gray-400 whitespace-nowrap"
                v-model:start_date="model.start_date"
                v-model:deadline="model.deadline"
                v-model:completed_at="model.completed_at"
                :project="project"
                :disabled="!canEditFields"
              />
            </div>
            <div class="flex items-center">
              <div class="text-gray-700 text-sm font-bold mr-2">{{ $t('Privacy') }}</div>
              <ProjectPrivacyInlineSelect
                v-model="model.privacy"
                :project="project"
                :disabled="!canEditFields || !can(actions.CHANGE_PROJECT_PRIVACY)"
              />
            </div>

            <ProjectGroups
              :project="project"
              :canEditFields="canEditFields"
              :isProjectSaving="isSaving"
            />

            <ProjectUsers
              :project="project"
              :type="UserTypes.Creators"
              :canEditFields="canEditFields"
              :isProjectSaving="isSaving"
            />

            <ProjectUsers
              :project="project"
              :type="UserTypes.Collaborators"
              :canEditFields="canEditFields"
              :isProjectSaving="isSaving"
            />

            <ProjectCustomFields
              :project="project"
              :disabled="!canEditFields"
              v-model="model.custom_fields"
              class="disabled-focus"
            />
          </div>
          <ProjectDescription
            v-model="model.description"
            v-model:notifiable_user_ids="model.notifiable_user_ids"
            :loading="projectLoading"
            :project="project"
            :disabled="!canEditFields"
            :isSaving="isSaving"
            @show-options-changed="descriptionOptionsVisible = $event"
            @shortcuts-count-updated="toolShortcutsCount = $event"
          />

          <RequiresPermissionTo
            v-if="!isProjectClosed"
            :action="$actions.ADD_PROJECT_TOOLS"
          >
            <ProjectAddToolDropdown
              :isRemoveToolsEnabled="isRemoveToolsEnabled"
              :project="project"
              :disabled="!project?.id"
              :visibleToolsCount="visibleToolsCount"
              :toolShortcutsCount="toolShortcutsCount"
              :descriptionOptionsVisible="descriptionOptionsVisible"
              @toggle-remove-tools-enabled="isRemoveToolsEnabled = !isRemoveToolsEnabled"
            />
          </RequiresPermissionTo>

          <div
            class="text-xs text-gray-400 rounded-lg bg-gray-50 border border-gray-200 p-2 hidden"
          >
            <i class="fa-solid fa-triangle-exclamation text-yellow-400 mr-2" />
            {{ $t('Click a tool card below to view its page') }}
          </div>
          <ProjectToolsLoading
            v-if="projectLoading && !project?.relationships?.tools && !$route.query?.newProject"
          />
          <ProjectDashboardCards
            :project="project"
            :isRemoveToolsEnabled="isRemoveToolsEnabled"
            @visible-tools-count-changed="visibleToolsCount = $event"
            @disable-remove-tools="isRemoveToolsEnabled = false"
          />
        </div>

      </div>
      <div
        class="col-span-full px-6 py-4 border-t border-gray-200 sm:sticky bottom-0 bg-white w-full rounded-b-lg"
        :class="{
          'bottom-16 md:bottom-0': !isDialog,
          'mt-4': useNewLayout,
        }"
      >
        <template v-if="project?.id">
          <ProjectDetailsOptionsButtons
            :project="project"
            :isDialog="isDialog"
            class="self-start ml-auto hidden sm:flex flex-wrap gap-2"
            @closeProject="onCloseProject"
          />
          <ProjectDetailsOptionsDropdown
            :project="project"
            :isDialog="isDialog"
            class="self-start ml-auto sm:hidden"
            @closeProject="onCloseProject"
          />
        </template>

        <ProjectCreateButtons
          v-else
          :disabled="!isValidModel || (maxActiveProjectsLimitReached && !isTemplatePath)"
          :isTemplatePath="model.is_template"
          @cancel="isDialog ? $emit('close-dialog') : $router.push(isTemplatePath ? '/templates' : '/projects')"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
// Components
import ProjectDashboardCards from "@/modules/projects/components/ProjectDashboardCards.vue";
import ProjectDetailsLoading from "@/modules/projects/components/ProjectDetailsLoading.vue";
import ProjectToolsLoading from "@/modules/projects/components/ProjectToolsLoading.vue";
import ProjectAddToolDropdown from "@/modules/projects/components/ProjectAddToolDropdown.vue";
import ProjectUsers from "@/modules/projects/components/ProjectUsers.vue";
import ProjectGroups from "@/modules/projects/components/ProjectGroups.vue";
import ProjectCustomFields from "@/modules/projects/components/ProjectCustomFields.vue";

import ProjectDetailsOptionsButtons from "@/modules/projects/components/ProjectDetailsOptionsButtons.vue";
import ProjectDetailsOptionsDropdown from "@/modules/projects/components/ProjectDetailsOptionsDropdown.vue";
import ProjectCreateButtons from "@/modules/projects/components/ProjectCreateButtons.vue";
import ProjectImages from "@/modules/projects/components/ProjectImages.vue";
import ProjectDescription from "@/modules/projects/components/ProjectDescription.vue";
import ProjectPrivacyInlineSelect from "@/modules/projects/components/ProjectPrivacyInlineSelect.vue";
import ProjectStatusInlineSelect from "@/modules/projects/components/ProjectStatusInlineSelect.vue";
import ProjectDates from "@/modules/projects/components/ProjectDates.vue";
import ProjectSelect from "@/components/selects/ProjectSelect.vue"
import { XCircleIcon } from '@zhuowenli/vue-feather-icons'
import WarningAlert from "@/components/common/WarningAlert.vue";
import UpgradePlanWarning from "@/modules/accounts/components/UpgradePlanWarning.vue";

// Libs / helpers
import { onMounted, ref, provide, computed, watch } from "vue";
import { cloneDeep, debounce, get } from 'lodash-es'
import i18n from "@/i18n";
import { Privacy } from "@/modules/projects/utils/projectHelpers"
import {
  getCustomFieldValuesObject,
  getCustomFieldValuesStringified
} from "@/modules/accounts/utils/modelUtils"
import { error, success } from '@/components/common/NotificationPlugin';
import { getSetting } from "@/plugins/settingsPlugin";
import {
  DefaultUnsplashCoverPhoto,
  DefaultUnsplashPortraitPhoto,
} from "@/modules/common/utils/unsplashUtils";
import { UserTypes } from "@/modules/users/util/userUtils";

// Composables
import { useStore } from 'vuex';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import useCan from "@/modules/common/composables/useCan";
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import useEntityCrud from "@/modules/common/composables/useEntityCrud"
import useLayout from "@/modules/common/composables/useLayout";

const store = useStore();
const route = useRoute();
const router = useRouter();
const { can, actions } = useCan();
const {
  entityTypes,
  triggerEntityCreate,
} = useEntityCrud()

const {
  useNewLayout,
} = useLayout()

// Emits
const emit = defineEmits([
  'save',
  'close-dialog',
  'is-valid-changed',
])

// Props
const props = defineProps({
  currentProject: {
    type: Object,
    default: () => null
  },
  projectId: {
    type: [String, Number],
    default: () => null
  },
  loading: {
    type: Boolean,
    default: false
  },
  isDialog: {
    type: Boolean,
    default: false
  },
  redirectAfterSave: {
    type: Boolean,
    default: true,
  },
})

// Const
const PROJECT_INIT_MODEL = {
  image: getSetting('auto_project_image') ? DefaultUnsplashPortraitPhoto : '',
  cover_image: getSetting('auto_cover_image') ? DefaultUnsplashCoverPhoto : '',
  name: '',
  description: '',
  privacy: getSetting('default_project_privacy') || Privacy.Account,
  status_id: getSetting('default_project_status'),
  created_at: '',
  start_date: new Date(),
  is_template: false,
  deadline: '',
  completed_at: '',
  custom_fields: {},
  notifiable_user_ids: [],
}

const UNSPLASH_COVER_IMAGE_PARAMS = computed(() => {
  return {
    q: 80,
    auto: 'format',
    w: useNewLayout.value ? 1200 : 900,
    h: 150,
    fit: 'crop',
    crop: 'edges'
  }
})

const UNSPLASH_PROJECT_IMAGE_PARAMS = {
  q: 80,
  auto: 'format',
  w: 160,
  h: 160,
  fit: 'crop',
  crop: 'edges'
}

// State / Data
const isRemoveToolsEnabled = ref(false);
const descriptionOptionsVisible = ref(false);
const toolShortcutsCount = ref(0)

const project = ref(null)
const isLoading = ref(false)
const resetCount = ref(0)
const visibleToolsCount = ref(0)

const projectLoading = computed(() => {
  return props.loading || isLoading.value
})

const isProjectCreate = computed(() => {
  return !props.projectId && !props.currentProject?.id && !route?.params?.id
})

// Model
const model = ref(cloneDeep(PROJECT_INIT_MODEL))

async function tryInitFromTemplate() {
  const templateId = route.query.templateId
  if (!templateId) {
    return
  }

  try {
    const template = await store.dispatch('projects/getProjectById', {
      id: templateId,
      returnEntity: true
    })

    onTemplateSelect(template)
  }
  catch (err) {
    if (err.handled) {
      return
    }

    error(i18n.t('Cannot create project from template'))
  }
}

function resetModel() {
  model.value = cloneDeep({
    ...PROJECT_INIT_MODEL,
    status_id: defaultStatus.value?.id,
    is_template: isTemplatePath.value,
  })

  resetCount.value++
}

const isTemplatePath = computed(() => {
  return store.getters['templates/isTemplatePath']
})

const customFields = computed(() => {
  return store.getters['accounts/entityFormCustomFields']('project')
})

// Statuses
const orderedProjectStatuses = computed(() => {
  return store.getters['projects/orderedStatuses'] || []
})

const completedStatus = computed(() => {
  return orderedProjectStatuses.value[orderedProjectStatuses.value.length - 1]
})

const isProjectClosed = computed(() => {
  if (!project.value || !completedStatus.value) {
    return false
  }

  return project.value?.attributes?.status_id == completedStatus.value?.id
})

const defaultStatus = computed(() => {
  return store.getters['projects/defaultProjectStatus']
})


watch(defaultStatus, (value) => {
  if (value && value.id && !model.value.status_id) {
    model.value.status_id = value.id
  }
}, { immediate: true })

// Properties
const coverImageSrc = computed(() => {
  if (!model.value.cover_image) {
    return null
  }

  if (!model.value.cover_image.includes('unsplash')) {
    return model.value.cover_image
  }

  const url = new URL(model.value.cover_image);
  const urlParams = new URLSearchParams();

  for (const key in UNSPLASH_COVER_IMAGE_PARAMS.value) {
    urlParams.set(key, UNSPLASH_COVER_IMAGE_PARAMS.value[key])
  }

  url.search = `?${urlParams.toString()}`

  return url.toString()
})

const projectImageSrc = computed(() => {
  if (!model.value.image) {
    return null
  }
  
  if (!model.value.image.includes('unsplash')) {
    return model.value.image
  }

  const url = new URL(model.value.image);
  const urlParams = new URLSearchParams()

  for (const key in UNSPLASH_PROJECT_IMAGE_PARAMS) {
    urlParams.set(key, UNSPLASH_PROJECT_IMAGE_PARAMS[key])
  }

  url.search = `?${urlParams.toString()}`

  return url.toString()
})

// Save (edit) project
const isSaving = ref(false);
const isSaveEnabled = ref(route.path.includes('/projects/add'));
const savedProject = ref(null);

const { maxActiveProjectsLimitReached, accountLimits } = useAccountLimits()

const canEditFields = computed(() => {
  if (isProjectCreate.value && maxActiveProjectsLimitReached.value && !isTemplatePath.value) {
    return false
  }

  if (isProjectCreate.value) {
    if (isSaving.value) {
      return false
    }

    return isTemplatePath.value
      ? can(actions.CREATE_PROJECT_TEMPLATES)
      : can(actions.CREATE_PROJECTS)
  }

  if (isTemplatePath.value) {
    return can(actions.EDIT_PROJECT_TEMPLATES)
  }

  return !isProjectClosed.value && can(actions.EDIT_PROJECTS)
})

const requiredFields = computed(() => {
  const fields = [ 'name' ]

  for (const customField of customFields.value) {
    if (customField.attributes.rules?.includes('required')) {
      fields.push(`custom_fields.cf_${customField.id}`)
    }
  }

  return fields
})

const isValidModel = computed(() => {

  for (const prop of requiredFields.value) {
    const v = get(model.value, prop)
    if (v === null || v === undefined || v === '') {
      return false
    }
  }

  return true
})

const lostProjectAccess = ref(false)
const trySaveProject = debounce(trySaveProjectFn, 200)
async function trySaveProjectFn(willLoseAccess = false) {
  willLoseAccess = typeof willLoseAccess === 'boolean'
    ? willLoseAccess
    : false

  if (!isSaveEnabled.value || !isValidModel.value || !project.value?.id || lostProjectAccess.value) {
    return
  }

  const data = cloneDeep(model.value);

  data.custom_fields = getCustomFieldValuesStringified(data.custom_fields)
  data.is_template = isTemplatePath.value
  delete data.group_ids

  try {
    lostProjectAccess.value = willLoseAccess

    await store.dispatch('projects/editProject', {
      project: project.value,
      data,
      syncWithServer: !willLoseAccess
    })
  }
  catch (err) {
    if (willLoseAccess) {
      lostProjectAccess.value = false
    }
  }
  finally {
    isSaving.value = false
  }
}

function triggerSave(willLoseAccess = false) {
  if (!project.value?.id || !canEditFields.value) {
    return
  }
  trySaveProject(willLoseAccess)
}

async function triggerDescriptionUpdate() {
  if (!project.value?.id || !canEditFields.value) {
    return
  }
  await store.dispatch('projects/editProjectDescription', {
    id: project.value.id,
    description: model.value.description,
    notifiable_user_ids: model.value.notifiable_user_ids,
  })
}

async function onCloseProject() {
  if (props.isDialog) {
    emit('close-dialog')
    return
  }

  if (isTemplatePath.value) {
    const viewOptions = store.getters['users/defaultTargetViewOptions']('templates')
    return await router.push(`/templates/${viewOptions.view_type}`)
  }

  const viewOptions = store.getters['users/defaultTargetViewOptions']('projects')
  return await router.push(`/projects/${viewOptions.view_type}`)
}

provide("triggerSave", triggerSave)
provide("triggerDescriptionUpdate", triggerDescriptionUpdate)

onBeforeRouteLeave(() => {
  if (model.value.description !== project.value?.attributes?.description) {
    trySaveProject()
  }
})

watch(() => props.currentProject, (value) => {
  project.value = value
}, { immediate: true });

watch(() => props.projectId, async (value) => {
  if (!value) {
    return
  }

  isLoading.value = true
  try {
    project.value = await store.dispatch('projects/getProjectById', {
      id: value,
      returnEntity: true
    })
  }
  catch (err) {
    if (err.handled) {
      return
    }

    error(i18n.t('Failed to load project...'))
  }
  finally {
    isLoading.value = false
  }

}, { immediate: true })

watch(project, (value) => {
  if (!value?.attributes) {
    resetModel()
    return
  }

  isSaveEnabled.value = false

  model.value = {
    ...model.value,
    ...value.attributes,
    custom_fields: getCustomFieldValuesObject(value.attributes.custom_fields)
  }

  setTimeout(() => {
    isSaveEnabled.value = true
  }, 500)
}, { immediate: true })

watch(() => projectLoading.value, (value) => {
  if (value) {
    return
  }

  clearNewProjectRedirect()
}, { immediate: true })

function clearNewProjectRedirect() {
  const query = {
    ...route.query
  }

  if (!query.newProject) {
    return
  }

  delete query.newProject

  router.replace({
    query
  })
}

watch(() => project.value?.id, value => {
  if (!value) {
    return
  }

  markNotificationsRead()
})

watch(() => isValidModel.value, (value) => {
  emit('is-valid-changed', value)
}, { immediate: true })

async function onStatusChange(status_id) {
  if (!project.value?.id) {
    return
  }

  const currentStatusId = project.value.attributes.status_id

  const isCompletedStatus = status_id == completedStatus.value?.id

  if (isCompletedStatus) {
    // Keep current status id until user makes a decision in the complete project dialog
    model.value.status_id = currentStatusId
    store.commit('setEntityCreateParams', {
      project: project.value,
    })

    store.commit('projects/triggerCompleteProject')
    return
  }

  await store.dispatch('projects/changeProjectStatus', {
    project: project.value,
    status_id
  })

  project.value = await store.dispatch('projects/getProjectById', {
    id: project.value.id,
    returnEntity: true
  })
}

// Create project
function onNameEnterPressed() {
  if (!isProjectCreate.value) {
    return
  }

  tryCreateProject()
}

async function tryCreateProject() {
  if (!isValidModel.value) {
    return
  }

  const data = cloneDeep(model.value);

  data.custom_fields = getCustomFieldValuesStringified(data.custom_fields)
  data.is_template = isTemplatePath.value
  delete data.group_ids

  isSaving.value = true

  try {
    savedProject.value = await store.dispatch('projects/createProject', data)
    if (savedProject.value?.id) {
      
      if (isTemplatePath.value) {
        success(i18n.t('Template created successfully'))
      }
      else {
        success(i18n.t('Project created successfully'))
      }

      const baseBath = isTemplatePath.value
        ? `/templates`
        : `/projects`

      emit('save', savedProject.value)

      if (!props.redirectAfterSave) {
        return
      }
      

      store.commit('projects/setCurrentProject', savedProject.value)
      await router.push(`${baseBath}/${savedProject.value.id}?newProject=true`)
    }
  }
  finally {
    isSaving.value = false
  }
}

// + Create project from template
const templateId = ref(null);
const selectedTemplate = ref({});

function onTemplateSelect(template) {
  templateId.value = template.id
  selectedTemplate.value = template
  const {
    image,
    cover_image,
    name,
    description,
    privacy,
    custom_fields,
    status_id,
  } = template?.attributes

  model.value.image = image
  model.value.cover_image = cover_image
  model.value.name = name
  model.value.description = description
  model.value.privacy = privacy
  model.value.custom_fields = getCustomFieldValuesObject(custom_fields)
  model.value.status_id = status_id

  createProjectFromTemplate()
}

function removeSelectedTemplate() {
  if (!templateId.value) {
    return
  }

  templateId.value = null
  selectedTemplate.value = {}
  resetModel()
}

async function createProjectFromTemplate() {
  store.dispatch('templates/triggerCreateProjectFromTemplate', {
    templateId: templateId.value,
    template: selectedTemplate.value,
  })
}

// Triggers
function markNotificationsRead() {
  if (!route.params.id) {
    return
  }

  store.dispatch('notifications/markEntityNotificationsRead', {
    notifiable_target_type: 'project',
    notifiable_target_id: route.params.id,
  })
}

function triggerEditProjectPeople() {
  if (!project.value?.id && !props.projectId) {
    throw ({
      message: 'Project id is required for adding people',
      context: {
        project: project.value,
        props,
      }
    })
  }

  store.commit('setEntityCreateParams', {
    projectId: project.value.id,
  })
  store.commit('projects/triggerProjectPeople')
}
// Hooks
onMounted(() => {
  tryInitFromTemplate()
})

defineExpose({
  triggerSave,
  triggerDescriptionUpdate,
})
</script>
<style lang="scss">
.base-button.change-cover-image-button {
  @apply shadow-sm text-gray-700 border border-gray-300;
}

.remove-template-control:hover {
  .circle-remove {
    @apply sm:flex;
  }
}

.inline-input:hover {
  .start-with-template-label {
    @apply text-gray-400;
  }
}

.group-user-logo {
  img,
  .initial-avatar {
    @apply border-2 border-white;
  }
}

.project-inline-details {

  .el-date-editor.el-input.inline  .el-input__wrapper input {
    font-size: 12px;
    width: 70px;
  }

  .project-properties {

    .base-select.inline-select .el-input__wrapper {
      @apply p-0;
    }

    .el-date-editor.el-input__wrapper.inline, .el-date-editor.el-input.inline .el-input__wrapper {
      @apply p-0;
    }

    .custom-field-inline-input {
      &:not(.is-date-type) {
        @apply -ml-1;
      }

      .fa-sparkles {
        @apply text-sm text-gray-700;
      }

      .circle-remove {
        @apply w-5 h-5;
      }

      .custom-field-value-label {
          @apply text-gray-700 text-sm font-bold mr-2 whitespace-nowrap;
      }

      .custom-field-value-display-value {
        @apply text-xs text-gray-400 whitespace-nowrap;
      }
    }
  }
}
</style>
