<template>
  <div>
    <BaseInput
      v-model="url"
      @update:modelValue="loading = true"
      :placeholder="$t('Paste an image URL here...')"
      name="Url Photo Search"
      @keyup.enter.stop="selectPhoto"
    />
    <div class="mt-3 text-center text-xs text-gray-400">
      {{ $t("Enter an image link from cloud storage or the web.") }}
    </div>
    <div
      v-if="url"
      v-loading="loading"
      class="mt-3 grid h-full items-center flex-wrap gap-2 w-full place-items-center"
    >
      <img
        ref="image"
        :src="url"
        class="m-auto h-24 w-24 rounded-md object-cover cursor-pointer flex-1"
        crossorigin="anonymous"
        @load="loading = false"
        @click="selectPhoto"
      />
    </div>
  </div>
</template>
<script>
import { imgToFile } from "@/modules/common/utils/imageUtils"
export default {
  data() {
    return {
      url: '',
      loading: false,
    }
  },
  methods: {
    async selectPhoto() {
      if (this.loading) {
        return
      }

      try {
        const file = await imgToFile(this.$refs.image, 'image')
        this.$emit('select-image', file)
      }
      catch (err) {
        this.$emit('select-url', this.imgClearbiturl)
      }
    }
  },
}
</script>
