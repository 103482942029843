export function formatPercent(value) {
  if (value === null || value === undefined) {
    return '- -'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  return formatter.format(value / 100)
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$formatPercent = formatPercent
  }
}
