import { useWebWorkerFn } from '@vueuse/core'

function computeUserGroupsMap(
  bindingArray: any[],
  allGroups: any[],
) {

  const map: {[key: string | number]: {
    group_ids: (string | number)[],
    groups: any[]
  }} = {}

  bindingArray.forEach(b => {
    const group = allGroups.find(g => g.id == b.group_id)
    if (!group) {
      console.warn('Group not found for user binding', b)
      return
    }

    if (!map[b.user_id]) {
      map[b.user_id] = {
        group_ids: [],
        groups: []
      }
    }

    map[b.user_id].group_ids.push(b.group_id)
    map[b.user_id].groups.push(group)
  })

  return map
}

export const { workerFn: userGroupsMapWorker } = useWebWorkerFn(computeUserGroupsMap)
