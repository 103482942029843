<template>
  <div class="max-w-2xl m-auto align-middle bg-white overflow-hidden">
    <div
      v-if="starredPages.length === 0 && !starredPagesLoading"
      class="font-medium flex w-full items-center justify-center mt-4"
    >
      <NoStarredPages/>
    </div>
    <div
      v-loading="starredPagesLoading"
      class="flow-root mt-6">
      <draggable
        v-model="starredPages"
        :animation="200"
        tag="ul"
        class="-mt-6 divide-y divide-gray-200"
        ghost-class="ghost-card"
        handle=".fa-grip-dots"
        group="starred"
        item-key="id"
      >
        <template #item="{element}">
          <li class="py-4 px-6">
            <div class="flex items-center space-x-2 sm:space-x-4">
              <div class="shrink-0 text-lg font-medium text-gray-300 hover:text-gray-500">
                <i class="fas fa-grip-dots cursor-move"/>
              </div>
              <div class="flex-1 min-w-0">
                <p class="text-sm text-gray-500 truncate">
                  <router-link :to="element?.attributes?.link">
                  {{ element?.attributes?.title }}
                  </router-link>
                </p>
              </div>
              <BaseDropdown
                :options="getDropdownOptions(element)"
                trigger="hover"
                class="relative inline-block text-left"
              />
            </div>
          </li>
        </template>
      </draggable>
    </div>
    <StarredPageDialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      :page="pageToEdit"
      @close="showEditDialog = false"
      @save="showEditDialog = false"
    />
  </div>
</template>
<script>
import draggable from "vuedraggable/src/vuedraggable"
import { orderBy } from "lodash-es";
import StarredPageDialog from "@/modules/accounts/components/StarredPageDialog.vue";
import NoStarredPages from "@/modules/accounts/components/NoStarredPages.vue";

export default {
  components: {
    draggable,
    NoStarredPages,
    StarredPageDialog,
  },
  data() {
    return {
      pageToEdit: null,
      showEditDialog: false,
    }
  },
  computed: {
    starredPages: {
      get() {
        const pages = this.$store.state.accounts.starredPages.data || []
        return orderBy(pages, 'attributes.order')
      },
      async set(pages) {
        await this.$store.dispatch('accounts/reorderStarredPages', pages)
      }
    },
    starredPagesLoading() {
      return this.$store.state.accounts.starredPagesLoading || false
    }
  },
  methods: {
    getDropdownOptions(element) {
      return [
        {
          label: this.$t('Open'),
          action: () => this.navigateToPage(element)
        },
        {
          label: this.$t('Edit'),
          action: () => this.editPage(element)
        },
        {
          label: this.$t('Copy Link'),
          action: () => this.copyPageLink(element)
        },
        {
          label: this.$t('Delete'),
          action: () => this.deletePage(element)
        },
      ]
    },
    async deletePage(page) {
      await this.$store.dispatch('accounts/deleteStarredPage', page.id)
    },
    navigateToPage(page) {
      this.$router.push(page?.attributes?.link)
    },
    copyPageLink(page) {
      const fullLink = `${window.location.host}${page?.attributes?.link}`
      this.$copyToClipboard(fullLink, this.$t('Link copied to clipboard'))
    },
    editPage(page) {
      this.pageToEdit = page
      this.showEditDialog = true
    },
  },
}
</script>
