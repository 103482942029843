import { get } from 'lodash-es'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { customFieldTypes } from "@/modules/accounts/utils/modelUtils.js"
import i18n from "@/i18n.js";
import { getEntityTarget } from "@/modules/common/utils/filterUtils";

export const entityTypes = {
  Task: 'task',
  Project: 'project',
}

function getCustomFieldOptions(field) {
  let options = field.attributes?.options || [];
  
  if (!Array.isArray(options)) {
    options = Object.values(options).map(o => {
      return {
        value: o,
        label: o,
      }
    })
  }

  options = [ ...options ]
  // Temp allow only select color field type
  if (!field.attributes?.rules?.includes('required')) {
    const notSetOption = {
      value: null,
      label: i18n.t('Not set'),
    }

    options.unshift(notSetOption)
  }

  return options.map(x => ({
      ...x,
      custom_field: field
    })
  )
}
export function useKanbanGroups({ entity }) {
  const store = useStore()
  const route = useRoute()

  const projectStatuses = computed(() => store.getters['projects/orderedStatuses'])
  const taskStatuses = computed(() => store.getters['tasks/orderedStatuses'])
  
  const statuses = computed(() => entity === entityTypes.Task ? taskStatuses.value : projectStatuses.value)
  // Predefined fields
  const statusField = computed(() => {
    const field = {
      type: 'status',
      key: 'status_id',
      filterKey: 'status_ids',
    }
    return {
      name: i18n.t('Status'),
      groupByProp: 'relationships.status.attributes.name',
      options: statuses.value.map(status => getFieldConfig(status, field))
    }
  })


  const validCustomFields = computed(() => {
    const allCustomFields = store.getters['accounts/tableColumnCustomFields'](entity)

    return allCustomFields
      .filter(c => {
        const { custom_field_type, entity_type, allow_filter_by, is_disabled } = c.attributes
        if (is_disabled) {
          return false
        }

        const supportedTypes = [customFieldTypes.Select, customFieldTypes.SelectColor]
        return supportedTypes.includes(custom_field_type) && entity_type === entity && allow_filter_by
      })
  })

  function getFieldConfig(option, field) {
    const hasAttributes = option?.attributes
    const createParams = {}

    if (hasAttributes) {
      createParams[field?.key] = option.id
    }
    else if (option.custom_field && option.value !== null) {
      const value = option.custom_field.attributes.custom_field_type === customFieldTypes.Select
        ? option.value
        : {
          value: option.value,
          label: option.label,
          order: option.order
        }

      createParams.custom_fields = {
        [field?.key]: value
      }
    }

    let color

    if (option.custom_field && option.custom_field.attributes.custom_field_type === customFieldTypes.SelectColor) {
      color = option.value
    }
    else if (option.attributes?.color) {
      color = option.attributes.color
    }

    const optionConfig = {
      id: hasAttributes ? option.id : option.value,
      value: hasAttributes ? option.id : option.value,
      color,
      label: hasAttributes ? option.attributes?.name : option.label,
      prop: field?.key,
      type: hasAttributes ? option.type : 'custom_field',
      createParams
    }

    let query

    if (field.type === 'status') {
      query = [ optionConfig.value ]
    }
    else {
      if (option.custom_field && option.custom_field.attributes?.custom_field_type === customFieldTypes.Select) {
        query = optionConfig.value || ''
      }
      else {
        query = {
          label: optionConfig.label,
          value: optionConfig.value
        }
      }
    }

    optionConfig.filterQuery = {
      key: 'filters',
      value: [{
        column: field.filterKey || field.key,
        query,
      }]
    }

    return optionConfig
  }

  const fieldOptions = computed(() => {
    const customFields = validCustomFields.value.map(field => {
      const { key, name } = field.attributes
      let options = getCustomFieldOptions(field)

      const groupByProp = field.attributes?.custom_field_type === customFieldTypes.Select
        ? `attributes.custom_fields.${key}`
        : `attributes.custom_fields.${key}.label`

      return {
        name,
        groupByProp,
        isProjectScoped: !!field.attributes?.project_id,
        options: options?.map(option => getFieldConfig(option, field.attributes))
      }
    })
    
    return [
      statusField.value,
      ...customFields,
    ]
  })
  const target = computed(() => {
    return getEntityTarget(route)
  })
  
  const selectedField = computed(() => {
    let kanbanColumn = store.getters['filters/targetLocalFilters'](target.value)?.kanbanColumn

    if (!kanbanColumn) {
      return get(fieldOptions.value, '[0]') || { options:  [] }
    }

    return fieldOptions.value.find(field => field.groupByProp === kanbanColumn.groupByProp) || { options:  [] }
  })
  return {
    fieldOptions,
    selectedField,
  }
}
