<template>
  <div class="flex space-x-2">
    <BaseSelect
      :modelValue="modelValue"
      @update:modelValue="onFilterColumnChanged($event)"
      :options="options"
      :labelPrefix="$t('Filter by:')"
      :placeholder="$t('Filter by: Choose filter by field...')"
      :returnObject="false"
      clearable
    />
    <FilterByOptionInput
      v-if="modelValue && selectedColumn"
      v-bind="filterInputProps"
      :modelValue="filterValue"
      @update:modelValue="onFilterValueChanged"
      v-focus
      :column="(selectedColumn as Object)"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import FilterByOptionInput from "@/modules/filters/components/FilterByOptionInput.vue"

// Utils
import { TableColumn } from "@/components/table/tableUtils";
import { PropType, computed, ref } from "vue";

const props = defineProps({
  columns: {
    type: Object as PropType<TableColumn[]>,
    required: true,
  },
  modelValue: {
    type: String,
  },
  filterValue: {
    type: String,
  },
})

const emit = defineEmits(['update:modelValue', 'update:filterValue'])

function getFilterByOptions(column?: TableColumn) {
  if (!column) {
    return null
  }

  if (typeof column.filterBy === 'function') {
    return column.filterBy()
  }

  return column.filterBy
}

const selectedColumn = computed(() => {
  return props.columns.find(c => {
    return getFilterByOptions(c)?.prop === props.modelValue
  })
})

const filterInputProps = computed(() => {
  return getFilterByOptions(selectedColumn.value)?.props || {}
})

const options = computed(() => {
  return props.columns.map((column) => {
    const name = typeof column.name === 'function'
      ? column.name()
      : column.name
      
    return {
      value: getFilterByOptions(column)?.prop,
      label: name,
    }
  })
})

function onFilterColumnChanged(value: string) {
  emit('update:modelValue', value)
  emit('update:filterValue', '')
}

function onFilterValueChanged(value: any) {
  emit('update:filterValue', value)
}
</script>
