<template>
  <el-tooltip
    v-bind="$attrs"
    :show-after="+showAfter"
    :content="content ? content.toString() : ''"
    :placement="$attrs.placement || 'top'"
    :disabled="$attrs.disabled || (!content && !$slots.content)"
    :popperClass="{
      [$attrs.popperClass]: $attrs.popperClass,
      'base-tooltip-popper': true,
    }"
    :popper-options="{
      gpuAcceleration: true,
    }"
  >
    <span :class="{[$attrs.class]: $attrs.class}">
      <slot />
    </span>
    <template
      v-if="$slots.content"
      #content
    >
      <slot name="content"></slot>
    </template>
  </el-tooltip>
</template>
<script>
import { ElTooltip } from 'element-plus'

export default {
  name: 'BaseTooltip',
  inheritAttrs: false,
  props: {
    content: {
      type: [String, Number, Object, Boolean],
      default: '',
    },
    showAfter: {
      type: [String, Number],
      default: 300
    }
  },
  components: {
    ElTooltip,
  }
}
</script>
<style>
</style>
