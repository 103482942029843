<template>
  <BaseSelect
    v-bind="$attrs"
    v-model="model"
    :placeholder="$t('Language')"
    :options="sttLanguageOptions"
    :returnObject="false"
  >
    <template #prefix>
      <div class="flex items-center space-x-2">
        <img
          v-if="selectedLanguageFlag"
          class="w-5 h-5 border-1 border-white"
          :src="selectedLanguageFlag"
        />
        <div class="text-gray-900">
          {{ selectedOption?.label }}
        </div>
      </div>
    </template>
    <template #default="{option}">
      <div class="flex items-center space-x-2">
        <img
          v-if="option.flag"
          class="w-5 h-5 border-1 border-white"
          :src="option.flag"
        />
        <div>
          {{ option.label }}
        </div>
      </div>
    </template>
  </BaseSelect>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { sttLanguageOptions } from "@/modules/accounts/utils/localeUtils";

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() { 
    return props.modelValue || ''
  },
  set(locale: string) {
    emit('update:modelValue', locale)
  }
})

const selectedOption = computed(() => {
  const locale = model.value
  return sttLanguageOptions.find(o => o.value === locale)
})

const selectedLanguageFlag = computed(() => {
  return selectedOption.value?.flag || ''
})
</script>
