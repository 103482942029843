<template>
  <BaseBadge
    :color="color"
  >
    {{ statusLabel }}
  </BaseBadge>
</template>
<script lang="ts" setup>
import { PAYMENT_STATUSES } from "@/modules/payments/enum/paymentEnums";

import Data = API.Data
import Payment = App.Domains.Payments.Models.Payment;
import { PropType, computed } from "vue";
import i18n from "@/i18n";

const props = defineProps({
  payment: {
    type: Object as PropType<Data<Payment>>,
  }
})

const status = computed(() => {
  return props.payment?.attributes?.payment_status || PAYMENT_STATUSES.UNPAID
})

const isRecurring = computed(() => {
  return props.payment?.attributes?.is_recurring
})

const statusLabel = computed(() => {
  if (isRecurring.value) {
    return i18n.t('Recurring')
  }
  if (status.value === PAYMENT_STATUSES.PAID) {
    return i18n.t('Paid')
  }

  if (status.value === PAYMENT_STATUSES.UNPAID) {
    return i18n.t('Unpaid')
  }

  return status.value
})

const color = computed(() => {
  if (isRecurring.value) {
    return '#ed8936'
  }

  if (status.value === PAYMENT_STATUSES.PAID) {
    return '#48bb78'
  }

  if (status.value === PAYMENT_STATUSES.UNPAID) {
    return '#f56565'
  }

  return ''
})
</script>
