<template>
  <BaseEntityForm
    :title="$t('Manage Webhooks')"
    :subtitle="$t('Create custom webhooks to integrate with your own systems.')"
    width-class="max-w-6xl"
    class="flex flex-col h-full webhooks-container"
  >
    <template #default="{ meta, errors }">
      <UpgradePlanWarning
        v-if="!can($actions.WEB_HOOKS)"
        :message="$t(' to use and manage webhooks.')"
        class="mt-4 mx-6"
      />
      <div
        v-else
        class="flex-1"
      >
        <iframe
          v-if="data.url"
          :src="data.url"
          class="w-full h-full"
          allow="clipboard-write"
          loading="lazy"
        />
      </div>
    </template>
  </BaseEntityForm>
</template>
<script lang="ts">

import { getCssVariable, getHeaderLogo, getHexWithoutAlpha } from "@/modules/accounts/utils/accountBrandingUtils";
import UpgradePlanWarning from "@/modules/accounts/components/UpgradePlanWarning.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    UpgradePlanWarning
  },
  data() {
    return {
      loading: false,
      model: {
        enabled: false,
      },
      data: {
        url: '',
        token: ''
      },
    }
  },
  methods: {
    async getWebhooksDashboard() {
      const { data } = await this.$store.dispatch('accounts/getWebhooksDashboard')
      data.url = this.parseIframeUrl(data.url)
      this.data = data
    },
    parseIframeUrl(url: string) {
      const defaultColor = getCssVariable('--primary-500')
      let primaryColor = this.$settings('action_button_color') || defaultColor
      primaryColor = getHexWithoutAlpha(primaryColor)
      primaryColor = primaryColor.replace('#', '')

      const logoUrl = getHeaderLogo()

      const fullUrl = new URL(url)
      fullUrl.searchParams.set('primaryColor', primaryColor)
      fullUrl.searchParams.set('interactiveAccent', primaryColor)
      fullUrl.searchParams.set('logo', logoUrl)
      return fullUrl.toString()
    }
  },
  created() {
    this.getWebhooksDashboard()
  },
})
</script>
<style lang="scss">
.dashboard-content.Webhooks {
  @apply flex-1;
}
.webhooks-container iframe {
  min-height: 70vh;
}
</style>
