<template>
  <div class="dnd-file-upload">
    <el-upload
      v-model:file-list="model"
      :auto-upload="false"
      :on-remove="onRemove"
      :show-file-list="false"
      :disabled="disabled"
      action="#"
      drag
      :multiple="multiple"
    >
      <slot>
        <div
          class="drag-container border-4 border-dashed border-gray-200 p-10 text-center text-gray-500"
          :class="{
            'cursor-not-allowed bg-gray-100 text-gray-500/60': disabled,
            'hover:border-gray-300 ': !disabled,
          }"
        >
          {{ $tc('Drag & drop files here to upload', +multiple + 1) }}
        </div>
      </slot>
    </el-upload>

    <template v-if="renderList">
      <BasePopoverInput
        v-for="file of model"
        :key="file.uid"
        :disabled="!enablePreview"
        :append-to-body="false"
        popper-class="file-preview-popover"
      >
        <template #reference>
          <div class="flex p-1 mt-1 rounded hover:bg-gray-100 cursor-pointer text-base text-gray-500 transition duration-500 ease-in-out items-center">
            <BaseFilePreview
              :file="file"
              size="sm"
              style="min-width: 1.25rem;"
            />

            <span class="text-sm ml-2 whitespace-nowrap overflow-hidden text-ellipsis">
              {{ file?.name }}
            </span>

            <span class="ml-auto mt-1">
              <CloseButton
                size="sm"
                @click.stop="onRemove(file)"
              />
            </span>
          </div>
        </template>
        <div class="mb-1 z-10">
          {{ file.name }}
        </div>
        <BaseFilePreview
          :file="file"
          :cleanup="false"
          size="full"
          embed
        />
      </BasePopoverInput>
    </template>
  </div>
</template>
<script>
// Libs
import { ElUpload } from "element-plus";

// Components
import CloseButton from "@/components/common/buttons/CloseButton.vue";

export default {
  components: {
    ElUpload,
    CloseButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [Array, File, Object],
      default: () => null
    },
    enablePreview: {
      type: Boolean,
      default: true
    },
    renderList: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    model: {
      get() {
        if (Array.isArray(this.modelValue)) {
          return this.modelValue;
        } else if(this.modelValue) {
          return [this.modelValue];
        } else {
          return [];
        }
      },
      set(value) {
        if (Array.isArray(this.modelValue)) {
          this.$emit('update:modelValue', value);
          return;
        }
        this.$emit('update:modelValue', value?.[0] || value);
      }
    }
  },
  methods: {
    onRemove(file) {
      if (!Array.isArray(this.modelValue)) {
        this.model = null
        return
      }

      const newModelValue = [...this.model]

      const fileIdx = newModelValue.findIndex(x => x.uid === file.uid)
      if (fileIdx !== -1) {
        newModelValue.splice(fileIdx, 1)
      }

      this.model = newModelValue
    },
  }
}
</script>
<style lang="scss">
.dnd-file-upload {
  position: relative;

  .el-upload {
    width: 100%;
  }

  .el-upload-dragger {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: unset;

    @apply p-0;

    &.is-dragover {
      border: none;

      .drag-container {
        @apply border-blue-300;
      }
    }
  }

  .el-upload-list__item {
    cursor: pointer;
  }

  .file-preview-popover {
    min-width: 400px !important;
    @apply h-fit;
  }
}

</style>
