<template>
  <BaseInlineInput>
    <template #default="{ triggerSave }">
      <BaseSelect
        :modelValue="modelValue"
        :options="visibleOptions"
        :disabled="$attrs.disabled"
        :return-object="false"
        inline
        class="fit-content disabled-focus"
        @update:modelValue="onChange($event, triggerSave)"
      >
        <template #prefix>
          <BaseBadge
            v-if="selectedOption"
            :color="selectedOption.color"
          >
            {{ selectedOption.label }}
          </BaseBadge>
          <BaseBadge
            v-else
            color="grey"
          >
            {{ $t('No privacy') }}
          </BaseBadge>
        </template>
      </BaseSelect>
    </template>
  </BaseInlineInput>
</template>
<script lang="ts" setup>
import Data = API.Data
import Project = App.Domains.Projects.Models.Project;
import User = App.Domains.Users.Models.User;

import i18n from "@/i18n"
import { capitalize } from 'lodash-es'
import { Privacy } from "@/modules/projects/utils/projectHelpers"
import { PropType, computed } from "vue"
import { useAuth } from "@/modules/auth/composables/useAuth";
import { $confirm } from '@/components/common/dialog/modalPlugin'
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const { currentUser } = useAuth()
const router = useRouter()
const store = useStore()

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  project: {
    type: Object as PropType<Data<Project>>,
    default: null,
  }
})

const emit = defineEmits(['update:modelValue'])

const userIsPartOfProject = computed(() => {
  const projectUsers = (props.project?.relationships?.users || [])
  return projectUsers.some((user: Data<User>) => user.id == currentUser.value.id)
})

async function onChange(value: string, triggerSave: Function | null) {
  let willLoseAccess = false

  if (props.project?.id) {
    willLoseAccess = props.modelValue === Privacy.Account && !userIsPartOfProject.value
  }

  if (willLoseAccess) {
    const confirmed = await $confirm({
      title: i18n.t(`Warning`),
      description: i18n.t(`If you change the privacy of this project you will lose access to it. To maintain access to this project you need to be added as a person on the project. Please confirm which privacy setting you would like for this project.`),
      buttonText: i18n.tc(`confirm privacy change`, { privacy: i18n.t(capitalize(value)) }),
    })

    if (!confirmed) {
      return
    }
  }

  emit('update:modelValue', value)
  triggerSave?.(willLoseAccess)

  if (willLoseAccess) {
    store.commit('projects/deleteProject', props.project?.id)
    router.push(`/projects/list`)
  }
}

const privacyOptions = [
  {
    label: i18n.t('Visible to all Creators & invited Collaborators'),
    value: Privacy.Account,
    color: 'rgb(59, 130, 246)',
  },
  {
    label: i18n.t('Only visible to invited people'),
    value: Privacy.Project,
    color: 'rgb(107, 114, 128)'
  },
  {
    label: i18n.t('Only visible to me'),
    value: Privacy.Private,
    color: 'rgb(239, 68, 68)',
    hidden: true
  }
]

const visibleOptions = computed(() => {
  return privacyOptions.filter(option => !option.hidden)
})

const selectedOption = computed(() => {
  return privacyOptions.find(option => option.value === props.modelValue)
})
</script>
