<template>
  <div class="task-counts flex items-center space-x-2">
    <div
      v-if="isRecurringTask"
      class="flex space-x-2 items-center"
    >
      <i
        class="far fa-repeat"
        :class="{
          'text-gray-400': !isSourceRecurringTask,
          'text-primary-500': isSourceRecurringTask,
          'text-xs': isCard
        }"
      />
      <div
        v-if="isSourceRecurringTask && !isTaskClosed && !isCard"
        :title="$t('Editing this master task will update the future recurrence.')"
        class="bg-primary-500 text-xs text-white rounded-md px-2 py-1"
      >
        {{ $t('Master') }}
      </div>
    </div>
    <div
      v-if="activeSubTasksCount"
      :title="$t(`The number of incomplete sub-tasks.`)"
    >
      <i
        class="mr-1 far fa-diagram-subtask text-gray-400"
        :class="{
          'text-xs mr-0.5': isCard
        }"
      />
      <span
        class="text-xs font-normal"
        :class="{
          'text-gray-400': isCard
        }"
      >
        {{ activeSubTasksCount }}
      </span>
    </div>
    <div v-if="filesCount">
      <i
        class="mr-1 far fa-file text-gray-400"
        :class="{
          'text-xs mr-0.5': isCard
        }"
      />
      <span
        class="text-xs font-normal"
        :class="{
          'text-gray-400': isCard
        }"
      >
        {{ filesCount }}
      </span>
    </div>
    <div v-if="commentCount">
      <i
        class="mr-1 far fa-comments text-gray-400"
        :class="{
          'text-xs mr-0.5': isCard
        }"
      />
      <span
        class="text-xs font-normal"
        :class="{
          'text-gray-400': isCard
        }"
      >
        {{ commentCount }}
      </span>
    </div>
    <i
      v-if="hasCreatorsAndCollaboratorsPrivacy"
      :title="$t(`This task is visible to Collaborators`)"
      class="far fa-user-lock cursor-pointer text-gray-400"
      :class="{
        'text-xs mt-1': isCard
      }"
    />
  </div>
</template>
<script>
import { taskDateTypes, visibilityTypes } from '@/modules/tasks/utils/modelUtils';
import { getSubtasks } from '@/modules/tasks/utils/taskTableUtils';

export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    isCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    subTasks() {
      return getSubtasks(this.task)
    },
    isTaskClosedFn() {
      return this.$store.getters['tasks/isTaskClosed']
    },
    isTaskClosed() {
      return this.isTaskClosedFn(this.task)
    },
    activeSubTasksCount() {
      return this.subTasks.filter(subTask => !this.isTaskClosedFn(subTask)).length
    },
    filesCount() {
      return this.task.attributes.files_count
    },
    commentCount() {
      return this.task.attributes.comment_count
    },
    isRecurringTask() {
      return this.task.attributes.date_type === taskDateTypes.RECURRING
    },
    isSourceRecurringTask() {
      return this.isRecurringTask && this.task.attributes.is_recurrence_source
    },
    hasCreatorsAndCollaboratorsPrivacy() {
      return this.task.attributes.visibility === visibilityTypes.CREATORS_AND_COLLABORATORS
    }
  },
};
</script>
