<template>
  <BaseInlineInput>
    <TaskStatusSelect
      v-model="model"
      :disabled="$attrs.disabled"
      :return-object="false"
      inline
      :filterable="filterable"
      class="disabled-focus"
    >
      <template #prefix>
        <BaseBadge
          v-if="selectedOption"
          :color="selectedOption?.attributes?.color"
        >
          {{ selectedOption?.attributes?.name }}
        </BaseBadge>
        <BaseBadge
          v-else
          color="grey"
        >
          {{ $t('No status') }}
        </BaseBadge>
      </template>
    </TaskStatusSelect>
  </BaseInlineInput>
</template>
<script>
import TaskStatusSelect from "@/modules/tasks/components/TaskStatusSelect.vue";

export default {
  components: {
    TaskStatusSelect
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: '',
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    statuses() {
      return this.$store.getters['tasks/orderedStatuses'] || []
    }
  },
  watch: {
    statuses() {
      this.syncStatus()
    },
    model: {
      immediate: true,
      handler() {
        this.syncStatus()
      }
    }
  },
  methods: {
    syncStatus() {
      this.selectedOption = this.statuses.find(x => x.id?.toString() === this.model?.toString())
    },
  }
}
</script>
