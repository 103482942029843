<template>
  <BaseNoDataSection
    v-loading="loading"
    class="no-projects-section"
    :title="$t('No Projects Found')"
    iconClass="fa-inbox"
    :canCreate="can($actions.CREATE_PROJECTS)"
  >
    <template #can-create-section>
      <div>{{ $t('A project is a self-contained space for each piece of work.')}}</div>
      <div>{{ $t('Create a project for every thing you need to do.')}}</div>
      <div>
        <router-link
          to="/projects/add"
          class="hover:text-primary-500"
        >
          {{ $t('Create a new project') }}
          <i class="fa-solid fa-arrow-right" aria-hidden="true" />
        </router-link>
      </div>
      <div class="text-center flex items-center justify-center">
        <InlineProjectSelect
          v-model="templateId"
          is-template
          :required="false"
          :showIcon="false"
          :showActionIcon="!creatingFromTemplate"
          :placeholder="$t('Start with a template')"
          @change="createProjectFromTemplate"
        />
        <LoadingIcon
          v-if="creatingFromTemplate"
          size="xs"
          class="ml-1"
        />
      </div>
      <div>
        <BaseEntityImportLink
          :entityType="importEntityTypes.Projects"
        />
      </div>
    </template>
  </BaseNoDataSection>
</template>
<script>
import InlineProjectSelect from "@/components/selects/InlineProjectSelect.vue";
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";
import {
  importEntityTypes,
} from "@/modules/accounts/utils/importUtils"

export default {
  components: {
    InlineProjectSelect,
    LoadingIcon,
  },
  data() {
    return {
      templateId: null,
      loading: false,
      importEntityTypes,
      creatingFromTemplate: false,
    }
  },
  methods: {
    async createProjectFromTemplate() {
      if (!this.templateId) {
        return
      }

      try {
        this.creatingFromTemplate = true
        await this.$store.dispatch('templates/triggerCreateProjectFromTemplate', {
          templateId: this.templateId,
        })
      }
      catch (err) {

        if (err.handled) {
          return
        }

        this.$error(this.$t('Could not create project from the selected template'))
      }
      finally {
        this.creatingFromTemplate = false
      }
    },
  }
}
</script>
<style lang="scss">
.no-projects-section {
  .inline-select-wrapper {
    @apply mx-auto justify-center p-0;
    .el-tooltip__trigger {
      @apply w-32;
    }
    
    .project-inline-select .select-trigger .el-input .el-input__wrapper input.el-input__inner {
      &::placeholder {
        @apply hover:text-primary-500;
      }
    }
  }
}
</style>
