<template>
  <slot v-if="can(action)" />
</template>
<script>

export default {
  name: 'RequiresPermissionTo',
  props: {
    action: {
      type: String,
      required: true
    }
  },
  methods: {
    validateAction() {
      for (const action in this.$actions) {
        if (this.$actions[action] === this.action) {
          return
        }
      }
      console.error(`Error: unrecognized action ${this.action}`)
    }
  },
  created() {
    this.validateAction()
  }
}
</script>
