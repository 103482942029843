<template>
  <BaseTooltip :content="role">
    <i
      class="fa-solid"
      :class="{
        [iconClass]: true,
        'text-xs': size === 'xs',
        'text-sm': size === 'sm',
        'text-base': size === 'base',
        'text-md': size === 'md',
        'text-lg': size === 'lg',
        'text-xl': size === 'xl',
        [$attrs.class]: $attrs.class,
      }"
      :style="{ color }"
    />
  </BaseTooltip>
</template>
<script>
import { roleProps } from "@/modules/users/util/userUtils";

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "xs"
    },
  },
  computed: {
    role() {
      return this.user?.attributes?.role;
    },
    iconClass() {
      return roleProps[this.role]?.icon
    },
    color() {
      return roleProps[this.role]?.iconColor
    }
  }
}
</script>
