<template>
  <time>
    {{ timer.displayTime }}
  </time>
</template>
<script>
import { get } from 'lodash-es'
import Timer from '@/utils/Timer'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import { TimerStatusTypes } from '@/modules/time/utils/timeEntryUtils.js'

export default {
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timer: null,
    }
  },
  methods: {
    triggerTimerState(status) {
      this.timer?.stop()
      let initialTimeInSeconds = 0

      const isRecording = status === TimerStatusTypes.Recording
      const workedMinutes = get(this.row, 'attributes.worked_minutes', 0)
      initialTimeInSeconds = workedMinutes * 60

      if (isRecording) {
        const startDate = get(this.row, 'attributes.timer_started_at')
        const startedAtSecondsAgo = startDate ? differenceInSeconds(new Date(), new Date(startDate)) : 0
        initialTimeInSeconds += startedAtSecondsAgo
      }

      this.timer = new Timer({
        initialTimeInSeconds,
      })

      if (isRecording) {
        return this.timer.start()
      }

      this.timer.stop()
    },
  },
  watch: {
    'row.attributes.status': {
      immediate: true,
      handler(status) {
        this.triggerTimerState(status)
      },
    },
    'row.attributes.worked_minutes'() {
      this.triggerTimerState()
    },
  },
  beforeDestroy() {
    if (!this.timer) {
      return
    }
    this.timer.stop()
  },
}
</script>
