<template>
  <div class="flex justify-between">
    <BaseTooltip
      v-for="option of options"
      :key="option.label"
      :content="option.tooltip"
      :disabled="!option.tooltip"
      :class="option.class"
    >
      <BaseButton
        :variant="option.variant"
        :disabled="option.disabled"
        @click="onAction(option)"
      >
        {{ option.label }}
      </BaseButton>
    </BaseTooltip>
  </div>
</template>
<script>
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import i18n from "@/i18n";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isTemplatePath: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      maxActiveProjectsLimitReached,
      accountLimits,
    } = useAccountLimits()
    return {
      accountLimits,
      maxActiveProjectsLimitReached,
    }
  },
  computed: {
    options() {
      let createTooltip = ''

      if (this.disabled) {
        createTooltip = this.maxActiveProjectsLimitReached
          ? i18n.tc('account limit reached', {
            limit: 'active projects',
            maxCount: this.accountLimits.projects.available,
          })

          : i18n.t(`Some required fields (*) don't have a value`)
      }

      return [
        {
          label: i18n.t('Cancel'),
          action: 'cancel',
          variant: 'white',
          class: 'mr-auto',
        },
      ]
    },
  },
  methods: {
    onAction(option) {
      if (this[option.action]) {
        this[option.action]()
        return
      }

      if (option.path) {
        this.$router.push(option.path)
        return
      }

      this.$emit(option.action, this.task)
    },
  },
};
</script>
