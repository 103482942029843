<template>
  <BaseNoDataSection
    :title="$t('No Resources Found')"
    iconClass="fa-file-code"
    :canCreate="can($actions.CREATE_PROJECT_EMBEDS) && !isCurrentProjectClosed"
  >
    <template #can-create-section>
      <div>{{ $t(`Resources are 3rd party tools, external links & internal references.`) }}</div>
      <div>{{ $t(`Organize them here instead of pinning them to the project dashboard.`) }}</div>
      <div
        v-for="option in createOptions"
        :key="option.label"
        class="hover:text-primary-500 cursor-pointer"
        @click="option.action"
      >
       {{ option.label }}
       <i class="fa-solid fa-arrow-right" aria-hidden="true" />
      </div>

      <EmbedDialog
        v-if="showEmbedCreateDialog"
        v-model="showEmbedCreateDialog"
        key="quick-embed-create"
        @close="showEmbedCreateDialog = false"
        @save="onResourceCreated"
      />
      <ProjectLinkDialog
        v-if="showLinkCreateDialog"
        v-model="showLinkCreateDialog"
        :defaultPinned="false"
        key="quick-link-create"
        @close="showLinkCreateDialog = false"
        @save="onResourceCreated"
      />
      <ProjectReferenceDialog
        v-if="showReferenceCreateDialog"
        v-model="showReferenceCreateDialog"
        :defaultPinned="false"
        key="quick-reference-create"
        @close="showReferenceCreateDialog = false"
        @save="onResourceCreated"
      />
    </template>
  </BaseNoDataSection>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import { ref, computed } from 'vue'

import EmbedDialog from "@/modules/resources/components/EmbedDialog.vue";
import ProjectLinkDialog from "@/modules/projects/components/ProjectLinkDialog.vue";
import ProjectReferenceDialog from "@/modules/projects/components/ProjectReferenceDialog.vue";

import { useStore } from 'vuex'
const store = useStore()

const showEmbedCreateDialog = ref(false)
const showLinkCreateDialog = ref(false)
const showReferenceCreateDialog = ref(false)

const createOptions =  [
  {
    label: i18n.t('Embed a URL'),
    action: () => { showEmbedCreateDialog.value = true }
  },
  {
    label: i18n.t('Create a new link'),
    action: () => { showLinkCreateDialog.value = true }
  },
  {
    label: i18n.t('Create a new reference'),
    action: () => { showReferenceCreateDialog.value = true }
  }
]

const currentProjectId = computed(() => {
  return store.getters.projectId
})

const isCurrentProjectClosed = computed(() => {
  return store.getters['projects/isCurrentProjectClosed']
})

async function onResourceCreated() {
  showEmbedCreateDialog.value = false
  showLinkCreateDialog.value = false
  showReferenceCreateDialog.value = false

  await store.dispatch('resources/getResources')

  store.dispatch('projects/getProjectById', {
    id: currentProjectId.value,
    forceFetch: true,
    silent: true
  })
}
</script>
