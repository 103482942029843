<template>
  <BaseTooltip class="inline">
    <template #content>
      <div class="date-tooltip-entry">
        <span class="font-bold">{{ $t("Created") }}:</span>
        <span>{{ $formatDate(createdAt) }}</span>
      </div>
      <div class="date-tooltip-entry">
        <span class="font-bold">{{ $t("Started") }}:</span>
        <span v-if="startDate">
          {{ $formatDate(startDate) }}
        </span>
        <span v-else>
          {{ $t("Not Started") }}
        </span>
      </div>
      <div class="date-tooltip-entry">
        <span class="font-bold">{{ $t("Deadline") }}:</span>
        <span v-if="deadline">
          {{ $formatDate(deadline) }}
        </span>
        <span v-else>
          {{ $t("No Deadline") }}
        </span>
      </div>
      <div class="date-tooltip-entry">
        <span class="font-bold">{{ $t("Completed") }}:</span>
        <span v-if="completedAt">
          {{ $formatDate(completedAt) }}
        </span>
        <span v-else>
          {{ $t("Not Completed") }}
        </span>
      </div>
    </template>
    <span>
      <slot />
    </span>
  </BaseTooltip>
</template>
<script>
export default {
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    dates: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    createdAt() {
      return this.dates.created_at || this.project?.attributes?.created_at
    },
    startDate() {
      return this.dates.start_date || this.project?.attributes?.start_date
    },
    deadline() {
      return this.dates.deadline || this.project?.attributes?.deadline
    },
    completedAt() {
      return this.dates.completed_at || this.project?.attributes?.completed_at
    }
  }
};
</script>
<style lang="scss">
.date-tooltip-entry {
  @apply flex justify-between space-x-4;
}
</style>
