<template>
  <div>
    <BaseDropdown
      :options="availableOptions"
      :slim="slim"
      :auto-open="true"
      @action="onAction"
    >
      <template #default>
        <slot />
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import useProofChannel from '@/modules/file-proofs/composables/useProofs'
import i18n from '@/i18n'

export default {
  props: {
    proof: {
      type: Object,
      default: () => ({})
    },
    slim: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    projectId() {
      return this.$route.params?.id || this.$route.query?.projectId
    },
    storeProof() {
      return this.proofs.find(proof => proof?.id === this.proof?.id);
    },
    proofs() {
      return this.$store.state.proofs.proofs?.data || []
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.projectId)
    },
    proofHasComments() {
      return this.storeProof?.relationships?.reviewers?.some(reviewer => reviewer?.attributes?.comments)
    },
    proofHasPublicLink() {
      return this.storeProof?.attributes?.public_link
    },
    options() {
       return [
        {
          label: i18n.t('Open'),
          path: `/projects/${this.projectId}/file-proofs/${this.proof?.id}`,
        },
        {
          label: i18n.t('Edit'),
          action: () => {
            this.$router.push({
              path: this.$route.path,
              query: {
                ...this.$route.query,
                edit: this.proof?.id
              }
            })
          },
          enabled: () => {
            const isProofOwner = this.proof?.attributes?.created_by?.toString() === this.$user.id?.toString()
            const canCreateProof = this.can(this.$actions.CREATE_PROJECT_FILE_PROOFS)
            return !this.isProjectClosed && canCreateProof && isProofOwner
          },
        },
        {
          label: i18n.t('Download Comments PDF'),
          disabled: !this.proofHasComments,
          tooltip: !this.proofHasComments ? i18n.t('The comments PDF will be available to download when there are 1 or more comments on the proof.') : '',
          action: () => this.downloadCommentsPdf(),
        },
        {
          label: i18n.t('Copy public URL'),
          tooltip: this.proofHasPublicLink
            ? i18n.t('Share this URL with people outside of your account so they can review & comment.')
            : i18n.t('The public URL will be available once the proof has finished processing.'),
          disabled: !this.proofHasPublicLink,
          action: () => this.$copyToClipboard(this.proof?.attributes?.public_link, i18n.t('Copied to clipboard')),
        },
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          enabled: () => {
            const isProofOwner = this.proof?.attributes?.created_by?.toString() === this.$user.id?.toString()
            const canDeleteProof = this.can(this.$actions.DELETE_PROJECT_FILE_PROOFS)
            return !this.isProjectClosed && canDeleteProof && isProofOwner
          },
        }
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    async downloadCommentsPdf() {
      try {
        const { request_id } = await this.$store.dispatch('proofs/downloadCommentsPdf', this.proof?.id)
        this.$success(i18n.t('The PDF is being generated and will be downloaded in a few seconds.'))
        useProofChannel(request_id)
      } catch(e) {
        console.warn(e)
      }
    },
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.proof)
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Delete Proof'),
        description: i18n.t('Are you sure? This will permanently remove the file proof.')
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch('proofs/deleteProof', this.proof.id);
      }
      catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(i18n.t('Cannot delete proof'));
        throw err
      }
    },
  }
}
</script>
