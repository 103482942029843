<template>
  <EntitySelect
    v-bind="$attrs"
    url="/restify/tasks"
    labelKey="attributes.name"
    valueKey="id"
    :placeholder="$attrs.placeholder|| $t('Select tasks...')"
    :url-params="{
      filters: taskFilters,
      ...urlParams,
    }"
  >
    <template #option="{row}">
      <div class="flex items-center py-1">
        <BaseTooltip
          v-if="isTaskClosed(row)"
          :content="$t('This task is completed')"
        >
          <i class="cursor-pointer fas fa-lock mr-2 text-red-600" />
        </BaseTooltip>
        <span class="mr-2 truncate">{{ row.label || row.value }}</span>
        <template v-if="hasCreatorsAndCollaboratorsPrivacy(row)">
          <BaseTooltip
            :content="$t(`This task is visible to Collaborators`)"
          >
            <i
              class="far fa-user-lock cursor-pointer text-gray-400"
            />
          </BaseTooltip>
        </template>
      </div>
    </template>
  </EntitySelect>
</template>
<script>
import EntitySelect from "@/components/form/EntitySelect.vue";
import Status from "@/components/table/Status.vue";
import { encodeFilters } from '@/modules/common/utils/filterUtils';
import { visibilityTypes } from '@/modules/tasks/utils/modelUtils';

export default {
  components: {
    EntitySelect,
    Status,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    apiFilters() {
      return this.$store.getters['filters/targetApiFilters']('tasks') || []
    },
    taskFilters() {
      const completedFilter = this.apiFilters?.find(f => f.key === 'completed')

      if (!completedFilter) {
        return
      }

      return encodeFilters([
        completedFilter
      ])
    }
  },
  methods: {
    isTaskClosed(row) {
      return this.$store.getters['tasks/isTaskClosed'](row?.originalValue)
    },
    hasCreatorsAndCollaboratorsPrivacy(row) {
      return row?.originalValue?.attributes?.visibility === visibilityTypes.CREATORS_AND_COLLABORATORS
    }
  }
}
</script>
