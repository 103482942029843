<template>
  <BaseNoDataSection
    :title="$t('No Custom Fields Found')"
    iconClass="fa-stars"
    :canCreate="canCreateCustomFields && !isCurrentProjectClosed"
  >
    <template #can-create-section>
      <div>{{ $t(`Create custom fields to record the data that matters to you on each entity.`) }}</div>
      <BaseTutorialLink
        :name="tutorial.name"
        class="hover:text-primary-500"
      >
        <div>{{ tutorial.label }}</div>
      </BaseTutorialLink>
      <div
        class="hover:text-primary-500 cursor-pointer"
        @click="triggerCreateCustomField()"
      >
        {{ $t('Create a new custom field') }}
        <i class="fa-solid fa-arrow-right" aria-hidden="true" />
      </div>
    </template>
  </BaseNoDataSection>
</template>
<script>
import { triggerCreateCustomField } from "@/modules/accounts/utils/modelUtils"
export default {
  computed: {
    project_id() {
      return this.$store.getters.project_id
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
    tutorial() {
      const name = this.project_id
        ? 'custom-fields-project'
        : 'custom-fields-account'
      
      const label = this.project_id
        ? this.$t('Find out more about project level custom fields here.')
        : this.$t('Find out more about custom fields here.')
      
      return {
        name,
        label
      }
    },
    canCreateCustomFields() {
      const hasPermissions = this.can(this.$actions.CREATE_CUSTOM_FIELDS) || (this.project_id && this.can(this.$actions.CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS))
      return hasPermissions && !this.isCurrentProjectClosed
    }
  },
  methods: {
    triggerCreateCustomField
  }
}
</script>

