import { GridApi, IRowDragItem, RowDragEvent, RowNode } from "@ag-grid-community/core";
import { computed, nextTick, ref } from "vue";
import store from "@/store/index.js";
import { error } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";

import useCan from "@/modules/common/composables/useCan"
import { get, set } from "lodash-es";
import { sleep } from "@/modules/common/utils/commonUtils";
const { can, actions } = useCan()


export function useTaskDragging() {
  const tasksApiFilters = computed(() => {
    return store.getters['filters/targetApiFilters']('tasks') || []
  })

  const showDetailsView = computed(()=> {
    return tasksApiFilters.value.find((filter: any) => filter.key === 'show-details-view')?.value?.show || false
  })

  const isInsideProject = computed(() => {
    return !!store.getters.project_id
  })

  const isInsideClosedProject = computed(() => {
    return store.getters['projects/isCurrentProjectClosed']
  })

  const orderProp = computed(() => {
    if (isInsideProject.value) {
      return `attributes.order`
    }

    return `attributes.global_order`
  })

  const updatedOrders = ref<any>({})
  const updatedTasks = ref<any>([])

  function getRowDragText(params: IRowDragItem, dragItemCount: number, isDetailGrid: boolean = false) {
    const isParentChange = params?.columns?.length
    if (isDetailGrid && !isParentChange) {
      return i18n.t('Cannot reorder subtasks in detail view...')
    }

    if (isParentChange && allowParentChange.value) {
      return i18n.t('Drop to another task to set as parent...')
    }

    if (!isParentChange && allowReordering.value) {
      return i18n.t('Drop anywhere to reorder...')
    }

    return i18n.t('Sort by Drag & Drop ascending should be active to allow reordering...')
  }

  const isDragToChangeParent = ref(false)
  const isAlreadyDragging = ref(false)

  // #region Drag Events

  function onRowDragEnter(event: RowDragEvent, isDetailGrid: boolean = false) {
    if (isAlreadyDragging.value) {
      return
    }

    isAlreadyDragging.value = true

    const targetElement = event.event.target as HTMLElement | null
    isDragToChangeParent.value = targetElement?.classList.contains('reorder-drag-handle') || false
  }

  function onRowDragMove(event: RowDragEvent, isDetailGrid: boolean = false) {
    if (!allowReordering.value || isDragToChangeParent.value) {
      return
    }

    if (!event.overNode) {
      return;
    }
    
    const allNodes: RowNode[] = []

    event.api.forEachNodeAfterFilterAndSort((node) => {
      node.data && allNodes.push(node)
    })

    const firstSelectedRow = event.nodes[0]?.data
    const lastSelectedRow = event.nodes[event.nodes.length - 1]?.data

    const nodesBetweenSelection: any[] = []

    allNodes.forEach((node) => {
      if (node.isSelected()) {
        return
      }

      const row = node.data
      if (
        get(row, orderProp.value) > get(firstSelectedRow, orderProp.value)
        &&
        get(row, orderProp.value) < get(lastSelectedRow, orderProp.value)
      ) {
        nodesBetweenSelection.push(node)
      }
    })

    if (nodesBetweenSelection.includes(event.overNode)) {
      return
    }

    const nodesData = event.nodes.map(n => n.data)
    const overNode = event.overNode
    const targetOrder = get(overNode?.data, orderProp.value, 0)
    const firstElOrder = get(nodesData[0], orderProp.value)
    const lastElOrder = get(nodesData[nodesData.length - 1], orderProp.value)
    
    let newTargetOrder: number
    // Moving up
    if (firstElOrder > targetOrder) { 
      newTargetOrder = lastElOrder
      
      let lastOrder = 0
      nodesData.forEach((n, i) => {
        const newOrder = newTargetOrder - nodesData.length - nodesBetweenSelection.length + i
        updatedOrders.value[n.id] = {
          old: get(n, orderProp.value),
          new: newOrder
        }

        set(n, orderProp.value, newOrder)

        lastOrder = newOrder
      })

      // Nodes between must go down (increase order)
      // 1st node between = last order + 1, 2nd = 1st + 1, etc
      nodesBetweenSelection.forEach((node, i) => {
        const row = node.data
        const newOrder = lastOrder + i + 1
        updatedOrders.value[row.id] = {
          old: get(row, orderProp.value),
          new: newOrder
        }

        set(row, orderProp.value, newOrder)
      })
    }
    // Moving down
    else {
      newTargetOrder = firstElOrder
      
      let firstOrder = 0
      nodesData.forEach((n, i) => {
        const newOrder = newTargetOrder + i + nodesBetweenSelection.length  + 1
        updatedOrders.value[n.id] = {
          old: get(n, orderProp.value),
          new: newOrder
        }

        set(n, orderProp.value, newOrder)

        firstOrder = firstOrder || newOrder
      })

      // Nodes between must go up (decrease order)
      // 1st node between = first sel order - 1, 2nd = first sel order - 2, etc, 3rd = 1st - 3, etc
      nodesBetweenSelection.forEach((node, i) => {
        const row = node.data
        const newOrder = firstOrder - (i + 1)
        updatedOrders.value[row.id] = {
          old: get(row, orderProp.value),
          new: newOrder
        }

        set(row, orderProp.value, newOrder)
      })
    }

    const targetData = { ...event.overNode!.data }
    set(targetData, orderProp.value, newTargetOrder)

    updatedOrders.value[targetData.id] = {
      old: targetOrder,
      new: newTargetOrder
    }

    const tasksToUpdate = [...nodesData, targetData]

    tasksToUpdate.forEach((task) => {
      const index = updatedTasks.value.findIndex((t: any) => t.id === task.id)
      if (index > -1) {
        updatedTasks.value[index] = task
      } else {
        updatedTasks.value.push(task)
      }
    })

    event.api.applyTransaction({
      update: tasksToUpdate
    })
  }

  async function onRowDragEnd(event: RowDragEvent, isDetailGrid: boolean = false) {
    isAlreadyDragging.value = false
    if (!event.overNode) {
      return;
    }

    if (isDragToChangeParent.value) {
      updateParentTask(event.nodes, event.overNode, event.api)

      // Not necessarily needed, but it's a good practice to reset the state
      isDragToChangeParent.value = false
      return
    }

    if (!allowReordering.value) {
      return
    }

    updateOrders(event.api)
  }

  // #endregion

  // #region Reorder

  const currentSort = computed(() => {
    let currentSort = tasksApiFilters.value.find((filter: any) => filter.key === 'sorts')?.value || []

    if (!Array.isArray(currentSort)) {
      currentSort = [currentSort]
    }

    return currentSort
  })

  const isSortByOrderAsc = computed(() => {
    return currentSort.value.length === 1 && currentSort.value[0].column === 'order' && currentSort.value[0].order === 'asc'
  })

  const allowReordering = computed(() => {
    const prerequisites = [
      !isInsideClosedProject.value,
      isSortByOrderAsc.value,
      can(actions.EDIT_TASKS)
    ]
    
    return prerequisites.every(Boolean)
  })

  async function updateOrders(gridApi: GridApi) {
    if (!allowReordering.value) {
      return
    }

    const tasksToUpdate = Object.keys(updatedOrders.value).map((id) => {
      return {
        id,
        order: updatedOrders.value[id].new
      }
    })

    try {
      gridApi.deselectAll()

      if (isInsideProject.value) {
        await store.dispatch('tasks/updateTasks', { taskModels: tasksToUpdate })

        updatedTasks.value.forEach(async (task: any) => {
          store.dispatch('tasks/updateTaskInArray', task)
        })
      }
      else {
        const newOrdersObject = tasksToUpdate.reduce((acc: { [key: number | string]: number }, task) => {
          acc[task.id] = task.order
          return acc
        }, {})

        await store.dispatch('tasks/updateUserScopedOrders', newOrdersObject)
        updatedTasks.value.forEach(async (task: any) => {
          store.dispatch('tasks/updateTaskInArray', task)
        })
      }
    } catch (e) {
      error(i18n.t('Failed to update task order'))
    }
    finally {
      updatedOrders.value = {}
      updatedTasks.value = []
    }
  }

  // #endregion

  // #region Change parent

  const allowParentChange = computed(() => {
    const prerequisites = [
      !isInsideClosedProject.value,
      showDetailsView.value,
      can(actions.EDIT_TASKS),
    ]

    return prerequisites.every(Boolean)
  })

  async function updateParentTask(nodes: RowNode[], parentNode: RowNode | null, gridApi: GridApi) {
    if (!allowParentChange.value) {
      return
    }

    if (parentNode && nodes.includes(parentNode)) {
      return
    }

    let parentTask = parentNode?.data
    const parentTaskId = parentTask?.id || null

    const originalTasks: any = []
    const updatedChildTasks: any[] = []

    nodes.forEach((node) => {
      const childTask = node.data

      originalTasks.push(childTask)

      if (childTask.id == parentTaskId) {
        return
      }

      if (childTask.attributes.parent_id == parentTaskId) {
        return
      }

      if (parentTask && childTask.attributes.project_id != parentTask.attributes.project_id) {
        error(i18n.t('Task not changed to a sub task as the selected parent task is part of a different project and tasks cannot be moved to a different project.'))
        return
      }

      if (parentTask && parentTask.attributes.visibility != childTask.attributes.visibility) {
        error(i18n.t('The privacy of the 2 tasks must be the same to make one a sub-task.'))
        return
      }

      const updatedChildTask = {
        ...childTask,
        attributes: {
          ...childTask.attributes,
          prev_parent_id: childTask.attributes.parent_id,
          parent_id: parentTaskId,
        }
      }

      updatedChildTasks.push(updatedChildTask)
    })

    updatedChildTasks.forEach(async (task) => {
      store.dispatch('tasks/syncParentRelationship', {
        childTask: task,
        previousParentId: task.attributes.prev_parent_id,
        newParentId: task.attributes.parent_id,
      })
      await sleep(100)
      store.dispatch('tasks/updateTaskInArray', task)
    })

    try {
      const tasksToUpdate = updatedChildTasks.map(task => {
        return {
          id: task.id,
          parent_id: parentTaskId,
        }
      })

      await store.dispatch('tasks/updateTasks', { taskModels: tasksToUpdate })
    } catch (e) {
      // Reset relationships in case it fails
      originalTasks.forEach((task: any) => {
        store.dispatch('tasks/updateTaskInArray', task)
      })
    }
  }

  return {
    allowReordering,
    allowParentChange,
    isAlreadyDragging,
    isDragToChangeParent,
    onRowDragEnter,
    onRowDragMove,
    onRowDragEnd,
    getRowDragText,
  }

}
