<template>
  <div class="flex space-x-2">
    <BaseTooltip
      :content="isTemplatePath ? $t('Name your template to allow adding tools...') : $t('Name your project to allow adding tools...')"
      :disabled="project?.id"
    >
      <BaseButton
        type="button"
        :disabled="$attrs.disabled"
        @click="onOpenDialog"
      >
        <i class="fa-solid fa-plus mr-1" />
        {{ toolsLabel }}
        <i
          v-if="toolsLimitReached"
          class="fas fa-crown text-yellow-500 ml-2"
        />

      </BaseButton>
    </BaseTooltip>
    <BaseButton
      v-if="visibleToolsCount > 0"
      variant="white"
      type="button"
      class="focus:ring-0 focus:border-gray-200"
      @click="$emit('toggle-remove-tools-enabled')"
    >
      {{ isRemoveToolsEnabled ? $t('Done') : $t('Remove Tools') }}
    </BaseButton>
  </div>
  <ProjectToolsDialog
    v-if="showToolsDialog"
    v-model="showToolsDialog"
    v-show="showToolsDialog"
    key="project-tools"
    :project="project"
    @close="showToolsDialog = false"
  />
</template>
<script>
// Components
import ProjectToolsDialog from "@/modules/projects/components/ProjectToolsDialog.vue";

// Helpers
import { isAppleDevice } from "@/modules/common/utils/navigatorUtils"
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from "@/modules/common/composables/useCan";

export default {
  components: {
    ProjectToolsDialog,
  },
  props: {
    isRemoveToolsEnabled: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => ({})
    },
    visibleToolsCount: {
      type: Number,
      default: 0
    },
    toolShortcutsCount: {
      type: Number,
      default: 0
    },
    descriptionOptionsVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle-remove-tools-enabled'],
  setup() {
    const {
      hasReachedLimit,
      openUpgradeDialog
    } = useAccountLimits()

    return {
      openUpgradeDialog,
      hasReachedLimit,
    }
  },
  data() {
    return {
      showToolsDialog: false,
    }
  },
  computed: {
    toolsLimitReached() {
      return this.hasReachedLimit(AccountPermissions.ProjectTools)(this.project)
    },
    dropdownShortcut() {
      if (isAppleDevice()) {
        return `command + option + T <br> (⌘ ⌥ T)`
      }
      return `Ctrl + Alt + T`
    },
    toolsLabel() {
      if (!this.descriptionOptionsVisible || !this.toolShortcutsCount) {
        return this.$t('Add Tools')
      }

      return this.$t('See All Tools')
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    }
  },
  methods: {
    onKeyDown(event) {
      if ((event.ctrlKey || event.metaKey) && event.altKey && event.code === 'KeyT') {
        this.showToolsDialog = true
      }
    },
    onOpenDialog() {
      if (this.toolsLimitReached) {
        return this.openUpgradeDialog(AccountPermissions.ProjectTools)
      }

      this.showToolsDialog = true
    }
  },
  mounted() {
    addEventListener('keydown', this.onKeyDown)
  },
  unmounted() {
    removeEventListener('keydown', this.onKeyDown)
  }
}
</script>
