<template>
  <BaseNoDataSection
    :title="$t('No notes found')"
    iconClass="fa-sticky-note"
    :canCreate="true"
  >
    <template #can-create-section>
      <div>{{ $t('Notes let you keep track of important information and organize it.') }}</div>
    </template>
  </BaseNoDataSection>
</template>
