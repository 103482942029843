<template>
  <BaseSelect
    v-model="model"
    :options="fileTypeOptions"
    :placeholder="$t('Select File Type...')"
    :return-object="returnObject"
  />
</template>
<script>
import { getFileTypeOptions } from "@/modules/files/utils/filtersUtils"

export default {
  name: "FileTypeSelect",
  props: {
    modelValue: {
      type: [Object, String],
      required: true,
    },
    returnObject: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fileTypeOptions: getFileTypeOptions().map((x, i) => ({
        ...x,
        id: i
      }))
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
};
</script>
<style>
</style>
