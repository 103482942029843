<template>
  <time>
    {{ formattedTime }}
  </time>
</template>
<script>
import { get } from 'lodash-es'

// Helpers
import { minutesToFormattedTime } from "@/modules/common/utils/timeUtils"
import { formatDate } from "@/modules/common/utils/dateUtils";

export default {
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    row: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({
        format: "HH:mm",
        valueType: 'm'
      })
    }
  },
  computed: {
    formattedTime() {
      const value = get(this.row, this.column.prop)
      if (!value) {
        return '- -'
      }
      const date = new Date();
      const valueType = this.params.valueType
      if (valueType === 'h') {
        date.setHours(value, 0, 0);
      }
      else if (valueType === 'm') {
        return minutesToFormattedTime(value)
      }
      else if (valueType === 's') {
        date.setHours(0, 0, value);
      }
      
      const timeFormat = this.params.format
      return formatDate(date, timeFormat)
    }
  }
}
</script>
