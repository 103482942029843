<template>
  <div
    class="animate-pulse grid grid-cols-8"
    :class="{
      'max-w-6xl m-auto rounded-lg bg-white border-gray-200 border shadow': !$useNewLayout
    }"
  >
    <!--Left Side: START-->
    <div class="col-span-8 md:col-span-5 p-6 sm:p-8">
      <div class="relative text-left float-right w-24 bg-gray-200 h-10 rounded-md" />
      <!--Task Name-->
      <div class="mb-4 h-10 bg-gray-200 w-9/12 rounded-md" />

      <!--Task Properties -->
      <div class="flex flex-wrap items-center gap-y-3 gap-x-6 border border-gray-200 bg-gray-50 py-2 pl-1 pr-2 rounded-md">
        <div class="w-16 h-4 bg-gray-100 rounded-sm" />
        <div class="w-24 h-4 bg-gray-100 rounded-sm" />
        <div class="w-20 h-4 bg-gray-100 rounded-sm" />
      </div>

      <div class="my-4 flex flex-col space-y-2">
        <div class="w-full h-5 bg-gray-100 rounded-md" />
      </div>
      <div class="flex flex-col mb-4">
        <div class="w-full h-16 bg-gray-100 rounded-md" />
      </div>

      <div class="flex flex-wrap items-center gap-y-3 gap-x-6 py-2" />


      <div class="w-52 h-5 bg-gray-100 rounded-md" />
      <div class="w-52 h-5 bg-gray-100 rounded-md mt-2" />
    </div>
    <!--Left Side: END-->

    <!--Right Side: START-->
    <div class="col-span-8 md:col-span-3 p-6 sm:p-8 border-l border-gray-200 h-full">
      <div class="text-gray-900 text-xl font-bold mb-4 bg-gray-200 w-24 h-7 rounded-md" />

      <div class="grid grid-cols-10 gap-3 items-start">    
        <template
          v-for="i in 8"
          :key="i"
        >
          <div class="col-span-4 bg-gray-200 h-5 w-28 mt-1 rounded-md" />
          <div class="col-span-6">
            <div class="bg-gray-200 h-5 w-40 mt-1 rounded-md" />
          </div>
        </template>
      </div>
      <div class="relative text-left w-48 bg-gray-200 h-10 rounded-md mt-8" />
    </div>
    <!--Right Side: END-->
  </div>
</template>
<script lang="ts" setup />
