<template>
  <span>
    {{ formattedPrice }}
  </span>
</template>
<script>
import { get } from 'lodash-es'

export default {
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    row: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formattedPrice() {
      const value = get(this.row, this.column.prop)
      if (!value) {
        return '- -'
      }
      return this.$formatPrice(value, {
        currencyCode: get(this.row, 'attributes.currency')
      })
    }
  }
}
</script>
<style>
</style>
