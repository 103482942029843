<template>
  <BaseBadge :color="color"
             class="my-0"
  >
    <div class="flex w-full justify-center">
      {{ value }}
    </div>
  </BaseBadge>
</template>
<script>
import { get } from 'lodash-es';
export default {
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    row: {
      type: Object,
      default: () => ({})
    },
    color: {
      type: String,
      default: 'rgb(107, 114, 128)',
    },
  },
  computed: {
    value() {
      const value = get(this.row, this.column.prop)
      if (!value) {
        return 0
      }
      return value
    }
  }
}
</script>
