import { get } from 'lodash-es'

export const rolesEnum = {
  COLLABORATOR: 'Collaborator',
  COLLABORATOR_PLUS: 'Collaborator Plus',
  CREATOR: 'Creator',
  CREATOR_PLUS: 'Creator Plus',
  CREATOR_ADMIN: 'Creator Admin',
}

export const roleRanksEnum = {
  COLLABORATOR: 1,
  COLLABORATOR_PLUS: 2,
  CREATOR: 3,
  CREATOR_PLUS: 4,
  CREATOR_ADMIN: 5,
}

export const getRoleRank = (role) => {
  if (typeof role === 'number')
    return role

  for (const roleKey in rolesEnum)
    if (rolesEnum[roleKey] === role)
      return roleRanksEnum[roleKey]

  return 0;
}

/**
 * Compares 2 given roles to see if a role is greater than the other
 * @param role1
 * @param role2
 * @return {boolean}
 */
export function isRoleGreaterOrEqual(role1, role2) {
  const role1Rank = getRoleRank(role1)
  const role2Rank = getRoleRank(role2)
  if (!role1Rank) {
    return false
  }
  if (!role2Rank) {
    return true
  }
  return role1Rank >= role2Rank
}

export function isCollaborator(user) {
  const role = get(user, 'attributes.role[0]')
  const roleRank = getRoleRank(role)
  return [roleRanksEnum.COLLABORATOR, roleRanksEnum.COLLABORATOR_PLUS].includes(roleRank)
}
