import i18n from '@/i18n'
import { computed, onBeforeMount, ref } from 'vue'
import {
  ColumnHooks,
  DropdownOptionAPI,
  ErrorValues,
  ImportLogs,
  PassSubmitResult,
  RejectSubmitResult,
  ResultValues,
  SettingsAPI
} from "nuvo-vuejs"
import { Privacy } from "@/modules/projects/utils/projectHelpers"
import { taskDateTypes, visibilityTypes } from "@/modules/tasks/utils/modelUtils"
import { ImportFinishedEvent, importEntityTypes } from "@/modules/accounts/utils/importUtils"

import { useStore } from 'vuex'
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { rolesEnum } from '@/modules/common/utils/isRoleGreaterOrEqual'
import { getSetting } from '@/plugins/settingsPlugin'

import { isProduction } from '@/modules/common/config'

export const defaultImporterSettings: SettingsAPI = {
  identifier: 'default',
  columns: [],
  developerMode: !isProduction(),
  enableExamples: true,
  allowCustomColumns: false,
  /*
    Set as true to enable custom columns and import dynamic custom fields
    Should this be injected from configuration (user selection?) & only enabled for imports inside project (project level custom fields)?
  */
  style: {
    buttons: {
      import: {
        backgroundColor: '#431794',
        color: 'white',
        padding: "0.5rem 1rem",
        borderRadius: "6px",
      },
    }
  }
}

export type CustomFieldImport = {
  key: string,
  name: string,
  type: string,
}

export type CompleteFunction = (submitResult?: RejectSubmitResult | PassSubmitResult) => void

export type PostProcessFunction = (
  results: ResultValues,
  errors: ErrorValues,
  complete: CompleteFunction,
  logs: ImportLogs
) => { updatedResults: ResultValues, updatedErrors: ErrorValues, rejected?: boolean }

export type EntityImporter = {
  settings: SettingsAPI,
  columnHooks: ColumnHooks,
  postProcessing?: PostProcessFunction
}

export type EntityImportOptions = {
  overwrite_data?: boolean,
  auto_create_projects?: boolean,
  auto_create_groups?: boolean,
  auto_invite_people?: boolean,
  project_id?: number | string,
  custom_fields?: CustomFieldImport[]
}

export type DataImportRequest = {
  import_type: importEntityTypes,
  data: any[],
  options: EntityImportOptions
}

function stringToArray(result: any, property: string) {
  let stringValue = result[property]
  let value = []

  if (stringValue.includes(';')) {
    value = stringValue.split(';').map((group: string) => group.trim())
  }
  else if (stringValue.includes(',')) {
    value = stringValue.split(',').map((group: string) => group.trim())
  }
  else if (stringValue) {
    value = [stringValue]
  }

  return value
}

const dateColumnHook = (values: any) => {
  return values.map(([item, index]: any) => {
    let date = item
    let info = []

    if (date?.startsWith('00')) {
      date = `20${date.substring(2)}`
    }

    if (date) {
      info.push({
        message: i18n.t('Format: YYYY-MM-DD'),
        level: 'info'
      })
    }

    return [
      {
        value: date,
        info: info.length ? info : undefined
      },
      index
    ]
  })
}

export function useDataImporter() {
  const store = useStore()

  const {
    accountLimits,
    isFreePlan
  } = useAccountLimits()

  const taskStatuses = computed(() => {
    return store.getters['tasks/orderedStatuses'] || []
  })

  const defaulTaskStatus = computed(() => {
    const defaultTaskStatusId = getSetting('default_task_status')
    const defaultAccountStatus = taskStatuses.value.find((status: any) => status.id == defaultTaskStatusId)

    return defaultAccountStatus || taskStatuses.value[0]
  })

  const defaultTaskVisibility = computed(() => {
    const defaultAccountVisibility = getSetting('default_task_visibility')

    return defaultAccountVisibility || visibilityTypes.CREATORS_ONLY
  })

  const projectStatuses = computed(() => {
    return store.getters['projects/orderedStatuses'] || []
  })

  const defaultProjectStatus = computed(() => {
    const defaultProjectStatusId = getSetting('default_project_status')
    const defaultAccountStatus = projectStatuses.value.find((status: any) => status.id == defaultProjectStatusId)

    return defaultAccountStatus || projectStatuses.value[0]
  })

  const defaultProjectPrivacy = computed(() => {
    const defaultAccountPrivacy = getSetting('default_project_privacy')

    return defaultAccountPrivacy || Privacy.Project
  })

  const userRoles = computed(() => {
    return store.getters['users/orderedRoles'] || []
  })

  const maxCreatorsToImport = computed(() => {
    return accountLimits.value.seats.available - accountLimits.value.seats.used
  })

  const maxCollaboratorsToImport = computed(() => {
    if (!accountLimits.value.collaboratorSeats.available) {
      return 10000
    }

    return accountLimits.value.collaboratorSeats.available - accountLimits.value.collaboratorSeats.used
  })

  const groupsImporter = computed<EntityImporter>(() => {
    const settings: SettingsAPI = {
      identifier: importEntityTypes.Groups,
      columns: [
        {
          label: i18n.t('Name'),
          key: 'name',
          columnType: 'string',
          validations: [
            {
              validate: 'required'
            },
          ]
        },
        {
          label: i18n.t('Invoice Details'),
          key: 'invoice_details',
          columnType: 'string',
          example: 'address1'
        },
        {
          label: i18n.t('Image'),
          columnType: 'url_https',
          key: 'image',
        },
        {
          label: i18n.t('Created At'),
          key: 'created_at',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD'
        },
      ]
    }

    const columnHooks: ColumnHooks = {
      created_at: dateColumnHook
    }

    return {
      settings,
      columnHooks
    }
  })

  const peopleImporter = computed<EntityImporter>(() => {
    const roleOptions = userRoles.value.map((role: any) => {
      return {
        label: role.attributes.name,
        value: role.attributes.name,
        type: 'string'
      }
    })

    const settings: SettingsAPI = {
      identifier: importEntityTypes.People,
      maxEntries: 20, // Accept max 20 users at a time
      columns: [
        {
          label: i18n.t('Email'),
          key: 'email',
          columnType: 'email',
          validations: [
            {
              validate: 'required'
            },
            {
              validate: 'unique'
            },
          ]
        },
        {
          label: i18n.t('Role'),
          key: 'role',
          columnType: 'category',
          dropdownOptions: roleOptions
        },
        {
          label: i18n.t('Created At'),
          key: 'created_at',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD'
        },
        {
          label: i18n.t('Avatar'),
          columnType: 'url_https',
          key: 'avatar',
        },
        {
          label: i18n.t('Groups'),
          key: 'groups',
        }
      ]
    }

    const columnHooks: ColumnHooks = {
      created_at: dateColumnHook,
      role: (values: any) => {
        return values.map(([item, index]: any) => {
          let role = item
    
          if (role) {
            return [
              {
                value: role,
              },
              index
            ]
          }

          role = roleOptions?.[0]?.value

          return [
            {
              value: role,
              info: [
                {
                  message: i18n.t('We automatically assigned the role for this row as it was empty.'),
                  level: 'info'
                }
              ]
            },
            index
          ]
        })
      }
    }

    function postProcessing(results: ResultValues, errors: ErrorValues, complete: CompleteFunction, logs: ImportLogs) {
      const pendingCreatorsToImport = results.filter((result: any) => {
        return [
          rolesEnum.CREATOR,
          rolesEnum.CREATOR_PLUS,
          rolesEnum.CREATOR_ADMIN
        ].includes(result.role)
      })

      const pendingCollaboratorsToImport = results.filter((result: any) => {
        return [
          rolesEnum.COLLABORATOR,
          rolesEnum.COLLABORATOR_PLUS,
        ].includes(result.role)
      })

      if (pendingCreatorsToImport.length > maxCreatorsToImport.value) {
        const submitResult = new RejectSubmitResult(
          i18n.t(`Import Error`),
          i18n.tc(`max creators to import error`, {
            maxCount: maxCreatorsToImport.value,
            pendingCount: pendingCreatorsToImport.length
          }),
        )

        complete(submitResult)

        return {
          updatedResults: results,
          updatedErrors: errors,
          rejected: true
        }
      }

      if (pendingCollaboratorsToImport.length > maxCollaboratorsToImport.value) {
        const submitResult = new RejectSubmitResult(
          i18n.t(`Import Error`),
          i18n.tc(`max collaborators to import error`, {
            maxCount: maxCollaboratorsToImport.value,
            pendingCount: pendingCollaboratorsToImport.length
          }),
        )

        complete(submitResult)

        return {
          updatedResults: results,
          updatedErrors: errors,
          rejected: true
        }
      }

      const updatedResults = results.map((result: any) => {
        let groups = stringToArray(result, 'groups')
        
        return {
          ...result,
          groups
        }
      })

      return {
        updatedResults,
        updatedErrors: errors
      }
    }

    return {
      settings,
      columnHooks,
      postProcessing
    }
  })
  
  const projectsImporter = computed<EntityImporter>(() => {
    const statusOptions = projectStatuses.value.map((status: any) => {
      return {
        label: status.attributes.name,
        value: status.attributes.name,
        type: 'string'
      }
    })

    const privacyOptions: DropdownOptionAPI[] = [
      {
        label: i18n.t('Only visible to invited people'),
        value: Privacy.Project,
        type: 'string'
      },
      {
        label: i18n.t('Visible to all Creators & invited Collaborators'),
        value: Privacy.Account,
        type: 'string'
      },
    ]

    const settings: SettingsAPI = {
      identifier: importEntityTypes.Projects,
      columns: [
        {
          label: i18n.t('Name'),
          key: 'name',
          columnType: 'string',
          validations: [
            {
              validate: 'required'
            },
          ]
        },
        {
          label: i18n.t('Cover Image'),
          columnType: 'url_https',
          key: 'cover_image',
        },
        {
          label: i18n.t('Image'),
          columnType: 'url_https',
          key: 'image',
        },
        {
          label: i18n.t('Description'),
          key: 'description',
          columnType: 'string',
        },
        {
          label: i18n.t('Status'),
          key: 'status_name',
          columnType: 'category',
          dropdownOptions: statusOptions,
          allowCustomOptions: true, // Allow custom statuses to be created & matched automatically
        },
        {
          label: i18n.t('Created At'),
          key: 'created_at',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD'
        },
        {
          label: i18n.t('Start Date'),
          key: 'start_date',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD'
        },
        {
          label: i18n.t('Deadline'),
          key: 'deadline',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD'
        },
        {
          label: i18n.t('Completed At'),
          key: 'completed_at',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD'
        },
        {
          label: i18n.t('Privacy'),
          key: 'privacy',
          columnType: 'category',
          optionMappingMode: 'smart',
          dropdownOptions: privacyOptions
        },
        {
          label: i18n.t('Groups'),
          key: 'groups',
        },
        {
          label: i18n.t('People'),
          key: 'users',
        }
      ]
    }

    const columnHooks: ColumnHooks = {
      created_at: dateColumnHook,
      start_date: dateColumnHook,
      deadline: dateColumnHook,
      completed_at: dateColumnHook,
      status_name: (values: any) => {
        return values.map(([item, index]: any) => {
          let status_name = item
    
          if (status_name) {
            return [
              {
                value: status_name,
              },
              index
            ]
          }

          status_name = defaultProjectStatus.value?.attributes?.name

          return [
            {
              value: status_name,
              info: [
                {
                  message: i18n.t('We automatically assigned the status for this row as it was empty.'),
                  level: 'info'
                }
              ]
            },
            index
          ]
        })
      },
      privacy: (values: any) => {
        return values.map(([item, index]: any) => {
          let privacy = item
    
          if (privacy) {
            return [
              {
                value: privacy,
              },
              index
            ]
          }

          privacy = defaultProjectPrivacy.value

          return [
            {
              value: privacy,
              info: [
                {
                  message: i18n.t('We automatically assigned the privacy for this row as it was empty.'),
                  level: 'info'
                }
              ]
            },
            index
          ]
        })
      }
    }

    function postProcessing(results: ResultValues, errors: ErrorValues, complete: CompleteFunction, logs: ImportLogs) {
      const updatedResults = results.map((result: any) => {
        let groups = stringToArray(result, 'groups')
        let users = stringToArray(result, 'users')

        return {
          ...result,
          groups,
          users
        }
      })

      return {
        updatedResults,
        updatedErrors: errors
      }
    }

    return {
      settings,
      columnHooks,
      postProcessing
    }
  })

  const tasksImporter = computed<EntityImporter>(() => {
    const statusOptions = taskStatuses.value.map((status: any) => {
      return {
        label: status.attributes.name,
        value: status.attributes.name,
        type: 'string'
      }
    })

    const dateTypeOptions: DropdownOptionAPI[] = [
      {
        label: i18n.t('No Date'),
        value: taskDateTypes.NO_DATE,
        type: 'string'
      },
      {
        label: i18n.t('Single Date'),
        value: taskDateTypes.SINGLE_DATE,
        type: 'string'
      },
      {
        label: i18n.t('Date Range'),
        value: taskDateTypes.DATE_RANGE,
        type: 'string'
      },
      // We don't need this for now as recurring tasks are not supported at import
      // {
      //   label: i18n.t('Recurring'),
      //   value: taskDateTypes.RECURRING,
      //   type: 'string'
      // }
    ]

    const visibilityOptions: DropdownOptionAPI[] = [
      {
        label: i18n.t('Visible to Collaborators'),
        value: visibilityTypes.CREATORS_AND_COLLABORATORS,
        type: 'string'
      },
      {
        label: i18n.t('Hidden from Collaborators'),
        value: visibilityTypes.CREATORS_ONLY,
        type: 'string'
      }
    ]
    
    const settings: SettingsAPI = {
      identifier: importEntityTypes.Tasks,
      columns: [
        {
          label: i18n.t('Name'),
          key: 'name',
          columnType: 'string',
          validations: [
            {
              validate: 'required'
            },
          ]
        },
        {
          label: i18n.t('Status'),
          key: 'status_name',
          columnType: 'category',
          dropdownOptions: statusOptions,
          allowCustomOptions: true, // Allow custom statuses to be created & matched automatically
        },
        {
          label: i18n.t('Date Type'),
          key: 'date_type',
          columnType: 'category',
          dropdownOptions: dateTypeOptions,
        },
        {
          label: i18n.t('Due Date'),
          key: 'date',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD',
          validations: [
            {
              validate: 'required_with_values',
              columnValues: {
                date_type: [taskDateTypes.SINGLE_DATE]
              }
            }
          ]
        },
        {
          label: i18n.t('Start Date'),
          key: 'start_date',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD',
          validations: [
            {
              validate: 'required_with_values',
              columnValues: {
                date_type: [taskDateTypes.DATE_RANGE]
              }
            }
          ]
        },
        {
          label: i18n.t('End Date'),
          key: 'end_date',
          columnType: 'date',
          outputFormat: 'YYYY-MM-DD',
          validations: [
            {
              validate: 'required_with_values',
              columnValues: {
                date_type: [taskDateTypes.DATE_RANGE]
              }
            }
          ]
        },
        {
          label: i18n.t('Visibility'),
          key: 'visibility',
          columnType: 'category',
          dropdownOptions: visibilityOptions,
          description: 'Description explaining task visibility options'
        },
        {
          label: i18n.t('Project Name'),
          key: 'project_name',
          columnType: 'string',
          validations: [
            {
              validate: 'required'
            },
          ]
        },
        {
          label: i18n.t('Allocations'),
          key: 'allocations',
        },
        {
          label: i18n.t('Followers'),
          key: 'followers',
        },
        {
          label: i18n.t('Groups'),
          key: 'groups',
        },
        {
          label: i18n.t('Description'),
          key: 'notes',
          columnType: 'string',
        },
        {
          label: i18n.t('Parent Task'),
          key: 'parent_task_name',
          columnType: 'string',
        }
      ],
    }

    const columnHooks: ColumnHooks = {
      date: dateColumnHook,
      start_date: dateColumnHook,
      end_date: dateColumnHook,
      status_name: (values: any) => {
        return values.map(([item, index]: any) => {
          let status_name = item
    
          if (status_name) {
            return [
              {
                value: status_name,
              },
              index
            ]
          }

          status_name = defaulTaskStatus.value?.attributes?.name

          return [
            {
              value: status_name,
              info: [
                {
                  message: i18n.t('We automatically assigned the status for this row as it was empty.'),
                  level: 'info'
                }
              ]
            },
            index
          ]
        })
      },
      date_type: (values: any) => {
        return values.map(([item, index]: any) => {
          let date_type = item
    
          if (date_type) {
            return [
              {
                value: date_type,
              },
              index
            ]
          }

          date_type = dateTypeOptions?.[0]?.value

          return [
            {
              value: date_type,
              info: [
                {
                  message: i18n.t('We automatically assigned the date type for this row.'),
                  level: 'info'
                }
              ]
            },
            index
          ]
        })
      },
      visibility: (values: any) => {
        return values.map(([item, index]: any) => {
          let visibility = item
    
          if (visibility) {
            return [
              {
                value: visibility,
              },
              index
            ]
          }

          visibility = defaultTaskVisibility.value

          return [
            {
              value: visibility,
              info: [
                {
                  message: i18n.t('We automatically assigned the visibility for this row.'),
                  level: 'info'
                }
              ]
            },
            index
          ]
        })
      }
    }

    function postProcessing(results: ResultValues, errors: ErrorValues, complete: CompleteFunction, logs: ImportLogs) {
      const updatedResults = results.map((result: any) => {
        let groups = stringToArray(result, 'groups')
        let followers = stringToArray(result, 'followers')
        let allocations = stringToArray(result, 'allocations')

        return {
          ...result,
          groups,
          followers,
          allocations
        }
      })

      return {
        updatedResults,
        updatedErrors: errors
      }
    }

    return {
      settings,
      columnHooks,
      postProcessing
    }
  })

  const entityImporters = computed<Record<importEntityTypes, EntityImporter>>(() => {
    return {
      [importEntityTypes.Groups]: groupsImporter.value,
      [importEntityTypes.People]: peopleImporter.value,
      [importEntityTypes.Projects]: projectsImporter.value,
      [importEntityTypes.Tasks]: tasksImporter.value,
    }
  })

  async function startImport( request: DataImportRequest) {
    return await store.dispatch('accounts/startImport', request)
  }

  async function handleImportFinished(event: ImportFinishedEvent) {
    const importTypeHandlers: Partial<Record<importEntityTypes, Function>> = {
      [importEntityTypes.Projects]: () => {
        store.dispatch('projects/getAllStatuses', true)
      },
      [importEntityTypes.Tasks]: () => {
        store.dispatch('tasks/getAllStatuses', true)
      }
    }

    const handler = importTypeHandlers[event.import_type]

    if (handler) {
      await handler()
    }

    return await store.dispatch('users/getData')
  }

  async function loadOptions() {
    await store.dispatch('users/getRoles')
    await store.dispatch('projects/getAllStatuses')
    await store.dispatch('tasks/getAllStatuses')
  }

  const loadingImportEntries = ref(false)

  async function getImportEntries() {
    try {
      loadingImportEntries.value = true
      await store.dispatch('accounts/getImportEntries')
    }
    catch (error) {
      console.error(error)
    }
    finally {
      loadingImportEntries.value = false
    }
  }

  const importEntries = computed(() => {
    return store.state.accounts.importEntries || []
  })

  onBeforeMount(async () => {
    await loadOptions()
  })

  return {
    entityImporters,
    defaultImporterSettings,
    startImport,
    getImportEntries,
    handleImportFinished,
    loadingImportEntries,
    importEntries,
  }
}
