<template>
  <BaseEntityForm
    width-class="max-w-6xl"
    @submit="onSubmit"
  >
    <template #default="{ meta, errors }">
      <div class="md:grid md:grid-cols-4 md:gap-4 md:items-start">
        <BaseInput
          :modelValue="model.group_id"
          :name="$t('Group')"
          :label="$t('Group')"
          :errorMessage="errors[$t('Group')]"
          layout="vertical"
          id="person"
          class="mt-1 sm:mt-0"
        >
          <div class="sm:mt-0 sm:col-span-2">
            <GroupSelect
              v-model="model.group_id"
              :initialValue="model.group"
              :urlParams="{
                hasUsersWithRoleGreaterThan: $roleRanks.COLLABORATOR_PLUS,
                project_id: model.project_id
              }"
              :placeholder="$t('Choose group...')"
              :allow-entity-create="can($actions.CREATE_GROUPS)"
              @change="onGroupChanged"
            />
          </div>
        </BaseInput>

        <BaseInput
          :modelValue="model.user_id"
          :name="$t('Person')"
          :label="$t('Person')"
          rules="required"
          layout="vertical"
          id="person"
          class="mt-1 sm:mt-0"
        >
          <div class="sm:mt-0 sm:col-span-2">
            <UserSelect
              v-model="model.user_id"
              :initialValue="model.user"
              :placeholder="$t('Choose person...')"
              :urlParams="{
                group_id: model.group_id,
                roleGreaterThan: $roleRanks.COLLABORATOR_PLUS,
                project_id: model.project_id
              }"
              @change="onUserChanged"
            />
          </div>
        </BaseInput>

      </div>
      <div
        class="md:grid md:grid-cols-4 md:gap-4 md:items-start relative"
        v-for="(entry, i) in model.entries"
        :key="i"
      >
        <button
          v-if="model.entries.length > 1"
          type="button"
          class="text-xs text-gray-400 hover:text-red-500 flex items-center gap-2 absolute right-0 -top-1 h-min"
          @click="deleteEntry(i)"
        >
          <i class="far fa-trash" />
          <span>{{ $t('Delete entry') }}</span>
        </button>
        <BaseInput
          v-if="!$store.getters.project_id"
          :modelValue="entry.project_id"
          :name="`${$t('Project')}_${i}`"
          :label="$t('Project')"
          layout="vertical"
          rules="required"
          id="person"
          class="mt-1 sm:mt-0"
        >
          <div class="sm:mt-0 sm:col-span-2">
            <ProjectSelect
              v-model="entry.project_id"
              :initialValue="entry.project"
              :disabled="!model.user_id"
              :urlParams="{
                belongsToProject: model.user_id,
              }"
              :placeholder="$t('Choose project...')"
              @change="entry.task_id = null"
            />
          </div>
        </BaseInput>
        <BaseInput
          :name="`${$t('Task')}_${i}`"
          :label="$t('Task')"
          layout="vertical"
          id="person"
          class="mt-1 sm:mt-0"
        >
          <div class="sm:mt-0 sm:col-span-2">
            <TaskSelect
              v-model="entry.task_id"
              :initialValue="entry.task"
              :disabled="!entry.project_id"
              :urlParams="{
                project_id: entry.project_id,
              }"
              :placeholder="$t('Choose task (optional)...')"
            />
          </div>
        </BaseInput>
        <BaseInput
          :modelValue="entry.date"
          :name="`${$t('Date')}_${i}`"
          :label="$t('Date')"
          rules="required"
          class="sm:mt-0"
        >
          <BaseDatePicker
            v-model="entry.date"
            :disabled="!model.user_id || !entry.project_id"
            class="relative sm:mt-0"
          />
        </BaseInput>
        <BaseInput
          v-focus="!!timeEntry?.id"
          :modelValue="entry.worked_minutes"
          :name="`${$t('Hours')}_${i}`"
          :label="$t('Hours')"
          :placeholder="$t('Enter hours...')"
          rules="required|min_minutes:1|max_minutes:1440"
          layout="vertical"
          id="hours"
          class="mt-1 sm:mt-0"
        >
          <TimeInput
            v-model="entry.worked_minutes"
            :disabled="!model.user_id || !entry.project_id"
            :placeholder="$t('Choose how much time to allocate...')"
          />
        </BaseInput>

        <TimeEntryCustomFields
          :key="`time-entry-custom-fields-${i}`"
          :prependName="`Time Entry [${i}]`"
          :time-entry="entry"
          @update="entry.custom_fields = $event"
        />

      </div>

      <template v-if="!timeEntry?.id">
        <BaseButton
          :disabled="!model.user_id"
          type="button"
          variant="white"
          @click="addNewEntry"
        >
          {{ $t('Add Another') }}
        </BaseButton>
      </template>
    </template>

    <template #actions="{ meta, errors }">
      <div class="mr-auto">
        <router-link
          v-if="showTimeActualLink"
          to="/time/actual"
        >
          <BaseButton
            :tabindex="-1"
            variant="white"
          >
            {{ $t('View all time entries') }}
          </BaseButton>
        </router-link>
      </div>
      <div class="flex space-x-2">
        <BaseButton variant="white" @click="$emit('cancel')">
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          type="submit"
        >
          {{ timeEntry?.id ? $t('Save') : $t('Submit time entry') }}
        </BaseButton>
      </div>
    </template>
  </BaseEntityForm>
</template>
<script>
// Libs
import { cloneDeep } from 'lodash-es'

// Components
import ProjectSelect from '@/components/selects/ProjectSelect.vue'
import UserSelect from '@/components/selects/UserSelect.vue'
import GroupSelect from '@/components/selects/GroupSelect.vue'
import TaskSelect from '@/components/selects/TaskSelect.vue'
import TimeInput from '@/components/form/TimeInput.vue'
import TimeEntryCustomFields from '@/modules/time/components/TimeEntryCustomFields.vue'

// Utils
import { timeEntryToFormModel } from '@/modules/time/utils/formModelUtils.js'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import { canNote, NoteActions } from '@/modules/projects/utils/noteUtils'

export default {
  name: 'TimeEntryManualForm',
  components: {
    CloseButton,
    TimeEntryCustomFields,
    ProjectSelect,
    UserSelect,
    GroupSelect,
    TaskSelect,
    TimeInput,
  },
  props: {
    timeEntry: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['save', 'cancel'],
  data() {
    return {
      loading: false,
      model: {
        group_id: null,
        project_id: null,
        user_id: null,
        entries: [],
      },
      savedTimeEntry: {},
    }
  },
  watch: {
    timeEntry: {
      handler() {
        this.initModel()
      },
      immediate: true,
    },
  },
  computed: {
    NoteActions() {
      return NoteActions
    },
    showTimeActualLink() {
      return !this.$route.path.includes('time/actual')
    },
  },
  methods: {
    canNote,
    async deleteEntry(index) {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete Time Entry'),
        description: this.$t('Are you sure? This will remove the time entry from the list.'),
      })

      if (!confirmed) {
        return
      }

      this.model.entries.splice(index, 1)
    },
    async onSubmit(emitSave = true, { resetForm }) {
      try {
        this.loading = true

        const data = cloneDeep(this.model)

        if (this.timeEntry?.id) {
          await this.$store.dispatch('time/editTimeEntry', {
            timeEntryId: this.timeEntry.id,
            timeEntry: this.timeEntry,
            data,
          })

          this.$success('Time entry updated successfully')
          if (emitSave) {
            this.$emit('save')
          }

        } else {
          this.savedTimeEntry = await this.$store.dispatch(
            'time/createTimeEntry',
            { data },
          )
          this.initModel()
          resetForm()
          this.$success('Time entry created successfully')
          if (emitSave) {
            this.$emit('save')

            if (!this.$store.getters.project_id) {
              return
            }
            // When we are in the project page, we need to update the project data
            this.$store.dispatch('projects/getProjectById', {
              id: data.project_id,
            })

          } else {
            await this.redirectToSavedTimeEntry()
          }
        }
      } catch (err) {
        if (err.handled) {
          return false
        }
        this.$error( this.timeEntry?.id
          ? this.$t('Could not update the time entry')
          : this.$t('Could not create the time entry')
        )

        return false
      } finally {
        this.loading = false
      }
      return true
    },
    async redirectToSavedTimeEntry() {
      await this.$store.dispatch('time/getTimeEntryById', {
        id: this.savedTimeEntry?.id,
      })
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          taskId: this.savedTimeEntry?.id,
        },
      })
    },
    initModel() {
      this.model.project_id = this.$store.getters.project_id || null
      if (this.timeEntry?.id) {
        this.model = timeEntryToFormModel(this.timeEntry)
        return
      }

      const user_id = this.$user.id
      const user = {
        id: user_id,
        attributes: {
          id: user_id,
          name: this.$user.name,
        },
      }

      this.model = {
        group_id: null,
        project_id: this.$store.getters.project_id || null,
        user_id,
        user,
        entries: [],
      }

      this.addNewEntry()
    },
    getNewEntryDefaults() {
      let project = null, project_id = null

      if (this.$store.getters.project_id) {
        project_id = this.$store.getters.project_id
        project = this.$store.state.projects.currentProject
      }

      return {
        project_id,
        project,
        task_id: null,
        date: new Date(),
        worked_minutes: null,
        custom_fields: {},
      }
    },
    addNewEntry() {
      const newEntry = cloneDeep(this.getNewEntryDefaults())
      this.model.entries.push(newEntry)
    },
    async onGroupChanged() {
      await this.$nextTick()

      if (!this.model.group_id) {
        return
      }

      this.model.user_id = null
      this.model.entries.forEach((e) => {
        if (!this.$store.getters.project_id) {
          e.project_id = null
        }
        e.task_id = null
      })
    },
    onUserChanged() {
      this.model.entries.forEach((e) => {
        if (!this.$store.getters.project_id) {
          e.project_id = null
        }
        e.task_id = null
      })
    },
  },
}
</script>
