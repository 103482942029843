export const entityTypes = {
  Task: 'task',
  Project: 'project',
  Group: 'group',
  User: 'user',
  ProjectPeople: 'projectPeople',
  CustomField: 'available_custom_field',
  Reference: 'reference',
  Folder: 'folder',
  File: 'file',
  Media: 'media',
  UpgradeAccount: 'upgradeAccount',
  TemplateGallery: 'templateGallery',
  TemplateDialog: 'templateDialog',
  Payment: 'payment',
  TimeEntry: 'time_entry',
  AllocatedTime: 'time_allocation',
  ProjectFromTemplate: 'projectFromTemplate',
  ProjectStatus: 'project_status',
  TaskStatus: 'task_status',
  ProjectTool: 'project_tool',
}

export const entityDialogTriggers = {
  [entityTypes.Task]: 'tasks/triggerAddTask',
  [entityTypes.Project]: 'projects/triggerAddProject',
  [entityTypes.ProjectPeople]: 'projects/triggerProjectPeople',
  [entityTypes.Reference]: 'projects/triggerAddReference',
  [entityTypes.Group]: 'groups/triggerAddGroup',
  [entityTypes.User]: 'users/triggerAddUser',
  [entityTypes.CustomField]: 'accounts/triggerAddCustomField',
  [entityTypes.Folder]: 'files/triggerAddFolder',
  [entityTypes.File]: 'files/triggerAddFile',
  [entityTypes.ProjectFromTemplate]: 'projects/triggerCreateProjectFromTemplate',
  [entityTypes.UpgradeAccount]: 'accounts/triggerUpgradeAccount',
  [entityTypes.TemplateGallery]: 'templateGallery/triggerDialogOpen',
  [entityTypes.TemplateDialog]: 'templateGallery/triggerTemplateDialogOpen',
  [entityTypes.TimeEntry]: 'time/triggerAddTimeEntry',
}
