<template>
  <BaseInlineInput>
    <template #default="{ triggerSave }">
      <BaseSelect
        :modelValue="model"
        :options="taskPrivacyOptions"
        :disabled="$attrs.disabled"
        :return-object="false"
        inline
        class="w-full"
        @update:modelValue="onChange($event, triggerSave)"
      >
        <template #prefix>
          <BaseBadge
            v-if="selectedOption"
            :color="selectedOption?.color"
          >
            {{ selectedOption?.label }}
          </BaseBadge>
          <BaseBadge
            v-else
            color="grey"
          >
            {{ $t('No privacy') }}
          </BaseBadge>
        </template>
      </BaseSelect>
    </template>
  </BaseInlineInput>
</template>
<script setup lang="ts">
import Data = API.Data
import Task = App.Domains.Tasks.Models.Task;

import { PropType, computed, nextTick } from 'vue'
import { taskPrivacyOptions } from "@/modules/tasks/utils/modelUtils";
import { $confirm } from '@/components/common/dialog/modalPlugin'

import i18n from '@/i18n';
import { capitalize } from 'lodash-es';

import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: '',
  },
  task: {
    type: Object as PropType<Data<Task>>,
    default: null,
  }
})

const emit = defineEmits([
  'update:modelValue',
  'sync-children-visibility'
])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const selectedOption = computed(() => {
  return taskPrivacyOptions.value.find(o => o.value === model.value)
})

const subtaskIds = computed(() => {
  return props.task?.attributes?.subtask_ids || []
})

const hasSubtasks = computed(() => {
  return subtaskIds.value.length > 0
})

async function onChange(value: string, triggerSave: () => void) {
  if (hasSubtasks.value) {
    const confirmed = await $confirm({
      title: i18n.t(`Warning`),
      description: i18n.t(`This will also update the privacy of all the sub tasks.`),
      buttonText: i18n.tc(`confirm privacy change`, { privacy: i18n.t(capitalize(value)) }),
    })

    if (!confirmed) {
      return
    }

    emit('sync-children-visibility', {
      subtaskIds: subtaskIds.value,
      visibility: value,
    })
  }

  emit('update:modelValue', value)
  triggerSave()
}
</script>
