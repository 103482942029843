<template>
  <div>
    <div
      contenteditable="false"
      translate="no"
      tabindex="0"
      class="rounded-md text-gray-500 bg-yellow-50 border border-yellow-500 px-3 py-1 space-y-2"
    >
      <p>
        <span class="text-gray-700 font-bold capitalize">
          {{ $t('Action') }}:
        </span>
        <i
          v-if="actionFunction?.iconClass"
          class="fa-regular mx-1"
          :class="{
            [actionFunction.iconClass]: actionFunction.iconClass
          }"
        />

        {{ actionDisplayName }}
      </p>
      <template v-if="Array.isArray(initialModel)">
        <div
          v-for="(model, idx) in bulkBindingModel"
          :key="idx"
        >
          <div class="my-2 border border-yellow-500" />
          <div class="space-y-2">
            <AiActionBindingResult
              v-for="prop in Object.keys(model)"
              :key="prop"
              :property="prop"
              :result="bulkBindingModel[idx][prop]"
              @discard="({ property, result }) => discardBindingResult({ property, result, idx })"
              @resolve="({ property, result, value, query }) => resolveBindingResult({ property, result, value, query, idx })"
            />
          </div>
        </div>
      </template>
      <template
        v-else
      >
        <AiActionBindingResult
          v-for="prop in Object.keys(bindingModel)"
          :key="prop"
          :property="prop"
          :result="bindingModel[prop]"
          @discard="({ property, result }) => discardBindingResult({ property, result, idx: 0 })"
          @resolve="({ property, result, value, query }) => resolveBindingResult({ property, result, value, query, idx: 0 })"
        />
      </template>
      <BaseAlert
        v-if="hasBindingIssues"
        variant="error"
        :dismissable="false"
      >
        {{ $t(`Click the values to fix them before proceeding.`)}}
      </BaseAlert>
    </div>
    <div class="space-x-4 mt-2">
      <BaseButton
        :variant="userChoice === UserChoice.Confirm ? 'primary' : 'white'"
        :disabled="!!userChoice || hasBindingIssues"
        :loading="executingLocalAction && userChoice === UserChoice.Confirm"
        class="border border-gray-200 rounded-lg px-2 py-1 hover:bg-gray-50"
        @click="onConfirm"
      >
        <i class="fa-solid fa-check mr-1 text-primary-500" />
        {{ $t('Run action') }}
      </BaseButton>
      <BaseButton
        :variant="userChoice === UserChoice.Revise ? 'primary' : 'white'"
        :disabled="!!userChoice"
        :loading="executingLocalAction && userChoice === UserChoice.Revise"
        class="border border-gray-200 rounded-lg px-2 py-1 hover:bg-gray-50"
        @click="onRevise"
      >
        <i class="fa-solid fa-pen mr-1 text-blue-500" />
        {{ $t('Revise') }}
      </BaseButton>
      <BaseButton
        :variant="userChoice === UserChoice.Cancel ? 'primary' : 'white'"
        :disabled="!!userChoice"
        :loading="executingLocalAction && userChoice === UserChoice.Cancel"
        class="border border-gray-200 rounded-lg px-2 py-1 hover:bg-gray-50"
        @click="onCancel"
      >
        <i class="fa-solid fa-xmark mr-1 text-red-500" />
        {{ $t('Cancel') }}
      </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
// Components
import AiActionBindingResult from "@/modules/ai/components/AiActionBindingResult.vue"

// Utils
import i18n from "@/i18n";
import { PropType, computed, onMounted, ref } from "vue";
import {
  AiFunctions,
  ChatMessage,
  UserChoice,
  ChatMessageTypes,
BindingDefinition,
} from "@/modules/ai/types/aiTypes";
import { BindingResult, DataBinder } from "@/modules/ai/filters/DataBinder";

// Composables
import { useStore } from "vuex";
import { functions } from "@/modules/ai/composables/useChatbox";
import { useActionChat } from "@/modules/ai/composables/useActionChat";

const store = useStore()

const {
  addChatMessage,
} = useActionChat()

const props = defineProps({
  message: {
    type: Object as PropType<ChatMessage>,
    default: '',
  },
})

const userChoice = ref<UserChoice | null>(props.message?.data?.userChoice || null)

const requiresUserAction = computed({
  get() {
    return store.state.ai.requiresUserAction
  },
  set(value) {
    store.commit('ai/setRequiresUserAction', value)
  }
})

const executingLocalAction = ref(false)

const isExecutingAction = computed({
  get() {
    return store.state.ai.isExecutingAction
  },
  set(value) {
    store.commit('ai/setIsExecutingAction', value)
  }
})


const requiresConfirmation = computed(() => {
  return props.message.type === 'user-confirmation'
})

const initialModel = computed(() => {
  if (!requiresConfirmation) {
    return null
  }

  return props.message.data.initialModel
})

const functionName = computed<AiFunctions>(() => {
  if (!requiresConfirmation.value) {
    return null
  }

  return props.message.data.functionName
})

const actionFunction = computed(() => {
  if (!requiresConfirmation.value) {
    return null
  }

  return functions[functionName.value]
})

const actionDisplayName = computed(() => {
  if (!requiresConfirmation.value) {
    return null
  }

  return props.message.data?.actionDisplayName || props.message.data?.actionChoice || functionName.value
})

async function onConfirm() {
  userChoice.value = UserChoice.Confirm
  props.message.data.userChoice = UserChoice.Confirm
  props.message.data.bindingModel = bindingModel.value
  props.message.data.bulkBindingModel = bulkBindingModel.value

  isExecutingAction.value = true
  executingLocalAction.value = true

  try {
    const model = actionModel.value
    const result = await store.dispatch(`ai/${functionName.value}`, model)

    if (result.id || result.ids) {
      // success
      addChatMessage({
        type: ChatMessageTypes.AssistantLocal,
        data: {
          actionResult: store.state.ai.actionResult
        },
        date: new Date(),
      })
    }
    else {
      // fail
      addChatMessage({
        type: ChatMessageTypes.AssistantLocal,
        message: i18n.t(`Action failed. Let me know if you would like to try again or need help with something else.`),
        date: new Date(),
      })
    }
  }
  catch(err) {
    addChatMessage({
      type: ChatMessageTypes.AssistantLocal,
      message: i18n.t(`Action failed. Let me know if you would like to try again or need help with something else.`),
      date: new Date(),
    })
  }
  finally {
    isExecutingAction.value = false
    requiresUserAction.value = false
    executingLocalAction.value = false
  }
}

function onRevise() {
  userChoice.value = UserChoice.Revise
  props.message.data.userChoice = UserChoice.Revise
  props.message.data.bindingModel = bindingModel.value
  props.message.data.bulkBindingModel = bulkBindingModel.value

  addChatMessage({
    type: ChatMessageTypes.AssistantLocal,
    message: i18n.t(`Let me know what you would like to change.`),
    date: new Date(),
  })

  requiresUserAction.value = false
}

async function onCancel() {
  userChoice.value = UserChoice.Cancel
  props.message.data.userChoice = UserChoice.Cancel
  props.message.data.bindingModel = bindingModel.value
  props.message.data.bulkBindingModel = bulkBindingModel.value

  store.dispatch('ai/resetActionMode')

  requiresUserAction.value = false
}

type BindingModel = Record<string, BindingResult | BindingResult[]>

const bindingModel = ref<BindingModel>(props.message.data?.bindingModel || {})
const bulkBindingModel = ref<BindingModel[]>(props.message.data?.bulkBindingModel || [])

const hasBindingIssues = computed(() => {
  if (Array.isArray(initialModel.value)) {
    return bulkBindingModel.value.some(model => {
      return Object.keys(model).some(key => {
        const value = model[key]
        if (Array.isArray(value)) {
          return value.some((v: any) => !v.success)
        }
        return !value.success
      })
    })
  }

  return Object.keys(bindingModel.value).some(key => {
    const value = bindingModel.value[key]
    if (Array.isArray(value)) {
      return value.some((v: any) => !v.success)
    }
    return !value.success
  })
})

function buildObjectModel(binding: BindingModel) {
  const model: Record<string, any> = {}
  for (let key in binding) {
    const bindingResult = binding[key] as BindingResult | BindingResult[]
    if (Array.isArray(bindingResult)) {
      model[key] = bindingResult.filter(br => br.success).map(br => br.value)
    }
    else if (bindingResult.success) {
      model[key] = bindingResult.value
    }
  }
  return model
}

const actionModel = computed(() => {
  if (Array.isArray(initialModel.value)) {
    return bulkBindingModel.value.map(buildObjectModel)
  }

  return buildObjectModel(bindingModel.value)
})

function discardBindingResult({ property, result, idx = 0 }: { property: string, result: BindingResult, idx: number }) {
  if (Array.isArray(initialModel.value)) {
    if (Array.isArray(bulkBindingModel.value[idx][property])) {
      let bindingArray = bulkBindingModel.value[idx][property] as BindingResult[]

      bindingArray = bindingArray.filter(r => r.query !== result.query)

      if (bindingArray.length === 0) {
        delete bulkBindingModel.value[idx][property]
      }
      else {
        bulkBindingModel.value[idx][property] = bindingArray
      }
    }
    else {
      delete bulkBindingModel.value[idx][property]
    }
    return
  }
 
  if (Array.isArray(bindingModel.value[property])) {
    let bindingArray = bindingModel.value[property] as BindingResult[]

    bindingArray = bindingArray.filter(r => r.query !== result.query)

    if (bindingArray.length === 0) {
      delete bindingModel.value[property]
    }
    else {
      bindingModel.value[property] = bindingArray
    }
  }
  else {
    delete bindingModel.value[property]
  }
}

function resolveBindingResult({
  property,
  result,
  value,
  query,
  idx
}: {
  property: string,
  result: BindingResult,
  value: any,
  query: string,
  idx: number
}) {
  if (Array.isArray(initialModel.value)) {

    if (Array.isArray(bulkBindingModel.value[idx][property])) {

      const bindingArray = bulkBindingModel.value[idx][property] as BindingResult[]

      const idx2 = bindingArray.findIndex((r: any) => r.query === result.query)
      bindingArray[idx2].value = value
      bindingArray[idx2].success = true
      bindingArray[idx2].query = query
      bindingArray[idx2].error = ''
    }
    else {
      const bindingObj = bulkBindingModel.value[idx][property] as BindingResult
      bindingObj.value = value
      bindingObj.success = true
      bindingObj.query = query
      bindingObj.error = ''
    }
    return
  }

  if (Array.isArray(bindingModel.value[property])) {
    const bindingArray = bindingModel.value[property] as BindingResult[]

    const idx = bindingArray.findIndex((r: any) => r.query === result.query)
    bindingArray[idx].value = value
    bindingArray[idx].success = true
    bindingArray[idx].query = query
    bindingArray[idx].error = ''
  }
  else {
    const bindingObj = bindingModel.value[property] as BindingResult
    bindingObj.value = value
    bindingObj.success = true
    bindingObj.query = query
    bindingObj.error = ''
  }
}

function getBindingResult(bindingDefinition: BindingDefinition, query: string, modelBindingResult: Record<string, BindingResult | BindingResult[]>) {
  const extraParamsArray: string[] = bindingDefinition.extraParams || [] as string[]
  
  const extraParams: Record<string, any> = {}

  for (let p of extraParamsArray) {
    extraParams[p] = (modelBindingResult[p] as BindingResult)?.value || ''
  }

  const result = DataBinder.bind(bindingDefinition.type, query, extraParams)
  result.label = bindingDefinition.label
  result.clearable = !bindingDefinition.required
  return result
}

function getModelBinding(model: any, mapping: Record<string, BindingDefinition>) {
  const modelBindingResult: Record<string, BindingResult | BindingResult[]>= {}
  const keys = Object.keys(model)

  keys.sort((k1, k2) => {
    return (mapping[k1].order || 0) - (mapping[k2].order || 0)
  })

  for (let key of keys) {
    const bindingDefinition = mapping[key] as BindingDefinition
    const query = model[key]

    if (bindingDefinition.multiple) {
      if (!query.length) {
        continue
      }

      modelBindingResult[bindingDefinition.property] = []

      query.forEach((q: string) => {
        const result = getBindingResult(bindingDefinition, q, modelBindingResult)
        // @ts-ignore
        modelBindingResult[bindingDefinition.property].push(result)
      })
    }
    else {
      if (!query) {
        continue
      }

      const result = getBindingResult(bindingDefinition, query, modelBindingResult)

      modelBindingResult[bindingDefinition.property] = result
    }
  }

  return modelBindingResult
}

function buildBindingModel() {
  const mapping = actionFunction.value?.formattedModelBinding
  if (!mapping) {
    return
  }

  if (Array.isArray(initialModel.value)) {
    bulkBindingModel.value = initialModel.value.map((model: any) => getModelBinding(model, mapping))
  }
  else {
    bindingModel.value = getModelBinding(initialModel.value, mapping)
  }
}

function init() {
  if (!userChoice.value) {
    requiresUserAction.value = true
  }

  if (!props.message.data.bindingModel && !props.message.data.bulkBindingModel) {
    buildBindingModel()
  }
}

onMounted(() => {
  init()
})
</script>
