<template>
  <div class="flex flex-col h-full">
    <TopFilters
      :columns="activeColumns"
      :available-filters="['filter', 'sort', 'columns']"
      :searchPlaceholder="$t('Search groups...')"
    >
      <template
        v-if="can($actions.CREATE_GROUPS)"
        #action
      >
        <BaseDropdown
          :split-button="true"
          :options="createGroupOptions"
          class="create-options-dropdown"
        >
          <router-link to="/groups/add">
            <BaseButton
              block
            >
              {{ $t('New Group') }}
            </BaseButton>
          </router-link>
        </BaseDropdown>
      </template>
    </TopFilters>

    <router-view />
  </div>
</template>
<script lang="ts" setup>
import i18n from "@/i18n";
import { computed  } from "vue";
import { importEntityTypes } from "@/modules/accounts/utils/importUtils";

import TopFilters from "@/modules/filters/components/TopFilters.vue";

import useCan from "@/modules/common/composables/useCan"
import { useStore } from "vuex";
const store = useStore()
const {
  can,
  actions
} = useCan()

const activeColumns = computed(() => {
  return store.getters['groups/activeColumns'] || []
})

const createGroupOptions = computed(() => {
  return [
    {
      label: i18n.t('Import Groups'),
      action: () => {
        store.commit('setEntityCreateParams', {
          importType: importEntityTypes.Groups,
        })

        store.commit('accounts/triggerDataImport')
      },
      enabled: can(actions.MANAGE_ACCOUNT_SETTINGS),
    },
  ]
})
</script>
