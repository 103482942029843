<template>
  <BaseTableCell :params="params">
    <BaseBadge
      v-if="status"
      :color="status?.attributes?.color"
      class="my-0"
    >
      {{ status?.attributes?.name }}
    </BaseBadge>
    <span v-else>- -</span>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { ICellRendererParams } from "@ag-grid-community/core";
import { PropType, computed } from "vue"
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams<any>>,
    default: () => ({})
  },
})

const row = computed<any>(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const entityType = computed<string>(() => {
  return props.params.colDef?.cellRendererParams?.target || props.params?.target || 'tasks'
})

const status_id = computed<string | number>(() => {
  return row.value?.attributes?.status_id
})

const taskStatuses = computed(() => {
  return store.getters['tasks/orderedStatuses'] || []
})

const projectStatuses = computed(() => {
  return store.getters['projects/orderedStatuses'] || []
})

const status = computed(() => {
  if (entityType.value === 'projects') {
    return projectStatuses.value.find((status: any) => status?.id?.toString() === status_id.value?.toString())
  }

  return taskStatuses.value.find((status: any) => status?.id?.toString() === status_id.value?.toString())
})
</script>
