<template>
  <div class="flex justify-between">
    <BaseDropdown
      v-if="options?.length"
      :options="options"
      @action="onAction"
    >
      <button
        type="button"
        class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 sm:mt-0 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none z-10"
      >
        {{ $t('Options') }}
        <i class="fas fa-chevron-down mt-1 ml-2"></i>
      </button>

      <template #option="{ option }">
        <i v-if="option.icon" :class="`${option.icon} mr-2`" />
        {{ option.label }}
      </template>
    </BaseDropdown>
    <BaseTooltip
      :content="$t(`Some required fields (*) don't have a value`)"
      :disabled="!disabled"
    >
      <BaseButton
        variant="primary"
        :disabled="disabled"
        @click="$emit('createAndClose')"
      >
        {{ $t('Create & Close') }}
      </BaseButton>
    </BaseTooltip>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash-es'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: [String, Number],
      default: '',
    }
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('Cancel'),
          action: 'cancel',
          variant: 'white',
          class: 'mr-auto',
          alwaysEnabled: true,
        },
        {
          label: this.$t('Create & New'),
          action: 'createAndNew',
          variant: 'white',
          disabled: this.disabled
        },
        {
          label: this.$t('Create & Continue'),
          action: 'createAndContinue',
          variant: 'white',
          disabled: this.disabled
        },
      ].slice().reverse()
    },
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.task)
    },
  },
};
</script>
