<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-4xl font-extrabold">
      {{ $t(`Account defaults`) }}
    </h2>
    <p class="text-gray-500 leading-7 text-lg">
      {{ $t(`Set your account defaults so projects, tasks and notes start with your preferred status & privacy settings when they are first created.`) }}
    </p>

    <Account
      ref="accountDefaultsForm"
      :customFields="fields"
      inputLayout="vertical"
      lightForm
      :hasActions="false"
      isDialogForm
      slim
      silentSync
    />

    <div class="flex space-x-2 items-center mt-4">
      <router-link
        to="/account-setup-wizard/task-statuses"
        class="rounded-md bg-white px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-gray-500 border border-gray-200"
      >
        <i class="fa-solid fa-arrow-left mr-2" aria-hidden="true"></i>
        {{ $t(`Back`) }}
      </router-link>
      <button
        type="button"
        class="rounded-md body-background px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-white"
        @click="onSubmit"
      >
        {{ $t(`Next`) }}
        <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Account from "@/modules/accounts/pages/account.vue";
import { nextTick, ref } from "vue";

import { useRouter } from "vue-router";

const router = useRouter()

const fields = [
  'default_project_status',
  'default_project_privacy',
  'default_task_status',
  'default_task_privacy',
  'default_note_privacy',
  'default_payment_currency',
]

const accountDefaultsForm = ref();

async function onSubmit() {
  accountDefaultsForm.value?.onSubmit()

  await nextTick()
  router.push(`/account-setup-wizard/view-types`)
}
</script>
