// Libs
import i18n from "@/i18n.js"
import { computed } from "vue"

// Helpers
import { getEntityTarget } from "@/modules/common/utils/filterUtils"
import { notificationStatuses } from '@/modules/accounts/utils/notificationUtils.js'

// Composables
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import useCan from "@/modules/common/composables/useCan";
import useMobileUtils from "@/modules/common/composables/useMobileUtils"

export function useEntityViewTypes() {
  const store = useStore()
  const { can, actions } = useCan()
  const route = useRoute()

  const { isMobileDevice } = useMobileUtils()
  
  const target = computed(() => {
    return getEntityTarget(route)
  })

  const isTemplatePath = computed(() => {
    return store.getters['templates/isTemplatePath']
  })

  const projectId = computed(() => {
    return store.getters.project_id
  })

  function getPath(pathToAppend: string, isProjectEntity = false) {
    if (isProjectEntity && projectId.value) {
      const parentEntity = isTemplatePath.value
        ? 'templates'
        : 'projects'

      return `/${parentEntity}/${projectId.value}${pathToAppend}`
    }

    return pathToAppend
  }

  const projects = computed(() => {
    return [
      {
        name: i18n.t('List'),
        path: getPath(`/${target.value}/list`),
        isActive: route.path === `/${target.value}/list`,
        icon: 'fa-list-check',
        showIf: () => {
          return can(actions.ACCESS_PROJECTS_LIST)
        }
      },
      {
        name: i18n.t('Cards'),
        path: getPath(`/${target.value}/card`),
        isActive: route.path === `/${target.value}/card`,
        icon: 'fa-grid-2',
        showIf: () => {
          return can(actions.ACCESS_PROJECTS_CARD)
        }
      },
      {
        name: i18n.t('Calendar'),
        path: getPath(`/${target.value}/calendar`),
        isActive: route.path === `/${target.value}/calendar`,
        icon: 'fa-calendar-days',
        showIf: () => {
          return !isTemplatePath.value && can(actions.ACCESS_PROJECTS_CALENDAR) && !isMobileDevice.value
        }
      },
      {
        name: i18n.t('Scheduler'),
        path: getPath(`/${target.value}/scheduler`),
        isActive: route.path === `/${target.value}/scheduler`,
        icon: 'fa-chart-simple-horizontal',
        showIf: () => {
          return !isTemplatePath.value && can(actions.ACCESS_PROJECTS_CALENDAR) && !isMobileDevice.value
        }
      },
      {
        name: i18n.t('Kanban'),
        path: getPath(`/${target.value}/kanban`),
        isActive: route.path === `/${target.value}/kanban`,
        icon: 'fa-objects-column',
        showIf: () => {
          return !isTemplatePath.value && can(actions.ACCESS_PROJECTS_KANBAN)
        }
      },
      {
        name: i18n.t('Gallery'),
        action: () => {
          store.commit('templateGallery/triggerDialogOpen')
        },
        icon: 'fa-rectangle-vertical-history',
        isNewFeature: true,
        showIf: () => {
          return isTemplatePath.value
        }
      }
    ]
  })

  const tasks = computed(() => {
    return [
      {
        name: i18n.t('List'),
        path: getPath('/tasks/list', true),
        isActive: route.path.includes('/tasks/list'),
        icon: 'fa-list-check',
        showIf: () => {
          return can(actions.ACCESS_TASKS_LIST) && !isMobileDevice.value
        }
      },
      {
        name: i18n.t('Cards'),
        path: getPath(`/tasks/card`, true),
        isActive:  route.path.includes('/tasks/card'),
        icon: 'fa-grid-2',
        showIf: () => {
          return can(actions.ACCESS_TASKS_LIST) // && isMobileDevice.value
        }
      },
      {
        name: i18n.t('Calendar'),
        path: getPath('/tasks/calendar', true),
        isActive: route.path.includes('/tasks/calendar'),
        icon: 'fa-calendar-days',
        showIf: () => {
          return can(actions.ACCESS_TASKS_CALENDAR) && !isMobileDevice.value
        }
      },
      {
        name: i18n.t('Scheduler'),
        path: getPath('/tasks/scheduler', true),
        isActive: route.path.includes('/tasks/scheduler'),
        icon: 'fa-chart-simple-horizontal',
        showIf: () => {
          return can(actions.ACCESS_TASKS_SCHEDULER) && !isMobileDevice.value
        }
      },
      {
        name: i18n.t('Kanban'),
        path: getPath('/tasks/kanban', true),
        isActive: route.path.includes('/tasks/kanban'),
        icon: 'fa-objects-column',
        showIf: () => {
          return can(actions.ACCESS_TASKS_KANBAN)
        }
      }
    ]
  })

  const timeEntityTypes = computed(() => {
    return [
      {
        name: i18n.t('Actual'),
        path: getPath('/time/actual', true),
        icon: 'fa-stopwatch',
        isActive: route.path.includes('/time/actual'),
        showIf: () => {
          return can(actions.VIEW_TIME_ENTRIES) && !isTemplatePath.value
        },
      },
      {
        name: i18n.t('Allocated'),
        path: getPath('/time/allocated', true),
        icon: 'fa-calendar-plus',
        isActive: route.path.includes('/time/allocated'),
        showIf: () => {
          return can(actions.VIEW_TIME_ENTRIES)
        },
      },
      {
        name: i18n.t('All'),
        path: getPath('/time/all', true),
        icon: 'fa-calendar-clock',
        isActive: route.path.includes('/time/all') && !route.path.includes('/time/allocated'),
        showIf: () => {
          return can(actions.VIEW_TIME_ENTRIES) && !isTemplatePath.value
        },
      },
    ]
  })

  const timeEntriesViewOptions = computed(() => {
    return [
      {
        name: i18n.t('List'),
        path: getPath('/time/actual/list', true),
        isActive: route.path.includes('/time/actual/list'),
        icon: 'fa-list-check',
      },
      {
        name: i18n.t('Cards'),
        path: getPath(`/time/actual/card`, true),
        isActive:  route.path.includes('/time/actual/card'),
        icon: 'fa-grid-2',
      },
    ]
  })

  const allocatedTimeViewOptions = computed(() => {
    return [
      {
        name: i18n.t('List'),
        path: getPath('/time/allocated/list', true),
        isActive: route.path.includes('/time/allocated/list'),
        icon: 'fa-list-check',
      },
      {
        name: i18n.t('Cards'),
        path: getPath(`/time/allocated/card`, true),
        isActive:  route.path.includes('/time/allocated/card'),
        icon: 'fa-grid-2',
      },
    ]
  })

  
  function getInboxPath(queryParams?: any) {
    let basePath = '/inbox'
    let query = {
      ...route.query,
    }

    delete query.status

    if (queryParams) {
      query = {
        ...queryParams,
        ...query,
      }
    }

    return {
      path: basePath,
      query,
    }
  }

  const inbox = computed(() => {
    return [
      {
        name: i18n.t('Unread'),
        icon: 'fa-envelope',
        isActive: route.query.status === notificationStatuses.Unread,
        path: getInboxPath({ status: notificationStatuses.Unread }),
      },
      {
        name: i18n.t('Read'),
        icon: 'fa-envelope-open',
        isActive: route.query.status === notificationStatuses.Read,
        path: getInboxPath({ status: notificationStatuses.Read }),
      },
      {
        name: i18n.t('Pinned'),
        icon: 'fa-thumbtack',
        isActive: route.query.status === notificationStatuses.Pinned,
        path: getInboxPath({ status: notificationStatuses.Pinned }),
      },
      {
        name: i18n.t('All'),
        icon: 'fa-bell',
        isActive: !route.query.status,
        path: getInboxPath(),
      },
    ]
  })

  const users = computed(() => {
    return [
      {
        name: i18n.t('List'),
        path: getPath('/users/list'),
        isActive: route.path.includes('/users/list'),
        icon: 'fa-list-check',
      },
      {
        name: i18n.t('Cards'),
        path: getPath(`/users/card`),
        isActive:  route.path.includes('/users/card'),
        icon: 'fa-grid-2',
      },
    ]
  })

  const groups = computed(() => {
    return [
      {
        name: i18n.t('List'),
        path: getPath('/groups/list'),
        isActive: route.path.includes('/groups/list'),
        icon: 'fa-list-check',
      },
      {
        name: i18n.t('Cards'),
        path: getPath(`/groups/card`),
        isActive:  route.path.includes('/groups/card'),
        icon: 'fa-grid-2',
      },
    ]
  })

  const payments = computed(() => {
    return [
      {
        name: i18n.t('List'),
        path: getPath('/payments/list', true),
        isActive: route.path.includes('/payments/list'),
        icon: 'fa-list-check',
      },
      {
        name: i18n.t('Cards'),
        path: getPath(`/payments/card`, true),
        isActive:  route.path.includes('/payments/card'),
        icon: 'fa-grid-2',
      },
    ]
  })

  const files = computed(() => {
    return [
      {
        name: i18n.t('Tree View'),
        path: getPath('/files/list', true),
        isActive: route.path.includes('/files/list'),
        icon: 'fa-list-tree',
      },
      {
        name: i18n.t('Cards'),
        path: getPath(`/files/card`, true),
        isActive:  route.path.includes('/files/card'),
        icon: 'fa-grid-2',
      },
    ]
  })

  const customViewTypes = computed<{ [key: string]: Array<any> }>(() => {
    return {
      projects: projects.value,
      tasks: tasks.value,
      templates: projects.value,
      'time-entries': timeEntriesViewOptions.value,
      'allocated-time': allocatedTimeViewOptions.value,
      notifications: inbox.value,
      users: users.value,
      groups: groups.value,
      payments: payments.value,
      files: files.value,
    }
  })


  const entityTypeSelect = computed<{ [key: string]: Array<any> }>(() => {
    return {
      'time-entries': timeEntityTypes.value,
      'allocated-time': timeEntityTypes.value,
      'all-time': timeEntityTypes.value,
    }
  })

  const defaultTargetViewTypes = computed(() => {
    return [
      {
        name: i18n.t('List'),
        path: '',
        isActive: true,
        icon: 'fa-list-check',
      }
    ]
  })


  function getTargetAvailableViewTypes(entityTarget: string) {
    const targetViewTypes = (customViewTypes.value[entityTarget] || []).filter(viewType => !viewType.showIf || viewType.showIf())
    return targetViewTypes.length
      ? targetViewTypes
      : defaultTargetViewTypes.value
  }

  function getEntityAvailableViewTypes(entityType: string) {
    return (entityTypeSelect.value[entityType] || []).filter(viewType => !viewType.showIf || viewType.showIf())
  }

  const currentAvailableViewTypes = computed(() => {
    return getTargetAvailableViewTypes(target.value)
  })

  const currentEntityAvailableViewTypes = computed(() => {
    return getEntityAvailableViewTypes(target.value)
  })

  return {
    projects,
    tasks,
    currentAvailableViewTypes,
    currentEntityAvailableViewTypes
  }
}
