import { entityTypes } from '@/modules/common/enum/entityTypes.js';
import { EntityChangeEvent } from '@/modules/common/utils/entityUtils';
import { notify } from '@/components/common/NotificationPlugin';
import { useStore } from 'vuex';
import { useAuth } from "@/modules/auth/composables/useAuth";
import { useRouter } from 'vue-router';

import Data = API.Data
import User = App.Domains.Users.Models.User;

import {
  getFileWithRelationshipsAndPath,
  getFolderWithRelationshipsAndPath,
} from '@/modules/files/utils/fileTreeUtils'
import { computed } from 'vue';

type EntityTypes = keyof typeof entityTypes

export type EntityEvents = {
  event: EntityChangeEvent,
  entity_type: EntityTypes,
  entity_id: string | number,
  updated_by: string | number,
}

export default function useEntityEventHandlers() {
  const { currentUser } = useAuth()
  const store = useStore()
  const router = useRouter()

  const users = computed<Data<User>[]>(() => {
    return store.state.users.allUsers || []
  })

  const currentUserId = computed(() => {
    return Number(currentUser.value?.id)
  })

  const notificationsEnabled = true

  function showNotification(entityEvent: EntityEvents, notificationOptions: any) {
    if (!notificationsEnabled) {
      return
    }

    const {
      event,
      entity_type,
      updated_by
    } = entityEvent

    const updatedByUser = users.value.find(user => user.id == updated_by)
    if (!updatedByUser) {
      return
    }

    notify({
      message: `${updatedByUser.attributes.name} ${event}d ${entity_type} ${notificationOptions.entityDescription}`,
      ...(notificationOptions || {})
    })
  }
  
  function showTaskNotification(event: EntityEvents, task: any) {
    const allocated_ids = (task?.attributes?.allocated_ids || []).map(Number)
    const follower_ids = (task?.attributes?.follower_ids || []).map(Number)

    if (!allocated_ids.includes(currentUserId.value) && !follower_ids.includes(currentUserId.value)) {
      return
    }

    const notificationOptions = {
      entityDescription: task.attributes.name,
      onClick() {
        router.push(`/tasks/${task.id}`)
      }
    }

    showNotification(event, notificationOptions)
  }

  const taskHanders = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      const task = await store.dispatch('tasks/getTaskById', {
        id: event.entity_id,
        returnEntity: true,
        silentError: true
      })

      if (!task?.id) {
        return
      }
      
      store.dispatch('tasks/addTaskToArray', task)
      showTaskNotification(event, task)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      const task = await store.dispatch('tasks/getTaskById', {
        id: event.entity_id,
        returnEntity: true,
        silentError: true
      })

      if (!task?.id) {
        return
      }

      store.dispatch('tasks/updateTaskInArray', task)
      showTaskNotification(event, task)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      const task = {
        id: event.entity_id,
        type: 'tasks'
      }

      store.commit('tasks/deleteTask', { task })
    },
  }

  const projectHanders = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      const project = await store.dispatch('projects/getProjectById', {
        id: event.entity_id,
        returnEntity: true,
        silent: true
      })

      if (!project?.id) {
        return
      }

      store.commit('projects/addProject', project)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      const project = await store.dispatch('projects/getProjectById', {
        id: event.entity_id,
        returnEntity: true,
        silent: true
      })

      if (!project?.id) {
        return
      }

      store.commit('projects/updateProject', project)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('projects/deleteProject', { project: {
        id: event.entity_id,
        type: 'projects'
      } })

      store.commit('projects/deleteProject', event.entity_id )
    }
  }

  const fileHanders = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      let file = await store.dispatch('files/getFileById', { fileId: event.entity_id })
      if (!file?.id) {
        return
      }

      file = getFileWithRelationshipsAndPath(file)
      store.commit('files/addFile', file)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      let file = await store.dispatch('files/getFileById', { fileId: event.entity_id })
      if (!file?.id) {
        return
      }

      file = getFileWithRelationshipsAndPath(file)
      store.commit('files/updateFile', file)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('files/deleteFile', event.entity_id)
    }
  }

  const folderHanders = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      let folder = await store.dispatch('files/getFolderById', event.entity_id)
      if (!folder?.id) {
        return
      }

      folder = getFolderWithRelationshipsAndPath(folder)
      store.commit('files/addFolder', folder)
      store.dispatch('files/setFoldersTreeData')
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      let folder = await store.dispatch('files/getFolderById', event.entity_id)
      if (!folder?.id) {
        return
      }

      folder = getFolderWithRelationshipsAndPath(folder)
      store.commit('files/updateFolder', folder)
      store.dispatch('files/setFoldersTreeData')
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('files/deleteFolder', event.entity_id)
      store.dispatch('files/setFoldersTreeData')
    }
  }

  const userHandlers = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      const user = await store.dispatch('users/getUserById', {
        id: event.entity_id,
        returnEntity: true,
        includeArchived: true
      })

      if (!user?.id) {
        return
      }

      store.commit('users/addUser', user)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      const user = await store.dispatch('users/getUserById', {
        id: event.entity_id,
        returnEntity: true,
        includeArchived: true
      })

      if (!user?.id) {
        return
      }

      store.commit('users/updateUser', user)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('users/deleteUser', event.entity_id)
    }
  }

  const groupHandlers = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      const group = await store.dispatch('groups/getGroupById', { id: event.entity_id, returnEntity: true })
      if (!group?.id) {
        return
      }

      store.commit('groups/addGroup', group)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      const group = await store.dispatch('groups/getGroupById', { id: event.entity_id, returnEntity: true })
      if (!group?.id) {
        return
      }
      store.commit('groups/updateGroup', group)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('groups/deleteGroup', event.entity_id)
    }
  }

  const paymentHandlers = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      const payment = await store.dispatch('payments/getPaymentById', { id: event.entity_id, returnEntity: true })
      if (!payment?.id) {
        return
      }

      store.commit('payments/addPayment', payment)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      const payment = await store.dispatch('payments/getPaymentById', { id: event.entity_id, returnEntity: true })
      if (!payment?.id) {
        return
      }

      store.commit('payments/updatePayment', payment)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('payments/deletePayment', event.entity_id)
    }
  }


  const timeEntryHandlers = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      const timeEntry = await store.dispatch('time/getTimeEntryById', { id: event.entity_id, returnEntity: true })

      if (!timeEntry?.id) {
        return
      }

      store.commit('time/addTimeEntry', timeEntry)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      const timeEntry = await store.dispatch('time/getTimeEntryById', { id: event.entity_id, returnEntity: true })
      if (!timeEntry?.id) {
        return
      }

      store.commit('time/updateTimeEntry', timeEntry)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('time/deleteTimeEntry', event.entity_id)
    }
  }

  const allocatedTimeHandlers = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      const allocatedTime = await store.dispatch('time/getAllocatedTimeById', { id: event.entity_id, returnEntity: true })
      if (!allocatedTime?.id) {
        return
      }

      store.commit('time/addAllocatedTime', allocatedTime)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      const allocatedTime = await store.dispatch('time/getAllocatedTimeById', { id: event.entity_id, returnEntity: true })
      if (!allocatedTime?.id) {
        return
      }

      store.commit('time/updateAllocatedTime', allocatedTime)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('time/deleteAllocatedTime', event.entity_id)
    }
  }

  const customFieldHandlers = {
    [EntityChangeEvent.Create]: async (event: EntityEvents) => {
      const customField = await store.dispatch('accounts/getCustomFieldById', { id: event.entity_id, returnEntity: true })
      if (!customField?.id) {
        return
      }

      store.commit('accounts/addCustomField', customField)
    },
    [EntityChangeEvent.Update]: async (event: EntityEvents) => {
      const customField = await store.dispatch('accounts/getCustomFieldById', { id: event.entity_id, returnEntity: true })
      if (!customField?.id) {
        return
      }

      store.commit('accounts/updateCustomField', customField)
    },
    [EntityChangeEvent.Delete]: (event: EntityEvents) => {
      store.commit('accounts/deleteCustomField', { id: event.entity_id })
    }
  }

  const eventHandlers = {
    [entityTypes.Task]: taskHanders,
    [entityTypes.Project]: projectHanders,
    [entityTypes.Media]: fileHanders,
    [entityTypes.Folder]: folderHanders,
    [entityTypes.User]: userHandlers,
    [entityTypes.Group]: groupHandlers,
    [entityTypes.Payment]: paymentHandlers,
    [entityTypes.TimeEntry]: timeEntryHandlers,
    [entityTypes.AllocatedTime]: allocatedTimeHandlers,
    [entityTypes.CustomField]: customFieldHandlers,
  }

  async function handleEntityEvent(entityEvent: EntityEvents) {
    console.log('Incoming entity event', entityEvent)

    const {
      event,
      entity_type,
    } = entityEvent

    const handler = eventHandlers[entity_type]?.[event]

    if (!handler) {
      console.error(`No handler for entity event`, entityEvent)
      return
    }

    await handler(entityEvent)
  }

  return {
    handleEntityEvent,
  }
}
