import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import AuthService from "@/modules/auth/services/AuthService";
import config from "@/modules/common/config";

import store from "@/store";

import {
  DisplayProps,
  DisplayValue
} from "@/modules/ai/types/aiTypes";

export function userIdsToName(user_ids: Array<string | number> | undefined) {
  return (user_ids || []).map((userId: string | number) => {
    // @ts-ignore
    const find = store.state.users.allUsers.find((user: Record<any, any>) => {
      return +user.id === +userId;
    });
    return `${find?.attributes?.first_name} ${find?.attributes?.last_name}`
  }).join(', ')
}

export function groupIdsToName(group_ids: Array<string | number> | undefined) {
  return (group_ids || []).map((groupId: string | number) => {
    // @ts-ignore
    const find = store.state.groups.allGroups.find((group: Record<any, any>) => {
      return +group.id === +groupId;
    });
    return find?.attributes?.name
  }).join(', ')
}

export function projectStatusIdToName(status_id: string | number) {
  // @ts-ignore
  return store.state.projects.statuses.data
    .find((status: Record<any, any>) => +status.id === +status_id)
    ?.attributes
    ?.name;
}

export function taskStatusIdToName(status_id: string | number) {
  // @ts-ignore
  return store.state.tasks.statuses.data
    .find((status: Record<any, any>) => +status.id === +status_id)
    ?.attributes
    ?.name;
}

export function projectToolIdsToName(tool_ids: Array<string | number> | undefined) {
  return (tool_ids || []).map((toolId: string | number) => {
    // @ts-ignore
    return store.state.projects.tools.data
      .find((tool: Record<any, any>) => +tool.id === +toolId)
      ?.attributes
      ?.name;
  }).join(', ')
}


export function getProjectName(project_id: string | number) {
  // @ts-ignore
  return store.state.projects.allProjects.find((p: string | number) => +p.id === +project_id)
    ?.attributes
    ?.name;
}

export function getTaskName(parent_id: string | number) {
  // @ts-ignore
  return store.state.tasks.allTasks.find((t: string | number) => +t.id === +parent_id)
    ?.attributes
    ?.name;
}

export function getDisplayValuesArray(model: Record<string, any>, displayProps: DisplayProps) {
  const displayValues: DisplayValue[] = []

  Object.keys(displayProps).forEach((key) => {
    let displayValue = model[key]
    if (!displayValue) {
      return
    }
    
    if (displayProps[key].transformer) {
      displayValue = displayProps[key].transformer?.(displayValue)
    }

    if (!displayValue) {
      return
    }

    displayValues.push({
      label: displayProps[key].label,
      displayValue
    })
  })

  return displayValues
}

export function strJoin(strs: string[]) {
  return strs.join(', ')
}

export type AiStreamedMessageEvents = {
  onNewContent?: (content: string) => void
  onDone?: () => void
}

export async function aiStreamedMessage(body: any, events: AiStreamedMessageEvents, endpoint: string = 'gpt-prompt') {
  const apiUrl = `${config.getApiHost()}/${endpoint}`
  const token = AuthService.getToken();
  let requestController = new AbortController();

  let description = ''


  function getNewContent(event: EventSourceMessage) {
    try {
      if (event.data === '[DONE]') {
        return '[DONE]'
      }
  
      const data = JSON.parse(event.data)
      const content = data?.choices[0]?.delta?.content || ''
  
      return content
    } catch (err) {
      console.log('Error parsing data from BE', err)
      console.warn(err)
    }
  }

  await fetchEventSource(apiUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    signal: requestController.signal,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${token}`
    },
    onmessage: async (event) => {
      let newContent = getNewContent(event)
      if (!newContent) {
        return
      }

    
      if (newContent === '[DONE]') {
        events.onDone?.()
        return
      }

      events.onNewContent?.(newContent)
    },
  })

  return description

}
