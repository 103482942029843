import { h } from 'vue'
import { get } from 'lodash-es'
import { SetupContext } from '@vue/runtime-core'
import { tableCellComponents } from "@/components/table/cells/tableCellComponents";

interface columnTypesParams {
  slots: SetupContext['slots']
  emit: Function
  props: Readonly<any>
}

export function getColumnTypes({ slots, emit, props }: columnTypesParams) {
  const componentCells: any = {}

  for (const key in tableCellComponents) {
    const component = tableCellComponents[key]
    componentCells[key] = {
      cellRenderer: component
    }
  }

  return {
    ...componentCells,
    custom: {
      cellRenderer: {
        name: 'CustomCell',
        render(props: any) {
          const field = props.params.colDef.field
          const fieldScopedSlot = slots[field]
          if (!fieldScopedSlot) {
            return h('div', get(props.params.data, field))
          }
          
          return fieldScopedSlot({
            row: props.params.data,
            index: props.params.rowIndex,
            params: props.params,
          })
        },
      },
    },
  }
}
