<template>
  <BaseSelect
    v-bind="$attrs"
    :options="options"
    :labelPrefix="$t('Editing dashboard for:')"
    :returnObject="false"
  />
</template>
<script lang="ts" setup>
import { computed } from "vue";
import i18n from '@/i18n'
import {
  UserTypes,
} from "@/modules/users/util/userUtils";

const options = computed(() => {
  return [
    {
      value: UserTypes.Self,
      label: i18n.t('Myself'),
    },
    {
      value: UserTypes.Collaborators,
      label: i18n.t('All Collaborators'),
    },
    {
      value: UserTypes.Creators,
      label: i18n.t('All Creators'),
    },
  ]
})
</script>
