import { getFilterPresetPriority, PROJECT_OPTIONS } from "@/modules/common/utils/filterUtils"
import axios from "axios"
import { sortBy } from "lodash-es"

const state = () => ({
  columns: [],
  filters: {}, // of type "<target>": { api: [], local: {} }
  columnStates: {}, // of type "<target>_<inside_project (true/false)>": ColumnState[]
  filterPresets: [],
  filterPresetsLoading: false,
  filterPresetsLoaded: false,
  localFiltersChanged: 1
})

const mutations = {
  setLocalFilters(state, { target, value }) {
    state.filters[target] = {
      ...state.filters[target],
      local: value
    }
  },
  setApiFilters(state, { target, value }) {
    state.filters[target] = {
      ...state.filters[target],
      api: value
    }
  },
  setFilterPresets(state, value) {
    state.filterPresets = value
  },
  addFilterPreset(state, value) {
    state.filterPresets.push(value)
  },
  setFilterPresetsLoading(state, value) {
    state.filterPresetsLoading = value
  },
  removeFilterPreset(state, filterPresetId) {
    state.filterPresets = state.filterPresets.filter(x => x.id !== filterPresetId)
  },
  setFilterPresetsLoaded(state, value) {
    state.filterPresetsLoaded = value
  },
  triggerLocalFiltersChanged(state) {
    state.localFiltersChanged++
  },
  saveTargetColumnState(state, { target, isInsideProject, columnState }) {
    const targetKey = `${target}_${isInsideProject}`
    state.columnStates[targetKey] = columnState
  }
}

const actions = {
  async getFilterPresets({ commit }) {
    try {
      commit("setFilterPresetsLoading", true)
      const filterPresets = await axios.get('/restify/filter-presets', {
        params: {
          perPage: 1000
        }
      })
      commit("setFilterPresets", filterPresets.data)
      commit('setFilterPresetsLoaded', true)
    }
    catch (err) {
      if (err.handled) {
        return;
      }
      else throw err
    }
    finally {
      commit("setFilterPresetsLoading", false)
    }
  },
  async createOrUpdateFilterPreset({ state, commit, dispatch }, data) {
    try {
      const existingFilterPreset = state.filterPresets.find(filterPreset => {
        const targetMatches = filterPreset.attributes.target === data.target;
        const projectScopeMatches = filterPreset.attributes.apply_to_projects === data.apply_to_projects
        const usersScopeMatches = filterPreset.attributes.apply_to_users === data.apply_to_users
        const viewTypeMatches = filterPreset.attributes.view_type === data.view_type
        const userIdsMatches = JSON.stringify(filterPreset.attributes.user_ids) === JSON.stringify(data.user_ids)
        const projectIdsMatches = JSON.stringify(filterPreset.attributes.project_ids) === JSON.stringify(data.project_ids)

        return targetMatches
          && projectScopeMatches
          && usersScopeMatches
          && viewTypeMatches
          && userIdsMatches
          && projectIdsMatches
      })

      let result = null
      if (!existingFilterPreset) {
        result = await axios.post('/restify/filter-presets', data)
      }
      else {
        result = await axios.put(`/restify/filter-presets/${existingFilterPreset.id}`, data)
      }

      await dispatch('getFilterPresets')
      commit("setFilterPresetsLoading", false)

      return result.data
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
    finally {
      commit("setFilterPresetsLoading", false)
    }
  },
  async deleteFilterPreset({ commit }, filterPresetId) {
    try {
      commit("setFilterPresetsLoading", true)
      await axios.delete(`/restify/filter-presets/${filterPresetId}`)
      commit('removeFilterPreset', filterPresetId)
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
    finally {
      commit("setFilterPresetsLoading", false)
    }
  }
}

const getters = {
  targetApiFilters: (state) => (target) => {
    return state.filters[target]?.api || []
  },
  targetLocalFilters: (state) => (target) => {
    return state.filters[target]?.local || {}
  },
  defaultTargetViewTypeFilterPresets: (state) => (target, view_type, project_id) => {
    return state.filterPresets.filter(preset => {

      switch (preset.attributes.apply_to_projects) {
        case PROJECT_OPTIONS.NONE:
          if (project_id) return false
          break;
        case PROJECT_OPTIONS.SPECIFIC:
          if (!preset.attributes.project_ids.includes(project_id))
            return false
          break;
        case PROJECT_OPTIONS.ALL:
          if (!project_id) return false
          break;
      }

      return preset.attributes.target === target && preset.attributes.view_type === view_type
    })
  },
  sortedDefaultTargetViewTypeFilterPresets: (state, getters) => (target, view_type, project_id) => {
    const filterPresets = getters.defaultTargetViewTypeFilterPresets(target, view_type, project_id)
    return sortBy(filterPresets, getFilterPresetPriority)
  },
  targetColumnState: (state) => (target, isInsideProject) => {
    const targetKey = `${target}_${isInsideProject}`
    return state.columnStates[targetKey]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
