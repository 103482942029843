<template>
  <button
      :class="buttonClassName"
      :disabled="disabled"
      v-bind="$attrs"
  >
    <slot></slot>
  </button>
</template>

<script setup>
import { computed, toRefs } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'secondary', 'tertiary', 'quaternary', 'ghost'].includes(value),
  },
  active: Boolean,
  activeClassname: String,
  buttonSize: {
    type: String,
    default: 'medium',
    validator: (value) => ['medium', 'small', 'icon', 'iconSmall'].includes(value),
  },
  disabled: Boolean,
});

const buttonClassName = computed(() => {
  const baseClass = 'flex group items-center justify-center border border-transparent gap-2 text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap';
  const variantClasses = {
    primary: `text-white bg-black border-black dark:text-black dark:bg-white dark:border-white ${!props.disabled && !props.active && 'hover:bg-neutral-800 active:bg-neutral-900 dark:hover:bg-neutral-200 dark:active:bg-neutral-300'} ${props.active && `bg-neutral-900 dark:bg-neutral-300 ${props.activeClassname}`}`,
    secondary: `text-neutral-900 dark:text-white ${!props.disabled && !props.active && 'hover:bg-neutral-100 active:bg-neutral-200 dark:hover:bg-neutral-900 dark:active:bg-neutral-800'} ${props.active && 'bg-neutral-200 dark:bg-neutral-800'}`,
    tertiary: `bg-neutral-50 text-neutral-900 dark:bg-neutral-900 dark:text-white dark:border-neutral-900 ${!props.disabled && !props.active && 'hover:bg-neutral-100 active:bg-neutral-200 dark:hover:bg-neutral-800 dark:active:bg-neutral-700'} ${props.active && `bg-neutral-200 dark:bg-neutral-800 ${props.activeClassname}`}`,
    ghost: `bg-transparent border-transparent text-neutral-500 dark:text-neutral-400 ${!props.disabled && !props.active && 'hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200'} ${props.active && `bg-black/10 text-neutral-800 dark:bg-white/20 dark:text-neutral-200 ${props.activeClassname}`}`,
  };
  const sizeClasses = {
    medium: 'py-2 px-3',
    small: 'py-1 px-2',
    icon: 'w-8 h-8',
    iconSmall: 'w-6 h-6 px-1.5',
  };

  return [baseClass, variantClasses[props.variant], sizeClasses[props.buttonSize]].filter(Boolean).join(' ');
});
</script>
