
import i18n from "@/i18n.js";
import { RRule } from "rrule";
import { getOrdinalOptions } from "@/modules/common/utils/numberUtils";
import { getDateWithoutTime } from "@/modules/common/utils/dateUtils.js";

export const frequencyOptions = [
  {
    label: i18n.t("Daily"),
    value: RRule.DAILY,
  },
  {
    label: i18n.t("Weekly"),
    value: RRule.WEEKLY,
  },
  {
    label: i18n.t("Monthly"),
    value: RRule.MONTHLY,
  },
  {
    label: i18n.t("Yearly"),
    value: RRule.YEARLY,
  },
];

export const defaultFrequency = RRule.DAILY

export const getRecurringWeekDays = () => [
  {
    label: i18n.t("Monday"),
    value: RRule.MO.weekday,
  },
  {
    label: i18n.t("Tuesday"),
    value: RRule.TU.weekday,
  },
  {
    label: i18n.t("Wednesday"),
    value: RRule.WE.weekday,
  },
  {
    label: i18n.t("Thursday"),
    value: RRule.TH.weekday,
  },
  {
    label: i18n.t("Friday"),
    value: RRule.FR.weekday,
  },
  {
    label: i18n.t("Saturday"),
    value: RRule.SA.weekday,
  },
  {
    label: i18n.t("Sunday"),
    value: RRule.SU.weekday,
  },
];

export const getRecurringMonths = () => [
  {
    label: i18n.t("January"),
    value: 1,
  },
  {
    label: i18n.t("February"),
    value: 2,
  },
  {
    label: i18n.t("March"),
    value: 3,
  },
  {
    label: i18n.t("April"),
    value: 4,
  },
  {
    label: i18n.t("May"),
    value: 5,
  },
  {
    label: i18n.t("June"),
    value: 6,
  },
  {
    label: i18n.t("July"),
    value: 7,
  },
  {
    label: i18n.t("August"),
    value: 8,
  },
  {
    label: i18n.t("September"),
    value: 9,
  },
  {
    label: i18n.t("October"),
    value: 10,
  },
  {
    label: i18n.t("November"),
    value: 11,
  },
  {
    label: i18n.t("December"),
    value: 12,
  }
]

export const dayTypeOrdinalOptions = [...getOrdinalOptions(6), {
  label: i18n.t("Last"),
  value: -1
}]
export const dateInMonthOrdinalOptions = [...getOrdinalOptions(32), {
  label: i18n.t("Last"),
  value: -1
}]

export const weekNumberOrdinalOptions = getOrdinalOptions(53)
export const yearDayOrdinalOptions = getOrdinalOptions(367)

export function getRRuleFromText(text) {
  return RRule.fromText(text)
}

export function getRRuleFromString(string) {
  return RRule.fromString(string)
}

export function getNextOccurrence(rrule) {
  if (!rrule) {
    return
  }

  let rruleInstance
  if (typeof rrule === 'string') {
    try {
      rruleInstance = getRRuleFromString(rrule)
    }
    catch { }
  }
  else {
    rruleInstance = rrule
  }

  if (!rruleInstance) {
    return
  }

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  
  return getDateWithoutTime(rruleInstance.after(yesterday)?.toISOString())
}

export function rruleToString(rrule) {
  let stringRrule = rrule.toString();
      
  if (stringRrule.startsWith('DTSTART')) {
    let [ dateStart, rruleString ] = stringRrule.split('\n');

    dateStart = dateStart.replace(':', '=')

    stringRrule = `${rruleString};${dateStart}`
  }

  return stringRrule
}
