export const Commands = {
  Convert: {
    name: 'convert',
    action: 'slashMenu',
    icon: 'fa-solid fa-list-dropdown',
  },
  Bold: {
    name: 'bold',
    action: 'toggleBold',
    icon: 'fa-solid fa-bold',
  },
  Italic: {
    name: 'italic',
    action: 'toggleItalic',
    icon: 'fa-solid fa-italic',
  },
  Strike: {
    name: 'strike',
    action: 'toggleStrike',
    icon: 'fa-solid fa-strikethrough',
  },
  Underline: {
    name: 'underline',
    action: 'toggleUnderline',
    icon: 'fa-solid fa-underline',
  },
  Highlight: {
    name: 'highlight',
    action: 'toggleHighlight',
    icon: 'fa-solid fa-highlighter',
    options: [
      { label: 'Yellow', value: '#fef08a', default: true },
      { label: 'Red', value: '#fecaca' },
      { label: 'Green', value: '#a7f3d0' },
      { label: 'Blue', value: '#bfdbfe' },
      { label: 'Purple', value: '#e9d5ff' },
      { label: 'Orange', value: '#fed7aa' },
      { label: 'Pink', value: '#fbcfe8' },
      { label: 'Gray', value: '#e5e7eb' },
    ],
  },
  Link: {
    name: 'link',
    action: 'toggleLink',
    icon: 'fa-solid fa-link',
  },
  Table: {
    name: 'table',
    action: 'tableMenu',
    icon: 'fa-solid fa-list-dropdown',
  },
  BulletList: {
    name: 'bulletList',
    action: 'toggleBulletList',
    icon: 'fa-solid fa-list-ul'
  },
  OrderedList: {
    name: 'orderedList',
    action: 'toggleOrderedList',
    icon: 'fa-solid fa-list-ol'
  },
  CodeBlock: {
    name: 'codeBlock',
    action: 'toggleCodeBlock',
    icon: 'fa-solid fa-code'
  },
};

export function getFlattenedNodes(node, allNodes = []) {
  if (node.content) {
    node.content.forEach(contentNode => {
      getFlattenedNodes(contentNode, allNodes)
    })
  } else {
    allNodes.push(node)
    return allNodes
  }
}

export function getFileNodes(json) {
  let flatNodes = []
  getFlattenedNodes(json, flatNodes)
  return flatNodes.filter(node => node.type === 'uploadingFile')
}

export function getMentionNodes(json) {
  let flatNodes = []
  getFlattenedNodes(json, flatNodes)
  return flatNodes.filter(node => node.type === 'customMention')
}

export function hasExtension(editor, extensionName) {
  return editor?.options?.extensions?.find(item => item.name === extensionName) !== undefined
}

export function getTextFromRange(editor, range) {
  const { $from, $to } = range || {}
  return editor.state?.doc?.textBetween($from?.pos, $to?.pos, ' ')
}
