<template>
  <div class="flex flex-col h-full">
    <TableCharts
      :grid-api="gridApi"
      :columns="tableColumns"
      default-chart-type="stackedColumn"
      formatter="currency"
      hasTotalsFooter
    />
    <BaseDataTable
      :data="data"
      :meta="meta"
      :columns="tableColumns"
      :loading="loading"
      :extraFilters="extraFilters"
      groupIncludeTotalFooter
      class="flex-1 min-h-[400px]"
      @first-data-rendered="onFirstDataRendered"
    >
      <template #no-data>
        <NoPayments />
      </template>
    </BaseDataTable>
  </div>
</template>
<script lang="ts" setup>
// Utils
import { computed, ref } from 'vue'
import { getExtraFilters } from "@/modules/payments/utils/paymentTableUtils"

// Components
import NoPayments from "@/modules/payments/components/NoPayments.vue";

// Composables
import { useStore } from "vuex"
import TableCharts from "@/components/table/charts/TableCharts.vue";
import { FirstDataRenderedEvent } from "@ag-grid-community/core";
const store = useStore()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const tableColumns = computed(() => {
  return store.getters['payments/tableColumns'] || []
})

const extraFilters = computed(() => {
  return getExtraFilters()
})

const meta = computed(() => {
  return store.state.payments.payments.meta || {}
})

const gridApi = ref()
function onFirstDataRendered(params: FirstDataRenderedEvent) {
  gridApi.value = params.api
}
</script>
