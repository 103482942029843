<template>
  <div
    class="animate-pulse w-full m-auto bg-white border-gray-200 border my-6"
    :class="{
      'rounded-lg shadow': !$useNewLayout,
    }"
  >
    <div class="flex items-center my-3">
      <div class="w-6 h-6 bg-gray-100 ml-6 mr-2 rounded-lg"/>
      <div class="bg-gray-100 h-6 w-1/3"/>
    </div>
    <div class="flex-1 flex flex-col truncate space-y-1 mx-6">
      <div class="bg-gray-100 h-5"/>
      <div class="bg-gray-100 h-5"/>
      <div class="bg-gray-100 h-5"/>
      <div class="bg-gray-100 h-5"/>
      <div class="bg-gray-100 h-5"/>
    </div>
    <div class="flex items-center my-3">
      <div class="w-24 h-8 bg-gray-100 mx-6 rounded-lg"/>
      <div class="w-24 h-8 bg-gray-100 rounded-lg"/>
    </div>
  </div>
</template>
<script setup />
