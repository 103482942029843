import store from "@/store"
import i18n from '@/i18n'
import { computed } from "vue";
import { decodeFilters, encodeFilters, getSystemDefaultEntityFilters } from '@/modules/common/utils/filterUtils';

export const taskPrivacyOptions = computed(() => {
  return [
    {
      label: i18n.t('Hidden from Collaborators'),
      value: visibilityTypes.CREATORS_ONLY,
      color: 'rgb(107, 114, 128)'
    },
    {
      label: i18n.t('Visible to Collaborators'),
      value: visibilityTypes.CREATORS_AND_COLLABORATORS,
      color: 'rgb(59, 130, 246)'
    },
  ]
})

export function getMyTasksFilterAppliedQuery(project_id = null, inside_project = false) {
  const viewOptions = store.getters['users/defaultTargetViewOptions']('tasks', inside_project)
  let entityFilters = store.getters['filters/sortedDefaultTargetViewTypeFilterPresets']('tasks', viewOptions.view_type, project_id) || []

  let query
  if (entityFilters.length) {
    query = JSON.parse(entityFilters[0].attributes.filter)
  }
  else {
    query = getSystemDefaultEntityFilters('tasks', viewOptions.view_type)
  }

  const myTasksFilter = {
    key: 'my-tasks',
    value: {
      show: viewOptions.view_group === 'my'
    }
  }

  if (!query?.filters) {
    query = {
      ...query,
      filters: encodeFilters([ myTasksFilter ])
    }
  }
  else {
    const decodedFilters = decodeFilters(query.filters)
    const myTasksIdx = decodedFilters.findIndex(filter => filter.key === 'my-tasks')

    if (myTasksIdx !== -1) {
      decodedFilters.splice(myTasksIdx, 1)
    }

    decodedFilters.push(myTasksFilter)

    query = {
      ...query,
      filters: encodeFilters(decodedFilters)
    }
  }

  return query
}

export function getCompletedTasksFilterAppliedQuery(project_id, inside_project = false) {
  const viewOptions = store.getters['users/defaultTargetViewOptions']('tasks', inside_project)
  const entityFilters = store.getters['filters/sortedDefaultTargetViewTypeFilterPresets']('tasks', viewOptions.view_type, project_id) || []

  let query
  if (entityFilters.length) {
    query = JSON.parse(entityFilters[0].attributes.filter)
  }
  else {
    query = getSystemDefaultEntityFilters('tasks', viewOptions.view_type)
  }

  const completedTasksFilter = {
    key: "completed",
    value: {
      show: true
    }
  }

  if (!query?.filters) {
    query = {
      ...query,
      filters: encodeFilters([ completedTasksFilter ])
    }
  }
  else {
    const decodedFilters = decodeFilters(query.filters)
    const completedTasksIdx = decodedFilters.findIndex(filter => filter.key === 'completed')

    if (completedTasksIdx !== -1) {
      decodedFilters.splice(completedTasksIdx, 1)
    }

    decodedFilters.push(completedTasksFilter)

    query = {
      ...query,
      filters: encodeFilters(decodedFilters)
    }
  }

  return query
}


export const taskDateTypes = {
  NO_DATE: 'no date',
  SINGLE_DATE: 'single date',
  DATE_RANGE: 'date range',
  RECURRING: 'recurring',
  DAYS_FROM_PROJECT_START: 'days from project start',
  WORKING_DAYS_FROM_PROJECT_START: 'working days from project start',
}

export const visibilityTypes = {
  CREATORS_ONLY: 'creators only',
  CREATORS_AND_COLLABORATORS: 'creators & collaborators',
}

export function getTaskDueDate(task) {
  if (!task?.attributes) {
    return null
  }

  switch (task.attributes.date_type) {
    case taskDateTypes.NO_DATE:
      return null
    case taskDateTypes.SINGLE_DATE:
      return task.attributes.date?.substring(0, 10) || null
    case taskDateTypes.DATE_RANGE:
      return task.attributes.end_date?.substring(0, 10) || null
    case taskDateTypes.RECURRING:
      return task.attributes.recurrence_date?.substring(0, 10) || null
    default:
      return null
  }
}

export function getTaskGroupingDate(task) {
  let value = null
  switch (task.attributes.date_type) {
    case taskDateTypes.NO_DATE:
      value = null
      break
    case taskDateTypes.SINGLE_DATE:
      value = task.attributes.date?.substring(0, 10) || null
      break
    case taskDateTypes.DATE_RANGE:
      const start = task.attributes.start_date?.substring(0, 10) || null
      const end = task.attributes.end_date?.substring(0, 10) || null
      value = start && end ? `${start}_${end}` : null
      break
    case taskDateTypes.RECURRING:
      value = task.attributes.recurrence_date?.substring(0, 10) || null
      break
    default:
      return null
  }

  return value
}

export function groupTasksByDate(tasks) {
  let groupedTasks = new Map()

  tasks.forEach(task => {
    let dateValue = getTaskGroupingDate(task)

    const dateType = task.attributes.date_type
    const groupKey = dateValue
      ? `${dateType}_${dateValue}`
      : null

    if (!groupedTasks.has(groupKey)) {
      groupedTasks.set(groupKey, [])
    }

    groupedTasks.get(groupKey).push(task)
  })

  return groupedTasks
}

export function isTaskClosed(task) {
  return store.getters['tasks/isTaskClosed'](task)
}
