<template>
  <div
    v-if="columnOptions?.length"
    class="relative lg:inline-block text-left mt-2 lg:mt-0"
  >
    <BaseDropdown
      :options="columnOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
    >
      <TopFilterChip
        :isActive="!!activeGroupColumns"
        :prependLabel="$t('Group')"
        :label="activeGroupColumns || $t('None')"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ $t('Group By') }}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import { orderBy } from 'lodash-es'
import TopFilterChip from "@/modules/filters/components/TopFilterChip.vue";
export default {
  components: {
    TopFilterChip,
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    defaultFilter: {
      type: Object,
      default: () => ([])
    },
    allowMultiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      groupBy: [],
    }
  },
  computed: {
    columnsInDropdown() {
      return this.columns.filter(c => c.group || c.enableRowGroup)
    },
    columnOptions() {
      return this.columnsInDropdown.map(column => ({
        label: column.name,
        action: (event) => this.toggleGroupBy(event, column),
        variant: this.groupBy.some(field => field === (column.prop || column.field))
          ? 'active'
          : '',
        disabled: this.disabled,
      }))
    },
    activeGroupColumns() {
      const active = this.columnsInDropdown.filter(column => this.groupBy.some(field => field === (column.prop || column.field)))
      return orderBy(active, (column) => this.groupBy.indexOf(column.prop || column.field), 'asc')
        .map(c => c.headerName || c.name)
        .join(', ')
    }
  },
  methods: {
    toggleGroupBy(event, column) {
      if (!this.allowMultiple || !event.shiftKey) {
        const newGroupBy = this.groupBy.find(field => field === (column.prop || column.field))
          ? []
          : [column.prop]

        this.groupBy = newGroupBy
        this.$emit('change', this.groupBy)
        return
      }

      const columnIndex = this.groupBy.findIndex(field => field === (column.prop || column.field))
      if (columnIndex !== -1) {
        this.groupBy.splice(columnIndex, 1)
      } else {
        this.groupBy.push(column.prop)
      }
      this.$emit('change', this.groupBy)
    }
  },
  watch: {
    defaultFilter: {
      handler() {
        let newGroupBy = this.defaultFilter || []

        if (!Array.isArray(newGroupBy)) {
          newGroupBy = [newGroupBy.prop || newGroupBy.field]
        }

        this.groupBy = newGroupBy
      },
      immediate: true
    }
  }
}
</script>
