<template>
  <CustomFieldsEntityForm
    v-model="model.custom_fields"
    :prependName="$attrs.prependName"
    :col-span="1"
    :textarea-rows="2"
    inputs-layout="vertical"
    entityType="time_entry"
    @update:modelValue="onUpdateCustomField"
  />
</template>
<script lang="ts" setup>
import { PropType, watch, ref } from 'vue'
import CustomFieldsEntityForm from '@/modules/common/components/CustomFieldsEntityForm.vue'

const emit = defineEmits(['update'])

const props = defineProps({
  timeEntry: {
    type: Object as PropType<Record<string, any> | null>,
    default: () => ({}),
  },
})

const model = ref()

function onUpdateCustomField(data: Record<string, any> | null) {
  emit('update', data)
}

watch(() => props.timeEntry, (value) => {
  model.value = value
}, { immediate: true })
</script>
