<template>
  <BaseTreeSelect
    v-bind="$attrs"
    :data="data"
    :clearable="clearable"
  />
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Data = API.Data
import Folder = App.Domains.Folders.Models.Folder;

const props = defineProps({
  projectId: {
    type: Number,
    default: null,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  isParentSelect: {
    type: Boolean,
    default: false,
  },
  childFolderId: {
    type: Number,
    default: null,
  },
})

const store = useStore();

function mapDisabled(item: Data<Folder>) {
  return {
    ...item,
    disabled: true,
    relationships: {
      ...item.relationships,
      children: (item.relationships?.children || []).map(mapDisabled),
    }
  }
}

function disableSubtree(treeData: Data<Folder>[], subtreeItemId: number): Data<Folder>[] {
  return treeData.map((item: Data<Folder>) => {
    if (item.id === subtreeItemId) {
      return mapDisabled(item)
    }

    return {
      ...item,
      relationships: {
        ...item.relationships,
        children: disableSubtree(item.relationships?.children || [], subtreeItemId),
      }
    }
  })
}

const data = computed(() => {
  const treeData = store.getters['files/foldersTreeData'](props.projectId)

  if (props.isParentSelect && props.childFolderId) {
    return disableSubtree(treeData, props.childFolderId)
  }

  return store.getters['files/foldersTreeData'](props.projectId)
})
</script>
