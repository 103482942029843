<template>
  <div class="space-y-2">
    <CustomFieldInlineEditInput
      v-for="customField of displayedFields"
      :key="customField.id"
      v-bind="$attrs"
      :disabled="disabled"
      :entityModel="project"
      :custom-field="customField"
      class="disabled-focus"
    />
    <div
      v-if="customFields.length > displayMaxCount"
      class="inline-block border-b border-dashed border-gray-300 cursor-pointer mt-2 text-xs text-gray-400 hover:border-gray-500 hover:text-gray-500"
      @click="showAll = !showAll"
    >
      {{ showAll ? $t('See less custom fields') : $t('See all custom fields') }}
    </div>
  </div>
</template>
<script lang="ts" setup>
// Components
import CustomFieldInlineEditInput from "@/modules/common/components/CustomFieldInlineEditInput.vue";

// Utils
import { computed, ref } from 'vue';

// Composables
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
  project: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const customFields = computed(() => {
  return store.getters['accounts/entityFormCustomFields']('project') || []
})

const showAll = ref(false)

const displayMaxCount = 2

const displayedFields = computed(() => {
  if (showAll.value) {
    return customFields.value
  }

  return customFields.value.slice(0, displayMaxCount)
})
</script>
