<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-2xl relative"
    @close="$emit('close')"
  >
    <BaseEntityForm
      :title="$t('Add/Remove Groups')"
      :isDialogForm="true"
      width-class="max-w-2xl"
    >
      <template #default="{ meta, errors }">
        <div class="flex space-x-2 w-full relative">
          <div class="w-full">
            <GroupSelectNew
              v-model="addGroupIds"
              :excludedOptions="userGroupIds"
              :createEntityParams="{
                enable: true,
                entityType: 'group',
                label: $t('Create New Group'),
              }"
              multiple
            />
          </div>
          <BaseButton
            :disabled="!addGroupIds?.length"
            :loading="addingGroups"
            @click="addGroups"
          >
            <div class="flex items-center space-x-2">
              <i class="fa fa-plus" />
              <span class="hidden sm:inline">{{ $t('Add') }}</span>
            </div>
          </BaseButton>
        </div>
        <div class="flow-root mt-6">
          <ul
            class="divide-y divide-gray-200"
          >
            <UserGroup
              v-for="group in userGroups"
              :key="group.id"
              :group="group"
              :user="user"
              class="py-2"
              @remove="removeGroup(group)"
            />
          </ul>
        </div>
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          @click="$emit('close')"
        >
          {{ $t("I'm done adding groups - Close") }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
// Components
import GroupSelectNew from '@/components/selects/GroupSelectNew.vue'
import UserGroup from '@/modules/users/components/UserGroup.vue'

// Utils
import i18n from '@/i18n';
import { PropType, computed, ref } from "vue";
import { error, success } from '@/components/common/NotificationPlugin';
import apiCache from '@/modules/common/utils/apiCache';

import Data = API.Data;
import User = App.Domains.Users.Models.User;
import Group = App.Domains.Groups.Models.Group;

// Composables
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  user: {
    type: Object as PropType<Data<User>>,
    default: () => ({}),
  },
})

const addGroupIds = ref<number[]>([])
const addingGroups = ref<boolean>(false)

async function addGroups() {
  let newGroupIds = addGroupIds.value.filter(id => !userGroupIds.value.includes(id))
      
  if (!newGroupIds.length) {
    error(i18n.t('User is already part of the selected groups'))
    return
  }

  newGroupIds = userGroupIds.value.concat(newGroupIds)

  addingGroups.value = true

  try {
    await store.dispatch('users/updateUserGroups', {
      user: props.user,
      groups: newGroupIds,
    })

    await store.dispatch('users/getUserById', {
      id: props.user.id,
      silent: true
    })
    addGroupIds.value = []
    apiCache.removeForEntity('groups')
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('Could not add groups to the user'))
  }
  finally {
    addingGroups.value = false
  }
}

async function removeGroup(group: Data<Group>) {
  let newGroupIds = userGroupIds.value.filter(id => id !== +group.id)

  try {
    await store.dispatch('users/updateUserGroups', {
      user: props.user,
      groups: newGroupIds,
    })

    await store.dispatch('users/getUserById', {
      id: props.user.id,
      silent: true
    })
    addGroupIds.value = []
    apiCache.removeForEntity('groups')
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('Could not add groups to the user'))
  }
  finally {
    addingGroups.value = false
  }
}

const userGroups = computed<Data<Group>[]>(() => {
  return props.user?.relationships?.groups || []
})

const userGroupIds = computed<number[]>(() => {
  return userGroups.value.map((group) => +group.id)
})
</script>
