import i18n from "@/i18n"
import { AccountPermissions } from "@/modules/common/composables/useCan";

export const TimeCreationMethods = {
  Timer: 'timer',
  Manual: 'manual',
  AllocateTime: 'allocate-time',
}

export const TimeCreationOptions = [
  {
    value: TimeCreationMethods.Timer,
    label: i18n.t('Timer'),
    limitedFeature: AccountPermissions.TaskTimer
  },
  {
    value: TimeCreationMethods.Manual,
    label: i18n.t('Manual'),
  },
]

export const AllTimeCreationOptions = [
  {
    value: TimeCreationMethods.AllocateTime,
    label: i18n.t('Allocate'),
  },
  ...TimeCreationOptions,
]

export const TimerStatusTypes = {
  Recording: 'recording',
}
