<template>
  <CustomFieldInput
    v-for="customField of displayedCustomFields"
    :key="customField.attributes.key"
    :prependName="$attrs.prependName"
    :custom-field="customField"
    :layout="inputsLayout"
    :colSpan="colSpan"
    :disabled="$attrs.disabled"
    :columnCount="columnCount"
    :textareaRows="textareaRows"
    :modelValue="getCustomFieldValue(customField.attributes.key)"
    :label-icon="labelIcon"
    :default-hidden-input="defaultHiddenInput"
    @update:modelValue="setCustomFieldValue(customField.attributes.key, $event)"
  />
</template>
<script>
import CustomFieldInput from "@/modules/common/components/CustomFieldInput.vue";
import {
  getCustomFieldValuesObject,
} from "@/modules/accounts/utils/modelUtils"

export default {
  components: {
    CustomFieldInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    inputsLayout: {
      type: String,
      default: "horizontal",
    },
    colSpan: {
      type: Number,
      default: 3,
    },
    columnCount: {
      type: Number,
      default: 4,
    },
    textareaRows: {
      type: Number,
      default: 4,
    },
    extraCustomFields: {
      type: Array,
      default: () => []
    },
    labelIcon: {
      type: Boolean,
      default: false
    },
    defaultHiddenInput: {
      type: Boolean,
      default: false
    },
    onlyRequiredFields: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    customFields() {
      const tableColumnCustomFields = this.$store.getters["accounts/entityFormCustomFields"](this.entityType, this.projectId)
      return [...tableColumnCustomFields, ...this.extraCustomFields]
    },
    customFieldValues() {
      return getCustomFieldValuesObject(this.modelValue)
    },
    displayedCustomFields() {
      if (!this.onlyRequiredFields) {
        return this.customFields
      }

      return this.customFields.filter(customField => customField.attributes?.rules?.includes('required') || !!this.getCustomFieldValue(customField?.attributes?.key))
    }
  },
  methods: {
    getCustomFieldValue(key) {
      return this.customFieldValues?.[key];
    },
    setCustomFieldValue(key, value) {
      const modelValue = {
        ...this.modelValue,
        [key]: value,
      };
      this.$emit("update:modelValue", modelValue);
    },
  },
};
</script>
