<template>
  <GroupsTable
    :key="+isLoading"
    :data="groups"
    :loading="isLoading"
    class="flex-1"
  />
</template>
<script lang="ts" setup>
import GroupsTable from "@/modules/groups/components/GroupsTable.vue";

import { computed, ref, watch } from "vue";

import { useStore } from "vuex";
const store = useStore()

const loading = computed(() => {
  return store.state.groups.groupsLoading ||
    store.state.projects.projectsLoading ||
    store.state.users.usersLoading
})

const isLoading = ref(true)

watch(() => loading.value, (value) => {
  isLoading.value = value
}, { immediate: true })

const groups = computed(() => {
  if (isLoading.value) {
    return []
  }

  return store.getters['groups/groupsData'] || []
})
</script>
