<template>
  <div
    v-if="!dismissed"
    class="rounded-md p-4"
    :class="{
      [bgClass]: bgClass
    }"
  >
    <div class="flex items-center">
      <div class="shrink-0">
        <slot name="icon">
          <i
            v-if="variant === 'success'"
            class="fas fa-circle-check text-lg"
            :class="{
              [iconClass]: iconClass
            }"
          />
          <ExclamationIcon
            v-else
            :class="`h-5 w-5 ${iconClass}`"
            aria-hidden="true"
          />
        </slot>
      </div>
      <div class="ml-3">
        <p
          class="text-sm font-medium"
          :class="{
            [textClass]: textClass
          }"
        >
          <slot>
            {{ message }}
          </slot>
        </p>
      </div>
      <div
        v-if="dismissable"
        class="ml-auto pl-3" 
      >
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="inline-flex rounded-md p-1.5  focus:outline-none focus:ring-2 focus:ring-offset-2"
            :class="{
              [buttonClass]: buttonClass
            }"
            @click="dismiss"
          >
            <span class="sr-only">{{ $t('Dismiss') }}</span>
            <XIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationIcon, XIcon } from '@heroicons/vue/solid'
import { computed, ref } from 'vue'

const props = defineProps({
  dismissable: {
    type: Boolean,
    default: true 
  },
  message: {
    type: String,
    default: ''
  },
  variant: {
    type: String,
    default: 'warning'
  }
})

const dismissed = ref(false)

const emit = defineEmits(['dismiss'])

const bgClasses: {[key: string]: string} = {
  success: 'bg-green-50',
  warning: 'bg-yellow-50',
  error: 'bg-red-50',
  info: 'bg-blue-50',
  primary: 'bg-primary-50',
}

const bgClass = computed(() => {
  return bgClasses[props.variant]
})

const textClasses: {[key: string]: string} = {
  success: 'text-green-700',
  warning: 'text-yellow-700',
  error: 'text-red-700',
  info: 'text-blue-700',
  primary: 'text-primary-700',
}

const textClass = computed(() => {
  return textClasses[props.variant]
})

const iconClasses: {[key: string]: string} = {
  success: 'text-green-400',
  warning: 'text-yellow-400',
  error: 'text-red-400',
  info: 'text-blue-400',
  primary: 'text-primary-500',
}

const iconClass = computed(() => {
  return iconClasses[props.variant]
})

const buttonClasses: {[key: string]: string} = {
  success: 'text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600',
  warning: 'text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600',
  error: 'text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600',
  info: 'text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600',
  primary: 'text-primary-500 hover:bg-primary-100 focus:ring-offset-primary-50 focus:ring-primary-600',
}

const buttonClass = computed(() => {
  return buttonClasses[props.variant]
})

function dismiss() {
  dismissed.value = true
  emit('dismiss')
}
</script>
