<template>
  <NodeViewWrapper
    :as="isInline ? 'span': 'div'"
    :draggable="isDraggable"
    :data-drag-handle="isDraggable"
    :class="{
      'max-w-[320px] my-4': !isInline,
    }"
    class="task-details-template"
  >
    <template v-if="isInline">
      <TaskTextRow
        v-if="task"
        :task="task"
        @click="showDialog = true"
      />
    </template>
    <template v-else>
      <TaskLoadingCard v-if="loading"/>
      <TaskCard
        v-else-if="!loading && task"
        :task="task"
        :hide-link="true"
        class="cursor-pointer"
        @click="showDialog = true"
      />
    </template>

    <TaskDialog
      v-if="showDialog"
      v-model="showDialog"
      :task="task"
      @save="onSave"
      @close="onClose"
    />
  </NodeViewWrapper>
</template>
<script>
import { cloneDeep } from 'lodash-es'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import TaskLoadingCard from "@/modules/tasks/components/TaskLoadingCard.vue";
import TaskCard from "@/modules/tasks/components/TaskCard.vue";
import TaskTextRow from "@/modules/tasks/components/TaskTextRow.vue";

export default {
  name: 'TaskTemplate',
  components: {
    TaskCard,
    TaskTextRow,
    NodeViewWrapper,
    TaskLoadingCard,
  },
  props: nodeViewProps,
  data() {
    return {
      url: this.node.attrs.url || null,
      loading: true,
      task: null,
      showDialog: false,
    }
  },
  computed: {
    taskId() {
      return this.node?.attrs?.id
    },
    isDraggable() {
      return this.node?.attrs?.isDraggable
    },
    isInline() {
      return this.node?.attrs?.inline
    },
  },
  methods: {
    async getTask() {
      if (!this.taskId) {
        this.loading = false
        return
      }
      this.task = await this.$store.dispatch('tasks/getTaskById', {
        id: this.taskId,
        silentError: true,
      })
      this.loading = false
    },
    onSave(task) {
      if (task.id) {
        this.task = cloneDeep(task)
      }
      this.showDialog = false
    },
    async onClose() {
      this.showDialog = false
      await this.getTask()
    }
  },
  async mounted() {
    await this.getTask()
  }
}
</script>
<style lang="scss">
.task-details-template {
  .initial-avatar,
  .base-avatar,
  .base-avatar img {
    @apply my-0;
  }
}
</style>
