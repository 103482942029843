@@ -0,0 +1,41 @@
<template>
  <BaseCardsView
    :target="EntityTargetTypes.GROUPS"
    :loading="groupsLoading"
    dataGetterPath="groups/groupsData"
    columnsGetter="groups/tableColumns"
  >
    <template #no-data>
      <NoGroups
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
    </template>
  </BaseCardsView>
</template>
<script lang="ts" setup>
// Components
import NoGroups from "@/modules/groups/components/NoGroups.vue";

// Libs
import { computed } from "vue";

// Utils
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'

// Composables
import { useStore } from "vuex";

const store = useStore()

const groupsLoading = computed(() => {
  return store.state.groups.groupsLoading
})
</script>
