<template>
  <table class="min-w-full table-auto">
    <thead class="hidden sm:table-header-group">
      <tr>
        <th
          v-for="column in columns"
          :key="column.name"
          class="bg-gray-50 text-left text-xs font-medium text-gray-500 whitespace-nowrap"
          :class="{
            [column.class]: column.class,
            [column.padding]: column.padding,
            [column.headerPadding]: column.headerPadding,
            'px-6 py-3': !(column.padding || column.headerPadding)
          }"
        >
          <div
            v-if="column.tableHeader"
            v-html="column.tableHeader"
          />
          <div
            v-else-if="isIconColumn(column)"
            :class="column.iconClass"
            @click="onIconColumnClicked(column)"
          >
            <BaseTooltip :content="column.tooltip">
              <i :class="`fas ${getColumnIcon(column)}`"/>
            </BaseTooltip>
          </div>
          <div v-else>
            {{ column.name }}
            <i
              v-if="column.editable"
              class="fa-solid fa-pen-to-square ml-1 text-gray-300 hover:text-gray-400 cursor-pointer"
              @click="onEditColumn(column)"
            />
          </div>
        </th>
      </tr>
    </thead>
    <draggable
      :modelValue="data"
      :animation="200"
      tag="tbody"
      class="divide-y divide-gray-200 relative"
      ghost-class="ghost-card"
      handle=".fa-grip-dots"
      :group="draggableGroup"
      item-key="id"
      :move="move"
      @update:modelValue="$emit('reorder', $event)"
      @change="$emit('drag-change', $event)"
      @end="onDragEnd"
    >
      <template #header>
        <div class="table-drop-overlay absolute inset-0 hidden place-items-center">
          <div class="absolute inset-0 bg-gray-100 opacity-60 z-10" />
          <div class="absolute inset-0 text-2xl text-gray-500 font-bold border-4 rounded-lg border-dashed backdrop-blur-[1px] flex items-center justify-center z-10">
            <div>
              <div class="flex justify-center mb-2">
                <i :class="`text-6xl far ${dropOverlayIcon}`" />
              </div>
              {{ dropOverlayMessage }}
            </div>
          </div>
        </div>
      </template>
      <template #item="{ element }">
        <tr
          :key="element.id"
          class="first-of-type:rounded-t-lg first-of-type:shadow-t sm:first-of-type:rounded-none sm:first-of-type:shadow-none bg-white block sm:table-row p-4 sm:px-0 sm:py-0 relative"
        >
          <td
            v-for="(column, index) in columns"
            :key="column.prop + element.id"
            class="block sm:table-cell text-sm text-gray-500"
            :class="getCellClasses(column)"
          >
            <div class="flex items-center">
              <div
                v-if="column.showCardLabel"
                class="font-bold mr-1 inline sm:hidden"
              >
                {{ column.name }}:
              </div>
              <slot :name="column.prop" :row="element" :column="column" :index="index">
                <template v-if="column.action">
                  <span></span>
                </template>
                <template v-else-if="column.prop === 'attributes.order'">
                  <div class="hidden sm:block m-auto shrink-0 text-lg font-medium text-gray-300 hover:text-gray-500">
                    <i class="fa-solid fa-grip-dots cursor-move" />
                  </div>
                </template>
                <template v-else-if="column.component">
                  <component
                    :is="column.component"
                    :column="column"
                    :row="element"
                    :params="column.params"
                  />
                </template>
                <template v-else>
                  {{ get(element, column.prop, '- -') || '- -' }}
                </template>
              </slot>
            </div>
          </td>
        </tr>
      </template>
      <template #footer>
        <TableLoadingRows
          v-if="loading && data.length === 0"
          :columns="columns"
          :rowCount="loadingRowCount"
          :getCellClasses="getCellClasses"
        />
          <tr v-if="!data || data.length === 0" class="bg-white block sm:table-row px-6 py-4 sm:px-0 sm:py-0">
            <td :colspan="columns.length"
                class="sm:px-6 sm:py-3 block sm:table-cell text-gray-500 text-center">
              <slot v-if="!loading"
                    name="no-data">
                <template>
                  {{$t('No data')}}
                </template>
              </slot>
            </td>
          </tr>
      </template>
    </draggable>
    <tfoot v-if="$slots['table-footer']">
      <tr class="bg-white block sm:table-row px-6 py-4 sm:px-0 sm:py-0 border-t border-gray-200">
        <td colspan="100%">
          <slot name="table-footer" />
        </td>
      </tr>
    </tfoot>
    <CustomFieldDialog
      v-if="showEditCustomFieldDialog"
      v-model="showEditCustomFieldDialog"
      :customField="customFieldToEdit"
      @save="showEditCustomFieldDialog = false"
      @close="showEditCustomFieldDialog = false"
    />
  </table>
</template>
<script>
import Status from "@/components/table/Status.vue";
import UserStatus from "@/components/table/UserStatus.vue";
import Count from "@/components/table/Count.vue";
import FormattedDate from "@/components/table/FormattedDate.vue";
import FormattedTime from "@/components/table/FormattedTime.vue";
import FormattedPrice from "@/components/table/FormattedPrice.vue";
import ProjectCell from "@/components/table/ProjectCell.vue";
import draggable from "vuedraggable/src/vuedraggable"
import TableLoadingRows from "@/components/table/TableLoadingRows.vue";
import CustomFieldDialog from "@/modules/accounts/components/CustomFieldDialog.vue";

export default {
  name: 'BaseTable',
  components: {
    Status,
    Count,
    UserStatus,
    draggable,
    FormattedDate,
    FormattedTime,
    FormattedPrice,
    ProjectCell,
    TableLoadingRows,
    CustomFieldDialog,
  },
  props: {
    loading: Boolean,
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    draggableGroup: {
      type: String,
      default: ''
    },
    move: {
      type: Function,
    },
    loadingRowCount: {
      type: Number,
      default: 10
    },
    groupBy: {
      type: Object,
      default: () => ({})
    },
    onDragEnd: {
      type: Function
    },
    sameGroupHovering: {
      type: Boolean,
      default: false
    },
  },
  emits: [
    'edit-column',
    'add-custom-field',
    'reorder',
    'drag-change'
  ],
  data() {
    return {
      showEditCustomFieldDialog: false,
      customFieldToEdit: null,
    }
  },
  computed: {
    dropOverlayMessage() {
      if (this.sameGroupHovering) {
        return this.$tc('drop to another group to change property', {
          propertyName: this.groupBy?.name
        })
      }

      return this.$tc("drop here to change property", {
        propertyName: this.groupBy?.name
      })
    },
    dropOverlayIcon() {
      if (['attributes.date', 'attributes.created_at'].includes(this.groupBy?.prop)) {
        return 'fa-calendar-alt'
      }

      if (['attributes.status_id', 'relationships.status.attributes.name'].includes(this.groupBy?.prop)) {
        return 'fa-stream'
      }

      if (this.groupBy?.customField) {
        return 'fa-sparkles'
      }

      return ''
    },
  },
  methods: {
    isIconColumn(column) {
      return column.name.split('::')?.[0] === 'icon'
    },
    getColumnIcon(column) {
      return column.name.split('::')?.[1]
    },
    getCellClasses(column) {
      return {
        [column.cellClass]: column.cellClass,
        [column.padding]: column.padding,
        'sm:px-6 sm:py-3': column.padding === undefined,
        'hidden': column.action
      }
    },
    onIconColumnClicked(column) {
      if (column.action) {
        this.$emit(column.action)
      }
    },
    onEditColumn(column) {
      if (!column.customField) {
        this.$emit('edit-column', column)
        return
      }

      this.customFieldToEdit = column.customField
      this.showEditCustomFieldDialog = true
    }
  }
}
</script>
<style lang="scss">
.drag-overlay-active {
  .table-drop-overlay {
    @apply grid;
  }
}
</style>
