<template>
  <BaseTooltip
    :content="$t('Select file from OneDrive')"
    :show-after="500"
  >
    <div @click="launchPicker">
      <slot>
        <button
          class="p-2 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
        >
          <i class="fas fa-clouds" />
        </button>
      </slot>
    </div>
  </BaseTooltip>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps(
  {
    editor: {
      type: Object,
      default: () => ({}),
    },
  }
)

const baseUrl = "https://onedrive.live.com/picker"

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
  });
}

async function loadMsScript() {
  return new Promise((resolve, reject) => {
    if (window.msal) {
      resolve(window.msal)
      return
    }

    const script = document.createElement('script');
    script.src = `https://alcdn.msauth.net/browser/2.19.0/js/msal-browser.min.js`
    script.id = 'dropboxjs';
    script.onload = () => {
      resolve(window.msal)
    };
    script.onerror = reject;
    document.body.appendChild(script);
  })
}

const cid = ref(null)

function processAuthResponse(response: any) {
  cid.value = response?.idTokenClaims?.oid?.replace(/-/g, "").slice(16)

  localStorage.setItem('one_drive_token_response', JSON.stringify(response))
  return response.accessToken
}

async function getToken() {
  if (localStorage.getItem('one_drive_token_response')) {
    console.log('returning from local storage')
    return processAuthResponse(JSON.parse(localStorage.getItem('one_drive_token_response') || ''))
  }

  const clientId = import.meta.env.VITE_ONE_DRIVE_CLIENT_ID || '5e1e4a72-55ec-45d8-aef1-0089b8dac9b9';
  const msalParams = {
    auth: {
      authority: "https://login.microsoftonline.com/consumers",
      clientId: clientId,
      redirectUri: 'http://localhost:3000'
    },
  }

  const msal = await loadMsScript() as any
  const app = new msal.PublicClientApplication(msalParams);

  let accessToken = "";

  const authParams = { scopes: ['OneDrive.ReadOnly'] };

  try {
    // see if we have already the idtoken saved
    const resp = await app.acquireTokenSilent(authParams);
    return processAuthResponse(resp);
  } catch (e) {

    // per examples we fall back to popup
    const resp = await app.loginPopup(authParams);
    console.log('resp - new ', resp)
    cid.value = resp?.idTokenClaims?.oid?.replace(/-/g, "").slice(16)
    app.setActiveAccount(resp.account);

    if (resp.idToken) {
      const resp2 = await app.acquireTokenSilent(authParams);
      return processAuthResponse(resp2)
    }
  }

  return accessToken;
}

const channelId = uuid();

function getParams() {
  console.log('getParams', channelId)
  return {
    sdk: "8.0",
    entry: {
      oneDrive: {
      }
    },
    authentication: {},
    messaging: {
      origin: window.location.href,
      channelId,
    },
    search: {
      enabled: true,
    },
    // typesAndSources: {
    //   mode: "all",
    //   pivots: {
    //     oneDrive: true,
    //     recent: true,
    //     sharedLibraries: true,
    //   },
    // },
    selection: {
      mode: "multiple",
    }
  }
}

const pickerWindow = ref<Window | null>(null)
const port = ref<MessagePort | null>(null)


async function launchPicker(e: Event) {
  e.preventDefault();

  console.log('launchPicker', channelId)

  pickerWindow.value = window.open("", "Picker", "width=1500,height=800")

  const authToken = await getToken();
  console.log('authToken', authToken)

  const params = getParams();
  console.log({
    params,
    cid: cid.value,
  })
  const queryString = new URLSearchParams({
    filePicker: JSON.stringify(params),
    cid: cid.value || '',
  });

  const url = `${baseUrl}?${queryString}`;
  if (!pickerWindow.value) {
    console.error("Could not open picker window...");
    return;
  }

  const form = pickerWindow.value.document.createElement("form");
  form.setAttribute("action", url);
  form.setAttribute("method", "POST");
  pickerWindow.value.document.body.append(form);

  const input = pickerWindow.value.document.createElement("input");
  input.setAttribute("type", "hidden")
  input.setAttribute("name", "access_token");
  input.setAttribute("value", authToken);
  form.appendChild(input);

  form.submit();

  window.addEventListener("message", (event) => {
    if (!event.source || event.source !== pickerWindow.value) {
      return;
    }

    const message = event.data;

    if (message.type === "initialize" && message.channelId === params.messaging.channelId) {
      port.value = event.ports[0];

      port.value.addEventListener("message", messageListener);

      port.value.start();

      port.value.postMessage({
          type: "activate",
      });
    }
  });
}

async function processCommad(message: MessageEvent) {
  port.value?.postMessage({
    type: "acknowledge",
    id: message.data.id,
  });

  const command = message.data.data;

  enum Commands {
    Authenticate = 'authenticate',
    Close = 'close',
    Pick = 'pick',
  }

  switch(command.command) {
    case Commands.Authenticate:
      const token = await getToken();
      if (typeof token !== "undefined" && token !== null) {
        port.value?.postMessage({
          type: "result",
          id: message.data.id,
          data: {
            result: "token",
            token,
          }
        });
      } else {
        console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
      }
      break;
    case Commands.Close:
      pickerWindow.value?.close();
      break;
    case Commands.Pick:
      console.log(`Picked:`, {
        command,
        message,
      });

      addFiles(command.items)

      port.value?.postMessage({
        type: "result",
        id: message.data.id,
        data: {
          result: "success",
        },
      });

      pickerWindow.value?.close();
      break;
    default:
      console.warn(`Unsupported command: ${JSON.stringify(command)}`, 2);
      port.value?.postMessage({
        result: "error",
        error: {
          code: "unsupportedCommand",
          message: command.command
        },
        isExpected: true,
      });
      break;
  }
}

async function messageListener(message: MessageEvent) {
  switch (message.data.type) {
    case "notification":
      console.log(`notification: `, message.data);
      break;

    case "command":
      await processCommad(message);
      break;
  }
}

function prepareEditor() {
  return props.editor
    .chain()
    .focus()
}

function addFile(file: any) {
  prepareEditor().setExternalFile({
    url: file.webDavUrl,
    icon: file.icon,
    name: file.name,
    size: file.size,
  })
  .run()
}

function addFiles(files: any[]) {
  files.forEach(addFile)
}
</script>
