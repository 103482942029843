<template>
  <BaseTooltip
    v-bind="$attrs"
    tabindex="-1"
  >
    <i
      :class="{
        [$attrs.class]: $attrs.class,
        'left-1': !$attrs.class?.includes('left-'),
      }
      "
      class="fas fa-info-circle relative bottom-1 text-gray-300 hover:text-gray-500 cursor-pointer"
    />

    <template #content>
      <div
        class="max-w-[14rem]"
        v-html="content"
      />

      <BaseTutorialLink
        v-if="tutorialName"
        :name="tutorialName"
      >
        <span class="underline">{{ tutorialLabel }}</span>
      </BaseTutorialLink>
    </template>
  </BaseTooltip>
</template>
<script>
import i18n from '@/i18n'

export default {
  name: 'BaseIconTooltip',
  props: {
    content: {
      type: String,
      default: ''
    },
    tutorialName: {
      type: String,
      default: ''
    },
    tutorialLabel: {
      type: String,
      default: i18n.t('Learn more')
    }
  }
}
</script>
