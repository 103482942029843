<template>
  <div class="bg-white border border-gray-200 rounded-lg shadow py-2 px-1 w-[320px] max-h-[320px] overflow-y-auto">
    <div class="flex items-center py-1 cursor-pointer w-full h-full">
      <div class="result-item" @click="triggerProjectPeopleDialog">
        <div
          class="rounded-md uppercase bg-primary-500 flex items-center justify-center tracking-wider leading-6 font-normal text-white h-7 w-7 text-xs"
          tabindex="0">
          <i class="fa-solid fa-plus"></i>
        </div>
        <span class="ml-2 truncate text-primary-500">
          {{ $t('Add people to project') }}
        </span>
      </div>
    </div>
    <template v-if="items.length">
      <MentionItem
        v-for="(user, index) in items"
        :user="user"
        :key="index"
        :class="{ 'is-selected': index === selectedIndex }"
        @click="selectItem(index)"
      />
    </template>
    <div v-else class="result-item text-sm text-gray-700">
      {{ $t('No results') }}
    </div>
  </div>
</template>

<script>
import MentionItem from "@/components/html/extensions/components/MentionItem.vue";

export default {
  components: {
    MentionItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
    hidePopup: {
      type: Function,
    },
    projectId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      selectedIndex: 0,
    }
  },
  methods: {
    getName(user) {
      const { name, email, first_name, last_name } = user.attributes
      return name || first_name || last_name || email
    },
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }
      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }
      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },
    enterHandler() {
      this.selectItem(this.selectedIndex)
    },
    triggerProjectPeopleDialog() {
      if (this.hidePopup) {
        this.hidePopup()
      }

      this.$store.commit('setEntityCreateParams', {
        projectId: this.projectId,
      })
      this.$store.commit('projects/triggerProjectPeople', )
    },
    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ id: item.id, label: this.getName(item) })
      }
    },
    async scrollToSelectedItem() {
      await this.$nextTick();
      const selectedItem = this.$el.querySelector('.result-item.is-selected');
      if (!selectedItem) {
        return
      }
      selectedItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  },
  watch: {
    items() {
      this.selectedIndex = 0
    },
    async selectedIndex() {
      await this.scrollToSelectedItem()
    }
  },
}
</script>

<style scoped>
.result-item {
  @apply flex items-center hover:bg-gray-100 py-1 w-full space-x-2 px-2 rounded cursor-pointer;
}

.result-item.is-selected {
  @apply bg-gray-100;
}
</style>
