<template>
  <TemplateOptionsDropdown
    v-if="isTemplate"
    :template="project"
    :slim="slim"
  >
    <template #default>
      <slot />
    </template>
  </TemplateOptionsDropdown>
  <BaseDropdown
    v-else
    :options="availableOptions"
    :slim="slim"
    divide-items
    @action="onAction"
  >
    <template #default>
      <slot />
    </template>
  </BaseDropdown>
</template>
<script>
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import TemplateOptionsDropdown from "@/modules/templates/components/TemplateOptionsDropdown.vue"
import i18n from "@/i18n";

export default {
  components: {
    TemplateOptionsDropdown
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      maxActiveProjectsLimitReached,
      accountLimits,
    } = useAccountLimits()
    return {
      accountLimits,
      maxActiveProjectsLimitReached,
    }
  },
  computed: {
    isTemplate() {
      return this.project?.attributes?.is_template
    },
    orderedProjectStatuses() {
      return this.$store.getters['projects/orderedStatuses'] || []
    },
    completedStatus() {
      return this.orderedProjectStatuses[this.orderedProjectStatuses.length - 1]
    },
    isProjectClosed() {
      return this.project?.attributes?.status_id == this.completedStatus?.id
    },
    options() {
       return [
        {
          label: i18n.t('Open'),
          path: `/projects/${this.project.id}`
        },
        {
          label: i18n.t('Duplicate Project'),
          action: 'duplicateProject',
          disabled: this.maxActiveProjectsLimitReached,
          tooltip: this.maxActiveProjectsLimitReached
            ? i18n.tc('account limit reached', {
                limit: 'active projects',
                maxCount: this.accountLimits.projects.available,
              })
            : i18n.t("Duplicate this project and all its data"),
          requiresPermissionTo: this.$actions.DUPLICATE_PROJECTS
        },
        {
          label: i18n.t('Convert to Template'),
          action: 'convertToTemplate',
          requiresPermissionTo: this.$actions.CREATE_PROJECT_TEMPLATES
        },
        {
          label: i18n.t('Reopen'),
          action: 'changeProjectState',
          variant: 'white',
          disabled: this.maxActiveProjectsLimitReached,
          tooltip: this.maxActiveProjectsLimitReached
            ? i18n.tc('account limit reached', {
                limit: 'active projects',
                maxCount: this.accountLimits.projects.available,
              })
            : i18n.t('Reopen this project to enable editing'),
          enabled: () => {
            return this.isProjectClosed && this.can(this.$actions.EDIT_PROJECTS)
          }
        },
        {
          label: i18n.t('Complete Project'),
          action: 'changeProjectState',
          variant: 'white',
          tooltip: i18n.t('Mark this project as completed'),
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.CLOSE_PROJECTS)
          }
        },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.project)
    },
    async duplicateProject() {
      try {
        const project = await this.$store.dispatch('projects/duplicateProject', this.project.id)

        this.$success(i18n.t('Project duplicated successfully'))

        this.$store.commit('projects/setCurrentProject', null)
        await this.$nextTick()
        await this.$router.push(`/projects/${project.id}`)
      }
      catch (err) {
        if (err.handled) {
          return
        }

        this.$error(i18n.t('Could not duplicate project'))
      }
    },
    async convertToTemplate() {
      try {
        const template = await this.$store.dispatch('projects/convertToTemplate', this.project.id)

        this.$success(i18n.t('A new template based on this project was successfully created'))

        this.$store.commit('projects/setCurrentProject', null)
        await this.$nextTick()
        await this.$router.push(`/templates/${template.id}`)
      }
      catch (err) {
        if (err.handled) {
          return
        }

        this.$error(i18n.t('Could not convert project to template'))
      }
    },
    async changeProjectState() {
      let stateName = this.isProjectClosed ? 'active' : 'completed'

      if (stateName === 'completed') {
        this.$store.commit('setEntityCreateParams', {
          project: this.project,
        })

        this.$store.commit('projects/triggerCompleteProject')
        return;
      }
      try {
        const status = this.isProjectClosed
          ? this.orderedProjectStatuses[0]
          : this.completedStatus;

        await this.$store.dispatch('projects/changeProjectStatus', {
          project: this.project,
          status_id: status?.id
        })
        this.$success(i18n.t(`Project was set to ${stateName}`))
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t(`An error occurred while trying to set the project as ${stateName}`))
      }
    },
  },
};
</script>
