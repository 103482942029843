<template>
  <div class="text-gray-400 text-base">
    <div>
      <div
        v-for="option of initOptionsFiltered"
        :key="option.label"
        class="py-1"
        :class="{
          'cursor-pointer group hover:text-gray-500': !option.disabled,
          'opacity-50': option.disabled,
          'cursor-not-allowed': option.disabled,
        }"
        @click.stop="!option.disabled && option.action()"
      >
        <i
          class="w-6" 
          :class="{
            [option.icon]: option.icon,
            [option.iconHoverColor]: option.iconHoverColor,
          }"
        />
        {{ option.label }}
      </div>
    </div>

    <div v-if="toolOptionsFiltered?.length">
      <BaseTooltip
        :content="isTemplatePath ? $t('Name your template to allow adding tools...') : $t('Name your project to allow adding tools...')"
        placement="top-start"
        popperClass="hide-arrow"
        :disabled="project?.id"
      >
        <div
          class="font-bold text-xs mt-4"
          :class="{
            'opacity-50': !project?.id
          }"
        >
          {{ $t('Quick add tools:') }}
        </div>
        <div
          v-for="option of toolOptionsFiltered"
          :key="option.label"
          class="py-1"
          :class="{
            'cursor-pointer group hover:text-gray-500': !option.disabled,
            'opacity-50': option.disabled,
            'cursor-not-allowed': option.disabled,
          }"
          @click.stop="!option.disabled && !option.loading && addProjectTool(option.value)"
        >
          <div
            v-loading="option.loading"
            class="inline-block bg-transparent"
          >
            <i
              class="w-6"
              :class="{
                [option.icon]: option.icon,
                [option.iconHoverColor]: option.iconHoverColor,
              }"
            />
          </div>
          {{ option.label }}
        </div>
    </BaseTooltip>
    </div>
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n';
import { toolsEnum } from "@/modules/projects/utils/toolUtils.js";
import { PropType, computed, ref, watch } from 'vue';
import { warning } from '@/components/common/NotificationPlugin';
import Data = API.Data;
import Project = App.Domains.Projects.Models.Project;

// Composables
import { useStore } from 'vuex';
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from "@/modules/common/composables/useCan";
const {
  accountLimits,
  hasReachedLimit,
} = useAccountLimits()

const aiTokensLimitReached = computed(() => {
  return hasReachedLimit(AccountPermissions.AiAssistant)
})

const store = useStore()

type ToolKeys = keyof typeof toolsEnum
type ToolName = typeof toolsEnum[ToolKeys]

const props = defineProps({
  project: {
    type: Object as PropType<Data<Project>>,
    required: true,
  },
})

const emit = defineEmits([
  'init-default',
  'init-with-ai',
  'shortcuts-count-updated'
])

const initOptions = [
  {
    label: i18n.t(`Start with a blank project description`),
    action: () => emit('init-default'),
    icon: 'fa-regular fa-page',
    iconHoverColor: 'group-hover:text-gray-500',
    disabled: false,
    visible: true
  },
  {
    label: i18n.t(`Start writing with AI`),
    action: () => emit('init-with-ai'),
    icon: 'fa-duotone fa-wand-magic-sparkles',
    iconHoverColor: 'group-hover:text-primary-500',
    disabled: false,
    visible: () => {
      return !aiTokensLimitReached.value
    },
  },
]

const initOptionsFiltered = computed(() => {
  return initOptions.filter((option: any) => {
    if (typeof option.visible === 'function') {
      return option.visible()
    }

    return option.visible
  })
})

const isTemplatePath = computed(() => {
  return store.getters['templates/isTemplatePath']
})

const projectTools = computed(() => {
  return props.project?.relationships?.tools || []
})

function projectHasTool(toolName: ToolName) {
  return projectTools.value.some((tool: any) => tool.attributes?.name === toolName)
}

const toolDefinitions = computed(() => {
  return store.state.projects?.tools?.data || []
})

const addingTool = ref<{[key: ToolName]: boolean}>({})

const toolsLimitReached = computed(() => {
  return (hasReachedLimit(AccountPermissions.ProjectTools) as Function)(props.project)
})

async function addProjectTool(toolName: ToolName) {
  if (toolsLimitReached.value) {
    warning({
      message: i18n.t('tools limit reached short', { limit: accountLimits.value.toolsPerProject.available }),
      action: {
        path: '/account-settings/billing',
        text: 'Click here to go to your plans page.'
      }
    })
    return
  }

  addingTool.value[toolName] = true

  try {
    const tool = toolDefinitions.value.find((tool: any) => tool.attributes?.name === toolName)
    await store.dispatch('projects/addProjectTool', {
      tool,
      projectId: props.project?.id,
    })
  }
  finally {
    addingTool.value[toolName] = false
  }
}

const toolOptions = computed(() => ([
  {
    label: i18n.t(`Tasks`),
    value: toolsEnum.TASKS,
    icon: 'fa-regular fa-badge-check',
    iconHoverColor: 'group-hover:text-yellow-500',
    disabled: !props.project?.id,
    loading: addingTool.value[toolsEnum.TASKS],
  },
  {
    label: i18n.t(`Discussion`),
    value: toolsEnum.DISCUSSIONS,
    icon: 'fa-regular fa-message',
    iconHoverColor: 'group-hover:text-green-500',
    disabled: !props.project?.id,
    loading: addingTool.value[toolsEnum.DISCUSSIONS],
  },
  {
    label: i18n.t(`Files`),
    value: toolsEnum.FILES,
    icon: 'fa-regular fa-file',
    iconHoverColor: 'group-hover:text-blue-500',
    disabled: !props.project?.id,
    loading: addingTool.value[toolsEnum.FILES],
  },
  {
    label: i18n.t(`Notes`),
    value: toolsEnum.NOTES,
    icon: 'fa-regular fa-sticky-note',
    iconHoverColor: 'group-hover:text-primary-500',
    disabled: !props.project?.id,
    loading: addingTool.value[toolsEnum.NOTES],
  }
]))

const toolOptionsFiltered = computed(() => {
  return toolOptions.value.filter((option: any) => !projectHasTool(option.value))
})

watch(() => toolOptionsFiltered.value?.length, (value) => {
  emit('shortcuts-count-updated', value)
}, { immediate: true })
</script>
