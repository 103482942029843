<template>
  <BaseDialog
    v-model="visible"
    :hasBodyMargin="false"
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <GroupForm
      :isDialogForm="true"
      @cancel="visible = false"
      @save="onGroupCreated"
    />
  </BaseDialog>
</template>
<script>
import GroupForm from '@/modules/groups/components/GroupForm.vue'

export default {
  components: {
    GroupForm,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    onGroupCreated(group) {
      this.$emit('save', group);
      this.visible = false
    }
  }
}
</script>
<style>
</style>
