import projectLinksJson from "@/modules/resources/utils/linkTypes.json";
import i18n from "@/i18n"

export const projectLinkTypes = projectLinksJson.map((x, i) => ({
 ...x,
 id: i + 100,
 label: i18n.t(x.label),
 subtitle: i18n.t(x.subtitle),
 tutorial_link: i18n.t(x.tutorial_link),
 image: getProjectLinkImage(x) 
}))

export function getProjectLinkImage(projectLink) {
  if (typeof projectLink === 'string') {
    projectLink = projectLinkTypes.find(x => x.value === projectLink)
  }

  if (!projectLink || projectLink.icon) {
    return ''
  }

  return `/img/project_resources/${projectLink.value}.png`
}

export function getProjectLinkIcon(projectLink) {
  if (typeof projectLink === 'string') {
    projectLink = projectLinkTypes.find(x => x.value === projectLink)
  }

  if (!projectLink) {
    return 'fal fa-arrow-up-right-from-square'
  }

  return projectLink.icon || 'fal fa-arrow-up-right-from-square'
}

export function getProjectLinkLabel(projectLink, size = 48) {
  if (typeof projectLink === 'string') {
    projectLink = projectLinkTypes.find(x => x.value === projectLink)
  }
  return projectLink?.label || i18n.t('Link')
}
