import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { watch, onBeforeUnmount } from 'vue'

/**
 * Used to handle data fetching for list
 * Will automatically add watchers for pagination, filters and search
 */

const DEFAULTS = {
  IMMEDIATE: true,
  PAGE_MAPPING: 'page',
  PER_PAGE_MAPPING: 'perPage'
}

export default function useListFetch({
    action,
    immediate = DEFAULTS.IMMEDIATE,
    pageMapping = DEFAULTS.PAGE_MAPPING,
    perPageMapping = DEFAULTS.PER_PAGE_MAPPING
  }) {
  const route = useRoute()
  const router = useRouter()
  const store = useStore()

  if (!action) {
    return
  }

  let willUnMount = false
  
  async function fetchData(shouldReset = false) {
    let filters = {
      ...route.query
    }
    const newRoute = router.resolve({
      path: route.path,
      query: {
        ...filters
      }
    })

    await router.replace(newRoute)
    if (willUnMount) {
      return
    }
    const actionFilters = {
      ...filters,
      page: route.query[pageMapping],
      perPage: route.query[perPageMapping],
      shouldReset,
    }

    await store.dispatch(action, actionFilters)
  }

  const { filters, localFilters } = route.query

  // similar to triggering in created
  if (filters || localFilters || immediate) {
    fetchData(true).catch(err => {
      console.warn('Fetch error', err)
    })
  }

  watch(() => route.query[pageMapping], fetchData)
  watch(() => route.query[perPageMapping], fetchData)
  watch(() => route.query.search, fetchData)
  watch(() => route.query.filters, fetchData)

  onBeforeUnmount(() => {
    willUnMount = true
  })
  return {
    fetchData,
  }
}
