<template>
  <component
    :is="tag"
    class="flex-none whitespace-nowrap items-center px-2 py-0.5 rounded-md text-xs font-semibold capitalize badge"
    :style="{
      backgroundColor,
      color,
      border: `1px solid ${color}`
    }"
  >
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'BaseBadge',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    color: {
      type: String,
      default: 'rgb(107, 114, 128)'
    }
  },
  computed: {
    backgroundColor() {
      const color = this.color || 'rgb(107, 114, 128)'
      if (color.startsWith('#')) {
        return `${color}0D`
      }

      let colorWithOpacity = color.replace(')', ', 0.05)')
      
      if (!colorWithOpacity.includes('rgba')) {
        colorWithOpacity = colorWithOpacity.replace('rgb', 'rgba')
      }

      return colorWithOpacity
    }
  }
}
</script>
<style scoped>
.badge {
  min-width: 50px;
  min-height: 20px;
}
</style>
