import Echo from 'laravel-echo';
import config from '@/modules/common/config'
import AuthService from '@/modules/auth/services/AuthService';
import Pusher from 'pusher-js'

// Uncomment for Pusher logging in dev
// if (import.meta.env.DEV) {
//   Pusher.logToConsole = true
// }

let echoInstance = null
const echo = () => {
  const token = AuthService.getToken()
  if (echoInstance) {
    return echoInstance
  }
  const options =  {
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    authEndpoint: `${config.getBaseUrl()}/broadcasting/auth`,
    encrypted: true,
    enableStats: false,
    auth: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  }

  echoInstance = new Echo(options)

  return echoInstance
}

const PUSHER_SUBSCRIPTION_ERROR_EVENT = 'pusher:subscription_error'
const PUSHER_SUBSCRIPTION_SUCCEEDED_EVENT = 'pusher:subscription_succeeded'
const NOTIFICATION_CREATED_EVENT = `Illuminate\\Notifications\\Events\\BroadcastNotificationCreated`
const REACTION_ADDED_EVENT = 'client-REACTION_ADDED_EVENT'
const REACTION_REMOVED_EVENT = 'client-REACTION_REMOVED_EVENT'
const COMMENT_ADDED_EVENT = 'client-COMMENT_ADDED_EVENT'
const USER_IS_TYPING_EVENT = 'client-USER_IS_TYPING_EVENT'
const COMMENT_DELETED_EVENT = 'client-COMMENT_DELETED_EVENT'
const COMMENT_UPDATED_EVENT = 'client-COMMENT_UPDATED_EVENT'
const THREAD_COMMENT_ADDED_EVENT = 'client-THREAD_COMMENT_ADDED_EVENT'
const THREAD_COMMENT_DELETED_EVENT = 'client-THREAD_COMMENT_DELETED_EVENT'
const THREAD_COMMENT_UPDATED_EVENT = 'client-THREAD_COMMENT_UPDATED_EVENT'

const IS_TYPING_NOTIFICATION_TIMEOUT_MS = 2000

const getDiscussionChannel = (entityType, entityId) => {
  return `discussion-${entityType}-${entityId}`
}

const getUserChannel = (userId, tenantId)  => {
  return `user-${userId}-${tenantId}`;
}

const mapPusherComment = (data) => {
  return {
    id: data.id,
    attributes: {
      discussionable_type: data.discussionable_type,
      discussionable_id: data.discussionable_id,
      message: data.message,
      created_at: data.created_at,
      updated_at: data.created_at,
      creator: {
        id: data.user_id,
        name: data.user_name,
        avatar: data.user_avatar
      }
    }
  }
}

export default {
  echo,
  PUSHER_SUBSCRIPTION_ERROR_EVENT,
  PUSHER_SUBSCRIPTION_SUCCEEDED_EVENT,
  NOTIFICATION_CREATED_EVENT,
  COMMENT_ADDED_EVENT,
  USER_IS_TYPING_EVENT,
  COMMENT_DELETED_EVENT,
  COMMENT_UPDATED_EVENT,
  THREAD_COMMENT_ADDED_EVENT,
  THREAD_COMMENT_DELETED_EVENT,
  THREAD_COMMENT_UPDATED_EVENT,
  REACTION_ADDED_EVENT,
  REACTION_REMOVED_EVENT,
  IS_TYPING_NOTIFICATION_TIMEOUT_MS,
  getDiscussionChannel,
  getUserChannel,
  mapPusherComment
}
