<template>
  <BaseTableCell :params="params">
    <PaymentStatusChip
      :payment="row"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
// Utils
import { computed } from "vue"

// Components
import PaymentStatusChip from "@/modules/payments/components/PaymentStatusChip.vue";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed<any>(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})
</script>
