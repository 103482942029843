<template>
  <div class="flex -space-x-1 min-w-max groups-list">
    <router-link
      v-for="group in groups"
      :to="`/groups/${group.id}`"
      :key="group.id"
      class="flex items-center space-x-2"
    >
      <BaseLogo
        :entity="group"
        logo-key="attributes.image"
      />
      <span class="group-title hidden">
        {{ group?.attributes?.name }}
      </span>
    </router-link>
    <span v-if="groups?.length === 0" class="text-xs no-groups flex items-center">
      {{ $t('No groups') }}  
    </span>
  </div>
</template>
<script>
export default {
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'md'
    },
  }
}
</script>
