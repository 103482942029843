import i18n from "@/i18n"
import store from "@/store"

import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";
import {
  ACCESS_PROJECTS_LIST,
  ACCESS_PROJECTS_CARD,
  ACCESS_PROJECTS_KANBAN,
  ACCESS_TEMPLATES_LIST,
  ACCESS_TEMPLATES_CARD,
  ACCESS_TASKS_LIST,
  ACCESS_TASKS_CALENDAR,
  ACCESS_TASKS_SCHEDULER,
  ACCESS_TASKS_KANBAN,
  ACCESS_PROJECTS_CALENDAR,
} from '@/modules/common/enum/actionsEnum'

import { getRoleRank, roleRanksEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";

import Data = API.Data;
import User = App.Domains.Users.Models.User;

export const roleProps = {
  [rolesEnum.COLLABORATOR]: {
    icon: 'fa-user',
    iconColor: '#0fb981',
    description: i18n.t("<span class='font-bold'>For clients or freelancers.</span> They can collaborate in discussions, see the due date of tasks & invite other people from their team. They can't edit tasks or view notes. They can only see projects they are invited to.")
  },
  [rolesEnum.COLLABORATOR_PLUS]: {
    icon: 'fa-user-plus',
    iconColor: '#0fb981',
    description: i18n.t("<span class='font-bold'>For clients or freelancers who need to create & edit tasks.</span> In addition to the Collaborator role they can create new tasks and open & edit existing tasks (that are shared with collaborators). They can only see projects they are invited to.")
  },
  [rolesEnum.CREATOR]: {
    icon: 'fa-user',
    iconColor: '#f6b141',
    description: i18n.t("<span class='font-bold'>For members of your team.</span> They can see all task views, create & edit tasks, view all account level projects, invite & edit people, use templates, view & create time entries, view & create payments."),
    isPaid: true
  },
  [rolesEnum.CREATOR_PLUS]: {
    icon: 'fa-user-plus',
    iconColor: '#f6b141',
    description: i18n.t("<span class='font-bold'>For members of your team who need more control.</span> In addition to the Creator role they can create & manage custom fields, create & edit templates, create & delete groups, edit allocated time & delete time entries, delete payments."),
    isPaid: true
  },
  [rolesEnum.CREATOR_ADMIN]: {
    icon: 'fa-user-crown',
    iconColor: '#f6b141',
    description: i18n.t("<span class='font-bold'>For account administrators.</span> In addition to the Creator Plus role they can also view and edit all account settings."),
    isPaid: true
  }
}

export const DEFAULT_VIEW_TYPE_TARGETS = {
  PROJECTS: 'projects',
  TEMPLATES: 'templates',
  TASKS: 'tasks',
}

const VIEW_GROUPS = {
  MY: 'my',
  ALL: 'all'
}

export const VIEW_TYPES = {
  LIST: 'list',
  CALENDAR:'calendar',
  SCHEDULER: 'scheduler',
  KANBAN: 'kanban',
  CARD: 'card'
}

export const USER_PROFILE_DEFAULT_VIEW_OPTIONS = {
  [DEFAULT_VIEW_TYPE_TARGETS.PROJECTS]: {
    icon: 'fa-inbox',
    view_groups: [],
    view_types: [
      {
        type: VIEW_TYPES.LIST,
        label: i18n.t('List View'),
        requiresPermissionTo: ACCESS_PROJECTS_LIST
      },
      {
        type: VIEW_TYPES.CALENDAR,
        label: i18n.t('Calendar View'),
        requiresPermissionTo: ACCESS_PROJECTS_CALENDAR
      },
      {
        type: VIEW_TYPES.SCHEDULER,
        label: i18n.t('Scheduler View'),
        requiresPermissionTo: ACCESS_PROJECTS_CALENDAR
      },
      {
        type: VIEW_TYPES.CARD,
        label: i18n.t('Cards View'),
        requiresPermissionTo: ACCESS_PROJECTS_CARD
      },
      {
        type: VIEW_TYPES.KANBAN,
        label: i18n.t('Kanban View'),
        requiresPermissionTo: ACCESS_PROJECTS_KANBAN
      }
    ],
    inside_project_switch: false,
    default: {
      view_group: null,
      view_type: VIEW_TYPES.LIST
    }
  },
  [DEFAULT_VIEW_TYPE_TARGETS.TEMPLATES]: { 
    icon: 'fa-layer-group',
    view_groups: [],
    view_types: [
      {
        type: VIEW_TYPES.LIST,
        label: i18n.t('List View'),
        requiresPermissionTo: ACCESS_TEMPLATES_LIST
      },
      {
        type: VIEW_TYPES.CARD,
        label: i18n.t('Cards View'),
        requiresPermissionTo: ACCESS_TEMPLATES_CARD
      }
    ],
    inside_project_switch: false,
    default: {
      view_group: null,
      view_type: VIEW_TYPES.LIST
    }
  },
  [DEFAULT_VIEW_TYPE_TARGETS.TASKS]: {
    icon: 'fa-badge-check',
    view_groups: [
      VIEW_GROUPS.ALL,
      VIEW_GROUPS.MY
    ],
    view_types: [
      {
        type: VIEW_TYPES.LIST,
        label: i18n.t('List View'),
        requiresPermissionTo: ACCESS_TASKS_LIST
      },
      {
        type: VIEW_TYPES.CALENDAR,
        label: i18n.t('Calendar View'),
        requiresPermissionTo: ACCESS_TASKS_CALENDAR
      },
      {
        type: VIEW_TYPES.CARD,
        label: i18n.t('Cards View'),
        requiresPermissionTo: ACCESS_TASKS_LIST
      },
      {
        type: VIEW_TYPES.SCHEDULER,
        label: i18n.t('Scheduler View'),
        requiresPermissionTo: ACCESS_TASKS_SCHEDULER
      },
      {
        type: VIEW_TYPES.KANBAN,
        label: i18n.t('Kanban View'),
        requiresPermissionTo: ACCESS_TASKS_KANBAN
      }
    ],
    inside_project_switch: true,
    default: {
      view_group: VIEW_GROUPS.ALL,
      view_type: VIEW_TYPES.LIST
    }
  }
}

export function hasNotificationsOn(user: Data<User>) {
  const { notifiable_on_project, user_related_notifications } = user?.pivots?.notifiable_settings || {}
  return notifiable_on_project === true || user_related_notifications === true
}

export function isUserArchived(user: Data<User>) {
  return !!user?.attributes?.archived_at || user?.attributes?.status === 'archived'
}

export enum UserPreviewActions {
  View = 'view',
  Remove = 'remove',
}

export enum UserPreviewTypes {
  Disabled = 'disabled',
  Inline = 'inline',
  Full = 'full'
}

export enum UserTypes {
  All = 'all',
  Self = 'self',
  Creators = 'creators',
  Collaborators = 'collaborators',
}

export function filterUsersByType(users: Data<User>[], type: UserTypes) {
  switch (type) {
    case UserTypes.All:
      return users
    case UserTypes.Creators:
      return users.filter((user) => getRoleRank(user?.attributes?.role[0]) >= roleRanksEnum.CREATOR)
    case UserTypes.Collaborators:
      return users.filter((user) => getRoleRank(user?.attributes?.role[0]) < roleRanksEnum.CREATOR)
    default:
      return users
  }
}

export function getUserRole(user: Data<User>) {
  return user?.attributes?.role[0]
}

export function getRoleId(role: string) {
  // @ts-ignore
  const roles = store.state.users.roles.data || []
  const roleId = roles.find((r: any) => r.attributes.name === role)?.id

  return +roleId
}

export function isCollaboratorRole(role: string) {
  return [
    rolesEnum.COLLABORATOR,
    rolesEnum.COLLABORATOR_PLUS
  ].includes(role)
}

export function isCreatorRole(role: string) {
  return [
    rolesEnum.CREATOR,
    rolesEnum.CREATOR_PLUS,
    rolesEnum.CREATOR_ADMIN
  ].includes(role)
}

export function getCollaboratorRoleIds() {
  return [
    getRoleId(rolesEnum.COLLABORATOR),
    getRoleId(rolesEnum.COLLABORATOR_PLUS),
  ]
}

export function getCreatorRoleIds() {
  return [
    getRoleId(rolesEnum.CREATOR),
    getRoleId(rolesEnum.CREATOR_PLUS),
    getRoleId(rolesEnum.CREATOR_ADMIN),
  ]
}
