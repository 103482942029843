import FilesModuleLayout from "@/modules/files/layout/FilesModuleLayout.vue";
import FilesLayout from "@/modules/files/layout/FilesLayout.vue";
import NewFileUpload from '@/modules/files/pages/new-file-upload.vue';
import FilesList from "@/modules/files/pages/files-list.vue";
import FilesCards from "@/modules/files/pages/files-cards.vue";

import { CREATE_PROJECT_FILES } from "@/modules/common/enum/actionsEnum";

export default [
  {
    path: '/files',
    redirect: '/files/list',
    name: 'All Files',
    component: FilesModuleLayout,
    children: [
      {
        path: '/files',
        redirect: '/files/list',
        name: 'All Files List',
        component: FilesLayout,
        meta: {
          hideBreadCrumb: true,
        },
        children: [
          {
            path: '/files/list',
            name: 'All Files',
            meta: {
              isViewType: true,
            },
            component: FilesList,
          },
          {
            path: '/files/card',
            name: 'Files Cards',
            component: FilesCards,
            meta: {
              isViewType: true,
            },
          }
        ]
      },
      {
        path: '/files/add',
        name: 'New File Upload',
        component: NewFileUpload,
        meta: {
          requiresPermissionTo: CREATE_PROJECT_FILES
        }
      },
    ]
  },
]
