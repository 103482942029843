<template>
  <div>
    <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
      <div class="mb-4">
        <img
          class="m-auto mb-2 h-10 rounded"
          :src="logoSrc"
          alt="Project.co"
        >
        <BasePageTitle>
          {{$t('Reset password')}}
        </BasePageTitle>
        <p class="mt-2 text-center text-sm text-gray-600 max-w">
          {{$t('Or')}}
          <router-link to="/login" class="font-medium text-primary-500 hover:text-primary-600">
            {{$t('sign in into your account')}}
          </router-link>
        </p>
      </div>
      <VeeForm v-slot="{ handleSubmit, errors, meta }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="space-y-4"
        >
          <BaseInput
            v-model="model.password"
            :label="$t('New Password')"
            :name="$t('New Password')"
            :placeholder="$t('New Password')"
            layout="horizontal"
            rules="required|min:8|max:50"
            id="password"
            type="password"
          />
          <BaseInput
            v-model="model.password_confirmation"
            :label="$t('Confirm Password')"
            :name="$t('Confirm Password')"
            :placeholder="$t('Confirm Password')"
            layout="horizontal"
            :rules="`required|confirmed:@${$t('New Password')}`"
            id="password_confirmation"
            type="password"
          />
          <div class="mt-6">
            <BaseButton
              block
              type="submit"
              :disabled="!meta.valid"
              :loading="loading"
            >
              {{ $t('Reset Password') }}
            </BaseButton>
          </div>
        </form>
      </VeeForm>
    </div>
  </div>
</template>
<script>
import i18n from '@/i18n'
import AuthService from '@/modules/auth/services/AuthService.js';
import config from "@/modules/common/config";
import { logoDarkFull } from '@/modules/accounts/utils/accountBrandingUtils'

export default {
  data() {
    return {
      model: {
        email: '',
        token: '',
        password: null,
        password_confirmation: null,
      },
      loading: false,
      currentOrganization: '',
    };
  },
  computed: {
    organizations() {
      return this.$store.state.auth.organizations || []
    },
    customLogo() {
      return this.$settings('login_page_logo')
    },
    logoSrc() {
      return this.customLogo || logoDarkFull.value
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true

        this.model = {
          ...this.model,
          ...this.$route.query,
        }
        await AuthService.resetPassword(this.model)
        await this.$store.dispatch('auth/login', {
          email: this.model.email,
          password: this.model.password,
        })

        if (this.currentOrganization || import.meta.env.DEV) {
           await this.$router.push('/welcome')
           return
        }

        await this.handleOrganizationSelect()
        this.$success(i18n.t('Password reset successfully!'))
      } catch (e) {
        if (e.handled) {
          return
        }
        this.$error(i18n.t('Could not reset your password. Please try again or contact us.'))
      } finally {
        this.loading = false
      }
    },
    async handleOrganizationSelect() {
      AuthService.tryRedirectToSubdomain()
      await this.$router.push('/select-organization')
    },
    setCurrentOrganization() {
      this.currentOrganization = config.getOrganizationName()
    }
  },
  async mounted() {
    this.setCurrentOrganization()
  }
};
</script>
