import i18n from "@/i18n";

const ENGLISH_ORDINAL_RULES = new Intl.PluralRules("en", { type: "ordinal" });
const SUFFIXES = {
  one: "st",
  two: "nd",
  few: "rd",
  other: "th"
};

export function ordinal(number) {
  const suffix = SUFFIXES[ENGLISH_ORDINAL_RULES.select(number)];
  return (number + suffix);
}

export const getOrdinalOptions = (count, includeZero = true) => {
  return [...new Array(count)].map((x, i) => ({
    label: includeZero ? (i && ordinal(i) || i18n.t('All')) : ordinal(i + 1),
    value: includeZero ? i || undefined : i + 1
  }))
}
