<template>
  <div class="flex flex-col h-full">
    <TableCharts
      :grid-api="gridApi"
      :columns="tableColumns"
      formatter="time"
      hasTotalsFooter
    />
    <BaseDataTable
      v-bind="$attrs"
      :data="data"
      :meta="meta"
      :columns="tableColumns"
      :loading="loading"
      :extraFilters="extraFilters"
      groupIncludeTotalFooter
      :remapQueryParams="{
        page: 'timeEntriesPage',
        perPage: 'timeEntriesPerPage'
      }"
      class="flex-1"
      :class="{
        'min-h-[400px]': !limitedCount,
        'min-h-[200px]': limitedCount,
        'preserve-ag-background': isAllViewActive
      }"
      allowEntityType="time_entries"
      @firstDataRendered="onFirstDataRendered"
    >
      <template #no-data>
        <slot name="no-date">
          <NoTimeEntries
            :class="{
              'rounded-t-none': isAllViewActive
            }"
          />
        </slot>
      </template>
    </BaseDataTable>
  </div>
</template>
<script lang="ts" setup>
// Utils
import i18n from '@/i18n';
import { computed, ref, onMounted, onBeforeUnmount } from 'vue'
import { FirstDataRenderedEvent } from "@ag-grid-community/core";
import { getExtraFilters } from "@/modules/time/utils/timeEntryTableUtils"
import { TableColumn } from '@/components/table/tableUtils';

// Components
import NoTimeEntries from "@/modules/time/components/NoTimeEntries.vue"
import TableCharts from "@/components/table/charts/TableCharts.vue";

// Composables
import { useStore } from "vuex"
import { useRoute } from 'vue-router';

const store = useStore()
const route = useRoute()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  columns: {
    type: Array,
    default: () => []
  },
  limitedCount: {
    type: Boolean,
    default: false
  },
})

const tableColumns = computed(() => {
  const columns = props.columns.length
    ? props.columns
    : store.getters['time/timeEntryTableColumns'] || []

  if (!props.limitedCount) {
    return columns
  }

  return columns.map((column: TableColumn) => {
    // Always enable project column displaying the table inside the time entry modal timers
    if (column.name === i18n.t('Project')) {
      return {
        ...column,
        disabled: false
      }
    }

    return column
  })
})


const extraFilters = computed(() => {
  return getExtraFilters()
})

const isAllViewActive = computed(() => {
  return route.path.endsWith('time/all')
})

const meta = computed(() => {
  return store.state.time.timeEntries.meta || {}
})

const gridApi = ref()
function onFirstDataRendered(params: FirstDataRenderedEvent) {
  gridApi.value = params.api
}

async function loadData() {
  store.dispatch('time/getAllTimeEntries')
}

const refreshInterval = ref<ReturnType<typeof setInterval>>()

function startTimer() {
  // Refresh the grid every minute to update worked minutes for running timers
  refreshInterval.value = setInterval(() => {
    gridApi.value?.refreshCells({
      columns: ['attributes.worked_minutes'],
    })
    gridApi.value?.refreshClientSideRowModel('aggregate')
  }, 1000 * 60)
}

onMounted(() => {
  loadData()
  startTimer()
})

function stopTimer() {
  if (!refreshInterval.value) {
    return
  }

  clearInterval(refreshInterval.value)
}

onBeforeUnmount(() => {
  stopTimer()
})
</script>
<style lang="scss">
.el-collapse-item__header {
  @apply rounded-lg;
}

.el-collapse-item__header.is-active {
  @apply rounded-b-none;
}

.ag-ltr .ag-chart-tool-panel-button-enable .ag-chart-menu-close {
  @apply hidden;
}
</style>
