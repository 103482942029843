import store from "@/store"
import Data = API.Data
import FilterPreset = App.Domains.FilterPresets.Model.FilterPreset

/**
 * Loads project data after create from template and duplicate actions
 * @param projectOrTemplateId - Newly created project id
 * @param forceFetch - Wheter to force fetch filter presets
 */
export async function syncNewProjectData(projectOrTemplateId: string | number, forceFetch = false) {
  store.commit('files/setAllFilesLoaded', false, { root: true })
  store.commit('payments/setAllPaymentsLoaded', false, { root: true }) 
  store.commit('time/setAllAllocatedTimeLoaded', false, { root: true })

  store.dispatch('accounts/getConfiguration', null, { root: true })
  store.dispatch('users/getData', null, { root: true })

  await checkAndReloadFilterPresets(projectOrTemplateId, forceFetch)
}

export async function checkAndReloadFilterPresets(projectOrTemplateId: string | number, forceFetch = false) {
  // @ts-ignore
  const filterPresets = store.state.filters.filterPresets
  const hasSavedPresets = filterPresets.some((preset: Data<FilterPreset>) => {
    return preset.attributes?.project_ids?.includes(projectOrTemplateId?.toString())
  })

  if (!forceFetch && !hasSavedPresets) {
    return
  }

  await store.dispatch('filters/getFilterPresets')
}
