<template>
  <div
    :class="{
    'max-w-6xl mx-auto rounded-lg shadow bg-white': !$useNewLayout
    }"
  >
    <BaseFormHeader
      :title="$t('Create New Time Entry')"
      :subtitle="$t('Create a new time entry to record the time people spend on projects.')"
      :isDialogForm="isDialogForm"
    />

    <ButtonGroups
      v-if="can($actions.CREATE_TIME_ENTRIES_TIMER)"
      :active-option="currentCreationMethod"
      :options="AllTimeCreationOptions"
      class="mt-6"
      :class="{
        'mb-6': !isDialogForm,
      }"
      @change="onCreateMethodChanged"
    />

    <TimeEntryManualForm
      v-if="currentCreationMethod === TimeCreationMethods.Manual || !can($actions.CREATE_TIME_ENTRIES_TIMER)"
      :time-entry="timeEntry"
      ref="timeEntryManualForm"
      class="mx-auto"
      :isDialogForm="isDialogForm"
      @save="$emit('save')"
      @cancel="$emit('cancel')"
    />

    <UpgradePlanWarning
      v-if="!can($actions.TASK_TIMERS) && currentCreationMethod === TimeCreationMethods.Timer"
      :message="$t(' to use the timer functionality.')"
      class="max-w-md m-auto mt-4"
    />

    <TimeEntryTimerForm
      v-if="currentCreationMethod === TimeCreationMethods.Timer"
      :time-entry="timeEntry"
      :isDialogForm="isDialogForm"
      class="mx-auto"
      @save="$emit('save')"
      @cancel="$emit('cancel')"
    />
    <template v-if="currentCreationMethod === TimeCreationMethods.AllocateTime">
      <div
        v-if="!authorizedToAllocateTime"
        class="p-4 mx-auto"
      >
        <WarningAlert
          :message="$t(`You don't have enough permissions to allocate time.`)"
        />
      </div>
      <AllocatedTimeForm
        v-else
        :time-entry="timeEntry"
        :time-entry-scoped="true"
        :show-title="false"
        :isDialogForm="isDialogForm"
        class="mx-auto"
        @save="$emit('save')"
        @cancel="$emit('cancel')"
      />
    </template>
  </div>
</template>
<script>
import TimeEntryTimerForm from '@/modules/time/components/TimeEntryTimerForm.vue'
import TimeEntryManualForm from '@/modules/time/components/TimeEntryManualForm.vue'
import { TimeCreationMethods, AllTimeCreationOptions } from '@/modules/time/utils/timeEntryUtils.js'
import UpgradePlanWarning from '@/modules/accounts/components/UpgradePlanWarning.vue'
import AllocatedTimeForm from '@/modules/time/components/AllocatedTimeForm.vue'
import { isRoleGreaterOrEqual } from '@/modules/common/utils/isRoleGreaterOrEqual.js'

export default {
  name: 'TimeEntryForm',
  components: {
    AllocatedTimeForm,
    UpgradePlanWarning,
    TimeEntryTimerForm,
    TimeEntryManualForm,
  },
  props: {
    timeEntry: {
      type: Object,
      default: () => ({}),
    },
    isDialogForm: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['save', 'cancel'],
  data() {
    return {
      TimeCreationMethods,
      AllTimeCreationOptions,
      currentCreationMethod: TimeCreationMethods.Manual,
    }
  },
  computed: {
    authorizedToAllocateTime() {
      return isRoleGreaterOrEqual(this.$userRole, this.$roles.CREATOR_PLUS)
    },
  },
  methods: {
    onCreateMethodChanged(value) {
      this.currentCreationMethod = value
    },
  }
}
</script>
