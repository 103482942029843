<template>
  <div
    :class="{
      [$attrs.expandedClass]: !isCollapsed && $attrs.expandedClass,
    }"
  >
    <h2
      v-if="groupByColumn.prop"
      :class="{
        'capitalize font-bold flex items-center group-title rounded-lg': !$attrs.headerClass,
        'bg-white border border-gray-200': !isCalendarResource && !slim && !$attrs.headerClass,
        'shadow border-b-0': !$useNewLayout && !isCalendarResource && !slim && !$attrs.headerClass,
        'pl-4 py-2 mb-4 sm:text-xl': !slim && !$attrs.headerClass,
        'sm:text-base mb-3': slim && !$attrs.headerClass,
        [$attrs.headerClass]: $attrs.headerClass,
        [$attrs.appendHeaderClass]: $attrs.appendHeaderClass
      }"
    >
      <i
        v-if="collapsable"
        class="fas text-gray-400 mr-2 cursor-pointer w-4"
        :class="{
          'fa-chevron-down': !isCollapsed,
          'fa-chevron-right': isCollapsed
        }"
        @click="onToggleCollapsed"
      />
      <span v-if="showColumnName && groupByColumn.name" class="text-gray-500 mr-1 align-center">
        {{ groupByColumn.name }}:
      </span>
      <template v-if="title">
        {{ title }}
      </template>
      <template v-else-if="isImage">
        <BaseLogo :entity="firstObject(data)"/>
      </template>
      <template v-else-if="isPaymentStatus">
        <BaseBadge
          v-if="firstObject(data)?.attributes?.payment_status"
          :color="getPaymentStatusColor(firstObject(data)?.attributes?.payment_status)"
        >
          {{ firstObject(data)?.attributes?.payment_status }}
        </BaseBadge>
        <template v-else>
          - -
        </template>
      </template>
      <template v-else-if="isStatus">
        <Status
          v-if="get(data, '[0]')"
          :row="get(data, '[0]')"
          :params="{
            target
          }"
        />
        <template v-else>
          - -
        </template>
      </template>
      <template v-else-if="isTasksGroupByDate">
        <div class="flex items-center space-x-2">
          <i
            v-if="isRecurringTask(data)"
            class="far fa-repeat text-green-400"
          />
          <span>
          {{ formatDate(data, groupByColumn.prop) }}
        </span>
        </div>
      </template>
      <template v-else-if="isDate">
        <div class="flex items-center space-x-2">
        <span>
          {{ formatDate(data, groupByColumn.prop) }}
        </span>
        </div>
      </template>
      <template v-else-if="isAllocation">
        <AllocationsList
          :allocations="getGroupValue(data, 'relationships.allocations')"
        />
      </template>
      <template v-else-if="isGroup">
        <GroupsList
          :groups="getGroupValue(data, groupByColumn.prop)"
        />
      </template>
      <template v-else-if="isGroupIds">
        <GroupsList
          :groups="groupsById"
        />
      </template>
      <template v-else-if="isProject">
        <router-link
          v-if="getGroupValue(data, groupByColumn.prop)"
          :to="getGroupPath(data, 'project')"
          class="flex items-center space-x-2 max-w-full w-full pr-2"
        >
          <BaseLogo
            :entity="get(data, '[0].relationships.project')"
            logo-key="attributes.image"
          />
            <span class="group-by-text truncate"> {{ getGroupValue(data, projectNameProp || groupByColumn.prop) }} </span>
          <Status
            :row="get(data, '[0].relationships.project')"
          />
        </router-link>
        <template v-else>
          - -
        </template>
      </template>
      <template v-else-if="isParentTask">
        <router-link
          v-if="getGroupValue(data, groupByColumn.prop)"
          :to="getGroupPath(data, 'parentTask')"
          class="flex items-center space-x-2"
        >
          <span>{{ getGroupValue(data, groupByColumn.prop) }}</span>
          <Status
            :row="get(data, '[0]')"
            :params="{
              target: 'task'
            }"
          />
        </router-link>
        <template v-else>
          - -
        </template>
      </template>
      <template v-else-if="groupByCustomField">
        <CustomFieldValue
          class="normal-case"
          :customField="groupByCustomField"
          :model="data[0]"
        />
      </template>
      <template v-else-if="isTimeInMinutes">
        {{  minutesToFormattedTime(keyValue)  }}
      </template>
      <template v-else>
        {{ getGroupValue(data, groupByColumn.prop) }}
      </template>
    </h2>
    <div v-show="!isCollapsed">
      <slot />
    </div>
  </div>
</template>
<script>
import AllocationsList from "@/modules/tasks/components/AllocationsList.vue";
import GroupsList from "@/modules/groups/components/GroupsList.vue";
import CustomFieldValue from "@/modules/common/components/CustomFieldValue.vue";
import Status from "@/components/table/Status.vue";

import { getPaymentStatusColor } from "@/modules/payments/enum/paymentEnums.js";
import { taskDateTypes } from "@/modules/tasks/utils/modelUtils.js";
import { minutesToFormattedTime } from "@/modules/common/utils/timeUtils";

export default {
  components: {
    AllocationsList,
    GroupsList,
    CustomFieldValue,
    Status
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    target: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    showColumnName: {
      type: Boolean,
      default: true,
    },
    projectNameProp: {
      type: String,
      default: ''
    },
    allocationsProp: {
      type: String,
      default: ''
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    keyValue: {
      type: String,
      default: ''
    },
    isCalendarResource: {
      type: Boolean,
      default: false
    },
    groupBy: {
      type: Array,
      default: null
    },
    slim: {
      type: Boolean,
      default: false
    },
    initCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapsed: this.initCollapsed,
      minutesToFormattedTime
    }
  },
  computed: {
    groupByColumn() {
      const groupByValue = this.groupBy || this.$store.getters['filters/targetLocalFilters'](this.target)?.groupBy
      const groupByProp = groupByValue?.[0]
      return this.columns.find(column => column.prop === groupByProp) || {
        prop: groupByProp
      }
    },
    isTimeInMinutes() {
      return [
        'attributes.actual_time',
        'attributes.allocated_minutes',
        'attributes.worked_minutes',
      ].includes(this.groupByColumn?.prop)
    },
    allCustomFields() {
      return this.$store.state.accounts.customFields?.data || []
    },
    groupByCustomField() {
      if (!this.groupByColumn?.customField) {
        return null
      }

      return this.allCustomFields.find(cf => cf.id?.toString() === this.groupByColumn?.customField?.id?.toString()) || this.groupByColumn?.customField
    },
    columnProp() {
      return this.groupByColumn?.prop || ''
    },
    isImage() {
      return this.columnProp.includes('image')
    },
    isStatus() {
      return this.columnProp.includes('status')
    },
    isPaymentStatus() {
      return this.columnProp.includes('payment_status')
    },
    isDate() {
      return this.columnProp.includes('date') || this.columnProp.includes('created_at')
    },
    isTasksGroupByDate() {
      return this.columnProp.includes('sortable_date')
    },
    isAllocation() {
      return this.columnProp.includes('allocations') || this.columnProp.includes('allocated_ids')
    },
    isGroup() {
      return this.columnProp.includes('groups')
    },
    isGroupIds() {
      const isGroupIds = this.columnProp.includes('group_ids')
      if (isGroupIds) {
        this.loadGroups()
      }
      return isGroupIds
    },
    isProject() {
      return this.columnProp.includes('project')
    },
    isTask() {
      return this.columnProp.includes('task')
    },
    isParentTask() {
      return this.columnProp.includes('parentTask')
    },
    allGroups() {
      return this.$store.state.groups.allGroups || []
    },
    groupsById() {
      const groupValue = this.get(this.data, `[0].${this.groupByColumn.prop}`)
      return groupValue?.map(id => this.allGroups.find(group => group.id === id) || id)
    }
  },
  watch: {
    isCollapsed: {
      immediate: true,
      handler(value) {
        this.$emit('toggle-collapsed', value)
      }
    }
  },
  methods: {
    async loadGroups() {
      await this.$store.dispatch("groups/getAllGroups");
    },
    getPaymentStatusColor,
    getGroupValue(data, prop) {
      const groupValue = this.get(data, `[0].${prop}`)
      if (this.isAllocation || this.isGroup) {
        return groupValue
      }

      if (this.isProject) {
        return this.get(data, '[0].relationships.project.attributes.name', '- -') || groupValue
      }

      if (this.isTask) {
        return this.get(data, '[0].relationships.task.attributes.name', '- -') || groupValue
      }

      if (Array.isArray(groupValue)) {
        return groupValue.length
          ? groupValue.join(', ')
          : '- -';
      }

      return groupValue || '- -'
    },
    getGroupPath(data, type) {
      const row = this.firstObject(data)
      if (type === 'project') {
        const projectId = this.get(row, 'relationships.project.id', '')
        return `/projects/${projectId}`
      }

      if (type === 'parentTask') {
        const taskId = this.get(row, 'relationships.parentTask.id', '')

        return {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            taskId
          }
        }
      }

      return '/'
    },
    firstObject(data) {
      return this.get(data, '[0]', {})
    },
    formatDate(data, prop) {
      const row = this.firstObject(data)

      if (this.isTasksGroupByDate) {
        const [
          dateType,
          dateValue,
          endDateValue
       ] = this.keyValue?.split('_') || []
       
       if (endDateValue) {
          return `${this.$formatDate(dateValue)} → ${this.$formatDate(endDateValue)}`
       }

       return this.$formatDate(dateValue)
      }

      if (this.keyValue) {
        return this.$formatDate(this.keyValue)
      }

      const date = this.get(row, prop, '')
      const { start_date, end_date, recurrence_date } = row?.attributes || {}
      if (date) {
        return this.$formatDate(date)
      } else if (start_date || end_date) {
        const from = this.$formatDate(start_date)
        const to = this.$formatDate(end_date)
        return `${from} → ${to}`
      } else if (recurrence_date) {
        return this.$formatDate(recurrence_date)
      } else {
        return '- -'
      }
    },
    isRecurringTask(data) {
      return this.firstObject(data)?.attributes?.date_type === taskDateTypes.RECURRING
    },
    onToggleCollapsed() {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
</script>
