<template>
  <EntityViewTypesSelect
    :viewTypes="viewTypes"
    :label="$t('Time')"
    :title="$t('Change type')"
    icon="fa-stopwatch"
  />
</template>
<script>
import EntityViewTypesSelect from '@/modules/common/components/EntityViewTypesSelect.vue'

export default {
  name: 'TimeViewTypes',
  components: {
    EntityViewTypesSelect,
  },
  computed: {
    isActualViewActive() {
      return this.$route.path?.includes('/time/actual')
    },
    isAllocatedViewActive() {
      return this.$route.path?.includes('/time/allocated')
    },
    isAllViewActive() {
      return this.$route.path?.includes('/time/all')
    },
    viewTypes() {
      const viewTypes = [
        {
          name: this.$t('Actual Time'),
          path: this.getPath('time/actual'),
          isActive: this.isActualViewActive,
          showIf: () => {
            return this.can(this.$actions.VIEW_TIME_ENTRIES)
          },
        },
        {
          name: this.$t('Allocated Time'),
          path: this.getPath('time/allocated'),
          isActive: this.isAllocatedViewActive,
          showIf: () => {
            return this.can(this.$actions.VIEW_TIME_ENTRIES)
          },
        },
        {
          name: this.$t('All'),
          path: this.getPath('time/all'),
          isActive: this.isAllViewActive,
          showIf: () => {
            return this.can(this.$actions.VIEW_TIME_ENTRIES) && !this.$isMobileDevice
          },
        },
      ]

      return viewTypes
    },
    project_id() {
      return this.$store.getters.project_id
    },
  },
  methods: {
    getPath(pathToAppend) {
      let path = `/${pathToAppend}`
      if (this.$route.path.includes('projects') && this.project_id) {
        path = `/projects/${this.project_id}/${pathToAppend}`
      }

      return path
    },
  },
}
</script>
