// Libs
import axios from "axios";
import i18n from "@/i18n.js";
import store from "@/store/index.js";
import { get, set, sortBy, cloneDeep } from 'lodash-es'

// Helpers
import { getProjectColumns, onCellValueChanged, isPropEditable } from "@/modules/projects/utils/projectTableUtils"
import { syncNewProjectData } from "@/modules/projects/utils/projectActionsUtils"
import { error } from '@/components/common/NotificationPlugin';
import { groupByArrayValues, removeFromListById } from "@/modules/common/utils/listUtils.js";
import apiCache from "@/modules/common/utils/apiCache.js";
import {
  syncEntityInArray,
  EntityChangeEvent,
  getEntityArrayForWorker,
  groupEntitiesToMap,
} from "@/modules/common/utils/entityUtils";
import { projectUsersMapWorker, projectGroupsMapWorker } from "@/modules/projects/utils/projectRelationshipsUtils";

import { comparatorTypesMap, ComparatorTypes } from "@/components/table/tableUtils";

import { columnBuilder } from '@/components/table/tableUtils'
import { getNoteName } from '@/modules/projects/utils/stripNotesHTML'
import {
  notePinnedTypes,
  NotesVisibility,
  pinnedOptions
} from '@/modules/projects/utils/noteUtils'
import { isDateBetween } from '@/modules/common/utils/dateUtils'
import router from "@/router";
import { scrollToBottom } from "@/utils/global";
import { nextTick } from "vue";
import { taskPrivacyOptions } from "@/modules/tasks/utils/modelUtils";
import { Activities, trackActivity } from "@/modules/common/utils/trackingUtils";
import { getSetting } from "@/plugins/settingsPlugin";

const projectRelations = 'tools,status,groups,users,embeds,notes,links,references'
export const projectListRelations = 'status,groups'


const state = () => ({
  projects: {
    data: [],
  },
  allProjects: [],
  allProjectsLoaded: false,
  tools: {
    data: [],
  },
  statuses: {
    data: [],
  },
  statusesLoading: false,
  projectsLoading: true,
  toolsLoading: false,
  currentProjectLoading: false,
  currentProject: null,
  currentProjectNoteLoading: false,
  // Notes Data
  notesLoading: {
    all: false,
    current: false,
    create: false,
    delete: ''
  },
  currentProjectNote: null,
  notes: [],
  notesColumns: [
    {
      name: i18n.t('Drag & Drop'),
      sortProp: 'order',
      sortable: true,
      prop: 'attributes.order',
      group: false,
      showInChooseColumns: false,
      visibleInTable: false,
    },
    {
      name: i18n.t('Privacy'),
      prop: 'attributes.visibility',
      filterBy: {
        prop: 'visibility',
        component: 'TaskPrivacySelect',
        displayFormat(value) {
          return taskPrivacyOptions.value.find(x => x.value === value)?.label || ''
        },
        doesFilterPass: (note, filterValue) => {
          return note.attributes.visibility === filterValue
        },
      },
      group: true
    },
    {
      name: i18n.t('Pinned'),
      prop: 'attributes.pinned',
      filterBy: {
        prop: 'pinned',
        component: 'NotePinnedSelect',
        displayFormat(value) {
          return pinnedOptions.value.find(x => x.value === value)?.label || ''
        },
        doesFilterPass: (note, filterValue) => {
          if (filterValue === notePinnedTypes.PINNED) {
            return note.attributes.pinned
          }
          if (filterValue === notePinnedTypes.UNPINNED) {
            return !note.attributes.pinned
          }
          return true
        },
      },
      group: true
    },
    {
      name: i18n.t('Created date'),
      prop: 'attributes.created_at',
      showCardLabel: true,
      sortProp: 'created_at',
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.Date]('attributes.created_at'),
      visibleInTable: false,
      showInChooseColumns: false,
      filterBy: {
        prop: 'created_at',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy',
        doesFilterPass: (note, filterValue) => {
          return isDateBetween(note?.attributes?.created_at, filterValue)
        },
      },
      group: true
    },
  ],
  // Project Data
  currentProjectId: null,
  addProjectTrigger: 1,
  projectPeopleTrigger: 1,
  addReferenceTrigger: 1,
  createProjectFromTemplateTrigger: 1,
  completeProjectTrigger: 1,
  projectGroupsBinding: [],
  projectToUsersBindingMap: {
    // project_id: { user_ids: [], users: [] }
  },
  projectToGroupsBindingMap: {
    // project_id: { group_ids: [], groups: [] }
  },
  projectUsersBinding: [],
})

const mutations = {
  setNotes(state, value) {
    state.notes = value
  },
  setNotesLoading(state, { type, val }) {
    state.notesLoading[type] = val
  },
  addProjectNote(state, note) {
    state.notes.unshift(note)
  },
  removeNotes(state, noteIds) {
    state.notes = state.notes.filter(n => !noteIds.includes(n.id))
  },
  setProjects(state, value) {
    state.projects = value
  },
  setAllProjects(state, value) {
    state.allProjects = value
    state.allProjectsLoaded = true
  },
  setProjectGroupsBinding(state, value) {
    state.projectGroupsBinding = value

    const map = {}
    value.forEach(b => {
      if (!map[b.project_id]) {
        map[b.project_id] = []
      }

      map[b.project_id].push(b.group_id)
    })

    state.projectToGroupsBindingMap = map
  },
  setProjectUsersBinding(state, value) {
    state.projectUsersBinding = value
  },
  setProjectUsersBindingMap(state, value) {
    state.projectToUsersBindingMap = value
  },
  setProjectGroupsBindingMap(state, value) {
    state.projectToGroupsBindingMap = value
  },
  addProjects(state, value) {
    state.projects.data = [...state.projects.data, ...value]
  },
  addProject(state, project) {
    if (!project) {
      return
    }

    syncEntityInArray(project, state.allProjects, EntityChangeEvent.Create)
  },
  updateProject(state, updatedProject) {
    syncEntityInArray(updatedProject, state.allProjects, EntityChangeEvent.Update)

    if (String(updatedProject.id) !== String(state.currentProject?.id)) {
      return
    }

    state.currentProject = {
      ...state.currentProject,
      ...updatedProject,
      attributes: {
        ...state.currentProject.attributes,
        ...updatedProject.attributes
      },
      relationships: {
        ...state.currentProject.relationships,
        ...updatedProject.relationships
      }
    }
  },
  updateProjectDescription(state, description) {
    if (!state.currentProject) {
      return
    }
    state.currentProject.attributes.description = description
  },
  deleteProject(state, id) {
    syncEntityInArray({ id }, state.allProjects, EntityChangeEvent.Delete)
  },
  setAllTools(state, value) {
    state.tools = value
  },
  setAllStatuses(state, value) {
    state.statuses = value
  },
  deleteStatus(state, id) {
    removeFromListById(state.statuses.data, id)
  },
  setStatusesLoading(state, value) {
    state.statusesLoading = value
  },
  setProjectsLoading(state, value) {
    state.projectsLoading = value
  },
  setToolsLoading(state, value) {
    state.toolsLoading = value
  },
  setCurrentProjectLoading(state, value) {
    state.currentProjectLoading = value
  },
  setCurrentProject(state, value) {
    state.currentProjectId = value?.id
    state.currentProject = value
  },
  setCurrentProjectId(state, value) {
    state.currentProjectId = value
  },
  setCurrentProjectNote(state, value) {
    state.currentProjectNote = value
  },
  updateProjectNote(state, data) {
    const { id = state.currentProjectNote?.id, prop, value } = data
    if (id === state.currentProjectNote?.id) {
      if (!state.currentProjectNote) state.currentProjectNote = {}
      set(state.currentProjectNote, prop, value)
    }

    const projectNoteIndex = state.currentProject?.relationships?.notes?.findIndex(n => String(n.id) === String(id))
    if (projectNoteIndex && projectNoteIndex !== -1) {
      set(state.currentProject.relationships.notes[projectNoteIndex], prop, value)
    }

    const note = state.notes?.find(n => n?.id?.toString() === id?.toString())
    if (note) {
      set(note, prop, value)
    }
  },
  updateProjectToolOrders(state, orderedTools) {
    const tools = state.currentProject?.relationships?.tools || []
    tools.forEach(tool => {
      const newOrder = orderedTools.find(t => t.id == tool.pivots.id)?.order || tool.pivots.order
      set(tool, 'pivots.order', newOrder)
    })
  },
  triggerAddProject(state) {
    state.addProjectTrigger++
  },
  triggerCreateProjectFromTemplate(state) {
    state.createProjectFromTemplateTrigger++
  },
  triggerCompleteProject(state) {
    state.completeProjectTrigger++
  },
  triggerProjectPeople(state) {
    state.projectPeopleTrigger++
  },
  triggerAddReference(state) {
    state.addReferenceTrigger++
  },
}

const actions = {
  async computeProjectUsersBinding({ commit, state, rootState }, value) {
    const bindingArray = getEntityArrayForWorker(value || state.projectUsersBinding || [])
    commit('setProjectUsersBinding', bindingArray)

    const allUsers = getEntityArrayForWorker(rootState.users.allUsers || [])

    if (!rootState.users.allUsersLoaded || !bindingArray.length) {
      return
    }

    const bindingMap = await projectUsersMapWorker(bindingArray, allUsers)

    commit('setProjectUsersBindingMap', bindingMap)
  },
  async computeProjectGroupsBinding({ commit, state, rootState }, value) {
    const bindingArray = getEntityArrayForWorker(value || state.projectGroupsBinding || [])
    commit('setProjectGroupsBinding', bindingArray)

    const allGroups = getEntityArrayForWorker(rootState.groups.allGroups || [])

    if (!rootState.groups.allGroupsLoaded || !bindingArray.length) {
      return
    }

    const bindingMap = await projectGroupsMapWorker(bindingArray, allGroups)

    commit('setProjectGroupsBindingMap', bindingMap)
  },
  async getProjects({ commit, getters, rootGetters, rootState }, filters) {
    let projects = null
    try {
      if (filters?.shouldReset === true || filters?.calendar) {
        commit("setProjects", { data: [] })
      }
      filters = filters || {...rootState.route?.query }

      delete filters?.shouldReset

      let isSchedulerPath = rootState?.route.path?.includes('scheduler')
      let isCalendarPath = rootState?.route.path?.includes('calendar')

      if (isSchedulerPath || isCalendarPath) {
        filters.perPage = 500
        filters.page = 1
      }
      commit('setProjectsLoading', true)

      const is_template = rootGetters['templates/isTemplatePath']

      projects = await apiCache.getRequest('/restify/projects', {
        params: {
          ...filters,
          is_template,
          related: projectListRelations,
        }
      })

      commit('setProjects', projects)
    } finally {
      commit('setProjectsLoading', false)
    }
  },
  async getProjectCardsPage({ commit, rootGetters, rootState }, { page }) {
    let projects = null
    try {
      let filters
      if (filters?.shouldReset === true) {
        commit("setProjects", { data: [] })
      }
      filters = filters || { ...rootState.route?.query }
      commit('setProjectsLoading', true)

      const is_template = rootGetters['templates/isTemplatePath']

      projects = await apiCache.getRequest('/restify/projects', {
        params: {
          ...filters,
          is_template,
          page,
          related: projectListRelations,
        }
      })

      commit('addProjects', projects.data)
      return projects
    } finally {
      commit('setProjectsLoading', false)
    }
  },
  async getAllTools({ commit, state }) {
    try {
      if (state.tools?.data?.length > 0) {
        return
      }
      commit('setToolsLoading', true)
      const tools = await axios.get('/restify/tools')
      commit('setAllTools', tools)
    } finally {
      commit('setToolsLoading', false)
    }
  },
  async getAllStatuses({ commit, state }, forceFetch = false) {
    try {
      if (!forceFetch && state.statuses?.data?.length > 0) {
        return
      }
      commit('setStatusesLoading', true)
      const statuses = await apiCache.getRequest('/restify/project-statuses')
      commit('setAllStatuses', statuses)
    } finally {
      commit('setStatusesLoading', false)
    }
  },
  async deleteStatus({ commit }, statusId) {
    await axios.delete(`/restify/project-statuses/${statusId}`);
    commit('deleteStatus', statusId)
  },
  async getProjectById({ commit, state, getters }, {
    id,
    returnEntity = false,
    silent = false,
    allowCache = false,
    related = null
  }) {
    if (!id && state.currentProjectId) {
      id = state.currentProjectId
    }
    if (!id) {
      return
    }
    try {
      const requestOptions = {
        params: {
          related: related || projectRelations
        }
      }

      if (returnEntity) {
        const { data } = allowCache
          ? await apiCache.getRequest(`/restify/projects/${id}`, requestOptions)
          : await axios.get(`/restify/projects/${id}`, requestOptions)

        // Temp fix for users returned as object instead of array
        if (data?.relationships?.users && typeof data.relationships.users === 'object' && !Array.isArray(data.relationships.users)) {
          data.relationships.users = Object.values(data.relationships.users)
        }

        return data
      }

      commit('setCurrentProjectId', id)

      if (state.currentProject?.id?.toString() !== id.toString()) {
        const project = state.projects.data.find(p => p.id.toString() === id.toString())
        if (project) {
          // We set this temporarily and then fetch the project again to get the full data
          commit('setCurrentProject', project)
        }
      }

      if (!silent) {
        commit('setCurrentProjectLoading', true)
        commit("setNotesLoading", { type: 'all', val: true });
      }

      const { data } = allowCache
      ? await apiCache.getRequest(`/restify/projects/${id}`, requestOptions)
      : await axios.get(`/restify/projects/${id}`, requestOptions)

      // Temp fix for users returned as object instead of array
      if (data?.relationships?.users && typeof data.relationships.users === 'object' && !Array.isArray(data.relationships.users)) {
        data.relationships.users = Object.values(data.relationships.users)
      }

      commit('setNotes', data?.relationships?.notes || [])

      commit('setCurrentProject', data)
    }
    catch (err) {
      commit('setCurrentProjectId', null)
      if (err.status === 404 && !silent) {
        await router.push('/404')
        return
      }
      if (err.handled || silent) {
        return
      }
      throw err
    }
    finally {
      if (!silent) {
        commit('setCurrentProjectLoading', false)
        commit("setNotesLoading", { type: 'all', val: false });
      }
    }
  },
  async createProject({ dispatch, commit, rootState }, data) {
    const response = await axios.post(`/restify/projects`, data)

    const projectId = response?.data?.id;

    if (!projectId) {
      return
    }

    if (data.is_template) {
      trackActivity(Activities.TemplateCreated, response.data)
    } else {
      trackActivity(Activities.ProjectCreated, response.data)
    }
    const newProject = await dispatch('getProjectById', { id: projectId, returnEntity: true })
    commit('addProject', newProject)

    dispatch('accounts/syncSubscriptionStats', { limitType: 'activeProjects' },  { root: true })
    return newProject
  },
  async editProject({ commit, dispatch }, { data: requestData, project, syncWithServer = true }) {
    const projectId = project?.id
    const data = cloneDeep(requestData)
    delete data.description_collaboration
    const response = await axios.post(`/restify/projects/${projectId}`, data)

    let updatedProject = response?.data

    if (syncWithServer) {
      updatedProject = await dispatch('getProjectById', {
        id: projectId,
        returnEntity: true
      })
    }

    commit('updateProject', updatedProject)

    return updatedProject
  },
  async editProjectDescription({ commit, dispatch }, project) {
    const projectId = project?.id
    await axios.patch(`/restify/projects/${projectId}`, {
      description: project?.description,
      notifiable_user_ids: project?.notifiable_user_ids,
    })

    commit('updateProjectDescription', project.description)
  },
  async overwriteProjectDescription({ state, commit, dispatch }, project) {
    const projectId = project?.id
    const { data } = await axios.patch(`/restify/projects/${projectId}`, {
      description: project?.description,
      description_collaboration: null,
    })

    if (projectId == state.currentProjectId) {
      commit('updateProjectDescription', project.description)
      dispatch('getProjectById', { id: projectId, forceFetch: true, silent: true })
    }

    return data
  },
  async updateProject({ commit }, { projectId, model }) {
    const response = await axios.put(`/restify/projects/${projectId}`, model)

    let updatedProject = response?.data

    commit('updateProject', updatedProject)

    return updatedProject
  },
  async duplicateProject({ dispatch }, projectId) {
    const project = await axios.post(`/restify/projects/${projectId}/actions?action=duplicate-project`)

    dispatch('accounts/syncSubscriptionStats', { limitType: 'activeProjects' },  { root: true })

    syncNewProjectData(projectId)

    return project.data
  },
  async convertToTemplate({}, projectId) {
    const project = await axios.post(`/restify/projects/${projectId}/actions?action=convert-to-template`)

    syncNewProjectData(projectId)

    return project.data
  },
  async deleteProject({ commit, dispatch }, projectId) {
    await axios.delete(`/restify/projects/${projectId}`);

    dispatch('accounts/syncSubscriptionStats', { limitType: 'activeProjects' },  { root: true })

    commit('deleteProject', projectId)
  },
  async changeProjectStatus({ dispatch, commit }, { status_id, project }) {
    if (project?.status_id?.toString() === status_id?.toString() || !status_id) {
      return
    }
    await axios.post(`/restify/projects/${project?.id}/actions?action=change-project-status`, {
      status_id,
    })

    commit('updateProject', {
      id: project?.id,
      attributes: {
        status_id,
      }
    })

    dispatch('accounts/syncSubscriptionStats', { limitType: 'activeProjects' },  { root: true })
  },
  async completeProject({ dispatch, getters, commit }, { project, completed_at }) {
    try {
      const data = {}
      if (completed_at) {
        data.completed_at = completed_at
      }

      await axios.post(`/restify/projects/${project?.id}/actions?action=complete-project`, data)

      dispatch('accounts/syncSubscriptionStats', { limitType: 'activeProjects' },  { root: true })

      commit('updateProject', {
        id: project?.id,
        attributes: {
          status_id: getters.completedStatus?.id,
        }
      })

      return true
    } catch (err) {
      if (err.handled) {
        return false
      }
      error(i18n.t('Could not mark the project as completed'))
      return false
    }
  },
  async uploadFileToProject({ commit }, { projectId, noteId, file }) {
    const formData = new FormData()
    formData.append('files[]', file)
    if (noteId) {
      formData.append('note_id', noteId)
    }

    const url = `/restify/projects/${projectId}/actions?action=attach-project-file`
    const { data } = await axios.post(url, formData, {
      onUploadProgress: progressEvent => {
        let progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        commit('files/setUploadProgress', { id: file.id, progress }, { root: true})
      }
    })
    store.dispatch('files/addUploadedFiles', data.files_uploaded);
    // Temporary work around because API returns the project here and not the uploaded file
    const uploadedFiles = get(data, 'files_uploaded', [])
    return get(uploadedFiles, `[${uploadedFiles.length - 1}]`, {})
  },
  async addProjectTool({ dispatch, state }, { tool, projectId }) {
    if (!tool?.id || !projectId) {
      return
    }

    await dispatch('getProjectById', { id: projectId, silent: true })
    const currentProjectToolIds = state.currentProject?.relationships?.tools?.map(t => t.id?.toString())
    if (currentProjectToolIds.includes(tool?.id?.toString()) && !tool?.attributes?.allow_multiple) {
      return
    }

    await axios.post(`/restify/projects/${projectId}/attach/tools`, {
      tools: [tool.id],
      order: currentProjectToolIds.length + 1,
      options: [tool.attributes?.default_options]
    })
    await dispatch('getProjectById', { id: projectId, forceFetch: true, silent: true })
  },
  async addMultipleProjectTools({ dispatch, commit }, { toolIds, projectId }) {
    if (!toolIds?.length || !projectId) {
      return
    }

    const project = await dispatch('getProjectById', { id: projectId, silent: true, returnEntity: true })
    const currentToolIds = project?.relationships?.tools?.map(t => t.id?.toString())

    const newToolIds = toolIds.filter(id => !currentToolIds.includes(id.toString()))

    if (!newToolIds.length) {
      return
    }

    await axios.post(`/restify/projects/${projectId}/attach/tools`, {
      tools: newToolIds,
    })

    const updatedProject = await dispatch('getProjectById', { id: projectId, silent: true, returnEntity: true })
    
    commit('updateProject', updatedProject)
  },
  async removeProjectTool({ dispatch, state }, params ) {
    const { tool, projectId = state.currentProjectId } = params
    const toolId = tool.pivots?.id
    if (!toolId || !projectId) {
      return
    }
    await axios.delete(`/restify/project-tool/${toolId}`)
    await dispatch('getProjectById', { id: projectId, forceFetch: true, silent: true })
  },
  async addProjectUsers({ dispatch, commit }, { users, groupId, projectId }) {
    if (!users?.length || !projectId) {
      return
    }
    await axios.post(`/restify/projects/${projectId}/actions?action=attach-users-to-project`, {
      group_id: groupId,
      users,
    })

    const updatedProject = await dispatch('getProjectById', {
      id: projectId,
      returnEntity: true,
    })

    commit('updateProject', updatedProject)

    return updatedProject
  },
  async removeProjectUsers({ dispatch, state }, { users, projectId }) {
    if (!users.length || !projectId) {
      return
    }
    await axios.post(`/restify/projects/${projectId}/detach/users`, {
      users,
    })
  },
  async toggleNotificationsForUser({ dispatch, state }, { user, project, notifiable_settings }) {
    try {
      const settings = []

      for (let key in notifiable_settings) {
        settings.push({
          user_id: user.id,
          notification_type: key,
          is_enabled: !!notifiable_settings[key],
        })
      }
      await axios.post(`/restify/projects/${project.id}/actions?action=update-project-notification`, {
        notifiable_settings: settings,
      })
    } catch (err) {
      if (err.handled) {
        return
      }
      error(i18n.t('Could not update user notification preferences'))
    }
  },
  async reorderProjectTools({ commit }, { tools }) {
    const orderedTools = tools.map((tool, index) => {
      const order = index + 1
      tool.pivots.order = order
      return {
        order,
        id: +tool?.pivots?.id,
      }
    })

    try {
      commit('updateProjectToolOrders', orderedTools)
      await axios.post(`restify/project-tool/bulk/update`, orderedTools)
    }
    catch (err) {
      if (err.handled) {
        return
      }

      error(i18n.t('Could not save the new order of tools'))
    }
  },
  async reorderProjectStatuses({}, { statuses }) {
    const orderedStatuses = statuses.map((status, index) => {
      const order = index + 1
      status.attributes.order = order
      return {
        order,
        id: +status.id,
      }
    })
    .slice(1, statuses.length - 1)

    await axios.post(`restify/project-statuses/bulk/update`, orderedStatuses)
  },
  // ProjectNote
  async pinNote({ commit }, { id, pinned }) {
    try {
      await axios.post(`/restify/project-notes/${id}/actions?action=pin-note`, { pinned })
      commit('updateProjectNote', {
        id,
        prop: 'attributes.pinned',
        value: pinned,
      })
    } catch(e) {
      console.warn(e)
    }
  },
  async changeNoteVisibility({ commit }, { id, value }) {
    try {
      await axios.post(`/restify/project-notes/${id}/actions?action=change-visibility`, { visibility: value })
      commit('updateProjectNote', {
        id,
        value,
        prop: 'attributes.visibility',
      })
    } catch(e) {
      console.warn(e)
    }
  },
  async getProjectNoteById({ commit, state }, { id, forceFetch = false }) {
    try {
      commit('setNotesLoading', { type: 'current', val: true })
      let note = state.notes.find(n => n.id.toString() === id.toString())
      if (note && !forceFetch) {
        commit('setCurrentProjectNote', note)
      }
      else {
        const data = await axios.get(`/restify/project-notes/${id}`)
        note = data.data
        commit('setCurrentProjectNote', note)
      }
      return note
    } catch (err) {
      if (err.status === 404) {
        await router.push('/404')
        return
      }
      if (err.handled) {
        return
      }

      throw err
    }
    finally {
      commit('setNotesLoading', { type: 'current', val: false })
    }
  },
  async createNewProjectNote({ dispatch, state, commit }, params) {
    const {
      project_id = state.currentProjectId,
      name = 'Untitled',
      notes = '',
      pinned = false,
    } = params

    if (!project_id) {
      return
    }
    try {
      commit('setNotesLoading', { type: 'create', val: true })
      await nextTick()
      scrollToBottom('#note-list-container')
      const visibility = getSetting('default_note_privacy', NotesVisibility.CREATORS_EDIT_COLLABORATORS_RESTRICT)

      const { data } = await axios.post(`/restify/project-notes`, {
        project_id,
        name,
        notes,
        pinned,
        visibility,
      })
      if (project_id === state.currentProjectId) {
        await dispatch('addProjectNote', data)
        commit('setNotesLoading', { type: 'create', val: false })
      }
      return data
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    } finally {
      commit('setNotesLoading', { type: 'create', val: false })
    }
  },
  async saveCurrentProjectNote({ state, commit }) {
    const { id = '' } = state.currentProjectNote;
    if (!id) {
      return
    }

    try {
      commit('setNotesLoading', { type: 'current', val: true })

      const model = {
        ...state.currentProjectNote?.attributes || {},
      }

      model.name = model.name || getNoteName(model.notes)

      delete model.description_collaboration // we save this through realtime server

      await axios.patch(`/restify/project-notes/${id}`, model);
    }
    catch (err) {
      console.error(err)
    }
    finally {
      commit('setNotesLoading', { type: 'current', val: false })
    }
  },
  async overwriteProjectNoteDescription({ state, commit }, { id, project_id, description }) {
    const isOpenedProject = project_id == state.currentProjectId
    try {
      if (isOpenedProject) {
        commit('setNotesLoading', { type: 'current', val: true })
      }

      const { data } = await axios.patch(`/restify/project-notes/${id}`, {
        notes: description,
        description_collaboration: null,
      });

      if (project_id == state.currentProjectId) {
        dispatch('getProjectById', { id: project_id, forceFetch: true, silent: true })
      }

      return data
    }
    catch (err) {
      console.warn(err)
    }
    finally {
      commit('setNotesLoading', { type: 'current', val: false })
    }
  },
  async removeProjectNote({ dispatch, commit, getters, state }, params) {
    const { note } = params
    if (!note.id) {
      return
    }

    await axios.delete(`/restify/project-notes/${note.id}`)

    commit('removeNotes', [ note.id ])

    if (!state.notes.length || note.attributes.pinned) {
      dispatch('getProjectById', { id: state.currentProjectId, forceFetch: true, silent: true })
    }
  },
  // Notes actions
  async getNotes({ state, commit }, filters) {
    try {

      if (filters?.shouldReset === true) {
        commit("setNotes", { data: [] });
      }

      filters = filters || {...rootState.route?.query }
      commit("setNotesLoading", { type: 'all', val: true });
      const notes = await axios.get("/restify/project-notes", {
        params: {
          project_id: state.currentProjectId,
          perPage: 100,
          ...filters,
        },
      });

      commit("setNotes", notes);
    } finally {
      commit("setNotesLoading", { type: 'all', val: false });
    }
  },
  async addProjectNote({ commit }, note) {
    try {
      if (!note?.attributes) {
        const { data } = await axios.get(`/restify/notes/${note}`);
        note = data
      }
      commit("addProjectNote", note)
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async setNotesPinned({ commit }, { note_ids, is_pinned }) {
    try {
      await axios.post(`/restify/notes/actions?action=mark-as-pinned`, {
        repositories: note_ids,
        is_pinned
      })

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  // TODO ProjectLink
  async createProjectLink({}, { data }) {
    try {
      const url = `/restify/project-links`
      return await axios.post(url, data)
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async editProjectLink({}, { linkId, data }) {
    try {
      const url = `/restify/project-links/${linkId}`
      return await axios.put(url, data)
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async createProjectReference({}, { data }) {
    try {
      const url = `/restify/project-references`
      return await axios.post(url, data)
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async editProjectReference({}, { referenceId, data }) {
    try {
      const url = `/restify/project-references/${referenceId}`
      return await axios.put(url, data)
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
}

const getters = {
  activeColumns: (state) => {
    const { mainColumns, extraColumns } = getProjectColumns()

    let columns = mainColumns

    columnBuilder.addCustomFieldColumns(columns, 'project', 'attributes.deadline', { onCellValueChanged, isPropEditable })

    columnBuilder.addCustomColumns(columns, extraColumns)

    return columns
  },
  tableColumns: (state, getters) => {
    return getters.activeColumns.filter(c => c.visibleInTable !== false)
  },
  orderedProjectTools: state => {
    return sortBy(state.currentProject?.relationships?.tools, 'pivots.order')
  },
  orderedStatuses: state => {
    const statuses = state.statuses?.data || []
    return sortBy(statuses, 'attributes.order').map(status => ({
      ...status,
      id: Number(status.id),
    }))
  },
  defaultProjectStatus: (state, getters) => {
    const statuses = getters.orderedStatuses
    const defaultStatusId = +getSetting('default_project_status')
    const fallbackStatus = statuses[0]
    if (defaultStatusId) {
      return statuses.find(s => s.id === defaultStatusId) || fallbackStatus
    }
    return fallbackStatus
  },
  completedStatus: (state, getters) => {
    return getters.orderedStatuses[getters.orderedStatuses.length - 1]
  },
  isCurrentProjectClosed(state, getters) {
    if (!state.currentProject) {
      return false
    }

    return getters.isProjectClosed(state.currentProject)
  },
  isProjectClosed: (state, getters) => (project) => {
    return project?.attributes?.status_id?.toString() === getters.completedStatus?.id?.toString()
  },
  projects: (state, getters, rootState) => {
    const allProjects = rootState.projects.allProjects || []

    return allProjects.filter(p => !p.attributes?.is_template)
  },
  projectsOrTemplatesData(state, getters, rootState, rootGetters) {
    const isTemplatePath = rootGetters['templates/isTemplatePath']
    const data = isTemplatePath
      ? rootGetters['templates/templates']
      : getters.projects

    // const projectUsersBinding = state.projectUsersBinding
    // const projectGroupsBinding = state.projectGroupsBinding

    // const allUsers = rootState.users.allUsers || []
    // const allGroups = rootState.groups.allGroups || []

    return data.map(project => {
      let user_ids = state.projectToUsersBindingMap[project.id]?.user_ids || []
      let group_ids = state.projectToGroupsBindingMap[project.id]?.group_ids || []

      let users = state.projectToUsersBindingMap[project.id]?.users || []
      let groups = state.projectToGroupsBindingMap[project.id]?.groups || []

      if (!user_ids.length) {
        user_ids = project.relationships?.users?.map(u => u.id) || []
        users = project.relationships?.users || []
      }

      if (!group_ids.length) {
        group_ids = project.relationships?.groups?.map(g => g.id) || []
        groups = project.relationships?.groups || []
      }
  
      return {
        ...project,
        attributes: {
          ...project.attributes,
          user_ids,
          group_ids
        },
        relationships: {
          ...project.relationships,
          users,
          groups,
        }
      }
    })
  },
  groupedProjects: (state, getters, rootState, rootGetters) => (projects, groupBy = null) => {
    let allProjects = projects || getters.projectsOrTemplatesData

    groupBy = groupBy || rootGetters['filters/targetLocalFilters']('projects')?.groupBy
    const groupByColumn = groupBy?.[0];
    let groupByKey = groupByColumn
    const isCustomFieldGrouping = (groupByKey || '').startsWith('attributes.custom_fields')

    const arrayValueKeys = ['relationships.groups', 'relationships.users', 'attributes.group_ids']

    let groupedProjects
    if (arrayValueKeys.includes(groupByKey) || isCustomFieldGrouping) {
      if (groupByColumn?.customField?.attributes?.custom_field_type === 'select-color') {
        groupByKey += '.value'
      }
      groupedProjects = groupByArrayValues(allProjects, groupByKey, isCustomFieldGrouping)
    }
    else {
      groupedProjects = groupEntitiesToMap(allProjects, groupByKey)
    }

    return groupedProjects
  },
  getStatusColor: state => statusId => {
    return state.statuses?.data?.find(s => s?.id?.toString() === statusId?.toString())?.attributes?.color || 'rgb(107, 114, 128)'
  },
  toolById: state => id => {
    return state.tools?.data?.find(s => s?.id?.toString() === id?.toString())
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
