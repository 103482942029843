import i18n from "@/i18n"
import store from "@/store";
import {
  TableColumn,
  getDefaultOptionsColumn, doesFilterPassTypesMap, FilterTypes
} from '@/components/table/tableUtils'
import { ColumnTypes } from '@/components/table/cells/tableCellComponents'
import {
  GetQuickFilterTextParams, ValueFormatterParams,
} from "@ag-grid-community/core";
import { ProofsStatuses } from "@/modules/file-proofs/utils/proofModels";


export function getProofColumn(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      name: i18n.t('Name'),
      prop: 'attributes.name',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: ColumnTypes.ProofName,
      },
      sortProp: 'name',
      sortable: true,
      required: true,
      minWidth: 400,
      flex: 1,
      getQuickFilterText: (params: GetQuickFilterTextParams<any>) => params?.data?.attributes?.name,
      filterBy: {
        prop: 'name',
        type: 'text',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.Text]('attributes.name'),
      },
    },
    {
      name: i18n.t('Progress'),
      prop: 'attributes.progress',
      component: ColumnTypes.ProofProgress,
    },
    {
      name: i18n.t('Status'),
      prop: 'attributes.decision_status',
      component: ColumnTypes.ProofStatus,
      sortProp: 'decision_status',
      enableRowGroup: true,
      sortable: true,
      comparator: (status1: ProofsStatuses, status2: ProofsStatuses) => {
        const statuses = store.getters['proofs/orderedStatuses'] || []

        const status1Entity = statuses.find((status: any) => status.attributes.value == status1)
        const status2Entity = statuses.find((status: any) => status.attributes.value == status2)

        return (status1Entity?.attributes?.order || 0) - (status2Entity?.attributes?.order || 0)
      },
    },
    {
      name: i18n.t('Decisions'),
      prop: 'attributes.decision_made',
      valueFormatter: ({ data }: ValueFormatterParams) => {
        const { decision_made = 0, decision_total = 0} = data?.attributes || {}
        return `${decision_made} ${i18n.t('of')} ${decision_total}`
      },
    },
    {
      name: i18n.t('Created Date'),
      prop: 'attributes.created_at',
      sortProp: 'created_at',
      showCardLabel: true,
      enableRowGroup: true,
      sortable: true,
      component: ColumnTypes.Date,
      filterBy: {
        prop: 'created_at',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.DateRange]('attributes.created_at'),
      },
    },
    {
      name: i18n.t('Owner'),
      prop: 'relationships.creator',
      component: ColumnTypes.ProofUser,
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn(),
  }

  const extraColumns = [optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}

export const proofFields = [
  'id',
  'name',
  'project_id',
  'thumbnail_link',
  'progress',
  'status',
  'reviewers_ids',
  'notify_ids',
]
