import i18n from "@/i18n.js";
import { encodeFilters } from "@/modules/common/utils/filterUtils";

export function userProjectsFilter(user) {
  return encodeFilters([
    {
      key: "sorts",
      value: {
        column: "created_at",
        order: "desc"
      }
    },
    {
      key: "filters",
      value: [
        {
          name: i18n.t('Person'),
          column: "user_ids",
          query: [user?.id],
          displayValue: user?.attributes?.name
        }
      ]
    },
    {
      key: 'closed',
      value: {
        show: false
      }
    },
  ])
}

export function userGroupsFilter(groups) {
  return encodeFilters([
    {
      key: "filters",
      value: [
        {
          name: i18n.t('Groups'),
          column: "group_ids",
          query: groups.map(user => user.id),
          displayValue: groups.map(user => user?.attributes?.name).join(', ')
        }
      ]
    },
  ])
}


export function userTimeFilter(user) {
  return encodeFilters([
    {
      key: "filters",
      value: [
        {
          name: i18n.t('Person'),
          column: "user_ids",
          query: [user?.id],
          displayValue: user?.attributes?.name
        }
      ]
    },
  ])
}

export function userTasksFilter(user) {
  return encodeFilters([
    {
      key: "filters",
      value: [
        {
          name: i18n.t('Assigned'),
          column: "allocated_ids",
          query: [user?.id],
          displayValue: user?.attributes?.name
        }
      ]
    },
    {
      key: "completed",
      value: {
        show: false
      }
    },
  ])
}

export function convertDateRangeToAdvancedFilter(query, column = 'date') {
  let advancedFilters = query?.filters || ''
  try {
    if (query.start && query.end) {
      advancedFilters = JSON.parse(atob(advancedFilters))
      const index = advancedFilters.findIndex(f => f.key === 'filters')
      const value =  {
        column,
        hidden: true,
        query: { min: query.start, max: query.end }
      } 
      if (index !== -1) {
        const filters = advancedFilters[index].value || [] 
        const filterIndex = filters.findIndex(f => f.column === column)
        
        if (filterIndex !== -1) {
          advancedFilters[index].value[filterIndex] = value 
        } else {
          advancedFilters[index].value.push(value)
        }
      }
      else {
        advancedFilters.push({
          key: 'filters',
          value: [value],
        })
      }
      advancedFilters = btoa(JSON.stringify(advancedFilters))
      query.filters = advancedFilters
    }
  } catch (err) {
    console.log('Error parsing date range filters')
  }
  return query
}
