<template>
  <div class="mx-auto max-w-2xl mt-6 pb-8">
    <TrialManagerHeader />

    <TrialManagerItems />

    <dl class="mt-10 divide-y divide-gray-900/10 border border-gray-200 rounded-lg">
      <div class="grid grid-cols-2 divide-x divide-gray-200">
        <div class="col-span-1">
          <KnowledgeBaseCard />
        </div>
        <div class="col-span-1">
          <ContactSupportCard />
        </div>
      </div>
    </dl>
  </div>
</template>
<script lang="ts" setup>
import { onBeforeMount } from "vue";

import TrialManagerHeader from "@/modules/accounts/components/trial-manager/TrialManagerHeader.vue";
import TrialManagerItems from "@/modules/accounts/components/trial-manager/TrialManagerItems.vue";
import KnowledgeBaseCard from "@/modules/accounts/components/getting-started/KnowledgeBaseCard.vue";
import ContactSupportCard from "@/modules/accounts/components/getting-started/ContactSupportCard.vue";

import {
  useTrialManager
} from '@/modules/accounts/composables/useTrialManager';

const {
  getTrialFeaturesState
} = useTrialManager()

onBeforeMount(() => {
  getTrialFeaturesState()
})
</script>
