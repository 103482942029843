export const colors = [
  '#0ea5e9',
  '#10b981',
  '#8b5cf6',
  '#f97316',
  '#f43f5e',
  '#14b8a6',
  '#eab308',
  '#ec4899',
  '#6b7280'
]

function stringToHash(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) - hash) + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

export function getUserColor(name: string) {
  const realIndex = stringToHash(name) % colors.length
  return colors[realIndex] || colors[0]
}
