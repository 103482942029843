<template>
  <BaseTooltip :content="$t('Select file from Google Drive')" :show-after="500">
    <div @click="onClick">
      <slot>
        <button
          class="p-2 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
        >
          <i class="fa-brands fa-google-drive" />
        </button>
      </slot>
    </div>
  </BaseTooltip>
</template>
<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const [route, router] = [
  useRoute(),
  useRouter()
]

const props = defineProps({
  editor: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['select'])

const apiScriptLoaded = ref(false)
const pickerInitialized = ref(false)
const accessToken = ref(null)

function onClick() {
  if (!apiScriptLoaded.value) {
    setTimeout(onClick, 1000)
    return
  }
  createPicker()
}

function pickerCallback(data: any) {
  if (data[window.google.picker.Response.ACTION] !== window.google.picker.Action.PICKED) {
    return
  }
  const documents = data.docs;
  documents.forEach((file: any)=> {
    addFile({
      link: file.url,
      icon: file.iconUrl,
      name: file.name,
      bytes: file.sizeBytes,
    })
  })

  emit('select', documents)
}

async function createPicker() {
  const showPicker = () => {
    const mainListView = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
      .setParent('root')
      .setMode(window.google.picker.DocsViewMode.LIST)
      .setIncludeFolders(true)

    const sharedDrivesView = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
      .setMode(window.google.picker.DocsViewMode.LIST)
      .setEnableDrives(true)
      .setIncludeFolders(true)

    const picker = new window.google.picker.PickerBuilder()
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .addView(mainListView)
      .addView(sharedDrivesView)
      .setOAuthToken(accessToken.value)
      .setDeveloperKey(import.meta.env.VITE_GOOGLE_PICKER_KEY)
      .setCallback(pickerCallback)
      .build();

    picker.setVisible(true);
  }

  if (!accessToken.value) {
    accessToken.value = await store.dispatch('accounts/getGooglePickerAccessToken')
  }

  if (!accessToken.value) {
    // If we still don't have an access token, we simply cancel picker opening flash, because the user was already redirected
    return
  }

  showPicker()
}


function prepareEditor() {
  return props.editor
  .chain()
  .focus()
}

function addFile(file: any) {
  prepareEditor().setExternalFile({
    url: file.link,
    icon: file.icon,
    name: file.name,
    size: file.bytes,
  }).run()
}

function checkShouldOpenPicker() {
  if (!route.query['google-api']) {
    return
  }

  createPicker()

  const query = {
    ...route.query
  }
  delete query['google-api']

  router.replace({
    query
  })
}

function onPickerApiLoad() {
  pickerInitialized.value = true;
}

function initApiScript() {
  if (window.gapi) {
    apiScriptLoaded.value = true
    return
  }
  const script = document.createElement('script');
  script.src = 'https://apis.google.com/js/api.js';
  script.id = 'google-api';
  script.onload = () => {
    window.gapi.load('picker', onPickerApiLoad);
    apiScriptLoaded.value = true
    checkShouldOpenPicker()
  };
  document.body.appendChild(script);
}

onBeforeMount(() => {
  initApiScript()
})
</script>
