<template>
  <tr
    v-for="i in rowCount"
    :key="i"
    class="first-of-type:rounded-t-lg first-of-type:shadow-t sm:first-of-type:rounded-none bg-white block space-y-0 sm:table-row p-4 sm:px-0 sm:py-0 relative"
  >
    <td
      v-for="column in columns"
      :key="column.prop"
      class="block sm:table-cell text-sm text-gray-500"
      :class="getCellClasses(column)"
    >
      <div v-if="column.prop === undefined" />
      <div
        v-else-if="isImage(column)"
        class="bg-gray-100 rounded-md h-10 w-10"
      />
      <div
        v-else-if="isStatus(column)"
        class="bg-gray-100 rounded-md h-5 w-16"
      />
      <div
        v-else-if="isCompleted(column)"
        class="bg-gray-100 rounded-full h-7 w-7"
      />
      <div
        v-else-if="isOptions(column)"
        class="bg-gray-100 rounded-md h-7 w-12"
      />
      <div v-else class="bg-gray-100 rounded w-full h-5 min-w-[20px]" />
    </td>
  </tr>
</template>
<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rowCount: {
      type: Number,
      default: 5,
    },
    getCellClasses: {
      type: Function,
      default: () => ({})
    }
  },
  methods: {
    isImage(column) {
      const imageProps = ['image', 'avatar', 'allocations']
      return imageProps.some(prop => column?.prop?.includes(prop))
    },
    isStatus(column) {
      return column?.prop?.includes('status')
    },
    isOptions(column) {
      return column?.prop?.includes('options')
    },
    isCompleted(column) {
      return column?.prop?.includes('completed')
    }
  }
}
</script>
