<template>
  <div class="infinite-scroll">
    <div
      v-if="direction === 'up'"
      v-show="!reachedEnd"
      ref="triggerEl"
    >
      <slot name="spinner">
        <DataSyncingIndicator
          class="text-lg mt-0"
          :class="$attrs.loaderClass"
        />
      </slot>
    </div>

    <slot />

    <div
      v-if="direction === 'down'"
      v-show="!reachedEnd"
      ref="triggerEl"
    >
      <slot name="spinner">
        <DataSyncingIndicator
          class="text-lg mt-0"
          :class="$attrs.loaderClass"
        />
      </slot>
    </div>
  </div>
</template>
<script>
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"
import { useIntersectionObserver } from '@vueuse/core'
import { ref } from 'vue'

export default {
  name: 'InfiniteScroll',
  components: {
    DataSyncingIndicator
  },
  props: {
    direction: {
      type: String,
      default: 'down'
    },
    distance: {
      type: Number,
      default: 0
    },
    scrollableContainerSelector: {
      type: String,
      default: '',
    },
    scrollableElSelector: {
      type: String,
      default: '.scrollable-el'
    },
    loadNext: {
      type: Function,
      required: true
    },
    scrollBehavior: {
      type: String,
      default: 'smooth'
    }
  },
  setup() {
    const triggerEl = ref(null)
    const isTriggerVisible = ref(false)
    
    const { stop } = useIntersectionObserver(triggerEl, ([{ isIntersecting }]) => {
      isTriggerVisible.value = isIntersecting
    })

    return {
      triggerEl,
      isTriggerVisible,
    }
  },
  data() {
    return {
      scrollableContainer: null,
      triggerKey: 1,
      scrollToEl: null,
      lastScrollTop: 0,
      reachedEnd: false,
      state: {
        loaded: async () => {
          if (this.direction !== 'up' || !this.scrollToEl || !this.triggerEl) {
            return
          }

          const scrollTopffset = this.scrollToEl.offsetTop - this.distance - this.triggerEl.offsetHeight
          await this.$nextTick()
          this.scrollableContainer.scrollTo(0, scrollTopffset)
        },
        complete: () => {
          this.reachedEnd = true
        },
        reset: () => {
          this.reachedEnd = false
          this.init()
        },
        checkVisibility: () => {
          this.triggerVisibilityCheck()
        }
      }
    }
  },
  watch: {
    isTriggerVisible: {
      handler(value) {
        if (!value || this.reachedEnd) {
          return
        }

        this.loadNext(this.state)
      },
    },
    triggerKey() {
      if (!this.isTriggerVisible || this.reachedEnd) {
        return
      }
      
      this.loadNext(this.state)
    }
  },
  methods: {
    async initScrollPosition() {
      if (this.direction !== 'up') {
        return
      }

      await this.$nextTick()

      const scrollables = this.$el.querySelectorAll(this.scrollableElSelector)
      const lastEl = scrollables[scrollables.length - 1]
      if (lastEl) {
        lastEl.scrollIntoView({
          block: 'center',
          behavior: this.scrollBehavior
        })
      }
    },
    async init() {
      await this.loadNext(this.state)
      await this.initScrollPosition()
    },
    async triggerVisibilityCheck() {
      if (this.reachedEnd) {
        return
      }
      
      setTimeout(() => {
        const { stop } = useIntersectionObserver(this.triggerEl, ([{ isIntersecting }]) => {
          this.isTriggerVisible = isIntersecting
        })
        this.triggerKey++
        this.triggerVisibilityCheck()
      }, 50);
    },
  },
  async mounted() {
   await this.init()
  },
}
</script>
