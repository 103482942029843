import i18n from "@/i18n"
import store from "@/store/index.js";
import { ColumnTypes, HeaderTypes } from '@/components/table/cells/tableCellComponents'

import TimeEntry = App.Domains.Time.Models.TimeEntry
import Data = API.Data;

import { formatTimeSpent } from "@/modules/common/utils/dateUtils";
import {
  ComparatorTypes,
  comparatorTypesMap,
  getDefaultOptionsColumn,
  doesFilterPassTypesMap,
  FilterTypes,
  TableColumn
} from "@/components/table/tableUtils";
import { customFieldAppliesToOptions } from "@/modules/accounts/utils/modelUtils";
import {
  GetQuickFilterTextParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams
} from "@ag-grid-community/core";
import useCan from "@/modules/common/composables/useCan";
import { minutesToFormattedTime } from "@/modules/common/utils/timeUtils";
import { TimerStatusTypes } from '@/modules/time/utils/timeEntryUtils.js'

const { can, actions } = useCan()

export function getTimeEntryColumns(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      name: i18n.t('Group'),
      prop: 'relationships.group.attributes.name',
      component: ColumnTypes.Link,
      params: {
        entityPath: 'relationships.group',
      },
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data?.relationships?.group?.attributes.name
      },
      flex: 1,
      minWidth: 150,
      showCardLabel: true,
      sortable: true,
      sortProp: 'group',
      enableRowGroup: true,
      chartDataType: 'category',
      filterBy: {
        prop: 'group_ids',
        component: 'GroupSelect',
        props: {
          multiple: true,
          urlParams: {
            hasTimeEntries: true
          }
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.group_id')
      },
    },
    {
      name: i18n.t('Person'),
      prop: 'relationships.user.attributes.name',
      component: ColumnTypes.Link,
      params: {
        entityPath: 'relationships.user'
      },
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data?.relationships?.user?.attributes.name
      },
      flex: 1,
      minWidth: 150,
      showCardLabel: true,
      sortable: true,
      sortProp: 'user',
      chartDataType: 'category',
      filterBy: {
        prop: 'user_ids',
        component: 'UserSelect',
        props: {
          multiple: true,
          urlParams: {
            hasTimeEntries: true
          }
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.user_id')
      },
      required: true,
      enableRowGroup: true,

    },
    {
      name: i18n.t('Project'),
      prop: 'relationships.project.attributes.name',
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data?.relationships?.project?.attributes?.name || ''
      },
      component: ColumnTypes.Project,
      params: {
        entity_type: i18n.t('time entry')
      },
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.RelationshipName]('relationships.project.attributes.name'),
      flex: 1,
      minWidth: 150,
      showCardLabel: true,
      sortProp: 'project',
      chartDataType: 'category',
      filterBy: {
        prop: 'project_ids',
        component: 'ProjectSelect',
        props: {
          multiple: true
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.project_id')
      },
      enableRowGroup: true,
      disabled: () => {
        return !!store.getters.project_id
      }
    },
    {
      name: i18n.t('Task'),
      prop: 'relationships.task.attributes.name',
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data?.relationships?.task?.attributes?.name || ''
      },
      component: ColumnTypes.Task,
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.RelationshipName]('relationships.task.attributes.name'),
      flex: 1,
      minWidth: 150,
      showCardLabel: true,
      sortProp: 'task',
      chartDataType: 'category',
      filterBy: {
        prop: 'task_ids',
        component: 'TaskSelect',
        props: {
          multiple: true
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.task_id')
      },
      enableRowGroup: true,
      class: 'w-20',

    },
    {
      name: i18n.t('Date'),
      prop: 'attributes.date',
      component: ColumnTypes.Date,
      showCardLabel: true,
      sortable: true,
      sortProp: 'date',
      maxWidth: 150,
      comparator: comparatorTypesMap[ComparatorTypes.Date]('attributes.date'),
      filterBy: {
        prop: 'date',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.DateRange]('attributes.date')
      },
      enableRowGroup: true,
      required: true,
    },
    {
      name: i18n.t('Time'),
      prop: 'attributes.worked_minutes',
      showCardLabel: true,
      sortable: true,
      sortProp: 'worked_minutes',
      maxWidth: 120,
      comparator: comparatorTypesMap[ComparatorTypes.Numeric]('attributes.worked_minutes'),
      valueGetter: (params: ValueGetterParams) => {
        if (params?.data?.attributes?.status === TimerStatusTypes.Recording) {
          const startedAt = new Date(params?.data?.attributes?.timer_started_at).getTime()
          const now = new Date().getTime()
          const diff = Math.floor((now - startedAt) / 1000 / 60)

          if (diff < 0) {
            return 0
          }
          
          return diff
        }

        return params?.data?.attributes?.worked_minutes || 0
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return minutesToFormattedTime(params.value)?.toString()
      },
      enableRowGroup: true,
      required: true,
      aggFunc: 'sum',
      valueParser(params: ValueParserParams) {
        if (!params) {
          return
        }
        return +params.newValue
      },
      chartDataType: 'series',
      filterBy: {
        prop: 'worked_minutes',
        type: 'range-slider',
        displayFormat: 'formatTimeSpentRange',
        props: {
          formatTooltip(minutes: number) {
            return formatTimeSpent(minutes);
          },
          range: () => {
            return store.getters[`time/timeEntriesDataPropRange`]('attributes.worked_minutes')
          }
        }
      },
    },
    {
      name: i18n.t('Timer'),
      prop: 'timer-actions',
      component: ColumnTypes.TimeRunningClock,
      width: 105,
      minWidth: 105,
      maxWidth: 105,
      disabled() {
        return !(can(actions.EDIT_TIME_ENTRIES) && can(actions.TASK_TIMERS))
      }
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn(),
  }

  const addCustomFieldColumn: TableColumn = {
    headerComponent: HeaderTypes.AddCustomField,
    prop: 'add-custom-field',
    headerComponentParams: {
      defaultAppliesTo: customFieldAppliesToOptions.TIME_ENTRY,
      tooltip: i18n.t('Add a new custom field for all time entries across the account')
    },
    valueFormatter(params: ValueFormatterParams) {
      return ''
    },
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    disabled: () => {
      return !can(actions.CREATE_CUSTOM_FIELDS) || store.getters['projects/isCurrentProjectClosed']
    },
    showInChooseColumns: false
  }

  const extraColumns = [addCustomFieldColumn, optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}

export const getExtraFilters = () => {
  const filters = [
    {
      key: 'my-time',
      doesFilterPass: (row: Data<TimeEntry>, filterValue: { show: boolean }) => {
        if (filterValue?.show) {
          return (+row.attributes.user_id === +store.state.auth.user.id)
        }

        return true
      },
    }
  ]

  return filters
}

export const timeEntryFields = [
  'id',
  'group_id',
  'project_id',
  'task_id',
  'user_id',
  'date',
  'worked_minutes',
  'timer_started_at',
  'custom_fields',
]
