import i18n from "@/i18n"
import store from "@/store/index.js";

import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";
import { getGroupFilter } from "@/modules/groups/utils/filterUtils.js";
import { encodeFilters } from "@/modules/common/utils/filterUtils.js";
import { customFieldAppliesToOptions } from "@/modules/accounts/utils/modelUtils"

import { CREATE_CUSTOM_FIELDS } from "@/modules/common/enum/actionsEnum";
import { ColumnTypes, HeaderTypes } from '@/components/table/cells/tableCellComponents'
import { TableColumn, FilterTypes, doesFilterPassTypesMap, getDefaultOptionsColumn, getEntityImageColumn } from "@/components/table/tableUtils";
import { GetQuickFilterTextParams, ValueFormatterParams } from "@ag-grid-community/core";

export function getGroupColumns(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      ...getEntityImageColumn()
    },
    {
      name: i18n.t('Name'),
      prop: 'attributes.name',
      sortProp: 'name',
      sortable: true,
      required: true,
      flex: 1,
      minWidth: 180,
      component: ColumnTypes.Link,
      filterBy: {
        prop: 'name',
        type: 'text',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.Text]('attributes.name')
      },
      getQuickFilterText: (params: GetQuickFilterTextParams<any>) => {
        return params.data?.attributes?.name
      },
      extendedCellClass: 'w-full',
      cardClass: 'font-bold text-gray-900 text-base leading-5 inline-flex'
    },
    {
      name: i18n.t('People count'),
      prop: 'attributes.users_count',
      showCardLabel: true,
      sortProp: 'users_count',
      sortable: true,
      filterBy: {
        prop: 'users_count',
        type: 'text',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.Numeric]('attributes.users_count')
      },
      component: ColumnTypes.Link,
      params: {
        getLink(row: any) {
          return {
            path: '/users/list',
            query: {
              filters: groupFilter(row)
            }
          }
        },
      },
    },
    {
      name: i18n.t('Projects count'),
      prop: 'attributes.projects_count',
      showCardLabel: true,
      sortProp: 'projects_count',
      sortable: true,
      filterBy: {
        prop: 'projects_count',
        type: 'text',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.Numeric]('attributes.projects_count')
      },
      component: ColumnTypes.Link,
      params: {
        getLink(row: any) {

          const filters = groupFilter(row, [{
            key: 'closed',
            value: {
              show: true
            }
          }])

          return {
            path: '/projects/list',
            query: {
              filters
            }
          }
        }
      },
    },
    {
      name: i18n.t('Created Date'),
      prop: 'attributes.created_at',
      showCardLabel: true,
      sortProp: 'created_at',
      sortable: true,
      component: ColumnTypes.Date,
      filterBy: {
        prop: 'created_at',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.DateRange]('attributes.created_at')
      },
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn(),
    disabled: () => {
      return !store.getters['users/isCurrentUserRoleGreaterOrEqual'](rolesEnum.CREATOR)
    }
  }

  const addCustomFieldColumn: TableColumn = {
    headerComponent: HeaderTypes.AddCustomField,
    prop: 'add-custom-field',
    headerComponentParams: {
      defaultAppliesTo: customFieldAppliesToOptions.GROUP,
      tooltip: i18n.t('Add a new custom field for all groups across the account')
    },
    valueFormatter(params: ValueFormatterParams) {
      return ''
    },
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    disabled: () => {
      if (!store.getters['users/can'](CREATE_CUSTOM_FIELDS)) {
        return true
      }

      return false
    },
    showInChooseColumns: false
  }

  const extraColumns: TableColumn[] = [addCustomFieldColumn, optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}

function groupFilter(group: any, extraFilters: any[] = []) {
  try {
    const filters = getGroupFilter(group, extraFilters)
    return encodeFilters(filters)
  } catch (err) {
    return ""
  }
}

export const groupFields = [
  'id',
  'name',
  'custom_fields',
  'image',
  'created_at',
]
