import { Form as VeeForm } from 'vee-validate'
import { TransitionRoot } from '@headlessui/vue'
import RequiresPermissionTo from "@/components/common/RequiresPermissionTo.vue"
import AccessControlIndicator from "@/components/common/AccessControlIndicator.vue"
import TaskDialog from "@/modules/tasks/components/TaskDialog.vue";
import HtmlEditor from "@/manual-components/HtmlEditor.vue";
import CollaborativeHtmlEditor from "@/manual-components/CollaborativeHtmlEditor.vue";
import FilePreviewDialog from "@/modules/files/components/FilePreviewDialog.vue";
import DiscussionComment from '@/modules/common/components/DiscussionComment.vue'
import LimitedFeature from '@/modules/accounts/components/LimitedFeature.vue'
import EntityViewTypesTabsAll from '@/modules/common/components/EntityViewTypesTabsAll.vue'

/**
 * This will require and make global all components starting with **Base** (e.g BaseButton, BaseInput etc) or **Icon** (e.g IconPhone)
 */
export default {
  install(Vue) {
    Vue.component('HtmlEditor', HtmlEditor)
    Vue.component('CollaborativeHtmlEditor', CollaborativeHtmlEditor)
    Vue.component('VeeForm', VeeForm)
    Vue.component('TransitionRoot', TransitionRoot)
    Vue.component('RequiresPermissionTo', RequiresPermissionTo)
    Vue.component('AccessControlIndicator', AccessControlIndicator)
    Vue.component('TaskDialog', TaskDialog)
    Vue.component('FilePreviewDialog', FilePreviewDialog)
    Vue.component('DiscussionComment', DiscussionComment)
    Vue.component('LimitedFeature', LimitedFeature)
    Vue.component('EntityViewTypesTabsAll', EntityViewTypesTabsAll)
  }
}

