import config from "@/modules/common/config.js";
import { error } from "@/components/common/NotificationPlugin/index.js";
import i18n from "@/i18n"

const PREVIEWABLE_FILE_EXTENSIONS = ["pdf", "txt", "png", "jpg", "webm", "mp4", "mp3", "wav"];

const DOC_FILE_EXTENSIONS = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"];

function getRawFile(file) {
  return file.raw || file.file || file;
}

function getFileExtension(file) {
  const rawFile = getRawFile(file);
  const parts = (rawFile.file_name || rawFile.name)?.split('.') || ''

  if (parts.length === 1) {
    return 'blank'
  }

  const extension = parts[parts.length - 1]
  if (!extension) {
    return 'txt'
  }

  return extension.toLowerCase()
}

export function getFileIcon(file) {
  return `fiv-viv fiv-icon-${getFileExtension(file)}`;
}

export function createFileURL(file, thumbnail = false) {
  if (!file.url) {
    file.url = `${config.getApiHost()}/media/preview/${file?.uuid}`
  }

  let fileUrl = file.url
  if (thumbnail) {
    fileUrl = `${config.getApiHost()}/media/thumbnail/${file?.uuid}`
  }

  return fileUrl
}

export function getFileDownloadUrl(file) {
  return `${config.getApiHost()}/media/download/${file?.uuid}`
}

export function revokeFileURL(file) {
  URL.revokeObjectURL(file.url)
}

export function isImage(file) {
  const rawFile = getRawFile(file);
  const type = rawFile.type || rawFile.mime_type;
  return type?.includes('image') && !type?.includes('tif')
}

export function isAudio(file) {
  return file.mime_type?.includes('audio')
}

export function isPreviewable(file) {
  return isImage(file)
    || isAudio(file)
    || PREVIEWABLE_FILE_EXTENSIONS.includes(getFileExtension(file))
}

export function isDoc(file) {
  return DOC_FILE_EXTENSIONS.includes(getFileExtension(file))
}

export function humanReadableSize(sizeInBytes, decimals = 2, preferMu = 'KB') {
  if (!sizeInBytes) {
    return `0 ${preferMu}`
  }
  const rank = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const mus = ['B', 'KB', 'MB', 'GB', 'TB'];
  const mu = mus[rank];
  const size = (sizeInBytes / Math.pow(1024, rank)).toFixed(decimals)

  return `${size * 1} ${mu}`;
}

export function bytesToMegaBytes(sizeInBytes) {
  return (sizeInBytes / Math.pow(1024, 2))
}

export const maxFileUploadSizeInBytes = Number(import.meta.env.VITE_MAX_FILE_UPLOAD_SIZE_IN_BYTES);
export const uploadSizeExceededMessage = i18n.tc('file size limit exceeded', {
  limit: humanReadableSize(maxFileUploadSizeInBytes)
})

export function checkFileSizeLimitExceeded(file, displayError = false) {
  const rawFile = getRawFile(file)

  file.sizeLimitExceeded = rawFile.size > maxFileUploadSizeInBytes

  if (file.sizeLimitExceeded && displayError) {
    error(uploadSizeExceededMessage)
  }

  return file.sizeLimitExceeded
}
