<template>
  <div
    class="h-full flex flex-col justify-center"
    :class="{
      'py-12 px-2 sm:px-6 lg:px-8': !$route.path.includes('/register'),
    }"
  >
    <div
      :class="{
        'sm:mx-auto sm:w-full sm:max-w-lg': !$route.path.includes('/register'),
        'h-full': $route.path.includes('/register'),
      }"
    >
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>
  </div>
</template>
<script setup />
