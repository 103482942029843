import { ChartRef, ColDef, ICellEditorParams, ICellRendererParams, RefreshCellsParams, RowNode } from '@ag-grid-community/core';
import { ColumnTypes } from '@/components/table/cells/tableCellComponents'
import {
  customFieldAppliesToOptions,
  customFieldToColumnMapper
} from "@/modules/accounts/utils/modelUtils"
import store from "@/store/index.js";
import { get } from "lodash-es"
import i18n from '@/i18n';

type DoesFilterPassFunction = (row: any, filterValue: any) => boolean;
type ComparatorFunction = (valueA: any, valueB: any, nodeA: RowNode<any>, nodeB: RowNode<any>, isDescending: boolean) => number

const optionsWidth = 70
export function getDefaultOptionsColumn(): TableColumn {
  return {
    name: i18n.t('Options'),
    prop: 'options',
    component: ColumnTypes.EntityActions,
    width: optionsWidth,
    minWidth: optionsWidth,
    maxWidth: optionsWidth,
    cardClass: 'entity-card-dropdown rounded-md border border-gray-200 overflow-hidden',
    cardProps: {
      slim: true
    },
    extendedCellClass: 'justify-center',
  }
}

const imgWidth = 60
export function getEntityImageColumn(): TableColumn {
  return {
    name: i18n.t('Img'),
    prop: 'attributes.image',
    component: ColumnTypes.EntityLogo,
    width: imgWidth,
    minWidth: imgWidth,
    maxWidth: imgWidth,
    cardClass: 'inline-flex bg-white',
    extendedCellClass: 'justify-center',
  }
}

export type FilterBy = {
  type?: string;
  prop: string;
  label?: string;
  component?: string;
  props?: any; // Component props
  format?: string | Function;
  displayFormat?: string | Function;
  doesFilterPass?: DoesFilterPassFunction,
  hidden?: boolean;
}

export type TableColumn = Omit<ColDef, 'sortable'> & {
  params?: any;
  customField?: any;
  showCardLabel?: boolean;
  cardProps?: any;
  cardClass?: string;
  cardIconClass?: string;
  cardOrder?: number;
  keepHidden?: boolean;
  sortProp?: string;
  filterBy?: FilterBy | (() => FilterBy | undefined);
  // Remapped
  name?: string | Function;
  prop?: string;
  component?: string;
  extendedCellClass?: string;
  extendedHeaderClass?: string;
  group?: boolean;
  isMainColumn?: boolean;
  visibleInTable?: boolean;
  showInChooseColumns?: boolean | Function;
  disabled?: boolean | Function;
  class?: string; // Needed ?
  enableGroupRow?: boolean | (() => boolean); // Needed ?
  required?: boolean; // Needed ?
  relatedProp?: string; // Needed ?
  headerPadding?: string; // Needed ?
  padding?: string; // Needed ?
  renderIf?: Function,
  sortable?: boolean | (() => boolean);
  enableWidgetFilter?: boolean;
  enableWidgetSort?: boolean;
}

export const defaultColDef: TableColumn = {
  sortable: false,
  resizable: true,
  minWidth: 100,
  getQuickFilterText: (params: any) => {
    return ''
  },
  comparator: (valueA: any, valueB: any) => {
    const [vA, vB] = [(valueA || '').toString().toLowerCase(), (valueB || '').toString().toLowerCase()]

    return vA.localeCompare(vB)
  },
  headerClass: 'px-3 py-1 bg-gray-50 text-left text-xs font-medium text-gray-500 whitespace-nowrap border-b-1 border-gray-200',
  cellClass: 'px-3 py-1 text-gray-500 whitespace-pre-wrap leading-relaxed flex items-center',
}

export function getCardViewColumns(initialColumns: TableColumn[]): TableColumn[] {
  // We also keep the main columns as hidden columns so that we can use them with Ag Grid's API (sorting, filtering, etc.)
  // TODO: always ensure they are hidden - even when sort/filter is changed
  const hiddenColumns = initialColumns.map((column) => ({
    ...column,
    hide: true
  }))

  const cardColumn: TableColumn = {
    headerName: 'Card',
    field: 'card',
    type: ColumnTypes.EntityCard,
    minWidth: 300,
    cellClass: 'p-0',
    wrapText: true,
    autoHeight: true,
    params: {
      initialColumns
    }
  }

  return [cardColumn, ...hiddenColumns]
}

export const defaultAutoGroupColDef: TableColumn = {
  flex: 1,
  minWidth: 180,
  cellRendererParams: {
    footerValueGetter: (params: any) =>  {
      return ''
    },
  }
}

export function agGridColumnMapper(col: TableColumn): TableColumn {
  return {
    ...col,
    headerName: col.name as string | undefined,
    field: col.prop,
    sortProp: col.sortProp,
    type: col.component,
    cellRenderer: col.cellRenderer,
    cellRendererParams: col.cellRendererParams,
    minWidth: col.minWidth,
    maxWidth: col.maxWidth,
    headerComponent: col.headerComponent,
    headerComponentParams: col.headerComponentParams,
    flex: col.flex,
    rowGroup: col.rowGroup,
    showRowGroup: col.showRowGroup,
    keepHidden: col.keepHidden,
    enableRowGroup: col.enableRowGroup,
    hide: col.hide,
    initialHide: col.initialHide,
    filter: col.filter,
    sortable: col.sortable,
    comparator: col.comparator,
    getQuickFilterText: col.getQuickFilterText,
    params: col.params,
    customField: col.customField,
    showCardLabel: col.showCardLabel,
    cardProps: col.cardProps,
    rowDrag: col.rowDrag,
    cardClass: col.cardClass,
    headerClass: col.headerClass || `${defaultColDef.headerClass} ${col.extendedHeaderClass || ''}`,
    cellClass: col.cellClass || `${defaultColDef.cellClass} ${col.extendedCellClass || ''}`,
    colId: col.colId,
    checkboxSelection: col.checkboxSelection,
    suppressMenu: col.suppressMenu,
    headerCheckboxSelection: col.headerCheckboxSelection,
    filterBy: col.filterBy,
    editable: col.editable,
  }
}

type CustomFieldColumnOptions = {
  onCellValueChanged: Function;
  isPropEditable: Function | boolean;
  params: any
}

export const columnBuilder = {
  addCustomFieldColumns(columns: any[], entityType: string, insertAfter: string = '', options: CustomFieldColumnOptions | null = null) {
    if (!Object.values(customFieldAppliesToOptions).includes(entityType)) {
      return columns
    }
    const customFields = store.getters['accounts/tableColumnCustomFields'](entityType)
    const customFieldsColumns = customFields.map((customField: any) => customFieldToColumnMapper(customField, options))

    if (insertAfter) {
      const colIndex = columns.findIndex((c: any) => c.prop === insertAfter)

      columns.splice(colIndex + 1, 0, ...customFieldsColumns)
    }
    else {
      columns.push(...customFieldsColumns)
    }
  },
  addCustomColumns(columns: any[], columnsToAdd: any[]) {
    columns.push(...columnsToAdd)
  },
  filterDisabledColumns(columns: any) {
    return columns.filter((col: any) => {
      if (typeof col.disabled === 'function') {
        return !col.disabled()
      }
      return !col.disabled
    })
  },
  remapDynamicProperties(columns: any[]) {

    // TODO: Change this logic to work the other way around
    // Declare function props that need to be executed: disabled, name, etc.
    const ignoredColumns = [
      'comparator',
      'getQuickFilterText',
      'onCellValueChanged',
      'valueSetter',
      'valueParser',
      'valueGetter',
      'cellEditorParams',
      'editable',
      'aggFunc',
      'valueFormatter'
    ]

    return columns.map((col: any) => {
      const newCol: any = {}
      for (const prop in col) {
        newCol[prop] = typeof col[prop] === 'function' && !ignoredColumns.includes(prop)
          ? col[prop]()
          : col[prop]
      }

      return newCol
    })
  },
}

function getRowPropValue(row: any, prop: string | ((row: any) => any), defaultValue: any = null) {
  return typeof prop === 'function'
    ? prop(row) || defaultValue
    : get(row, prop) || defaultValue
}

export enum FilterTypes {
  Text = 'text',
  Numeric = 'numeric',
  NumericRange = 'numericRange',
  DateRange = 'dateRange',
  RelationshipId = 'relationshipId',
  Select = 'select',
  InArray = 'inArray',
  Boolean = 'boolean',
}

type DoesFilterTypesValue = (prop: string | ((row: any) => any) ) => DoesFilterPassFunction

type DoesFilterPassTypesMap = Partial<Record<FilterTypes, DoesFilterTypesValue>>

export const doesFilterPassTypesMap: DoesFilterPassTypesMap = {
  [FilterTypes.Text]:  (prop: string | ((row: any) => any)) => (row: any, filterValue: string): boolean => {
    const value = getRowPropValue(row, prop)

    if (!value) {
      return false
    }

    return value.toLowerCase().includes(filterValue.toLowerCase())
  },
  [FilterTypes.Numeric]: (prop: string | ((row: any) => any)) => (row: any, filterValue: string | number): boolean => {
    const value = getRowPropValue(row, prop)

    if ([null, undefined].includes(value)) {
      return false
    }

    return Number(value) === Number(filterValue)
  },
  [FilterTypes.NumericRange]: (prop: string | ((row: any) => any)) => (row: any, filterValue: { min: string | number, max: string | number }): boolean => {
    const value = getRowPropValue(row, prop)

    if ([null, undefined].includes(value)) {
      return false
    }

    const numberValue = Number(value)

    if (isNaN(numberValue)) {
      return false
    }

    const min = Number(filterValue.min)
    const max = Number(filterValue.max)

    return numberValue >= min && numberValue <= max
  },
  [FilterTypes.DateRange]: (prop: string | ((row: any) => any)) =>  (row: any, filterValue: { min: string, max: string }): boolean => {
    const value = getRowPropValue(row, prop)

    if (!value) {
      return false
    }

    const dateValue = Date.parse(value)
    const startDate = Date.parse(filterValue.min)
    const endDate = Date.parse(filterValue.max)

    return dateValue >= startDate && dateValue <= endDate
  },
  [FilterTypes.RelationshipId]: (prop: string | ((row: any) => any)) => (row: any, filterValue: (string | number)[]): boolean => {
    const propId = getRowPropValue(row, prop)
    return filterValue.some(id => id == propId)
  },
  [FilterTypes.Select]: (prop: string | ((row: any) => any)) => (row: any, filterValue: string): boolean => {
    const value = getRowPropValue(row, prop)

    if (!value) {
      return false
    }

    return value.toString().toLowerCase() === filterValue.toString().toLowerCase()
  }
}

export enum ComparatorTypes {
  Text = 'text',
  Numeric = 'numeric',
  Date = 'date',
  RelationshipName = 'relationshipName',
}

type ComparatorTypesValue = (prop: string | ((row: any) => any) ) => ComparatorFunction

type ComparatorTypesMap = {
  [key in ComparatorTypes]: ComparatorTypesValue
}

export const comparatorTypesMap: ComparatorTypesMap = {
  [ComparatorTypes.Text]: (prop: string | ((row: any) => string)) => (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean): number => {
    const a = getRowPropValue(nodeA?.data, prop, '')?.toString().toLowerCase()
    const b = getRowPropValue(nodeB?.data, prop, '')?.toString().toLowerCase()

    return a.localeCompare(b)
  },
  [ComparatorTypes.Numeric]: (prop: string | ((row: any) => number)) => (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean): number => {
    const a = Number(getRowPropValue(nodeA?.data, prop, 0))
    const b = Number(getRowPropValue(nodeB?.data, prop, 0))

    return a - b
  },
  [ComparatorTypes.Date]: (prop: string | ((row: any) => string)) => (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean): number => {
    const a = Date.parse(getRowPropValue(nodeA?.data, prop, 0))
    const b = Date.parse(getRowPropValue(nodeB?.data, prop, 0))

    return a - b
  },
  [ComparatorTypes.RelationshipName]: (prop: string | ((row: any) => string)) => (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean): number => {
    const a = getRowPropValue(nodeA?.data, prop, '')?.toString().toLowerCase()
    const b = getRowPropValue(nodeB?.data, prop, '')?.toString().toLowerCase()

    return a.localeCompare(b)
  }
}

type StopEditingOptions = {
  revertChange?: boolean
  keepFocus?: boolean
}

const defaultStopEditingOptions: StopEditingOptions = {
  revertChange: false,
  keepFocus: true,
}

export interface RefreshRowNodeCellsParams extends RefreshCellsParams {
  rowNodeIds: (string | number)[]
}

export function stopCellEditing(params: ICellEditorParams, options: StopEditingOptions = defaultStopEditingOptions) {
  const { api } = params
  const { revertChange, keepFocus } = options

  api.stopEditing(revertChange)

  if (keepFocus) {
    api.setFocusedCell(params.rowIndex, params.column, params.node.rowPinned)
  }
}


const chartPanelTemplate = `
  <div class="chart-wrapper ag-theme-alpine">
    <div class="chart-wrapper-top">
      <h2 class="chart-wrapper-title"></h2>
      <button class="chart-wrapper-close">Destroy Chart</button>
    </div>
    <div class="chart-wrapper-body"></div>
  </div>
`;

function appendTitle(el: Element) {
  const formatter = new Intl.DateTimeFormat('en', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  });
  const date = formatter.format();
  el.innerHTML = `Chart created ${date}`;
}

export function createChartContainer(chartRef: ChartRef) {
  const eChart = chartRef.chartElement;
  const eTemp = document.createElement('div');
  eTemp.innerHTML = chartPanelTemplate;
  const eChartWrapper = eTemp.firstChild as Element;

  const eParent = document.querySelector('.data-table-container') as Element;
  eParent.appendChild(eChartWrapper);

  const chartBody = eChartWrapper.querySelector('.chart-wrapper-body') as Element
  chartBody.appendChild(eChart);

  const titleDiv = eChartWrapper.querySelector('.chart-wrapper-title') as Element
  appendTitle(titleDiv);
}

export function triggerCellEdit(params: ICellRendererParams<any>) {
  const rowIndex = params.node?.rowIndex
  if (rowIndex === null) {
    console.error('Cell edit failed. Could not find row index', params)
    return
  }

  params.api?.startEditingCell({
    rowIndex,
    colKey: params.column!.getColId(),
    rowPinned: params.node?.rowPinned
  })
}
