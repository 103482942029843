<template>
  <ProfileDropdown
    id="sidebar-profile-dropdown"
  >
    <div class="w-full flex items-center gap-x-4 px-6 py-3 text-sm font-medium leading-6 opacity-60 hover:opacity-100 breadcrumb-color hover:bg-white/10">
      <BaseLogo
        :entity="currentUser"
        logo-key="avatar"
        name-key="first_name"
        size="sm"
        rounded="full"
        disable-tooltip
      />
      <span class="sr-only">
        {{ $t('Your profile') }}
      </span>
      <span aria-hidden="true">
        {{ currentUser.name }}
      </span>
    </div>
  </ProfileDropdown>
</template>
<script lang="ts" setup>
import ProfileDropdown from "@/modules/dashboard/components/ProfileDropdown.vue";
import { useAuth } from "@/modules/auth/composables/useAuth";

const {
  currentUser
} = useAuth()
</script>
