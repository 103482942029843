<template>
  <div class="flex items-center h-full">
    <div
      v-for="progress in ProofProgresses"
      :title="ProofProgressLabels[progress]"
      v-text="ProofProgressLabels[progress][0]"
      class="progress-indicator"
      :class="{
          'bg-gray-200 text-gray-600': !value?.[progress],
          'bg-green-500 text-white': value?.[progress] || progress === ProofProgresses.NOTIFIED,
        }"
    />
  </div>
</template>

<script setup lang="ts">
import { ProofProgressLabels } from "@/modules/file-proofs/utils/fileProofsUtils";
import { PropType, toRef } from "vue";
import useCellProps from "@/components/table/composables/useCellProps";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ProofProgresses } from "@/modules/file-proofs/utils/proofModels";

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams>,
    default: () => ({} as PropType<ICellRendererParams>),
    required: true
  },
})

const { value } = useCellProps(toRef(props, 'params'), [])
</script>

<style lang="scss">
.progress-indicator {
  @apply w-[18px] h-4 flex items-center justify-center leading-none text-xs relative;
}

.progress-indicator:not(.notified):after {
  @apply absolute w-1.5 h-4 z-[1] -left-[3px] scale-110 content-[''];
  background: url('/svg/proof-progress-arrow.svg') no-repeat;
}
</style>
