<template>
  <div v-if="!dismissed" class="rounded-md bg-yellow-50 p-4">
    <div class="flex items-center">
      <div class="shrink-0">
        <slot name="icon">
          <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </slot>
      </div>
      <div class="ml-3">
        <p class="text-sm font-medium text-yellow-700">
          <slot>
            {{ message }}
          </slot>
        </p>
      </div>
      <div
        v-if="dismissable"
        class="ml-auto pl-3" 
      >
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
            @click="dismissed = true"
          >
            <span class="sr-only">{{ $t('Dismiss') }}</span>
            <XIcon class="h-5 w-5" aria-hidden="true"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon, XIcon } from '@heroicons/vue/solid'

export default {
  components: {
    ExclamationIcon,
    XIcon,
  },
  props: {
    dismissable: {
      type: Boolean,
      default: true 
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dismissed: false
    }
  },
}
</script>
