<template>
  <div class="px-8 py-2 bg-white border-b border-gray-200 sticky top-0 rounded-t-lg">
    <div class="flex gap-2 text-xs text-medium text-gray-400">
      <router-link
        class="hover:text-gray-500"
        :to="getRoutePath(parentRoute)"
      >
        <i class="fa-solid fa-arrow-left mr-1" />
        <span>
          {{ backLabel }}
        </span>
      </router-link>
      <BreadcrumbsItemNew
        v-for="r of childRoutes"
        :key="r.path"
        :matched-route="r"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
// Components
import BreadcrumbsItemNew from "@/modules/dashboard/components/BreadcrumbsItemNew.vue"

// Utils
import { computed } from 'vue';
import i18n from "@/i18n";

// Composables
import useBreadcrumbs from "@/modules/dashboard/utils/useBreadcrumbs"

const {
  parentRoute,
  childRoutes,
  getRoutePath,
  getDisplayedName,
} = useBreadcrumbs()

const parentRouteName = computed(() => {
  return getDisplayedName(parentRoute.value)
})

const backLabel = computed(() => {
  return i18n.tc('back to page', {
    pageName: parentRouteName.value
  })
})
</script>
