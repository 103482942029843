<template>
  <div class="flex flex-col h-full">
    <TableCharts
      :grid-api="gridApi"
      :columns="tableColumns"
      formatter="time"
      hasTotalsFooter
    />
    <BaseDataTable
      :data="data"
      :meta="meta"
      :columns="tableColumns"
      :loading="loading"
      groupIncludeTotalFooter
      :remapQueryParams="{
        page: 'allocatedTimePage',
        perPage: 'allocatedTimePerPage'
      }"
      class="flex-1 min-h-[400px]"
      :class="{
        'preserve-ag-background': isAllViewActive
      }"
      allowEntityType="time_allocations"
      @firstDataRendered="onFirstDataRendered"
    >
      <template #no-data>
        <NoAllocatedTime
          :class="{
            'rounded-t-none': isAllViewActive
          }"
        />
      </template>
    </BaseDataTable>
  </div>
</template>
<script lang="ts" setup>
// Utils
import { computed, ref, onMounted } from 'vue'

// Components
import NoAllocatedTime from "@/modules/time/components/NoAllocatedTime.vue"
import TableCharts from "@/components/table/charts/TableCharts.vue";
import { FirstDataRenderedEvent } from "@ag-grid-community/core";

// Composables
import { useStore } from "vuex"
import { useRoute } from 'vue-router';
const store = useStore()
const route = useRoute()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
})

const tableColumns = computed(() => {
  return store.getters['time/allocatedTimeTableColumns'] || []
})

const isAllViewActive = computed(() => {
  return route.path.endsWith('time/all')
})

const meta = computed(() => {
  return store.state.time.allocatedTime.meta || {}
})

async function loadData() {
  store.dispatch('time/getAllAllocatedTime')
}

onMounted(() => {
  loadData()
})

const gridApi = ref()
function onFirstDataRendered(params: FirstDataRenderedEvent) {
  gridApi.value = params.api
}
</script>
