<template>
  <kbd :class="className">
    <template v-if="text === 'Mod'">{{ isMac ? '⌘' : 'Ctrl' }}</template>
    <template v-else-if="text === 'Shift'">⇧</template>
    <template v-else-if="text === 'Alt'">{{ isMac ? '⌥' : 'Alt' }}</template>
    <template v-else>{{ text }}</template>
  </kbd>
</template>
<script setup lang="ts">
const props = defineProps({
  text: String
})

const isMac = /Mac|iPod|iPhone|iPad/.test(window.navigator.platform)

const className = 'inline-flex items-center justify-center w-5 h-5 p-1 text-[0.625rem] rounded font-semibold leading-none border border-neutral-200 text-neutral-500 border-b-2'
</script>
