<template>
  <BaseEntityForm
    :isDialogForm="true"
    width-class="max-w-2xl"
    @submit="onSubmit"
  >
    <template #title>
      <BaseFormHeader
        :isDialogForm="true"
      >
        <template #title>
          <h3 class="text-2xl font-bold">
            {{ embed?.id ? $t('Edit') : $t("Create") }}
            <span
              v-if="embedName"
              class="capitalize"
            >
              {{ embedName }}
            </span>
            {{ $t('Embed')}}
          </h3>
        </template>
        <template
          v-if="!embed?.id"
          #subtitle
        >
          <p
            class="text-sm mt-2"
            :class="{
              'text-gray-500': !$useNewLayout,
            }"
          >
            {{ embedTypeDescription || defaultDescription }}
          </p>
        </template>
      </BaseFormHeader>
    </template>
    <template #default="{ meta, errors }">
      <BaseInput
        :modelValue="model.image"
        :name="$t('Image')"
        :label="$t('Image')"
        id="image"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      >
        <BaseAvatarPicker
          v-model="model.image"
          size="md"
          :disabled-options="['group', 'unsplash']"
          :placeholder="$t('+ Add an image (optional)')"
        />
      </BaseInput>
      
      <BaseInput
        v-if="!defaultEmbedType && !embed?.id"
        :modelValue="model.type"
        :name="$t('Type')"
        :label="$t('Type')"
        id="type"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <EmbedTypeSelect
            v-model="model.type"
            return-object
            @change="onEmbedTypeChange"
          />
        </div>
      </BaseInput>

      <BaseInput
        v-model="model.name"
        :name="$t('Name')"
        :label="$t('Name')"
        :placeholder="$t('Enter Name...')"
        rules="required"
        id="name"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      />
      <BaseInput
        v-if="isCodeEmbed"
        :modelValue="model.html_string"
        :key="`${model.type.value}_code`"
        ref="embedUrl"
        validate-on-mount
        :name="$t('Code')"
        :label="$t('Code')"
        :placeholder="$t('Enter Code...')"
        rules="required"
        id="src"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      >
        <textarea
          v-model="model.html_string"
          id="html-string"
          name="model.html_string"
          rows="6"
          class="block w-full form-input"
          :placeholder="$t('Paste embed code here...')"
        />
        <template
          v-if="selectedType?.tutorial_link"
        >
          <div class="text-gray-400 text-xs basis-full">
            {{ selectedType?.tutorial_link }}
            <BaseTutorialLink
              name="project-tools"
            >
              {{ $t('More info') }}
            </BaseTutorialLink>.
          </div>
        </template>
      </BaseInput>

      <BaseInput
        v-else
        v-model="model.src"
        :key="model.type.value"
        ref="embedUrl"
        validate-on-mount
        :name="$t('URL')"
        :label="$t('URL')"
        :placeholder="$t('Enter URL...')"
        :rules="`required|embedUrl:${typeof model.type === 'object' ? model.type?.value : model.type}`"
        id="src"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      >
        <template
          v-if="selectedType?.tutorial_link"
          #after-input
        >
          <div class="text-gray-400 text-xs basis-full">
            {{ selectedType?.tutorial_link }}
            <BaseTutorialLink
              name="project-tools"
            >
              {{ $t('More info') }}
            </BaseTutorialLink>.
          </div>
        </template>
      </BaseInput>

      <BaseInput
        v-model="model.description"
        :name="$t('Description')"
        :label="$t('Description')"
        :placeholder="$t('Enter Description (optional)...')"
        rules="max:55"
        id="description"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      />

      <BaseRoundedCheckbox
        v-if="!singleEmbed"
        v-model="model.pinned"
        :name="$t('Pinned')"
        :label="$t('Pinned')"
        size="sm"
        inputClass="sm:col-span-3"
      />
      
      <template v-if="meta.valid && (embedIframeUrl || model.html_string)">
        <div class="sm:grid sm:gap-4 sm:items-start sm:grid-cols-4">
          <label class="text-sm font-medium leading-5 text-gray-700 flex flex-wrap sm:mt-px sm:pt-2 sm:col-span-1">
            {{"Preview"}}
          </label>
          <BaseButton
            custom-class="sm:col-span-3"
            block
            @click="previewVisible = !previewVisible"
          >
            {{ previewVisible ? $t("Hide preview") : $t("Show preview") }}
          </BaseButton>
        </div>

        <template v-if="previewVisible">
          <WarningAlert
            class="border border-yellow-500 mb-2"
            :dismissable="false"
          >
            {{ $t("If your embed doesn't display correctly then") }}
            <BaseTutorialLink
              name="embed-issues"
            >
            {{ $t('view this article for more info') }}
            </BaseTutorialLink>.
          </WarningAlert>
          <div
            v-loading="iframeLoading"
            class="w-full h-96"
          >
            <iframe
              v-if="model.html_string"
              class="rounded-md m-auto"
              width="100%"
              height="100%"
              :srcdoc="model.html_string"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              @load="iframeLoading = false"
            />
            <iframe
              v-else
              class="rounded-md m-auto"
              width="100%"
              height="100%"
              :src="embedIframeUrl"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              @load="iframeLoading = false"
            />
          </div>
        </template>
      </template>
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
          variant="white"
          @click="$emit('cancel')"
        >
          {{ $t("Cancel") }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          :disabled="!meta.valid || !model.project_id"
          type="submit"
          class="ml-2"
        >
          {{ $t("Save") }}
        </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
// Libs
import { cloneDeep } from "lodash-es";
import i18n from "@/i18n";

// Components
import EmbedTypeSelect from "@/modules/resources/components/EmbedTypeSelect.vue";

// Helpers
import { embedTypes, getEmbedIFrameUrl } from "@/modules/resources/utils/embedUtils";
import { embedTools } from "@/modules/projects/utils/toolUtils"

export default {
  name: "EmbedForm",
  props: {
    embed: {
      type: Object,
      default: () => null,
    },
    singleEmbed: {
      type: Boolean,
      default: false,
    },
    defaultEmbedType: {
      type: [Object, String],
      default: () => null,
    },
    projectId: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    EmbedTypeSelect,
  },
  emits: ["save", "cancel"],
  data() {
    const type = this.getDefaultType() || this.defaultEmbedType || 'anything'
    const selectedType = embedTypes.find(t => t.value === this.defaultEmbedType)
    return {
      loading: false,
      embedTypes,
      model: {
        type,
        name: "",
        project_id: this.projectId || this.$store.getters.project_id,
        src: "",
        html_string: '',
        pinned: this.singleEmbed,
        image: selectedType?.image || '',
        description: ''
      },
      previewVisible: false,
      iframeLoading: true,
    };
  },
  computed: {
    isEditAction() {
      return !!this.embed?.id;
    },
    embedIframeUrl() {
      if (!this.model.type || !this.model.src) {
        return ''
      }

      return getEmbedIFrameUrl(this.model.type.value, this.model.src)
    },
    selectedType() {
      return embedTypes.find(t => t.value === this.model.type?.value)
    },
    isCodeEmbed() {
      return this.selectedType?.is_code_embed || this.model.type === 'anything_by_code'
    },
    embedName() {
      return embedTools.find(t => t.value === this.defaultEmbedType)?.name || ''
    },
    embedTypeDescription() {
      return embedTools.find(t => t.value === this.defaultEmbedType)?.description || ''
    },
    defaultDescription() {
      if (this.defaultEmbedType === 'anything_by_code') {
        return i18n.t('Embed anything by pasting the embed code')
      }

      return i18n.t('Embed anything by simply pasting the web page URL.')
    }
  },
  methods: {
    async onSubmit(emitSave = true) {
      try {
        this.loading = true;
        const data = cloneDeep(this.model);
        data.type = (typeof this.model.type === 'object'
        ? this.model.type.value
        : this.model.type) || 'anything';

        data.src = getEmbedIFrameUrl(data.type, data.src)

        let embed;
        if (this.isEditAction) {
          embed = await this.editEmbed(data)
        }
        else {
          embed = await this.createEmbed(data)
        }

        const message = this.isEditAction
          ? this.$t("Embed updated successfully")
          : this.$t("Embed created successfully");

        this.$success(message);
        if (emitSave) {
          this.$emit("save", embed);
        } else {
          this.$router.go(-1);
        }
      } catch (err) {
        if (err.handled) {
          return false;
        }

        const message = this.isEditAction
          ? this.$t("Could not update embed")
          : this.$t("Could not create embed");

        this.$error(message);
        return false;
      } finally {
        this.loading = false;
      }
      return true;
    },
    async createEmbed(data) {
      const result = await this.$store.dispatch("resources/createEmbed", { data });
      return result.data;
    },
    async editEmbed(data) {
      const result = await this.$store.dispatch("resources/editEmbed", {
        embedId: this.embed?.id,
        data,
      });

      return result.data;
    },
    getDefaultType() {
      if (typeof this.defaultEmbedType === "string" && this.defaultEmbedType !== 'anything') {
        return embedTypes.find((type) => type.value === this.defaultEmbedType) || ""
      }

      return this.defaultEmbedType || ""
    },
    onEmbedTypeChange() {
      if (!this.model.src) {
        return
      }

      this.$refs?.embedUrl?.validate()
    },
  },
  watch: {
    embed: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        
        let type = this.embedTypes.find((t) => t.value === value.attributes.type) || this.defaultEmbedType

        if (!type) {
          type = value.attributes?.html_string
            ? 'anything_by_code'
            : 'anything'
        }
        
        this.model = {
          ...value.attributes,
          pinned: !!Number(value.attributes.pinned || 0),
          type,
        };
      },
    },
  },
  // mounted() {
  //   this.convertUrlToEmbedDebounced = debounce(this.convertUrlToEmbed, 500)
  // }
};
</script>
