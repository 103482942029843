<template>
  <div
    element-loading-background="rgba(0, 0, 0, 0)"
    aria-labelledby="kanban-view"
    class="kanban-view overflow-x-auto"
    :class="{
      '-mx-4 sm:-mx-8 px-8 pt-4 bg-gray-100': $useNewLayout,
    }"
  >
    <div
      v-if="loading"
      class="w-full grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
    >
      <EntityLoadingCards
        :params="{
          columns
        }"
        :count="10"
      />
    </div>
    <InfiniteScroll
      v-else
      :load-next="loadNextPage"
      direction="down"
      loaderClass="mt-6"
    >
      <div class="space-y-8">
        <div
          v-for="(key) of displayedGroups.keys()"
          :key="key"
        >
          <GroupByTitle
            :target="target"
            :columns="columns"
            :data="displayedGroups.get(key)"
            :keyValue="key"
            collapsable
            :initCollapsed="!!groupByProp"
            appendHeaderClass="mr-6"
            expandedClass="table-row whitespace-pre"
            @toggle-collapsed="isCollapsed => isCollapsed && triggerVisibilityCheck()"
          >
            <slot
              :data="displayedGroups.get(key)"
              :groupBy="{
                prop: groupByProp,
                value: key,
              }"
            />
          </GroupByTitle>
        </div>
      </div>
    </InfiniteScroll>
    <slot name="after" />
  </div>
</template>
<script setup lang="ts">
import { PropType, computed, nextTick, ref, watch } from 'vue'
import {
  setKanbanHeightDebounced
} from "@/modules/common/utils/kanbanUtils";

// Components
import GroupByTitle from "@/modules/filters/components/GroupByTitle.vue";
import EntityLoadingCards from "@/components/table/EntityLoadingCards.vue";
import InfiniteScroll from "@/components/common/InfiniteScroll.vue";

// Composables
import useSortedAndFilteredData from "@/modules/common/composables/useSortedAndFilteredData";
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import useMobileUtils from "@/modules/common/composables/useMobileUtils"

const store = useStore()
const route = useRoute()

const props = defineProps({
  target: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  dataGetterPath: {
    type: String,
    required: true,
  },
  groupedDataGetter: {
    type: String,
    default: '',
  },
  columnsGetter: {
    type: String,
    default: '',
  },
  getQuickFilterText: {
    type: Function as PropType<(entity: any) => string>,
    default: (entity: any): string => entity?.attributes?.name,
  },
  extraFilters: {
    type: Array,
    default: () => [],
  },
})

const groupByProp = computed(() => {
  return store.getters['filters/targetLocalFilters'](props.target)?.groupBy?.[0]
})

const { sortedAndFilteredData } = useSortedAndFilteredData({
  target: props.target,
  extraFilters: props.extraFilters,
  dataGetterPath: props.dataGetterPath,
  columnsGetter: props.columnsGetter,
  getQuickFilterText: props.getQuickFilterText,
})

const columns = computed(() => {
  return store.getters[props.columnsGetter] || []
})

const perPage = ref(3)
const currentPage = ref(1)

const groupedEntries = ref<Map<any, any[]>>(new Map())

const infiniteScrollState = ref<any>(null)

const displayCount = computed(() => {
  return perPage.value * currentPage.value
})

const displayedGroups = computed(() => {
  const arrayTmp = Array.from(groupedEntries.value)
  return new Map(arrayTmp.slice(0, displayCount.value))
})

const canDisplayMore = computed(() => {
  return displayCount.value < groupedEntries.value.size
})

const displayData = computed(() => {
  return sortedAndFilteredData.value
})

const groupedData = computed(() => {
  if (!props.groupedDataGetter) {
    const map = new Map()
    map.set('default', displayData.value)
    return map
  }

  return store.getters[props.groupedDataGetter](displayData.value)
})

async function loadNextPage($state: any) {
  if (props.loading) {
    return
  }

  if (!infiniteScrollState.value) {
    infiniteScrollState.value = $state
  }

  currentPage.value++

  await nextTick()

  if (!canDisplayMore.value) {
    infiniteScrollState.value?.complete()
  }
}

function triggerVisibilityCheck() {
  infiniteScrollState.value?.checkVisibility()
}

watch(() => route.query?.filters, triggerVisibilityCheck)

async function resetState() {
  await nextTick()

  currentPage.value = 1
  infiniteScrollState.value?.reset()
}

watch(() => sortedAndFilteredData.value, () => {
  resetState()
})

async function syncGroupedData() {
  await nextTick()

  groupedEntries.value = groupedData.value
}

watch(() => groupedData.value, async () => {
  await nextTick()
  if (!route.path.includes('kanban')) {
    return
  }

  syncGroupedData()
})

const topFiltersResized = computed(() => {
  return store.state.topFiltersResized
})

const {
  isMobileDevice,
} = useMobileUtils()

watch(() => topFiltersResized.value, () => {
  if (isMobileDevice.value) {
    return
  }

  setKanbanHeightDebounced()
}, {
  immediate: true,
})
</script>
