<template>
  <BaseSelect
      v-model="model"
      :options="pinnedOptions"
      :disabled="$attrs.disabled"
      filterable
      :return-object="false"
  />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { pinnedOptions } from '@/modules/projects/utils/noteUtils'

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: '',
  }
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>
