<template>
  <div
    class="h-full flex-col-grow"
    :class="route.name?.toString()?.replaceAll?.(' ', '_') || ''"
  >
    <Sidebar />
    <!-- Main content (router view) -->
    <div class="drag-area absolute h-4 w-full"></div>
    <main
      class="p-4 pt-10 lg:pt-4 sm:h-full flex-col-grow relative"
      :class="{
        'lg:pl-60': sidebarVisible,
      }"
    >
      <BreadcrumbsNew
        v-if="showBreadcrumbs"
      />
      <div
        class="bg-white rounded-lg sm:h-full sm:flex-col-grow router-view-wrapper"
        :class="{
          'px-4 pr-4 pt-4 sm:px-8 sm:pt-4': !isFullPage,
          'overflow-y-auto': route.name !== 'Account Inbox' && !route.path?.includes('kanban'),
          'rounded-t-none': showBreadcrumbs,
          'h-full': isFullHeightMobile
        }"
      >

        <SubscriptionInvalidMessage
          v-if="!isSubscriptionValid && settingsLoaded"
          class="my-4 border border-red-600 w-full"
        />

        <ArchiveDownloadMessage />

        <SimulatedRoleMessage />

        <FinishedImportMessage />

        <div
          class="router-view-content"
          :class="{
            'is-list-view': isListOrEmbedView
          }"
        >
          <router-view />
        </div>
      </div>
    </main>
  </div>
</template>
<script lang="ts" setup>
// Components
import Sidebar from '@/modules/dashboard/components/Sidebar.vue'
import BreadcrumbsNew from '@/modules/dashboard/components/BreadcrumbsNew.vue'
import SubscriptionInvalidMessage from "@/modules/accounts/components/SubscriptionInvalidMessage.vue";
import ArchiveDownloadMessage from "@/modules/accounts/components/ArchiveDownloadMessage.vue";
import SimulatedRoleMessage from "@/modules/accounts/components/SimulatedRoleMessage.vue";
import FinishedImportMessage from "@/modules/accounts/components/FinishedImportMessage.vue";

// Utils
import { computed, onBeforeMount } from 'vue';

// Composables
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import useLayout from "@/modules/common/composables/useLayout"
import useBreadcrumbs from "@/modules/dashboard/utils/useBreadcrumbs"
import useMobileUtils from "@/modules/common/composables/useMobileUtils"

const route = useRoute()
const store = useStore()

const {
  showBreadcrumbs
} = useBreadcrumbs()

const { isMobileDevice } = useMobileUtils()

const props = defineProps({
  settingsLoaded: {
    type: Boolean,
    default: false
  },
})

const {
  sidebarVisible
} = useLayout()

const isListOrEmbedView = computed(() => {
  return (route.path.includes('/list') || isMobileDevice && route.path.includes('/card')) ||
    route.path.includes('/time/actual') ||
    route.path.includes('/time/allocated') ||
    route.path.includes('/time/all') ||
    route.path.includes('/custom-fields') ||
    ['Project Embed', 'Template Embed', 'Proof Embed'].includes(route?.name?.toString() || '') ||
    (isFullPage.value && route?.name !== 'Add New Task' && route?.name !== 'Task Details') ||
    route.path.includes('/inbox')
})

const fullPages = [
  'Add New Task',
  'Task Details',
  'Project Embed',
  'Template Embed',
  'Proof Embed',
  'Payment Invoice',
  'Invoice',
  'Project View',
  'New Project',
  'New Template',
  'Template View'
]

const isFullHeightMobile = computed(() => {
  return route.path.includes('/embeds') || route.path.includes('/resources') || route.path.includes('/file-proofs')
})

const isFullPage = computed(() => {
  return fullPages.includes(route?.name?.toString() || '')
})

const isSubscriptionValid = computed(() => {
  return store.getters['accounts/isValidSubscription']
})

onBeforeMount(async () => {
  await store.dispatch('users/getRoles')
  await store.dispatch('dashboard/getDashboards')
})
</script>
<style lang="scss">
.router-view-content {
  &:not(.is-list-view) {
    @apply pb-20 md:pb-0;
  }

  &.is-list-view {
    @apply h-full;
  }
}

.Project_View {
  .router-view-wrapper {
    @apply pb-0;
  }
}
</style>
