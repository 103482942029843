<template>
  <BaseTableCell :params="params">
    <div class="inline-flex items-center space-x-2 whitespace-nowrap">
      <UserRoleBadge 
        :user="row"
      />
      <div> {{ role }} </div>
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
// Components
import UserRoleBadge from "@/components/common/UserRoleBadge.vue";

// Utils
import { computed } from "vue"
import { get } from "lodash-es"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed<any>(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const role = computed<string>(() => {
  const roles = get(row.value, colDef.value.field) || []

  return roles.join(', ')
})
</script>
