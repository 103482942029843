<template>
  <BaseNoDataSection
    :title="$t('No Files Found')"
    iconClass="fa-file"
    :canCreate="!isCurrentProjectClosed && can($actions.CREATE_PROJECT_FILES)"
  >
    <template #can-create-section>
      <div>{{ $t('Upload files & create folders to organize them.') }}</div>
      <div>
        <BaseDndFileUpload
          v-model="files"
          :renderList="false"
          class="mt-2"
          @update:modelValue="onFileUpload"
        />
      </div>
    </template>
  </BaseNoDataSection>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

import useEntityCrud,{ EntityTypes } from "@/modules/common/composables/useEntityCrud"

const {
  entityTypes,
  triggerEntityCreate
} = useEntityCrud()

const isCurrentProjectClosed = computed(() => {
  return store.getters['projects/isCurrentProjectClosed']
})

const currentProject = computed(() => {
  return store.state.projects.currentProject
})

const files = ref([])

function onFileUpload() {
  triggerEntityCreate(entityTypes.File as EntityTypes, {
    project: currentProject.value,
    files: files.value,
  })

  files.value = []
}
</script>
