import i18n from '@/i18n'
import { Editor, Range } from '@tiptap/core'
import ObjectValues = API.ObjectValues;

type SuggestionCategory = 'Formatting' | 'Insert' | 'Link' | 'Embed'

export const BlockCategories = {
  Formatting: i18n.t('Formatting'),
  Insert: i18n.t('Insert'),
  Link: i18n.t('Link'),
  Embed: i18n.t('Embed'),
}

type BlockCategory = ObjectValues<typeof BlockCategories>

export type SuggestionItem = {
  id?: string
  title: string
  description: string
  icon: string
  isActive: (editor: Editor) => boolean
  category: BlockCategory
  command: (params: { editor: Editor; range: Range; [key: string]: any }) => void | Promise<void>
  disableConvert?: boolean
  disableInsert?: boolean
  enabled?: (editor: Editor) => boolean
  needsRoleUpgrade?: () => string | boolean
  needsPlanUpgrade?: boolean
  disabledTooltip?: () => string | boolean
}

