<template>
  <BaseDropdown
    v-if="availableOptions.length"
    :options="availableOptions"
    :slim="slim"
    divide-items
    @action="onAction"
  >
    <template #default>
      <slot />
    </template>
  </BaseDropdown>
</template>
<script>
import { getGroupFilter } from "@/modules/groups/utils/filterUtils.js";
import { encodeFilters } from "@/modules/common/utils/filterUtils.js";
import i18n from '@/i18n'

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    slim: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    hasUsers() {
      const usersCount = this.group?.attributes?.users_count || this.group?.relationships?.users?.length || 0
      return usersCount > 0
    },
    options() {
      return [
        {
          label: i18n.t('View Details'),
          path: `/groups/${this.group.id}`,
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: i18n.t('View Projects'),
          path: this.getGroupPathFilter('/projects/list'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: i18n.t('View Tasks'),
          path: this.getGroupPathFilter('/tasks/list'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: i18n.t('View People'),
          path: this.getGroupPathFilter('/users/list'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: i18n.t('View Payments'),
          path: this.getGroupPathFilter('/payments/list'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: i18n.t('View Time'),
          path: this.getGroupPathFilter('/time/all'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          disabled: this.hasUsers,
          tooltip: this.hasUsers
            ? i18n.t('Cannot delete group with users')
            : null,
          requiresPermissionTo: this.$actions.DELETE_GROUPS
        }
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    getGroupPathFilter(path) {
      return {
        path,
        query: {
          filters: this.getGroupFilter(this.group)
        }
      }
    },
    getGroupFilter(group) {
      try {
        const filters = getGroupFilter(group)
        return encodeFilters(filters)
      } catch (err) {
        return ""
      }
    },
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.group)
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t("Delete Group"),
        description: i18n.t("Are you sure? This will permanently delete the group"),
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch("groups/deleteGroup", this.group.id);
      } catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(i18n.t("Cannot delete the group"));
        throw err;
      }
    },
  }
}
</script>
