<template>
  <div
    class="entity-select-wrapper"
    :class="{
      'clearable': clearable
    }"
  >
    <el-select
      v-bind="$attrs"
      v-loading="syncing"
      ref="entitySelectRef"
      :modelValue="selectValue"
      :multiple="multiple"
      :clearable="clearable"
      :remote="true"
      :remote-method="getData"
      :collapse-tags="multiple"
      :placeholder="$attrs.placeholder || $t(`Search for ${entity}...`)"
      @change="onChange"
      @blur="onBlur"
      @focus="onFocus"
      :filterable="!$attrs.externalActivator"
      class="w-full relative"
      :class="{
        'external-activator': $attrs.externalActivator
      }"
    >
      <template #empty>
        <slot name="empty" />
      </template>
      <template
        v-if="$attrs.entityLink && selectValue"
        #prefix
      >
        <div class="entity-select-entity-link hidden z-10">
          <BaseButton
            variant="white"
            size="xs"
            tag="router-link"
            :to="`${$attrs.entityLink}/${selectValue}`"
            @click.stop.prevent="$router.push(`${$attrs.entityLink}/${selectValue}`)"
          >
            {{ $attrs.openEntityLabel || $t('Open') }}
            <i class="fas fa-chevron-right ml-1" />
          </BaseButton>

          <BaseButton
            v-if="$attrs.openExternal"
            variant="white"
            size="xs"
            class="ml-2"
            tag="a"
            target="_blank"
            :href="`${$attrs.entityLink}/${selectValue}`"
          >
            <i class="text-xs far fa-external-link" />
          </BaseButton>
        </div>
      </template>
      <slot>
        <slot name="custom-options" />
        <el-option
          v-if="allowEntityCreate && createInfoText"
          value=""
          disabled
          class="menu-info-section -mt-2"
        >
          <div class="flex items-center py-2">
            <span class="text-xs text-gray-400">
              {{ createInfoText }}
              <BaseTutorialLink
                :name="entity"
              >
                <span>{{ $t('Learn more.') }}</span>
              </BaseTutorialLink>
            </span>
          </div>
        </el-option>
        <el-option
          v-if="allowEntityCreate"
          value=""
          disabled
          class="create-entity-option"
        >
          <div
            class="flex items-center py-1 cursor-pointer w-full h-full"
            @click="triggerEntityCreate"
          >
            <div class="rounded-md uppercase bg-white flex items-center justify-center tracking-wider leading-6 font-normal text-primary-500 h-8 w-8 text-xs" tabindex="0">
              <i class="fa-solid fa-plus" />
            </div>
            <span
              class="ml-2 truncate text-white"
              v-html="addEntityLabel || $tc('create new entity', { entity: entitySingular })"
            />
          </div>
        </el-option>
        <el-option
          v-if="loading"
          value=""
          disabled
        >
          <div class="flex justify-center w-full">
            <LoadingIcon  size="xs" />
          </div>
        </el-option>
        <el-option
          v-if="!loading && !filteredOptions?.length"
          value=""
          disabled
        >
          <div class="text-center w-full">{{$t('No data')}}</div>
        </el-option>
        <el-option
          v-for="option in mappedOptions"
          :value="option.value"
          :label="option.label || option.value"
          :key="option.value"
          :disabled="option.disabled"
        >
          <slot name="option" :row="option">
            <div class="flex items-center">
              <component
                v-if="option.icon"
                :is="option.icon"
                size="1.3x"
                class="mr-2"
              />
              <span class="truncate">{{ option.label || option.value }}</span>
            </div>
          </slot>
        </el-option>
      </slot>
      <slot name="extra">
        <div
          class="flex justify-center items-center pt-1"
          v-if="lastPage > currentPage"
        >
          <BaseButton
            variant="primary-link"
            :loading="loading"
            @click="loadMore"
          >
            {{ $t("View More") }}
          </BaseButton>
        </div>
      </slot>
    </el-select>
  </div>
</template>

<script>
// Libs
import { get, uniqBy, capitalize } from "lodash-es";
import { ElSelect, ElOption } from "element-plus";
import { inject } from "vue";

// Components
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";

// Utils
import apiCache from "@/modules/common/utils/apiCache.js";
import axios from 'axios'

export default {
  name: "entity-select",
  inheritAttrs: false,
  components: {
    ElSelect,
    ElOption,
    LoadingIcon,
  },
  props: {
    modelValue: {
      type: [String, Number, Object, null],
      default: "",
    },
    initialValue: {
      type: [Object, Array],
      default: () => null
    },
    url: {
      type: String,
      default: "",
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    valueKey: {
      type: String,
      default: "id",
    },
    labelKey: {
      type: String,
      default: "attributes.name",
    },
    fallbackLabelKey: {
      type: String,
      default: "attributes.name",
    },
    selectFirstOption: {
      type: Boolean,
      default: false,
    },
    labelFormat: {
      type: Function,
    },
    multiple: Boolean,
    rowFilter: {
      type: Function,
    },
    excludedOptions: {
      type: [Function, Array],
    },
    reverseOptions: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    allowEntityCreate: {
      type: Boolean,
      default: false
    },
    addEntityTrigger: {
      type: String,
      default: ''
    },
    addEntityParams: {
      type: Object,
      default: null
    },
    addEntityLabel: {
      type: String,
      default: ''
    },
    addEntityAction: {
      type: Function,
      default: null
    },
    entityCreatedCallback: {
      type: Function,
      default: null
    },
    focusOnEntityDialogClosed: {
      type: Boolean,
      default: true
    },
    createInfoText: {
      type: String,
      default: ''
    },
    triggerSync: {
      type: Boolean,
      default: false
    },
    mapData: {
      type: Function,
    },
    disableValues: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    fetchOnMount: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return {
      handleFormChange: inject('handleChange', null),
    }
  },
  data() {
    return {
      lastPage: 1,
      currentPage: 1,
      query: "",
      loading: false,
      syncing: false,
      blurred: false,
      options: [],
      shouldReload: true,
      allOptionsEver: [],
    };
  },
  computed: {
    filteredOptions() {
      let options = this.options || []
      if (this.reverseOptions) {
        options = [...options].reverse()
      }
      if (this.excludedOptions) {
        if (typeof this.excludedOptions === 'function') {
          options = options.filter(x => !this.excludedOptions(x))
        }
        else {
          options = options.filter(x => !this.excludedOptions.includes(this.get(x, this.valueKey)))
        }
      }
      if (!this.rowFilter || typeof this.rowFilter !== 'function') {
        return options
      }
      return options.filter(this.rowFilter)
    },
    mappedOptions() {
      return this.filteredOptions.map((option) => {
        const finalOption = {
          value: this.get(option, this.valueKey)?.toString() || "",
          label: this.get(option, this.labelKey) || this.get(option, this.fallbackLabelKey),
          originalValue: option,
        };
        if (this.labelFormat) {
          finalOption.label = this.labelFormat(option);
        }
        if(this.disableValues.includes(finalOption.value)) {
          finalOption.disabled = true;
        }
        return finalOption;
      });
    },
    entity() {
      const parts = this.url.split("/");
      let entity = "";
      if (parts.length) {
        entity = parts[parts.length - 1];
      }
      return entity;
    },
    entitySingular() {
      if (!this.entity?.substr) {
        return this.entity
      }

      return this.entity.substr(0, this.entity.length - 1)
    },
    selectValue() {
      if (this.valueKey !== "id") {
        return this.modelValue;
      }

      if (this.multiple) {
        return Array.isArray(this.modelValue) && this.modelValue?.map(x => x.toString()) || []
      }

      return this.modelValue?.toString();
    },
  },
  methods: {
    onChange(value) {
      if (this.multiple) {
        const options = this.allOptionsEver.filter(
          (o) => value?.includes(get(o, this.valueKey))
        );

        this.$emit("raw-change", options)
      }
      else {
        const option = this.allOptionsEver.find(
          (o) => get(o, this.valueKey) === value?.toString()
        );
        this.$emit("raw-change", option);
      }
      if (this.handleFormChange) {
        this.handleFormChange(value);
      }
    },
    onBlur() {
      this.blurred = true;
      this.$emit("blur");
    },
    onFocus() {
      this.blurred = false;
      if (this.shouldReload) {
        this.getData(this.query, true)
      }
    },
    loadMore(event) {
      this.getData(this.query, false, event);
    },
    filterLocalOptions(query) {
      const data = this.data || [];
      this.options = data.filter((x) => {
        const label = this.get(x, this.labelKey) || this.get(x, this.fallbackLabelKey);
        return label.toLowerCase().includes(query.toLowerCase());
      })
      this.allOptionsEver = uniqBy([...this.allOptionsEver, ...data], this.valueKey)
    },
    async forceFetch() {
      await this.getData('', true, true, true)
    },
    getFetchParams(query) {
      const params = {
        page: this.currentPage,
      };
      if (query) {
        params.search = query;
      }
      let finalParams = {
        ...params,
      };
      if (this.urlParams) {
        finalParams = {
          ...params,
          ...this.urlParams,
        };
      }
      return finalParams
    },
    async getData(query, resetPage = false, event = null, invalidateCache = false) {
      if (this.blurred && !event) {
        return
      }
      if (query !== this.query || resetPage) {
        this.currentPage = 1;
      } else {
        this.currentPage++;
      }
      this.query = query;
      if (!this.url && this.data) {
        this.filterLocalOptions(query);
        return;
      }
      try {
        this.loading = true;
        const finalParams = this.getFetchParams(query)
        let { data, meta } = await apiCache.getRequest(this.url, {
          params: finalParams,
          invalidateCache,
        });

        data = data || []

        if (this.mapData) {
          data = this.mapData(data);
        }
        this.lastPage = meta?.last_page || 1;
        if (this.currentPage === 1) {
          this.options = data;
        } else {
          this.options = this.options.concat(data);
        }

        this.allOptionsEver = uniqBy([...this.allOptionsEver, ...data], this.valueKey)

        this.shouldReload = false
      } finally {
        this.loading = false;
      }
    },
    onSelectFirstOption() {
      if (this.options.length) {
        this.$emit("update:modelValue", this.options[0][this.valueKey]);
      }
    },
    reset() {
      this.shouldReload = true
      this.options = []
    },
    tryAddInitialValue() {
      if (!this.initialValue) {
        return
      }

      let optionsToPush

      if(Array.isArray(this.initialValue)) {
        optionsToPush = this.initialValue
      }
      else {
        optionsToPush = [this.initialValue]
      }

      const newOptions = [...this.options, ...optionsToPush]
      this.options = uniqBy(newOptions, this.valueKey)
    },
    async triggerEntityCreate() {
      this.$refs.entitySelectRef?.blur()
      await this.$nextTick()

      if (this.addEntityAction) {
        this.addEntityAction()
        return
      }

      const mutation = this.addEntityTrigger || `${this.entity}/triggerAdd${capitalize(this.entitySingular)}`
      this.$store.commit('setEntityCreateParams', this.addEntityParams)
      this.$store.commit(mutation)

      this.$store.commit('setEntityCreatedCallback', async (entity) => {
        this.onEntityCreated(entity)

        if (!this.entityCreatedCallback) {
          return
        }

        const { shouldFocusInput } = await this.entityCreatedCallback(entity)

        if (shouldFocusInput) {
          this.$refs.entitySelectRef?.focus()
        }
      })
      this.$store.commit('setEntityDialogClosedCallback', (entity) => {
        this.reset()

        if (this.focusOnEntityDialogClosed && !entity) {
          this.$refs.entitySelectRef?.focus()
        }
      })
    },
    onEntityCreated(entity) {
      if (!entity) {
        return
      }

      this.options = [entity, ...this.options]
      this.allOptionsEver.push(entity)

      let newModelValue = entity[this.valueKey]
      if (this.multiple) {
        newModelValue = [...this.modelValue || [], newModelValue]
      }
      this.$emit('update:modelValue', newModelValue)
      this.$emit('change', newModelValue)
      this.onChange(newModelValue)
    }
  },
  async mounted() {
    if (this.fetchOnMount) {
      await this.getData('', true)
    }
  },
  watch: {
    url(value) {
      if (!value) {
        return
      }
      this.reset()
    },
    urlParams(newValue, oldValue) {
      if(JSON.stringify(newValue) === JSON.stringify(oldValue)) {
        return
      }
      this.reset()
      this.onFocus()
    },
    initialValue: {
      immediate: true,
      handler() {
        this.tryAddInitialValue()
      }
    },
    modelValue: {
      immediate: true,
      async handler(value) {
        if (!this.triggerSync || !value || this.options.find(o => o.id?.toString() === value?.toString())) {
          return
        }

        try {
          this.syncing = true

          const { data } = await axios.get(`${this.url}/${value}`)

          this.options.push(data)
        }
        finally {
          this.syncing = false
        }
      }
    },
    syncing: {
      immediate: true,
      handler(value) {
        this.$emit('is-syncing', value)
      }
    }
  }
};
</script>

<style lang="scss">
.entity-select-wrapper {
  .el-input__prefix {
    @apply absolute;
    left: initial;
    right: 10px;
  }
  .base-select-inner {
    min-width: 250px;
  }

  .external-activator {
    .el-input__inner {
      background: transparent;
      border: none;
      color: transparent !important;
      box-shadow: none !important;
      max-height: 10px !important;
      &::-webkit-input-placeholder {
        color: transparent !important;
        user-select: none;
      }
      &::-moz-placeholder {
        color: transparent !important;
        user-select: none;
      }
    }

    .el-input__suffix {
      i {
         color: transparent;
      }
    }
  }

  &:hover {
    .entity-select-entity-link {
      @apply block;
    }
  }

  &.clearable {
    .el-input__prefix {
      left: initial;
      right: 32px;
    }
  }
}

.el-select__popper {
  .create-entity-option.is-disabled {
    @apply bg-primary-500 hover:bg-primary-600;
  }
}
</style>
