<template>
  <BaseSelect
    v-bind="$attrs"
    v-model="model"
    :options="roleOptions"
    :placeholder="$t('Choose user role..')"
    :name="$t('Add new tool')"
    popper-class="user-role-select-popper "
    class="shadow-sm h-full user-role-select"
    :return-object="false"
  >
    <template #default="{ option }">
      <div class="bg-white w-12 h-12 p-1 border rounded-md self-start mt-2 flex-none text-center">
        <i
          :class="option.icon"
          :style="{ color: option.iconColor }"
          class="text-2xl mt-1 fa-solid"
        />
      </div>
      <div class="ml-4 mb-1">
        <div
          class="font-bold text-sm mb-1 inline"
          v-html="option?.attributes?.name"
        />
        <div
          class="font-normal text-xs whitespace-pre-wrap"
          v-html="option.description"
        />
      </div>
    </template>
  </BaseSelect>
</template>
<script>
import EntitySelect from "@/components/form/EntitySelect.vue";
import UserStatus from "@/components/table/UserStatus.vue";
import { roleProps } from "@/modules/users/util/userUtils";

export default {
  components: {
    EntitySelect,
    UserStatus,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    excludedOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)

        const rawValue = this.roleOptions.find(r => r.value == value)
        this.$emit('raw-change', rawValue)
      }
    },
    roles() {
      return this.$store.getters['users/editableRoles'] || []
    },
    filteredRoles() {
      return this.roles.filter(r => !this.excludedOptions.includes(r.attributes.name))
    },
    roleOptions() {
      return this.filteredRoles.map(role => {
        return {
          ...role,
          ...roleProps[role.attributes.name],
          value: role.id,
          label: role.attributes.name,
        } 
      }).reverse()
    },
    rolesLoading() {
      return this.$store.state.users.rolesLoading || false
    },
  }
}
</script>
<style lang="scss">
.user-role-select-popper {
  @apply w-min;

  .el-select-dropdown__list {
    .el-select-dropdown__item {
      height: unset;

      &:not(.is-disabled) {
        @apply py-2 pl-3 pr-9 hover:text-white;
      }

      &.is-disabled {
        @apply cursor-default bg-transparent;
      }

      &:hover {
        @apply text-white;

        &.selected {
          @apply text-white;
        }

        .paid-user-indicator {
          @apply text-white;
        }
      }
    }
  }

  .el-select-dropdown__list {
    @apply divide-y;
  }
}

.user-role-select.el-select .select-trigger .el-input input.el-input__inner[disabled] {
  @apply opacity-0;
}
</style>
