<template>
  <BaseDialog
    v-model="visible"
    :hasBodyMargin="false"
    :appendToBody="true"
    :overflowVisible="true"
    size="lg"
    @close="$emit('close')"
  >
    <div class="w-full border-b border-gray-200 p-6">
      <slot name="title">
        <h3 class="text-2xl font-bold">
          <span>{{ $t('Create Project From Template') }}</span>
        </h3>
        <p class="text-sm text-gray-500 mt-2">
          {{ $t(`Choose a start date for your project. A start date is required because this template has tasks with relative date types. All relative dates will be calculated based on the start date you choose below.`) }}
        </p>
      </slot>
    </div>
    <div class="sm:space-y-5 p-6">
      <BaseInput
        :modelValue="start_date"
        :label="$t('Start Date')"
        :name="$t('Start Date')"
        layout="horizontal"
        rules="required"
      >
        <template #label>
          <i class="far fa-calendar mr-1 my-auto" />
          <span> {{ $t('Start Date') }} </span>
        </template>
        <BaseDatePicker
          v-model="start_date"
        />
      </BaseInput>
    </div>
    <div class="px-6 py-4 mt-2 border-t border-gray-200">
      <div class="flex justify-end">
        <BaseButton
          variant="white"
          @click="visible = false; $emit('cancel')">
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          type="button"
          class="ml-2"
          @click="createProject"
        >
          {{ $t('Create project') }}
        </BaseButton>
      </div>
    </div>
  </BaseDialog>
</template>
<script>
import i18n from "@/i18n";

export default {
  props: {
    templateId: {
      type: [Number, String],
      default: null
    },
    template: {
      type: Object,
      default: () => ({})
    },
    extraData: {
      type: Boolean,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      start_date: new Date(),
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
  methods: {
    async createProject() {
      this.loading = true
      try {
        const data = {
          templateId: this.templateId || this.template?.id,
          start_date: this.start_date,
          ...this.extraData
        }
        const newProject = await this.$store.dispatch('templates/createProjectFromTemplate', data)

        this.$success(i18n.t(`Project created successfully`))

        await this.$router.push(`/projects/${newProject.id}`)
      }
      catch (e) {
        this.$error(i18n.t(`Could not create project from template`))
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>
