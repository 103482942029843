import i18n from "@/i18n";
import store from "@/store";
import { VIEW_TYPES } from "@/modules/users/util/userUtils"
import { formatDate } from "@/modules/common/utils/dateUtils";
import { DEFAULT_PER_PAGE } from "@/modules/common/utils/paginationUtils"
import {
  ApiFilterKeys,
} from "@/modules/common/commonTypes.d";

export function encodeFilters(filters) {
  if (!filters || !Object.keys(filters).length) {
    return ''
  }

  const stringifiedFilters = JSON.stringify(filters)
  const encodeUri = encodeURIComponent(stringifiedFilters)
  const unescapedFilters = unescape(encodeUri)
  const encodedFilters = btoa(unescapedFilters)

  return encodedFilters
}

export function decodeFilters(filters, returnIfEmpty) {
  if (!filters) {
    return returnIfEmpty
  }

  try {
    const atobResult = atob(filters.toString())
    const escapedAtob = escape(atobResult)
    const decodedUri = decodeURIComponent(escapedAtob)
    const decodedFilters = JSON.parse(decodedUri)

    return decodedFilters
  } catch (err) {
    console.error('Could not decode filters', filters)
  }

  return returnIfEmpty
}

window.decodeFilters = (filters) => {
  if (!filters) {
    filters = new URLSearchParams(window.location.search).get('filters')
  }

  return decodeFilters(filters)
}

window.decodeLocalFilters = (filters) => {
  if (!filters) {
    filters = new URLSearchParams(window.location.search).get('localFilters')
  }

  return decodeFilters(filters, {})
}

export const PEOPLE_OPTIONS = {
  SPECIFIC: 'specific',
  ALL: 'all'
}

export const PROJECT_OPTIONS = {
  NONE: 'none',
  SPECIFIC: 'specific',
  ALL: 'all'
}

export const peopleRadioOptions = [
  {
    value: PEOPLE_OPTIONS.SPECIFIC,
    label: i18n.t('Just Me'),
    description: i18n.t("Save for just me. This will be your default view for this page. Other users won't see this view.")
  },
  {
    value: PEOPLE_OPTIONS.ALL,
    label: i18n.t('All Users'),
    description: i18n.t("Save for all users. Can be overridden by creating a 'Just Me' view.")
  }
]

export const projectRadioOptions = [
  {
    value: PROJECT_OPTIONS.SPECIFIC,
    label: i18n.t('This Project Only'),
    description: i18n.t("Save for this project only.")
  },
  {
    value: PROJECT_OPTIONS.ALL,
    label: i18n.t('All Projects'),
    description: i18n.t("Save for all projects.")
  }
]

export const filterPresetTypesPriority = {
  [`${PROJECT_OPTIONS.NONE}_${PEOPLE_OPTIONS.SPECIFIC}`]: 0,
  [`${PROJECT_OPTIONS.NONE}_${PEOPLE_OPTIONS.ALL}`]: 1,
  [`${PROJECT_OPTIONS.SPECIFIC}_${PEOPLE_OPTIONS.SPECIFIC}`]: 2,
  [`${PROJECT_OPTIONS.SPECIFIC}_${PEOPLE_OPTIONS.ALL}`]: 3,
  [`${PROJECT_OPTIONS.ALL}_${PEOPLE_OPTIONS.SPECIFIC}`]: 4,
  [`${PROJECT_OPTIONS.ALL}_${PEOPLE_OPTIONS.ALL}`]: 5,
}

export function getFilterPresetPriority(filterPreset) {
  const { apply_to_projects, apply_to_users } = filterPreset.attributes
  return filterPresetTypesPriority[`${apply_to_projects}_${apply_to_users}`]
}

export const EntityTargetTypes = {
  PROJECTS: 'projects',
  TEMPLATES: 'templates',
  TASKS: 'tasks',
  USERS: 'users',
  USERS_ARCHIVED: 'users-archived',
  GROUPS: 'groups',
  FILES: 'files',
  PROJECT_NOTES: 'project-notes',
  PAYMENTS: 'payments',
  EMBEDS: 'embeds',
  RESOURCES: 'resources',
  TIME_ENTRIES: 'time-entries',
  ALLOCATED_TIME: 'allocated-time',
  ALL_TIME: 'all-time',
  NOTIFICATIONS: 'notifications',
  CUSTOM_FIELDS: 'custom-fields',
  FILE_PROOFS: 'proofs',
}

export function getEntityTarget(route) {
  if (route.path.includes('/time/actual')) {
    return EntityTargetTypes.TIME_ENTRIES
  }
  if (route.path.includes('/time/allocated')) {
    return EntityTargetTypes.ALLOCATED_TIME
  }
  if (route.path.includes('/time/all')) {
    return EntityTargetTypes.ALL_TIME
  }
  if (route.path.includes('/users/archived')) {
    return EntityTargetTypes.USERS_ARCHIVED
  }
  if (route.path.includes('/notes')) {
    return EntityTargetTypes.PROJECT_NOTES
  }
  if (route.path.includes('/inbox')) {
    return EntityTargetTypes.NOTIFICATIONS
  }
  if (route.path.includes('/custom-fields')) {
    return EntityTargetTypes.CUSTOM_FIELDS
  }
  if (route.path.includes('/file-proofs')) {
    return EntityTargetTypes.FILE_PROOFS
  }

  try {
    return route.path?.split('/').at(-2)
  }
  catch (err) {
    return ''
  }
}

const createAtDescSortFilter = [
  {
    key: "sorts",
    value: {
      column: "created_at",
      order: "desc"
    }
  },
]

const createAtDescSortFilterEncoded = encodeFilters(createAtDescSortFilter);

const descDateSortFilter = [
  {
    key: "sorts",
    value: {
      column: "date",
      order: "desc"
    }
  },
]

const descDateSortFilterEncoded = encodeFilters(descDateSortFilter);

const orderAscSortFilter = [
  {
    key: "sorts",
    value: {
      column: "order",
      order: "asc"
    }
  },
]

const orderAscSortFilterEncoded = encodeFilters(orderAscSortFilter);

const projectsDefaultFilter = [
  {
    key: "sorts",
    value: {
      column: "created_at",
      order: "desc"
    }
  },
  {
    key: 'closed',
    value: {
      show: false
    }
  },
]

const projectsKanbanDefaultFilter = [
  {
    key: "sorts",
    value: {
      column: "order",
      order: "asc"
    }
  },
  {
    key: 'closed',
    value: {
      show: false
    }
  },
]


const tasksDefaultFilter = [
  {
    key: "sorts",
    value: {
      column: "order",
      order: "asc"
    }
  },
  {
    key: "completed",
    value: {
      show: false
    }
  },
  {
    key: "my-tasks",
    value: {
      show: false
    }
  },
  {
    key: "overdue-tasks",
    value: {
      show: false
    }
  },
  {
    key: "show-details-view",
    value: {
      show: true
    }
  },
]

const tasksKanbanDefaultFilter = [
  {
    key: "sorts",
    value: {
      column: "order",
      order: "asc"
    }
  },
  {
    key: "completed",
    value: {
      show: false
    }
  },
  {
    key: "my-tasks",
    value: {
      show: false
    }
  },
  {
    key: "overdue-tasks",
    value: {
      show: false
    }
  }
]

function tryApplyMyAllFilter(filters, target, fromRoute) {
  if (!fromRoute?.query?.filters) {
    return
  }

  const fromTarget = getEntityTarget(fromRoute)

  if (fromTarget !== target) {
    return
  }
  
  const filterKey = target === EntityTargetTypes.PROJECTS
    ? ApiFilterKeys.MyProjects
    : ApiFilterKeys.MyTasks

  const fromFilters = decodeFilters(fromRoute.query.filters)

  const myFilterIdx_from = fromFilters.findIndex(f => f.key === filterKey)
  if (myFilterIdx_from === -1) {
    return
  }
  const myFilterIdx_to = filters.findIndex(f => f.key === filterKey)

  if (myFilterIdx_to === -1) {
    filters.push(fromFilters[myFilterIdx_from])
  }
  else {
    filters[myFilterIdx_to] = fromFilters[myFilterIdx_from]
  }
}

function getDateSortFilter(sortProp = 'date', order = 'desc') {
  return [
    {
      key: "sorts",
      value: {
        column: sortProp,
        order
      }
    },
  ]
}

export const DEFAULT_TARGET_LIST_FILTERS = {
  [EntityTargetTypes.PROJECTS](view_type, fromRoute = null) {
    const filters = view_type === VIEW_TYPES.KANBAN
      ? projectsKanbanDefaultFilter
      : projectsDefaultFilter
      
    tryApplyMyAllFilter(filters, EntityTargetTypes.PROJECTS, fromRoute)

    return {
      filters: encodeFilters(filters)
    }
  },
  [EntityTargetTypes.TEMPLATES]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.TASKS](view_type, fromRoute = null) {

    const defaultApiFilters = {
      [VIEW_TYPES.KANBAN]: tasksKanbanDefaultFilter,
      [VIEW_TYPES.LIST]: tasksDefaultFilter,
      [VIEW_TYPES.CARD]: getDateSortFilter('sortable_date', 'asc'),
    }

    const defaultLocalFilters = {
      [VIEW_TYPES.CARD]: {
        groupBy: ['attributes.sortable_date']
      }
    }

    const filters = defaultApiFilters[view_type] || []
    const localFilters = defaultLocalFilters[view_type] || {}

    tryApplyMyAllFilter(filters, EntityTargetTypes.TASKS, fromRoute)

    return {
      filters: encodeFilters(filters),
      localFilters: encodeFilters(localFilters)
    }
  },
  [EntityTargetTypes.USERS]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.USERS_ARCHIVED]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.GROUPS]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.PROJECT_NOTES]: {
    filters: orderAscSortFilterEncoded
  },
  [EntityTargetTypes.FILES]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.PAYMENTS]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.RESOURCES]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.TIME_ENTRIES]: {
    filters: descDateSortFilterEncoded
  },
  [EntityTargetTypes.FILE_PROOFS]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.ALLOCATED_TIME]: {
    filters: createAtDescSortFilterEncoded
  },
  [EntityTargetTypes.EMBEDS]() {
    return {
      localFilters: encodeFilters({
        groupBy: ['attributes.type']
      })
    }
  },
  [EntityTargetTypes.CUSTOM_FIELDS]() {
    return {
      localFilters: encodeFilters({
        groupBy: ['attributes.entity_type']
      })
    }
  }
}

function getCalendarSchedulerFilters(view_type, target = EntityTargetTypes.TASKS, dateColumn = 'date') {
  const dateFormat = 'yyyy-MM-dd'
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  const firstDiff = today.getDate() - today.getDay() + 1
  const lastDiff = firstDiff + 6;

  const firstDayOfWeek = new Date(today.setDate(firstDiff))
  const lastDayOfWeek = new Date(today.setDate(lastDiff + 1))

  let calendarFilters = [...tasksDefaultFilter]

  if (target === EntityTargetTypes.PROJECTS) {
    calendarFilters = [...projectsDefaultFilter]
  }


  const diff = firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + 1

  const firstCalendarDay = new Date(firstDayOfMonth)
  firstCalendarDay.setDate(diff)

  const lastCalendarDay = new Date(lastDayOfMonth)

  const diffDays = (lastDayOfMonth.getTime() - firstCalendarDay.getTime()) / (1000 * 3600 * 24)
  lastCalendarDay.setDate(lastCalendarDay.getDate() + (42 - diffDays))

  const minMax = {
    min: view_type === VIEW_TYPES.SCHEDULER
      ? formatDate(firstDayOfWeek, dateFormat)
      : formatDate(firstCalendarDay, dateFormat),

    max: view_type === VIEW_TYPES.SCHEDULER
      ? formatDate(lastDayOfWeek, dateFormat)
      : formatDate(lastCalendarDay, dateFormat)
  }

  calendarFilters.push({
    key: 'filters',
    value: [
      {
        column: dateColumn,
        hidden: true,
        query: minMax,
      }
    ]
  })

  return calendarFilters
}

export function getSystemDefaultEntityFilters(target, view_type, fromRoute = null) {
  const isCalendarOrScheduler = [VIEW_TYPES.SCHEDULER, VIEW_TYPES.CALENDAR].includes(view_type)
  if (target === EntityTargetTypes.TASKS && isCalendarOrScheduler) {
    const calendarFilters = getCalendarSchedulerFilters(view_type, target, 'date')
    return {
      filters: encodeFilters(calendarFilters),
      localFilters: encodeFilters({
        groupBy: ['attributes.allocated_ids']
      })
    }
  } else if (target === EntityTargetTypes.PROJECTS && isCalendarOrScheduler) {
    const calendarFilters = getCalendarSchedulerFilters(view_type, target, 'start_date_deadline')

    return {
      filters: encodeFilters(calendarFilters),
      localFilters: encodeFilters({
        groupBy: ['attributes.status_id']
      })
    }
  }

  const defaultQuery = {
    filters: '',
    localFilters: '',
    perPage: DEFAULT_PER_PAGE
  }

  if (!DEFAULT_TARGET_LIST_FILTERS[target]) {
    return defaultQuery
  }

  const targetQuery = typeof DEFAULT_TARGET_LIST_FILTERS[target] === 'function'
    ? DEFAULT_TARGET_LIST_FILTERS[target](view_type, fromRoute)
    : DEFAULT_TARGET_LIST_FILTERS[target]

  return {
    ...defaultQuery,
    ...targetQuery
  }
}

export function isFilterPresetActive(filterPreset) {
  if (typeof filterPreset === 'string') {
    try {
      filterPreset = JSON.parse(filterPreset)
    }
    catch (err) {
      return false
    }
  }

  const keys = ['filters', 'localFilters', 'perPage', 'viewType', 'slotWidth']
  for (const k of keys) {

    let queryValue = (store.state.route?.query[k] || '').toString()
    let filterValue = (filterPreset[k] || '').toString()

    if (k === 'perPage') {
      queryValue = queryValue || DEFAULT_PER_PAGE.toString()
      filterValue = filterValue || DEFAULT_PER_PAGE.toString()
    }

    if (queryValue !== filterValue) {
      return false
    }
  }

  return true
}

export function getFilterPresetTitle(filterPreset) {
  const filterPresetAttributes = filterPreset.attributes

  let projectLabel = ''
  if (filterPresetAttributes.apply_to_projects === PROJECT_OPTIONS.ALL) {
    projectLabel = i18n.t('All Projects')
  } else if (filterPresetAttributes.apply_to_projects === PROJECT_OPTIONS.SPECIFIC) {
    projectLabel = i18n.t('This Project')
  }

  let usersLabel = ''

  if (filterPresetAttributes.apply_to_users === PEOPLE_OPTIONS.ALL) {
    usersLabel = i18n.t('All Users')
  } else if (filterPresetAttributes.apply_to_users === PEOPLE_OPTIONS.SPECIFIC) {
    usersLabel = i18n.t('Just Me')
  }

  if (!projectLabel) {
    return usersLabel
  }

  return `${projectLabel} - ${usersLabel}`;
}
