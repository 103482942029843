<template>
  <BaseDialogNew
    v-model="visible"
    class="max-w-lg w-full"
    @close="$emit('close')"
  >
    <div class="p-6">
      <slot name="title">
        <h3 class="text-2xl font-bold">
          <span>{{ $t('Duplicate Task') }}</span>
        </h3>
        <p class="text-sm text-gray-500 mt-2">
          {{ $t(`This will duplicate the task along with all data except for allocated time, actual time and the chat conversation.`) }}
        </p>
      </slot>
    </div>
    <div class="px-6 py-4 mt-2 border-t border-gray-200">
      <div class="flex justify-end">
        <BaseButton
          variant="white"
          @click="visible = false; $emit('cancel')"
        >
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          type="button"
          class="ml-2"
          @click="duplicateTask"
        >
          {{ $t('Duplicate Task') }}
        </BaseButton>
      </div>
    </div>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import i18n from '@/i18n';
import { error, success } from "@/components/common/NotificationPlugin";
import { useStore } from 'vuex';
import useEntityCrud from "@/modules/common/composables/useEntityCrud"

const store = useStore()

const {
  entityTypes,
  triggerEntityCreate
} = useEntityCrud()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  task: {
    type: Object,
    default: () => ({})
  },
})

const emit = defineEmits(['update:modelValue'])

const loading = ref(false)

const visible = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  }
})

async function duplicateTask() {
  loading.value = true
  try {
    const duplicatedTask = await store.dispatch('tasks/duplicateTask', {
      taskId: props.task.id,
    })

    triggerEntityCreate(entityTypes.Task, {
      taskId: duplicatedTask.id,
    })

    success(i18n.t('Task duplicated successfully.'))

    visible.value = false
  }
  catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not duplicate task.'));
  }
  finally {
    loading.value = false
  }
}
</script>
