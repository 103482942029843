<template>
  <BaseEntityForm
    :title="reference?.id ? $t('Edit Reference') : $t('Create Reference')"
    :subtitle="$t('A reference is a link to another item within your account')"
    :isDialogForm="true"
    width-class="max-w-2xl"
    @submit="onSubmit"
  >
    <template #default="{ meta, errors }">
      <BaseInput
        :modelValue="model.image"
        :name="$t('Image')"
        :label="$t('Image')"
        id="image"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      >
        <BaseAvatarPicker
          v-model="model.image"
          size="md"
          :disabled-options="['group', 'unsplash']"
          :placeholder="$t('+ Add an image (optional)')"
        />
      </BaseInput>

      <BaseInput
        :modelValue="model.target_project_id"
        :name="$t('Project')"
        :label="$t('Project')"
        layout="horizontal"
        rules="required"
        id="project"
        class="mt-1 sm:mt-0 md:col-span-2"
        :info-text="$t('Only people that have access to this project will see this reference.')"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <ProjectSelect
            v-model="model.target_project_id"
            :url-params="{
              related: 'tools',
            }"
            :initialValue="selectedProject"
            :placeholder="$t('Choose project...')"
            @raw-change="selectedProject = $event"
          />
        </div>
      </BaseInput>

      <BaseInput
        :modelValue="model.referenceable_type"
        :name="$t('Tool')"
        :label="$t('Tool')"
        layout="horizontal"
        rules="required"
        id="tool"
        class="mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <BaseSelect
            v-model="model.referenceable_type"
            :disabled="!model.target_project_id"
            :options="referenceOptions"
            :return-object="false"
            :placeholder="$t('Choose tool...')"
            @change="onSelectedTypeChanged"
          />
        </div>
      </BaseInput>

      <BaseInput
        :modelValue="model.referenceable_id"
        :name="$t('Item')"
        :label="$t('Item')"
        layout="horizontal"
        rules="required"
        id="referenceable_id"
        class="mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <EntitySelect
            v-if="selectedType?.url"
            v-model="model.referenceable_id"
            :disabled="!model.target_project_id || !selectedType"
            :url="selectedType?.url"
            :url-params="{
              project_id: model.target_project_id,
            }"
            :labelKey="selectedType?.labelKey || undefined"
            :triggerSync="true"
            :placeholder="$t('Choose item...')"
            @raw-change="onItemChange"
          />
          
          <BaseSelect
            v-else
            v-model="model.referenceable_id"
            :disabled="!model.target_project_id || !selectedType"
            :options="selectedType?.options"
            :placeholder="$t('Choose item...')"
            @update:modelValue="onItemChange"
          />
        </div>
      </BaseInput>

      <BaseInput
        v-model="model.name"
        :label="$t('Name')"
        :placeholder="$t('Enter reference name...')"
        :errorMessage="errors[$t('Reference Name')]"
        :name="$t('Name')"
        layout="horizontal"
        rules="required"
        id="name"
      />

      <BaseInput
        v-model="model.description"
        :name="$t('Description')"
        :label="$t('Description')"
        :placeholder="$t('Enter Description (optional)...')"
        rules="max:55"
        id="description"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      />
      
      <BaseRoundedCheckbox
        v-if="!defaultPinned"
        v-model="model.pinned"
        :name="$t('Pinned')"
        :label="$t('Pinned')"
        size="sm"
        inputClass="sm:col-span-3"
      />
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$emit('cancel')"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        :disabled="!meta.valid"
        :loading="loading"
        type="submit"
        class="ml-2"
      >
        {{ $t('Save') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script setup>
import { get, cloneDeep } from 'lodash-es'
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import i18n from "@/i18n.js";

import ProjectSelect from "@/components/selects/ProjectSelect.vue";
import BaseSelect from "@/components/form/BaseSelect.vue";
import EntitySelect from "@/components/form/EntitySelect.vue";
import {
  getReferenceOptions,
  projectReferenceTypes
} from "@/components/html/util/referenceUtils.js";
import { success, error } from '@/components/common/NotificationPlugin';

const store = useStore()
const route = useRoute()

const props = defineProps({
  reference: {
    type: Object,
    default: () => null
  },
  defaultPinned: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['save'])

const model = ref({
  project_id: route.params.id || '',
  target_project_id: '',
  referenceable_type: '',
  referenceable_id: null,
  name: '',
  description: '',
  image: '',
  pinned: props.defaultPinned,
  url: null,
})

const selectedProject = ref(null)
const loading = ref(false)
const syncingProject = ref(false)
const projectSynced = ref(false)


const currentProject = computed(() => {
  return store.state.projects.currentProject
})

const projectTools = computed(() => {
  const project = selectedProject.value
  const tools = project?.relationships?.tools || []
  return tools.map(t => t.attributes.name)
})

watch(() => model.value.target_project_id, async (value) => {
  if (!value) {
    return
  }

  try {
    if (projectSynced.value) {
      return
    }
    syncingProject.value = true
    selectedProject.value = await store.dispatch('projects/getProjectById', {
      id: value,
      returnEntity: true
    })
    projectSynced.value = true
  }
  finally {
    syncingProject.value = false
  }
}, { immediate: true })

const referenceOptions = computed(() => {
  const options = getReferenceOptions(projectTools.value, model.value.target_project_id)
  return options.filter(o => o.value === model.value.referenceable_type || !o.renderIf || o.renderIf())
})

const selectedType = computed(() => {
  return referenceOptions.value.find(o => o.value === model.value.referenceable_type)
})

async function onSubmit(emitSave = true) {
  try {
    loading.value = true;
    const data = cloneDeep(model.value);
    const isPageReference = [
      projectReferenceTypes.Payment,
      projectReferenceTypes.TimeEntry,
      projectReferenceTypes.Discussion,
      projectReferenceTypes.Project,
    ].includes(data.referenceable_type)

    if (isPageReference) {
      data.referenceable_id = null
    }

    let reference;
    if (props.reference?.id) {
      reference = await editReference(data)
    }
    else {
      reference = await createReference(data)
    }

    const message = props.reference?.id
      ? i18n.t("Reference updated successfully")
      : i18n.t("Reference created successfully");

    success(message)

    if (emitSave) {
      emit("save", reference);
    } else {
      router.go(-1);
    }
  } catch (err) {
    if (err.handled) {
      return false;
    }

    const message = props.reference?.id
      ? i18n.t("Could not update reference")
      : i18n.t("Could not create reference");

    error(message);
    return false;
  } finally {
    loading.value = false;
  }
}

async function createReference(data) {
  const result = await store.dispatch("projects/createProjectReference", { data });
  return result.data;
}

async function editReference(data) {
  const result = await store.dispatch("projects/editProjectReference", {
    referenceId: props.reference?.id,
    data,
  });

  return result.data;
}


watch(() => props.reference, (value) => {
  if (!value?.id) {
    return
  }

  model.value = {
    ...value.attributes,
    project_id: value.attributes.project_id || route.params.id,
    pinned: !!Number(value.attributes.pinned)
  }

  if (!model.value.referenceable_id) {
    onSelectedTypeChanged(selectedType.value, false)
  }
  
}, { immediate: true })

function onProjectChange(project) {
  selectedProject.value = project
  model.value.referenceable_id = null
  model.value.url = null
}
function onSelectedTypeChanged(option, triggerItemChange = true) {
  model.value.referenceable_type = option?.value
  model.value.url = null
  model.value.referenceable_id = null

  if (option?.options?.length === 1 ) {
    const item = option.options[0]
    model.value.referenceable_id = item?.value

    if (triggerItemChange) {
      onItemChange(item)
    }
  }
}

function onItemChange(item) {
  if (syncingProject.value) {
    return
  }

  const name = get(item, selectedType.value?.labelKey)
  const projectName = selectedProject?.value?.attributes?.name
  
  model.value.name = name
  if (model.value.target_project_id !== currentProject.value?.id && projectName) {
    model.value.name = `${projectName} - ${name}`  
  }
  model.value.url = selectedType.value?.itemUrl(item)
}

</script>
