<template>
  <BaseDialog
    v-bind="$attrs"
    size="xl"
  >
    <div class="m-auto align-middle bg-white border border-gray-200 overflow-hidden rounded-lg shadow">
      <div class="w-full border-b border-gray-200 p-6">
        <h3
          class="text-2xl font-bold"
          v-html="$t('save entity page settings', { target, view_type })"
        />
        <p class="text-sm text-gray-500 mt-2">
          <span>{{ $t('Save the current filter, sorting, grouping and column choices for this page.') }}</span>
        </p>
      </div>
      <VeeForm
        v-slot="{ meta }"
        class="divide-y divide-gray-200"
        @submit="onSubmit"
      >
        <div class="sm:space-y-5 p-6">
          <RequiresPermissionTo :action="$actions.MANAGE_ALL_USERS_VIEWS">
            <BaseInput
              :modelValue="model.apply_to_users"
              :name="$t('People')"
              :column-count="8"
              layout="horizontal"
              rules="required"
              id="people"
            >
              <template #label>
                <label class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                  <i class="far fa-users mr-2 w-4" />
                  {{ $t('People') }}
                </label>
              </template>
              <BaseRadioGroup
                v-model="model.apply_to_users"
                :options="peopleRadioOptions"
                type="radio-button"
              >
                <template #option="{ option, isSelected }">
                  <div
                    class="w-full relative block bg-white rounded-lg px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none border-2"
                    :class="{
                      'border-primary-500': isSelected,
                      'border-gray-200': !isSelected
                    }"
                  >
                    <div class="flex items-center">
                      <div class="text-sm">
                        <p class="font-medium text-gray-900">{{ option.label }}</p>
                        <div class="text-gray-500">
                          <p class="sm:inline whitespace-pre-wrap font-normal">{{ option.description }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="absolute -inset-px rounded-lg border-0 pointer-events-none" aria-hidden="true"></div>
                  </div>
                </template>
              </BaseRadioGroup>
            </BaseInput>
          </RequiresPermissionTo>

          <BaseInput
            v-if="project_id"
            :modelValue="model.apply_to_projects"
            :name="$t('Project')"
            :column-count="8"
            layout="horizontal"
            rules="required"
            id="project"
          >
            <template #label>
              <label class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                <i class="far fa-inbox mr-2 w-4" />
                {{ $t('Project') }}
              </label>
            </template>
            <BaseRadioGroup
              v-model="model.apply_to_projects"
              :options="projectRadioOptions"
              type="radio-button"
            >
              <template #option="{ option, isSelected }">
                <div
                  class="w-full relative block bg-white rounded-lg px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none border-2"
                  :class="{
                    'border-primary-500': isSelected,
                    'border-gray-200': !isSelected
                  }"
                >
                  <div class="flex items-center">
                    <div class="text-sm">
                      <p class="font-medium text-gray-900">{{ option.label }}</p>
                      <div class="text-gray-500">
                        <p class="sm:inline whitespace-pre-wrap font-normal">{{ option.description }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="absolute -inset-px rounded-lg border-0 pointer-events-none" aria-hidden="true"></div>
                </div>
              </template>
            </BaseRadioGroup>
          </BaseInput>
        </div>
        <div class="px-6 py-4 mt-6 border-t border-gray-200 sticky w-full bottom-0 bg-white z-10">
          <div class="flex justify-end">
            <BaseButton
              variant="white"
              @click="$emit('close')"
            >
              {{ $t('Cancel') }}
            </BaseButton>
            <BaseButton
              :loading="loading"
              :disabled="!meta.valid"
              type="submit"
              class="ml-2"
            >
              {{ $t('Save') }}
            </BaseButton>
          </div>
        </div>
      </VeeForm>
    </div>
  </BaseDialog>
</template>
<script>
import {
  PEOPLE_OPTIONS,
  PROJECT_OPTIONS,
  peopleRadioOptions,
  projectRadioOptions
} from "@/modules/common/utils/filterUtils"

export default {
  props: {
    target: {
      type: String,
      required: true
    },
    view_type: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {
        apply_to_users: PEOPLE_OPTIONS.SPECIFIC,
        apply_to_projects: PROJECT_OPTIONS.SPECIFIC,
      },
      peopleRadioOptions,
      projectRadioOptions,
    }
  },
  computed: {
    project_id() {
      return this.$store.getters.project_id
    },
  },
  methods: {
    async onSubmit(emitSave = true) {
      if (!emitSave) {
        return
      }
      this.$emit('save', this.model)
    },
  }
}
</script>
