<template>
  <BasePopoverInput
    :disabled="previewType === UserPreviewTypes.Disabled"
  >
    <template #reference>
      <div class="flex space-x-2 items-center">
        <BaseLogo
          :entity="group"
          :size="size"
          :class="$attrs.logoClass"
          :disableTooltip="$attrs.disableTooltip"
          :rounded="$attrs.rounded"
        />
        <slot name="after" />
      </div>
    </template>
    <GroupPreviewCard
      :group="group"
      :showGroups="showGroups"
      :type="previewType"
    >
      <div
        v-if="actions?.length"
        class="flex justify-between space-x-2"
      >
        <router-link
          v-if="actions.includes(UserPreviewActions.View)"
          :to="`/groups/${group.id}/view`"
          class="w-full whitespace-nowrap"
        >
          <BaseButton
            variant="primary"
            size="sm"
            block
          >
            {{ $t('View Group') }}
          </BaseButton>
        </router-link>
        <BaseButton
          v-if="actions.includes(UserPreviewActions.Remove)"
          variant="danger"
          size="sm"
          block
          @click="emit('remove')"
        >
          {{ $t('Remove') }}
        </BaseButton>
      </div>
    </GroupPreviewCard>
  </BasePopoverInput>
</template>
<script lang="ts" setup>
// Components
import GroupPreviewCard from "@/modules/groups/components/GroupPreviewCard.vue";

// Utils
import { PropType } from "vue";
import {
  UserPreviewTypes,
  UserPreviewActions
} from "@/modules/users/util/userUtils";



const props = defineProps({
  group: {
    type: Object,
    default: () => ({})
  },
  size: {
    type: String,
    default: 'sm'
  },
  previewType: {
    type: String as PropType<UserPreviewTypes>,
    default: UserPreviewTypes.Inline
  },
  actions: {
    type: Array as PropType<Array<UserPreviewActions>>,
    default: () => [
      UserPreviewActions.View,
      UserPreviewActions.Remove
    ]
  },
  showGroups: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'remove'
])

</script>
