export function encodeEmoji(unicodeEmoji) {
  return window.btoa(encodeURIComponent(unicodeEmoji))
}

export function decodeEmoji(base64Emoji) {
  try {
    return decodeURIComponent(window.atob(base64Emoji)) 
  } catch (err) {
    console.warn('Error decoding avatar emoji', base64Emoji)
    return ''
  }
}
