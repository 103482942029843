<template>
  <ProjectStatusSelect
    v-model="model"
    @update:modelValue="saveValue"
    v-focus
    :return-object="false"
    inline
    filterable
    class="disabled-focus table-inline-edit-input"
  >
    <template #prefix>
      <BaseBadge
        v-if="selectedOption"
        :color="selectedOption?.attributes?.color"
      >
        {{ selectedOption?.attributes?.name }}
      </BaseBadge>
      <BaseBadge
        v-else
        color="grey"
      >
        {{ $t('No status') }}
      </BaseBadge>  
    </template>
  </ProjectStatusSelect>
</template>
<script lang="ts">
// Components
import ProjectStatusSelect from "@/modules/projects/components/ProjectStatusSelect.vue";

// Utils
import { PropType, defineComponent } from "vue";
import { ICellEditorParams } from "@ag-grid-community/core";
import { stopCellEditing } from "@/components/table/tableUtils";

export default defineComponent({
  components: {
    ProjectStatusSelect,
  },
  props: {
    params: {
      type: Object as PropType<ICellEditorParams<any>>,
      default: () => ({})
    },
  },
  data() {
    return {
      model: this.params.value,
    }
  },
  computed: {
    orderedStatuses() {
      return this.$store.getters['projects/orderedStatuses'] || []
    },
    selectedOption() {
      return this.orderedStatuses.find((status: any) => status.id?.toString() === this.model?.toString())
    },
    completedStatus() {
      return this.$store.getters['projects/completedStatus']
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.params.data)
    }
  },
  methods: {
    isCancelBeforeStart() {
      // TODO: Check if user has permission to edit projects or project closed
      return false
    },
    isCancelAfterEnd() {
      // TODO: Check if this needs to be used
      return false
    },
    getValue() {
      return this.model
    },
    saveValue() {
      stopCellEditing(this.params)
    }
  },
})
</script>
