<template>
  <HtmlEditor
    v-if="(providerInitialized && extensions.length) || !isRealTimeEnabled"
    v-bind="$attrs"
    :loading="!isSynced && isRealTimeEnabled"
    :model-value="modelValue"
    :real-time="realTime && isRealTimeEnabled"
    :extra-extensions="extensions"
    :read-only="isSynced"
    @editor-created="onEditorCreated"
    @blur="onBlur"
    @update:modelValue="updateModel"
  >
    <template #loading>
      <slot name="loading" />
    </template>
  </HtmlEditor>
</template>
<script lang="ts" setup>
import * as Y from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { enableRealTimeCollaboration, getRealTimeHost } from "@/modules/common/config.js";
import orderBy from "lodash-es/orderBy";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { getUserColor } from "@/components/html/util/collaborationColors";
import store from "@/store/index.js";

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  realTimeValue: {
    required: true,
  },
  realTimeDocUrl: {
    type: String,
  },
  realTimeDocField: {
    type: String,
  },
  realTime: {
    type: Boolean,
    default: true,
  }
})

let provider: any = null
const editor = ref()
const providerInitialized = ref(false)
const isSynced = ref(false)

function onEditorCreated(value: any) {
  editor.value = value
}

const emit = defineEmits(['blur', 'update:modelValue'])

function updateModel(value: string) {
  if (enableRealTimeCollaboration && props.realTime && !isSynced.value) {
    return
  }
  emit('update:modelValue', value)
}
function onBlur(event: any) {
  if (props.realTime && isRealTimeEnabled.value && !isSynced.value) {
    return
  }
  emit('blur', event)
}

const userDetails = computed(() => {
  const user = store.state.auth?.user || {}
  return {
    name: user.name,
    color: getUserColor(user.name),
    joinedAt: new Date().getTime(),
  }
})

const isRealTimeEnabled = computed(() => {
  return enableRealTimeCollaboration && props.realTime
})

const extensions = computed(() => {
  if (!isRealTimeEnabled.value) {
    return []
  }
  const collaboration = Collaboration.configure({
    document: provider?.document,
  })
  const collaborationCursor = CollaborationCursor.configure({
    provider: provider,
    user: userDetails.value,
  })
  return [
    collaboration,
    collaborationCursor,
  ]
})

const collaborationUsers = ref<any[]>([])

function initProvider() {
  if (!props.realTime || !isRealTimeEnabled.value) {
    return
  }
  const ydoc = new Y.Doc()
  provider = new HocuspocusProvider({
    url: getRealTimeHost(),
    name: props.realTimeDocUrl || crypto.randomUUID(),
    document: ydoc,
    token: localStorage.getItem('token'),
    parameters: {
      docUrl: props.realTimeDocUrl,
      docField: props.realTimeDocField
    },
    onSynced: () => {
      /**
       * We need this to fall back to the previous html value
       * For example when we have: description: <p>test</p> and description_collaboration: null, we need to fall back
       * to description because the real time value was not set previously
       */
      const noRealTimeValue = !props.realTimeValue || props.realTimeValue === "null" || editor.value?.getText() === ''
      if (noRealTimeValue && props.modelValue) {
        editor.value?.commands?.setContent(props.modelValue, false)
      }
      isSynced.value = true
    },
    onAwarenessUpdate: ({states}) => {
      collaborationUsers.value = orderBy(states, 'user.joinedAt')
    },
  })
  providerInitialized.value = true
}

onMounted(() => {
  initProvider()
})
onBeforeUnmount(() => {
  provider?.destroy()
})

defineExpose({
  getHTML: () => {
    return editor.value?.getHTML()
  },
  editor,
})
</script>
