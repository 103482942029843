<template>
  <EntitySelect
    v-bind="$attrs"
    url="/restify/custom-field-types"
    labelKey="attributes.name"
    valueKey="id"
    :placeholder="$attrs.placeholder || $t('Select Custom field type...')"
    :labelFormat="labelFormat"
    reverse-options
    class="custom-field-type-select"
  >
    <template #option="{ row }">
      <div class="flex items-center py-1">
        <span class="capitalize truncate">{{ row.label || row.value }}</span>
      </div>
    </template>
  </EntitySelect>
</template>
<script>
import EntitySelect from "@/components/form/EntitySelect.vue";
import { capitalize } from "lodash-es"

export default {
  components: {
    EntitySelect,
  },
  methods: {
    labelFormat(option) {
      return this.$t(capitalize(option.attributes.name))
    }
  }
};
</script>
<style lang="scss">
.custom-field-type-select {
  input {
    text-transform: capitalize;
  }
}
</style>
