import commonCurrencies from "@/modules/common/currencies/common-currencies.json";

export const PAYMENT_STATUSES = {
  PAID: 'succeeded',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  UNPAID: 'unpaid',
  UPCOMING: 'upcoming',
}

export const INVOICE_STATUSES = {
  PAID: 'paid',
  FAILED: 'failed',
  UNPAID: 'unpaid',
  UPCOMING: 'upcoming',
  UPCOMING_FIRST: 'upcoming_first',
}

export const CURRENCIES = {
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
}

export const PaymentProviders = {
  Manual: 'Manual',
  Stripe: 'stripe',
  Paypal: 'paypal'
}

export const ALL_CURRENCIES = [CURRENCIES.GBP, CURRENCIES.EUR, CURRENCIES.USD]

const COMMON_CURRENCIES = []

for (let code in commonCurrencies) {
  COMMON_CURRENCIES.push(commonCurrencies[code])
}

export const ALL_COMMON_CURRENCIES = COMMON_CURRENCIES

export const DEFAULT_CURRENCY = COMMON_CURRENCIES.find(x => x.code === 'GBP')

export const colors = {
  [PAYMENT_STATUSES.PAID]: '#10B981',
  [PAYMENT_STATUSES.UNPAID]: 'rgb(239, 68, 68)',
}

export function getPaymentStatusColor(status) {
  return colors[status] || colors[PAYMENT_STATUSES.UNPAID]
}
