<template>
  <div v-if="editor" ref="menuContainerRef">
    <template v-if="layout === Layouts.Box">
      <EditorBoxMenu
        :editor="editor"
        :readonly="readonly"
        :charLimit="charLimit"
        :infoText="infoText"
        :refference-button="slashMenu"
        :disabled-plugins="disabledPlugins"
        v-bind="$attrs"
      >
        <template #footer-right>
          <slot name="footer-right"></slot>
        </template>
        <EditorContent :editor="editor"/>
      </EditorBoxMenu>
    </template>

    <template v-else>
      <EditorBubbleMenu
        :editor="editor"
        :readonly="readonly"
        :infoText="infoText"
      />
      <template v-if="loading">
        <slot name="loading">
          <div class="animate-pulse w-full bg-gray-50 h-6"/>
        </slot>
      </template>
      <template v-else>
        <EditorContent :editor="editor" />
        <template v-if="!readonly">
          <ContentItemMenu :editor="editor"/>
          <TableRowMenu :editor="editor" :appendTo="$refs.menuContainerRef"/>
          <TableColumnMenu :editor="editor" :appendTo="$refs.menuContainerRef"/>
          <ColumnsMenu :editor="editor" :appendTo="$refs.menuContainerRef"/>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'

// Extensions
import StarterKit from '@tiptap/starter-kit'
import TasksList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Placeholder from '@tiptap/extension-placeholder'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import DropCursor from '@tiptap/extension-dropcursor'
import Focus from '@tiptap/extension-focus'
import Details from '@tiptap-pro/extension-details'
import CharacterCount from '@tiptap/extension-character-count'
import DetailsSummary from '@tiptap-pro/extension-details-summary'
import DetailsContent from '@tiptap-pro/extension-details-content'

// Custom extensions
import Commands from '@/components/html/extensions/commandsMenuExtension'
import Emojis from '@/components/html/extensions/emojiExtension.js'
import Document from "@/components/html/extensions/Document/Document";
import ResizableImage from "@/components/html/extensions/resizableImageExtension.js";
import Link from "@/components/html/extensions/linkExtension.ts";
import { uploadDroppedFiles } from "@/components/html/util/uploadUtils.js";
import Iframe from "@/components/html/extensions/iframeExtension.js";
import Icon from "@/components/html/extensions/iconExtension.js";
import Task from "@/components/html/extensions/taskExtension.js";
import Reference from "@/components/html/extensions/referenceExtension.js";
import CodeBlockExtension from "@/components/html/extensions/codeBlockExtension.js";
import MentionExtension from "@/components/html/extensions/mentionExtension.js";
import HardBreak from "@/components/html/extensions/hardBreakExtension.js";
import Alert from "@/components/html/extensions/alertExtension.js";
import InsertHTML from "@/components/html/extensions/insertHtml";
import UploadingFile from "@/components/html/extensions/uploadingFileExtension.js";
import ExternalFile from "@/components/html/extensions/externalFileExtension.js";
import AiAssistantExtension from "@/components/html/extensions/aiAssistantExtension";
// Components
import EditorBubbleMenu from "@/components/html/EditorBubbleMenu.vue";
import EditorBoxMenu from "@/components/html/EditorBoxMenu.vue";

// Utils
import i18n from "@/i18n.js";
import { getFileNodes, getMentionNodes } from "@/components/html/util/tipTapUtils.js";
import { entityTypes } from '@/modules/common/enum/entityTypes'
import { EditorInitCommands } from '@/components/html/util/editorUtils'
import ContentItemMenu from "@/components/html/extensions/ContentItemMenu/ContentItemMenu.vue";
import TableRowMenu from "@/components/html/extensions/Table/menus/TableRow/TableRowMenu.vue";
import TableColumnMenu from "@/components/html/extensions/Table/menus/TableColumn/TableColumnMenu.vue";
import { Table, TableCell, TableHeader, TableRow } from "@/components/html/extensions/Table";
import ColumnsMenu from "@/components/html/extensions/MultiColumn/menus/ColumnsMenu.vue";
import { Column, Columns } from "@/components/html/extensions/MultiColumn";
import { TrailingNode } from "@/components/html/extensions/TrailingNode/trailing-node";

const Layouts = {
  Inline: 'inline',
  Box: 'box',
}
export default {
  components: {
    ColumnsMenu,
    TableColumnMenu,
    TableRowMenu,
    ContentItemMenu,
    EditorBoxMenu,
    EditorBubbleMenu,
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    json: {
      type: Object,
      default: () => ({}),
    },
    mentions: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    enforceTitle: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: i18n.t('Start writing or type / for options…'),
    },
    autofocus: {
      type: [String, Boolean],
      default: 'end',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: Layouts.Inline,
    },
    contentClass: {
      type: String,
      default: '',
    },
    charLimit: {
      type: Number,
      default: -1,
    },
    cleanupFilesOnDestroy: {
      type: Boolean,
      default: false,
    },
    showPlaceholderOnlyWhenEditable: {
      type: Boolean,
      default: true
    },
    disabledPlugins: {
      type: Array,
      default: () => []
    },
    infoText: {
      type: String,
      default: ''
    },
    projectId: {
      type: [String, Number, Boolean],
      default: null
    },
    realTime: {
      type: Boolean,
      default: false,
    },
    extraExtensions: {
      type: Array,
      default: () => []
    },
    slashMenu: {
      type: Boolean,
      default: true,
    },
    commandsIds: {
      type: Array,
      default: () => []
    },
    initCommand: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      editor: null,
      Layouts,
    }
  },
  methods: {
    updateMentions(json) {
      if (this.disabledPlugins.includes('mention')) {
        return
      }
      const mentionNodes = getMentionNodes(json)
      const userIds = mentionNodes
        .map(node => +node.attrs.id)
        .filter(id => id !== undefined)


      this.$emit('update:mentions', userIds)
    },
    updateTitle() {
      if (!this.editor || !this.$el?.querySelector) {
        return
      }

      const titleHeading = this.$el.querySelector('h1')
      const title = titleHeading?.innerText || ''

      if (title !== this.title) {
        this.$emit('update:title', title)
      }
    },
    initEditorExtensions() {
      let extensions = [
        TasksList,
        TaskItem.extend({
          draggable: true,
        }),
        Highlight.configure({
          multicolor: true,
        }),
        TrailingNode,
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            class: 'link-extension'
          },
        }),
        Icon,
        Underline,
        Typography.configure({
          oneHalf: false,
          oneQuarter: false,
          threeQuarters: false,
        }),
        Details.configure({
          persist: true,
          HTMLAttributes: {
            class: 'details',
          },
        }),
        DetailsSummary,
        DetailsContent,
        InsertHTML,
        Iframe,
        ResizableImage.configure({
          inline: true
        }),
        Columns,
        Column,
        Table,
        TableRow,
        TableCell,
        TableHeader,
        ExternalFile,
        AiAssistantExtension,
        Placeholder.configure({
          showOnlyWhenEditable: this.showPlaceholderOnlyWhenEditable,
          placeholder: ({ node }) => {
            if (node.type.name === 'heading') {
              return this.$t("What's the title?")
            } else if (node.type.name === 'paragraph') {
              return this.placeholder
            } else if (node.type.name === 'detailsSummary') {
              return this.$t('Summary...')
            }
            return this.placeholder
          },
        }),
        CodeBlockExtension,
        Emojis,
        Alert,
        Focus,
      ]
      if (this.slashMenu) {
        extensions.push(Commands(this.commandsIds))
      }

      if (!this.disabledPlugins.includes('mention')) {
        extensions.push(MentionExtension)
      }

      if (!this.disabledPlugins.includes('task')) {
        extensions.push(Task)
      }

      if (!this.disabledPlugins.includes('file-upload')) {
        extensions.push(UploadingFile)
      }

      if (!this.disabledPlugins.includes(entityTypes.Reference)) {
        extensions.push(Reference)
      }

      if (this.charLimit > 0) {
        extensions.push(CharacterCount.configure({ limit: this.charLimit, }))
      }
      if (this.enforceTitle) {
        const CustomDocument = Document.extend({
          content: 'heading (block|columns)*',
        })
        const CustomStarterKit = StarterKit.configure({
          document: false,
          hardBreak: false,
          codeBlock: false,
          dropcursor: false,
        })
        extensions.push(CustomStarterKit)
        extensions.push(CustomDocument)
      } else {
        extensions.push(Document)
        extensions.push(StarterKit.configure({
          document: false,
          hardBreak: false,
          codeBlock: false,
          dropcursor: false,
        }))
      }
      if (this.extraExtensions.length) {
        extensions = extensions.concat(this.extraExtensions)
      }

      extensions.push(HardBreak)
      extensions.push(DropCursor.configure({
        width: 2,
        class: 'ProseMirror-dropcursor border-black',
      }))

      return extensions
    },
    handleDropOrPaste(view, event, slice, moved) {
      if (this.readonly) {
        return false
      }

      const hasClipboardFiles = event?.clipboardData?.files?.length > 0
      const hasDraggedFiles = !moved && event?.dataTransfer?.files?.length > 0

      if (!hasClipboardFiles && !hasDraggedFiles) {
        return false
      }
      // We use promise style here because this function expects back a boolean to pass the event forward or not.
      uploadDroppedFiles({
        view,
        event,
        editor: this.editor,
        fullScale: this.layout === Layouts.Inline,
      })
        .catch(() => {
          this.$error(this.$t('An error occurred while uploading the files'))
        });

      return true;
    },
    getHTML() {
      return this?.editor.getHTML()
    },
    initEditor() {
      const extensions = this.initEditorExtensions()
      this.editor = new Editor({
        extensions,
        editorProps: {
          attributes: {
            class: `prose max-w-none leading-6 prose-p:my-4 prose-ul:my-4 prose-ol:my-4 prose-li:my-0 focus:outline-none ${this.contentClass}`,
          },
          handleDrop: this.handleDropOrPaste,
          handlePaste: this.handleDropOrPaste,
          projectId: this.projectId
        },
        autofocus: this.readonly ? false : this.autofocus,
        content: this.realTime ? undefined : this.modelValue,
        onUpdate: () => {
          const json = this.editor.getJSON()
          const html = this.editor.getHTML()
          this.$emit('update:modelValue', html)
          this.$emit('update:json', json)
          this.updateMentions(json)
          this.updateTitle()
        },
        onBlur: (event) => {
          this.$emit('blur', event)
        },
        onFocus: (event) => {
          this.$emit('focus', event)
        },
        onCreate: () => {
          const json = this.editor?.getJSON()
          this.$emit('update:json', json)
          this.updateMentions(json)
          this.updateTitle()
        }
      })
      this.$emit('editor-created', this.editor)
      this.setEditable(!this.readonly)
    },
    setEditable(value) {
      this.editor?.setEditable(value)
    },
    focus() {
      this.editor?.focus()
    },
    async removeFile(fileId) {
      const file = await this.$store.dispatch('files/getFileById', { fileId })
      if (!file) {
        return
      }
      await this.$store.dispatch('files/deleteFile', { file })
    },
    async cleanupResources() {
      if (this.readonly || this.cleanupFilesOnDestroy === false) {
        return
      }
      const json = this.editor.getJSON()
      const fileNodes = getFileNodes(json)
      const promises = fileNodes.map(node => this.removeFile(node.attrs.id))
      await Promise.all(promises)
    },
    triggerInitCommand() {
      const initCommands = {
        [EditorInitCommands.AiAssistant]: () => {
          this.editor
            ?.chain()
            .focus()
            .setAssistant({})
            .run()
        }
      }

      if (!initCommands[this.initCommand]) {
        console.error(`Unknown init command: '${this.initCommand}'`)
        return
      }

      initCommands[this.initCommand]()
    },
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame || this.realTime) {
        return
      }
      this.editor.commands.setContent(value, false)
    },
    readonly: {
      immediate: true,
      handler(value) {
        this.setEditable(!value)
      },
    },
    loading: {
      immediate: true,
      handler(value) {
        if (value || !this.initCommand || !this.editor) {
          return
        }

        this.triggerInitCommand()
      },
    },
    editor: {
      immediate: true,
      handler(value) {
        if (!value || this.loading || !this.initCommand) {
          return
        }

        this.triggerInitCommand()
      },
    },
  },
  mounted() {
    this.initEditor()
  },
  beforeUnmount() {
    this.cleanupResources()
    this.editor.destroy()
  },
}
</script>
