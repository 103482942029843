import { get } from 'lodash-es'
import { ElLoading } from 'element-plus'
import NotificationPlugin from '@/components/common/NotificationPlugin/index'
import ModalPlugin from '@/components/common/dialog/modalPlugin'
import DateFormatPlugin from './dateFormatPlugin'
import SettingsPlugin from './settingsPlugin.js'
import FormatPrice from './formatPrice'
import FormatPercent from './formatPercent'
import CopyToClipboard from './copyToClipboard.js'
import clickOutside from '@/directives/click-outside.js'
import focusDirective from '@/directives/focus.js'
import VeeValidate from "@/plugins/veeValidate";
import actionsEnum from '@/modules/common/enum/actionsEnum'
import { roleRanksEnum, rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual.js";
import useCan from "@/modules/common/composables/useCan";
import useMobileUtils from "@/modules/common/composables/useMobileUtils"

export default {
  install(Vue) {
    Vue.use(ModalPlugin)
    Vue.use(NotificationPlugin)
    Vue.use(DateFormatPlugin)
    Vue.use(SettingsPlugin)
    Vue.use(FormatPrice)
    Vue.use(FormatPercent)
    Vue.use(VeeValidate)
    Vue.use(CopyToClipboard)
    Vue.directive('click-outside', clickOutside)
    Vue.directive('focus', focusDirective)
    Vue.directive('loading', ElLoading.directive)

    const { can, canRole, isRoleGreaterOrEqual } = useCan()
    const { isMobileDevice } = useMobileUtils()

    Vue.mixin({
      computed: {
        $user() {
          return this.$store.state.auth?.user || {}
        },
        $userRole() {
          return this.$store.getters['users/currentRole']
        },
        $roles() {
          return rolesEnum
        },
        $roleRanks() {
          return roleRanksEnum
        },
        isSubscriptionValid() {
          return this.$store.getters['accounts/isValidSubscription']
        },
        $actions() {
          return actionsEnum
        },
        $isMobileDevice() {
          return isMobileDevice.value
        },
        $useNewLayout() {
          return this.$store.state.dashboard.useNewLayout
        }
      },
      methods: {
        get,
        can,
        canRole,
        isRoleGreaterOrEqual,
      },
    })
  },
}
