<template>
  <button
    type="button"
    class="result-item"
    :class="{
      'is-selected': index === selectedIndex,
      'opacity-60 cursor-not-allowed': itemRestriction,
    }"
    :disabled="itemRestriction"
  >
    <div
      :title="item.title"
      :class="{
        'w-12 h-12 rounded-lg': size === 'md',
        'w-6 h-6 min-w-[24px] max-h-[24px] rounded-md': size === 'sm',
      }"
      class="flex items-center justify-center text-gray-500 border border-gray-200"
    >
      <i
        :class="item.icon"
      />
    </div>
    <div class="flex flex-col items-start">
      <h5 class="text-sm">
        {{ item.title }}
        <i
          v-if="item.needsPlanUpgrade"
          class="fas fa-crown text-yellow-500 ml-2"
        />
      </h5>
      <p class="text-xs text-gray-500">
        {{ item.description }}
      </p>
      <p v-if="typeof itemRestriction === 'string'"
         class="text-xs text-gray-500 text-left"
      >
        <i v-if="!disabledTooltip" class="fas fa-star mr-1 text-yellow-500" aria-hidden="true"></i>
        {{ itemRestriction }}
      </p>
    </div>
  </button>
</template>
<script lang="ts" setup>
import { SuggestionItem } from "@/components/html/types/suggestionTypes";
import { computed, PropType } from "vue";

const props = defineProps({
    item: {
      type: Object as PropType<SuggestionItem>,
      required: true,
      default: () => ({})
    },
    index: Number,
    selectedIndex: Number,
    size: {
      type: String,
      default: 'md',
    }
  },
)

const disabledTooltip = computed(() => {
  if (typeof props.item?.disabledTooltip === 'function') {
    return props.item.disabledTooltip()
  }
  return false
})

const itemRestriction = computed(() => {
  if (disabledTooltip.value) {
    return disabledTooltip.value
  }
  if (typeof props.item?.needsRoleUpgrade === 'function') {
    return props.item.needsRoleUpgrade()
  }
  return props.item?.needsRoleUpgrade || false
})
</script>
<style scoped>
.result-item {
  @apply flex items-center hover:bg-gray-100 py-1 w-full space-x-2 px-2 rounded;
}

.el-dropdown-menu__item:focus .result-item,
.result-item.is-selected,
.result-item:focus {
  @apply bg-gray-100;
}

.el-dropdown-menu__item .result-item i {
  @apply mr-0;
}
</style>
