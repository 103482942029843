<template>
  <el-radio-group
    v-model="model"
    class="radio-group"
    :class="`radio-group-${type}`"
  >
    <template
      v-for="option of options"
      :key="option.value"
    >
      <el-radio
        v-if="type === RadioGroupType.Radio"
        :label="option.value"
        :disabled="option.disabled"
        class="radio"
      >
        <slot
          name="option"
          :option="option"
          :is-selected="option.value === modelValue"
        >
          <div v-html="option.label" />
        </slot>
      </el-radio>
      <el-radio-button
        v-else-if="type === RadioGroupType.RadioButton"
        :label="option.value"
        :disabled="option.disabled"
        class="radio-button"
      >
        <slot
          name="option"
          :option="option"
          :is-selected="option.value === modelValue"
        >
          <span v-html="option.label" />
        </slot>
      </el-radio-button>
      <slot
        name="option-after"
        :option="option"
        :is-selected="option.value === modelValue"
      />
    </template>
  </el-radio-group>
</template>
<script>
import { ElRadioGroup, ElRadio, ElRadioButton } from "element-plus";

const RadioGroupType = {
  Radio: "radio",
  RadioButton: "radio-button"
}

export default {
  name: 'base-radio-group',
  components: {
    ElRadioGroup,
    ElRadio,
    ElRadioButton
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: RadioGroupType.Radio  // radio | radio-button
    }
  },
  emits: ['update:modelValue', 'update:rawValue'],
  data() {
    return {
      RadioGroupType
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        
        const rawOption = this.options?.find(o => o.value === value)
        this.$emit('update:rawValue', rawOption)
      }
    },
  }
}
</script>
<style lang="scss">
.radio-group {
  @apply space-y-4;

  .el-radio-button {
    @apply w-full;
  }

  .el-radio-button:first-child .el-radio-button__inner,
  .el-radio-button__inner,
  .el-radio-button__original-radio:checked+.el-radio-button__inner {
    @apply w-full p-0 shadow-none border-l-0 border-0 bg-transparent text-left;
  }
}

</style>
