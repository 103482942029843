<template>
  <nav class="flex items-center" aria-label="Progress">
    <ol role="list" class="flex items-center space-x-5">
      <li
        v-for="(step, i) in visibleSteps"
        :key="step.key"
      >
        <div class="relative flex items-center justify-center" aria-current="step">
          <span
            v-if="i === modelValue"
            class="absolute flex h-5 w-5 p-px" aria-hidden="true"
          >
            <span class="h-full w-full rounded-full bg-[#5720b420]"></span>
          </span>
          <span
            class="relative block h-2.5 w-2.5 rounded-full"
            :class="{
              'bg-[#5720b4]': i <= modelValue,
              'bg-gray-200': i > modelValue
            }"
            aria-hidden="true"
          />
          <span class="sr-only">
            {{ $tc('step number', { number: i + 1 }) }}
          </span>
        </div>
      </li>
    </ol>
    <div class="text-xs text-gray-300 ml-4">
      <span v-if="prependText">
        {{ prependText }}
      </span>
      <span>
        {{ $tc('step x of y', { x: modelValue + 1, y: visibleSteps.length }) }}
      </span>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { PropType, computed } from 'vue';
import { StepperStep } from "@/modules/common/commonTypes";

const props = defineProps({
  modelValue: {
    type: Number,
    required: true
  },
  steps: {
    type: Array as PropType<StepperStep[]>,
    required: true
  },
  prependText: {
    type: String,
    default: ''
  }
})

const visibleSteps = computed(() => {
  return props.steps.filter(step => !step.hidden)
})
</script>
