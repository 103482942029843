<template>
  <router-view />
</template>
<script>
export default {
  watch: {
    '$route.params.id': {
      immediate: true,
      async handler(projectId) {
        const isProjectOrTemplatePath = this.$route.path.includes('/projects/') || this.$route.path.includes('/templates/')
        if (!projectId || !isProjectOrTemplatePath) {
          return
        }
        this.$store.commit('projects/setCurrentProjectId', projectId)
        await this.$store.dispatch('projects/getProjectById', { id: projectId })
      }
    },
  },
  unmounted() {
    this.$store.commit('projects/setCurrentProject', null)
  }
}
</script>
