<template>
  <BaseEntityForm
    class="custom-field-form"
    width-class="max-w-2xl"
    :isDialogForm="true"
    @submit="onSubmit"
  >
    <template #title>
      <BaseFormHeader
        :isDialogForm="true"
      >
        <template #title>
          <h3
            class="text-2xl font-bold"
            v-html="title"
          />
        </template>
        <template #subtitle>
          <p
            class="text-sm mt-2"
            :class="{
              'text-gray-500': !$useNewLayout,
            }"
          >
            <span v-html="subtitle" />
            <span
              v-if="!$route.path.includes('/custom-fields')"
              @click.stop="$emit('close')"
            >
              <span>&nbsp;{{ $t('or') }}&nbsp;</span>
              <router-link :to="customFieldsPath">
                <span
                  class="border-b border-dashed border-gray-300 hover:border-gray-500 cursor-pointer"
                  :class="{
                    'hover:text-gray-500': !$useNewLayout,
                  }"
                >
                  {{ customFieldsPathMessage }}
                </span>
              </router-link>
            </span>
            <BaseTutorialLink
              :name="projectId ? 'custom-fields-project': 'custom-fields-account'"
              class="block"
            />
          </p>
        </template>
      </BaseFormHeader>
    </template>
    <template #default="{ meta, errors }">
      <BaseInput
        v-if="!defaultAppliesTo && !projectId"
        :modelValue="model.entity_type"
        :name="$t('Applies to')"
        :label="$t('Applies to')"
        layout="horizontal"
        rules="required"
        id="appliesTo"
      >
        <EntityTypeSelect
          v-model="model.entity_type"
          v-focus="100"
        />
      </BaseInput>
      <BaseInput
        v-model="model.name"
        :label="$t('Name')"
        layout="horizontal"
        :placeholder="$t('Enter Custom Field name...')"
        :name="$t('Name')"
        rules="required"
        id="name"
      />

      <WarningAlert
        v-if="customFieldCollision"
        class="mt-2 flex justify-center"
        :dismissable="false"
      >
          <div v-if="isCollisionDisabled" class="text-center -ml-2">
            <div v-html="$tc('disabled custom field message', { entity_type: model.entity_type })" />
            <div class="mt-1 rounded-md">
              <button
                type="button"
                :class="{'bg-primary-500 text-white': handleCollision === 'restore'}"
                class="select-button rounded-l hover:bg-primary-500 hover:border-primary-500"
                @click="handleCollision = 'restore'"
              >
                {{ $t('Restore') }}
              </button>
              <button
                type="button"
                :class="{'bg-primary-500 text-white': handleCollision === 'overwrite'}"
                class="select-button rounded-r hover:bg-primary-500 hover:border-primary-500"
                @click="handleCollision = 'overwrite'"
              >
                {{ $t('Overwrite') }}
              </button>
            </div>
          </div>
          <div v-else>
            <div v-html="$t('duplicate active custom field message', { entity_type: model.entity_type })" />
          </div>
        </WarningAlert>

      <BaseInput
        v-show="!shouldRestoreCustomField"
        :modelValue="model.custom_field_type_id"
        rules="required"
        id="custom-field-type"
        :label="$t('Type')"
        layout="horizontal"
      >
        <CustomFieldTypeSelect
          v-model="model.custom_field_type_id"
          :initial-value="initialType"
          @raw-change="selectedFieldTypeName = $event?.attributes?.name"
        />
      </BaseInput>

      <WarningAlert
        v-if="model.custom_field_type_id && isKanbanFieldCreation && !isKanbanFieldType"
        :dismissable="false"
        :message="$t('Heads up: Only Select & Select-Color types can be used as kanban column headers.')"
        class="border border-yellow-500"
      />

      <BaseInput
        v-if="isSelectFieldType"
        v-show="!shouldRestoreCustomField"
        layout="horizontal"
        :label="selectedFieldTypeName === 'currency' ? $t('Available currencies') : $t('Available options')"
      >
      <draggable
        v-model="model.options"
        :animation="200"
        class="space-y-2 relative"
        ghost-class="ghost-card"
        handle=".fa-grip-dots"
        item-key="id"
        tag="div"
      >
        <template #item="{ element, index }">
          <div
            class="flex mt-1 relative w-full"
            :key="element.id"
          >
            <span class="hidden sm:inline my-auto -ml-6 mr-2 shrink-0 text-lg font-medium text-gray-300 hover:text-gray-500">
              <i class="fa-solid fa-grip-dots cursor-move" />
            </span>
            <template v-if="selectedFieldTypeName === 'currency'">
              <BaseInput
                :modelValue="model.options[index]"
                :id="`custom-field-option_curr_${element.id}`"
                :name="`custom-field-option_curr_${element.id}`"
                class="w-full"
                rules="required"
              >
                <CurrencySelect
                  v-model="model.options[index]"
                />
              </BaseInput>
            </template>
            <template v-else-if="selectedFieldTypeName === 'select-color'">
              <div class="flex w-full">
                <input
                  v-model="element.label"
                  class="form-input h-12 w-full"
                >
                <BaseInput
                  class="ml-3 h-full m-auto"
                  :id="`custom-field-option_value_${element.id}`"
                  :name="`custom-field-option_value_${element.id}`"
                >
                  <ColorPicker
                    v-model="element.value"
                    :key="`cp_${element.order}`"
                    :append-to-body="false"
                  />
                </BaseInput>
              </div>
            </template>
            <template v-else>
              <BaseInput
                v-model="element.label"
                v-focus
                rules="required"
                class="w-full"
                :id="`Custom Field Option ${element.id}`"
                :name="`Custom Field Option ${element.id}`"
                @update:modelValue="optionLabelChanged(index, $event)"
              />
            </template>
            <XCircleIcon
              v-show="model.options.length > 1"
              class="absolute top-4 -right-6 h-5 w-5 text-xl text-red-200 cursor-pointer hover:text-red-400"
              @click="removeOption(index)"
            />
          </div>
        </template>
      </draggable>
        <BaseButton
          @click="addNewOption"
          type="button"
          variant="white"
          class="mt-1"
        >
          {{ $t("Add Another") }}
        </BaseButton>

        <WarningAlert
          v-if="!hasUniqueColors"
          :dismissable="false"
          class="border border-yellow-500 mt-2"
        >
          {{ $t('Please make sure colors are unique between the options.') }}
        </WarningAlert>
      </BaseInput>
      <BaseRoundedCheckbox
        v-if="!shouldRestoreCustomField"
        v-model="model.required"
        :name="$t('Required')"
        :label="$t('Required')"
        size="sm"
        inputClass="sm:col-span-3"
      />
      <BaseRoundedCheckbox
        v-show="!shouldRestoreCustomField"
        v-model="model.allow_filter_by"
        :name="$t('Allow Filter By')"
        :label="$t('Allow Filter By')"
        size="sm"
        inputClass="sm:col-span-3"
        class="pt-2"
      />
      <BaseRoundedCheckbox
        v-show="!shouldRestoreCustomField"
        v-model="model.allow_group_by"
        :name="$t('Allow Group By')"
        :label="$t('Allow Group By')"
        size="sm"
        inputClass="sm:col-span-3"
        class="pt-2"
      />
      <BaseRoundedCheckbox
        v-show="!shouldRestoreCustomField"
        v-model="model.allow_sort_by"
        :name="$t('Allow Sort By')"
        :label="$t('Allow Sort By')"
        size="sm"
        inputClass="sm:col-span-3"
        class="pt-2"
      />
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$emit('cancel')"
      >
        {{ $t("Cancel") }}
      </BaseButton>
      <BaseButton
        :loading="loading"
        :disabled="isSaveDisabled(meta)"
        class="ml-2"
        type="submit"
      >
        {{ saveText }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
// Helpers
import { XCircleIcon } from "@heroicons/vue/outline";
import { capitalize, cloneDeep } from "lodash-es";
import { customFieldAppliesToOptions } from "@/modules/accounts/utils/modelUtils"

// Components
import CustomFieldTypeSelect from "@/components/selects/CustomFieldTypeSelect.vue";
import ColorPicker from "@/components/form/ColorPicker.vue";
import EntityTypeSelect from "@/modules/accounts/components/EntityTypeSelect.vue"
import CurrencySelect from "@/components/selects/CurrencySelect.vue";
import WarningAlert from "@/components/common/WarningAlert.vue";
import draggable from "vuedraggable/src/vuedraggable"

export default {
  inheritAttrs: false,
  components: {
    CustomFieldTypeSelect,
    ColorPicker,
    XCircleIcon,
    EntityTypeSelect,
    CurrencySelect,
    WarningAlert,
    draggable
  },
  props: {
    customField: {
      type: Object,
      default: () => null,
    },
    defaultAppliesTo: {
      type: String,
      default: ''
    },
    project_id: {
      type: [String, Number],
      default: null
    },
    isKanbanFieldCreation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      model: {
        name: "",
        key: "",
        custom_field_type_id: null,
        entity_type: this.defaultAppliesTo,
        required: false,
        allow_filter_by: true,
        allow_group_by: true,
        allow_sort_by: true,
        options: [],
        project_id: this.project_id || this.$store.getters.project_id
      },
      selectedFieldTypeName: null,
      handleCollision: 'restore'
    };
  },
  computed: {
    hasUniqueColors() {
      if (this.selectedFieldTypeName !== 'select-color') {
        return true
      }

      const colors = {}
      for (const option of this.model.options) {
        if (colors[option.value]) {
          return false
        }

        colors[option.value] = true
      }
      return true
    },
    projectId() {
      return this.model.project_id
    },
    isEditAction() {
      return !!this.customField?.id;
    },
    scope() {
      if (!this.projectId) {
        return this.$t('Account Level')
      }

      if (this.isTemplatePath) {
        return this.$t('Template Level')
      }

      return this.$t('Project Level')

    },
    title() {
      if (this.isEditAction) {
        return this.$t('edit scoped custom field', { scope: this.scope })
      }
      return this.$t('create scoped custom field', { scope: this.scope })
    },
    subtitle() {
      if (this.isEditAction) {
        return ''
      }

      let appliesTo = '';

      switch(this.model.entity_type) {
        case customFieldAppliesToOptions.PROJECT:
          appliesTo = this.$t('to all projects')
          break;
        case customFieldAppliesToOptions.TASK:
          appliesTo = this.$t('to all tasks')
          break;
        case customFieldAppliesToOptions.GROUP:
          appliesTo = this.$t('to all groups')
          break;
        case customFieldAppliesToOptions.USER:
          appliesTo = this.$t('to all users')
          break;
        case customFieldAppliesToOptions.TIME_ENTRY:
          appliesTo = this.$t('to all time entries')
          break;
        case customFieldAppliesToOptions.PAYMENT:
          appliesTo = this.$t('to all payments')
          break;
        case customFieldAppliesToOptions.FILE:
          appliesTo = this.$t('to all files')
          break;
      }

      if (!this.projectId) {
        return this.$t('account level custom field dialog subtitle', { appliesTo })
      }

      if (this.isTemplatePath) {
        return this.$t('template level custom field dialog subtitle', { appliesTo })
      }

      return this.$t('project level custom field dialog subtitle', { appliesTo })
    },
    saveText() {
      if (this.isEditAction) {
        return this.$t("Save");
      }

      if (!this.customFieldCollision || !this.isCollisionDisabled) {
        return this.$t("Create Custom Field");
      }

      if (this.handleCollision === 'restore') {
        return this.$t("Restore Custom Field");
      }

      return this.$t("Overwrite Custom Field");
    },
    isSelectFieldType() {
      const selectFieldTypes = ["select", "multiselect", "select-color", "currency"];
      return selectFieldTypes.includes(this.selectedFieldTypeName);
    },
    isKanbanFieldType() {
      const kanbanFieldTypes = ["select", "select-color"];
      return kanbanFieldTypes.includes(this.selectedFieldTypeName);
    },
    initialType() {
      if (!this.customField) {
        return null
      }

      if (this.customField.relationships?.custom_field_type) {
        return this.customField.relationships?.custom_field_type
      }

      return {
        id: this.customField?.attributes?.custom_field_type_id?.toString(),
        attributes: {
          name: this.customField?.attributes?.custom_field_type
        }
      }
    },
    currentScopeCustomFields() {
      return this.$store.getters['accounts/orderedCustomFields'](/* filterDisabled */ false) || []
    },
    customFieldCollision() {
      if (this.isEditAction || !this.model.entity_type || !this.model.name) {
        return
      }
      return this.currentScopeCustomFields.find(this.findDuplicateCustomField)
    },
    hasCollision() {
      return !!this.customFieldCollision
    },
    isCollisionDisabled() {
      return this.customFieldCollision?.attributes?.is_disabled
    },
    shouldRestoreCustomField() {
      return this.hasCollision && this.handleCollision === 'restore'
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    customFieldsPath() {
      if (!this.projectId) {
        return '/account-settings/custom-fields'
      }

      const { params } = this.$route
      const projectId = params.id
      const basePath = this.isTemplatePath ? `/templates/${projectId}` : `/projects/${projectId}`

      return `${basePath}/custom-fields`
    },
    customFieldsPathMessage() {
      if (this.projectId) {
        return this.$t('click here to manage existing custom fields for this project')
      }

      if (this.isTemplatePath) {
        return this.$t('click here to manage existing custom fields for this template')
      }

      return this.$t('click here to manage all account level custom fields.')
    }
  },
  methods: {
    findDuplicateCustomField(value) {
      if (value.id === this.customField?.id) {
        return false
      }

      if (value.attributes.name !== this.model.name) {
        return false
      }

      if (value.attributes.entity_type !== this.model.entity_type) {
        return false
      }

      return true
    },
    isSaveDisabled(meta) {
      if (this.customFieldCollision && this.isCollisionDisabled && this.handleCollision === 'restore') {
        return false
      }

      return !meta.valid || !this.hasUniqueColors
    },
    async handleRestore(emitSave) {
      const customField = await this.$store.dispatch("accounts/toggleCustomFieldDisabled", {
        customField: this.customFieldCollision,
        disable: false
      })

      if (emitSave) {
        this.$emit("save", customField);
      }
    },
    async onSubmit(emitSave = true) {

      if (this.hasCollision && this.isCollisionDisabled) {
        if (this.handleCollision === 'restore') {
          await this.handleRestore(emitSave)
          return
        }

        await this.$store.dispatch('accounts/deleteCustomField', { customField: this.customFieldCollision })
      }
      try {
        const data = cloneDeep(this.model);
        this.loading = true;

        let customField, message

        if (this.isEditAction) {
          message = this.$t("Custom Field updated successfully")
          customField = await this.editCustomField(data)
        }
        else {
          message = this.$t("Custom Field created successfully")
          customField = await this.createCustomField(data)
        }

        this.$success(message);

        if (emitSave) {
          this.$emit("save", customField);
        }

      } catch (err) {
        if (err.handled) {
          return;
        }

        if (err.response?.data?.data?.message === 'Duplicate custom field') {
          const appliesTo = this.model.entity_type
          this.$error(this.$t('duplicate custom field', { entity_type: capitalize(appliesTo) }))
          return
        }

        this.$error(this.$t("Could not save the Custom Field"));
      } finally {
        this.loading = false;
      }
    },
    async createCustomField(data) {
      return await this.$store.dispatch("accounts/createCustomField", {
        data,
      });
    },
    async editCustomField(data) {
      return await this.$store.dispatch("accounts/editCustomField", {
        id: this.customField.id,
        data,
      });
    },
    addNewOption() {
      const option = {
        is_new: true,
        order: this.model.options.length + 1,
        id: this.model.options.length + 1,
        value: "",
        label: "",
      };
      if (this.selectedFieldTypeName === "select-color") {
        option.value = "#6B7280";
      }
      this.model.options.push(option);
    },
    optionLabelChanged(i, labelValue) {
      if (!this.model.options[i].is_new || this.selectedFieldTypeName === "select-color") {
        return;
      }
      this.model.options[i].value = labelValue;
    },
    removeOption(i) {
      this.model.options.splice(i, 1);
    }
  },
  watch: {
    isSelectFieldType: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.model.options = [];
          return;
        }

        if (this.model.options.length) {
          return;
        }

        this.addNewOption();
      },
    },
    customField: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        let options = value.attributes?.options || []
        if (!Array.isArray(options) && typeof options === 'object') {
          options = []
          for(let key in value.attributes?.options) {
            options.push({
              value: key,
              label: value.attributes?.options[key],
            })
          }
        };
        const required = value.attributes?.rules?.includes("required") || false;
        this.model = {
          ...this.model,
          ...value.attributes,
          options: options.map((option, i) => ({
            ...option,
            order: option.order || (i + 1),
            id: i + 1
          })),
          required,
        };

        this.selectedFieldTypeName = value.attributes?.custom_field_type;
      },
    },
    projectId: {
      immediate: true,
      handler(value) {
        if (!value) {
          if (this.defaultAppliesTo) {
            this.model.entity_type = this.defaultAppliesTo
          }

          return
        }

        this.model.entity_type = customFieldAppliesToOptions.TASK
      }
    }
  },
};
</script>
<style lang="scss">
.custom-field-form {

  .el-color-picker, .el-color-picker__trigger {
    width: 46px;
    height: 46px;
  }
}
</style>
