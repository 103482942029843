<template>
  <div
    v-loading="loading"
    class="w-full h-full bg-white"
    :class="{
      'rounded-md shadow': !$useNewLayout
    }"
  >
    <NoEmbedData v-if="!src && !html_string && !loading" class="h-full flex items-center">
      {{ $t('Could not load the embed data') }}
    </NoEmbedData>
    <iframe
      v-else-if="html_string"
      :key="embedId"
      class="m-auto bg-white"
      :class="{
        'rounded-md shadow': !$useNewLayout
      }"
      width="100%"
      height="100%"
      :srcdoc="`${html_string}${custom_styles}`"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      @load="iframeLoading = false"
    />
    <iframe
      v-else
      :key="src"
      class="m-auto"
      :class="{
        'rounded-md shadow': !$useNewLayout
      }"
      width="100%"
      height="100%"
      :src="src"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      @load="iframeLoading = false"
    />
  </div>
</template>
<script>
import NoEmbedData from "@/modules/resources/components/NoEmbedData.vue";

export default {
  components: {
    NoEmbedData
  },
  data() {
    return {
      iframeLoading: true,
      custom_styles: `<style>
        html, body {
          margin: 0;
          height: calc(100% - 2px);
        }

        iframe {
          width: 100%;
          height: 100%;
        }
      </style>`
    }
  },
  computed: {
    embedId() {
      return this.$route.params.embedId;
    },
    currentEmbed() {
      return this.$store.state.resources.currentEmbed || {};
    },
    html_string() {
      return this.currentEmbed?.attributes?.html_string;
    },
    src() {
      return this.currentEmbed?.attributes?.src;
    },
    loading() {
      return (!this.src && !this.html_string) || this.iframeLoading;
    },
  },
  watch: {
    html_string: {
      immediate: true,
      handler() {
        this.iframeLoading = false
      }
    },
    embedId: {
      immediate: true,
      handler() {
        if (!this.embedId) {
          return
        }

        this.tryGetEmbed()
      }
    }
  },
  methods: {
    async tryGetEmbed() {
      try {
        this.loading = true;
        await this.$store.dispatch('resources/getEmbedById', { id: this.embedId })
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
