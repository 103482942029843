<template>
  <div
    v-if="!abortInit"
    class="h-full flex-col-grow"
    :class="$route.name?.replaceAll?.(' ', '_')"
  >
    <DashboardHeader />

    <div
      class="dashboard-content"
      :class="{
        'has-breadcrumbs': hasBreadcrumbs,
        [$route.name?.replaceAll?.(' ', '_')]: true
      }"
    >
      <SubscriptionInvalidMessage
        v-if="!isSubscriptionValid && settingsLoaded"
        class="my-4 border border-red-600 w-full"
      />

      <ArchiveDownloadMessage />

      <SimulatedRoleMessage />

      <FinishedImportMessage />

      <div
        class="router-view-content"
        :class="{
          'is-list-view': isListOrEmbedView
        }"
      >
        <router-view />
      </div>
    </div>
    <DashboardFooter />
  </div>
</template>
<script>
// Components
import DashboardFooter from "@/modules/dashboard/components/DashboardFooter.vue";
import DashboardHeader from "@/modules/dashboard/components/DashboardHeader.vue";
import SubscriptionInvalidMessage from "@/modules/accounts/components/SubscriptionInvalidMessage.vue";
import ArchiveDownloadMessage from "@/modules/accounts/components/ArchiveDownloadMessage.vue";
import SimulatedRoleMessage from "@/modules/accounts/components/SimulatedRoleMessage.vue";
import FinishedImportMessage from "@/modules/accounts/components/FinishedImportMessage.vue";

export default {
  components: {
    DashboardHeader,
    DashboardFooter,
    SubscriptionInvalidMessage,
    ArchiveDownloadMessage,
    SimulatedRoleMessage,
    FinishedImportMessage,
  },
  props: {
    settingsLoaded: {
      type: Boolean,
      default: false
    },
    abortInit: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    hasBreadcrumbs() {
      return this.$route.path !== '/welcome'
    },
    organizations() {
      return this.$store.state.auth.organizations || []
    },
    isListOrEmbedView() {
      return this.$route.path.includes('/list') ||
        this.$route.path.includes('/time/actual') ||
        this.$route.path.includes('/time/allocated') ||
        this.$route.path.includes('/time/all') ||
        this.$route.path.includes('/custom-fields') ||
        ['Project Embed', 'Template Embed', 'Proof Embed'].includes(this.$route.name)
    }
  },
}
</script>
<style lang="scss">
.dashboard-content {
  min-height: 100vh;

  @apply px-4 sm:px-6 pt-20 pb-10 print:p-0;

  .router-view-content {
    &:not(.is-list-view) {
      @apply pb-20 md:pb-0;
    }

    &.is-list-view {
      @apply h-full;
    }
  }

  @media (min-width: 640px) {
    padding-top: 5.5rem; // sm:pt-22
    padding-bottom: 1.5rem;
  }

  &.has-breadcrumbs {
    @apply pb-20 pt-20 print:pt-10 print:pb-0;

    @media (min-width: 640px) {
      @apply pb-6 print:pb-0;
    }

    @media (min-width: 768px) {
      padding-top: 6rem; // md:pt-30
      @apply print:pb-0;
    }
  }

  &.All_Tasks_Kanban, &.Tasks_Kanban, &.Projects_Kanban {
    @apply pb-0;
  }

  &.Discussion {
    padding-bottom: 0;
    @apply pt-16 md:pt-32;
  }
}
</style>
