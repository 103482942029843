<template>
  <BaseSelect
    v-model="model"
    :options="embedTypes"
    :placeholder="$t('Select Embed Type...')"
    :return-object="returnObject"
  >
    <template #prefix="{ selected }">
      <div class="flex items-center">
        <template v-if="selected">
          <img
            v-if="selected.image"
            :src="selected.image"
            class="mr-2 w-6 h-6"
          />
          <i
            v-else-if="selected.icon"
            :class="selected.icon" 
            class="mr-2 w-6"
          />
          <div class="text-gray-700">
            {{ selected.label }}
          </div>
        </template>
      </div>
    </template>
    <template #default="{ option }">
      <div class="flex items-center">
        <img
          v-if="option.image"
          :src="option.image"
          class="mr-2 w-6 h-6"
        />
        <i
          v-else-if="option.icon"
          :class="option.icon" 
          class="mr-2 w-6"
        />
        <div>
          {{ option.label }}
        </div>
      </div>
    </template>
  </BaseSelect>
</template>
<script>
import { embedTypes } from "@/modules/resources/utils/embedUtils"

export default {
  name: "EmbedTypeSelect",
  props: {
    modelValue: {
      type: [Object, String],
      required: true,
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      embedTypes
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
};
</script>
<style>
</style>
