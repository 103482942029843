<template>
   <BaseEntityForm
    :title="isEdit ? $t(`Edit Folder`) : $t(`Create New Folder`)"
    width-class="max-w-2xl"
    @submit="onSubmit"
  > 
    <template #default="{ meta, errors}">
      <BaseInput
        v-if="!$store.getters.project_id"
        :label="$t('Project')"
        :name="$t('Project')"
        layout="horizontal"
        id="project"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <ProjectSelect
            v-model="model.project_id"
            v-focus="!model.project_id"
            :initialValue="folder?.relationships?.project"
            :placeholder="$t('Choose a project for this folder')"
            rules="required"
            :allow-entity-create="can($actions.CREATE_PROJECTS)"
            :add-entity-label="$t('Quick Create Project')"
            @change="model.parent_folder_id = null"
          />
        </div>
      </BaseInput>

      <BaseInput
        v-model="model.name"
        v-focus
        :label="$t('Name')"
        :placeholder="$t('Enter folder name...')"
        :name="$t('Name')"
        layout="horizontal"
        rules="required"
        id="name"
      />
      <BaseInput
        v-if="model.project_id && projectHasFolders"
        :label="$t('Parent Folder')"
        :name="$t('Parent Folder')"
        layout="horizontal"
        id="parent-folder"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <FolderSelect
            v-model="model.parent_folder_id"
            :projectId="model.project_id"
            :initialValue="folder?.relationships?.parentFolder"
            :isParentSelect="true"
            :child-folder-id="folder?.id"
            :placeholder="$t('Choose parent folder (optional)...')"
          />
        </div>
      </BaseInput>
    </template>
    <template #actions="{ meta, errors}">
      <BaseButton
        variant="white"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        :loading="loading"
        :disabled="!meta.valid"
        type="submit"
        class="ml-2"
      >
        {{ isEdit ? $t('Save'): $t('Create') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
// Libs
import { cloneDeep } from 'lodash-es'

// Components
import ProjectSelect from '@/components/selects/ProjectSelect.vue'
import FolderSelect from '@/components/selects/FolderSelect.vue'

export default {
  name: 'FolderForm',
  components: {
    ProjectSelect,
    FolderSelect,
  },
  props: {
    folder: {
      type: Object,
      default: () => null
    },
  },
  emits: ['save', 'cancel'],
  data() {
    return {
      loading: false,
      model: {
        name: '',
        project_id: this.$store.getters.project_id || null,
        parent_folder_id: null,
      },
      projectSyncing: false,
      parentSyncing: false,
    }
  },
  computed: {
    isEdit() {
      return !!this.folder?.id
    },
    projectHasFolders() {
      return this.$store.getters['files/foldersTreeData'](this.model.project_id)?.length > 0
    },
  },
  methods: {
    async onSubmit(emitSave = true) {
      try {
        this.loading = true

        const data = cloneDeep(this.model)
        let folder

        if (this.isEdit) {
          folder = await this.$store.dispatch('files/editFolder', {
            folderId: this.folder.id,
            data
          })
        }
        else {
          folder = await this.$store.dispatch('files/createFolder', {
            data
          })
        }

        const message = this.isEdit
          ? this.$t('Folder updated successfully')
          : this.$t('Folder created successfully')

        this.$success(message)

        if (emitSave) {
          this.$emit('save', this.model)
        }
      } catch (err) {
        if (err.handled) {
          return false
        }
        
        const errorMessage = this.isEdit
          ? this.$t('Could not update folder')
          : this.$t('Could not create folder')

        this.$error(errorMessage)
        return false
      } finally {
        this.loading = false
      }
      return true
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
  
  watch: {
    folder: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.model = {
          ...this.model,
          ...value?.attributes,
        }
      }
    }
  }
}
</script>
