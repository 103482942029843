<template>
  <div class="relative bg-white p-4 border border-gray-200 rounded-lg group shadow space-y-2 overflow-x-hidden">
    <div class="bg-gray-100 w-2/3 h-5 mt-1 mb-2">
    </div>
    <div class="text-sm text-gray-500">
      <div class="flex h-4 w-12">
        <span
          class="inline-flex whitespace-nowrap bg-gray-100 items-center px-2.5 py-0.5 rounded-md w-12 text-xs font-medium text-white capitalize badge my-0">
      </span>
      </div>
    </div>
    <div class="text-sm bg-gray-100 w-1/2 h-4"></div>
    <div class="text-sm bg-gray-100 w-1/2 h-4"></div>
    <div class="text-sm bg-gray-100 w-6 h-4 rounded"></div>
  </div>
</template>
<script>
export default {}
</script>
