<template>
  <BaseDialogNew
    v-model="visible"
    class="w-full max-w-6xl"
    @close="onCloseDialog"
  >
    <TimeEntryForm
      :title="title"
      :subtitle="subtitle"
      :time-entry="timeEntry"
      :isDialogForm="true"
      @cancel="$emit('close')"
      @save="$emit('save')"
    />
  </BaseDialogNew>
</template>
<script>
import TimeEntryForm from '@/modules/time/components/TimeEntryForm.vue'
import i18n from "@/i18n";

export default {
  name: 'TimeEntryDialog',
  inheritAttrs: false,
  components: {
    TimeEntryForm,
  },
  emits: ['save', 'close'],
  props: {
    timeEntry: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title() {
      if (this.timeEntry?.id) {
        return i18n.t('Edit Time Entry')
      }
      return i18n.t('Create New Time Entry')
    },
    subtitle() {
      if (this.timeEntry?.id) {
        return ''
      }
      return i18n.t('Create a new time entry to record the time people spend on projects.')
    },
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    onCloseDialog() {
      this.$emit('save')
      // * Reload last user time entries recorded
      this.$store.dispatch('time/getRecordedTimeEntriesSummary')
      this.$emit('close')
    },
  },
}
</script>
