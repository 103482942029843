<template>
  <EntitySelect
      ref="select"
      url="/get-stripe-plans"
      labelKey="product_name"
      valueKey="id"
      :placeholder="$attrs.placeholder|| $t('Select a stripe product...')"
      :createInfoText="$t('Choose a stripe product to associate with this payment. If you don\'t have a product yet, you can create one in your Stripe dashboard.')"
      :map-data="mapData"
      :label-format="formattedLabel"
      :fetch-on-mount="true"
      v-bind="$attrs"
  >
    <template #empty>
      <div class="p-2 flex flex-col text-center text-sm">
        <span>{{ $t('No stripe products found.') }}</span>
        <a
          href="https://dashboard.stripe.com/products/create"
          target="_blank"
          rel="noopener noreferrer"
          class="text-primary underline">
          {{ $t('Click here to create a new stripe product.') }}
        </a>
      </div>
    </template>
    <template #option="{ row }">
      <div class="flex items-center py-1">
        <span class="font-semibold">
          {{ getPrice(row.originalValue) }}
        </span>
        <span class="ml-1">
          {{ $t('every') }} {{ row.originalValue?.interval }}
        </span>
        <span v-if="row.originalValue?.product_name"
              class="text-sm ml-1">
          - {{ row.originalValue?.product_name }}
        </span>
      </div>
    </template>
  </EntitySelect>
</template>
<script>
import EntitySelect from "@/components/form/EntitySelect.vue";
import { orderBy } from "lodash-es";

export default {
  components: {
    EntitySelect,
  },
  data() {
    return {
      stripeProducts: []
    }
  },
  methods: {
    getPrice(option) {
      return `${option.amount/100} ${option.currency?.toUpperCase()}`;
    },
    mapData(data) {
      if (!Array.isArray(data)) {
        data = Object.values(data)
      }
      data = data.filter(d => d.active)
      this.stripeProducts = data
      return orderBy(data, 'amount', ['desc'])
    },
    formattedLabel(option) {
      const name = option?.product_name
      return `${this.getPrice(option)} ${this.$t('every')} ${option?.interval} - ${name}`
    },
    async refresh() {
      await this.$refs?.select?.forceFetch()
    }
  }
}
</script>
