<template>
  <BaseNoDataSection
    :title="$t('No Tasks Found')"
    iconClass="fa-badge-check"
    :canCreate="!isCurrentProjectClosed && can($actions.CREATE_TASKS)"
  >
    <template #can-create-section>
      <div>{{ $t('Tasks help you keep track of everything that needs to be done.')}}</div>
      <div
        class="hover:text-primary-500 cursor-pointer"
        @click="triggerAddTask(false)"
      >
        {{ $t('Create a new task') }}
        <i class="fa-solid fa-arrow-right" aria-hidden="true" />
      </div>
      <div
        class="hover:text-primary-500 cursor-pointer"
        @click="triggerAddTask(true)"
      >
        {{ $t('Add multiple tasks at once') }}
        <i class="fa-solid fa-arrow-right" aria-hidden="true" />
      </div>
      <div>
        <BaseEntityImportLink
          :entityType="importEntityTypes.Tasks"
        />
      </div>
    </template>
  </BaseNoDataSection>
</template>
<script>
import {
  importEntityTypes,
} from "@/modules/accounts/utils/importUtils"
export default {
  data() {
    return {
      importEntityTypes,
    }
  },
  computed: {
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    triggerAddTask(isBulkCreate = false) {
      this.$store.commit('setEntityCreateParams', {
        isBulkCreate,
      })

      this.$store.commit('setEntityDialogClosedCallback', () => {
        this.$store.commit('tasks/triggerCreateDialogClosed')
      })

      this.$store.commit('setEntityCreatedCallback', () => {
        this.$store.commit('tasks/triggerGlobalTaskCreated')
      })

      this.$store.commit('tasks/triggerAddTask')
    },
  }
}
</script>
