import { get } from 'lodash-es'

export function getFormData(json, properties, allowEmpty = false) {
  const formData = new FormData()
  properties.forEach(property => {
    const value = get(json, property)

    if (!value && !allowEmpty) {
      return
    }

    formData.append(property, value)
  })
  return formData
}
