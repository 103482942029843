<template>
  <div
    class="overflow-hidden bg-gray-200"
    :class="{
      'rounded-full': rounded
    }"
  >
    <div
      class="h-2"
      style="background: -webkit-linear-gradient(60deg, #8B48F9, #E827A1, #FFA102);"
      :class="{
        'rounded-full': rounded
      }"
      :style="{
        width: `${percentageCompleted}%`
      }"
    />
  </div>
</template>
<script lang="ts" setup>
import {
  useGettingStarted
} from '@/modules/accounts/composables/useGettingStarted';

const props = defineProps({
  rounded: {
    type: Boolean,
    default: false
  }
});

const {
  percentageCompleted,
} = useGettingStarted()
</script>
