<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-3xl sm:text-5xl font-extrabold">
      {{ i18n.t(`Congratulations!`) }}
    </h2>
    <p class="text-gray-500 leading-7 text-lg">
      {{ i18n.t(`You've created your first project and added the tools you need to get the job done.`)}}
    </p>
    <p class="text-gray-500 leading-7 text-lg">
      {{ i18n.t(`There's so much more you can do so click the button below to go to your first project and start exploring!`)}}
    </p>
  </div>
  <div class="flex space-x-2 items-center mt-4">
    <router-link
      :to="`/projects/${projectId}?isOnboardingProject=true`"
      class="rounded-md body-background px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-white"
    >
      {{ i18n.t('Go to your project') }}
      <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
    </router-link>
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import { computed, ref } from 'vue';
import { useStore } from 'vuex'

const store = useStore()

const onboardingModel = computed(() => {
  return store.state.accounts.onboardingModel
})

const projectId = ref(onboardingModel.value.projectId)
</script>
