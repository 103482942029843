<template>
  <div
    :class="{'p-6': isFullPreview}"
    class="bg-white rounded-lg"
  >
    <div class="grid flex space-y-2">

      <div class="shrink-0 flex justify-center">
        <BaseLogo
          :entity="user"
          :size="isFullPreview ? 'xl' : 'lg'"
          class="rounded-md"
          logo-key="attributes.avatar"
        />
      </div>

      <div>
        <p
          :class="{
            'text-xl sm:text-2xl': isFullPreview,
            'text-lg': isFullPreview,
          }"
          class="text-center font-bold text-gray-900"
        >
          {{ user?.attributes?.name || user?.attributes?.email}}
          <UserRoleBadge 
            :user="user"
            size="xl"
            class="ml-2"
          />
        </p>
      </div>

      <div
        v-if="showGroups && hasGroups"
        class="flex flex-col justify-center space-y-2 pt-1"
      >
        <router-link
          v-for="group in user?.relationships?.groups"
          :key="group.id"
          :to="`/groups/${group.id}/view`"
          class="inline-flex items-center p-1 pr-2 rounded-md border border-gray-200 text-xs font-medium bg-white text-gray-500 capitalize">

          <BaseLogo
            :entity="group"
            size="sm"
            class="rounded mr-2"
          />
          {{ group?.attributes?.name }}
        </router-link>
      </div>

      <slot />

    </div>
  </div>
</template>
<script>
// Components
import UserRoleBadge from "@/components/common/UserRoleBadge.vue";

// Utils
import { UserPreviewTypes } from "@/modules/users/util/userUtils";

export default {
  components: {
    UserRoleBadge
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({})
    },
    type: {
      type: String,
      default: UserPreviewTypes.Full
    },
    showGroups: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isFullPreview() {
      return this.type === UserPreviewTypes.Full
    },
    groups() {
      return this.user?.relationships?.groups || []
    },
    hasGroups() {
      return this.groups.length > 0
    }
  }
}
</script>
