import i18n from "@/i18n";
import embedTypesJson from "@/modules/resources/utils/embedTypes.json";
import embedCodeTypesJson from "@/modules/resources/utils/embedCodeTypes.json";
import projectLinkTypesJson from "@/modules/resources/utils/linkTypes.json";
import useCan, { AccountPermissions } from "@/modules/common/composables/useCan";

export const toolsEnum = {
  FILES: 'Files',
  FILE_PROOFING: 'File Proofing',
  TIME: 'Time',
  TASKS: 'Tasks',
  PAYMENTS: 'Payments',
  DISCUSSIONS: 'Discussion',
  PEOPLE: 'People',
  NOTE: 'Note',
  NOTES: 'Notes',
  SINGLE_EMBED: 'Single Embed',
  EMBEDS: 'Embeds',
  LINK: 'Link',
  REFERENCE: 'Reference',
}

export const embedTools = [...embedTypesJson, ...embedCodeTypesJson].map((embedType) => ({
  id: 'embed',
  name: i18n.t(embedType.label),
  description: i18n.t(embedType.subtitle),
  value: embedType.value,
  icon: embedType.icon,
  image: embedType.icon ? '' : `/img/project_resources/${embedType.value}.png`,
  color: embedType.color,
  isCodeEmbed: embedType.is_code_embed,
  allowMultiple: true
}))

export const projectLinkTools = projectLinkTypesJson.map((linkType) => ({
  id: 'link',
  name: i18n.t(linkType.label),
  description: i18n.t(linkType.subtitle),
  value: linkType.value,
  icon: linkType.icon,
  image: linkType.icon ? '' : `/img/project_resources/${linkType.value}.png`,
  color: linkType.color,
  allowMultiple: true
}))

export const coreTools = [
  {
    name: i18n.t('Discussion'),
    value: toolsEnum.DISCUSSIONS,
    icon: 'fal fa-comments-alt',
    color: '#10B981',
    description: i18n.t('Chat and share files with people who are part of this project.')
  },
  {
    name: i18n.t('Tasks'),
    value: toolsEnum.TASKS,
    icon: 'fal fa-badge-check',
    color: '#F59E0B',
    description: i18n.t('Manage the things you need to do to complete this project.')
  },
  // Not displayed anymore explicitly
  // {
  //   name: i18n.t('People'),
  //   value: toolsEnum.PEOPLE,
  //   icon: "fal fa-users",
  //   description: i18n.t("Invite people to this project so you can collaborate and get things done."),
  //   color: "#EF4444"
  // },
  {
    name: i18n.t('Notes'),
    value: toolsEnum.NOTES,
    icon: "fal fa-sticky-note",
    description: i18n.t('Keep track of important information for this project.'),
    color: "#5720b4",
  },
  {
    name: i18n.t('Note'),
    value: toolsEnum.NOTE,
    icon: "fal fa-sticky-note",
    description: i18n.t('Record important information in note form for this project.'),
    color: "#6B7280",
    allowMultiple: true
  },
  {
    name: i18n.t('Files'),
    value: toolsEnum.FILES,
    icon: "fal fa-file",
    description: i18n.t('Upload, manage & view files associated with this project.'),
    color: "#3B82F6"
  },
  {
    name: i18n.t('File Proofing'),
    value: toolsEnum.FILE_PROOFING,
    icon: "fal fa-files",
    description: i18n.t('Review, annotate, comment on & approve all file types.'),
    color: "#a855f7",
    limitedFeature: AccountPermissions.FileProofing,
  },
  {
    name: i18n.t('Payments'),
    value: toolsEnum.PAYMENTS,
    icon: "fal fa-money-bill",
    description: i18n.t('Request & make payments and view invoices.'),
    color: "#10B981"
  },
  {
    name: i18n.t('Time'),
    value: toolsEnum.TIME,
    icon: "fal fa-stopwatch",
    description: i18n.t('Allocate and record time for people who work on this project.'),
    color: "#EC4899"
  },
  {
    id: 'embed',
    name: i18n.t('Embed URL'),
    value: 'anything',
    icon: "fal fa-input-pipe",
    description: i18n.t('Embed anything by simply pasting the web page URL.'),
    color: "#6B7280",
    allowMultiple: true
  },
  {
    id: 'embed',
    name: i18n.t('Embed Code'),
    value: 'anything_by_code',
    description: i18n.t('Embed anything by pasting the embed code.'),
    icon: "fa-solid fa-code",
    color: "#6B7280",
    isCodeEmbed: true,
    allowMultiple: true
  },
  {
    name: i18n.t('Link'),
    value: toolsEnum.LINK,
    icon: "fal fa-arrow-up-right-from-square",
    description: i18n.t('Link to another web page, URL or file.'),
    color: "#6B7280",
    allowMultiple: true
  },
  {
    name: i18n.t('Reference'),
    value: toolsEnum.REFERENCE,
    icon: "fal fa-link",
    description: i18n.t('Add a reference to something else in your account.'),
    color: "#6B7280",
    allowMultiple: true
  },
  {
    name: i18n.t('Resources'),
    value: toolsEnum.EMBEDS,
    icon: "fal fa-rectangle-list",
    description: i18n.t('A list of all your embeds, links and references.'),
    color: "#3B82F6"
  },
]

export const toolShortcutIcons = {
  [toolsEnum.FILES]: 'fa-square-f',
  [toolsEnum.TIME]: 'fa-square-i',
  [toolsEnum.TASKS]: 'fa-square-t',
  [toolsEnum.PAYMENTS]: 'fa-square-p',
  [toolsEnum.DISCUSSIONS]: 'fa-square-d',
  [toolsEnum.PEOPLE]: '',
  [toolsEnum.NOTE]: 'fa-square-n',
  [toolsEnum.EMBEDS]: '',
  [toolsEnum.SINGLE_EMBED]: 'fa-square-e',
  [toolsEnum.LINK]: '',
  [toolsEnum.REFERENCE]: '',
}

export const toolShortcuts = {
  [toolsEnum.FILES]: 'KeyF',
  [toolsEnum.TIME]: 'KeyI',
  [toolsEnum.TASKS]: 'KeyT',
  [toolsEnum.PAYMENTS]: 'KeyP',
  [toolsEnum.DISCUSSIONS]: 'KeyD',
  [toolsEnum.PEOPLE]: '',
  [toolsEnum.NOTE]: 'KeyN',
  [toolsEnum.EMBEDS]: '',
  [toolsEnum.SINGLE_EMBED]: 'KeyE',
  [toolsEnum.LINK]: '',
  [toolsEnum.REFERENCE]: '',
}

export function isFileProofingEnabled() {
  return import.meta.env.VITE_DISABLE_FILE_PROOFING !== 'true'
}

export function filterTools(tool, term = '') {
  const excludedTools = [toolsEnum.NOTE]
  if (excludedTools.includes(tool.value)) {
    return false
  }

  if (tool.value === toolsEnum.FILE_PROOFING) {
    const {
      hasAccountPermission
    } = useCan()

    return isFileProofingEnabled() && hasAccountPermission(AccountPermissions.FileProofing)
  }

  return tool.name.toLowerCase().includes(term.toLowerCase()) || tool.description.toLowerCase().includes(term.toLowerCase())
}
