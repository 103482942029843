<template>
  <div
    class="options-section w-full"
    :class="{
      'rounded-lg bg-white border-gray-200 border shadow mb-6': !$useNewLayout,
      'mb-3 use-new-layout': $useNewLayout,
    }"
  >
    <div
      :class="{
        'px-6 py-4': !$useNewLayout,
      }"
    >

      <button
        id="options-toggle"
        type="button"
        class="lg:hidden options-toggle block w-full relative items-center px-3 py-2 rounded-md border border-gray-200 bg-white text-sm font-medium text-gray-500 focus:outline-none focus:none"
        @click="showFilters = !showFilters"
      >
        {{ $t("Options") }}
        <i
          class="fas mt-1 ml-2 text-gray-400"
          :class="{
            'fa-chevron-down': !showFilters,
            'fa-chevron-up': showFilters,
          }"
        />
      </button>

      <div class="button-block flex items-end space-x-4">
        <div
          v-if="$slots.action"
          class="relative text-left"
        >
          <slot name="action" />
        </div>

        <div class="flex-grow">
          <EntityViewTypesTabsAll />
        </div>

        <div class="lg:max-w-[350px] flex-grow mt-2 lg:mt-0">
          <label for="search" class="sr-only">{{ searchPlaceholder }}</label>
          <div class="relative flex-1">
            <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <i class="far fa-search text-gray-400"></i>
            </div>
            <input
              v-model="allFilters.filters.search"
              :placeholder="searchPlaceholder"
              id="search"
              name="search"
              class="block w-full bg-white border-b border-gray-200 overflow-hidden py-2 pl-10 pr-3 text-sm focus:outline-none sm:text-sm"
              :class="{
                'border-primary-500': !!allFilters.filters.search,
              }"
              type="search"
            >
          </div>
        </div>
      </div>

      <div
        class="button-block flex-wrap lg:flex lg:items-center mt-3"
        :class="{
          'hidden': !showFilters,
          'gap-4': !$useNewLayout,
          'gap-2': $useNewLayout,
        }"
      >

        <slot name="more-actions" />

        <FilterByFilter
          v-if="availableFilters.includes('filter')"
          ref="filterByFilter"
          :columns="activeColumns"
          :default-filter="defaultFilterByFilter"
          :target="target"
          :filter-by-filter="defaultFilterByFilter"
          @filter-changed="onFilterByChange"
        />


        <MyAllFilter
          v-if="availableFilters.includes('myProjects')"
          :default-filter="defaultMyProjectsFilter"
          :entityType="$t('Projects')"
          :target="target"
          icon="fa-inbox"
          @change="onMyProjectsChange"
        />

        <MyAllFilter
          v-if="availableFilters.includes('myTasks')"
          :default-filter="defaultMyTasksFilter"
          :entityType="$t('Tasks')"
          :target="target"
          icon="fa-badge-check"
          @change="onMyTasksChange"
        />

        <CustomFilters
          v-if="availableFilters.includes('customFilters')"
          :availableFilters="availableFilters"
          :defaultValues="{
            showDateOnly: defaultDateOnlyFilter,
            overdueTasks: defaultOverdueTasksFilter,
            showDetailView: defaultDetailsView,
            showRecurrences: defaultShowRecurrencesFilter,
          }"
          @show-overdue-changed="onOverdueTasksChange"
          @show-recurrences-changed="onShowRecurrencesChange"
          @show-detail-view-changed="onShowDetailsChange"
          @show-date-only-changed="onDateOnlyChange"
        />

        <ArchivedUsersFilter
          v-if="availableFilters.includes('archivedUsers')"
          :default-filter="defaultArchivedUsersFilter"
          @change="onArchivedUsersChange"
        />

        <slot />

        <SortFilter
          v-if="availableFilters.includes('sort') && sortColumns.length"
          :columns="sortColumns"
          :default-sort="defaultSortFilter"
          @change="onSortChange"
        />

        <GroupByFilter
          v-if="availableFilters.includes('group')"
          :columns="activeColumns"
          :default-filter="defaultGroupByFilter"
          :allow-multiple="!$route.path.includes('scheduler')"
          :disabled="hasChartFilterEnabled"
          @change="onGroupByChange"
        />

        <ChooseColumnsFilter
          v-if="availableFilters.includes('columns')"
          :columns="activeColumns"
          :default-filter="defaultColumnsFilter"
          @change="onColumnsChange"
        />

        <ChartFilter
          v-if="availableFilters.includes('chart')"
          :columns="activeColumns"
          :default-sort="defaultChartFilter"
          @change="onChartChange"
        />

        <TotalRowFilter
          v-if="availableFilters.includes('totalRow')"
          :default-filter="defaultTotalRowFilter"
          @change="onTotalRowChange"
        />

        <CompletedFilter
          v-if="availableFilters.includes('closedProjects')"
          :columns="activeColumns"
          :default-filter="defaultCompletedProjectsFilter"
          @change="onCompletedProjectsChange"
        />

        <CompletedFilter
          v-if="availableFilters.includes('completedTasks')"
          :loading="$store.state.tasks.completedTasksLoading"
          :title="$t('Show completed tasks?')"
          :columns="activeColumns"
          :default-filter="defaultCompletedTasksFilter"
          @change="onCompletedTasksChange"
        />

        <CompletedTasksFilter
          v-if="availableFilters.includes('globalCompletedTasks')"
          :title="$t('Show completed tasks?')"
          :columns="activeColumns"
          :default-filter="defaultGlobalCompletedTasksFilter"
          @change="onGlobalCompletedTasksChange"
        />

        <KanbanColumnsFilter
          v-if="availableFilters.includes('kanbanColumns')"
          :default-filter="defaultKanbanFilter"
          :target="target"
          @change="onKanbanColumnsChange"
        />

        <slot name="extra-filters" />

        <FilterPresets
          v-if="showFilterOptions"
          :target="target"
          :view_type="view_type"
        />
      </div>
      <div
        v-if="availableFilters.includes('filter') && defaultFilterByFilter.filter(x => !x.hidden).length"
        class="flex flex-wrap gap-1 mt-2"
      >
        <FilterByChips
          :filter-by-filter="defaultFilterByFilter"
          @remove-filter="removeFilterByFilter"
          @filter-chip-click="filterChipClick"
        />
      </div>
    </div>
  </div>
</template>
<script>
// Helpers
import i18n from "@/i18n"
import { debounce } from "lodash-es";
import { SYSTEM_DEFAULT_FILTER } from "@/modules/filters/utils/commonFilterUtils";
import {
  decodeFilters,
  encodeFilters,
  EntityTargetTypes,
  getEntityTarget
} from "@/modules/common/utils/filterUtils";
import { getViewType } from "@/modules/auth/middleware/filtersMiddleware";
import { VIEW_TYPES } from "@/modules/users/util/userUtils";
import { columnBuilder } from '@/components/table/tableUtils'

// Components
import SortFilter from "@/modules/filters/components/SortFilter.vue";
import ChooseColumnsFilter from "@/modules/filters/components/ChooseColumnsFilter.vue";
import GroupByFilter from "@/modules/filters/components/GroupByFilter.vue";
import CompletedFilter from "@/modules/filters/components/CompletedFilter.vue";
import ToggleFutureRecurrencesFilter from "@/modules/filters/components/ToggleFutureRecurrencesFilter.vue";
import ToggleDetailsViewFilter from "@/modules/filters/components/ToggleDetailsViewFilter.vue";
import ArchivedUsersFilter from "@/modules/filters/components/ArchivedUsersFilter.vue";
import MyAllFilter from "@/modules/filters/components/MyAllFilter.vue";
import OverdueFilter from "@/modules/filters/components/OverdueFilter.vue";
import FilterByFilter from "@/modules/filters/components/FilterByFilter.vue";
import FilterByChips from "@/modules/filters/components/FilterByChips.vue";
import FilterPresets from "@/modules/filters/components/FilterPresets.vue";
import KanbanColumnsFilter from "@/modules/filters/components/KanbanColumnsFilter.vue";
import ChartFilter from "@/modules/filters/components/ChartFilter.vue";
import TotalRowFilter from "@/modules/filters/components/TotalRowFilter.vue";
import CompletedTasksFilter from "@/modules/filters/components/CompletedTasksFilter.vue";
import TasksWithDateFilter from "@/modules/filters/components/TasksWithDateFilter.vue";
import CustomFilters from "@/modules/filters/components/CustomFilters.vue";

export default {
  components: {
    ChartFilter,
    TotalRowFilter,
    MyAllFilter,
    OverdueFilter,
    CompletedFilter,
    ToggleFutureRecurrencesFilter,
    ToggleDetailsViewFilter,
    ArchivedUsersFilter,
    GroupByFilter,
    ChooseColumnsFilter,
    KanbanColumnsFilter,
    SortFilter,
    FilterByFilter,
    FilterByChips,
    FilterPresets,
    CompletedTasksFilter,
    TasksWithDateFilter,
    CustomFilters,
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    availableFilters: {
      type: Array,
      default: () => []
    },
    searchPlaceholder: {
      type: String,
      default: i18n.t('Search projects...')
    },
    showFilterOptions: {
      type: Boolean,
      default: true
    },
    syncGroupingWithSort: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showFilters: false,
      target: this.routeTarget,
      entitySystemDefaultFilter: {},
      allFilters: {
        filters: {
          search: this.$route.query.search || SYSTEM_DEFAULT_FILTER.filters.search,
          page: this.$route.query.page || SYSTEM_DEFAULT_FILTER.filters.page
        },
        filterByFilter: SYSTEM_DEFAULT_FILTER.filterByFilter,
        sortFilter: SYSTEM_DEFAULT_FILTER.sortFilter,
        completedProjectsFilter: SYSTEM_DEFAULT_FILTER.completedProjectsFilter,
        completedTasksFilter: SYSTEM_DEFAULT_FILTER.completedTasksFilter,
        myProjectsFilter: SYSTEM_DEFAULT_FILTER.myProjectsFilter,
        myTasksFilter: SYSTEM_DEFAULT_FILTER.myTasksFilter,
        overdueTasksFilter: SYSTEM_DEFAULT_FILTER.overdueTasksFilter,
      },
    }
  },
  computed: {
    activeColumns() {
      let columns = [...this.columns]

      columns = columnBuilder.filterDisabledColumns(columns)

      columns = columnBuilder.remapDynamicProperties(columns)

      return columns
    },
    defaultFilterByFilter() {
      return this.apiFilters.find(f => f.key === 'filters')?.value || []
    },
    defaultSortFilter() {
      return this.apiFilters.find(f => f.key === 'sorts')?.value || {}
    },
    defaultCompletedProjectsFilter() {
      return this.apiFilters.find(f => f.key === 'closed')?.value?.show || false
    },
    defaultCompletedTasksFilter() {
      return this.apiFilters.find(f => f.key === 'completed')?.value?.show || false
    },
    defaultGlobalCompletedTasksFilter() {
      return this.apiFilters.find(f => f.key === 'global-completed-tasks')?.value
    },
    defaultShowRecurrencesFilter() {
      return this.apiFilters.find(f => f.key === 'show-future-recurrences')?.value?.show || false
    },
    defaultDetailsView() {
      return this.apiFilters.find(f => f.key === 'show-details-view')?.value?.show || false
    },
    defaultDateOnlyFilter() {
      return this.apiFilters.find(f => f.key === 'date-only')?.value?.show || false
    },
    defaultMyProjectsFilter() {
      return this.apiFilters.find(f => f.key === 'my-projects')?.value?.show
    },
    defaultMyTasksFilter() {
      return this.apiFilters.find(f => f.key === 'my-tasks')?.value?.show
    },
    defaultArchivedUsersFilter() {
      return this.apiFilters.find(f => f.key === 'archived-users')?.value?.show
    },
    defaultOverdueTasksFilter() {
      return this.apiFilters.find(f => f.key === 'overdue-tasks')?.value?.show
    },
    defaultColumnsFilter() {
      return this.localFilters.columnsToHide || []
    },
    defaultGroupByFilter() {
      return this.localFilters.groupBy
    },
    defaultChartFilter() {
      return this.localFilters.chart
    },
    defaultTotalRowFilter() {
      return this.localFilters.totalRow
    },
    hasChartFilterEnabled() {
      return this.defaultChartFilter?.length > 0
    },
    defaultKanbanFilter() {
      return this.localFilters.kanbanColumn
    },
    localFilters: {
      get() {
        return this.$store.getters['filters/targetLocalFilters'](this.target)
      },
      set(value) {
        this.$store.commit('filters/setLocalFilters', { target: this.target, value })
      }
    },
    localFiltersChanged() {
      return this.$store.state.filters.localFiltersChanged
    },
    apiFilters: {
      get() {
        return this.$store.getters['filters/targetApiFilters'](this.target)
      },
      set(value) {
        this.$store.commit('filters/setApiFilters', { target: this.target, value })
      }
    },
    sortColumns() {
      if (!this.localFilters.groupBy?.prop) {
        return this.activeColumns
      }

      if (this.localFilters.groupBy.sortProp) {
        return [this.localFilters.groupBy]
      }

      return []
    },
    project_id() {
      return this.$store.getters.project_id
    },
    routeTarget() {
      return getEntityTarget(this.$route)
    },
    view_type() {
      return getViewType(this.$route)
    },
  },
  methods: {
    onFilterByChange(filterBy) {
      this.onApiFilterChanged('filters', filterBy, !filterBy || !filterBy.length)
    },
    onSortChange(sort) {
      this.onApiFilterChanged('sorts', sort, !sort?.length)
    },
    onCompletedProjectsChange(filter) {
      this.onApiFilterChanged('closed', { show: filter }, !this.availableFilters.includes('closedProjects'))
    },
    onCompletedTasksChange(filter) {
      this.onApiFilterChanged('completed', { show: filter }, !this.availableFilters.includes('completedTasks'))
    },
    onGlobalCompletedTasksChange(filter) {
      this.onApiFilterChanged('global-completed-tasks', filter, !this.availableFilters.includes('globalCompletedTasks'))
    },
    onShowRecurrencesChange(filter) {
      this.onApiFilterChanged('show-future-recurrences', { show: filter }, !this.availableFilters.includes('toggleFutureRecurrences'))
    },
    onDateOnlyChange(filter) {
      this.onApiFilterChanged('date-only', { show: filter }, !this.availableFilters.includes('dateOnly'))
    },
    onShowDetailsChange(filter) {
      this.onApiFilterChanged('show-details-view', { show: filter }, !this.availableFilters.includes('toggleDetailsView'))
    },
    onMyProjectsChange(filter) {
      this.onApiFilterChanged('my-projects', { show: filter }, !this.availableFilters.includes('myProjects'))
    },
    onMyTasksChange(filter) {
      this.onApiFilterChanged('my-tasks', { show: filter }, !this.availableFilters.includes('myTasks'))
    },
    onArchivedUsersChange(filter) {
      this.onApiFilterChanged('archived-users', { show: filter }, !this.availableFilters.includes('archivedUsers'))
    },
    onOverdueTasksChange(filter) {
      this.onApiFilterChanged('overdue-tasks', { show: filter }, !this.availableFilters.includes('overdueTasks'))
    },
    async onApiFilterChanged(key, value, shouldRemove = true) {

      const filterIdx = this.apiFilters.findIndex(x => x.key === key);
      const exists = filterIdx !== -1

      if (shouldRemove) {
        if (exists) {
          this.apiFilters.splice(filterIdx, 1)
        }
      }
      else {
        const newValue = {
          key: key,
          value: value
        };

        if (filterIdx === -1) {
          this.apiFilters.push(newValue)
        }
        else {
          this.apiFilters[filterIdx] = newValue
        }
      }

      await this.$nextTick()
      this.changeRouteDebounced()
    },
    async onColumnsChange(filter) {
      this.localFilters = {
        ...this.localFilters,
        columnsToHide: filter,
      }
      await this.$nextTick()
      this.changeRouteDebounced()
    },
    async onKanbanColumnsChange(filter) {
      this.localFilters = {
        ...this.localFilters,
        kanbanColumn: filter,
      }
      await this.$nextTick()
      this.changeRouteDebounced()
    },
    async onGroupByChange(filter) {
      this.localFilters = {
        ...this.localFilters,
        groupBy: filter,
      }

      // const column = this.columns.find(c => c.prop === filter?.[0])
      // if (filter?.length === 1 && column?.sortProp) {
      //   this.onSortChange([{
      //     column: column.sortProp,
      //     order: 'asc',
      //   }])
      // }
      await this.$nextTick()
      this.changeRouteDebounced()
    },
    onChartChange(filter) {
      this.localFilters = {
        ...this.localFilters,
        chart: filter,
      }
    },
    async onTotalRowChange(filter) {
      this.localFilters = {
        ...this.localFilters,
        totalRow: filter,
      }

      await this.$nextTick()
      this.changeRouteDebounced()
    },
    async buildApiFilters() {
      let apiFilters = []
      if (
        this.availableFilters.includes('filter')
        &&
        this.allFilters.filterByFilter
        &&
        this.allFilters.filterByFilter.length
      ) {
        apiFilters.push({
          key: 'filters',
          value: [
            ...this.allFilters.filterByFilter
          ]
        })
      }

      if (this.availableFilters.includes('sort') && this.allFilters.sortFilter.column) {
        apiFilters.push({
          key: 'sorts',
          value: {
            ...this.allFilters.sortFilter
          }
        })
      }

      if (this.availableFilters.includes('closedProjects')) {
        apiFilters.push({
          key: 'closed',
          value: {
            show: this.allFilters.completedProjectsFilter
          }
        })
      }
      if (this.availableFilters.includes('completedTasks')) {
        apiFilters.push({
          key: 'completed',
          value: {
            show: this.allFilters.completedTasksFilter
          }
        })
      }

      if (this.availableFilters.includes('toggleFutureRecurrences')) {
        apiFilters.push({
          key: 'show-future-recurrences',
          value: {
            show: this.allFilters.showRecurrencesFilter
          }
        })
      }

      if (this.availableFilters.includes('toggleDetailsView')) {
        apiFilters.push({
          key: 'show-details-view',
          value: {
            show: this.allFilters.showDetailsFilter
          }
        })
      }

      if (this.availableFilters.includes('myProjects')) {
        apiFilters.push({
          key: 'my-projects',
          value: {
            show: this.allFilters.myProjectsFilter
          }
        })
      }
      if (this.availableFilters.includes('myTasks')) {
        apiFilters.push({
          key: 'my-tasks',
          value: {
            show: this.allFilters.myTasksFilter
          }
        })
      }

      if (this.availableFilters.includes('overdueTasks')) {
        apiFilters.push({
          key: 'overdue-tasks',
          value: {
            show: this.allFilters.overdueTasksFilter
          }
        })
      }

      this.apiFilters = apiFilters

      await this.$nextTick()
      this.changeRouteDebounced()
    },
    changeRoute(encodedApiFilters) {
      let query = {
        ...this.$route.query,
        ...this.allFilters.filters,
      }
      for (let key in query) {
        const value = query[key]
        if (value === null || value === '' || value === undefined) {
          delete query[key]
        }
      }

      if (encodedApiFilters) {
        query.filters = encodedApiFilters
      }
      else {
        if (this.apiFilters.length > 0) {
          query.filters = encodeFilters(this.apiFilters)
        } else {
          delete query.filters
        }
      }

      if (Object.keys(this.localFilters).length > 0) {
        query.localFilters = encodeFilters(this.localFilters)
      }
      else {
        delete query.localFilters
      }

      delete query.show
      delete query.overdue
      delete query.completed
      delete query.ignoreDefault
      const route = this.$router.resolve({
        path: this.$route.path,
        query,
      })

      this.$router.replace(route)
    },
    removeFilterByFilter(filter) {
      const newFilters = this.defaultFilterByFilter.filter(f => f.column !== filter.column)
      this.onFilterByChange(newFilters)
    },
    filterChipClick(filter) {
      this.$refs.filterByFilter?.openFilter(filter)
    },
    clearFilters() {
      this.apiFilters = []
      this.localFilters = {}
    },
    async removeFilters(columns) {
      const apiFilters = decodeFilters(this.$route.query.filters, [])

      const filtersIdx = apiFilters.findIndex(x => x.key === 'filters')

      if (filtersIdx === -1) {
        return
      }

      for (let col of columns) {
        const idx = apiFilters[filtersIdx].value.findIndex(v => v.column === col);
        if (idx !== -1) {
          apiFilters[filtersIdx].value.splice(idx, 1)
        }
      }

      if (!apiFilters[filtersIdx].value.length) {
        apiFilters.splice(filtersIdx, 1)
      }

      this.changeRouteDebounced(encodeFilters(apiFilters))
    },
    isValidContext() {
      return Object.values(EntityTargetTypes).includes(this.routeTarget)
        && Object.values(VIEW_TYPES).includes(this.view_type)
    },
    onElementResize(resizeList, observer) {
      this.$store.commit('setTopFiltersResized')
    },
    observeElementResize() {
      if (!this.$route.path.includes('kanban')) {
        return
      }

      const resizerOptions = {
        childList: true,
        subtree: true
      }

      const onElementResizeDebounced = debounce(this.onElementResize, 100)

      this.resizeObserver = new ResizeObserver(onElementResizeDebounced)
      this.resizeObserver.observe(this.$el)
    },
    unobserveElementResize() {
      this.resizeObserver?.disconnect()
    }
  },
  watch: {
    async localFiltersChanged() {
      await this.$nextTick()

      this.changeRouteDebounced && this.changeRouteDebounced()
    },
    // search term or page changed
    'allFilters.filters': {
      deep: true,
      async handler(filters) {
        await this.$nextTick()
        if (!this.isValidContext()) {
          return
        }
        this.$emit('filters-change', filters)

        this.changeRouteDebounced && this.changeRouteDebounced()
      }
    },
    '$route.query.localFilters': {
      immediate: true,
      async handler(value) {
        await this.$nextTick()
        if (!this.isValidContext()) {
          this.clearFilters()
          return
        }
        this.localFilters = decodeFilters(value, {})
      }
    },
    '$route.query.filters': {
      immediate: true,
      async handler(value) {
        await this.$nextTick()
        if (!this.isValidContext()) {
          this.clearFilters()
          return
        }
        this.apiFilters = decodeFilters(value)
      }
    },
    async view_type(value, oldValue) {
      await this.$nextTick()
      if (!this.isValidContext()) {
        this.clearFilters()
        return
      }

      // When leaving, we need to remove the date filter specific to calendar/scheduler
      if (['calendar', 'scheduler'].includes(oldValue) && !['calendar', 'scheduler'].includes(value)) {
        this.removeFilters(['date', 'start_date_deadline'])
      }
    }
  },
  mounted() {
    this.observeElementResize()
  },
  unmounted() {
    this.unobserveElementResize()
  },
  created() {
    this.target = this.routeTarget
    this.changeRouteDebounced = debounce(this.changeRoute, 300)
  },
}
</script>
