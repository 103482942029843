<template>
  <ul role="list" class="-mx-2 -my-1 flex justify-between">
    <li
      v-for="item of filteredItems"
      :key="item.icon"
    >
      <div
        class="breadcrumb-color group hover:bg-white/10 group flex-0 rounded-md py-2 px-1 cursor-pointer relative"
        @click="!item.disabled && item.onClick()"
      >
        <i
          class="fa-light text-lg text-center h-5 w-5 shrink-0 opacity-60 group-hover:opacity-100"
          :class="{
            [item.icon]: item.icon,
          }"
        />
        <span
          v-if="item.countBadge"
          class="notification-badge-new opacity-80 group-hover:opacity-100"
        >
          {{ item.countBadge }}
        </span>
        <i
          v-if="isItemLimitReached(item)"
          class="fas fa-crown text-sm text-yellow-500 absolute -top-1 -left-1"
        />
      </div>
    </li>
  </ul>
</template>
<script lang="ts" setup>
// Utils
import i18n from '@/i18n';
import { computed, onBeforeMount } from 'vue';
import { notificationStatuses } from '@/modules/accounts/utils/notificationUtils'
import { AccountPermissions } from '@/modules/common/composables/useCan';

// Composables
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import useCan from '@/modules/common/composables/useCan';
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";

const [
  store,
  route,
  router
] = [
  useStore(),
  useRoute(),
  useRouter()
];

const {
  openUpgradeDialog,
  hasReachedLimit
} = useAccountLimits()

const {
  can,
  actions
} = useCan()

const emit = defineEmits(['item-clicked'])

const hasBackRoute = computed(() => {
  return route.path && !!window.history?.state?.back
})

const hasForwardRoute = computed(() => {
  return route.path && !!window.history?.state?.forward
})

const accountStats = computed(() => {
  return store.state.accounts.stats || {}
})

const time_entry_recorded_count = computed(() => {
  return store.state.time.stats.time_entry_recorded_count || 0
})

const unread_comments_count = computed(() => {
  return accountStats.value.unread_comments_count || 0
})

const isSubscriptionValid = computed(() => {
  return store.getters['accounts/isValidSubscription']
})

type SidebarItem = {
  icon: string
  label: string
  disabled?: boolean
  requiresPermissionTo?: string
  limitedFeature?: AccountPermissions
  onClick: () => void
  countBadge?: number
}

const items = computed(() => {
  return [
    {
      icon: 'fa-chevron-left',
      label: i18n.t('Previous'),
      disabled: !hasBackRoute.value,
      onClick: () => {
        router.go(-1)
        emit('item-clicked')
      }
    },
    {
      icon: 'fa-chevron-right',
      label: i18n.t('Next'),
      disabled: !hasForwardRoute.value,
      onClick: () => {
        router.go(+1)
        emit('item-clicked')
      }
    },
    {
      icon: 'fa-stopwatch',
      label: i18n.t('Time'),
      disabled: !isSubscriptionValid.value,
      requiresPermissionTo: actions.VIEW_TIME_ENTRIES,
      onClick: () => {
        store.commit('time/triggerAddTimeRecordingEntry')
        emit('item-clicked')
      },
      countBadge: time_entry_recorded_count.value
    },
    {
      icon: 'fa-bell',
      label: i18n.t('Notifications'),
      onClick: () => {
        router.push(`/inbox?status=${notificationStatuses.Unread}`)
        emit('item-clicked')
      },
      countBadge: unread_comments_count.value
    },
    {
      icon: 'fa-search',
      label: i18n.t('Search'),
      disabled: !isSubscriptionValid.value,
      onClick: () => {
        store.commit('accounts/triggerGlobalSearch')
        emit('item-clicked')
      }
    },
    {
      icon: 'fa-circle-dashed',
      label: i18n.t('AI Assistant'),
      disabled: !isSubscriptionValid.value,
      requiresPermissionTo: actions.USE_AI_ASSISTANT,
      limitedFeature: AccountPermissions.AiAssistant,
      onClick: () => {
        if (hasReachedLimit(AccountPermissions.AiAssistant)) {
          openUpgradeDialog(AccountPermissions.AiAssistant)
          emit('item-clicked')
          return
        }

        store.commit('ai/triggerAiChatDialog')
        emit('item-clicked')
      }
    }
  ]
})

const filteredItems = computed(() => {
  return items.value.filter(item => {
    if (!item.requiresPermissionTo) {
      return true
    }

    return can(item.requiresPermissionTo)
  })
})

function isItemLimitReached(item: SidebarItem) {
  if (!item.limitedFeature) {
    return false
  }

  return hasReachedLimit(item.limitedFeature)
}

onBeforeMount(async () => {
  await store.dispatch("time/getRecordedTimeEntriesSummary")
})
</script>
<style lang="scss">
.notification-badge-new {
  @apply absolute -top-2 -left-2 min-w-[25px] rounded-full bg-red-500 py-0.5 px-1 text-xs text-white leading-4 flex items-center justify-center border-2 border-[var(--body-background)];
}
</style>
