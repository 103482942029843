import { useWebWorkerFn } from '@vueuse/core'
import store from "@/store/index.js";

export function getPaymentWithRelationships(payment: any, allProjects: any[], allGroups: any[]) {
  allProjects = allProjects || store.state.projects.allProjects || []
  allGroups = allGroups || store.state.groups.allGroups || []

  const project = allProjects.find(p => p.id == payment.attributes.project_id)
  const group = allGroups.find(g => g.id == payment.attributes.group_id)

  return {
    ...payment,
    relationships: {
      ...payment.relationships,
      project,
      group,
    }
  }
}

function computeRelationships(
  allPayments: any[],
  allGroups: any[],
  allProjects: any[],
) {

  return allPayments.map(payment => {
    const project = allProjects.find(p => p.id == payment.attributes.project_id)
    const group = allGroups.find(g => g.id == payment.attributes.group_id)

    return {
      ...payment,
      relationships: {
        ...payment.relationships,
        project,
        group,
      }
    }
  })
}

export const { workerFn: paymentsWithRelationshipsWorker } = useWebWorkerFn(computeRelationships)
