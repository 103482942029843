

export type DropdownOption = {
  icon?: string
  text: string
  onClick: () => void
}

export type DropdownConfig = {
  options: DropdownOption[],
  btnClass?: string,
  dropdownClass?: string
}

export function getDropdownHtml(config: DropdownConfig) {
  const dropdown = document.createElement('div');
  dropdown.setAttribute('class', `inline-dropdown ${config.dropdownClass || ''}`);

  const btnActivator = document.createElement('button');
  btnActivator.setAttribute('class', `inline-dropbtn ${config.btnClass || ''}`);

  const icon = document.createElement('i');
  icon.setAttribute('class', 'fas fa-grip-dots text-gray-500');
  btnActivator.appendChild(icon);

  const dropdownContent = document.createElement('div');
  dropdownContent.setAttribute('class', 'inline-dropdown-content');

  config.options.forEach(option => {
    const a = document.createElement('a');
    if (option.icon) {
      const icon = document.createElement('i');
      icon.setAttribute('class', `${option.icon} mr-1`);
      a.appendChild(icon);
    }

    a.appendChild(document.createTextNode(option.text));
    a.onclick = option.onClick;
    dropdownContent.appendChild(a);
  })
  
  dropdown.appendChild(btnActivator);
  dropdown.appendChild(dropdownContent);

  return dropdown;
}
