<template>
  <BaseDialogNew
    v-model="visible"
    :hasBodyMargin="false"
    :appendToBody="true"
    class="max-w-2xl"
    @close="$emit('close')"
  >
    <ProjectReferenceForm
      :reference="reference"
      :defaultPinned="defaultPinned"
      @cancel="visible = false"
      @save="onReferenceCreated"
    />
  </BaseDialogNew>
</template>
<script>
import ProjectReferenceForm from "@/modules/projects/components/ProjectReferenceForm.vue";

export default {
  components: {
    ProjectReferenceForm,
  },
  props: {
    reference: {
      type: Object,
      default: () => ({})
    },
    defaultPinned: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    onReferenceCreated(reference) {
      this.$emit('save', reference);
      this.visible = false
    }
  }
}
</script>
