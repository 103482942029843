<template>
  <BaseTooltip
    :disabled="!tooltip"
    :content="tooltip"
  >
    <time v-if="!isCard || value">
      {{ formattedDate }}
    </time>
  </BaseTooltip>
</template>
<script>
import { get, capitalize } from 'lodash-es'
import { formatDate } from "@/modules/common/utils/dateUtils";
import { getRRuleFromString } from "@/modules/common/utils/recurrenceUtils";

export default {
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    row: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    isCard: {
      type: Boolean,
      default: false
    },
    emptyValuePlaceholder: {
      type: String,
      default: '- -'
    }
  },
  data() {
    return {
      tooltip: '',
    }
  },
  computed: {
    value() {
      let value

      if (this.params.getValue && typeof this.params.getValue === 'function') {
        value = this.params.getValue(this.row)

        if (typeof value === 'string' && value.startsWith('F_')) {
          return value.substr(2)
        }
      }
      else {
        value = get(this.row, this.column.prop || this.column.field)
      }

      return value
    },
    formattedDate() {
      if (!this.value || typeof this.value !== 'object') {
        return this.formatStringDate(this.value)
      }
        
      if (this.value.recurring) {
        const rrule = getRRuleFromString(this.row.attributes.recurrence)
        
        let recurrenceText = rrule.toText()
        recurrenceText = recurrenceText.charAt(0).toUpperCase() + recurrenceText.slice(1)
        const fromDate = this.$formatDate(rrule.origOptions.dtstart?.toISOString())

        this.tooltip = this.$tc('recurrence with from date', {
          recurrenceText,
          fromDate
        })
        
        return this.formatStringDate(this.value.date)
      }

      const fromDate = this.formatStringDate(this.value?.start_date)
      const toDate = this.formatStringDate(this.value?.end_date)

      return `${fromDate} → ${toDate}`
    },
  },
  methods: {
    formatStringDate(dateString) {
      if (!dateString) {
        return this.emptyValuePlaceholder
      }

      return this.$formatDate(dateString)
    }
  }
}
</script>
