<template>
  <div
    :key="cellKey"
    class="w-full flex justify-between items-center relative base-table-cell"
    :class="{
      'font-semibold text-gray-800 whitespace-nowrap': isGroupRow,
      'h-full px-3 py-1': isEditableNameCell,
    }"
    @click="onClick"
    @dblclick="onDblClick"
  >
    <div class="line-clamp-2 w-full overflow-visible">
      <slot v-if="showSlot"/>
      <div v-else-if="showFooterLabel">
        {{  $tc('row total', { totalType }) }}
      </div>
    </div>

    <DataSyncingIndicator
      v-if="showSyncIndicator"
      key="data-sync-indicator"
      class="absolute top-1/2 -translate-y-1/2 right-2 mt-0"
    />

    <div
      v-else-if="!isGroupRow && isEditable"
      class="cursor-pointer cell-edit-icon text-gray-300 items-center justify-center hidden"
      :class="{
        'w-full h-8 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 bg-white rounded-md border border-gray-100': column.type === ColumnTypes.EntityLogo,
      }"
    >
      <div class="flex space-x-2">
        <slot name="actions" />
        <i
          class="fa-solid fa-pen-to-square hover:text-gray-400"
          @click.stop="triggerCellEdit(props.params)"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ICellRendererParams } from "@ag-grid-community/core";
import { PropType, computed } from "vue"
import { TableColumn, triggerCellEdit } from "@/components/table/tableUtils"
import { ColumnTypes } from '@/components/table/cells/tableCellComponents'
import { totalRowOptions, totalRowFunctions } from '@/modules/filters/utils/commonFilterUtils'

import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue";

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams<any>>,
    default: () => ({})
  }
})

const isGroupRow = computed(() => {
  return props.params.node?.group
})

const isFooterRow = computed(() => {
  return props.params.node?.footer
})

const localFilters = computed(() => {
  return props.params.context.getLocalFilters()
})

const totalType = computed(() => {
  const aggFunc = localFilters.value.totalRow || totalRowFunctions.Sum
  return totalRowOptions.find(option => option.value === aggFunc)?.label
})

const isFirstColumn = computed(() => {
  const firstVisibleCol = props.params.columnApi
    ?.getAllDisplayedColumns()
    ?.find(col => col.getColId() !== 'ag-Grid-AutoColumn')

  return props.params.column?.getColId() === firstVisibleCol?.getColId()
})

const showFooterLabel = computed(() => {
  return isFooterRow.value && isFirstColumn.value
})

const column = computed<TableColumn>(() => {
  return props.params.colDef as TableColumn
})

const showSlot = computed(() => {
  return !isGroupRow.value || column.value?.showRowGroup
})

const showSyncIndicator = computed(() => {
  const data = props.params.data
  return data?.id === -1 && data.isSaving && column.value?.isMainColumn
})

const isEditable = computed(() => {
  return props.params.column?.isCellEditable(props.params.node)
})

const cellKey = computed(() => {
  const field = props.params.colDef?.field
  return `${props.params.data?.id}-${field}`
})

const isEditableNameCell = computed(() => {
  return isEditable.value && column.value.field === 'attributes.name'
})

function onClick(e: Event) {
  // Prevent single click edit for name cells
  if (isEditableNameCell.value) {
    e.stopImmediatePropagation()
    e.stopPropagation()
  }
}

function onDblClick(e: Event) {
  // Enable double click edit for name cells
  if (isEditableNameCell.value) {
    triggerCellEdit(props.params)
  }
}
</script>
