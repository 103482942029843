<template>
  <div>
    <BaseTooltip
      v-for="option of availableOptions"
      :key="option.label"
      :content="option.tooltip"
      :disabled="!option.tooltip"
      :class="option.class"
    >
      <BaseButton
        :variant="option.variant"
        :hoverVariant="option.hoverVariant"
        :loading="option.loading"
        @click="onAction(option)"
      >
        <i v-if="option.icon" :class="`${option.icon} mr-2`" />
        {{ option.label }}
      </BaseButton>
    </BaseTooltip>
    <DuplicateTaskDialog
      v-if="showDuplicateTaskDialog"
      v-model="showDuplicateTaskDialog"
      :task="task"
    />
  </div>
</template>
<script>
import i18n from '@/i18n'
import DuplicateTaskDialog from '@/modules/tasks/components/DuplicateTaskDialog.vue'
import { getProjectImages } from "@/modules/projects/utils/projectHelpers";

export default {
  components: {
    DuplicateTaskDialog,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    projectId: {
      type: [String, Number],
      default: '',
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDuplicateTaskDialog: false,
      creatingProject: false,
    }
  },
  computed: {
    project() {
      return this.task?.relationships?.project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    isTemplateTask() {
      if (!this.task?.id) {
        return this.$store.getters['templates/isTemplateOpened']
      }
      return this.task?.relationships?.project?.attributes?.is_template
    },
    customFieldsPath() {
      const basePath = this.isTemplateTask ? `/templates/${this.projectId}` : `/projects/${this.projectId}`

      return `${basePath}/custom-fields`
    },
    statuses() {
      return this.$store.getters['tasks/orderedStatuses'] || []
    },
    completedStatus() {
      return this.statuses?.[this.statuses?.length - 1]
    },
    isTaskClosed() {
      if (!this.task?.id || !this.completedStatus) {
        return false
      }

      return this.task?.attributes?.status_id == this.completedStatus?.id
    },
    options() {
      return [
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          variant: 'white',
          hoverVariant: 'danger',
          tooltip: i18n.t('Delete this task from your account'),
          icon: 'fa-regular fa-trash',
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.DELETE_TASKS)
          },
        },
        {
          label: i18n.t('Duplicate'),
          variant: 'white',
          action: () => this.showDuplicateTaskDialog = true,
          tooltip: i18n.t("Duplicate this task and all its data"),
          icon: 'fa-regular fa-copy',
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.CREATE_TASKS)
          },
        },
        {
          label: i18n.t('Custom Fields'),
          path: this.customFieldsPath,
          variant: 'white',
          tooltip: i18n.t('View and edit the custom fields for this project'),
          icon: 'fa-regular fa-stars',
          enabled: () => {
            if (!this.projectId) {
              return false
            }

            return this.can(this.$actions.CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS)
          },
        },
        {
          label: i18n.t('Convert to Project'),
          variant: 'white',
          icon: 'fa-regular fa-inbox',
          action: 'convertToProject',
          loading: this.creatingProject,
          tooltip: i18n.t('Create a new project based on this task'),
          requiresPermissionTo: this.$actions.CREATE_PROJECTS,
        },
        {
          label: this.isTaskClosed ? i18n.t('Reopen') : i18n.t('Complete'),
          action: 'changeTaskState',
          variant: 'white',
          icon: 'fa-regular fa-circle-check',
          tooltip: this.isTaskClosed 
            ? i18n.t('Reopen this task to enable editing')
            : i18n.t('Mark this task as completed'),
          enabled: () => {
            if (!this.can(this.$actions.EDIT_TASKS)) {
              return false
            }

            if (this.isTaskClosed && this.isProjectClosed) {
              return false
            }

            return true
          }
        },
        {
          label: this.isDialog ? i18n.t('Close Dialog') : i18n.t('Close Page'),
          variant: 'primary',
          action: 'closeTask',
          class: 'ml-auto',
        },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    onAction(option) {
      if (typeof option.action === 'function') {
        option.action()
        return
      }

      if (this[option.action]) {
        this[option.action]()
        return
      }

      if (option.path) {
        this.$router.push(option.path)
        return
      }

      this.$emit(option.action, this.task)
    },
    async changeTaskState() {
      await this.$store.dispatch('tasks/toggleTaskCompleted', {
        task: this.task,
        setCompleted: !this.isTaskClosed
      })

      this.$emit('completed-changed')
    },
    async convertToProject() {
      try {
        let user_ids = this.task.relationships?.allocations?.map(user => user.id)
        if (!user_ids?.length) {
          user_ids = this.task.attributes?.user_ids || []
        }
        let group_ids = this.task.relationships?.groups?.map(group => group.id)
        if (!group_ids?.length) {
          group_ids = this.task.attributes?.group_ids || []
        }

        const {
          image,
          cover_image
        } = await getProjectImages()

        const projectModel = {
          name: this.task.attributes.name,
          description: this.task.attributes.notes,
          user_ids,
          group_ids,
          image,
          cover_image
        }

        this.creatingProject = true
        const project = await this.$store.dispatch('projects/createProject', projectModel)

        this.$success(i18n.t('Project created successfully'))

        if (user_ids?.length) {
          await this.$store.dispatch('projects/addProjectUsers', {
            users: user_ids,
            projectId: project.id,
          })
        }

        this.$router.push(`/projects/${project.id}`)
      }
      finally {
        this.creatingProject = false
      }
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Delete Task'),
        description: i18n.t('Are you sure? This will permanently remove the task.')
      });

      if (!confirmed) {
        return;
      }

      await this.$store.dispatch('tasks/deleteTask', this.task);
      this.$success(i18n.t('Task deleted successfully'))
      this.$emit('deleted')
    },
  },
};
</script>
