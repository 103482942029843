<template>
  <BaseDataTable
    :data="data"
    :meta="meta"
    :columns="tableColumns"
    :loading="loading"
    treeData
    :getDataPath="getDataPath"
    groupIncludeTotalFooter
    prependEntityType
    :autoGroupColumnDef="autoGroupColumnDef"
    :enableInputRow="enableInputRow"
    :defaultInputRowModel="defaultInputRowModel"
    rowDragEntireRow
    multipleEntityTypes
    :onRowDragLeave="FileDraggingUtils.onRowDragLeave"
    :onRowDragMove="FileDraggingUtils.onRowDragMove"
    :onRowDragEnd="FileDraggingUtils.onRowDragEnd"
    :getRowDragText="() => $t(`Move file into a folder`)"
  >
    <template #no-data>
      <NoFiles />
    </template>
  </BaseDataTable>
</template>
<script lang="ts" setup>
// Components
import NoFiles from "@/modules/files/components/NoFiles.vue";

// Utils
import i18n from "@/i18n";
import { computed } from 'vue'
import { TableColumn } from "@/components/table/tableUtils";
import * as FileDraggingUtils from "@/modules/files/utils/fileDraggingUtils";
import { get } from "lodash-es";

// Composables
import { useStore } from "vuex"
import { CellClassParams, GetQuickFilterTextParams } from "@ag-grid-community/core";
const store = useStore()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  enableInputRow: {
    type: Boolean,
    default: true
  }
})

const autoGroupColumnDef: TableColumn = {
  flex: 1,
  minWidth: 450,
  headerName: i18n.t('Files'),
  sortable: true,
  sortProp: 'project',
  valueGetter: (params: any) => {
    if (params?.data?.type) {
      return [params.data.type, params.data.id].join('_')
    }
    
    return params?.node?.key
  },
  getQuickFilterText: (params: GetQuickFilterTextParams<any>) => {
    const fileOrFolder = params.data
    const project = get(fileOrFolder, 'relationships.project', '')

    return [fileOrFolder?.attributes?.name || '', project?.attributes?.name || ''].join(' ')
  },
  comparator(valueA, valueB, nodeA, nodeB) {
    const nodeA_file = nodeA.allLeafChildren[nodeA.allLeafChildren.length - 1]?.data
    const nodeB_file = nodeB.allLeafChildren[nodeB.allLeafChildren.length - 1]?.data

    const projectA = get(nodeA_file, 'relationships.project.attributes.name', '')
    const projectB = get(nodeB_file, 'relationships.project.attributes.name', '')

    return projectA.localeCompare(projectB);
  },
  cellRendererParams: {
    suppressCount: true,
    innerRenderer: 'FileNodePreviewCell'
  },
  cellClass: 'tree-cell flex items-center',
  cellClassRules: {
    'hover-over': (params: CellClassParams) => {
      return params.node?.key === FileDraggingUtils.potentialParent.value?.key;
    },
  }
}

const defaultInputRowModel = computed(() => {
  return {
    id: -1,
    type: 'dropzone',
    attributes: {
      name: i18n.t('Drop files here to upload...')
    }
  }
})

const inInsideProject = computed(() => {
  return store.getters.project_id
})

function getDataPath(fileOrFolder: any) {
  if (inInsideProject.value) {
    return fileOrFolder.path.slice(1)
  }

  return fileOrFolder.path
}

const tableColumns = computed(() => {
  return store.getters['files/tableColumns'] || []
})

const meta = computed(() => {
  return store.state.files.files.meta || {}
})
</script>
