<template>
  <section
    v-if="orderedProjectTools?.length"
    id="project-dashboard-tools"
    aria-labelledby="quick-links-title"
    @click="$emit('disable-remove-tools')"
  >
    <draggable
      v-model="orderedProjectTools"
      :animation="200"
      class="w-full grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-4"
      :class="{
        'xl:grid-cols-4': $useNewLayout
      }"
      ghost-class="ghost-card"
      handle=".fa-grip-dots"
      group="tools"
      item-key="pivots.id"
      tag="div"
      :disabled="!can($actions.EDIT_PROJECT_TOOLS)"
    >
      <template #item=" { element }">
        <ProjectDashboardCardNew
          v-if="$useNewLayout"
          :tool="element"
          :isRemoveToolsEnabled="isRemoveToolsEnabled"
        />
        <ProjectDashboardCard
          v-else
          :tool="element"
          :isRemoveToolsEnabled="isRemoveToolsEnabled"
        />
      </template>
    </draggable>
  </section>
</template>
<script>
// Libs
import draggable from 'vuedraggable/src/vuedraggable'
import {
  toolsEnum,
  isFileProofingEnabled,
} from '@/modules/projects/utils/toolUtils.js'
import { cloneDeep } from 'lodash-es'
// Components
import ProjectDashboardCard from '@/modules/projects/components/ProjectDashboardCard.vue'
import ProjectDashboardCardNew from '@/modules/projects/components/ProjectDashboardCardNew.vue'

export default {
  components: {
    ProjectDashboardCard,
    ProjectDashboardCardNew,
    draggable,
  },
  props: {
    isRemoveToolsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    projectId() {
      return this.$route.params.id
    },
    canViewTimeEntries() {
      return this.can(this.$actions.VIEW_TIME_ENTRIES)
    },
    project() {
      return this.$store.state.projects.currentProject
    },
    notes() {
      return this.project?.relationships?.notes || []
    },
    embeds() {
      return this.project?.relationships?.embeds || []
    },
    links() {
      return this.project?.relationships?.links || []
    },
    references() {
      return this.project?.relationships?.references || []
    },
    resourceTypes() {
      return {
        [toolsEnum.EMBEDS]: this.embeds,
        [toolsEnum.NOTE]: this.notes,
        [toolsEnum.REFERENCE]: this.references,
        [toolsEnum.LINK]: this.links,
      }
    },
    canViewByType() {
      return {
        [toolsEnum.PEOPLE]: false, // We hide the people tool now because users can perform actions from "Add/remove groups & people"
        [toolsEnum.TIME]: this.canViewTimeEntries,
        [toolsEnum.FILE_PROOFING]: isFileProofingEnabled()
      }
    },
    orderedProjectTools: {
      get() {
        // TODO: Temp workaround to remove "Notes" (list tool) duplicates
        let orderedTools = cloneDeep(this.$store.getters['projects/orderedProjectTools']);

        const noteListTools = orderedTools.filter(t => t.attributes?.name === toolsEnum.NOTES)

        if (noteListTools.length > 1) {
          const firstPivotId = noteListTools[0].pivots?.id
          // Remove "Notes" (list tool) duplicates
          orderedTools = orderedTools.filter(t => t.attributes?.name !== toolsEnum.NOTES || t.pivots?.id === firstPivotId)
        }

        return orderedTools.filter(t => {
          t.resource = this.getResourceById(t)
          const name = t.attributes?.name

          if (name === toolsEnum.REFERENCE) {
            return true
          }

          let canViewTool = true
          if (this.canViewByType.hasOwnProperty(name)) {
            canViewTool = this.canViewByType[name]
          }

          if (!canViewTool) {
            return false
          }

          if (t.resource) {
            return t.resource?.attributes?.pinned || false
          }

          return true
        })
      },
      async set(value) {
        await this.$store.dispatch('projects/reorderProjectTools', {
          projectId: this.projectId,
          tools: value,
        })
      },
    },
  },
  watch: {
    'orderedProjectTools.length': {
      immediate: true,
      handler(value) {
        this.$emit('visible-tools-count-changed', value)
      },
    },
  },
  methods: {
    getResourceById(tool) {
      const resources = this.resourceTypes?.[tool?.attributes?.name]
      if(!resources) return null
      return resources?.find(r => String(r.id) === String(tool?.pivots?.options?.entity_id)) || null
    },
  }
}
</script>
