<template>
  <div class="sm:max-w-xs max-w-full m-auto rounded-lg bg-white border-gray-200 border shadow">
    <div class="bg-white rounded-lg p-6">
      <div class="grid space-y-2">

        <div class="shrink-0 flex justify-center">
          <BaseLogo
            :entity="group"
            size="xl"
            class="rounded-md"
          />
        </div>

        <div class="">
          <p class="text-center text-xl font-bold text-gray-900 sm:text-2xl">
            {{ group?.attributes?.name }}
          </p>
        </div>

        <BaseDropdown
          :options="groupOptions"
          :label="$t('Options')"
          trigger="hover"
          class="m-auto"
          button-size="md"
          divide-items
        />

      </div>
    </div>
  </div>
  <GroupUsers
    v-loading="groupLoading"
    :group="group"
    class="mt-6"
  />
</template>
<script>
import GroupUsers from "@/modules/groups/components/GroupUsers.vue";
import { encodeFilters } from "@/modules/common/utils/filterUtils.js";
import { getGroupFilter } from "@/modules/groups/utils/filterUtils.js";

export default {
  components: {
    GroupUsers,
  },
  computed: {
    group() {
      return this.$store.state.groups.currentGroup || {}
    },
    groupOptions() {
      return [
        {
          label: this.$t('Edit Group'),
          enabled: () => this.can(this.$actions.EDIT_ALL_GROUPS) || this.can(this.$actions.EDIT_THEIR_OWN_GROUP),
          path: `/groups/${this.groupId}/edit`
        },
        {
          label: this.$t('Invite People'),
          path: this.groupInviteLink
        },
        {
          label: this.$t('View Projects'),
          path: this.getGroupPathFilter('/projects/list'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: this.$t('View Tasks'),
          path: this.getGroupPathFilter('/tasks/list'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: this.$t('View Payments'),
          path: this.getGroupPathFilter('/payments/list'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: this.$t('View Time'),
          path: this.getGroupPathFilter('/time/all'),
          requiresPermissionTo: this.$actions.EDIT_ALL_GROUPS
        },
        {
          label: this.$t('Delete Group'),
          variant: 'danger',
          enabled: () => {
            return this.can(this.$actions.DELETE_GROUPS)
          },
          disabled: this.hasUsers,
          tooltip: this.hasUsers
            ? this.$t('Cannot delete group with users')
            : null,
          action: this.deleteGroup
        }

      ]
    },
    groupLoading() {
      return this.$store.state.groups.currentGroupLoading
    },
    groupId() {
      return this.$route.params.id
    },
    hasUsers() {
      const usersCount = this.group?.attributes?.users_count || this.group?.relationships?.users?.length || 0
      return usersCount > 0
    },
    groupInviteLink() {
      return `/users/add?group_id=${this.group.id}&group_name=${this.group?.attributes?.name}`
    },
    groupPaymentsFilter() {
      return [
        {
          key: "filters",
          value: [
            {
              name: this.$t('Group'),
              column: "group_ids",
              query: [ this.groupId ],
              displayValue: this.group?.attributes?.name
            }
          ]
        },
      ]
    },
    groupTimeFilter() {
      return [
        {
          key: "filters",
          value: [
            {
              name: this.$t('Group'),
              column: "group_ids",
              query: [this.groupId],
              displayValue: this.group?.attributes?.name
            }
          ]
        },
      ]
    }
  },
  methods: {
    getGroupPathFilter(path) {
      return {
        path,
        query: {
          filters: this.getGroupFilter(this.group)
        }
      }
    },
    getGroupFilter(group) {
      try {
        const filters = getGroupFilter(group)
        return encodeFilters(filters)
      } catch (err) {
        return ""
      }
    },
    async deleteGroup() {
      if (this.hasUsers) {
        this.$error('Groups that have users cannot be deleted')
        return
      }
      const confirmed = await this.$deleteConfirm({
        title: this.$t("Delete Group"),
        description: this.$t("Are you sure? This will permanently delete the group"),
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch("groups/deleteGroup", this.group.id);
        this.$router.push('/groups/list')
      } catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(this.$t("Cannot delete the group"));
        throw err;
      }
    }
  }
}
</script>
