<template>
  <div
    class="max-w-3xl border border-gray-200 m-auto align-middle overflow-hidden rounded-lg group-people"
    :class="{
      'bg-white shadow mt-6': !$useNewLayout,
      'bg-gray-50': $useNewLayout,
    }"
  >
    <div>
      <div class="px-6 py-4 mb-6 border-b border-gray-200">
        <div>
          <label class="block text-sm font-medium text-gray-700 sr-only">
            {{ $t('Add people') }}
          </label>
          <UserSelect
            v-model="model.user_id"
            :placeholder="$t('Add People')"
            :row-filter="filterUsers"
            rules="required"
            class="mt-2"
            allow-entity-create
            :addEntityLabel="$t('Invite New People')"
            :entityCreatedCallback="onNewUsersInvited"
            :focusOnEntityDialogClosed="false"
            :addEntityParams="{
              defaultValues: {
                group_id: group.id,
                group
              }
            }"
            @change="addUserToGroup"
          />
        </div>
      </div>
      <div class="flow-root mt-6">
        <ul class="-mt-6 divide-y divide-gray-200">
          <li v-for="user in groupUsers"
              :key="user.id"
              class="py-4 px-6">
            <div class="flex items-center space-x-4">
              <div class="shrink-0">
                <BaseLogo :entity="user" logo-key="attributes.avatar"/>
              </div>
              <div class="flex-1 min-w-0">
                <p class="font-bold text-gray-700 truncate">
                  {{ user?.attributes?.name || user?.attributes?.email }}
                </p>
                <p class="text-sm text-gray-500 truncate">
                  <span class="font-bold text-gray-700 mr-1">{{ $t('Role:') }}</span>
                  {{ user?.attributes?.role?.join(',') || '- -' }}
                </p>
              </div>
              <div class="relative lg:inline-block text-left">
                <BaseDropdown
                  :options="getUserOptions(user)"
                  :label="$t('Options')"
                  trigger="hover"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import UserSelect from "@/components/selects/UserSelect.vue";

export default {
  name: 'GroupUsers',
  components: {
    UserSelect,
  },
  props: {
    group: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      model: {
        user_id: null,
      }
    }
  },
  computed: {
    groupUsers() {
      return this.group?.relationships?.users || []
    },
    groupUserIds() {
      return this.groupUsers?.map(u => +u.id) || []
    }
  },
  methods: {
    getUserOptions(user) {
      return [
        {
          label: this.$t('Remove from group'),
          action: () => this.removeUserFromGroup(user)
        }
      ]
    },
    userBelongsToCurrentGroup(user) {
      return this.groupUserIds.includes(+user?.id)
    },
    filterUsers(user) {
      return !this.userBelongsToCurrentGroup(user)
    },
    async removeUserFromGroup(user) {
      await this.$store.dispatch('groups/removeUsersFromGroup', {
        groupId: this.group?.id,
        users: [user?.id]
      })
    },
    async addUserToGroup(userId) {
      await this.$store.dispatch('groups/addUsersToGroup', {
        groupId: this.group?.id,
        users: [userId]
      })
      this.model.user_id = null
      this.$success(this.$t('User added to group!'))
      this.$store.dispatch('groups/getGroupById', { id: this.$route.params.id })
    },
    async onNewUsersInvited() {
      const oldUsersCount = this.groupUserIds.length

      await this.$store.dispatch('groups/getGroupById', { id: this.$route.params.id })
      await this.$nextTick()

      const newUsersCount = this.groupUserIds.length

      return {
        shouldFocusInput: newUsersCount === oldUsersCount
      }
    },
  }
}
</script>
