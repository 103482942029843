import { useWebWorkerFn } from '@vueuse/core'

function computeGroupUsersMap(
  bindingArray: any[],
  allUsers: any[],
) {
  const map: {[key: string | number]: {
    user_ids: (string | number)[],
    users: any[]
  }} = {}

  bindingArray.forEach(b => {
    const user = allUsers.find(u => u.id == b.user_id)
    if (!user) {
      console.warn('User not found for group binding', b)
      return
    }

    if (!map[b.group_id]) {
      map[b.group_id] = {
        user_ids: [],
        users: []
      }
    }

    map[b.group_id].user_ids.push(b.user_id)
    map[b.group_id].users.push(user)
  })

  return map
}

export const { workerFn: groupUsersMapWorker } = useWebWorkerFn(computeGroupUsersMap)

function computeGroupProjectsMap(
  bindingArray: any[],
  allProjects: any[],
) {
  const map: {[key: string | number]: {
    project_ids: (string | number)[],
    projects: any[]
  }} = {}

  bindingArray.forEach(b => {
    const project = allProjects.find(p => p.id == b.project_id)
    if (!project) {
      console.warn('Project not found for group binding', b)
      return
    }

    if (!map[b.group_id]) {
      map[b.group_id] = {
        project_ids: [],
        projects: []
      }
    }

    map[b.group_id].project_ids.push(b.project_id)
    map[b.group_id].projects.push(project)
  })

  return map
}

export const { workerFn: groupProjectsMapWorker } = useWebWorkerFn(computeGroupProjectsMap)
