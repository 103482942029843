<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-4xl m-auto rounded-lg bg-white border-gray-200 border mb-6 shadow"
    @close="$emit('close')"
  >
  
  <ProjectToolsForm
    :project="project"
    @cancel="$emit('close')"
    @close="$emit('close')"
  />
  </BaseDialogNew>
</template>
<script>
import ProjectToolsForm from "@/modules/projects/components/ProjectToolsForm.vue"

export default {
  name: 'ProjectToolsDialog',
  components: {
    ProjectToolsForm
  },
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject
    }
  }
}
</script>
