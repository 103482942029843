<template>
  <div>
    <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
      <div class="mb-4">
        <img
          class="m-auto mb-2 h-10 rounded"
          :src="logoSrc"
          alt="Project.co"
        >
        <BasePageTitle class="capitalize">
          {{ $t(`Join The ${accountName} Account`) }}
        </BasePageTitle>
      </div>

      <VeeForm v-slot="{ handleSubmit, errors, meta }">
        <form @submit.prevent="handleSubmit(onSubmit)"
              class="space-y-4"
        >
          <div class="sm:grid sm:gap-4 sm:items-start sm:grid-cols-4 align-middle">
            <label class="text-sm font-medium leading-5 text-gray-700 flex flex-wrap m-auto">
              {{ $t("Profile picture") }}
            </label>
            <BaseAvatarPicker
              ref="avatarPicker"
              class="w-full"
              label-classes="sm:col-span-3"
              v-model="model.avatar"
              :should-upload-image="false"
              :disabled-options="['group', 'emoji', 'unsplash', 'clearbit']"
            />
          </div>
          <BaseInput
            :modelValue="$route.query.email"
            disabled
            :label="$t('Email')"
            :name="$t('Email')"
            :placeholder="$t('Email')"
            layout="horizontal"
            rules="required"
            id="email"
          />
          <BaseInput
            v-model="model.first_name"
            :label="$t('First Name')"
            :name="$t('First Name')"
            :placeholder="$t('First Name')"
            layout="horizontal"
            rules="required"
            id="first_name"
          />
          <BaseInput
            v-model="model.last_name"
            :label="$t('Last Name')"
            :name="$t('Last Name')"
            :placeholder="$t('Last Name')"
            layout="horizontal"
            rules="required"
            id="last_name"
          />
          <BaseInput
            v-model="model.password"
            :label="$t('Password')"
            :name="$t('Password')"
            :placeholder="$t('Password')"
            layout="horizontal"
            rules="required|min:8|max:50"
            id="password"
            type="password"
          />
          <BaseInput
            v-model="model.password_confirmation"
            :label="$t('Confirm Password')"
            :name="$t('Confirm Password')"
            :placeholder="$t('Confirm Password')"
            layout="horizontal"
            :rules="`required|confirmed:@${$t('Password')}`"
            id="password_confirmation"
            type="password"
          />
          <div class="mt-6">
            <BaseButton
              block
              type="submit"
              :disabled="!meta.valid"
              :loading="loading"
            >
              {{ $t('Create Your Account') }}
            </BaseButton>
          </div>
        </form>
      </VeeForm>
    </div>
  </div>
</template>
<script>
import i18n from '@/i18n';

import { getFormData } from '@/modules/common/utils/formDataUtils';
import { cloneDeep } from 'lodash-es';

import AuthService from '@/modules/auth/services/AuthService.js';
import { logoDarkFull } from '@/modules/accounts/utils/accountBrandingUtils'

export default {
  data() {
    return {
      model: {
        email: '',
        token: '',
        first_name: '',
        last_name: '',
        password: null,
        password_confirmation: null,
        avatar: ''
      },
      loading: false,
    };
  },
  computed: {
    name() {
      return `${this.model.first_name} ${this.model.last_name}`
    },
    tenantName() {
      return this.$store.getters['auth/tenantName']
    },
    accountName() {
      return this.$settings('account_name') || this.tenantName
    },
    customLogo() {
      return this.$settings('login_page_logo')
    },
    logoSrc() {
      return this.customLogo || logoDarkFull.value
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true

        const data = cloneDeep({
          ...this.model,
          ...this.$route.query,
        })

        const formData = getFormData(data, [
          'email',
          'first_name',
          'last_name',
          'password',
          'password_confirmation'
        ])
        const file = this.$refs?.avatarPicker?.fileUploadModel
        if (file) {
          formData.append('avatar', this.$refs?.avatarPicker?.fileUploadModel)
        }
        await AuthService.confirmInvitation(data.token, formData)
        await this.$store.dispatch('auth/login', {
          email: data.email,
          password: this.model.password,
        })
        await this.$router.push('/welcome')
        this.$success(i18n.t('Registration was successful'))
      } catch (e) {
        if (e.handled) {
          return
        }
        this.$error(i18n.t('Could not confirm your registration. Please try again or contact us.'))
      } finally {
        this.loading = false
      }
    },
  },
};
</script>
