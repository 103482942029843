<template>
   <BaseEntityForm
    :title="payment?.id ? $t('Edit Payment Request') : $t('New Payment Request')"
    :subtitle="payment?.id ? '' : $t('Create a payment request to take a card payment from anyone who has access to the project. Once a payment has been made an invoice will be generated. Add a group (optional) so that the group details show on the invoice as well as the account details.')"
    width-class="max-w-3xl"
    @submit="onSubmit"
  >
    <template #default="{ errors, meta }">
      <ButtonGroups
        v-if="!payment?.id"
        :active-option="paymentType"
        @change="paymentType = $event"
        :options="paymentTypeOptions"
      />
      <WarningAlert
        v-if="!isPaypalEnabled && !isStripeEnabled"
        :dismissable="false"
        class="border-yellow-700 border max-w-4xl m-auto mb-4"
      >
        {{ $t('No payment methods configured. Please configure') }}

        <router-link
          to="/integrations/stripe-setup"
          class="font-medium underline text-yellow-700 hover:text-yellow-600"
        >
          {{ $t('Stripe') }}
        </router-link>

        {{ $t(' or ') }}

        <router-link
          to="/integrations/paypal"
          class="font-medium underline text-yellow-700 hover:text-yellow-600"
        >
          {{ $t('Paypal') }}
        </router-link>

        {{ $t(' to accept online payments.') }}
      </WarningAlert>

      <BaseInput
        v-focus
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Enter payment description...')"
        :name="$t('Description')"
        rules="required"
        layout="horizontal"
        id="description"
      />

      <BaseInput
        v-if="!$store.getters.project_id"
        :modelValue="model.project_id"
        :label="$t('Project')"
        :name="$t('Project')"
        rules="required"
        layout="horizontal"
        id="role"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <ProjectSelect
            v-model="model.project_id"
            :initial-value="model.project"
            :placeholder="$t('Choose a project for this payment')"
            @change="onProjectChange"
          />
        </div>
      </BaseInput>

      <BaseInput
        :modelValue="model.group_id"
        :label="$t('Group')"
        :name="$t('Group')"
        :info-text="$t('Specifying a group will add the groups invoice details to the invoice once the payment has been successfully completed')"
        layout="horizontal"
        id="role"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <GroupSelect
            v-model="model.group_id"
            :disabled="!model.project_id"
            :initial-value="model.group"
            :placeholder="$t('Choose a group for this payment')"
            :urlParams="{
              project_id: model.project_id,
            }"
            allow-entity-create
            addEntityTrigger="projects/triggerProjectPeople"
            :addEntityParams="{ projectId: model.project_id }"
            :addEntityLabel="$t('Add groups to project')"
            createInfoText=""
          />
        </div>
      </BaseInput>

      <BaseInput
        v-if="paymentType === PaymentTypes.Recurring"
        :label="$t('Stripe Product')"
        :name="$t('Stripe Product')"
        :info-text="$t('The Stripe product that will be used to create a recurring payment. If you want to create a recurring payment, you must first create a product in Stripe.')"
        rules="required"
        layout="horizontal"
        id="strioe_price"
      >
        <StripeProductSelect
          ref="stripeProductSelect"
          v-model="model.stripe_price"
          @raw-change="onStripePriceChange"
        />
        <div class="mt-0.5 flex justify-between">
          <a
            :href="stripeCreateProductLink"
            target="_blank"
            rel="noopener"
            class="text-xs font-medium text-primary-500"
          >
            <span>
              {{ $t('Click here to create a new product in your Stripe account') }}
            </span>
            <i class="fa-regular fa-arrow-up-right-from-square ml-1"></i>
          </a>
          <BaseTooltip :content="$t('Refresh the Stripe products list.')">
            <button type="button" class="cursor-pointer" @click="refreshStripeProducts">
              <i class="fa-solid fa-rotate-right text-sm text-primary-500"></i>
            </button>
          </BaseTooltip>
        </div>
      </BaseInput>

      <BaseInput
        v-if="paymentType === PaymentTypes.Recurring"
        :label="$t('Auto Tax')"
        :placeholder="$t('Auto Tax')"
        :name="$t('Auto Tax')"
        :info-text="$t('When this is ON Stripe will calculate the tax automatically based on your tax settings in Stripe. This requires tax to be configured in your Stripe account. When this is OFF no tax will be collected.')"
        class="col-span-6"
        layout="horizontal"
      >
        <BaseSwitch
          v-model="model.auto_tax"
          class="mt-2"
        />

        <template v-if="model.auto_tax && !hasConfiguredStripeTax">
          <WarningAlert
            :dismissable="false"
            class="border-yellow-700 border max-w-4xl m-auto mt-2"
          >
            {{ $t('Automatic tax has not been set up in your Stripe account. This needs to be setup so Stripe can calculate the tax for recurring payments.') }}
            <BaseTutorialLink
              name="stripe-tax-setup"
              class="underline"
            >
              {{  $t('Click here to get started.')  }}
            </BaseTutorialLink>
            {{ $t('Updated your settings in Stripe?') }}
            <a
              class="underline cursor-pointer"
              @click="checkStripeTaxConfigured"
            >
              {{ $t('Re-check status') }}
            </a>
            <DataSyncingIndicator
              v-if="checkingStripeTaxConfigured"
              class="inline-block ml-2"
            />
          </WarningAlert>
        </template>
      </BaseInput>

      <BaseInput
        v-if="paymentType === PaymentTypes.OneTime"
        :modelValue="model.currency"
        :label="$t('Currency')"
        :name="$t('Currency')"
        rules="required"
        layout="horizontal"
        id="role"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <CurrencySelect
            v-model="model.currency"
            :return-object="false"
            @raw-change="currencyRaw = $event"
          />
        </div>
      </BaseInput>

      <BaseInput
        v-if="paymentType === PaymentTypes.OneTime"
        v-model="model.amount"
        :label="$t('Amount')"
        :placeholder="$t('0.00')"
        :name="$t('Amount')"
        type="number"
        step="0.01"
        rules="required|min_value:0.01"
        layout="horizontal"
        class="-mt-1"
        id="amount"
      >
        <template #prefix>
          <div class="mt-1">{{ currencySign }}</div>
        </template>
        <template #suffix>
          <div class="uppercase mt-4">{{ model.currency }}</div>
        </template>
      </BaseInput>

      <BaseInput
        v-if="paymentType === PaymentTypes.OneTime"
        v-model="model.tax_rate"
        :label="$t('Tax Rate')"
        :placeholder="$t('0 %')"
        :name="$t('Tax Rate')"
        type="number"
        step="0.01"
        rules="required|min_value:0|max_value:100"
        layout="horizontal"
        id="tax-rate"
      >
        <template #prefix>
          <div class="mt-1">%</div>
        </template>
      </BaseInput>
      <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start mb-4">
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {{ $t('Total') }}
        </label>
        <div class="ml-2 sm:mt-px sm:pt-1 sm:col-span-3">
          <template v-if="model.amount === 1  && paymentType === PaymentTypes.Recurring">
            - -
          </template>
          <template v-else>
            {{ $formatPrice(total, { currencyCode: model.currency }) }}
          </template>
        </div>
      </div>

      <CustomFieldsEntityForm
        v-model="model.custom_fields"
        :col-span="3"
        :column-count="4"
        entity-type="payment"
      />

      <BaseInput
        :modelValue="model.users_to_notify"
        :label="$t('Notify People')"
        :name="$t('Notify People')"
        layout="horizontal"
        id="role"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <UserSelect
            v-model="model.users_to_notify"
            :initial-value="model.users"
            :key="model.project_id"
            :disabled="!model.project_id"
            :url-params="{
              project_id: model.project_id
            }"
            :placeholder="$t('Choose people to notify')"
            multiple
          />
        </div>
      </BaseInput>

      <AddGroupDialog
        v-show="showCreateGroupDialog"
        v-model="showCreateGroupDialog"
        key="group-create"
        @save="onGroupCreate"
      />
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$emit('cancel')"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        :loading="loading"
        :disabled="!meta.valid || !isValidPayment"
        type="submit"
        class="ml-2"
      >
        {{ $t('Save') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script lang="ts">
import { cloneDeep } from "lodash-es";
import AddGroupDialog from "@/modules/groups/components/AddGroupDialog.vue";
import GroupSelect from "@/components/selects/GroupSelect.vue";
import ProjectSelect from "@/components/selects/ProjectSelect.vue";
import CurrencySelect from "@/components/selects/CurrencySelect.vue";
import UserSelect from "@/components/selects/UserSelect.vue";
import { DEFAULT_CURRENCY } from "@/modules/payments/enum/paymentEnums.js";
import CustomFieldsEntityForm from '@/modules/common/components/CustomFieldsEntityForm.vue'
import { getCustomFieldValuesStringified } from '@/modules/accounts/utils/modelUtils.js'
import { getSetting } from "@/plugins/settingsPlugin";
import ButtonGroups from "@/components/common/ButtonGroups.vue";
import { paymentTypeOptions, PaymentTypes } from "@/modules/payments/utils/paymentTypes";
import { defineComponent } from "vue";
import StripeProductSelect from "@/modules/payments/components/StripeProductSelect.vue";
import Group = App.Domains.Groups.Models.Group;
import User = App.Domains.Users.Models.User;
import Project = App.Domains.Projects.Models.Project;
import Data = API.Data;
import { RECURRING_PAYMENTS } from "@/modules/common/enum/actionsEnum";
import { BetaFeatures, useAccountFeatures } from "@/modules/auth/composables/useAccountFeatures";

export default defineComponent({
  name: 'PaymentForm',
  components: {
    StripeProductSelect,
    ButtonGroups,
    CustomFieldsEntityForm,
    UserSelect,
    GroupSelect,
    ProjectSelect,
    CurrencySelect,
    AddGroupDialog,
  },
  props: {
    payment: {
      type: Object,
      default: () => ({})
    },
  },
  emits: ['save', 'cancel'],
  setup() {
    const { hasFeature } = useAccountFeatures()
    return {
      hasFeature,
    }
  },
  data() {
    return {
      BetaFeatures,
      showCreateGroupDialog: false,
      loading: false,
      paymentType: PaymentTypes.OneTime,
      PaymentTypes,
      paymentTypeOptions,
      stripeCreateProductLink: 'https://dashboard.stripe.com/products/create',
      model: {
        description: null,
        project_id: this.$store.getters.project_id || null,
        group_id: null as number | null | undefined,
        group: null as Group | null | undefined,
        project: null as Project | null | undefined,
        users: null as User[] | null | undefined,
        currency: getSetting('default_payment_currency') || DEFAULT_CURRENCY?.code,
        stripe_price: undefined,
        auto_tax: getSetting('default_payment_auto_tax') || false,
        amount: 1,
        tax_rate: 0,
        users_to_notify: [],
        custom_fields: {} as any,
      },
      currencyRaw: null as any,
      hasConfiguredStripeTax: true,
      checkingStripeTaxConfigured: false
    }
  },
  computed: {
    paypalPaymentDetails() {
      return this.$store.getters['accounts/paypalPaymentDetails']
    },
    isPaypalEnabled() {
      return this.paypalPaymentDetails?.attributes?.enabled
    },
    isStripeEnabled() {
      return this.$store.getters['accounts/getStripeKey']
    },
    currencySign() {
      return this.currencyRaw?.symbol
    },
    total() {
      if (!this.model.tax_rate) {
        return this.model.amount
      }
      const rateWithTax = (this.model.tax_rate / 100) + 1

      return this.model.amount * rateWithTax
    },
    isValidPayment() {
      if (this.paymentType === PaymentTypes.Recurring) {
        return !this.model.auto_tax || this.hasConfiguredStripeTax
      }

      return true
    }
  },
  methods: {
    RECURRING_PAYMENTS() {
      return RECURRING_PAYMENTS
    },
    getFormData() {
      let data = cloneDeep(this.model)
      data.custom_fields = getCustomFieldValuesStringified(data.custom_fields)

      if (!data.group_id) {
        data.group_id = null
      }
      data.currency = data.currency?.toLowerCase()
      delete data.project
      delete data.group
      delete data.users

      return data
    },
    async onSubmit() {
      try {
        this.loading = true
        const data = this.getFormData()
        if (!this.payment?.id) {
          await this.createPayment(data)
        } else {
          await this.editPayment(data)
        }
        this.$emit('save')
      } catch (err: any) {
        if (err.handled) {
          return
        }
        if (!this.payment?.id) {
          this.$error(this.$t('Could not create the payment'))
        } else {
          this.$error(this.$t('Could not update the payment'))
        }
      } finally {
        this.loading = false
      }
    },
    async createPayment(data: any) {
      await this.$store.dispatch('payments/createPayment', data)
      this.$success(this.$t('Payment created successfully!'))
    },
    async editPayment(data: any) {
      await this.$store.dispatch('payments/editPayment', data)
      this.$success(this.$t('Payment updated successfully!'))
    },
    onGroupCreate(group: Group) {
      this.model.group_id = group.id
      this.model.group = group
      this.showCreateGroupDialog = false
      this.$store.dispatch('users/getGroups')
    },
    markNotificationsRead() {
      if (!this.payment?.id) {
        return
      }
      this.$store.dispatch('notifications/markEntityNotificationsRead', {
        notifiable_target_type: 'payment',
        notifiable_target_id: this.payment.id,
      })
    },
    onProjectChange() {
      this.model.users_to_notify = []
      this.model.group_id = null
    },
    onStripePriceChange(priceObject: any) {
      if (!priceObject) {
        this.model.amount = 1
        return
      }
      this.model.amount = priceObject.amount / 100
      this.model.currency = priceObject.currency?.toUpperCase()
    },
    async refreshStripeProducts() {
      await this.$refs?.stripeProductSelect.refresh('')
    },
    async getPaymentDetails() {
      const hasPaymentDetails = this.$store.state.paymentDetails?.data?.length > 0
      if (!hasPaymentDetails) {
        await this.$store.dispatch('accounts/getPaymentDetails')
      }
    },
    async checkStripeTaxConfigured() {
      try {
        this.checkingStripeTaxConfigured = true
        this.hasConfiguredStripeTax = await this.$store.dispatch('accounts/checkStripeTaxConfigured')
      }
      catch(err) {
        this.hasConfiguredStripeTax = false
      }
      finally {
        this.checkingStripeTaxConfigured = false
      }
    }
  },
  watch: {
    payment: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.model = {
          ...this.model,
          ...value?.attributes,
          id: value?.id,
        }
        this.model.group = value?.relationships?.group
        this.model.project = value?.relationships?.project
        this.model.users = value?.attributes?.users_to_notify.map((x: Data<User>) => ({
          id: x.id.toString(),
          attributes: {
            ...x
          }
        }))
        this.model.users_to_notify = value?.attributes?.users_to_notify?.map((x: Data<User>) => x.id.toString()) || []
        this.model.currency = this.model.currency?.toUpperCase()
        if (value?.attributes?.is_recurring) {
          this.paymentType = PaymentTypes.Recurring
        }
      }
    },
  },
  async mounted() {
    this.markNotificationsRead()
    await this.getPaymentDetails()
    await this.checkStripeTaxConfigured()
  },
})
</script>
<style lang="scss">
.payment-form {
  #amount input, #tax-rate input {
    @apply pl-10;
  }

  #amount .input-suffix {
    @apply right-5;
  }
}
</style>
