import i18n from "@/i18n"
import tippy from 'tippy.js'
import { VueRenderer } from '@tiptap/vue-3'
import { Editor, Range } from '@tiptap/core'
import CommandsList from './extensions/components/CommandsList.vue'
import { getImgSize } from "@/modules/common/utils/imageUtils.js";
import { error, success } from '@/components/common/NotificationPlugin/index.js'
import store from "@/store/index.js";
import { uploadDroppedFiles } from "@/components/html/util/uploadUtils.js";
import { Commands, getTextFromRange, hasExtension } from "@/components/html/util/tipTapUtils.js";
import { entityTypes } from "@/modules/common/enum/entityTypes.js";
import { toolsEnum } from "@/modules/projects/utils/toolUtils.js";
import { sleep } from "@/modules/common/utils/commonUtils"
import { BlockCategories, SuggestionItem } from "@/components/html/types/suggestionTypes";
import { SelectionRange } from "prosemirror-state"
import {
  useAccountLimits,
} from "@/modules/auth/composables/useAccountLimits";

import useCan, { AccountPermissions } from "@/modules/common/composables/useCan"
import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";
import { notesMainPath } from "@/modules/projects/utils/noteUtils";
import router from "@/router";

const { can, actions } = useCan()

function canCreateReferences() {
  return can(actions.CREATE_NOTE_REFERENCES)
}

function canCreateTasks() {
  return can(actions.CREATE_TASKS)
}


function prepareEditor(editor: Editor, range: Range) {
  return editor
    .chain()
    .focus()
    .deleteRange(range)
}

let popup: any = []

function triggerTaskCreation({ editor, range, inline = false }: { editor: Editor, range: Range, inline?: boolean }) {
  popup[0]?.hide()

  const selectedText = getTextFromRange(editor, range)
  editor.commands.deleteSelection()
  let editorInstance = prepareEditor(editor, range)

  let task = {
    attributes: {
      name: selectedText,
    },
  }

  store.dispatch('triggerEntityCreate', {
    entityType: entityTypes.Task,
    entityCreateParams: {
      task,
      redirectAfterSave: false,
    },
    events: {
      closed() {
        editorInstance.run()
      },
      async created(task: any) {
        editorInstance
          .setTask({
            id: task.id,
            name: task.attributes?.name,
            url: `/tasks/${task.id}`,
            description: task.attributes?.notes,
            inline,
          })
          .focus()
          .run()

        editorInstance.blur()

        // @ts-ignore
        const currentProject = store.state.projects.currentProject
        const isCurrentProject = task.attributes?.project_id?.toString() === currentProject?.id?.toString()
        const projectHasTasksTool = currentProject?.relationships?.tools?.find((t: any) => t.attributes?.name === toolsEnum.TASKS)

        if (!isCurrentProject || projectHasTasksTool) {
          return
        }

        await sleep(500)

        store.dispatch('projects/getProjectById', {
          id: task.attributes?.project_id,
          silent: true
        })
      }
    },
  })
}

export function triggerReferenceCreation({ editor, range = '' }: { editor: Editor, range?: Range | SelectionRange | string }) {
  if (!range) {
    range = editor?.state?.selection?.ranges[0]
  }
  popup[0]?.hide()

  const selectedText = getTextFromRange(editor, range)
  let editorInstance = prepareEditor(editor, range as Range)

  let reference = {
    name: selectedText,
  }

  store.dispatch('triggerEntityCreate', {
    entityType: entityTypes.Reference,
    entityCreateParams: { reference },
    events: {
      closed() {
        editorInstance.run()
      },
      async created(reference: any) {
        editorInstance
          .setReference(reference)
          .focus()
          .run()
      },
    },
  })
}

async function createNewNote({ editor, range }: { editor: Editor, range: Range }) {
  popup[0]?.hide()

  const selectedText = getTextFromRange(editor, range)
  // @ts-ignore
  const projectId = store.state.projects.currentProject?.id
  const titleLength = 50
  const name = selectedText.length > titleLength ? selectedText.substring(0, titleLength) + '...' : selectedText

  const note = await store.dispatch('projects/createNewProjectNote', {
    projectId,
    name,
    notes: `<h1>${name}</h1><p>${selectedText}</p>`
  })
  success(i18n.t('Note added successfully'))


  editor.commands.deleteSelection()
  let editorInstance = prepareEditor(editor, range)

  const url = `/${notesMainPath.value}/${projectId}/notes/${note?.id}`

  const reference = {
    project_id: projectId,
    type: 'note',
    name,
    url,
  }

  editorInstance
    .setReference(reference)
    .focus()
    .run()
}

const suggestion = {
  items: ({ query = '' } = {}): SuggestionItem[] => {
    const {
      hasReachedLimit,
      openUpgradeDialog,
    } = useAccountLimits()

    const aiTokensLimitReached = hasReachedLimit(AccountPermissions.AiAssistant)

    const isTemplatePath = store.getters['templates/isTemplatePath']
    let path = router.currentRoute?.value?.path || ''
    const isAddPath = path.includes('/add')

    function templateDisabledTooltip() {
      if (isTemplatePath) {
        return i18n.t('This item can’t be added to templates.')
      }
      return false
    }
    function addRestrictionTooltip() {
      if (isAddPath) {
        return i18n.t('You have to create the entity first before using this command.')
      }
      return false
    }
    const { isRoleGreaterOrEqual } = useCan()
    const items: SuggestionItem[] = [
      {
        title: i18n.t('AI Assistant'),
        description: i18n.t('Ask AI to write anything'),
        icon: 'fa-duotone fa-wand-magic-sparkles',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('ai-assistant', { level: 1 }),
        command: ({ editor, range }) => {
          if (aiTokensLimitReached) {
            prepareEditor(editor, range)
              .run()

            return openUpgradeDialog(AccountPermissions.AiAssistant)
          }

          prepareEditor(editor, range)
            .setAssistant({})
            .run()
        },
        needsPlanUpgrade: aiTokensLimitReached as boolean,
        needsRoleUpgrade: () => {
          if (!isRoleGreaterOrEqual(rolesEnum.CREATOR)) {
            return i18n.t('Upgrade to Creator to use AI Assistant')
          }

          return false
        },
      },
      {
        title: i18n.t('Heading 1'),
        description: i18n.t('Big section heading'),
        icon: 'fa-solid fa-h1',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('heading', { level: 1 }),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setNode('heading', { level: 1 })
            .run()
        },
      },
      {
        title: i18n.t('Heading 2'),
        description: i18n.t('Medium section heading'),
        icon: 'fa-solid fa-h2',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('heading', { level: 2 }),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setNode('heading', { level: 2 })
            .run()
        },
      },
      {
        title: i18n.t('Heading 3'),
        description: i18n.t('Small section heading'),
        icon: 'fa-solid fa-h3',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('heading', { level: 3 }),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setNode('heading', { level: 3 })
            .run()
        },
      },
      {
        title: i18n.t('Text'),
        description: i18n.t('A normal text paragraph'),
        icon: 'fa-solid fa-text',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('paragraph'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setNode('paragraph')
            .run()
        },
      },
      {
        title: i18n.t('Bulleted List'),
        description: i18n.t('A simple bulleted list'),
        icon: 'fa-solid fa-list-ul',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('bulletList'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .toggleBulletList()
            .run()
        },
      },
      {
        title: i18n.t('Numbered List'),
        description: i18n.t('A simple numbered list'),
        icon: 'fa-solid fa-list-ol',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('orderedList'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .toggleOrderedList()
            .run()
        },
      },
      {
        title: i18n.t('Task List'),
        description: i18n.t('A list with checkboxes'),
        icon: 'fa-solid fa-list-check',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('taskList'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .toggleTaskList()
            .run()
        },
      },
      {
        title: i18n.t('Toggle List'),
        description: i18n.t('A list with content inside'),
        icon: 'fa-solid fa-caret-right',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('details'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setDetails()
            .run()
        },
      },
      {
        title: i18n.t('Quote'),
        description: i18n.t('Capture a quote'),
        icon: 'fa-solid fa-quote-right',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('blockQuote'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setBlockquote()
            .run()
        },
      },
      {
        title: i18n.t('Alert'),
        description: i18n.t('A visual element to draw attention'),
        icon: 'fa-solid fa-triangle-exclamation',
        category: BlockCategories.Formatting,
        isActive: (editor) => editor.isActive('alert'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setAlert()
            .run()
        },
      },
      {
        title: i18n.t('Image'),
        description: i18n.t('Place an image in your note'),
        icon: 'fa-solid fa-image',
        isActive: (editor) => editor.isActive('image'),
        disableConvert: true,
        category: BlockCategories.Insert,
        command: async ({ editor, range, src }) => {
          const { width, height } = await getImgSize({ src })
          prepareEditor(editor, range)
            .setImage({ src, width, height })
            .run()
        },
        disabledTooltip: () => {
          const value = templateDisabledTooltip()
          if (value === false) {
            return addRestrictionTooltip()
          }
          return value
        },
      },
      {
        title: i18n.t('File'),
        description: i18n.t('Upload a file in your note'),
        icon: 'fa-solid fa-arrow-up-from-bracket',
        isActive: (editor) => editor.isActive('file'),
        disableConvert: true,
        category: BlockCategories.Insert,
        command: async ({ editor, range, event }) => {
          if (!event) {
            return
          }
          await uploadDroppedFiles({
            event,
            editor: editor,
            view: editor.view,
            fullScale: false,
          })
          prepareEditor(editor, range).run()
        },
        disabledTooltip: () => {
          const value = templateDisabledTooltip()
          if (value === false) {
            return addRestrictionTooltip()
          }
          return value
        },
      },
      {
        title: i18n.t('Dropbox'),
        description: i18n.t('Link to a file in your Dropbox account'),
        icon: 'fa-solid fa-brands fa-dropbox',
        isActive: (editor) => editor.isActive('externalFile'),
        disableConvert: true,
        category: BlockCategories.Insert,
        command: async ({ editor, range }) => {
          prepareEditor(editor, range).run()
        },
      },
      {
        title: i18n.t('Google Drive'),
        description: i18n.t('Link to a file in your Google Drive'),
        icon: 'fa-solid fa-brands fa-google-drive',
        isActive: (editor) => editor.isActive('externalFile'),
        disableConvert: true,
        category: BlockCategories.Insert,
        command: async ({ editor, range }) => {
          prepareEditor(editor, range).run()
        },
      },
      {
        title: i18n.t('Task Text'),
        description: i18n.t('Create an inline text task'),
        icon: 'fa-regular fa-badge-check',
        isActive: (editor) => editor.isActive('task'),
        category: BlockCategories.Insert,
        enabled: (editor) => {
          return canCreateTasks() && hasExtension(editor, 'task')
        },
        command: ({ editor, range }) => {
          triggerTaskCreation({
            editor,
            range,
            inline: true,
          })
        },
        disabledTooltip: templateDisabledTooltip
      },
      {
        title: i18n.t('Task Card'),
        description: i18n.t('Create a task with a card preview'),
        icon: 'fa-regular fa-box-check',
        isActive: (editor) => editor.isActive('task'),
        category: BlockCategories.Insert,
        enabled: (editor) => {
          return canCreateTasks() && hasExtension(editor, 'task')
        },
        command: ({ editor, range }) => {
          triggerTaskCreation({
            editor,
            range,
            inline: false,
          })
        },
        disabledTooltip: templateDisabledTooltip,
      },
      {
        title: i18n.t('Table'),
        description: i18n.t('Place a table in your note'),
        icon: 'fa-solid fa-table',
        isActive: (editor) => editor.isActive('table'),
        disableConvert: true,
        category: BlockCategories.Insert,
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .insertTable({
              withHeaderRow: false,
              cols: 2,
              rows: 3,
            })
            .run()
        },
      },
      {
        title: i18n.t('Columns'),
        description: i18n.t('Add two column content'),
        icon: 'fa-solid fa-columns-3',
        isActive: (editor) => editor.isActive('columns'),
        disableConvert: true,
        category: BlockCategories.Insert,
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setColumns()
            .focus(editor.state.selection.head - 1)
            .run()
        },
      },
      {
        title: i18n.t('Code'),
        description: i18n.t('Insert a code snippet'),
        icon: 'fa-regular fa-code',
        category: BlockCategories.Insert,
        isActive: (editor) => editor.isActive('codeBlock'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .setCodeBlock()
            .run()
        },
      },
      {
        title: i18n.t('Emoji'),
        description: i18n.t('Insert an emoji :)'),
        icon: 'fa-regular fa-face-smile',
        disableConvert: true,
        category: BlockCategories.Insert,
        isActive: (editor) => editor.isActive('emoji'),
        command: ({ editor, range, shortCode }) => {
          prepareEditor(editor, range)
            .setEmoji(shortCode)
            .run()
        },
      },
      {
        title: i18n.t('Link'),
        description: i18n.t('Link to an external page'),
        icon: 'fa-solid fa-link',
        category: BlockCategories.Link,
        disableConvert: true,
        isActive: (editor) => editor.isActive(Commands.Link),
        command: ({ editor, range, link }) => {
          prepareEditor(editor, range)
            .setLink({ href: link })
            .command(({ tr }) => {
              tr.insertText(link)
              return true
            })
            .run()
        },
      },
      {
        title: i18n.t('Mention'),
        description: i18n.t('Mention project people'),
        icon: 'fa-solid fa-at',
        disableConvert: true,
        category: BlockCategories.Link,
        enabled: (editor) => hasExtension(editor, 'task'),
        isActive: (editor) => editor.isActive('mention'),
        command: ({ editor, range }) => {
          prepareEditor(editor, range)
            .insertContent('@')
            .run()
        },
      },
      {
        id: entityTypes.Reference,
        title: i18n.t('Reference'),
        description: i18n.t('Link to another project'),
        icon: 'fa-regular fa-file-export',
        isActive: (editor) => editor.isActive(entityTypes.Reference),
        category: BlockCategories.Link,
        enabled: (editor) => canCreateReferences() && hasExtension(editor, entityTypes.Reference),
        command: ({ editor, range }) => {
          triggerReferenceCreation({ editor })
        },
      },
      {
        title: i18n.t('Note'),
        description: i18n.t('Create a link to a new note'),
        icon: 'fa-regular fa-note',
        isActive: (editor) => editor.isActive('note'),
        category: BlockCategories.Insert,
        disableInsert: true,
        enabled: (editor) => canCreateReferences() && hasExtension(editor, entityTypes.Reference),
        command: async ({ editor, range }) => {
          await createNewNote({ editor, range })
        },
      },
      {
        title: i18n.t('Embed'),
        description: i18n.t('Place an embed in your note'),
        icon: 'fa-solid fa-film',
        isActive: (editor) => editor.isActive('iframe'),
        disableConvert: true,
        category: BlockCategories.Embed,
        command: async ({ editor, range, src }) => {
          const result = await store.dispatch('resources/convertUrlToEmbed', { src })

          if (result.error) {
            error(result.error)
            return
          }
          prepareEditor(editor, range)
            .setIframe({
              html: result.html.replace('data-iframely-url', 'src'),
              small: result?.options?.card !== undefined,
            })
            .run()
        },
      },
    ]
    let lowerCaseQuery = query.toLowerCase()

    function includesQuery(title: string) {
      return title.toLowerCase().startsWith(lowerCaseQuery)
    }

    return items.filter(item => includesQuery(item.title))
  },

  render: () => {
    let component: VueRenderer;

    return {
      onStart: (props: Record<string, any>) => {
        component = new VueRenderer(CommandsList, {
          props,
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props: Record<string, any>) {
        component.updateProps(props)

        popup[0]?.setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props: Record<string, any>) {
        if (props.event.key === 'Escape') {
          popup[0]?.hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0]?.destroy()
        component?.destroy()
      },
    }
  },
}


export function isLinkActive(editor: Editor) {
  editor.isActive(Commands.Link.name)
}

export function getConvertOptions(editor: Editor) {
  return suggestion
    .items({ query: '' })
    .filter(item => {
      const isConvertEnabled = !item.disableConvert
      if (item.title === 'Embed') {
        return isLinkActive(editor)
      }
      if (item.enabled !== undefined) {
        return isConvertEnabled && item.enabled(editor)
      }
      return isConvertEnabled
    })
    .map(item => {
      return {
        ...item,
        label: item.title,
      }
    })
}

export default suggestion
