import { FilterTypes } from "@/components/table/tableUtils";

export class GenericFilter {
  property: string;
  comparisonType: FilterTypes;
  value: any;
  constructor(property: string, comparisonType: FilterTypes, value: any) {
    this.property = property;
    this.comparisonType = comparisonType;
    this.value = value;
  }

  matches(entity: Record<any, any>) {
    const entityValue = entity[this.property];
    switch (this.comparisonType) {
      case FilterTypes.Text:
        return this.filterText(entityValue)
      case FilterTypes.Numeric:
        return this.filterNumeric(entityValue)
      case FilterTypes.DateRange:
        return this.filterDate(entityValue);
      case FilterTypes.InArray:
        return this.filterInArray(entityValue);
      case FilterTypes.Boolean:
        return this.filterBoolean(entityValue)
      default:
        return false;
    }
  }

  filterBoolean(entityValue: boolean) {
    return Boolean(entityValue) === Boolean(this.value)
  }

  filterText(entityValue: string) {
    return entityValue?.toLowerCase()?.includes(this.value.toLowerCase())
  }

  filterNumeric(entityValue: number) {
    return Number(entityValue) === Number(this.value)
  }

  filterDate(entityValue: string) {
    const dateValue = Date.parse(entityValue)
    const startDate = Date.parse(this.value.min)
    const endDate = Date.parse(this.value.max)

    if (isNaN(startDate)) {
      return dateValue <= endDate
    }

    if (isNaN(endDate)) {
      return dateValue >= startDate
    }

    if (isNaN(dateValue)) {
      return false
    }

    if (isNaN(startDate) && isNaN(endDate)) {
      return true
    }

    return dateValue >= startDate && dateValue <= endDate
  }

  private filterInArray(entityValue: any[]) {
    return this.value.every((element: any) => {
      return entityValue?.includes(element);
    })
  }
}
