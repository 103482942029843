export function getPaymentTotal(payment) {
  let data = payment.attributes ? payment.attributes : payment
  if (!data.tax_rate) {
    return data.amount
  }
  const rateWithTax = (data.tax_rate / 100) + 1

  return data.amount * rateWithTax
}

export function parseInvoiceDetails(details) {
  let invoiceDetails = details
  let invoiceDetailsResult = ``
  try {
    invoiceDetails = JSON.parse(details)
    invoiceDetailsResult = `${invoiceDetails.companyNumber} ${invoiceDetails.vatNumber} \n`
    if (invoiceDetails.address) {
      invoiceDetails.address = JSON.parse(invoiceDetails.address)
      const { lineOne, lineTwo, country, city, postcode, county } = invoiceDetails.address
      invoiceDetailsResult+=`${lineOne} \n`  
      invoiceDetailsResult+=`${lineTwo} \n`
      invoiceDetailsResult+=`${city} ${postcode} \n`  
      invoiceDetailsResult+=`${county} ${country} \n`  
    }
    invoiceDetailsResult+=`${invoiceDetails.invoiceFooter}`
    
    return invoiceDetailsResult
  } catch (err) {
    return invoiceDetails
  }
}
