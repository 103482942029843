import { getFormData } from "@/modules/common/utils/formDataUtils.js";

export function getAttachUrl(file) {
  const targetEntityType =
    file.task_id || file.relationships?.task?.id
      ? 'task'
      : 'project';

  const entityTypePlural = `${targetEntityType}s`;
  const entityId = file[`${targetEntityType}_id`] || file.relationships?.[targetEntityType]?.id;
  const action = `attach-${targetEntityType}-file`;

  return `/restify/${entityTypePlural}/${entityId}/actions?action=${action}`;
}

export function getRemoveUrl(file) {
  const targetEntityType = file.attributes?.model_type;

  if (targetEntityType === 'task') {
    return `/restify/tasks/actions?action=remove-task-file`
  }

  return `/restify/projects/actions?action=remove-project-file`
}

export function getRemoveModel(file) {
  const targetEntityType = file.attributes?.model_type;

  const entityId = targetEntityType === 'task'
    ? file.attributes?.model_id
    : file.attributes?.project_id;

  if (targetEntityType === 'task') {
    return getFormData({
      files: [ file.id ],
      task_id: entityId
    }, ['files', 'task_id'])
  }

  return getFormData({
    files: [ file.id ],
    project_id: entityId
  }, ['files', 'project_id'])
}
