
import { useWebWorkerFn } from '@vueuse/core'

function computeRelationships(
  tasks: any [],
  allGroups: any[],
  allUsers: any[],
  allProjects: any[],
  allFiles: any[],
  userScopedOrders: { [key: string | number]: number},
  bindCompletedTasks: boolean = false,
  activeTasks: any[] = [],
  allTasksMapArray: any[],
) {

  let projectTasks: any[] = []
  let templateTasks: any[] = []
  const allTasksMap = new Map(allTasksMapArray)

  const getSubTaskIdsList = bindCompletedTasks
    ? [...tasks, ...activeTasks]
    : tasks
    

  tasks.forEach((task, i) => {
    const group_ids = task.attributes.group_ids || []
    const groups = allGroups.filter(group => group_ids.some((taskGroupId: any) => taskGroupId == group.id))
    const project = allProjects.find(p => p.id == task.attributes.project_id)

    const allocated_ids = task.attributes.allocated_ids || []
    const allocations = allUsers.filter(u => allocated_ids.some((allocated_id: any) => allocated_id == u.id))

    const media = allFiles.filter(file => file.attributes.task_id == task.id)
    const files_count = media.length

    const subtask_ids = getSubTaskIdsList.filter(t => t.attributes.parent_id == task.id).map(t => t.id)

    const subtasks_count = subtask_ids.length
    let global_order = userScopedOrders[task.id]

    if (!global_order) {
      global_order = (i + 1) + (bindCompletedTasks
        ? activeTasks.length
        : 0 )
    }
    
    const taskWithRelationships = {
      ...task,
      attributes: {
        ...task.attributes,
        global_order,
        files_count,
        subtask_ids,
        subtasks_count,
      },
      relationships: {
        ...(task.relationships || {}),
        groups,
        project,
        allocations,
        media,
      }
    }
    
    if (project?.attributes?.is_template) {
      templateTasks.push(taskWithRelationships)
    }
    else {
      projectTasks.push(taskWithRelationships)
    }

    allTasksMap.set(Number(task.id), taskWithRelationships)
  })
  
  return {
    projectTasks,
    templateTasks,
    allTasksMap
  }
}

function filterOutChildTasks(tasks: any[]): any[] {
  return tasks.filter(task => !task.attributes.parent_id)
}

function computeActiveTasksCompletedChildren(
  activeTasks: any[],
  completedTasks: any[],
) {
  const childrenToAdd = {} as any
  completedTasks.forEach(completedTask => {
    const parentTask = activeTasks.find(task => task.id == completedTask.attributes.parent_id)
    if (!parentTask) {
      return
    }

    if (!childrenToAdd[parentTask.id]) {
      childrenToAdd[parentTask.id] = []
    }

    if (childrenToAdd[parentTask.id].some((task: any) => task.id == completedTask.id)) {
      return
    }

    childrenToAdd[parentTask.id].push(completedTask.id)
  })

  return childrenToAdd
}

export const { workerFn: tasksWithRelationshipsWorker } = useWebWorkerFn(computeRelationships)
export const { workerFn: completedTasksWithRelationshipsWorker } = useWebWorkerFn(computeRelationships)
export const { workerFn: filterOutChildTasksWorker } = useWebWorkerFn(filterOutChildTasks)
export const { workerFn: computeActiveTasksCompletedChildrenWorker } = useWebWorkerFn(computeActiveTasksCompletedChildren)
