<template>
  <div
    v-if="isFreePlan"
    class="max-w-5xl m-auto pb-10"
  >
    <div
      class="w-full min-h-full relative p-8 bg-white border border-gray-200 overflow-hidden rounded-2xl shadow-sm flex flex-col m-auto">
      <div class="flex-1 flex flex-col h-full space-y-2">
        <h2 class="text-2xl font-bold text-gray-900">
          🗂️ {{ $t(`Try before you buy with the trial manager`) }}
        </h2>
        <p class="text-gray-500">
          {{ $t(`The trial manager gives you an easy way to start a 7-day trial of each feature you
            don't currently have access to in your account. Simply choose the feature you want to try and click the button
            to start a 7-day trial. You'll then have full access to that feature so you can see how it works before you
            buy.`)
          }}
        </p>
        <span class="flex-1 flex flex-col justify-end rounded-md block w-1/2">
          <span class="rounded-md block w-full shadow-sm relative button-wrapper">
            <router-link
              to="/account-settings/trial-manager"
            >
              <BaseButton
                class="py-3"
                block
              >
                <span>
                  {{ $t(`Go to the trial manager`) }}
                  <i class="fa-solid fa-arrow-right" aria-hidden="true" />
                </span>
              </BaseButton>
            </router-link>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  useAccountLimits
} from "@/modules/auth/composables/useAccountLimits";

const {
  isFreePlan,
} = useAccountLimits()
</script>
