<template>
  <ProjectsCalendar/>
</template>
<script>
import ProjectsCalendar from "@/modules/projects/components/ProjectsCalendar.vue";

export default {
  name: 'projects-calendar-page',
  components: {
    ProjectsCalendar,
  }
}
</script>
