<template>
  <div class="flex flex-col h-full">
    <TopFilters
      v-if="showFilters"
      :columns="userColumns"
      :available-filters="availableFilters"
      :searchPlaceholder="$t('Search users...')"
    >
      <template #action>
        <BaseDropdown
          :split-button="true"
          :options="inviteUserOptions"
          class="create-options-dropdown"
        >
          <router-link to="/users/add">
            <BaseButton
              block
            >
              {{ $t('Invite New') }}
            </BaseButton>
          </router-link>
        </BaseDropdown>
      </template>

    </TopFilters>

    <DataSyncingIndicator
      v-if="users.length && usersLoading"
      dataView
    />
    <router-view />
  </div>
</template>
<script>
import { importEntityTypes } from "@/modules/accounts/utils/importUtils";

import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue";
import TopFilters from "@/modules/filters/components/TopFilters.vue";

export default {
  components: {
    TopFilters,
    DataSyncingIndicator,
  },
  computed: {
    users() {
      return this.$store.state.users.users.data || []
    },
    userColumns() {
      return this.$store.getters['users/activeColumns']
    },
    usersLoading() {
      return this.$store.state.users.usersLoading
    },
    availableFilters() {
      if (this.$route.path === 'users/invitations') {
        return []
      }

      const filters = ['filter', 'sort', 'columns', 'group']

      if (this.can(this.$actions.VIEW_ARCHIVED_USERS) && (this.$isMobileDevice || this.$useNewLayout)) {
        filters.push('archivedUsers')
      }

      return filters
    },
    showFilters() {
      return !['Person Details', 'Invite People', 'View Person', 'Edit Person'].includes(this.$route.name)
    },
    inviteUserOptions() {
      return [
        {
          label: this.$t('Import People'),
          action: () => {
            this.$store.commit('setEntityCreateParams', {
              importType: importEntityTypes.People,
            })

            this.$store.commit('accounts/triggerDataImport')
          },
          enabled: this.can(this.$actions.MANAGE_ACCOUNT_SETTINGS)
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('users/getRoles')
  },
}
</script>
