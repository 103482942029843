<template>
  <EntitySelectNew
    v-bind="$attrs"
    ref="entitySelect"
    :placeholder="$t('Select projects...')"
    :options="options"
    :loading="loading"
  />
</template>
<script lang="ts" setup>
// Components
import EntitySelectNew from "@/components/form/EntitySelectNew.vue";

// Utils
import { computed, ref } from "vue";

import Data = API.Data;
import Project = App.Domains.Projects.Models.Project;

// Composables
import { useStore } from "vuex";
const store = useStore()

const props = defineProps({
  isTemplate: {
    type: Boolean,
    default: false
  },
})

const projects = computed<Data<Project>[]>(() => {
  return (store.state.projects.allProjects || []).filter((project: Data<Project>) => {
    return project.attributes.is_template == props.isTemplate
  })
})

const options = computed(() => {
  return projects.value
})

const loading = computed(() => {
  return store.state.projects.projectsLoading
})

const entitySelect = ref<any>(null)

defineExpose({
  setQuery: () => {
    entitySelect.value?.setQuery()
  }
})
</script>
