<template>
  <div
    v-loading="loading"
    class="wistia_responsive_padding relative"
    style="padding: 56.25% 0 0 0;"
    :style="{ height }"
  >
    <div class="wistia_responsive_wrapper absolute top-0 left-0 w-full h-full">
      <span
        class="wistia_embed popover=true videoFoam=true wistia_embed_initialized inline-block w-full h-full relative"
        :class="{
          [`wistia_async_${videoId}`]: videoId
        }"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';
import { initWistia } from "@/modules/common/utils/wistiaUtils";

const props = defineProps({
  videoId: {
    type: String,
    required: true
  },
  width: {
    type: String,
    default: `210px`
  },
  height: {
    type: Number,
    default: `120px`
  }
});

const loading = ref(true);

async function initWistiaPlayer() {
  await initWistia()
  loading.value = false;
}

onBeforeMount(() => {
  initWistiaPlayer()
});
</script>
