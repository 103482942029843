<template>
  <div class="task-related-tasks grid grid-cols-10 col-span-full gap-3 mt-1">
    <BaseTooltip
      :content="task?.id
        ? $t('Make this task a sub-task by assigning a parent task.')
        : $t('You can assign a parent task after creating the task')
      "
      class="col-span-full grid grid-cols-10"
    >
      <label
        class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4"
        :class="{
          'opacity-50': !task?.id
        }"
      >
        <span>
          <i class="fa-regular fa-diagram-subtask mr-2 w-4" />
          <span>{{ $t('Parent Task') }}</span>
        </span>
      </label>
      
      <div class="col-span-6">
        <!-- Select parent task -->
        <div
          v-if="!currentParentTask?.id"
          class="parent-task-select -mt-[5px]"
        >
          <TaskSelect
            v-model="model.parent_id"
            :url-params="{
              project_id: taskModel.project_id
            }"
            :excluded-options="excludedRelatedTaskOptions"
            :placeholder="$t('+ Set parent task')"
            :disabled="$attrs.disabled || !task?.id"
            @raw-change="onParentChange"
          />
        </div>
      </div>
    </BaseTooltip>

    <!-- Render parent task -->
    <div
      v-if="currentParentTask?.id"
      class="mb-1.5 flex items-center group w-full py-2 border-t border-b border-dashed border-gray-200 col-span-full -mt-2 relative"
    >
      <div
        class="flex items-center justify-between text-sm text-gray-500 hover:text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis w-full"
        :class="{
          'cursor-pointer': !showRelatedTaskDialog,
          'cursor-not-allowed': showRelatedTaskDialog
        }"
        @click="openRelatedTask(currentParentTask)"
      >
      
        <span>
          <i class="fa-regular fa-diagram-subtask mr-2" />
            {{ currentParentTask?.attributes?.name || currentParentTask?.name }}
        </span>
        <Status
          :row="currentParentTask"
          :params="{
            target: 'task'
          }"
        />
      </div>
      <div class="flex-1 flex justify-end absolute left-0 bg-gray-50">
        <XCircleIcon
          v-if="!$attrs.disabled"
          class="circle-remove hidden group-hover:flex h-4 w-4 cursor-pointer text-red-300 hover:text-red-500 bg-transparent z-40 overflow-visible"
          @click="onParentChange(null)"
        />
      </div>
    </div>

    <BaseTooltip
      :content="task?.id
        ? $t('Add sub-tasks for this task.')
        : $t('You can add sub-tasks after creating the task')
      "
      class="col-span-full grid grid-cols-10"
    >
      <label
        class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap items-center col-span-4"
        :class="{
          'opacity-50': !task?.id
        }"
      >
        <span>
          <i class="fa-regular fa-diagram-subtask mr-2 w-4" />
          <span>{{ $t('Sub-Tasks') }}</span>
        </span>
      </label>

      <div class="col-span-6">

        <!-- Add existing subTask or create a new one -->
        <div>
          <TaskSelect
            v-model="model.task"
            :key="subTasks.length"
            :urlParams="{
              parent_id: 'null',
              project_id: taskModel.project_id
            }"
            allow-entity-create
            addEntityTrigger="tasks/triggerAddTask"
            :excluded-options="excludedRelatedTaskOptions"
            :entityCreatedCallback="onSubTaskCreated"
            :addEntityLabel="$t('Create new sub task')"
            :addEntityParams="{
              isSubTask: true,
              task: subTaskModel
            }"
            :placeholder="$t('+ Add sub task')"
            :disabled="$attrs.disabled || !task?.id"
            class="sub-task-select"
            @raw-change="onSubTaskSelected"
          />
        </div>
      </div>

    </BaseTooltip>
    
    <!-- Render current sub tasks -->
    <div
      v-if="taskSubTasks.length > 0"
      class="space-y-2 py-2 border-t border-b border-dashed border-gray-200 col-span-full -mt-2 relative"
    >
      <div
        v-for="task in taskSubTasks"
        :key="task.id"
        class="flex items-center group w-full"
      >
        <div
          class="flex items-center justify-between text-sm text-gray-500 hover:text-gray-700 w-full"
          :class="{
            'cursor-pointer': !showRelatedTaskDialog,
            'cursor-not-allowed': showRelatedTaskDialog
          }"
          @click="openRelatedTask(task)"
        >
          <span>
            <i class="far fa-diagram-subtask mr-2" />
            {{ task?.attributes?.name || task?.name }}
          </span>
          <Status
            :row="task"
            :params="{
              target: 'task'
            }"
          />
        </div>
        <div class="flex-1 flex justify-end absolute left-0 bg-gray-50">
          <XCircleIcon
            v-if="!$attrs.disabled"
            class="circle-remove hidden group-hover:flex h-4 w-4 cursor-pointer text-red-300 hover:text-red-500 bg-transparent z-40 overflow-visible"
            @click="removeSubTask(task)"
          />
        </div>
      </div>
    </div>
    <TaskDialog
      v-if="showRelatedTaskDialog"
      v-model="showRelatedTaskDialog"
      key="related-task-dialog"
      :taskId="currentRelatedTaskId"
      @close="showRelatedTaskDialog = false"
      @save="onRelatedTaskEdited"
    />
  </div>
</template>
<script setup>
import { inject } from "vue"
const triggerSave = inject('triggerSave', null)
</script>
<script>
// Components
import TaskSelect from "@/components/selects/TaskSelect.vue";
import { XCircleIcon } from '@heroicons/vue/outline'
import Status from "@/components/table/Status.vue";

// Utils
import { capitalize } from "lodash-es";

export default {
  components: {
    TaskSelect,
    XCircleIcon,
    Status
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    taskModel: {
      type: Object,
      default: () => ({})
    },
    parent: {
      type: [String, Number],
    },
    addSubTasks: {
      type: Array,
      default: () => []
    },
    deleteSubTasks: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      model: {
        task: null,
        parent_id: this.parent,
      },
      parentTask: null,
      showRelatedTaskDialog: false,
      currentRelatedTaskId: null,
      taskSubTasks: this.subTasks || []
    }
  },
  computed: {
    subTasks() {
      return this.task?.relationships?.tasks || []
    },
    currentParentTask() {
      return this.parentTask || {}
    },
    subTaskModel() {
      return {
        attributes: {
          parent_id: this.task?.id,
          project_id: this.task?.attributes?.project_id,
          visibility: this.task?.attributes?.visibility
        },
        relationships: {
          parentTask: this.task,
          project: this.task?.relationships?.project
        }
      }
    },
    excludedRelatedTaskOptions() {
      const relatedTaskIds = [...this.subTasks.map(x => x.id), this.task?.id]
      
      if (this.parentTask) {
        relatedTaskIds.push(this.parentTask.id)
      }

      return relatedTaskIds
    },
    sortedSubTasks() {
      return [...this.taskSubTasks].sort((x, y) => x.id - y.id)
    },
    taskSyncTriggers() {
      return this.$store.state.tasks.taskSyncTriggers
    }
  },
  watch: {
    task: {
      immediate: true,
      handler() {
        this.syncParentTask()
      }
    },
    subTasks: {
      immediate: true,
      handler(value) {
        this.taskSubTasks = value
      }
    },
    taskSyncTriggers: {
      deep: true,
      handler(value) {
        for (let taskId in value) {
          this.trySyncParentTask(taskId, value[taskId])
          this.trySyncChildren(taskId, value[taskId])
        }
      }
    }
  },
  methods: {
    trySyncParentTask(taskId, event) {
      if (this.parentTask?.id != taskId) {
        return
      }

      this.parentTask = {
        ...this.parentTask,
        ...event.task,
        attributes: {
          ...(this.parentTask?.attributes || {}),
          ...(event.task?.attributes || {})
        },
        relationships: {
          ...(this.parentTask?.relationships || {}),
          ...(event.task?.relationships || {})
        }
      }
    },
    trySyncChildren(taskId, event) {
      const subTaskIndex = this.taskSubTasks.findIndex(subTask => subTask.id == taskId)
      if (subTaskIndex === -1) {
        return
      }

      this.taskSubTasks[subTaskIndex] = {
        ...this.taskSubTasks[subTaskIndex],
        ...event.task,
        attributes: {
          ...(this.taskSubTasks[subTaskIndex]?.attributes || {}),
          ...(event.task?.attributes || {})
        },
        relationships: {
          ...(this.taskSubTasks[subTaskIndex]?.relationships || {}),
          ...(event.task?.relationships || {})
        }
      }
    },
    async checkSubtaskPrivacyMatch(subTask) {
      const subtaskPrivacy = subTask.attributes.visibility
      const parentPrivacy = this.taskModel.visibility
      if (subtaskPrivacy == parentPrivacy) {
        return true
      }

      const confirmed = await this.$confirm({
        title: this.$t(`Warning`),
        description: this.$tc(`selected subtask has different privacy`, {
          privacy: capitalize(subtaskPrivacy),
          parentPrivacy: capitalize(this.taskModel.visibility)
        }),
        buttonText: this.$t(`Yes, change sub task privacy`)
      })

      if (!confirmed) {
        return false
      }

      await this.$store.dispatch('tasks/updateTask', {
        taskId: subTask.id,
        model: {
          visibility: parentPrivacy
        }
      })

      return true
    },
    async onParentChange(parent) {
      
      const previousParent = this.parentTask
      const newParent = parent

      const parentId = parent?.id
      this.parentTask = parent
      const currentVisibility = this.taskModel.visibility

      if (parent && currentVisibility != parent?.attributes?.visibility) {
        this.model.parent_id = null
        this.parentTask = null
        this.$error(this.$t('The privacy of the 2 tasks must be the same to make one a sub-task.'));
        return
      }

      const value = parentId || null
      this.model.parent_id = value
      if (!parentId) {
        this.parentTask = null
      }
      this.$emit('update:parent', value)
      this.$emit('on-parent-changed', {
        previousParentId: previousParent?.id,
        newParentId: newParent?.id
      })

      this.triggerSave?.()
    },
    async onSubTaskSelected(subTask) {
      if (!subTask) {
        this.model.task = null
        return;
      }
      
      const canAddSubtask = await this.checkSubtaskPrivacyMatch(subTask)
      if (!canAddSubtask) {
        this.model.task = null
        return
      }
      
      this.model.task = null
      this.taskSubTasks.push(subTask)

      await this.$store.dispatch('tasks/addSubTasks', {
        taskId: this.task?.id,
        tasks: [ subTask ],
      })
      this.fetchTaskSilently()
    },
    async removeSubTask(subTask) {
      if (!subTask) {
        return;
      }

      this.taskSubTasks = this.taskSubTasks.filter(task => task.id.toString() !== subTask.id.toString())

      await this.$store.dispatch('tasks/removeSubTasks', { tasks: [ subTask ] })
      this.fetchTaskSilently()
    },
    async onSubTaskCreated(subTask) {
      this.fetchTaskSilently()

      this.model.task = null
      
      return {
        shouldFocusInput: false
      }
    },
    async openRelatedTask(task) {
      if (this.showRelatedTaskDialog) {
        return
      }

      this.currentRelatedTaskId = task.id
      this.showRelatedTaskDialog = true
    },
    syncParentTask() {
      this.parentTask = this.task?.relationships?.parentTask?.[0] || this.task?.relationships?.parentTask || null
    },
    onRelatedTaskEdited() {
      this.showRelatedTaskDialog = false
    },
    async fetchTaskSilently() {
      if (!this.task?.id) {
        return
      }

      await this.$store.dispatch('tasks/getTaskById', {
        id: this.task?.id,
        silent: true
      })
    }
  },
}
</script>
