<template>
  <AllocatedTimeTable
    :data="allocatedTime"
    :loading="isLoading"
    class="flex-1"
  />
</template>
<script lang="ts" setup>
// Utils
import { computed, ref, watch } from "vue";

// Components
import AllocatedTimeTable from "@/modules/time/components/AllocatedTimeTable.vue"

// Composables
import { useStore } from "vuex"
const store = useStore()

const loading = computed(() => {
  return store.state.time.allocatedTimeLoading
})

const isLoading = ref(true)

watch(() => loading.value, (value) => {
  isLoading.value = value
}, { immediate: true })

const allocatedTime = computed(() => {
  if (isLoading.value) {
    return []
  }

  return store.getters['time/currentAllocatedTime'] || []
})
</script>
