<template>
  <BaseSelect
    v-model="model"
    @update:modelValue="onLocaleChange"
    :placeholder="$t('Language')"
    :options="languageOptions"
    :returnObject="false"
    v-bind="$attrs"
  >
    <template #prefix>
      <div class="flex items-center space-x-2">
        <img
          v-if="selectedLanguageFlag"
          class="w-5 h-5 border-1 border-white"
          :src="selectedLanguageFlag"
        />
        <div class="text-gray-900">
          {{ selectedOption.label }}
        </div>
      </div>
    </template>
    <template #default="{option}">
      <div class="flex items-center space-x-2">
        <img
          v-if="option.flag"
          class="w-5 h-5 border-1 border-white"
          :src="option.flag"
        />
        <div>
          {{ option.label }}
        </div>
      </div>
    </template>
  </BaseSelect>
</template>
<script>
import { i18n, loadLocaleMessages } from "@/i18n";

export default {
  props: {
    modelValue: {
      type: String,
    },
    showAccountLevelOption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue || null
      },
      set(locale) {
        this.$emit('update:modelValue', locale)
      }
    },
    accountLocale() {
      return this.$settings('locale')
    },
    languageOptions() {
      const options = [
        {
          label: this.$t('English'),
          value: 'en',
          flag: this.getFlag('gb'),
        },
        {
          label: this.$t('French'),
          value: 'fr',
          flag: this.getFlag('fr'),
        },
        {
          label: this.$t('German'),
          value: 'de',
          flag: this.getFlag('de'),
        },
        {
          label: this.$t('Italian'),
          value: 'it',
          flag: this.getFlag('it'),
        },
        {
          label: this.$t('Spanish'),
          value: 'es',
          flag: this.getFlag('es'),
        },
      ]

      if (!this.showAccountLevelOption) {
        return options
      }
      
      const accountOption = options.find(o => o.value === this.accountLocale)

      if (!accountOption) {
        return options
      }

      const accountLanguageOption = {
        label: this.$tc('use account language', {
          language: accountOption.label,
        }),
        value: null,
        flag: accountOption.flag
      }

      return [accountLanguageOption, ...options]
    },
    selectedOption() {
      const locale = this.model
      return this.languageOptions.find(o => o.value === locale) || {}
    },
    selectedLanguageFlag() {
      return this.selectedOption?.flag
    },
  },
  methods: {
    async onLocaleChange(locale) {
      if (!locale) {
        locale = this.accountLocale
      }
      await loadLocaleMessages(i18n, locale) 
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)
    },
    getFlag(country) {
      return `https://flagicons.lipis.dev/flags/4x3/${country}.svg`
    },
  },
}
</script>
