<template>
  <div class="text-gray-400 text-base">
    <div
      v-for="option of initOptions"
      :key="option.label"
      class="py-1"
      :class="{
        'cursor-pointer group hover:text-gray-500': !option.disabled,
        'opacity-50': option.disabled,
        'cursor-not-allowed': option.disabled,
      }"
      @click.stop="!option.disabled && option.action()"
    >
      <i
        class="w-6" 
        :class="{
          [option.icon]: option.icon,
          [option.iconHoverColor]: option.iconHoverColor,
        }"
      />
      {{ option.label }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n';
const emit = defineEmits([
  'init-default',
  'init-with-ai',
])

const initOptions = [
  {
    label: i18n.t(`Start with a blank description`),
    action: () => emit('init-default'),
    icon: 'fa-regular fa-page',
    iconHoverColor: 'group-hover:text-gray-500',
    disabled: false
  },
  {
    label: i18n.t(`Start writing with AI`),
    action: () => emit('init-with-ai'),
    icon: 'fa-duotone fa-wand-magic-sparkles',
    iconHoverColor: 'group-hover:text-primary-500',
    disabled: false
  },
]
</script>
