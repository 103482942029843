<template>
  <div>
    <BaseDropdown
      :options="availableOptions"
      :slim="slim"
      :auto-open="true"
      divide-items
      @action="onAction"
    >
      <template #default>
        <slot/>
      </template>
    </BaseDropdown>
    <FilePreviewDialog
      v-model="showFilePreviewDialog"
      :file="file"
    />
    <FileRenameDialog
      v-model="showFileRenameDialog"
      :file="file"
      @save="onFileRenamed"
    />
  </div>
</template>
<script>
import * as FileUtils from "@/modules/common/utils/fileUtils.js";
import FileRenameDialog from "@/modules/files/components/FileRenameDialog.vue";
import { useFileActions } from "@/modules/files/composables/useFileActions";
import proofAcceptFormats from '@/modules/file-proofs/utils/proofAcceptFormats.json'
import {
  isFileProofingEnabled,
} from '@/modules/projects/utils/toolUtils.js'

import i18n from '@/i18n'

export default {
  components: {
    FileRenameDialog,
  },
  props: {
    file: {
      type: Object,
      default: () => ({})
    },
    slim: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const { deleteFileFromTable } = useFileActions()
    return {
      deleteFileFromTable
    }
  },
  data() {
    return {
      showFilePreviewDialog: false,
      showFileRenameDialog: false
    }
  },
  computed: {
    project() {
      return this.file?.relationships?.project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    filePath() {
      return {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          fileId: this.file.id
        }
      }
    },
    options() {
      return [
        {
          label: i18n.t('Preview'),
          action: () => this.showFilePreviewDialog = true,
        },
        {
          label: i18n.t('Download'),
          action: 'download',
        },
        {
          label: i18n.t('Edit'),
          action: () => this.showFileRenameDialog = true,
          enabled: () => {
            const isFileOwner = this.file?.attributes?.created_by?.toString() === this.$user.id?.toString()
            return isFileOwner || this.can(this.$actions.RENAME_PROJECT_FILES)
          },
        },
        {
           label: i18n.t('Convert to proof'),
           path: `/projects/${this.project.id}/file-proofs/list?create-from-file=${this.file.id}`,
           enabled: () => {
             return isFileProofingEnabled() && this.can(this.$actions.CREATE_PROJECT_FILE_PROOFS) && this.isProofAcceptedFormat()
           },
        },
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.DELETE_PROJECT_FILES)
          },
        }
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    fileFormat() {
      const { file_name } = this.file.attributes
      return file_name.split('.')?.pop()?.trim() || ''
    },
    isProofAcceptedFormat() {
      const format = this.fileFormat()
      if(!format) return false
      return proofAcceptFormats.includes(format.toUpperCase())
    },
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.file)
    },
    download() {
      const url = FileUtils.getFileDownloadUrl(this.file.attributes)
      window.open(url, '_blank')
    },
    async confirmDelete() {
      await this.deleteFileFromTable(this.file)
    },
    onFileRenamed(name) {
      this.file.attributes.name = name
      this.showFileRenameDialog = false
      this.$store.commit('triggerGridDataChanged', { id: this.file.id })
    }
  }
}
</script>
