<template>
  <BaseInput
    :label="$t('AppSumo Code(s)')"
    :name="$t('AppSumo Code(s)')"
    :columnCount="6"
    layout="horizontal"
    id="modelValue"
  >
    <div class="space-y-4">
      <div
        v-for="(code, index) of modelValue"
        :key="index"
        class="relative"
      >
        <BaseInput 
          v-model="modelValue[index]"
          rules="required"
          class="w-full"
          :id="`App Sumo Code ${index}`"
          :name="`App Sumo Code ${index}`"
        />

        <XCircleIcon
          v-show="modelValue.length > 1"
          class="absolute top-4 -right-6 h-5 w-5 text-xl text-red-200 cursor-pointer hover:text-red-400"
          @click="removeCode(index)"
        />
      </div>
    </div>

    <BaseButton
      @click="addNewCode"
      type="button"
      variant="white"
      class="mt-2"
    >
      {{ $t("+ Add Another") }}
    </BaseButton>
  </BaseInput>
</template>
<script>
import { XCircleIcon } from '@heroicons/vue/outline'
export default {
  components: {
    XCircleIcon
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    addNewCode() {
      this.modelValue.push('')
    },
    removeCode(index) {
      this.modelValue.splice(index, 1);
    }
  }
};
</script>
