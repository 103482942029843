<template>
  <PopoverRoot>
    <PopoverTrigger asChild>
      <button
        class="w-9 h-9 rounded"
        :class="{ 'bg-gray-200': editor.isActive(command.name) }"
        :style="{
            opacity: 0.8,
            backgroundColor: highlightColor,
          }"
      >
        <i :class="command.icon" class="text-gray-700"></i>
      </button>
    </PopoverTrigger>
    <PopoverContent side="top"  asChild :side-offset="2">
      <ToolbarWrapper>
        <ToolbarButton
          v-for="option in command.options"
          :key="option.value"
          @click="runToggleHighlightColor(option)"
          :active="option.value === currentHighlightColor"
        >
          <div
            class="px-2 w-10 rounded h-6"
            :style="{ backgroundColor: option.value }"
          />
        </ToolbarButton>
      </ToolbarWrapper>
    </PopoverContent>
  </PopoverRoot>
</template>

<script setup lang="ts">
import { Commands } from "@/components/html/util/tipTapUtils.js";
import { computed, ref } from "vue";
import { PopoverRoot, PopoverContent, PopoverTrigger } from "radix-vue";
import ToolbarWrapper from "@/components/html/components/toolbar/ToolbarWrapper.vue";
import ToolbarButton from "@/components/html/components/toolbar/ToolbarButton.vue";

type ColorOption = {
  label: string;
  value: string;
  default?: boolean;
};

const command = Commands.Highlight

const props = defineProps({
  editor: {
    type: Object,
    required: true,
  }
})
const emit = defineEmits(['update:modelValue'])

const lastHighlightColor = ref('')

const currentHighlightColor = computed(() => {
  let color = ''
  if (!props.editor) {
    return color
  }
  command.options.forEach(({ value }) => {
    if (props.editor.isActive('highlight', { color: value })) {
      color = value
    }
  })
  return color
})

const defaultHighlightColor = computed(() => {
  const firstColor = command.options[0].value
  return command.options.find(option => option.default)?.value || firstColor
})

const highlightColor = computed(() => {
  return currentHighlightColor.value || lastHighlightColor.value || defaultHighlightColor.value
})

function runToggleHighlightColor({ value }: ColorOption) {
  if (!value)  {
    return
  }
  props.editor.chain().focus().toggleHighlight({ color: value }).run()
  if (value !== currentHighlightColor.value) {
    lastHighlightColor.value = value
  }
}
</script>
