<template>
  <BaseAlert
    variant="primary"
    :dismissable="false"
  >
    <span>
      {{ $t('Would you like to start a new conversation or continue the current one?') }}
    </span>
    <BaseIconTooltip
      :content="$t(`Continuing the conversation might increase token usage with information that is no longer relevant.`)"
      class="text-primary-500 opacity-40 hover:opacity-100 hover:text-primary-500"
    />
    <div class="flex space-x-2 mt-2">
      <BaseButton
        variant="white"
        size="sm"
        class="shadow-none"
        :has-focus="false"
        @click="$emit('start-new')"
      >
        {{ $t('Start a new conversation')  }}
      </BaseButton>
      <BaseButton
        variant="white"
        size="sm"
        class="shadow-none"
        :has-focus="false"
        @click="$emit('continue')"
      >
        {{ $t('Continue current conversation')  }}
      </BaseButton>
    </div>
  </BaseAlert>
</template>
