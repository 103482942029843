<template>
  <div>
    <div
      v-for="option of availableOptions"
      :key="option.label"
      class="pr-4 text-xs text-gray-300 hover:text-gray-400 cursor-pointer"
    >
      <BaseAvatarPicker
        v-if="option.action === 'changeImage'"
        v-model="imageModel"
        :disabled="disabled"
      >
        <template #activator>
          <span>
            <i class="fa-solid fa-image mr-1" />
            <span>{{ option.label }}</span>
          </span>
        </template>
        <template #empty>
          <span>
            <i class="fa-solid fa-image mr-1" />
            <span>{{ option.label }}</span>
          </span>
        </template>
      </BaseAvatarPicker>
      <BaseAvatarPicker
        v-else-if="option.action === 'changeCoverImage'"
        v-model="coverImageModel"
        :disabled-options="['group', 'emoji', 'clearbit']"
        :disabled="disabled"
      >
        <template #activator>
          <span>
            <i class="fa-solid fa-image-landscape mr-1" />
            <span>{{ option.label }}</span>
          </span>
        </template>
        <template #empty>
          <span>
            <i class="fa-solid fa-image-landscape mr-1" />
            <span>{{ option.label }}</span>
          </span>
        </template>
      </BaseAvatarPicker>
    </div>
  </div>
</template>
<script>
import { getSetting } from "@/plugins/settingsPlugin";
import { getRandomUnsplashPhoto } from "@/modules/common/utils/unsplashUtils";

import { getProjectImages } from "@/modules/projects/utils/projectHelpers";

export default {
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    image: {
      type: String,
      default: ''
    },
    cover_image: {
      type: String,
      default: ''
    },
    slim: {
      type: Boolean,
      default: false,
    },
    isProjectCreate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isTemplate() {
      return this.project?.attributes?.is_template
    },
    options() {
      if (this.isTemplate) {
        return [
          {
            label: this.image ? this.$t('Edit template image') : this.$t('Add template image'),
            action: 'changeImage',
          },
          {
            label: this.cover_image ? this.$t('Edit cover image') : this.$t('Add cover image'),
            action: 'changeCoverImage',
          },
        ]
      }

      return [
        {
          label: this.image ? this.$t('Edit project image') : this.$t('Add project image'),
          action: 'changeImage',
        },
        {
          label: this.cover_image ? this.$t('Edit cover image') : this.$t('Add cover image'),
          action: 'changeCoverImage',
        },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    },
    imageModel: {
      get() {
        return this.image
      },
      set(value) {
        this.$emit('update:image', value)
      }
    },
    coverImageModel: {
      get() {
        return this.cover_image
      },
      set(value) {
        this.$emit('update:cover_image', value)
      }
    }
  },
  methods: {
    async initImages() {
      if (!this.isProjectCreate) {
        return
      }

      const {
        image,
        cover_image
      } = await getProjectImages()

      this.imageModel = image
      this.coverImageModel = cover_image
    },
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.project)
    },
  },
  mounted() {
    this.initImages()
  },
  watch: {
    isProjectCreate(value) {
      if (value) {
        this.initImages()
      }
    }
  }
};
</script>
