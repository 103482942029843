import { humanReadableSize } from '@/modules/common/utils/fileUtils';
import { getUnixTimestamp } from '@/modules/common/utils/dateUtils';
export function initProfitWell(model) {
  if (!window?.profitwell) {
    return
  }
  window?.profitwell('start', {
    user_email: model.organization.email,
  });
}

export function initPostHog(model) {
  window.posthog?.identify(model.id, {
    name: model.person.name,
    email: model.person.email,
    company: model.organization,
    ...model.organization,
    ...model.person,
  })

  if (!model.organization) {
    return
  }
  window.posthog?.group('organization', model.organization.organization_id, {
    name: model.organization.organization_name,
    plan: model.organization.v2_plan,
    created_at: model.organization.created_at,
    creator_seats: model.organization.v2_number_of_creators,
  });
}

export function initProduktly(model) {
  const data = {
    name: model.person.name,
    email: model.person.email,
    ...model.organization,
    ...model.person,
  }
  delete data.campaign
  delete data.v2_trial
  window.Produktly?.identifyUser(model.id, data)
}


export function initOrtto(model) {
  const cookie = ap3c.getCookie?.()
  ap3c.track?.({
    v: 0,
    ei: model.id,
    email: model.person.email,
    first: model.person.name,
  }, () => {
    if (cookie) {
      ap3c.setCookie?.(cookie)
    }
  })
  trackLogin(model)
}

export const Activities = {
  ProjectCreated: 'project-created',
  TemplateCreated: 'created-a-template',
  TemplateUsed: 'used-a-template',
  TaskCreated: 'created-a-task',
  GroupCreated: 'created-a-group',
  PaymentCreated: 'created-a-payment',
  TimeEntryCreated: 'created-a-time-entry',
  CustomFieldCreated: 'created-a-custom-field',
  UsersInvited: 'invited-people',
  PageStarred: 'starred-a-page',
  Register: 'signed-up',
  Login: 'signed-in',
  CompletedOnboarding: 'completed-onboarding',
  SetupWizardCompleted: 'setup-wizard-completed',
  StartedFeatureTrial: 'started-feature-trial',
  // AI Chat
  AiActionProjectCreated: 'ai-action-project-created',
  AiActionProjectUpdated: 'ai-action-project-updated',
  AiActionTasksCreated: 'ai-action-tasks-created',
  AiActionTasksUpdated: 'ai-action-tasks-updated',

  AiCreateAddToProject: 'ai-create-add-to-project',
  AiCreateAddToExistingTask: 'ai-create-add-to-existing-task',
  AiCreateAddToNewTask: 'ai-create-add-to-new-task',
  AiCreateAddToNote: 'ai-create-add-to-note',
  AiCreateCopyToClipboard: 'ai-create-copy-to-clipboard',
  AiCreateSendToAction: 'ai-create-send-to-action',

  // TODO: Will probably keep these 2 temporarily, just to get an idea of how many people are using the AI chat
  AiCreateMessageSent: 'ai-create-message-sent',
  AiActionMessageSent: 'ai-action-message-sent',
}

export function trackActivity(activity, data) {
  const functionMap = {
    [Activities.ProjectCreated]: trackProjectCreated,
    [Activities.TemplateCreated]: trackTemplateCreated,
    [Activities.TemplateUsed]: trackTemplateUsed,
    [Activities.TaskCreated]: trackTaskCreated,
    [Activities.TimeEntryCreated]: trackTimeEntryCreated,
    [Activities.CustomFieldCreated]: trackCustomFieldCreated,
    [Activities.UsersInvited]: trackUsersInvited,
    [Activities.GroupCreated]: trackGroupCreated,
    [Activities.PaymentCreated]: trackPaymentCreated,
    [Activities.PageStarred]: trackStarredPage,
    [Activities.Register]: trackRegister,
    [Activities.Login]: trackLogin,
    [Activities.CompletedOnboarding]: trackCompletedOnboarding,
    [Activities.SetupWizardCompleted]: trackSetupWizardCompleted,
    [Activities.StartedFeatureTrial]: trackStartedFeatureTrial,
  }
  const fn = functionMap[activity]
  if (fn) {
    fn(data)
  }
}

function track(activity, data) {
  ap3c.activity?.({
    activity_id: `act:cm:${activity}`,
    attributes: data,
  })

}

export function trackPostHog(activity, data) {
  window.posthog.capture?.(activity, data)
}

function trackTaskCreated(task) {
  const data = {
    id: task.id?.toString(),
    name: task.attributes?.name,
    project_id: task.attributes?.project_id?.toString(),
    project_name: task.relationships?.project?.attributes?.name,
    created_at: task.attributes?.created_at,
  }
  track(Activities.TaskCreated, {
      "str:cm:id": data.id,
      "str:cm:name": data.name,
      "str:cm:project-id": data.project_id,
      "str:cm:project-name": data.project_name,
      "tme:cm:created-at": data.created_at,
    }
  )
  trackPostHog(Activities.TaskCreated, data)
}

function trackUsersInvited(data) {
  const activityData = {
    emails: data.emails.join(','),
    created_at: new Date().toISOString()
  }
  track(Activities.UsersInvited, {
    "str:cm:emails": activityData.emails,
    "tme:cm:created-at": activityData.created_at,
  })
  trackPostHog(Activities.UsersInvited, activityData)
}


function trackProjectCreated(project) {
  const data = {
    id: project.id,
    name: project.attributes?.name,
    created_at: project.attributes?.created_at,
  }
  track(Activities.ProjectCreated, {
    "str:cm:id": data.id,
    "str:cm:project-name": data.name,
    "tme:cm:created-at": data.created_at,
  })
  trackPostHog(Activities.ProjectCreated, data)
}

function trackTemplateCreated(template) {
  const data ={
    id: template.id,
    name: template.attributes?.name,
    created_at: template.attributes?.created_at,
  }
  track(Activities.TemplateCreated, {
    "str:cm:id": data.id,
    "str:cm:name": data.name,
    "tme:cm:created-at": data.created_at,
  })
  trackPostHog(Activities.TemplateCreated, data)
}

function trackTemplateUsed(data) {
  const activityData = {
    template_id: data.template_id,
    name: data.attributes?.name,
    created_at: data.attributes?.created_at
  }
  track(Activities.TemplateUsed, {
    "str:cm:template-id": activityData.template_id,
    "str:cm:name": activityData.name,
    "tme:cm:created-at": activityData.created_at,
  })
  trackPostHog(Activities.TemplateUsed, activityData)
}


function trackGroupCreated(group) {
  const data = {
    id: group.id,
    name: group.attributes?.name,
    created_at: group.attributes?.created_at,
  }
  track(Activities.GroupCreated, {
    "str:cm:id": data.id,
    "str:cm:name": data.name,
    "tme:cm:created-at": data.created_at,
  })
  trackPostHog(Activities.GroupCreated, data)
}

function trackPaymentCreated(payment) {
  const data = {
    id: payment.id,
    name: payment.attributes?.description,
    created_at: payment.attributes?.created_at,
  }
  track(Activities.PaymentCreated, {
    "str:cm:id": data.id,
    "str:cm:name": data.description,
    "tme:cm:created-at": data.created_at,
  })
  trackPostHog(Activities.PaymentCreated, data)
}

function trackStarredPage(starredPage) {
  const data = {
    title: starredPage.title,
    created_at: new Date().toISOString(),
  }
  track(Activities.PageStarred, {
    "str:cm:name": data.title,
    "tme:cm:created-at": data.created_at,
  })
  trackPostHog(Activities.PageStarred, data)
}

function trackTimeEntryCreated(timeEntry) {
  const data = {
      created_at: timeEntry.attributes?.created_at
  }
  track(Activities.TimeEntryCreated, {
    "tme:cm:created-at": data.created_at,
  })
  trackPostHog(Activities.TimeEntryCreated, data)
}

function trackCustomFieldCreated(customField) {
  const data = {
    id: customField.id,
    name: customField.attributes?.name,
    custom_field_type: customField.attributes?.custom_field_type,
    created_at: customField.attributes?.created_at,
  }
  track(Activities.CustomFieldCreated, {
    "str:cm:id": data.id,
    "str:cm:name": data.name,
    "str:cm:type": data.custom_field_type,
    "tme:cm:created-at": data.created_at,
  })
  trackPostHog(Activities.CustomFieldCreated, data)
}

export function trackRegister(data) {
  const trackData = {
    v: 0,
    ei: data.id,
    email: data.email,
    first: data.first_name,
    last: data.last_name,
  }
  const cookie = ap3c.getCookie?.()
  const activityData = {
    "str:cm:first-name": data.first_name,
    "str:cm:last-name": data.last_name,
    "str:cm:email": data.email,
    "str:cm:account-name": data.account_name,
    "str:cm:account-url": window.location.origin,
    "tme:cm:created-at": new Date().toISOString()
  }
  data.onboarding = data.onboarding || []
  data.onboarding.forEach(question => {
    activityData[question.key] = question.answer
  })

  ap3c.track?.(trackData, () => {
    if (cookie) {
      ap3c.setCookie?.(cookie)
    }
    track(Activities.Register, activityData)
  })
  trackPostHog(Activities.Register, {
      ...trackData,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      account_url: window.location.origin,
      created_at: new Date().toISOString(),
  })
}

function trackCompletedOnboarding(data) {
  const activityData = {
    first_name: data.first_name,
    last_name: data.last_name,
    account_name: data.account_name,
    email: data.email,
    project_id: data.project_id,
    account_url: window.location.origin,
  }

  track(Activities.CompletedOnboarding, {
    "str:cm:first-name": activityData.first_name,
    "str:cm:last-name": activityData.last_name,
    "str:cm:email": activityData.email,
    "str:cm:account-name": activityData.account_name,
    "str:cm:account-url": activityData.account_url,
    "str:cm:project-id": activityData.project_id,
  })

  trackPostHog(Activities.CompletedOnboarding, activityData)
}

function trackSetupWizardCompleted(data) {
  const activityData = {
    first_name: data.first_name,
    last_name: data.last_name,
    account_name: data.account_name,
    email: data.email,
    account_url: window.location.origin,
  }

  track(Activities.SetupWizardCompleted, {
    "str:cm:first-name": activityData.first_name,
    "str:cm:last-name": activityData.last_name,
    "str:cm:email": activityData.email,
    "str:cm:account-name": activityData.account_name,
    "str:cm:account-url": activityData.account_url,
  })

  trackPostHog(Activities.SetupWizardCompleted, activityData)
}

function trackStartedFeatureTrial(data) {
  if (!data.features?.length) {
    return
  }

  const baseActivityData = {
    first_name: data.first_name,
    last_name: data.last_name,
    account_name: data.account_name,
    email: data.email,
    account_url: window.location.origin,
  }

  data.features.forEach((feature) => {
    const activityData = {
      ...baseActivityData,
      feature,
    }

    track(Activities.StartedFeatureTrial, {
      "str:cm:first-name": activityData.first_name,
      "str:cm:last-name": activityData.last_name,
      "str:cm:email": activityData.email,
      "str:cm:feature": activityData.feature,
      "str:cm:account-name": activityData.account_name,
      "str:cm:account-url": activityData.account_url,
    })

    trackPostHog(Activities.StartedFeatureTrial, activityData)
  })
}

function trackLogin(data) {
  const loginTracked = localStorage.getItem('loginTracked')
  if (loginTracked) {
    return
  }
  const storage_used_gb = humanReadableSize(
    data.organization.v2_storage_used,
    /* decimals */ 2,
    /* preferMu */ 'GB'
  )

  const activityData = {
      role: data.person.v2_role,
      account_creator: !!data.person.v2_account_creator,
      account_url: window.location.href,
      organization_name: data.organization.organization_name,
      active_tasks: data.organization.v2_active_tasks,
      creators_count: data.organization.v2_number_of_creators,
      collaborators_count: data.organization.v2_number_of_collaborators,
      storage_used: storage_used_gb,
  }
  track(Activities.Login, {
    "str:cm:role": activityData.role,
    "bol:cm:account-creator": activityData.account_creator,
    "bol:cm:account-url": activityData.account_url,
    "str:cm:organization-name": activityData.organization_name,
    "int:cm:active-tasks": activityData.active_tasks,
    "int:cm:creators-count": activityData.creators_count,
    "int:cm:collaborators-count": activityData.collaborators_count,
    "str:cm:storage-used": activityData.storage_used,
  })
  trackPostHog(Activities.Login, activityData)
  localStorage.setItem('loginTracked', 'true')
}
