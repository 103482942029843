<template>
  <BaseEntityForm
    :title="isEditAction ? $t('Edit Group') : $t('Create New Group')"
    :subtitle="$t('A group is a collection of people with a common interest such as a company, team or department.')"
    width-class="max-w-3xl"
    :isDialogForm="isDialogForm"
    @submit="onSubmit"
  >
    <template #default="{ errors, meta }">
      <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start">
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {{ $t('Photo/Icon') }}
        </label>
        <div class="sm:mt-px sm:pt-1 sm:col-span-3">
          <BaseAvatarPicker
            v-model="model.image"
            :disabled-options="['group']"
          />
        </div>
      </div>

      <BaseInput
        v-model="model.name"
        v-focus
        :label="$t('Group Name')"
        :placeholder="$t('Enter group name...')"
        :errorMessage="errors[$t('Group Name')]"
        :name="$t('Group Name')"
        layout="horizontal"
        rules="required"
        id="name"
      />

      <BaseInput
        :label="$t('Invoice Details')"
        :name="$t('Invoice Details')"
        layout="horizontal"
        id="details"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <textarea
            v-model="model.invoice_details"
            id="invoice-details"
            name="invoice-details"
            rows="6"
            class="block w-full form-input"
            :placeholder="$t('Enter address to be shown on any invoices for this group...')"
          />
        </div>
      </BaseInput>

      <CustomFieldsEntityForm
        v-model="model.custom_fields"
        :col-span="3"
        :column-count="4"
        entity-type="group"
      />
    </template>
    <template #actions="{ meta, errors}">
      <BaseButton
        variant="white"
        @click="$emit('cancel')">
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        :loading="loading"
        :disabled="!meta.valid"
        type="submit"
        class="ml-2"
      >
        {{ $t('Save') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
// Libs
import { cloneDeep } from 'lodash-es'
import { getCustomFieldValuesObject } from "@/modules/accounts/utils/modelUtils"

// Components
import CustomFieldsEntityForm from "@/modules/common/components/CustomFieldsEntityForm.vue"

export default {
  components: {
    CustomFieldsEntityForm,
  },
  props: {
    group: {
      type: Object,
      default: () => null
    },
    isDialogForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      model: {
        image: '',
        name: null,
        invoice_details: '',
        custom_fields: {}
      },
    }
  },
  computed: {
    isEditAction() {
      return !!this.group?.id
    }
  },
  methods: {
    async onSubmit(emitSave = true) {
      const action = this.isEditAction
          ? 'editGroup'
          : 'createGroup'

      try {
        const data = cloneDeep(this.model);
        this.loading = true

        const group = await this[action](data)

        if (emitSave) {
          this.$emit('save', group)
        }

        const message = this.isEditAction
          ? this.$t('Group updated successfully')
          : this.$t('Group created successfully')
  
        this.$success(message)
      } catch (err) {
        if (err.handled) {
          return
        }
        const message = this.isEditAction
          ? this.$t('Could not update group')
          : this.$t('Could not create group')
      
        this.$error(message)
      } finally {
        this.loading = false
      }
    },
    async editGroup(data) {
      const result = await this.$store.dispatch('groups/editGroup', {
        groupId: this.group.id,
        data
      })
      

      await this.$store.dispatch('groups/getGroupById', { id: this.group.id })

      return result.data
    },
    async createGroup(data) {
      const result = await this.$store.dispatch('groups/createGroup', data)
      return result.data
    }
  },
  watch: {
    group: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.model = {
          ...this.model,
          ...value?.attributes,
          custom_fields: getCustomFieldValuesObject(value?.attributes?.custom_fields)
        }
      }
    }
  }
}
</script>
