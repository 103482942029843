<template>
  <div
    v-if="availableViewTypes?.length"
    class="relative lg:inline-block text-left mt-2 lg:mt-0"
  >
    <BaseDropdown
      :options="availableViewTypes"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
    >
      <TopFilterChip
        :isActive="activeViewType"
        :prependLabel="$t('View')"
        :label="activeViewType?.name"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ title }}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import TopFilterChip from '@/modules/filters/components/TopFilterChip.vue';
import i18n from '@/i18n';

export default {
  components: {
    TopFilterChip,
  },
  name: 'EntityViewTypesSelect',
  props: {
    viewTypes: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: i18n.t('Change view type'),
    },
    icon: {
      type: String,
      default: 'fa-calendar-days',
    }
  },
  computed: {
    activeViewType() {
      return this.viewTypes.find(viewType => viewType.isActive);
    },
    availableViewTypes() {
      return this.viewTypes.filter(viewType => !viewType.showIf || viewType.showIf())
        .map(viewType => ({
          label: viewType.name,
          path: viewType.path,
          variant: viewType.isActive ? 'active' : '',
        }))
    },
  }
}
</script>
