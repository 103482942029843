<template>
  <DashboardWidget
    :widget="widget"
    :ui="ui"
    :widgetData="widgetData"
  >
    <template #item="{ item: payment }">
      <div class="space-x-3 flex items-center">
        <ProjectLogoNew
          v-if="payment?.relationships?.project"
          :project="payment?.relationships?.project"
        />
        <router-link
          :to="`/payments/${payment.id}`"
          class="space-x-3 flex items-center"
        >
          <div class="line-clamp-1 text-sm text-gray-500">
            {{ payment.attributes.description }}
          </div>
          <PaymentStatusChip
            :payment="payment"
          />
          
        </router-link>
      </div>
    </template>
  </DashboardWidget>
</template>
<script lang="ts" setup>
// Components
import PaymentStatusChip from "@/modules/payments/components/PaymentStatusChip.vue";
import DashboardWidget from "@/modules/dashboard/components/widgets/DashboardWidget.vue";
import ProjectLogoNew from "@/modules/projects/components/ProjectLogoNew.vue";

// Utils
import i18n from "@/i18n";
import { PropType, computed } from 'vue'
import { DashboardWidgetType, WidgetUI, WidgetData } from "@/modules/dashboard/utils/widgetUtils"
import { getExtraFilters } from "@/modules/payments/utils/paymentTableUtils"

// Composables
import { useStore } from 'vuex'
import useCan from "@/modules/common/composables/useCan"
const { can, actions } = useCan()

const store = useStore()

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidgetType>,
    required: true
  }
})

const ui = computed<WidgetUI>(() => {
  return {
    listPath: '/payments/list',
    listLabel: i18n.t('Go to payments'),
    entityCreate: can(actions.CREATE_PAYMENTS) ? '/payments/add' : '',
    footerKey: 'showing payments'
  }
})

const widgetData = computed<WidgetData>(() => {
  return {
    target: 'payments',
    dataGetterPath: 'payments/currentPayments',
    groupedDataGetter: 'payments/groupedPayments',
    loading: store.state.payments.paymentsLoading,
    extraFilters: getExtraFilters()
  }
})
store.dispatch('payments/getAllPayments')
</script>
