<template>
  <div class="flex flex-col h-full">
    <AllocatedTimeDialog
      v-show="showCreateAllocatedTimeDialog && !$route.query.allocatedTimeId"
      v-model="showCreateAllocatedTimeDialog"
      key="allocated-time-create"
      @close="redirectToList"
      @save="onAllocatedTimeCreate"
    />
    <AllocatedTimeDialog
      v-show="showEditAllocatedTimeDialog && $route.query.allocatedTimeId"
      v-model="showEditAllocatedTimeDialog"
      :allocatedTime="currentAllocatedTime"
      :title="$t(`Edit ${currentAllocatedTime?.attributes?.name}`)"
      key="allocated-time-edit"
      @close="redirectToList"
      @save="onAllocatedTimeEdit"
    />
    <TopFilters
      :columns="topFilterColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search allocated time...')"
    >
      <template
        v-if="!isCurrentProjectClosed && hasActions"
        #action
      >
        <RequiresPermissionTo :action="$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS">
          <BaseButton
            v-if="isAllocatedViewActive || isAllViewActive"
            block
            @click="showCreateAllocatedTimeDialog = true"
          >
            {{ $t('Allocate Time') }}
          </BaseButton>
        </RequiresPermissionTo>
        <RequiresPermissionTo :action="$actions.CREATE_TIME_ENTRIES">
          <BaseButton
            v-if="isActualViewActive || isAllViewActive"
            :class="{
              'lg:ml-4 mt-2 lg:mt-0': isAllViewActive
            }"
            block
            @click="showCreateTimeEntryDialog = true"
          >
            {{ $t('New Time Entry') }}
          </BaseButton>
        </RequiresPermissionTo>
      </template>

      <TimeViewTypes
        v-if="$isMobileDevice && !isTemplatePath"
      />

    </TopFilters>
    
    <DataSyncingIndicator
      v-if="shouldDisplaySyncIndicator"
      dataView
    />
    <slot>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
    </slot>
  </div>
</template>
<script>
import TopFilters from "@/modules/filters/components/TopFilters.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import AllocatedTimeDialog from "@/modules/time/components/AllocatedTimeDialog.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"
import TimeViewTypes from "@/modules/time/components/TimeViewTypes.vue";

export default {
  components: {
    AllocatedTimeDialog,
    BaseButton,
    TopFilters,
    DataSyncingIndicator,
    TimeViewTypes,
  },
  data() {
    return {
      showCreateAllocatedTimeDialog: false,
      showEditAllocatedTimeDialog: false,
    }
  },
  computed: {
    isActualViewActive() {
      return false
    },
    isAllocatedViewActive() {
      return true
    },
    isAllViewActive() {
      return false
    },
    hasActions() {
      return this.can(this.$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS)
    },
    availableFilters() {
      const filters = ['filter', 'sort', 'group', 'columns']
      
      if (this.$route.path.includes('list')) {
        filters.push(...['chart', 'other', 'totalRow'])
      }
      return filters
    },
    topFilterColumns() {
      return this.allocatedTimeColumns
    },
    shouldDisplaySyncIndicator() {
      return this.allocatedTime.length && this.$store.state.time.allocatedTimeLoading
    },
    // Allocated time
    allocatedTime() {
      return this.$store.state.time.allocatedTime?.data || []
    },
    currentAllocatedTime() {
      return this.$store.state.time.currentAllocatedTime || {}
    },
    allocatedTimeColumns() {
      return this.$store.getters['time/allocatedTimeActiveColumns'] || []
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    addAllocatedTimeTrigger() {
      return this.$store.state.time.addAllocatedTimeTrigger
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    onAllocatedTimeCreate() {
      this.showCreateAllocatedTimeDialog = false
    },
    onAllocatedTimeEdit() {
      this.redirectToList()
    },
    redirectToList() {
      this.showCreateAllocatedTimeDialog = false
      this.showEditAllocatedTimeDialog = false

      const query = {
        ...this.$route.query
      }
      delete query.timeEntryId
      delete query.allocatedTimeId
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
  },
  watch: {
    '$route.query.allocatedTimeId': {
      immediate: true,
      async handler(allocatedTimeId) {
        if (!allocatedTimeId || !this.can(this.$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS)) {
          this.redirectToList()
          return
        }
        await this.$store.dispatch('time/getAllocatedTimeById', { id: allocatedTimeId })
        this.showEditAllocatedTimeDialog = true
      }
    },
    addAllocatedTimeTrigger() {
      this.showCreateAllocatedTimeDialog = true
    }
  },
}
</script>
