<template>
  <BaseTooltip :content="isConnected ? $t('Stop speech-to-text') : $t('Activate speech-to-text')">
    <LimitedFeature
      :feature="AccountPermissions.SpeechToText"
      size="sm"
      :variant="isConnected ? 'danger' : 'white'"
      :hasFocus="false"
      :loading="isConnecting"
      class="flex items-center cursor-pointer shadow-none"
      @click="toggleConnected"
    >
      <template
        v-if="context === ChatContext.Discussion"
        #default="{ onClick }"
      >
        <button
          type="button"
          class="p-2 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
          @click="onClick"
        >
          <LoadingIcon
            v-if="isConnecting"
            size="xs"
          />
          <i
            v-else
            class="fas fa-microphone"
            :class="{
              'text-red-500': isConnected,
              'text-gray-500': !isConnected
            }"
          />
        </button>
      </template>
      <template
        v-else
        #button-content
      >
        <i class="fas fa-microphone" />
      </template>
    </LimitedFeature>
    <div
      v-if="isConnected"
      class="text-gray-500 text-xs ml-2"
    >
      {{ runningTimerFormatted }}
    </div>
  </BaseTooltip>
</template>
<script lang="ts" setup>
// Libs
import i18n from "@/i18n";
import { computed, inject, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave } from "vue-router";

// Components
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";

// Utils
import { error } from '@/components/common/NotificationPlugin/index.js'
import { ChatContext } from "@/modules/common/commonTypes.d";
import { secondsToFormattedTime } from "@/modules/common/utils/timeUtils";

// Composables
import { useStore } from 'vuex'
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from '@/modules/common/composables/useCan';
import { useAuth } from "@/modules/auth/composables/useAuth";

const store = useStore()

const { currentUser } = useAuth()

const {
  hasReachedLimit
} = useAccountLimits()

const limitReached = computed(() => {
  return hasReachedLimit(AccountPermissions.SpeechToText)
})

const isConnected = ref(false)
const isConnecting = ref(false)

const runningTimer = ref(0)
const timerInterval = ref<NodeJS.Timer | null>(null)


const socket = ref<WebSocket | null>(null)
const mediaRecorder = ref<MediaRecorder | null>(null)
const stream = ref<MediaStream | null>(null)

type SpeechToTextConfig = {
  api_key_id: string
  key: string
  comment: string
  scopes: string[]
  tags: string[]
  created: string
  expiration_date: string
}

const config = computed<SpeechToTextConfig>(() => {
  return store.state.accounts.speechToTextConfig
})

const emit = defineEmits([
  'text-added'
])

const context = inject<ChatContext>('context', ChatContext.Discussion)

const isTokenExpired = computed(() => {
  if (!config.value.expiration_date) {
    return false
  }

  const expirationDate = Date.parse(config.value.expiration_date)
  const now = Date.parse(new Date().toISOString())

  return now >= expirationDate
})

const token = computed(() => {
  return config.value.key
})

const tag = computed(() => {
  return config.value.tags[0]
})

async function initConfig() {
  if (token.value && !isTokenExpired.value) {
    return
  }

  isConnecting.value = true
  try {
    await store.dispatch('accounts/getSpeechToTextConfig')
  } finally {
    isConnecting.value = false
  }
}

function startTimerInterval() {
  timerInterval.value = setInterval(() => {
    runningTimer.value++
  }, 1000)
}

function clearTimer() {
  runningTimer.value = 0

  if (!timerInterval.value) {
    return
  }

  clearInterval(timerInterval.value)
}
const runningTimerFormatted = computed(() => {
  return secondsToFormattedTime(runningTimer.value, false, false)
})

const baseUrl = 'wss://api.deepgram.com/v1/listen'

async function connect() {
  isConnecting.value = true

  await initConfig()

  stream.value = await navigator.mediaDevices.getUserMedia({ audio: true })
  mediaRecorder.value = new MediaRecorder(stream.value)

  const language = currentUser.value?.stt_locale || 'en'
  const url = new URL(baseUrl)
  url.searchParams.append('tag', tag.value)
  url.searchParams.append('model', 'nova-2')
  url.searchParams.append('smart_format', 'true')
  url.searchParams.append('language', language)

  socket.value = new WebSocket(url, [
    'token', token.value,
  ])

  socket.value.onopen = (event) => {
    console.log({ event: 'onopen', onOpenEvent: event })

    isConnected.value = true
    isConnecting.value = false
    startTimerInterval()

    mediaRecorder.value?.addEventListener('dataavailable', event => {
      if (event.data.size > 0 && socket.value?.readyState == 1) {
        socket.value?.send(event.data)
      }
    })

    mediaRecorder.value?.start(250)
  }

  socket.value.onmessage = (message) => {
    console.log({ event: 'onmessage', message })
    const received = JSON.parse(message.data)

    const transcript = received.channel.alternatives[0].transcript
    if (transcript && received.is_final) {
      emit('text-added', `${transcript} `)
    }
  }

  socket.value.onclose = (event) => {
    isConnected.value = false
    console.log({ event: 'onclose', onCloseEvent: event })
  }

  socket.value.onerror = (errorEvent) => {
    console.log({ event: 'onerror', errorEvent })
    error(i18n.t('Something went wrong. Please try again later.'))
    isConnecting.value = false
    disconnect()
  }
}

function disconnect() {
  if (!isConnected.value) {
    return
  }

  clearTimer()

  stream.value?.getTracks().forEach(track => {
    track.stop()
    track.enabled = false
  })
  mediaRecorder.value?.stop()
  socket.value?.send(JSON.stringify({ type: 'CloseStream' }))
  socket.value?.close()
  store.dispatch('accounts/syncSubscriptionStats', { })
}

function toggleConnected() {
  if (isConnected.value) {
    disconnect()
  } else {
    connect()
  }
}

const closeSpeechToTextTrigger = computed(() => {
  return store.state.accounts.closeSpeechToTextTrigger
})

watch(closeSpeechToTextTrigger, disconnect)

onBeforeRouteLeave(disconnect)

onMounted(async () => {
  if (limitReached.value) {
    return
  }

  await initConfig()
})

defineExpose({
  disconnect
})
</script>
