<template>
  <BaseDropdown
    :options="dropdownOptions"
    :disabled="!dropdownOptions.length"
    divide-items
    show-empty
    trigger="hover"
    class="inline-flex"
  >
    <div class="flex items-center text-xs text-medium space-x-2 text-gray-400 hover:text-gray-500 cursor-pointer">
      <div>/</div>
      <router-link
        :to="path"
      >
        {{ displayedName }}
      </router-link>
      <i
        v-if="dropdownOptions.length"
        class="fa-solid fa-square-caret-down"
      />
    </div>
  </BaseDropdown>
</template>
<script lang="ts" setup>
import { PropType, computed } from 'vue';
import { RouteLocationMatched } from 'vue-router';
import useBreadcrumbs from "@/modules/dashboard/utils/useBreadcrumbs"

const props = defineProps({
  matchedRoute: {
    type: Object as PropType<RouteLocationMatched>,
    required: true
  }
})

const {
  getDisplayedName,
  getDropdownOptions,
  getRoutePath,
} = useBreadcrumbs()

const dropdownOptions = computed(() => {
  return getDropdownOptions(props.matchedRoute)
})

const path = computed(() => {
  return getRoutePath(props.matchedRoute)
})

const displayedName = computed(() => {
  return getDisplayedName(props.matchedRoute)
})
</script>
