<template>
  <BaseTableCell :params="params">
    <div
      v-if="columnParams?.isImage"
      class="cursor-pointer"
    >
      <BaseTooltip :content="resourceTypeLabel">
        <BaseAvatar
          v-if="resourceImage"
          :avatar="resourceImage"
        />
        <i
          v-else-if="resourceIcon"
          :class="resourceIcon"
          class="text-3xl"
        />
      </BaseTooltip>
    </div>
    <div v-else>
      <a
        v-if="resourcePath?.target"
        :href="resourcePath?.href"
        :target="resourcePath?.target"
      >
        {{ projectResource?.attributes?.name || '- -' }}
      </a>

      <router-link
        v-else-if="resourcePath"
        :to="resourcePath"
        class="cursor-pointer"
      >
        {{ projectResource?.attributes?.name || '- -' }}
      </router-link>
      <div v-else>
        {{ projectResource?.attributes?.name || '- -' }}
      </div>
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import i18n from "@/i18n"
import {
  getEmbedImage,
  getEmbedIcon,
  getEmbedLabel
} from "@/modules/resources/utils/embedUtils.js";
import {
  projectResourceTypes,
  getResourcePath
} from "@/modules/resources/utils/projectResourceUtils.js";
import {
  getProjectLinkImage,
  getProjectLinkIcon,
  getProjectLinkLabel
} from "@/modules/resources/utils/projectLinkUtils.js";
import { useStore } from "vuex";
const store = useStore()

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})


const project = computed(() => {
  return store.state.projects.currentProject
})

const embeds = computed(() => {
  return project.value?.relationships?.embeds || []
})

const references = computed(() => {
  return project.value?.relationships?.references || []
})

const links = computed(() => {
  return project.value?.relationships?.links || []
})


const projectResource = computed(() => {
  const resourceTypes = {
    [projectResourceTypes.ProjectEmbeds]: embeds.value,
    [projectResourceTypes.ProjectReferences]: references.value,
    [projectResourceTypes.ProjectLink]: links.value
  }
  return resourceTypes[row.value?.type]?.find((r: any) => r.id?.toString() === row.value?.id?.toString())
})

const resourcePath = computed(() => {
  return getResourcePath(projectResource.value, store.state.route.path || '')
})

const resourceTypeLabel = computed(() => {
  const resource = projectResource.value
  if (!resource) {
    return ''
  }

  if (resource.type === projectResourceTypes.ProjectReferences) {
    return i18n.t('Reference')
  }

  if (resource.type === projectResourceTypes.ProjectLink) {
    return getProjectLinkLabel(row.value.attributes.type)
  }

  if (resource.type === projectResourceTypes.ProjectEmbeds) {
    return getEmbedLabel(row.value.attributes.type)
  }

  return i18n.t('Embed')
})

const resourceImage = computed(() => {
  const resource = projectResource.value
  if (!resource) {
    return ''
  }

  if (resource.attributes?.image) {
    return row.value?.attributes?.image
  }

  if (resource.type === projectResourceTypes.ProjectLink) {
    return getProjectLinkImage(row.value?.attributes.type)
  }

  return getEmbedImage(row.value?.attributes.type)
})

const resourceIcon = computed(() => {
  const resource = projectResource.value
  if (!resource) {
    return ''
  }

  if (resource.type === projectResourceTypes.ProjectReferences) {
    return 'fal fa-link'
  }

  if (resource.type === projectResourceTypes.ProjectLink) {
    return getProjectLinkIcon(row.value?.attributes.type)
  }

  if (resource.type === projectResourceTypes.ProjectEmbeds) {
    return getEmbedIcon(row.value?.attributes.type)
  }

  return 'fal fa-file-code'
})

</script>
