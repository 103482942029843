<template>
  <div>
    <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
      <div class="mb-4">
        <img
          v-if="logoSrc"
          class="m-auto mb-2 h-10 rounded"
          :src="logoSrc"
          alt="Project.co"
        >
        <BasePageTitle>
          {{ $t('Planned Maintenance') }}
        </BasePageTitle>
      </div>

      <p class="text-md text-gray-500 text-center">
        {{ $t('We are currently performing some planned maintenance and will be back online within ') }} {{maintenanceTime}}
      </p>
    </div>
  </div>
</template>
<script>
import { logoDarkFull } from '@/modules/accounts/utils/accountBrandingUtils'

export default {
  computed: {
    customLogo() {
      return this.$settings('login_page_logo')
    },
    logoSrc() {
      return this.customLogo || logoDarkFull.value
    },
    maintenanceTime() {
      const envTime = import.meta.env.VITE_MAINTENANCE_TIME
      return envTime || '15 minutes.'
    }
  },
}
</script>
