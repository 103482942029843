<template>
  <BaseInlineInput>
    <template #default="{ triggerSave }">
      <textarea
        ref="textarea"
        v-bind="$attrs"
        v-model="model"
        :type="type"
        :key="key"
        :name="name || label"
        class="w-full outline-none resize-none rounded-md -mx-1 px-1"
        :rows="$attrs.rows || 1"
        :class="{
          [$attrs.class]: $attrs.class,
          'overflow-auto': scrollable,
          'overflow-hidden': !scrollable,
        }"
        @keydown.enter="onEnterPressed"
        @blur="onBlur(triggerSave)"
        @focus="onFocus"
      />
    </template>
  </BaseInlineInput>
</template>
<script>
import { debounce } from "lodash-es"
export default {
  props: {
    key: {
      type: String,
      default: '0'
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number, Object],
      default: '',
    },
    type: {
      type: [String, Number],
      default: 'text',
    },
    autoResize: {
      type: Boolean,
      default: false,
    },
    allowLineBreaks: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      focusValue: this.modelValue
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    model: {
      immediate: true,
      handler() {
        this.tryAutoResizeDebounced && this.tryAutoResizeDebounced()
      }
    }
  },
  methods: {
    tryAutoResize() {
      if (!this.autoResize || !this.$refs?.textarea || !this.$refs.textarea.scrollHeight) {
        return
      }

      this.$refs.textarea.style.height = 'auto'
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
    },
    async tryAutoFocus() {
      await this.$nextTick()
      if (!this.autofocus) {
        return
      }

      requestAnimationFrame(this.focus)
    },
    focus() {
      this.$refs.textarea?.focus()
    },
    onBlur(triggerSave) {
      // Value not changed
      if (this.focusValue === this.modelValue) {
        return
      }

      triggerSave()
    },
    onFocus() {
      this.focusValue = this.modelValue
    },
    onEnterPressed(event) {
      if (this.allowLineBreaks) {
        return
      }

      event.preventDefault();
    }
  },
  mounted() {
    this.tryAutoResizeDebounced = debounce(this.tryAutoResize, 16)
    this.tryAutoResize()
    this.tryAutoFocus()
  }
}
</script>
