<template>
  <div class="relative hidden md:visible lg:inline-block text-left mt-2 lg:mt-0">
    <BaseDropdown
      :options="totalRowOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
      @option-selected="onOptionSelected"
    >
      <TopFilterChip
        :isActive="!!selectedOption"
        :prependLabel="$t('Total')"
        :label="selectedOption?.label || $t('None')"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ $t('Total Row') }}
            <BaseTooltip>
              <i class="fa-solid fa-circle-question ml-1 cursor-pointer text-gray-400" />
              <template #content>
                {{ $t('This defines the way the total row calculates the data.') }}
                <br />
                {{ $t('The total row is the last row in the table.')}}
              </template>
            </BaseTooltip>
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import { totalRowOptions, totalRowFunctions } from '@/modules/filters/utils/commonFilterUtils'
import TopFilterChip from '@/modules/filters/components/TopFilterChip.vue'
export default {
  components: {
    TopFilterChip,
  },
  props: {
    defaultFilter: {
      type: Object,
      default: () => ''
    }
  },
  data() {
    return {
      totalRowFilter: this.defaultFilter || totalRowFunctions.Sum,
    }
  },
  computed: {
    totalRowOptions() {
      return totalRowOptions.map(option => {
        return {
          ...option,
          variant: this.totalRowFilter === option.value ? 'active' : ''
        }
      })
    },
    selectedOption() {
      return this.totalRowOptions.find(option => option.value === this.totalRowFilter)
    }
  },
  methods: {
    onOptionSelected(option) {
      if (this.totalRowFilter === option.value) {
        return
      }
      
      this.totalRowFilter = option.value
      this.$emit('change', this.totalRowFilter)
    }
  },
  watch: {
    defaultFilter() {
      this.totalRowFilter = this.defaultFilter
    }
  }
}
</script>
