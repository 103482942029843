<template>
  <DataImportForm />

  <BaseEntityForm
    :title="$t('Past Imports')"
    :subtitle="$t('Past imports will show below.')"
    width-class="max-w-3xl mt-6"
    slim
  >
    <template
      v-if="$useNewLayout"
      #title
    >
      <div class="max-w-3xl mx-auto rounded-lg">
        <h3 class="text-2xl font-bold text-gray-900">
          {{ $t('Past Imports') }}
        </h3>
        <p class="text-sm text-gray-500 mt-2">
          {{ $t('Past imports will show below.') }}
        </p>
      </div>
    </template>
    <template #default>
      <NoImports
        v-if="!importEntries?.length && !loadingImportEntries"
      />
      <div
        v-else
        class="divide-y divide-gray-200"
      >
        <div
          v-loading="loadingImportEntries"
          class="divide-y divide-gray-200 sm:space-y-5 p-6 min-h-[200px]"
        >
          <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start">
              <label for="data-type" class="block text-sm font-medium text-gray-700">
                {{ $t('Imports') }}
                <BaseTooltip
                  :content="$t('Reload imports')"
                >
                  <i
                    class="fa fa-rotate ml-1 cursor-pointer"
                    @click="getImportEntries"
                  />
                </BaseTooltip>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-3 space-y-2 text-sm font-medium text-gray-500">
                <div
                  v-for="importEntry in importEntries"
                  :key="importEntry.id"
                  class="block hover:bg-gray-50 rounded-md p-0.5 group"
                >

                    <div class="inline-flex justify-between items-center w-full">
                      <div>
                        <BaseTooltip
                          :content="getImportEntryTooltip(importEntry)"
                        >
                          <i
                            class="fa-solid fa-file-csv mr-2 cursor-pointer"
                            :class="{
                              'text-orange-500': importEntry.attributes.status === importEntryStatuses.Pending,
                              'text-green-500': importEntry.attributes.status === importEntryStatuses.Successful,
                              'text-red-500': [
                                importEntryStatuses.Failed,
                                importEntryStatuses.Incomplete,
                                importEntryStatuses.PendingDelete,
                                importEntryStatuses.Deleted
                              ].includes(importEntry.attributes.status),
                            }"
                          />
                        </BaseTooltip>
                        <span>
                          {{ getImportName(importEntry) }}
                          <span class="font-light no-underline">
                            ({{ formatExportDateTime(importEntry) }})
                          </span>
                      </span>
                      </div>
                      <BaseTooltip
                        v-if="![
                          importEntryStatuses.Failed,
                          importEntryStatuses.Incomplete,
                          importEntryStatuses.PendingDelete,
                          importEntryStatuses.Deleted
                        ].includes(importEntry.attributes.status)"
                        :content="$t('Delete imported data')"
                      >
                        <XCircleIcon
                          class="circle-remove h-4 w-4 cursor-pointer text-red-300 hover:text-red-500"
                          aria-hidden="true"
                          @click.stop="confirmDelete(importEntry)"
                        />
                      </BaseTooltip>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseEntityForm>
</template>
<script>
import DataImportForm from "@/modules/accounts/components/DataImportForm.vue";
import NoImports from "@/modules/accounts/components/NoImports.vue"
import { XCircleIcon } from '@heroicons/vue/outline'

import { capitalize } from 'lodash-es'
import {
  importEntryStatuses,
} from "@/modules/accounts/utils/importUtils"
import {
  useDataImporter,
} from '@/modules/accounts/composables/useDataImporter';

export default {
  components: {
    NoImports,
    XCircleIcon,
    DataImportForm
  },
  setup() {
    const {
      getImportEntries,
      loadingImportEntries,
      importEntries,
    } = useDataImporter()

    return {
      getImportEntries,
      loadingImportEntries,
      importEntries,
    }
  },
  data() {
    return {
      importEntryStatuses,
    }
  },
  created() {
    this.getImportEntries()
  },
  methods: {
    capitalize,
    getImportName(importEntry) {
      const entityTypesNameMapping = {
        "group": this.$t('Groups'),
        "user": this.$t('People'),
        "task": this.$t('Tasks'),
        "project": this.$t('Projects'),
      }

      return entityTypesNameMapping[importEntry.attributes.entity_type] || importEntry.attributes.entity_type
    },
    getImportEntryTooltip(importEntry) {
      if (importEntry.attributes.status === importEntryStatuses.Incomplete) {

        if (importEntry.attributes.entity_type === "project") {
          return this.$t('Import failed - Not enough active projects available.')
        }

        if (importEntry.attributes.entity_type === "user") {
          return this.$t('Import failed - Not enough Creator seats available.')
        }
      }

      return capitalize(importEntry.attributes.status)
    },
    formatExportDateTime(importEntry) {
      return this.$formatDate(
        importEntry.attributes.created_at,
        null,
        /* lowerCaseMeridians */ true,
        /* withTime */ true
      )
    },
    async confirmDelete(importEntry) {
      const confirmed = await this.$deleteConfirm({
        title: this.$t("Delete Imported Data"),
        description: this.$t(
          "Are you sure? This will permanently delete data imported for this entry."
        ),
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch("accounts/deleteImportedData", { importEntry });

        this.$store.dispatch('users/getData')

        this.getImportEntries()

        this.$success(this.$t("Data deleted successfully"));
      } catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(this.$t("Could not delete imported data!"));
        throw err;
      }
    }
  }
}
</script>
