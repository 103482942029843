<template>
  <div class="relative">
    <div
      v-if="sortedAndFilteredNotes.length"
      class="bg-primary-500 min-w-[25px] z-2 rounded-full text-white absolute p-1 -top-3 right-3 text-xs text-center items-center"
    >
      {{ sortedAndFilteredNotes.length }}
    </div>
    <div
      id="note-list-container"
      class="max-h-[268px] md:max-h-[unset] md:h-20 flex-col-grow overflow-auto rounded-lg"
    >
      <div
        class="divide-y divide-gray-200 bg-white"
        :class="{
          'rounded-lg shadow border border-gray-200': !$useNewLayout,
        }"
      >
        <NoProjectNotes v-if="sortedAndFilteredNotes.length === 0 && !notesLoading.all && !notesLoading.create" />

        <template v-if="sortedAndFilteredNotes.length === 0 && notesLoading.all">
          <ProjectNoteLoading v-for="i in loadingItems" :key="i" />
        </template>

        <draggable
          v-model="sortedAndFilteredNotes"
          :animation="200"
          :class="{
            'divide-y divide-gray-200': !$useNewLayout,
          }"
          ghost-class="ghost-card"
          group="notes"
          handle=".fa-grip-dots"
          item-key="id"
          tag="div"
        >
          <template #item="{element}">
            <ProjectNoteItem
              :id="`project-note-${element.id}`"
              :key="element.id"
              :can-reorder="canReorder"
              :note="element"
            />
          </template>
        </draggable>

        <ProjectNoteLoading v-if="notesLoading.create" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ProjectNoteItem from '@/modules/projects/components/projectNotes/ProjectNoteItem.vue'
import ProjectNoteLoading from '@/modules/projects/components/projectNotes/ProjectNoteLoading.vue'
import NoProjectNotes from "@/modules/accounts/components/NoProjectNotes.vue";
import draggable from "vuedraggable/src/vuedraggable"
import { get } from 'lodash-es'
import { filterEntities, sortEntities } from '@/modules/common/utils/entityUtils'
import axios from 'axios'
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from "vue-router";
import { ApiFilter, Note, SortFilter } from "@/modules/common/commonTypes";
import Data = API.Data;
import { notesMainPath } from "@/modules/projects/utils/noteUtils";

const store = useStore()
const route = useRoute()
const router = useRouter()

const loadingItems = ref(5)

const notesLoading = computed(() => store.state.projects.notesLoading)
const searchTerm = computed(() => route.query?.search || '')
const notesApiFilters = computed<ApiFilter[]>(() => store.getters['filters/targetApiFilters'](EntityTargetTypes.PROJECT_NOTES) || [])
const notesColumns = computed(() => store.state.projects.notesColumns)
const projectNotes = computed(() => store.state.projects.notes)

const sortArray = computed<SortFilter[]>(() => {
  const sorts = notesApiFilters.value.find(f => f.key === 'sorts')?.value || []
  if (sorts && !Array.isArray(sorts)) {
    return [sorts] as SortFilter[]
  }
  return sorts as SortFilter[]
})

const sortedAndFilteredNotes = computed({
  get() {
    const notes = [...projectNotes.value]
    const filterParams = {
      data: notes,
      entityColumns: notesColumns.value,
      allFilters: notesApiFilters.value,
      extraFilters: [],
      extraCondition: (t: any) => {
        const notes = get(t, 'attributes.notes', '') || ''
        return notes.toLowerCase().includes((searchTerm.value as string).toLowerCase())
      }
    }
    const filteredNotes = filterEntities(filterParams)

    return sortEntities({
      data: filteredNotes,
      entityColumns: notesColumns.value,
      sortArray: sortArray.value,
    })
  },
  set(notes) {
    reorderProjectNotes(notes)
  },
})

watch(sortedAndFilteredNotes, (val) => {
  if (!route.path.includes('/notes')) {
    return
  }
  const noteId = route.params.noteId
  const projectId = route.params.id
  if (!projectId) {
    router.replace('/projects')
  }

  if (!val.length || noteId) {
    return
  }

  router.replace({
    path: `/${notesMainPath.value}/${projectId}/notes/${val[0].id}`,
    query: {
      ...route.query
    }
  })
}, { immediate: true })

const canReorder = computed(() => {
  const hasFilters = notesApiFilters.value.some(el => el.key === 'filters')
  const isOrderSort = sortArray.value?.[0]?.column === 'order'
  return isOrderSort && !hasFilters
})

async function reorderProjectNotes(notes: Data<Note>[]) {
  const isAscOrderSort = sortArray.value[0].order === 'asc'
  const notesLength = notes.length
  const orderedNotes = notes.map((note, index) => {
    const order = isAscOrderSort ? index + 1 : notesLength - index
    note.attributes.order = order
    return {
      order,
      id: +note.id,
    }
  })
  await axios.post(`/restify/project-notes/bulk/update`, orderedNotes)
}
</script>
