<template>
  <div
    :class="{
      'bg-white overflow-hidden rounded-lg shadow': !$useNewLayout,
      'border-b border-gray-200': $useNewLayout,
    }"
  >
    <div class="px-6 py-3 mb-6 border-b border-gray-200 flex items-center justify-between">
      <div>
        <h2 class="font-bold text-gray-700">
          {{ $t('Inbox') }}
        </h2>
      </div>
      <div class="flex items-center gap-2 relative">
        <DataSyncingIndicator
          v-if="processingBulkAction"
          class="mt-0"
        />
        <NotificationsOptions
          v-if="isSelectionEnabled"
          :notifications="filteredNotifications"
          @processing-bulk-action="processingBulkAction = $event"
        />
        <BaseTooltip
          :content="$t('Toggle selection enabled')"
        >
          <BaseRoundedCheckbox
            v-model="isSelectionEnabled"
            size="sm"
            class="mb-1 block"
          />
        </BaseTooltip>
      </div>
    </div>
    <div
      class="flow-root -mt-6 overflow-y-auto"
    >
      <NoUnreadNotifications v-if="filteredNotifications.length === 0 && !notificationsLoading"/>
      <ul
        ref="inboxList"
        tag="ul"
        class="divide-y divide-gray-200 max-h-60 overflow-y-auto"
        :class="{
          'sm:max-h-[calc(100vh-191px)]': $useNewLayout,
          'sm:max-h-[calc(100vh-260px)]': !$useNewLayout,
        }"
      >
        <template v-if="notificationsLoading">
          <LoadingNotification v-for="i in placeholderCount" :key="i"/>
        </template>

        <NotificationListItem
          v-for="notification of filteredNotifications"
          :key="notification.id"
          :notification="notification"
        />
        <div
          v-if="filteredNotifications.length >= perPage"
          class="flex justify-center items-center"
          role="button"
          @click="viewMore"
        >
          <base-button
            :loading="viewMoreLoading"
            :has-focus="false"
            variant="primary-link"
          >
            {{ $t('View More') }}
          </base-button>
        </div>
      </ul>

    </div>
  </div>
</template>
<script>
import { sleep } from '@/modules/common/utils/commonUtils.js'
import NotificationListItem from '@/modules/accounts/components/NotificationListItem.vue'
import NoUnreadNotifications from '@/modules/accounts/components/NoUnreadNotifications.vue'
import NotificationsOptions from '@/modules/accounts/components/NotificationsOptions.vue'
import LoadingNotification from '@/modules/accounts/components/notifications/LoadingNotification.vue'

const DefaultPerPage = 50

export default {
  components: {
    NotificationsOptions,
    NoUnreadNotifications,
    NotificationListItem,
    LoadingNotification,
  },
  data() {
    return {
      placeholderCount: 8,
      perPage: DefaultPerPage,
      viewMoreLoading: false,
      processingBulkAction: false,
    }
  },
  computed: {
    notificationsLoading() {
      return this.$store.state.notifications.notificationsLoading
    },
    filteredNotifications() {
      const notifications = this.notifications?.slice(0, this.perPage)
      return notifications.length ? notifications.sort((a, b) => !!(a.is_pinned) > !!(b.is_pinned) ? -1 : 1) : []
    },
    notifications() {
      return this.$store.state.notifications.notifications?.data || []
    },
    openedNotification: {
      get() {
        return this.$store.state.notifications.openedNotification
      },
      set(value) {
        this.$store.commit('notifications/setOpenedNotification', value)
      },
    },
    isSelectionEnabled: {
      get() {
        return this.$store.state.notifications.isNotificationSelectionEnabled
      },
      set(value) {
        if (!value) {
          this.selectedNotifications = []
        }
        this.$store.commit('notifications/setIsNotificationSelectionEnabled', value)
      },
    },
    selectedNotifications: {
      get() {
        return this.$store.state.notifications.selectedNotifications
      },
      set(value) {
        this.$store.commit('notifications/setSelectedNotifications', value)
      },
    },
  },
  methods: {
    tryOpenFirstNotification(notifications) {
      this.selectedNotifications = []
      this.openedNotification = notifications?.[0]

      if (!this.$refs.inboxList) {
        return
      }
      this.$refs.inboxList.scrollTop = 0
    },
    async viewMore() {
      this.viewMoreLoading = true

      await sleep(100)

      this.viewMoreLoading = false
      this.perPage += DefaultPerPage
    },
  },
  watch: {
    notifications: {
      immediate: true,
      handler(value) {
        this.tryOpenFirstNotification(value)
      },
    },
  },
}
</script>
