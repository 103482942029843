<template>
  <button
      v-bind="$attrs"
      :class="buttonClass"
      :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>
<script lang="ts" setup>
import {computed} from "vue";
import { cn } from "@/components/html/util/tailwindUtils";

const props = defineProps({
  isActive: Boolean,
  disabled: Boolean,
  className: String,
})
const buttonClass = computed(() => {
  return cn(
      'flex items-center gap-2 p-1.5 text-sm font-medium text-neutral-500 dark:text-neutral-400 text-left bg-transparent w-full rounded',
      !props.isActive && !props.disabled,
      'hover:bg-neutral-100 hover:text-neutral-800 dark:hover:bg-neutral-900 dark:hover:text-neutral-200',
      props.isActive && !props.disabled && 'bg-neutral-100 text-neutral-800 dark:bg-neutral-900 dark:text-neutral-200',
      props.disabled && 'text-neutral-400 cursor-not-allowed dark:text-neutral-600',
  )
})
</script>
