<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-2xl rounded-lg bg-white relative"
    @close="$emit('close')"
  >
    <BaseFormHeader
      :title="title"
      :subtitle="description"
      :is-dialog-form="true"
    >
      <template #content="">
        <BaseTutorialLink
          v-if="tutorials[props.feature] && !isAppSumoSubscription"
          :name="tutorials[props.feature]?.tutorial"
          class="ml-1"
        >
          <span class="underline text-gray-500">
            {{ tutorials[props.feature]?.label || $t('Click here to learn more about this') }}
          </span>
        </BaseTutorialLink>
      </template>
    </BaseFormHeader>
    <template v-if="isAppSumoSubscription || !canUpgrade">
      <BaseAlert
        :dismissable="false"
        class="px-4 py-6"
      >
        <div v-html="contactMessage" />
      </BaseAlert>
    </template>
    <BaseForm
      v-else
      v-slot="{ meta }"
    >
      <div class="p-6 text-sm text-gray-500 space-y-6">
        <template
          v-if="requireUpgrade"
        >
          <div>
            {{
              $tc('creator seat quantity message', {
                usedSeatCount: usedSeatCount,
                minSeatCount: minSeatCount,
              })
            }}
          </div>
          <div class="grid grid-cols-3 gap-4">
            <BaseInput
              v-focus
              v-model="quantityModel"
              :label="$t('Creator Seats')"
              :placeholder="$t('Creator Seats')"
              :name="$t('Creator Seats')"
              :rules="`required|min_value:${minSeatCount}`"
              :info-text="$tc(`creator seat count`, {
                seatCount: usedSeatCount,
                minSeatCount: minSeatCount,
              })"
              type="number"
              layout="vertical"
              id="creator_seats"
            />
            <div>
              <span class="text-3xl font-extrabold text-gray-900">
                {{ getFormattedPrice(monthlyPricePerSeat) }}
              </span>
              <p class="whitespace-pre-line">
                {{
                  $tc('price per person', {
                    yearlyPrice: getFormattedPrice(yearlyPricePerSeat)
                  })
                }}
              </p>
            </div>
            <div>
              <span class="text-3xl font-extrabold text-gray-900">
                {{
                  getFormattedPrice(totalMonthlyPrice)
                }}
              </span>
              <p class="whitespace-pre-line">
                {{
                  $tc('total upgrade price', {
                    yearlyPrice: getFormattedPrice(totalYearlyPrice)
                  })
                }}
              </p>
            </div>
          </div>
        </template>
        <div v-else>
          <BaseInput
            v-focus
            v-model="quantityModel"
            :label="$t('Total Creator Seats')"
            :placeholder="$t('Total Creator Seats')"
            :name="$t('Total Creator Seats')"
            :rules="`required|min_value:${minSeatCount}`"
            :info-text="$tc(`creator seat count`, {
              minSeatCount: minSeatCount,
              usedSeatCount: usedSeatCount,
            })"

            type="number"
            layout="horizontal"
            id="creator_seats"
          />
        </div>

        <div class="flex justify-between space-x-4">
          <template v-if="requireUpgrade">
            <BaseButton
              block
              type="submit"
              :disabled="!meta.valid"
              :loading="loading && chosenPlan?.identifier === paidPlanIdentifiers.Monthly"
              @click="onPayMonthly"
            >
              {{ $t(`Pay Monthly`) }}
            </BaseButton>
            <BaseButton
              block
              type="submit"
              :disabled="!meta.valid"
              :loading="loading && chosenPlan?.identifier === paidPlanIdentifiers.Yearly"
              @click="onPayYearly"
            >
              {{ $t(`Pay Yearly`) }}
              <span class="ml-2">
                ({{
                  $tc('save yearly discount', {
                    discount: getFormattedPrice(yearlyDiscount)
                  })
                }})
              </span>
            </BaseButton>
          </template>
          <template v-else>
            <BaseButton
              block
              type="submit"
              :disabled="!meta.valid"
              :loading="loading"
              @click="onBuySeats"
            >
              {{ $t(`Update number of user seats`) }}
            </BaseButton>
          </template>
        </div>
        <div class="text-xs">
          <div
            v-for="paragraph in upgradeParagraphs"
            :key="paragraph"
          >
            {{ paragraph }}
          </div>
        </div>
        <div
          v-if="isFreePlan"
          class="bg-gray-50 border rounded-lg p-4"
        >
          <span class="font-bold text-gray-700">
            {{ $t(`With the Pro plan you'll also get these great features:`) }}
          </span>
          <div class="grid grid-cols-2 sm:grid-cols-3 gap-2 mt-4 border-gray-200">
            <div
              v-for="feature in premiumFeatures"
              :key="feature.title"
            >
              <i class="fa-solid fa-check mr-1 text-base text-primary-500" />
              <a
                v-if="feature.link"
                :href="feature.link"
                class="hover:text-gray-600 underline"
                target="_blank"
                rel="noopener"
              >
                {{ feature.title}}
              </a>
              <span v-else>{{ feature.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </BaseForm>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
// Helpers
import i18n from "@/i18n.js"
import { PropType, computed, ref } from 'vue';
import { error, success } from '@/components/common/NotificationPlugin';
import { humanReadableSize } from '@/modules/common/utils/fileUtils';
import { Plan } from "@/modules/accounts/types/accountTypes";
import { formatPrice } from "@/plugins/formatPrice";

// Composables
import {
  useAccountLimits,
} from "@/modules/auth/composables/useAccountLimits";
import useCan, { AccountPermissions } from "@/modules/common/composables/useCan";
import { useStore } from 'vuex';
import BaseFormHeader from "@/components/form/BaseFormHeader.vue";

const store = useStore()
const {
  isFreePlan,
  isAppSumoSubscription,
  accountLimits,
  subscription
} = useAccountLimits()

const props = defineProps({
  feature: {
    type: String as PropType<AccountPermissions>,
    required: true
  }
})

const emit = defineEmits(['close'])

const isTenantOwner = computed(() => {
  return store.getters['auth/isTenantOwner']
})

const canUpgrade = computed(() => {
  return isTenantOwner.value
})

function getFormattedStorage(value: number | string) {
  return humanReadableSize(
    value,
    /* decimals */ 2,
    /* preferMu */ 'GB'
  )
}

const requireUpgrade = computed(() => {
  return isFreePlan.value
})

const monthlyPricePerSeat = computed(() => {
  return 10
})

const yearlyPricePerSeat = computed(() => {
  return monthlyPricePerSeat.value * 10 - 1
})

const totalMonthlyPrice = computed(() => {
  return monthlyPricePerSeat.value * quantityModel.value
})

const totalYearlyPrice = computed(() => {
  return yearlyPricePerSeat.value * quantityModel.value
})

const yearlyDiscount = computed(() => {
  return (totalMonthlyPrice.value * 12) - totalYearlyPrice.value
})


const maxStorageFormatted = computed(() => {
  return getFormattedStorage(accountLimits.value.storage.available)
})

const maxAiTokensFormatted = computed(() => {
  // @ts-ignore
  let formatter = Intl.NumberFormat(i18n.locale, { notation: 'compact' })
  const available = accountLimits.value.aiTokens.available || 0
  const availableFormatted = formatter.format(available)

  return availableFormatted
})

const features = computed(() => {
  const maxProjectTools = accountLimits.value.toolsPerProject.available
  const maxActiveProjects = accountLimits.value.projects.available

  let fileProofingDescription
  let aiAssitantDescription
  let speechToTextDescription

  if (isFreePlan.value) {
    fileProofingDescription =
      canUpgrade.value
        ? i18n.t(`file proofs free can upgrade`)
        : i18n.t(`file proofs free cannot upgrade`)

    aiAssitantDescription =
      canUpgrade.value
        ? i18n.t(`ai assistant free can upgrade`)
        : i18n.t(`ai assistant free cannot upgrade`)
    
    speechToTextDescription =
      canUpgrade.value
        ? i18n.t(`speech to text free can upgrade`)
        : i18n.t(`speech to text free cannot upgrade`)
  }
  else {
    fileProofingDescription =
      canUpgrade.value
        ? i18n.tc(`max proofs limit reached can upgrade`, {
          maxCount: accountLimits.value.proofs.available
        })
        : i18n.tc(`max proofs limit reached cannot upgrade`, {
          maxCount: accountLimits.value.proofs.available
        })

    aiAssitantDescription =
      canUpgrade.value
        ? i18n.tc(`max ai tokens limit reached can upgrade`, {
          maxCount: maxAiTokensFormatted.value
        })
        : i18n.tc(`max ai tokens limit reached cannot upgrade`, {
          maxCount: maxAiTokensFormatted.value
        })

    speechToTextDescription =
      canUpgrade.value
        ? i18n.tc(`max speech to text limit reached can upgrade`, {
          maxCount: accountLimits.value.speechToText.available
        })
        : i18n.tc(`max speech to text limit reached cannot upgrade`, {
          maxCount: accountLimits.value.speechToText.available
        })
  }

  return {
    [AccountPermissions.CreatorSeats]: {
      title: i18n.t(`Upgrade to invite more Creator users`),
      description: i18n.tc(`max creator seats message`, {
        maxCount: accountLimits.value.seats.available
      })
    },
    [AccountPermissions.CollaboratorsSeats]: {
      title: i18n.t(`Upgrade to invite more Collaborator users`),
      description: i18n.tc(`max collaborator seats message`, {
        maxCount: accountLimits.value.collaboratorsSeats.available
      })
    },
    [AccountPermissions.ActiveProjects]: {
      title: i18n.t(`Upgrade to create more projects`),
      description: canUpgrade.value
        ? i18n.tc(`max active projects can upgrade`, {
            maxCount: maxActiveProjects
          })
        : i18n.tc(`max active projects cannot upgrade`, {
            maxCount: maxActiveProjects
          })
    },
    [AccountPermissions.ProjectTools]: {
      title: i18n.t(`Upgrade to add more tools`),
      description: canUpgrade.value
        ? i18n.tc(`max project tools can upgrade`, {
            maxCount: maxProjectTools
          })
        : i18n.tc(`max project tools cannot upgrade`, {
            maxCount: maxProjectTools
          })
    },
    [AccountPermissions.Storage]: {
      title: i18n.t(`Storage limit reached`),
      description: canUpgrade.value
        ? i18n.tc(`max storage can upgrade`, {
            maxCount: maxStorageFormatted.value
          })
        : i18n.tc(`max storage cannot upgrade`, {
            maxCount: maxStorageFormatted.value
          })
    },
    [AccountPermissions.RecurringPayments]: {
      title: i18n.t(`Upgrade to use recurring payments`),
      description: canUpgrade.value
        ? i18n.t(`You don't have access to recurring payments. Upgrade to get access.`)
        : i18n.t(`This account does not have access to recurring payments. Ask the organization owner to upgrade to get access.`)
    },
    [AccountPermissions.TaskTimer]: {
      title: i18n.t(`Upgrade to use the timer`),
      description: canUpgrade.value
        ? i18n.t(`You don't have access to the timer. Upgrade to get access.`)
        : i18n.t(`This account does not have access to the timer. Ask the organization owner to upgrade to get access.`)
    },
    [AccountPermissions.GanttView]: {
      title: i18n.t(`Upgrade to use the gantt view`),
      description: canUpgrade.value
        ? i18n.t(`You don't have access to the gantt view. Upgrade to get access.`)
        : i18n.t(`This account does not have access to the gantt view. Ask the organization owner to upgrade to get access.`)
    },
    [AccountPermissions.FileProofing]: {
      title: i18n.t(`Upgrade to use file proofing`),
      description: fileProofingDescription
    },
    [AccountPermissions.AiAssistant]: {
      title: i18n.t(`Upgrade to use the AI assistant`),
      description: aiAssitantDescription
    },
    [AccountPermissions.CustomBranding]: {
      title: i18n.t(`Upgrade to use custom branding`),
      description: i18n.t(`You don't have access to use custom branding. Upgrade to get access.`)
    },
    [AccountPermissions.CustomDomain]: {
      title: i18n.t(`Upgrade to use a custom domain`),
      description: i18n.t(`You don't have access to use a custom domain. Upgrade to get access.`)
    },
    [AccountPermissions.CustomEmails]: {
      title: i18n.t(`Upgrade to use custom emails`),
      description: i18n.t(`You don't have access to use a custom email address. Upgrade to get access.`)
    },
    [AccountPermissions.PublicApi]: {
      title: i18n.t(`Upgrade to use the public API`),
      description: i18n.t(`You don't have access to use the public API. Upgrade to get access.`)
    },
    [AccountPermissions.Webhooks]: {
      title: i18n.t(`Upgrade to use webhooks`),
      description: i18n.t(`You don't have access to use webhooks. Upgrade to get access.`)
    },
    [AccountPermissions.CsvImporter]: {
      title: i18n.t(`Upgrade to use the data importer`),
      description: i18n.t(`You don't have access to use the import tool. Upgrade to get access. Alternatively contact us and we'd be happy to help with your migration.`)
    },
    [AccountPermissions.SpeechToText]: {
      title: i18n.t(`Upgrade to use Speech to Text`),
      description: speechToTextDescription
    },
  }
})

type AppSumoMessages = {
  [key in AccountPermissions | 'default']?: {
    title: string,
    description: string,
    contact?: string,
  }
}

const appSumoMessages = computed<AppSumoMessages>(() => {
  return {
    [AccountPermissions.CreatorSeats]: {
      title: i18n.t(`Upgrade to invite more Creator users`),
      description: i18n.t(`You have reached the limit of Creator seats on your AppSumo plan.`),
      contact: i18n.t('contact support to increase limit')
    },
    [AccountPermissions.FileProofing]: {
      title: i18n.t(`Upgrade to use file proofing`),
      description: i18n.t(`This feature is not available on the AppSumo plan.`),
      contact: i18n.t('contact support to use feature')
    },
    [AccountPermissions.AiAssistant]: {
      title: i18n.t(`Upgrade to use the AI assistant`),
      description: i18n.tc(`max ai tokens limit reached cannot upgrade`, {
        maxCount: maxAiTokensFormatted.value
      }),
      contact: i18n.t(`contact support to increase limit`),
    },
    [AccountPermissions.Storage]: {
      title: i18n.t(`Storage limit reached`),
      description:  i18n.tc(`max storage cannot upgrade`, {
        maxCount: maxStorageFormatted.value
      }),
      contact: i18n.t(`contact support to increase limit`),
    },
    default: {
      title: i18n.t(`Upgrade to use this feature`),
      description: i18n.t(`This feature is not available on the AppSumo plan.`),
      contact: i18n.t(`contact support by email`),
    }
  }
})


const title = computed(() => {
  if (isAppSumoSubscription.value && canUpgrade.value) {
    return appSumoMessages.value[props.feature]?.title || appSumoMessages.value.default?.title || ''
  }

  return features.value[props.feature].title
})

const description = computed(() => {
  if (isAppSumoSubscription.value && canUpgrade.value) {
    return appSumoMessages.value[props.feature]?.description || appSumoMessages.value.default?.description
  }

  return features.value[props.feature].description
})

const contactMessage = computed(() => {
  if (isAppSumoSubscription.value && canUpgrade.value) {
    return appSumoMessages.value[props.feature]?.contact || appSumoMessages.value.default?.contact
  }

  return i18n.t(`Contact the organization owner to upgrade the account.`)
})

type FeatureTutorials = {
  [key in AccountPermissions]?: {
    tutorial: string,
    label?: string,
  }
}
const tutorials: FeatureTutorials = {
  [AccountPermissions.AiAssistant]: {
    tutorial: 'ai-tokens',
    label: i18n.t(`See how the AI tokens work here.`),
  }
}

function getFormattedPrice(price: number) {
  return formatPrice(price, {
    currencyCode: 'usd',
    minimumFractionDigits: 0,
  })
}

const paidPlanParagraphs = [
  i18n.t(`Creator seats = $8 per month (or $80 annually)`),
  i18n.t(`Only pay for your internal team - Unlimited clients are free!`)
]

const freePlanParagraphs = [
  i18n.t(`Only pay for your internal team (people with a Creator, Creator Plus or Creator Admin role).`),
  i18n.t(`You can invite unlimited clients (people with a Collaborator or Collaborator Plus role) for free!`),
]

const upgradeParagraphs = computed(() => {
  if (isFreePlan.value) {
    return freePlanParagraphs
  }

  return paidPlanParagraphs
})

const premiumFeatures = [
  {
    title: i18n.t(`Unlimited projects`),
  },
  {
    title: i18n.t(`Custom branding`),
    link: 'https://kb.project.co/p/XAFmq_bhA0dmxR/Custom-Branding',
  },
  {
    title: i18n.t(`AI Assistant`),
    link: 'https://kb.project.co/p/tKB6FjY4sWbBT1/AI-Assistant',
  },
  {
    title: i18n.t(`Unlimited Collaborators`),
  },
  {
    title: i18n.t(`Custom domain`),
    link: 'https://kb.project.co/p/09uj7bKNGo94Fd/Custom-Domain',
  },
  {
    title: i18n.t(`Live project & task timer`),
    link: 'https://kb.project.co/p/j3YTbsauiLlO7l/Tasks',
  },
  {
    title: i18n.t(`Pay per Creator seat`),
  },
  {
    title: i18n.t(`Custom sender email`),
    link: 'https://kb.project.co/p/t-yFQ7KwbOLeLc/Custom-Emails',
  },
  {
    title: i18n.t(`Recurring payments`),
    link: 'https://kb.project.co/p/BsowoHyIpcbrcT/Payments',
  },
  {
    title: i18n.t(`50GB file storage/seat`),
    link: 'https://kb.project.co/p/79oh0Jqblu25K5/Files',
  },
  {
    title: i18n.t(`Webhooks & Zapier`),
    link: 'https://kb.project.co/p/eNlQFFzfJQZYaz/Public-API',
  },
  {
    title: i18n.t(`Satisfaction guarantee`),
    link: 'https://kb.project.co/p/XkCBsxYZIXW0xX/Satisfaction-guarantee',
  },
]

const paidPlanIdentifiers = {
  Monthly: 'pdc_pro_monthly',
  Yearly: 'pdc_pro_yearly',
}

const allPlans =  computed<Plan[]>(() => {
  return store.state.accounts.plans || []
})

const monthlyPlan = computed(() => {
  return allPlans.value.find(plan => plan.identifier === paidPlanIdentifiers.Monthly)
})

const yearlyPlan = computed(() => {
  return allPlans.value.find(plan => plan.identifier === paidPlanIdentifiers.Yearly)
})

const loading = ref(false)

const chosenPlan = ref<Plan | undefined>()

function onPayMonthly() {
  chosenPlan.value = monthlyPlan.value
  initCheckoutSession()
}

function onPayYearly() {
  chosenPlan.value = yearlyPlan.value
  initCheckoutSession()
}

async function initCheckoutSession() {
  if (!chosenPlan.value) {
    error('Something went wrong, please try again')

    throw ({
      message: 'No plan provided',
      allPlans: allPlans.value,
      plan: chosenPlan.value,
    })
  }

  try {
    loading.value = true
    const checkoutSession: any = await store.dispatch('accounts/getCheckoutSession', {
      plan: chosenPlan.value,
      data: {
        quantity: quantityModel.value,
      },
      params: {
        successUrl: window.location.href,
        cancelUrl: window.location.href,
      }
    })

    if (!checkoutSession?.data?.url) {
      error('Something went wrong, please try again')
      return;
    }

    window.location.href = checkoutSession.data.url
  }
  finally {
    loading.value = false
  }
}

const subscriptionQuantity = computed(() => {
  return subscription.value?.quantity || 0
})

const minSeatCount = computed(() => {
  const usedSeats = accountLimits.value.seats.used || 0
  const minCreatorCount = 1
  return Math.max(usedSeats, minCreatorCount)
})

const usedSeatCount = computed(() => {
  return accountLimits.value.seats.used || 0
})

const quantity = ref(Math.max(subscriptionQuantity.value, minSeatCount.value))

const quantityModel = computed({
  get() {
    return Number(quantity.value)
  },
  set(value) {
    quantity.value = value
  }
})

const currentPlan = computed(() => {
  return subscription.value?.plan
})

async function onBuySeats() {
  try {
    loading.value = true
    const data = {
      subscription_id: subscription.value.id,
      plan_id: currentPlan.value?.id,
      quantity: quantityModel.value,
    }

    await store.dispatch('accounts/updateSubscription', data)

    success(`${currentPlan.value?.nickname} Plan successfully updated.`)
    await store.dispatch('accounts/getSubscription', { forceFetch: true })
    emit('close')
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('Could not update your subscription.'))
  }
  finally {
    loading.value = false
  }
}

store.dispatch('accounts/getPlans')
</script>
