<template>
  <BaseTableCell :params="params">
    <div
      v-if="row?.id"
      class="flex justify-center items-center"
    >
      <i
        ref="dragHandle"
        class="fa fa-grip-dots cursor-move text-lg text-gray-300"
      />
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from "vue"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const dragHandle = ref(null)

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

onMounted(async () => {
  await nextTick()
  
  if (dragHandle.value) {
    props.params.registerRowDragger?.(dragHandle.value)
  }
})
</script>
