<template>
  <BaseTableCell :params="params">
    <div v-if="!dateValue">
      {{ $t('- -')  }}
    </div>
    <div
      v-else-if="!isCard || dateValue"
      :title="tooltip"
    >
      {{ formattedDate }}
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import i18n from "@/i18n"
import { PropType, computed, ref } from "vue"
import { get } from "lodash-es"
import { formatDate } from "@/modules/common/utils/dateUtils";
import { getRRuleFromString } from "@/modules/common/utils/recurrenceUtils";
import { ICellRendererParams } from "@ag-grid-community/core";

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams<any>>,
    default: () => ({})
  },
  // TODO: used for ProjectCard -> on migrate, add cardProps to colDef { emptyValuePlaceholder: 'N/A' }
  emptyValuePlaceholder: {
    type: String,
    default: ''
  },
  isCard: {
    type: Boolean,
    default: false
  },
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

let tooltip = ref('')

const dateValue = computed(() => {
  if (typeof columnParams.value?.getValue !== 'function') {
    return get(row.value, colDef.value.field)
  }
  
  let value = columnParams.value?.getValue(row.value)

  if (typeof value === 'string' && value.startsWith('F_')) {
    return value
  }

  return value
})

function formatStringDate(dateString: string) {
  if (!dateString) {
    return props.emptyValuePlaceholder
  }

  if (typeof dateString === 'string' && dateString.startsWith('F_')) {
    return dateString.substring(2)
  }

  return formatDate(dateString)
}

const formattedDate = computed(() => {
  if (!dateValue.value || typeof dateValue.value !== 'object') {
    return formatStringDate(dateValue.value)
  }
    
  if (dateValue.value.recurring) {
    const rrule = getRRuleFromString(row.value.attributes.recurrence)
    
    let recurrenceText = rrule.toText()
    recurrenceText = recurrenceText.charAt(0).toUpperCase() + recurrenceText.slice(1)
    const fromDate = formatDate(rrule.origOptions.dtstart?.toISOString())

    tooltip.value = i18n.tc('recurrence with from date', {
      recurrenceText,
      fromDate
    })
    
    return formatStringDate(dateValue.value.date)
  }

  const fromDate = formatStringDate(dateValue.value?.start_date)
  const toDate = formatStringDate(dateValue.value?.end_date)

  return `${fromDate} → ${toDate}`
})
</script>
