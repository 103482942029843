<template>
  <BaseCardsView
    :target="EntityTargetTypes.TASKS"
    :loading="tasksLoading"
    dataGetterPath="tasks/currentTasks"
    groupedDataGetter="tasks/groupedTasks"
    columnsGetter="tasks/tableColumns"
    :extraFilters="getExtraFilters()"
    :ignoredColumns="ignoredColumns"
  >
    <template #no-data>
      <NoTasks
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
    </template>
  </BaseCardsView>
</template>
<script lang="ts" setup>
// Components
import NoTasks from "@/modules/tasks/components/NoTasks.vue";

// Libs
import { computed } from "vue";

// Utils
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'
import { getExtraFilters } from "@/modules/tasks/utils/taskTableUtils"

// Composables
import { useStore } from "vuex";

const store = useStore()

const tasksLoading = computed(() => {
  return store.state.tasks.tasksLoading
})

const ignoredColumns = ['attributes.order', 'attributes.completed', 'attributes.visibility']
</script>
