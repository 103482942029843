<template>
  <BaseDropdown
   :options="availableOptions"
   @action="onAction"
   divide-items
   class="w-full profile-dropdown flex justify-center"
  >
    <slot>
      <div class="relative lg:inline-block text-left w-full">
        <BaseButton
          type="button"
          variant="white"
          block
          class="mt-2 lg:mt-0"
        >
          {{ $t('Options') }}
          <i class="fas fa-chevron-down mt-1 ml-2" />
        </BaseButton>
      </div>
    </slot>
   </BaseDropdown>
 </template>
 <script setup>
import i18n from "@/i18n.js"
import { computed } from "vue"
import { getContactLink, HELP_LINK } from "@/modules/common/utils/linkUtils.js";
import { BetaFeatures, useAccountFeatures } from "@/modules/auth/composables/useAccountFeatures";

// Composables
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useCan from "@/modules/common/composables/useCan";
import useLayout from "@/modules/common/composables/useLayout"
import { useAuth } from "@/modules/auth/composables/useAuth";

const store = useStore()
const router = useRouter()
const { can, actions } = useCan();

const emit = defineEmits([])

const { hasFeature } = useAccountFeatures()

const {
  useNewLayout,
} = useLayout()

const organizations = computed(() => {
  return store.state.auth.organizations || []
})

const userIsPartOfMultipleOrganizations = computed(() => {
  return organizations.value.length > 1
})

const { currentUser, currentTenant } = useAuth()

const options = computed(() => [
  {
    label: i18n.t('Your Settings'),
    path: `/profile`,
    icon: 'fa-solid fa-user text-gray-400'
  },
  {
    label: i18n.t('Account Settings'),
    path: `/account-settings`,
    requiresPermissionTo: actions.MANAGE_ACCOUNT_SETTINGS,
    icon: 'fa-solid fa-sliders text-gray-400'
  },
  {
    label: i18n.t('Integrations'),
    path: `/integrations`,
    requiresPermissionTo: actions.VIEW_INTEGRATIONS,
    icon: 'fa-solid fa-circle-nodes text-gray-400'
  },
  {
    label: i18n.t('Simulate Roles'),
    path: `/simulate-roles`,
    enabled: () => {
      return hasFeature(BetaFeatures.SimulateRoles) && can(actions.SIMULATE_ROLES)
    },
    icon: 'fa-solid fa-retweet text-gray-400'
  },
  {
    label: i18n.t('Switch Account'),
    path: `/select-organization`,
    icon: 'fa-solid fa-arrows-repeat text-gray-400',
    enabled: () => {
      return userIsPartOfMultipleOrganizations.value
    }
  },
  {
    label: useNewLayout.value
      ? i18n.t('Switch to classic layout')
      : i18n.t('Switch to new layout'),
    icon: 'fa-solid fa-table-layout text-gray-400',
    action() {
      useNewLayout.value = !useNewLayout.value
    },
    isNewFeature: !useNewLayout.value
  },
  {
    label: i18n.t('Knowledge base'),
    path: HELP_LINK,
    target: '_blank',
    icon: 'fa-solid fa-books text-gray-400',
    requiresPermissionTo: actions.ACCESS_HELP_CENTER
  },
  {
    label: i18n.t('Contact support'),
    path: getContactLink(currentUser.value, currentTenant.value),
    target: '_blank',
    icon: 'fa-solid fa-comments-alt text-gray-400',
    requiresPermissionTo: actions.ACCESS_HELP_CENTER
  },
  {
    label: i18n.t('Log out'),
    action: 'logout',
    icon: 'fa-solid fa-arrow-right-from-bracket text-gray-400'
  },
])

const optionActions = {
  async logout() {
    await store.dispatch('auth/logout');
    await router.push('/login');
  }
}

function onAction(action) {
  if (optionActions[action]) {
    optionActions[action]()
    return
  }

  emit(action)
}

const availableOptions = computed(() => {
  return options.value.filter(x => {
    if (x.enabled) {
      return x.enabled()
    }
    return !x.requiresPermissionTo || can(x.requiresPermissionTo)
  })
})
</script>
