import { useWindowSize } from '@vueuse/core'
import { computed } from 'vue'

export default function useMobileUtils() {
  const { width } = useWindowSize()

  const isMobileDevice = computed(() => {
    return width.value < 768
  })

  return {
    isMobileDevice,
  }
}
