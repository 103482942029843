import store from "@/store";
import i18n from "@/i18n";
import { getNoteName } from '@/modules/projects/utils/stripNotesHTML'

function getInvoiceTitle() {
  const invoice = store.state.payments.currentPaymentInvoice?.attributes
  return `${i18n.t(`Invoice `)} ${invoice?.invoice_number || ''}`
}

function getEmbedTitle() {
  const embedId = store.state.route.params.embedId
  const projectTools = store.state.projects.currentProject?.relationships?.tools
  const embedTool =  projectTools?.find(
    (tool) =>
      tool.pivots?.options?.entity_type === "embed" &&
      tool.pivots?.options?.entity_id == embedId
  );

  const { name, title} = embedTool?.pivots?.options || {}

  return name || title ||  ""
}

export function getRouteName(route) {
  const state = store.state
  route = route || state.route

  if (['Template Details', 'Project Details'].includes(route.name)) {
    return state.projects.currentProject?.attributes?.name || route.name
  }
  if (['Task Details', 'Edit Task'].includes(route.name)) {
    return state.tasks.currentTask?.attributes?.name || route.name
  }
  if (route.name === 'Template Gallery View') {
    return state.templateGallery.selectedTemplate?.attributes?.name || route.name
  }
  if (route.name === 'Project Notes') {
    const { notes = '' } = state.projects.currentProjectNote?.attributes || {}
    const title = getNoteName(notes)
    return `${i18n.t('Note')}: ${title || i18n.t('Untitled')}`
  }
  if (route.name === 'Project Embed') {
    return `${i18n.t('Embed')}: ${getEmbedTitle()}`
  }
  if (route.name === 'Proof Embed') {
    const fileProofs = state.proofs.proofs.data;
    const embedId = store.state.route.params?.proofId;
    const proof = fileProofs?.find((proof) => proof?.id === embedId);
    return `${proof?.attributes?.name || i18n.t('Proof Embed')}`
  }
  if (['Invoice', 'Payment Invoice'].includes(route.name)) {
    return getInvoiceTitle()
  }
  if (route.name === 'Project Custom Fields') {
    return i18n.t('Custom Fields')
  }

  return i18n.t(route.name)
}

export function isCurrentPageStarred() {
  const route = store.state.route
  if(!route?.fullPath) {
    return false
  }
  return store.getters['accounts/isPageStarred'](route.fullPath) && true
}
