<template>
  <img v-if="logo"
       :class="{
          'h-10 w-10': size === 'md',
          'h-14 w-14': size === 'lg',
          'h-20 w-20': size === 'xl',
       }"
       :src="logo"
       :alt="name"
       class="rounded-md object-cover"
  >
  <div v-else
       class="rounded-md uppercase text-white flex items-center justify-center tracking-wider leading-6"
       :class="[imageColor, {
         'h-10 w-10': size === 'md',
         'h-14 w-14': size === 'lg',
         'h-20 w-20': size === 'xl',
       }]"
  >
    {{ shortName }}
  </div>
</template>
<script>
import { getShortString, getStringColor } from "@/modules/projects/utils/projectHelpers.js";

export default {
  props: {
    project: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    name() {
      const name = this.project?.attributes?.name || ''
      return name.substring(0, 10)
    },
    logo() {
      return this.project?.attributes?.image
    },
    shortName() {
      return getShortString(this.name)
    },
    imageColor() {
      return getStringColor(this.name)
    }
  }
}
</script>
<style>
</style>
