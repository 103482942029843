<template>
  <div
    v-if="!isBlankDescription || can($actions.EDIT_PROJECTS)"
    ref="projDescriptionInput"
    class="project-description"
    :class="{
      'min-h-[46px]': !$attrs.disabled
    }"
  >

    <ProjectDescriptionOptions
      v-if="showOptions && (!$attrs.disabled || isSaving)"
      :project="project"
      @init-default="showOptions = false"
      @init-with-ai="initWithAI"
      @shortcuts-count-updated="$emit('shortcuts-count-updated', $event)"
    />
    <BaseInlineInput
      v-else
    >
      <template #default="{ triggerDescriptionUpdate }">
        <CollaborativeHtmlEditor
          ref="htmlEditor"
          v-model:mentions="mentions"
          v-model="model"
          :realTimeValue="project?.attributes?.description_collaboration"
          :initCommand="initEditorCommand"
          :placeholder="placeholder"
          :showPlaceholderOnlyWhenEditable="false"
          :projectId="project?.id !== undefined"
          :autofocus="false"
          :readonly="$attrs.disabled"
          :class="{
            'mt-4 mb-4': !$attrs.disabled || !!modelValue
          }"
          :real-time="!!project?.id"
          :real-time-doc-url="apiUrl"
          real-time-doc-field="description_collaboration"
          contentClass="text-gray-500"
          @blur="onBlur($event, triggerDescriptionUpdate)"
        />
      </template>
    </BaseInlineInput>
  </div>
</template>
<script>
// Components
import ProjectDescriptionOptions from "@/modules/projects/components/ProjectDescriptionOptions.vue";

// Utils
import axios from 'axios'
import { enableRealTimeCollaboration } from "@/modules/common/config";
import { EditorInitCommands } from '@/components/html/util/editorUtils'

export default {
  components: {
    ProjectDescriptionOptions
  },
  props: {
    project: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: String,
      default: ''
    },
    notifiable_user_ids: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      focusValue: this.modelValue,
      isFocused: false,
      initEditorCommand: null,
      showOptions: true,
    }
  },
  computed: {
    isBlankDescription() {
      return !this.modelValue?.replaceAll('<p></p>', '')?.trim()
    },
    apiUrl() {
      return `${axios.defaults.baseURL}/restify/projects/${this.project.id}`
    },
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        if (this.loading) {
          return
        }
        this.$emit('update:modelValue', value)
      }
    },
    mentions: {
      get() {
        return this.notifiable_user_ids
      },
      set(value) {
        this.$emit('update:notifiable_user_ids', value)
      }
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    placeholder() {
      if (this.$attrs.disabled) {
        return ''
      }

      if (this.isTemplatePath) {
        if (this.$isMobileDevice) {
          return this.$t('Enter template description...')
        }

        return this.$t('Enter template description here or press / for options')
      }

      if (this.$isMobileDevice) {
        return this.$t('Enter project description...')
      }

      return this.$t('Enter project description here or press / for options')
    }
  },
  mounted() {
    addEventListener("click", this.onClickEvent)
  },
  unmounted() {
    removeEventListener("click", this.onClickEvent)
  },
  watch: {
    isBlankDescription: {
      handler(value) {
        this.showOptions = value
      },
      immediate: true,
    },
    showOptions: {
      handler(value) {
        this.$emit('show-options-changed', value)
      },
      immediate: true,
    }
  },
  methods: {
    initWithAI() {
      this.initEditorCommand = EditorInitCommands.AiAssistant
      this.showOptions = false
    },
    onClickEvent(e) {
      if (!this.$refs?.projDescriptionInput) {
        return
      }

      if (this.$refs.projDescriptionInput.contains(e.target)) {
        this.onFocus(e)
        return
      }

      this.isFocused = false;
    },
    onBlur(e, triggerDescriptionUpdate) {
      const clickedEditorControls = !!e.event.relatedTarget
      if (clickedEditorControls) {
        return
      }
      const description = this.$refs.htmlEditor?.getHTML()
      if (description) {
        this.$emit('update:modelValue', description)
      }
      triggerDescriptionUpdate && triggerDescriptionUpdate()
    },
    onFocus(e) {
      const tippyBox = this.$refs.projDescriptionInput?.querySelector('.tippy-box')
      const clickedEditorControls = tippyBox && tippyBox.contains(e.target)

      if (this.isFocused || clickedEditorControls) {
        return;
      }

      this.isFocused = true;

      this.focusValue = this.model
    },
  },
}
</script>
<style lang="scss">
.project-description {
  .ProseMirror input[type=checkbox] {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    @apply border-2 text-primary-500 w-6 h-6 rounded-2xl align-baseline mt-0 mr-0 mb-1;
  }
}
</style>
