<template>
  <el-color-picker
    v-bind="$attrs"
    :predefine="predefineColors"
  />
</template>
<script>
import { ElColorPicker } from 'element-plus'

export default {
  components: {
    ElColorPicker
  },
  data() {
    return {
      predefineColors: [
        '#FFFFFF',
        '#D1D5DB',
        '#6B7280',
        '#EF4444',
        '#F59E0B',
        '#10B981',
        '#3B82F6',
        '#6366F1',
        '#8B5CF6',
        '#EC4899',
      ]
    }
  }
}
</script>
