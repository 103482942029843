<template>
  <el-tree-select
    v-model="model"
    :data="remappedData"
    :filter-node-method="filterNodeMethod"
    check-strictly
    filterable
    accordion
    :node-key="valueKey"
    class="w-full"
  />
</template>
<script lang="ts">
import { get } from 'lodash-es'
import { ElTreeSelect } from 'element-plus'
import { TreeSelectItem } from "@/modules/common/commonTypes";

export default {
  components: {
    ElTreeSelect,
  },
  props: {
    modelValue: {
      type: [Object, String, Number],
      default: () => undefined,
    },
    data: {
      type: Array,
      default: () => [],
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'attributes.name',
    },
    childrenKey: {
      type: String,
      default: 'relationships.children',
    },
    customFilterNodeMethod: {
      type: Function,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value: string | number | object) {
        this.$emit('update:modelValue', value)
      },
    },
    remappedData() {
      return this.data.map(this.remapDataItem)
    },
  },
  methods: {
    remapDataItem(item: any) {
      const children = get(item, this.childrenKey)?.map(this.remapDataItem) || []
      return {
        ...item,
        label: get(item, this.labelKey, ''),
        children,
      }
    },
    filterNodeMethod(value: string, data: TreeSelectItem) {
      if (this.customFilterNodeMethod) {
        return this.customFilterNodeMethod(value, data)
      }

      return data.label.toLowerCase().includes(value.toLowerCase())
    },
  },
}
</script>
