import TimeModuleLayout from "@/modules/time/layout/TimeModuleLayout.vue";
import TimeLayout from "@/modules/time/layout/TimeLayout.vue";
import TimeEntriesLayout from "@/modules/time/layout/TimeEntriesLayout.vue";
import AllocatedTimeLayout from "@/modules/time/layout/AllocatedTimeLayout.vue";
import TimeEntryAdd from '@/modules/time/pages/time-entry-add.vue';
import TimeListAll from "@/modules/time/pages/time-list-all.vue";
import TimeListActual from "@/modules/time/pages/time-list-actual.vue";
import TimeCardsActual from "@/modules/time/pages/time-cards-actual.vue";
import TimeListAllocated from "@/modules/time/pages/time-list-allocated.vue";
import TimeCardsAllocated from "@/modules/time/pages/time-cards-allocated.vue";
import { CREATE_TIME_ENTRIES, VIEW_TIME_ENTRIES } from "@/modules/common/enum/actionsEnum";

export default [
  {
    path: '/time',
    redirect: '/time/all',
    name: 'Time',
    component: TimeModuleLayout,
    children: [
      {
        path: '/time',
        redirect: '/time/all',
        name: 'Time Layout',
        component: TimeLayout,
        meta: {
          isEntityTypeSelect: true,
        },
        children: [
          {
            path: '/time/all',
            name: 'All Time',
            component: TimeListAll,
            meta: {
              requiresPermissionTo: VIEW_TIME_ENTRIES,
              hideBreadCrumb: true
            }
          },
        ]
      },
      {
        path: '/time/allocated',
        name: 'Allocated Time Layout',
        component: AllocatedTimeLayout,
        redirect: '/time/allocated/list',
        meta: {
          isEntityTypeSelect: true,
        },
        children: [
          {
            path: 'list',
            name: 'Allocated Time',
            component: TimeListAllocated,
            meta: {
              requiresPermissionTo: VIEW_TIME_ENTRIES,
              isViewType: true
            },
          },
          {
            path: 'card',
            name: 'Allocated Time Cards',
            component: TimeCardsAllocated,
            meta: {
              requiresPermissionTo: VIEW_TIME_ENTRIES,
              isViewType: true
            },
          },
        ]
      },
      {
        path: '/time/actual',
        name: 'Time Entries Layout',
        component: TimeEntriesLayout,
        redirect: '/time/actual/list',
        meta: {
          isEntityTypeSelect: true,
        },
        children: [
          {
            path: 'list',
            name: 'Actual Time',
            meta: {
              requiresPermissionTo: VIEW_TIME_ENTRIES,
              isViewType: true
            },
            component: TimeListActual,
          },
          {
            path: 'card',
            name: 'Actual Time Cards',
            component: TimeCardsActual,
            meta: {
              requiresPermissionTo: VIEW_TIME_ENTRIES,
              isViewType: true
            },
          },
        ]
      },
      {
        path: '/time/add',
        name: 'New Time Entry',
        component: TimeEntryAdd,
        meta: {
          requiresPermissionTo: CREATE_TIME_ENTRIES
        }
      },
    ]
  },
]
