<template>
  <div class="flex flex-col h-full">
    <TimeEntryDialog
      v-show="showCreateTimeEntryDialog && !$route.query.timeEntryId"
      v-model="showCreateTimeEntryDialog"
      key="time-entry-create"
      @close="redirectToList"
      @save="onTimeEntryCreate"
    />
    <TimeEntryDialog
      v-show="showEditTimeEntryDialog && $route.query.timeEntryId"
      v-model="showEditTimeEntryDialog"
      :timeEntry="currentTimeEntry"
      :title="$t(`Edit ${currentTimeEntry?.attributes?.name}`)"
      key="time-entry-edit"
      @close="redirectToList"
      @save="onTimeEntryEdit"
    />
    <AllocatedTimeDialog
      v-show="showCreateAllocatedTimeDialog && !$route.query.allocatedTimeId"
      v-model="showCreateAllocatedTimeDialog"
      key="allocated-time-create"
      @close="redirectToList"
      @save="onAllocatedTimeCreate"
    />
    <AllocatedTimeDialog
      v-show="showEditAllocatedTimeDialog && $route.query.allocatedTimeId"
      v-model="showEditAllocatedTimeDialog"
      :allocatedTime="currentAllocatedTime"
      :title="$t(`Edit ${currentAllocatedTime?.attributes?.name}`)"
      key="allocated-time-edit"
      @close="redirectToList"
      @save="onAllocatedTimeEdit"
    />
    <TopFilters
      :columns="topFilterColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search time...')"
    >
      <template
        v-if="!isCurrentProjectClosed && hasActions"
        #action
      >
        <div class="flex mr-4">
          <RequiresPermissionTo :action="$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS">
            <BaseButton
              v-if="isAllocatedViewActive || isAllViewActive"
              block
              @click="showCreateAllocatedTimeDialog = true"
            >
              {{ $t('Allocate Time') }}
            </BaseButton>
          </RequiresPermissionTo>
          <RequiresPermissionTo :action="$actions.CREATE_TIME_ENTRIES">
            <BaseButton
              v-if="isActualViewActive || isAllViewActive"
              :class="{
                'lg:ml-4 mt-2 lg:mt-0 mr-2': isAllViewActive
              }"
              block
              @click="showCreateTimeEntryDialog = true"
            >
              {{ $t('New Time Entry') }}
            </BaseButton>
          </RequiresPermissionTo>
        </div>
      </template>

      <TimeViewTypes
        v-if="$isMobileDevice && !isTemplatePath"
      />

    </TopFilters>

    <DataSyncingIndicator
      v-if="shouldDisplaySyncIndicator"
      dataView
    />
    <slot>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
    </slot>
  </div>
</template>
<script>
import TopFilters from "@/modules/filters/components/TopFilters.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import TimeEntryDialog from "@/modules/time/components/TimeEntryDialog.vue";
import AllocatedTimeDialog from "@/modules/time/components/AllocatedTimeDialog.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"
import TimeViewTypes from "@/modules/time/components/TimeViewTypes.vue";

import { cloneDeep } from "lodash-es"

export default {
  components: {
    TimeEntryDialog,
    AllocatedTimeDialog,
    BaseButton,
    TopFilters,
    DataSyncingIndicator,
    TimeViewTypes,
  },
  data() {
    return {
      showCreateTimeEntryDialog: false,
      showEditTimeEntryDialog: false,
      showCreateAllocatedTimeDialog: false,
      showEditAllocatedTimeDialog: false,
    }
  },
  computed: {
    isActualViewActive() {
      return this.$route.path.endsWith('time/actual')
    },
    isAllocatedViewActive() {
      return this.$route.path.endsWith('time/allocated')
    },
    isAllViewActive() {
      return this.$route.path.endsWith('time/all')
    },
    hasActions() {
      if (this.isAllocatedViewActive) {
        return this.can(this.$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS)
      }

      if (this.isActualViewActive) {
        return this.can(this.$actions.CREATE_TIME_ENTRIES)
      }

      return this.can(this.$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS) ||
        this.can(this.$actions.CREATE_TIME_ENTRIES)
    },
    availableFilters() {
      if (this.isActualViewActive) {
        return ['filter', 'sort', 'group', 'chart', 'columns', 'other', 'totalRow']
      }

      if (this.isAllocatedViewActive) {
        return ['filter', 'sort', 'group', 'chart', 'columns', 'other', 'totalRow']
      }

      return ['filter', 'sort', 'other', 'totalRow']
    },
    topFilterColumns() {
      if (this.isActualViewActive) {
        return this.timeEntryColumns
      }
      if (this.isAllocatedViewActive) {
        return this.allocatedTimeColumns
      }

      // Return common columns
      const columns = []
      for (const timeEntryColumn of this.timeEntryColumns) {
        const allocatedColumn = this.allocatedTimeColumns.find(x => x.prop === timeEntryColumn.prop)

        if (!allocatedColumn) {
          continue
        }

        const column = cloneDeep(timeEntryColumn)

        if ([this.$t('Group'), this.$t('Person')].includes(column.name)) {
          column.filterBy.props.urlParams = {
            hasTimeAllocationsOrTimeEntries: true
          }
        }

        columns.push(column)
      }

      return columns
    },
    shouldDisplaySyncIndicator() {
      if (this.isActualViewActive) {
        return this.timeEntries.length && this.$store.state.time.timeEntriesLoading
      }

      if (this.isAllocatedViewActive) {
        return this.allocatedTime.length && this.$store.state.time.allocatedTimeLoading
      }

      return this.timeEntries.length && this.allocatedTime.length && (this.$store.state.time.timeEntriesLoading || this.$store.state.time.allocatedTimeLoading);
    },
    // Time entries
    timeEntries() {
      return this.$store.state.time.timeEntires?.data || []
    },
    currentTimeEntry() {
      return this.$store.state.time.currentTimeEntry || {}
    },
    timeEntryColumns() {
      return this.$store.getters['time/timeEntryActiveColumns'] || []
    },

    // Allocated time
    allocatedTime() {
      return this.$store.state.time.allocatedTime?.data || []
    },
    currentAllocatedTime() {
      return this.$store.state.time.currentAllocatedTime || {}
    },
    allocatedTimeColumns() {
      return this.$store.getters['time/allocatedTimeActiveColumns'] || []
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    addTimeEntryTrigger() {
      return this.$store.state.time.addTimeEntryTrigger
    },
    addAllocatedTimeTrigger() {
      return this.$store.state.time.addAllocatedTimeTrigger
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    onTimeEntryCreate() {
      this.showCreateTimeEntryDialog = false
    },
    onTimeEntryEdit() {
      this.redirectToList()
    },
    onAllocatedTimeCreate() {
      this.showCreateAllocatedTimeDialog = false
    },
    onAllocatedTimeEdit() {
      this.redirectToList()
    },
    redirectToList() {
      this.showCreateTimeEntryDialog = false
      this.showEditTimeEntryDialog = false
      this.showCreateAllocatedTimeDialog = false
      this.showEditAllocatedTimeDialog = false

      const query = {
        ...this.$route.query
      }
      delete query.timeEntryId
      delete query.allocatedTimeId
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
  },
  watch: {
    '$route.query.timeEntryId': {
      immediate: true,
      async handler(timeEntryId) {
        if (!timeEntryId || !this.can(this.$actions.EDIT_TIME_ENTRIES)) {
          this.redirectToList()
          return
        }
        await this.$store.dispatch('time/getTimeEntryById', { id: timeEntryId })
        this.showEditTimeEntryDialog = true
      }
    },
    '$route.query.allocatedTimeId': {
      immediate: true,
      async handler(allocatedTimeId) {
        if (!allocatedTimeId || !this.can(this.$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS)) {
          this.redirectToList()
          return
        }
        await this.$store.dispatch('time/getAllocatedTimeById', { id: allocatedTimeId })
        this.showEditAllocatedTimeDialog = true
      }
    },
    addTimeEntryTrigger() {
      this.showCreateTimeEntryDialog = true
    },
    addAllocatedTimeTrigger() {
      this.showCreateAllocatedTimeDialog = true
    }
  }
}
</script>
