<template>
  <BaseEntityForm
    v-loading="$store.state.users.currentUserLoading"
    :title="$t('Edit Person')"
    :subtitle="$t('Edit this users role & group(s) below.')"
    width-class="max-w-2xl"
    @submit="onSubmit"
  >
    <template #default="{ meta, errors }">
      <WarningAlert
        :dismissable="false"
        class="border border-yellow-500"
      >
        <template v-if="isCurrentUser">
          <span>{{ $t(`In order to change your profile image or name go to`) }}</span>
          <router-link to="/profile">
            <span class="underline ml-1">{{ $t(`Your Settings`) }}</span>
          </router-link>
        </template>
        <span v-else>
          {{ $t(`Only the person themselves can edit their name and profile image.`) }}
        </span>
      </WarningAlert>

      <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start">
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {{ $t('Photo/Icon') }}
        </label>
        <div class="sm:mt-px sm:pt-1 sm:col-span-3">
          <label class="text-sm text-gray-400 hover:text-gray-500">
            <UserLogo
              :user="user"
              :disableTooltip="true"
              :previewType="UserPreviewTypes.Disabled"
              class="rounded-lg cursor-pointer"
              size="sm"
            />
          </label>
        </div>
      </div>

      <BaseInput
        :label="$t('Name')"
        :name="$t('Name')"
        layout="horizontal"
        id="name"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3 text-gray-500">
          {{ model.name || model.email }}
        </div>
      </BaseInput>

      <BaseInput
        v-if="isCurrentUserRoleGreaterOrEqual"
        :label="$t('Role')"
        :placeholder="$t('Enter project status...')"
        :name="$t('Role')"
        layout="horizontal"
        id="role"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <BaseTooltip
            :content="$t('You are not allowed to change your own role.')"
            :disabled="!isCurrentUser"
          >
            <UserRoleSelect
              v-model="model.role"
              v-focus
              :disabled="isCurrentUser"
              class="w-full"
            />
          </BaseTooltip>
        </div>
      </BaseInput>
      <CustomFieldsEntityForm
        v-if="canEditCustomFieldValues"
        v-model="model.custom_fields"
        :col-span="3"
        :column-count="4"
        entity-type="user"
      />
      <BaseInput
        v-if="canUpdateUserGroups && !$store.state.users.currentUserLoading"
        :modelValue="model.groups"
        :label="$t('Groups')"
        :placeholder="$t('Select groups...')"
        :name="$t('Groups')"
        layout="horizontal"
        id="groups"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <GroupSelect
            v-model="model.groups"
            :initial-value="user?.relationships?.groups"
            :multiple="true"
            :placeholder="$t('Select group')"
          >
          </GroupSelect>
        </div>
      </BaseInput>
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$router.push('/users/list')"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        v-if="isCurrentUserRoleGreaterOrEqual"
        :loading="loading"
        :disabled="!meta.valid"
        type="submit"
        class="ml-2"
      >
        {{ $t('Save') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
// Components
import CustomFieldsEntityForm from "@/modules/common/components/CustomFieldsEntityForm.vue"
import UserRoleSelect from "@/components/selects/UserRoleSelect.vue";
import UserLogo from '@/components/common/UserLogo.vue'
import GroupSelect from "@/components/selects/GroupSelect.vue";

// Utils
import {
  getCustomFieldValuesObject,
  getCustomFieldValuesStringified
} from "@/modules/accounts/utils/modelUtils"
import { UserPreviewTypes } from "@/modules/users/util/userUtils";

export default {
  components: {
    GroupSelect,
    CustomFieldsEntityForm,
    UserRoleSelect,
    UserLogo,
  },
  data() {
    return {
      loading: false,
      model: {
        avatar: null,
        name: '',
        email: '',
        role: null,
        groups: [],
        custom_fields: {}
      },
      UserPreviewTypes,
    }
  },
  computed: {
    roles() {
      return this.$store.getters['users/editableRoles']
    },
    defaultRole() {
      return this.tryGetRoleId('Collaborator')
    },
    user() {
      return this.$store.state.users.currentUser || {}
    },
    isCurrentUserRoleGreaterOrEqual() {
      const userToEditRole = this.get(this.user, 'attributes.role[0]')
      return this.$store.getters['users/isCurrentUserRoleGreaterOrEqual'](userToEditRole)
    },
    canUpdateUserGroups() {
      return this.can(this.$actions.ASSIGN_PEOPLE_TO_ANY_GROUP) 
    },
    canEditCustomFieldValues() {
      return this.can(this.$actions.EDIT_CUSTOM_FIELD_VALUES_FOR_USERS)
    },
    isCurrentUser() {
      return this.user?.id?.toString() === this.$user?.id?.toString()
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true

        const custom_fields = getCustomFieldValuesStringified(this.model.custom_fields)

        await this.$store.dispatch('users/updateUser', {
          user: this.user,
          role: this.model.role,
          custom_fields,
          groups: this.model.groups,
          canUpdateGroups: this.canUpdateUserGroups,
          canEditCustomFieldValues: this.canEditCustomFieldValues
        })

        this.$success(this.$t('User updated successfully'))

        await this.$router.push(`/users/${this.user.id}`)
      } catch (err) {
        console.error(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not save the user'))
      } finally {
        this.loading = false
      }
    },
    tryGetRoleId(name) {
      const role = this.roles.find(s => s?.attributes?.name === name)
      return role?.id
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(value) {
        if (!value?.attributes) {
          return
        }
        this.model = {
          ...this.model,
          ...value.attributes,
          custom_fields: getCustomFieldValuesObject(value.attributes.custom_fields)
        }
        const roleLabel = this.get(value, 'attributes.role[0]')
        if (roleLabel) {
          this.model.role = this.tryGetRoleId(roleLabel)
        }
        this.imageUrl = value?.attributes?.avatar
        this.model.groups = value?.relationships?.groups?.map(g => g.id) || []
      }
    },
    defaultRole: {
      immediate: true,
      handler(value) {
        if (value && value.id && !this.model.role) {
          this.model.role = value.id
        }
      }
    },
    roles: {
      handler() {
        const roleLabel = this.get(this.user, 'attributes.role[0]')
        if (roleLabel) {
          this.model.role = this.tryGetRoleId(roleLabel)
        }
      }
    }
  },
  created() {
    this.$store.dispatch('users/getRoles')
  }
}
</script>
<style>
</style>
