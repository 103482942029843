
import i18n from "@/i18n";
import store from "@/store";
import { entityTypes } from "@/modules/common/enum/entityTypes";
import {
  AiFunctions,
  AiFunctionDefinition,
  BindingDefinition,
} from "@/modules/ai/types/aiTypes";

import { FilterBuilder } from "@/modules/ai/filters/FilterBuilder";
import { FilterTypes } from "@/components/table/tableUtils";
import { FilterEngine } from "@/modules/ai/filters/FilterEngine";

// #region Add or update task
const formattedModelBinding: Record<string, BindingDefinition> = {
  'Id': {
    label: i18n.t('Name'),
    property: 'id',
    type: entityTypes.Task,
    required: true,
    extraParams: ['project_id'],
    order: 1,
  },
  'Project name': {
    label: i18n.t('Project'),
    property: 'project_id',
    type: entityTypes.Project,
    required: true,
    order: 0,
  },
  'Task name': {
    label: i18n.t('Name'),
    property: 'name',
    type: 'raw',
    extraParams: ['project_id'],
    order: 2
  },
  'Description': {
    label: i18n.t('Description'),
    property: 'notes',
    type: 'raw',
    order: 3,
  },
  'Privacy': {
    label: i18n.t('Visibility'),
    property: 'visibility',
    type: 'capitalize',
    order: 4,
  },
  'Status': {
    label: i18n.t('Status'),
    property: 'status_id',
    type: entityTypes.TaskStatus,
    order: 5,
  },
  'Assigned': {
    label: i18n.t('Assignees'),
    property: 'allocated_ids',
    type: entityTypes.User,
    multiple: true,
    order: 6
  },
  'Followers': {
    label: i18n.t('Followers'),
    property: 'follower_ids',
    type: entityTypes.User,
    multiple: true,
    order: 7
  },
  'Groups': {
    label: i18n.t('Groups'),
    property: 'group_ids',
    type: entityTypes.Group,
    multiple: true,
    order: 8
  },
  'Date Type': {
    label: i18n.t('Date type'),
    property: 'date_type',
    type: 'capitalize',
    order: 9
  },
  'Date': {
    label: i18n.t('Due Date'),
    property: 'date',
    type: 'date',
    order: 10
  },
  'Start date': {
    label: i18n.t('Start Date'),
    property: 'start_date',
    type: 'date',
    order: 11,
  },
  'End date': {
    label: i18n.t('End Date'),
    property: 'end_date',
    type: 'date',
    order: 12
  },
}

export const addOrUpdateTask: AiFunctionDefinition = {
  name: AiFunctions.AddOrUpdateTask,
  requiresUserConfirmation: true,
  entity: entityTypes.Task,
  formattedModelBinding,
  actionDisplayName: (isEditAction: boolean) => {
    return isEditAction ? i18n.t('Update Task') : i18n.t('Create Task')
  },
  iconClass: 'far fa-badge-check text-yellow-500'
}

// #endregion

// #region Bulk add tasks

export const addBulkTasks: AiFunctionDefinition = {
  name: AiFunctions.AddBulkTasks,
  requiresUserConfirmation: true,
  entity: entityTypes.Task,
  formattedModelBinding,
  actionDisplayName: (isEditAction: boolean) => {
    return i18n.t('Create Tasks')
  },
  iconClass: 'far fa-badge-check text-yellow-500'
}

// #endregion

// #region Bulk edit tasks

export const editBulkTasks: AiFunctionDefinition = {
  name: AiFunctions.EditBulkTasks,
  requiresUserConfirmation: true,
  entity: entityTypes.Task,
  formattedModelBinding,
  actionDisplayName: (isEditAction: boolean) => {
    return i18n.t('Edit Tasks')
  },
  iconClass: 'far fa-badge-check text-yellow-500'
}

// #endregion

// #region Get tasks

interface TasksSearchModel extends Record<any, any> {
  id: number
  status_id: number
  project_id: number
  parent_id: number
  name: string
  created_at: string
  date: string
  allocated_ids: string
  group_ids: string
}

function getMappedTasks() {
  // @ts-ignore
  return store.state.tasks.allTasks
    .map((task: Record<any, any>) => ({
      id: task.id,
      name: task.attributes.name,
      project_id: task.attributes.project_id,
      status_id: task.attributes.status_id,
      start_date: task.attributes.start_date,
      end_date: task.attributes.end_date,
      date: task.attributes.date,
      allocated_ids: task.attributes.allocated_ids,
      group_ids: task.attributes.group_ids,
      created_at: task.attributes.created_at,
    }));
}

export function getTasks(params: TasksSearchModel) {
  const { name, id, status_id, parent_id, project_id, date, created_at, allocated_ids, group_ids } = params;
  const allTasks = getMappedTasks();
  console.log({params, allTasks});

  let filterBuilder = new FilterBuilder();

  let dynamicFilters = filterBuilder
    .addFilter('name', FilterTypes.Text, name)
    .addFilter('id', FilterTypes.Numeric, id)
    .addFilter('status_id', FilterTypes.Numeric, status_id)
    .addFilter('project_id', FilterTypes.Numeric, project_id)
    .addFilter('parent_id', FilterTypes.Numeric, parent_id)
    .addFilter('date', FilterTypes.DateRange, date)
    .addFilter('created_at', FilterTypes.DateRange, created_at)
    .addFilter('allocated_ids', FilterTypes.InArray, allocated_ids)
    .addFilter('group_ids', FilterTypes.InArray, group_ids)
    .build();

  let filteredTasks = FilterEngine.filter(allTasks, dynamicFilters);

  if (filteredTasks.length > 10) {
    return `There are ${filteredTasks.length} tasks. Please narrow your search.`;
  }

  return JSON.stringify(filteredTasks);
}

// #endregion
