<template>
  <BaseTableCell :params="params">
    <i
      v-if="isGroupRow && row"
      class="fas fa-badge-check text-yellow-500 mr-1"
    />
    <div v-if="!row">
      - -
    </div>
    <div
      v-else-if="row.id === -1 && !row.attributes?.name"
      class="text-gray-400 hover:text-gray-500"
    >
      {{ $t('+ Add task') }}
    </div>
    <div
      v-else
      class="flex space-x-2 items-center"
    >
      <div
        v-if="!isCard && can($actions.EDIT_TASKS) && isCheckboxEnabled"
        v-loading="togglingCompletedState"
        class="text-gray-400 hover:text-primary-500 text-lg cursor-pointer"
        :title="isTaskCompleted ? $t(`Unmark as completed`) : $t(`Mark as completed`)"
        @click.stop="toggleTaskCompleted"
      >
        <i
          class="fa-light fa-circle-check"
          :class="{
            'text-primary-500 font-bold': isTaskCompleted,
          }"
          aria-hidden="true"
        />
      </div>
      <TaskDescription
        :task="row"
        :isCard="isCard"
        class="inline-flex"
      />
    </div>
    <template #actions>
      <i
        v-if="canChangeParent"
        ref="parentDragHandle"
        :title="$t(`Drag from here to change parent...`)"
        class="fa-solid fa-diagram-subtask reorder-drag-handle hover:text-gray-400"
      />
    </template>
  </BaseTableCell>
</template>
<script lang="ts" setup>
// Components
import TaskDescription from "@/modules/tasks/components/TaskDescription.vue"

// Utils
import i18n from "@/i18n"
import { PropType, computed, nextTick, onMounted, ref } from "vue"
import { ICellRendererParams } from "@ag-grid-community/core";
import { error } from "@/components/common/NotificationPlugin";
import { getSetting } from "@/plugins/settingsPlugin";

// Composables
import { useStore } from "vuex"
import useCan from "@/modules/common/composables/useCan.js";
const store = useStore()
const { can, actions } = useCan()

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams<any>>,
    default: () => ({})
  },
  isCard: {
    type: Boolean,
    default: false
  }
})

const isCheckboxEnabled = computed(() => {
  return getSetting('show_task_checkbox')
})

const column = computed(() => {
  return props.params.colDef
})

const isGroupRow = computed(() => {
  return props.params.node?.group
})

const row = computed(() => {
  if (isGroupRow.value) {
    return props.params.api?.getRowNode(props.params.value)?.data
  }

  if (column.value?.field === 'parent_id') {
    return props.params.api?.getRowNode(props.params.data.parent_id)?.data
  }

  return props.params?.data
})

const isTaskCompleted = computed(() => {
  return store.getters['tasks/isTaskClosed'](row.value)
})

const togglingCompletedState = ref(false)

async function toggleTaskCompleted() {
  try {
    togglingCompletedState.value = true
    await store.dispatch('tasks/toggleTaskCompleted', {
      task: row.value,
      setCompleted: !isTaskCompleted.value
    })
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    const errMessage = isTaskCompleted.value
      ? i18n.t(`Could not unmark task as completed`)
      : i18n.t(`Could not mark task as completed`)

    error(errMessage)
  }
  finally {
    togglingCompletedState.value = false
  }
}

const tasksApiFilters = computed(() => {
  return store.getters['filters/targetApiFilters']('tasks') || []
})

const showDetailsView = computed(()=> {
  return tasksApiFilters.value.find((filter: any) => filter.key === 'show-details-view')?.value?.show || false
})

const isInsideClosedProject = computed(() => {
  return store.getters['projects/isCurrentProjectClosed']
})

const canChangeParent = computed(() => {
  const prerequisites = [
    row.value?.id !== -1,
    !isInsideClosedProject.value,
    showDetailsView.value,
    can(actions.EDIT_TASKS),
  ]

  return prerequisites.every(Boolean)
})

const parentDragHandle = ref<HTMLElement | null>(null)
onMounted(async () => {
  await nextTick()

  if (parentDragHandle.value) {
    props.params.registerRowDragger?.(parentDragHandle.value)
  }
})
</script>
