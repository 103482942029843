<template>
  <div class="space-x-3 flex items-center">
    <ProjectLogoNew
      v-if="project"
      :project="project"
    />
    <div class="space-x-3 flex flex-wrap items-center">
      <div
        v-if="group"
        :key="group.id"
        class="hidden sm:flex bg-gray-50 hover:bg-gray-100 text-xs text-gray-400 hover:text-gray-500 p-0.5 pr-2 border border-gray-200 rounded-full cursor-pointer items-center space-x-1"
      >
        <GroupLogo
          :group="group"
          :actions="[UserPreviewActions.View]"
          class="flex items-center"
          size="xs"
          rounded="full"
          @click.stop
        >
          <template #after>
            <span class="text-sm">{{ group.attributes.name }}</span>
          </template>
        </GroupLogo>
      </div>
      <div
        v-if="task"
        class="hidden sm:flex bg-yellow-500 p-0.5 pr-2 text-sm rounded-full text-white capitalize whitespace-nowrap items-center space-x-2 cursor-pointer"
        @click="showTaskDialog = true"
      >
        <i class="fa-light fa-badge-check text-base leading-none" />
        <div>{{ task.attributes.name }}</div>
      </div>
      <div
        class="flex items-center"
        :class="{
          'cursor-pointer': canEdit
        }"
        @click.stop="onClick"
      >
        <div class="hidden sm:line-clamp-1 text-sm text-gray-500 mr-1">
          {{ label }} -
        </div>
        <div
          v-if="timeEntry.attributes.date"
          class="inline text-sm text-gray-500 mr-1"
        >
          {{ $formatDate(timeEntry.attributes.date) }} -
        </div>
        <div class="inline text-sm text-gray-500">
          {{ formattedTime }}
        </div>
      </div>
    </div>
    <TimeRunningClock
      v-if="timeEntry?.attributes?.status === TimerStatusTypes.Recording"
      :isRunning="true"
      :actionLabel="$t('Stop')"
      size="xxs"
      @triggerTimerAction="triggerTimerAction"
    />
    <TaskDialog
      v-if="task?.id"
      v-show="showTaskDialog"
      v-model="showTaskDialog"
      :taskId="task.id"
      @close="showTaskDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import TimeRunningClock from '@/modules/time/components/TimeRunningClock.vue'
import ProjectLogoNew from "@/modules/projects/components/ProjectLogoNew.vue";
import GroupLogo from "@/components/common/GroupLogo.vue";

// Utils
import { PropType, computed, ref } from 'vue';
import { get } from "lodash-es";
import { TimerStatusTypes } from "@/modules/time/utils/timeEntryUtils";
import { minutesToFormattedTime } from "@/modules/common/utils/timeUtils";
import {
  UserPreviewActions,
} from "@/modules/users/util/userUtils";

// Composables
import { useStore } from 'vuex'
import useCan from "@/modules/common/composables/useCan.js";
import useEntityCrud from "@/modules/common/composables/useEntityCrud"

import Data = API.Data
import TimeEntry = App.Domains.Time.Models.TimeEntry;

const store = useStore()
const { can, actions } = useCan()
const {
  entityTypes,
  triggerEntityCreate
} = useEntityCrud()

const props = defineProps({
  timeEntry: {
    type: Object as PropType<Data<TimeEntry>>,
    required: true
  }
})

const canEdit = computed(() => {
  return can(actions.EDIT_TIME_ENTRIES) && props.timeEntry?.attributes?.status !== TimerStatusTypes.Recording
})

const showTaskDialog = ref(false)

function onClick() {
  if (!canEdit.value) {
    return
  }

  triggerEntityCreate(entityTypes.TimeEntry, {
    timeEntry: props.timeEntry
  })
}

const project = computed(() => {
  return props.timeEntry?.relationships?.project
})

const group = computed(() => {
  return props.timeEntry?.relationships?.group
})

const task = computed(() => {
  return props.timeEntry?.relationships?.task
})

const label = computed(() => {
  const userName = get(props.timeEntry, 'relationships.user.attributes.name', '')
  const groupName = get(props.timeEntry, 'relationships.group.attributes.name', '')
  const projectName = get(props.timeEntry, 'relationships.project.attributes.name', '')
  const taskName = get(props.timeEntry, 'relationships.task.attributes.name', '')

  return userName || groupName || projectName || taskName
})

const workedMinutes = computed(() => {
  if (props.timeEntry?.attributes?.status === TimerStatusTypes.Recording) {
    const startedAt = new Date(props.timeEntry?.attributes?.timer_started_at as string).getTime()
    const now = new Date().getTime()

    if (isNaN(startedAt)) {
      return 0
    }

    return Math.floor((now - startedAt) / 1000 / 60)
  }

  return props.timeEntry?.attributes?.worked_minutes || 0
})

const formattedTime = computed(() => {
  return minutesToFormattedTime(workedMinutes.value)
})

async function triggerTimerAction() {
  const data = await store.dispatch('time/stopTimer', props.timeEntry.id)

  props.timeEntry.attributes.status = data.status
  props.timeEntry.attributes.worked_minutes = data.worked_minutes
}
</script>
