<template>
  <ElPopover
    trigger="click"
    ref="linkPopover"
    :width="300"
    placement="right"
    @show="focusOnLinkInput"
  >
    <template #reference>
      <slot>
        <div>{{ $t('Enter link') }}</div>
      </slot>
    </template>

    <BaseInput
      ref="linkInput"
      v-model="link"
      :placeholder="$t('Insert link here...')"
      @keydown.enter.stop="onLinkEnter"
    />

  </ElPopover>
</template>
<script>
import { ElPopover } from 'element-plus'

export default {
  components: {
    ElPopover,
  },
  data() {
    return {
      link: '',
      popoverVisible: false,
    }
  },
  methods: {
    async focusOnLinkInput() {
      await this.$nextTick();
      this.$refs?.linkInput?.focus();
    },
    onLinkEnter() {
      let href = this.link

      if (href && !href.startsWith('http')) {
        this.link = `https://${href}`
        href = this.link
      }
      this.$emit('change', href)
    },
    open() {
      this.$refs?.linkPopover?.show()
    }
  }
}
</script>
