<template>
  <div
    v-if="!abortInit"
    class="relative h-full flex-col-grow"
  >
    <AddEntityDialogs />

    <DashboardLayoutNew
      v-if="useNewLayout"
      :settingsLoaded="settingsLoaded"
    />
    <DashboardLayoutOld
      v-else
      :abortInit="abortInit"
      :settingsLoaded="settingsLoaded"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import DashboardLayoutNew from "@/modules/dashboard/components/DashboardLayoutNew.vue";
import DashboardLayoutOld from "@/modules/dashboard/components/DashboardLayoutOld.vue";
import AddEntityDialogs from "@/components/common/dialog/AddEntityDialogs.vue";

// Utils
import i18n from "@/i18n";
import { computed, onBeforeMount, ref, watch } from "vue";
import config, { enableConfigurationEndpoint, isRootApi } from "@/modules/common/config";
import AuthService from "@/modules/auth/services/AuthService";
import {
  initOrtto,
  initPostHog,
  initProduktly,
  initProfitWell,
} from "@/modules/common/utils/trackingUtils";
import PusherUtils from "@/modules/common/utils/pusherUtils"
import { globalNotifications } from '@/utils/global.js'
import { error, success } from "@/components/common/NotificationPlugin";
import { playAudio } from "@/modules/common/utils/audioUtils"
import { desktopAppUtils } from "@/modules/common/utils/desktopAppUtils.js";
import { ImportFinishedEvent } from "@/modules/accounts/utils/importUtils";

// Composables
import useLayout from "@/modules/common/composables/useLayout";
import { useStore } from "vuex";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import useCan from "@/modules/common/composables/useCan.js";
import { useAuth } from "@/modules/auth/composables/useAuth";
import useInitPusher from "@/modules/common/composables/useInitPusher";
import useEntityEventHandlers from "@/modules/common/composables/useEntityEventHandlers"
import usePageUtils from "@/modules/common/composables/usePageUtils";
import { useDataImporter } from "@/modules/accounts/composables/useDataImporter";

const {
  useNewLayout
} = useLayout()

const store = useStore()
const [
  route,
  router
] = [
  useRoute(),
  useRouter()
]

const {
  isRoleGreaterOrEqual,
  roles
} = useCan()

const {
  currentUser
} = useAuth()

const {
  channel,
  isConnectedToChannel
} = useInitPusher('current-user')

const {
  handleEntityEvent
} = useEntityEventHandlers()

const {
  formattedPageTitle,
  setTitle
} = usePageUtils()

const {
  handleImportFinished
} = useDataImporter()

const abortInit = ref(false)
const appLoading = computed({
  get() {
    return store.state.appLoading
  },
  set(value: boolean) {
    store.commit('setAppLoading', value)
  }
})
const settingsLoaded = ref(false)

function checkOrganization() {
  const currentOrganization = config.getOrganizationName()

  if (currentOrganization || import.meta.env.DEV) {
    abortInit.value = isRootApi()
    return
  }

  abortInit.value = true
}

async function tryRedirectToSubdomain() {
  const isLoggedIn = store.state.auth.isLoggedIn
  if (!isLoggedIn) {
    return
  }

  AuthService.tryRedirectToSubdomain(route.query)

  await router.push('/select-organization')
}

async function loadConfiguration() {
  await store.dispatch('accounts/getConfiguration')

  if (isSubscriptionValid.value) {
    await initAnalytics()
  }
}

async function getSettings() {
  try {
    settingsLoaded.value = false
    const subscriptionId = store.state.accounts.subscription?.id
    if (subscriptionId) {
      loadConfiguration()
    }
    else {
      await loadConfiguration()
    }

    await store.dispatch("accounts/getPaymentDetails")
    await store.dispatch('projects/getAllTools')

  } finally {
    settingsLoaded.value = true
  }
}

const isSubscriptionValid = computed(() => {
  return store.getters['accounts/isValidSubscription']
})

async function initAnalytics() {
  if (import.meta.env.DEV) {
    return
  }

  let model
  if (enableConfigurationEndpoint) {
    model = store.state.accounts.intercomData
  } else {
    model = await store.dispatch('accounts/getIntercomModel')
  }
  model.id = currentUser.value?.id

  initPostHog(model)
  // Only track roles >= CREATOR
  if (!isRoleGreaterOrEqual(roles.CREATOR)) {
    return
  }
  initProduktly(model)
  initOrtto(model)
  initProfitWell(model)
}

async function initApp() {
  try {
      appLoading.value = true

      await checkOrganization()
      await store.dispatch('auth/getProfile')
      if (isRootApi()) {
        await tryRedirectToSubdomain()
      }
      if (abortInit.value) {
        return
      }

      await getSettings()

      if (!isSubscriptionValid.value) {
        return
      }

      store.dispatch('users/getData')

      if (!enableConfigurationEndpoint) {
        await Promise.allSettled([
          store.dispatch('users/getDefaultViews'),
          store.dispatch('accounts/getStarredPages'),
          store.dispatch("accounts/getStats"),
          store.dispatch('projects/getAllStatuses'),
          store.dispatch('tasks/getAllStatuses'),
        ])
      }

    } finally {
      appLoading.value = false
    }
}

onBeforeMount(() => {
  initApp()
})

onBeforeRouteLeave(() => {
  // @ts-ignore
  window.ap3c?.talk.execute('hide')
})


// Pusher events

async function handleProfileUpdatedEvent(data: any) {
  await store.dispatch('auth/getProfile')

  // @ts-ignore
  const notifications = globalNotifications[data.changed];
  if (!notifications) {
    return;
  }

  if (data.success) {
    success(notifications.success)
  }
  else {
    error(notifications.failed)
  }
}

async function handleNewNotificationEvent(data: any) {
  playAudio('new_notification.wav')

  let notifyMessage = i18n.t('New notification')

  if (data?.notification_message) {
    const span = document.createElement('span');
    span.innerHTML = data?.notification_message;
    const message =  span.textContent || span.innerText;

    notifyMessage += `: ${message}`
  }

  desktopAppUtils.sendNotification(notifyMessage)

  store.commit('accounts/incrementStats', {
    key: 'unread_comments_count',
    value: 1
  })

  // Only load notification details if in inbox view for real time list update
  if (route.path === '/inbox') {
    return store.dispatch('notifications/addNotification', data.notification_id)
  }
}

async function handleMasterProjectsSeeded() {
  store.commit('tasks/setAllTasksLoaded', false)
  store.commit('files/setAllFilesLoaded', false)
  await Promise.all([
    store.dispatch("accounts/getConfiguration"),
    store.dispatch('users/getData'),
  ])
}

async function handleProofUpdateEvent(data: any) {
  if (route.fullPath.includes(`/projects/${data?.project_id}`)) {
    store.dispatch("proofs/getProofById", data.proof_id)
  }
}

async function handleDataImportFinished(event: ImportFinishedEvent) {
  await handleImportFinished(event)
}

function bindPusherEvents() {
  if (!channel.value || abortInit.value) {
    return
  }

  // @ts-ignore
  channel.value.on(PusherUtils.NOTIFICATION_CREATED_EVENT, async (data: any) => {
    if (data.masterProjectsSeeded) {
      await handleMasterProjectsSeeded()
      return
    }

    if (data.profile_updated) {
      await handleProfileUpdatedEvent(data)
      return
    }

    if (data.notification_id) {
      await handleNewNotificationEvent(data)
      return
    }

    if (data?.proof_id) {
      await handleProofUpdateEvent(data)
      return
    }

    if (data.entity_type) {
      await handleEntityEvent(data)
      return
    }

    if (data.import_finished) {
      await handleDataImportFinished(data)
      return
    }

    console.error('No handler for pusher event', data)
  })
}

watch(() => isConnectedToChannel.value, (value) => {
  if (value) {
    bindPusherEvents()
  }
})

watch(() => formattedPageTitle.value, setTitle, {
  immediate: true
})
</script>
