import i18n from "@/i18n"
import store from "@/store/index.js";
import { getSetting } from "@/plugins/settingsPlugin";
import { getRandomUnsplashPhoto } from "@/modules/common/utils/unsplashUtils";

function getShortStringCode(str = '') {
  let code = 0
  const shortString = getShortString(str)
  for (let i = 0; i < shortString.length; i++) {
    code += shortString.charCodeAt(i)
  }
  return code
}

export function getShortString(str = '') {
  const words = str.split(' ')
  const firstLetters = words.map(w => w[0] || '').join('')
  return firstLetters.substring(0, 2)
}

export function getStringColor(str = '') {
  const colors = ['gray', 'red', 'green', 'blue', 'purple', 'pink']
  const index = getShortStringCode(str) % colors.length || 0
  const color = colors[index] || 'gray'
  return `bg-${color}-500`
}

export const coreToolsGroup = {
  groupTitle: true,
  title: i18n.t('Core Tools'),
  disabled: true,
  id: -2
}

export const thirdPartyToolsGroup = {
  groupTitle: true,
  title: i18n.t('3rd Party Tools'),
  disabled: true,
  class: 'mt-6',
  id: -1
}

export const Privacy = {
  Account: 'account',
  Project: 'project',
  Private: 'private',
}

export function isProjectClosed(project) {
  return store.getters['projects/isProjectClosed'](project)
}

export async function getProjectImages() {
  const autoImage = getSetting('auto_project_image')
  const autoCoverImage = getSetting('auto_cover_image')
  let cover_image = ''
  let image = ''

  if (!autoImage && !autoCoverImage) {
    return {
      image,
      cover_image
    }
  }

  const result = await getRandomUnsplashPhoto()
  if (autoImage) {
    image = result.urls.thumb
  }
  if (autoCoverImage) {
    cover_image = result.urls.regular
  }
  return {
    image,
    cover_image
  }
}
