<template>
  <BaseTableCell :params="params">
    <UserStatus
      :column="colDef"
      :row="row"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
// Components
import UserStatus from "@/components/table/UserStatus.vue"

// Utils
import { computed } from "vue"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed<any>(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})
</script>
