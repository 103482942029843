
import { timeAllocationLevels } from "@/modules/time/utils/modelUtils.js";

export function timeEntryFormModelToBulk(data) {
  return data.entries.map(entry => ({
      group_id: data.group_id,
      user_id: data.user_id,
      ...entry,
      task_id: entry.task_id || undefined,
    })
  )
}

export function timeEntryToFormModel(timeEntry) {
  return {
    group_id: timeEntry.attributes.group_id,
    group: timeEntry.relationships?.group,
    user_id: timeEntry.attributes.user_id,
    user: timeEntry.relationships?.user,
    entries: [{
      project_id: timeEntry.attributes.project_id,
      project: timeEntry.relationships?.project,
      task_id: timeEntry.attributes.task_id,
      task: timeEntry.relationships?.task,
      date: timeEntry.attributes.date,
      worked_minutes: timeEntry.attributes.worked_minutes,
      custom_fields: timeEntry.attributes.custom_fields,
    }]
  }
}

export function preprocessAllocatedTimeModel(model) {

  if (model.allocationLevel === timeAllocationLevels.GROUP) {
    delete model.user_id
    delete model.task_id
  }
  else if (model.allocationLevel === timeAllocationLevels.PERSON) {
    delete model.group_id
    delete model.task_id
  }
  else if (model.allocationLevel === timeAllocationLevels.TASK) {
    delete model.group_id
    delete model.user_id
  }

  delete model.allocationLevel

  return model
}

export function allocatedTimeToFormModel(allocatedTime) {
  let allocationLevel = ''

  if (allocatedTime.attributes?.group_id) {
    allocationLevel = timeAllocationLevels.GROUP
  }
  else if (allocatedTime.attributes?.user_id) {
    allocationLevel = timeAllocationLevels.PERSON
  }
  else if (allocatedTime.attributes?.task_id) {
    allocationLevel = timeAllocationLevels.TASK
  }

  return {
    project_id: allocatedTime.attributes?.project_id,
    project: allocatedTime.relationships?.project,
    group_id: allocatedTime.attributes?.group_id,
    group: allocatedTime.relationships?.group,
    user_id: allocatedTime.attributes?.user_id,
    user: allocatedTime.relationships?.user,
    task_id: allocatedTime.attributes?.task_id,
    task: allocatedTime.relationships?.task,
    allocated_minutes: allocatedTime.attributes?.allocated_minutes,
    allocationLevel,
  }
}
