import { LicenseManager } from "@ag-grid-enterprise/core";

export function setLicenseKey() {
  let licenseKey = import.meta.env.VITE_AG_GRID_LICENSE_KEY

  if (!licenseKey) {
    console.error('No Ag Grid license key configured (VITE_AG_GRID_LICENSE_KEY environment variable)')
    return
  }

  if (licenseKey.startsWith('\"')) {
    // Remove quotes since some tools like Forge don't allow setting env vars with spaces
    try {
      licenseKey = JSON.parse(licenseKey)
    } catch (err) {
      console.warn('Error parsing the ag grid license key', err)
    }
  }
  LicenseManager.setLicenseKey(licenseKey)
}
