<template>
  <div>
    <DataSyncingIndicator
      v-if="tasksLoading"
    />
    <BaseCalendar
      :loading="tasksLoading"
      :is-scheduler="isScheduler"
      :options="calendarOptions"
      :scheduler-options="schedulerOptions"
      :can-select="can($actions.CREATE_TASKS)"
      :can-resize="can($actions.EDIT_TASKS)"
      :can-move="can($actions.EDIT_TASKS)"
      @select="handleDateSelect"
      @event-click="handleEventClick"
      @event-drop="handleEventMove"
      @event-resize="handleEventMove"
    >
      <template #eventContent="{event}">
        <EventContent :event="event"/>
      </template>
      <template #resourceLabelContent="{resource}">
        <svg v-if="tasksLoading"
             viewBox="0 0 640 357" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H87C89.2091 16 91 14.2091 91 12V4C91 1.79086 89.2091 0 87 0H4ZM76 72C73.7909 72 72 73.7909 72 76V84C72 86.2091 73.7909 88 76 88H159C161.209 88 163 86.2091 163 84V76C163 73.7909 161.209 72 159 72H76ZM72 240C72 237.791 73.7909 236 76 236H159C161.209 236 163 237.791 163 240V248C163 250.209 161.209 252 159 252H76C73.7909 252 72 250.209 72 248V240ZM40 72C37.7909 72 36 73.7909 36 76V84C36 86.2091 37.7909 88 40 88H48C50.2091 88 52 86.2091 52 84V76C52 73.7909 50.2091 72 48 72H40ZM36 240C36 237.791 37.7909 236 40 236H48C50.2091 236 52 237.791 52 240V248C52 250.209 50.2091 252 48 252H40C37.7909 252 36 250.209 36 248V240ZM76 144C73.7909 144 72 145.791 72 148V156C72 158.209 73.7909 160 76 160H263C265.209 160 267 158.209 267 156V148C267 145.791 265.209 144 263 144H76ZM72 312C72 309.791 73.7909 308 76 308H263C265.209 308 267 309.791 267 312V320C267 322.209 265.209 324 263 324H76C73.7909 324 72 322.209 72 320V312ZM76 96C73.7909 96 72 97.7909 72 100V116C72 118.209 73.7909 120 76 120H418C420.209 120 422 118.209 422 116V100C422 97.7909 420.209 96 418 96H76ZM72 264C72 261.791 73.7909 260 76 260H418C420.209 260 422 261.791 422 264V280C422 282.209 420.209 284 418 284H76C73.7909 284 72 282.209 72 280V264ZM0.5 36C0.223858 36 0 36.2239 0 36.5C0 36.7761 0.22385 37 0.499992 37H639.5C639.776 37 640 36.7761 640 36.5C640 36.2239 639.776 36 639.5 36H0.5ZM0 196.5C0 196.224 0.223858 196 0.5 196H639.5C639.776 196 640 196.224 640 196.5C640 196.776 639.776 197 639.5 197H0.499992C0.22385 197 0 196.776 0 196.5ZM0.5 356C0.223858 356 0 356.224 0 356.5C0 356.776 0.22385 357 0.499992 357H639.5C639.776 357 640 356.776 640 356.5C640 356.224 639.776 356 639.5 356H0.5Z"
                fill="#EAEAEA"/>
        </svg>
        <ResourceContent v-if="!tasksLoading" :resource="resource"/>
      </template>
    </BaseCalendar>
  </div>
</template>
<script>
// Components
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue";
import ResourceContent from "@/modules/tasks/components/calendar/ResourceContent.vue";
import EventContent from "@/modules/tasks/components/calendar/EventContent.vue";

// Helpers
import { taskDateTypes } from "@/modules/tasks/utils/modelUtils.js";
import { getDateWithoutTime } from "@/modules/common/utils/dateUtils.js";
import { getExtraFilters } from "@/modules/tasks/utils/taskTableUtils"
import { EntityTargetTypes } from "@/modules/common/utils/filterUtils"

// Composables
import useSortedAndFilteredData from "@/modules/common/composables/useSortedAndFilteredData";

export default {
  components: {
    DataSyncingIndicator,
    ResourceContent,
    EventContent,
  },
  props: {
    isScheduler: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const { sortedAndFilteredData } = useSortedAndFilteredData({
      target: EntityTargetTypes.TASKS,
      extraFilters: getExtraFilters(),
      dataGetterPath: 'tasks/currentTasks',
      getQuickFilterText: (task) => task.attributes.name,
    })

    return {
      sortedAndFilteredData,
    }
  },
  data() {
    return {
      allGroupedTasks: new Map()
    }
  },
  computed: {
    calendarOptions() {
      return {
        events: this.taskEvents,
      }
    },
    isGroupedByAllocations() {
      return this.groupByProp?.includes('allocations')
    },
    isGroupedByStatus() {
      return this.groupByColumn?.prop?.includes('status')
    },
    groupByProp() {
      let groupColumnProp = this.groupByColumn?.prop || ''
      let groupPropParts = groupColumnProp?.split('.') || []
      return groupPropParts[groupPropParts.length - 1]
    },
    schedulerOptions() {
      if (!this.isScheduler) {
        return {}
      }
      const resources = []
      for (let key of this.groupedTasks.keys()) {
        const tasks = this.groupedTasks.get(key)
        resources.push({
          id: this.getResourceKey(key),
          title: this.getResourceKey(key),
          name: this.getResourceName(tasks),
          extendedProps: {
            tasks,
            firstResource: resources.length === 0,
          }
        })
      }
      
      return {
        resources: resources,
        events: this.taskEvents,
      }
    },
    groupByColumn() {
      return this.$store.getters['filters/targetLocalFilters']('tasks')?.groupBy || {}
    },
    tasksLoading() {
      return this.$store.state.tasks.tasksLoading
    },
    groupedTasks() {
      return this.$store.getters['tasks/groupedTasks'](this.sortedAndFilteredData)
    },
    apiFilters() {
      return this.$store.getters['filters/targetApiFilters']('tasks') || []
    },
    showFutureRecurrences() {
      return this.apiFilters.find((filter) => filter.key === 'show-future-recurrences')?.value?.show
    },
    isTaskClosed() {
      return this.$store.getters['tasks/isTaskClosed']
    },
    taskEvents() {
      let events = []

      for(let key of this.allGroupedTasks.keys()) {
        const tasks = this.allGroupedTasks.get(key)
        for(let task of tasks) {
          let {
            start_date,
            end_date,
            date,
            recurrence_date,
            sortable_date,
            name,
            recurrence,
            date_type,
            status_id
          } = task?.attributes
          let statusColor = this.$store.getters['tasks/getStatusColor'](status_id)
          const isDateRange = date_type === taskDateTypes.DATE_RANGE

          let end = null
          
          if (end_date) {
            end = new Date(end_date);
            end.setDate(end.getDate() + 1)
            end = end.toISOString()
          }
          
          const baseStartDate = date_type === taskDateTypes.DATE_RANGE
            ? start_date
            : sortable_date || date || recurrence_date

          const start = getDateWithoutTime(baseStartDate)
          end = getDateWithoutTime(end)

          const event = {
            id: task.id,
            title: name,
            start,
            end,
            allDay: true,
            rrule: this.showFutureRecurrences && !this.isTaskClosed(task)
              ? (recurrence || undefined)
              : undefined,
            isRecurring: this.showFutureRecurrences && recurrence !== undefined,
            date: recurrence
              ? this.$formatDate(recurrence_date)
              : this.$formatDate(date),
            eventColor: statusColor,
            backgroundColor: statusColor,
            borderColor: statusColor,
            textColor: 'white',
            classNames: ['calendar-task'],
            extendedProps: task,
            durationEditable: isDateRange && this.can(this.$actions.EDIT_TASKS),
          }
          
          if (this.isScheduler) {
            event.resourceId = this.getResourceKey(key)
          }
          
          events.push(event)
        }
        
      }
      return events
    }
  },
  methods: {
    getResourceKey(key) {
      if (key?.toString() === '[object Object]') {
        return 'no-groups'
      }
      return key?.toString()
    },
    getResourceName(tasks) {
      const resourceValue = this.get(tasks, `[0].${this.groupByColumn?.prop}`)
      if (Array.isArray(resourceValue)) {
        return this.get(resourceValue, '[0].attributes.name', '')
      }
      if (typeof resourceValue === 'object') {
        return this.get(resourceValue, 'attributes.name', '')
      }
      return resourceValue
    },
    handleDateSelect({ start_date, end_date }) {
      
      let date_type = taskDateTypes.SINGLE_DATE
      
      if (end_date) {
        date_type = taskDateTypes.DATE_RANGE
      }
      
      this.$store.commit('setEntityCreateParams', {
        task: {
          attributes: {
            date_type,
            date: date_type === taskDateTypes.SINGLE_DATE ? start_date : undefined,
            start_date: start_date,
            end_date: end_date,
          }
        }
      })
      this.$store.commit('tasks/triggerAddTask')
    },
    handleEventClick(clickInfo) {
      let path = this.getTaskPath(clickInfo.event.extendedProps)
      this.$router.push(path)
    },
    async handleEventMove({ event, newResource }) {
      let task = event?.extendedProps
      const newDate = this.getNewTaskDate(task?.attributes, event)

      const requestData = {
        taskId: task.id,
        data: {
          ...task?.attributes,
          date: newDate
        },
        silent: true
      }

      if (this.isGroupedByAllocations && newResource) {
        const allocated_ids = this.get(newResource, 'extendedProps.tasks[0].attributes.allocated_ids')
        const project_id = task?.attributes?.project_id
        requestData.data.allocated_ids = allocated_ids
        newResource.extendedProps.tasks.push(task)

        await this.$store.dispatch('projects/addProjectUsers', {
          users: allocated_ids,
          projectId: project_id,
        })
      }

      if (this.isGroupedByStatus && newResource) {
        const newTask = this.get(newResource, 'extendedProps.tasks[0].attributes', {})
        requestData.data.status_id = newTask?.status_id
        newResource.extendedProps.tasks.push(task)
      }

      const updatedTask = {
        id: task.id,
        attributes: {
          ...requestData.data,
          date: typeof newDate === 'string' ? newDate : null,
          start_date: newDate?.start || null,
          end_date: newDate?.end || null,
          sortable_date: newDate?.end || newDate,
        }
      }

      await this.$store.dispatch('tasks/updateTaskInArray', updatedTask)

      await this.$store.dispatch('tasks/editTask', requestData)
    },
    getNewTaskDate(task, event) {
      let date_type = task?.date_type
      const start = getDateWithoutTime(event?.start)

      if (date_type === taskDateTypes.DATE_RANGE) {
        const end = event.end
        end.setDate(end?.getDate() - 1)

        return {
          start: start,
          end: getDateWithoutTime(end),
        }
      }
      else if (date_type === taskDateTypes.SINGLE_DATE) {
        return start
      }

      return task?.date
    },
    getTaskPath(task) {
      return {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          taskId: task.id,
        }
      }
    },
    async syncGroupedTasks() {
      await this.$nextTick()
      this.allGroupedTasks = this.groupedTasks
    }
  },
  watch: {
    groupedTasks: {
      immediate: true,
      deep: true,
      handler(tasks) {
        if (!this.$route.path.includes('calendar') && !this.$route.path.includes('scheduler')) {
          return
        }

        this.syncGroupedTasks()
      }
    },
  },
}
</script>
