<template>
  <div class="flex justify-between">
    <BaseDropdown
      v-if="availableOptions?.length"
      :options="availableOptions"
      :slim="slim"
      divide-items
      @action="onAction"
    >
      <button
        type="button"
        class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 sm:mt-0 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none z-10"
      >
        {{ $t('Options') }}
        <i class="fas fa-chevron-down mt-1 ml-2"></i>
      </button>
      <template #option="{ option }">
        <i v-if="option.icon" :class="`${option.icon} mr-2`" />
        {{ option.label }}
      </template>
    </BaseDropdown>

    <BaseButton
      v-if="!isTemplate"
      variant="primary"
      @click="$emit('closeProject')"
    >
      {{ $t('Close Page') }}
    </BaseButton>
    <LimitedFeature
      v-else
      :feature="AccountPermissions.ActiveProjects"
      :label="$t('Create Project')"
      @click="createProjectFromTemplate"
    />
    <ShareTemplateDialog
      v-if="isTemplate && showShareTemplateDialog"
      v-model="showShareTemplateDialog"
      :template="project"
      @close="showShareTemplateDialog = false"
    />
  </div>
</template>
<script>
import ShareTemplateDialog from "@/modules/templates/components/ShareTemplateDialog.vue";
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from '@/modules/common/composables/useCan';
import i18n from "@/i18n";

export default {
  components: {
    ShareTemplateDialog,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      maxActiveProjectsLimitReached,
      accountLimits,
    } = useAccountLimits()

    return {
      accountLimits,
      maxActiveProjectsLimitReached,
      AccountPermissions,
    }
  },
  data() {
    return {
      showShareTemplateDialog: false,
    }
  },
  computed: {
    orderedProjectStatuses() {
      return this.$store.getters['projects/orderedStatuses'] || []
    },
    completedStatus() {
      return this.orderedProjectStatuses[this.orderedProjectStatuses.length - 1]
    },
    isProjectClosed() {
      return this.project?.attributes?.status_id == this.completedStatus?.id
    },
    isTemplate() {
      return this.project?.attributes?.is_template
    },
    options() {
      if (this.isTemplate) {
        return [
          {
            label: i18n.t('Delete'),
            action: 'confirmDelete',
            variant: 'white',
            hoverVariant: 'danger',
            icon: 'fa-regular fa-trash',
            tooltip: i18n.t('Delete this template from your account'),
            requiresPermissionTo: this.$actions.DELETE_PROJECTS
          },
          {
            label: i18n.t('Custom Fields'),
            path: `${this.$route.path}/custom-fields`,
            variant: 'white',
            icon: 'fa-regular fa-stars',
            tooltip: i18n.t('View and edit the custom fields for this template'),
            requiresPermissionTo: this.$actions.CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS
          },
          {
            label: i18n.t('Share'),
            action: 'shareTemplate',
            variant: 'white',
            icon: 'fa-regular fa-share-nodes',
            tooltip: i18n.t('Share this template with other users'),
            requiresPermissionTo: this.$actions.CREATE_PROJECT_TEMPLATES
          },
        ].slice().reverse()
      }

      return [
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          variant: 'white',
          hoverVariant: 'danger',
          icon: 'fa-regular fa-trash',
          tooltip: i18n.t('Delete this project from your account'),
          requiresPermissionTo: this.$actions.DELETE_PROJECTS
        },
        {
          label: i18n.t('Duplicate'),
          action: 'duplicateProject',
          variant: 'white',
          icon: 'fa-regular fa-copy',
          disabled: this.maxActiveProjectsLimitReached,
          tooltip: this.maxActiveProjectsLimitReached
            ? i18n.tc('account limit reached', {
                limit: 'active projects',
                maxCount: this.accountLimits.projects.available,
              })
            : i18n.t("Duplicate this project and all its data"),
          requiresPermissionTo: this.$actions.DUPLICATE_PROJECTS,
          limitedFeature: AccountPermissions.ActiveProjects,
        },
        {
          label: i18n.t('Convert'),
          action: 'convertToTemplate',
          variant: 'white',
          icon: 'fa-regular fa-layer-group',
          tooltip: i18n.t("Create a new template based on this project"),
          requiresPermissionTo: this.$actions.DELETE_PROJECTS
        },
        {
          label: i18n.t('Custom Fields'),
          path: `${this.$route.path}/custom-fields`,
          variant: 'white',
          icon: 'fa-regular fa-stars',
          tooltip: i18n.t('View and edit the custom fields for this project'),
          requiresPermissionTo: this.$actions.CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS
        },
        {
          label: i18n.t('Reopen'),
          action: 'changeProjectState',
          variant: 'white',
          icon: 'fa-regular fa-circle-check',
          disabled: this.maxActiveProjectsLimitReached,
          tooltip: this.maxActiveProjectsLimitReached
            ? i18n.tc('account limit reached', {
                limit: 'active projects',
                maxCount: this.accountLimits.projects.available,
              })
            : i18n.t('Reopen this project to enable editing')
          ,
          enabled: () => {
            return this.isProjectClosed && this.can(this.$actions.EDIT_PROJECTS)
          }
        },
        {
          label: i18n.t('Complete Project'),
          action: 'changeProjectState',
          variant: 'white',
          icon: 'fa-regular fa-circle-check',
          tooltip: i18n.t('Mark this project as completed'),
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.CLOSE_PROJECTS)
          }
        },
      ].slice().reverse()
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.project)
    },
    async duplicateProject() {
      try {
        const project = await this.$store.dispatch('projects/duplicateProject', this.project.id)

        this.$success(i18n.t('Project duplicated successfully'))

        this.$store.commit('projects/setCurrentProject', null)
        await this.$nextTick()
        await this.$router.push(`/projects/${project.id}`)
      }
      catch (err) {
        if (err.handled) {
          return
        }

        this.$error(i18n.t('Could not duplicate project'))
      }
    },
    async convertToTemplate() {
      try {
        const template = await this.$store.dispatch('projects/convertToTemplate', this.project.id)

        this.$success(i18n.t('A new template based on this project was successfully created'))

        this.$store.commit('projects/setCurrentProject', null)
        await this.$nextTick()
        await this.$router.push(`/templates/${template.id}`)
      }
      catch (err) {
        if (err.handled) {
          return
        }

        this.$error(i18n.t('Could not convert project to template'))
      }
    },
    async createProjectFromTemplate() {
      this.$store.dispatch('templates/triggerCreateProjectFromTemplate', {
        templateId: this.project?.id,
        template: this.project,
      })
    },
    async changeProjectState() {
      const stateName = this.isProjectClosed ? 'active' : 'completed'

      if (stateName === 'completed') {
        this.$store.commit('setEntityCreateParams', {
          project: this.project,
        })

        this.$store.commit('projects/triggerCompleteProject')
        return;
      }

      try {
        const status = this.isProjectClosed
          ? this.orderedProjectStatuses[0]
          : this.completedStatus;

        await this.$store.dispatch('projects/changeProjectStatus', {
          project: this.project,
          status_id: status?.id
        })
        await this.$store.dispatch('projects/getProjectById', { id: this.project.id })
        this.$success(i18n.t(`Project was set to ${stateName}`))
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t(`An error occurred while trying to set the project as ${stateName}`))
      }
    },
    shareTemplate() {
      this.showShareTemplateDialog = true
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Delete Project'),
        description: i18n.t("Are you sure you want to delete this project? This will delete all data related to this project. This action can't be undone.")
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch('projects/deleteProject', this.project.id);
        const viewOptions = this.$store.getters['users/defaultTargetViewOptions']('projects')

        this.$router.push(`/projects/${viewOptions.view_type}`)
      } catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(i18n.t('Cannot delete project'));
        throw err
      }
    },
  },
};
</script>
