<template>
  <SettingsForm
    ref="settingsForm"
    :fields="customBrandingKeys"
    :title="lightForm ? '' : $t('Custom Branding')"
    :description="lightForm ? '' : $t(`Brand your account so it feels like your own. The settings below will apply for everyone who accesses your account.`)"
    tutorial="custom-branding"
    :disabled="!can($actions.CUSTOM_BRANDING)"
  >
    <template #default="{ model }">

      <UpgradePlanWarning
        v-if="!can($actions.CUSTOM_BRANDING) && !lightForm"
        :message="$t(' to enable custom branding.')"
      />

      <CustomBrandingForm
        :model="model"
      />
    </template>
  </SettingsForm>
</template>
<script>
import SettingsForm from "@/modules/accounts/components/SettingsForm.vue";
import CustomBrandingForm from "@/modules/accounts/components/CustomBrandingForm.vue";
import SubscriptionDetails from "@/modules/accounts/components/SubscriptionDetails.vue";
import { customBrandingKeys } from "@/modules/accounts/utils/accountBrandingUtils";
import UpgradePlanWarning from "@/modules/accounts/components/UpgradePlanWarning.vue";

export default {
  components: {
    UpgradePlanWarning,
    SettingsForm,
    CustomBrandingForm,
    SubscriptionDetails,
  },
  props: {
    lightForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customBrandingKeys
    }
  },
  methods: {
    async onSubmit() {
      await this.$refs.settingsForm?.onSubmit()
    },
  },
  async created() {
    await this.$store.dispatch('auth/getTenantDetails')
    this.$store.dispatch("accounts/getPlans")
    this.$store.dispatch("accounts/getSubscription", { forceFetch: true })
  },
  beforeRouteLeave() {
    this.$refs.settingsForm?.resetModel()
    this.$store.commit('accounts/setTempBranding', null)
  }
}
</script>
