export async function initWistia() {
  if (window.Wistia) {
    return Promise.resolve(true)
  }

  const wistiaScript = document.createElement('script')
  wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
  wistiaScript.async = true
  document.body.appendChild(wistiaScript)

  return new Promise((resolve, reject) => {
    wistiaScript.onload = () => {
      resolve(true)
    }

    wistiaScript.onerror = (err) => {
      reject(err)
    }
  })
}
