import i18n from '@/i18n'
import store from "@/store"
import { computed } from 'vue'
import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";
import useCan from "@/modules/common/composables/useCan";
const { isRoleGreaterOrEqual } = useCan()

export const notesMainPath = computed(() => {
  const isTemplatePath = store.getters['templates/isTemplatePath']
  return isTemplatePath ? 'templates' : 'projects'
})


export const notePinnedTypes = {
  ALL: 'all',
  PINNED: 'pinned',
  UNPINNED: 'unpinned'
}

export enum NoteActions {
  CREATE = 'create',
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
  PIN = 'pin',
  CHANGE_VISIBILITY = 'change_visibility',
  REORDER = 'reorder',
}

export enum NotesVisibility {
  CREATORS_EDIT_COLLABORATORS_RESTRICT = 'creators_edit_collaborator_restrict',
  CREATORS_EDIT_COLLABORATORS_READ = 'creators_edit_collaborator_read_only',
  CREATORS_EDIT_COLLABORATORS_EDIT = 'creators_and_collaborator_edit',
}

export function canNote(action: NoteActions, visibility: NotesVisibility | '' = '') {
  if (action === NoteActions.CREATE) {
    return isRoleGreaterOrEqual(rolesEnum.COLLABORATOR_PLUS)
  }

  if ([
    NoteActions.PIN, NoteActions.CHANGE_VISIBILITY,
    NoteActions.REORDER, NoteActions.DELETE
  ].includes(action)) {
    return isRoleGreaterOrEqual(rolesEnum.CREATOR)
  }

  if (!visibility) {
    return false
  }

  const userRole = store.getters['users/currentRole']
  const isCreator = userRole.includes(rolesEnum.CREATOR)
  if (isCreator) {
    return true
  }
  if (visibility === NotesVisibility.CREATORS_EDIT_COLLABORATORS_READ && action === NoteActions.VIEW) {
    return true
  }
  return visibility === NotesVisibility.CREATORS_EDIT_COLLABORATORS_EDIT && action === NoteActions.EDIT;
}

export const notePrivacyOptions = computed(() => {
  return [
    {
      label: i18n.t('Creators (can edit) & Collaborators (no access)'),
      value: NotesVisibility.CREATORS_EDIT_COLLABORATORS_RESTRICT,
    },
    {
      label: i18n.t('Creators (can edit) & Collaborators (read-only)'),
      value: NotesVisibility.CREATORS_EDIT_COLLABORATORS_READ,
    },
    {
      label: i18n.t('Creators (can edit) & Collaborators (can edit)'),
      value: NotesVisibility.CREATORS_EDIT_COLLABORATORS_EDIT,
    },
  ]
})

export const pinnedOptions = computed(() => {
  return [
    {
      label: i18n.t('All'),
      value: notePinnedTypes.ALL,
    },
    {
      label: i18n.t('Yes'),
      value: notePinnedTypes.PINNED,
    },
    {
      label: i18n.t('No'),
      value: notePinnedTypes.UNPINNED,
    },
  ]
})
