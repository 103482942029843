<template>
  <BaseCardsView
    :target="EntityTargetTypes.USERS"
    :loading="usersLoading"
    dataGetterPath="users/usersWithGroups"
    groupedDataGetter="users/groupedUsers"
    columnsGetter="users/tableColumns"
    :extraFilters="extraFilters"
  >
    <template #no-data>
      <NoUsers
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
    </template>
  </BaseCardsView>
</template>
<script lang="ts" setup>
// Components
import NoUsers from "@/modules/users/components/NoUsers.vue";

// Libs
import { computed } from "vue";

// Utils
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'
import { extraFilters } from "@/modules/users/util/userTableUtils"

// Composables
import { useStore } from "vuex";

const store = useStore()

const usersLoading = computed(() => {
  return store.state.users.usersLoading
})
</script>
