<template>
  <div class="border border-gray-200 rounded-lg h-fit overflow-hidden">
    <div class="flex items-center body-background text-xl font-extrabold border-b border-gray-200 px-6 py-2 space-x-3">
      <div class="flex-none breadcrumb-color">
        <i
          v-if="widget.icon"
          class="opacity-60 align-middle mr-2"
          :class="{
            [widget.icon]: widget.icon
          }"
        />
        {{ widget.name }}
      </div>
      <div
        v-if="ui.listPath"
        class="grow"
      >
        <router-link
          :to="ui.listPath"
          class="text-gray-400 breadcrumb-color opacity-60 hover:opacity-100 text-xs font-normal align-middle"
        >
          {{ ui.listLabel }}
          <i class="fa-regular fa-arrow-right-long ml-1" />
        </router-link>
      </div>
      <div
        v-if="ui.entityCreate"
        class="flex-none"
      >
        <component
          :to="ui.entityCreate"
          :is="typeof ui.entityCreate === 'function' ? 'div' : 'router-link'"
          class="text-gray-300 text-base breadcrumb-color opacity-60 hover:opacity-100 cursor-pointer"
          @click="typeof ui.entityCreate === 'function' && ui.entityCreate()"
        >
          <i class="fa-solid fa-plus" />
        </component>
      </div>
    </div>
    <div
      v-loading="showLoadingOverlay"
      class="bg-white"
    >

      <div v-if="displayData.length">
        <div
          v-for="(key, i) of groupedData.keys()"
          :key="key"
        >
          <GroupByTitle
            :target="widgetData.target"
            :data="groupedData.get(key)"
            :columns="entityColumns"
            :keyValue="key"
            :groupBy="groupBy"
            :showColumnName="false"
            slim
            :headerClass="`capitalize font-bold flex items-center px-6 py-1.5 bg-gray-50 border-b border-gray-200 ${i > 0 ? 'border-t' : ''}`"
          >
            <div class="divide-y divide-gray-200">
              <div
                v-for="item of groupedData.get(key)"
                :key="item.id"
                class="space-x-3 flex items-center px-6 py-1.5"
              >
                <slot name="item" :item="item">
                  <img
                    v-if="item.attributes.image"
                    class="inline h-8 w-8 rounded-md"
                    :src="item.attributes.image"
                    alt=""
                  >
                  <div class="inline text-sm text-gray-500">
                    {{ item.attributes.name }}
                  </div>
                </slot>
              </div>
            </div>
          </GroupByTitle>
        </div>
      </div>
      <div v-else class="px-6 py-4 text-center bg-gray-50">
        <i
          v-if="emptyPlaceholderIcon"
          :class="emptyPlaceholderIcon"
          class="fa-duotone text-4xl text-gray-400"
        />
        <div class="text-sm text-gray-400">
          {{ emptyPlaceholder }}
        </div>
      </div>
    </div>
    <router-link
      v-if="ui.listPath && ui.footerKey && displayData.length < items.length"
      :to="showMorePath"
      class="bg-gray-50 px-6 py-2 text-sm text-gray-400 border-t border-gray-200 rounded-b-lg block"
      v-html="$tc(ui.footerKey, {
        showCount: displayMaxCount,
        totalCount: items.length
      })"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import GroupByTitle from "@/modules/filters/components/GroupByTitle.vue";

// Utils
import i18n from "@/i18n";
import { PropType, computed, inject } from 'vue'
import {
  DashboardWidgetType,
  WidgetUI,
  WidgetData,
  WidgetsContext,
  WidgetContextPages,
  getEmptyPlaceholders,
  emptyPlaceholderIcons
} from "@/modules/dashboard/utils/widgetUtils"
import { AllFilters, ApiFilter, LocalFilters } from "@/modules/common/commonTypes";
import { decodeFilters } from '@/modules/common/utils/filterUtils';

// Composables
import useSortedAndFilteredData from "@/modules/common/composables/useSortedAndFilteredData";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";

const store = useStore()
const router = useRouter()

const props = defineProps({
  ui: {
    type: Object as PropType<WidgetUI>,
    required: true
  },
  widget: {
    type: Object as PropType<DashboardWidgetType>,
    required: true
  },
  widgetData: {
    type: Object as PropType<WidgetData>,
    required: true
  },
})

const encodedFilters = computed(() => {
  if (!props.widget.filters) {
    return {
      filters: '',
      localFilters: ''
    }
  }

  return JSON.parse(props.widget.filters)
})

const decodedFilters = computed<AllFilters>(() => {
  const {
    filters,
    localFilters
  } = encodedFilters.value

  return {
    filtersAndSorts: decodeFilters(filters, []),
    localFilters: decodeFilters(localFilters, {})
  }
})

const filtersAndSorts = computed<ApiFilter[]>(() => {
  return decodedFilters.value.filtersAndSorts
})

const localFilters = computed<LocalFilters>(() => {
  return decodedFilters.value.localFilters
})

const groupBy = computed(() => {
  return localFilters.value.groupBy
})

const {
  sortedAndFilteredData: items,
  entityColumns
} = useSortedAndFilteredData({
  target: props.widgetData.target,
  dataGetterPath: props.widgetData.dataGetterPath,
  columnsGetter: props.widgetData.columnsGetter,
  filtersAndSorts,
  extraFilters: props.widgetData.extraFilters
})

const displayMaxCount = computed(() => {
  return props.widget.displayMaxCount || 5
})

const displayData = computed<any[]>(() => {
  return items.value.slice(0, displayMaxCount.value)
})

const groupedData = computed(() => {
  if (!props.widgetData.groupedDataGetter) {
    const map = new Map()
    map.set('default', displayData.value)
    return map
  }

  return store.getters[props.widgetData.groupedDataGetter](displayData.value, groupBy.value)
})

const showLoadingOverlay = computed(() => {
  return props.widgetData.loading || false
})

const showMorePath = computed(() => {
  return router.resolve({
    path: props.ui.listPath,
    query: {
      ...encodedFilters.value
    }
  })
})

const context = inject<WidgetsContext>('context', {
  page: WidgetContextPages.HomeDashboard,
})

const emptyPlaceholder = computed(() => {
  const emptyPlaceholders = getEmptyPlaceholders()
  return emptyPlaceholders[context?.page]?.[props.widget.component] || i18n.t('No data to display')
})

const emptyPlaceholderIcon = computed(() => {
  return emptyPlaceholderIcons[props.widget.component] || ''
})

</script>
