<template>
  <BaseTableCell :params="params">
    <div>
      {{ formattedPrice }}
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { get } from 'lodash-es';
import { computed } from 'vue';
import { formatPrice } from "@/plugins/formatPrice";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const currencyCode = computed(() => {
  return get(row.value, 'attributes.currency')
})

const amount = computed(() => {
  if (typeof columnParams.value?.getAmount === 'function') {
    return columnParams.value?.getAmount(row.value)
  }

  return get(row.value, colDef.value.field) || 0
})

const formattedPrice = computed(() => {
  return formatPrice(amount.value, {
    currencyCode: currencyCode.value
  })
})
</script>
