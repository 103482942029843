import AuthLayout from "../auth/layout/AuthLayout.vue";
import Login from './pages/login.vue'
import ForgotPassword from './pages/forgot-password.vue'
import ResetPassword from './pages/reset-password.vue'
import Register from './pages/register.vue'
import RegisterOnboarding from './pages/register-onboarding.vue'
import Maintenance from './pages/maintenance.vue'
import RegisterInvite from './pages/register-invite.vue'
import NoOrganization from './pages/no-organization.vue'
import Version from './pages/version.vue'

function hasToken() {
  return localStorage.getItem('token')
}

export default [
  {
    path: '/',
    components: {
      default: AuthLayout
    },
    redirect: hasToken() ? '/welcome' : '/login',
    meta: {
      productionReady: true,
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'register',
        name: 'Register',
        component: Register,
      },
      {
        path: 'register/onboarding',
        name: 'Register Onboarding',
        component: RegisterOnboarding,
      },
      {
        path: 'register/invitation',
        name: 'Register invitation',
        component: RegisterInvite,
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword,
      },
      {
        path: 'password/reset',
        name: 'Reset Password',
        component: ResetPassword
      },
      {
        path: 'maintenance',
        name: 'Maintenance',
        component: Maintenance
      },
    ]
  },
  {
    path: '/no-organization',
    name: 'Organization Not Found',
    meta: {
      skipOrganizationCheck: true,
      productionReady: true,
    },
    component: NoOrganization,
  },
  {
    path: '/version',
    name: 'Version Page',
    meta: {
      skipOrganizationCheck: true,
    },
    component: Version,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    meta: {
      skipOrganizationCheck: true,
    },
    component: () => import(/* webpackChunkName: "auth" */ './pages/404.vue')
  },
]
