<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    :appendToBody="true"
    size="full"
    class="max-w-4xl mx-auto p-0"
    @before-close="beforeClose"
    @close="onClose"
  >
    <div class="m-auto align-middle bg-white rounded-lg">
      <ProjectInlineDetails
        ref="projectDetails"
        :currentProject="project"
        :projectId="projectId"
        :isDialog="true"
        :loading="loading"
        :redirectAfterSave="redirectAfterSave"
        @is-valid-changed="isValidModel = $event"
        @close-dialog="onCloseButtonClicked"
        @save="$emit('save', $event)"
      />
    </div>
  </BaseDialog>
</template>
<script>
import ProjectInlineDetails from "@/modules/projects/components/ProjectInlineDetails.vue";
import i18n from "@/i18n";

export default {
  inheritAttrs: false,
  components: {
    ProjectInlineDetails,
  },
  props: {
    project: {
      type: Object,
      default: () => null
    },
    projectId: {
      type: [String, Number],
      default: () => null
    },
    redirectAfterSave: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      isValidModel: true
    }
  },
  computed: {
    isProjectCreate() {
      return !this.projectId && !this.project?.id
    }
  },
  methods: {
    onClose() {
      if (!this.isProjectCreate && !this.isValidModel) {
        this.$error(i18n.t("Couldn't save project as invalid items weren't resolved."))
      }

      this.$emit('close');
    },
    onCloseButtonClicked() {
      this.beforeClose()
      this.onClose()
    },
    beforeClose() {
      this.$refs.projectDetails?.triggerSave()
    }
  }
}
</script>
