<template>
  <BaseInlineInput>
    <template #default="{ triggerSave }">
      <BaseSelect
        v-model="model"
        v-focus="autoFocus"
        :options="availableOptions"
        :returnObject="false"
        :persistent="false"
        :disabled="$attrs.disabled"
        labelKey="label"
        valueKey="value"
        inline
        :filterable="autoFocus"
        class="date-type-select"
        @update:modelValue="triggerSave && triggerSave()"
      >
        <template #prefix>
          <div class="text-sm text-gray-400 cursor-pointer inline">
            {{ selectedOption?.label || '' }}
          </div>
        </template>
      </BaseSelect>
    </template>
  </BaseInlineInput>
</template>
<script>
import { taskDateTypes } from "@/modules/tasks/utils/modelUtils"
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    isTemplateTask: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    isBulkCreate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateTypeOptions: [
        {
          label: this.$t('No Date'),
          value: taskDateTypes.NO_DATE,
        },
        {
          label: this.$t('Single Date'),
          value: taskDateTypes.SINGLE_DATE,
        },
        {
          label: this.$t('Date Range'),
          value: taskDateTypes.DATE_RANGE,
        },
        {
          label: this.$t('Recurring'),
          value: taskDateTypes.RECURRING,
          enabled: () => !this.isBulkCreate,
        },
        {
          label: this.$t('X Days after project start date'),
          value: taskDateTypes.DAYS_FROM_PROJECT_START,
          enabled: () => this.isTemplateTask
        },
        {
          label: this.$t('X Working days after project start date'),
          value: taskDateTypes.WORKING_DAYS_FROM_PROJECT_START,
          enabled: () => this.isTemplateTask
        },
      ]
    }
  },
  computed: {
    availableOptions() {
      return this.dateTypeOptions.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return true
      })
    },
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    selectedOption() {
      return this.dateTypeOptions.find(x => x.value === this.modelValue)
    }
  },
}
</script>
