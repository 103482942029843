import i18n from "@/i18n";
import { formatPercent } from "@/plugins/formatPercent";
import { formatPrice, getCurrencyWithSymbol } from "@/plugins/formatPrice";
import { EditableCellTypes } from "@/components/table/cells/tableFrameworkComponents";
import { get, set } from 'lodash-es';
import { formatDate } from "@/modules/common/utils/dateUtils";
import store from "@/store";

async function onCustomFieldCreated(customField, postSync) {
  if (postSync && typeof postSync === 'function') {
    postSync(customField)
  }
}

export function triggerCreateCustomField(entity_type, project_id, options) {
  store.commit('setEntityCreateParams', {
    defaultAppliesTo: entity_type,
    project_id,
    ...(options?.createParams || {})
  })

  const defaultOnCreate = (customField) => onCustomFieldCreated(customField, options?.postSync)

  store.commit('setEntityCreatedCallback', options?.onCreateCallback || defaultOnCreate)
  store.commit('accounts/triggerAddCustomField')
}

export function triggerEditCustomField(customField, options = {}) {
  store.commit('setEntityCreateParams', {
    customField
  })

  const defaultOnEdit = (customField) => onCustomFieldCreated(customField, options?.postSync)

  store.commit('setEntityCreatedCallback', options?.onEditCallback || defaultOnEdit)
  store.commit('accounts/triggerAddCustomField')
}

export const customFieldTypes = {
  Text: 'text',
  Textarea: 'textarea',
  Numeric: 'numeric',
  Currency: 'currency',
  Percent: 'percent',
  Select: 'select',
  SelectColor: 'select-color',
  Multiselect: 'multiselect',
  Date: 'date'
}

export const customFieldAppliesToOptions = {
  PROJECT: 'project',
  TASK: 'task',
  GROUP: 'group',
  USER: 'user',
  TIME_ENTRY: 'time_entry',
  PAYMENT: 'payment',
  FILE: 'media',
}

export const entityTypeOptions = [
  {
    value: customFieldAppliesToOptions.PROJECT,
    label: i18n.t("Project"),
  },
  {
    value: customFieldAppliesToOptions.TASK,
    label: i18n.t("Task"),
  },
  {
    value: customFieldAppliesToOptions.GROUP,
    label: i18n.t("Group"),
  },
  {
    value: customFieldAppliesToOptions.USER,
    label: i18n.t("People"),
  },
  {
    value: customFieldAppliesToOptions.TIME_ENTRY,
    label: i18n.t("Time Entry"),
  },
  {
    value: customFieldAppliesToOptions.PAYMENT,
    label: i18n.t("Payment"),
  },
  {
    value: customFieldAppliesToOptions.FILE,
    label: i18n.t("File"),
  },
];

const comparatorTypesMap = {
  textComparator: (a, b) => {
    const [vA, vB] = [
      (a || '').toString().toLowerCase(),
      (b || '').toString().toLowerCase()
    ]

    return vA.localeCompare(vB)
  },
  dateComparator: (a, b) => {
    return Date.parse(a || 0) - Date.parse(b || 0)
  },
  numericComparator: (a, b) => {
    return Number(a || 0) - Number(b || 0)
  },
  selectComparator: (a, b, customFieldDefinition) => {
    const valueA = a?.value || a || ''
    const valueB = b?.value || b || ''

    const options = customFieldDefinition.attributes?.options || []
    const aOrder = options.findIndex((option) => option.value == valueA)
    const bOrder = options.findIndex((option) => option.value == valueB)

    return aOrder - bOrder
  },
  multiselectComparator: (a, b) => {
    const strA = (a || []).map(item => item.label).join(', ')
    const strB = (b || []).map(item => item.label).join(', ')

    return strA.localeCompare(strB)
  }
}

const comparators = {
  [customFieldTypes.Text]: comparatorTypesMap.textComparator,
  [customFieldTypes.Textarea]: comparatorTypesMap.textComparator,
  [customFieldTypes.Numeric]: comparatorTypesMap.numericComparator,
  [customFieldTypes.Currency]:  (a, b, customFieldDefinition) => {
    const options = customFieldDefinition.attributes?.options || []

    const currencyIndexA = options.findIndex(option => option.value === a?.currencyName)
    const currencyIndexB = options.findIndex(option => option.value === b?.currencyName)

    if (currencyIndexA !== currencyIndexB) {
      return currencyIndexA - currencyIndexB
    }

    return comparatorTypesMap.numericComparator(a?.currencyValue, b?.currencyValue)
  },
  [customFieldTypes.Percent]: comparatorTypesMap.numericComparator,
  [customFieldTypes.Select]: comparatorTypesMap.selectComparator,
  [customFieldTypes.SelectColor]: comparatorTypesMap.selectComparator,
  [customFieldTypes.Multiselect]: (a, b) => {
    const strA = (a || []).map(item => item.label).join(', ')
    const strB = (b || []).map(item => item.label).join(', ')

    return comparatorTypesMap.textComparator(strA, strB)
  },
  [customFieldTypes.Date]: comparatorTypesMap.dateComparator
}

const filtererTypes = {
  textFilter: (row, prop, filterValue) => {
    const value = get(row, prop, '')

    return value.toLowerCase().includes(filterValue.toLowerCase())
  },
  numericRangeFilter: (row, prop, filterValue) => {
    const value = Number(get(row, prop, ''))
    const min = Number(filterValue.min)
    const max = Number(filterValue.max)

    return value >= min && value <= max
  },
  dateRangeFilter: (row, prop, filterValue) => {
    const value = Date.parse(get(row, prop, 0))
    const min = Date.parse(filterValue.min)
    const max = Date.parse(filterValue.max)

    return value >= min && value <= max
  },
  selectFilter: (row, prop, filterValue) => {
    const value = get(row, prop, '')

    filterValue = typeof filterValue === 'string'
      ? filterValue
      : filterValue?.value || ''

    return value === filterValue
  }
}

const filterers = {
  [customFieldTypes.Text]: filtererTypes.textFilter,
  [customFieldTypes.Textarea]: filtererTypes.textFilter,
  [customFieldTypes.Numeric]: filtererTypes.numericRangeFilter,
  [customFieldTypes.Currency]: (row, prop, filterValue) => {
    const currencyName = get(row, `${prop}.currencyName`, '')
    const currencyValue = get(row, `${prop}.currencyValue`, '')

    if (filterValue.currencyName && filterValue.currencyName !== currencyName) {
      return false
    }

    if (!filterValue.max) {
      return currencyValue >= Number(filterValue.min)
    }

    if (!filterValue.min) {
      return currencyValue <= Number(filterValue.max)
    }

    return filtererTypes.numericRangeFilter(row, `${prop}.currencyValue`, filterValue)
  },
  [customFieldTypes.Percent]: filtererTypes.numericRangeFilter,
  [customFieldTypes.Select]: filtererTypes.selectFilter,
  [customFieldTypes.SelectColor]: filtererTypes.selectFilter,
  [customFieldTypes.Multiselect]: (row, prop, filterValue) => {
    let value = get(row, prop, [])
    value = Array.isArray(value) ? value : []

    return value.some(item => filterValue.some(fv => fv.value === item.value))
  },
  [customFieldTypes.Date]: filtererTypes.dateRangeFilter
}

export function customFieldToColumnMapper(customField, options) {
  let prop = `attributes.custom_fields.${customField.attributes.key}`
  let customFieldValuePath = prop
  const customFieldType = customField.attributes?.custom_field_type;

  const sortProp = customField.attributes.allow_sort_by ? customField.attributes.key : '';
  const sortable = customField.attributes.allow_sort_by
  const group = customField.attributes.allow_group_by

  const needsValueForGrouping = [
    customFieldTypes.SelectColor
  ].includes(customFieldType)

  // We need to set .value for some custom field types to be used when grouping
  if (needsValueForGrouping) {
    prop += `.value`
  }

  return {
    headerComponent: 'EditCustomFieldHeader',
    headerComponentParams: {
      customField,
      enableSorting: sortable,
    },
    sortable,
    comparator: (a, b) => comparators[customFieldType](a, b, customField),
    // OLD
    name: customField.attributes.name,
    prop,
    sortProp,
    customField,
    showCardLabel: true,
    editable: options?.isPropEditable || false,
    cellEditor: EditableCellTypes.CustomField,
    onCellValueChanged: options?.onCellValueChanged,
    valueSetter: (params) => {
      let currentValue = get(params.data, customFieldValuePath)
      let newValue = params.newValue

      if (typeof currentValue !== 'string') {
        currentValue = JSON.stringify(currentValue)
      }

      if (typeof newValue !== 'string') {
        newValue = JSON.stringify(newValue)
      }

      if (currentValue === newValue) {
        return false
      }

      set(params.data, customFieldValuePath, params.newValue)

      return true
    },
    component: 'CustomFieldCell',
    params: {
      ...(options?.params || {})
    },
    filterBy: customField.attributes?.allow_filter_by && {
      prop: customField.attributes.key,
      type: customField.attributes.custom_field_type === customFieldTypes.Date
        ? 'date-range'
        :'',
      component: customField.attributes.custom_field_type === customFieldTypes.Date
        ? ''
        : "CustomFieldInput",
      displayFormat(value) {
        return customFieldValueDisplayFormat(customField, customFieldType, value);
      },
      range: [
        customFieldTypes.Percent,
        customFieldTypes.Numeric
      ].includes(customFieldType),
      props: {
        customField,
        hideLabel: ![ customFieldTypes.Currency ].includes(customFieldType),
        layout: "vertical",
        colSpan: 4,
        columnCount: 2,
        clearable: false,
        minMaxRange: [ customFieldTypes.Currency ].includes(customFieldType),
      },
      doesFilterPass(row, filterValue) {
        const value = get(row, prop, '')
        if (!value) {
          return false;
        }

        return filterers[customFieldType](row, prop, filterValue)
      }
    },
    group,
    required: false,
  };
}

export function customFieldValueDisplayFormat(customField, type, value) {
  if (!value) {
    return "";
  }

  if (type === customFieldTypes.Numeric) {
    return formatNumeric(value);
  }

  if (type === customFieldTypes.Percent) {
    return formatPercentValue(value);
  }

  if (type === customFieldTypes.Currency) {
    return formatCurrency(value);
  }

  if (type === customFieldTypes.Multiselect) {
    const multiValue = (value || [])

    return Array.isArray(multiValue)
      ? multiValue.map((x) => x.label).join(", ")
      : []
  }

  if (type === customFieldTypes.Select) {
    return (customField.attributes?.options || []).find(v => v.value?.toString() === value?.toString())?.label || ''
  }

  if (type === customFieldTypes.SelectColor) {
    let options = customField.attributes?.options || [];

    if (!Array.isArray(options)) {
      options = Object.values(options).map(o => {
        return {
          value: o,
          label: o,
        }
      })
    }
    const option = options.find(v => v.value?.toString() === value.value?.toString() || v.label?.toString() === value.label?.toString())

    if (!option) {
      return {
        ...value,
        hasColor: true,
      }
    }

    return {
      ...option,
      hasColor: true
    }
  }

  if (type === customFieldTypes.Date) {
    if (typeof value !== "object") {
      return formatDate(value);
    }

    return `${formatDate(value.min)} - ${formatDate(value.max)}`;
  }

  return value;
}

function formatNumeric(value) {
  if (typeof value !== "object") {
    return formatPrice(value, {
      currencyDisplay: false,
    });
  }

  const min = formatPrice(value.min, {
    currencyDisplay: false,
  });
  const max = formatPrice(value.max, {
    currencyDisplay: false,
  });

  return `${min} - ${max}`;
}

function formatPercentValue(value) {
  if (typeof value !== "object") {
    return formatPercent(value);
  }

  const min = formatPercent(value.min);
  const max = formatPercent(value.max);

  return `${min} - ${max}`;
}

function formatCurrency(value) {
  if (value?.currencyValue) {
    return formatPrice(value?.currencyValue, {
      currencyCode: value?.currencyName,
      ignoreDefaultCurrency: true
    });
  }

  const min = formatPrice(value.min, {
    currencyCode: value.currencyName,
    ignoreDefaultCurrency: true
  });
  const max = formatPrice(value.max, {
    currencyCode: value.currencyName,
    ignoreDefaultCurrency: true
  });

  if (value.min && !value.max) {
    return `>= ${min}`
  }

  if (value.max && !value.min) {
    return `<= ${max}`
  }

  if (!value.min && !value.max) {
    return getCurrencyWithSymbol(value?.currencyName)
  }

  return `${min} - ${max}`;
}

export function getCustomFieldValuesObject(customFieldValues) {
  if (!customFieldValues) {
    return {}
  }

  if (typeof customFieldValues === 'string') {
    return JSON.parse(customFieldValues)
  }

  return customFieldValues
}

export function getCustomFieldValuesStringified(customFieldValues) {
  if (!customFieldValues) {
    return null
  }

  if (typeof customFieldValues === 'string') {
    return customFieldValues
  }

  if (!Object.keys(customFieldValues).length) {
    return null
  }

  return JSON.stringify(customFieldValues)
}
