<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    :appendToBody="true"
    class="embed-dialog-wrapper"
  >
    <div class="max-w-3xl m-auto align-middle bg-white border border-gray-200 overflow-hidden rounded-lg shadow">
      <div class="w-full border-b border-gray-200 p-6">
        <h3 class="text-2xl font-bold">{{ $t('New Embed') }}</h3>
        <p class="text-sm text-gray-500 mt-2">
          {{ $t(`Enter any link below and we'll try to convert it into an embed link automatically.`) }}
        </p>
      </div>
      <VeeForm v-slot="{ handleSubmit, meta }">
        <form class="divide-y divide-gray-200"
              @submit.prevent="handleSubmit(onSubmit)">
          <div class="divide-y divide-gray-200 sm:space-y-5 p-6">

            <div class="space-y-6 sm:space-y-5">
              <BaseInput
                v-focus
                v-model="model.src"
                :label="$t('Link')"
                :placeholder="$t('Enter a link...')"
                :name="$t('Link')"
                rules="required"
                layout="horizontal"
                id="description">
              </BaseInput>
            </div>
          </div>

          <div class="px-6 py-4 mt-6 border-t border-gray-200">
            <div class="flex space-x-2 justify-end">

              <BaseButton
                variant="white"
                @click="$emit('cancel')"
                class="justify-items-end"
              >
                {{ $t('Cancel') }}
              </BaseButton>
              <BaseButton
                :disabled="!meta.valid"
                type="submit"
              >
                {{ $t('Save') }}
              </BaseButton>

            </div>
          </div>
        </form>
      </VeeForm>
    </div>
  </BaseDialog>
</template>
<script>
export default {
  name: 'EmbedDialogForm',
  emits: ['submit', 'cancel'],
  data() {
    return {
      model: {
        src: ''
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.model)
    },
  }
}
</script>
<style scoped>
.embed-dialog-wrapper {
  /* work around so dialog appears above / command menu */
  z-index: 10000 !important;
}
</style>
