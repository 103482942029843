import embedTypesJson from "@/modules/resources/utils/embedTypes.json";
import embedCodeTypesJson from "@/modules/resources/utils/embedCodeTypes.json";
import i18n from "@/i18n"

export const embedTypes = [...embedTypesJson, ...embedCodeTypesJson].map((x, i) => ({
 ...x,
 id: i + 100,
 label: i18n.t(x.label),
 subtitle: i18n.t(x.subtitle),
 tutorial_link: i18n.t(x.tutorial_link),
 image: getEmbedImage(x) 
}))

export function getEmbedImage(embedType) {
  if (typeof embedType === 'string') {
    embedType = embedTypes.find(x => x.value === embedType)
  }

  if (!embedType || embedType.icon) {
    return ''
  }

  return `/img/project_resources/${embedType.value}.png`
}

export function getEmbedIcon(embedType) {
  if (embedType === 'anything_by_code') {
    return 'fa-solid fa-code'
  }

  if (typeof embedType === 'string') {
    embedType = embedTypes.find(x => x.value === embedType)
  }

  if (!embedType) {
    return 'fa-solid fa-input-pipe'
  }

  return embedType.icon || 'fa-solid fa-input-pipe'
}

export function getEmbedLabel(embedType, size = 48) {
  if (typeof embedType === 'string') {
    embedType = embedTypes.find(x => x.value === embedType)
  }
  return embedType?.label || i18n.t('Embed')
}

const embedUrlPreprocessing = {
  'figma': (url) => {
    const prefix = 'https://www.figma.com/embed?embed_host=share&url='
    if (url.startsWith(prefix)) {
      return url
    }
    return `${prefix}${url}`
  },
  'miro': (url) => {
    const prefix = 'https://miro.com/app/embed/'
    if (url.startsWith(prefix)) {
      return url
    }

    url = new URL(url)
    const code = url.pathname.split('/').filter(x => !!x && !['app', 'board'].includes(x))[0]

    return `${prefix}${code}`
  },
  'airtable': (url) => {
    const prefix = 'https://airtable.com/embed/'
    const suffix = `?viewControls=on`
    if (url.startsWith(prefix)) {
      return url
    }
    
    url = new URL(url)
    const code = url.pathname.split('/').filter(x => !!x)[0]

    return `${prefix}${code}${suffix}`
  },
  'loom': (url) => {
    const prefix = 'https://www.loom.com/embed/'
    if (url.startsWith(prefix)) {
      return url
    }
    
    return url.replace('/share/', '/embed/')
  },
  'wistia': (url) => {
    const prefix = 'https://fast.wistia.net/embed/iframe/'
    if (url.startsWith(prefix)) {
      return url
    }

    url = new URL(url);

    const paths = url.pathname.split('/').filter(x => !!x)
    let code = ''
    for (let i = 0; i < paths.length; i++) {
      if (paths[i] === 'medias') {
        code = paths[i + 1]
        break;
      }
    }

    if (!code) {
      console.error('Invalid Wistia link - cannot extract code', url)
    }

    return `${prefix}${code}`
  },
  'vimeo': (url) => {
    const prefix = `https://player.vimeo.com/video/`
    if (url.startsWith(prefix)) {
      return url
    }

    url = new URL(url)
    
    const code = url.pathname.split('/').filter(x => !!x)[0]

    if (!code) {
      console.error('Invalid Vimeo link - cannot extract code', url)
    }

    return `${prefix}${code}`
  },
  'youtube': (url) => {
    const prefix = `https://www.youtube.com/embed/`
    if (url.startsWith(prefix)) {
      return url
    }

    url = new URL(url);
    let code = ''
    if (url.host === 'youtu.be') {
      code = url.pathname.split('/').filter(x => !!x)[0]
    }
    else if (url.hostname.includes('youtube.com')) {
      code = url.searchParams.get('v')
    }

    if (!code) {
      console.error('Invalid youtube link - cannot extract code', url)
    }

    return `${prefix}${code}`
  },
}

export function getEmbedIFrameUrl(embedType, url) {
  if (!embedUrlPreprocessing[embedType]) {
    return url
  }
  
  try {
    return embedUrlPreprocessing[embedType](url)
  } catch (err) {
    return ''
  }
}
