<template>
  <BaseInlineInput>
    <template #default="{ triggerSave }">
      <BaseSelect
        v-model="model"
        :options="notePrivacyOptions"
        :disabled="$attrs.disabled"
        :return-object="false"
        inline
        filterable
        class="fit-content disabled-focus"
      >
        <template #prefix>
          <div>
            <i class="far fa-lock mr-0.5"/>
            {{ selectedOption?.label }}
          </div>
        </template>
      </BaseSelect>
    </template>
  </BaseInlineInput>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { notePrivacyOptions } from "@/modules/projects/utils/noteUtils";

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: '',
  }
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (value === props.modelValue) {
      return
    }
    emit('update:modelValue', value)
  }
})

const selectedOption = computed(() => {
  return notePrivacyOptions.value?.find(o => o.value === model.value)
})
</script>

<style lang="scss" scoped>
:deep(.el-input .el-input__prefix-inner > :last-child) {
  @apply mr-0;
}

:deep(.el-input__wrapper) {
  padding-right: 0 !important;
}
</style>
