<template>
  <BubbleMenu
      :editor="editor"
      :pluginKey="pluginKey"
      :shouldShow="shouldShow"
      updateDelay="0"
      :tippyOptions="tippyOptions"
  >
    <ToolbarWrapper>
      <ToolbarButton
          :tooltip="$t('Sidebar left')"
          :active="editor.isActive('columns', { layout: ColumnLayout.SidebarLeft })"
          @click="onColumnLeft"
      >
        <Icon name="fa-regular fa-left-to-line"/>
      </ToolbarButton>
      <ToolbarButton
          :tooltip="$t('Two columns')"
          :active="editor.isActive('columns', { layout: ColumnLayout.TwoColumn })"
          @click="onColumnTwo"
      >
        <Icon name="fa-regular fa-table-columns"/>
      </ToolbarButton>
      <ToolbarButton
          :tooltip="$t('Sidebar right')"
          :active="editor.isActive('columns', { layout: ColumnLayout.SidebarRight })"
          @click="onColumnRight"
      >
        <Icon name="fa-regular fa-right-to-line"/>
      </ToolbarButton>
    </ToolbarWrapper>
  </BubbleMenu>
</template>
<script setup>
import { BubbleMenu } from '@tiptap/vue-3'
import { sticky } from 'tippy.js'
import { computed } from "vue";
import getRenderContainer from "@/components/html/util/getRenderContainer";
import { ColumnLayout } from "@/components/html/extensions/MultiColumn";
import ToolbarWrapper from "@/components/html/components/toolbar/ToolbarWrapper.vue";
import ToolbarButton from "@/components/html/components/toolbar/ToolbarButton.vue";
import Icon from "@/components/html/components/Icon.vue";
const uuid = crypto.randomUUID

const props = defineProps({
  editor: Object,
  appendTo: Object
})

const getReferenceClientRect = () => {
  const renderContainer = getRenderContainer(props.editor, 'columns')
  const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0)
  return rect
}

const tippyOptions = {
  offset: [0, 8],
  popperOptions: {
    modifiers: [{ name: 'flip', enabled: false }],
  },
  getReferenceClientRect: getReferenceClientRect,
  appendTo: () => props.appendTo,
  plugins: [sticky],
  sticky: 'popper',
}
const shouldShow = () => {
  const isColumns = props.editor.isActive('columns')
  return isColumns
}

const pluginKey = computed(() => {
  return `columns-menu-${crypto.randomUUID()}`
})

const onColumnLeft = () => {
  props.editor.chain().focus().setLayout(ColumnLayout.SidebarLeft).run()
}

const onColumnRight = () => {
  props.editor.chain().focus().setLayout(ColumnLayout.SidebarRight).run()
}

const onColumnTwo = () => {
  props.editor.chain().focus().setLayout(ColumnLayout.TwoColumn).run()
}
</script>
