<template>
  <TooltipProvider>
    <TooltipRoot v-bind="forwarded">
      <TooltipTrigger asChild>
        <slot></slot>
      </TooltipTrigger>
      <TooltipContent>
        <slot name="content">
          <span
              class="flex items-center gap-2 px-2.5 py-1 bg-white border border-neutral-100 rounded-lg shadow-sm z-[999]"
              :tabindex="-1"
              :data-placement="placement || attrs['data-placement']"
              :data-reference-hidden="attrs['data-reference-hidden']"
              :data-escaped="attrs['data-escaped']"
          >
            <span v-if="title" class="text-xs font-medium text-neutral-500">
              {{ title }}
            </span>

            <span v-if="shortcut" class="flex items-center gap-0.5">
              <ShortcutKey v-for="key in shortcut" :key="key" :text="key"/>
            </span>
          </span>
        </slot>
      </TooltipContent>
    </TooltipRoot>
  </TooltipProvider>
</template>

<script setup lang="ts">
import {
  TooltipContent,
  TooltipProvider,
  TooltipRoot,
  type TooltipRootEmits,
  TooltipTrigger,
  useForwardPropsEmits
} from 'radix-vue'
import {PropType, useAttrs} from "vue";
import ShortcutKey from "@/components/html/components/tooltip/ShortcutKey.vue";

const props = defineProps({
  title: String,
  shortcut: {
    type: Array as PropType<string[]>,
  },
  placement: {
    type: String,
    default: 'top',
  }
})
const emits = defineEmits([])

const forwarded = useForwardPropsEmits(props, emits as any)
const attrs = useAttrs()
</script>
