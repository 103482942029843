<template>
  <BaseTableCell :params="params">
    <CustomFieldValue
      :model="row"
      :customField="customField"
      :placeholder="placeholder"
      class="inline-flex"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { PropType, computed } from "vue"
import CustomFieldValue from "@/modules/common/components/CustomFieldValue.vue";
import { ICellRendererParams } from "@ag-grid-community/core";

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams<any>>,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const placeholder = computed(() => {
  return columnParams.value?.hideEmpty?.(row.value) ? '' : '- -'
})

const customField = computed(() => {
  return colDef.value.customField
})
</script>
