<template>
  <li
    v-if="canUseFilter"
    class="flex"
  >
    <Component
      :is="filterComponent"
      :default-filter="defaultValue"
      :entityType="entityLabel"
      :target="target"
      isBreadcrumbFilter
      @change="onFilterChange"
    >
      <div class="flex items-center">
        <i class="fa-solid fa-caret-right breadcrumb-color opacity-80 hover:opacity-100 text-base" />
        
        <div class="ml-4 text-sm font-medium truncate max-w-[350px] breadcrumb-color opacity-80 hover:opacity-100">
          {{ displayValue }}
        </div>

        <i class="fa-solid fa-square-chevron-down ml-2 breadcrumb-color opacity-80 hover:opacity-100" />
      </div>
    </Component>
  </li>
</template>
<script lang="ts" setup>
// Components
import MyAllFilter from "@/modules/filters/components/MyAllFilter.vue";
import ArchivedUsersFilter from "@/modules/filters/components/ArchivedUsersFilter.vue";

// Utils
import { computed } from "vue";
import i18n from "@/i18n";
import { EntityTargetTypes, encodeFilters } from "@/modules/common/utils/filterUtils"
import {
  ApiFilterValue,
  ApiFilterKeys,
  BreadcrumbsFilterOptions,
  BreadcrumbsFiltersMap,
} from "@/modules/common/commonTypes.d";

// Composables
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import useCan from "@/modules/common/composables/useCan"

const props = defineProps({
  matchedRoute: {
    type: Object,
    required: true,
  },
})

const store = useStore()
const route = useRoute()
const router = useRouter()
const { can, actions } = useCan()

const filters: BreadcrumbsFiltersMap = {
  [ApiFilterKeys.MyProjects]: {
    target: EntityTargetTypes.PROJECTS,
    entityLabel: i18n.t('Projects'),
    component: MyAllFilter,
    displayValue(value: Boolean) {
      return value
        ? i18n.t('My Projects')
        : i18n.t('All Projects')
    }
  },
  [ApiFilterKeys.MyTasks]: {
    target: EntityTargetTypes.TASKS,
    entityLabel: i18n.t('Tasks'),
    component: MyAllFilter,
    displayValue(value: Boolean) {
      return value
        ? i18n.t('My Tasks')
        : i18n.t('All Tasks')
    }
  },
  [ApiFilterKeys.ArchivedUsers]: {
    target: EntityTargetTypes.USERS,
    component: ArchivedUsersFilter,
    displayValue(value: Boolean) {
      return value
        ? i18n.t('Archived')
        : i18n.t('Active')
    },
    requirePermissionTo: actions.VIEW_ARCHIVED_USERS,
  },
}

const filterKey = computed<ApiFilterKeys>(() => {
  return props.matchedRoute.meta.useFilter
})

const filterOptions = computed<BreadcrumbsFilterOptions>(() => {
  return filters[filterKey.value]
})

const canUseFilter = computed(() => {
  return !filterOptions.value?.requirePermissionTo || can(filterOptions.value?.requirePermissionTo)
})

const target = computed(() => {
  return filterOptions.value.target
})

const apiFilters = computed(() => {
  return store.getters['filters/targetApiFilters'](target.value)
})

const defaultValue = computed(() => {
  return apiFilters.value.find((f: ApiFilterValue) => f.key === filterKey.value)?.value?.show
})

const entityLabel = computed(() => {
  return filterOptions.value?.entityLabel
})

const displayValue = computed(() => {
  return filterOptions.value?.displayValue(defaultValue.value)
})

const filterComponent = computed(() => {
  return filterOptions.value?.component
})

function onFilterChange(value: any) {
  const filterIdx = apiFilters.value.findIndex((f: ApiFilterValue) => f.key === filterKey.value);
  
  const newApiFilters = [...apiFilters.value]

  const newValue = {
    key: filterKey.value,
    value: { show: value }
  };

  if (filterIdx === -1) {
    newApiFilters.push(newValue)
  }
  else {
    newApiFilters[filterIdx] = newValue
  }

  const query = {
    ...route.query,
    filters: encodeFilters(newApiFilters),
  }

  const newRoute = router.resolve({
    path: route.path,
    query,
  })

  router.replace(newRoute)
}
</script>
