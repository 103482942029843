<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    :appendToBody="true"
  >
    <BaseEntityForm
      width-class="max-w-3xl"
      :title="status?.id ? $t('Edit Project Status') : $t('Create New Project Status')"
      :isDialogForm="true"
      @submit="onSubmit"
    >
      <template #default="{ meta, errors }">
        <BaseInput
          :label="$t('Color')"
          id="color"
        >
          <ColorPicker
            v-model="model.color"
          />
        </BaseInput>

        <BaseInput
          v-model="model.name"
          v-focus="100"
          :label="$t('Name')"
          :placeholder="$t('Enter status name...')"
          :name="$t('Name')"
          :disabled="isCompletedStatus"
          rules="required"
          id="name"
        />
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          variant="white"
          @click="$emit('update:modelValue', false)"
        >
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          :disabled="!meta.valid"
          type="submit"
          class="ml-2"
        >
          {{ saveText }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialog>
</template>
<script>
import ColorPicker from "@/components/form/ColorPicker.vue";
import axios from "axios";
import i18n from "@/i18n";

export default {
  inheritAttrs: false,
  components: {
    ColorPicker,
  },
  props: {
    status: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      loading: false,
      model: {
        name: '',
        color: '#6B7280',
      }
    }
  },
  computed: {
    saveText() {
      if (this.status?.id) {
        return i18n.t('Save')
      }
      return i18n.t('Create Status')
    },
    isCompletedStatus() {
      return this.status?.id === this.$store.getters['projects/completedStatus']?.id
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        const statusId = this.status?.id
        if (statusId) {
          await axios.put(`/restify/project-statuses/${statusId}`, this.model)
        } else {
          await axios.post(`/restify/project-statuses`, this.model)
        }
        this.$store.dispatch('projects/getAllStatuses', true)
        if (statusId) {
          this.$success(i18n.t('Status updated successfully'))
        } else {
          this.$success(i18n.t('Status created successfully'))
        }
        this.closeModal()
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not save the project status'))
      } finally {
        this.loading = false
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false)
    }
  },
  watch: {
    status: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.model = {
          ...this.model,
          ...value.attributes,
        }
      }
    }
  }
}
</script>
<style>
</style>
