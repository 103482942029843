<template>
  <div
    class="relative bg-white border border-gray-200 rounded-lg group cursor-pointer"
    @click="onClick"
  >
    <div
      v-if="isSelected"
      class="bg-primary-300 absolute right-0 left-0 top-0 bottom-0 text-right rounded-lg pt-2"
    >
      <div
        v-if="allowMultiple"
        class="text-sm font-medium text-white underline mr-2"
        @click="$emit('toggle-tool-selected')"
      >
        {{ $t('+ Add Another') }}
      </div>
      <router-link
        v-if="canOpenTool"
        :to="path"
        @click.stop
      >
        <span class="text-sm font-medium text-white underline mr-2">
          {{ $t('Open') }}
        </span>
      </router-link>
      <div
        v-if="canRemoveTool"
        class="text-sm font-medium text-white underline mr-2"
        @click="$emit('toggle-tool-selected')"
      >
        {{ $t('Remove') }}
      </div>
    </div>
    <div class="p-4">
      <div class="flex space-x-2 items-center">
        <span
          :style="{ color }"
          class="tool-icon"
        >
          <img
            v-if="image"
            :src="image"
            class="w-8 h-8 scale-125 mr-1 rounded-lg"
          />
          <i
            v-else
            :class="icon"
            class="text-3xl"
          />
        </span>
        <h3 class="text-lg font-bold">
          {{ tool.name }}
          <i v-if="isToolLimitReached"
            class="fas fa-crown text-yellow-500 ml-2"
          />
        </h3>
      </div>
    </div>
    <div class="border-t border-gray-200 p-4">
      <div class="flex items-center">
        <div class="w-full text-sm text-gray-500 line-clamp-2">
          <div class="project-tool-description text-sm text-gray-500">
            {{ $t(tool.description) }}
          </div>
        </div>
      </div>
    </div>

    <i
      v-if="tool.shortcutIcon"
      :class="{
        [tool.shortcutIcon]: true,
        'text-white opacity-50': isSelected,
        'text-gray-500': !isSelected
      }"
      class="fa absolute right-2 bottom-0 text-lg"
    />
  </div>
</template>
<script>
import { toolsEnum } from "@/modules/projects/utils/toolUtils.js";
import { getCompletedTasksFilterAppliedQuery, getMyTasksFilterAppliedQuery } from "@/modules/tasks/utils/modelUtils"
import {
  useAccountLimits
} from "@/modules/auth/composables/useAccountLimits"


export default {
  props: {
    tool: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const {
      hasReachedLimit,
      openUpgradeDialog
    } = useAccountLimits()

    return {
      hasReachedLimit,
      openUpgradeDialog
    }
  },
  computed: {
    isToolLimitReached() {
      if (!this.tool.limitedFeature) {
        return false
      }

      return this.hasReachedLimit(this.tool.limitedFeature)
    },
    isSelected() {
      return this.tool.isSelected
    },
    allowMultiple() {
      return this.tool.allowMultiple
    },
    color() {
      return this.tool.color
    },
    image() {
      return this.tool.image
    },
    icon() {
      return this.tool.icon
    },

    toolOptions() {
      const {
        entity_type,
        entity_id,
        description,
        type
      } = this.tool.projectTool?.pivots?.options || {}

      return {
        entity_type,
        entity_id,
        type,
        description
      }
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
    isTemplateOpened() {
      return this.$store.getters['templates/isTemplateOpened']
    },
    path() {
      const { params } = this.$route
      const projectId = params.id
      const basePath = this.isTemplateOpened ? `/templates/${projectId}` : `/projects/${projectId}`
      const name = this.tool?.value

      switch (name) {
        case toolsEnum.TASKS:
          const viewOptions = this.$store.getters['users/defaultTargetViewOptions']('tasks', /* inside_project */  true)
          let query = getMyTasksFilterAppliedQuery(projectId, true)

          if (this.isCurrentProjectClosed) {
            query = getCompletedTasksFilterAppliedQuery(projectId, /* inside_project */ true)
          }

          return {
            path: `${basePath}/tasks/${viewOptions.view_type}`,
            query
          }
        case toolsEnum.FILES:
          return `${basePath}/files/list`
        case toolsEnum.PAYMENTS:
          return `${basePath}/payments/list`
        case toolsEnum.TIME:
          const timePage = this.isTemplateOpened
            ? 'allocated'
            : 'actual';

          return `${basePath}/time/${timePage}`
        case toolsEnum.NOTE:
          return `${basePath}/notes/${this.toolOptions?.entity_id}`
        case toolsEnum.DISCUSSIONS:
          return `${basePath}/discussion`
        case toolsEnum.PEOPLE:
          return `${basePath}/people`
        case toolsEnum.EMBEDS:
          if (this.toolOptions?.entity_type === 'embed') {
            return `${basePath}/embeds/${this.toolOptions?.entity_id}`
          }
          return `${basePath}/resources/list`
        case toolsEnum.NOTES:
          return `${basePath}/notes`
        default:
          return `${basePath}/tools/${this.tool.id}`
      }
    },
    canAccessTool() {
      if (this.tool.value !== toolsEnum.DISCUSSIONS) {
        return true
      }
      return !this.isTemplateOpened
    },
    canOpenTool() {
      return !this.tool.allowMultiple && this.canAccessTool && this.path
    },
    canRemoveTool() {
      return !this.tool.allowMultiple
    }
  },
  methods: {
    onClick() {
      if (this.isToolLimitReached) {
        this.openUpgradeDialog(this.tool.limitedFeature)
        return
      }

      if (!this.isSelected) {
        this.$emit('toggle-tool-selected')
      }
    }
  }
}
</script>
