<template>
  <div class="bg-white rounded-lg p-6">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div class="sm:flex sm:space-x-5">
        <div class="shrink-0">
          <router-link to="/profile" :aria-label="$t('Profile page')">
            <BaseLogo
              :entity="$user"
              logo-key="avatar"
              name-key="first_name"
              size="xl"
              class="mx-auto"
              disable-tooltip
            />
          </router-link>
        </div>
        <div class="text-center sm:text-left m-auto">
          <p class="text-sm font-medium text-gray-600">{{ welcomeMessage }}</p>
          <p class="text-xl font-bold text-gray-900 sm:text-2xl">{{ $user.name }}</p>
          <div
            class="group text-sm text-gray-400 hover:text-yellow-500 cursor-pointer mt-1"
            @click="useNewLayout = true"
          >
            <i class="fa-duotone fa-stars text-yellow-300 group-hover:text-yellow-500" />
            {{ $t('Try the new layout') }} 
          </div>
          <p
            v-if="isTrialPeriod && can($actions.SEE_TRIAL_PERIOD)"
            class="text-yellow-600 font-semibold"
          >
            {{ trialDaysRemaining }} {{ $t('Days left in your trial') }}
          </p>
        </div>
      </div>
      <ProfileDropdown
        class="sm:w-auto sm:float-right"
      />
    </div>
  </div>
</template>
<script setup>
import i18n from "@/i18n.js"
import { computed } from "vue"
import ProfileDropdown from "@/modules/dashboard/components/ProfileDropdown.vue";

// Composables
import { useStore } from 'vuex'
import useLayout from "@/modules/common/composables/useLayout"

const store = useStore()

const {
  useNewLayout,
} = useLayout()

const welcomeMessage = computed(() => {
  const hour = new Date().getHours()
  if (hour < 12) {
    return i18n.t('Good morning,')
  } else if (hour >= 12 && hour <= 18) {
    return i18n.t('Good afternoon,')
  }
  return i18n.t('Good evening,')
})

const isTrialPeriod = computed(() => {
  return store.getters['accounts/isTrialPeriod']
})

const trialDaysRemaining = computed(() => {
  return store.getters['accounts/trialDaysRemaining']
})
</script>
