<template>
  <BaseEntityForm
    width-class="max-w-3xl"
    :has-actions="false"
    slim
  >
    <div>
      <div class="px-6 py-4 mb-6 flex justify-start relative border-b border-gray-200">
        <BaseTutorialLink
          name="project-statuses"
          class="absolute -mt-2 mr-3 right-0 text-xs text-gray-300 hover:text-gray-400 no-underline"
        >
          <i class="fa-solid fa-circle-question mr-1" />
          {{ $t('How to use')}}
        </BaseTutorialLink>
        <BaseButton
          @click="showCreateDialog = true"
        >
          {{ $t('+ New Project Status') }}
        </BaseButton>
      </div>
      
      <div class="flow-root mt-6">
        <DraggableStatuses
          v-model="statuses"
          :loading="!silent && statusesLoading"
          @edit="openEditDialog"
          @delete="removeStatus"
        />
      </div>

      <ProjectStatusDialog
        v-if="showCreateDialog"
        v-show="showCreateDialog"
        v-model="showCreateDialog"
      />
      <ProjectStatusDialog
        v-if="showEditDialog"
        v-show="showEditDialog"
        v-model="showEditDialog"
        :status="statusToEdit"
      />
    </div>
  </BaseEntityForm>
</template>
<script>
import ProjectStatusDialog from "@/modules/accounts/components/ProjectStatusDialog.vue";
import DraggableStatuses from "@/modules/accounts/components/DraggableStatuses.vue";

export default {
  components: {
    DraggableStatuses,
    ProjectStatusDialog,
  },
  props: {
    silent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCreateDialog: false,
      showEditDialog: false,
      statusToEdit: null,
    }
  },
  computed: {
    statuses: {
      get() {
        return this.$store.getters['projects/orderedStatuses'] || []
      },
      async set(statuses) {
        await this.$store.dispatch('projects/reorderProjectStatuses', { statuses })
      }
    },
    statusesLoading() {
      return this.$store.state.projects.statusesLoading || false
    }
  },
  methods: {
    async removeStatus(status) {
      try {
        await this.$store.dispatch('projects/deleteStatus', status.id)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not remove the provided status'))
      }
    },
    openEditDialog(status) {
      this.showEditDialog = true
      this.statusToEdit = status
    },
    getStatuses() {
      this.$store.dispatch('projects/getAllStatuses', true)
    }
  },
  created() {
    this.getStatuses()
  }
}
</script>
