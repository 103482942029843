<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    overflowVisible
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <FileForm
      :folder="folder"
      :project="project"
      :files="files"
      :isDialogForm="true"
      @save="$emit('save')"
      @cancel="$emit('close')"
    />
  </BaseDialog>
</template>
<script>
import FileForm from "@/modules/files/components/FileForm.vue";

export default {
  name: 'FileDialog',
  inheritAttrs: false,
  components: {
    FileForm,
  },
  props: {
    folder: {
      type: Object,
      default: () => null
    },
    project: {
      type: Object,
      default: () => null,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['save', 'close'],
}
</script>
