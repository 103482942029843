<template>
  <div ref="recaptchaDiv"/>
</template>

<script setup lang="ts">
const ReCaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
import { ref, onMounted } from 'vue'

const recaptchaDiv = ref(null)
let recaptcha: any = null
const scriptId = "recaptcha-script";

const props = defineProps({
  size: {
    type: String,
    required: false,
    default: "invisible"
  },
  theme: {
    type: String,
    required: false,
    default: "light"
  },
  loadingTimeout: {
    type: Number,
    required: false,
    default: 0
  }
})

const emit = defineEmits({
  verify: (response: string) => {
    if (response != null && response != "")
      return true;
    else
      return false;
  },
  error: (reason: any) => reason,
  expire: null,
  fail: null
})

defineExpose({
  execute: function () {
    window.grecaptcha.execute(recaptcha)
  },
  reset: function () {
    window.grecaptcha.reset(recaptcha);
  }
})

function renderRecaptcha() {
  recaptcha = window.grecaptcha.render(recaptchaDiv.value, {
    'sitekey': ReCaptchaSiteKey,
    'size': props.size,
    'callback': (response: string) => emit("verify", response),
    'expired-callback': () => emit("expire"),
    'error-callback': () => emit("fail")
  })
}

function loadRecaptcha() {
  return new Promise((resolve, reject) => {
    let loadingCountdown: ReturnType<typeof setTimeout>;
    let responded = false;

    window.recaptchaReady = function () {
      if (responded) return;
      responded = true;
      clearTimeout(loadingCountdown);
      resolve(1);
    };

    const loadingFailed = (reason: string) => {
      return () => {
        if (responded) return;
        responded = true;
        clearTimeout(loadingCountdown);
        document.getElementById(scriptId)?.remove();
        reject(reason);
      }
    };

    if (props.loadingTimeout > 0) {
      loadingCountdown = setTimeout(loadingFailed("timeout"), props.loadingTimeout)
    }

    const doc = window.document;
    const scriptTag = doc.createElement("script");
    scriptTag.id = scriptId;
    scriptTag.onerror = loadingFailed("error");
    scriptTag.onabort = loadingFailed("aborted");
    scriptTag.setAttribute("src", `https://www.google.com/recaptcha/api.js?onload=recaptchaReady`);
    doc.head.appendChild(scriptTag);
  })
}

onMounted(async () => {
  if (window.grecaptcha) {

    renderRecaptcha();
    return
  }

  try {
    await loadRecaptcha();
    renderRecaptcha();
  } catch (e) {
    emit("error", e)
  }
})
</script>
