<template>
  <span>
    <BaseInlineInput>
      <template #default="{ triggerSave }">
        <div class="flex items-center relative group base-inline-date-picker">
          <i
            v-if="displayIcon"
            class="far fa-calendar-alt mr-0.5"
          />
          <XCircleIcon
            v-if="clearable && model && !$attrs.disabled"
            class="circle-remove hidden h-4 w-4 cursor-pointer text-red-300 hover:text-red-500 absolute -left-1 bg-white rounded-full z-10"
            aria-hidden="true"
            @click.stop="onUpdateModelValue(null, triggerSave)"
          />
          <BaseDatePicker
            v-model="model"
            @update:modelValue="onUpdateModelValue($event, triggerSave)"
            :type="type"
            :disabled="$attrs.disabled"
            :placeholder="placeholder"
            class="inline inline-date-picker"
            :class="$attrs.class"
            @change="$emit('change', $event)"
          />
        </div>
      </template>
    </BaseInlineInput>
  </span>
</template>
<script>
import { XCircleIcon } from '@heroicons/vue/outline'
import i18n from '@/i18n'

export default {
  components: {
    XCircleIcon
  },
  props: {
    modelValue: {
      type: [Object, String],
      default: () => null
    },
    placeholder: {
      type: String,
      default: i18n.t('Select date...')
    },
    clearable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'date' // year | month | date | dates | datetime | week | datetimerange | daterange | monthrange
    },
    displayIcon: {
      type: Boolean,
      default: true
    },
    onUpdate: {
      type: Function,
      default: null
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    displayValue() {
      if (!this.modelValue) {
        return this.placeholder
      }

      if (this.isRange) {
        const start = this.$formatDate(this.modelValue?.start || '')
        const end = this.$formatDate(this.modelValue?.end || '')
        if (!start && !end) {
          return this.placeholder
        }

        return `${start} → ${end}`
      }

      return this.$formatDate(this.modelValue || '')
    }
  },
  methods: {
    getBoundEvents(inputEvents) {
      if (this.$attrs.disabled) {
        return null
      }

      if (this.isRange) {
        return { ...inputEvents.start, ...inputEvents.end }
      }

      return inputEvents
    },
    onUpdateModelValue(value, triggerSave) {
      this.model = value

      if (this.onUpdate) {
        this.onUpdate(value, triggerSave)
        return;
      }

      triggerSave && triggerSave()
    }
  }
}
</script>
