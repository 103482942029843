<template>
  <DashboardWidget
    :widget="widget"
    :ui="ui"
    :widgetData="widgetData"
  >
    <template #item="{ item: task }">
      <TaskWidgetEntry
        :task="task"
      />
    </template>
  </DashboardWidget>
</template>
<script lang="ts" setup>
// Components
import DashboardWidget from "@/modules/dashboard/components/widgets/DashboardWidget.vue";
import TaskWidgetEntry from "@/modules/dashboard/components/widgets/TaskWidgetEntry.vue";

// Utils
import i18n from "@/i18n";
import { PropType, computed } from 'vue'
import { DashboardWidgetType, WidgetUI, WidgetData } from "@/modules/dashboard/utils/widgetUtils"
import { getExtraFilters } from "@/modules/tasks/utils/taskTableUtils"

// Composables
import { useStore } from 'vuex'
import useCan from "@/modules/common/composables/useCan"
import useEntityCrud from "@/modules/common/composables/useEntityCrud"

const {
  can,
  actions
} = useCan()

const {
  triggerEntityCreate,
  entityTypes
} = useEntityCrud()

const store = useStore()

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidgetType>,
    required: true
  }
})

const tasksPath = computed(() => {
  const viewOptions = store.getters['users/defaultTargetViewOptions']('tasks')
  const view_type = viewOptions?.view_type || 'list'

  return `/tasks/${view_type}`
})

const ui = computed<WidgetUI>(() => {
  let entityCreate = null
  if (can(actions.CREATE_TASKS)) {
    entityCreate = () => {
      triggerEntityCreate(entityTypes.Task)
    }
  }
  return {
    listPath: tasksPath.value,
    listLabel: i18n.t('Go to tasks'),
    entityCreate,
    footerKey: 'showing tasks'
  }
})

const widgetData = computed<WidgetData>(() => {
  return {
    target: 'tasks',
    dataGetterPath: 'tasks/currentTasks',
    groupedDataGetter: 'tasks/groupedTasks',
    extraFilters: getExtraFilters(),
    loading: store.state.tasks.tasksLoading
  }
})
</script>
