<template>
  <div class="max-w-3xl m-auto align-middle bg-white border border-gray-200 overflow-hidden rounded-lg shadow relative">
    <div class="w-full border-b border-gray-200 p-6">
      <div class="flex items-center justify-between">
        <h3 class="text-2xl font-bold">
          <span class="mr-2">
            {{ $t('Code Stacking') }}
          </span>
        </h3>
      </div>
      <p class="text-sm text-gray-500 mt-2">
        {{ $t(`Got more codes to stack on to your plan? Enter them below and they will be added to your plan and your limits will be adjusted accordingly.`) }}
      </p>
    </div>

    <div>
      <NoAppSumoCodes
        v-if="!appSumoCodes?.length && !codesLoading"
      />
      <div
        v-else
        v-loading="loadingCodes"
      >
        <h3 class="px-6 mt-6 text-xl font-bold">
          <span class="mr-2">
            {{ $t('Submitted') }}
          </span>
        </h3>
        
        <div class="px-6 mt-3 space-y-2 text-sm font-medium text-gray-500">
          <div
            v-for="code of appSumoCodes"
            :key="code.id"
            class="block rounded-md p-0.5"
          >

          <div class="flex flex-wrap justify-between items-center w-full">
            <div class="flex-1">
              <BaseTooltip
                :content="getCodeTooltip(code)"
              >
                <i
                  class="fa-solid fa-tags mr-2 cursor-pointer"
                  :class="{
                    'text-orange-500': !code.attributes.applied_at && !code.attributes.disabled_at,
                    'text-green-500': code.attributes.applied_at && !code.attributes.disabled_at,
                    'text-red-500': code.attributes.disabled_at,
                  }"
                />
              </BaseTooltip>
              <span>{{ code.attributes.code }}</span>
            </div>
            <div class="font-light flex-1">
              <span> {{ $t('Applied on: ') }} </span>
              <span>{{  $formatDate(code.attributes.applied_at) }}</span>
            </div>
            <div class="font-light flex-1">
              <template v-if="code.attributes.disabled_at">
                <span> {{ $t('Disabled on: ') }} </span>
                <span>{{  $formatDate(code.attributes.disabled_at) }}</span>
              </template>
            </div>
          </div>

          </div>
        </div>
      </div>
      <VeeForm
        v-slot="{ handleSubmit, errors, meta }"
        :key="resetCount"
      >
        <form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <AppSumoCodesInput
            v-model="model.app_sumo_codes"
            class="px-6 mt-6 mr-6"
          />

          <div class="px-6 py-4 mt-6 border-t border-gray-200 bg-white sticky bottom-0 w-full">
            <div class="flex justify-end">
              <BaseButton
                variant="white"
                @click="$router.push('/account-settings')"
              >
                {{ $t('Cancel') }}
              </BaseButton>
              <BaseButton
                :loading="loading || loadingCodes"
                :disabled="!meta.valid"
                type="submit"
                class="ml-2"
              >
                {{ $t('Submit') }}
              </BaseButton>
            </div>
          </div>
        </form>
      </VeeForm>
    </div>
  </div>
</template>
<script>
// Components
import NoAppSumoCodes from "@/modules/accounts/components/NoAppSumoCodes.vue";
import AppSumoCodesInput from  '@/modules/common/components/AppSumoCodesInput.vue'

export default {
  components: {
    AppSumoCodesInput,
    NoAppSumoCodes,
  },
  data() {
    return {
      loading: false,
      loadingCodes: false,
      model: {
        app_sumo_codes: ['']
      },
      resetCount: 1
    }
  },
  computed: {
    appSumoCodes() {
      return this.$store.state.accounts?.appSumoCodes || []
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        await this.$store.dispatch('accounts/submitAppSumoCodes', this.model.app_sumo_codes)
        
        this.$success(this.$t('Codes submitted successfully!'))
        
        this.getAppSumoCodes()
        this.$store.dispatch('accounts/getSubscription', { forceFetch: true })
        this.$store.dispatch('auth/getTenantDetails')

        this.resetModel()
      }
      catch(err) {
        if (err.handled) {
          return
        }

        this.$error(this.$t('Could not submit codes..'))
      }
      finally {
        this.loading = false
      }

    },
    resetModel() {
      this.model.app_sumo_codes = ['']
      this.resetCount++
    },
    getCodeTooltip(code) {
      if (code.attributes.disabled_at) {
        return this.$t('Disabled. This code has been disabled.')
      }

      if (code.attributes.applied_at) {
        return this.$t('Active. This code has been applied and is active.')
      }

      return this.$t('Pending. This code has not been applied yet.')
    },
    async getAppSumoCodes() {
      try {
        this.loadingCodes = true
        await this.$store.dispatch('accounts/getAppSumoCodes')
      }
      finally {
        this.loadingCodes = false
      }
    }
  },
  mounted() {
    this.getAppSumoCodes()
  }
}
</script>
