<template>
  <div
    class="w-full flex items-center"
    @click.stop="onClick"
  >
    {{ customField.attributes.name }}
    <BaseTooltip
      v-if="canEditCustomFields"
      :content="$t(`Edit the values for this field.`)"
    >
      <i
        class="fa-solid fa-pen-to-square ml-2 text-gray-300 hover:text-gray-400 cursor-pointer block"
        @click.stop="triggerEditCustomField(customField)"
      />
    </BaseTooltip>
    
    <span
      v-if="activeSortForColumn?.sortIndex"
      class="ag-sort-indicator-icon ag-sort-order"
    >
      {{ activeSortForColumn.sortIndex }}
    </span>

    <span
      v-if="isSortAscending"
      class="ag-sort-indicator-icon ag-sort-ascending-icon"
    >
      <span class="ag-icon ag-icon-asc" unselectable="on" role="presentation" />
    </span>
    <span
      v-if="isSortDesc"
      class="ag-sort-indicator-icon ag-sort-descending-icon"
    >
      <span class="ag-icon ag-icon-desc" unselectable="on" role="presentation" />
    </span>

  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { triggerEditCustomField } from "@/modules/accounts/utils/modelUtils"
import { getActiveSortForColumn } from "@/modules/filters/utils/commonFilterUtils";
import { getEntityTarget } from "@/modules/common/utils/filterUtils"

// Composables
import { useRoute } from "vue-router";
import useCan from "@/modules/common/composables/useCan.js";
const route = useRoute()
const { can, actions } = useCan()

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const customField = computed(() => {
  return props.params.customField
})

// Local Filters: Grouping etc
const target = computed(() => {
  return getEntityTarget(route)
})

const activeSortForColumn = computed(() => {
  return getActiveSortForColumn(target.value, props.params.column?.getColDef()) 
})

const canEditCustomFields = computed(() => {
  if (customField.value.attributes.project_id) {
    return can(actions.EDIT_CUSTOM_FIELDS_INSIDE_PROJECTS)
  }

  return can(actions.EDIT_CUSTOM_FIELDS)
})  

const isSortAscending = computed(() => {
  return activeSortForColumn?.value?.order === 'asc'
})

const isSortDesc = computed(() => {
  return activeSortForColumn?.value?.order === 'desc'
})

function onClick(event: MouseEvent) {
  if (!props.params.enableSorting) {
    return
  }

  props.params.progressSort(event.shiftKey)
}
</script>
