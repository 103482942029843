<template>
  <div class="relative lg:inline-block text-left mt-2 lg:mt-0">
    <BaseDropdown
      :options="columnOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
    >
      <TopFilterChip
        :isActive="activeSortColumns?.length"
        :prependLabel="$t('Sort')"
        :label="activeSortDescription || $t('None')"
      />
      <template #option="{ option }">
        <span>{{ option.label }}</span>
        <i v-if="option.variant === 'active'"
          class="fas ml-2"
          :class="{
            'fa-sort-alt opacity-60': sort.find(cs => cs.column === option.sortProp)?.order === sortOrder.NONE,
            'fa-sort-alpha-up': sort.find(cs => cs.column === option.sortProp)?.order === sortOrder.ASC,
            'fa-sort-alpha-down-alt': sort.find(cs => cs.column === option.sortProp)?.order === sortOrder.DESC,
          }"
        />
      </template>
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ $t("Sort By")}}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import TopFilterChip from "@/modules/filters/components/TopFilterChip.vue";

const sortOrder = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: '',
}
export default {
  components: {
    TopFilterChip,
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    defaultSort: {
      type: Object,
      default: () => ([])
    },
  },
  data() {
    let sort = []

    if (this.defaultSort) {
      sort = this.defaultSort

      if (!Array.isArray(this.defaultSort)) {
        sort = [sort]
      }
    }

    return {
      sort,
      sortOrder
    }
  },
  computed: {
    sortableColumns() {
      return this.columns.filter(c => c.sortable)
    },
    activeSortColumns() {
      return this.sortableColumns.filter(c => this.sort.some(sc => sc.column === c.sortProp))
    },
    columnOptions() {
      return this.sortableColumns.map(column => ({
        label: column.name,
        sortProp: column.sortProp,
        action: (event) => {
          this.sortChange(column.sortProp, event)
          this.$emit('change', this.sort)
        },
        variant: this.isSortingActiveForColumn(column.sortProp) ? 'active' : '',
      }))
    },
    activeSortDescription() {
      return [...this.activeSortColumns].sort((c1, c2) => {
        const index1 = this.sort.findIndex(sc => sc.column === c1.sortProp)
        const index2 = this.sort.findIndex(sc => sc.column === c2.sortProp)

        return index1 - index2
      })
      .map((c) => {
        const index = this.sort.findIndex(sc => sc.column === c.sortProp)
        
        let columnDescription = `${c.name}`

        if (this.activeSortColumns.length > 1) {
          columnDescription += ` (${index + 1})`
        }

        return columnDescription
      }).join(', ')
    }
  },
  methods: {
    isSortingActiveForColumn(sortProp) {
      return this.sort.some(sc => sc.column === sortProp)
    },
    toggleNewColumnSort(sortProp, event) {
      if (event.shiftKey) {
        this.sort.push({
          column: sortProp,
          order: sortOrder.ASC,
        })

        return
      }

      this.sort = [{
        column: sortProp,
        order: sortOrder.ASC,
      }]
    },
    sortChange(sortProp, event) {
      if (!this.isSortingActiveForColumn(sortProp)) {
        this.toggleNewColumnSort(sortProp, event)
        return
      }

      if (!event.shiftKey) {
        const column = this.sort.find(c => c.column === sortProp)
        if (column.order === sortOrder.ASC) {
          this.sort = [{
            column: sortProp,
            order: sortOrder.DESC,
          }]
        } else if (column.order === sortOrder.DESC) {
          this.sort = []
        }

        return
      }

      const column = this.sort.find(c => c.column === sortProp)
      if (column.order === sortOrder.ASC) {
        column.order = sortOrder.DESC
      } else if (column.order === sortOrder.DESC) {
        this.sort = this.sort.filter(c => c.column !== sortProp)
      }
    },
  },
  watch: {
    defaultSort() {
      let sort = []

      if (this.defaultSort) {
        sort = this.defaultSort
        if (!Array.isArray(this.defaultSort)) {
          sort = [sort]
        }
      }

      this.sort = sort
    }
  }
}
</script>
