export function timeStringToMinutes(value) {
  let newValue = null
  if (value === null || value === undefined || value === '') {
    return newValue
  }

  if (value.includes(':')) {
    let [ hours, minutes ] = value.split(':').map(x => +x)

    newValue = hours * 60 + minutes
  }
  else if (value.includes('.')) {
    newValue = parseFloat(value) * 60
  }
  else {
    newValue = parseFloat(value) * 60
  }

  if (isNaN(newValue)) {
    newValue = null
  }
  else {
    newValue = Math.ceil(newValue)
  }

  return newValue
}

export function minutesToFormattedTime(value, objectResponse = false) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  value = parseInt(value)

  let hours = Math.floor(value / 60);
  let minutes = value % 60;

  hours = hours.toString().padStart(2, '0')
  minutes = minutes.toString().padStart(2, '0')

  if (objectResponse) {
    return {
      hours: +hours,
      minutes: +minutes
    }
  }

  return `${hours}:${minutes}`
}

export function secondsToFormattedTime(value, objectResponse = false, showHours = true) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  value = parseInt(value)

  let hours = Math.floor(value / 3600);
  let minutes = Math.floor((value - (hours * 3600)) / 60);
  let seconds = value % 60;

  hours = hours.toString().padStart(2, '0')
  minutes = minutes.toString().padStart(2, '0')
  seconds = seconds.toString().padStart(2, '0')

  if (objectResponse) {
    return {
      hours: +hours,
      minutes: +minutes,
      seconds: +seconds
    }
  }

  if (showHours) {
    return `${hours}:${minutes}:${seconds}`
  }

  return `${minutes}:${seconds}`
}

