<template>
  <div
    class="search-page"
    :class="{
      '-mx-8': $useNewLayout, 
    }"
  >
    <h2
      class="text-2xl font-bold flex items-center rounded-lg"
      :class="{
        'body-background breadcrumb-color p-4 mx-8': $useNewLayout,
        'bg-gray-50 border border-gray-200 shadow p-2': !$useNewLayout,
      }"
    >
      {{
        $tc('search results for', {
          searchTerm: keyword
        })
      }}

      <i
        v-if="$useNewLayout"
        class="fa-solid fa-search ml-auto cursor-pointer"
        @click="$store.commit('accounts/triggerGlobalSearch')"
      />
    </h2>

   <div
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0)"
   >
     <div
        v-if="searchResults.length === 0 && !loading"
        class="text-lg font-semibold flex w-full items-center min-h-full justify-center my-10"
      >
       <NoSearchResults />
     </div>

     <div
      v-for="result in searchResults"
      :key="result.title"
      class="global-search-result"
     >

      <h2
        class="text-2xl font-bold mt-8 pl-4 capitalize flex items-center group-title py-2"
        :class="{
          'bg-gray-50 shadow border-b rounded-t-lg': !$useNewLayout,
        }"
      >
        {{ result.title }}
      </h2>

       <div
        class="align-middle min-w-full bg-white overflow-y-visible sm:overflow-x-auto"
        :class="{
          'shadow rounded-b-lg': !$useNewLayout,
        }"
      >
         <component
           :is="result.component"
           :data="result.response.data"
           :loading="loading"
           :enableInputRow="false"
           domLayout="autoHeight"
         />
        <div
          v-if="result.response?.meta?.total > result.response.data?.length"
          class="flex items-center justify-center my-2"
        >
           <router-link
             :to="result.moreLink"
            >
             <BaseButton>
               {{ $t(`View All Matching ${result.title}`) }}
             </BaseButton>
           </router-link>
         </div>
       </div>
     </div>
   </div>
  </div>
</template>
<script>
import ProjectsTable from "@/modules/projects/components/ProjectsTable.vue";
import TasksTable from "@/modules/tasks/components/TasksTable.vue";
import FilesTable from "@/modules/files/components/FilesTable.vue";
import UsersTable from "@/modules/users/components/UsersTable.vue";
import GroupsTable from "@/modules/groups/components/GroupsTable.vue";
import NoSearchResults from "@/modules/accounts/components/NoSearchResults.vue";
import { encodeFilters } from '@/modules/common/utils/filterUtils';

export default {
  components: {
    TasksTable,
    FilesTable,
    UsersTable,
    GroupsTable,
    ProjectsTable,
    NoSearchResults,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    projects() {
      return this.$store.state.projects.projects
    },
    templates() {
      return this.$store.state.templates.templates
    },
    tasks() {
      return this.$store.state.tasks.tasks
    },
    files() {
      return this.$store.state.files.files
    },
    users() {
      return this.$store.state.users.users
    },
    groups() {
      return this.$store.state.groups.groups
    },
    keyword() {
      return this.$route.query.keyword || ''
    },
    entitySearches() {
      const searches = [
        {
          title: this.$t('Projects'),
          response: this.projects,
          component: ProjectsTable,
          moreLink: `/projects/list?${this.getQueryParams('projects')}`,
          search: async () => this.$store.dispatch('projects/getProjects', this.getSearchParams('projects')),
          requiresPermissionTo: this.$actions.ACCESS_PROJECTS_LIST
        },
        {
          title: this.$t('Templates'),
          response: this.templates,
          component: ProjectsTable,
          moreLink: `/templates/list?${this.getQueryParams('templates')}`,
          search: async () => this.$store.dispatch('templates/getTemplates', this.getSearchParams('templates')),
          requiresPermissionTo: this.$actions.ACCESS_TEMPLATES_LIST
        },
        {
          title: this.$t('Tasks'),
          response: this.tasks,
          component: TasksTable,
          moreLink: `/tasks/list?${this.getQueryParams('tasks')}`,
          search: async () => this.$store.dispatch('tasks/getTasks', this.getSearchParams('tasks')),
          requiresPermissionTo: this.$actions.ACCESS_TASKS_LIST
        },
        {
          title: this.$t('Files'),
          response: this.files,
          component: FilesTable,
          moreLink: `/files/list?${this.getQueryParams('files')}`,
          search: async () => this.$store.dispatch('files/getFiles', this.getSearchParams('files')),
        },
        {
          title: this.$t('Users'),
          response: this.users,
          component: UsersTable,
          moreLink: `/users/list?${this.getQueryParams('users')}`,
          search: async () =>this.$store.dispatch('users/getUsers', this.getSearchParams('users')),
          enabled: () => {
            return this.can(this.$actions.CREATE_PROJECTS) || this.$user.groups?.length
          }
        },
        {
          title: this.$t('Groups'),
          response: this.groups,
          component: GroupsTable,
          moreLink: `/groups/list${this.getQueryParams('groups')}`,
          search: async () => this.$store.dispatch('groups/getGroups', this.getSearchParams('groups')),
          enabled: () => {
            return this.can(this.$actions.CREATE_PROJECTS) || this.$user.groups?.length
          }
        },
      ]

      return searches.filter(s => {
        return (!s.requiresPermissionTo || this.can(s.requiresPermissionTo)) && (!s.enabled || s.enabled())
      })
    },
    searchResults() {
      return this.entitySearches.filter(result => result.response?.data?.length > 0)
    },
  },
  methods: {
    getQueryParams(entityType) {
      return `search=${this.keyword}&filters=${this.getSearchParams(entityType).filters}`
    },
    getSearchParams(entityType) {

      let sortColumn = 'created_at'

      if (entityType === 'tasks') {
        sortColumn = 'sortable_date'
      }

      const filters = encodeFilters([
        {
          key: "sorts",
          value: {
            column: sortColumn,
            order: "desc"
          }
        },
      ])

      const searchParams = {
        search: this.keyword,
        perPage: 5,
        filters
      }
      
      return searchParams
    },
    async fetchData() {
      try {
        this.loading = true

        const searchPromises = this.entitySearches.map(p => p.search())        
        
        await Promise.all(searchPromises)
      } finally {
        this.loading = false
      }
    },
    handleOpenTask(taskId) {
      if (!taskId || !this.can(this.$actions.OPEN_TASKS)) {
          return
      }

      this.$store.dispatch('triggerEntityCreate', {
        entityType: 'task',
        entityCreateParams: {
          taskId
        },
        events: {
          closed: () => {
            const query = {
              ...this.$route.query
            }
            delete query.taskId
            this.$router.push({
              path: this.$route.path,
              query
            })
          }
        }
      })
    }
  },
  watch: {
    '$route.query.keyword'() {
      this.fetchData()
    },
    '$route.query.taskId': {
      immediate: true,
      handler(taskId) {
        this.handleOpenTask(taskId)
      }
    },
  },
  async created() {
    await this.fetchData()
  }
}
</script>
<style lang="scss" scoped>
.search-title {
  @apply text-xl font-bold mt-4 mb-1 capitalize flex items-center;
}

.global-search-result {
  .data-table-container.use-new-layout {
    @apply mt-0 mx-0;
  }
}
</style>
