import { computed } from "vue";
import store from "@/store";
import { toolsEnum } from "@/modules/projects/utils/toolUtils.js";
import useEntityCrud from "@/modules/common/composables/useEntityCrud"
import useCan, { AccountPermissions } from "@/modules/common/composables/useCan";

import Data = API.Data;
import Project = App.Domains.Projects.Models.Project;
import Tool = App.Domains.Tools.Models.Tool;

interface AccountLimits {
  seats: {
    used: number;
    available: number;
    bonus: number;
  };
  collaboratorsSeats: {
    used: number;
    available: number;
  };
  storage: {
    used: number;
    available: number;
    bonus: number;
  };
  aiTokens: {
    used: number;
    available: number;
  };
  projects: {
    used: number;
    available: number;
  };
  toolsPerProject: {
    available: number;
  },
  proofs: {
    used: number;
    available: number;
  },
  speechToText: {
    used: number;
    available: number;
  }
}

const SettingKeys = {
  Storage: 'extra_storage',
  Creators: 'extra_creators',
  Projects: 'extra_projects',
  CustomBranding: 'extra_custom_branding',
} as const

export function useAccountLimits() {

  const {
    entityTypes,
    triggerEntityCreate
  } = useEntityCrud()

  const {
    hasAccountPermission,
  } = useCan()

  const subscription = computed(() => {
    // @ts-ignore
    return store.state.accounts.subscription || {}
  })

  const tenantStats = computed(() => {
    // @ts-ignore
    return store.state.accounts.tenantStats || {}
  })


  const isFreePlan = computed(() => {
    return subscription.value.plan?.price === 0
  })

  const isAppSumoSubscription = computed(() => {
    return store.getters['accounts/isAppSumoSubscription']
  })

  const isFreeForeverSubscription = computed(() => {
    return isFreePlan.value && !isAppSumoSubscription.value
  })

  const accountLimits = computed<AccountLimits>(() => {

    const bonusSeats = tenantStats.value?.seats?.bonus || 0
    const bonusStorage = tenantStats.value?.storage?.bonus || 0
    const bonusProjects = tenantStats.value?.projects?.bonus || 0
    const toolsPerProject = tenantStats.value?.toolsPerProject?.available

    return {
      seats: {
        available: +tenantStats.value?.seats?.available,
        used: +tenantStats.value?.seats?.used || 1,
        bonus: bonusSeats,
      },
      collaboratorsSeats: {
        available: +(tenantStats.value?.collaboratorsSeats?.available || 0),
        used: +(tenantStats.value?.collaboratorsSeats?.used || 0),
      },
      storage: {
        available: +tenantStats.value?.storage?.available || 0,
        used: +tenantStats.value?.storage?.used || 0,
        bonus: bonusStorage,
      },
      aiTokens: {
        available: +tenantStats.value?.aiTokens?.available,
        used: +tenantStats.value?.aiTokens?.used,
      },
      projects: {
        available: +(tenantStats.value?.projects?.available || 0),
        used: +(tenantStats.value?.projects?.used || 0),
        bonus: bonusProjects,
      },
      toolsPerProject: {
        available: toolsPerProject
      },
      proofs: {
        available: +tenantStats.value?.proofs?.available,
        used: +tenantStats.value?.proofs?.used,
      },
      speechToText: {
        available: +tenantStats.value?.speechToText?.available,
        used: +tenantStats.value?.speechToText?.used,
      }
    }
  })

  const creatorSeatsLimitReached = computed(() => {
    return accountLimits.value.seats.used >= accountLimits.value.seats.available
  })

  const collaboratorsSeatsLimitReached = computed(() => {
    if (!accountLimits.value.collaboratorsSeats.available) {
      return false
    }

    return accountLimits.value.collaboratorsSeats.used >= accountLimits.value.collaboratorsSeats.available
  })

  const maxActiveProjectsLimitReached = computed(() => {
    const activeProjectsCount = accountLimits.value.projects.used
    const availableProjects = accountLimits.value.projects.available
    if (availableProjects === 0) {
      return false
    }
    return activeProjectsCount >= availableProjects
  })

  const aiTokensLimitReached = computed(() => {
    return accountLimits.value.aiTokens.used >= accountLimits.value.aiTokens.available
  })

  const speechToTextLimitReached = computed(() => {
    return accountLimits.value.speechToText.used >= accountLimits.value.speechToText.available
  })

  const storageLimitReached = computed(() => {
    return accountLimits.value.storage.used >= accountLimits.value.storage.available
  })

  const proofLimitReached = computed(() => {
    return accountLimits.value.proofs.used >= accountLimits.value.proofs.available
  })

  function checkWillPassStorageLimit(size: number) {
    const willPassLimit = (accountLimits.value.storage.used + size) > accountLimits.value.storage.available
    if (willPassLimit) {
      openUpgradeDialog(AccountPermissions.Storage)
    }

    return willPassLimit
  }

  function openUpgradeDialog(feature: AccountPermissions) {
    triggerEntityCreate(entityTypes.UpgradeAccount, {
      feature
    })
  }

  function hasReachedLimit(feature: AccountPermissions): boolean | ((project: Data<Project>) => boolean) {

    const limitReachedConditions = {
      // Seat based limitations
      [AccountPermissions.CreatorSeats]: creatorSeatsLimitReached.value,
      [AccountPermissions.Storage]: storageLimitReached.value,

      // Free features with limitations for free plans
      [AccountPermissions.CollaboratorsSeats]: collaboratorsSeatsLimitReached.value,
      [AccountPermissions.ProjectTools]: (project: Data<Project>) => {
        return false
      },
      [AccountPermissions.ActiveProjects]: maxActiveProjectsLimitReached.value,
      // Paid features
      [AccountPermissions.AiAssistant]: !hasAccountPermission(AccountPermissions.AiAssistant) || aiTokensLimitReached.value,
      [AccountPermissions.FileProofing]: !hasAccountPermission(AccountPermissions.FileProofing) || proofLimitReached.value,
      [AccountPermissions.SpeechToText]: !hasAccountPermission(AccountPermissions.SpeechToText) || speechToTextLimitReached.value,

      // Paid features, unlimited usage
      [AccountPermissions.RecurringPayments]: !hasAccountPermission(AccountPermissions.RecurringPayments),
      [AccountPermissions.TaskTimer]: !hasAccountPermission(AccountPermissions.TaskTimer),
      [AccountPermissions.GanttView]: !hasAccountPermission(AccountPermissions.GanttView),
      [AccountPermissions.CustomBranding]: !hasAccountPermission(AccountPermissions.CustomBranding),
      [AccountPermissions.CustomDomain]: !hasAccountPermission(AccountPermissions.CustomDomain),
      [AccountPermissions.CustomEmails]: !hasAccountPermission(AccountPermissions.CustomEmails),
      [AccountPermissions.PublicApi]: !hasAccountPermission(AccountPermissions.PublicApi),
      [AccountPermissions.Webhooks]: !hasAccountPermission(AccountPermissions.Webhooks),
      [AccountPermissions.CsvImporter]: !hasAccountPermission(AccountPermissions.CsvImporter),
    }

    if (limitReachedConditions[feature]) {
      return limitReachedConditions[feature]
    }

    return false
  }

  return {
    subscription,
    isFreePlan,
    isAppSumoSubscription,
    isFreeForeverSubscription,
    accountLimits,
    maxActiveProjectsLimitReached,
    checkWillPassStorageLimit,
    openUpgradeDialog,
    hasReachedLimit,
  }
}
