<template>
  <PaymentsTable
    :data="payments"
    :loading="isLoading"
    class="flex-1"
  />
</template>
<script>
import PaymentsTable from "@/modules/payments/components/PaymentsTable.vue";

export default {
  components: {
    PaymentsTable
  },
  data() {
    return {
      showCreateDialog: false,
      showEditDialog: false,
      isLoading: true
    }
  },
  computed: {
    loading() {
      const paymentsLoading = this.$store.state.payments.paymentsLoading
      const groupsLoading = this.$store.state.groups.groupsLoading
      const projectsLoading = this.$store.state.projects.projectsLoading

      return paymentsLoading || groupsLoading || projectsLoading
    },
    payments() {
      if (this.isLoading) {
        return []
      }

      return this.$store.getters['payments/currentPayments'] || []
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(value) {
        this.isLoading = value
      }
    },
  },
}
</script>
