import axios from 'axios'
import i18n from "@/i18n.js";
import { error } from '@/components/common/NotificationPlugin';

const state = () => ({
  projectDiscussionLoading: false,
  projectDiscussion: [],
  taskDiscussionLoading: false,
  taskDiscussion: [],
  discussionThreads: {}
})

const mutations = {
  setProjectDiscussionLoading(state, value) {
    state.projectDiscussionLoading = value
  },
  setProjectDiscussion(state, value) {
    state.projectDiscussion = value.reverse()
  },
  addProjectDiscussionPage(state, value) {
    state.projectDiscussion = [...value.reverse(), ...state.projectDiscussion]
  },
  addCommentToProjectDiscussion(state, value) {
    state.projectDiscussion.push(value)
  },
  removeCommentFromDiscussion(state, commentId) {
    state.projectDiscussion = state.projectDiscussion.filter(comment => comment.id.toString() !== commentId.toString())
  },
  updateDiscussionComment(state, updatedComment) {
    state.projectDiscussion = state.projectDiscussion.map(comment => {
      if (comment.id.toString() === updatedComment.id.toString()) {
        return updatedComment
      }
      return comment
    })
  },
  setTaskDiscussionLoading(state, value) {
    state.taskDiscussionLoading = value
  },
  setTaskDiscussion(state, value) {
    state.taskDiscussion = value.reverse()
  },
  addTaskDiscussionPage(state, value) {
    state.taskDiscussion = [...state.taskDiscussion, ...value]
  },
  addCommentToTaskDiscussion(state, value) {
    state.taskDiscussion = [value, ...state.taskDiscussion]
  },
  removeCommentFromTaskDiscussion(state, commentId) {
    state.taskDiscussion = state.taskDiscussion.filter(comment => comment.id.toString() !== commentId.toString())
  },
  updateTaskDiscussionComment(state, updatedComment) {
    state.taskDiscussion = state.taskDiscussion.map(comment => {
      if (comment.id.toString() === updatedComment.id.toString()) {
        return updatedComment
      }
      return comment
    })
  },
  setThreadDiscussion(state, { threadId, data }) {
    if (!state.discussionThreads[threadId]) {
      state.discussionThreads[threadId] = {
        data,
        loading: false,
      }
    } else {
      state.discussionThreads[threadId].data = data
    }
  },
  setThreadDiscussionLoading(state, { threadId, loading }) {
    if (!state.discussionThreads[threadId]) {
      state.discussionThreads[threadId] = {
        data: [],
        loading,
      }
    } else {
      state.discussionThreads[threadId].loading = loading
    }
  },
  addCommentToThread(state, { threadId, comment }) {
    const thread = state.discussionThreads[threadId]?.data
    if (!thread) {
      return
    }
    thread.push(comment)
  },
  deleteThreadComment(state, { threadId, commentId }) {
    const threadComments = state.discussionThreads[threadId]?.data || []
    const index = threadComments.findIndex(c => c.id === commentId)
    if (index === -1) {
      return
    }
    threadComments.splice(index, 1)
  },
  updateThreadComment(state, { threadId, comment }) {
    const threadComments = state.discussionThreads[threadId]?.data || []
    const index = threadComments.findIndex(c => c.id === comment.id)

    if (index === -1) {
      return
    }
    threadComments[index] = comment
  },
  addThreadCommentAtIndex(state, { threadId, index, comment }) {
    const threadComments = state.discussionThreads[threadId]?.data || []
    threadComments?.splice(index, 1, comment)
  }
}

const discussionsRelated = 'reactions,creator[id|name|avatar]'

const actions = {
  async getProjectDiscussion({ commit }, { projectId, page }) {
    try {
      if (page === 1) {
        commit("setProjectDiscussion", [])
      }

      commit("setProjectDiscussionLoading", true);
      const discussion = await axios.get(`/restify/discussions`, {
        params: {
          project: projectId,
          parent_id: "null",
          page,
          related: discussionsRelated,
        }
      })

      commit("addProjectDiscussionPage", discussion.data)

      return discussion
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    } finally {
      commit("setProjectDiscussionLoading", false);
    }
  },
  async createProjectDiscussionComment({ commit, dispatch }, { projectId, message, parentId, notifiable_user_ids }) {
    try {
      commit("setProjectDiscussionLoading", true);
      const data = {
        discussionable_type: "project",
        discussionable_id: projectId,
        notifiable_user_ids,
        is_mention: notifiable_user_ids?.length > 0,
        parent_id: parentId || undefined,
        message
      }
      const result = await axios.post('/restify/discussions', data)
      if (!result?.data?.id) {
        throw new Error('Could not send comment')
      }
      const newComment = await dispatch('getDiscussionById', result.data.id)
      commit("addCommentToProjectDiscussion", newComment)

      return newComment
    } finally {
      commit("setProjectDiscussionLoading", false);
    }
  },
  async updateProjectDiscussionComment({ commit, dispatch }, { comment, message, notifiable_user_ids }) {
    try {
      commit("setProjectDiscussionLoading", true);
      const result = await axios.put(`/restify/discussions/${comment.id}`,
        {
          message,
          notifiable_user_ids,
          is_mention: notifiable_user_ids?.length > 0,
        },
        {
          params: {
            related: 'reactions',
          }
        }
      )

      if (!result?.data?.id) {
        throw new Error('Could not update comment')
      }

      const newComment = await dispatch('getDiscussionById', result.data?.id)
      newComment.attributes.thread_count = comment.attributes.thread_count
      commit('updateDiscussionComment', newComment)

      return newComment
    }
    finally {
      commit("setProjectDiscussionLoading", false);
    }
  },
  async deleteProjectDiscussionComment({ commit }, { commentId }) {
    try {
      commit("setProjectDiscussionLoading", true);
      const result = await axios.delete(`/restify/discussions/${commentId}`)
      commit('removeCommentFromDiscussion', commentId)
      return result
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    } finally {
      commit("setProjectDiscussionLoading", false);
    }
  },
  async getTaskDiscussion({ commit }, { taskId, page }) {
    try {
      if (page === 1) {
        commit("setTaskDiscussion", [])
      }

      commit("setTaskDiscussionLoading", true);
      const discussion = await axios.get(`/restify/discussions`, {
        params: {
          task: taskId,
          page,
          related: discussionsRelated,
        }
      })

      commit("addTaskDiscussionPage", discussion.data)

      return discussion
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
    finally {
      commit("setTaskDiscussionLoading", false);
    }
  },
  async createTaskDiscussionComment({ commit, dispatch }, { taskId, message, notifiable_user_ids, isConnectedToChannel }) {
    try {
      commit("setTaskDiscussionLoading", true);
      const data = {
        discussionable_type: "task",
        discussionable_id: taskId,
        notifiable_user_ids,
        is_mention: notifiable_user_ids?.length > 0,
        message
      }
      const result = await axios.post('/restify/discussions', data)

      if (!result?.data?.id) {
        throw new Error('Could not send comment')
      }

      const newComment = await dispatch('getDiscussionById', result.data?.id)
      commit("addCommentToTaskDiscussion", newComment)

      return newComment
    }
    finally {
      commit("setTaskDiscussionLoading", false);
    }
  },

  async updateTaskDiscussionComment({ commit, dispatch }, { commentId, message, notifiable_user_ids }) {
    try {
      commit("setTaskDiscussionLoading", true);
      const result = await axios.put(`/restify/discussions/${commentId}`, {
        message,
        notifiable_user_ids,
        is_mention: notifiable_user_ids?.length > 0,
      }, {
        params: {
          related: 'reactions',
        }
      })

      if (!result?.data?.id) {
        throw new Error('Could not update comment')
      }

      const newComment = await dispatch('getDiscussionById', result.data?.id)
      commit('updateTaskDiscussionComment', newComment)

      return newComment
    }
    finally {
      commit("setTaskDiscussionLoading", false);
    }
  },
  async deleteTaskDiscussionComment({ commit }, { commentId }) {
    try {
      commit("setTaskDiscussionLoading", true);
      const result = await axios.delete(`/restify/discussions/${commentId}`)
      commit('removeCommentFromTaskDiscussion', commentId)
      return result
    }
    catch(err) {
      if (err.handled) {
        return
      }
      throw err
    }
    finally {
      commit("seTaskDiscussionLoading", false);
    }
  },
  async getDiscussionById({}, id) {
    const result = await axios.get(`/restify/discussions/${id}`, {
      params: {
        related: discussionsRelated,
      }
    })
    return result.data
  },
  async addCommentReaction({}, requestData) {
    const { data } = await axios.post(`/restify/reactions`, requestData)
    return data
  },
  async removeCommentReaction({}, reactionId) {
    await axios.delete(`/restify/reactions/${reactionId}`)
  },
  async getThreadDiscussion({ commit, state }, { project, task, page, parent_id }) {
    try {
      commit('setThreadDiscussionLoading', { threadId: parent_id, loading: true })
      const result = await axios.get(`/restify/discussions`, {
        params: {
          project,
          task,
          page,
          parent_id,
          related: discussionsRelated,
        }
      })
      const previousDiscussions = state.discussionThreads[parent_id]?.data || []
      const reversedData = result.data.reverse()
      let discussions = reversedData

      if (page > 1) {
        discussions = [...reversedData, ...previousDiscussions]
      }

      commit('setThreadDiscussion', {
        threadId: parent_id,
        data: discussions
      })
      return result
    } finally {
      commit('setThreadDiscussionLoading', { threadId: parent_id, loading: false })
    }
  },
  async createThreadComment({ commit, dispatch }, { message, entity_id, entity_type, parent_id, notifiable_user_ids }) {
    try {
      if (!message) {
        return
      }

      const data = {
        notifiable_user_ids,
        discussionable_type: entity_type,
        discussionable_id: entity_id,
        parent_id,
        message,
      }
      const result = await axios.post('/restify/discussions', data)

      const threadComment = await dispatch('getDiscussionById', result.data.id)
      
      commit('addCommentToThread', {
        threadId: parent_id,
        comment: threadComment
      })
      
      return threadComment

    } catch (err) {
      if (err.handled) {
        return
      }
      error(i18n.t('Could not add a new comment'))
    }
  },
  async updateThreadComment({ dispatch }, { comment, message }) {
    const oldMessage = comment.attributes.message
    try {
      comment.attributes.message = message
      const result = await axios.put(`/restify/discussions/${comment.id}`, {
        message
      })
      
      const threadComment = await dispatch('getDiscussionById', result.data.id)

      return threadComment

    } catch (err) {
      comment.attributes.message = oldMessage
      if (err.handled) {
        return
      }
      error(i18n.t('Could not update discussion message'))
    }
  },
  async deleteThreadComment({ commit, state }, { comment, threadId }) {
    const threadComments = state.discussionThreads[threadId]?.data || []
    const index = threadComments.findIndex(c => c.id === comment.id)

    try {
      commit('deleteThreadComment', { threadId, commentId: comment.id })

      await axios.delete(`/restify/discussions/${comment.id}`)
    } catch (err) {
      commit('addThreadCommentAtIndex', { threadId, index, comment })
      if (err.handled) {
        return
      }
      error(i18n.t('Could not delete discussion message'))
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
