<template>
  <BaseTableCell
    v-if="dropdownComponent"
    :params="params"
  >
    <div
      v-if="!showDropdown"
      class="flex items-center justify-center py-1 leading-4 text-gray-500 cursor-pointer bg-white"
      :class="{
        'rounded-md border border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-500': !slim,
        'w-[46px] px-4': !isCard,
        'px-2': isCard
      }"
      @pointerup="showDropdown = true"
    >
      <!-- Test simple icon -->
      <i class="fas fa-ellipsis-h text-xs" />
    </div>
    <component
      v-else
      :is="dropdownComponent"
      v-bind="dropdownProps"
    >
      <div
        class="flex items-center justify-center py-1 leading-4 text-gray-500 cursor-pointer bg-white"
        :class="{
          'rounded-md border border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-500': !slim,
          'w-[46px] px-4': !isCard,
          'px-2': isCard
        }"
      >
        <i class="fas fa-ellipsis-h text-xs" />
      </div>
    </component>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { PropType, computed, ref } from 'vue'
import { entityDropdowns } from "@/modules/common/utils/entityUtils";
import BaseTableCell from "@/components/table/cells/BaseTableCell.vue";
import { ICellRendererParams } from '@ag-grid-community/core';

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams<any>>,
    default: () => ({})
  },
  slim: {
    type: Boolean,
    default: false
  },
  isCard: {
    type: Boolean,
    default: false
  }
})

const showDropdown = ref(false)

const entity = computed(() => {
  return props.params.data
})

const dropdown = computed(() => {
  return entityDropdowns[entity.value?.type]
})

const dropdownComponent = computed(() => {
  return dropdown.value?.component
})

const dropdownProps = computed(() => {

  const attrs = {
    ...(dropdown.value?.props(entity.value) || {}),
    slim: props.slim,
    autoOpen: true,
    node: props.params.node,
  }

  return attrs
})
</script>
