export function loadPaypalSDK(merchantId, currencyCode = 'EUR') {
  return new Promise((resolve, reject) => {
    const clientId = import.meta.env.VITE_PAYPAL_CLIENT_ID
  
    if (!clientId || !merchantId) {
      throw `Cannot load paypal SDK. Client ID: ${clientId}, Merchant ID: ${merchantId} `
    }

    const components = 'buttons'
    const intent = 'capture'
    const scriptSrc = `https://www.paypal.com/sdk/js?client-id=${clientId}&merchant-id=${merchantId}&components=${components}&currency=${currencyCode}&intent=${intent}`
    
    if (document.head.querySelector(`script[src="${scriptSrc}"]`) && window.paypal) {
      resolve()
      return;
    }

    const paypalScript = document.createElement('script')
    paypalScript.src = scriptSrc
    paypalScript.async = true
    paypalScript.defer = true

    paypalScript.onload = resolve
    paypalScript.onerror = (err) => {
      paypalScript.remove()
      reject(err)
    }
  
    document.head.appendChild(paypalScript)
  })
}
