<template>
  <div
    class="max-h-[52rem] min-w-[40rem] max-w-[60rem] transition-all duration-500 bg-white rounded-lg overflow-hidden shadow-2xl h-screen"
    :style="{
      transform: `translateX(${transform?.x}%) translateY(${transform?.y}%) scale(1)`
    }"
  >
    <!--HEADER: START-->
    <div class="body-background text-sm px-4 py-3 space-x-1">
      <i class="fa-solid fa-circle text-red-400" aria-hidden="true"></i>
      <i class="fa-solid fa-circle text-yellow-400" aria-hidden="true"></i>
      <i class="fa-solid fa-circle text-green-400" aria-hidden="true"></i>
    </div>
    <!--HEADER:END-->

    <!--WEBAPP MAIN: START-->
    <div
      v-if="currentStepKey === OnboardingSteps.CreateTasks"
      class="rounded-b-lg border border-t-0 border-gray-200 p-6 space-y-4"
    >

      <!--BUTTONS MOCKUP:START-->
      <div class="flex gap-2">
        <div class="bg-gray-200 rounded-md px-4 py-3">
          <div class="h-2 w-20 bg-gray-100 rounded-sm"></div>
        </div>
        <div class="bg-gray-50 border border-gray-200 rounded-md px-4 py-3">
          <div class="h-2 w-16 bg-gray-200 rounded-sm"></div>
        </div>
        <div class="bg-gray-50 border border-gray-200 rounded-md px-4 py-3">
          <div class="h-2 w-20 bg-gray-200 rounded-sm"></div>
        </div>
        <div class="bg-gray-50 border border-gray-200 rounded-md px-4 py-3">
          <div class="h-2 w-16 bg-gray-200 rounded-sm"></div>
        </div>
        <div class="bg-gray-50 border border-gray-200 rounded-md px-4 py-3">
          <div class="h-2 w-20 bg-gray-200 rounded-sm"></div>
        </div>
      </div>
      <!--BUTTONS MOCKUP:END-->


      <!--VIEW TYPES:START-->
      <div>
        <div class="w-full space-x-4 border-b border-gray-200">
          <div class="text-sm text-gray-400 p-2 pt-0 inline-block cursor-default border-b-2 border-[#301151]">
            <i class="fas fa-list-check mr-2" aria-hidden="true" />
            <span>{{ $t(`List`) }}</span>
          </div>
          <div class="text-sm text-gray-400 p-2 pt-0 inline-block cursor-default">
            <i class="fas fa-grid-2 mr-2" aria-hidden="true" />
            <span>{{ $t(`Cards`) }}</span>
          </div>
          <div class="text-sm text-gray-400 p-2 pt-0 inline-block cursor-default">
            <i class="fas fa-calendar-days mr-2" aria-hidden="true" />
            <span>{{ $t('Calendar') }}</span>
          </div>
          <div class="text-sm text-gray-400 p-2 pt-0 inline-block cursor-default">
            <i class="fas fa-chart-simple-horizontal mr-2" aria-hidden="true" />
            <span>{{ $t('Scheduler') }}</span>
          </div>
          <div class="text-sm text-gray-400 p-2 pt-0 inline-block cursor-default">
            <i class="fas fa-objects-column mr-2" aria-hidden="true" />
            <span>{{ $t('Kanban') }}</span>
          </div>
        </div>
      </div>
      <!--VIEW TYPES:END-->

      <!--TABLE:START-->
      <div class="flow-root -mx-6">
        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden border-b border-t border-gray-200">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr class="h-12 divide-x divide-gray-200">
                    <th scope="col" class="w-3/5 px-6 py-3.5 text-left text-sm font-medium text-gray-400">
                      {{ $t(`Task Name`)}}
                    </th>
                    <th scope="col" class="w-1/5 px-3 py-3.5 text-left text-sm font-medium text-gray-400">
                      {{ $t(`Date`) }}
                    </th>
                    <th scope="col" class="w-1/5 px-3 py-3.5 text-left text-sm font-medium text-gray-400">
                      {{ $t(`Assigned`) }}
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="task of tasksToAdd"
                    :key="task.name"
                    class="divide-x divide-gray-200 h-12 max-h-12"
                  >
                    <td class="px-6 my-4 text-sm text-gray-500 line-clamp-1">
                      <i class="fa-light fa-circle-check mr-2 text-gray-400 text-lg align-middle leading-4" aria-hidden="true" />
                      {{ task.name }}
                    </td>
                    <td class="px-3 my-4 text-sm text-gray-500 whitespace-nowrap">
                      {{ $formatDate(task.start_date) }} → {{ $formatDate(task.end_date) }}
                    </td>
                    <td class="px-3 my-4 text-sm text-gray-500">
                      <UserLogo
                        :user="userWithAttributes"
                        class="flex items-center"
                        :previewType="UserPreviewTypes.Disabled"
                      />
                    </td>
                  </tr>

                  <tr
                    v-for="i in 10"
                    :key="i"
                    class="divide-x divide-gray-200 h-12 max-h-12"
                  >
                    <td class="px-6 my-6 text-sm text-gray-500 line-clamp-1">
                      <div class="flex items-center">
                        <div class="h-2 w-3/4 bg-gray-100 rounded-sm"></div>
                      </div>
                    </td>
                    <td class="px-3 my-6 text-sm text-gray-500">
                      <div class="h-2 w-3/4 bg-gray-100 rounded-sm"></div>
                    </td>
                    <td class="px-3 my-6 text-sm text-gray-500">
                      <div class="h-8 w-8 bg-gray-100 rounded-full -my-1.5"></div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--TABLE:END-->
    </div>

    <div
      v-else
      class="h-full border border-t-0 border-gray-200 p-6 space-y-4"
    >
      <!--BRANDING: START-->
      <div class="overflow-hidden rounded-lg relative">
        <img
          :src="projectCoverImage"
          class="w-full object-cover object-top h-36"
        />
        <div class="rounded-lg p-1 text-center bg-white inline-block absolute top-1/2 -translate-y-1/2 left-6">
          <div class="h-20 w-20">
            <img
              :src="projectImage"
              alt="User avatar"
              class="object-cover rounded-md h-20 w-20"
            />
          </div>
        </div>
      </div>
      <!--BRANDING: END-->

      <!--TITLE: START-->
      <div
        class="text-3xl font-extrabold"
        :class="{
          'text-gray-400': !projectName,
          'text-gray-900': projectName
        }"
      >
        {{ projectName || $t(`Your project name`)}}
      </div>
      <!--TITLE: END-->
      <!--PEOPLE: START-->
      <div class="flex space-x-2">
        <div class="text-gray-700 text-sm font-bold mt-1">
          {{ $t(`People`) }}:
        </div>
        <div class="bg-gray-50 text-xs text-gray-400 p-0.5 pr-2 border border-gray-200 rounded-full flex items-center space-x-1">
          <UserLogo
            :user="userWithAttributes"
            class="flex items-center"
            :previewType="UserPreviewTypes.Disabled"
            size="xs"
            rounded="full"
          >
            <template #after>
              <span>{{ currentUser.name || currentUser.email }}</span>
            </template>
          </UserLogo>
        </div>

        <div
          v-for="email of inviteUsers"
          class="bg-gray-50 text-xs text-gray-400 p-0.5 pr-2 border border-gray-200 rounded-full flex items-center space-x-1"
        >
          <UserLogo
            :user="{
              attributes: {
                email
              }
            }"
            class="flex items-center"
            :previewType="UserPreviewTypes.Disabled"
            size="xs"
            rounded="full"
          >
            <template #after>
              <span>{{ email }}</span>
            </template>
          </UserLogo>
        </div>
        
      </div>
      <!--PEOPLE: END-->
      <!--DESCRIPTION: START-->
      <div
        v-if="description"
        class="text-lg text-gray-500"
      >
        {{ description}}
      </div>
      <template v-else>
        <div class="h-6 w-full bg-gray-100 rounded-sm"></div>
        <div class="h-6 w-2/4 bg-gray-100 rounded-sm"></div>
        <div class="h-6 w-3/5 bg-gray-100 rounded-sm"></div>
        <div class="h-6 w-2/5 bg-gray-100 rounded-sm"></div>
      </template>
      <!--DESCRIPTION: END-->



      <!--TOOLS: START-->
      <div class="w-full grid gap-6 grid-cols-1 lg:grid-cols-2">
        <div
          v-for="tool of displayedTools"
          :key="tool.value"
          class="flex border border-gray-200 rounded-lg relative text-base font-semibold hover:bg-gray-50 min-h-[82px]"
        >
          <div
            class="flex-none w-14 text-center bg-emerald-500 text-white text-2xl rounded-l-lg flex items-center justify-center"
            :style="{
              background: tool.color
            }"
          >
            <i
              :class="tool.icon"
              class="text-xl"
            />
          </div>
          <div class="px-4 py-2 overflow-hidden">
            <div class="flex items-center line-clamp-2 text-gray-800">
              {{ tool.name }}
            </div>
            <div class="flex items-center">
              <div class="text-gray-500 line-clamp-2">
                <div
                  v-html="tool.description"
                  class="text-sm text-gray-500 font-normal"
                />
              </div>
            </div>
          </div>
        </div>
        <template v-if="currentStepKey === OnboardingSteps.AddProjectTools || !displayedTools.length">
          <div
            v-for="i in (6 - displayedTools.length)"
            :key="i"
            class="flex border border-gray-200 rounded-lg cursor-select relative"
          >
            <div class="flex-none w-14 text-center text-white text-2xl rounded-l-lg flex items-center justify-center bg-gray-200">
            </div>
            <div class="w-full px-4 py-2">
              <div class="space-y-2">
                <div class="h-6 w-2/4 bg-gray-100 rounded-sm"></div>
                <div class="h-3 w-full bg-gray-100 rounded-sm"></div>
                <div class="h-3 w-3/4 bg-gray-100 rounded-sm"></div>
              </div>
            </div>
          </div>
        </template>
       
      </div>
      <!--TOOLS: END-->

    </div>
    <!--WEBAPP MAIN: END-->
  </div>

</template>
<script lang="ts" setup>
import { PropType, computed } from 'vue';
import {
  WebappTransform,
  projectImage,
  projectCoverImage,
  OnboardingSteps,
} from '@/modules/accounts/utils/onboardingUtils'
import { coreTools } from '@/modules/projects/utils/toolUtils'

import UserLogo from "@/components/common/UserLogo.vue";

import { useAuth } from "@/modules/auth/composables/useAuth";

const { currentUser } = useAuth()

const userWithAttributes = computed(() => {
  return {
    ...currentUser.value,
    attributes: {
      ...currentUser.value,
    }
  }
})

const props = defineProps({
  transform: {
    type: Object as PropType<WebappTransform>,
  },
  currentStepKey: {
    type: String as PropType<OnboardingSteps>,
  }
})

import { useStore } from 'vuex'
import { UserPreviewTypes } from '@/modules/users/util/userUtils';

const store = useStore()

const onboardingModel = computed(() => {
  return store.state.accounts.onboardingModel
})

const projectName = computed(() => {
  return onboardingModel.value.projectName || ''
})

const description = computed(() => {
  return onboardingModel.value.projectDescription || ''
})

const displayedTools = computed(() => {
  const selectedTools = onboardingModel.value.projectTools || []
  return coreTools.filter((tool) => selectedTools.includes(tool.value))
})

const tasksToAdd = computed<any[]>(() => {
  const tasksArray = Object.values(onboardingModel.value.tasksToAdd)
  return tasksArray.filter((task: any) => task.name)
})

const inviteUsers = computed(() => {
  return onboardingModel.value.inviteUsers || []
})
</script>
