<template>
  <div class="bg-white rounded-lg">
    <div class="mx-auto space-y-2">
      <BaseLogo
        :entity="project"
        class="rounded-md mx-auto"
        size="lg"
      />
      <p class="text-center font-bold text-gray-900 max-w-sm">
        {{ project.attributes.name }}
      </p>
      <div class="flex justify-between space-x-2">
        <slot name="actions">
          <BaseButton
            variant="primary"
            size="sm"
            @click.stop.prevent="$router.push(openRoute)"
          >
            <span>{{ viewLabel }}</span>
          </BaseButton>
          
          <BaseButton
            variant="white"
            size="sm"
            tag="a"
            target="_blank"
            :href="openRoute"
          >
            <span>{{ $t('Open in new tab') }}</span>
          </BaseButton>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
const PreviewTypes = {
  Inline: 'inline',
  Full: 'full'
}
import ProjectLogo from "@/modules/projects/components/ProjectLogo.vue";
export default {
  components: {
    ProjectLogo
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: PreviewTypes.Full
    }
  },
  computed: {
    isFullPreview() {
      return this.type === PreviewTypes.Full
    },
    isTemplate() {
      return this.project?.attributes?.is_template
    },
    openRoute() {
      if (this.isTemplate) {
        return `/templates/${this.project.id}`
      }

      return `/projects/${this.project.id}`
    },
    viewLabel() {
      if (this.isTemplate) {
        return this.$t('View Template')
      }

      return this.$t('View Project')
    }
  }
}
</script>
