import { getReferencePath } from "@/components/html/util/referenceUtils.js";

export const projectResourceTypes = {
  ProjectLink: 'project_links',
  ProjectReferences: 'project_references',
  ProjectEmbeds: 'project_embeds'
}

export function getResourcePath(resource, currentPath) {
  if (!resource) {
    return ''
  }

  if (resource.type === projectResourceTypes.ProjectReferences) {
    return getReferencePath(resource.attributes)
  }

  if (resource.type === projectResourceTypes.ProjectLink) {
    return {
      target: '_blank',
      href: resource.attributes.link
    }
  }

  if (resource.type === projectResourceTypes.ProjectEmbeds) {
    return `${currentPath.replace('/resources/list', '')}/embeds/${resource.id}`
  }

  return `/`
}

export function getResourceUrl(resource) {
  if (resource.type === projectResourceTypes.ProjectReferences) {
    return getReferencePath(resource.attributes)
  }

  if (resource.type === projectResourceTypes.ProjectLink) {
    return resource.attributes.link
  }

  if (resource.type === projectResourceTypes.ProjectEmbeds) {
    return resource.attributes.src
  }

  return ''
}
