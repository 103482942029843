<template>
  <section
    aria-labelledby="quick-links-title"
    class="welcome-cards"
    :class="{ 'opacity-50': !isSubscriptionValid }"
  >
    <div class="w-full grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      <div
        v-for="card of availableCards"
        :key="card.title"
        class="relative bg-white p-6 border border-gray-200 rounded-lg group shadow tool-card"
      >
        <i
          class="fas fa-chevron-up pointer-events-none absolute top-5 right-5 text-gray-300 origin-center rotate-45 tool-chevron"
          :class="{
            [`group-hover:text-pink-500`]: card.colorClass === 'text-pink-500',
            [`group-hover:text-red-500`]: card.colorClass === 'text-red-500',
            [`group-hover:text-blue-500`]: card.colorClass === 'text-blue-500',
            [`group-hover:${card.colorClass}`]: true
          }"
        />

        <div class="rounded-lg inline-flex sm:m-0 p-0 ring-4 ring-white">
          <i
            class="fal text-3xl sm:text-5xl tool-icon mr-4"
            :class="[card.iconClass, card.colorClass]"
            aria-hidden="true"
          />
          <h3 class="text-2xl font-bold sm:mt-2">
            <router-link
              :to="card.path"
              class="focus:outline-none"
            >
              <span
                class="absolute inset-0"
                aria-hidden="true"
              />
              <span>{{ card.title }}</span>
              <AccessControlIndicator
                v-if="card.displayAclIndicator && card.displayAclIndicator()"
                class="ml-1"
              />
            </router-link>
          </h3>
        </div>
        <div class="border-b border-gray-200 my-4 -mx-6  hidden sm:block" />
        <div class="sm:mt-2">
          <p class="mt-2 text-sm text-gray-500">
            {{ card.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import { useNavigationCards } from "@/modules/dashboard/utils/useNavigationCards"

const { availableCards } = useNavigationCards()
</script>
