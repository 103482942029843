<template>
  <ElPopover
    :visible="autoShow ? visible : undefined"
    :trigger="$attrs.trigger || 'click'"
    :popper-class="`inline-input-popover ${popperClass}`"
    :width="width"
    @show="onShow"
    @hide="onHide"
  >
    <template #reference>
      <span>
        <slot name="reference" />
      </span>
    </template>
    <div ref="content">
      <slot />
    </div>
  </ElPopover>
</template>
<script setup>
import { ElPopover } from 'element-plus'
import { ref, nextTick, onMounted } from 'vue'

const props = defineProps({
  width: {
    type: String,
    default: 'auto'
  },
  focusElementSelector: {
    type: String,
    default: 'input'
  },
  autoShow: {
    type: Boolean,
    default: false
  },
  disableAutofocus: {
    type: Boolean,
    default: false
  },
  popperClass: {
    type: String,
    default: ''
  }
})

const content = ref(null)
const visible = ref(false)

const emit = defineEmits(['show', 'hide'])

async function onShow() {
  visible.value = true
  emit('show')

  if (props.disableAutofocus) {
    return
  }

  await nextTick()
  const input = content.value?.querySelector(props.focusElementSelector)
  if (!input) {
    return
  }

  input.focus()
}

function onHide() {
  emit('hide')
  visible.value = false
}

onMounted(() => {
  props.autoShow && onShow()
})
</script>
<style lang="scss">
.el-popover.inline-input-popover {
  .base-select .el-input__prefix {
    @apply h-full;
  }

  .form-input, .el-select .select-trigger .el-input input.el-input__inner {
    --tw-shadow: 0;
    --tw-shadow-colored: 0;
    height: unset !important;
    outline-color: transparent !important;

    @apply p-1 border-none;

    &:focus {
      --tw-ring-color: transparent;
      @apply outline-none focus-visible:outline-none;
    }

    @apply shadow-none;
  }

  .form-input::placeholder {
    @apply text-gray-300;
  }
}
</style>
