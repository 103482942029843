<template>
  <div v-if="showIfActive || status !== USER_STATUSES.ACTIVE_STATUS">
    <BaseBadge
      v-if="status"
      :color="color"
      class="my-0"
    >
      {{ status }}
    </BaseBadge>
    <template v-else>- -</template>
  </div>
</template>
<script>
import { isUserArchived } from "@/modules/users/util/userUtils";

const USER_STATUSES = {
  ACTIVE_STATUS: 'active',
  PENDING_STATUS: 'pending',
  ARCHIVE_STATUS: 'archived',
}

export default {
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    row: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    showIfActive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      USER_STATUSES
    }
  },
  computed: {
    status() {
      // TODO: This is a temp fix until archive status is moved to tenant pivot BE-side, currently archived_at is more reliable
      if (isUserArchived(this.row)) {
        return USER_STATUSES.ARCHIVE_STATUS
      }

      const statusName = this.get(this.row, this.column.prop || this.column.field, '')

      if (statusName === USER_STATUSES.PENDING_STATUS) {
        return USER_STATUSES.PENDING_STATUS
      }

      return USER_STATUSES.ACTIVE_STATUS
    },
    color() {
      const colors = {
        green: 'rgb(16, 185, 129)',
        gray: 'rgb(107, 114, 128)'
      }
      if (this.status === 'active') {
        return colors.green
      }
      return colors.gray
    }
  }
}
</script>
