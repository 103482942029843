<template>
  <div>
    <div
      class="max-h-[500px] overflow-x-auto"
      :class="{
        'p-6': chatMessages.length,
      }"
    >
      <div class="space-y-6">
        <template
          v-for="chatMessage in chatMessages"
          :key="chatMessage.date"
        >
          <AiUserMessage
            v-if="chatMessage.type === ChatMessageTypes.User"
            :message="chatMessage"
          />
          <AiSystemMessage
            v-else-if="chatMessage.type === ChatMessageTypes.System"
            :message="chatMessage"
          />
          <AiCreateMessage
            v-else
            :message="chatMessage"
            @send-to-action-mode="emit('send-to-action-mode', $event)"
          />
        </template>
        <AiConversationPrompt
          v-if="showConversationPrompt"
          @start-new="startNewConversation"
          @continue="showConversationPrompt = false"
        />
      </div>
      <div id="create-discussion-bottom" />
    </div>
    <HtmlEditor
      v-model="prompt"
      :placeholder="$t(`What would you like the AI Assistant to create?`)"
      layout="box"
      :readonly="disableNewMessages"
      :slashMenu="false"
      :showFormattingControls="false"
      :disabledPlugins="['mention', 'file-upload', 'link', 'emoji', 'reference']"
      @send="sendMessage"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import AiUserMessage from "@/modules/ai/components/AiUserMessage.vue";
import AiCreateMessage from "@/modules/ai/components/AiCreateMessage.vue";
import AiSystemMessage from "@/modules/ai/components/AiSystemMessage.vue";
import AiConversationPrompt from "@/modules/ai/components/AiConversationPrompt.vue";

// Utils
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { ChatMessage, ChatMessageTypes } from '@/modules/ai/types/aiTypes';
 import showdown from 'showdown'
import {
  sendCreateModeRequest
} from "@/modules/ai/utils/aiCreateUtils";

// Composables
import { useStore } from 'vuex';

const store = useStore();

const showConversationPrompt = computed({
  get() {
    return store.state.ai.showConversationPrompt
  },
  set(value) {
    store.commit('ai/setShowConversationPrompt', value)
  }
})

const emit = defineEmits<{
  (e: 'send-to-action-mode', messageHtml: string): void 
}>()

const history = computed<ChatMessage[]>(() => {
  return store.state.ai.createModeHistory
})

const activeMessages = computed<ChatMessage[]>(() => {
  return store.state.ai.createModeMessages
})

const disableNewMessages = computed(() => {
  return showConversationPrompt.value;
})


const htmlConverter = new showdown.Converter({
  tables: true,
})

const prompt = ref('')

function sendMessage() {
  let promptHtml = prompt.value?.trim()
  if (!promptHtml) {
    return;
  }

  const promptMarkdown = htmlConverter.makeMarkdown(prompt.value)

  sendCreateModeRequest(promptMarkdown)

  prompt.value = '';
}


const chatMessages = computed<ChatMessage[]>(() => {
  return [
    ...history.value,
    ...activeMessages.value
  ]
})

watch(() => [
  chatMessages.value.length,
  ],
  scrollToBottom,
  { immediate: true }
)

function startNewConversation() {
  store.dispatch('ai/startNewCreateConversation');
  showConversationPrompt.value = false;
}

async function scrollToBottom() {
  await nextTick()
  const el = document.getElementById('create-discussion-bottom');
  if (el) {
    el.scrollIntoView();
  }
}


function promptConversationClear() {
  if (!chatMessages.value.length || chatMessages.value[chatMessages.value.length - 1].type === ChatMessageTypes.System) {
    return;
  }

  showConversationPrompt.value = true;
}

onMounted(() => {
  promptConversationClear()
  scrollToBottom()
})
</script>