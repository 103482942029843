<template>
  <div class="task-groups flex flex-wrap items-center whitespace-nowrap gap-y-1 gap-x-2">
    <BaseInlineInput class="w-full">
      <template #default="{ triggerSave }">
        <span
          v-if="!$attrs.disabled"
          class="relative w-full"
        >
          <GroupSelect
            v-model="model"
            :initialValue="groups"
            :urlParams="{
              project_id: taskModel.project_id,
            }"
            allow-entity-create
            :showInfoText="false"
            addEntityTrigger="projects/triggerProjectPeople"
            :addEntityParams="{ projectId: taskModel.project_id }"
            :addEntityLabel="$t('Add groups to project')"
            :placeholder="placeholder"
            :clearable="false"
            :multiple="true"
            :disabled="$attrs.disabled || !taskModel.project_id"
            @raw-change="onRawChange($event, triggerSave)"
          />
          <span
            v-if="model?.length"
            class="absolute left-1 top-1/2 -translate-y-1/2 text-sm text-gray-400"
          >
            {{ placeholder }}
          </span>
        </span>
        <span
          v-else-if="!taskGroups?.length"
          class="p-1 text-gray-400"
        >
          - -
        </span>
        <GroupChips
          v-if="taskGroups?.length"
          :groups="taskGroups"
          :actions="groupActions"
          @remove="(group) => removeGroup(group, triggerSave)"
        />
      </template>
    </BaseInlineInput>
  </div>
</template>
<script>
import GroupSelect from "@/components/selects/GroupSelect.vue";
import GroupLogo from "@/components/common/GroupLogo.vue";
import GroupChips from "@/components/common/GroupChips.vue";
import i18n from "@/i18n";
import { debounce } from "lodash-es"
import {
  UserPreviewActions
} from "@/modules/users/util/userUtils";

export default {
  components: {
    GroupSelect,
    GroupLogo,
    GroupChips,
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    taskModel: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: i18n.t('+ Add Groups')
    }
  },
  data() {
    return {
      taskGroups: this.groups || []
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    groups() {
      return this.task?.relationships?.groups || []
    },
    sortedTaskGroups() {
      return [...this.taskGroups].sort((x, y) => x.id - y.id)
    },
    groupActions() {
      if (this.$attrs.disabled) {
        return [UserPreviewActions.View]
      }

      return [UserPreviewActions.View, UserPreviewActions.Remove]
    },
  },
  watch: {
    groups: {
      immediate: true,
      handler(value) {
        this.taskGroups = value
      }
    },
  },
  methods: {
    onRawChange(groups, triggerSave) {
      this.taskGroups = [...groups]

      if (!this.triggerSaveDebounced) {
        this.triggerSaveDebounced = debounce(triggerSave, 500)
      }
      
      this.triggerSaveDebounced()
    },
    removeGroup(group, triggerSave) {
      this.model = this.model.filter(id => id !== group.id)
      this.taskGroups = this.taskGroups.filter(g => g.id !== group.id)

      triggerSave()
    },
  }
}
</script>
