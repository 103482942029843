<template>
  <BaseAlert
    v-if="showImportMessage && can($actions.MANAGE_ACCOUNT_SETTINGS)"
    :key="newImportState.key"
    :dismissable="dismissable"
    variant="warning"
    class="mb-4 border border-yellow-500"
  >
    {{ message }}

    <span v-if="fetchingData && !dataFetched">
      {{ $t('Loading data...') }}
    </span>
    <span v-if="dataFetched">
      {{ $t('You can now access your imported data.') }}
    </span>
    <router-link
      v-if="!fetchingData"
      to="/account-settings/import"
      class="underline block"
      @click="showImportMessage = false"
    >
      {{ $t('Click here to review your imports.') }}
    </router-link>
  </BaseAlert>
</template>
<script lang="ts" setup>
import i18n from "@/i18n";
import { computed, ref, watch } from "vue"
import { useStore } from 'vuex'
import {
  ImportFinishedEvent,
  importEntryStatuses
} from "@/modules/accounts/utils/importUtils";

const store = useStore()

const newImportState = computed<ImportFinishedEvent & { key: number }>(() => {
  return store.state.accounts.newImportState
})

const message = computed(() => {
  const messages: Partial<Record<importEntryStatuses, string>> = {
    [importEntryStatuses.Successful]: i18n.t(`Data imported successfully!`),
    [importEntryStatuses.Failed]: i18n.t(`Something failed while trying to import data.`),
    [importEntryStatuses.Pending]: i18n.t(`Importing data... You will be notified when the import is complete.`),
  }

  return messages[newImportState.value.import_status]
})

const dismissable = computed(() => {
  if (dataFetched.value) {
    return true
  }

  return newImportState.value.import_status !== importEntryStatuses.Successful
})

const showImportMessage = ref(false)

const fetchingData = ref(false)
const dataFetched = ref(false)

async function fetchData() {
  try {
    fetchingData.value = true
    await store.dispatch('users/getData')
    dataFetched.value = true
  } catch (error) {
    console.error(error)
  } finally {
    fetchingData.value = false
  }
}

function handleSync() {
  const handlers: Partial<Record<importEntryStatuses, Function>> = {
    [importEntryStatuses.Successful]: () => {
      fetchData()
    },
  }

  if (!handlers[newImportState.value.import_status]) {
    return
  }

  handlers[newImportState.value.import_status]?.()
}

function resetState() {
  fetchingData.value = false
  dataFetched.value = false
}

watch(() => newImportState.value.key, (value) => {
  showImportMessage.value = !!value
  resetState()
  handleSync()
}, { immediate: true })
</script>
