import AuthService from "@/modules/auth/services/AuthService.js";
import { error } from '@/components/common/NotificationPlugin';
import { get, merge } from "lodash-es";
import axios from 'axios'
import config, { isRootApi } from "@/modules/common/config";
import i18n from "@/i18n";
import { trackRegisterEvent } from "@/modules/common/utils/analyticUtils.js";
import { trackRegister } from "@/modules/common/utils/trackingUtils";

const state = () => ({
  user: {},
  isLoggedIn: false,
  organizations: [],
  tenantInfo: {},
  newAccount: false,
  registerModel: null
});

const mutations = {
  setUser(state, value) {
    state.user = value;
  },
  setLoggedIn(state, value) {
    state.isLoggedIn = value;
  },
  setOrganizations(state, value) {
    state.organizations = value
  },
  setCurrentTenant(state, value) {
    state.tenantInfo = merge(state.tenantInfo, value)
  },
  setNewAccount(state, value) {
    state.newAccount = value
  },
  setRegisterModel(state, value) {
    state.registerModel = value
  },
}

const actions = {
  async login({ commit, dispatch }, data) {
    try {
      const { user } = await AuthService.login(data);
      commit('setUser', user)
      commit('setLoggedIn', true);
      await dispatch('getProfile')
    } catch (err) {
      if (![400, 401, 422].includes(err.response.status)) {
        error('An error occurred during login. Please try again or contact us')
      }
      throw err
    }
  },
  async register({ commit }, { data, params }) {
    try {
      const res = await AuthService.register(data, params)
      const location = res?.data?.tenants[0]?.fqdn
      const provider = params.provider || 'email'

      await trackRegisterEvent({
        provider,
        email: data.email,
        uid: res?.data?.user?.stripe_id,
      })

      commit('setRegisterModel', null)

      if (location) {
        const ap3cCookie = ap3c?.getCookie?.()
        const redirectUrl = new URL(location)

        redirectUrl.searchParams.set('loginToken', res?.data?.token)
        redirectUrl.searchParams.set('newAccount', true)

        if (ap3cCookie) {
          redirectUrl.searchParams.set('ap3c', ap3cCookie)
        }

        window.location = redirectUrl.href
        return
      }

      return {}
    } catch (err) {
      console.warn(err)
      throw err
    }
  },
  async getTenantsList({ commit }) {
    try {
      const tenants = await AuthService.getTenants();
      commit('setOrganizations', tenants || [])
    } catch (err) {
      console.warn('Could not fetch tenant organizations')
    }
  },
  async getProfile({ commit, state, dispatch }) {
    try {
      if (!state.isLoggedIn) {
        return
      }

      if (isRootApi()) {
        await dispatch('getTenantsList')
        return
      }

      const res = await AuthService.getProfile();
      const user = {
        ...res?.attributes,
        ...res?.relationships,
        id: res.id,
      }

      if (user.locale) {
        await i18n.setLocale(user.locale)
      }

      commit('setUser', user)
      commit('setOrganizations', res.relationships?.tenants || [])
      commit('dashboard/setUseNewLayout', user.new_layout, { root: true })
    } catch (err) {
      if (err.handled) {
        return
      }
      error('An error occurred while trying to update the profile')
    }
  },
  async updateProfile({ commit, state, rootGetters }, { data, canUpdateRole }) {
    try {
      await AuthService.updateProfile(data);

      const res = await AuthService.getProfile();
      const user = {
        ...state.user,
        ...res?.attributes,
        ...res?.relationships
      }

      commit('setUser', user)

      if (!canUpdateRole) {
        return
      }

      const roles = rootGetters['users/editableRoles']
      const oldRoleLabel = get(user, 'role[0]')
      const oldRole = roles.find(s => s?.attributes?.name === oldRoleLabel)
      const oldRoleId = oldRole?.id
      const role = data.role

      if (oldRoleId?.toString() === role?.toString()) {
        return
      }

      await axios.post(`/restify/users/${user.id}/actions?action=change-role`, {
        role,
      })
    } catch (err) {
      if (err.handled) {
        return
      }
      error('Could not update your profile.')
    }
  },
  async updateUserNotifiable({}, {
    user,
    notifiable_settings
  }) {
    try {
      const settings = []
      for (const key in notifiable_settings) {
        settings.push({
          notification_type: key,
          is_enabled: !!notifiable_settings[key]
        })
      }
      await axios.post(`/restify/users/${user.id}/actions?action=change-notification-settings`, {
        notifiable_settings: settings,
      })
    } catch (err) {
      error(i18n.t('Could not update user notification preferences'))
      throw err
    }
  },
  async logout({ commit }) {
    commit('setLoggedIn', false);
    await AuthService.logout();
  },
  async getTenantDetails({ getters, commit }) {
    const tenantId = getters.currentTenant?.id
    if (!tenantId) {
      return
    }

    const { data } = await axios.get(`/tenant/${tenantId}/details`)

    commit('setCurrentTenant', data)
  }
};
const getters = {
  isTenantOwner: (state) => {
    return state.user.tenant_owner
  },
  currentTenant: (state) => {
    return state.organizations.find(tenant => tenant.attributes?.subdomain === config.getOrganizationName())
  },
  tenantName: (state) => {
    return state.tenantInfo?.name || config.getOrganizationName()
  },
  onboardingAnswers: (state) => {
    return state.tenantInfo?.onboardings?.[0] || {}
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
