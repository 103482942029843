<template>
  <BaseEntityForm
    :title="title || $t('Recurrence')"
    :isDialogForm="true"
    width-class="max-w-lg"
  >
    <template #default="{ meta, errors }">
      <BaseInput
        :modelValue="model.frequency"
        :label="$t('Frequency')"
        :layout="layout"
      >
        <BaseSelect
          v-model="model.frequency"
          :options="frequencyOptions"
          :return-object="false"
          @change="onFrequencyChanged"
        />
      </BaseInput>
      <BaseInput
        v-model="model.interval"
        :label="$t('Interval')"
        :layout="layout"
        type="number"
      />
      <BaseInput
        v-if="isSingleDaySelect"
        :label="$t('Day Type')"
        :layout="layout"
      >
        <BaseSelect
          v-model="model.selectedWeekDayOrdinal"
          :options="dayTypeOrdinalOptions"
          :return-object="false"
          clearable
        />
      </BaseInput>
      <!-- TODO: Could also add "special options" (helpers) -> all week days, weekend days -->
      <BaseInput :label="isSingleDaySelect ? $t('Day') : $t('Day(s)')" :layout="layout">
        <BaseSelect
          v-model="model.selectedWeekDays"
          :options="weekDays"
          :multiple="!isSingleDaySelect"
          clearable
        />
      </BaseInput>
        <BaseInput
        v-if="isDateInMonthEnabled"
        :label="$t('Date in month')"
        :layout="layout"
      >
        <BaseSelect
          v-model="model.dateInMonth"
          :options="dateInMonthOrdinalOptions"
          :return-object="false"
          clearable
        />
      </BaseInput>
      <BaseInput
        v-if="isWeekNumberEnabled"
        :label="$t('Week number')"
        :layout="layout"
      >
        <BaseSelect
          v-model="model.weekNumber"
          :options="weekNumberOrdinalOptions"
          :return-object="false"
          clearable
        />
      </BaseInput>
      <BaseInput :label="$t('Month(s)')" :layout="layout">
        <BaseSelect
          v-model="model.selectedMonths"
          :options="months"
          multiple
          clearable
        />
      </BaseInput>
        <BaseInput
        v-if="isYearDayEnabled"
        :label="$t('Year day')"
        :layout="layout"
      >
        <BaseSelect
          v-model="model.yearDay"
          :options="yearDayOrdinalOptions"
          :return-object="false"
          clearable
        />
      </BaseInput>
      <!-- START/END RULES -->
      <BaseInput :label="$t('Start Date')" :layout="layout">
        <BaseDatePicker
          v-model="model.dateStart"
        />
      </BaseInput>
      <BaseInput
        v-if="!model.count"
        :label="$t('End Date')"
        :layout="layout"
      >
        <BaseDatePicker
          v-model="model.dateEnd"
          clearable
        />
      </BaseInput>
      <BaseInput
        v-if="!model.dateEnd"
        v-model="model.count"
        :label="$t('End after occurences')"
        :layout="layout"
        type="number"
      />
      <div class="sm:grid sm:gap-4 sm:items-start sm:grid-cols-4">
        <label class="text-sm font-medium leading-5 text-gray-700 flex flex-wrap sm:mt-px sm:pt-2 sm:col-span-1">
          {{"Preview"}}
        </label>
        <BaseButton
          custom-class="sm:col-span-3"
          block
          @click="previewVisible = !previewVisible"
        >
          {{ previewVisible? $t("Hide preview") : $t("See a preview of these settings") }}
        </BaseButton>
      </div>
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$emit('cancel')"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        type="submit"
        class="ml-2"
        @click="$emit('confirm', {
          recurrenceDate,
          rrule: recurrenceResult,
        })"
      >
        {{ $t('Confirm') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
import { RRule } from "rrule";

const weekDaysList = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU]

import {
  frequencyOptions,
  defaultFrequency,
  getRecurringWeekDays,
  getRecurringMonths,
  dayTypeOrdinalOptions,
  dateInMonthOrdinalOptions,
  weekNumberOrdinalOptions,
  yearDayOrdinalOptions,
  getRRuleFromString,
  getRRuleFromText,
  getNextOccurrence,
} from "@/modules/common/utils/recurrenceUtils";

import {
  getDateWithoutTime,
} from "@/modules/common/utils/dateUtils.js";

export default {
  props: {
    initModel: {
      type: Object,
      required: false,
    },
    layout: {
      type: String,
      default: "horizontal",
    },
    title: {
      type: String,
      default :''
    },
    defaultPreviewVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['cancel', 'confirm'],
  data() {
    return {
      RRule,
      frequencyOptions,
      weekDays: getRecurringWeekDays(),
      months: getRecurringMonths(),
      dayTypeOrdinalOptions,
      dateInMonthOrdinalOptions,
      weekNumberOrdinalOptions,
      yearDayOrdinalOptions,
      model: {
        frequency: defaultFrequency,
        interval: 1,
        selectedWeekDays: null,
        selectedWeekDayOrdinal: null,
        selectedMonths: null,
        dateInMonth: null,
        yearDay: null,
        weekNumber: null,
        dateStart: new Date(),
        dateEnd: null,
        count: null,
      },
      maxPreviewItems: 12,
      previewColumns: [
        {
          name: this.$t("#"),
          prop: "nr",
          class: "w-min",
        },
        {
          name: this.$t("Day"),
          prop: "day",
        },
        {
          name: this.$t("Month"),
          prop: "month",
        },
        {
          name: this.$t("Year"),
          prop: "year",
        },
      ],
      previewVisible: this.defaultPreviewVisible
    };
  },
  computed: {
    recurrenceResult() {
      return this.modelToRRule()
    },
    previewData() {
      return this.recurrenceResult
        .all((d, len) => {
          if (len > this.maxPreviewItems - 1) return false;
          return true;
        })
        .map((date, i) => {
          const d = getDateWithoutTime(date.toISOString())
          return {
            nr: i + 1,
            day: this.$formatDate(d, "EEE, do"),
            month: this.$formatDate(d, "MMM"),
            year: this.$formatDate(d, "yyyy"),
          }
        });
    },
    recurrenceDate() {
      return getNextOccurrence(this.recurrenceResult)
    },
    formattedNextOccurence() {
      const formattedDate = this.$formatDate(
        this.recurrenceDate,
        `EEE, do 'of' MMM yyyy`
      );

      return formattedDate;
    },
    totalOccurences() {
      if (!this.model.count && !this.model.dateEnd) {
        return ''
      }
      return this.recurrenceResult.all().length
    },
    isSingleDaySelect() {
      return [RRule.YEARLY, RRule.MONTHLY].includes(this.model.frequency)
    },
    isDateInMonthEnabled() {
      return [RRule.YEARLY, RRule.MONTHLY].includes(this.model.frequency)
    },
    isYearDayEnabled() {
      // return [RRule.YEARLY].includes(this.model.frequency)
      return false
    },
    isWeekNumberEnabled() {
      return [RRule.YEARLY].includes(this.model.frequency)
    }
  },
  methods: {
    // Model 2 RRule
    getRRuleSelectedWeekDays() {
      const selectedWeekDays = this.model.selectedWeekDays || null
      if (!this.isSingleDaySelect) {
        return selectedWeekDays?.map(x => x.value) || null
      }

      if (!selectedWeekDays) {
        return null
      }

      if (this.model.selectedWeekDayOrdinal) {
        return [ weekDaysList[selectedWeekDays.value].nth(this.model.selectedWeekDayOrdinal) ]
      }

      return [ selectedWeekDays.value ]
    },
    modelToRRule() {
      if (!this.model.interval || this.model.interval < 1) {
        this.model.interval = 1
      }
      const interval = parseInt(this.model.interval) || 1;
      const byweekday = this.getRRuleSelectedWeekDays()
      const bymonth = this.model.selectedMonths?.map(x => x.value) || null;

      const bymonthday = (this.isDateInMonthEnabled && this.model.dateInMonth) || null;
      const byyearday = (this.isYearDayEnabled && this.model.yearDay) || null;
      const byweekno = (this.isWeekNumberEnabled && this.model.weekNumber) || null;
      
      if (this.model.dateEnd) {
        this.model.count = null
      }

      if (this.model.count) {
        this.model.dateEnd = null
      }

      return new RRule({
        freq: this.model.frequency,
        interval,
        byweekday,
        bymonth,
        bymonthday,
        byyearday,
        byweekno,
        dtstart: this.model.dateStart
          ? new Date(this.model.dateStart)
          : null,
        until: this.model.dateEnd
          ? new Date(this.model.dateEnd)
          : null,
        count: this.model.count
      });
    },
    // RRule 2 Model
    getSelectedWeekDaysAndOrdinalFromRRule(rrule) {
      const options = rrule.options
      const origOptions = rrule.origOptions

      const byweekday = origOptions.byweekday
      
      let selectedWeekDayOrdinal = null
      let selectedWeekDays = null

      if (byweekday && [RRule.MONTHLY, RRule.YEARLY].includes(options.freq)) { // day with ordinal
        selectedWeekDays = this.weekDays.find(day => day.value === byweekday[0]?.weekday) || null
        selectedWeekDayOrdinal = byweekday[0]?.n || null
      }
      else {
        selectedWeekDays = byweekday?.map(dayValue => this.weekDays.find(day => day.value === dayValue?.weekday)) || null
      }
      
      return {
        selectedWeekDays,
        selectedWeekDayOrdinal
      }
    },
    rruleToModel(rrule) {
      const options = rrule.options
      const selectedMonths = options?.bymonth?.map(monthValue => this.months.find(month => month.value === monthValue))

      const {
        selectedWeekDays,
        selectedWeekDayOrdinal
      } = this.getSelectedWeekDaysAndOrdinalFromRRule(rrule)

      this.model = {
        frequency: options.freq,
        interval: options.interval,
        selectedWeekDays,
        selectedWeekDayOrdinal,
        selectedMonths,
        dateInMonth: options.bymonthday?.[0] || options.bynmonthday?.[0],
        yearDay: options.byyearday?.[0],
        weekNumber: options.byweekno?.[0],
        dateStart: getDateWithoutTime(options.dtstart?.toISOString()),
        dateEnd: getDateWithoutTime(options.until?.toISOString()),
        count: options.count
      }
    },
    onFrequencyChanged() {
      this.model.selectedWeekDays = null
      this.model.selectedWeekDayOrdinal = null
    }
  },
  mounted() {
    if (!this.initModel) {
      return
    }

    if (this.initModel.string) {
      const rrule = getRRuleFromString(this.initModel.string)
      this.rruleToModel(rrule)
      return
    }

    if (this.initModel.text) {
      const rrule = getRRuleFromText(this.initModel.text)
      this.rruleToModel(rrule)
      return
    }
  }
};
</script>
