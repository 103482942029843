<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-2xl relative"
    @close="$emit('close')"
  >
    <BaseEntityForm
      :title="texts.title"
      :subtitle="texts.subtitle"
      :isDialogForm="true"
      width-class="max-w-2xl"
    >
      <template #default="{ meta, errors }">
        <div class="flex space-x-2 w-full relative">
          <div class="w-full">
            <UserSelectNew
              v-model="addUserIds"
              :filterGroupIds="filterGroupIds"
              :excludedOptions="projectUserIds"
              :type="type"
              :createEntityParams="{
                enable: true,
                entityType: 'user',
                label: $t('Invite New People'),
              }"
              multiple
            >
              <template #custom-options>
                <div class="flex items-center space-x-2 pl-5 pr-8 py-2">
                  <BaseSwitch
                    v-model="filterPeopleFromSelectedGroups"
                  />
                  <span class="text-gray-500 text-sm">
                    {{ $t('Only show people from added groups') }}
                  </span>
                </div>
              </template>
            </UserSelectNew>
          </div>
          <BaseButton
            :disabled="!addUserIds?.length"
            :loading="sendingInvites"
            @click="inviteUsers"
          >
            <div class="flex items-center space-x-2">
              <i class="fal fa-paper-plane-top" />
              <span class="hidden sm:inline">{{ $t('Invite') }}</span>
            </div>
          </BaseButton>
        </div>
        <div class="flow-root mt-6">
          <ul
            class="divide-y divide-gray-200"
          >
            <ProjectUser
              v-for="user in filteredUsers"
              :key="user.id"
              :user="user"
              :project="project"
              class="py-2"
            />
          </ul>
        </div>
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          @click="$emit('close')"
        >
          {{ $t("I'm done adding people - Close") }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
// Components
import UserSelectNew from '@/components/selects/UserSelectNew.vue'
import ProjectUser from "@/modules/projects/components/ProjectUser.vue"

// Utils
import i18n from '@/i18n';
import { PropType, computed, ref } from "vue";
import { UserTypes, filterUsersByType } from "@/modules/users/util/userUtils";
import { error } from '@/components/common/NotificationPlugin';
import apiCache from '@/modules/common/utils/apiCache';

import Data = API.Data;
import User = App.Domains.Users.Models.User;
import Project = App.Domains.Projects.Models.Project;
import Group = App.Domains.Groups.Models.Group;

// Composables
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  project: {
    type: Object as PropType<Data<Project>>,
    default: () => ({}),
  },
  type: {
    type: String as PropType<UserTypes>,
    default: UserTypes.All,
  },
})

const addUserIds = ref<number[]>([])
const filterPeopleFromSelectedGroups = ref<boolean>(false)
const sendingInvites = ref<boolean>(false)

async function inviteUsers() {
  const newUserIds = addUserIds.value.filter(id => !projectUserIds.value.includes(+id))
      
  if (!newUserIds.length) {
    error(i18n.t('Selected users are already part of the project'))
    return
  }

  sendingInvites.value = true

  try {
    await store.dispatch('projects/addProjectUsers', {
      users: newUserIds,
      projectId: props.project.id,
    })

    await getProjectSilently()
    apiCache.removeForEntity('users')
    addUserIds.value = []
  }
  catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not add users to the project'))
  }
  finally {
    sendingInvites.value = false
  }
}

async function getProjectSilently() {
  await store.dispatch('projects/getProjectById', {
    id: props.project.id,
    silent: true
  })
}

const projectGroups = computed<Data<Group>[]>(() => {
  return props.project?.relationships?.groups || []
})

const projectGroupIds = computed<number[]>(() => {
  return projectGroups.value.map((group) => +group.id)
})

const projectUsers = computed<Data<User>[]>(() => {
  return props.project?.relationships?.users || []
})

const projectUserIds = computed<number[]>(() => {
  return projectUsers.value.map((user) => +user.id)
})

const filterGroupIds = computed<number[]>(() => {
  return filterPeopleFromSelectedGroups.value
    ? projectGroupIds.value
    : []
})

const filteredUsers = computed<Data<User>[]>(() => {
  return filterUsersByType(projectUsers.value, props.type)
})

const projectName = computed<string>(() => {
  return props.project?.attributes?.name || ''
})

const texts = computed(() => {
  const userTypesTitle = {
    [UserTypes.Creators]: i18n.t('Invite Creators'),
    [UserTypes.Collaborators]: i18n.t('Invite Collaborators'),
    [UserTypes.All]: i18n.t('Invite People'),
  }

  const userTypesSubtitle = {
    [UserTypes.Creators]: i18n.t('Invite people with a Creator role to this project.'),
    [UserTypes.Collaborators]: i18n.t(' Invite people with a Collaborator role to this project.'),
    [UserTypes.All]: i18n.t('Invite people to your project. When you add people they will get a project invite. They will also be notified about any project updates based on their notification settings.'),
  }

  return {
    title: userTypesTitle[props.type],
    subtitle: userTypesSubtitle[props.type],
  }
})
</script>
