<template>
  <BaseEntityForm
    width-class="max-w-3xl"
    @submit="onSubmit"
  >
    <template #title>
      <BaseFormHeader>
        <template #title>
          <div class="flex items-center justify-between">
            <h3 class="text-2xl font-bold">
              <span class="mr-2">
                {{ $t('Manage configuration') }}
              </span>
            </h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="77"
              height="32"
              :fill="$useNewLayout ? 'var(--breadcrumb-color)' : ''"
            >
              <path
                d="M76.8 16.51c0-5.46-2.65-9.77-7.7-9.77-5.08 0-8.15 4.31-8.15 9.73 0 6.42 3.63 9.66 8.83 9.66 2.54 0 4.46-.57 5.91-1.38v-4.27a11.33 11.33 0 0 1-5.23 1.17c-2.07 0-3.9-.72-4.13-3.24h10.43c0-.28.04-1.39.04-1.9zM66.26 14.5c0-2.42 1.47-3.42 2.82-3.42 1.3 0 2.69 1 2.69 3.42h-5.5zM52.71 6.74a6 6 0 0 0-4.18 1.67l-.27-1.33h-4.7v24.88l5.34-1.13.02-6.04a6 6 0 0 0 3.77 1.34c3.82 0 7.3-3.07 7.3-9.83-.02-6.19-3.54-9.56-7.28-9.56zm-1.28 14.7c-1.25 0-2-.45-2.51-1l-.02-7.92a3.18 3.18 0 0 1 2.53-1.04c1.95 0 3.29 2.17 3.29 4.97 0 2.86-1.32 4.99-3.29 4.99zM36.22 5.48l5.36-1.15V0l-5.36 1.13zm0 1.62h5.35v18.67h-5.35zm-5.73 1.58-.35-1.58h-4.6v18.67h5.33V13.12c1.26-1.64 3.4-1.34 4.05-1.1V7.1c-.68-.25-3.18-.72-4.43 1.58zM19.82 2.47l-5.2 1.11-.03 17.1a5.36 5.36 0 0 0 5.53 5.47c1.75 0 3.03-.32 3.73-.7v-4.33c-.68.28-4.05 1.26-4.05-1.9v-7.57h4.05V7.1H19.8l.02-4.63zM5.4 12.52c0-.83.68-1.15 1.81-1.15 1.62 0 3.67.5 5.3 1.37V7.72c-1.78-.7-3.53-.98-5.3-.98C2.88 6.74 0 9 0 12.78c0 5.89 8.1 4.95 8.1 7.49 0 .98-.85 1.3-2.04 1.3-1.77 0-4.03-.73-5.83-1.7v5.07c1.99.85 4 1.21 5.83 1.21 4.44 0 7.49-2.2 7.49-6.01-.02-6.36-8.15-5.23-8.15-7.62z"
              />
            </svg>
          </div>
        </template>
        <template #subtitle>
          <div
            class="text-sm mt-2"
            :class="[{
              'text-gray-500': !$useNewLayout
            }]"
          >
            <div>
              {{
                $t(`In order to accept payments via stripe, you have to create a stripe account and provide the API keys from Stripe.`)
              }}
            </div>
            <a
              href="https://support.stripe.com/questions/locate-api-keys-in-the-dashboard"
              class="cursor-pointer select-none text-sm underline"
              target="_blank"
              rel="noopener"
            >
              {{ $t('Locate Stripe API keys') }}
            </a>
            -
            <BaseTutorialLink
              name="stripe"
            />
          </div>
        </template>
      </BaseFormHeader>
    </template>
    <template #default="{ meta, errors }">
      <BaseInput
        v-model="model.public_key"
        v-focus="100"
        :label="$t('Public Key')"
        :placeholder="$t('Enter public key...')"
        :name="$t('Public Key')"
        layout="horizontal"
        rules="required"
        id="name"
      />
      <BaseInput
        v-model="model.secret_key"
        :label="$t('Secret Key')"
        :placeholder="$t('Enter secret key...')"
        :name="$t('Secret Key')"
        :info-text="$t('The secret key will be encrypted and you will no longer be able to see it after saving it. You can however update your secret key by providing another one.')"
        autocomplete="off"
        layout="horizontal"
        rules="required"
        id="name"
      />
      <BaseInput
        :label="$t('Mode')"
        :info-text="$t(`Stripe has 2 sets of keys, one for testing purposes and one for real payments. The mode will detect the provided keys and show the mode for stripe payments.`)"
        layout="horizontal"
      >
        <div
          class="relative rounded-md mt-2 sm:col-span-3"
          :class="{
            'text-yellow-600': isTestMode,
            'text-green-600': isLiveMode,
            'text-red-600': !isLiveMode && !isTestMode,
          }"
        >
          <template v-if="isTestMode">
            <BaseTooltip
              :content="$t('In test mode, you can make test payments by using test cards. Real credit cards will be ignored.')">
              <span>{{ $t('Test') }}</span>
            </BaseTooltip>
          </template>
          <template v-else-if="isLiveMode">
            <BaseTooltip :content="$t('In live mode, you can make only real payments with real credit cards.')">
              {{ $t('Live') }}
            </BaseTooltip>
          </template>
          <template v-else-if="!isStripeEnabled">
            {{ $t('Not configured') }}
          </template>
          <template v-else>
            {{ $t('Wrong keys provided') }}
          </template>
        </div>
      </BaseInput>
    </template>
    <template #actions="{ meta, errors }">
      <div class="flex justify-end">
        <template v-if="isStripeEnabled">
          <BaseButton
            variant="white"
            hover-variant="danger"
            class="mr-2 whitespace-nowrap"
            :loading="deleteInProgress"
            @click="confirmDelete"
          >
            {{ $t('Delete Keys') }}
          </BaseButton>
        </template>
        <template v-else>
          <BaseButton
            variant="white"
            @click="$router.push('/account-settings')"
          >
            {{ $t('Cancel') }}
          </BaseButton>
          <BaseButton
            :loading="loading"
            :disabled="!meta.valid"
            type="submit"
            class="ml-2"
          >
            {{ $t('Save') }}
          </BaseButton>
        </template>
      </div>
    </template>
  </BaseEntityForm>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      formKey: 1,
      model: {
        public_key: '',
        secret_key: '',
        provider: 'stripe',
      },
      deleteInProgress: false
    }
  },
  computed: {
    stripePaymentDetails() {
      return this.$store.getters['accounts/stripePaymentDetails']
    },
    isStripeEnabled() {
      return this.$store.getters['accounts/isStripeEnabled']
    },
    isTestMode() {
      return this.model.public_key?.includes('pk_test')
    },
    isLiveMode() {
      return this.model.public_key?.includes('pk_live')
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        if (this.model.id) {
          await this.$store.dispatch('accounts/editPaymentDetails', this.model)
        } else {
          await this.$store.dispatch('accounts/createPaymentDetails', this.model)
        }
        await this.getPaymentDetails()
        this.model.secret_key = ''
        this.formKey++
        this.$success(this.$t('Stripe API Keys were saved successfully'))
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not save payment details'))
      } finally {
        this.loading = false
      }
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t("Delete Stripe Keys"),
        description: this.$t(
          "Are you sure? This will permanently delete currently configured Stripe keys."
        ),
      });

      if (!confirmed) {
        return;
      }

      try {
        this.deleteInProgress = true
        await this.$store.dispatch('accounts/deletePaymentDetails', this.stripePaymentDetails?.id)
        await this.getPaymentDetails()
        this.formKey++
        this.$success(this.$t('Successfully deleted Stripe keys!'))
      }
      catch (err) {
        this.$error(this.$t('Could not delete Stripe Keys'))
      }
      finally {
        this.deleteInProgress = false
      }
    },
    async getPaymentDetails() {
      await this.$store.dispatch('accounts/getPaymentDetails')
    },
    tryInitForm(value) {
      this.model = {
        ...this.model,
        public_key: value?.attributes.public_key || '',
        secret_key: value?.attributes.secret_key || '',
        id: value?.id,
      }
    }
  },
  created() {
    this.getPaymentDetails()
  },
  watch: {
    stripePaymentDetails: {
      immediate: true,
      handler(value) {
        this.tryInitForm(value)
      }
    }
  }
}
</script>
