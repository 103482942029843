<template>
  <BaseEntityForm
    :title="$t('Create Reference')"
    :subtitle="$t('A reference is a link to another item within your account')"
    :isDialogForm="true"
    width-class="max-w-2xl"
    @submit="onSubmit"
  >
    <template #default="{ meta, errors }">
      <BaseInput
        :modelValue="model.project_id"
        :name="$t('Project')"
        :label="$t('Project')"
        layout="horizontal"
        rules="required"
        id="project"
        class="mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <ProjectSelect
            v-model="model.project_id"
            :url-params="{
              related: 'tools,notes',
            }"
            :initialValue="currentProject"
            :placeholder="$t('Choose project...')"
            @raw-change="onProjectChange"
          />
        </div>
      </BaseInput>

      <BaseInput
        :modelValue="model.type"
        :name="$t('Tool')"
        :label="$t('Tool')"
        layout="horizontal"
        rules="required"
        id="tool"
        class="mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <BaseSelect
            v-model="selectedType"
            :disabled="!model.project_id"
            :options="referenceOptions"
            :placeholder="$t('Choose tool...')"
            @update:modelValue="onToolChange"
          />
        </div>
      </BaseInput>

      <BaseInput
        :modelValue="model.item_id"
        :name="$t('Item')"
        :label="$t('Item')"
        layout="horizontal"
        rules="required"
        id="item_id"
        class="mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <EntitySelect
            v-if="selectedType?.url"
            v-model="model.item_id"
            :disabled="!model.project_id || !selectedType"
            :url="selectedType?.url"
            :url-params="{
              project_id: model.project_id,
            }"
            :labelKey="selectedType?.labelKey || undefined"
            :placeholder="$t('Choose item...')"
            @raw-change="onItemChange"
          />

          <BaseSelect
            v-else
            v-model="model.item_id"
            :disabled="!model.project_id || !selectedType"
            :options="selectedType?.options"
            :placeholder="$t('Choose item...')"
            @update:modelValue="onItemChange"
          />
        </div>
      </BaseInput>

      <BaseInput
        v-model="model.name"
        :label="$t('Name')"
        :placeholder="$t('Enter reference name...')"
        :errorMessage="errors[$t('Reference Name')]"
        :name="$t('Name')"
        layout="horizontal"
        rules="required"
        id="name"
      />
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$emit('cancel')">
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        :disabled="!meta.valid"
        type="submit"
        class="ml-2"
      >
        {{ $t('Save') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script setup>
import { get } from 'lodash-es'
import { reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import i18n from "@/i18n.js";

import ProjectSelect from "@/components/selects/ProjectSelect.vue";
import BaseSelect from "@/components/form/BaseSelect.vue";
import EntitySelect from "@/components/form/EntitySelect.vue";
import { referenceTypes } from "@/components/html/util/referenceUtils.js";
import * as FileUtils from "@/modules/common/utils/fileUtils.js";
import { notesMainPath } from '@/modules/projects/utils/noteUtils'

const store = useStore()
const route = useRoute()

const props = defineProps({
  reference: {
    type: Object,
    default: () => null
  },
})

const emit = defineEmits(['save'])

const model = reactive({
  project_id: route.params.id || '',
  type: '',
  item_id: null,
  name: props.reference?.name || '',
  url: null,
})

const selectedType = ref(null)
const selectedProject = ref(null)

const currentProject = computed(() => {
  return store.state.projects.currentProject
})

const projectId = computed(() => {
  return model.project_id
})

const activeProject = computed(() => {
  return selectedProject.value || currentProject.value
})

const projectTools = computed(() => {
  const tools = activeProject.value?.relationships?.tools || []
  return tools.map(t => t.attributes.name)
})


const projectNotes = computed(() => {
  return activeProject.value?.relationships?.notes.map((el) => ({
    label: el.attributes.name,
    value: el.id
  })) || []
})

const referenceOptions = computed(() => {
  const options = [
    {
      label: i18n.t('Tasks'),
      value: referenceTypes.Task,
      url: `/restify/tasks`,
      labelKey: 'attributes.name',
      itemUrl: (item) => {
        return `/tasks/${item.id}`
      },
      renderIf: () => projectTools.value.includes('Tasks'),
    },
    {
      label: i18n.t('Files'),
      value: referenceTypes.File,
      url: '/restify/media',
      labelKey: 'attributes.name',
      itemUrl: (item) => {
        return FileUtils.createFileURL(item?.attributes)
      },
      renderIf: () => projectTools.value.includes('Files'),
    },
    {
      label: i18n.t('Proofs'),
      value: referenceTypes.File,
      url: '/restify/proofs',
      labelKey: 'attributes.name',
      itemUrl: (item) => {
        return `/projects/${projectId.value}/file-proofs/${item?.id}`
      },
      renderIf: () => projectTools.value.includes('File Proofing'),
    },
    {
      label: i18n.t('Embeds'),
      value: referenceTypes.Embed,
      url: '/restify/project-embeds',
      labelKey: 'attributes.name',
      itemUrl: (item) => {
        return `/projects/${projectId.value}/embeds/${item.id}`
      },
      renderIf: () => projectTools.value.includes('Embed'),
    },
    {
      label: i18n.t('Discussion'),
      value: referenceTypes.Discussion,
      options: [
        {
          label: i18n.t('Discussion Page'),
          value: i18n.t('Discussion Page'),
        },
      ],
      labelKey: 'value',
      itemUrl: () => {
        return `/projects/${projectId.value}/discussion`
      },
      renderIf: () => projectTools.value.includes('Discussion'),
    },
    {
      label: i18n.t('Notes'),
      value: referenceTypes.Note,
      options: projectNotes.value,
      itemUrl: (item) => `/${notesMainPath.value}/${projectId.value}/notes/${item?.value}`,
      renderIf: () => projectTools.value.includes('Note'),
    },
    {
      label: i18n.t('Time Entries'),
      value: referenceTypes.Time,
      options: [
        {
          label: i18n.t('Time Entries Page'),
          value: i18n.t('Time Entries Page'),
        },
      ],
      labelKey: 'value',
      itemUrl: () => {
        return `/projects/${projectId.value}/time/actual`
      },
      renderIf: () => projectTools.value.includes('Time'),
    },
    {
      label: i18n.t('Payments'),
      value: referenceTypes.Payment,
      options: [
        {
          label: i18n.t('Payments Page'),
          value: i18n.t('Payments Page'),
        },
      ],
      labelKey: 'value',
      itemUrl: () => {
        return `/projects/${projectId.value}/payments/list`
      },
      renderIf: () => projectTools.value.includes('Payments'),
    },
  ]
  return options.filter(o => o.renderIf())
})

async function onSubmit() {
  model.id = model.item_id
  emit('save', model)
}

const referenceForm = ref()

function onProjectChange(project) {
  selectedProject.value = project
  model.item_id = null
  model.url = null
  referenceForm.value?.resetForm()
}

function onToolChange(option) {
  model.type = option?.value
  model.url = null
  model.item_id = null
  if (option?.options?.length === 1 ) {
    const item = option.options[0]
    model.item_id = item?.value
    onItemChange(item)
  }
  referenceForm.value?.resetForm()
}

function onItemChange(item) {
  if (!item) {
    return
  }
  const name = get(item, selectedType.value?.labelKey)
  const projectName = selectedProject?.value?.attributes?.name

  if (!props.reference.name) {
    model.name = name
  }
  if (projectId.value !== currentProject.value?.id && projectName && !props.reference.name) {
    model.name = `${projectName} - ${name}`
  }
  model.url = selectedType.value?.itemUrl(item)
}

</script>
