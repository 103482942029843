import i18n from "@/i18n"
import store from "@/store";
import { capitalize } from "lodash-es";
import { entityTypes } from "@/modules/common/enum/entityTypes"
import { BindingType } from "@/modules/ai/types/aiTypes";
import { formatDate } from "@/modules/common/utils/dateUtils";
import { coreTools } from "@/modules/projects/utils/toolUtils";

import Data = API.Data
import User = App.Domains.Users.Models.User;
import Group = App.Domains.Groups.Models.Group;
import Project = App.Domains.Projects.Models.Project;
import Task = App.Domains.Tasks.Models.Task;

export type BindingResult = {
  label?: string;
  value: any;
  success: boolean;
  query: string;
  error?: any;
  clearable?: boolean;
  type: BindingType,
  extraParams?: any,
}

function getProjectStatusId(status: string) {
  const statusOptions = store.getters['projects/orderedStatuses']
  return statusOptions.find((s: any) => s.attributes.name.toLowerCase() === status.toLowerCase())?.id
}

function getTaskStatusId(status: string) {
  const statusOptions = store.getters['tasks/orderedStatuses']
  return statusOptions.find((s: any) => s.attributes.name.toLowerCase() === status.toLowerCase())?.id
}

function getProjectToolId(toolName: string) {
  // @ts-ignore
  const tools = store.state.projects.tools.data;

  const tool = coreTools.find((t: any) => t.name === toolName)

  return tools.find((t: any) => t.attributes.name === (tool?.value || toolName))?.id
}


function getUsers(name: string) {
  // @ts-ignore
  const users = store.state.users.allUsers

  name = name.toLowerCase()

  const directNameMatch = users.filter((u: Data<User>) => u.attributes.name?.toLowerCase() === name)

  if (directNameMatch.length) {
    return directNameMatch
  }

  return users.filter((u: Data<User>) => u.attributes.first_name?.toLowerCase() === name || u.attributes.last_name?.toLowerCase() === name)
}


function getGroups(groupName: string) {
  // @ts-ignore
  const groups = store.state.groups.allGroups
  groupName = groupName.toLowerCase()

  return groups.filter((g: Data<Group>) => g.attributes.name.toLowerCase() === groupName)
}

function getProjects(projectName: string) {
  // @ts-ignore
  const projects = store.state.projects.allProjects
  projectName = projectName.toLowerCase()

  return projects.filter((p: Data<Project>) => p.attributes.name.toLowerCase() === projectName)
}

function getTasks(taskName: string, project_id?: number) {
  // @ts-ignore
  const tasks = store.state.tasks.allTasks
  taskName = taskName.toLowerCase()

  return tasks.filter((t: Data<Task>) => t.attributes.name.toLowerCase() === taskName && (!project_id || t.attributes.project_id == project_id))
}

const binders: Record<BindingType, (query: string, extraParams?: any) => BindingResult> = {
  raw: (query: string) => {
    return {
      value: query,
      success: true,
      query,
      type: 'raw',
    }
  },
  capitalize: (query: string) => {
    return {
      value: query,
      success: true,
      query: capitalize(query),
      type: 'capitalized',
    }
  },
  date: (query: string) => {
    return {
      value: query,
      success: true,
      query: formatDate(query),
      type: 'date',
    }
  },
  [entityTypes.Group]: (query: string) => {
    const groups = getGroups(query)
    if (groups.length > 1) {
      return {
        value: groups,
        success: false,
        query,
        error: i18n.t('Multiple matching groups found'),
        type: entityTypes.Group
      }
    }

    if (groups.length === 0) {
      return {
        value: null,
        success: false,
        query,
        error: i18n.t('No matching groups found'),
        type: entityTypes.Group
      }
    }

    const group = groups[0]
    return {
      value: group.id,
      success: true,
      query: group.attributes.name,
      type: entityTypes.Group
    }
  },
  [entityTypes.User]: (query: string) => {
    const users = getUsers(query)
    if (!users.length) {
      return {
        value: null,
        success: false,
        query,
        error: i18n.t('No matching users found'),
        type: entityTypes.User
      }
    }

    if (users.length > 1) {
      return {
        value: users,
        success: false,
        query,
        error: i18n.t('Multiple matching users found'),
        type: entityTypes.User
      }
    }

    const user = users[0]
    return {
      value: user.id,
      success: true,
      query: user.attributes.name,
      type: entityTypes.User
    }
  },
  [entityTypes.Project]: (query: string) => {
    const projects = getProjects(query)
    if (!projects.length) {
      return {
        value: null,
        success: false,
        query,
        error: i18n.t('No matching projects found'),
        type: entityTypes.Project
      }
    }

    if (projects.length > 1) {
      return {
        value: projects,
        success: false,
        query,
        error: i18n.t('Multiple matching projects found'),
        type: entityTypes.Project
      }
    }

    const project = projects[0]
    return {
      value: project.id,
      success: true,
      query: project.attributes.name,
      type: entityTypes.Project
    }
  },
  [entityTypes.Task]: (query: string, extraParams?: any) => {
    const project_id = extraParams?.project_id
    const tasks = getTasks(query, project_id)

    if (!tasks.length) {
      return {
        value: null,
        success: false,
        query,
        extraParams,
        error: i18n.t('No matching tasks found'),
        type: entityTypes.Task,
      }
    }

    if (tasks.length > 1) {
      return {
        value: tasks,
        success: false,
        query,
        extraParams,
        error: i18n.t('Multiple matching tasks found'),
        type: entityTypes.Task
      }
    }

    const task = tasks[0]
    return {
      value: task.id,
      success: true,
      query: task.attributes.name,
      extraParams,
      type: entityTypes.Task
    }
  },
  [entityTypes.ProjectStatus]: (query: string) => {
    return {
      value: getProjectStatusId(query),
      success: true,
      query: capitalize(query),
      type: entityTypes.ProjectStatus,
    }
  },
  [entityTypes.ProjectTool]: (query: string) => {
    return {
      value: getProjectToolId(query),
      success: true,
      query,
      type: entityTypes.ProjectTool,
    }
  },
  [entityTypes.TaskStatus]: (query: string) => {
    return {
      value: getTaskStatusId(query),
      success: true,
      query: capitalize(query),
      type: entityTypes.TaskStatus,
    }
  },
}

export class DataBinder {
  static bind(bindingType: BindingType, query: string, extraParams?: any): BindingResult {
    try {
      return binders[bindingType](query, extraParams)
    }
    catch (e) {
      return {
        value: null,
        success: false,
        query,
        error: e,
        type: bindingType
      }
    }
  }
}
