<template>
  <div>
    <div class="relative lg:inline-block text-left sm:w-full mt-2 lg:mt-0 lg:w-min">
      <TopFilterChip
        :label="$t('Filter')"
        :isActive="filterByFilter.length"
        @click="openFilterDialog"
      />
    </div>
    <BaseDialogNew
      v-if="filterDialogVisible"
      v-model="filterDialogVisible"
      class="max-w-xl"
    >
      <BaseFormHeader
        :isDialogForm="true"
        :title="title || $t('Filter')"
      />

      <div class="filter-by-form p-6 space-y-5 sm:space-y-3">
        <BaseInput
          v-for="(column, i) in columnsInDropdown"
          :key="column.prop"
          :id="column.filterBy.prop"
          layout="horizontal"
        >
          <template #label>
          <span class="block text-sm font-medium text-gray-700">
            {{ column.filterBy.label || (column.name) }}
          </span>
          </template>
          <FilterByOptionInput
            v-model="filterByModel[column.filterBy.prop]"
            v-bind="{
              ...column.filterBy.props,
              urlParams: {
                ...column.filterBy?.props?.urlParams,
                project_id: $store.getters.project_id
              }
            }"
            v-focus="i === 0 && !filterByFilter.length"
            :initialValue="rawValues[column.filterBy.prop]"
            :column="column"
            :target="target"
            :filterBy="true"
            @raw-change="onRawValueChanged(column.filterBy.prop, $event)"
            @keyup.enter.stop="applyFilters"
          />
        </BaseInput>
      </div>

      <div class="px-6 py-4 mt-6 border-t border-gray-200 sticky w-full bottom-0 bg-white z-10">
        <div class="flex justify-end">
          <BaseButton
            variant="white"
            @click="filterDialogVisible = false"
          >
            {{ $t('Cancel') }}
          </BaseButton>
          <BaseButton
            type="submit"
            class="ml-2"
            @click="applyFilters"
          >
            {{ $t('Apply') }}
          </BaseButton>
        </div>
      </div>
    </BaseDialogNew>
  </div>
</template>
<script>
// Components
import FilterByOptionInput from "@/modules/filters/components/FilterByOptionInput.vue"
import TopFilterChip from "@/modules/filters/components/TopFilterChip.vue"

// Helpers
import { formatTimeSpent } from "@/modules/common/utils/dateUtils";
import { getEntityCompactValue } from "@/modules/common/utils/entityUtils";

export default {
  components: {
    FilterByOptionInput,
    TopFilterChip
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    defaultFilter: {
      type: Object,
      default: () => []
    },
    filterByFilter: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    }
  },
  emits: ['filter-changed'],
  data() {
    return {
      filterDialogVisible: false,
      filterByModel: {},
      rawValues: {},
      formatters: {
        formatDate: this.$formatDate,
        formatDateRangeValue: (v, format) => {
          return {
            min: this.formatters.formatDate(v.min, format),
            max: this.formatters.formatDate(v.max, format),
          }
        },
        formatDateRange: (v, format) => {
          return `${this.formatters.formatDate(v.min, format)} - ${this.formatters.formatDate(v.max, format)}`
        },
        formatTimeSpentRange: (v) => {
          return `${formatTimeSpent(v.min)} - ${formatTimeSpent(v.max)}`

        },
        capitalize(v) {
          return `<span class="capitalize">${v}</span>`
        },
        formatId(value) {
          return value.id
        },
        formatValue(value) {
          return value.value
        },
        formatLabel(value) {
          return value.label
        },
        formatColorOption(value) {
          return {
            ...value,
            hasColor: true
          }
        },
        formatIdsArray(value) {
          if (!value) {
            return []
          }

          return value.map(this.formatId)
        },
        formatColorOptions(value) {
          if (!value) {
            return []
          }

          return value.map(this.formatColorOption)
        },
        formatLabelsArray(value) {
          if (!value) {
            return ''
          }
          return value.map(x => x.label).join(', ')
        }
      }
    }
  },
  computed: {
    columnsInDropdown() {
      return this.columns.filter(c => c.filterBy && !c.filterBy.hidden)
    },
    filterBy: {
      get() {
        return this.filterByFilter.length ? this.filterByFilter : this.defaultFilter
      },
      set(value) {
        this.$emit('filter-changed', value)
      }
    }
  },
  methods: {
    applyFilters() {
      this.filterDialogVisible = false
      const newFilter = [ ...this.filterBy ]

      for (const column of this.columnsInDropdown) {
        this.updateColumnFilter(column, newFilter);
      }

      this.filterBy = newFilter
    },
    updateColumnFilter(column, newFilter) {
      let value = this.filterByModel[column.filterBy.prop]

      if (typeof value === 'string') {
        value = value.trim()
      }

      if (column.filterBy.props?.multiple && !value?.length) {
        value = null
      }

      const existingIdx = newFilter.findIndex(x => x.column === column.filterBy.prop);
      if (!value) {
        if (existingIdx !== -1) {
          newFilter.splice(existingIdx, 1)
        }
        return;
      }
      let rawValue = this.rawValues[column.filterBy.prop] || value;
      let displayValue = rawValue?.attributes?.name || rawValue?.attrributes?.title || value

      if (column.filterBy.props?.multiple && !column.filterBy.displayFormat) {
        displayValue = (rawValue?.map(x => x.label || x.attributes?.name || x.attributes?.title || '') || []).join(', ')
      }

      if (column.filterBy.format) {
        if (typeof column.filterBy.format === 'function') {
          value = column.filterBy.format(value)
        }
        else {
          const [ funct, format ] = column.filterBy.format.split('::')
          value = this.formatters[funct](value, format)
        }
      }
      if (column.filterBy.displayFormat) {
        if (typeof column.filterBy.displayFormat === 'function') {
          displayValue = column.filterBy.displayFormat(displayValue)
        }
        else {
          const [ funct, format ] = column.filterBy.displayFormat.split('::')
          displayValue = this.formatters[funct](displayValue, format)
        }
      }

      if (existingIdx !== -1) {
        newFilter[existingIdx].query = value
        newFilter[existingIdx].rawValue = rawValue
        newFilter[existingIdx].displayValue = displayValue
      }
      else {
        newFilter.push({
          name: column.filterBy.label || column.name,
          column: column.filterBy.prop,
          query: value,
          rawValue,
          displayValue
        })
      }
    },
    syncModel() {
      const filterByModel = {}, rawValues = {}
      for (const filter of this.filterByFilter) {
        if (filter.hidden) {
          continue
        }
  
        filterByModel[filter.column] = filter.query
        rawValues[filter.column] = filter.rawValue
      }
      this.filterByModel = filterByModel
      this.rawValues = rawValues
    },
    openFilterDialog() {
      this.syncModel()
      this.filterDialogVisible = true
    },
    async openFilter(filter) {
      this.openFilterDialog()
      await this.$nextTick()
      
      const selector = `#${filter.column} input`
      const input = this.$el.querySelector(selector)
      
      if (input) {
        input.focus()
      }
    },
    onRawValueChanged(prop, value) {
      if (Array.isArray(value)) {
        this.rawValues[prop] = value.map(getEntityCompactValue)
        return
      }

      this.rawValues[prop] = getEntityCompactValue(value)
    }
  },
  created() {
    this.syncModel()
  }
}
</script>
