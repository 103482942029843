<template>
  <BaseNoDataSection
    :title="$t('No Groups Found')"
    iconClass="fa-ball-pile"
    :canCreate="can($actions.CREATE_GROUPS)"
  >
    <template #can-create-section>
      <div>{{ $t('Create groups to organize your projects.') }}</div>
      <div>
        <router-link
          to="/groups/add"
          class="hover:text-primary-500"
        >
          {{ $t('Create a new group') }}
          <i class="fa-solid fa-arrow-right" aria-hidden="true" />
        </router-link>
      </div>
      <div>
        <BaseEntityImportLink
          :entityType="importEntityTypes.Groups"
        />
      </div>
      <div>
        <div class="mt-4 p-6 text-sm text-gray-400 bg-gray-50 border border-gray-200 rounded-lg space-y-2">
          <h4 class="text-xl font-bold text-gray-500">{{ $t('What are groups for?') }}</h4>
          <p>{{ $t('Groups let you combine people with a common interest such as a company, team or department so you can see all their work together rather than person-by-person.') }}</p>
          <ul class="space-y-1">
            <li
              v-for="usage of groupUsages"
              :key="usage"
              class="text-left sm:pl-12"
            >
              <i class="fa-sharp fa-solid fa-circle-check mr-2 text-primary-500 text-lg align-middle" />
              <span>{{ usage  }}</span>
            </li>
          </ul>
          <p>
            <strong>{{ $t('Best practive advice:') }}</strong>
            {{ $t('Create a group for each team in your business and also for each client company you work with.') }}
          </p>
          <div>
            <BaseTutorialLink
              name="groups"
            >
              <span class="hover:text-primary-500">
                {{ $t('Click here for more information.')}}
              </span>
            </BaseTutorialLink>
          </div>
        </div>
      </div>
    </template>
  </BaseNoDataSection>
</template>
<script lang="ts" setup>
import i18n from "@/i18n"
import {
  importEntityTypes,
} from "@/modules/accounts/utils/importUtils"

const groupUsages = [
  i18n.t('Create groups for departments and client companies.'),
  i18n.t('Filter projects, tasks, payments & time by group.'),
  i18n.t('People in the same group can see each other.'),
  i18n.t('Collaborators can invite people to their own group.'),
]   
</script>
