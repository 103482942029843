<template>
  <div
    class="base-avatar"
    :class="{
      [$attrs.class]: $attrs.class,
      [sizeClass]: sizeClass,
      'flex items-center justify-start': isEmoji,
      'cursor-pointer': clickable,
    }"
  >
    <div v-if="isEmoji">
      {{ formattedAvatar }}
    </div>
    <img
      v-else
      :src="avatar"
      :alt="$t('User avatar')"
      class="object-cover w-full h-full"
      :class="{
        [`rounded-${rounded}`]: true,
        [sizeClass]: sizeClass,
      }"
    />
  </div>
</template>
<script>
import { decodeEmoji } from "@/modules/common/utils/emojiUtils"
export default {
  name: 'BaseAvatar',
  props: {
    avatar: {
      type: String,
      default: ''
    },
    isEmojiEncoded: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    },
    clickable: {
      type: Boolean,
      default: true
    },
    emojiClass: {
      type: String,
      default: ''
    },
    rounded: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    isEmoji() {
      if (
        !this.avatar
        ||
        this.avatar.startsWith('http')
        ||
        this.avatar.startsWith('data:image/')
        ||
        this.avatar.startsWith('www.')
        ||
        this.avatar.startsWith('/img')
      ) {
        return false
      }

      return true
    },
    formattedAvatar() {
      if (this.isEmojiEncoded && this.isEmoji) {
          return decodeEmoji(this.avatar)
      }
      return this.avatar
    },
    sizeClass() {
      if (this.isEmoji && this.emojiClass) {
        return this.emojiClass
      }

      const sizeClasses = {
        xs: 'h-5 w-5 text-xs',
        sm: 'h-8 w-8 text-2xl',
        md: 'h-10 w-10 text-4xl',
        lg: 'h-14 w-14 text-5xl',
        xlg: 'h-16 w-16 text-5xl',
        xl: 'h-20 w-20 text-6xl',
        xxl: 'h-24 w-24 text-7xl',
        full: 'h-full w-full text-9xl',
        'xlg sm:xl': 'h-16 w-16 text-5xl sm:h-20 sm:w-20 sm:text-6xl'
      }

      return sizeClasses[this.size]
    }
  }
}
</script>
