<template>
  <BaseNoDataSection
    :title="$t('No File Proofs Found')"
    iconClass="fa-files"
    :canCreate="!isCurrentProjectClosed && can($actions.CREATE_PROJECT_FILE_PROOFS)"
  >
    <template #can-create-section>
      <div>{{ $t('Review and annotate any file type.') }}</div>
      <div>{{ $t('Track comments & get approvals from people.') }}</div>
      <div
        class="hover:text-primary-500 cursor-pointer"
        @click.stop="createProof"
      >
        {{ $t('Create a new file proof') }}
        <i class="fa-solid fa-arrow-right" aria-hidden="true" />
      </div>
    </template>
  </BaseNoDataSection>
</template>
<script>
export default {
  computed: {
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    createProof() {
      return this.$router.replace({
        query: {
          ...this.$route.query,
          'create-from-file': 'new',
        },
      })
    },
  },
}
</script>
