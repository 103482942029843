<template>
  <div class="inline max-w-0 basis-0">
    <ElDialog
      v-bind="$attrs"
      :fullscreen="fullscreen"
      :append-to-body="true"
      :lock-scroll="true"
      :align-center="true"
      :show-close="showClose"
    >
      <template #header>
        <slot name="header">
          <span />
        </slot>
      </template>
      <slot />
    </ElDialog>
  </div>
</template>
<script>
import { ElDialog } from "element-plus"
import { useWindowSize } from '@vueuse/core'

export default {
  name: 'BaseDialogNew',
  props: {
    showFullscreen: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ElDialog
  },
  setup() {
    const { width } = useWindowSize()
    return {
      width
    }
  },
  computed: {
    fullscreen() {
      return this.showFullscreen || this.width < 768
    }
  }
}
</script>
