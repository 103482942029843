<template>
  <div
    v-bind="$attrs"
    class="fixed sm:px-4 sm:pb-4 inset-0 grid place-items-center dialog-wrapper"
    ref="dialogWrapper"
    @keydown.esc="onClose"
  >
    <transition enter-active-class="ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-class="opacity-0"
                leave-to-class="opacity-100"
                appear
    >
      <div v-if="showModal"
           class="fixed inset-0 top-0 transition-opacity">
        <div @click="onClose"
             class="absolute inset-0 bg-gray-500 opacity-75">
        </div>
      </div>
    </transition>

    <transition enter-active-class="ease-out duration-300"
                enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                leave-active-class="ease-out duration-200"
                leave-class="opacity-100 translate-y-0 sm:scale-100"
                leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                appear
                @after-enter="onAfterEnter"
                @after-leave="onCloseFinished"
    >
      <div v-if="showModal"
           class="dialog-container relative bg-white rounded-lg shadow-xl transition-all sm:w-full"
           :class="{
              'overflow-hidden overflow-y-auto': !overflowVisible,
              'overflow-visible': overflowVisible,
              'px-4 pt-5 pb-4 sm:p-6': hasBodyMargin,
              'sm:max-w-lg': size === 'md',
              'sm:max-w-xl': size === 'xl',
              'sm:max-w-2xl': size === 'lg',
              'sm:max-w-4xl': size === 'xlg',
              'sm:max-w-full': size === 'full'
           }"
           role="dialog"
           aria-modal="true"
           aria-labelledby="modal-headline">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <CloseButton @click="onClose"/>
        </div>
        <div>
          <slot />
        </div>
        <div class="el-dialog__footer flex justify-center bg-gray-100 border-t border-grey-light"
             v-if="$slots.footer">
          <slot name="footer" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import i18n from "@/i18n";
import CloseButton from "@/components/common/buttons/CloseButton.vue";
import { getMaxZIndex } from "@/modules/common/utils/documentUtils";

export default {
  name: "BaseDialog",
  inheritAttrs: false,
  components: {
    CloseButton,
  },
  props: {
    modelValue: Boolean,
    title: {
      type: String,
      default: i18n.t('Delete Information'),
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    hasBodyMargin: {
      type: Boolean,
      default: false
    },
    overflowVisible: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: i18n.t('Are you sure ? This will permanently remove the information you are about to delete.'),
    },
    deleteText: {
      type: String,
      default: i18n.t('Delete'),
    },
    cancelText: {
      type: String,
      default: i18n.t('Cancel'),
    },
    type: {
      type: String,
      default: 'danger'
    },
    size: {
      type: String,
      default: 'md'
    },
    freezeScroll: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'delete', 'close', 'before-close'],
  data() {
    return {
      showModal: this.modelValue
    }
  },
  methods: {
    onDelete() {
      this.showModal = false
      this.$emit('delete')
    },
    onClose() {
      this.$emit('before-close')
      this.showModal = false
    },
    onCloseFinished() {
      this.$emit('update:modelValue', false)
      this.$emit('close', false)
    },
    onAfterEnter() {
      if (!this.$refs.cancel) {
        return
      }
      this.$refs.cancel.focus()
    },
    updateZIndex() {
      const maxZIndex = getMaxZIndex('.dialog-wrapper')
      const maxZIndexOverlay = getMaxZIndex('.el-overlay')

      const maxZ = Math.max(maxZIndex || 0, maxZIndexOverlay || 0)

      const currentZIndex = this.$refs.dialogWrapper.style.zIndex;

      if (!currentZIndex || currentZIndex != maxZ) {
        this.$refs.dialogWrapper.style.zIndex = maxZ + 1
      }
    }
  },
  watch: {
    modelValue(value) {
      this.showModal = value

      if (value) {
        this.updateZIndex()
      }

      if (!this.freezeScroll) {
        return;
      }

      if (!value) {
        document.body.classList.remove('overflow-hidden')
        return
      }

      document.body.classList.add('overflow-hidden')
    }
  },
  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
    document.addEventListener("keyup", event => {
      if (event.code === 'Escape' && this.modelValue) {
        this.onClose()
      }
    })

    this.updateZIndex()
  },
  unmounted() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el?.parentNode) {
      this.$el?.parentNode?.removeChild(this.$el);
    }
  }
}
</script>
<style scoped lang="scss">
.dialog-wrapper {
  z-index: 1001;
}

.dialog-container {
  max-height: 100vh;
  width: 100%;

  @screen sm {
    max-height: 96vh;
  }
}
</style>
