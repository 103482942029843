<template>
  <BaseInput
    :label="$t('Enable Branding')"
    :placeholder="$t('Enable Branding')"
    :name="$t('Enable Branding')"
    class="col-span-6"
    layout="horizontal"
  >
    <BaseSwitch
      v-model="model.enable_branding"
      :disabled="!canUseCustomBranding"
      class="mt-2"
    />
  </BaseInput>

  <h3 class="text-2xl font-bold col-span-3 mt-3">{{ $t('Background') }}</h3>

  <BaseInput
    :label="$t('Background Color')"
    :name="$t('Background Color')"
    :placeholder="$t('Background Color')"
    class="col-span-6"
    layout="horizontal"
  >
    <ColorPicker
      v-model="model.background_color"
      :disabled="!canUseCustomBranding"
    />
  </BaseInput>

  <BaseInput
    v-if="!useNewLayout"
    :info-text="$t(`This image will be used as the background in your account but it won't be used for emails. Set a solid background colour to be used in emails.`)"
    :label="$t('Background Image')"
    :name="$t('Background Image')"
    :placeholder="$t('Background Image')"
    class="col-span-6"
    layout="horizontal"
  >
    <BaseAvatarPicker
      v-model="model.background_image"
      :disabled="!canUseCustomBranding"
      :disabled-options="['group', 'emoji', 'unsplash']"
    >
      <template #activator>
        <img
          v-if="model.background_image"
          :src="model.background_image"
          class="h-14 object-cover rounded-md cursor-pointer"
        />
        <span
          v-else
          class="text-sm text-gray-500 cursor-pointer"
        >
          {{ $t('Click here to choose a background image...') }}
        </span>
      </template>
    </BaseAvatarPicker>
  </BaseInput>

  <h3 class="text-2xl font-bold col-span-3 mt-3">{{ $t('Custom colors') }}</h3>

  <BaseInput
    :label="useNewLayout ? $t('Text color') : $t('Breadcrumb color')"
    :name="useNewLayout ? $t('Text color') : $t('Breadcrumb color')"
    class="col-span-6"
    layout="horizontal"
  >
    <ColorPicker
      v-model="model.breadcrumb_color"
      :disabled="!canUseCustomBranding"
    />
  </BaseInput>

  <BaseInput
    :label="$t('Primary Color')"
    :name="$t('Primary Color')"
    :placeholder="$t('Primary Color')"
    class="col-span-6"
    layout="horizontal"
  >
    <ColorPicker
      v-model="model.action_button_color"
      :disabled="!canUseCustomBranding"
    />
  </BaseInput>

  <BaseInput
    :label="$t('Shadows')"
    :name="$t('Shadows')"
    :placeholder="$t('Shadows')"
    class="col-span-6"
    layout="horizontal"
  >
    <BaseSwitch
      v-model="model.shadow"
      :disabled="!canUseCustomBranding"
    />
  </BaseInput>

  <BaseInput
    :info-text="$t('Make all tool icons on the welcome page and project dashboard the primary colour.')"
    :label="$t('Primary Color Tools')"
    :name="$t('Primary Color Tools')"
    :placeholder="$t('Primary Color Tools')"
    class="col-span-6"
    layout="horizontal"
  >
    <BaseSwitch
      v-model="model.primary_color_tools"
      :disabled="!canUseCustomBranding"
    />
  </BaseInput>

  <h3 class="text-2xl font-bold col-span-3 mt-3">{{ $t('Custom logos') }}</h3>

  <BaseInput
    :label="$t('Header Logo')"
    :placeholder="$t('Header Logo')"
    :name="$t('Header Logo')"
    class="col-span-6"
    layout="horizontal"
  >
    <BaseAvatarPicker
      v-model="model.header_logo"
      size="lg"
      :disabled-options="['group', 'emoji', 'unsplash', 'remove']"
      :disabled="!canUseCustomBranding"
    >
      <template #activator>
        <img
          class="h-14 object-cover rounded-md cursor-pointer"
          :src="model.header_logo"
        />
      </template>
    </BaseAvatarPicker>
  </BaseInput>

  <BaseInput
    :label="$t('Favicon')"
    :placeholder="$t('Favicon')"
    :name="$t('Favicon')"
    class="col-span-6"
    layout="horizontal"
  >
    <BaseAvatarPicker
      v-model="model.favicon"
      size="md"
      :disabled-options="['group', 'emoji', 'unsplash', 'remove']"
      :disabled="!canUseCustomBranding"
    >
      <template #activator>
        <img
          class="h-14 object-cover rounded-md cursor-pointer"
          :src="model.favicon"
        />
      </template>
    </BaseAvatarPicker>
  </BaseInput>

  <BaseInput
    :label="$t('Login Page Logo')"
    :placeholder="$t('Login Page Logo')"
    :name="$t('Login Page Logo')"
    class="col-span-6"
    layout="horizontal"
  >
    <BaseAvatarPicker
      v-model="model.login_page_logo"
      :disabled-options="['group', 'emoji', 'unsplash', 'remove']"
      :disabled="!canUseCustomBranding"
    >
      <template #activator>
        <img
          class="h-14 object-cover rounded-md cursor-pointer"
          :src="model.login_page_logo"
        />
      </template>
    </BaseAvatarPicker>
  </BaseInput>

  <BaseInput
    :label="$t('Email Logo')"
    :placeholder="$t('Email Logo')"
    :name="$t('Email Logo')"
    class="col-span-6"
    layout="horizontal"
  >
    <BaseAvatarPicker
      v-model="model.email_logo"
      :disabled-options="['group', 'emoji', 'unsplash', 'remove']"
      :disabled="!canUseCustomBranding"
      acceptUploadType="image/png, image/jpg, image/jpeg"
    >
      <template #activator>
        <img
          class="h-14 object-cover rounded-md cursor-pointer"
          :src="model.email_logo"
        />
      </template>
    </BaseAvatarPicker>
  </BaseInput>
</template>
<script setup>
import { watch, computed } from 'vue'
import ColorPicker from '@/components/form/ColorPicker.vue'

// Utils
import {
  applyCustomBackground,
  applyCustomPrimaryColor,
  toggleShadow,
  applyPrimaryColorTools,
  clearBranding,
  applyCustomBreadCrumbColor,
} from '@/modules/accounts/utils/accountBrandingUtils.js'

// Composables
import { useStore } from 'vuex'
import actionsEnum from "@/modules/common/enum/actionsEnum";
import useCan from "@/modules/common/composables/useCan";
import useLayout from "@/modules/common/composables/useLayout";

const store = useStore()


const {
  useNewLayout
} = useLayout()

const props = defineProps({
  model: {
    type: Object,
    default: () => ({}),
  },
})

const subscription = computed(() => {
  return store.state.accounts.subscription || {}
})

const { can } = useCan()

const canUseCustomBranding = computed(() => {
  return can(actionsEnum.CUSTOM_BRANDING)
})

const shouldApplyCustomBranding = computed(() => {
  return canUseCustomBranding.value && props.model.enable_branding
})

watch(() => props.model.header_logo, (value) => {
  store.commit('accounts/setTempBranding', {
    enable_branding: props.model.enable_branding,
    header_logo: value,
    favicon: props.model.favicon,
  })
})

watch(() => props.model.favicon, (value) => {
  store.commit('accounts/setTempBranding', {
    enable_branding: props.model.enable_branding,
    header_logo: props.model.header_logo,
    favicon: value,
  })
})

watch(() => props.model.enable_branding, (value) => {
  store.commit('accounts/setTempBranding', {
    enable_branding: value,
    header_logo: props.model.header_logo,
    favicon: props.model.favicon,
  })

  if (!value || !shouldApplyCustomBranding.value) {
    return clearBranding()
  }

  if (props.model.background_image && !useNewLayout.value) {
    applyCustomBackground(props.model.background_image, true)
  } else {
    applyCustomBackground(props.model.background_color)
  }

  applyCustomBreadCrumbColor(props.model.breadcrumb_color)
  applyCustomPrimaryColor(props.model.action_button_color)
  toggleShadow(props.model.shadow)
  applyPrimaryColorTools(props.model.primary_color_tools)
}, { immediate: true })

watch(() => props.model.breadcrumb_color, (value) => {
  if (!shouldApplyCustomBranding.value) {
    return
  }

  applyCustomBreadCrumbColor(value)
}, { immediate: true })

watch(() => props.model.background_color, (value) => {
  if (!shouldApplyCustomBranding.value || (props.model.background_image && !useNewLayout.value)) {
    return
  }

  applyCustomBackground(value)
}, { immediate: true })

watch(() => props.model.background_image, (value) => {
  if (!shouldApplyCustomBranding.value || useNewLayout.value) {
    return
  }

  if (!value) {
    applyCustomBackground(props.model.background_color)
    return
  }

  applyCustomBackground(value, true)

}, { immediate: true })

watch(() => props.model.action_button_color, (value) => {
  if (!value || !shouldApplyCustomBranding.value) {
    return
  }

  applyCustomPrimaryColor(value)

}, { immediate: true })

watch(() => props.model.shadow, (value) => {
  if (!shouldApplyCustomBranding.value) {
    return
  }

  toggleShadow(value)

}, { immediate: true })

watch(() => props.model.primary_color_tools, (value) => {
  if (!shouldApplyCustomBranding.value) {
    return
  }

  applyPrimaryColorTools(value)

}, { immediate: true })
</script>
