import { computed } from 'vue'
import { useStore } from 'vuex'
import i18n from '@/i18n'

export enum GettingStartedKeys {
  AccountCreated = 'account_created',
  FirstProjectCreated = 'first_project_created',
  AccountSetupWizardCompleted = 'account_setup_wizard_completed',
  SecondProjectCreated = 'second_project_created',
  PeopleInvited = 'people_invited',
  TrialFeatureStarted = 'trial_feature_started',
}

export type GettingStartedStep = {
  key: GettingStartedKeys;
  title: string;
  description: string;
  action?: {
    label: string;
    to?: string;
    allowMarkCompleted?: boolean;
    handler?: () => void;
  },
  iconClass: string;
  completed: boolean;
  completedDescription: string;
  completedLink?: {
    label: string;
    to: string;
  },
  videoId?: string;
}

export const defaultCompletionState: Record<GettingStartedKeys, boolean> = {
  [GettingStartedKeys.AccountCreated]: true,
  [GettingStartedKeys.FirstProjectCreated]: false,
  [GettingStartedKeys.AccountSetupWizardCompleted]: false,
  [GettingStartedKeys.SecondProjectCreated]: false,
  [GettingStartedKeys.PeopleInvited]: false,
  [GettingStartedKeys.TrialFeatureStarted]: false,
}

export function useGettingStarted() {
  const store = useStore()

  async function getCompletionState() {
    await store.dispatch('accounts/getGettingStartedCompletionState')
  }

  async function setStepCompleted(step: GettingStartedKeys, completed = true) {
    await store.dispatch('accounts/setGettingStartedStepCompleted', {
      step,
      completed
    })
  }

  const completionState = computed<Record<GettingStartedKeys, boolean>>(() => {
    return store.state.accounts.gettingStartedState || defaultCompletionState
  })
  
  const gettingStartedSteps = computed<GettingStartedStep[]>(() => {
    return [
      {
        key: GettingStartedKeys.AccountCreated,
        title: i18n.t(`Create your account`),
        description: i18n.t(`When you created your account you set your name and password. You also defined your account name and URL so you can access your account at any time.`),
        iconClass: 'fa-grid-2',
        completed: completionState.value[GettingStartedKeys.AccountCreated],
        completedDescription: i18n.t(`This has been done. Update your account details at any time from`),
        completedLink: {
          label: i18n.t(`your account settings.`),
          to: '/account-settings/account'
        },
        videoId: '7jlosvz9hl',
      },
      {
        key: GettingStartedKeys.FirstProjectCreated,
        title: i18n.t(`Create your first project`),
        description: i18n.t(`In Project.co everything is organized into projects. We recommend creating a project for every piece of work you need to do. Each project contains a set of tools to help you get the job done.`),
        action: {
          label: i18n.t(`Create a project`),
          to: '/projects/add'
        },
        iconClass: 'fa-inbox group-hover:text-purple-500',
        completed: completionState.value[GettingStartedKeys.FirstProjectCreated],
        completedDescription: i18n.t(`This has been done.`),
        completedLink: {
          label: i18n.t(`Create another project here.`),
          to: '/projects/add'
        },
        videoId: 'z4a0jetb8s',
      },
      {
        key: GettingStartedKeys.AccountSetupWizardCompleted,
        title: i18n.t(`Complete the account setup wizard`),
        description: i18n.t(`The account setup wizard will help you setup the most important settings in your account. This quick step by step process helps you set up things like location, project and task statuses and more.`),
        action: {
          label: i18n.t(`Begin setup`),
          to: '/account-setup-wizard',
        },
        iconClass: 'fa-stars group-hover:text-yellow-500',
        completed: completionState.value[GettingStartedKeys.AccountSetupWizardCompleted],
        completedDescription: i18n.t(`This has been done. Update your settings at any time from`),
        completedLink: {
          label: i18n.t(`your account settings.`),
          to: '/account-settings'
        },
        videoId: 'h4vud3mq3r',
      },
      {
        key: GettingStartedKeys.SecondProjectCreated,
        title: i18n.t(`Create another project`),
        description: i18n.t(`In Project.co everything is organized into projects. We recommend creating a project for every piece of work you need to do. Each project contains a set of tools to help you get the job done. Create your second project to start tracking your most important work inside your Project.co account.`),
        action: {
          label: i18n.t(`Create project`),
          to: '/projects/add',
        },
        iconClass: 'fa-inbox group-hover:text-purple-500',
        completed: completionState.value[GettingStartedKeys.SecondProjectCreated],
        completedDescription: i18n.t(`This has been done.`),
        completedLink: {
          label: i18n.t(`Create another project here.`),
          to: '/projects/add'
        },
        videoId: 'z4a0jetb8s',
      },
      {
        key: GettingStartedKeys.PeopleInvited,
        title: i18n.t(`Invite people to collaborate with`),
        description: i18n.t(`Project.co is a collaborative tool with discussion, task allocations and file sharing. It works well when used alone but it works best when you invite your team and clients.`),
        action: {
          label: i18n.t(`Invite people`),
          to: '/users/add',
          allowMarkCompleted: true
        },
        iconClass: 'fa-users group-hover:text-red-500',
        completed: completionState.value[GettingStartedKeys.PeopleInvited],
        completedDescription: i18n.t(`This has been done.`),
        completedLink: {
          label: i18n.t(`Invite more people here.`),
          to: '/users/add'
        },
        videoId: 't933vf5cn5',
      },
      {
        key: GettingStartedKeys.TrialFeatureStarted,
        title: i18n.t(`Use the trial manager`),
        description: i18n.t(`The trial manager is a way to start and manage trials of features that aren't included with your current plan. This gives you the ability to test features before you decide to upgrade to the Pro plan.`),
        action: {
          label: i18n.t(`Go to trial manager`),
          to: '/account-settings/trial-manager',
        },
        iconClass: 'fa-business-time group-hover:text-blue-500',
        completed: completionState.value[GettingStartedKeys.TrialFeatureStarted],
        completedDescription: i18n.t(`This has been done. Start a new trial at any time from`),
        completedLink: {
          label: i18n.t(`the trial manager.`),
          to: '/account-settings/trial-manager'
        },
        videoId: 'dt02yq690h',
      }
    ]
  })
  
  const percentageCompleted = computed(() => {
    const completedSteps = gettingStartedSteps.value.filter(step => step.completed).length
    return Math.round((completedSteps / gettingStartedSteps.value.length) * 100)
  })


  return {
    gettingStartedSteps,
    percentageCompleted,
    getCompletionState,
    setStepCompleted
  }
}
