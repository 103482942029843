import i18n from "@/i18n.js";

export const exportTypes = {
  All: 'all',
  CreatedRange: 'created_range',
  CompletedRange: 'completed_range',
}

export const exportEntityTypes = {
  Projects: 'projects',
  Tasks: 'tasks',
  People: 'people',
  Groups: 'groups',
  Payments: 'payments',
  Time: 'time',
}

export const exportStatuses = {
  Pending: 'pending',
  Successful: 'successful',
  Failed: 'failed',
}

export const entityTypeOptions = [
  {
    label: i18n.t('Projects'),
    value: exportEntityTypes.Projects,
  },
  {
    label: i18n.t('Tasks'),
    value: exportEntityTypes.Tasks,
  },
  {
    label: i18n.t('People'),
    value: exportEntityTypes.People,
  },
  {
    label: i18n.t('Groups'),
    value: exportEntityTypes.Groups,
  },
  {
    label: i18n.t('Payments'),
    value: exportEntityTypes.Payments,
  },
  {
    label: i18n.t('Time'),
    value: exportEntityTypes.Time,
  },
]
