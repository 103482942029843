import store from "@/store";
import {
  EntityTargetTypes,
  getSystemDefaultEntityFilters,
  getEntityTarget
} from "@/modules/common/utils/filterUtils"
import { VIEW_TYPES } from "@/modules/users/util/userUtils"

export function getViewType(route) {
  const listExceptions = [
    '/time/actual',
    '/time/allocated',
    '/time/all',
    '/users/archived',
    '/custom-fields',
    '/inbox',
    '/notes'
  ]

  for (const exception of listExceptions) {
    if (route.path?.includes(exception)) {
      return VIEW_TYPES.LIST
    }
  }
  try {
    const pathSplit = route.path?.split('/')
    return pathSplit?.[pathSplit?.length - 1]
  }
  catch (err) {
    return ''
  }
}

async function loadFilterPresets(from) {
  if (store.state.filters.filterPresetsLoaded) {
    return
  }

  const isNewAccount = store.state.auth.newAccount || from.query.newAccount

  if (isNewAccount) {
    store.dispatch('filters/getFilterPresets')
    return
  }

  await store.dispatch('filters/getFilterPresets')
}

export default async function filtersMiddleware(router) {
  router.beforeEach(async (to, from, next) => {
    // If query is present (filters, localFilters or page initialised) continue navigation
    if (to.query.filters || to.query.localFilters || to.query.page) {
      return next()
    }

    const target = getEntityTarget(to)
    const view_type = getViewType(to)

    if (
      !Object.values(EntityTargetTypes).includes(target)
      ||
      !Object.values(VIEW_TYPES).includes(view_type)
    ) {
      return next()
    }

    await loadFilterPresets(from)

    let project_id

    if (to.path.includes('/projects') || to.path.includes('/templates')) {
      project_id = to.params.id
    }

    const entityFilters = store.getters['filters/sortedDefaultTargetViewTypeFilterPresets'](target, view_type, project_id) || []

    let query, customView

    if (entityFilters.length) {
      query = JSON.parse(entityFilters[0].attributes.filter)
      customView = entityFilters[0].id
    }
    else {
      query = getSystemDefaultEntityFilters(target, view_type, from)
      customView = 'default'
    }

    if (query && (query.filters || query.localFilters)) {
      return next({
        path: to.path,
        query: {
          ...to.query,
          filters: query.filters,
          localFilters: query.localFilters,
          cv: customView,
          perPage: query.perPage,
          // Notifications specific - persist view in query
          unread: query.unread,
          read: query.read,
          is_pinned: query.is_pinned,
          page: 1, // not always needed
          // Calendar / scheduler specific
          viewType: query.viewType,
          slotWidth: query.slotWidth,
        },
      })
    }


    return next()
  });
}
