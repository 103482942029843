<template>
  <ProjectsCalendar :is-scheduler="true"/>
</template>
<script>

import ProjectsCalendar from "@/modules/projects/components/ProjectsCalendar.vue";

export default {
  name: 'projects-scheduler-page',
  components: {
    ProjectsCalendar,
  }
}
</script>
