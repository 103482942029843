<template>
  <router-view />
</template>
<script>
export default {
  created() {
    this.$store.dispatch('groups/getGroupById', { id: this.$route.params.id })
  }
}
</script>
