<template>
  <TaskDetailsLoading
    v-if="!isTaskCreate && taskLoading && !$route.query?.newTask"
  />
  <div
    v-else
    class="sm:grid grid-rows-1 grid-cols-12 sm:h-full rounded-lg task-details"
    :class="{
      'border-gray-200 border shadow': !isDialog && !useNewLayout,
    }"
  >
    <!--Left Side: START-->
    <div
      class="col-span-12 md:col-span-7 flex flex-col p-4 h-full"
      :class="{
        'sm:p-8': !useNewLayout || !isDialog,
        'sm:px-8 sm:py-4': useNewLayout && isDialog,
      }"
    >
      <!--BUTTON: START-->

      <div
        v-if="!isBulkCreate"
        class="flex items-center"
      >
        <BaseTooltip
          v-if="isTaskClosed"
          :content="$t('This task is completed. In order to make changes, please reopen it first.')"
        >
          <i class="cursor-pointer fas fa-lock text-2xl mr-2 text-red-600"/>
        </BaseTooltip>
        <!--Task Name-->
        <BaseInlineTextareaEdit
          v-model="model.name"
          :readonly="!canEditFields"
          :allow-line-breaks="false"
          :autofocus="isTaskCreate"
          auto-resize
          class="text-xl sm:text-3xl font-bold text-gray-900 w-full"
          :placeholder="$t('Name your task...')"
          @keydown.enter.stop="onNameEnterPressed"
        />
      </div>

      <div
        :class="{
          'border-dashed border-b border-t border-gray-200': !isBulkCreate,
          'h-full': isBulkCreate
        }"
      >
        <BulkCreateTasksInput
          v-if="isBulkCreate"
          v-model="model.notes"
          :readonly="!canEditFields"
        />
        <!-- Task Description -->
        <TaskDescriptionInput
          v-else
          v-model="model.notes"
          v-model:notifiable_user_ids="model.notifiable_user_ids"
          :key="task?.id || 'new'"
          :task="task"
          :disabled="!canEditFields"
          :isSaving="isSaving"
        />
      </div>

      <div
        v-if="!isBulkCreate"
        class="pt-2 text-sm"
      >
        <BaseTooltip
          :content="$t(`You can add files after creating the task`)"
          placement="top-start"
          popperClass="hide-arrow"
          :disabled="!isTaskCreate"
        >
          <TaskFiles
            ref="taskFiles"
            :task="task"
            :disabled="isTaskCreate || !canEditFields"
            @files-changed="reloadTask"
          />
        </BaseTooltip>
        <BaseTooltip
          :content="$t(`You can start a conversation after creating the task`)"
          placement="top-start"
          popperClass="hide-arrow"
          :disabled="!isTaskCreate"
        >
          <TaskDiscussion
            v-if="!isTemplateTask"
            :task="task"
            :disabled="isTaskCreate || !canEditFields"
            class="mt-4"
          />
        </BaseTooltip>
      </div>
    </div>
    <!--Left Side: END-->

    <!--Right Side: START-->
    <div
      class="col-span-12 md:col-span-5 p-4 border-l border-gray-200 bg-gray-50 task-details-right"
      :class="{
        'sm:p-8': !useNewLayout || !isDialog,
        'sm:px-8 sm:py-4': useNewLayout && isDialog,
      }"
    >
      <div class="sticky top-4 sm:top-8 properties-container h-full">

        <WarningAlert
          v-if="showRequiredFieldsAlert"
          :dismissable="false"
          class="border-yellow-700 border mb-4"
        >
          {{ $t('Please complete all required fields labelled with * or the task will not be saved.') }}
        </WarningAlert>

        <div class="grid grid-cols-10 gap-2 items-start">
          <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
            <span class="inline">
              <i class="fa-regular fa-inbox mr-2 w-4" aria-hidden="true"/>
              <span>{{ $t('Project') }}</span>
              <span v-if="!model.project_id" class="text-red-500">*</span>
            </span>
          </label>
          <div class="col-span-6">
            <BaseTooltip
              :content="$t(`Tasks that have sub tasks or have a parent task can't be moved to another project.`)"
              :disabled="canChangeProject"
            >
              <div>
                <InlineProjectSelect
                  v-model="model.project_id"
                  :initialValue="model.project"
                  :disabled="!canChangeProject || !canEditFields"
                  :showIcon="false"
                />
              </div>
            </BaseTooltip>
          </div>

          <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
            <span class="inline">
              <i class="fa-regular fa-lock mr-2 w-4" aria-hidden="true"/>
              <span>{{ $t('Privacy') }}</span>
              <span v-if="!model.visibility" class="text-red-500">*</span>
            </span>
          </label>
          <div class="col-span-6">
            <RequiresPermissionTo :action="$actions.CHANGE_TASK_VISIBILITY">
              <BaseTooltip
                :content="$t(`Sub tasks cannot have a different privacy than their parent.`)"
                :disabled="canChangePrivacy"
              >
                <TaskPrivacyInlineSelect
                  v-model="model.visibility"
                  :disabled="!canChangePrivacy || !canEditFields"
                  :task="task"
                  class="flex items-center cursor-pointer"
                  @sync-children-visibility="onSyncChildrenVisibility"
                />
              </BaseTooltip>
            </RequiresPermissionTo>
          </div>

          <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
            <span class="inline">
              <i class="fa-regular fa-rectangle mr-2 w-4" aria-hidden="true"/>
              <span>{{ $t('Status') }}</span>
              <span v-if="!model.status_id" class="text-red-500">*</span>
            </span>
          </label>
          <div class="col-span-6">
            <TaskStatusInlineSelect
              v-model="model.status_id"
              @update:modelValue="onStatusChange"
              :disabled="!canEditFields"
            />
          </div>

          <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
            <span class="inline">
              <i class="fa-regular fa-calendar-alt mr-2 w-4" aria-hidden="true"/>
              <span>{{ $t('Date Type') }}</span>
              <span v-if="!model.date_type" class="text-red-500">*</span>
            </span>
          </label>
          <div class="col-span-6">
            <TaskDateTypeInlineSelect
              v-model="model.date_type"
              :disabled="!canEditFields"
              :isTemplateTask="isTemplateTask"
              :isBulkCreate="isBulkCreate"
              @update:modelValue="onDateTypeChange"
            />
          </div>

          <template v-if="model.date_type === taskDateTypes.RECURRING">
            <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
              <span class="inline">
                <i class="fa-regular fa-repeat mr-2 w-4" aria-hidden="true"/>
                <span>{{ $t('Recurrence') }}</span>
                <span v-if="!model.recurrence" class="text-red-500">*</span>
              </span>
            </label>
            <div class="col-span-6">
              <BaseInlineInput>
                <template #default="{ triggerSave }">
                  <RecurrenceInput
                    v-model="model.recurrence"
                    @update:modelValue="triggerSave"
                    v-model:recurrenceDate="model.recurrence_date"
                    v-model:humanReadableText="recurrenceHumanReadableText"
                    :disabled="!canEditFields"
                    :title="`${model.name || $t('Task')} ${$t('Recurrence')}`"
                    :placeholder="$t('Set task recurrence...')"
                    class="mt-1 relative sm:mt-0 sm:col-span-3"
                  />
                </template>
              </BaseInlineInput>
            </div>
          </template>

          <template v-else-if="[
              taskDateTypes.DAYS_FROM_PROJECT_START,
              taskDateTypes.WORKING_DAYS_FROM_PROJECT_START
            ].includes(model.date_type)"
          >

            <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
              <span class="inline">
                <i class="fa-regular fa-calendar-alt mr-2 w-4" aria-hidden="true"/>
                <span>{{ $t('Days') }}</span>
                <span
                  v-if="
                    (model.date_type === taskDateTypes.DAYS_FROM_PROJECT_START && model.days_from_project_start === null)
                    ||
                    (model.date_type === taskDateTypes.WORKING_DAYS_FROM_PROJECT_START && model.working_days_from_project_start === null)
                  "
                  class="text-red-500"
                >*</span>
              </span>
            </label>

            <div class="col-span-6">
              <div class="text-sm text-gray-400 cursor-pointer inline">
                <BaseInlineTextEdit
                  v-if="model.date_type === taskDateTypes.DAYS_FROM_PROJECT_START"
                  v-model="model.days_from_project_start"
                  type="number"
                  :placeholder="$t('Enter number of days...')"
                  :disabled="!canEditFields"
                />
                <BaseInlineTextEdit
                  v-else
                  v-model="model.working_days_from_project_start"
                  type="number"
                  :placeholder="$t('Enter number of days...')"
                  :disabled="!canEditFields"
                />
              </div>
            </div>
          </template>

          <template v-else-if="model.date_type !== taskDateTypes.NO_DATE">
            <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
              <span class="inline">
                <i class="fa-regular fa-calendar-alt mr-2 w-4" aria-hidden="true"/>
                <span>
                  {{ model.date_type === taskDateTypes.SINGLE_DATE ? $t('Due Date') : $t('Date') }}
                </span>
                <span v-if="!model.date" class="text-red-500">*</span>
              </span>
            </label>
            <div class="col-span-6">
              <div class="text-sm text-gray-400 cursor-pointer inline">
                <BaseInlineDatePicker
                  v-model="model.date"
                  :type="model.date_type === taskDateTypes.DATE_RANGE ? 'daterange': 'date'"
                  :displayIcon="false"
                  :disabled="!canEditFields"
                  :placeholder="$t('Select date...')"
                  :start-placeholder="$t('Select start date...')"
                  :end-placeholder="$t('Select end date...')"
                />
              </div>
            </div>
          </template>

          <BaseTooltip
            :content="$t(`You can assign users after selecting a project`)"
            :disabled="Boolean(model.project_id)"
            class="col-span-full grid grid-cols-10"
          >
            <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
              <span
                class="inline"
                :class="{
                  'opacity-50': !model.project_id
                }"
              >
                <i class="fa-regular fa-user mr-2 w-4" aria-hidden="true"></i>
                <span>{{ $t('Assigned') }}</span>
                <BaseIconTooltip
                  v-if="model.project_id"
                  :content="$t('task assigness info text')"
                />
              </span>
            </label>
            <div class="col-span-6">
              <TaskUsers
                v-model="model.allocated_ids"
                v-model:isValid="hasValidAllocations"
                :task="task"
                :taskModel="model"
                :disabled="!canEditFields"
                :key="resetCount"
              />
            </div>
          </BaseTooltip>
          <div
            v-if="!hasValidAllocations"
            class="col-span-full"
          >
            <WarningAlert
              :dismissable="false"
              class="border-yellow-700 border"
            >
              {{
                $t("The task privacy is set to 'Creators Only' but you have Collaborators allocated. Please change the privacy setting or remove the collaborators before saving the task.")
              }}
            </WarningAlert>
          </div>

          <BaseTooltip
            :content="$t(`You can add groups after selecting a project`)"
            :disabled="Boolean(model.project_id)"
            class="col-span-full grid grid-cols-10"
          >
            <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
              <span
                class="inline"
                :class="{
                  'opacity-50': !model.project_id
                }"
              >
                <i class="fa-regular fa-ball-pile mr-2 w-4" aria-hidden="true"/>
                <span>{{ $t('Groups') }}</span>
                <BaseIconTooltip
                  :content="$t('Create groups for every client company, internal department or team. People with a Collaborator or Collaborator Plus role can only see their group and can invite people to it.')"
                  tutorialName="groups"
                />
              </span>
            </label>
            <div class="col-span-6">
              <TaskGroups
                v-model="model.group_ids"
                :task="task"
                :task-model="model"
                :disabled="!canEditFields"
                :key="resetCount"
              />
            </div>
          </BaseTooltip>

          <BaseTooltip
            :content="$t(`You can add followers after selecting a project`)"
            :disabled="Boolean(model.project_id)"
            class="col-span-full grid grid-cols-10"
          >
            <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
              <span
                class="inline"
                :class="{
                  'opacity-50': !model.project_id
                }"
              >
                <i class="fa-regular fa-users mr-2 w-4" aria-hidden="true"/>
                <span>{{ $t('Followers') }}</span>
              </span>
            </label>
            <div class="col-span-6">
              <TaskUsers
                v-model="model.follower_ids"
                v-model:isValid="hasValidFollowers"
                users-key="followers"
                :task="task"
                :task-model="model"
                :disabled="!canEditFields"
                :key="resetCount"
              />
            </div>
          </BaseTooltip>
          <div
            v-if="!hasValidFollowers"
            class="col-span-full"
          >
            <WarningAlert
              :dismissable="false"
              class="border-yellow-700 border"
            >
              {{
                $t("The task privacy is set to 'Creators Only' but you have Collaborators following. Please change the privacy setting or remove the collaborators before saving the task.")
              }}
            </WarningAlert>
          </div>
          <template
            v-for="customField of customFields"
            :key="customField.id"
          >
            <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
              <span class="inline">
                <i class="fa-regular fa-sparkles mr-2 w-4" aria-hidden="true"/>
                <span>{{ customField.attributes.name }}</span>
                <span
                  v-if="customField.attributes?.rules?.includes('required') && !model.custom_fields?.[customField?.attributes?.key]"
                  class="text-red-500 ml-1"
                >*</span>
              </span>
            </label>
            <div class="col-span-6">
              <CustomFieldInlineEditInput
                v-model="model.custom_fields"
                :disabled="!canEditFields"
                :entityModel="task"
                :custom-field="customField"
                :showLabel="false"
              />
            </div>
          </template>
          <template v-if="enableCustomFields">
            <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
              <span class="inline">
                <i class="fa-regular fa-sparkles mr-2 w-4" aria-hidden="true"/>
                <span>{{ $t('Custom Fields') }}</span>
              </span>
            </label>
            <div class="col-span-6">
              <!--Add fields-->
              <TaskCustomFields
                :task="task"
                :project_id="model.project_id"
              />
            </div>
          </template>
          <TaskRelatedTasks
            v-if="!isBulkCreate"
            v-model:parent="model.parent_id"
            :task="task"
            :taskModel="model"
            :disabled="!canEditFields || isTaskCreate"
            @on-parent-changed="onParentChanged"
          />

          <RequiresPermissionTo
            v-if="!isBulkCreate"
            :action="$actions.VIEW_TIME_ENTRIES"
          >
            <TaskTimeTracking
              :task="task"
              :disabled="!canEditFields || isTaskCreate"
            />
          </RequiresPermissionTo>
        </div>
      </div>
    </div>

    <div
      class="col-span-full px-6 py-4 border-t border-gray-200 sm:sticky bottom-0 bg-white w-full rounded-b-lg h-fit mt-auto"
      :class="{
        'bottom-16 md:bottom-0': !isDialog
      }"
    >
      <template v-if="task?.id">
        <TaskDetailsOptionsButtons
          :task="task"
          :projectId="model.project_id"
          :isDialog="isDialog"
          class="hidden sm:flex flex-wrap gap-2"
          @completed-changed="reloadTask"
          @closeTask="onCloseTask"
          @deleted="taskDeleted = true; onCloseTask()"
        />
        <TaskDetailsOptionsDropdown
          :task="task"
          :projectId="model.project_id"
          :isDialog="isDialog"
          class="sm:hidden"
          @completed-changed="reloadTask"
          @closeTask="onCloseTask"
          @deleted="taskDeleted = true; onCloseTask()"
        />
      </template>
      <template v-else>
        <TaskCreateButtons
          :disabled="!isValidModel"
          :isSaving="isSaving"
          :chosenAction="chosenAfterCreateAction"
          :isBulkCreate="isBulkCreate"
          class="hidden sm:flex flex-wrap gap-2"
          @cancel="isDialog ? $emit('close-dialog') : $router.go(-1)"
          @createAndNew="trySaveTask('new')"
          @createAndContinue="trySaveTask('continue')"
          @createAndClose="trySaveTask('close')"
        />
        <TaskCreateDropdown
          v-loading="isSaving"
          :disabled="!isValidModel"
          class="sm:hidden"
          @cancel="isDialog ? $emit('close-dialog') : $router.go(-1)"
          @createAndNew="trySaveTask('new')"
          @createAndContinue="trySaveTask('continue')"
          @createAndClose="trySaveTask('close')"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
// Libs
import { cloneDeep, debounce, get } from 'lodash-es'
import { computed, nextTick, onMounted, provide, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { taskDateTypes, visibilityTypes } from '@/modules/tasks/utils/modelUtils'
import useCan from '@/modules/common/composables/useCan'
import { error, success } from "@/components/common/NotificationPlugin/index.js"
import i18n from '@/i18n'
import { getCustomFieldValuesObject, } from "@/modules/accounts/utils/modelUtils"
import { getDateWithoutTime } from '@/modules/common/utils/dateUtils'
import { EntityChangeEvent } from "@/modules/common/utils/entityUtils";
import { getSetting } from "@/plugins/settingsPlugin";
import useLayout from "@/modules/common/composables/useLayout";

// Components
import TaskDetailsOptionsButtons from "@/modules/tasks/components/TaskDetailsOptionsButtons.vue"
import TaskDetailsOptionsDropdown from "@/modules/tasks/components/TaskDetailsOptionsDropdown.vue"
import TaskCreateButtons from "@/modules/tasks/components/TaskCreateButtons.vue"
import TaskCreateDropdown from "@/modules/tasks/components/TaskCreateDropdown.vue"
import TaskPrivacyInlineSelect from "@/modules/tasks/components/TaskPrivacyInlineSelect.vue";
import TaskStatusInlineSelect from "@/modules/tasks/components/TaskStatusInlineSelect.vue";
import InlineProjectSelect from "@/components/selects/InlineProjectSelect.vue";
import TaskRelatedTasks from "@/modules/tasks/components/TaskRelatedTasks.vue";
import TaskDescriptionInput from "@/modules/tasks/components/TaskDescriptionInput.vue";
import TaskDateTypeInlineSelect from "@/modules/tasks/components/TaskDateTypeInlineSelect.vue";
import CustomFieldInlineEditInput from "@/modules/common/components/CustomFieldInlineEditInput.vue";
import TaskFiles from "@/modules/tasks/components/TaskFiles.vue";
import TaskUsers from "@/modules/tasks/components/TaskUsers.vue";
import TaskGroups from "@/modules/tasks/components/TaskGroups.vue";
import TaskCustomFields from "@/modules/tasks/components/TaskCustomFields.vue";
import TaskDetailsLoading from "@/modules/tasks/components/TaskDetailsLoading.vue";
import WarningAlert from "@/components/common/WarningAlert.vue";

import TaskDiscussion from "@/modules/tasks/components/TaskDiscussion.vue";
import RecurrenceInput from "@/modules/common/components/RecurrenceInput.vue";
import TaskTimeTracking from "@/modules/tasks/components/TaskTimeTracking.vue"
import BulkCreateTasksInput from "@/modules/tasks/components/BulkCreateTasksInput.vue";

const store = useStore()
const route = useRoute()
const router = useRouter()
const { can, actions } = useCan();

const {
  useNewLayout
} = useLayout()


// Emits
const emit = defineEmits(['save', 'is-valid-changed', 'close-dialog'])

// Props
const props = defineProps({
  currentTask: {
    type: Object,
    default: () => null
  },
  taskId: {
    type: [String, Number],
    default: () => null
  },
  loading: {
    type: Boolean,
    default: false
  },
  isSubTask: {
    type: Boolean,
    default: false
  },
  isDialog: {
    type: Boolean,
    default: false
  },
  redirectAfterSave: {
    type: Boolean,
    default: true,
  },
  isBulkCreate: {
    type: Boolean,
    default: false,
  },
})

// State/Data
const hasValidAllocations = ref(true)
const hasValidFollowers = ref(true)
const isLoading = ref(false)
const task = ref(null)
const taskDeleted = ref(false)
const resetCount = ref(0)

const taskLoading = computed(() => {
  return props.loading || isLoading.value
})

const enableCustomFields = computed(() => {
  return canEditFields.value && !props.isBulkCreate && (can(actions.CREATE_CUSTOM_FIELDS) || can(actions.CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS))
})

watch(() => taskLoading.value, (value) => {
  if (value) {
    return
  }

  clearNewTaskRedirect()
})

function clearNewTaskRedirect() {
  const query = {
    ...route.query
  }

  if (!query.newTask) {
    return
  }

  delete query.newTask

  router.replace({
    query
  })
}

const isTaskCreate = computed(() => {
  const { params, path } = route
  const hasParamsTaskId = params?.id && !path.includes('projects')
  return !props.taskId && !props.currentTask?.id && !hasParamsTaskId
})

// Model
const taskInitModel = computed(() => {
  let visibility = can(actions.CHANGE_TASK_VISIBILITY)
    ? visibilityTypes.CREATORS_ONLY
    : visibilityTypes.CREATORS_AND_COLLABORATORS

  const defaultVisibility = getSetting('default_task_privacy')
  if (can(actions.CHANGE_TASK_VISIBILITY) && defaultVisibility) {
    visibility = defaultVisibility
  }

  return {
    name: props.isBulkCreate ? i18n.t('Bulk create task') : '',
    notes: '',
    visibility,
    status_id: getSetting('default_task_status'),
    parent_id: null,
    project_id: store.getters.project_id || null,
    project: store.state.projects.currentProject || null,
    date_type: taskDateTypes.NO_DATE,
    date: null,
    start_date: null,
    end_date: null,
    recurrence_date: null,
    recurrence: '',
    days_from_project_start: null,
    working_days_from_project_start: null,
    allocated_ids: [],
    group_ids: [],
    follower_ids: [],
    custom_fields: {},
    notifiable_user_ids: []
  }
})

const model = ref(cloneDeep(taskInitModel.value))
const recurrenceHumanReadableText = ref('')

const customFields = computed(() => {
  return store.getters['accounts/entityFormCustomFields']('task', model.value.project_id)
})

const isTemplateTask = computed(() => {
  if (!task.value?.id) {
    return store.getters['templates/isTemplateOpened']
  }
  return task.value?.relationships?.project?.attributes?.is_template
})

// Statuses
const statuses = computed(() => {
  return store.getters['tasks/orderedStatuses'] || []
})

const completedStatus = computed(() => {
  return statuses.value?.[statuses.value?.length - 1]
})

const isTaskClosed = computed(() => {
  if (!task.value || !completedStatus.value) {
    return false
  }

  return task.value?.attributes?.status_id == completedStatus.value?.id || model.value.status_id == completedStatus.value?.id
})

async function onStatusChange(status_id) {
  if (!task.value?.id) {
    return
  }

  await store.dispatch('tasks/changeTaskStatus', {
    task: task.value,
    status_id
  })

  reloadTask()
}

const defaultStatus = computed(() => {
  return store.getters['tasks/defaultTaskStatus']
})

function resetModel() {
  model.value = cloneDeep({
    ...taskInitModel.value,
    status_id: defaultStatus.value?.id
  })

  resetCount.value++
}

// Save/edit task
const isSaving = ref(false);
const isSaveEnabled = ref(!props.taskId && !props.currentTask?.id)
const savedTask = ref(null);

const requiredFields = computed(() => {
  const fields = ['name', 'project_id']

  if ([taskDateTypes.DATE_RANGE, taskDateTypes.SINGLE_DATE].includes(model.value.date_type)) {
    fields.push('date')
  }

  if (model.value.date_type === taskDateTypes.RECURRING) {
    fields.push('recurrence')
  }

  if (model.value.date_type === taskDateTypes.DAYS_FROM_PROJECT_START) {
    fields.push('days_from_project_start')
  }

  if (model.value.date_type === taskDateTypes.WORKING_DAYS_FROM_PROJECT_START) {
    fields.push('working_days_from_project_start')
  }

  for (const customField of customFields.value) {
    if (customField.attributes.rules?.includes('required')) {
      fields.push(`custom_fields.cf_${customField.id}`)
    }
  }

  return fields
})

const isValidModel = computed(() => {
  if (!hasValidAllocations.value || !hasValidFollowers.value) {
    return false
  }

  for (const field of requiredFields.value) {
    if (!fieldHasValue(field)) {
      return false
    }
  }

  return true
})

const invalidFields = computed(() => {
  const fields = []

  for (const field of requiredFields.value) {
    if (!fieldHasValue(field)) {
      fields.push(field)
    }
  }

  return fields
})

function fieldHasValue(field) {
  const v = get(model.value, field)
  return v !== null && v !== undefined && v !== ''
}

const showRequiredFieldsAlert = computed(() => {
  const mainRequiredFields = ['name', 'project_id']
  const hasMainRequiredFields = invalidFields.value.every(field => mainRequiredFields.includes(field))
  return !taskLoading.value && !isValidModel.value && !hasMainRequiredFields
})

const hasRelatedTasks = computed(() => {
  const hasParentTask = !!task.value?.attributes?.parent_id || !!model.value?.parent_id
  const hasChildTasks = !!task.value?.relationships?.tasks?.length

  return hasParentTask || hasChildTasks
})

const canChangePrivacy = computed(() => {
  return !task.value?.attributes?.parent_id
})

const canChangeProject = computed(() => {
  if (props.isSubTask || hasRelatedTasks.value) {
    return false
  }

  return true
})

const canEditFields = computed(() => {
  if (!task.value?.id) {
    return can(actions.CREATE_TASKS)
  }

  return !isTaskClosed.value && can(actions.EDIT_TASKS)
})


function onDateTypeChange(value) {
  if (value === taskDateTypes.NO_DATE) {
    model.value.date = null
    model.value.recurrence = null
    model.value.recurrence_date = null
  } else if (value === taskDateTypes.SINGLE_DATE) {
    model.value.date = getDateWithoutTime(new Date())
    model.value.recurrence = null
    model.value.recurrence_date = null
  } else if (value === taskDateTypes.DATE_RANGE) {
    model.value.date = {
      start: getDateWithoutTime(new Date()),
      end: getDateWithoutTime(new Date())
    }

    model.value.recurrence = null
    model.value.recurrence_date = null
  } else if (value === taskDateTypes.RECURRING) {
    // Transform date start from '2022-07-13T09:45:08.675Z' to '20220713T094523Z' format accepted by rrule
    const dtStart = new Date().toISOString().split('.')[0].replace(/-|:/g, '') + 'Z'
    model.value.recurrence = `RRULE:FREQ=DAILY;INTERVAL=1;DTSTART=${dtStart}`
    model.value.recurrence_date = new Date()
  }
}

const taskSyncTrigger = computed(() => {
  return store.state.tasks.taskSyncTriggers[task.value?.id]
})

const taskSyncTriggerCount = computed(() => {
  return taskSyncTrigger.value?.count || 0
})

watch(() => taskSyncTriggerCount.value, () => {
  const triggerEvent = taskSyncTrigger.value

  if (triggerEvent?.event !== EntityChangeEvent.Update) {
    return
  }

  task.value = cloneDeep(triggerEvent.task)
})

watch(() => props.currentTask, (value) => {
  task.value = value
}, { immediate: true });


watch(task, (value) => {
  if (!value?.attributes) {
    resetModel()
    return
  }

  let date = value.attributes?.date
  if (value.attributes?.date_type === taskDateTypes.DATE_RANGE) {
    date = {
      start: value.attributes?.start_date,
      end: value.attributes?.end_date
    }
  }

  model.value = {
    ...model.value,
    ...value?.attributes,
    custom_fields: getCustomFieldValuesObject(value?.attributes.custom_fields),
    date
  }

  const { status_id, project_id } = value?.attributes || {}
  if (status_id) {
    model.value.status_id = status_id?.toString()
  } else {
    model.value.status_id = defaultStatus.value?.id
  }
  if (project_id) {
    model.value.project_id = project_id?.toString()
    model.value.project = value?.relationships?.project
  }

  const { allocations, followers, groups } = value?.relationships || {}

  model.value.allocated_ids = (allocations || []).map(x => x.id)
  model.value.follower_ids = (followers || []).map(x => x.id)
  model.value.group_ids = (groups || []).map(x => x.id)

  setTimeout(() => {
    isSaveEnabled.value = true
  }, 500)
}, { immediate: true })

watch(() => task.value?.id, value => {
  if (!value) {
    return
  }

  markNotificationsRead()
})

watch(() => props.taskId, async (value) => {
  if (!value) {
    return
  }

  isLoading.value = true
  try {
    await getTask(value)
  } catch (err) {
    if (err.handled) {
      return
    }

    error(i18n.t('Failed to load task...'))
  } finally {
    isLoading.value = false
  }

}, { immediate: true })

watch(() => isValidModel.value, (value) => {
  emit('is-valid-changed', value)
}, { immediate: true })

const taskFiles = ref()

async function getTask(id) {
  task.value = await store.dispatch('tasks/getTaskById', {
    id,
    returnEntity: true,
  })
}

async function reloadTask(silent = true) {
  if (!task.value?.id) {
    return
  }

  try {
    !silent && (isLoading.value = true)
    await getTask(task.value?.id)
    if (taskFiles.value) {
      taskFiles.value.filesToUpload = []
    }
  } catch (err) {
    if (err.handled) {
      return
    }
    error('Something failed...')
  } finally {
    !silent && (isLoading.value = false)
  }

}

let afterSave = null

function onSyncChildrenVisibility({ subtaskIds, visibility }) {
  if (isTaskCreate.value) {
    return
  }

  afterSave = async () => {
    for (const id of subtaskIds) {
      await nextTick()
      store.dispatch('tasks/updateTaskInArray', {
        id,
        attributes: {
          visibility,
        }
      })
    }
  }
}

function onParentChanged({ previousParentId, newParentId }) {
  if (isTaskCreate.value) {
    return
  }

  afterSave = () => {
    if (!task.value) {
      return
    }

    store.dispatch('tasks/syncParentRelationship', {
      childTask: task.value,
      previousParentId,
      newParentId
    })
  }
}

function triggerSave() {
  if (!task.value?.id || !canEditFields.value || taskDeleted.value) {
    return
  }

  trySaveTask()
}

provide("triggerSave", triggerSave)

async function saveTask(onAfterCreate) {
  await nextTick()
  if (!isSaveEnabled.value) {
    return
  }

  if (!isValidModel.value) {
    return
  }

  try {
    isSaving.value = true
    const data = cloneDeep(model.value)

    if (!task.value?.id) {
      await createTask(data, onAfterCreate)
    } else if (canEditFields.value) {
      await editTask(data)
    }

    if (afterSave) {
      afterSave()
      afterSave = null
    }

  } catch (err) {
    console.log(err)
    // if (err.handled) {
    //   return
    // }
    if (!task.value?.id) {
      error(i18n.t('Could not create the task'))
    } else {
      error(i18n.t('Could not update the task'))
    }
    throw err
  } finally {
    isSaving.value = false
  }
}

const trySaveTask = debounce(saveTask, 1500)
const chosenAfterCreateAction = ref(null)

function onNameEnterPressed(event) {
  if (!isTaskCreate.value) {
    return
  }

  if (event.metaKey || event.ctrlKey) {
    trySaveTask('close')
    return
  }

  trySaveTask('continue')
}

async function createTask(data, onAfterCreate) {
  chosenAfterCreateAction.value = onAfterCreate

  if (props.isBulkCreate) {
    
    const taskModels = data.notes.split('\n')
      .filter(name => name !== '')
      .map(name => {
        const task = cloneDeep(data)
        task.name = name
        task.notes = ''
        return task
      })

    await store.dispatch('tasks/bulkCreateTasks', {
      taskModels,
    })
    success(i18n.t('Tasks created successfully!'))
    await onTaskCreated(onAfterCreate)
    return
  }
  savedTask.value = await store.dispatch('tasks/createTask', {
    data,
    setAsCurrent: false
  })

  if (!savedTask.value?.id) {
    return
  }

  success(i18n.t('Task created successfully!'))
  emit('save', savedTask.value)

  await onTaskCreated(onAfterCreate)
}

async function onTaskCreated(onAfterCreate) {
  if (!props.redirectAfterSave) {
    return
  }

  if (route.path === '/tasks/add') {
    if (onAfterCreate === 'new') {
      task.value = null
      await nextTick()
      resetModel()
    } else if (onAfterCreate === 'continue') {
      task.value = savedTask.value
      store.commit('tasks/setCurrentTask', savedTask.value)

      await nextTick()
      await router.push(`/tasks/${savedTask.value.id}?newTask=true`)
    } else if (onAfterCreate === 'close') {
      task.value = null
      await nextTick()
      const project_id = savedTask.value.attributes.project_id
      await router.push(`/projects/${project_id}/tasks/list`)
    }

    return
  }

  if (props.isSubTask) {
    return
  }


  if (onAfterCreate === 'new') {
    task.value = null
    await nextTick()
    resetModel()
  } else if (onAfterCreate === 'continue') {
    task.value = savedTask.value
    store.commit('tasks/setCurrentTask', savedTask.value)
  } else if (onAfterCreate === 'close') {
    task.value = null
    await nextTick()
    resetModel()

    emit('close-dialog')
  }
}

async function editTask(data) {
  const taskId = task.value.id

  const isRecurringTaskCompleted = model.value.date_type === taskDateTypes.RECURRING
    && data.status_id != task.value.attributes.status_id // status changed
    && data.status_id == completedStatus.value.id; // status changed to completed status

  if (isRecurringTaskCompleted) {
    delete data.status_id
  }

  savedTask.value = await store.dispatch('tasks/editTask', { taskId, data })

  if (isRecurringTaskCompleted) {
    await store.dispatch('tasks/toggleTaskCompleted', {
      task: task.value,
      setCompleted: true,
    })
  }
}

async function onCloseTask() {
  triggerSave()
  if (props.isDialog) {
    emit('close-dialog')
    return
  }

  await router.go(-1)
}

// Hooks
function markNotificationsRead() {
  if (!task.value?.id) {
    return
  }
  store.dispatch('notifications/markEntityNotificationsRead', {
    notifiable_target_type: 'task',
    notifiable_target_id: task.value.id,
  })
}

onMounted(() => {
  store.dispatch('tasks/getAllStatuses')
})

onBeforeRouteLeave(() => {
  if (!task.value?.id) {
    return
  }
  if (!isTaskCreate.value && !isValidModel.value) {
    error(i18n.t("Couldn't save task as invalid items weren't resolved."))
  }

  document.body.classList.remove('overflow-hidden')
})

defineExpose({
  triggerSave,
})
</script>
