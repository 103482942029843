<template>
  <div class="flex justify-center rounded-md mx-auto">
    <div class="relative self-center bg-gray-200 rounded-lg p-0.5 flex">
      <button
        v-for="(option, index) in options"
        :key="option.value"
        :class="{
          'text-gray-900': activeOption !== option.value,
          'bg-primary-500 text-white' : activeOption === option.value
        }"
        type="button"
        class="relative border border-transparent rounded-md py-2 px-3 text-sm font-medium whitespace-nowrap sm:w-auto sm:px-8"
        @click="onChangeValue(option)"
      >
        <span>
          {{ $t(option.label) }}
        </span>

        <i
          v-if="isOptionLimitReached(option)"
          class="fas fa-crown text-yellow-500 ml-2"
        />

      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue';
import {
  useAccountLimits
} from '@/modules/auth/composables/useAccountLimits';
import { AccountPermissions } from '@/modules/common/composables/useCan';

type ButtonGroupOption = {
  label: string;
  value: string | number;
  limitedFeature?: AccountPermissions;
}

const {
  hasReachedLimit,
  openUpgradeDialog,
} = useAccountLimits()

const props = defineProps({
  activeOption: {
    type: [String, Number],
    default: '',
  },
  options: {
    type: Array as PropType<ButtonGroupOption[]>,
    default: () => [],
  },
})

const emit = defineEmits(['change'])

function onChangeValue(option: ButtonGroupOption) {
  if (isOptionLimitReached(option)) {
    return openUpgradeDialog(option.limitedFeature!)
  }

  emit('change', option.value)
}

function isOptionLimitReached(option: ButtonGroupOption) {
  if (!option.limitedFeature) {
    return false
  }

  return hasReachedLimit(option.limitedFeature)
}
</script>
