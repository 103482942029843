<template>
  <BaseTableCell :params="params">
    <template #loading>
      <EntityLoadingCards
        :params="{
          columns: entityCardParams.columns
        }"
        :count="1"
      />
    </template>
    <EntityCard
      :params="entityCardParams"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
// Utils
import { computed } from 'vue'
import EntityCard from "@/components/table/EntityCard.vue";
import EntityLoadingCards from "@/components/table/EntityLoadingCards.vue";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const colDef = computed(() => {
  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const entityCardParams = computed(() => {
  return {
    ...props.params,
    columns: columnParams.value.initialColumns
  }
})
</script>
