<template>
  <BaseSelect
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :options="options"
    :excludedOptions="excludedOptions"
    :placeholder="$t('+ Add card')"
    :returnObject="false"
  >
    <template #empty>
      <div class="px-5 py-4 text-gray-400 text-sm">
        {{ $t('No more cards to add.') }}
      </div>
    </template>
  </BaseSelect>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import { PropType, computed } from "vue";
import { Widgets, displayWidgetConditions } from "@/modules/dashboard/utils/widgetUtils"
import { UserTypes } from '@/modules/users/util/userUtils';

const props = defineProps({
  modelValue: {
    type: String as PropType<Widgets | null>,
  },
  excludedOptions: {
    type: Array as PropType<Widgets[]>,
    default: () => [],
  },
  scope: {
    type: String as PropType<UserTypes>,
    required: true,
  },
})

const options = computed(() => {
  const opts: { value: Widgets, label: string }[] = [
    // TODO: Uncomment after implementing pinned items
    // {
    //   value: Widgets.PinnedItems,
    //   label: i18n.t('Pinned items'),
    // },
    {
      value: Widgets.Projects,
      label: i18n.t('Projects'),
    },
    {
      value: Widgets.Tasks,
      label: i18n.t('Tasks'),
    },
    {
      value: Widgets.Time,
      label: i18n.t('Time'),
    },
    {
      value: Widgets.Payments,
      label: i18n.t('Payments'),
    },
    {
      value: Widgets.Files,
      label: i18n.t('Files'),
    }
  ]

  return opts.filter((opt) => {
    if (!displayWidgetConditions[opt.value]) {
      return true
    }

    return displayWidgetConditions[opt.value]?.(props.scope)
  })
})
</script>
