<template>
  <BaseEntityForm
    ref="formWrapper"
    width-class="max-w-6xl"
    @submit="startTimer"
  >
    <template #default="{ meta, errors }">
      <div class="md:grid md:grid-cols-4 md:gap-4">
        <BaseInput
          :modelValue="model.group_id"
          :name="$t('Group')"
          :label="$t('Group')"
          :errorMessage="errors[$t('Group')]"
          layout="vertical"
          id="person"
          class="mt-1 sm:mt-0"
        >
          <div class="sm:mt-0 sm:col-span-2">
            <GroupSelect
              v-model="model.group_id"
              :initialValue="model.group"
              :urlParams="{
                hasUsersWithRoleGreaterThan: $roleRanks.COLLABORATOR_PLUS,
                project_id: model.project_id
              }"
              :placeholder="$t('Choose group...')"
              :allow-entity-create="can($actions.CREATE_GROUPS)"
              @change="onGroupChanged"
            />
          </div>
        </BaseInput>

        <BaseInput
          :modelValue="model.user_id"
          :name="$t('Person')"
          :label="$t('Person')"
          rules="required"
          layout="vertical"
          id="person"
          class="mt-1 sm:mt-0"
        >
          <div class="sm:mt-0 sm:col-span-2">
            <UserSelect
              v-model="model.user_id"
              :initialValue="model.user"
              :placeholder="$t('Choose person...')"
              :urlParams="{
                group_id: model.group_id,
                roleGreaterThan: $roleRanks.COLLABORATOR_PLUS,
                project_id: model.project_id
              }"
            />
          </div>
        </BaseInput>

        <BaseInput
          v-if="!$store.getters.project_id"
          :modelValue="model.project_id"
          :label="$t('Project')"
          :name="$t('Project')"
          layout="vertical"
          rules="required"
          id="person"
          class="mt-1 sm:mt-0"
        >
          <div class="sm:mt-0 sm:col-span-2">
            <ProjectSelect
              v-model="model.project_id"
              :initialValue="model.project"
              :disabled="!model.user_id"
              :urlParams="{
                belongsToProject: model.user_id,
              }"
              :placeholder="$t('Choose project...')"
              @change="model.task_id = null"
            />
          </div>
        </BaseInput>

        <BaseInput
          :label="$t('Task')"
          :name="$t('Task')"
          layout="vertical"
          id="person"
          class="mt-1 sm:mt-0"
        >
          <div class="sm:mt-0 sm:col-span-2">
            <TaskSelect
              v-model="model.task_id"
              :initialValue="model.task"
              :disabled="!model.project_id"
              :urlParams="{
                project_id: model.project_id,
              }"
              :placeholder="$t('Choose task (optional)...')"
            />
          </div>
        </BaseInput>

        <TimeEntryCustomFields
          :time-entry="model"
          @update="model.custom_fields = $event"
        />

        <div class="col-span-4 flex justify-end">
          <BaseButton
            :loading="loading"
            :disabled="submitDisabled"
            type="submit"
            variant="primary"
          >
            {{ $t('Start Timer') }}
          </BaseButton>
        </div>

      </div>
      <!-- Running timers table -->
      <template v-if="timeEntriesTimer.length">
        <p class="text-gray-400 text-xs px-6 mb-1">{{ $t('Your active timers are displayed below:') }}</p>
        <div class="mx-6">
          <TimeEntriesTable
            :data="timeEntriesTimer"
            :limitedCount="true"
            key="timeEntriesTable"
            domLayout="autoHeight"
          />
        </div>
      </template>
    </template>
    <template #actions="{ meta, errors}">
      <div class="mr-auto">
        <router-link
          v-if="showTimeActualLink"
          to="/time/actual"
        >
          <BaseButton
            :tabindex="-1"
            variant="white"
          >
            {{ $t('View all time entries') }}
          </BaseButton>
        </router-link>
      </div>
      <div class="flex space-x-2">
        <BaseButton variant="white" @click="$emit('cancel')">
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton variant="primary" @click="$emit('cancel')">
          {{ $t('Close') }}
        </BaseButton>
      </div>
    </template>
  </BaseEntityForm>
</template>
<script>
import { cloneDeep, get } from 'lodash-es'
import { formatISO } from 'date-fns'
import TimeInput from '@/components/form/TimeInput.vue'
import TaskSelect from '@/components/selects/TaskSelect.vue'
import UserSelect from '@/components/selects/UserSelect.vue'
import GroupSelect from '@/components/selects/GroupSelect.vue'
import ProjectSelect from '@/components/selects/ProjectSelect.vue'
import { TimeCreationMethods } from '@/modules/time/utils/timeEntryUtils.js'
import TimeEntriesTable from '@/modules/time/components/TimeEntriesTable.vue'
import TimeEntryCustomFields from '@/modules/time/components/TimeEntryCustomFields.vue'
import { getCustomFieldValuesStringified } from '@/modules/accounts/utils/modelUtils'

const emptyModel = {
  group_id: null,
  user_id: null,
  project_id: null,
  task_id: null,
  worked_minutes: null,
  custom_fields: {},
  date: new Date(),
  type: TimeCreationMethods.Timer,
  timer_started_at: formatISO(new Date()),
}

export default {
  name: 'TimeEntryTimerForm',
  components: {
    TimeEntryCustomFields,
    TimeEntriesTable,
    ProjectSelect,
    UserSelect,
    GroupSelect,
    TaskSelect,
    TimeInput,
  },
  props: {
    timeEntry: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      model: structuredClone(emptyModel),
      timeEntriesTimer: [],
    }
  },
  emits: ['cancel'],
  computed: {
    showTimeActualLink() {
      return !this.$route.path.includes('time/actual')
    },
    lastUserEntries() {
      return get(this.$store.state, 'time.lastRecordedTimeEntries.data', [])
    },
    submitDisabled() {
      return !(this.model.user_id && this.model.project_id) || !this.can(this.$actions.TASK_TIMERS)
    },
  },
  methods: {
    async startTimer() {
      try {
        this.loading = true

        const timeEntry = cloneDeep(this.model)
        timeEntry.custom_fields = getCustomFieldValuesStringified(timeEntry.custom_fields)

        const { data } = await this.$store.dispatch('time/addTimeEntryTimer', {
          model: this.model,
        })

        this.resetForm()

        this.$refs.formWrapper.resetForm()
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      const { user_id, user } = this.model
      const project_id = this.$store.getters.project_id || null

      this.model = {
        ...emptyModel,
        user_id,
        user,
        project_id,
      }
    },
    setDefaults() {
      let project = null, project_id = null

      if (this.$store.getters.project_id) {
        project_id = this.$store.getters.project_id
        project = this.$store.state.projects.currentProject
      }

      const user_id = this.$user.id
      const user = {
        id: user_id,
        attributes: {
          id: user_id,
          name: this.$user.name,
        },
      }

      this.model = {
        ...emptyModel,
        project_id,
        project,
        user_id,
        user,
      }

      if (!this.lastUserEntries.length) {
        return
      }
      this.timeEntriesTimer = this.lastUserEntries
    },
    initFormData() {
      if (!this.timeEntry.id) {
        return this.setDefaults()
      }

      this.model = {
        ...this.model,
        ...this.timeEntry.attributes,
        ...this.timeEntry.relationships,
      }
      this.timeEntriesTimer.push(this.timeEntry)
    },
    async onGroupChanged() {
      await this.$nextTick()

      if (!this.model.group_id) {
        return
      }

      this.model.user_id = null
    },
  },
  created() {
    this.initFormData()
  },
  watch: {
    lastUserEntries() {
      this.timeEntriesTimer = this.lastUserEntries
    }
  },
}
</script>
