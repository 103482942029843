<template>
  <div class="relative">
    <!-- Sidebar open/close (mobile)-->
    <div
      class="fixed top-0 w-full z-40 flex items-center gap-x-6 body-background px-4 py-2 sm:px-6 lg:hidden"
    >
      <button
        type="button" class="-m-2.5 p-2.5 text-gray-400 lg:hidden"
        @click="sidebarVisible = true"
      >
        <span class="sr-only">{{ $t('Open sidebar') }}</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
        </svg>
      </button>
    </div>
    <!-- Static sidebar for desktop -->
    <div
      v-show="sidebarVisible"
      class="relative z-50 flex flex-col max-w-xs lg:fixed lg:inset-y-0 lg:w-60"
      role="dialog"
      aria-modal="true"
    >
    <div class="absolute -right-10 top-0 flex justify-center lg:hidden z-50 body-background rounded-lg">
      <button
        type="button"
        class="p-1"
        @click="sidebarVisible = false"
      >
        <span class="sr-only">{{ $t('Close sidebar') }}</span>
        <i class="fa-solid fa-circle-xmark text-2xl text-white/80" />
      </button>
    </div>
      <div class="fixed inset-0 lg:hidden"></div>
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="sidebar-wrapper fixed inset-0 flex flex-col grow lg:relative overflow-y-auto">
        <div class="sidebar flex grow flex-col gap-y-2 body-background px-6 lg:my-4 relative max-w-xs lg:max-w-full ring-1 ring-white/10 lg:ring-0 overflow-y-auto">

          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-6">
              <li>
                <div class="h-16 lg:h-auto lg:pt-2 flex shrink-0 items-center">
                  <router-link
                    to="/welcome"
                  >
                    <img
                      class="max-h-10 w-auto"
                      :src="logoSrc"
                      alt="Project.co"
                    >
                  </router-link>
                </div>
              </li>
              <!-- Navigation buttons - back, forward etc -->
              <li id="sidebar-quick-actions">
                <SidebarNavigationButtons
                  @item-clicked="$isMobileDevice && (sidebarVisible = false)"
                />
              </li>
              <!-- Main navigation items -->
              <li id="sidebar-navigation-items">
                <ul role="list" class="-mx-2 -mt-2">
                  <li
                    v-for="item of availableCards"
                    :id="item.id"
                    :key="item.title"
                    class="group breadcrumb-color rounded-md py-2 pl-2 flex items-center"
                    :class="{
                      'bg-white/10': item.isActive,
                      'opacity-60 hover:opacity-100 hover:bg-white/10': !item.isActive,

                    }"
                    @click="$isMobileDevice && (sidebarVisible = false)"
                  >
                    <router-link
                      :to="item.path"
                      class="flex-none text-sm leading-4 font-medium grow flex items-center"
                    >
                      <i
                        class="fa-light text-lg text-center leading-5 h-5 w-5"
                        :class="{
                          [item.iconClass]: item.iconClass,
                        }"
                      />
                      <span
                        class="text-sm leading-4 font-medium ml-3"
                      >
                        {{ item.title }}
                      </span>
                    </router-link>
                    <router-link
                      v-if="item.createRoute"
                      :to="item.createRoute"
                      class="inline-flex lg:hidden group-hover:inline-flex flex-none px-2 breadcrumb-color opacity-60 hover:opacity-100 lg:border-l lg:border-[var(--body-background)]"
                    >
                      <i class="fa-solid fa-plus" />
                    </router-link>
                  </li>
                </ul>
              </li>
              <li id="sidebar-starred-pages">
                <SidebarStarredPages
                  @item-clicked="$isMobileDevice && (sidebarVisible = false)"
                />
              </li>
              <li class="-mx-6 mt-auto">
                <SidebarUpgradeButton />
                <SidebarGettingStarted />
                <SidebarProfileDropdown />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!--Sidebar open/close (desktop)-->
    <div
      class="hidden lg:inline fixed bottom-6 h-fit body-background pr-1 py-3 rounded-r-md cursor-pointer z-50"
      :class="{
        'left-4': !sidebarVisible,
        'left-60': sidebarVisible,
      }"
      @click="sidebarVisible = !sidebarVisible"
    >
      <i
        class="fa-solid breadcrumb-color"
        :class="{
          'fa-chevron-right': !sidebarVisible,
          'fa-chevron-left': sidebarVisible,
        }"
      />
    </div>
    <!--Sidebar open/close-->
  </div>
</template>
<script lang="ts" setup>
// Components
import SidebarStarredPages from '@/modules/dashboard/components/SidebarStarredPages.vue'
import SidebarNavigationButtons from '@/modules/dashboard/components/SidebarNavigationButtons.vue'
import SidebarProfileDropdown from '@/modules/dashboard/components/SidebarProfileDropdown.vue'
import SidebarGettingStarted from '@/modules/dashboard/components/SidebarGettingStarted.vue'
import SidebarUpgradeButton from '@/modules/dashboard/components/SidebarUpgradeButton.vue'

// Utils
import { computed, onBeforeMount } from 'vue'
import { getHeaderLogo } from '@/modules/accounts/utils/accountBrandingUtils'

// Composables
import { useNavigationCards } from "@/modules/dashboard/utils/useNavigationCards"
import useLayout from "@/modules/common/composables/useLayout";
import useMobileUtils from "@/modules/common/composables/useMobileUtils"

const {
  availableCards
} = useNavigationCards()

const {
  sidebarVisible
} = useLayout()

const logoSrc = computed(() => {
  return getHeaderLogo()
})

const {
  isMobileDevice,
} = useMobileUtils()

function initSidebarVisibility() {
  if (isMobileDevice.value) {
    sidebarVisible.value = false
  }
}

onBeforeMount(() => {
  initSidebarVisibility()
})
</script>
