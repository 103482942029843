<template>
  <BaseDropdown
    :options="availableOptions"
    :slim="slim"
    divide-items
    @action="onAction"
  >
    <template #default>
      <slot />
    </template>
  </BaseDropdown>
</template>
<script>
import { TimerStatusTypes } from '@/modules/time/utils/timeEntryUtils.js'
import i18n from '@/i18n'

export default {
  props: {
    timeEntry: {
      type: Object,
      default: () => ({}),
    },
    slim: {
      type: Boolean,
      default: false,
    },
    authorizedToEdit: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['delete'],
  computed: {
    project() {
      return this.timeEntry?.relationships?.project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    timeEntryPath() {
      return {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          timeEntryId: this.timeEntry.id,
        },
      }
    },
    isRecordingEntry() {
      const status = this.timeEntry.attributes?.status || this.timeEntry.status
      return status === TimerStatusTypes.Recording
    },
    options() {
      return [
        {
          label: i18n.t('Edit'),
          path: this.timeEntryPath,
          enabled: () => {
            return this.authorizedToEdit && !this.isRecordingEntry && !this.isProjectClosed && this.can(this.$actions.EDIT_TIME_ENTRIES)
          },
        },
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.DELETE_TIME_ENTRIES)
          },
        },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    },
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.timeEntry)
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Delete Time Entry'),
        description: i18n.t('Are you sure? This will permanently remove the time entry.'),
      })

      if (!confirmed) {
        return
      }

      await this.$store.dispatch('time/deleteTimeEntry', this.timeEntry.id)
      this.$store.dispatch('time/getRecordedTimeEntriesSummary')
    },
  },
}
</script>
