import { createApp } from "vue";
import store from "@/store/index.js";
import i18n from "@/i18n.js";
import router from "@/router/index.js";
import globalPlugins from "@/plugins/globalPlugins.js";
import globalComponents from "@/plugins/globalComponents.js";
import { flareVue } from "@flareapp/flare-vue";

function initMonitoring(app) {
  if (import.meta.env.DEV) {
    return
  }

  app.use(flareVue);
}

function createAppWithPlugins(options) {
  const app = createApp(options)
  app.use(store)
  app.use(i18n)
  app.use(globalPlugins)
  app.use(globalComponents)

  initMonitoring(app)
  app.use(router)
  return app
}

export default createAppWithPlugins;
