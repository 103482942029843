<template>
  <div v-loading="creatingProject">
    <BaseDropdown
      :options="availableOptions"
      :slim="slim"
      :auto-open="true"
      divide-items
      @action="onAction"
    >
      <template #default>
        <slot />
      </template>
    </BaseDropdown>
    <ShareTemplateDialog
      v-if="showShareTemplateDialog"
      v-model="showShareTemplateDialog"
      :template="template"
      @close="showShareTemplateDialog = false"
    />
  </div>
</template>
<script>
import ShareTemplateDialog from "@/modules/templates/components/ShareTemplateDialog.vue"
import { AccountPermissions } from '@/modules/common/composables/useCan';

export default {
  components: {
    ShareTemplateDialog,
  },
  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showShareTemplateDialog: false,
      creatingProject: false,
    }
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('Open Template'),
          path: `/templates/${this.template.id}`
        },
        {
          label: this.$t('Share Template'),
          action: 'shareTemplate',
          requiresPermissionTo: this.$actions.CREATE_PROJECT_TEMPLATES
        },
        {
          label: this.$t('Create Project'),
          action: 'createProjectFromTemplate',
          requiresPermissionTo: this.$actions.CREATE_PROJECTS,
          limitedFeature: AccountPermissions.ActiveProjects
        },
        {
          label: this.$t('Delete'),
          action: 'confirmDelete',
          requiresPermissionTo: this.$actions.DELETE_PROJECTS
        },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.template)
    },
    async createProjectFromTemplate() {
      this.$store.dispatch('templates/triggerCreateProjectFromTemplate', {
        templateId: this.template?.id,
        template: this.template,
      })
    },
    shareTemplate() {
      this.showShareTemplateDialog = true
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete Template'),
        description: this.$t('Are you sure? This will permanently remove the template.')
      });

      if(!confirmed) {
        return;
      }

      await this.$store.dispatch('projects/deleteProject', this.template.id);
    }
  },
};
</script>
