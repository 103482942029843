<template>
  <div class="pb-10">
    <!-- ALLOCATED TIME -->
    <h2
      class="text-2xl font-bold pl-4 capitalize flex items-center group-title bg-gray-50 py-2 border-gray-200"
      :class="{
        '-mx-8 border-t': $useNewLayout,
        'rounded-t-lg shadow border': !$useNewLayout,
      }"
    >
      {{ $t("Allocated Time") }}
    </h2>
    <AllocatedTimeTable
      :data="allocatedTime"
      :loading="isLoadingAllocatedTime"
      class="max-h-[400px]"
    />
    <!-- ACTUAL TIME -->
    <h2
      class="text-2xl font-bold pl-4 capitalize flex items-center group-title bg-gray-50 py-2 mt-6 border-gray-200"
      :class="{
        '-mx-8 border-t': $useNewLayout,
        'rounded-t-lg shadow border': !$useNewLayout,
      }"
    >
      {{ $t("Actual Time") }}
    </h2>
    <TimeEntriesTable
      :data="timeEntries"
      :loading="isLoadingTimeEntries"
      class="max-h-[400px]"
    />
  </div>
</template>
<script lang="ts" setup>
// Utils
import { computed, ref, watch } from "vue";

// Components
import TimeEntriesTable from "@/modules/time/components/TimeEntriesTable.vue"
import AllocatedTimeTable from "@/modules/time/components/AllocatedTimeTable.vue"

// Composables
import { useStore } from "vuex"
const store = useStore()

// Time entries
const timeEntriesLoading = computed(() => {
  return store.state.time.timeEntriesLoading
})

const isLoadingTimeEntries = ref(true)

watch(() => timeEntriesLoading.value, (value) => {
  isLoadingTimeEntries.value = value
}, { immediate: true })

const timeEntries = computed(() => {
  if (isLoadingTimeEntries.value) {
    return []
  }

  return store.getters['time/currentTimeEntries'] || []
})

// Time allocations
const allocatedTimeLoading = computed(() => {
  return store.state.time.allocatedTimeLoading
})

const isLoadingAllocatedTime = ref(true)

watch(() => allocatedTimeLoading.value, (value) => {
  isLoadingAllocatedTime.value = value
}, { immediate: true })

const allocatedTime = computed(() => {
  if (isLoadingAllocatedTime.value) {
    return []
  }

  return store.getters['time/currentAllocatedTime'] || []
})
</script>
