<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    overflowVisible
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <div class="max-w-2xl m-auto align-middle bg-white border border-gray-200 overflow-hidden rounded-lg shadow">
      <div class="w-full border-b border-gray-200 p-6">
        <h3 class="text-2xl font-bold">
          {{ editProofId ? $t('Edit proof') : $t("Create new proof") }}
        </h3>
      </div>
      <FileProofForm
        @save="$emit('save')"
        @cancel="$emit('close')"
      />
    </div>
  </BaseDialog>
</template>
<script>
import FileProofForm from "@/modules/file-proofs/components/FileProofForm.vue";
import UpgradePlanWarning from '@/modules/accounts/components/UpgradePlanWarning.vue'

export default {
  name: 'FileProofDialog',
  inheritAttrs: false,
  components: {
    UpgradePlanWarning,
    FileProofForm,
  },
  emits: ['save', 'close'],
  computed: {
    editProofId() {
      if (this.$route?.fullPath.includes('/file-proofs/list')) {
        return this.$route?.query?.edit
      }
      return ''
    },
  }
}
</script>
