<template>
  <BaseSelect
    v-model="model"
    :options="notePrivacyOptions"
    :disabled="$attrs.disabled"
    :return-object="false"
    filterable
  />
</template>
<script lang="ts" setup>
import { notePrivacyOptions } from "@/modules/projects/utils/noteUtils";
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: '',
  }
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const selectedOption = computed(() => {
  return notePrivacyOptions.value?.find(o => o.value === model.value)
})
</script>
