<template>
  <li>
    <div class="flex items-center space-x-4">
      <div class="shrink-0">
        <router-link :to="`/users/${user.id}`">
          <BaseLogo
            :entity="user"
            logo-key="attributes.avatar"
          />
        </router-link>
      </div>
      <div class="flex-1 min-w-0">
        <router-link :to="`/users/${user.id}`">
          <p class="font-bold text-gray-700 truncate">
            {{ user?.attributes?.name || user?.attributes?.email }}
            <UserRoleBadge
              :user="user"
            />
          </p>
        </router-link>
        <p class="text-sm truncate">
          <span class="font-bold text-gray-700 mr-1">{{ $t('Notifications:') }}</span>
          <span v-if="hasAllProjectNotifications" class="font-bold text-green-500">{{ $t('ON') }}</span>
          <span v-if="hasNoNotifications" class="font-bold text-red-500">{{ $t('OFF') }}</span>
          <span v-if="hasUserRelatedNotifications" class="font-bold text-yellow-500">{{ $t('Related notifications only') }}</span>
        </p>
      </div>
      <BaseDropdown
        :options="userOptions"
        :label="$t('Options')"
        trigger="hover"
        class="relative lg:inline-block text-left"
        button-size="sm"
        divide-items
      />
    </div>
  </li>
</template>
<script>
// Components
import UserRoleBadge from "@/components/common/UserRoleBadge.vue";

// Helpers
import apiCache from '@/modules/common/utils/apiCache';
import { set } from "lodash-es";

export default {
  components: {
    UserRoleBadge
  },
  name: 'ProjectUser',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    projectId() {
      return this.project.id
    },
    notificationSettings() {
      return this.user?.pivots?.notifiable_settings || {}
    },
    hasAllProjectNotifications() {
      return this.notificationSettings.notifiable_on_project === true
    },
    hasUserRelatedNotifications() {
      return this.notificationSettings.user_related_notifications === true && !this.notificationSettings.notifiable_on_project
    },
    hasNoNotifications() {
      return !this.notificationSettings.user_related_notifications && !this.notificationSettings.notifiable_on_project
    },
    canPerformActionForUser() {
      if (this.user?.id?.toString() === this.$user?.id?.toString()) {
        return true
      }

      if (this.can(this.$actions.CHANGE_PROJECT_SETTINGS_FOR_ANY_USER)) {
        return true
      }

      const userGroups = this.user.relationships?.groups || []

      const haveSharedGroups = userGroups.some(userGroup => {
        return this.currentUserGroups.some(currentUserGroup => currentUserGroup.id.toString() === userGroup.id.toString())
      })

      return haveSharedGroups
    },
    canToggleNotificationsForUser() {
     return this.canPerformActionForUser
    },
    canRemoveUser() {
      return this.canPerformActionForUser
    },
    userOptions() {
      return [
        {
          label: this.$t('All notifications'),
          enabled: () => {
            return this.canToggleNotificationsForUser
          },
          action: () => this.enableAllNotifications(),
          variant: this.hasAllProjectNotifications ? 'active' : '',
        },
        {
          label: this.$t('Related notifications only'),
          enabled: () => {
            return this.canToggleNotificationsForUser
          },
          action: () => this.enableUserRelatedNotification(),
          variant: this.hasUserRelatedNotifications ? 'active' : '',
          tooltip: this.$t('Notifications will only be sent when the user is @mentioned, allocated, a follower or is specifically selected to be notified.\n')
        },
        {
          label: this.$t('Notifications off'),
          enabled: () => {
            return this.canToggleNotificationsForUser
          },
          action: () => this.disableAllNotifications(),
          variant: this.hasNoNotifications ? 'active' : '',
        },
        {
          label: this.$t('Remove from project'),
          enabled: () => {
            return this.canRemoveUser
          },
          action: () => this.removeUser(),
        }
      ]
    },
  },
  methods: {
    async removeUser() {
      if (!this.user?.id) {
        return
      }

      await this.$store.dispatch('projects/removeProjectUsers', {
        projectId: this.projectId,
        users: [this.user?.id]
      })
      
      apiCache.removeForEntity('users')

      const isRemovingSelf = this.user?.id?.toString() === this.$user?.id?.toString()
      const noLongerHasAccess = [
        this.$roles.COLLABORATOR,
        this.$roles.COLLABORATOR_PLUS
      ].includes(this.$userRole)
      
      if (isRemovingSelf && noLongerHasAccess) {
        await this.$router.push('/projects')
        return;
      }

      await this.getProjectSilently()
    },
    async enableAllNotifications() {
      const notifiable_settings = {
        ...this.notificationSettings
      }

      notifiable_settings.user_related_notifications = true
      notifiable_settings.notifiable_on_project = true

      await this.$store.dispatch('projects/toggleNotificationsForUser', { user: this.user, project: this.project, notifiable_settings })
      set(this.user, 'pivots.notifiable_settings', notifiable_settings)
    },
    async disableAllNotifications() {
      const notifiable_settings = {
        ...this.notificationSettings
      }

      notifiable_settings.user_related_notifications = false
      notifiable_settings.notifiable_on_project = false

      await this.$store.dispatch('projects/toggleNotificationsForUser', { user: this.user, project: this.project, notifiable_settings })
      set(this.user, 'pivots.notifiable_settings', notifiable_settings)
    },
    async enableUserRelatedNotification(user) {
      const previousSettings = this.notificationSettings
      const notifiable_settings = {
        ...previousSettings,
      }
      
      notifiable_settings.user_related_notifications = true
      notifiable_settings.notifiable_on_project = false

      await this.$store.dispatch('projects/toggleNotificationsForUser', { user: this.user, project: this.project, notifiable_settings })
      set(this.user, 'pivots.notifiable_settings', notifiable_settings)
    },
    async getProjectSilently() {
      await this.$store.dispatch('projects/getProjectById', {
        id: this.project.id,
        silent: true
      })
    },
  },
}
</script>
