import { VueNodeViewRenderer } from '@tiptap/vue-3'
import CodeBlockComponent from "@/components/html/extensions/components/CodeBlockComponent.vue";
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { createLowlight, common } from 'lowlight'
const lowlight = createLowlight(common)

const CodeBlockExtension = CodeBlockLowlight.extend({
  addNodeView() {
    return VueNodeViewRenderer(CodeBlockComponent)
  },
}).configure({ lowlight, defaultLanguage: null, })

export default CodeBlockExtension
