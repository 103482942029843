import { useWebWorkerFn } from '@vueuse/core'
import store from "@/store/index.js";
import Data = API.Data;
import Media = App.Domains.Media.Models.Media;
import Project = App.Domains.Projects.Models.Project;
import Task = App.Domains.Tasks.Models.Task;
import Folder = App.Domains.Folders.Models.Folder;
import User = App.Domains.Users.Models.User;

type HierarchyMap = { [key: string | number]: (string | number)[] }

function getFolderPath(folder: Data<Folder>, filesTreeData: Array<Data<Folder> | Data<Media>>, pathsMap: HierarchyMap) {
  if (!folder) {
    return []
  }

  if (!folder.attributes.parent_folder_id) {
    pathsMap[folder.id] = [`projects_${folder.attributes.project_id}`, `folders_${folder.id}`]

    return pathsMap[folder.id]
  }

  const parentFolder = filesTreeData.find((f: any) => f.id == folder.attributes.parent_folder_id && f.type == 'folders')
  const parentFolderPath = getFolderPath(parentFolder as Data<Folder>, filesTreeData, pathsMap)

  pathsMap[parentFolder.id] = parentFolderPath
  pathsMap[folder.id] = [...parentFolderPath, `folders_${folder.id}`]

  return pathsMap[folder.id]
}

function getFilePath(file: Data<Media>, filesTreeData: Array<Data<Folder> | Data<Media>>, pathsMap: HierarchyMap) {
  if (!file.attributes.folder_id) {
    return [`projects_${file.attributes.project_id}`, `media_${file.id}`]
  }

  const path = getFolderPath(file.relationships.folder, filesTreeData, pathsMap)

  return [...path, `media_${file.id}`]
}

export function getFileWithRelationshipsAndPath(
  file: Data<Media>,
  allProjects?: Array<Data<Project>>,
  allTasks?: Array<Data<Task>>,
  allUsers?: Array<Data<User>>,
  filesTreeData?: Array<Data<Folder> | Data<Media>>,
) {
  // @ts-ignore
  allProjects = allProjects || store.state.projects.allProjects || []
  // @ts-ignore
  allTasks = allTasks || store.state.tasks.allTasks || []
  // @ts-ignore
  allUsers = allUsers || store.state.users.allUsers || []
  // @ts-ignore
  filesTreeData = filesTreeData || store.state.files.filesTreeData || []

  const project = allProjects!.find(p => p.id == file.attributes.project_id)
  const creator = allUsers!.find(u => u.id == file.attributes.created_by)
  const folder = filesTreeData!.find(f => f.id == file.attributes.folder_id && f.type === 'folders')

  let task = file.relationships?.task

  if (!task && file.attributes?.model_type === 'task') {
    task = allTasks.find(t => t.id == file.attributes?.model_id)
  }

  const fileWithRelationships = {
    ...file,
    id: Number(file.id),
    relationships: {
      ...(file.relationships || {}),
      project,
      task,
      creator,
      folder
    }
  }

  const path = getFilePath(fileWithRelationships, filesTreeData, {} as HierarchyMap)

  return {
    ...fileWithRelationships,
    path,
  }
}

export function getFolderWithRelationshipsAndPath(
  folder: Data<Folder>,
  allProjects?: Array<Data<Project>>,
  filesTreeData?: Array<Data<Folder> | Data<Media>>,
) {
  // @ts-ignore
  allProjects = allProjects || store.state.projects.allProjects || []
  // @ts-ignore
  filesTreeData = filesTreeData || store.state.files.filesTreeData || []

  const project = allProjects!.find(p => p.id == folder.attributes.project_id)
  const parentFolder = filesTreeData!.find(f => f.id == folder.attributes.parent_folder_id && f.type === 'folders')

  const folderWithRelationships = {
    ...folder,
    id: Number(folder.id),
    relationships: {
      ...(folder.relationships || {}),
      project,
      parentFolder,
    }
  }

  const path = getFolderPath(folderWithRelationships, filesTreeData, {} as HierarchyMap)

  return {
    ...folderWithRelationships,
    path,
  }
}

function getFilesTreeData(
  allFiles: Array<Data<Media>>,
  allFolders: Array<Data<Folder>>,
  allProjects: Array<Data<Project>>,
  allTasks: Array<Data<Task>>,
  allUsers: Array<Data<User>>,
) {

  function getFolderPathWorker(folder: Data<Folder>, folders: Array<Data<Folder>>, pathsMap: HierarchyMap) {
    if (!folder) {
      return []
    }

    if (!folder.attributes.parent_folder_id) {
      pathsMap[folder.id] = [`projects_${folder.attributes.project_id}`, `folders_${folder.id}`]

      return pathsMap[folder.id]
    }

    const parentFolder = folders.find((f: any) => f.id == folder.attributes.parent_folder_id)
    const parentFolderPath = getFolderPathWorker(parentFolder, folders, pathsMap)

    pathsMap[parentFolder.id] = parentFolderPath
    pathsMap[folder.id] = [...parentFolderPath, `folders_${folder.id}`]

    return pathsMap[folder.id]
  }

  function getFilePathWorkder(file: Data<Media>, folders: Array<Data<Folder>>, pathsMap: HierarchyMap) {
    if (!file.attributes.folder_id) {
      return [`projects_${file.attributes.project_id}`, `media_${file.id}`]
    }

    const path = getFolderPathWorker(file.relationships.folder, folders, pathsMap)

    return [...path, `media_${file.id}`]
  }

  const folderPathsMap = {} as HierarchyMap

  const filesAndFolders = [...allFolders, ...allFiles]

  const treeData = filesAndFolders.map((fileOrFolder: Data<Media> | Data<Folder>) => {
    const isFile = fileOrFolder.type === 'media'
    const project = fileOrFolder.relationships?.project || allProjects.find(p => p.id == fileOrFolder.attributes.project_id)

    const relationships: {
      project?: Data<Project>
      task?: Data<Task>
      folder?: Data<Folder>
      parentFolder?: Data<Folder>,
      creator?: Data<User>
    } = {
      ...(fileOrFolder.relationships || {}),
      project,
    }

    if (isFile) {
      let task = fileOrFolder.relationships?.task
      let file = fileOrFolder as Data<Media>

      if (!task && file.attributes?.model_type === 'task') {
        task = allTasks.find(t => t.id == file.attributes?.model_id)
      }

      relationships.task = task
      relationships.folder = allFolders.find(f => f.id == file.attributes.folder_id)
      relationships.creator = allUsers.find(u => u.id == file.attributes.created_by)
    }
    else {
      let folder = fileOrFolder as Data<Folder>
      // We want to avoid an infinite loop in the case of a folder being its own parent
      if (folder.id === folder.attributes.parent_folder_id) {
        folder.attributes.parent_folder_id = null
      }
      relationships.parentFolder = allFolders.find(f => f.id == folder.attributes.parent_folder_id)
    }

    const fileOrFolderWithRelationships = {
      ...fileOrFolder,
      relationships
    }

    const path = isFile
      ? getFilePathWorkder(fileOrFolderWithRelationships as Data<Media>, allFolders, folderPathsMap)
      : getFolderPathWorker(fileOrFolderWithRelationships as Data<Folder>, allFolders, folderPathsMap)

    return {
      ...fileOrFolderWithRelationships,
      id: Number(fileOrFolderWithRelationships.id),
      path,
    }
  })

  return treeData
}

function computeFoldersTree(filesTreeData: Array<Data<Folder> | Data<Media>>) {
  function getChildrenWorker(folder: Data<Folder>, filesTreeData: Array<Data<Folder> | Data<Media>>) {
    const children = filesTreeData.filter(f => {
      if (f.type !== 'folders') {
        return false
      }

      return (f as Data<Folder>).attributes?.parent_folder_id == folder.id
    })

    for (let child of children) {
      child.relationships.children = getChildrenWorker(child as Data<Folder>, filesTreeData)
    }

    return children
  }

  const foldersTree: any[] = []

  for (let fileOrFolder of filesTreeData) {
    if (fileOrFolder.type === 'media') {
      continue
    }

    const children = getChildrenWorker(fileOrFolder as Data<Folder>, filesTreeData)

    foldersTree.push({
      ...fileOrFolder,
      id: Number(fileOrFolder.id),
      relationships: {
        ...fileOrFolder.relationships,
        children
      }
    })
  }

  return foldersTree.filter(folder => !folder.attributes?.parent_folder_id)
}

export const { workerFn: computeFilesTreeDataWorker } = useWebWorkerFn(getFilesTreeData)
export const { workerFn: computeFoldersTreeWorker } = useWebWorkerFn(computeFoldersTree)
