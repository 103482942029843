import Link from "@tiptap/extension-link";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { getAttributes } from "@tiptap/core";
import { isExternalLink } from "@/utils/global";

export default Link.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      openOnClick: false
    };
  },
  addProseMirrorPlugins() {
    const plugins: Plugin[] = this.parent?.() || [];

    const clickHandler = new Plugin({
      key: new PluginKey("clickHandler"),
      props: {
        handleClick(view, pos, event) {
          if (!view.editable) {
            return false
          }

          const attrs = getAttributes(view.state, "link");
          const link = (event.target as HTMLElement)?.closest("a");
          const href = link?.getAttribute('href')
          const isExternal = isExternalLink(href);

          if (href && isExternal) {
            window.open(href, attrs.target);
            return true;
          }

          return false;
        }
      }
    });

    plugins.push(clickHandler);

    return plugins;
  }
});
