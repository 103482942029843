<template>
  <div class="isolate flex flex-wrap gap-2 overflow-hidden items-center">
    <slot name="label" />
    <div
      v-for="group of displayedGroups"
      :key="group.id"
      class="bg-gray-50 hover:bg-gray-100 text-xs text-gray-400 hover:text-gray-500 p-0.5 pr-2 border border-gray-200 rounded-full cursor-pointer flex items-center space-x-1"
    >
      <GroupLogo
        :group="group"
        :actions="actions"
        class="flex items-center"
        size="xs"
        rounded="full"
        @remove="emit('remove', group)"
      >
        <template #after>
          <span>{{ group.attributes.name }}</span>
        </template>
      </GroupLogo>
    </div>
    <div
      v-if="groups.length > displayMaxCount"
      class="bg-gray-50 hover:bg-gray-100 text-xs text-gray-400 hover:text-gray-500 p-0.5 pr-2 border border-gray-200 rounded-full cursor-pointer flex items-center space-x-1"
      @click="showAll = !showAll"
    >
      <span class="ml-1">
        {{ showMoreLabel }}
      </span>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Components
import GroupLogo from "@/components/common/GroupLogo.vue";

// Utils
import { PropType, computed, ref } from 'vue';
import i18n from "@/i18n";

import Data = API.Data
import Group = App.Domains.Groups.Models.Group

import {
  UserPreviewActions,
} from "@/modules/users/util/userUtils";

const props = defineProps({
  groups: {
    type: Array as PropType<Data<Group>[]>,
    default: () => []
  },
  actions: {
    type: Array as PropType<UserPreviewActions[]>,
    default: () => [UserPreviewActions.View]
  },
  displayMaxCount: {
    type: Number,
    default: 5
  }
})

const emit = defineEmits(['remove'])

const showAll = ref(false)

const displayedGroups = computed(() => {
  if (showAll.value) {
    return props.groups
  }

  return props.groups.slice(0, props.displayMaxCount)
})

const showMoreLabel = computed(() => {
  if (showAll.value) {
    return i18n.t('Show less')
  }
  return i18n.tc('plus count others', {
    count: props.groups.length - props.displayMaxCount
  })
})
</script>
