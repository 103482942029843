import axios from 'axios'
import { getSetting } from "@/plugins/settingsPlugin";

let apiClient

export function getUnsplashClient() {
  if (!apiClient) {
    apiClient = axios.create({
      baseURL: 'https://api.unsplash.com',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        client_id: import.meta.env.VITE_UNSPLASH_CLIENT_ID
      }
    })
  }

  return apiClient
}

export function isUnsplashConfigured() {
  const client_id = import.meta.env.VITE_UNSPLASH_CLIENT_ID
  if (!client_id) {
    console.warn('Unsplash cannot be initialized. CLIENT_ID (Access key) is missing from env')
  }
  return !!client_id
}

export async function searchUnsplashPhotos({query, page, per_page}) {
  if (!query) {
    throw 'Query cannot be empty';
  }

  return await getUnsplashClient().get('/search/photos', {
    params: {
      query,
      page,
      per_page
    }
  })
}

const defaultCollectionId = import.meta.env.VITE_UNSPLASH_COLLECTION_ID
export async function getRandomUnsplashPhoto(params = {}) {
  const collectionId = getSetting('unsplash_collection_id') || defaultCollectionId
  const result = await getUnsplashClient().get('/photos/random', {
    params: {
      collections: collectionId,
      ...params,
    }
  })
  return result.data
}

export const DefaultUnsplashCoverPhoto = '/img/default-cover-image.png'
export const DefaultUnsplashPortraitPhoto = '/img/default-project-image.png'
