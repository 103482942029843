<template>
  <BasePopoverInput
    :disabled="result.success"
    @show="setQuery"
  >
    <template #reference>
      <Status
        v-if="isStatusBinding"
        :row="{
          attributes: {
            status_id: result.value
          }
        }"
        :params="{
          target: result.type === 'task_status' ? 'task' : 'project'
        }"
      />
      <span
        v-else
        :class="{
          'bg-red-500 text-white cursor-pointer px-2 rounded-full': !result.success,
          'bg-gray-100 border px-2 py-0.5 rounded-full': result.success && isEntityBinding,
        }"
      >
        {{ result.query }}
        <span
          v-if="!result.success"
          class="ml-1"
        >
          - {{ result.error }}
          <BaseTooltip
            v-if="result.clearable"
            :content="$t('Remove')"
          >
            <i
              class="far fa-circle-xmark ml-1"
              @click.stop="emit('discard', result)"
            />
          </BaseTooltip>
        </span>
      </span>
    </template>
    <UserSelectNew
      v-if="result.type == entityTypes.User"
      ref="select"
      v-model="model"
      :placeholder="$t('Select user...')"
      :initQuery="result.query"
      @raw-change="resolveUserBinding"
    />
    
    <GroupSelectNew
      v-else-if="result.type == entityTypes.Group"
      ref="select"
      v-model="model"
      v-bind="result.extraParams || {}"
      :placeholder="$t('Select group...')"
      :initQuery="result.query"
      @raw-change="resolveGroupBinding"
    />

    <ProjectSelectNew
      v-else-if="result.type == entityTypes.Project"
      ref="select"
      v-model="model"
      v-bind="result.extraParams || {}"
      :placeholder="$t('Select project...')"
      :initQuery="result.query"
      @raw-change="resolveProjectBinding"
    />

    <TaskSelectNew
      v-else-if="result.type == entityTypes.Task"
      ref="select"
      v-model="model"
      v-bind="result.extraParams || {}"
      :placeholder="$t('Select task...')"
      :initQuery="result.query"
      @raw-change="resolveTaskBinding"
    />

  </BasePopoverInput>
</template>
<script lang="ts" setup>
// Components
import UserSelectNew from '@/components/selects/UserSelectNew.vue'
import GroupSelectNew from '@/components/selects/GroupSelectNew.vue'
import ProjectSelectNew from '@/components/selects/ProjectSelectNew.vue'
import TaskSelectNew from '@/components/selects/TaskSelectNew.vue'

// Utils
import { PropType, computed, ref } from 'vue';
import { BindingResult } from "@/modules/ai/filters/DataBinder"
import { entityTypes } from '@/modules/common/enum/entityTypes'

import Data = API.Data;
import User = App.Domains.Users.Models.User;
import Group = App.Domains.Groups.Models.Group;
import Project = App.Domains.Projects.Models.Project;
import Task = App.Domains.Tasks.Models.Task;

const props = defineProps({
  result: {
    type: Object as PropType<BindingResult>,
    required: true
  }
})

const emit = defineEmits([
  'discard',
  'resolve'
])

const isEntityBinding = computed(() => {
  return [
    entityTypes.User,
    entityTypes.Group,
    entityTypes.ProjectTool,
  ].includes(props.result.type)
})

const isStatusBinding = computed(() => {
  return [
    entityTypes.TaskStatus,
    entityTypes.ProjectStatus,
  ].includes(props.result.type)
})

const model = ref(null)
const select = ref<any>(null)

function setQuery() {
  select.value?.setQuery(props.result.query)
}

function resolveUserBinding(user: Data<User>) {
  resolveBinding({
    value: user?.id,
    query: user?.attributes.name || user?.attributes.email || ''
  })
}

function resolveGroupBinding(group: Data<Group>) {
  resolveBinding({
    value: group?.id,
    query: group?.attributes.name || ''
  })
}

function resolveProjectBinding(project: Data<Project>) {
  resolveBinding({
    value: project?.id,
    query: project?.attributes.name || ''
  })
}

function resolveTaskBinding(task: Data<Task>) {
  resolveBinding({
    value: task?.id,
    query: task?.attributes.name || ''
  })
}

function resolveBinding({ value, query }: { value: any, query: string }) {
  emit('resolve', {
    result: props.result,
    value,
    query
  })
}
</script>
