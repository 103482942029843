import { entityTypes } from "@/modules/common/enum/entityTypes"

export type DisplayProp = {
  label: string,
  transformer?: Function
}

export type DisplayProps = Record<string, DisplayProp>
export type DisplayValue = {
  label: string,
  displayValue: string
}

type EntityTypesKey = keyof typeof entityTypes
export type EntityTypes = typeof entityTypes[EntityTypesKey]
export type BindingType = EntityTypes | 'raw' | 'capitalize' | 'date'

export type BindingDefinition = {
  label: string
  property: string
  type: BindingType
  multiple?: boolean
  required?: boolean
  extraParams?: any,
  order?: number
}

export type AiFunctionDefinition = {
  name: string;
  requiresUserConfirmation?: boolean;
  modelToHumanReadable?: (model: any) => DisplayValue[] | Array<DisplayValue[]>
  actionDisplayName?: (isEditAction: boolean) => string
  formattedModelBinding?: Record<string, BindingDefinition>
  iconClass?: string
  entity?: string
};

export enum AiFunctions {
  GetTasks = 'get_tasks',
  GetProjects = 'get_projects',
  GetProjectUsers = 'get_project_users',
  GetProjectGroups = 'get_project_groups',
  GetUsers = 'get_users',
  GetGroups = 'get_groups',
  GetCustomFields = 'get_custom_fields',
  GetOrganizationConfig = 'get_organization_config',

  AddOrUpdateProject = 'add_or_update_project',
  AddOrUpdateTask = 'add_or_update_task',
  AddBulkTasks = 'add_bulk_tasks',
  EditBulkTasks = 'edit_bulk_tasks',
  AddOrUpdateGroup = 'add_or_update_group',
  CreateProjectFromTemplate = 'create_project_from_template',
  CreateEntityView = 'create_entity_view',
}

export enum ActionChoices {
  CreateProject = 'Create Project',
  UpdateProject = 'Update Project',
  CreateTasks = 'Create Tasks',
  UpdateTasks = 'Update Tasks',
  CreateMultipleTasks = 'Create Multiple Tasks',
}

export const ActionChoiceToFunction: Record<ActionChoices, AiFunctions> = {
  [ActionChoices.CreateProject]: AiFunctions.AddOrUpdateProject,
  [ActionChoices.UpdateProject]: AiFunctions.AddOrUpdateProject,
  [ActionChoices.CreateTasks]: AiFunctions.AddBulkTasks,
  [ActionChoices.UpdateTasks]: AiFunctions.EditBulkTasks,
  [ActionChoices.CreateMultipleTasks]: AiFunctions.AddBulkTasks,
}

export type ActionResult = {
  entity?: string
  id?: string | number
  ids?: string[] | number[]
  url?: string
  urlLabel?: string
  urls?: string[]
  name?: string
  names?: string[]
  action?: 'create' | 'update'
}

export enum UserChoice {
  Confirm = 'confirm',
  Revise = 'revise',
  Cancel = 'cancel',
}

export type ActionResponseLink = {
  entity: string,
  url: string
}

export enum ChatMessageTypes {
  User = 'user',
  UserHidden = 'user-hidden',
  UserConfirmation = 'user-confirmation',
  Assistant = 'assistant',
  AssistantHidden = 'assistant-hidden',
  AssistantLocal = 'assistant-local',
  System = 'system',
  ActionChoice = 'action-choice',
}

export type ChatMessage = {
  type: ChatMessageTypes,
  message?: string,
  data?: any
  link?: ActionResponseLink, // If it is a direct link
  variant?: string,
  isStreaming?: boolean,
  date: Date,
}

export interface ToolOutput {
  tool_call_id: string,
  output: string,
}

export interface ToolResponse {
  thread_id: string,
  run_id: string,
  parameters: {
    tool_outputs: ToolOutput[]
  }
}

export interface ResponseMessage {
  id: string
  role: string
  content: {
    text: {
      value: string
    }
  }[]
}

export interface ToolCall {
  id: string
  function: {
    name: string
    arguments: string
  }
}

export interface PromptResponse {
  messages: {
    object: string
    data: ResponseMessage[]
    firstId: string
    lastId: string
    hasMore: boolean
  },
  thread: {
    id: string
    thread_id: string
    status: string
    required_action: {
      submit_tool_outputs: {
        tool_calls: ToolCall[]
      }
    }
  }
}

export type AiModeUsage = {
  title: string
  description: string
  icon: string
}

export enum ChatModes {
  Action = 'action',
  Create = 'create',
}

