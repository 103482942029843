<template>
  <EntitySelect
    url="/restify/users"
    labelKey="attributes.name"
    fallbackLabelKey="attributes.email"
    valueKey="id"
    :placeholder="$attrs.placeholder || $t('Select users...')"
    v-bind="$attrs"
  >
    <template #prefix>
      <slot name="prefix" />
    </template>
    <template #custom-options>
      <slot name="custom-options" />
    </template>
    <template #option="{ row }">
      <div class="flex items-center py-1 w-full">
        <BaseLogo
          :entity="row.originalValue"
          logo-key="attributes.avatar"
          size="sm"
        />
        <span class="ml-2 truncate main-label">
          {{ row.label }}
        </span>
        <UserRoleBadge
          :user="row.originalValue"
          size="xs"
          class="ml-2"
        />
        <UserStatus
          :row="row?.originalValue"
          :column="{ prop: 'attributes.status' }"
          :show-if-active="false"
          class="ml-2"
        />
        <div
          v-if="displayGroups"
          class="flex pl-2 -space-x-2 ml-auto items-center"
        >
          <GroupLogo
            v-for="group of getUserGroups(row)"
            :key="group.id"
            :group="group"
            class="group-user-logo"
            size="sm"
          />
        </div>
      </div>
    </template>
  </EntitySelect>
</template>
<script>
import EntitySelect from "@/components/form/EntitySelect.vue";
import UserStatus from "@/components/table/UserStatus.vue";
import UserRoleBadge from "@/components/common/UserRoleBadge.vue";
import GroupLogo from "@/components/common/GroupLogo.vue";

export default {
  components: {
    EntitySelect,
    UserStatus,
    UserRoleBadge,
    GroupLogo
  },
  computed: {
    displayGroups() {
      return this.$attrs?.['url-params']?.related?.includes('groups')
    },
  },
  methods: {
    getUserGroups(row) {
      return row?.originalValue?.relationships?.groups || []
    }
  }
}
</script>
<style>
.el-select-dropdown__item.hover .email-text {
  @apply text-gray-100;
}
</style>
