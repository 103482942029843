<template>
  <router-view/>
</template>
<script>
export default {
  methods: {
    async getPaymentDetails() {
      const hasPaymentDetails = this.$store.state.paymentDetails?.data?.length > 0
      if (!hasPaymentDetails) {
        await this.$store.dispatch('accounts/getPaymentDetails')
      }
    }
  },
  created() {
    this.$store.dispatch('payments/getPaymentDetailsById', this.$route.params.paymentId)
    this.getPaymentDetails()
  }
}
</script>
