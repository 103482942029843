<template>
  <BaseInput :layout="layout">
    <template #label>
      <span class="capitalize">
        <i
          v-if="defaultView.icon && showIcon"
          :class="defaultView.icon"
          class="far mr-2 w-4"
        />
        <span v-html="defaultView.label"/>
      </span>
    </template>
    <div class="flex gap-2">
      <BaseSelect
        v-if="defaultView.groupOptions.length"
        style="flex-basis: 25%"
        :returnObject="false"
        :options="viewGroupOptions"
        :modelValue="defaultView.view_group"
        @update:modelValue="onChange('view_group', $event)"
      />
      <BaseSelect
        :options="viewTypeOptions"
        :returnObject="false"
        :modelValue="defaultView.view_type"
        @update:modelValue="onChange('view_type', $event)"
      />
    </div>
  </BaseInput>
</template>
<script>
import { DefaultViewLevelTypes } from "@/modules/common/enum/viewTypeLevels";
import { getAccountDefaultViews } from "@/plugins/settingsPlugin";

export default {
  props: {
    defaultView: {
      type: Object,
      default: () => ({})
    },
    level: {
      type: String,
      default: DefaultViewLevelTypes.User, // user or account
    },
    layout: {
      type: String,
      default: 'horizontal',
    },
    showIcon: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      defaultViewsModel: [],
    }
  },
  computed: {
    matchingAccountView() {
      let accountDefaultViews = getAccountDefaultViews()
      return accountDefaultViews.find(view => view.attributes.target === this.defaultView.target && view.attributes.inside_project == this.defaultView.inside_project)
    },
    viewTypeOptions() {
      const baseOptions = this.defaultView.viewTypeOptions || []
      if (this.level === DefaultViewLevelTypes.Account || !this.matchingAccountView) {
        return baseOptions
      }
      const { view_type, viewTypeOptions } = this.matchingAccountView.attributes
      const setting = this.getAccountOptionLabel(viewTypeOptions, view_type)
      return [
        {
          value: null,
          label: this.$tc('use account setting', { setting }),
        },
        ...baseOptions
      ]
    },
    viewGroupOptions() {
      const baseOptions = this.defaultView.groupOptions || []
      if (this.level === DefaultViewLevelTypes.Account || !this.matchingAccountView) {
        return baseOptions
      }
      const { view_group, groupOptions } = this.matchingAccountView.attributes
      const setting = this.getAccountOptionLabel(groupOptions, view_group)

      return [
        {
          value: null,
          label: this.$tc('use account setting', { setting }),
        },
        ...baseOptions
      ]
    }
  },
  methods: {
    getAccountOptionLabel(options, value) {
      return options.find(o => o.value === value)?.label || ''
    },
    onChange(prop, value) {
      this.$emit('view-changed', { prop, value })
    },
  },
}
</script>
