<template>
  <BaseInlineInput>
    <template #default="{ triggerSave }">
      <input
        v-bind="$attrs"
        v-model="model"
        v-focus="autoFocus"
        :type="type"
        :key="key"
        :name="name || label"
        class="inline-text-edit w-full focus-visible:outline-none"
        :class="$attrs.class"
        ref="input"
        @blur="onBlur(triggerSave)"
        @focus="onFocus"
      />
    </template>
  </BaseInlineInput>
</template>
<script>
export default {
  props: {
    key: {
      type: String,
      default: '0'
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number, Object],
      default: '',
    },
    type: {
      type: [String, Number],
      default: 'text',
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      focusValue: this.modelValue
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    onBlur(triggerSave) {
      // Value not changed
      if (this.focusValue === this.modelValue) {
        return
      }

      triggerSave && triggerSave()
    },
    onFocus() {
      this.focusValue = this.modelValue
    },
  },
}
</script>
