import { createI18n } from 'vue-i18n'
import enLocale from 'element-plus/es/locale/lang/en'
import enDateFns from 'date-fns/locale/en-US'
import esDateFns from 'date-fns/locale/es'
import frDateFns from 'date-fns/locale/fr'
import deDateFns from 'date-fns/locale/de'
import itDateFns from 'date-fns/locale/it'

import enFullCalendar from '@fullcalendar/core/locales/en-gb';
import esFullCalendar from '@fullcalendar/core/locales/es';
import frFullCalendar from '@fullcalendar/core/locales/fr';
import deFullCalendar from '@fullcalendar/core/locales/de';
import itFullCalendar from '@fullcalendar/core/locales/it';

import enElPlus from 'element-plus/es/locale/lang/en'
import deElPlus from 'element-plus/es/locale/lang/de'
import frElPlus from 'element-plus/es/locale/lang/fr'
import esElPlus from 'element-plus/es/locale/lang/es'
import itElPlus from 'element-plus/es/locale/lang/it'
import { nextTick } from "vue";

export const dateFnsLocaleMap = {
  en: enDateFns,
  es: esDateFns,
  fr: frDateFns,
  de: deDateFns,
  it: itDateFns,
}

export const fcLocales = {
  en: enFullCalendar,
  es: esFullCalendar,
  fr: frFullCalendar,
  de: deFullCalendar,
  it: itFullCalendar,
}

export const elPlusLocales = {
  en: enElPlus,
  es: esElPlus,
  fr: frElPlus,
  de: deElPlus,
  it: itElPlus,
}

export const defaultLocale = 'en'
const storageLocale = localStorage.getItem('locale')

export const allLocales = ["en", "es", "fr", "de", "it"];
export const i18n = createI18n({
  locale: storageLocale || defaultLocale,
  fallbackLocale: defaultLocale,
  warnHtmlInMessage: 'off',
  messages: {
    el: enLocale.el,
  },
  silentTranslationWarn: true,
})

export async function loadLocaleMessages(i18n, locale) {
  if (i18n.global.availableLocales.includes(locale)) {
    return
  }
  // load locale messages with dynamic import
  const messages = await import(`./locales/${locale}.json`)
  i18n.global.setLocaleMessage(locale, messages.default)
  return nextTick()
}

export function getDateFnsLocale() {
  return dateFnsLocaleMap[getCurrentLocale()] || dateFnsLocaleMap.en
}

export function getFullCalendarLocale() {
  return fcLocales[getCurrentLocale()] || fcLocales.en
}

export function getElPlusLocale() {
  return elPlusLocales[getCurrentLocale()] || elPlusLocales.en
}

function getCurrentLocale() {
  return i18n.global.locale || defaultLocale
}

async function setLocale(locale) {
  await loadLocaleMessages(i18n, locale)
  i18n.global.locale = locale
  localStorage.setItem('locale', locale)
}

export default {
  i18n,
  install(Vue) {
    Vue.use(i18n)
  },
  t: i18n.global.t,
  tc: i18n.global.tc,
  setLocale,
  getCurrentLocale,
  getDateFnsLocale,
  getFullCalendarLocale,
}
