export async function sleep(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds)
  })
}

export function inputHasAutofill(input) {
  if (!input) {
    return false
  }

  try {
    return input.matches(':autofill')
  } catch (err) {
    try {
      return input.matches(':-webkit-autofill')
    } catch (er) {
      return false
    }
  }
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  })
}
