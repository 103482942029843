import store from "@/store";
import { FilterBuilder } from "@/modules/ai/filters/FilterBuilder";
import { FilterTypes } from "@/components/table/tableUtils";
import { FilterEngine } from "@/modules/ai/filters/FilterEngine";

// #region Get Users

interface UsersSearchModel extends Record<any, any> {
  id?: number
  name: string
  first_name?: string
  last_name?: string
  email?: string
  status?: string
  role?: string
}

function getMappedUsers() {
  // @ts-ignore
  return (store.getters['users/usersWithGroups'] || [])
    .map((user: Record<any, any>) => ({
      id: user.id,
      first_name: user.attributes.first_name,
      last_name: user.attributes.last_name,
      name: user.attributes.name,
      status: user.attributes.status,
      email: user.attributes.email,
      role: user.attributes.role,
      groups: (user.relationships?.groups || []).map((group: Record<any, any>) => group.attributes.name)
    }));
}

export function getUsers(params: UsersSearchModel) {
  const { id, first_name, last_name, email, status, name, role } = params;
  console.log(params);
  const allUsers = getMappedUsers();

  console.log('allUsers', allUsers)
  let filterBuilder = new FilterBuilder();

  let dynamicFilters = filterBuilder
    .addFilter('name', FilterTypes.Text, name)
    .addFilter('first_name', FilterTypes.Text, first_name)
    .addFilter('last_name', FilterTypes.Text, last_name)
    .addFilter('email', FilterTypes.Text, email)
    .addFilter('status', FilterTypes.Text, status)
    // .addFilter('role', FilterTypes.InArray, [role])
    .addFilter('id', FilterTypes.Numeric, id)
    .build();

  let filteredUsers = FilterEngine.filter(allUsers, dynamicFilters);

  if (filteredUsers.length > 10) {
    return `There are ${filteredUsers.length} users. Please narrow your search.`;
  }
  return JSON.stringify(filteredUsers);
}

// #endregion
