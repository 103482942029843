<template>
  <BaseSelect
    v-model="model"
    filterable
    :options="taskPrivacyOptions"
    :disabled="$attrs.disabled"
    :return-object="false"
  />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { taskPrivacyOptions } from "@/modules/tasks/utils/modelUtils";

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: '',
  }
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>
