<template>
  <router-link
    v-if="isFreePlan && isTenantOwner"
    id="sidebar-upgrade-button"
    to="/account-settings/billing"
  >
    <div class="py-4 opacity-60 hover:opacity-100 hover:bg-white/10 cursor-pointer text-white text-xs text-center space-y-1 transition ease-in-out duration-1000">
      <span class="px-6 py-1 bg-white/10 rounded-full">
        {{ $t(`Upgrade to Pro`) }}
        <i class="fa-solid fa-stars" aria-hidden="true" />
      </span>
    </div>
  </router-link>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";
import {
  useAccountLimits
} from "@/modules/auth/composables/useAccountLimits";

const store = useStore();

const {
  isFreePlan,
} = useAccountLimits()

const isTenantOwner = computed(() => {
  return store.getters['auth/isTenantOwner']
})
</script>
