<template>
  <div :class="{
    'opacity-50': !task?.id
  }">
    <div class="flex items-center text-gray-400 space-x-2">
      <i class="fa fa-file w-3" />
      <span>
        {{ $t('files count', { count: filesCount }) }}
      </span>
      <span>-</span>
      <span
        class="underline"
        :class="{
          'cursor-not-allowed': !task?.id,
          'cursor-pointer hover:text-gray-500': task?.id
        }"
        @click="task?.id && (showFiles = !showFiles)"
      >
        {{ toggleFilesLabel }}
      </span>
    </div>
    <div
      v-show="showFiles"
      class="mt-2"
      :class="{ 'mb-2': filesToUpload?.length || taskFiles?.length }"
    >
      <BaseDndFileUpload
        v-if="!disabled"
        v-model="filesToUpload"
        :renderList="false"
        class="mb-2"
      />
      
      <UploadFilePreview
        v-for="(file, index) in taskFiles"
        :key="file.id || index"
        :project_id="project_id"
        :task_id="task_id"
        :existingFile="file"
        :disabled="disabled"
        @remove="task?.relationships?.media.splice(index, 1)"
      />

      <UploadFilePreview
        v-for="(file, index) in filesToUpload"
        :key="file.id || index"
        :project_id="project_id"
        :task_id="task_id"
        :fileToUpload="file"
        :disabled="disabled"
        @remove="filesToUpload.splice(index, 1)"
      />
    </div>
  </div>
</template>
<script>
import CloseButton from "@/components/common/buttons/CloseButton.vue";
import UploadFilePreview from "@/modules/common/components/UploadFilePreview.vue";
import { createFileURL } from "@/modules/common/utils/fileUtils.js";
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    CloseButton,
    UploadFilePreview
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const { width } = useWindowSize()

    return {
      width
    }
  },
  data() {
    return {
      filesToUpload: [],
      showFiles: false
    }
  },
  computed: {
    taskFiles() {
      return (this.task?.relationships?.media || []).map(file => ({
        id: file.id,
        ...file.attributes,
        url: createFileURL(file.attributes),
      }));
    },
    project_id() {
      return this.task?.attributes?.project_id
    },
    task_id() {
      return this.task?.id
    },
    filesCount() {
      return (this.taskFiles?.length + this.filesToUpload?.length) || this.task?.attributes?.files_count || 0
    },
    toggleFilesLabel() {
      if (this.showFiles) {
        return this.$t('Hide files')
      }
      if (this.disabled) {
        return this.$t('View files')
      }

      return this.$t('View & upload files')
    }
  },
  methods: {
    async checkShowState() {
      await this.$nextTick()
      
      if (this.width < 768)  {
        return
      }

      this.showFiles = this.filesCount > 0
    }
  },
  mounted() {
    this.checkShowState()
  }
}
</script>
