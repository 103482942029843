<template>
  <div
    class="relative flex flex-col data-table-container"
    :class="{
      'use-new-layout -mx-8': $useNewLayout,
    }"
  >
    <div v-show="!loading && currentRowCount === 0 && !isInitializing">
      <slot name="no-data">
        <div
          class="w-full grid place-items-center bg-white no-data-container"
          :class="{
            'rounded-lg': !$useNewLayout,
          }"
        >
          <div class="text-center">
            <i class="tool-icon fal fa-empty-set text-6xl text-gray-500 mb-2" aria-hidden="true"></i>
            <h3 class="text-2xl font-bold text-gray-900">{{ $t('No Data Found') }}</h3>
            <p class="text-sm text-gray-500 mt-2">{{ $t('Amend your filter options above...') }}</p>
          </div>
        </div>
      </slot>
    </div>
    <!-- TODO: Temp solution for refreshing during master/detail change -->
    <AgGridVue
      v-show="(loading || currentRowCount) && !isInitializing"
      v-bind="getChartOptions()"
      ref="mainGrid"
      :key="(isCardView ? 'ag-card' : 'ag-table') + `_${masterDetail}`"
      class="ag-theme-alpine ag-grid flex-1 w-full overflow-hidden"
      :class="{
        'ag-cards': isCardView,
        'ag-tree': treeData,
        'bg-transparent': isCardView && loading,
        'bg-white': isCardView && !loading,
        'is-grouping-active': isGroupingActive,
        'is-master-detail': masterDetail,
        'use-new-layout': $useNewLayout,
      }"
      :columnTypes="columnTypes"
      :columnDefs="activeColumns"
      :defaultColDef="defaultColDef"
      :rowData="data"
      :treeData="treeData"
      :getDataPath="getDataPath"
      :masterDetail="masterDetail"
      :detailRowAutoHeight="true"
      :isRowMaster="isRowMaster"
      :detailCellRendererParams="detailCellRendererParams"
      :context="gridContext"
      :frameworkComponents="frameworkComponents"
      suppressMenuHide
      :rowDragManaged="false"
      :rowDragEntireRow="isDragEnabled"
      :suppressRowClickSelection="false"
      :rowMultiSelectWithClick="true"
      :rowSelection="isDragEnabled ? 'single' : ''"
      :rowDragMultiRow="true"
      :suppressAggFuncInHeader="suppressAggFuncInHeader"
      :animateRows="false"
      :singleClickEdit="true"
      :suppressColumnMoveAnimation="true"
      suppressPropertyNamesCheck
      :rowGroupPanelShow="rowGroupPanelShow"
      :groupDisplayType="groupDisplayType"
      :loadingOverlayComponent="DataSyncingIndicator"
      :groupDefaultExpanded="groupDefaultExpanded"
      :autoGroupColumnDef="autoGroupColumnDef"
      :getRowId="getRowId"
      :getRowHeight="getRowHeight"
      :isExternalFilterPresent="isExternalFilterPresent"
      :doesExternalFilterPass="doesExternalFilterPass"
      :groupIncludeFooter="groupIncludeFooter"
      :groupIncludeTotalFooter="groupIncludeTotalFooter"
      :dom-layout="domLayout"
      :pinnedTopRowData="isInputRowEnabled ? [inputRowModel] : []"
      :rowDragText="getRowDragText"
      @grid-ready="onGridReady"
      @rowDragEnter="onRowDragEnter"
      @rowDragLeave="onRowDragLeave"
      @rowDragMove="onRowDragMove"
      @rowDragEnd="onRowDragEnd"
      @columnRowGroupChanged="onColumnRowGroupChanged"
      @dragStopped="onColumnMoved"
      @sortChanged="onSortChanged"
      @columnResized="syncColumnSizes"
      @bodyScroll="onBodyScroll"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, useSlots, PropType, reactive, watch, nextTick } from 'vue'
import {
  defaultColDef,
  defaultAutoGroupColDef,
  FilterBy,
  RefreshRowNodeCellsParams,
} from '@/components/table/tableUtils'

import '@ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from '@ag-grid-community/vue3' // the AG Grid Vue Component

import {
  ModuleRegistry,
  GridApi,
  ColumnApi,
  GridReadyEvent,
  RowDragEvent,
  ColumnRowGroupChangedEvent,
  Column,
  ColumnState,
  GridOptions,
  DragStoppedEvent,
  SortChangedEvent,
  FirstDataRenderedEvent,
  ColumnResizedEvent,
  BodyScrollEvent,
  GetRowIdParams,
  IRowNode,
  RowHeightParams,
  GetDetailRowDataParams,
  IDetailCellRendererParams,
  RowClassParams,
} from '@ag-grid-community/core';

import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue";

import { frameworkComponents } from '@/components/table/cells/tableFrameworkComponents'
import { getColumnTypes } from '@/components/table/cells/tableColumnTypes'
import { agGridColumnMapper, TableColumn, getCardViewColumns } from '@/components/table/tableUtils'

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { getEntityTarget } from "@/modules/common/utils/filterUtils";

import { columnBuilder } from '@/components/table/tableUtils'

import { cloneDeep, debounce, get } from 'lodash-es'
import { encodeFilters } from "@/modules/common/utils/filterUtils";

import useMobileUtils from "@/modules/common/composables/useMobileUtils"

import { EntityChangeEvent } from "@/modules/common/utils/entityUtils";

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { setLicenseKey } from "@/components/table/licenseUtils";
import { getChartOptions } from "@/components/table/chartUtils";
const [
  store,
  route,
  router,
  slots
] = [
  useStore(),
  useRoute(),
  useRouter(),
  useSlots()
]

const mainGrid = ref<any>(null)

const { isMobileDevice } = useMobileUtils()

const isCardView = computed(() => {
  return isMobileDevice.value
})

const props = defineProps({
  data: {
    type: Array as PropType<any[]>,
  },
  treeData:{
    type: Boolean,
    default: false
  },
  getDataPath: {
    type: Function as PropType<(data: any) => any[]>,
    default: (data: any) => data.path
  },
  columns: {
    type: Array,
    default: () => []
  },
  detailColumnDefs: {
    type: Array as PropType<TableColumn[]>,
  },
  meta: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false
  },
  loadingRowsCount: {
    type: Number,
    default: 10
  },
  loadingCardsCount: {
    type: Number,
    default: 2
  },
  remapQueryParams: {
    type: Object,
    default: () => ({})
  },
  groupDisplayType: {
    type: String as PropType<GridOptions['groupDisplayType']>,
    default: (): GridOptions['groupDisplayType'] => 'singleColumn'
  },
  rowGroupPanelShow: {
    type: String as PropType<GridOptions['rowGroupPanelShow']>,
    default: (): GridOptions['rowGroupPanelShow'] => 'never'
  },
  rowDragEntireRow: {
    type: Boolean,
    default: false
  },
  onRowDragEnter: {
    type: Function as PropType<(event: RowDragEvent, isDetailGrid: boolean) => void>,
    default: null
  },
  onRowDragLeave: {
    type: Function as PropType<(event: RowDragEvent, isDetailGrid: boolean) => void>,
    default: null
  },
  onRowDragMove: {
    type: Function as PropType<(event: RowDragEvent, isDetailGrid: boolean) => void>,
    default: null
  },
  onRowDragEnd: {
    type: Function as PropType<(event: RowDragEvent, isDetailGrid: boolean) => void>,
    default: null
  },
  getRowDragText: {
    type: Function as PropType<((params: any, dragItemCount: number) => string) | undefined>,
    default: null
  },
  getDetailRowDragText: {
    type: Function as PropType<((params: any, dragItemCount: number) => string) | undefined>,
    default: null
  },
  masterDetail: {
    type: Boolean,
    default: false
  },
  detailDataPath: {
    type: String,
    default: 'relationships.children'
  },
  customIsRowMaster: {
    type: Function as PropType<(dataItem: any) => boolean>,
    default: null
  },
  getDetailRowData: {
    type: Function as PropType<(params: GetDetailRowDataParams) => void>,
    default: null
  },
  autoGroupColumnDef: {
    type: Object as PropType<TableColumn>,
    default: (): TableColumn => defaultAutoGroupColDef
  },
  extraFilters: {
    type: Array as PropType<{key: string, doesFilterPass: Function}[]>,
    default: () => []
  },
  domLayout: {
    type: String as PropType<'normal' | 'autoHeight' | 'print'>,
    default: 'normal'
  },
  enableInputRow: {
    type: Boolean,
    default: false
  },
  defaultInputRowModel: {
    type: Object as PropType<any>,
    default: () => ({})
  },
  groupIncludeFooter: {
    type: Boolean,
    default: false
  },
  groupIncludeTotalFooter: {
    type: Boolean,
    default: false
  },
  suppressAggFuncInHeader: {
    type: Boolean,
    default: true
  },
  prependEntityType: {
    type: Boolean,
    default: false
  },
  multipleEntityTypes: {
    type: Boolean,
    default: false
  },
  allowEntityType: {
    type: String,
    default: ''
  },
  groupDefaultExpanded: {
    type: Number,
    default: 0
  },
})

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RowGroupingModule,
  MasterDetailModule,
  GridChartsModule,
])

setLicenseKey()

const emit = defineEmits([
  'add',
  'edit',
  'view',
  'delete',
  'selectionChange',
  'dataUpdated',
  'gridReady',
  'detailRowDragStop',
  'gridReady',
  'firstDataRendered',
])

const columnTypes = getColumnTypes({
  slots,
  props,
  emit,
})

const target = computed(() => {
  return getEntityTarget(route)
})

const insideProjectId = computed(() => {
  return store.getters.project_id
})

const isInsideProject = computed(() => {
  return !!insideProjectId.value
})

const targetColumnState = computed(() => {
  return store.getters['filters/targetColumnState'](target.value, isInsideProject.value)
})

const appliedColumnsPersistedState = ref(false)

function saveTargetColumnState() {
  if (isCardView.value) {
    return
  }

  const columnState = gridColumnApi.value?.getColumnState().map((columnState: any) => {
    return {
      colId: columnState.colId,
      width: columnState.width,
    }
  })

  store.commit('filters/saveTargetColumnState', {
    target: target.value,
    isInsideProject: isInsideProject.value,
    columnState,
  })
}

function tryApplyTargetColumnState() {
  if (!targetColumnState.value?.length || isCardView.value) {
    appliedColumnsPersistedState.value = false
    return
  }

  gridColumnApi.value?.applyColumnState({
    state: targetColumnState.value,
    applyOrder: false,
  })

  appliedColumnsPersistedState.value = true
}

const gridApi = ref<GridApi | null>(null) // Optional - for accessing Grid's API
const gridColumnApi = ref<ColumnApi | null>(null)

const columnDefs: any = ref<TableColumn[]>([])
const detailColumns: any = ref<TableColumn[]>([])

const dataChangedTrigger = ref(1)
const currentRowCount = ref(0)

const detailCellRendererParams = ref<any | null>(null)

watch(() => dataChangedTrigger.value, async (value) => {
  if (props.loading) {
    currentRowCount.value = 0
    return
  }

  await nextTick()
  currentRowCount.value = gridApi.value?.getDisplayedRowCount() || 0
})

function initColumns() {
  let columns = columnBuilder.filterDisabledColumns(props.columns)

  columns = columnBuilder.remapDynamicProperties(columns)

  columnDefs.value = columns.map(agGridColumnMapper)

  if (detailCellRendererParams.value) {
    initDetailColumns()
  }
}

function initDetailColumns() {
  let columns = columnBuilder.filterDisabledColumns(props.detailColumnDefs || columnDefs.value)

  columns = columnBuilder.remapDynamicProperties(columns)

  detailColumns.value = columns.map(agGridColumnMapper)

  detailCellRendererParams.value.detailGridOptions.columnDefs = [detailColumns.value]
}

watch(() => props.columns, () => {
  initColumns()
}, { immediate: true })

watch(() => props.data, () => {
  gridApi.value?.setRowData(props.data || [])
}, { immediate: true })

const activeColumns = computed(() => {
  if (isCardView.value) {
    return getCardViewColumns(columnDefs.value)
  }

  return columnDefs.value
})

const isDragEnabled = computed(() => {
  return props.rowDragEntireRow && !isCardView.value
})

function isRowMaster(dataItem: any) {
  if (props.customIsRowMaster) {
    return props.customIsRowMaster(dataItem)
  }

  return get(dataItem, props.detailDataPath, []).length > 0
}

function getRowHeight(params: RowHeightParams) {
  if (params.node.detail) {
    const height = get(params.data, props.detailDataPath, []).length * 50

    let offset = 20 // Detail view offset to help users separate between detail view and next task
    if (isGroupingActive.value) {
      offset += 50 // Detail view header row
    }

    return height + offset
  }

  return (isCardView.value && !params.node.group) ? 80 : 50
}

function getRowId(params: GetRowIdParams) {
  if (props.prependEntityType && params.data?.type) {
    return `${params.data?.type}_${params.data?.id}`
  }
  return params.data?.id
}

const syncTopFilterControlsDebounced = ref(debounce(syncTopFilterControls, 100))

// Input row - allow creating new entries
const inputRowModel = ref(cloneDeep(props.defaultInputRowModel))

const isInputRowEnabled = computed(() => {
  return props.enableInputRow && !isCardView.value
})

function resetInputRow() {
  if (!isInputRowEnabled.value) {
    return
  }

  inputRowModel.value = cloneDeep(props.defaultInputRowModel)

  gridApi.value?.setPinnedTopRowData([inputRowModel.value])
}

const gridDataInputRowResetTrigger = computed(() => {
  return store.state.gridDataInputRowResetTrigger
})

watch(() => gridDataInputRowResetTrigger.value, resetInputRow)

const isInitializing = ref(true)

async function onGridReady(event: GridReadyEvent) {
  gridApi.value = event.api
  gridColumnApi.value = event.columnApi

  window.mainGridApi = gridApi.value
  window.mainGridColumnApi = gridColumnApi.value

  tryApplyTargetColumnState()

  syncTopFilterControlsDebounced.value?.()
  toggleLoadingOverlay()
  setDetailCellRendererParams()
  onFirstDataRendered({
    columnApi: event.columnApi,
    api: event.api
  } as FirstDataRenderedEvent)

  resetInputRow()

  setTimeout(() => {
    isInitializing.value = false
  }, 100)
}

async function onFirstDataRendered(params: FirstDataRenderedEvent) {

  const columnApi = params.columnApi || gridColumnApi.value
  if (!appliedColumnsPersistedState.value) {
    autoSizeColumns(columnApi)
  }

  dataChangedTrigger.value++
  emit('firstDataRendered', params)

  syncTotalRow()
}

function setDetailCellRendererParams() {
  if (!props.masterDetail) {
    return
  }

  const getDetailRowData = (params: GetDetailRowDataParams) => {
    if (props.getDetailRowData) {
      return props.getDetailRowData(params)
    }

    const data = get(params.data, props.detailDataPath, []).map((item: any) => {
      return {
        ...item,
        parentEntityId: params?.data?.id
      }
    })

    params.successCallback(data)
  }

  const detailGridOptions = {
    masterDetail: props.masterDetail,
    isRowMaster,
    columnTypes: { ...columnTypes },
    frameworkComponents,
    columnDefs: [ ...detailColumns.value ],
    defaultColDef: { ...defaultColDef },
    suppressPropertyNamesCheck: true,
    context: {
      level: 1,
    },
    onGridReady(event: GridReadyEvent) {
      syncGridState(event.api, event.columnApi)
      syncColumnSizes()

      event.api.setColumnDefs([ ...detailColumns.value ])

      getAllGridApis().forEach(api => {
        if (!api) {
          return
        }
        
        event.api.addRowDropZone(api.getRowDropZoneParams())
        api.addRowDropZone(event.api.getRowDropZoneParams())
      })
    },
    suppressColumnMoveAnimation: true,
    suppressHorizontalScroll: true,
    suppressPreventDefaultOnMouseWheel: true,
    rowDragEntireRow: isDragEnabled.value,
    animateRows: false,
    rowDragMultiRow: true,
    rowSelection: "single",
    suppressRowClickSelection: false,
    rowMultiSelectWithClick: true,
    detailRowAutoHeight: true,
    rowDragText: props.getDetailRowDragText,
    onRowDragEnter(event: RowDragEvent) {
      props.onRowDragEnter(event, /* isDetailGrid */ true)
    },
    onRowDragEnd(event: RowDragEvent) {
      props.onRowDragEnd(event, /* isDetailGrid */ true)
    },
    getRowId,
    getRowHeight,
    isExternalFilterPresent,
    doesExternalFilterPass,
    getRowClass: (params: RowClassParams) => {
      if (!params.context.level) {
        return ''
      }

      return `ag-row-detail-level-${params.context.level}`
    },
    alignedGrids: [{
      api: gridApi.value,
      columnApi: gridColumnApi.value,
    }],
  } as GridOptions

  const detail_CellRendererParams = {
    detailGridOptions,
    getDetailRowData,
  }

  detail_CellRendererParams.detailGridOptions.detailCellRendererParams = (masterParams: IDetailCellRendererParams) => {
    return {
      detailGridOptions: {
        ...detailGridOptions,
        context: {
          level: (masterParams?.context?.level || 0) + 1,
        }
      },
      getDetailRowData,
    }
  }

  detailCellRendererParams.value = detail_CellRendererParams
  initDetailColumns()
}

function getRecursiveApis(api: GridApi, gridApis: GridApi[]) {
  api.forEachDetailGridInfo((detailGridInfo) => {
    if (!detailGridInfo.api) {
      return
    }

    gridApis.push(detailGridInfo.api)
    getRecursiveApis(detailGridInfo.api, gridApis)
  })
}

function getAllGridApis() {
  if (!gridApi.value) {
    return []
  }

  // @ts-ignore
  const gridApis: GridApi[] = [gridApi.value]

  // @ts-ignore
  getRecursiveApis(gridApi.value, gridApis)

  return gridApis
}

function getRecursiveColumnApis(api: GridApi, columnApis: ColumnApi[]) {
  api.forEachDetailGridInfo((detailGridInfo) => {
    if (!detailGridInfo.columnApi || !detailGridInfo.api) {
      return
    }

    columnApis.push(detailGridInfo.columnApi)
    getRecursiveColumnApis(detailGridInfo.api, columnApis)
  })
}

function getAllGridColumnApis() {
  if (!gridColumnApi.value) {
    return []
  }

  // @ts-ignore
  const columnApis: ColumnApi[] = [gridColumnApi.value]

  // @ts-ignore
  getRecursiveColumnApis(gridApi.value, columnApis)

  return columnApis

}

function isValidSyncContext(entity: any) {
  if (isInsideProject.value) {
    const projectId = get(entity, 'attributes.project_id') || get(entity, 'relationships.project.id')
    
    if (projectId != insideProjectId.value) {
      return false
    }
  }

  if (props.multipleEntityTypes) {
    return true
  }

  const allowEntityType = props.allowEntityType || target.value

  if (allowEntityType && entity?.type !== allowEntityType) {
    return false
  }

  return true
}

const gridDataChangedTrigger = computed(() => {
  return store.state.gridDataChangedTrigger
})

watch(gridDataChangedTrigger, async (value) => {
  await nextTick()

  const { id, action, entity } = value

  if (!isValidSyncContext(entity)) {
    return
  }

  const rowNodeId = props.prependEntityType
     ? `${entity?.type}_${id}`
     : id

  const masterRowNode = gridApi.value?.getRowNode(rowNodeId)

  if (action === EntityChangeEvent.Create && !masterRowNode) {
    gridApi.value?.applyTransaction({ add: [entity] })
    dataChangedTrigger.value++
    return
  }

  getAllGridApis().forEach(api => {
    if (!api) {
      return
    }

    const rowNode = api.getRowNode(rowNodeId)
    if (!rowNode) {
      return
    }

    if (action === EntityChangeEvent.Update) {
      rowNode.setData(entity)
      api.applyTransaction({ update: [entity] })
      dataChangedTrigger.value++
      return
    }

    if (action === EntityChangeEvent.Delete) {
      api.applyTransaction({ remove: [rowNode] })
      dataChangedTrigger.value++
      return
    }
  })

}, { deep: true })

const gridTriggerCellRefresh = computed(() => {
  return store.state.gridTriggerCellRefresh
})

watch(gridTriggerCellRefresh, (refreshParams: RefreshRowNodeCellsParams) => {
  const rowNodes: IRowNode[] = [];

  (refreshParams.rowNodeIds || []).forEach((rowNodeId: string | number) => {
    const rowNode = gridApi.value?.getRowNode(String(rowNodeId))
    return rowNode && rowNodes.push(rowNode)
  })

  gridApi.value?.refreshCells({
    ...refreshParams,
    rowNodes
  })
})

async function autoSizeColumns(columnApi: ColumnApi) {
  await nextTick()

  if (!columnApi) {
    return
  }

  setTimeout(async () => {
    if (props.treeData) {
      gridApi.value?.sizeColumnsToFit()
      await nextTick()
      saveTargetColumnState()
      return
    }

    const columnsToAutosize: string[] = (columnApi?.getAllDisplayedColumns() || []).filter((column: Column) => {
      const colDef = column.getColDef()
      return !colDef.flex || colDef.showRowGroup
    }).map((column: Column) => column.getColId())

    if (!columnsToAutosize?.length) {
      return
    }

    columnApi?.autoSizeColumns(columnsToAutosize)

    await nextTick()
    saveTargetColumnState()
  }, 500)
}

watch(() => props.loading, async (value) => {
  dataChangedTrigger.value++

  if (!value) {
    await nextTick()
    onFirstDataRendered({ columnApi: gridColumnApi.value, api: gridApi.value } as FirstDataRenderedEvent)
  }

  toggleLoadingOverlay()
}, { immediate: true })

watch(() => props.data, () => {
  dataChangedTrigger.value++
}, { immediate: true })


async function toggleLoadingOverlay() {
  dataChangedTrigger.value++

  if (props.loading) {
    gridApi.value?.showLoadingOverlay()
  }
  else {
    if (!appliedColumnsPersistedState.value) {
      tryApplyTargetColumnState()
      await nextTick()
    }
    gridApi.value?.hideOverlay()
  }
}


// Local Filters: Grouping etc

function setFilters(value: any, key = 'localFilters') {
  let query: any = {
    ...route.query,
  }

  const removeKey = Array.isArray(value)
    ? !value?.length
    : !Object.keys(value).length

  if (removeKey) {
    delete query[key]
  }
  else {
    query[key] = encodeFilters(value)
  }

  const newRoute = router.resolve({
    path: route.path,
    query,
  })

  router.replace(newRoute)
}

const localFilters = computed<any>({
  get() {
    return store.getters['filters/targetLocalFilters'](target.value)
  },
  async set(value) {
    setFilters(value)
  }
})

function syncTopFilterControls() {
  if (!gridColumnApi.value) {
    return
  }

  syncGridState(gridApi.value as GridApi, gridColumnApi.value as ColumnApi)
  syncGrouping()
}

function syncGridState(api: GridApi, columnApi: ColumnApi) {
  syncQuickFilterText(api)
  syncDataFilters(api)
  syncColumnVisibility(columnApi)
  syncColumnOrder(columnApi)
  syncSort(columnApi)
}

function isSameState(state1: any, state2: any) {
  return JSON.stringify(state1) === JSON.stringify(state2)
}

// #region Row Aggregation
const totalRowFilter = computed(() => {
  return localFilters.value.totalRow
})

watch(() => totalRowFilter.value, syncTotalRow, { immediate: true })

function syncTotalRow() {
  if (!totalRowFilter.value || !gridColumnApi.value) {
    return
  }

  gridColumnApi.value?.getColumns()?.forEach((column: Column) => {
    const colDef = column.getColDef()
    if (!colDef.aggFunc) {
      return
    }

    gridColumnApi.value?.setColumnAggFunc(column, totalRowFilter.value)
  })
}

// #region Row Aggregation

// #region Grouping
watch(() => localFilters.value.groupBy, syncGrouping)

const isGroupingActive = computed(() => {
  return localFilters.value?.groupBy?.length
})

watch(() => isGroupingActive.value, () => {
  gridApi.value?.resetRowHeights()
}, { immediate: true })

async function syncGrouping() {
  await nextTick()

  const value: any = localFilters.value

  const groupByColumns: Column[] = gridColumnApi.value?.getRowGroupColumns() || []
  const currentState = groupByColumns.map(groupCol => groupCol.getColDef().field)

  let newGroupValue: any[] = value.groupBy

  if (!value?.groupBy) {
    newGroupValue = []
  }
  else if (!Array.isArray(value.groupBy)) {
    newGroupValue = [value.groupBy.prop || value.groupBy.field]
  }

  if (isSameState(currentState, newGroupValue) || !gridColumnApi.value) {
    return
  }

  gridColumnApi.value.setRowGroupColumns(newGroupValue)
  syncColumnVisibility()

  await nextTick()
  autoSizeColumns(gridColumnApi.value as ColumnApi)
}

function onColumnRowGroupChanged(event: ColumnRowGroupChangedEvent) {
  const newGroupByColumns: Column[] = gridColumnApi.value?.getRowGroupColumns() || []
  const newGroupValue = newGroupByColumns.map(groupCol => groupCol.getColDef().field)


  const newLocalFilters = { ...localFilters.value }
  if (!newGroupValue.length) {
    delete newLocalFilters.groupBy
  }
  else {
    newLocalFilters.groupBy = newGroupValue
  }

  const currentValue = localFilters.value?.groupBy || []

  if (isSameState(currentValue, newGroupValue)) {
    return
  }

  localFilters.value = newLocalFilters
}

// #endregion

// #region Column size

async function syncColumnSizes(event: ColumnResizedEvent | null = null) {
  await nextTick()

  if (event) {
    saveTargetColumnState()
  }

  if (!props.masterDetail) {
    return
  }

  const mainColumnSizes = (gridColumnApi.value?.getColumnState() || []).map((col: any) => {
    return {
      colId: col.colId,
      width: col.width,
    }
  })

  getAllGridColumnApis().forEach((gridColApi) => {
    gridColApi?.applyColumnState({
      state: mainColumnSizes,
      applyOrder: false,
    })
  })
}

function onBodyScroll(event: BodyScrollEvent) {
  if (!props.masterDetail || event.direction !== 'horizontal') {
    return
  }

  document.querySelectorAll('.ag-details-row .ag-center-cols-viewport').forEach((el: any) => {
    el.scrollLeft = event.left
  })
}

// #endregion

// #region Column visibility & order

watch(() => localFilters.value?.columnsToHide?.columns, (value: any) => syncColumnVisibility())
function syncGridColumnVisibility(columnApi: ColumnApi | undefined = undefined, hiddenColumns: any[]) {
  const allColumns = (columnApi?.getColumns() || []).filter(col => {
    const colDef: TableColumn | undefined = col.getColDef()
    if (!colDef) {
      return true
    }

    return !colDef.keepHidden
  })

  columnApi?.setColumnsVisible(allColumns, true)

  const keepHidden = (columnApi?.getColumns() || []).filter(col => {
    const keepColHidden = (col.getColDef() as TableColumn)?.keepHidden
    const isGroupedBy = gridColumnApi.value?.getRowGroupColumns().includes(col)

    return keepColHidden || isGroupedBy
  })
  columnApi?.setColumnsVisible([...hiddenColumns, ...keepHidden], false)
}

async function syncColumnVisibility(columnApi: ColumnApi | undefined = undefined) {
  await nextTick()

  if (isCardView.value) {
    return
  }

  const hiddenColumns = localFilters.value?.columnsToHide?.columns || []

  if (columnApi) {
    syncGridColumnVisibility(columnApi, hiddenColumns)
    return
  }

  getAllGridColumnApis().forEach((gridColApi) => {
    syncGridColumnVisibility(gridColApi, hiddenColumns)
  })
}

watch(() => localFilters.value?.columnOrder, (value) => syncColumnOrder())

function syncGridColumnOrder(columnApi: ColumnApi | undefined = undefined, localFiltersOrder: any[]) {
  const columnState: ColumnState[] = columnApi?.getColumnState() || []
  const currentColumnOrder = columnState.map(colState => colState.colId)


  if (isSameState(currentColumnOrder, localFiltersOrder)) {
    return
  }

  columnApi?.applyColumnState({
    state: localFiltersOrder.map((colId: string) => ({ colId })),
    applyOrder: true
  })
}

async function syncColumnOrder(columnApi: ColumnApi | undefined = undefined) {
  await nextTick()
  const localFiltersOrder = localFilters.value?.columnOrder || []

  if (columnApi) {
    syncGridColumnOrder(columnApi, localFiltersOrder)
    return
  }

  getAllGridColumnApis().forEach(gridColApi => {
    syncGridColumnOrder(gridColApi, localFiltersOrder)
  });
}

async function onColumnMoved(event: DragStoppedEvent) {
  if (!event.target.classList.contains('ag-header-cell')) {
    return
  }

  await nextTick()

  const columnState: ColumnState[] = gridColumnApi.value?.getColumnState() || []
  const columnOrder = columnState.map(colState => colState.colId)

  if (isSameState(columnOrder, localFilters.value?.columnOrder)) {
    return
  }

  localFilters.value = {
    ...localFilters.value,
    columnOrder,
  }
}

// #endregion

const apiFilters = computed<any>( {
  get() {
    return store.getters['filters/targetApiFilters'](target.value) || []
  },
  set(value) {
    setFilters(value, 'filters')
  }
})

// #region Sorting
const localSort = computed(() => {
  return apiFilters.value.find((filter: any) => filter.key === 'sorts')
})

watch(() => localSort.value, (value: any) => syncSort())

function getLocalSortState() {
  if (!localSort.value?.value) {
    return []
  }

  let sortValue = localSort.value?.value

  if (!Array.isArray(sortValue)) {
    sortValue = [sortValue]
  }

  return sortValue
}

function getGridSortState(columnApi: ColumnApi | undefined | null) {
  return columnApi?.getColumnState()?.filter(c => c.sort)
    .sort((c1, c2) => (c1.sortIndex || 0) - (c2.sortIndex || 0))
    .map(c => {
      const column = columnApi?.getColumn(c.colId)
      const sortProp = (column?.getColDef() as TableColumn).sortProp

      return {
        column: sortProp,
        order: c.sort
      }
    }) || []
}

function syncGridSort(columnApi: ColumnApi | undefined, localSortState: any) {
  const gridSortState = getGridSortState(columnApi)

  if (isSameState(localSortState, gridSortState)) {
    return
  }

  const state = localSortState.map((x: any, i: number) => {
    const colId = columnApi?.getAllGridColumns()
      ?.find(c => (c.getColDef() as TableColumn).sortProp === x.column)?.getColId()

    return {
      colId,
      sort: x.order,
      sortIndex: i
    }
  })

  columnApi?.applyColumnState({
    state,
    defaultState: { sort: null },
  })
}

function syncSort(columnApi: ColumnApi | undefined = undefined) {
  const localSortState = getLocalSortState()

  if (columnApi) {
    syncGridSort(columnApi, localSortState)
    return
  }

  getAllGridColumnApis().forEach(columnApi => {
    syncGridSort(columnApi, localSortState)
  });
}

function onSortChanged(event: SortChangedEvent) {
  const localSortState = getLocalSortState()
  const gridSortState = getGridSortState(gridColumnApi.value as ColumnApi | undefined)

  if (isSameState(localSortState, gridSortState)) {
    return
  }

  if (!gridSortState.length) {
    apiFilters.value = apiFilters.value.filter((filter: any) => filter.key !== 'sorts')
    return
  }

  const newLocalSort = {
    key: 'sorts',
    value: gridSortState
  }

  apiFilters.value = [
    ...apiFilters.value.filter((filter: any) => filter.key !== 'sorts'),
    newLocalSort
  ]
}

// #endregion

// #region Data filtering

function syncQuickFilterText(api: GridApi | undefined = undefined) {
  if (api) {
    api.setQuickFilter(quickFilterText.value)
    return
  }

  getAllGridApis().forEach((api) => {
    api?.setQuickFilter(quickFilterText.value)
  })
}

const quickFilterText = computed<string>(() => {
  return route.query.search?.toString() || ''
})

watch(() => quickFilterText.value, async (value: string) => {
  syncQuickFilterText()

  dataChangedTrigger.value++
})

const dataFilters = computed(() => {
  return apiFilters.value.find((filter: any) => filter.key === 'filters')?.value || []
})

watch(() => apiFilters.value, (value: any) => syncDataFilters())

function isExternalFilterPresent(params: any) {
  return (apiFilters.value || [])?.length > 0
}

function doesFilterPass(row: any, filter: { column: string, query: string | any }) {
  if (!filter.query) {
    return true
  }

  const filterByValue = filter.query

  const colDef = gridColumnApi.value?.getAllGridColumns().find(c => {
    const filterBy = (c.getColDef() as TableColumn)?.filterBy as FilterBy | undefined
    return filterBy?.prop  === filter.column
  })?.getColDef() as TableColumn

  const filterBy = colDef?.filterBy as FilterBy | undefined

  if (!filterBy?.doesFilterPass) {
    console.error(`Column ${colDef?.field} does not have a doesFilterPass function`)
    return false
  }

  return filterBy.doesFilterPass(row, filterByValue)
}

function doesExternalFilterPass(node: any) {
  const row = node.data

  for (const filter of dataFilters.value) {
    if (!doesFilterPass(row, filter)) {
      return false
    }
  }

  for (const filter of props.extraFilters) {
    const filterValue = apiFilters.value.find((f: any) => f.key === filter.key)?.value
    if (!filter.doesFilterPass(row, filterValue)) {
      return false
    }
  }

  return true
}

async function syncDataFilters(api: GridApi | undefined = undefined) {
  await nextTick()
  if (api) {
    api.onFilterChanged()
  }

  getAllGridApis().forEach(gridApi => {
    gridApi?.onFilterChanged()
  })

  dataChangedTrigger.value++
}

// #endregion

const gridContext = reactive({
  storageKey: 'default',
  filtersUrl: '',
  filterOptions: {
    sortables: [] as any[],
    matches: [] as any[],
  },
  getLocalFilters: () => localFilters.value,
  matchFilters: { },
  advancedFilters: [],
  fullMatchFilters: {},
  displayMatchFilters: {},
  level: 0,
})

</script>
<style lang="scss">
.ag-theme-alpine, .ag-theme-alpine-dark {
  /* disable all borders */
  --ag-borders: none;
  --ag-header-height: 42px;
  --ag-odd-row-background-color: white;
  --ag-borders-input: none;
  --ag-input-focus-box-shadow: none;
  --ag-input-focus-border: none;
  --ag-range-selection-border-style: none;
  --ag-cell-horizontal-border: none;

  .ag-group-contracted, .ag-group-expanded {
    .ag-icon:hover {
      @apply text-primary-500;
    }
  }

  .ag-header-viewport {
    @apply border border-gray-200;
  }

  &:not(.use-new-layout) {
    @apply rounded-lg;

    .ag-row-last {
      @apply rounded-b-lg overflow-hidden;
    }
  }

  .ag-row-level-0,
  .ag-row-level-1,
  .ag-row-level-2,
  .ag-row-level-3,
  .ag-row-level-4,
  .ag-row-level-5 {
    @apply bg-white;
  }

  .ag-row.ag-row-group-expanded {
    @apply bg-gray-100;
  }


  .ag-row-level-1, .ag-row-detail-level-1 {
    .ag-cell:first-of-type .ag-cell-wrapper {
        @apply pl-2;
    }
  }

  .ag-row-level-2, .ag-row-detail-level-2 {
    .ag-cell:first-of-type .ag-cell-wrapper {
      @apply pl-4;
    }
  }

  .ag-row-level-3, .ag-row-detail-level-3 {
    .ag-cell:first-of-type .ag-cell-wrapper {
      @apply pl-6;
    }
  }

  .ag-row-level-4, .ag-row-detail-level-4 {
    .ag-cell:first-of-type .ag-cell-wrapper {
      @apply pl-8;
    }
  }

  .ag-row-level-5, .ag-row-detail-level-5 {
    .ag-cell:first-of-type .ag-cell-wrapper {
      @apply pl-10;
    }
  }

  &:not(.ag-tree) {
    .ag-row-level-0,
    .ag-row-level-1,
    .ag-row-level-2,
    .ag-row-level-3,
    .ag-row-level-4,
    .ag-row-level-5 {
      @apply bg-white;
    }

    .ag-row-level-1 {
      @apply bg-gray-50;
    }

    .ag-row-level-2 {
      @apply bg-gray-100;
    }

    .ag-row-level-3 {
      @apply bg-gray-100;
    }

    .ag-row-level-4 {
      @apply bg-gray-100;
    }

    .ag-row-level-5 {
      @apply bg-gray-100;
    }

    .ag-row.ag-row-group-expanded {
      @apply bg-gray-100;
    }
  }

  .ag-row-hover::before {
    @apply bg-gray-50;
  }

  .ag-row-selected {
    &::before {
      @apply bg-gray-50;
    }
    &.ag-row-hover::before {
      background-image: none;
    }
  }

  .ag-cell {
    @apply border border-gray-200 border-t-0 border-b-0;

    .ag-input-wrapper.ag-checkbox-input-wrapper {
      @apply ml-1.5;
    }

    .ag-cell-wrapper {
      @apply w-full;

      .ag-group-value {
        @apply w-full;
      }
    }

    &:hover {
      .cell-edit-icon {
        @apply flex absolute right-0 mr-1 top-1/2 -translate-y-1/2 bg-gray-100;
      }
    }

    &.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
      @apply border-l border-gray-200 bg-gray-100;
      border-left-style: solid;
    }

    &.project-name-cell, &.task-name-cell {
      @apply px-0 py-0;
    }

    &.ag-cell-inline-editing {
      @apply bg-transparent shadow-none h-full rounded-none border-r border-gray-200 border-t border-b px-2 py-0;

      &.task-name-cell {
        @apply pl-10;
      }

      &.project-name-cell {
        @apply pl-3;
      }

      border-color: rgb(229 231 235) !important;

      .ag-text-field-input {
        @apply bg-transparent pl-0 h-full focus:border-none focus:shadow-none focus:border-gray-100;
      }
    }
  }

  .ag-header-container, .ag-row {
    @apply border-b border-gray-200 border-solid;
  }

  .ag-ltr input[class^=ag-][type=text] {
    @apply pl-0 text-sm;
  }
  .ag-cell-wrapper.ag-row-group {
    @apply items-center;
  }

  .ag-details-row {
    @apply p-0;

    .ag-root-wrapper {
      @apply bg-gray-50;
    }
  }

  &.is-grouping-active.is-master-detail {
    .ag-row.ag-row-group .ag-cell:first-child .ag-cell-wrapper {
      .ag-icon {
        @apply hidden;
      }

      &:has(.base-table-cell) {
        .ag-icon {
          @apply inline-flex;
        }
      }
    }
  }

  &:not(.is-grouping-active) {
    .ag-details-row {
      .ag-header {
        @apply hidden;
      }
    }
  }

  .ag-floating-top-container, .ag-center-cols-container {
    @apply min-w-full;
  }

  .ag-floating-top-container {
    .ag-row {
      height: 51px !important;
      &.ag-row-last {
        @apply rounded-b-none;
      }
    }
  }
}

.ag-grid.ag-cards {
  @apply p-[2px] border-none;

  .entity-card {
    @apply rounded-none;
  }

  .base-table-cell {
    @apply h-full;
  }

  .ag-cell {
    left: 0 !important;
    width: 100% !important;
  }

  .ag-cell.ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-theme-alpine-dark .ag-cell.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    @apply border-none;
  }

  .ag-header {
    @apply hidden;
  }

  .ag-sticky-top {
    top: 0 !important;
    @apply bg-gray-50;
  }

  .ag-center-cols-container {
    width: 100% !important;

    @apply grid gap-y-3;

    .ag-cell, .ag-row, .ag-row-hover::before {
      @apply border-none;
    }

    .ag-row-group {
      @apply bg-gray-50;
    }
  }

  .entity-card {
    @apply border-b border-gray-200 overflow-hidden;
  }
}

.data-table-container {
  &:not(.preserve-ag-background) {
    .ag-root-wrapper {
      @apply bg-transparent;
    }
  }
}

.hover-over {
  @apply bg-primary-50;
}
</style>
