<template>
  <div
    class="text-center text-md -mt-6"
    :class="{
      ...$attrs.class,
      'absolute flex justify-center h-6 w-full': dataView
    }"
  >
    <i
      class="m-auto fas fa-circle-notch animate-spin data-syncing-indicator"
      aria-hidden="true"
    />
  </div>
</template>
<script>
export default {
  name: "DataSyncingIndicator",
  props: {
    dataView: {
      type: Boolean,
      default: false
    }
  }
};
</script>
