<template>
  <BaseEntityForm
    width-class="max-w-3xl"
    @submit="onGenerate"
  >
    <template #title>
      <BaseFormHeader
        :title="$t('Public API')"
      >
        <template #subtitle>
          <div
            class="text-sm mt-2"
            :class="{
              'text-gray-500': !$useNewLayout
            }"
          >
            <div>
              {{
                $t(`Manage your public API keys. You can use these keys to integrate with other services.`)
              }}
            </div>
            <BaseTutorialLink
              name="public-api"
            />
          </div>
        </template>
      </BaseFormHeader>
    </template>
    <template #default="{ meta, errors }">
      <BaseInput
        :modelValue="apiKey?.attributes?.api_key"
        :label="$t('Public API Key')"
        :placeholder="$t('Click Generate to get a new public API key.')"
        readonly
        :name="$t('Public API Key')"
        layout="horizontal"
        id="api-key"
      >
        <template
          v-if="hasApiKey"
          #after-input
        >
        <BaseButton
          class="rounded-l-none"
          @click="copyToClipboard(apiKey.attributes.api_key, $t('Key copied to clipboard'))"
        >
          {{ $t('Copy') }}
        </BaseButton>
        </template>
      </BaseInput>
    </template>
    <template #actions="{ meta, errors }">
      <template v-if="hasApiKey">
        <BaseButton
          variant="white"
          hover-variant="danger"
          class="mr-2 whitespace-nowrap"
          :loading="loading"
          @click="confirmDelete"
        >
          {{ $t('Delete Key') }}
        </BaseButton>
      </template>
      <template v-else>
        <BaseButton
          variant="white"
          @click="$router.push('/account-settings')"
        >
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          type="submit"
          class="ml-2"
        >
          {{ $t('Generate') }}
        </BaseButton>
      </template>
    </template>
  </BaseEntityForm>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import { computed, onBeforeMount, ref } from 'vue'
import { $deleteConfirm } from '@/components/common/dialog/modalPlugin'
import { error } from '@/components/common/NotificationPlugin'
import { useStore } from 'vuex'
import { copyToClipboard } from "@/plugins/copyToClipboard"

const store = useStore()

const loading = ref(false)

const apiKey = computed(() => {
  return store.getters['accounts/publicApiKey']
})

const hasApiKey = computed(() => {
  return Boolean(apiKey.value)
})

async function confirmDelete() {
  const confirmed = await $deleteConfirm({
    title: i18n.t('Delete Public API Key'),
    description: i18n.t('Are you sure you want to delete your public API key? This action cannot be undone.'),
  })

  if (!confirmed) {
    return
  }
  try {
    loading.value = true
    await store.dispatch('accounts/deletePublicApiKey', { apiKey: apiKey.value })
    await loadApiKeys()
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('Could not delete public API key'))
  }
  finally {
    loading.value = false
  }
}

async function onGenerate() {
  try {
    loading.value = true
    await store.dispatch('accounts/generatePublicApiKey')
    await loadApiKeys()
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('Could not generate public API key'))
  }
  finally {
    loading.value = false
  }
}

async function loadApiKeys() {
  await store.dispatch('accounts/getPublicApiKeys')
}
onBeforeMount(() => {
  loadApiKeys()
})
</script>
