<template>
  <nav
    v-if="showBreadcrumbs"
    aria-label="Breadcrumb"
    class="hidden md:flex"
  >
    <ol class="w-full px-4 md:px-8 flex space-x-4">
      <li class="flex">
        <div class="flex items-center">
          <router-link class="breadcrumb-color opacity-80 hover:opacity-100" to="/welcome">
            <!-- Heroicon name: solid/home -->
            <svg
              class="shrink-0 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
            <span class="sr-only">{{ $t('Home') }}</span>
          </router-link>
        </div>
      </li>
      <template
        v-for="(route, index) in matchedRoutes"
        :key="route.path"
      >
        <BreadcrumbsFilter
          v-if="route.meta.useFilter"
          :matchedRoute="route"
        />
        <BreadcrumbsItem
          :matchedRoute="route"
          :index="index"
          :isLastItem="index === matchedRoutes.length - 1"
          :formattedRouteFullName="formattedRouteFullName"
        />
      </template>
    </ol>
  </nav>
</template>
<script>
// Components
import BreadcrumbsItem from "@/modules/dashboard/components/BreadcrumbsItem.vue"
import BreadcrumbsFilter from "@/modules/dashboard/components/BreadcrumbsFilter.vue"

// Utils
import { uniq } from 'lodash-es'
import { getRouteName } from "@/modules/accounts/utils/pageUtils"

export default {
  components: {
    BreadcrumbsItem,
    BreadcrumbsFilter
  },
  data() {
    return {
      showStarredPageSetTitleDialog: false,
      newStarredPageTitle: ''
    }
  },
  computed: {
    showBreadcrumbs() {
      return this.$route.path !== '/welcome'
    },
    matchedRoutes() {
      const exceptionRoutes = ['Projects Layout', 'Templates Layout', 'Tasks Layout', 'Welcome']
      return this.$route.matched.filter(r => !exceptionRoutes.includes(r.name) && !r?.meta?.hideBreadCrumb)
    },
    formattedRouteFullName() {
      let routeNames = []
      for (let i = this.matchedRoutes.length - 1; i >= 0; i--) {
        let routeName = this.getRouteName(this.matchedRoutes[i])
        if (routeName.includes('Module')) {
          continue
        }

        routeNames.push(routeName)
      }
      routeNames = uniq(routeNames.filter(r => r !== ''))
      return routeNames.join(', ')
    },
  },
  methods: {
    getRoute(route) {
      if (route.path.endsWith('/projects')) {
        const viewOptions = this.$store.getters['users/defaultTargetViewOptions']('projects')
        return `/projects/${viewOptions.view_type}`
      }

      if (route.path.endsWith('/templates')) {
        const viewOptions = this.$store.getters['users/defaultTargetViewOptions']('templates')
        return `/templates/${viewOptions.view_type}`
      }

      if (route.path.endsWith('/tasks')) {
        const viewOptions = this.$store.getters['users/defaultTargetViewOptions']('tasks')
        return `/tasks/${viewOptions.view_type}`
      }

      return this.$router.resolve({
        name: route.name,
        params: this.$route.params,
      })
    },
    getRouteName(route) {
      return getRouteName(route)
    },
    getShortName(name) {
      const maxLength = 15
      if (name?.length > maxLength) {
        return `${name.substr(0, maxLength)}...`
      }
      return name
    },
  },
}
</script>
