<template>
  <div>
    <BaseSelect
      ref="select"
      v-model="model"
      :options="statuses"
      :loading="statusesLoading"
      :placeholder="placeholder"
      :return-object="false"
      :persistent="false"
      :inline="$attrs.inline"
      :disabled="$attrs.disabled"
      :filterable="filterable"
      valueKey="id"
      popper-class="task-status-select"
      class="task-status-select"
      :class="{
        [$attrs.class]: $attrs.class
      }"
    >
      <template #prefix="{ selected }">
        <slot name="prefix">
          <div class="flex justify-center items-center h-full">
            <div
              v-if="selected?.attributes?.color"
              :style="{ backgroundColor: selected?.attributes?.color }"
              class="w-3 h-3 rounded-md mr-2"
            />
          </div>
        </slot>
      </template>
      <template #default="{option}">
        <div
          class="w-4 h-4 rounded-full mr-2 border-2 border-white"
          :style="{backgroundColor: option?.attributes?.color}"
        />
        <span class="capitalize">{{ option?.attributes?.name }}</span>
      </template>

      <template #after-options>
        <RequiresPermissionTo :action="$actions.EDIT_TASK_STATUSES">
          <EditStatusOption
            :entity="$t('Task')"
            @click="onEditTakStatuses"
          />
        </RequiresPermissionTo>
      </template>
    </BaseSelect>

    <TaskStatusesDialog
      v-show="showTaskStatusDialog"
      v-model="showTaskStatusDialog"
    />
  </div>
</template>
<script>
import { capitalize } from 'lodash-es';
import i18n from "@/i18n.js";
import TaskStatusesDialog from "@/modules/accounts/components/TaskStatusesDialog.vue";
import EditStatusOption from "@/components/common/EditStatusOption.vue";
import { getSetting } from "@/plugins/settingsPlugin";

export default {
  components: {
    EditStatusOption,
    TaskStatusesDialog,
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: i18n.t('Enter task status...'),
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTaskStatusDialog: false,
    }
  },
  computed: {
    model: {
      get() {
        return +this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', +value)
      }
    },
    statuses() {
      const statuses = this.$store.getters['tasks/orderedStatuses'] || []
      return statuses.map(status => {
        return {
          ...status,
          label: capitalize(status?.attributes?.name),
        }
      })
    },
    defaultStatus() {
      return this.$store.getters['tasks/defaultTaskStatus']
    },
    statusesLoading() {
      return this.$store.state.tasks.statuses.statusesLoading || false
    },
  },
  methods: {
    async getAllStatuses() {
      this.$store.dispatch('tasks/getAllStatuses')
    },
    onEditTakStatuses() {
      this.showTaskStatusDialog = true;
      this.$refs.select?.blur();
    }
  },
  watch: {
    defaultStatus: {
      immediate: true,
      handler(value) {
        if (value && value.id && !this.model) {
          this.model = value.id?.toString()
        }
      }
    }
  }
}
</script>
<style lang="scss">
.task-status-select {

  .el-select-dropdown__list .el-select-dropdown__item.is-disabled {
    @apply border-t border-gray-200;

    &.hover {
      @apply bg-transparent;
    }
  }

  .fa-info-circle,
  .info-text {
    @apply bottom-0;
  }
}
</style>
