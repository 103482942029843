<template>
  <span
    class="relative inline-task inline-flex items-center space-x-2 px-1 hover:bg-gray-50 rounded-md"
  >
    <span>
      <i class="fal fa-badge-check text-orange-500" />
    </span>
    <span class="text-base font-bold truncate text-gray-900 text-ellipsis">
      <TaskDescription :task="task" />
    </span>
    <Status
      :row="task"
      :params="{
        target: 'task'
      }"
    />

    <AllocationsList
      v-if="allocations.length"
      :allocations="allocations"
    />
    
  </span>
</template>
<script setup>
import { computed } from 'vue'
import Status from "@/components/table/Status.vue";
import TaskDescription from "@/modules/tasks/components/TaskDescription.vue"
import AllocationsList from "@/modules/tasks/components/AllocationsList.vue";

const props = defineProps({
  task: {
    type: Object,
    default: () => ({})
  }
})

const allocations = computed(() => {
  return props.task?.relationships?.allocations || [];
})
</script>
<style>
.inline-task .initial-avatar,
.inline-task .base-avatar,
.inline-task img {
  @apply my-0 h-8 w-8;
}
</style>
