<template>
  <div
    v-if="canAccessAll"
    class="relative lg:inline-block text-left mt-2 lg:mt-0"
  >
    <BaseDropdown
      :options="filterOptions"
      trigger="hover"
      divide-items
      :large-items="!isBreadcrumbFilter"
      class="w-full"
    >
      <slot>
        <TopFilterChip
          :isActive="filterValue"
          :prependLabel="$t('Show')"
          :label="filterValue ? $t('Archived') : $t('Active')"
        />
      </slot>
      <template
        v-if="!isBreadcrumbFilter"
        #menu-header
      >
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ title || $t('Change type') }}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import TopFilterChip from '@/modules/filters/components/TopFilterChip.vue';
export default {
  components: {
    TopFilterChip,
  },
  props: {
    defaultFilter: {
      type: Boolean,
      default: false,
    },
    entityType: {
      type: String,
      default: '',
    },
    title: {
      type: String,
    },
    target: {
      type: String,
      required: true
    },
    isBreadcrumbFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filterOptions() {
      return [
        {
          label: this.$t('Active'),
          action: () => this.filterValue = false,
          variant: this.filterValue === false && !this.isBreadcrumbFilter ? 'active' : ''
        },
        {
          label: this.$t('Archived'),
          action: () => this.filterValue = true,
          variant: this.filterValue === true && !this.isBreadcrumbFilter ? 'active' : ''
        }
      ]
    },
    filterValue: {
      get() {
        return this.defaultFilter
      },
      set(value) {
        this.$emit('change', value)
      }
    },
    canAccessAll() {
      return this.can(this.$actions.VIEW_ARCHIVED_USERS)
    },
    icon() {
      if (this.filterValue) {
        return 'fa-box-archive'
      }

      return 'fa-users'
    }
  },
}
</script>
