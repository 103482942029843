import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import ReferenceTemplate from "@/components/html/extensions/components/ReferenceTemplate.vue";

export default Node.create({
  name: 'reference',

  group: 'inline',

  inline: true,

  selectable: true,
  
  atom: true,

  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      name: {
        default: null,
      },
      type: {
        default: null,
      },
      url: {
        default: null,
      },
      isDraggable: {
        default: true,
        renderHTML: (attributes) => {
          return {};
        }
      },
    }
  },

  parseHTML() {
    return [{
      tag: 'reference',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['reference', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(ReferenceTemplate);
  },
  addCommands() {
    return {
      setReference: (options) => ({ tr, dispatch }) => {
        const { selection } = tr
        const node = this.type.create(options)

        if (dispatch) {
          tr.replaceRangeWith(selection.from, selection.to, node)
        }

        return true
      },
      toggleReference: () => ({ commands }) => {
        return commands.toggleWrap(this.name)
      },
      unsetReference: () => ({ commands }) => {
        return commands.lift(this.name)
      },
    }
  }

});
