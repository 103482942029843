<template>
  <div
    class="overflow-hidden h-1 mb-4 text-xs flex rounded"
     :class="{
        'bg-gray-200': value === 100,
        'bg-primary-200': value !== 100,
     }"
  >
    <div
      :style="{
        width: value + '%',
      }"
      :class="{
          'bg-gray-500': value === 100,
          'bg-primary-500': value !== 100,
       }"
      class="transition-all duration-200 ease-in-out shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
    >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    }
  }
}
</script>