<template>
  <BaseDropdown
    :options="availableOptions"
    :slim="slim"
    divide-items
    auto-open
    @action="onAction"
  >
    <template #default>
      <slot />
    </template>
  </BaseDropdown>
  <DuplicateTaskDialog
    v-model="showDuplicateTaskDialog"
    :task="task"
  />
</template>
<script>
import i18n from '@/i18n'
import DuplicateTaskDialog from '@/modules/tasks/components/DuplicateTaskDialog.vue'

export default {
  components: {
    DuplicateTaskDialog,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDuplicateTaskDialog: false,
    }
  },
  computed: {
    project() {
      return this.task?.relationships?.project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    options() {
      return [
        {
          label: i18n.t('Open As Page'),
          path: `/tasks/${this.task.id}`,
          requiresPermissionTo: this.$actions.OPEN_TASKS
        },
        {
          label: i18n.t('Open In Popup'),
          path: {
            path: this.$route.path,
            query: {
              ...this.$route.query,
              taskId: this.task.id
            }
          },
          requiresPermissionTo: this.$actions.OPEN_TASKS
        },
        {
          label: i18n.t('Duplicate Task'),
          action: () => this.showDuplicateTaskDialog = true,
          loading: this.isDuplicating,
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.CREATE_TASKS)
          },
        },
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.DELETE_TASKS)
          },
        },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    onOptionClicked(option) {
      if (option.path) {
        this.$router.push(option.path)
        return
      }

      this.onAction(option.action)
    },
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.task)
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Delete Task'),
        description: i18n.t('Are you sure? This will permanently remove the task.')
      });

      if(!confirmed) {
        return;
      }

      await this.$store.dispatch('tasks/deleteTask', this.task);
    },
  },
};
</script>
