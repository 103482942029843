<template>
  <div
    class="w-full h-full flex-col-grow"
  >
    <div
      class="grid grid-cols-8 h-full"
      :class="{
        '-mx-8 mt-3': $useNewLayout,
        'gap-6': !$useNewLayout,
      }"
    >
      <ProjectNotesList
        class="col-span-8 md:col-span-3 xl:col-span-2 flex-col-grow"
        :class="{
          'border-r border-t border-gray-200 bg-gray-50': $useNewLayout,
        }"
      />
      <ProjectNote
        v-if="noteId"
        :key="noteId"
        :noteId="noteId"
        ref="openedNote"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import ProjectNote from '@/modules/projects/components/projectNotes/ProjectNote.vue'
import ProjectNotesList from "@/modules/projects/components/projectNotes/ProjectNotesList.vue";
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()

const noteId = ref<string>()

const openedNote = ref()
watch(() => route.params.noteId, async (value) => {
  if (!noteId.value) {
    noteId.value = value as string
    return
  }

  await openedNote.value?.onSave()
  noteId.value = value as string
}, { immediate: true })

watch(() => noteId.value, async (value) => {
  const isProjectOrTemplatePath = route.path.includes('/projects/') || route.path.includes('/templates/')
  if (!value || !isProjectOrTemplatePath) {
    return
  }

  store.dispatch('projects/getProjectNoteById', { id: value })
}, { immediate: true })
</script>
