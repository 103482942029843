<template>
  <div class="relative lg:inline-block text-left mt-2 lg:mt-0">
    <BaseDropdown
      :options="availableOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
      :disabled="completedTasksLoading"
      @option-selected="onOptionSelected"
    >
      <TopFilterChip
        :isActive="selectedOption?.value !== completedTasksFilters.No"
        :prependLabel="$t('Completed')"
        :label="selectedOption?.label"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ $t('Show completed tasks?') }}
            <BaseTooltip>
              <i class="fa-solid fa-circle-question ml-1 cursor-pointer text-gray-400" />
              <template #content>
                {{ $t(`This will load all completed tasks created within the selected timeframe.`) }}
              </template>
            </BaseTooltip>
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import TopFilterChip from "@/modules/filters/components/TopFilterChip.vue";
import { completedTasksFilterOptions, completedTasksFilters } from '@/modules/tasks/utils/taskTableUtils'

export default {
  components: {
    TopFilterChip
  },
  props: {
    defaultFilter: {
      type: String,
      default: 'no'
    }
  },
  data() {
    return {
      completedTasksFilter: this.defaultFilter,
      completedTasksFilters,
    }
  },
  computed: {
    allTimeLoaded() {
      return this.$store.state.tasks.allTimeCompletedLoaded
    },
    completedTasksFilterOptions() {
      return completedTasksFilterOptions.map(option => {
        return {
          ...option,
          variant: this.completedTasksFilter === option.value ? 'active' : '',
          hidden: this.allTimeLoaded && ![completedTasksFilters.No, completedTasksFilters.AllTime].includes(option.value)
        }
      })
    },
    availableOptions() {
      return this.completedTasksFilterOptions.filter(option => !option.hidden)
    },
    selectedOption() {
      return this.completedTasksFilterOptions.find(option => option.value === this.completedTasksFilter)
    },
    completedTasksLoading() {
      return this.$store.state.tasks.completedTasksLoading
    },
  },
  methods: {
    async onOptionSelected(option) {
      if (this.completedTasksFilter === option.value) {
        return
      }

      if (option.value === completedTasksFilters.AllTime && !this.allTimeLoaded) {
        const confirmed = await this.$confirm({
          title: this.$t(`Load all completed tasks`),
          description: this.$t(`Are you sure? Showing completed tasks for all time might slow down this operation.`),
          buttonText: this.$t(`Continue`)
        })

        if (!confirmed) {
          return
        }
      }
      
      this.completedTasksFilter = option.value
      this.$emit('change', this.completedTasksFilter)
    }
  },
  watch: {
    defaultFilter() {
      this.completedTasksFilter = this.defaultFilter
    }
  }
}
</script>
