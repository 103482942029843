<template>
  <BaseDataTable
    :data="data"
    :meta="meta"
    :columns="tableColumns"
    :loading="loading"
    :onRowDragEnd="onRowDragEnd"
    :onRowDragMove="onRowDragMove"
    allowEntityType="available_custom_fields"
  >
    <template #no-data>
      <NoCustomFields />
    </template>
  </BaseDataTable>
</template>
<script lang="ts" setup>
// Utils
import { computed, nextTick } from 'vue'
import { RowDragEvent } from '@ag-grid-community/core';

// Components
import NoCustomFields from "@/modules/accounts/components/NoCustomFields.vue";

// Composables
import { useStore } from "vuex"
import { orderBy } from 'lodash-es';
import { TableColumn } from '@/components/table/tableUtils';
const store = useStore()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const tableColumns = computed(() => {
  const columns = store.getters['accounts/customFieldActiveColumns'] || []
  return columns.filter((c: TableColumn) => {
    if (typeof c.disabled === 'function') {
      return !c.disabled()
    }

    return !c.disabled
  })
})

const meta = computed(() => {
  return store.state.accounts.customFields.meta || {}
})

function onRowDragMove(event: RowDragEvent) {
  if (event.node.parent !== event.overNode?.parent) {
    return
  }

  const newOrders: any = {
    [event.node.data.id]: event.overNode.data.attributes.order,
    [event.overNode.data.id]: event.node.data.attributes.order
  }

  const customFieldsNew: any[] = []
  
  event.api.forEachNode(node => {
    if (node.data) {
      customFieldsNew.push({
        ...node.data,
        attributes: {
          ...node.data.attributes,
          order: newOrders[node.data.id] || node.data.attributes.order
        }
      })
    }
  })


  const newRowData = orderBy(customFieldsNew, ['attributes.entity_type', 'attributes.order'])

  event.api.setRowData(newRowData)
  event.api.clearFocusedCell()
}

async function onRowDragEnd(event: RowDragEvent) {

  const customFields: any[] = []
  
  event.api.forEachNode(node => {
    if (node.data) {
      customFields.push(node.data)
    }
  })

  await store.dispatch('accounts/reorderCustomFields', { customFields })

  await nextTick()

  event.api.refreshCells()
}
</script>
