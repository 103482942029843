import TasksModuleLayout from "@/modules/tasks/layout/TasksModuleLayout.vue";
import TasksLayout from "@/modules/tasks/layout/TasksLayout.vue";
import TasksKanbanLayout from "@/modules/tasks/layout/TasksKanbanLayout.vue";
import TasksList from "@/modules/tasks/pages/tasks-list.vue";
import TaskCards from '@/modules/tasks/pages/tasks-cards.vue'
import TasksCalendar from "@/modules/tasks/pages/tasks-calendar.vue";
import TasksScheduler from "@/modules/tasks/pages/tasks-scheduler.vue";
import TaskDetails from "@/modules/tasks/pages/task-details.vue";
import TasksKanban from "@/modules/tasks/pages/tasks-kanban.vue";
import {
  ACCESS_TASKS_CALENDAR,
  ACCESS_TASKS_KANBAN,
  ACCESS_TASKS_LIST,
  ACCESS_TASKS_SCHEDULER,
  CREATE_TASKS,
  OPEN_TASKS
} from "@/modules/common/enum/actionsEnum";
import { ApiFilterKeys } from "@/modules/common/commonTypes.d";

export default [
  {
    path: '/tasks',
    redirect: '/tasks/list',
    name: 'Tasks',
    component: TasksModuleLayout,
    children: [
      {
        path: '/tasks',
        redirect: '/tasks/list',
        name: 'Tasks Layout',
        component: TasksLayout,
        children: [
          {
            path: '/tasks/list',
            name: 'All Tasks List',
            component: TasksList,
            meta: {
              requiresPermissionTo: ACCESS_TASKS_LIST,
              isViewType: true,
              useFilter: ApiFilterKeys.MyTasks,
            }
          },
          {
            path: '/tasks/calendar',
            name: 'All Tasks Calendar',
            component: TasksCalendar,
            meta: {
              requiresPermissionTo: ACCESS_TASKS_CALENDAR,
              isViewType: true,
              useFilter: ApiFilterKeys.MyTasks,
            }
          },
          {
            path: '/tasks/scheduler',
            name: 'All Tasks Scheduler',
            component: TasksScheduler,
            meta: {
              requiresPermissionTo: ACCESS_TASKS_SCHEDULER,
              isViewType: true,
              useFilter: ApiFilterKeys.MyTasks,
            }
          },
        ],
      },
      {
        path: '/tasks',
        name: 'Tasks Kanban Layout',
        component: TasksKanbanLayout,
        meta: {
          hideBreadCrumb: true,
        },
        children: [
          {
            path: 'kanban',
            name: 'All Tasks Kanban',
            component: TasksKanban,
            meta: {
              requiresPermissionTo: ACCESS_TASKS_KANBAN,
              isViewType: true,
              useFilter: ApiFilterKeys.MyTasks,
            }
          },
          {
            path: 'card',
            name: 'Task Cards',
            component: TaskCards,
            meta: {
              requiresPermissionTo: ACCESS_TASKS_LIST,
              isViewType: true,
              useFilter: ApiFilterKeys.MyTasks,
            }
          },
        ]
      },
      {
        path: '/tasks/add',
        name: 'Add New Task',
        component: TaskDetails,
        meta: {
          requiresPermissionTo: CREATE_TASKS
        }
      },
      {
        path: '/tasks/:id',
        name: 'Task Details',
        component: TaskDetails,
        meta: {
          requiresPermissionTo: OPEN_TASKS
        }
      },
    ]
  },
]
