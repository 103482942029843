<template>
 <dl class="mt-6 divide-y divide-gray-900/10 border border-gray-200 rounded-lg">
  <div>
    <dt>
      <div class="flex w-full items-start justify-between text-left text-gray-900 px-6 py-4">
        <span class="text-lg font-bold">
          {{ $t(`Getting Started Checklist`) }}
        </span>
        <span class="text-sm text-gray-400 pt-1">
          {{ $tc('percentage complete', { percentage: percentageCompleted }) }}
        </span>
      </div>
    </dt>
    <ProgressBar /> 
  </div>
  <ElCollapse
    v-model="activeNames"
    class="divide-y divide-gray-900/10"
  >
    <GettingStartedItem
      v-for="step of gettingStartedSteps"
      :key="step.key"
      :step="step"
      @step-completed="onStepCompleted"
    />
  </ElCollapse>
</dl>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

import ProgressBar from '@/modules/accounts/components/getting-started/ProgressBar.vue';
import GettingStartedItem from '@/modules/accounts/components/getting-started/GettingStartedItem.vue';

import { ElCollapse } from 'element-plus';

import {
  useGettingStarted,
  GettingStartedKeys,
  GettingStartedStep
} from '@/modules/accounts/composables/useGettingStarted';

const {
  percentageCompleted,
  gettingStartedSteps,
} = useGettingStarted()

const activeNames = ref<GettingStartedKeys[]>([])

function onStepCompleted(step: GettingStartedStep) {
  activeNames.value = activeNames.value.filter((key) => step.key !== key)
}
</script>
