export function readURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => resolve(event.target.result);
    reader.onerror = event => reject(event);
    reader.readAsDataURL(file);
  });
}

export function imgToFile(img, fileName) {
  return new Promise(resolve => {
    const canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth
    canvas.height = img.naturalHeight

    const context = canvas.getContext('2d');
    context.drawImage(img, 0, 0);

    canvas.toBlob((blob) => {
      const file = new File([blob], fileName);
      resolve(file)
    })
  })
}

export async function getImgSize({ src, maxSize = 500 }) {
  return new Promise((resolve, reject) => {
    if (!src) {
      resolve({ width: 0, height: 0 });
    }
    const newImg = new Image();

    newImg.onload = () => {
      let height = newImg.height;
      let width = newImg.width;
      const aspectRatio = width / height

      if (maxSize && width > maxSize) {
        width = maxSize
        height = maxSize / aspectRatio
      } else if (maxSize && height > maxSize) {
        width = maxSize * aspectRatio
        height = maxSize
      }

      resolve({ height, width });
    }
    newImg.onerror = () => {
      reject('Could not load image')
    }

    newImg.src = src; // this must be done AFTER setting onload
  })
}

export function loadImage(url) {
  return new Promise(function (resolve, reject) {
    const img = new Image()
    img.onload = function () {
      resolve(url)
    }
    img.onerror = function () {
      reject(url)
    }
    img.src = url
  })
}
