<template>
  <div
    class="max-w-3xl m-auto align-middle bg-white border border-gray-200 overflow-hidden rounded-lg shadow
    print:max-w-full print:rounded-none print:min-h-screen
    print:-mt-10 print:shadow-none print:border-none"
  >
    <div
      v-loading="paymentLoading"
      class="w-full py-6 print:pt-2"
    >
      <div
        ref="pdf-root"
        class="px-10 bg-white"
      >
        <div>
          <div class="w-ful flex items-start justify-between">
            <p class="text-4xl font-extrabold sm:text-5xl">
              {{ $t('Recurring Payment') }}
            </p>
            <PaymentStatus v-if="hasStripeInfo" :status="paymentStatus" class="mt-2"/>
          </div>
          <div class="flex justify-between">
            <div>
              <p class="mt-4 text-base text-gray-500">{{ $t('Project:') }} {{ project?.name || '- -' }}</p>
              <p class="mt-2 text-base text-gray-500">{{ $t('Start Date:') }} {{ $formatDate(payment?.created_at) }}</p>
            </div>
            <div id="invoice-logo-container">
              <img
                v-if="invoiceInfo?.invoice_logo"
                :src="invoiceInfo?.invoice_logo"
                id="invoice-logo"
                alt="company logo"
                class="h-8"
              >
            </div>
          </div>
        </div>
        <section
          aria-labelledby="order-heading"
          class="mt-10 print:mt-6 border-t border-gray-200"
        >
          <h2 id="order-heading" class="sr-only">{{ $t('Your order') }}</h2>
          <h3 class="sr-only">{{ $t('Items') }}</h3>
          <div>
            <h3 class="sr-only">{{ $t('Your information') }}</h3>
            <h4 class="sr-only">{{ $t('Addresses') }}</h4>
            <dl class="grid grid-cols-1 md:grid-cols-3 gap-6 text-sm py-6 print:py-6">
              <div>
                <dt class="font-bold text-gray-900">{{ $t('Payment From') }}</dt>
                <dd class="mt-2 text-gray-700 word-break">
                  <pre
                    v-html="paymentFrom"
                    class="mt-2 text-sm text-gray-700 font-sans block"
                  />
                </dd>
              </div>
              <div>
                <dt class="font-bold text-gray-900">{{ $t('Payment To') }}</dt>
                <dd class="mt-2 text-gray-700">
                  <address class="not-italic">
                    <span v-if="paymentTo.street_address_1" class="block">
                      {{ paymentTo.street_address_1 }}
                    </span>
                    <span v-if="paymentTo.street_address_2" class="block">
                      {{ paymentTo.street_address_2 }}
                    </span>
                    <span v-if="paymentTo.city" class="block">
                      {{ paymentTo.city }}
                    </span>
                    <span v-if="paymentTo.region" class="block">
                      {{ paymentTo.region }}
                    </span>
                    <span v-if="paymentTo.post_code" class="block">
                      {{ paymentTo.post_code }}
                    </span>
                    <span v-if="paymentTo.country" class="block">
                      {{ paymentTo.country }}
                    </span>
                  </address>
                </dd>
              </div>
              <div>
                <dt class="font-bold text-gray-900">{{ $t('Payment Method') }}</dt>
                <dd class="mt-2 text-gray-700">
                  <address class="not-italic">
                      <span
                        v-if="payment?.payment_provider"
                        class="capitalize block"
                      >
                        {{ payment?.payment_provider }}
                      </span>
                    <span
                      v-if="payment?.card_brand"
                      class="capitalize block"
                    >
                        {{ payment?.card_brand }}
                      </span>
                    <span
                      v-if="payment?.last_four"
                      class="capitalize block"
                    >
                        <span aria-hidden="true">•••• </span>
                        <span class="sr-only">{{ $t('Ending in ') }}</span>
                        {{ payment?.last_four }}
                      </span>
                  </address>
                </dd>
              </div>
            </dl>
            <h3 class="sr-only">{{ $t('Summary') }}</h3>
            <div class="py-6 print:py-4 border-b border-gray-200 flex space-x-6 border-t">
              <div class="flex-auto flex flex-col">
                <div>
                  <h4 class="font-bold text-gray-900">
                    {{ $t('Payment Description') }}
                  </h4>
                  <p class="mt-2 text-sm text-gray-600">
                    {{ payment?.description || '- -' }}
                  </p>
                </div>
              </div>
            </div>
            <dl v-if="payment"
                class="space-y-6 text-sm pt-6 print:pt-4 print:space-y-3">
              <div v-for="invoice in invoices"
                   :key="invoice.id"
                   class="flex justify-between">
                <div class="flex items-center space-x-2">
                  <dt class="font-bold text-gray-900">
                    {{ $formatDate(invoice.payment_date || payment.created_at) }}
                  </dt>
                  <InvoiceStatus :status="invoice.invoice_status"/>
                </div>
                <div class="flex ites-center space-x-2">
                  <dd class="text-gray-700">
                    {{ $formatPrice(getPaidAmount(invoice), { currencyCode: payment?.currency, }) }}
                  </dd>
                  <div>
                    <router-link
                      class="text-primary-500 underline font-medium"
                      :to="getInvoicePath(invoice)">
                      {{ $t('View Invoice') }}
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="flex items-center space-x-2">
                  <dt class="font-bold text-gray-900" v-if="hasStripeInfo">{{ $formatDate(nextPaymentDate) }}</dt>
                  <InvoiceStatus :status="hasStripeInfo ? INVOICE_STATUSES.UPCOMING: INVOICE_STATUSES.UPCOMING_FIRST"/>
                </div>
                <dd class="text-gray-700">
                  {{ $formatPrice(payment?.amount, { currencyCode: payment?.currency, }) }}
                </dd>
              </div>
              <pre
                v-if="invoiceInfo?.invoice_footer"
                v-html="invoiceInfo?.invoice_footer"
                class="text-sm text-gray-600 font-sans block border-t border-gray-200 pt-6"
              />
            </dl>
          </div>
        </section>

      </div>

      <div class="flex justify-end space-x-2 px-10 mt-6">
        <BaseButton
          v-if="canMakePayment"
          :loading="checkoutSessionLoading"
          @click="onMakePayment"
        >
          <template v-if="hasStripeInfo">
            {{ $t('Subscription Details') }}
          </template>
          <template v-else>
            {{ $t('Make Payment') }}
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script>

import { INVOICE_STATUSES, PAYMENT_STATUSES } from "@/modules/payments/enum/paymentEnums.js";
import { parseInvoiceDetails } from "@/modules/payments/utils/paymentUtils.js";
import InvoiceStatus from "@/modules/payments/components/InvoiceStatus.vue";
import PaymentStatus from "@/modules/payments/components/PaymentStatus.vue";

export default {
  components: {
    PaymentStatus,
    InvoiceStatus,
  },
  data() {
    return {
      PAYMENT_STATUSES,
      INVOICE_STATUSES,
      checkoutSessionLoading: false,
    }
  },
  computed: {
    currentPayment() {
      return this.$store?.state.payments?.currentPayment
    },
    payment() {
      return this.currentPayment?.payment
    },
    invoices() {
      const invoices = this.currentPayment?.invoices || []
      return invoices.filter(invoice => invoice.invoice_status !== INVOICE_STATUSES.FAILED)
    },
    nextPaymentDate() {
      const nextPaymentDate = this.payment?.stripe_info?.current_period_end
      if (!nextPaymentDate || isNaN(nextPaymentDate)) {
        return new Date()
      }
      return new Date(nextPaymentDate * 1000)
    },
    hasStripeInfo() {
      return this.payment?.stripe_info
    },
    paymentStatus() {
      if (this.payment?.stripe_info?.canceled_at) {
        return PAYMENT_STATUSES.CANCELLED
      }
      return PAYMENT_STATUSES.ACTIVE
    },
    paymentLoading() {
      return this.$store.state.payments.currentPaymentLoading
    },
    paymentTo() {
      return {
        city: this.$settings('city'),
        region: this.$settings('region'),
        country: this.$settings('country'),
        street_address_1: this.$settings('street_address_1'),
        street_address_2: this.$settings('street_address_2'),
        post_code: this.$settings('post_code'),
      }
    },
    invoiceInfo() {
      return {
        invoice_logo: this.$settings('invoice_logo'),
        invoice_footer: this.$settings('invoice_footer'),
      }
    },
    paymentFrom() {
      return parseInvoiceDetails(this.currentPayment?.payment_group?.invoice_details)
    },
    project() {
      return this.currentPayment?.payment_project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    hasPaymentMethodsConfigured() {
      return this.$store.getters['accounts/hasPaymentMethodsConfigured']
    },
    canMakePayment() {
      return !this.isProjectClosed && this.hasPaymentMethodsConfigured
    }
  },
  methods: {
    getInvoicePath(invoice) {
      let currentPath = this.$route.path
      const invoicePath = currentPath.replace('details', 'invoice')
      return {
        path: invoicePath,
        query: {
          invoiceId: invoice.id
        }
      }
    },
    getPaidAmount(invoice) {
      const paidAmount = invoice?.paid_amount || 0
      return paidAmount / 100
    },
    async onMakePayment() {
      let url
      try {
        this.checkoutSessionLoading = true
        if (this.hasStripeInfo) {
          url = await this.$store.dispatch('payments/getPaymentBillingPortalSession', this.payment)
        } else {
          url = await this.$store.dispatch('payments/getPaymentCheckoutSession', this.payment)
        }
        if (!url) {
          this.$error(this.$t('Something went wrong. Please try again later.'))
          return
        }
        window.open(url, '_blank', 'noopener noreferrer')
      } finally {
        this.checkoutSessionLoading = false
      }
    },
  },
}
</script>
