<template>
  <div class="relative inline-block text-left">
    <template v-if="makePaymentOnly">
      <BaseButton
        v-if="canMakePayment"
        @click="onMakePayment"
      >
        {{ $t('Make payment') }}
      </BaseButton>
    </template>
    <BaseDropdown
      v-else
      :options="availableOptions"
      :slim="slim"
      :auto-open="true"
      divide-items
      @action="onAction"
    >
      <template #default>
        <slot />
      </template>
    </BaseDropdown>

    <MakePaymentDialog
      v-if="showMakePaymentDialog"
      v-model="showMakePaymentDialog"
      v-model:paymentErrors="paymentErrors"
      :payment="payment"
      @close="showMakePaymentDialog = false"
      @paid-with-stripe="onPaidWithStripe"
      @paid-with-paypal="onPaidWithPaypal"
      @payment-failed="onPaymentFailed"
    />

    <PaymentConfirmationDialog
      v-show="showPaymentConfirmationDialog"
      v-model="showPaymentConfirmationDialog"
      :variant="paymentErrors ? 'error' : 'primary'"
      :message="paymentErrors"
      @close="onPaymentConfirmation"
      @save="onPaymentConfirmation"
    />
  </div>
</template>
<script>
import {
  PAYMENT_STATUSES,
  PaymentProviders
} from "@/modules/payments/enum/paymentEnums.js";
import i18n from '@/i18n'
import MakePaymentDialog from "@/modules/payments/components/MakePaymentDialog.vue";
import PaymentConfirmationDialog from "@/modules/payments/components/PaymentConfirmationDialog.vue";

export default {
  components: {
    PaymentConfirmationDialog,
    MakePaymentDialog,
  },
  props: {
    payment: {
      type: Object,
      default: () => ({})
    },
    slim: {
      type: Boolean,
      default: false,
    },
    makePaymentOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showMakePaymentDialog: false,
      showPaymentConfirmationDialog: false,
      paymentErrors: '',
      makePaymentLoading: false,
    }
  },
  computed: {
    project() {
      return this.payment?.relationships?.project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    isPaid() {
      return this.payment?.attributes?.payment_status === PAYMENT_STATUSES.PAID
    },
    isTemplatePayment() {
      return this.payment?.relationships?.project?.attributes?.is_template
    },
    canMakePayment() {
      if (this.isRecurring) {
        return this.payment?.attributes?.payment_status === PAYMENT_STATUSES.UNPAID
      }
      return !this.isTemplatePayment && !this.isPaid
    },
    isManuallyPaid() {
      return this.isPaid && this.payment?.attributes?.payment_provider === PaymentProviders.Manual
    },
    isRecurring() {
      return this.payment?.attributes?.is_recurring
    },
    options() {
      return [
        {
          label: this.isRecurring
            ? i18n.t('View Details')
            : i18n.t('View Invoice'),
          action: 'onViewDetails',
        },
        {
          label: i18n.t('Make Payment'),
          action: 'onMakePayment',
          enabled: () => {
            return this.canMakePayment && !this.isProjectClosed
          }
        },
        {
          label: i18n.t('Mark Paid'),
          action: 'onMarkPaid',
          enabled: () => {
            if (this.isRecurring) {
              return false
            }
            return this.canMakePayment && this.can(this.$actions.EDIT_PAYMENTS) && !this.isProjectClosed
          }
        },
        {
          label: i18n.t('Mark Unpaid'),
          action: 'onMarkUnpaid',
          enabled: () => {
            return this.isManuallyPaid && this.can(this.$actions.EDIT_PAYMENTS) && !this.isProjectClosed
          }
        },
        {
          label: i18n.t('Edit Details'),
          action: 'onEdit',
          enabled: () => {
            if (this.isRecurring) {
              return false
            }
            return !this.isPaid && this.can(this.$actions.EDIT_PAYMENTS) && !this.isProjectClosed
          }
        },
        {
          label: i18n.t('Delete'),
          action: 'deletePayment',
          enabled: () => {
            return!this.isPaid && this.can(this.$actions.DELETE_PAYMENTS) && !this.isProjectClosed
          }
        }
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.payment)
    },
    async deletePayment() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t("Delete Payment"),
        description: i18n.t("Are you sure? This will permanently delete the payment"),
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch("payments/deletePayment", this.payment.id);
      } catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(i18n.t("Cannot delete the payment"));
        throw err;
      }
    },
    onEdit() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          paymentId: this.payment.id
        }
      })
    },
    async onMarkPaid(notify = true, invoice_details = null) {
      try {
        await this.$store.dispatch('payments/markPaid', {
          payment: this.payment,
          invoice_details
        })

        if (notify) {
          this.$success(i18n.t('Payment was marked as paid'))
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not mark the payment as paid'))
      }
    },
    async onMarkUnpaid() {
      try {
        await this.$store.dispatch('payments/markUnpaid', {
          payment: this.payment,
        })

        this.$success(i18n.t('Payment was marked as unpaid'))
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not mark the payment as unpaid'))
      }
    },
    async onMakeRecurringPayment() {
      let url
      if (this.hasStripeInfo) {
        url = await this.$store.dispatch('payments/getPaymentBillingPortalSession', this.payment)
      } else {
        url = await this.$store.dispatch('payments/getPaymentCheckoutSession', this.payment)
      }
      if (!url) {
        this.$error(i18n.t('Something went wrong. Please try again later.'))
        return
      }
      window.open(url, '_blank', 'noopener noreferrer')
    },
    onMakePayment() {
      if (this.isRecurring) {
        this.onMakeRecurringPayment()
        return
      }
      this.showMakePaymentDialog = true
    },
    async onPaidWithPaypal(details) {
      const invoice_details = {
        payment_method: PaymentProviders.Paypal,
        payment_reference: details.id
      }

      await this.onMarkPaid(false, invoice_details)
      await this.onPaymentSuccess()
    },
    async onPaidWithStripe() {
      const updatedPayment = {
        ...this.payment,
        attributes: {
          ...this.payment.attributes,
          payment_status: PAYMENT_STATUSES.PAID,
          payment_provider: PaymentProviders.Stripe
        }
      }
      this.$store.commit('payments/updatePayment', updatedPayment)

      await this.onPaymentSuccess()
    },
    async onPaymentSuccess() {
      this.showMakePaymentDialog = false
      this.paymentErrors = ''
      this.showPaymentConfirmationDialog = true
      this.$emit('payment-success')
    },
    onPaymentFailed() {
      this.showPaymentConfirmationDialog = true
    },
    onViewDetails() {
      const projectId = this.$store.getters.project_id
      let basePath = ''
      if (projectId) {
        basePath = this.isTemplatePayment ? `/templates/${projectId}/payments/` : `/projects/${projectId}/payments/`
      }
      const suffix = this.isRecurring ? '/details' : '/invoice'
      this.$router.push(`${basePath}${this.payment.id}${suffix}`)
    },
    onPaymentConfirmation(type) {
      this.showPaymentConfirmationDialog = false
      if (type === 'error') {
        return
      }
      this.$emit('refresh')
    }
  }
}
</script>
