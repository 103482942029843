<template>
  <BaseDropdown
    :options="availableOptions"
    :slim="slim"
    divide-items
    @action="onAction"
  >
    <template #default>
      <slot />
    </template>
  </BaseDropdown>
</template>
<script>
import { MenuButton, MenuItem } from "@headlessui/vue";
import i18n from '@/i18n'

export default {
  components: {
    MenuItem,
    MenuButton,
  },
  props: {
    allocatedTime: {
      type: Object,
      default: () => ({})
    },
    slim: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    project() {
      return this.allocatedTime?.relationships?.project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    allocatedTimePath() {
      return {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          allocatedTimeId: this.allocatedTime.id
        }
      }
    },
    options() {
       return [
        {
          label: i18n.t('Edit'),
          path: this.allocatedTimePath,
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS)
          },
        },
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.DELETE_TIME_ENTRIES)
          },
        },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.allocatedTime)
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Delete Allocated Time'),
        description: i18n.t('Are you sure? This will permanently remove the allocted time.')
      });

      if(!confirmed) {
        return;
      }

      await this.$store.dispatch('time/deleteAllocatedTime', this.allocatedTime.id);
    }
  }
}
</script>
