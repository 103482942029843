<template>
  <div class="relative lg:inline-block text-left mt-2 lg:mt-0">
    <BaseDropdown
      :options="dropdownOptions"
      trigger="hover"
      divide-items
      large-items
    >
      <TopFilterChip
        :isActive="!!kanbanColumn"
        :prependLabel="$t('Columns')"
        :label="kanbanColumn?.name || $t('None')"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ $t('Choose kanban columns') }}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import { useKanbanGroups } from "@/modules/projects/composables/useKanbanGroups.js";
import {
  customFieldAppliesToOptions,
  triggerCreateCustomField
} from "@/modules/accounts/utils/modelUtils"  
import TopFilterChip from '@/modules/filters/components/TopFilterChip.vue'

export default {
  components: {
    TopFilterChip,
  },
  props: {
    target: {
      type: String,
      default: 'projects'
    }
  },
  setup(props) {
    const { fieldOptions } = useKanbanGroups({
      entity: props.target === 'tasks' ? 'task' : 'project'
    })

    return {
      fieldOptions
    }
  },
  computed: {
    kanbanColumn: {
      get() {
        return this.$store.getters['filters/targetLocalFilters'](this.target)?.kanbanColumn || {}
      },
      set(value) {
        this.$emit('change', {
          name: value.name,
          groupByProp: value.groupByProp
        })
      }
    },
    project_id() {
      return this.$store.getters.project_id
    },
    canCreateCustomFields() {
      return this.can(this.$actions.CREATE_CUSTOM_FIELDS) || (this.project_id && this.can(this.$actions.CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS))
    },
    dropdownOptions() {
      const fieldOptions = this.fieldOptions.map(field => ({
        label: field.name,
        action: () => this.kanbanColumn = field,
        variant: this.kanbanColumn?.name === field?.name ? 'active' : ''
      }))
      
      if (!this.canCreateCustomFields) {
        return fieldOptions
      }

      const createCustomFieldOption = {
        label: this.$t('Create new custom field'),
        action: this.triggerCustomFieldCreation,
        icon: 'fa-solid fa-stars text-gray-400'
      }

      return [...fieldOptions, createCustomFieldOption]
    }
  },
  watch: {
    fieldOptions: {
      immediate: true,
      deep: true,
      async handler(newValue) {
        await this.$nextTick()

        if (this.kanbanColumn?.name) {
          return
        }

        this.kanbanColumn = newValue[0] || {}
      }
    },
  },
  
  methods: {
    triggerCustomFieldCreation() {
      const targetToAppliesTo = {
        'projects': customFieldAppliesToOptions.PROJECT,
        'tasks': customFieldAppliesToOptions.TASK,
      }

      const appliesTo = targetToAppliesTo[this.target]

      triggerCreateCustomField(appliesTo, this.project_id, {
        createParams: {
          isKanbanFieldCreation: true,
        },
        postSync: async (customField) => {
          await this.$nextTick()

          const customFieldColumn = this.fieldOptions.find(c => c.name === customField?.attributes?.name)

          if (customFieldColumn) {
            this.kanbanColumn = customFieldColumn 
          }
        }
      })
    }
  },
}
</script>
