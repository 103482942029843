<template>
  <component
    :is="wrapperComponent"
    :to="taskPath"
    draggable="false"
  >
    <div
      class="flex space-x-2 whitespace-wrap items-center"
      :class="{
        'sm:whitespace-nowrap': !isCard
      }"
    >
      <i
        v-if="isSubtask"
        class="far fa-diagram-subtask mr-1 text-gray-400"
      />
      <div
        class="truncate"
        :class="{
          'line-clamp-1': isCard
        }"
      >
        {{ task.attributes.name }}
      </div>
      <TaskStats
        v-if="!isCard"
        :task="task"
      />
    </div>
  </component>
</template>
<script>
import { taskDateTypes, visibilityTypes } from '@/modules/tasks/utils/modelUtils';
import { getSubtasks } from '@/modules/tasks/utils/taskTableUtils';
import TaskStats from '@/modules/tasks/components/TaskStats.vue';

export default {
  components: {
    TaskStats
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    link: {
      type: String
    },
    hideLink: {
      type: Boolean,
      default: false,
    },
    isCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    taskPath() {
      if (this.link) {
        return this.link
      }
      return {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          taskId: this.task.id,
        }
      }
    },
    isProjectViewRoute() {
      return this.$route.name === 'Project View'
    },
    wrapperComponent() {
      if (this.hideLink) {
        return 'div'
      }
      //! this is a workaround for a project view route, where we need to use <a> to avoid changing the route that causes the editor to rerender
      if (this.isProjectViewRoute) {
        return 'a'
      }
      return 'router-link'
    },
    isSubtask() {
      return !!this.task.attributes.parent_id
    },
    isRecurringTask() {
      return this.task.attributes.date_type === taskDateTypes.RECURRING
    },
    isSourceRecurringTask() {
      return this.isRecurringTask && this.task.attributes.is_recurrence_source
    },
    subTasks() {
      return getSubtasks(this.task)
    },
    isTaskClosedFn() {
      return this.$store.getters['tasks/isTaskClosed']
    },
    activeSubTasksCount() {
      return this.subTasks.filter(subTask => !this.isTaskClosedFn(subTask)).length
    },
    filesCount() {
      return this.task.attributes.files_count
    },
    commentCount() {
      return this.task.attributes.comment_count
    },
    isTaskClosed() {
      return this.isTaskClosedFn(this.task)
    },
    hasCreatorsAndCollaboratorsPrivacy() {
      return this.task.attributes.visibility === visibilityTypes.CREATORS_AND_COLLABORATORS
    }
  },
};
</script>
