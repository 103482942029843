export function secondsToFormattedTime(timeInSeconds) {

  let hours = Math.floor(timeInSeconds / 3600)
  timeInSeconds -= hours * 3600

  let minutes = Math.floor(timeInSeconds / 60) % 60
  timeInSeconds -= minutes * 60

  return `${addPrefix(hours)}:${addPrefix(minutes)}`
}

function addPrefix(value) {
  if (value < 10) {
    return `0${value}`
  }
  return value
}

export default class Timer {
  constructor(options = { interval: 1000, initialTimeInSeconds: 0 }) {
    this.state = {
      seconds: options.initialTimeInSeconds || 0,
    }
    this.options = options
    this.interval = null
  }

  get displayTime() {
    return secondsToFormattedTime(this.state.seconds)
  }

  reset() {
    this.state.seconds = 0
  }

  start() {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.interval = setInterval(() => {
      this.state.seconds++
    }, this.options.interval || 1000)
  }

  stop() {
    clearInterval(this.interval)
  }
}
