import axios from "axios";
import i18n from "@/i18n.js";
import { get, set } from "lodash-es";
import { NOTIFICATION_TYPES, notificationTypeOptions } from "@/modules/accounts/utils/notificationUtils"

const notificationsRelated = 'notificationCreator,notificationProject,notificationTask,notificationPayment,notificationUser'

const state = () => ({
  notifications: {
    data: [],
  },
  projectNotifications: {
    data: [],
  },
  projectNotificationsLoading: false,
  notificationsLoading: false,
  openedNotification: null,
  isNotificationSelectionEnabled: false,
  selectedNotifications: [],
  newNotificationReceived: false,
  notificationColumns: [
    {
      name: i18n.t('Notification Type'),
      prop: 'attributes.notifiable_target_type',
      filterBy: {
        prop: 'notifiable_target_type',
        component: 'NotificationTypeSelect',
        displayFormat(value) {
          return notificationTypeOptions.find(x => x.value === value)?.label || ''
        },
      },
      group: true
    },
    {
      name: i18n.t('Date'),
      prop: 'attributes.created_at',
      filterBy: {
        prop: 'created_at',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy'
      },
      group: true
    },
    {
      name: i18n.t('Posted by'),
      prop: 'attributes.created_by',
      filterBy: {
        prop: 'created_by',
        component: 'UserSelect',
        props: {
          multiple: true
        }
      },
      group: true
    },
    {
      name: i18n.t('Project'),
      prop: 'attributes.project',
      filterBy: {
        prop: 'project_ids',
        component: 'ProjectSelect',
        props: {
          multiple: true
        }
      },
      group: true
    },
    {
      name: i18n.t('Project Status'),
      prop: 'attributes.project',
      filterBy: {
        prop: 'project_status',
        type: 'ProjectStatusSelect',
        format: 'formatIdsArray',
        displayFormat: 'formatColorOptions',
        props: {
          multiple: true
        }
      },
      group: true
    },

  ],
});

const mutations = {
  setNotifications(state, value) {
    state.notifications.data = value
  },
  setProjectNotifications(state, value) {
    state.projectNotifications = {
      ...value,
    }
  },
  setProjectNotificationsLoading(state, value) {
    state.projectNotificationsLoading = value
  },
  setNotificationsLoading(state, value) {
    state.notificationsLoading = value
  },
  addNotification(state, notification) {
    state.projectNotifications.data.unshift(notification)
  },
  setOpenedNotification(state, value) {
    state.openedNotification = value
  },
  setIsNotificationSelectionEnabled(state, value) {
    state.isNotificationSelectionEnabled = value
  },
  setSelectedNotifications(state, value) {
    state.selectedNotifications = value
  },
  toggleNotificationSelected(state, notification) {
    const idx = state.selectedNotifications?.findIndex(x => x.id === notification.id)

    if (idx !== -1) {
      state.selectedNotifications?.splice(idx, 1)
    }
    else {
      state.selectedNotifications.push(notification)
    }
  },
  sortNotificationByLastAdded(state, lastNotificationIndex) {
    if (lastNotificationIndex === 0 || lastNotificationIndex === -1) {
      return
    }
    const lastNotification = state.notifications.data[lastNotificationIndex]
    state.notifications.data.splice(lastNotificationIndex, 1)
    state.notifications.data.splice(0, 0, lastNotification)
  },
  newNotificationReceived(state, value) {
    state.newNotificationReceived = value
  },
  markNotificationAsRead(state, notificationId) {
    const notificationIndex = state.notifications.data.findIndex(notification => notification.id === notificationId)
    if (notificationIndex === -1) {
      return
    }

    const { unread_notification_count, read_notification_count } = state.notifications.data[notificationIndex]
    state.notifications.data[notificationIndex]['unread_notification_count'] = unread_notification_count - 1
    state.notifications.data[notificationIndex]['read_notification_count'] = read_notification_count + 1
  },
  setNotificationsPinned(state, { projectId, isPinned }) {
    const notificationIndex = state.notifications.data.findIndex(notification => notification.id === projectId)

    if (notificationIndex === -1) {
      return
    }

    const { pinned_notification_count } = state.notifications.data[notificationIndex]
    const count = isPinned ? 1 : -1
    state.notifications.data[notificationIndex]['pinned_notification_count'] = pinned_notification_count + count
  },
};

const actions = {
  async getNotifications({ commit, rootState }, options) {
    let filters = options?.filters
    const silent = options?.silent

    try {

      if (!silent) {
        commit("setProjectNotificationsLoading", true)
        commit("setNotificationsLoading", true)
      }

      if (filters?.shouldReset === true) {
        commit("setNotifications", []);
      }

      filters = filters || { ...rootState?.route?.query }

      let { data } = await axios.get('/project-notifications', {
        params: {
          perPage: 999,
          ...filters,
        },
      }) || []

      commit("setNotifications", data)
    } finally {

      if (!silent) {
        commit("setNotificationsLoading", false)
        commit("setProjectNotificationsLoading", false)
      }
      
      commit("newNotificationReceived", false)
    }
  },

  async getProjectNotifications({ commit }, { projectId, filters }) {
    try {
      commit("setProjectNotificationsLoading", true)

      const notifications = await axios.get("/restify/notifications", {
        params: {
          perPage: 100,
          ...filters,
          related: notificationsRelated,
          project_id: projectId,
        },
      });

      notifications.data.forEach(notification => {
        const notificationType = get(notification, 'attributes.data.notifiable_target_type')
        const message = get(notification, 'attributes.data.message')

        if (notificationType === NOTIFICATION_TYPES.PAYMENT && message === 'Payment successfully paid') {
          set(notification, 'attributes.data.notifiable_target_type', NOTIFICATION_TYPES.PAYMENT_SUCCESS)
        }

        if (notificationType === NOTIFICATION_TYPES.DISCUSSION_REACTION) {
          const newType = get(notification, 'relationships.notificationTask.id')
            ? NOTIFICATION_TYPES.TASK_DISCUSSION_REACTION
            : NOTIFICATION_TYPES.PROJECT_DISCUSSION_REACTION

          set(notification, 'attributes.data.notifiable_target_type', newType)
        }
      })

      commit("setProjectNotifications", notifications)

    } finally {
      commit("setProjectNotificationsLoading", false)
    }
  },

  async addNotification({ commit, dispatch, state }, notificationId) {
    try {
      const { data } = await axios.get(`/restify/notifications/${notificationId}`, {
        params: {
          related: notificationsRelated,
        }
      })

      const projectId = get(data, 'attributes.project_id')
      const notificationIndex = (state.notifications?.data || []).findIndex(notification => notification.id === projectId)

      if (notificationIndex === -1) {
        commit("newNotificationReceived", true)
      }
      else {
        const { unread_notification_count } = state.notifications.data[notificationIndex]
        state.notifications.data[notificationIndex]['unread_notification_count'] = unread_notification_count + 1
      }

      if (projectId === state.openedNotification?.id) {
        commit("addNotification", data)
      }

      commit("sortNotificationByLastAdded", notificationIndex)

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },

  async markNotificationAsRead({ dispatch, commit }, { notificationId, projectId }) {
    try {
      await axios.post('/restify/notifications/actions?action=mark-as-read', {
        repositories: notificationId
      })

      dispatch('accounts/getStats', null, { root: true })
      commit('markNotificationAsRead', projectId)
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },

  async setProjectPinned({ commit }, { is_pinned, projectIds }) {
    try {
      await axios.post('/restify/projects/actions?action=pin-item', {
        repositories: projectIds,
        pinned: is_pinned,
      })
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },

  async setNotificationsPinned({ commit }, { notificationId, is_pinned, projectId }) {
    try {
      commit('setNotificationsPinned', {
        isPinned: is_pinned,
        projectId,
      })

      await axios.post(`/restify/notifications/actions?action=pin-item`, {
        repositories: [notificationId],
        pinned: is_pinned
      })
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },

  async markAllProjectNotificationsAsRead({ dispatch }, projectId) {
    try {
      await axios.post('/restify/notifications/actions?action=mark-as-read-notifications', {
        project_id: projectId,
      }, {
        onerror: (error) => {
          // Do nothing - silent mark as read
          console.error(`Mark notifications read failed for project, ${projectId}}. Message: ${error.response?.data.error}`, error)
        }
      })

      dispatch('accounts/getStats', null, { root: true })

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },

  async markEntityNotificationsRead({ dispatch }, { notifiable_target_type, notifiable_target_id }) {
    try {
      await axios.post(`/restify/notifications/actions?action=mark-as-read-entity`, {
        notifiable_target_type,
        notifiable_target_id
      }, {
        onerror: (error) => {
          // Do nothing - silent mark as read
          console.error(`Mark notifications read failed for {${notifiable_target_type}, ${notifiable_target_id}}. Message: ${error.response?.data.error}`, error)
        }
      })

      dispatch('accounts/getStats', null, { root: true })

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
