<template>
  <div class="flex items-center">
    <div class="text-gray-700 text-sm font-bold mr-2">
      {{ $t('Groups') }}
    </div>
    <GroupChips
      :groups="projectGroups"
      :displayMaxCount="displayMaxCount"
    />
    <div
      v-if="canEditFields && can(actions.ADD_PROJECT_PEOPLE) || isProjectSaving"
      class="shrink-0 text-gray-400 hover:text-gray-500 cursor-pointer text-xs ml-2"
      @click="onTriggerEdit"
    >
      {{ $t('+ Add/Remove Groups') }}
    </div>
    <ProjectGroupsDialog
      v-if="showProjectGroupsDialog"
      v-model="showProjectGroupsDialog"
      :project="project"
      @close="showProjectGroupsDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import GroupChips from "@/components/common/GroupChips.vue";
import ProjectGroupsDialog from "@/modules/projects/components/ProjectGroupsDialog.vue";

// Utils
import { PropType, computed, ref } from 'vue';

import Data = API.Data;
import Project = App.Domains.Projects.Models.Project;
import Group = App.Domains.Groups.Models.Group;

// Composables
import useCan from "@/modules/common/composables/useCan.js";
import useLayout from "@/modules/common/composables/useLayout.js";

const {
  can,
  actions
} = useCan()

const {
  useNewLayout
} = useLayout()

const props = defineProps({
  project: {
    type: Object as PropType<Data<Project>>,
    default: () => ({}),
  },
  canEditFields: {
    type: Boolean,
    default: false,
  },
  isProjectSaving: {
    type: Boolean,
    default: false,
  },
})

const showProjectGroupsDialog = ref(false)

const projectGroups = computed<Data<Group>[]>(() => {
  return props.project?.relationships?.groups || []
})

const displayMaxCount = computed(() => {
  if (useNewLayout.value) {
    return 5
  }

  return 3
})

function onTriggerEdit() {
  showProjectGroupsDialog.value = true
}
</script>
