import { reactive } from 'vue'
import { getImgSize } from "@/modules/common/utils/imageUtils.js";
import { insertHtml } from "@/components/html/extensions/insertHtml";
import { checkFileSizeLimitExceeded } from '@/modules/common/utils/fileUtils';

export const filesToUpload = reactive({})
export const FileUploadStatus = {
  Ready: 'ready',
  Error: 'error',
  Success: 'success',
  Uploading: 'uploading',
}

export async function uploadDroppedFiles({ view, event, editor, fullScale = true }) {
  let originalFiles = event?.dataTransfer?.files || event?.clipboardData?.files || event?.target?.files
  const files = Array.from(originalFiles)
  
  files.forEach(file => {
    if (checkFileSizeLimitExceeded(file, /* displayError */ true)) {
      return
    }

    uploadSingleFile({ view, file, editor, fullScale })
  })
}

async function uploadSingleFile({ view, file, fullScale = true }) {
  file.id = new Date().getTime()
  filesToUpload[file.id] = {
    file,
    status: FileUploadStatus.Ready,
  }
  appendFileProgressToEditor({ view, file, fullScale })
}

function appendFileProgressToEditor({ view, file, fullScale }) {
  insertHtml({
    html: `<uploading-file id="${file.id}" name="${file.name}" fullScale="${fullScale}"></uploading-file>`,
    tr: view.state.tr,
    state: view.state,
    dispatch: view.dispatch,
  })
}

export function appendImageToEditor({ view, url, uuid }) {
  const { schema } = view.state;
  const position = view.state.selection.from;
  getImgSize({ src: url }).then(({ width, height }) => {
    const node = schema.nodes.resizableImage.create({ src: url, uuid, width, height });
    const transaction = view.state.tr.insert(position, node);
    view.dispatch(transaction);
  })
}
