<template>
  <div class="flex items-center truncate">
    <div class="mr-2 my-0.5">
      <BaseLogo
        :entity="event.extendedProps"
        size="sm"
        class="project-logo"
      />
    </div>
    <div class="truncate">
      <div class="truncate flex items-center">
        <span class="truncate">{{ event.title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store/index.js";
import router from "@/router/index.js";
import { i18n } from "@/i18n.js";
import globalPlugins from "@/plugins/globalPlugins.js";
import globalComponents from "@/plugins/globalComponents.js";

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  beforeCreate() {
    /**
     * This is a hack to make sure the resource component uses our plugins.
     * The custom slots from full calendar don't share the app context and therefore hve no store, router etc by default.
     */
    this.$.appContext.app
      .use(store)
      .use(router)
      .use(i18n)
      .use(globalPlugins)
      .use(globalComponents)
  }

}
</script>
<style>
.project-logo {
  min-width: 24px;
  max-height: 24px;
}
</style>
