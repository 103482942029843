<template>
  <div class="flex flex-col h-full">
    <PaymentDialog
      v-show="showCreateDialog && !$route.query.paymentId"
      key="payment-create"
      v-model="showCreateDialog"
      @close="redirectToList"
      @save="onPaymentCreate"
    />
    <PaymentDialog
      v-show="showEditDialog && $route.query.paymentId"
      v-model="showEditDialog"
      :payment="currentPayment"
      key="payment-edit"
      @close="redirectToList"
      @save="onPaymentEdit"
    />
    <TopFilters
      :columns="paymentColumns"
      :available-filters="availableFilters"
      :searchPlaceholder="$t('Search payments...')"
    >
      <template
        v-if="!isCurrentProjectClosed && can($actions.CREATE_PAYMENTS)"
        #action
      >
        <BaseButton
          block
          @click="showCreateDialog = true"
        >
          {{ $t('New Payment') }}
        </BaseButton>
      </template>
    </TopFilters>

    <router-view />
  </div>
</template>
<script>
import TopFilters from "@/modules/filters/components/TopFilters.vue";
import PaymentDialog from "@/modules/payments/components/PaymentDialog.vue";
import WarningAlert from "@/components/common/WarningAlert.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"

export default {
  components: {
    TopFilters,
    WarningAlert,
    PaymentDialog,
    DataSyncingIndicator,
  },
  data() {
    return {
      showCreateDialog: false,
      showEditDialog: false,
    }
  },
  computed: {
    availableFilters() {
      const filters = ['filter','sort','columns','group']
      if (this.$route.path.includes('list')) {
        filters.push(...['chart', 'totalRow'])
      }
      return filters
    },
    paymentColumns() {
      return this.$store.getters['payments/activeColumns'] || []
    },
    currentPayment() {
      return this.$store.state.payments.currentPayment || {}
    },
    addPaymentTrigger() {
      return this.$store.state.payments.addPaymentTrigger
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    onPaymentCreate() {
      this.showCreateDialog = false
      this.getPayments()
    },
    onPaymentEdit() {
      this.redirectToList()
      this.getPayments()
    },
    redirectToList() {
      this.showCreateDialog = false
      this.showEditDialog = false
      let query = {
        ...this.$route.query
      }
      delete query.add
      delete query.paymentId
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
    async getPayments() {
      this.$store.dispatch('payments/getAllPayments')
    }
  },
  watch: {
    '$route.query.paymentId': {
      immediate: true,
      async handler(paymentId) {
        if (!paymentId || !this.can(this.$actions.EDIT_PAYMENTS)) {
          return
        }
        await this.$store.dispatch('payments/getPaymentById', { id: paymentId })
        this.showEditDialog = true
      }
    },
    addPaymentTrigger() {
      this.showCreateDialog = true
    }
  },
  created() {
    this.getPayments()
  }
}
</script>
