<template>
  <div v-if="!dismissed" class="rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="shrink-0">
        <ExclamationIcon class="h-5 w-5 text-red-400" aria-hidden="true"/>
      </div>
      <div class="ml-3">
        <p class="text-sm font-medium text-red-700">
          <slot>
            {{ message }}
          </slot>
        </p>
      </div>
      <div
        v-if="dismissable"
        class="ml-auto pl-3" 
      >
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
            @click="dismissed = true"
          >
            <span class="sr-only">{{ $t('Dismiss') }}</span>
            <XIcon class="h-5 w-5" aria-hidden="true"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon, XIcon } from '@heroicons/vue/solid'

export default {
  components: {
    ExclamationIcon,
    XIcon,
  },
  props: {
    dismissable: {
      type: Boolean,
      default: true 
    }
  },
  data() {
    return {
      dismissed: false
    }
  },
}
</script>
