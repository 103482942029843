<template>
  <DragHandle
    pluginKey="ContentItemMenu"
    :editor="editor"
    :tippyOptions="{
        offset: [0, 6],
        zIndex: 99,
      }"
    @node-change="data.handleNodeChange"
  >
    <div class="flex items-center max-w-[50px]">
      <PopoverRoot v-model.open="menuOpen">
        <PopoverTrigger asChild>
          <ToolbarButton
            :class="{
              'heading-node': isHeadingNode
            }"
            button-size="iconSmall"
            class="drag-button"
          >
            <Icon name="fa-solid fa-grip-dots-vertical"/>
          </ToolbarButton>
        </PopoverTrigger>
        <PopoverContent side="bottom" align="start" :sideOffset="8">
          <Surface class="p-2 flex flex-col min-w-[16rem]">
            <PopoverClose>
              <DropdownButton @click="actions.handleAdd">
                <Icon name="fa-regular fa-plus"/>
                {{ $t('Add item') }}
              </DropdownButton>
            </PopoverClose>
            <PopoverClose>
              <DropdownButton @click="actions.resetTextFormatting">
                <Icon name="fa-regular fa-text-slash"/>
                {{ $t('Clear formatting') }}
              </DropdownButton>
            </PopoverClose>
            <PopoverClose>
              <DropdownButton @click="actions.copyNodeToClipboard">
                <Icon name="fa-regular fa-clipboard"/>
                {{ $t('Copy to clipboard') }}
              </DropdownButton>
            </PopoverClose>
            <PopoverClose>
              <DropdownButton @click="actions.duplicateNode">
                <Icon name="fa-regular fa-copy"/>
                {{ $t('Duplicate') }}
              </DropdownButton>
            </PopoverClose>
            <ToolbarDivider horizontal/>
            <PopoverClose>
              <DropdownButton
                @click="actions.deleteNode"
                class="text-red-500 bg-red-500 dark:text-red-500 hover:bg-red-500 dark:hover:text-red-500 dark:hover:bg-red-500 bg-opacity-10 hover:bg-opacity-20 dark:hover:bg-opacity-20"
              >
                <Icon name="fa-regular fa-trash"/>
                {{ $t('Delete') }}
              </DropdownButton>
            </PopoverClose>
          </Surface>
        </PopoverContent>
      </PopoverRoot>
    </div>
  </DragHandle>
</template>
<script setup lang="ts">
import { useData } from "./composables/useData";
import { Editor } from "@tiptap/vue-3";
import useContentItemActions from "./composables/useContentItemActions";
import { PopoverClose, PopoverContent, PopoverRoot, PopoverTrigger } from "radix-vue";
import { ref, watch, computed } from "vue";
import DragHandle from "./DragHandle.vue";
import DropdownButton from "@/components/html/components/dropdown/DropdownButton.vue";
import Icon from "@/components/html/components/Icon.vue";
import Surface from "@/components/html/components/Surface.vue";
import ToolbarDivider from "@/components/html/components/toolbar/ToolbarDivider.vue";
import ToolbarButton from "@/components/html/components/toolbar/ToolbarButton.vue";

const props = defineProps({
  editor: Editor
})
const data = useData()
const actions = useContentItemActions(props.editor as Editor)

const menuOpen = ref(false)

const isHeadingNode = computed(() => {
  const pos = data.currentNodePos.value
  const nodeName = data.currentNode.value?.type?.name
  return pos === 0 && nodeName === 'heading'
})

watch(() => menuOpen.value, () => {
  if (menuOpen) {
    props.editor?.commands.setMeta('lockDragHandle', true)
  } else {
    props.editor?.commands.setMeta('lockDragHandle', false)
  }
})
</script>
<style lang="scss">
.drag-button.heading-node {
  @apply hidden;
}
</style>
