<template>
  <span
    v-for="filter of visibleFilterChips"
    :key="filter.column"
    class="inline-flex items-center py-1 px-2 pr-1 rounded-md text-xs font-medium border border-primary-500 bg-primary-50 text-primary-500 whitespace-nowrap cursor-pointer ic-filter-chip"
    :class="{
      'ic-filter-chip-completed': isCompletedStatusSelected(filter)
    }"
    @click="onClick(filter)"
  >
    <i class="fas fa-filter text-primary-400 mr-2" />
    <span>{{ filter.name }}:</span>
    <!-- Multiple selection -->
    <div v-if="Array.isArray(filter.displayValue)" class="flex">
      <FilterByChipDisplay
        v-for="(displayValue, i) of filter.displayValue" 
        :key="displayValue.id"
        :displayValue="displayValue"
        :is-last-value="i === filter.displayValue.length - 1"
      />
    </div>
    <!-- Single selection -->
    <FilterByChipDisplay
      v-else
      :displayValue="filter.displayValue"
      class="ml-1"
    />
    <button
      type="button"
      class="shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-primary-400 hover:bg-primary-400 hover:text-white"
      @click.stop="$emit('remove-filter', filter)"
    >
      <span class="sr-only">
        {{ $t('Remove small option') }}
      </span>
      <svg
        class="h-2 w-2"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 8 8"
      >
        <path
          stroke-linecap="round"
          stroke-width="1.5"
          d="M1 1l6 6m0-6L1 7"
        />
      </svg>
    </button>
  </span>
</template>
<script>
import FilterByChipDisplay from "@/modules/filters/components/FilterByChipDisplay.vue"
export default {
  components: {
    FilterByChipDisplay,
  },
  props: {
    filterByFilter: {
      type: Array,
      default: () => []
    }
  },
  emits: ['remove-filter', 'filter-chip-click'],
  computed: {
    visibleFilterChips() {
      return this.filterByFilter.filter(x => !x.hidden)
    }
  },
  methods: {
    onClick(filter) {
      this.$emit('filter-chip-click', filter)
    },
    isCompletedStatusSelected(filter) {
      if (filter.column !== 'status_ids' || !Array.isArray(filter.displayValue)) {
        return false
      }

      return filter.displayValue?.some(v => v.label === 'completed')
    }
  },
}
</script>
