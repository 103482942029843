<template>
  <div class="min-h-screen flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md text-yellow-500 text-7xl text-center">
      <i class="far fa-exclamation-triangle"></i>
    </div>

    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10 text-center text-gray-500 space-y-4">

        <p>{{ $t('The account URL:') }}</p>

        <p class="text-gray-600 font-bold">{{ accountLink }}</p>

        <p>{{ $t(`Doesn't exist. Please click the button below to login and find your account.`) }}</p>

        <BaseButton
          :href="loginLink"
          tag="a"
          block
        >
          {{ $t('Go to Login') }}
        </BaseButton>

      </div>
    </div>

  </div>
</template>
<script>
import config from "@/modules/common/config.js";

export default {
  data() {
    let loginLink = config.isCustomDomain()
      ? `https://${window.location.host}/login`
      : `${config.ROOT_DOMAIN_URL}/login`

    if (import.meta.env.DEV) {
      loginLink = '/login'
    } 

    return {
      loginLink,
      accountLink: config.getTenantHost()
    }
  }
}
</script>
