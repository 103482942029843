<template>
  <div class="result-item">
    <BaseLogo size="sm" :entity="user" logo-key="attributes.avatar"/>
    <span>{{ userName }}</span>
    <i
      v-if="hasNotificationsOff"
      :title="$t('This user has notifications turned off for this project.')"
      class="fa-solid fa-bell-slash text-red-500 text-sm"
    />
  </div>
</template>
<script>
import { hasNotificationsOn } from "@/modules/users/util/userUtils";

export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    userName() {
      const { name, email, first_name, last_name } = this.user?.attributes || {}
      return name || first_name || last_name || email
    },
    hasNotificationsOff() {
      return !hasNotificationsOn(this.user)
    }
  },

}
</script>
