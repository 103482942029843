<template>
  <dl
    v-if="trialFeatures.length > 0"
    class="mt-6 divide-y divide-gray-900/10 border border-gray-200 rounded-lg"
  >
    <ElCollapse
      v-model="activeNames"
      class="divide-y divide-gray-900/10"
    >
      <TrialFeatureItem
        v-for="feature of trialFeatures"
        :key="feature.key"
        :feature="feature"
      />
    </ElCollapse>
  </dl>
  <BaseAlert
    v-else
    variant="primary"
    class="mt-6"
    :dismissable="false"
  >
    {{ $t(`Your account has access to all available features at this time.`) }}
  </BaseAlert>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { ElCollapse } from 'element-plus';
import TrialFeatureItem from '@/modules/accounts/components/trial-manager/TrialFeatureItem.vue';

import {
  useTrialManager,
  TrialFeatureKeys,
} from '@/modules/accounts/composables/useTrialManager';

const {
  trialFeatures
} = useTrialManager()

const activeNames = ref<TrialFeatureKeys[]>([]);
</script>
