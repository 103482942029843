<template>
  <section aria-labelledby="quick-links-title">
    <div class="w-full grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="setting in filteredSettings"
        :key="setting.path"
        :class="{
          'opacity-50': !isSubscriptionValid,
          'shadow': !$useNewLayout
        }"
        class="relative bg-white p-6 border border-gray-200 rounded-lg group"
      >
        <span class="rounded-lg inline-flex sm:m-0 p-0 text-gray-500 ring-4 ring-white">
          <div class="mr-4">
            <i
              v-if="setting.icon"
              :class="setting.icon"
              class="text-3xl"
              aria-hidden="true"
            />

            <img
              v-else-if="setting.image"
              :src="setting.image"
              class="h-10 w-12 rounded-md"
            />
          </div>
          <h3 class="text-xl font-bold text-gray-900 sm:mt-1">
            <component
              :is="isLimitReached(setting) ? 'span' : 'router-link'"
              :to="setting.path"
              class="focus:outline-none cursor-pointer"
              @click="isLimitReached(setting) ? openUpgradeDialog(setting.limitedFeature) : null"
            >
              <!-- Extend touch target to entire panel -->
              <span class="absolute inset-0" aria-hidden="true"></span>
              {{ setting.name }}
              <i
                v-if="isLimitReached(setting)"
                class="fas fa-crown text-yellow-500 ml-2"
              />
            </component>
          </h3>
        </span>
        <div class="border-b border-gray-200 my-4 -mx-6  hidden sm:block" />
        <p class="text-sm text-gray-500">
          {{ setting.description }}
        </p>
        <div class="sm:mt-4">
        </div>
        <span
          class="pointer-events-none absolute top-4 right-5 text-gray-300 group-hover:text-gray-500 origin-center rotate-45"
          aria-hidden="true"
        >
          <span class="group-hover:opacity-0 absolute">
            <i class="fas fa-chevron-up" />
          </span>
          <span class="opacity-0 group-hover:opacity-100">
            <i class="fas fa-chevron-up" />
          </span>
        </span>
      </div>
    </div>
  </section>
</template>
<script>
import { useAccountLimits } from '@/modules/auth/composables/useAccountLimits'
import { AccountPermissions } from '@/modules/common/composables/useCan';
import { isProduction } from '@/modules/common/config'

export default {
  setup() {
    const {
      hasReachedLimit,
      openUpgradeDialog
    } = useAccountLimits()

    return {
      hasReachedLimit,
      openUpgradeDialog
    }
  },
  computed: {
    settings() {
      return [
        {
          path: '/integrations/zapier',
          name: this.$t('Zapier'),
          description: this.$t('Connect Project.co with 5,000+ other apps.'),
          image: '/img/zapier-logo.jpg',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/integrations/stripe-setup',
          name: this.$t('Stripe Setup'),
          description: this.$t('Connect your Stripe account so you can accept payments.'),
          icon: 'fab fa-cc-stripe',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/integrations/paypal',
          name: this.$t('Paypal'),
          description: this.$t('Connect your Paypal account so you can accept payments.'),
          icon: 'fab fa-cc-paypal',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/integrations/webhooks',
          name: this.$t('Webhooks'),
          description: this.$t('Create custom webhooks to integrate with your own systems.'),
          icon: 'fa-light fa-globe',
          enabled: () => {
            return this.can(this.$actions.CREATE_WEBHOOKS)
          },
          limitedFeature: AccountPermissions.Webhooks
        },
        {
          path: '/integrations/public-api',
          name: this.$t('Public API'),
          description: this.$t('Manage your public API keys to integrate with your own systems.'),
          icon: 'fa-light fa-webhook',
          enabled: () => {
            return this.can(this.$actions.CREATE_API_KEYS) && !isProduction()
          },
          limitedFeature: AccountPermissions.PublicApi
        },
      ]
    },
    filteredSettings() {
      return this.settings.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    isLimitReached(setting) {
      if (!setting.limitedFeature) {
        return false
      }

      return this.hasReachedLimit(setting.limitedFeature)
    }
  }
}
</script>
