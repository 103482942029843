<template>
  <div>
    <ButtonGroups
      v-if="authorizedToCreateTimer"
      :active-option="currentCreationMethod"
      :options="TimeCreationOptions"
      class="mt-6"
      @change="method => currentCreationMethod = method"
    />

    <UpgradePlanWarning
      v-if="!can(actions.TASK_TIMERS) && currentCreationMethod === TimeCreationMethods.Timer"
      :message="$t(' to use the timer functionality.')"
      class="max-w-md m-auto mt-4"
    />

    <VeeForm
      #default="{ handleSubmit, errors }"
      ref="formWrapper"
    >

      <div class="md:grid md:grid-cols-8 md:gap-4 p-6">
        <BaseInput
          :modelValue="model.group_id"
          :name="$t('Group')"
          :label="$t('Group')"
          id="person"
          class="mt-1 sm:mt-0 md:col-span-2"
        >
          <div class="sm:mt-0 sm:col-span-4">
            <GroupSelect
              v-model="model.group_id"
              :triggerSync="true"
              :urlParams="{
                  hasUsersWithRoleGreaterThan: $roleRanks.COLLABORATOR_PLUS,
               }"
              :placeholder="$t('Choose group...')"
              @change="onGroupChanged"
            />
          </div>
        </BaseInput>

        <BaseInput
          :modelValue="model.user_id"
          :name="$t('Person')"
          :label="$t('Person')"
          rules="required"
          id="person"
          class="mt-1 sm:mt-0 md:col-span-2"
        >
          <div class="sm:mt-0 sm:col-span-4">
            <UserSelect
              v-model="model.user_id"
              :placeholder="$t('Choose person...')"
              :triggerSync="true"
              :urlParams="{
                group_id: model.group_id,
                roleGreaterThan: $roleRanks.COLLABORATOR_PLUS,
              }"
            />
          </div>
        </BaseInput>

        <BaseInput
          :modelValue="model.date"
          :name="$t('Date')"
          :label="$t('Date')"
          rules="required"
          class="sm:mt-0 md:col-span-2"
        >
          <BaseDatePicker
            v-model="model.date"
            class="relative sm:mt-0 sm:col-span-4"
          />
        </BaseInput>

        <BaseInput
          v-if="currentCreationMethod === TimeCreationMethods.Manual"
          :modelValue="model.worked_minutes"
          :name="$t('Hours')"
          :label="$t('Hours')"
          :placeholder="$t('Enter hours...')"
          rules="required|min_minutes:1|max_minutes:1440"
          layout="vertical"
          id="hours"
          class="mt-1 sm:mt-0 md:col-span-2"
        >
          <TimeInput
            v-model="model.worked_minutes"
            :placeholder="$t('Choose how much time to allocate...')"
          />
        </BaseInput>

        <div class="col-span-8 flex justify-end">
          <BaseButton
            :disabled="submitDisabled"
            :loading="loading"
            type="button"
            variant="primary"
            @click="handleSubmit(addNewEntry)"
          >
            {{ submitText }}
          </BaseButton>
        </div>

      </div>

      <div
        v-if="tableData.length"
        class="mx-6"
      >
        <TimeEntriesTable
          :data="tableData"
          :columns="filteredTableColumns"
          :limitedCount="true"
          domLayout="autoHeight"
        />
      </div>

      <div class="px-6 py-4 mt-6 border-t border-gray-200 sticky w-full flex justify-between bottom-0 bg-white z-10">
        <div>
          <router-link
            to="/time/actual"
          >
            <base-button
              :tabindex="-1"
              variant="white"
            >
              {{ $t('View all time entries') }}
            </base-button>
          </router-link>
        </div>
        <div class="flex space-x-2">
          <BaseButton variant="white" @click="$emit('close')">
            {{ $t('Cancel') }}
          </BaseButton>
          <BaseButton variant="primary" @click="$emit('close')">
            {{ $t('Close') }}
          </BaseButton>
        </div>
      </div>
    </VeeForm>

  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import { watch, ref, computed } from 'vue'
import UserSelect from '@/components/selects/UserSelect.vue'
import GroupSelect from '@/components/selects/GroupSelect.vue'
import TimeInput from '@/components/form/TimeInput.vue'
import { TimeEntry } from '@/modules/time/timeEntryTypes'
import {
  TimeCreationMethods,
  TimeCreationOptions,
  TimerStatusTypes,
} from '@/modules/time/utils/timeEntryUtils.js'
import {
  CREATE_TIME_ENTRIES_TIMER,
} from '@/modules/common/enum/actionsEnum'
import TimeEntriesTable from '@/modules/time/components/TimeEntriesTable.vue'
import useCan from "@/modules/common/composables/useCan";

const { can, actions } = useCan()

import { useStore } from 'vuex'
import { formatISO } from 'date-fns'
import axios from 'axios'
import UpgradePlanWarning from '@/modules/accounts/components/UpgradePlanWarning.vue'

const store = useStore()

const props = defineProps({
  task: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['save', 'cancel', 'close'])

const emptyModel: TimeEntry = {
  group_id: null,
  user_id: store.state?.auth?.user?.id,
  worked_minutes: 0,
  date: new Date(),
  type: TimeCreationMethods.Manual,
}

let currentCreationMethod = ref<string>(TimeCreationMethods.Manual)
let model = ref<TimeEntry>({...emptyModel})
let loading = ref<boolean>(false)
const formWrapper = ref(null)

const submitDisabled = computed(() => {
  if (currentCreationMethod.value === TimeCreationMethods.Timer) {
    return !model.value.user_id || !can(actions.TASK_TIMERS)
  }
  return !model.value.user_id || !model.value.worked_minutes
})

const submitText = computed(() => {
  return currentCreationMethod.value === TimeCreationMethods.Timer
    ? i18n.t('Start timer')
    : i18n.t('Add time entry')
})

const authorizedToCreateTimer = computed(() => {
  return store.getters['users/can'](CREATE_TIME_ENTRIES_TIMER)
})

const allTableColumns = computed(() => {
  return store.getters['time/timeEntryTableColumns'] || []
})

const filteredTableColumns = computed(() => {
  let excludedColumns = ['relationships.project.attributes.name', 'relationships.task.attributes.name']

  if (currentCreationMethod.value === TimeCreationMethods.Manual) {
    excludedColumns.push('timer-actions')
  }

  return allTableColumns.value.filter((column: Record<string, any>) => {
    return !excludedColumns.includes(column['prop'])
  })
})

const timeEntries = computed(() => {
  return store.state.tasks.taskTimeEntries.data;
})

const tableData = computed(() => {
  if (currentCreationMethod.value === TimeCreationMethods.Timer) {
    // @ts-ignore
    return timeEntries.value.filter(entry => entry.attributes?.timer_started_at)
  }
  // @ts-ignore
  return timeEntries.value.filter(entry => !entry.attributes?.timer_started_at)
})

const timeEntryRecordedCount = computed(() => {
  return store.state.time.stats.time_entry_recorded_count || 0
})

const deletedTimeEntryCount = computed(() => {
  return store.state.time.stats.deleted_time_entry_count || 0
})

watch(() => [timeEntryRecordedCount.value, deletedTimeEntryCount.value], () => {
  store.dispatch('tasks/getTaskTimeEntries', props.task?.id)
})

async function addNewEntry() {
  try {
    loading.value = true

    let newEntry: TimeEntry = {
      ...model.value,
      task_id: props.task.id,
      project_id: props.task.attributes?.project_id,
    }

    if (currentCreationMethod.value === TimeCreationMethods.Timer) {
      newEntry.timer_started_at = formatISO(new Date())
      newEntry.status = TimerStatusTypes.Recording
      newEntry.worked_minutes = null

      await store.dispatch('time/addTimeEntryTimer', {
        model: newEntry,
      })

      store.dispatch('time/updateTimeEntryRecordedCount', 1)
    } else {
      await store.dispatch('time/createTimeEntry', {
        data: [newEntry],
        isBulkModel: true,
      })
    }

    await store.dispatch('tasks/getTaskTimeEntries', props.task?.id)
    model.value = {...emptyModel}
    // @ts-ignore
    formWrapper.value?.resetForm()
  } catch (err) {
    console.warn(err)
  } finally {
    loading.value = false
  }
}

function onGroupChanged() {
  model.value.user_id = null
}
</script>
