import { StepperStep } from "@/modules/common/commonTypes";

export enum AccountSetupKeys {
  LocationSettings = "location-settings",
  ProjectStatuses = "project-statuses",
  TaskStatuses = "task-statuses",
  AccountDefaults = "account-defaults",
  ViewTypes = "view-types",
  TrialCustomBranding = "trial-custom-branding",
  SetupCompleted = "setup-completed",
}

export interface AccountSetupStep extends StepperStep {
  // component: string;
}

export const accountSetupSteps = [
  {
    key: AccountSetupKeys.LocationSettings,
  },
  {
    key: AccountSetupKeys.ProjectStatuses,
  },
  {
    key: AccountSetupKeys.TaskStatuses,
  },
  {
    key: AccountSetupKeys.AccountDefaults,
  },
  {
    key: AccountSetupKeys.ViewTypes,
  },
  {
    key: AccountSetupKeys.TrialCustomBranding,
  },
  {
    key: AccountSetupKeys.SetupCompleted,
    hidden: true,
  },
]
