<template>
  <BaseEntityForm
    :title="$t('Invite New People')"
    :subtitle="$t('Enter the email addresses of the people you would like to invite and they will be sent an invite to this account.')"
    width-class="max-w-3xl"
    :isDialogForm="$attrs.isDialogForm"
    @submit="onSubmitForm"
  >
    <template #default="{ errors, meta }">
      <BaseInput
        v-focus
        :modelValue="model.emails"
        :label="$t('Details')"
        :placeholder="$t('Enter 1 email address per line...')"
        :name="$t('Details')"
        layout="horizontal"
        id="details"
        :rules="`max_rows:${maxUsersInvite},${$tc('invite users limit', { count: maxUsersInvite })}`"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <textarea
            v-model="model.emails"
            id="emails"
            name="emails"
            rows="6"
            class="block w-full form-input"
            :placeholder="$t('Enter 1 email address per line...')"
          >
          </textarea>
        </div>
      </BaseInput>


      <RequiresPermissionTo :action="$actions.ASSIGN_ROLES_TO_PEOPLE">
        <BaseInput
          :label="$t('Role')"
          :name="$t('Role')"
          layout="horizontal"
        >
          <div class="mt-1 sm:mt-0">
            <UserRoleSelect
              v-model="model.role_id"
              class="w-full"
            />
          </div>

          <ErrorAlert
            v-if="displayLimitReachedAlert"
            class="mt-2"
            :dismissable="false"
          >
            <p>
              {{
                $t("You have reached the maximum number of seats for this account.")
              }}
            </p>
            <p v-if="isTenantOwner">
              {{ $t("Update your ") }}
              <span
                class="underline cursor-pointer"
                @click="openUpgradeDialog(isCreatorRoleSelected ? AccountPermissions.CreatorSeats : AccountPermissions.CollaboratorsSeats)"
              >
              {{ $t("billing plan") }}
              </span>

              {{
                isCreatorRoleSelected
                  ? $t(" to allow inviting more creators. ")
                  : $t(" to allow inviting more collaborators. ")
              }}
            </p>
            <p v-else>
              {{ $t("Contact the organization owner to add more seats.") }}
            </p>
          </ErrorAlert>
        </BaseInput>

      </RequiresPermissionTo>

      <RequiresPermissionTo :action="$actions.ASSIGN_PEOPLE_TO_ANY_GROUP">
        <BaseInput
          :label="$t('Group')"
          :name="$t('Group')"
          layout="horizontal"
          id="group"
        >
          <div class="mt-1 sm:mt-0 sm:col-span-3">
            <GroupSelect
              v-model="model.group_id"
              :initial-value="model.group"
              :placeholder="$t('Choose a group for these people')"
              :allow-entity-create="can($actions.CREATE_GROUPS)"
            />
          </div>
        </BaseInput>
      </RequiresPermissionTo>

      <BaseInput
        :label="$t('Projects')"
        :name="$t('Projects')"
        layout="horizontal"
        id="projects"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <ProjectSelect
            v-model="model.project_ids"
            :placeholder="$t('Choose projects for these people')"
            multiple
            :allow-entity-create="can($actions.CREATE_PROJECTS)"
            :add-entity-label="$t('Quick Create Project')"
          />
        </div>
      </BaseInput>

      <CustomFieldsEntityForm
        v-model="model.custom_fields"
        :col-span="3"
        :column-count="4"
        entity-type="user"
      />

      <VueRecaptcha
        v-if="isFreeForeverSubscription"
        ref="vueRecaptcha"
        @verify="recaptchaVerified"
      />
      
    </template>
    <template #actions="{ meta, errors}">
      <BaseButton
        variant="white"
        @click="$emit('cancel')"
      >
        {{ $t("Cancel") }}
      </BaseButton>
      <BaseButton
        :loading="loading"
        :disabled="
          !meta.valid || !hasOneValidEmail || displayLimitReachedAlert
        "
        type="submit"
        class="ml-2"
      >
        {{ $t("Save") }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
// Components
import ProjectSelect from "@/components/selects/ProjectSelect.vue";
import GroupSelect from "@/components/selects/GroupSelect.vue";
import CustomFieldsEntityForm from "@/modules/common/components/CustomFieldsEntityForm.vue"
import ErrorAlert from "@/components/common/ErrorAlert.vue";
import UserRoleSelect from "@/components/selects/UserRoleSelect.vue";
import VueRecaptcha from '@/modules/dashboard/components/VueRecaptcha.vue';

// Helpers
import { email } from '@vee-validate/rules';
import apiCache from '@/modules/common/utils/apiCache';
import {
  getCustomFieldValuesStringified
} from "@/modules/accounts/utils/modelUtils"
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from "@/modules/common/composables/useCan";

export default {
  components: {
    GroupSelect,
    ProjectSelect,
    ErrorAlert,
    CustomFieldsEntityForm,
    UserRoleSelect,
    VueRecaptcha
  },
  props: {
    defaultValues: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const {
      accountLimits,
      openUpgradeDialog,
      isFreePlan,
      isFreeForeverSubscription,
    } = useAccountLimits()
    return {
      isFreePlan,
      isFreeForeverSubscription,
      accountLimits,
      openUpgradeDialog,
      AccountPermissions
    }
  },
  data() {
    const { group_name, group_id } = this.$route.query
    const defaultGroup = {
      id: group_id,
      attributes: {
        name: group_name
      },
    }
    return {
      maxUsersInvite: 20,
      recaptcha: null,
      model: {
        emails: '',
        role_id: null,
        group_id: group_id || null,
        project_ids: [],
        group: group_name ? defaultGroup : null,
        custom_fields: {},
        ...this.defaultValues
      },
      loading: false
    }
  },
  computed: {
    roles() {
      return this.$store.getters['users/editableRoles']
    },
    defaultRole() {
      return this.tryGetRoleId('Collaborator')
    },
    selectedRole() {
      return this.roles.find(r => +r.id === +this.model.role_id)
    },
    isTrialPeriod() {
      return this.$store.getters['accounts/isTrialPeriod']
    },
    groups() {
      return this.$store.state.users.groups.data || []
    },
    groupsLoading() {
      return this.$store.state.users.groupsLoading || false
    },
    emailsArray() {
      const lines = this.model.emails.split('\n')
      return lines.map(line => line.trim()).filter(line => email(line))
    },
    hasOneValidEmail() {
      return this.emailsArray.length > 0
    },
    subscription() {
      return this.$store.state.accounts.subscription || {}
    },
    currentCreatorsCount() {
      return this.accountLimits.seats.used
    },
    maxSeatCount() {
      return this.accountLimits.seats.available
    },
    currentCollaboratorsCount() {
      return this.accountLimits.collaboratorsSeats.used
    },
    maxCollaboratorSeatCount() {
      return this.accountLimits.collaboratorsSeats.available
    },
    newCreatorsCount() {
      if (this.isCreatorRoleSelected) {
        const exceptionEmails = ['support@project.co']
        const nonExceptionEmails = this.emailsArray.filter(email => !exceptionEmails.includes(email))

        return nonExceptionEmails.length + this.currentCreatorsCount
      }
      return this.currentCreatorsCount
    },
    newCollaboratorsCount() {
      if (this.isCollaboratorRoleSelected) {
        const exceptionEmails = ['support@project.co']
        const nonExceptionEmails = this.emailsArray.filter(email => !exceptionEmails.includes(email))

        return nonExceptionEmails.length + this.currentCollaboratorsCount
      }

      return this.currentCollaboratorsCount
    },
    isTenantOwner() {
      return this.$store.getters['auth/isTenantOwner']
    },
    isCollaboratorRoleSelected() {
      return [
        this.$roles.COLLABORATOR,
        this.$roles.COLLABORATOR_PLUS
      ].includes(this.selectedRole?.attributes?.name)
    },
    isCreatorRoleSelected() {
      return [
        this.$roles.CREATOR,
        this.$roles.CREATOR_PLUS,
        this.$roles.CREATOR_ADMIN
      ].includes(this.selectedRole?.attributes?.name)
    },
    displayLimitReachedAlert() {
      if (this.isCreatorRoleSelected) {
        return this.maxSeatCount && (this.newCreatorsCount > this.maxSeatCount) && !this.isTrialPeriod
      }

      return this.maxCollaboratorSeatCount && this.newCollaboratorsCount > this.maxCollaboratorSeatCount
    }
  },
  methods: {
    recaptchaVerified(response) {
      this.recaptcha = response;
      this.onSubmit();
    },
    async onSubmitForm() {
      if (this.isFreeForeverSubscription) {
        this.loading = true
        await this.$refs.vueRecaptcha.execute()
      }
      else {
        this.onSubmit()
      }
    },
    async onSubmit() {
      try {
        this.loading = true
        const data = {
          ...this.model,
        }

        if (this.isFreeForeverSubscription) {
          data['g-recaptcha-response'] = this.recaptcha
        }

        data.emails = this.emailsArray
        data.custom_fields = getCustomFieldValuesStringified(data.custom_fields)

        if (data.emails.some(e => e.endsWith('.ru'))) {
          return
        }

        const createdUsers = await this.$store.dispatch('users/createUser', data)
        this.$success('User(s) invited successfully!')

        apiCache.removeForEntity('users')
        this.$emit('save', createdUsers)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not invite the users'))
      } finally {
        this.loading = false

        if (this.isFreeForeverSubscription) {
          this.$refs.vueRecaptcha.reset()
        }
      }
    },
    tryGetRoleId(name) {
      const role = this.roles.find(s => s?.attributes?.name === name)
      return role?.id
    },
    async closePoppers() {
      await this.$nextTick()

      document.body.querySelectorAll('.el-popper').forEach(popper => {
        popper.style.display = 'none'
      })
    }
  },
  watch: {
    defaultRole: {
      immediate: true,
      handler(value) {
        if (value && value.id) {
          this.model.role_id = value.id
        }
      }
    },
    roles: {
      immediate: true,
      handler() {
        this.model.role_id = this.tryGetRoleId('Collaborator')
      }
    },
  },
  created() {
    this.$store.dispatch('users/getRoles')
    this.$store.dispatch('users/getGroups')
  },
  mounted() {
    this.closePoppers()
  }
};
</script>
