<template>
  <BaseDropdown
    v-bind="$attrs"
    :options="availableMenuItems"
    divide-items
  >
    <slot name="activator">
      <button
        type="button"
        class="w-10 h-10 inline-flex items-center p-3 border border-transparent rounded-lg shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
      >
        <i class="fas fa-plus"></i>
      </button>
    </slot>
  </BaseDropdown>
  <StarredPageSetTitleDialog
    v-model="showStarredPageSetTitleDialog"
    :init-title="newStarredPageTitle"
    @ok="starPage($event)"
    append-to-body
  />
</template>
<script>
import { ElDropdownItem } from "element-plus"
import StarredPageSetTitleDialog from "@/modules/accounts/components/StarredPageSetTitleDialog.vue"
import { isCurrentPageStarred, getRouteName } from "@/modules/accounts/utils/pageUtils"
import { AccountPermissions } from '@/modules/common/composables/useCan';

export default {
  components: {
    StarredPageSetTitleDialog,
    ElDropdownItem
  },
  props: {
    allowStar: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showStarredPageSetTitleDialog: false,
      newStarredPageTitle: ''
    }
  },
  computed: {
    isPageStarred() {
      return isCurrentPageStarred()
    },
    menuItems() {
       return [
        {
          label: this.$t('Project'),
          icon: 'fas fa-inbox text-gray-400 group-hover:text-gray-500 text-base w-5 h-5',
          path: '/projects/add',
          requiresPermissionTo: this.$actions.CREATE_PROJECTS,
          limitedFeature: AccountPermissions.ActiveProjects
        },
        {
          label: this.$t('Template'),
          icon: 'fas fa-window-alt text-gray-400 group-hover:text-gray-500 text-base w-5 h-5',
          path: '/templates/add',
          requiresPermissionTo: this.$actions.CREATE_PROJECT_TEMPLATES
        },
        
        {
          label: this.$t('Task'),
          icon: 'fas fa-badge-check text-gray-400 group-hover:text-gray-500 text-base w-5 h-5',
          path: '/tasks/add',
          requiresPermissionTo: this.$actions.CREATE_TASKS
        },
        {
          label: this.$t('Person'),
          icon: 'fas fa-user text-gray-400 group-hover:text-gray-500 text-base w-5 h-5',
          path: '/users/add',
          requiresPermissionTo: this.$actions.INVITE_NEW_PEOPLE
        },
        {
          label: this.$t('Group'),
          icon: 'fas fa-ball-pile text-gray-400 group-hover:text-gray-500 text-base w-5 h-5',
          path: '/groups/add',
          requiresPermissionTo: this.$actions.CREATE_GROUPS
        },
        {
          label: this.$t('File Upload'),
          icon: 'fas fa-file text-gray-400 group-hover:text-gray-500 text-base w-5 h-5',
          path: '/files/add',
          requiresPermissionTo: this.$actions.CREATE_PROJECT_FILES
        },
        {
          label: this.$t('Payment'),
          icon: 'fas fa-money-bill text-gray-400 group-hover:text-gray-500 text-base w-5 h-5',
          path: '/payments/add',
          requiresPermissionTo: this.$actions.CREATE_PAYMENTS
        },
        {
          label: this.$t('Time Entry'),
          icon: 'fas fa-stopwatch text-gray-400 group-hover:text-gray-500 text-base w-5 h-5',
          path: '/time/add',
          requiresPermissionTo: this.$actions.CREATE_TIME_ENTRIES
        },
        {
          label: this.isPageStarred
            ? this.$t('Unstar page')
            : this.$t('Star page'),
          icon: 'fas fa-star',
          iconClass: this.isPageStarred
            ? 'text-yellow-500'
            : 'text-gray-500',
          enabled: () => {
            return this.allowStar
          },
          action: this.toggleStarredPage,
        },
      ]
    },
    availableMenuItems() {
      return this.menuItems.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    async toggleStarredPage() {
      const { fullPath } = this.$route
      const route = this.$route
      if (!this.isPageStarred) {
        this.newStarredPageTitle = getRouteName(route)
        this.showStarredPageSetTitleDialog = true
      } else {
        await this.$store.dispatch('accounts/deleteStarredPageByLink', fullPath)
        this.$success(this.$t('Starred page removed'))
      }
    },
    async starPage(pageTitle) {
      await this.$store.dispatch('accounts/createStarredPage', {
        title: pageTitle,
        link: this.$route.fullPath,
      })
      this.$success(this.$t('Page starred'))
    },
  }
}
</script>
