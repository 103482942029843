<template>
  <div class="space-y-5 pb-6">
    <div class="flex">
      <div class="grow text-gray-900 text-4xl font-extrabold">
        {{ $t('Edit dashboard') }}
      </div>
    </div>
    <div class="flex flex-wrap gap-4">
      <DashboardScopeSelect
        v-if="can(actions.EDIT_ALL_DASHBOARDS)"
        v-model="model.scope"
        class="w-80"
      />

      <WidgetTypeSelect
        v-model="addWidgetType"
        @update:modelValue="onWidgetTypeSelect"
        :excludedOptions="model.widgets.map(w => w.component)"
        :scope="model.scope"
        class="w-48"
      />

      <BaseButton
        variant="white"
        @click="onResetToDefault"
      >
        {{ resetToDefaultLabel  }}
      </BaseButton>
      <BaseButton
        :loading="saveAndContinueLoading"
        @click="saveAndContinue"
      >
        {{ $t(`Save & continue`)  }}
      </BaseButton>
      <BaseButton
        :loading="saveAndExitLoading"
        @click="saveAndExit"
      >
        {{ $t(`Save & exit`)  }}
      </BaseButton>
      
      <div
        v-if="infoText"
        class="text-gray-400 text-sm w-full -mt-2"
      >
        {{ infoText }}
      </div>
    </div>
    <draggable
      :modelValue="model.widgets"
      :animation="200"
      tag="div"
      class="grid md:grid-cols-2 gap-6"
      ghost-class="ghost-card"
      handle=".fa-up-down-left-right"
      item-key="component"
      @update:modelValue="onReorder"
    >
      <template #item="{ element: widget }">
        <EditableWidget
          :key="`${widget.component}_${model.id}`"
          :widget="widget"
          :scope="model.scope"
          @filters-changed="onWidgetFilterChanged(widget, $event)"
          @remove="onRemoveWidget(widget)"
          @update:displayMaxCount="widget.displayMaxCount = $event"
        />
      </template>
    </draggable>
  </div>
</template>
<script lang="ts" setup>
// Components
import DashboardScopeSelect from "@/modules/dashboard/components/editable/DashboardScopeSelect.vue"
import WidgetTypeSelect from "@/modules/dashboard/components/editable/WidgetTypeSelect.vue"
import EditableWidget from "@/modules/dashboard/components/editable/EditableWidget.vue"
import draggable from "vuedraggable/src/vuedraggable"

// Utils
import i18n from "@/i18n"
import { computed, onBeforeMount, ref, watch } from "vue"
import { error, success } from "@/components/common/NotificationPlugin"
import {
  Widgets,
  DashboardWidgetType,
  DashboardModel,
  systemDefaultDashboard,
  applyWidgetPermissionsCheck,
  applyScopedSystemDefaultWidgetFilters
} from "@/modules/dashboard/utils/widgetUtils"
import { UserTypes, getCreatorRoleIds, getCollaboratorRoleIds } from "@/modules/users/util/userUtils"
import Data = API.Data
import { $deleteConfirm } from '@/components/common/dialog/modalPlugin'

// Composables
import { useStore } from "vuex"
import useCan from "@/modules/common/composables/useCan"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useRouter } from "vue-router"

const store = useStore()
const router = useRouter()

const {
  can,
  actions
} = useCan()

const {
  currentUser
} = useAuth()

const allAvailableWidgets = computed<DashboardWidgetType[]>(() => {
  return applyWidgetPermissionsCheck(systemDefaultDashboard.attributes.widgets || [])
})

const activeDashboard = computed<Data<DashboardModel>>(() => {
  return store.getters['dashboard/activeDashboard']
})

const activeDashboardWidgets = computed(() => {
  return applyWidgetPermissionsCheck(activeDashboard.value?.attributes?.widgets || [])
})

const model = ref<DashboardModel>({
  id: null,
  scope: UserTypes.Self,
  user_id: +currentUser.value?.id,
  role_ids: null,
  widgets: [],
})

function initDefaultWidgets() {
  let widgets: DashboardWidgetType[] = []
  
  const systemWidgetsFiltered = applyWidgetPermissionsCheck(systemDefaultDashboard.attributes.widgets || [], model.value.scope)
  if (model.value.scope === UserTypes.Collaborators && !model.value.id) {
    widgets = systemWidgetsFiltered
    widgets = applyScopedSystemDefaultWidgetFilters(widgets, UserTypes.Collaborators)
  }
  else if (model.value.scope === UserTypes.Creators && !model.value.id) {
    widgets = systemWidgetsFiltered.filter(w => w.component !== Widgets.Payments)
    widgets = applyScopedSystemDefaultWidgetFilters(widgets, UserTypes.Creators)
  }
  else {
    widgets = activeDashboardWidgets.value
  }

  model.value.widgets = JSON.parse(JSON.stringify(widgets))
}

async function onSave() {
  try {
    await store.dispatch('dashboard/updateDashboard', model.value)
    success(i18n.t('Your new configuration has been saved successfully.'))
  } catch (e: any) {
    if (e.handled) {
      return
    }

    error(i18n.t('Could not save dashboard'))
    throw e
  }
}

const saveAndContinueLoading = ref(false)
const saveAndExitLoading = ref(false)

async function onResetToDefault() {
  const confirmed = await $deleteConfirm({
    title: resetToDefaultLabel.value,
    description: i18n.t('Are you sure? This will return this dashboard to the default settings.'),
    buttonText: i18n.t('Reset'),
  })

  if (!confirmed) {
    return
  }

  if (model.value.id) {
    await onDeleteDashboard()
  }
  else {
    initDefaultWidgets()
  }

  success(i18n.t('Your new configuration has been saved successfully.'))
}

const isCreatorUser = computed(() => {
  return store.getters['users/isCreatorUser']
})

const isCollaboratorUser = computed(() => {
  return store.getters['users/isCollaboratorUser']
})

const resetToDefaultLabel = computed(() => {
  const isUsersScope = model.value.scope === UserTypes.Collaborators || model.value.scope === UserTypes.Creators

  if (can(actions.EDIT_ALL_DASHBOARDS) && isUsersScope) {
    return i18n.t('Reset to system default dashboard')
  }

  if (isCollaboratorUser.value) {
    return i18n.t('Reset to default collaborators dashboard')
  }

  if (isCreatorUser.value) {
    return i18n.t('Reset to default creators dashboard')
  }

  return i18n.t('Reset to system default dashboard')
})

async function saveAndContinue() {
  try {
    saveAndContinueLoading.value = true
    await onSave()
  }
  finally {
    saveAndContinueLoading.value = false
  }
}

async function saveAndExit() {
  try {
    saveAndExitLoading.value = true
    await onSave()
    router.push('/welcome')
  }
  finally {
    saveAndExitLoading.value = false
  }
}

const addWidgetType = ref<Widgets | null>(null)

const dashboards = computed(() => {
  return store.state.dashboard.dashboards || []
})

const matchingDashboard = computed<Data<DashboardModel>>(() => {
  return dashboards.value.find((d: Data<DashboardModel>) => d.attributes.scope === model.value.scope)
})

const infoText = computed(() => {
  if (model.value.scope === UserTypes.Collaborators) {
    return i18n.t(`Changes you make to the 'All Collaborators' dashboard will show for all people who have a Collaborator or Collaborator Plus role.`)
  }

  if (model.value.scope === UserTypes.Creators) {
    return i18n.t(`Changes you make to the 'All Creators' dashboard will show for all people who have a Creator, Creator Plus or Creator Admin role.`)
  }
  
  if (model.value.scope === UserTypes.Self) {
    return i18n.t(`Changes you make to this dashboard will only apply for you, not other people.`)
  }

  return ''
})

watch(() => model.value.scope, onScopeChanged, {
  immediate: true,
})

watch(() => matchingDashboard.value, checkMatchingDashboard)

function checkMatchingDashboard() {
  if (!matchingDashboard.value?.id) {
    model.value.id = null
    initDefaultWidgets()
    return
  }

  model.value = {
    id: matchingDashboard.value.id,
    ...matchingDashboard.value.attributes,
    widgets: applyWidgetPermissionsCheck(matchingDashboard.value.attributes.widgets || []),
  }
}

async function onScopeChanged(scope: UserTypes) {
  if (matchingDashboard.value?.id) {
    return
  }
  
  initDefaultWidgets()

  if (scope === UserTypes.Self) {
    model.value.user_id = +currentUser.value?.id
    model.value.role_ids = null
    return
  }

  model.value.user_id = null

  if (scope === UserTypes.Collaborators) {
    model.value.role_ids = getCollaboratorRoleIds()
  }
  else {
    model.value.role_ids = getCreatorRoleIds()
  }
}

function onWidgetTypeSelect(widgetType: Widgets) {
  const widgetDefinition = allAvailableWidgets.value.find(w => w.component === widgetType)
  if (!widgetDefinition) {
    error(`Widget type ${widgetType} not found`)
    return
  }

  const widget: DashboardWidgetType = {
    ...widgetDefinition,
    icon: '', // TODO: init as null if possible
    order: model.value.widgets.length + 1,
  }

  model.value.widgets.push(widget)

  addWidgetType.value = null
}

function onRemoveWidget(widget: DashboardWidgetType) {
  const index = model.value.widgets.findIndex(w => w.component === widget.component)
  if (index === -1) {
    error(`Widget type ${widget.component} not found`)
    return
  }

  model.value.widgets.splice(index, 1)
}

function onWidgetFilterChanged(widget: DashboardWidgetType, filters: string) {
  widget.filters = filters
}

function onReorder(widgets: DashboardWidgetType[]) {
  model.value.widgets = widgets.map((w, i) => ({
    ...w,
    order: i + 1,
  }))
}

async function onDeleteDashboard() {
  if (!model.value.id) {
    return
  }

  await store.dispatch('dashboard/deleteDashboard', model.value.id)
}

onBeforeMount(async () => {
  await store.dispatch('users/getRoles')
  checkMatchingDashboard()
})
</script>
