<template>
  <div
    v-loading="statsLoading"
    :class="{ 'opacity-50': !isSubscriptionValid }"
    class="border-t rounded-br-lg rounded-bl-lg border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-4 sm:divide-y-0 sm:divide-x"
  >
    <div
      class="px-6 py-5 text-sm font-medium text-center text-gray-500 group hover:text-gray-700 cursor-pointer"
      @click="openStarredPages"
    >
      <i class="fas fa-star opacity-70 mr-1 group-hover:opacity-100 group-hover:text-yellow-500" aria-hidden="true" />
      <span class="mr-1">{{ starred_pages_count }}</span>
      <span>{{  $t('Starred pages')  }}</span>
    </div>

    <router-link
      :to="myActiveProjectsPath"
      class="px-6 py-5 text-sm font-medium text-center text-gray-500 group hover:text-gray-700"
    >
      <i
        class="fas fa-inbox opacity-70 mr-1 group-hover:opacity-100 group-hover:text-purple-500"
        aria-hidden="true"
      />
      <span class="mr-1">{{ active_projects_count }} </span>
      <span>{{  $t('Active projects')  }}</span>
    </router-link>

    <router-link
      :to="myOverdueTasksPath"
      class="px-6 py-5 text-sm font-medium text-center text-gray-500 group hover:text-gray-700"
    >
      <i
        class="fas fa-badge-check opacity-70 mr-1 group-hover:opacity-100 group-hover:text-yellow-500"
        aria-hidden="true"
      />
      <span class="mr-1">{{ overdue_tasks_count }} </span>
      <span>{{  $t('Tasks due/overdue')  }}</span>
    </router-link>

    <router-link
      :to="`/inbox?status=${notificationStatuses.Unread}`"
      class="px-6 py-5 text-sm font-medium text-center text-gray-500 group hover:text-gray-700"
    >
      <i
        class="fas fa-bell opacity-70 mr-1 group-hover:opacity-100 group-hover:text-green-500"
        aria-hidden="true"
      />
      <span class="mr-1">{{ unread_comments_count }}</span>
      <span>{{ $t('Unread Notifications') }}</span>
    </router-link>
  </div>
</template>
<script setup>
// Libs
import i18n from "@/i18n.js"
import { computed, watch } from "vue"
import { notificationStatuses } from '@/modules/accounts/utils/notificationUtils.js'

// Compnents
import ProfileDropdown from "@/modules/dashboard/components/ProfileDropdown.vue";

// Helpers
import { encodeFilters } from '@/modules/common/utils/filterUtils';
import { getMyTasksFilterAppliedQuery } from '@/modules/tasks/utils/modelUtils';
import { desktopAppUtils } from "@/modules/common/utils/desktopAppUtils.js";

// Composables
import { useStore } from 'vuex'

const store = useStore()

const statsLoading = computed(() => {
  return store.state.accounts.statsLoading
})

const stats = computed(() => {
  return store.state.accounts.stats
})

const starred_pages_count = computed(() => {
  return stats.value?.starred_pages_count || 0
})

const active_projects_count = computed(() => {
  return stats.value?.active_projects_count || 0
})

const overdue_tasks_count = computed(() => {
  return stats.value?.overdue_tasks_count || 0
})

const unread_comments_count = computed(() => {
  return stats.value?.unread_comments_count || 0
})

const myActiveProjectsPath = computed(() => {
  const viewOptions = store.getters['users/defaultTargetViewOptions']('projects')
  const view_type = viewOptions?.view_type || 'list'

  const filters = encodeFilters([
    {
      key: 'sorts',
      value: {
        column: 'created_at',
        order: 'desc'
      }
    },
    {
      key: 'my-projects',
      value: {
        show: true
      }
    },
    {
      key: 'closed',
      value: {
        show: false
      }
    },
  ])

  return {
    path: `/projects/${view_type}`,
    query: {
      filters
    }
  }
})

const myOverdueTasksPath = computed(() => {
  const viewOptions = store.getters['users/defaultTargetViewOptions']('tasks')
  const view_type = viewOptions?.view_type || 'list'

  const filters = encodeFilters([
    {
      key: 'sorts',
      value: {
        column: 'sortable_date',
        order: 'asc'
      }
    },
    {
      key: 'my-tasks',
      value: {
        show: true
      }
    },
    {
      key: 'overdue-tasks',
      value: {
        show: true
      }
    },
    {
      key: 'completed',
      value: {
        show: false
      }
    }
  ])

  return {
    path: `/tasks/${view_type}`,
    query: {
      filters
    }
  }
})

watch(unread_comments_count, (newValue) => {
  desktopAppUtils.setBadgeCount(newValue);
})

function openStarredPages() {
  store.commit('accounts/triggerOpenStarredPages')
}
</script>
