import store from '@/store'
import i18n from '@/i18n'

export const SYSTEM_DEFAULT_FILTER = {
  filters: {
    search: '',
    page: 1
  },
  sortFilter: {},
  completedProjectsFilter: false,
  completedTasksFilter: false,
  myProjectsFilter: false,
  myTasksFilter: false,
  overdueTasksFilter: false,
  filterByFilter: [],
  presetFilters: {},
  kanbanColumnsFilter: {}
}

export const totalRowFunctions = {
  Sum: 'sum',
  Average: 'avg'
}

export const totalRowOptions = [
  {
    label: i18n.t('Sum'),
    value: totalRowFunctions.Sum
  },
  {
    label: i18n.t('Average'),
    value: totalRowFunctions.Average
  },
]

export function getActiveSortForColumn(target, column) {
  const apiFilters = store.getters['filters/targetApiFilters'](target) || []
  
  let localSortValue = apiFilters.find((filter) => filter.key === 'sorts')?.value

  if (!localSortValue) {
    localSortValue = []
  }
  else if (!Array.isArray(localSortValue)) {
    localSortValue = [localSortValue]
  }

  const activeSortIndex = localSortValue.findIndex((sort) => sort.column === column.sortProp)

  if (activeSortIndex === -1) {
    return null
  }
  
  return {
    ...localSortValue[activeSortIndex],
    sortIndex: localSortValue.length > 1 ? (activeSortIndex + 1) : null
  }
}
