<template>
  <BaseAlert
    v-if="status !== 'none'"
    :dismissable="dismissable"
    :variant="status === 'pending' ? 'warning' : 'success'"
    class="mb-4"
    :class="{
      'border border-yellow-500': status === 'pending',
      'border border-green-500': status === 'ready'
    }"
    @dismiss="onDismiss"
  >
    <template
      v-if="isArchivePending"
      #icon
    >
      <DataSyncingIndicator
        class="text-lg mt-0"
      />
    </template>
    <p v-if="isArchivePending">
      {{ $tc(`creating archive`, { archiveName }) }}
    </p>
    <p v-else-if="isArchiveReady">
      {{ $t(`Archive is ready for download. Click here to download it `) }}
      <a
        :href="downloadUrl"
        :download="archiveName"
        target="_blank"
        class="underline"
        @click.stop="dismissable = true"
      >
        {{ archiveName }}
      </a>
    </p>
  </BaseAlert>
</template>
<script lang="ts" setup>
// Components
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"

// Utils
import { useStore } from 'vuex'
import { computed, ref, watch } from "vue";
import { success } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";

const store = useStore()

const dismissable = ref(false)

const archiveState = computed(() => {
  return store.state.files.archiveState
})

const archiveName = computed(() => {
  return archiveState.value?.name
})

const status = computed(() => {
  return archiveState.value?.status
})

const isArchivePending = computed(() => {
  return status.value === 'pending'
})

const isArchiveReady = computed(() => {
  return status.value === 'ready'
})

const archiveBlob = computed(() => {
  return archiveState.value?.blob
})

const downloadUrl = computed(() => {
  if (!archiveBlob.value) {
    return ''
  }

  return URL.createObjectURL(archiveBlob.value)
})

watch(() => isArchiveReady.value, (value: boolean) => {
  if (!value) {
    return
  }

  success(i18n.t('Archive is ready for download.'))
})

function onDismiss() {
  store.commit('files/setArchiveState', {
    name: '',
    status: 'none',
    blob: null
  })
}
</script>
