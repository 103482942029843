<template>
  <BaseInput
    v-model="model"
    :key="customField.attributes.key"
    class="custom-field-input"
    :id="($attrs.prependName || '') +  customField.attributes.key"
    :name="($attrs.prependName || '') + customField.attributes.name"
    :label="type === 'currency' && $attrs.filterBy ? $t('Currency') : label"
    :placeholder="placeholder"
    :layout="layout"
    :rules="customField.attributes.rules"
    :type="baseInputType"
    :columnCount="columnCount"
  >
    <template v-if="labelIcon" #label>
      <div class="mt-1">
        <i class="far fa-sparkles mr-2 mt-1 w-4" />
        {{ label }}<span v-if="isRequired">*</span>
      </div>
    </template>
    <template v-if="type === 'percent' && !showLabelActivator" #prefix>
      <div class="pr-2 mt-1">%</div>
    </template>
    <template v-if="showCustomInput" #default>
      <div
        v-if="showLabelActivator"
        @click="showInput"
        class="mt-3 text-sm text-gray-400 hover:text-gray-500 cursor-pointer"
      >
        {{ `${$t('+ Set')} ${customField.attributes.name}` }}
      </div>
      <template v-else>
        <div v-if="isSelectFieldType" :class="`sm:col-span-${colSpan}`">
          <BaseSelect
            v-model="model"
            :options="selectOptions"
            :multiple="selectInputMultiple"
            :placeholder="placeholder"
            :return-object="type !== 'select'"
            class="w-full"
            :class="$attrs.class"
            valueKey="value"
            labelKey="label"
            :select-with-color="type === 'select-color'"
            :disabled="$attrs.disabled"
            :inline="$attrs.inline"
            :filterable="$attrs.filterable"
          >
            <template #prefix>
              <slot name="select-prefix" />
            </template>
          </BaseSelect>
        </div>
        <div v-else-if="type === 'textarea'" :class="`sm:col-span-${colSpan}`">
          <textarea
            v-model="model"
            :id="($attrs.prependName || '') + customField.attributes.key"
            :name="($attrs.prependName || '') + customField.attributes.name"
            :placeholder="placeholder"
            :rows="textareaRows"
            class="block w-full bg-white border border-gray-200 overflow-hidden rounded-md p-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-600 focus:border-gray-600 sm:text-sm"
          />
        </div>
        <CurrencyInput
          v-else-if="type === customFieldTypes.Currency"
          v-model="model"
          v-bind="$attrs"
          :currency-options="selectOptions"
          :placeholder="placeholder"
          :label="customField.attributes?.name"
        />
        <BaseDatePicker
          v-else-if="isDateType"
          v-model="model"
          :id="($attrs.prependName || '') + customField.attributes.key"
          :name="($attrs.prependName || '') + customField.attributes.name"
          :placeholder="placeholder"
          :class="`sm:col-span-${colSpan}`"
          :disabled="$attrs.disabled"
          :inline="$attrs.inline"
          :filterable="$attrs.filterable"
        />
      </template>
    </template>
  </BaseInput>
</template>
<script>
import CurrencyInput from "@/modules/common/components/CurrencyInput.vue";
import { customFieldTypes } from "@/modules/accounts/utils/modelUtils";

export default {
  components: {
    CurrencyInput
  },
  props: {
    modelValue: {
      type: [String, Object, null],
      required: false,
    },
    customField: {
      type: Object,
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: "horizontal",
    },
    colSpan: {
      type: Number,
      default: 3,
    },
    columnCount: {
      type: Number,
      default: 4,
    },
    textareaRows: {
      type: Number,
      default: 6,
    },
    labelIcon: {
      type: Boolean,
      default: false
    },
    defaultHiddenInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputVisible: false,
      customFieldTypes
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    type() {
      return this.customField.attributes.custom_field_type
    },
    baseInputType() {
      const numericTypes = [customFieldTypes.Numeric, customFieldTypes.Percent, customFieldTypes.Currency];
      if (numericTypes.includes(this.type)) {
        return "number";
      }
      return "text";
    },
    selectInputMultiple() {
      return this.type === customFieldTypes.Multiselect;
    },
    isSelectFieldType() {
      const selectFieldTypes = [customFieldTypes.Select, customFieldTypes.Multiselect, customFieldTypes.SelectColor];
      return selectFieldTypes.includes(this.type);
    },
    isDateType() {
      return this.type === customFieldTypes.Date
    },
    selectOptions() {
      const options = this.customField.attributes?.options || []
      if (!Array.isArray(options) && typeof options === 'object') {
        return Object.keys(options).map(key => {
          return {
            label: key,
            value: options[key]
          }
        });
      }
      return options
    },
    label() {
      if (this.hideLabel) {
        return ''
      }
      return this.customField.attributes.name
    },
    placeholder() {
      const action = this.isSelectFieldType || this.isDateType
        ? this.$t("Select")
        : this.$t("Enter");

      return `${action} ${this.customField.attributes.name}...`;
    },
    isRequired() {
      return this.customField.attributes.rules?.includes('required')
    },
    showLabelActivator() {
      return !this.isRequired && this.defaultHiddenInput && !this.model && !this.inputVisible
    },
    showCustomInput() {
      return this.isSelectFieldType || this.type === 'textarea' || this.type === 'currency' || this.showLabelActivator || this.isDateType
    },
  },
  methods: {
    async showInput() {
      this.inputVisible = true
      await this.$nextTick()
      this.$el.querySelector('input')?.focus()
    }
  }
};
</script>
