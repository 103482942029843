<template>
  <span
    :class="{
      'rounded-md': !rounded,
      'rounded-full': rounded,
      'block w-full': block,
      'inline-flex': !block,
      'shadow-sm': !variant.includes('link') && !$attrs.class?.includes('shadow-none'),
      [customClass]: customClass
    }"
    class="relative button-wrapper"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <component
      v-bind="$attrs"
      :is="tag"
      :type="$attrs.type || 'button'"
      :disabled="disabled || loading"
      ref="button"
      class="base-button items-center justify-center border font-medium focus:outline-none transition ease-in-out duration-150"
      :class="{
        'rounded-md': !rounded,
        'rounded-full': rounded,
        'focus:ring-gray-600 focus:border-gray-600': hasFocus,
        'text-white bg-primary-500 hover:bg-primary-600 focus:border-primary-700 active:bg-primary-700 border-transparent': currentVariant === 'primary',
        'text-primary-500 hover:text-primary-900 border-transparent': currentVariant === 'primary-link',
        'text-white bg-red-500 hover:bg-red-600 border-transparent focus:border-red-700 active:bg-red-700': currentVariant === 'danger',
        'text-red-700 border-red-300 hover:bg-red-50 focus:border-red-300 active:bg-red-400': currentVariant === 'danger-secondary',
        'text-gray-500 bg-white border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-500': currentVariant === 'white',
        'text-gray-500 bg-white border-gray-200 hover:border-primary-500 hover:bg-primary-500 hover:text-white': currentVariant === 'white-secondary',
        'text-gray-500 bg-white border-gray-200 hover:border-green-600 hover:bg-green-600 hover:text-white': currentVariant === 'success',
        'text-xs px-2 py-1 leading-2': size === 'xxs',
        'text-xs px-2.5 py-1.5 leading-4': size === 'xs',
        'text-sm px-3 py-2 leading-4': size === 'sm',
        'text-sm px-4 py-2 leading-5': size === 'md',
        'text-base px-6 py-2.5 leading-5': size === 'lg',
        'text-base px-8 py-3 leading-6': size === 'xl',
        'btn-disabled': disabled || localLoading,
        'inline-flex': !block,
        'w-full flex justify-center': block,
        [`${currentVariant}-variant`]: true
      }"
    >
      <div
        v-if="localLoading"
        class="absolute flex w-full items-center justify-center"
      >
        <LoadingIcon :size="size" />
      </div>

      <div
        class="flex items-center"
        :class="{ 'opacity-0': localLoading }"
      >
        <slot>
          <span>
            {{ label }}
          </span>
        </slot>
      </div>
    </component>
  </span>
</template>
<script>
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";

export default {
  inheritAttrs: false,
  components: {
    LoadingIcon,
  },
  props: {
    block: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary' // primary|secondary|error|danger|danger-secondary|white|success
    },
    hoverVariant: {
      type: String,
      default: '' // primary|secondary|error|danger|danger-secondary|white|success
    },
    size: {
      type: String,
      default: 'md' // xs|sm|md|xl
    },
    customClass: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: 'button',
    },
    hasFocus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localLoading: false,
      isHovering: false
    }
  },
  computed: {
    currentVariant() {
      if (this.hoverVariant && this.isHovering) {
        return this.hoverVariant
      }

      return this.variant
    }
  },
  methods: {
    focus() {
      if (!this.$refs.button) {
        return
      }
      this.$refs.button.focus()
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.localLoading = false
          return
        }
        setTimeout(() => {
          if (this.loading) {
            this.localLoading = true
          }
        }, 200)
      }
    }
  }
}
</script>
