<template>
  <ElPopover
    ref="emojiPopover"
    :width="400"
    :placement="placement"
    popper-class="emoji-popover"
    trigger="click"
    @show="focusOnEmojiInput"
    @hide="popoverVisible = false"
  >
    <template #reference>
      <slot>
        <button
          type="button"
          class="p-2 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
        >
          <i class="fa-regular fa-face-smile"></i>
        </button>
      </slot>
    </template>
    
    <VuemojiPicker
      v-if="popoverVisible"
      ref="picker"
      :is-dark="false"
      @emojiClick="onChange"
    />

  </ElPopover>
</template>
<script>
import { ElPopover } from 'element-plus'
import { VuemojiPicker } from 'vuemoji-picker'

export default {
  components: {
    ElPopover,
    VuemojiPicker,
  },
  props: {
    placement: {
      type: String,
      default: 'bottom',
    }
  },
  data() {
    return {
      popoverVisible: false,
    }
  },
  methods: {
    async focusOnEmojiInput() {
      this.popoverVisible = true
      await this.$nextTick();
      const input = document.body.querySelector('.el-popper[aria-hidden=false] emoji-picker').shadowRoot.querySelector('input');
      
      if (!input) {
        return
      }

      input.classList.add('form-input')
      input.focus()
    },
    onChange(value) {
      this.$emit('change', value)
      this.hide()
    },
    hide() {
      this.$refs?.emojiPopover?.hide()
    },
    open() {
      this.$refs?.emojiPopover?.show()
    }
  },
  async beforeUnmount() {
    const pickerEl = this.$refs?.picker?.$el
    await pickerEl?.querySelector('emoji-picker')?.database?.close()
  }
}
</script>
<style lang="scss">
.el-popper.emoji-popover {
  @apply p-0;
}
</style>
