<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-4xl font-extrabold">
      {{ $t(`Congratulations`) }} 🎉
    </h2>
    <p class="text-gray-500 leading-7 text-lg">
      {{ $t(`You have completed your account setup.`) }}
    </p>

    <h3 class="text-gray-900 text-2xl font-extrabold">
      {{ $t(`What would you like to do next?`) }}
    </h3>

    <div class="space-y-4 z-20" style="z-index: 200;">
      <div class="bg-gray-50 border border-gray-200 rounded-md w-full p-6">
        <router-link
          to="/welcome"
          class="text-gray-700 hover:text-primary-500 cursor-pointer text-xl font-extrabold"
        >
          {{ $t(`Go to dashboard`) }}
          <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
        </router-link>
        <p class="text-gray-500 leading-7 text-base">
          {{ $t(`Go back to the main dashboard of your account and explore your projects and tasks.`) }}
        </p>
      </div>

      <div class="bg-gray-50 border border-gray-200 rounded-md w-full p-6">
        <router-link
          to="/account-settings"
          class="text-gray-700 hover:text-primary-500 cursor-pointer text-xl font-extrabold"
        >
          {{ $t(`Go to account settings`) }}
          <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
        </router-link>
        <p class="text-gray-500 leading-7 text-base">
          {{ $t(`Go to your account settings to define the rest of your account preferences.`) }}
        </p>
      </div>

      <div class="bg-gray-50 border border-gray-200 rounded-md w-full p-6">
        <router-link
          to="/profile"
          class="text-gray-700 hover:text-primary-500 cursor-pointer text-xl font-extrabold"
        >
          {{ $t(`Go to your personal settings`) }}
          <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
        </router-link>
        <p class="text-gray-500 leading-7 text-base">
          {{ $t(`Go to your personal settings to set your personal preferences & connect your Google or Outlook calendar.`) }}
        </p>
      </div>

      <div class="bg-gray-50 border border-gray-200 rounded-md w-full p-6">
        <router-link
          to="/integrations"
          class="text-gray-700 hover:text-primary-500 cursor-pointer text-xl font-extrabold"
        >
          {{ $t(`Go to integrations`) }}
          <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
        </router-link>
        <p class="text-gray-500 leading-7 text-base">
          {{ $t(`Go to account integrations to connect 3rd party tools like Stripe, Paypal, Zapier & more.`) }}
        </p>
      </div>

    </div>
    <Vue3Lottie
      :animationLink="lottieAnimationLink"
      background="transparent"
      :loop="false"
      style="width: 1000px; height: 1000px; z-index: 0; position: absolute; pointer-events: none; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);"
    />
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';
import { Vue3Lottie } from 'vue3-lottie'
import { trackActivity, Activities } from "@/modules/common/utils/trackingUtils";

import {
  useGettingStarted,
  GettingStartedKeys
} from '@/modules/accounts/composables/useGettingStarted';
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useStore } from 'vuex'

const store = useStore()

const {
  setStepCompleted
} = useGettingStarted()

const { currentUser } = useAuth()

const lottieAnimationLink = `https://lottie.host/47d6f8fd-d228-4503-98bb-3d1ea855c05d/nMH5frvQCG.json`

function trackEventCompleted() {
  trackActivity(Activities.SetupWizardCompleted, {
    first_name: currentUser.value.first_name,
    last_name: currentUser.value.last_name,
    email: currentUser.value.email,
    account_name: store.state.auth.tenantInfo?.name || '',
  })
}

onMounted(() => {
  setStepCompleted(GettingStartedKeys.AccountSetupWizardCompleted, true)
  trackEventCompleted()
})
</script>
