<template>
  <SettingsForm
    :fields="fields"
    :title="$t('Invoice info')"
    :description="$t('Enter the information you would like to be displayed on any invoices that are created following successful payments by your clients.')"
    tutorial="invoice-info"
  >
    <template #default="{ model }">
      <div class="grid grid-cols-6 gap-4">
        <BaseInput
          :modelValue="model.invoice_logo"
          :label="$t('Invoice Logo')"
          class="col-span-6 md:col-span-3"
        >
          <div class="sm:mt-px sm:pt-1 sm:col-span-3">
            <BaseAvatarPicker
              v-model="model.invoice_logo"
              :disabled-options="['group', 'emoji', 'unsplash', 'clearbit', 'url']"
              return-base-64
            >
              <template #activator="{ avatar }">
                <img
                  v-if="avatar"
                  :src="avatar"
                  class="h-14 cursor-pointer"
                >
                <div
                  v-else
                  class="cursor-pointer transition ease-in-out duration-150 rounded-md border-gray-300 text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-100 text-sm px-3 py-2 leading-5 inline-flex"
                >
                  {{ $t("+ Add an image") }}
                </div>
              </template>
            </BaseAvatarPicker>
          </div>
        </BaseInput>

        <BaseInput
          :label="$t('Invoice Footer')"
          :placeholder="$t('Invoice Footer')"
          :name="$t('Invoice Footer')"
          class="col-span-6"
          id="invoice_footer"
        >
          <textarea
            v-model="model.invoice_footer"
            id="invoice-footer"
            name="invoice-footer"
            rows="6"
            class="block w-full form-input"
            :placeholder="$t('Invoice footer information...')"
          />
        </BaseInput>

        <BaseInput
          v-model="model.street_address_1"
          :label="$t('Billing Address')"
          :placeholder="$t('Line One')"
          :name="$t('Billing Address')"
          class="col-span-6 md:col-span-3"
          id="address_1"
        />
        <BaseInput
          v-model="model.street_address_2"
          :placeholder="$t('Line Two')"
          :name="$t('Line Two')"
          label="&nbsp"
          class="col-span-6 md:col-span-3"
          id="address_2"
        />

        <BaseInput
          v-model="model.city"
          :label="$t('City')"
          :placeholder="$t('City')"
          :name="$t('City')"
          class="col-span-6 md:col-span-3"
          id="city"
        />
        <BaseInput
          v-model="model.region"
          :label="$t('Region')"
          :placeholder="$t('Region')"
          :name="$t('Region')"
          class="col-span-6 md:col-span-3"
          id="region"
        />
        <BaseInput
          v-model="model.post_code"
          :label="$t('Post Code')"
          :placeholder="$t('Post Code')"
          :name="$t('Post Code')"
          class="col-span-6 md:col-span-3"
          id="post_code"
        />
        <BaseInput
          v-model="model.country"
          :label="$t('Country')"
          :placeholder="$t('Country')"
          :name="$t('Country')"
          class="col-span-6 md:col-span-3"
          id="country"
        />
      </div>
    </template>
  </SettingsForm>
</template>
<script>

import SettingsForm from "@/modules/accounts/components/SettingsForm.vue";

export default {
  components: {
    SettingsForm,
  },
  data() {
    return {
      fields: [
        'vat_number',
        'company_number',
        'invoice_series',
        'invoice_logo',
        'invoice_footer',
        'street_address_1',
        'street_address_2',
        'country',
        'city',
        'region',
        'post_code',
      ],
      imageUrl: null,
    }
  },
}
</script>
<style scoped>
.invoice-logo-wrapper :deep(img) {
  @apply w-auto;
}
</style>
