<template>
  <DashboardWidget
    :widget="widget"
    :ui="ui"
    :widgetData="widgetData"
  >
    <template #item="{ item: file }">
      <FileWidgetEntry
        :file="file"
      />
    </template>
  </DashboardWidget>
</template>
<script lang="ts" setup>
// Components
import DashboardWidget from "@/modules/dashboard/components/widgets/DashboardWidget.vue";
import FileWidgetEntry from "@/modules/dashboard/components/widgets/FileWidgetEntry.vue";

// Utils
import i18n from "@/i18n";
import { PropType, computed } from 'vue'
import { DashboardWidgetType, WidgetUI, WidgetData } from "@/modules/dashboard/utils/widgetUtils"

// Composables
import { useStore } from 'vuex'
import useCan from "@/modules/common/composables/useCan"
const { can, actions } = useCan()

const store = useStore()

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidgetType>,
    required: true
  }
})

const ui = computed<WidgetUI>(() => {
  return {
    listPath: '/files/list',
    listLabel: i18n.t('Go to files'),
    entityCreate: can(actions.CREATE_PROJECT_FILES) ? '/files/add' : '',
    footerKey: 'showing uploaded files'
  }
})

const widgetData = computed<WidgetData>(() => {
  return {
    target: 'files',
    dataGetterPath: 'files/currentFiles',
    groupedDataGetter: 'files/groupedFiles',
    loading: store.state.files.filesLoading
  }
})

store.dispatch('files/getAllFiles')
</script>
