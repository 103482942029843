<template>
  <BaseDialogNew
    v-bind="$attrs"
    v-model="visible"
    class="max-w-2xl"
    @close="$emit('close')"
  >
    <BaseEntityForm
      width-class="max-w-2xl"
      :title="$t('Complete Project')"
      :subtitle="$t('Mark this project as completed and close it for editing. You can reopen it from the project dashboard if you need to work on it again in future. Choose the official completion date for the project using the input below.')"
      :isDialogForm="true"
      @submit="completeProject"
    >
      <template #default="{ meta, errors }">
        <div v-loading="!currentProject" >
          <WarningAlert
            v-if="activeTasksCount > 0"
            :dismissable="false"
            class="border-yellow-700 border max-w-4xl m-auto mb-4"
          >
            {{
              $tc('active tasks warning', {
                count: activeTasksCount
              })
            }}
          </WarningAlert>

          <WarningAlert
            v-if="unpaidPaymentRequestsCount > 0"
            :dismissable="false"
            class="border-yellow-700 border max-w-4xl m-auto mb-4"
          >
            {{
              $tc('unpaid payment requests warning', {
                count: unpaidPaymentRequestsCount
              })
            }}
          </WarningAlert>

          <BaseInput
            :modelValue="completed_at"
            :label="$t('Completion Date')"
            :name="$t('Completion Date')"
            layout="horizontal"
          >
            <template #label>
              <i class="far fa-calendar mr-1 my-auto" />
              <span> {{ $t('Completion Date') }} </span>
            </template>
            <BaseDatePicker
              v-model="completed_at"
            />
          </BaseInput>
        </div>
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          variant="white"
          @click="visible = false; $emit('cancel')">
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          :disabled="!currentProject || !meta.valid"
          type="submit"
          class="ml-2"
        >
          {{ $t('Complete project') }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialogNew>
</template>
<script>
import i18n from "@/i18n";
import { isTaskClosed } from "@/modules/tasks/utils/modelUtils";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => ({})
    },
    syncProject: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      completed_at: new Date(),
      currentProject: null,
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    activeTasksCount() {
      return this.currentProject?.meta?.activeTasksCount || 0
    },
    unpaidPaymentRequestsCount() {
      return this.currentProject?.meta?.unpaidPaymentRequestsCount || 0
    },
    activeProjectTasks() {
      const allActiveTasks = this.$store.state.tasks.allTasks || []
      return allActiveTasks.filter(task => task?.attributes?.project_id == this.currentProject?.id && !isTaskClosed(task))
    }
  },
  methods: {
    async completeProject() {
      try {
        this.loading = true

        await this.$store.dispatch('projects/completeProject', {
          project: this.currentProject,
          completed_at: this.completed_at,
        })

        for (const task of this.activeProjectTasks) {
          this.$store.dispatch('tasks/handleTaskCompleted', {
            task,
          })
        }
        
        this.$success(i18n.t(`Project was successfully marked as completed!`))

        this.visible = false
      }
      catch(err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('An error occurred while trying to complete the project'))
      }
      finally {
        this.loading = false
      } 
    },
    async syncProjectSilently() {
      if (!this.syncProject) {
        this.currentProject = this.project
        return
      }

      this.currentProject = await this.$store.dispatch('projects/getProjectById', {
        id: this.project?.id,
        silent: true,
        returnEntity: true
      }) 
    }
  },
  mounted() {
    this.syncProjectSilently()
  }
}
</script>
