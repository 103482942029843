<template>
  <BaseDialogNew
    v-bind="$attrs"
    :hasBodyMargin="false"
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <BaseFormHeader
      :is-dialog-form="true"
      :title="$t(`Update ${plan.name?.replaceAll('-', ' ')} plan`)"
    />
    <BaseForm
      v-slot="{ meta }"
      @submit="choosePlan"
    >
      <div class="divide-y divide-gray-200 sm:space-y-5 p-6">

        <div class="space-y-6 sm:space-y-5">
          <BaseInput
            v-if="!isFreePlan"
            v-focus
            v-model="quantityModel"
            :label="$t('Creator Seats')"
            :placeholder="$t('Creator Seats')"
            :name="$t('Creator Seats')"
            :rules="`required|min_value:${minSeatCount}`"
            :info-text="$tc(`creator seat count`, {
                minSeatCount: minSeatCount,
                usedSeatCount: usedSeatCount,
              })"
            :disabled="isFreePlan"
            type="number"
            layout="horizontal"
            id="creator_seats"
          />
          <p class="text-sm text-gray-500">
            <template v-if="isFreePlan">
              {{ $t('Are you sure you want to move to the Free plan?') }}
            </template>
            <template v-else>
              {{ $tc(`creator seat count minimum`, { usedSeatCount: usedSeatCount, minSeatCount: minSeatCount }) }}
            </template>
          </p>
          <!-- <UpcomingPaymentInfo
            v-if="!isDifferentPlan"
            :plan="plan"
            :quantity="quantityModel"
            @is-loading="paymentInfoLoading = $event"
          /> -->
        </div>
      </div>

      <div class="px-6 py-4 border-t border-gray-200">
        <div class="flex justify-end">
          <BaseButton
            variant="white"
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </BaseButton>
          <BaseButton
            :loading="loading || paymentInfoLoading"
            :disabled="!meta.valid"
            type="submit"
            class="ml-2"
          >
            <template v-if="isDifferentPlan">
              {{ $t('Choose Plan') }}
            </template>
            <template v-else>
              {{ $t('Update Plan') }}
            </template>
          </BaseButton>
        </div>
      </div>
    </BaseForm>
  </BaseDialogNew>
</template>
<script>
import UpcomingPaymentInfo from "@/modules/accounts/components/UpcomingPaymentInfo.vue"
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import i18n from "@/i18n";

const minCreatorSeats = 1
const maxFreeStorage = 3
export default {
  components: {
    UpcomingPaymentInfo
  },
  props: {
    plan: {
      type: Object,
      default: () => ({})
    },
    userPlan: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const { accountLimits } = useAccountLimits()
    return {
      accountLimits,
    }
  },
  data() {
    let quantity = this.userPlan?.quantity || 0

    // Upgrading from free to paid plan
    if (!this.userPlan?.plan?.price) {
      quantity = this.accountLimits?.seats?.used || minCreatorSeats
    }
    if (quantity < minCreatorSeats) {
      quantity = minCreatorSeats
    }

    return {
      loading: false,
      invalidCard: true,
      paymentInfoLoading: false,
      validationCardMessage: '',
      maxFreeStorage,
      quantity,
      model: {
        payment_method: '',
      },
    }
  },
  computed: {
    isFreePlan() {
      return !this.plan?.price
    },
    isDifferentPlan() {
      return this.userPlan?.plan?.id !== this.plan.id
    },
    minSeatCount() {
      return this.usedSeatCount > minCreatorSeats ? this.usedSeatCount : minCreatorSeats
    },
    usedSeatCount() {
      return this.accountLimits.seats.used
    },
    usedStorage() {
      return this.accountLimits.storage.used
    },
    isTrialPeriod() {
      return this.$store.getters['accounts/isTrialPeriod']
    },
    quantityModel: {
      get() {
        return Number(this.quantity)
      },
      set(value) {
        this.quantity = value
      }
    },
  },
  methods: {
    async choosePlan() {
      try {
        this.loading = true

        if (!this.userPlan?.stripe_status || this.isTrialPeriod || this.isDifferentPlan) {
          await this.initCheckoutSession()
        }
        else {
          await this.updateSubscription()
          this.$success(`${this.plan?.nickname} Plan successfully updated.`)
          await this.$store.dispatch('accounts/getSubscription', { forceFetch: true })
        }
        this.$emit('close')
      } catch (err) {
        if (err.handled) {
          return
        }
        console.log(err)
        this.$error(i18n.t('An error occurred while trying to update the billing plan'))
      } finally {
        this.loading = false
      }
    },
    async initCheckoutSession() {
      const checkoutSession = await this.$store.dispatch('accounts/getCheckoutSession', {
        plan: this.plan,
        data: {
          quantity: this.quantity,
        },
        params: {
          successUrl: window.location.href,
          cancelUrl: window.location.href,
        }
      })

      if (!checkoutSession.data.url) {
        this.$error('Something went wrong, please try again')
        return;
      }

      window.location.href = checkoutSession.data.url
    },
    async updateSubscription() {
      let data = {
        subscription_id: this.userPlan.id,
        plan_id: this.plan.id,
        quantity: this.quantityModel,
      }

      await this.$store.dispatch('accounts/updateSubscription', data)
    },
  }
}
</script>
