<template>
  <BaseEntityForm
    :title="$t('Your settings')"
    :subtitle="$t('Update your name, email address and photo. Your current profile photo is shown below.')"
    width-class="max-w-3xl"
    :stickyActions="false"
    @submit="onSubmit"
  >
    <template #default="{ meta, errors }">
      <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start">
        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {{ $t('Photo/Icon') }}
        </label>
        <div class="sm:mt-px sm:pt-1 sm:col-span-3">
          <BaseAvatarPicker
            v-model="model.avatar"
            :disabled-options="['group', 'emoji', 'unsplash', 'clearbit']"
          />
        </div>
      </div>

      <BaseInput
        v-model="model.first_name"
        :label="$t('First Name')"
        :placeholder="$t('First Name')"
        :name="$t('First Name')"
        layout="horizontal"
        rules="required|max:25"
        id="first_name"
      />

      <BaseInput
        v-model="model.last_name"
        :label="$t('Last Name')"
        :placeholder="$t('Last Name')"
        :name="$t('Last Name')"
        layout="horizontal"
        rules="required|max:25"
        id="last_name"
      />

      <BaseInput
        v-model="model.email"
        :label="$t('Email')"
        :placeholder="$t('Email')"
        :name="$t('Email')"
        layout="horizontal"
        rules="required|email"
        id="email"
      />
      <RequiresPermissionTo :action="$actions.ASSIGN_ROLES_TO_PEOPLE">
        <BaseInput
          :label="$t('Role')"
          :placeholder="$t('Select user role...')"
          :name="$t('Role')"
          layout="horizontal"
          id="role"
        >
          <div class="mt-1 sm:mt-0 sm:col-span-3">
            <BaseTooltip
              :content="$t('You are not allowed to change your own role.')"
            >
              <UserRoleSelect
                v-model="model.role"
                v-focus
                disabled
                class="w-full"
              />
          </BaseTooltip>
          </div>
        </BaseInput>
      </RequiresPermissionTo>
      <RequiresPermissionTo :action="$actions.ASSIGN_PEOPLE_TO_ANY_GROUP">
        <BaseInput
          :modelValue="model.groups"
          :label="$t('Groups')"
          :placeholder="$t('Select groups...')"
          :name="$t('Groups')"
          layout="horizontal"
          id="groups"
        >
          <div class="mt-1 sm:mt-0 sm:col-span-3">
            <GroupSelect
              v-model="model.groups"
              :initial-value="profile?.groups"
              :multiple="true"
              :placeholder="$t('Select group')"
            />
          </div>
        </BaseInput>
      </RequiresPermissionTo>

      <BaseInput
        :label="$t('Project Notifications')"
        :info-text="$t('Set your default notifications preference for any projects you are added to in the future.')"
        class="col-span-6 md:col-span-3"
        layout="horizontal"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <BaseSelect
            :modelValue="projectNotifications"
            :options="notificationOptions"
            @update:modelValue="onChangeNotificationSetting"
          />
        </div>
      </BaseInput>

      <BaseRoundedCheckbox
        v-model="model.notifiable_settings.daily_task"
        :label="$t('Daily tasks email')"
        size="sm"
        inputClass="sm:col-span-3"
      >
        <template #label>
          <BaseTooltip :content="dailyTaskTooltip">
            <span>{{ $t('Daily tasks email') }}</span>
          </BaseTooltip>
        </template>
      </BaseRoundedCheckbox>

      <BaseInput
        :label="$t('Language')"
        :info-text="$t('Profile language select tip')"
        class="col-span-6 md:col-span-3"
        layout="horizontal"
      >
        <LanguageSelect
          v-model="model.locale"
          :showAccountLevelOption="true"
          id="locale"
        />
      </BaseInput>

      <BaseInput
        v-if="can(this.$actions.USE_SPEECH_TO_TEXT)"
        :label="$t('STT Language')"
        :info-text="$t('Select the language you would like to use for speech to text.')"
        class="col-span-6 md:col-span-3"
        layout="horizontal"
      >
        <SttLanguageSelect
          v-model="model.stt_locale"
          id="locale"
        />
      </BaseInput>

      <DefaultViewSettings
        :key="$i18n.locale"
        @user-views-changed="userDefaultViews = $event"
      />

      <UserProfileIntegrations />
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$router.push('/account-settings')"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        :loading="loading"
        :disabled="!meta.valid"
        type="submit"
        class="ml-2"
      >
        {{ $t('Save') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script>
import i18n from "@/i18n";
import GroupSelect from "@/components/selects/GroupSelect.vue";
import UserRoleSelect from "@/components/selects/UserRoleSelect.vue";
import DefaultViewSettings from "@/modules/accounts/components/DefaultViewSettings.vue"
import UserProfileIntegrations from "@/modules/accounts/components/UserProfileIntegrations.vue"
import LanguageSelect from "@/components/common/LanguageSelect.vue";
import SttLanguageSelect from "@/components/common/SttLanguageSelect.vue";

const ProjectNotificationOptions = {
  On: 'on',
  Off: 'off',
  RelatedOnly: 'relatedOnly',
}
export default {
  components: {
    GroupSelect,
    UserRoleSelect,
    DefaultViewSettings,
    UserProfileIntegrations,
    LanguageSelect,
    SttLanguageSelect
  },
  data() {
    return {
      loading: false,
      formKey: 1,
      model: {
        first_name: '',
        last_name: '',
        email: '',
        role: null,
        groups: [],
        notifiable_settings: {},
        locale: 'en',
      },
      projectNotifications: ProjectNotificationOptions.On,
      notificationOptions: [
        {
          label: i18n.t('All notifications'),
          value: ProjectNotificationOptions.On,
        },
        {
          label: i18n.t('Related notifications only'),
          value: ProjectNotificationOptions.RelatedOnly,
        },
        {
          label: i18n.t('Notifications off'),
          value: ProjectNotificationOptions.Off,
        },
      ],
      userDefaultViews: []
    }
  },
  computed: {
    profile() {
      return this.$user
    },
    roles() {
      return this.$store.getters['users/editableRoles'] || []
    },
    dailyTaskTooltip() {
      return i18n.t("When turned off you won't receive the daily task email. You will still receive system emails.")
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        const {
          first_name,
          last_name,
          email,
          avatar,
          role,
          notifiable_settings,
          locale,
          stt_locale
        } = this.model
        if (this.can(this.$actions.ASSIGN_PEOPLE_TO_ANY_GROUP)) {
          await this.$store.dispatch('users/updateUserGroups', {
            user: this.profile,
            groups: this.model.groups,
          })
        }

        await this.$store.dispatch('auth/updateUserNotifiable', {
          user: this.$user,
          notifiable_settings
        })

        await this.$store.dispatch('auth/updateProfile', {
          data: {
            email,
            avatar,
            last_name,
            first_name,
            role,
            locale,
            stt_locale
          },
          canUpdateRole: this.can(this.$roles.ASSIGN_ROLES_TO_PEOPLE)
        })

        await this.$store.dispatch('users/updateDefaultViews', this.userDefaultViews)

        this.$success(i18n.t('Your profile was updated.'))
      } finally {
        this.loading = false
      }
    },
    tryGetRoleId(name) {
      const role = this.roles.find(s => s?.attributes?.name === name)
      return role?.id
    },
    getNotificationSetting(settings) {
      if (settings.user_related_notifications === true && !settings.notifiable_on_project) {
        return ProjectNotificationOptions.RelatedOnly
      } else if (settings.notifiable_on_project === true) {
        return ProjectNotificationOptions.On
      } else {
        return ProjectNotificationOptions.Off
      }
    },
    onChangeNotificationSetting(settingObject) {
      const setting = settingObject?.value
      if (setting === ProjectNotificationOptions.On) {
        this.model.notifiable_settings.user_related_notifications = true
        this.model.notifiable_settings.notifiable_on_project = true
      } else if (setting === ProjectNotificationOptions.RelatedOnly) {
        this.model.notifiable_settings.user_related_notifications = true
        this.model.notifiable_settings.notifiable_on_project = false
      } else if (setting === ProjectNotificationOptions.Off) {
        this.model.notifiable_settings.user_related_notifications = false
        this.model.notifiable_settings.notifiable_on_project = false
      }
      this.projectNotifications = setting
    }
  },
  watch: {
    profile: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) {
          return
        }

        this.model = {
          ...this.model,
          ...value,
        }

        this.model.notifiable_settings = value?.notifiable_settings || {}
        this.projectNotifications = this.getNotificationSetting(this.model.notifiable_settings)

        const roleLabel = this.get(value, 'role[0]')
        if (roleLabel) {
          this.model.role = this.tryGetRoleId(roleLabel)
        }

        this.model.groups = value?.groups?.map(g => g.id) || []
      }
    },
    roles: {
      handler() {
        const roleLabel = this.get(this.profile, 'role[0]')
        if (roleLabel) {
          this.model.role = this.tryGetRoleId(roleLabel)
        }
      }
    }
  },
  created() {
    this.$store.dispatch('users/getRoles')
  }
}
</script>
