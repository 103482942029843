<template>
  <div class="flex flex-col h-full">
    <TopFilters
      :columns="projectColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="isTemplatePath ? $t('Search templates...') : $t('Search projects...')"
    >
    
      <template
        v-if="hasActions"
        #action
      >
        <RequiresPermissionTo
          v-if="isTemplatePath"
          :action="$actions.CREATE_PROJECT_TEMPLATES"
        >
          <router-link to="/templates/add">
            <BaseButton
              block
            >
              {{ $t('New Template') }}
            </BaseButton>
          </router-link>
        </RequiresPermissionTo>
        <RequiresPermissionTo
          v-else
          :action="$actions.CREATE_PROJECTS"
        >
          <BaseDropdown
            :split-button="true"
            :options="createProjectOptions"
            class="create-options-dropdown"
          >
            <router-link to="/projects/add">
              <BaseButton
                block
              >
                {{ $t('New Project') }}
              </BaseButton>
            </router-link>
          </BaseDropdown>
        </RequiresPermissionTo>
      </template>

      <EntityViewTypesSelect
        v-if="$isMobileDevice"
        :viewTypes="viewTypes"
      />
    </TopFilters>

    <DataSyncingIndicator
      v-if="projects.length && $store.state.projects.projectsLoading"
      dataView
    />

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
  </div>
</template>
<script>
import { importEntityTypes } from "@/modules/accounts/utils/importUtils";

import TopFilters from "@/modules/filters/components/TopFilters.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"
import EntityViewTypesSelect from "@/modules/common/components/EntityViewTypesSelect.vue";
import EntityViewTypesTabs from "@/modules/common/components/EntityViewTypesTabs.vue";

import { AccountPermissions } from "@/modules/common/composables/useCan";
import useEntityCrud from "@/modules/common/composables/useEntityCrud"

export default {
  components: {
    TopFilters,
    DataSyncingIndicator,
    EntityViewTypesSelect,
    EntityViewTypesTabs
  },
  setup() {
    const {
      triggerEntityCreate,
      entityTypes
    } = useEntityCrud()

    return {
      triggerEntityCreate,
      AccountPermissions,
      entityTypes
    }
  },
  computed: {
    projectColumns() {
      return this.$store.getters['projects/activeColumns']
    },
    projects() {
      return this.$store.state.projects.projects?.data || []
    },
    availableFilters() {
      const filters = ['filter']
      const { path } = this.$route
      if (!this.isTemplatePath) {
        filters.push('myProjects', 'closedProjects')
      }
      if (!this.isTemplatePath && (this.$isMobileDevice || this.$useNewLayout)) {
        filters.push('myProjects')
      }

      if (path.includes('list') || path.includes('card')) {
        filters.push(...['columns', 'sort', 'group'])
      } else if (path.includes('scheduler')) {
        filters.push(...['group'])
      }

      if (path.includes('kanban')) {
        filters.push(...['columns', 'group', 'kanbanColumns', 'sort'])
      }

      return filters
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    hasActions() {
      return (this.isTemplatePath && this.can(this.$actions.CREATE_PROJECT_TEMPLATES)) ||
        (!this.isTemplatePath && this.can(this.$actions.CREATE_PROJECTS))
    },
    target() {
      if (this.isTemplatePath) {
        return 'templates'
      }
      return 'projects'
    },
    viewTypes() {
      const viewTypes = [
        {
          name: this.$t('List View'),
          path: this.getPath(`/${this.target}/list`),
          isActive: this.$route.path === `/${this.target}/list`,
          icon: 'fa-list-check',
          showIf: () => {
            return this.can(this.$actions.ACCESS_PROJECTS_LIST) && !this.$isMobileDevice
          }
        },
        {
          name: this.$t('Cards View'),
          path: this.getPath(`/${this.target}/card`),
          isActive: this.$route.path === `/${this.target}/card`,
          icon: 'fa-grid-2',
          showIf: () => {
            return this.can(this.$actions.ACCESS_PROJECTS_CARD)
          }
        },
        {
          name: this.$t('Calendar View'),
          path: this.getPath(`/${this.target}/calendar`),
          isActive: this.$route.path === `/${this.target}/calendar`,
          icon: 'fa-calendar-days',
          showIf: () => {
            return !this.isTemplatePath && this.can(this.$actions.ACCESS_PROJECTS_CALENDAR) && !this.$isMobileDevice
          }
        },
        {
          name: this.$t('Scheduler View'),
          path: this.getPath(`/${this.target}/scheduler`),
          isActive: this.$route.path === `/${this.target}/scheduler`,
          icon: 'fa-chart-simple-horizontal',
          showIf: () => {
            return !this.isTemplatePath && this.can(this.$actions.ACCESS_PROJECTS_CALENDAR) && !this.$isMobileDevice
          }
        },
        {
          name: this.$t('Kanban View'),
          path: this.getPath(`/${this.target}/kanban`),
          isActive: this.$route.path === `/${this.target}/kanban`,
          icon: 'fa-objects-column',
          showIf: () => {
            return !this.isTemplatePath && this.can(this.$actions.ACCESS_PROJECTS_KANBAN)
          }
        }
      ]

      return viewTypes
    },
    createProjectOptions() {
      return [
        {
          label: this.$t('Import Projects'),
          action: () => {
            this.$store.commit('setEntityCreateParams', {
              importType: importEntityTypes.Projects,
            })

            this.$store.commit('accounts/triggerDataImport')
          },
          enabled: this.can(this.$actions.MANAGE_ACCOUNT_SETTINGS)
        }
      ]
    }
  },
  methods: {
    getPath(path) {
      return path
    }
  },
}
</script>
