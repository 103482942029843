<template>
  <div
    v-if="columnOptions?.length"
    class="relative hidden md:visible lg:inline-block text-left mt-2 lg:mt-0"
  >
    <BaseDropdown
      :options="columnOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
    >
      <TopFilterChip
        :isActive="!!activeGroupColumns"
        :prependLabel="$t('Chart')"
        :label="activeGroupColumns || $t('None')"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ $t('Chart') }}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import { orderBy } from 'lodash-es'
import TopFilterChip from '@/modules/filters/components/TopFilterChip.vue'

export default {
  components: {
    TopFilterChip,
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    defaultFilter: {
      type: Object,
      default: () => ([])
    },
    allowMultiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartFilter: this.defaultFilter || [],
    }
  },
  computed: {
    columnsInDropdown() {
      return this.columns.filter(c => c.chartDataType === 'category')
    },
    columnOptions() {
      return this.columnsInDropdown.map(column => ({
        label: `By ${column.name}`,
        action: (event) => this.toggleChartFilter(event, column),
        variant: this.chartFilter.some(field => field === (column.prop || column.field)) ? 'active' : '',
      }))
    },
    activeGroupColumns() {
      const active = this.columnsInDropdown.filter(column => this.chartFilter.some(field => field === (column.prop || column.field)))
      return orderBy(active, (column) => this.chartFilter.indexOf(column.prop || column.field), 'asc')
        .map(c => c.headerName || c.name)
        .join(', ')
    }
  },
  methods: {
    toggleChartFilter(event, column) {
      this.chartFilter = this.chartFilter.find(field => field === (column.prop || column.field))
        ? []
        : [column.prop]

      this.$emit('change', this.chartFilter)
    }
  },
  watch: {
    defaultFilter() {
      let newChartFilter = this.defaultFilter || []

      if (!Array.isArray(newChartFilter)) {
        newChartFilter = [newChartFilter.prop || newChartFilter.field]
      }

      this.chartFilter = newChartFilter
    }
  }
}
</script>
