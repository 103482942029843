<template>
  <WarningAlert
    v-if="isTrialPeriod"
    :dismissable="dismissable"
    class="border border-yellow-500"
  >
    <p>
      {{ $t('Your account is in trial period.') }}
      {{ $tc('Trials days left', trialDaysRemaining)}}
    </p>
    <p>
    </p>
  </WarningAlert>
</template>
<script>
import WarningAlert from "@/components/common/WarningAlert.vue";

export default {
  components: {
    WarningAlert,
  },
  props: {
    dismissable: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    isTrialPeriod() {
      return this.$store.getters['accounts/isTrialPeriod']
    },
    trialDaysRemaining() {
      return this.$store.getters['accounts/trialDaysRemaining']
    },
  }
}
</script>
