<template>
  <div>
    <BaseEntityForm
      :title="$tc('manage project tools', {
        projectName: project?.attributes?.name,
      })"
      :subtitle="$t('project tools form subtitle')"
      :isDialogForm="true"
      slim
      width-class="max-w-4xl"
    >
      <template #default>
        <div class="bg-white rounded-lg p-6 space-y-4">
          <div class="min-w-0 flex-1">
            <div class="flex items-center">
              <div class="w-full">
                <label for="search" class="sr-only">
                  {{ $t('Search tools...') }}
                </label>
                <div class="relative">
                  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <i class="far fa-search text-gray-400" aria-hidden="true"></i>
                  </div>
                  <input
                      v-model="filterTerm"
                      v-focus
                      id="tools-search"
                      name="tools-search"
                      class="block w-full bg-white border border-gray-200 rounded-md py-3 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:text-white focus:bg-gray-600 focus:placeholder-white focus:border-gray-600 sm:text-sm"
                      :placeholder="$t('Search tools...')"
                      type="search"
                      @keydown.stop
                  >
                </div>
              </div>
            </div>
          </div>

          <ErrorAlert
            v-if="toolsLimitReached"
            class="mt-2"
            :dismissable="false"
          >
            <p class="text-red-500">
              {{ $t('tools limit reached', { limit: accountLimits.toolsPerProject.available, }) }}
            </p>
          </ErrorAlert>

          <h3 class="text-xl font-bold text-gray-500">
            {{ $t('Core Tools') }}
          </h3>
          <section aria-labelledby="quick-links-title">
            <div class="w-full grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              <ProjectToolCard
                v-for="tool of coreToolsFiltered"
                v-loading="toolsLoading[tool.value]"
                :key="tool.value"
                :tool="tool"
                :project="project"
                :class="{
                  'cursor-not-allowed': toolsLimitReached && !tool.isSelected,
                }"
                @toggle-tool-selected="toggleToolSelected(tool)"
              />
            </div>
          </section>


          <h3 class="text-xl font-bold text-gray-500 pt-4">
            {{ $t('3rd Party Tools') }}
          </h3>
          <section aria-labelledby="quick-links-title">
            <div class="w-full grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              <ProjectToolCard
                v-for="tool of thirdPartyTools"
                v-loading="toolsLoading[tool.value]"
                :key="tool.value"
                :tool="tool"
                :project="project"
                @toggle-tool-selected="toggleToolSelected(tool)"
              />
            </div>
          </section>
        </div>
      </template>
      <template #actions>
        <div class="flex justify-between items-center w-full">
          <BaseButton
            variant="white"
            class="mr-auto"
            @click="$emit('cancel')"
          >
            {{ $t('Cancel') }}
          </BaseButton>

          <BaseButton
            @click="$emit('close')"
          >
            {{ $t(`I'm done adding tools - Close`) }}
          </BaseButton>
        </div>
      </template>
    </BaseEntityForm>
    <EmbedDialog
      v-if="showEmbedDialog"
      v-model="showEmbedDialog"
      v-show="showEmbedDialog"
      key="embed-create-new"
      single-embed
      :defaultEmbedType="createEmbedType"
      :projectId="project.id"
      @close="showEmbedDialog = false"
      @save="showEmbedDialog = false; onToolCreate()"
    />

    <ProjectLinkDialog
      v-if="showLinkDialog"
      v-model="showLinkDialog"
      v-show="showLinkDialog"
      :key="`link-create-new_${createLinkType}`"
      :defaultLinkType="createLinkType"
      :defaultPinned="true"
      @close="showLinkDialog = false"
      @save="showLinkDialog = false; onToolCreate()"
    />

    <ProjectReferenceDialog
      v-if="showReferenceDialog"
      v-model="showReferenceDialog"
      v-show="showReferenceDialog"
      :defaultPinned="true"
      key="reference-create-new"
      @close="showReferenceDialog = false"
      @save="showReferenceDialog = false; onToolCreate()"
    />
  </div>
</template>
<script>
// Components
import ProjectToolCard from '@/modules/projects/components/ProjectToolCard.vue'
import ProjectLinkDialog from '@/modules/projects/components/ProjectLinkDialog.vue'
import ProjectReferenceDialog from '@/modules/projects/components/ProjectReferenceDialog.vue'
import EmbedDialog from '@/modules/resources/components/EmbedDialog.vue'

// Utils
import i18n from '@/i18n'
import { computed } from 'vue'
import { orderBy } from 'lodash-es'
import {
  coreTools,
  embedTools,
  projectLinkTools,
  toolShortcuts,
  toolShortcutIcons,
  toolsEnum,
  filterTools,
} from '@/modules/projects/utils/toolUtils'
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from "@/modules/common/composables/useCan";

export default {
  components: {
    ProjectToolCard,
    EmbedDialog,
    ProjectLinkDialog,
    ProjectReferenceDialog,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {
      accountLimits,
      hasReachedLimit,
    } = useAccountLimits()
    

    const toolsLimitReached = computed(() => {
      return hasReachedLimit(AccountPermissions.ProjectTools)(props.project)
    })

    return {
      accountLimits,
      toolsLimitReached,
    }
  },
  data() {
    return {
      filterTerm: '',
      createEmbedType: null,
      createLinkType: null,
      showEmbedDialog: false,
      showLinkDialog: false,
      showReferenceDialog: false,
      toolsLoading: {},
    }
  },
  computed: {
    toolDefinitions() {
      return this.$store.state.projects?.tools?.data || []
    },
    projectTools() {
      return this.project?.relationships?.tools || []
    },
    coreToolsExtended() {
      return coreTools.map(tool => {
        const definition = this.toolDefinitions.find(t => t.attributes.name === tool.value)
        const projectTool = this.projectTools.find(t => {

          if (tool.id === 'embed' && t.pivots?.options?.entity_type === 'embed' && t.pivots?.options?.type === tool.value) {
            return true
          }

          if (t.attributes.name === toolsEnum.EMBEDS) {
            return t.id === definition?.id && !t.pivots?.options?.entity_type
          }

          return t.id === definition?.id
        })
        const shortcutIcon = toolShortcutIcons[tool.value]

        return {
          id: definition?.id,
          isSelected: projectTool !== undefined,
          definition,
          projectTool,
          shortcutIcon,
          ...tool,
        }
      })
    },
    coreToolsFiltered() {
      return this.coreToolsExtended.filter((tool) => filterTools(tool, this.filterTerm))
    },
    embedToolsExtended() {
      return embedTools.map(tool => {
        const isSelected = this.projectTools
            .some(t => t.pivots?.options?.entity_type === 'embed' && t.pivots?.options?.type === tool.value)

        return {
          isSelected,
          ...tool,
        }
      })
    },
    embedToolsFiltered() {
      return this.embedToolsExtended.filter((tool) => filterTools(tool, this.filterTerm))
    },
    linkToolsExtended() {
      return projectLinkTools.map(tool => {
        const isSelected = this.projectTools
            .some(t => t.pivots?.options?.entity_type === 'link' && t.pivots?.options?.type === tool.value)

        return {
          isSelected,
          ...tool,
        }
      })
    },
    linkToolsFiltered() {
      return this.linkToolsExtended.filter((tool) => filterTools(tool, this.filterTerm))
    },
    thirdPartyTools() {
      return orderBy([...this.embedToolsFiltered, ...this.linkToolsFiltered], 'name')
    },
  },
  methods: {
    onKeyDown(event) {
      const isDialogOpened = this.showEmbedDialog || this.showLinkDialog || this.showReferenceDialog
      if (event.target?.tagName === 'INPUT' || isDialogOpened) {
        return
      }

      this.tryAddToolByShortcut(event)
    },
    tryAddToolByShortcut(event) {
      if (event.ctrlKey || event.metaKey || event.altKey) {
        return
      }

      for (const toolName in toolShortcuts) {
        if (event.code !== toolShortcuts[toolName]) {
          continue
        }

        const tool = this.coreToolsExtended.find(t => t.value === toolName)
        if (!tool) {
          return
        }

        this.toggleToolSelected(tool)
      }
    },
    toggleToolSelected(tool) {
      if (this.toolsLimitReached && !tool.isSelected) {
        this.$error(this.$t('tools limit reached error'))
        return
      }
      // console
      if (tool.isSelected && !tool.allowMultiple) {
        this.confirmRemoveTool(tool)
      } else {
        this.addTool(tool)
      }
    },
    async addTool(tool) {
      if (tool.id === 'embed') {
        this.createEmbedType = tool.value
        this.showEmbedDialog = true
        return
      }

      if (tool.id === 'link') {
        this.createLinkType = tool.value
        this.showLinkDialog = true
        return
      }

      if (tool.allowMultiple) {
        switch (tool.value) {
          case toolsEnum.NOTE:
            return this.triggerAddNewNote()
          case toolsEnum.LINK:
            return this.triggerAddNewLink()
          case toolsEnum.REFERENCE:
            return this.showReferenceDialog = true
          default:
            return
        }
      }

      try {
        this.toolsLoading[tool.value] = true

        await this.$store.dispatch('projects/addProjectTool', {
          tool: tool.definition,
          projectId: this.project.id,
        })

        this.$success(i18n.t('project tool added successfully', {
          toolName: tool.name,
        }))
      } catch(err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('could not add project tool', {
          toolName: tool.name,
        }))
      } finally {
        this.toolsLoading[tool.value] = false
      }

    },
    triggerAddNewLink() {
      this.createLinkType = null
      this.showLinkDialog = true
    },
    async onToolCreate() {
      await this.$store.dispatch('projects/getProjectById', {
        id: this.project.id,
        forceFetch: true,
        silent: true,
      })
    },
    async triggerAddNewNote() {
      try {
        this.toolsLoading[toolsEnum.NOTE] = true
        await this.$store.dispatch('projects/createNewProjectNote', {
          project_id: this.project.id,
        })
        this.$success(this.$t('Note added successfully'))
        await this.onToolCreate()
      } finally {
        this.toolsLoading[toolsEnum.NOTE] = false
      }
    },
    async confirmRemoveTool(tool) {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Remove tool'),
        description: i18n.t('confirm remove project tool', {
          toolName: tool.name,
        }),
        buttonText: i18n.t('Remove'),
      })

      if (!confirmed) {
        return
      }

      this.removeTool(tool)
    },
    async removeTool(tool) {
      try {
        this.toolsLoading[tool.value] = true
        await this.$store.dispatch('projects/removeProjectTool', {
          tool: tool.projectTool,
          projectId: this.project.id,
        })
      } catch(err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not remove the tool from the project'))
      } finally {
        this.toolsLoading[tool.value] = false
      }
    },
  },
  mounted() {
    addEventListener('keydown', this.onKeyDown)
  },
  unmounted() {
    removeEventListener('keydown', this.onKeyDown)
  },
}
</script>
