import store from "@/store";
import { error } from "@/components/common/NotificationPlugin";

const billingRoute = '/account-settings/billing'
const accountSettingsRoute = '/account-settings'
const welcomeRoute = '/welcome'
/**
 * Middleware to check if the account subscription is active.
 * In case the subscription is not active, it will redirect the user to the billing or welcome page with a specific message
 * @param {object} router Vue router instance
 */
export default function subscriptionMiddleware(router) {
  router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (!requiresAuth) {
      return next()
    }
    const isSubscriptionValid = store.getters['accounts/isValidSubscription']
    const isTenantOwner = store.getters['auth/isTenantOwner']

    const exceptionRoutes = isTenantOwner ? [accountSettingsRoute, billingRoute, welcomeRoute] : [welcomeRoute]
    if (isSubscriptionValid || exceptionRoutes.includes(to.path)) {
      return next();
    }
    const subscriptionMessage = store.getters['accounts/subscriptionInvalidMessage']
    error(subscriptionMessage)

    if (isTenantOwner) {
      return next('/account-settings/billing')
    }
    return next('/welcome')
  });
}
