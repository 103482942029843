<template>
  <div v-if="filteredOptions.length">
    <BaseDropdown
      :options="filteredOptions"
      class="w-full"
      @action="onAction"
    >
      <div class="p-1 text-sm text-gray-400 w-full">
        {{ $t(`+ Add custom fields`) }}
      </div>
    </BaseDropdown>
  </div>
</template>
<script setup>
// Helpers
import {
  customFieldAppliesToOptions,
  triggerCreateCustomField,
} from '@/modules/accounts/utils/modelUtils'

import { computed } from 'vue'
import i18n from '@/i18n'
import useCan from '@/modules/common/composables/useCan'

const { can, actions } = useCan()

const props = defineProps({
  task: {
    type: Object,
    default: () => ({}),
  },
  project_id: {
    type: [String, Number],
    default: null,
  },
})

const options = [
  {
    label: i18n.t('Project level - For tasks in this project only'),
    action: 'createProjectLevelCustomField',
    enabled: () => {
      return !!props.project_id && can(actions.CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS)
    },
  },
  {
    label: i18n.t('Account level - For tasks in all projects'),
    action: 'createAccountLevelCustomField',
    requiresPermissionTo: actions.CREATE_CUSTOM_FIELDS,
  },
]

const filteredOptions = computed(() => {
  return options.filter(x => {
    if (x.enabled) {
      return x.enabled()
    }
    return !x.requiresPermissionTo || can(x.requiresPermissionTo)
  })
})

function onAction(action) {
  if (cf_actions[action]) {
    cf_actions[action]()
    return
  }

  emit(action)
}

const cf_actions = {
  createProjectLevelCustomField() {
    triggerCreateCustomField(customFieldAppliesToOptions.TASK, props.project_id)
  },
  createAccountLevelCustomField() {
    triggerCreateCustomField(customFieldAppliesToOptions.TASK)
  },
}
</script>
