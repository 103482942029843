<template>
  <div
    v-if="widgetCustomFilters.length"
    class="space-y-3"
  >
    <BaseSelect
      v-for="filter in widgetCustomFilters"
      :key="filter.filterKey"
      :modelValue="model[filter.filterKey]"
      @update:modelValue="onFilterValueChanged(filter.filterKey, $event)"
      :options="filter.options"
      :labelPrefix="filter.labelPrefix"
      :returnObject="false"
      :clearable="filter.clearable"
      :disabled="filter.disabled"
      class="custom-filter-select"
    />
  </div>
</template>
<script lang="ts" setup>
// Utils
import i18n from "@/i18n";
import { decodeFilters } from "@/modules/common/utils/filterUtils";
import { DashboardWidgetType, Widgets } from "@/modules/dashboard/utils/widgetUtils";
import { PropType, computed, watch } from "vue";
import { UserTypes } from "@/modules/users/util/userUtils";

// Composables
import { useStore } from "vuex";

const store = useStore()

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidgetType>,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
  },
  scope: {
    type: String as PropType<UserTypes>,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const isCollaboratorUser = computed(() => {
  return store.getters['users/isCollaboratorUser']
})

const customFilters = computed<Partial<Record<Widgets, any[]>>>(() => {
  return {
    [Widgets.Tasks]: [
      {
        labelPrefix: i18n.t(`Only show tasks with a date:`),
        filterKey: 'date-only',
        defaultValue: false,
        clearable: false,
        options: [
          {
            label: i18n.t('Yes'),
            value: true,
          },
          {
            label: i18n.t('No'),
            value: false,
          },
        ]
      },
      {
        labelPrefix: props.scope === UserTypes.Self
          ? i18n.t(`Only show tasks I am assigned to:`)
          : i18n.t(`Only show tasks the user is assigned to:`),
        filterKey: 'my-tasks',
        defaultValue: props.scope === UserTypes.Collaborators || isCollaboratorUser.value
          ? false // Show all tasks (they have access to) for collaborators by default
          : true, // Only show tasks they are assigned to for creators by default
        clearable: false,
        options: [
          {
            label: i18n.t('Yes'),
            value: true,
          },
          {
            label: i18n.t('No'),
            value: false,
          },
        ]
      },
    ],
    [Widgets.Projects]: [
      {
        labelPrefix: props.scope === UserTypes.Self
          ? i18n.t(`Only show projects I am part of:`)
          : i18n.t(`Only show projects the user is part of:`),
        filterKey: 'my-projects',
        defaultValue: true,
        clearable: false,
        disabled: props.scope === UserTypes.Collaborators || isCollaboratorUser.value,
        options: [
          {
            label: i18n.t('Yes'),
            value: true,
          },
          {
            label: i18n.t('No'),
            value: false,
          },
        ]
      },
    ],
  }
})

const widgetFilters = computed(() => {
  if (!props.widget.filters) {
    return []
  }

  const {
    filters,
  } = JSON.parse(props.widget.filters)
  
  if (!filters) {
    return []
  }

  return decodeFilters(filters)
})

const model = computed<any>({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

function onFilterValueChanged(key: string, value: any) {
  model.value = {
    ...model.value,
    [key]: value,
  }
}

const widgetCustomFilters = computed(() => {
  return customFilters.value[props.widget.component] || []
})

function initModel() {
  widgetCustomFilters.value.forEach((filter: any) => {
    const value = widgetFilters.value.find((f: any) => f.key === filter.filterKey)?.value?.show

    model.value[filter.filterKey] = value !== undefined
      ? value
      : filter.defaultValue
  })
}

watch(() => props.scope, () => {
  initModel()
}, { immediate: true })
</script>
<style lang="scss">
.base-select.el-select.custom-filter-select:not(.filterable-select) {
  .select-trigger input.el-input__inner[disabled] {
    opacity: 0;
  }
}
</style>
