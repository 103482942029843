<template>
  <BaseTableCell :params="params">
    <div
      v-if="row?.id === -1 && !hasLogo"
      class="rounded-md cursor-pointer"
      :class="{
        [dimClasses]: true
      }"
    >
      <div class="flex items-center justify-center h-full w-full rounded-md">
        <i class="fa-solid fa-image text-gray-300 text-3xl" />
      </div>
    </div>
    <BaseLogo
      v-else
      :entity="row"
      :logoKey="logoKey"
      :size="logoSize"
      :imageSrc="imageSrc"
      class="mx-auto"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import { get } from "lodash-es"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
  dimClasses: {
    type: String,
    default: 'w-8 h-8'
  },
  logoSize: {
    type: String,
    default: 'sm'
  },
  imageSrc: {
    type: String,
    default: null
  }
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const hasLogo = computed(() => {
  return get(row.value, logoKey.value, false) || props.imageSrc
})

const entityType = computed<string>(() => {
  return row.value.type
})

const logoKey = computed<string>(() => {
  if (entityType.value === 'users') {
    return 'attributes.avatar'
  }

  return 'attributes.image'
})
</script>
