<template>
  <BaseDialogNew
    ref="dialog"
    v-model="dialogModel"
    class="comment-thread-dialog w-full max-w-2xl"
    @close="onClose"
  >
    <div class="w-full align-middle bg-white border border-gray-200 overflow-hidden rounded-lg shadow">
      <div class="w-full border-b border-gray-200 p-6">
        <h3 class="text-2xl font-bold">{{ $t('Comment Thread') }}</h3>
      </div>

      <div class="m-auto align-middle p-6 space-y-6">

        <DiscussionComment
          :discussion="discussion"
          :readonly="readonly"
          :level="2"
          isThreadParent
        />

        <div class="w-full border-t border-dashed border-gray-200 text-gray-400 text-xs font-medium">
          {{ $tc('reply count', { count: replyCount }) }}
          <i class="fa-solid fa-caret-down ml-1"></i>
        </div>

        <InfiniteScroll
          :id="discussion.id"
          :load-next="loadNextPage"
          :distance="70"
          scrollableContainerSelector=".dialog-container"
          direction="up"
          class="flex-1 space-y-6"
          loaderClass="hidden"
        >
          <template v-if="loading && replyCount > 0 && discussions.length === 0">
            <LoadingComment v-for="i in placeholdersToRender" :key="i" />
          </template>
          <DiscussionComment
            v-for="discussion of discussions"
            :key="discussion.id"
            :discussion="discussion"
            :level="2"
            editable
            :readonly="readonly"
            @update="updateComment"
            @delete="deleteComment"
          />
        </InfiniteScroll>

        <HtmlCommentInput
          v-if="!readonly"
          :sendAction="sendComment"
          :id="`thread-editor-${discussion.id}`"
          :isConnectedToChannel="isConnectedToChannel"
          :usersTyping="usersTyping"
          :projectId="projectId"
          :notifyIsTyping="notifyIsTyping"
          :infoText="commentInputInfoText"
        />

      </div>

      <div class="px-6 py-4 border-t border-gray-200">
        <div class="flex justify-end">
          <button
            type="button"
            class="bg-white py-2 px-4 border border-gray-200 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
            @click="onClose(true)"
          >
            {{ $t('Close') }}
          </button>
        </div>
      </div>

    </div>
  </BaseDialogNew>
</template>
<script>
import { useRoute } from 'vue-router'

import InfiniteScroll from "@/components/common/InfiniteScroll.vue";
import PusherUtils from "@/modules/common/utils/pusherUtils.js";
import { useDiscussion } from "@/modules/projects/composables/useDiscussion.js";
import LoadingComment from "@/modules/common/components/LoadingComment.vue";
import HtmlCommentInput from "@/components/form/HtmlCommentInput.vue"

export default {
  name: 'CommentThread',
  components: {
    InfiniteScroll,
    LoadingComment,
    HtmlCommentInput,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    discussion: {
      type: Object,
      default: () => ({}),
    },
    entityType: {
      type: String,
      default: 'project'
    },
    entityId: {
      type: [String, Number],
    },
    defaultProjectId: {
      type: [String, Number],
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute()
    const entityId = props.entityId || route.params.id
    let projectId, taskId
    if (props.entityType === 'project') {
      projectId = entityId
    } else {
      taskId = entityId
    }

    const {
      usersTyping,
      retryCount,
      isConnectedToChannel,
      listenChannel,
      triggerChannel,
      tryJoinChannel,
      notifyIsTyping,
    } = useDiscussion({ projectId, taskId })

    return {
      usersTyping,
      retryCount,
      isConnectedToChannel,
      listenChannel,
      triggerChannel,
      tryJoinChannel,
      notifyIsTyping,
    }
  },
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    dialogModel: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    projectId() {
      return this.defaultProjectId || this.entityId || this.$route.params.id
    },
    taskId() {
      return this.entityId || this.$route.query.taskId
    },
    loading() {
      return this.$store.state.discussions.discussionThreads[this.discussion.id]?.loading
    },
    discussions() {
      return this.$store.state.discussions.discussionThreads[this.discussion.id]?.data || []
    },
    threadId() {
      return this.discussion.id
    },
    replyCount() {
      return this.discussion?.attributes?.thread_count
    },
    placeholdersToRender() {
      return Math.min(this.replyCount, 10)
    },
    commentInputInfoText() {
      if (this.entityType === 'project') {
        return this.$t('A notification will be sent to all people who are part of this project and have notifications turned on.')
      }

      return this.$t('A comment notification will be sent to all assigned people and all followers.')
    }
  },
  methods: {
    async loadNextPage($state) {
      if (this.loading) {
        return;
      }

      try {
        if (this.discussions.length) {
          $state.loaded()
        }

        let project, task
        if (this.entityType === 'project') {
          project = this.projectId
        } else {
          task = this.taskId
        }
        const result = await this.$store.dispatch('discussions/getThreadDiscussion', {
          task,
          project,
          page: this.page,
          parent_id: this.discussion.id,
        })

        $state.loaded()

        if (this.page === 1) {
          setTimeout(this.scrollToNewestComment, 100)
        }

        if (result?.meta?.current_page === result?.meta?.last_page) {
          $state.complete()
        } else {
          this.page++
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not load more comments'))
      }
    },
    async sendComment({ message, notifiable_user_ids }) {
      try {
        const entity_id = this.entityType === 'project'
          ? this.projectId
          : this.taskId
        const newAddedComment = await this.$store.dispatch('discussions/createThreadComment', {
          message,
          entity_id,
          notifiable_user_ids,
          entity_type: this.entityType,
          parent_id: this.discussion.id,
        })

        await this.scrollToNewestComment()
        this.triggerChannel?.trigger(PusherUtils.THREAD_COMMENT_ADDED_EVENT, newAddedComment)

        this.discussion.attributes.thread_count++

        const updatedParent = await this.$store.dispatch('discussions/getDiscussionById', this.discussion.id)
        this.discussion.attributes.thread_count = updatedParent.attributes.thread_count
        this.discussion.attributes.reply_users = updatedParent.attributes.reply_users

        this.triggerChannel?.trigger(PusherUtils.COMMENT_UPDATED_EVENT, this.discussion)

      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not add a new comment'))
        throw err
      }
    },
    async scrollToNewestComment() {
      await this.$nextTick()
      const scrollableEl = document.querySelector('.comment-thread-dialog .dialog-container')
      scrollableEl?.scrollTo({
        top: scrollableEl.scrollHeight,
        behavior: 'smooth',
      })
    },
    async updateComment({ comment, message }) {
      const updatedComment = await this.$store.dispatch('discussions/updateThreadComment', { comment, message })
      this.triggerChannel?.trigger(PusherUtils.THREAD_COMMENT_UPDATED_EVENT, updatedComment)
    },
    async deleteComment(comment) {
      await this.$store.dispatch('discussions/deleteThreadComment', { comment, threadId: this.discussion.id})
      this.discussion.attributes.thread_count--

      this.triggerChannel?.trigger(PusherUtils.THREAD_COMMENT_DELETED_EVENT, comment.id)
      this.triggerChannel?.trigger(PusherUtils.COMMENT_UPDATED_EVENT, this.discussion)
    },
    async onClose(forceClose = false) {
      if (forceClose) {
        this.$refs?.dialog?.onClose?.()
        this.dialogModel = false
      }
      await this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          threadId: undefined,
        },
      })
    },
    isSameParent(comment) {
      return comment?.attributes?.parent_id?.toString() === this.discussion?.id?.toString()
    },
    initChannelEvents() {
      this.page = 1

      this.listenChannel.on(PusherUtils.THREAD_COMMENT_ADDED_EVENT, (comment) => {
        if (!this.isSameParent(comment)) {
          return
        }
        this.$store.commit('discussions/addCommentToThread', { comment, threadId: this.threadId })
        this.scrollToNewestComment()
      })

      this.listenChannel.on(PusherUtils.THREAD_COMMENT_DELETED_EVENT, id => {
        this.$store.commit('discussions/deleteThreadComment', { threadId: this.threadId, commentId: id?.toString() })
      })

      this.listenChannel.on(PusherUtils.THREAD_COMMENT_UPDATED_EVENT, comment => {
        if (!this.isSameParent(comment)) {
          return
        }
        this.$store.commit('discussions/updateThreadComment', {
          threadId: this.threadId,
          comment
        })
      })
    }
  },
  mounted() {
    this.initChannelEvents()
    document.body.classList.add('overflow-y-hidden')
  },
  unmounted() {
    document.body.classList.remove('overflow-y-hidden')
  }
}
</script>
