<template>
  <Surface
      v-if="shouldShowContent"
      :class="toolbarClassName"
      v-bind="$attrs"
  >
    <slot></slot>
  </Surface>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import Surface from '../Surface.vue'

const props = defineProps({
  shouldShowContent: {
    type: Boolean,
    default: true,
  },
  isVertical: {
    type: Boolean,
    default: false,
  },
})

const { isVertical, shouldShowContent } = toRefs(props)

const toolbarClassName = computed(() => {
  return [
    'text-black inline-flex h-full leading-none gap-0.5',
    isVertical.value ? 'flex-col p-2' : 'flex-row p-1 items-center',
  ].filter(Boolean).join(' ')
})
</script>
