<template>
  <div class="flex items-center whitespace-nowrap gap-y-1 gap-x-2">
    <BasePopoverInput :autoShow="true">
      <template #reference>
      <div
        v-if="users.length"
        class="flex -space-x-1 overflow-hidden"
      >
        <UserLogo
          v-for="user of sortedUsers"
          :key="user.id"
          :user="user"
          :disableTooltip="true"
          :actions="userActions"
          class="rounded-lg cursor-pointer"
          size="sm"
          @remove="removeUser(user)"
        />
      </div>
    </template>
    <span class="relative w-full inline-users-list">
      <UserSelect
        v-model="model"
        v-focus
        :initial-value="users"
        :url-params="selectUrlParams"
        :allow-entity-create="row?.attributes?.project_id && false /* TODO: Update project people form to load the project locally instead of setting to store*/"
        addEntityTrigger="projects/triggerProjectPeople"
        :addEntityParams="{ projectId: row?.attributes?.project_id }"
        :addEntityLabel="$t('Add people to project')"
        :placeholder="placeholder"
        :clearable="false"
        :multiple="true"
        inline
        class="user-select inline-select"
        @raw-change="onRawChange($event)"
      />
      <span
        v-if="model?.length"
        class="absolute left-1 top-1/2 -translate-y-1/2 text-sm text-gray-400"
      >
        {{ placeholder }}
      </span>
    </span>
  </BasePopoverInput>
  </div>
</template>
<script lang="ts">
// Components
import UserSelect from "@/components/selects/UserSelect.vue";
import UserLogo from '@/components/common/UserLogo.vue'

// Utils
import { PropType, defineComponent } from "vue";
import { ICellEditorParams } from "@ag-grid-community/core";
import i18n from "@/i18n";
import { get } from "lodash-es";
import { roleRanksEnum } from "@/modules/common/utils/isRoleGreaterOrEqual.js";
import { TableColumn } from "@/components/table/tableUtils"
import {
  UserPreviewActions
} from "@/modules/users/util/userUtils";

export default defineComponent({
  components: {
    UserSelect,
    UserLogo,
  },
  props: {
    params: {
      type: Object as PropType<ICellEditorParams<any>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: this.params.value,
      users: [] as any[],
      roleRanksEnum,
    }
  },
  computed: {
    row() {
      return this.params?.node?.data;
    },
    colDef() {
      return this.params.colDef
    },
    columnParams() {
      return (this.colDef as TableColumn)?.params
    },
    cellEditorParams() {
      return this.colDef?.cellEditorParams || {}
    },
    placeholder() {
      return this.cellEditorParams?.placeholder || i18n.t('Select users...')
    },
    sortedUsers() {
      return [...this.users].sort((x: any, y: any) => x.id - y.id)
    },
    selectUrlParams() {
      const urlParams = this.cellEditorParams?.getSelectUrlParams?.(this.row) || {}

      return urlParams
    },
    userActions() {
      if (this.$attrs.disabled) {
        return [UserPreviewActions.View]
      }

      return [UserPreviewActions.View, UserPreviewActions.Remove]
    }
  },
  methods: {
    removeUser(user: any) {
      this.model = this.model?.filter((id: any) => id != user.id)
      this.users = this.users?.filter((u: any) => u.id != user.id)
    },
    onRawChange(value: any) {
      this.users = value
      this.cellEditorParams?.onRawChange?.(this.row, value)
    },
    initUsers() {
      if (typeof this.columnParams?.getUsers === 'function') {
        this.users = this.columnParams.getUsers(this.row)
        return
      }

      this.users = get(this.row, this.colDef?.field as string, [])
    },
    isCancelBeforeStart() {
      return this.cellEditorParams?.isCancelBeforeStart?.(this.row) || false
    },
    getValue() {
      return this.model
    },
  },
  created() {
    this.initUsers()
  },
})
</script>
