<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-7xl relative"
    :key="taskId"
    @close="beforeClose"
    @closed="onClosed"
  >
    <div
      v-if="$useNewLayout"
      class="body-background rounded-t-lg"
      :class="{
        'h-12': !isBulkCreate,
      }"
    >
      <BaseFormHeader
        v-if="isBulkCreate"
        :isDialogForm="true"
        :title="$t('Add multiple tasks')"
        :subtitle="$t('Enter up to 100 task names. Add 1 task name per line. When you submit all tasks will be created.')"
      />
    </div>
    <div class="bg-white rounded-lg h-full">
      <TaskInlineDetails
        ref="taskDetails"
        :currentTask="task"
        :taskId="taskId"
        :isSubTask="isSubTask"
        :isDialog="true"
        :loading="loading"
        :redirectAfterSave="redirectAfterSave"
        :isBulkCreate="isBulkCreate"
        @is-valid-changed="isValidModel = $event"
        @close-dialog="onCloseButtonClicked"
        @save="$emit('save', $event)"
      />
    </div>
  </BaseDialogNew>
</template>
<script>
import TaskInlineDetails from "@/modules/tasks/components/TaskInlineDetails.vue";
import i18n from "@/i18n";

export default {
  name: 'TaskDialog',
  inheritAttrs: false,
  components: {
    TaskInlineDetails,
  },
  emits: ['save', 'close'],
  props: {
    task: {
      type: Object,
      default: () => null
    },
    taskId: {
      type: [String, Number],
      default: () => null
    },
    isSubTask: {
      type: Boolean,
      default: false
    },
    redirectAfterSave: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    isBulkCreate: Boolean,
  },
  data() {
    return {
      isValidModel: true
    }
  },
  computed: {
    isTaskCreate() {
      return !this.taskId && !this.task?.id
    },
    isTaskClosed() {
      return this.$store.getters['tasks/isTaskClosed'](this.task)
    }
  },
  methods: {
    onClosed() {
      if (!this.isTaskCreate && !this.isValidModel) {
        this.$error(i18n.t("Couldn't save task as invalid items weren't resolved."))
      }

      this.$store.commit('accounts/triggerCloseSpeechToText')

      this.$emit('close');
    },
    onCloseButtonClicked() {
      this.beforeClose()
      this.onClosed()
    },
    beforeClose() {
      if (this.isTaskClosed) {
        return
      }

      this.$refs.taskDetails?.triggerSave()
    }
  },
}
</script>
