import axios from "axios";
import i18n from "@/i18n.js";
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import { formModelToBody } from "@/modules/accounts/utils/formDataUtils";
import { get, set, orderBy, startCase } from "lodash-es";
import { removeFromListById } from "@/modules/common/utils/listUtils.js";
import { importEntryStatuses } from "@/modules/accounts/utils/importUtils"
import { AppSumoPlanName } from "@/modules/accounts/utils/subscriptionUtils"
import apiCache from "@/modules/common/utils/apiCache.js";
import { customBrandingKeys } from "@/modules/accounts/utils/accountBrandingUtils";
import { transformToRestifyArray } from "@/modules/common/utils/dataUtils";
import { getCustomFieldColumns } from "@/modules/accounts/utils/customFieldTableUtils"
import { columnBuilder } from '@/components/table/tableUtils'
import { syncEntityInArray, EntityChangeEvent } from "@/modules/common/utils/entityUtils";
import { Activities, trackActivity } from "@/modules/common/utils/trackingUtils";
import {
  PaymentProviders
} from "@/modules/payments/enum/paymentEnums.js";
import { defaultCompletionState } from "@/modules/accounts/composables/useGettingStarted";

export const SubscriptionStatuses = {
  Trial: 'trialing',
  Active: 'active',
  Canceled: 'canceled',
  Unpaid: 'unpaid',
  PastDue: 'past_due',
  Ended: 'ended',
}
const state = () => ({
  customFields: {
    data: [],
  },
  currentProjectCustomFields: {
    data: [],
  },
  paymentDetails: {
    data: [],
  },
  organizationSettings: {
    data: [],
  },
  postmarkDomains: {
    data: [],
  },
  starredPages: {
    data: [],
  },
  stats: {
    active_projects_count: 0,
    overdue_tasks_count: 0,
    starred_pages_count: 0,
    unread_comments_count: 0,
    time_entry_recorded_count: 0,
    unpaid_payments_count: 0
  },
  plans: [],
  intercomData: {},
  subscription: {},
  subscriptionFetched: false,
  tenantStats: {},
  tenantFeatures: [],
  statsLoading: false,
  setPlansLoading: false,
  organizationSettingsLoading: false,
  starredPagesLoading: false,
  customFieldsLoading: false,
  showDisabledCustomFields: true,
  importEntries: [],
  newImportState: {
    key: 0,
    import_type: '',
    import_status: '',
  },
  csvBoxKeys: {},
  exportEntries: [],
  appSumoCodes: [],
  addCustomFieldTrigger: 1,
  customBranding: {
    enable_branding: false,
    header_logo: '',
    favicon: '',
    login_page_logo: '',
    email_logo: '',
    background_color: '',
    background_image: '',
    action_button_color: '',
    action_button_hover_color: '',
    shadow: true,
    primary_color_tools: false,
    account_name: '',
    canUseCustomBranding: false
  },
  tempBranding: null,
  openStarredPagesTrigger: 1,
  publicApiKeys: [],
  upgradeAcccountTrigger: 1,
  globalSearchTrigger: 1,
  speechToTextConfig: {
    api_key_id: '',
    key: '',
    comment: '',
    scopes: [],
    tags: [],
    created: '',
    expiration_date: '',
  },
  closeSpeechToTextTrigger: 1,
  onboardingModel: {
    completed: false,
    projectId: null,
    projectName: '',
    projectDescription: '',
    tasksToAdd: {},
    inviteUsers: [],
  },
  gettingStartedState: defaultCompletionState,
  trialFeaturesState: [],
  dataImportTrigger: 1,
});

const mutations = {
  setCustomFields(state, value) {
    state.customFields = value;
  },
  addCustomField(state, customField) {
    syncEntityInArray(customField, state.customFields.data || [], EntityChangeEvent.Create)
  },
  updateCustomField(state, customField) {
    syncEntityInArray(customField, state.customFields.data || [])
  },
  deleteCustomField(state, customField) {
    syncEntityInArray(customField, state.customFields.data || [], EntityChangeEvent.Delete)
  },
  setCurrentProjectCustomFields(state, value) {
    state.currentProjectCustomFields = value;
  },
  setCustomFieldsLoading(state, value) {
    state.customFieldsLoading = value;
  },
  setPaymentDetails(state, value) {
    state.paymentDetails = value;
  },
  setStats(state, value) {
    state.stats = value
  },
  setStatsLoading(state, value) {
    state.statsLoading = value
  },
  setIntercomData(state, value) {
    state.intercomData = value
  },
  setSubscription(state, value) {
    state.subscription = value
    state.subscriptionFetched = true
  },
  setTenantStats(state, value) {
    state.tenantStats = value
  },
  setTenantFeatures(state, value) {
    state.tenantFeatures = value
  },
  setPlans(state, value) {
    state.plans = value
  },
  setPlansLoading(state, value) {
    state.plansLoading = value
  },
  setStarredPages(state, value) {
    state.starredPages = value
  },
  deleteStarredPage(state, id) {
    removeFromListById(state.starredPages.data, id)
  },
  setStarredPagesLoading(state, value) {
    state.starredPagesLoading = value
  },
  setOrganizationSettings(state, value) {
    state.organizationSettings = value
  },
  updateOrganizationSetting(state, setting) {
    const settingIdx = state.organizationSettings.data.findIndex(s => s.id == setting.id)
    if (settingIdx === -1) {
      return
    }

    const newSetting = {
      ...setting,
      attributes: {
        ...setting.attributes,
        id: Number(setting.id)
      },
      id: Number(setting.id)
    }

    state.organizationSettings.data[settingIdx] = newSetting
  },
  setOrganizationSettingsLoading(state, value) {
    state.organizationSettingsLoading = value
  },
  setPostmarkDomains(state, value) {
    state.postmarkDomains = value
  },
  setShowDisabledCustomFields(state, value) {
    state.showDisabledCustomFields = value
  },
  setImportEntries(state, data) {
    state.importEntries = data
  },
  setCsvBoxKeys(state, data) {
    state.csvBoxKeys = data
  },
  setExportEntries(state, data) {
    state.exportEntries = data
  },
  addExportEntry(state, exportEntry) {
    state.exportEntries = [exportEntry, ...state.exportEntries]
  },
  setAppSumoCodes(state, data) {
    state.appSumoCodes = data
  },
  incrementStats(state, { key, value }) {
    const oldValue = get(state.stats, key, 0)

    state.stats = {
      ...state.stats,
      [key]: oldValue + value
    }
  },
  triggerAddCustomField(state) {
    state.addCustomFieldTrigger++
  },
  setCustomBranding(state, brandingSettings = null) {
    const customBranding = {}
    const settings = brandingSettings || state.organizationSettings?.data || []

    customBrandingKeys.forEach(key => {
      const setting = settings.find(setting => setting?.attributes?.key === key)
      if (setting) {
        customBranding[key] = setting?.attributes?.value
      }
    })

    if (brandingSettings?.length) {
      customBranding.canUseCustomBranding = true
      customBranding.enable_branding = true
    } else {
      customBranding.canUseCustomBranding = state.subscription?.canUseCustomBranding
    }

    state.customBranding = customBranding
  },
  setTempBranding(state, value) {
    state.tempBranding = value
  },
  triggerOpenStarredPages(state) {
    state.openStarredPagesTrigger++
  },
  setPublicApiKeys(state, data) {
    state.publicApiKeys = data
  },
  triggerUpgradeAccount(state) {
    state.upgradeAcccountTrigger++
  },
  triggerGlobalSearch(state) {
    state.globalSearchTrigger++
  },
  setSpeechToTextConfig(state, data) {
    state.speechToTextConfig = data
  },
  triggerCloseSpeechToText(state) {
    state.closeSpeechToTextTrigger++
  },
  setOnboardingModel(state, data) {
    state.onboardingModel = data
  },
  setOnboardingProjectDescription(state, description) {
    set(state.onboardingModel, 'projectDescription', description)
  },
  setGettingStartedState(state, data) {
    state.gettingStartedState = data
  },
  setTrialFeaturesState(state, data) {
    state.trialFeaturesState = data
  },
  triggerDataImport(state) {
    state.dataImportTrigger++
  },
  setNewImportState(state, data) {
    state.newImportState = {
      key: state.newImportState.key + 1,
      ...data
    }
  }
};

const actions = {
  async getCustomFields({ commit, rootState, dispatch, rootGetters }, filters) {
    try {
      const project_id = filters?.global ? null : rootGetters.project_id
      if (filters?.shouldReset === true) {
        if (project_id) {
          commit("setCurrentProjectCustomFields", { data: [] });
        } else {
          commit("setCustomFields", { data: [] });
        }
      }

      filters = filters || { ...rootState.route?.query }

      commit("setCustomFieldsLoading", true);
      const customFields = await axios.get("/restify/available-custom-fields", {
        params: {
          ...filters,
          perPage: 200,
          project_id
        },
      });

      if (project_id) {
        commit("setCurrentProjectCustomFields", customFields);
      } else {
        commit("setCustomFields", customFields);
      }
    } finally {
      commit("setCustomFieldsLoading", false);
    }
  },
  async getCustomFieldById({ }, { id, returnEntity = false }) {
    try {
      const { data } = await axios.get(`/restify/available-custom-fields/${id}`)
      if (returnEntity) {
        return data
      }

      return data
    } catch (err) {
      console.error('Failed loading custom field', err)
    }
  },
  async createCustomField({ commit }, { data }) {
    try {
      const url = "/restify/available-custom-fields";
      const body = formModelToBody(data);
      const result = await axios.post(url, body);

      commit('addCustomField', result.data)

      trackActivity(Activities.CustomFieldCreated, result.data)
      return result.data
    } catch (err) {
      if (err.handled) {
        return;
      }
      throw err;
    }
  },
  async editCustomField({ commit }, { id, data }) {
    const url = `/restify/available-custom-fields/${id}`;
    const body = formModelToBody(data);
    const result = await axios.patch(url, body, {
      onerror: (error) => {
        throw error
      }
    });

    if (!result.data?.id) {
      return result.data
    }

    const updatedCustomField = result.data

    commit('updateCustomField', updatedCustomField)

    return updatedCustomField
  },
  async toggleCustomFieldDisabled({}, { customField, disable }) {
    const action = disable ? 'disable' : 'enable'
    const url = `/restify/available-custom-fields/${customField.id}/actions?action=${action}`;
    const result = await axios.post(url);

    customField.attributes.is_disabled = disable

    return result.data
  },
  async deleteCustomField({ commit }, { customField }) {
    const url = `/restify/available-custom-fields/${customField.id}`;
    await axios.delete(url);
    commit("deleteCustomField", customField)
  },
  async reorderCustomFields({}, { customFields }) {
    const orderedCustomFields = customFields.map(customField => ({
      id: customField.id,
      order: customField.attributes.order,
    }))
    await axios.post(`restify/available-custom-fields/bulk/update`, orderedCustomFields)
  },
  async getPaymentDetails({ commit }) {
    const paymentDetails = await axios.get(`/restify/payment-details`)
    commit('setPaymentDetails', paymentDetails)
  },
  async checkStripeTaxConfigured({}) {
    const { data } = await axios.post(`/restify/payments/actions?action=check-stripe-tax-configured`)
    return data?.status === 'active'
  },
  async createPaymentDetails({}, details) {
    return axios.post(`/restify/payment-details`, details);
  },
  async editPaymentDetails({}, details) {
    return axios.put(`/restify/payment-details/${details.id}`, details)
  },
  async getWebhooksDashboard({}, params = {}) {
    return axios.post(`/webhooks-dashboard`, {
      params,
    })
  },
  async deletePaymentDetails({}, paymentDetailsId) {
    return axios.delete(`/restify/payment-details/${paymentDetailsId}`)
  },
  async getStats({ commit }) {
    try {
      commit('setStatsLoading', true)
      const response = await axios.get('/stats')
      commit('setStats', response.stats)
    } finally {
      commit('setStatsLoading', false)
    }
  },
  async applyPromoCode({}, code) {
    const { data } = await axios.post(`/subscription/coupon/validate`, {
      coupon: code,
    })
    return get(data, '[0]', {})
  },
  async getConfiguration({ commit, rootCommit }) {
    const { data } = await axios.get(`/configuration`)

    if (!data) {
      return
    }

    const subscription = {
      ...(data.subscription || {}),
      ...(data.subscription?.meta || {}),
    }
    commit('setSubscription', subscription)
    commit('setTenantStats', data['tenant-stats']?.data)
    commit('setTenantFeatures', data.all_features)
    const organizationSettings = transformToRestifyArray(data.organization_settings)
    commit("setOrganizationSettings", {
      data: organizationSettings
    });
    commit('setCustomBranding')

    const filterPresets = transformToRestifyArray(data.filter_presets)
    commit("filters/setFilterPresets", filterPresets, { root: true })
    commit('filters/setFilterPresetsLoaded', true, { root: true })

    const defaultViews = transformToRestifyArray(data.default_views)
    commit("users/setDefaultViews", defaultViews, { root: true })

    const starredPages = transformToRestifyArray(data.starred_pages)
    commit("setStarredPages", {
      data: starredPages
    })

    const customFields = transformToRestifyArray(data.custom_fields, { type: 'available_custom_fields' })
    commit("setCustomFields", {
      data: customFields
    });

    const projectStatuses = transformToRestifyArray(data.project_statuses)
    commit("projects/setAllStatuses", {
      data: projectStatuses,
    }, { root: true })

    const taskStatuses = transformToRestifyArray(data.task_statuses)
    commit("tasks/setAllStatuses", {
      data: taskStatuses,
    }, { root: true })

    commit('auth/setCurrentTenant', data.tenant_details, { root: true })

    const templateGallery = data.template_gallery
    const templateCategories = transformToRestifyArray(templateGallery?.categories || [], { type: 'categories' })
    const galleryTemplates = transformToRestifyArray(templateGallery?.templates || [], {
      type: 'templates',
      getRelationships: (template) => {
        const categories = templateCategories.filter(category => (template.categories || []).includes(category.id))

        return {
          categories
        }
      }
    })

    commit('templateGallery/setCategories', templateCategories, { root: true })
    commit('templateGallery/setTemplates', galleryTemplates, { root: true })

    commit('accounts/setGettingStartedState', data.onboarding_steps, { root: true })
    commit('accounts/setTrialFeaturesState', data.trial_features, { root: true })

    commit('setStats', data.stats)
    commit('setIntercomData', data.intercom_data)
  },
  async getSubscription({ commit, state }, { forceFetch } = {}) {
    if (!forceFetch && state.subscription.name) {
      return state.subscription
    }

    const response = await axios.get('/restify/subscriptions')
    const subscription = get(response, 'data[0].attributes', {})
    const subscriptionId = get(response, 'data[0].id', '')

    const currentSubscription = state.subscription || {}
    const account_bonuses = subscription?.meta?.account_bonuses || currentSubscription?.account_bonuses || []
    // TODO: check that still works as expected after injecting new account_permissions
    const account_permissions = subscription?.meta?.account_permissions || currentSubscription?.account_permissions || []

    const extraData = {
      account_permissions,
      account_bonuses,
      canUseCustomBranding: subscription.meta.canUseCustomBranding,
      maxActiveProjects: subscription.meta.maxActiveProjects,
      currentActiveProjectsCount: subscription.meta.currentActiveProjectsCount
    }

    commit('setSubscription', {
      ...subscription,
      ...extraData,
      id: subscriptionId,
    })

    return response.data
  },
  async syncSubscriptionStats({ state, commit }, { limitType = '' }) {
    const maxProjects = state.tenantStats?.projects?.available || 0

    // No limit on active projects - no need to sync
    if (limitType === 'activeProjects' && !maxProjects) {
      return
    }

    const { data } = await axios.get('/tenant-stats')

    commit('setTenantStats', data)
  },
  async getUpcomingPaymentInfo({}, { plan_id, quantity }) {
    const { data } = await axios.get(`/upcoming-payment-info/${plan_id}`, {
      params: {
        quantity
      }
    })

    return data
  },
  async getCheckoutSession({}, { plan, data, params }) {
    return await axios.post(`/checkout-session/${plan.id}`, data, {
      params
    })
  },
  async updateSubscription({ dispatch }, data) {
    await axios.post(`/subscription/${data.subscription_id}/update`, data)

    dispatch('auth/getProfile', null, { root: true })
  },
  async getStripeCustomerPortal({}, data = {}) {
    return axios.post('/customer-portal', data)
  },
  async getPlans({ commit }) {
    try {
      commit('setPlansLoading', true)
      const response = await axios.get('/plans')
      commit('setPlans', response.data)
    } finally {
      commit('setPlansLoading', false)
    }
  },
  async getStarredPages({ commit }) {
    try {
      commit('setStarredPagesLoading', true)
      const pages = await apiCache.getRequest('/restify/starred-pages', {
        params: {
          perPage: 100,
        }
      })
      commit('setStarredPages', pages)
    } finally {
      commit('setStarredPagesLoading', false)
    }
  },
  async reorderStarredPages({}, pages) {
    const orderedPages = pages.map((page, index) => {
      const order = index + 1
      page.attributes.order = order
      return {
        order,
        id: +page.id,
      }
    })
    await axios.post(`restify/starred-pages/bulk/update`, orderedPages)
  },
  async createStarredPage({ dispatch, commit }, data) {
    await axios.post('/restify/starred-pages', data)
    await dispatch('getStarredPages')
    commit('incrementStats', {
      key: 'starred_pages_count',
      value: 1
    })
    trackActivity(Activities.PageStarred, data)
  },
  async updateStarredPage({ dispatch }, data) {
    await axios.put(`/restify/starred-pages/${data.id}`, data)
    await dispatch('getStarredPages')
  },
  async deleteStarredPageByLink({ state, dispatch }, pageLink) {
    const page = state.starredPages.data.find(page => page?.attributes?.link === pageLink)
    if (!page) {
      return
    }
    dispatch('deleteStarredPage', page.id)
  },
  async deleteStarredPage({ state, dispatch, commit }, id) {
    await axios.delete(`/restify/starred-pages/${id}`)
    commit('deleteStarredPage', id)
    commit('incrementStats', {
      key: 'starred_pages_count',
      value: -1
    })
  },
  async getOrganizationSettings({ commit }) {
    try {
      commit("setOrganizationSettingsLoading", true);
      const organizationSettings = await axios.get("/restify/organization-settings", {
        params: {
          perPage: 100,
        },
      });
      commit("setOrganizationSettings", organizationSettings);
      commit('setCustomBranding')
    } finally {
      commit("setOrganizationSettingsLoading", false);
    }
  },

  async updateOrganizationSetting({ dispatch }, { setting, data /* { key, value } */ }) {
    try {
      await axios.post(`/restify/organization-settings/${setting.id}`, data)
      dispatch('getOrganizationSettings')
    }
    catch (err) {
      if (err.handled) {
        return
      }

      console.error('Failed updating organization setting', err)
    }
  },

  async getPostmarkDomains({ commit }) {
    try {
      const postmarkDomains = await axios.get("/restify/postmark-domains", {
        params: {
          perPage: 100,
        },
      });
      commit("setPostmarkDomains", postmarkDomains);
    } catch (err) {
      console.error('Failed loading custom email domains', err)
    }
  },
  async verifyPostmarkDkim({}, { domainId }) {
    const response = await axios.post('/restify/postmark-domains/actions?action=verify-dkim', {
      domain_id: domainId
    })

    return response?.data;
  },
  async verifyPostmarkReturnPath({}, { domainId }) {
    const response = await axios.post('/restify/postmark-domains/actions?action=verify-return-path', {
      domain_id: domainId
    })

    return response.data;
  },

  async deletePostmarkDomain({}, { domainId }) {
    return await axios.post(`/restify/postmark-domains/actions?action=delete-domain`, {
      domain_id: domainId
    })
  },

  async getV1OwnedAccounts({}, data) {
    return axios.post(`/owned-accounts`, data)
  },
  async importV1Data({}, account) {
    return axios.post(`/import-data`, {
      account_id: account.id,
      domain: account.domain,
    })
  },
  async startImport({}, importRequest) {
    const {
      import_type, // groups | people | projects | tasks
      data,
      options
    } = importRequest

    const response = await axios.post(`/import-new/${import_type}`, {
      data,
      options
    })

    return response
  },
  async getImportEntries({ commit }) {
    const { data } = await axios.get(`/restify/import-entries`, {
      params: {
        // origin: 'csvbox',
        perPage: 100
      }
    })
    commit('setImportEntries', data)
    return data
  },
  async deleteImportedData({}, { importEntry }) {
    const { data } = await axios.post(`/restify/import-entries/${importEntry.id}/actions?action=delete-csvbox-data`);

    return data;
  },
  async getCsvBoxKeys({ commit }) {
    const { data } = await axios.get('/csvbox-keys')

    commit('setCsvBoxKeys', data)
  },
  async triggerExport({ commit }, { data }) {
    const result = await axios.post(`/restify/export-entries`, data)

    commit('addExportEntry', result.data)

    return result.data
  },
  async getExportEntries({ commit }) {
    const { data } = await axios.get(`/restify/export-entries`, {
      params: {
        perPage: 100,
      }
    })

    commit('setExportEntries', data)
    return data
  },
  async getAppSumoCodes({ commit }) {
    const { data } = await axios.get('/restify/appsumo-codes')

    commit('setAppSumoCodes', data)
  },
  async submitAppSumoCodes({}, codes) {
    return await axios.post('/appsumo-codes/stack', {
      codes
    })
  },
  async getIntercomModel({}) {
    const { data } = await axios.get('/intercom')

    return data
  },
  async getPublicApiKeys({ commit }) {
    const { data } = await axios.get('/restify/api-keys')

    commit('setPublicApiKeys', data)
  },
  async generatePublicApiKey({}) {
    const { data } = await axios.post('/restify/api-keys')

    return data
  },
  async deletePublicApiKey({}, { apiKey }) {
    return await axios.post(`/restify/api-keys/${apiKey.id}/actions?action=invalidate-api-key`)
  },
  // Speech to text
  async getSpeechToTextConfig({ commit }) {
    const config = await axios.post(`/stt/key`)

    commit('setSpeechToTextConfig', config)
  },
  async deleteSpeechToTextToken({ state, commit }) {
    const api_key_id = state.speechToTextConfig.api_key_id
    if (!api_key_id) {
      return
    }

    await axios.delete(`/stt/key?key_id=${api_key_id}`)
    commit('setSpeechToTextConfig', {})
  },
  // Google Picker
  async getGooglePickerAccessToken({ }) {
    const body = {
      scopes: ['https://www.googleapis.com/auth/drive.file'],
      userLocation: window.location.href,
      userSetting: 'google_picker_token'
    }

    const { data } = await axios.post(`/get-google-token`, body)

    if (data.access_token) {
      return data.access_token
    }

    if (data.redirect_url) {
      window.location.href = data.redirect_url
    }
  },
  // Google calendar
  async getGoogleCalendars({}) {
    return await axios.post(`/calendar/list`, {
      provider: 'google',
      userLocation: window.location.href,
    })
  },
  async saveGoogleCalendar({}, { calendarId }) {
    return await axios.post(`/calendar/save`, {
      provider: 'google',
      calendarId,
    })
  },
  async removeGoogleCalendar({}) {
    return await axios.post(`/calendar/remove`, {
      provider: 'google'
    })
  },
  // Outlook calendar
  async getOutlookCalendars({}) {
    return await axios.post(`/calendar/list`, {
      provider: 'microsoft',
      userLocation: window.location.href,
    })
  },
  async saveOutlookCalendar({}, { calendarId }) {
    return await axios.post(`/calendar/save`, {
      provider: 'microsoft',
      calendarId,
    })
  },
  async removeOutlookCalendar({}) {
    return await axios.post(`/calendar/remove`, {
      provider: 'microsoft'
    })
  },
  // Getting started
  async getGettingStartedCompletionState({ commit }) {
    const { data } = await axios.get('/restify/tenants/getters/getting-started-steps')
    commit('setGettingStartedState', data)
  },
  async setGettingStartedStepCompleted({ commit }, { step, completed }) {
    const { data } = await axios.post('/restify/tenants/actions?action=set-getting-started-step-completed', {
      step,
      value: completed
    })

    commit('setGettingStartedState', data)
  },
  // Trial features
  async getTrialFeaturesState({ commit }) {
    const { data } = await axios.get('/restify/tenants/getters/trial-features')

    commit('setTrialFeaturesState', data)
  },
  async enableTrialFeatures({ rootState, commit, dispatch }, { features }) {
    const { data } = await axios.post('/restify/tenants/actions?action=trial-features-enable', {
      features
    })

    const currentUser = rootState.auth.user

    trackActivity(Activities.StartedFeatureTrial, {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      account_name: currentUser.account_name,
      email: currentUser.email,
      features
    })

    commit('setTrialFeaturesState', data)

    await dispatch('getSubscription', { forceFetch: true })
    dispatch('syncSubscriptionStats', { })
  }
};

const getters = {
  globalCustomFields: (state) => {
    const globalScopedFields = (state.customFields?.data || []).filter(customField => !customField.attributes?.project_id)
    return orderBy(globalScopedFields, 'attributes.order')
  },
  projectScopedCustomFields: (state) => {
    const projectScopedFields = (state.customFields?.data || []).filter(customField => !!customField.attributes?.project_id)
    return orderBy(projectScopedFields, 'attributes.order')
  },
  scopedCustomFieldsByProject: (state, getters) => (project_id) => {
    return (getters.projectScopedCustomFields || []).filter(
      (customField) => customField.attributes?.project_id == project_id
    );
  },
  // Used in entity type forms & entity type tables
  globalEntityTypeCustomFields: (state, getters) => (entity_type) => {
    return (getters.globalCustomFields || []).filter(
      (customField) => customField.attributes?.entity_type === entity_type
    );
  },
  currentProjectCustomFields: (state, getters, rootState, rootGetters) => {
    if (!rootGetters.project_id) {
      return []
    }

    return getters.scopedCustomFieldsByProject(rootGetters.project_id)
  },
  currentProjectEntityTypeCustomFields: (state, getters) => (entity_type) => {
    return (getters.currentProjectCustomFields || []).filter(
      (customField) => customField.attributes?.entity_type === entity_type
    );
  },
  tableColumnCustomFields: (state, getters) => (entity_type) => {
    const global = getters.globalEntityTypeCustomFields(entity_type);
    const projectScoped = getters.currentProjectEntityTypeCustomFields(entity_type)

    return [...global, ...projectScoped].filter(x => !x.attributes.is_disabled)
  },
  entityFormCustomFields: (state, getters) => (entity_type, project_id) => {
    const global = getters.globalEntityTypeCustomFields(entity_type);
    const projectScoped = getters.scopedCustomFieldsByProject(project_id).filter(x => x.attributes.entity_type === entity_type)

    return [...global, ...projectScoped].filter(x => !x.attributes.is_disabled)
  },
  // Used in custom fields tables (account or project level)
  customFieldActiveColumns: (state) => {
    const { mainColumns, extraColumns } = getCustomFieldColumns()
    let columns = [...mainColumns]

    columnBuilder.addCustomColumns(columns, extraColumns)

    return columns
  },
  customFieldTableColumns: (state, getters) => {
    return getters.customFieldActiveColumns.filter(c => c.visibleInTable !== false)
  },
  orderedCustomFields: (state, getters, rootState, rootGetters) => (filterDisabled = true) => {
    const customFields = !rootGetters.project_id
      ? getters.globalCustomFields
      : getters.scopedCustomFieldsByProject(rootGetters.project_id)

    if (filterDisabled && !state.showDisabledCustomFields) {
      return customFields.filter(x => !x.attributes.is_disabled)
    }

    return customFields
  },
  stripePaymentDetails: state => {
    return state.paymentDetails?.data?.find(p => p.attributes?.provider === PaymentProviders.Stripe)
  },
  paypalPaymentDetails: state => {
    return state.paymentDetails?.data?.find(p => p.attributes?.provider === PaymentProviders.Paypal)
  },
  isPaypalEnabled: (state, getters) => {
    return getters.paypalPaymentDetails?.attributes?.enabled && getters.paypalPaymentDetails?.attributes?.merchant_id
  },
  getStripeKey: (state, getters) => {
    return getters.stripePaymentDetails?.attributes?.public_key
  },
  isStripeEnabled: (state, getters) => {
    return !!getters.getStripeKey
  },
  hasPaymentMethodsConfigured: (state, getters) => {
    return getters.isStripeEnabled || getters.isPaypalEnabled
  },
  isPageStarred: state => pageLink => {
    return state.starredPages.data?.find(page => page?.attributes?.link === pageLink)
  },
  getSettingByKey: (state) => (key) => {
    return state.organizationSettings.data?.find(setting => setting?.attributes?.key === key)
  },
  isTrialPeriod: (state) => {
    return state.subscription?.stripe_status === SubscriptionStatuses.Trial
  },
  trialDaysRemaining: (state, getters) => {
    if (getters.isTrialPeriod) {
      let trialEndsAt = parseISO(state.subscription.trial_ends_at)
      return differenceInDays(trialEndsAt, new Date())
    }
    return state.subscription?.stripe_status === SubscriptionStatuses.Trial
  },
  isValidSubscription: (state, getters) => {
    const status = state.subscription?.stripe_status
    if (!status && !state.subscriptionFetched) {
      return true
    }
    if (!status && state.subscriptionFetched) {
      return false
    }
    const trialDays = getters.trialDaysRemaining
    return status === SubscriptionStatuses.Active || status === SubscriptionStatuses.Trial && trialDays > 0
  },
  isAppSumoSubscription: (state) => {
    const subscriptionPlan = state.subscription?.plan

    return subscriptionPlan?.name === AppSumoPlanName
  },
  subscriptionInvalidMessage: (state, getters) => {
    if (getters.isValidSubscription) {
      return null
    }
    const status = startCase(state.subscription?.stripe_status)
    if (!status) {
      return i18n.t(`Seems like you don't have any active subscriptions!`)
    }
    return i18n.t(`Seems like this account has a subscription which is <b>${status}</b>`)
  },
  pendingImport: state => {
    return state.importEntries.find(i => i?.attributes?.status === importEntryStatuses.Pending)?.attributes
  },
  latestImport: state => {
    return get(state.importEntries, '[0]')
  },
  latestImportStatus: (state, getters) => {
    return getters.latestImport?.attributes?.status || ''
  },
  canImportV1Data: (state, getters) => {
    const statuses = [
      importEntryStatuses.Deleted
    ]

    return !getters.latestImport || statuses.includes(getters.latestImportStatus)
  },
  isV1ImportRunning: (state, getters) => {
    return importEntryStatuses.Pending === getters.latestImportStatus
  },
  canDeleteV1Data: (state, getters) => {
    const statuses = [
      importEntryStatuses.Successful,
      importEntryStatuses.Failed,
      importEntryStatuses.FailedDelete
    ]

    return statuses.includes(getters.latestImportStatus)
  },
  isV1DeleteRunning: (state, getters) => {
    return importEntryStatuses.PendingDelete === getters.latestImportStatus
  },
  isCustomBrandingEnabled: (state, getters) => {
    return state.customBranding.canUseCustomBranding && state.customBranding.enable_branding
  },
  publicApiKey: (state) => {
    return state.publicApiKeys?.[0]
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
