import axios from 'axios'
import { get, set, debounce } from 'lodash-es'
import store from '@/store/index.js'
import i18n from '@/i18n.js'
import { error } from "@/components/common/NotificationPlugin/index.js";
import { getCustomFieldValuesObject } from '@/modules/accounts/utils/modelUtils';
import { taskDateTypes } from "@/modules/tasks/utils/modelUtils.js";

export const KANBAN_DRAG_DELAY_MOBILE = 200
export const KANBAN_DRAG_DELAY_ACTIVE_MAX_WIDTH = 1024

export function getEntityUpdateModel(entity, prop, newValue, data) {
  // Handle custom fields
  if (prop.startsWith('attributes.custom_fields')) {
    const keySplit = prop.split('.')
    const key = prop.endsWith('.label')
      ? keySplit?.[keySplit?.length - 2]
      : keySplit?.[keySplit?.length - 1]

    if (typeof newValue === 'string') {
      try {
        newValue = JSON.parse(newValue)
      } catch (err) {
        console.warn('Error parsing custom fields string in kanban utils')
      }
    }

    if (typeof newValue === 'object') {
      newValue = {
        value: newValue.value,
        label: newValue.label,
        order: newValue.order
      }
    }

    const previousCustomFields = getCustomFieldValuesObject(entity.attributes.custom_fields)
    
    let newCustomFields = {
      ...previousCustomFields,
      [key]: newValue
    }

    if (!newValue || newValue?.value === null) {
      newCustomFields[key] = null
    }

    // We previously converted the custom fields to string which caused issues. It should be preserved as an object.
    return {
      custom_fields: Object.keys(newCustomFields).length ? newCustomFields : null
    }
  }

  // Handle entity props
  switch (prop) {
    case 'attributes.date':
      if (!newValue || newValue === 'null') {
        return {
          date_type: taskDateTypes.NO_DATE
        }
      }

      const dateValue = new Date(newValue)

      switch (entity.attributes.date_type) {
        case taskDateTypes.NO_DATE:
          return {
            date_type: taskDateTypes.SINGLE_DATE,
            date: dateValue
          }

        case taskDateTypes.SINGLE_DATE:
          return {
            date: dateValue
          }

        case taskDateTypes.DATE_RANGE:
          return {
            end_date: dateValue
          }

        case taskDateTypes.RECURRING:
          error(i18n.t('Cannot change date for a recurring task using drag & drop. Please change its recurrence instead'))
          return;
      }
      break;
    case 'relationships.status.attributes.name':
      return {
        status_id: get(data, '[0].attributes.status_id')
      }
    default:
      break;
  }
}

export async function updateKanbanEntity(entity, entity_type, option, selectedField) {
  const data = [
    {
      attributes: {
        [option.prop]: option.id
      },
    }
  ]

  let newValue = option
  let prop = selectedField.groupByProp

  if (newValue.createParams?.custom_fields) {
    newValue = newValue.createParams.custom_fields[newValue?.prop]
  }

  const model = getEntityUpdateModel(entity, prop, newValue, data)

  if (!model || !Object.keys(model).length) {
    return
  }
  
  const updatedAttributes = {
    ...model
  }
  
  if (model.custom_fields) {
    updatedAttributes.custom_fields = getCustomFieldValuesObject(model.custom_fields)
  }

  entity.attributes = {
    ...entity.attributes,
    ...updatedAttributes
  }

  if (model.status_id) {
    if (entity_type === 'project') {
      await store.dispatch('projects/changeProjectStatus', {
        project: entity,
        status_id: model.status_id,
      })
    }
    else {
      await store.dispatch('tasks/changeTaskStatus', {
        task: entity,
        status_id: model.status_id,
      })
    }

    return
  }

  try {

    if (entity_type === 'project') {
      await store.dispatch('projects/updateProject', {
        projectId: entity.id,
        model
      })
    }
    else {
      await store.dispatch('tasks/updateTask', {
        taskId: entity.id,
        model,
        syncInArray: false
      })
    }
  }
  catch (err) {
    error(i18n.t('Could not update project..'))
  }

}

export async function updateKanbanEntititesOrder(entity_type, entities, groupIndex) {
  const newEntityOrders = []

  entities.forEach((entity, index) => {
    entity.attributes.order = (index + 1) * (groupIndex + 1)

    newEntityOrders.push({
      id: entity.id,
      order: entity.attributes.order
    })
  })

  return await axios.post(`restify/${entity_type}/bulk/update`, newEntityOrders)
}

export function setColumnHeight(element) {
  const kanbanCol = element.querySelector('.pdc-pk-col')

  if (!kanbanCol) {
    return
  }

  const appFooter = document.querySelector('.app-footer')

  const footerHeight = appFooter
    ? appFooter.getBoundingClientRect()?.height || 0
    : 0

  const top = kanbanCol.getBoundingClientRect()?.top + footerHeight + 20;
  kanbanCol.style.height = `calc(100vh - ${top}px)`
}

function setKanbanHeight() {
  const kanban = document.querySelector('.kanban-view')

  if (!kanban) {
    return
  }

  const appFooter = document.querySelector('.app-footer')

  const footerHeight = appFooter
    ? appFooter.getBoundingClientRect()?.height || 0
    : 0

  const top = kanban.getBoundingClientRect()?.top + footerHeight + 20;
  kanban.style.height = `calc(100vh - ${top}px)`
}

export const setKanbanHeightDebounced = debounce(setKanbanHeight, 50)

export function getColumnOptionValuePath(option) {
  if (!option.value && option.type === 'custom_field') {
    return `custom_fields.${option.prop}`
  }

  let valuePath = Object.keys(option.createParams)[0]
  let prop = option.createParams[valuePath]

  while (typeof prop === 'object') {
    let newKey = Object.keys(prop)[0] 
    valuePath += `.${newKey}`
    prop = prop[newKey]
  }

  return valuePath
}

function injectCustomField(template, groupByProp, fromEntity) {
  let cf_attribute = groupByProp
  if (cf_attribute.endsWith('.value')) {
    cf_attribute = cf_attribute.replace('.value', '')
  }

  const cf_key = cf_attribute.match(/cf_(\d+)/g)

  const cf_value = get(fromEntity, cf_attribute)

  if (!cf_value) {
    return
  }

  set(template, 'attributes.custom_fields', {
    ...(template.attributes.custom_fields || {}),
    [cf_key]: cf_value
  })
}

export function getTaskTemplate(template, groupBy, fromTask) {
  if (!groupBy?.prop) {
    return template
  }

  const groupByProp = groupBy.prop

  try {
    if (groupByProp === 'attributes.project_id') {
      set(template, groupByProp, get(fromTask, groupByProp))
      set(template, 'relationships.project', get(fromTask, 'relationships.project'))
    }

    else if (groupByProp === 'attributes.allocated_ids') {
      set(template, 'attributes.project_id', get(fromTask, 'attributes.project_id'))
      set(template, 'relationships.project', get(fromTask, 'relationships.project'))
      set(template, groupByProp, get(fromTask, groupByProp))
      set(template, 'relationships.allocations', get(fromTask, 'relationships.allocations'))
    }

    else if (groupByProp === 'attributes.group_ids') {
      set(template, 'attributes.project_id', get(fromTask, 'attributes.project_id'))
      set(template, 'relationships.project', get(fromTask, 'relationships.project'))
      set(template, groupByProp, get(fromTask, groupByProp))
      set(template, 'relationships.groups', get(fromTask, 'relationships.groups'))
    }
    else if (groupByProp === 'attributes.status_id') {
      set(template, 'attributes.status_id', get(fromTask, 'attributes.status_id'))
    }
    else if (groupByProp === 'attributes.sortable_date') {
      set(template, 'attributes.date_type', get(fromTask, 'attributes.date_type'))
      set(template, 'attributes.date', get(fromTask, 'attributes.date'))
      set(template, 'attributes.start_date', get(fromTask, 'attributes.start_date'))
      set(template, 'attributes.end_date', get(fromTask, 'attributes.end_date'))
    }
    else if (groupByProp.includes('custom_fields')) {
      injectCustomField(template, groupByProp, fromTask)
    }
  }
  catch(err) {
    console.warn('Could not bind group value to task template', {
      err,
      groupBy,
      template
    })
  }

  return template
}

export function getProjectTemplate(template, groupBy, fromProject) {
  if (!groupBy?.prop) {
    return template
  }

  const groupByProp = groupBy.prop

  try {
    if (groupByProp === 'attributes.group_ids') {
      set(template, groupByProp, get(fromProject, groupByProp))
      set(template, 'relationships.groups', get(fromProject, 'relationships.groups'))
    }
    else if (groupByProp === 'attributes.status_id') {
      set(template, 'attributes.status_id', get(fromProject, 'attributes.status_id'))
    }
    else if (groupByProp.includes('custom_fields')) {
      injectCustomField(template, groupByProp, fromProject)
    }
  }
  catch(err) {
    console.warn('Could not bind group value to project template', {
      err,
      groupBy,
      template
    })
  }
}
