<template>
  <BaseNoDataSection
    :title="$t('No Time Allocations Found')"
    iconClass="fa-stopwatch"
    :canCreate="!isCurrentProjectClosed && can($actions.EDIT_ALLOCATED_TIME_ON_PROJECTS)"
  >
    <template #can-create-section>
      <div>{{ $t('Keep track of allocated & actual time spent on projects.') }}</div>
      <div>{{ $t('Filter, group & sort. Then view in a chart to analyze.') }}</div>
      <div
        class="hover:text-primary-500 cursor-pointer"
        @click.stop="$store.commit('time/triggerAddAllocatedTime')"
      >
        {{ $t('Create a new time allocation') }}
        <i class="fa-solid fa-arrow-right" aria-hidden="true" />
      </div>
    </template>
  </BaseNoDataSection>
</template>
<script>
export default {
  computed: {
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
}
</script>  
