<template>
  <div class="discussion-comment-container">
    <div class="flex text-gray-500 text-sm relative">
      <div class="flex">
        <div class="shrink-0 mr-4 w-10">
          <i class="fa-solid fa-circle-dashed ai-icon text-3xl w-10 text-center" />
        </div>
        <div class="flex-1">
          <div class="flex flex-col space-x-0 sm:space-x-3 sm:flex-row">
            <div class="inline font-bold text-gray-700 text-base -mt-1">
              {{ $t('AI Assistant') }}
            </div>
            <div class="inline font-bold text-xs text-gray-400">
              <span>{{ formattedDate }}</span>
            </div>
          </div>
          <div
            contenteditable="false"
            translate="no"
            tabindex="0"
            class="prose max-w-none leading-6 prose-p:my-4 prose-ul:my-4 prose-ol:my-4 prose-li:my-0 prose-p:mt-2 prose-p:mb-0 prose-ul:mt-2 prose-ul:mb-0 prose-ol:mt-2 prose-ol:mb-0 focus:outline-none text-sm rounded-md text-gray-500"
          >
            <AiChatActionResult
              v-if="actionResult"
              :actionResult="actionResult"
              :showStartNewAction="false"
            />
            <p v-else>
              <span ref="messageContainer" />
              <span v-if="message.link">
                <router-link :to="message.link.url">{{ $t(message.link.entity) }}</router-link>
              </span>
            </p>
          </div>
          <div
            v-if="!actionResult"
            class="space-x-2 mt-2"
          >
            <BaseTooltip
              :content="$t('Copy to clipboard')"
            >
              <BaseButton
                variant="white"
                class="ai-button shadow-none"
                @click="onCopyToClipboard"
              >
                <i class="fa-regular fa-copy mr-1" aria-hidden="true" />
                {{ $t('Copy') }}
              </BaseButton>
            </BaseTooltip>
            <BaseButton
              variant="white"
              class="ai-button shadow-none"
              @click="onAddTo"
            >
              <i class="fa-solid fa-plus mr-1" aria-hidden="true" />
              {{ $t('Add To') }}
            </BaseButton>
            <BaseButton
              variant="white"
              class="ai-button shadow-none"
              @click="onSendToActionMode"
            >
              <i class="fa-solid fa-arrow-right mr-1" aria-hidden="true" />
              {{ $t('Send to Action Mode') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <AiAddToDialog
      v-if="showAddToDialog"
      v-model="showAddToDialog"
      :messageHtml="addToMessageHtml"
      @close="showAddToDialog = false"
      @cancel="showAddToDialog = false"
    />
  </div>
</template>

<script setup lang="ts">
// Libs
import i18n from "@/i18n";
import {
  computed,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";

// Helpers
import { formatDate } from "@/modules/common/utils/dateUtils";
import { ActionResult, ChatMessage } from "@/modules/ai/types/aiTypes";
import { copyToClipboard } from "@/plugins/copyToClipboard"
import { 
  DropdownConfig,
  getDropdownHtml
} from "@/modules/common/utils/htmlUtils";
import showdown from 'showdown'
import { Activities, trackPostHog } from "@/modules/common/utils/trackingUtils";

// Components
import AiAddToDialog from "@/modules/ai/components/AiAddToDialog.vue";
import AiChatActionResult from "@/modules/ai/components/AiChatActionResult.vue";

const props = defineProps({
  message: {
    type: Object as PropType<ChatMessage>,
    default: () => ({  }),
    required: true
  }
})

const showAddToDialog = ref(false)

const emit = defineEmits<{
  (e: 'send-to-action-mode', messageHtml: string): void 
}>()

const actionResult = ref<ActionResult | null>(props.message?.data?.actionResult || null)

const messageText = computed(() => {
  return props.message.message || ''
})

const htmlConverter = new showdown.Converter({
  tables: true,
})

const messageHtml = computed(() => {
  return htmlConverter.makeHtml(messageText.value)
})

const addToMessageHtml = ref('')

const formattedDate = computed(() => {
  return formatDate(props.message.date, null, true, true)
})

function onCopyToClipboard() {
  copyToClipboard(messageText.value, i18n.t('Message copied to clipboard'))
  trackPostHog(Activities.AiCreateCopyToClipboard)
}

function onAddTo() {
  addToMessageHtml.value = messageHtml.value
  showAddToDialog.value = true
}

function onSendToActionMode() {
  emit('send-to-action-mode', messageHtml.value)
}

const messageContainer = ref<HTMLElement | null>(null)

function preprocessMessage() {
  const htmlElement = document.createElement('div')
  htmlElement.innerHTML = messageHtml.value

  if (props.message.isStreaming) {
    if (messageContainer.value?.hasChildNodes()) {
      messageContainer.value?.removeChild(messageContainer.value?.firstChild as Node)
    }
    messageContainer.value?.appendChild(htmlElement)
    return
  }

  const listItems = htmlElement.querySelectorAll('li')

  listItems.forEach((listItem) => {
    const liHtml = listItem.innerHTML
    
    const dropdownConfig: DropdownConfig = {
      dropdownClass: 'ai-li-dropdown block opacity-0 group-hover:opacity-100',
      btnClass: 'ai-li-button rounded-md',
      options: [
        {
          text: i18n.t('Copy'),
          icon: 'fa-regular fa-copy',
          onClick() {
            copyToClipboard(liHtml, i18n.t('Message copied to clipboard'))
            trackPostHog(Activities.AiCreateCopyToClipboard)
          }
        },
        {
          text: i18n.t('Add To'),
          icon: 'fa-solid fa-plus',
          onClick() {
            addToMessageHtml.value = liHtml
            showAddToDialog.value = true
          }
        },
        {
          text: i18n.t('Send to Action Mode'),
          icon: 'fa-solid fa-arrow-right',
          onClick() {
            emit('send-to-action-mode', liHtml)
          }
        }
      ]
    }

    const dropdown = getDropdownHtml(dropdownConfig)
    listItem.classList.add('group')
    listItem.classList.add('relative')
    listItem.append(dropdown)
  })

  if (messageContainer.value?.hasChildNodes()) {
    messageContainer.value?.removeChild(messageContainer.value?.firstChild as Node)
  }
  messageContainer.value?.appendChild(htmlElement)
}

onMounted(() => {
  preprocessMessage()
})

watch(() => [
  props.message.isStreaming,
  props.message.message
], () => {
  preprocessMessage()
}, {
  immediate: true
})
</script>
<style lang="scss">
button.ai-button:hover {
  background: -webkit-linear-gradient(60deg, #8B48F9, #E827A1, #FFA102);
  color: #fff;
  border: 1px solid #fff;
}

button.ai-li-button {
  @apply inline-block bg-white text-gray-900 leading-4;
}

.inline-dropdown.ai-li-dropdown {
  @apply absolute -left-4 top-0 bg-white;
}
</style>
