import i18n from "@/i18n"
import store from "@/store/index.js";
import { orderBy } from "lodash-es";
import { customFieldAppliesToOptions } from "@/modules/accounts/utils/modelUtils"
import {
  CREATE_CUSTOM_FIELDS,
  CREATE_PROJECTS,
  CREATE_PROJECT_TEMPLATES,
  EDIT_PROJECTS,
  EDIT_PROJECT_TEMPLATES,
} from "@/modules/common/enum/actionsEnum";
import { isProjectClosed } from "@/modules/projects/utils/projectHelpers";
import { ColumnTypes, HeaderTypes } from '@/components/table/cells/tableCellComponents'
import { EditableCellTypes } from "@/components/table/cells/tableFrameworkComponents";
import { getDefaultOptionsColumn, getEntityImageColumn, TableColumn } from "@/components/table/tableUtils";
import { EditableCallbackParams, GetQuickFilterTextParams, NewValueParams, ValueFormatterParams } from "@ag-grid-community/core";
import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual.js";
import { error, success } from "@/components/common/NotificationPlugin";
import { nextTick } from "vue";

export function isPropEditable(params: EditableCallbackParams): boolean {
  const row = params.data
  const isTemplate = row?.attributes?.is_template
  if (row?.id == -1) {
    return (isTemplate && store.getters['users/can'](CREATE_PROJECT_TEMPLATES)) || store.getters['users/can'](CREATE_PROJECTS)
  }

  if (isTemplate) {
    return store.getters['users/can'](EDIT_PROJECT_TEMPLATES)
  }

  return !isProjectClosed(row) && store.getters['users/can'](EDIT_PROJECTS)
}

function isValidCreateModel(data: any) {
  const requiredFields = ['name']

  return !requiredFields.some(field => !data[field])
}

async function tryCreateProject(event: NewValueParams, project: any, data: any) {
  if (!isValidCreateModel(data)) {
    return;
  }

  try {
    project.isSaving = true
    await store.dispatch('projects/createProject', data)

    if (data.is_template) {
      success(i18n.t('Template created successfully'))
    }
    else {
      success(i18n.t('Project created successfully'))
    }

    store.commit('triggerGridDataInputRowReset')
    await nextTick()
    event.api.startEditingCell?.({
      rowIndex: 0,
      colKey: 'attributes.name',
      rowPinned: 'top',
    })
  }
  catch (e) {
    console.error(e)
    error(i18n.t('Could not create project!'))
  }
  finally {
    project.isSaving = false
  }
}

function changeProjectStatus(event: NewValueParams, project: any) {
  const newStatus = event.newValue

  const completedStatus = store.getters['projects/completedStatus']

  if (newStatus == completedStatus?.id) {
    // Revert UI to previous status until user confirms
    store.commit('projects/updateProject', {
      ...project,
      attributes: {
        ...project.attributes,
        status_id: event.oldValue
      }
    })

    store.commit('setEntityCreateParams', {
      project,
    })

    store.commit('projects/triggerCompleteProject')
    return;
  }

  store.dispatch('projects/changeProjectStatus', {
    project,
    status_id: event.newValue,
  })
}

export async function onCellValueChanged(event: NewValueParams) {
  const project = event.data
  const data = {
    ...project.attributes
  }

  if (project.id === -1) {
    tryCreateProject(event, project, data)
    return
  }

  if (event.colDef.field === 'attributes.status_id') {
    changeProjectStatus(event, project)
    return
  }

  store.dispatch('projects/editProject', {
    project,
    data,
    syncWithServer: false
  })
}

export function getProjectColumns(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      name: i18n.t('Drag & Drop'),
      sortProp: 'order',
      group: false,
      hide: true,
      keepHidden: true,
      disabled: () => {
        // @ts-ignore
        return !store.state.route?.path.includes('kanban')
      }
    },
    {
      name: i18n.t('Created Date'),
      prop: 'attributes.created_at',
      showCardLabel: true,
      sortProp: 'created_at',
      sortable: true,
      component: ColumnTypes.Date,
      showInChooseColumns: false,
      hide: true,
      keepHidden: true,
    },
    {
      ...getEntityImageColumn(),
      editable: isPropEditable,
      cellEditor: EditableCellTypes.AvatarEdit,
      onCellValueChanged,
    },
    {
      name: () => {
        if (store.getters['templates/isTemplatePath']) {
          return i18n.t('Template Name')
        }

        return i18n.t('Project Name')
      },
      getQuickFilterText: (params: GetQuickFilterTextParams<any>) => {
        return params.data?.attributes?.name
      },
      prop: 'attributes.name',
      component: ColumnTypes.Link,
      params: {
        getLink(row: any) {
          if (store.getters['templates/isTemplatePath']) {
            return `/templates/${row.id}`
          }

          return `/projects/${row.id}`
        },
        getPlaceholder(row: any) {
          if (row?.id !== -1) {
            return ''
          }

          if (store.getters['templates/isTemplatePath']) {
            return i18n.t('Name your template... ')
          }

          return i18n.t('Name your project... ')
        }
      },
      sortable: true,
      sortProp: 'name',
      editable: isPropEditable,
      onCellValueChanged,
      flex: 1,
      minWidth: 180,
      filterBy: {
        prop: 'name',
        type: 'text',
        doesFilterPass: (row: any, filterValue: string) => {
          const name = row?.attributes?.name?.toLowerCase() || ''
          return name?.includes(filterValue?.toLowerCase())
        }
      },
      required: true,
      group: false,
      isMainColumn: true,
      extendedCellClass: 'w-full project-name-cell',
      cardClass: 'font-bold text-gray-900 text-base leading-5 inline-flex',
    },
    {
      name: i18n.t('Start Date'),
      prop: 'attributes.start_date',
      component: ColumnTypes.Date,
      showCardLabel: true,
      sortProp: 'start_date',
      sortable: true,
      filterBy: () => {
        // @ts-ignore
        const routePath = store.state?.route?.path
        let isSchedulerPath = routePath?.includes('scheduler')
        let isCalendarPath = routePath?.includes('calendar')
        if (isSchedulerPath || isCalendarPath) {
          return undefined
        }

        return {
          prop: 'start_date',
          type: 'date-range',
          format: 'formatDateRangeValue::yyyy-MM-dd',
          displayFormat: 'formatDateRange::dd/MM/yy',
          doesFilterPass: (row: any, filterValue: { min: string, max: string }) => {
            const minDate = Date.parse(filterValue.min)
            const maxDate = Date.parse(filterValue.max)

            if (!row?.attributes?.start_date) {
              return false
            }

            const startDate = Date.parse(row?.attributes?.start_date)

            return startDate >= minDate && startDate <= maxDate
          }
        }
      },
      editable: isPropEditable,
      cellEditor: EditableCellTypes.Date,
      cellEditorParams: {
        clearable: true,
      },
      onCellValueChanged,
    },
    {
      name: i18n.t('Deadline'),
      prop: 'attributes.deadline',
      component: ColumnTypes.Date,
      showCardLabel: true,
      sortProp: 'deadline',
      sortable: true,
      filterBy: () => {
        // @ts-ignore
        const routePath = store.state?.route.path
        let isSchedulerPath = routePath?.includes('scheduler')
        let isCalendarPath = routePath?.includes('calendar')
        if (isSchedulerPath || isCalendarPath) {
          return undefined
        }
        return {
          prop: 'deadline',
          type: 'date-range',
          format: 'formatDateRangeValue::yyyy-MM-dd',
          displayFormat: 'formatDateRange::dd/MM/yy',
          doesFilterPass: (row: any, filterValue: { min: string, max: string }) => {
            const minDate = Date.parse(filterValue.min)
            const maxDate = Date.parse(filterValue.max)

            if (!row?.attributes?.deadline) {
              return false
            }

            const deadlineDate = Date.parse(row?.attributes?.deadline)

            return deadlineDate >= minDate && deadlineDate <= maxDate
          }
        }
      },
      editable: isPropEditable,
      cellEditor: EditableCellTypes.Date,
      cellEditorParams: {
        clearable: true,
      },
      onCellValueChanged,
    },
    {
      name: i18n.t('Status'),
      prop: 'attributes.status_id',
      headerComponent: HeaderTypes.EditStatuses,
      headerComponentParams: {
        target: 'projects'
      },
      component: ColumnTypes.Status,
      cellRendererParams: {
        target: 'projects'
      },
      sortProp: 'status',
      sortable: true,
      comparator: (statusId1: any, statusId2: any) => {
        const statuses = store.getters['projects/orderedStatuses'] || []
  
        const status1 = statuses.find((status: any) => status.id == statusId1)
        const status2 = statuses.find((status: any) => status.id == statusId2)
  
        return (status1?.attributes?.order || 0) - (status2?.attributes?.order || 0)
      },
      filterBy: {
        prop: 'status_ids',
        type: 'ProjectStatusSelect',
        format: 'formatIdsArray',
        displayFormat: 'formatColorOptions',
        props: {
          multiple: true
        },
        doesFilterPass: (row: any, filterValue: string[]) => {
          const statusId = row?.attributes?.status_id
          return (filterValue || []).some(filterStatusId => filterStatusId == statusId)
        }
      },
      enableWidgetFilter: true,
      enableRowGroup: true,
      editable: isPropEditable,
      cellEditor: EditableCellTypes.ProjectStatus,
      onCellValueChanged,
    },
    {
      name: i18n.t('Groups'),
      prop: 'attributes.group_ids',
      relatedProp: 'groups',
      component: ColumnTypes.GroupList,
      sortProp: 'groups',
      sortable: true,
      comparator: (groupsA: any, groupsB: any, nodeA: any, nodeB: any) => {
        const rowA = nodeA?.data || nodeA?.allLeafChildren?.[0]?.data
        const rowB = nodeB?.data || nodeB?.allLeafChildren?.[0]?.data

        groupsA = rowA?.relationships?.groups || []
        groupsB = rowB?.relationships?.groups || []

        const groupsStringA = orderBy(groupsA || [], 'id').map((group: any) => group?.attributes?.name || '').join(', ')
        const groupsStringB = orderBy(groupsB || [], 'id').map((group: any) => group?.attributes?.name || '').join(', ')

        return groupsStringA.localeCompare(groupsStringB);
      },
      params: {
        getGroups(row: any) {
          return row?.relationships?.groups || []
        },
      },
      // @ts-ignore
      enableRowGroup: () => {
        // @ts-ignore
        let isSchedulerPath = store.state?.route?.path?.includes('scheduler')
        return !isSchedulerPath
      },
      filterBy: {
        prop: 'group_ids',
        component: 'GroupSelect',
        props: {
          multiple: true,
        },
        doesFilterPass(row: any, filterValue: (string | number)[]) {
          if (!row.attributes.group_ids?.length) {
            return false
          }

          return row.attributes.group_ids.some((group_id: any) => filterValue.some(id => id == group_id))
        }
      },
      disabled: () => {
        return !store.getters['users/isCurrentUserRoleGreaterOrEqual'](rolesEnum.CREATOR)
      },
      showInChooseColumns: false,
      hide: true,
      keepHidden: true,
    },
    {
      name: i18n.t('People'),
      prop: 'relationships.users',
      group: false,
      filterBy: {
        prop: 'user_ids',
        component: 'UserSelect',
        props: {
          multiple: true
        },
        doesFilterPass: (row: any, filterValue: (string | number)[]) => {
          if (!row.attributes.user_ids?.length) {
            return false
          }

          return row.attributes.user_ids.some((user_id: any) => filterValue.some(id => id == user_id))
        }
      },
      showInChooseColumns: false,
      hide: true,
      keepHidden: true,
    },
    {
      name: i18n.t('Privacy'),
      group: false,
      showInChooseColumns: false,
      hide: true,
      keepHidden: true,
      filterBy: {
        prop: 'privacy',
        component: 'ProjectPrivacySelect',
        displayFormat: 'capitalize',
        doesFilterPass(row: any, filterValue: any) {
          return row.attributes.privacy == filterValue
        }
      }
    },
    {
      prop: 'start_date_deadline',
      filterBy: {
        prop: 'start_date_deadline',
        hidden: true,
        doesFilterPass(row: any, filterValue: { min: string, max: string }) {
          const minDate = Date.parse(filterValue.min)
          const maxDate = Date.parse(filterValue.max)

          if (!row.attributes.start_date) {
            return false
          }

          const startDate = Date.parse(row.attributes.start_date)

          if(!row.attributes.deadline) {
            return startDate >= minDate && startDate <= maxDate
          }

          const endDate = Date.parse(row.attributes.deadline)

          return startDate <= maxDate && endDate >= minDate
        }
      },
      hide: true,
      keepHidden: true,
      showInChooseColumns: false,
    }
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn(),
  }

  const addCustomFieldColumn: TableColumn = {
    headerComponent: HeaderTypes.AddCustomField,
    prop: 'add-custom-field',
    headerComponentParams: () => {
      return {
        defaultAppliesTo: customFieldAppliesToOptions.PROJECT,
        tooltip: i18n.t('Add a new custom field for all projects across the account')
      }
    },
    valueFormatter(params: ValueFormatterParams) {
      return ''
    },
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    class: 'w-1 text-center',
    disabled: () => {
      if (!store.getters['users/can'](CREATE_CUSTOM_FIELDS)) {
        return true
      }

      if (store.getters['templates/isTemplatePath']) {
        return true
      }

      return false
    },
    showInChooseColumns: false,
  }

  const extraColumns: TableColumn[] = [addCustomFieldColumn, optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}

export const extraFilters = [
  {
    key: 'my-projects',
    doesFilterPass: (row: any, filterValue: { show: boolean }) => {
      if (filterValue?.show) {
        // @ts-ignore
        const authUserId = store.state.auth.user.id
        return (row.attributes.user_ids || []).some((id: number | string) => id == authUserId)
      }

      return true
    },
  },
  {
    key: 'closed',
    doesFilterPass: (row: any, filterValue: { show: boolean }) => {
      if (filterValue?.show) {
        return true
      }

      return !isProjectClosed(row)
    },
  }
]

export const projectFields = [
  'id',
  'created_at',
  'created_by',
  'name',
  'status_id',
  'image',
  // 'user_ids', // TODO: add and use after implemented BE-side
  'start_date',
  'deadline',
  'custom_fields',
  'is_template',
  'share_link_identifier',
  'cover_image',
]
