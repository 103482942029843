<template>
  <p>
    <span class="text-gray-700 font-bold capitalize">
      {{ label }}:
    </span>
    <div
      v-if="Array.isArray(result)"
      class="inline-flex gap-2 flex-wrap items-center"
    >
      <AiActionBindingResolver
        v-for="r in result"
        :key="r.query"
        :result="r"
        @discard="discardResult"
        @resolve="resolveResult"
      />
    </div>
    <AiActionBindingResolver
      v-else
      :result="result"
      @discard="discardResult"
      @resolve="resolveResult"
    />
  </p>
</template>
<script lang="ts" setup>
// Components
import AiActionBindingResolver from "@/modules/ai/components/AiActionBindingResolver.vue"

// Utils
import { PropType, computed } from 'vue';
import { BindingResult } from "@/modules/ai/filters/DataBinder"

const props = defineProps({
  property: {
    type: String,
    required: true
  },
  result: {
    type: Object as PropType<BindingResult | BindingResult[]>,
    required: true
  }
})

const emit = defineEmits([
  'discard',
  'resolve'
])

const label = computed(() => {
  if (Array.isArray(props.result)) {
    return props.result[0]?.label
  }
  return props.result.label
})


function discardResult(result: BindingResult) {
  emit('discard', {
    property: props.property,
    result
  })
}

function resolveResult({ result, value, query }: { result: BindingResult, value: any, query: string }) {
  emit('resolve', {
    property: props.property,
    result,
    value,
    query,
  })
}
</script>
