<template>
  <div
    :class="{'p-6': isFullPreview}"
    class="bg-white rounded-lg"
  >
    <div class="mx-auto space-y-2">

      <div class="shrink-0 flex justify-center">
        <BaseLogo
          :entity="group"
          :size="isFullPreview ? 'xl' : 'lg'"
          class="rounded-md"
          logo-key="attributes.image"
        />
      </div>

      <div>
        <p
          :class="{
            'text-xl sm:text-2xl': isFullPreview,
            'text-lg': isFullPreview,
          }"
          class="text-center font-bold text-gray-900"
        >
          {{ group?.attributes?.name }}
        </p>
      </div>

      <slot />

    </div>
  </div>
</template>
<script>
const PreviewTypes = {
  Inline: 'inline',
  Full: 'full'
}
export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: PreviewTypes.Full
    },
  },
  computed: {
    isFullPreview() {
      return this.type === PreviewTypes.Full
    },
  }
}
</script>
