<template>
  <NodeViewWrapper
    as="div"
    class="ai-assistant"
  >
    <div v-if="generatedContent"
         v-html="generatedContentHtml"
    >
    </div>
    <div ref="inputWrapper">
      <BaseInput
        v-focus
        :placeholder="$t('Ask AI to edit or generate')"
        :name="$t('AI Prompt')"
      >
        <template #default>
          <el-autocomplete
            ref="autocomplete"
            v-model="prompt"
            :fetch-suggestions="querySearch"
            :disabled="loading"
            :popper-append-to-body="true"
            clearable
            popper-class="ai-assistant-autocomplete"
            class="w-full"
            :placeholder="$t('Ask AI to edit or generate')"
            @blur="onBlur"
            @select="onSelectOption"
            @keydown.enter="handleEnter"
          >
            <template #prefix>
            <span class="fa-sharp fa-regular fa-wand-magic-sparkles text-sm text-primary-500">
            </span>
            </template>
            <template #suffix>
              <BaseButton
                v-if="loading"
                variant="white"
                size="sm"
                @click.prevent="stopRequest">
                {{ $t('Stop') }}
              </BaseButton>
              <BaseButton
                v-else
                variant="white"
                size="sm"
                @click="closeInput"
              >
                {{ $t('Close') }}
              </BaseButton>
            </template>
            <template #default="{ item }">
              <div class="flex space-x-2 items-center">
                <i class="fa-regular fa-pen-line text-xs text-primary-500"></i>
                <span>{{ item.label }}</span>
              </div>
            </template>
          </el-autocomplete>
        </template>
      </BaseInput>
    </div>

  </NodeViewWrapper>
</template>
<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import showdown from 'showdown'
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { error } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import { $deleteConfirm } from '@/components/common/dialog/modalPlugin'
import { EventSourceMessage } from "@microsoft/fetch-event-source/lib/cjs/parse";
import config from "@/modules/common/config";
import AuthService from "@/modules/auth/services/AuthService";
import { useStore } from "vuex";
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from '@/modules/common/composables/useCan';

const prompt = ref('')
const lastSelectedOption = ref()
const loading = ref(false)

const props = defineProps({
  ...nodeViewProps,
});

interface Message {
  role: string
  content: string
}

const SystemMessage: Message = {
  role: "system",
  content: "You are a helpful AI assistant. Answers should be in markdown format."
}
const generatedContent = ref('')

const htmlConverter = new showdown.Converter({
  tables: true,
})
const inputWrapper = ref()
const generatedContentHtml = computed(() => {
  return htmlConverter.makeHtml(generatedContent.value)
})
const isConvert = ref(false)
const options = computed(() => {
  const generateOptions = [
    {
      label: i18n.t("Blog article - Write a blog article about "),
      promptText: i18n.t("Write a blog article about "),
    },
    {
      label: i18n.t("Email - Write an email about "),
      promptText: i18n.t("Write an email about "),
    },
    {
      label: i18n.t("To-Dos - Create a to-do list for "),
      promptText: i18n.t("Create a to-do list for "),
    },
    {
      label: i18n.t("Explain - Explain how to "),
      promptText: i18n.t("Explain how to "),
    },
    {
      label: i18n.t("Brainstorm - Give me 10 topic ideas for "),
      promptText: i18n.t("Give me 10 topic ideas for "),
    },
    {
      label: i18n.t("Social media post - Write a social media post about "),
      promptText: i18n.t("Write a social media post about "),
    },
    {
      label: i18n.t("Outline - Write an outline about "),
      promptText: i18n.t("Write an outline about "),
    },
    {
      label: i18n.t("Headings - Give me headings for "),
      promptText: i18n.t("Give me headings for "),
    },
    {
      label: i18n.t("Ideas - Give me 5 ideas around "),
      promptText: i18n.t("Give me 5 ideas around "),
    },
    {
      label: i18n.t("Suggestions - Give me 5 suggestions for "),
      promptText: i18n.t("Give me 5 suggestions for "),
    },
    {
      label: i18n.t("Job description - Write me a job description for "),
      promptText: i18n.t("Write me a job description for "),
    },
  ]
  const convertOptions = [
    {
      label: i18n.t("Fix the grammar - Fix the grammar "),
      promptText: i18n.t("Fix the grammar "),
    },
    {
      label: i18n.t("Improve writing - Improve the writing of this "),
      promptText: i18n.t("Improve the writing of this "),
    },
    {

      label: i18n.t("Translate language - Translate this text into "),
      promptText: i18n.t("Translate this text into "),
      extraInput: true
    },
    {
      label: i18n.t("Make shorter - Make this shorter "),
      promptText: i18n.t("Make this shorter "),
    },
    {
      label: i18n.t("Make longer - Make this longer "),
      promptText: i18n.t("Make this longer "),
    },
    {
      label: i18n.t("Plain English - Write this in plain English "),
      promptText: i18n.t("Write this in plain English "),
    },
    {
      label: i18n.t("Summarise - Summarise this"),
      promptText: i18n.t("Summarise this"),
    },
  ]
  return isConvert.value ? convertOptions : generateOptions
})


const querySearch = (queryString: string, cb: any) => {
  const results = queryString
    ? options.value.filter(createFilter(queryString))
    : options.value
  // call callback function to return suggestion objects
  cb(results)
}

const createFilter = (queryString: string) => {
  return (option: any) => {
    return option.label.toLowerCase().includes(queryString.toLowerCase())
  }
}

async function appendContent(content: string) {
  try {
    generatedContent.value += content
    inputWrapper.value?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  } catch (err: any) {
    console.log(err)
  }
}

function replaceNewlines(html: string) {
  const codeRegex = /<pre.*?<\/pre>/gs
  // replace newlines at the end of code blocks
  html = html.replaceAll(/\n<\/code>/g, '</code>')

  const codes = html.match(codeRegex) || [];
  const nonCodeHtml = html.replace(codeRegex, '[PRE_PLACEHOLDER]');

  const newlineRegex = /\n/g;
  let finalHtml = nonCodeHtml.replace(newlineRegex, '');
  codes.forEach((code: string) => {
    finalHtml = finalHtml.replace('[PRE_PLACEHOLDER]', code);
  });

  return finalHtml;
}

function insertGeneratedContent() {
  const position = props.getPos()
  const selectionRange = props.editor.state.selection
  initialPosition.value = position

  const content = replaceNewlines(generatedContentHtml.value)

  props.editor
    .chain()
    .insertContentAt(selectionRange, content)
    .run()
}

const initialPosition = ref(0)

function prepareEditor() {
  if (!isConvert.value) {
    return
  }
  const position = props.getPos()
  const selectionRange = props.editor.state.selection
  initialPosition.value = position
  props.editor
    .chain()
    .deleteRange(selectionRange)
    .run()
}

function onSelectOption(option: any) {
  lastSelectedOption.value = option.promptText
  prompt.value = option.promptText
  if (isConvert.value && !option.extraInput) {
    sendRequest()
  }
}

const autocomplete = ref()

function handleEnter() {
  if (!prompt.value || prompt.value === lastSelectedOption.value) {
    return
  }
  sendRequest()
}

async function onBlur() {
  if (loading.value || isConvert.value) {
    return
  }
  if (!prompt.value) {
    props.deleteNode()
    return
  }
  const confirmed = await $deleteConfirm({
    title: i18n.t('Do you want to discard the AI response'),
    buttonText: i18n.t('Discard'),
  })
  if (confirmed) {
    props.deleteNode()
  }
}

function closeInput() {
  props.deleteNode()
}

let requestController = new AbortController();

function stopRequest() {
  requestController.abort()
  prompt.value = ''
  loading.value = false
  insertGeneratedContent()
  props.deleteNode()
}

async function sendRequest() {
  if (!prompt.value) {
    error(i18n.t('Please enter a prompt'))
    return
  }
  try {
    loading.value = true
    let messages: Message[] = [
      SystemMessage,
    ]
    let promptText = prompt.value
    if (isConvert.value) {
      const { from, to } = props.editor.state.selection
      const textSelection = props.editor.state.doc.textBetween(from, to)
      promptText = `${prompt.value} ${textSelection}`
    }
    messages.push({
      role: "user",
      content: promptText
    })
    prompt.value = 'AI is writing...'
    prepareEditor()

    const apiUrl = `${config.getApiHost()}/gpt-prompt`
    const token = AuthService.getToken();
    requestController = new AbortController();

    await fetchEventSource(apiUrl, {
      method: 'POST',
      body: JSON.stringify({
        model: "gpt-4o",
        messages,
        stream: true,
      }),
      signal: requestController.signal,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`
      },
      onmessage: onRequestMessage,
      onclose: () => {
        prompt.value = ''
        loading.value = false
        insertGeneratedContent()
        props.deleteNode()
      },
    })
  } catch (err) {
    console.log(err)
  }
}

const store = useStore()

const {
  isFreePlan,
  hasReachedLimit
} = useAccountLimits()

const aiTokensLimitReached = computed(() => {
  return hasReachedLimit(AccountPermissions.AiAssistant)
})

async function handleRequestError() {
  stopRequest()
  await store.dispatch("accounts/getConfiguration")
  let message = i18n.t('Please upgrade your account to continue using the AI Assistant.')
  if (!isFreePlan.value) {
    message = i18n.t('You have reached the limit of tokens for this month. You can increase your limit by adding more creator seats to your account.')
  }
  if (aiTokensLimitReached.value) {
    error(message)
    return
  }
  throw new Error('Error connecting to the server')
}

function onRequestMessage(event: EventSourceMessage) {
  try {
    const data = JSON.parse(event.data)
    const content = data?.choices[0]?.delta?.content || ''
    if (content) {
      appendContent(content)
    }
  } catch (err) {
    console.log('Error parsing data from BE', err)
    console.warn(err)
  }
}

function checkIfConvert() {
  const { selection } = props.editor?.state || {}
  const selectionSize = Math.abs(selection?.from - selection?.to)
  isConvert.value = selectionSize > 1;
}

onMounted(() => {
  checkIfConvert()
})

onBeforeUnmount(() => {
  requestController?.abort()
})
</script>
<style>
.ai-assistant-autocomplete {
  z-index: 9999 !important;
}
</style>
