export type RestifyTransformOptions = {
  type?: string,
  getAttributes?: (entity: any) => any,
  getRelationships?: (entity: any) => any,
}

/**
 * Transform a single entity to restify format {id, attributes, relationships}
 * @param entity - Entity to transform
 * @param options - Options for setting custom type, attributes and relationships
 */
export function entityToRestifyMapper(entity: any, options?: RestifyTransformOptions) {
  const attributes = options?.getAttributes?.(entity) || { ...entity }
  const relationships = options?.getRelationships?.(entity) || {}

  return {
    id: Number(entity.id),
    type: options?.type,
    attributes,
    relationships,
  }
}

/**
 * Transform data to restify format {id, attributes, relationships}
 * @param data - Array data to transform
 * @param options - Options for setting custom type, attributes and relationships
 */
export function transformToRestifyArray(data: any[], options?: RestifyTransformOptions) {
  return data.map((entity) => entityToRestifyMapper(entity, options))
}
