<template>
  <div class="flex flex-col h-full">
    <FileDialog
      v-show="showEditDialog && $route.query.fileId"
      v-model="showEditDialog"
      :file="currentFile"
      :title="$t(`Edit ${currentFile?.attributes?.name}`)"
      key="file-edit"
      @close="redirectToList"
      @save="onFileEdit"
    />
    <TopFilters
      :columns="fileColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search files...')"
    >
      <template
        v-if="!isCurrentProjectClosed && (can($actions.CREATE_PROJECT_FILES) || can($actions.CREATE_PROJECT_FOLDERS))"
        #action
      >
        <div class="flex space-x-4">
          <RequiresPermissionTo :action="$actions.CREATE_PROJECT_FILES">
            <BaseButton
              block
              @click="triggerEntityCreate(entityTypes.File)"
              class="whitespace-nowrap"
            >
              <i class="fas fa-upload mr-2"></i>
              <span>{{ $t('Upload Files') }}</span>
            </BaseButton>
          </RequiresPermissionTo>
          <RequiresPermissionTo :action="$actions.CREATE_PROJECT_FOLDERS">
            <BaseButton
              block
              @click="triggerEntityCreate(entityTypes.Folder)"
            >
              <i class="fas fa-folder-plus mr-2"></i>
              <span>{{ $t('Add Folder') }}</span>
            </BaseButton>
          </RequiresPermissionTo>
        </div>
      </template>
    </TopFilters>

    <DataSyncingIndicator
      v-if="files.length &&$store.state.files.filesLoading"
      dataView
    />
    <slot>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
    </slot>
  </div>
</template>
<script>
import TopFilters from "@/modules/filters/components/TopFilters.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"
import FileDialog from "@/modules/files/components/FileDialog.vue";
import useEntityCrud from "@/modules/common/composables/useEntityCrud"

export default {
  components: {
    FileDialog,
    BaseButton,
    TopFilters,
    DataSyncingIndicator
  },
  data() {
    return {
      showEditDialog: false,
    }
  },
  setup() {
    const {
      entityTypes,
      triggerEntityCreate
    } = useEntityCrud()

    return {
      entityTypes,
      triggerEntityCreate
    }
  },
  computed: {
    currentFile() {
      return this.$store.state.files.currentFile || {}
    },
    fileColumns() {
      return this.$store.getters['files/activeColumns'] || []
    },
    files() {
      return this.$store.state.files.files?.data || []
    },
    availableFilters() {
      const filters = ['filter', 'sort', 'columns']

      if (this.$route.path.includes('list')) {
        filters.push(...['other', 'totalRow'])
      }

      if (this.$route.path.includes('card')) {
        filters.push(...['group'])
      }

      return filters
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    onFileEdit() {
      this.redirectToList()
    },
    redirectToList() {
      this.showCreateDialog = false
      this.showEditDialog = false
      const query = {
        ...this.$route.query
      }
      delete query.fileId
      this.$router.push({
        path: this.$route.path,
        query
      })
    }
  },
  created() {
    this.$store.dispatch('files/getAllFiles')
  }
}
</script>
