import i18n from "@/i18n";
import { formatDate } from "@/modules/common/utils/dateUtils";
import { getTaskDueDate } from "@/modules/tasks/utils/modelUtils";
import { formatPercent } from "@/plugins/formatPercent";
import { capitalize, upperFirst } from "lodash-es";
import { get } from 'lodash-es';
import { getNotificationsReactionsMessage } from "@/modules/common/utils/reactionUtils.js";
import { ProofsStatusLabels } from '@/modules/file-proofs/utils/fileProofsUtils'
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'
import { formatPrice } from "@/plugins/formatPrice";

export const NOTIFICATION_TYPES = {
  PROJECT: 'project',
  PROJECT_DESCRIPTION_MENTION: 'project_description_mention',
  PROJECT_NOTE_MENTION: 'project_note_mention',
  PROJECT_DISCUSSION: 'project_discussion',
  PROJECT_DISCUSSION_MENTION: 'project_discussion_mention',
  PROJECT_DISCUSSION_REACTION: 'project_discussion_reaction',
  DISCUSSION_REACTION: 'discussion_reaction',
  TASK: 'task', // Task allocation
  TASK_UPDATE: 'task_update',
  TASK_DESCRIPTION_MENTION: 'task_description_mention',
  TASK_DISCUSSION:'task_discussion',
  TASK_DISCUSSION_MENTION: 'task_discussion_mention',
  TASK_DISCUSSION_REACTION: 'task_discussion_reaction',
  PAYMENT: 'payment',
  PAYMENT_SUCCESS: 'payment_success',
  SUBSCRIPTION_REQUESTED: 'subscription_requested',
  SUBSCRIPTION_CANCELED: 'subscription_canceled',
  SUBSCRIPTION_FAILED: 'subscription_failed',
  SUBSCRIPTION_SUCCEEDED: 'subscription_succeeded',
  PROOF_CREATED: 'proof_created',
  PROOF_REVIEW_DECISION_STATUS_CHANGED: 'proof_review_decision_status_changed',
  PROOF_DECISION_STATUS_CHANGED: 'proof_decision_status_changed',
}

export const notificationTypeOptions = [
  {
    value: NOTIFICATION_TYPES.PROJECT,
    label: i18n.t("Project"),
  },
  {
    value: NOTIFICATION_TYPES.PROOF_CREATED,
    label: i18n.t("Proof Created"),
  },
  {
    value: NOTIFICATION_TYPES.PROOF_REVIEW_DECISION_STATUS_CHANGED,
    label: i18n.t("Proof Reviewer Decision Status Changed"),
  },
  {
    value: NOTIFICATION_TYPES.PROOF_DECISION_STATUS_CHANGED,
    label: i18n.t("Proof Decision Status Changed"),
  },
  {
    value: NOTIFICATION_TYPES.PROJECT_DESCRIPTION_MENTION,
    label: i18n.t("Project Description Mention"),
  },
  {
    value: NOTIFICATION_TYPES.PROJECT_NOTE_MENTION,
    label: i18n.t("Project Note Mention"),
  },
  {
    value: NOTIFICATION_TYPES.PROJECT_DISCUSSION,
    label: i18n.t("Project Discussion"),
  },
  {
    value: NOTIFICATION_TYPES.PROJECT_DISCUSSION_MENTION,
    label: i18n.t("Project Discussion Mention"),
  },
  {
    value: NOTIFICATION_TYPES.DISCUSSION_REACTION,
    label: i18n.t("Discussion Reaction"),
  },
  {
    value: NOTIFICATION_TYPES.TASK,
    label: i18n.t("Task Allocation"),
  },
  {
    value: NOTIFICATION_TYPES.TASK_UPDATE,
    label: i18n.t("Task Update"),
  },
  {
    value: NOTIFICATION_TYPES.TASK_DESCRIPTION_MENTION,
    label: i18n.t("Task Description Mention"),
  },
  {
    value: NOTIFICATION_TYPES.TASK_DISCUSSION,
    label: i18n.t("Task Discussion"),
  },
  {
    value: NOTIFICATION_TYPES.TASK_DISCUSSION_MENTION,
    label: i18n.t("Task Discussion Mention"),
  },
  {
    value: NOTIFICATION_TYPES.PAYMENT,
    label: i18n.t("Payment"),
  },
  {
    value: NOTIFICATION_TYPES.PAYMENT_SUCCESS,
    label: i18n.t("Payment Success"),
  },
  {
    value: NOTIFICATION_TYPES.SUBSCRIPTION_SUCCEEDED,
    label: i18n.t("Recurring Payment Success"),
  },
  {
    value: NOTIFICATION_TYPES.SUBSCRIPTION_FAILED,
    label: i18n.t("Recurring Payment Failed"),
  },
  {
    value: NOTIFICATION_TYPES.SUBSCRIPTION_REQUESTED,
    label: i18n.t("Recurring Payment Requested"),
  },
  {
    value: NOTIFICATION_TYPES.SUBSCRIPTION_CANCELED,
    label: i18n.t("Recurring Payment Cancelled"),
  },
];

export function getNotificationCreator(notification) {
  const creator = get(notification, 'relationships.notificationCreator') || {}

  if (!creator?.id) {
    return null
  }

  const first_name = creator?.attributes?.first_name || ''
  const last_name = creator?.attributes?.last_name || ''

  const name = [first_name, last_name].filter(n => !!n).join(' ')

  return {
    ...creator,
    attributes: {
      ...(creator?.attributes || {}),
      name
    }
  }
}

export function userHasAccessToProject(notification) {
  return !!get(notification, 'relationships.notificationProject.id')
}


function getTaskMessage(notification) {

  if (!userHasAccessToProject(notification)) {
    return ''
  }

  const allocations = get(notification, 'attributes.allocations') || []
  const task = get(notification, 'relationships.notificationTask')
  const dueDate = getTaskDueDate(task)
  const formattedDueDate = dueDate ? formatDate(dueDate) : i18n.t('No Date')

  return [
    {
      label: i18n.t('Date'),
      value: formattedDueDate,
    },
    {
      label: i18n.t('Status'),
      value: get(notification, 'relationships.notificationTask.attributes.status_name'),
      class: 'capitalize'
    },
    {
      label: i18n.t('Assigned'),
      value: allocations.join(', ')
    }
  ]
}

function getPaymentMessage(notification) {
  if (!userHasAccessToProject(notification)) {
    return ''
  }
  const isRecurring = notification?.relationships?.notificationPayment?.attributes?.is_recurring
  if (isRecurring) {
    return getRecurringPaymentMessage(notification)
  }

  return [
    {
      label: i18n.t('Payment description'),
      value: get(notification, 'relationships.notificationPayment.attributes.description')
    },
    {
      label: i18n.t('Currency'),
      value: get(notification, 'relationships.notificationPayment.attributes.currency'),
    },
    {
      label: i18n.t('Amount'),
      value: get(notification, 'relationships.notificationPayment.attributes.amount'),
    },
    {
      label: i18n.t('Tax'),
      value: formatPercent(get(notification, 'relationships.notificationPayment.attributes.tax_rate')),
    },
    {
      label: i18n.t('Total'),
      value: get(notification, 'relationships.notificationPayment.attributes.total_payment'),
    }
  ]
}

function getRecurringPaymentMessage(notification) {
  if (!userHasAccessToProject(notification)) {
    return ''
  }
  const payment = get(notification, 'relationships.notificationPayment.attributes')
  const amount = get(notification, 'attributes.data.amount')
  const tax = get(notification, 'attributes.data.tax')
  const total = get(notification, 'attributes.data.total')
  const currency = get(payment, 'currency')

  const isSuccessPayment = get(notification, 'attributes.data.notifiable_target_type') === NOTIFICATION_TYPES.SUBSCRIPTION_SUCCEEDED
  const isCancelledPayment = get(notification, 'attributes.data.notifiable_target_type') === NOTIFICATION_TYPES.SUBSCRIPTION_CANCELED
  let extraItems = [
    {
      label: i18n.t('Frequency'),
      value: get(payment, 'frequency'),
    },
  ]
  if (isSuccessPayment) {
    extraItems = [
      {
        label: i18n.t('Tax'),
        value: formatPrice(tax, {
          currencyCode: currency,
        }),
      },
      {
        label: i18n.t('Total'),
        value: formatPrice(total, {
          currencyCode: currency,
        }),
      },
      {
        label: i18n.t('Frequency'),
        value: get(payment, 'frequency'),
      },
    ]
  }

  if (isCancelledPayment) {
    extraItems = [
      {
        label: i18n.t('Frequency'),
        value: get(payment, 'frequency'),
      },
      {
        label: i18n.t('Cancellation date'),
        value: formatDate(get(notification, 'attributes.data.endsAt')),
      },
    ]
  }
  return [
    {
      label: i18n.t('Recurring payment description'),
      value: get(payment, 'description'),
    },
    {
      label: i18n.t('Currency'),
      value: get(payment, 'currency'),
    },
    {
      label: i18n.t('Amount'),
      value: formatPrice(amount, {
        currencyCode: currency,
      }),
    },
    ...extraItems,
  ]
}


const labelFormatter = {
  date_type: i18n.t('Date Type'),
  start_date: i18n.t('Start Date'),
  end_date: i18n.t('End Date'),
  allocation: i18n.t('Assigned'),
  groupsName: i18n.t('Groups')
}

function formatTaskUpdateLabel(prop) {
  if (!labelFormatter[prop]) {
    return prop
  }

  return labelFormatter[prop]
}

const valueFormatter = {
  date_type(value) {
    return capitalize(value)
  },
  visibility(value) {
    return capitalize(value)
  },
  status(value) {
    return upperFirst(value)
  },
  date(value) {
    return formatDate(value)
  },
  start_date(value) {
    return formatDate(value)
  },
  end_date(value) {
    return formatDate(value)
  },
  allocation(value) {
    return value ? value?.join(', ') : i18n.t('No assignees')
  },
  followers(value) {
    return value ? value?.join(', ') : i18n.t('No followers')
  },
  groupsName(value) {
    return value ? value?.join(', ') : i18n.t('No Groups')
  },
}

function formatTaskUpdateValue(prop, value, isOldValue = false) {
  const wrapper = isOldValue
    ? `<span class="line-through text-red-500">{value}</span>`
    : '<span class="text-gray-500">{value}</span>'

  // TODO: temp workaround for null values returned as empty array ([]) from BE
  if (Array.isArray(value) && value.length === 0) {
    value = ''
  }

  const formattedValue = valueFormatter[prop]
   ? valueFormatter[prop](value)
   : value

  return wrapper.replace('{value}', formattedValue)
}

function getTaskUpdateMessage(notification) {
  const data = notification.attributes.data.data;
  // TODO: ignoredProps logic can be removed after BE fixes
  const ignoredProps = [
    'updated_by',
    'updated_at',
    'sortable_date',
    'parent_id'
  ]
  const entries = []

  for (const prop in data) {
    if (ignoredProps.includes(prop)) {
      continue
    }

    const label = formatTaskUpdateLabel(prop)

    const oldValue = formatTaskUpdateValue(prop, data[prop].old, true)
    const newValue = formatTaskUpdateValue(prop, data[prop].new)

    entries.push({
      label,
      value: `${oldValue} → ${newValue}`
    })
  }

  return entries
}

export const notificationTypeProperties = {
  [NOTIFICATION_TYPES.PROJECT]: {
    iconClass: 'fa-inbox',
    iconColorClass: 'text-purple-500',
    userLabel: i18n.t('Invited by'),
    title: i18n.t('Project Invitation'),
    entityType: 'project',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'attributes.project_id',
    entityPath: `/projects/{entityId}`,
    openLabel: i18n.t('Open Project'),
    getMessage: (notification) => {
      if (!userHasAccessToProject(notification)) {
        return ''
      }

      const projectDescription = get(notification, 'relationships.notificationProject.attributes.description')
      return projectDescription || i18n.t('This project has no description')
    }
  },
  [NOTIFICATION_TYPES.PROOF_CREATED]: {
    iconClass: 'fa-files',
    iconColorClass: 'text-purple-500',
    userLabel: i18n.t('Created by'),
    title: i18n.t('Proof in Project'),
    entityType: EntityTargetTypes.FILE_PROOFS,
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityItem_path: '/projects/{projectId}/file-proofs/{entityId}',
    entityId_path: 'attributes.data.notifiable_target_id',
    entityPath: `/projects/{projectId}/file-proofs/list`,
    openLabel: i18n.t('Open Proof'),
    getMessage: (notification) => {
      return i18n.t('You were set as reviewer in a proof')
    }
  },
  [NOTIFICATION_TYPES.PROOF_REVIEW_DECISION_STATUS_CHANGED]: {
    iconClass: 'fa-files',
    iconColorClass: 'text-purple-500',
    userLabel: i18n.t('Reviewed by'),
    title: i18n.t('Proof updated'),
    entityType: EntityTargetTypes.FILE_PROOFS,
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityItem_path: '/projects/{projectId}/file-proofs/{entityId}',
    entityId_path: 'attributes.data.notifiable_target_id',
    entityPath: `/projects/{projectId}/file-proofs/list`,
    openLabel: i18n.t('Open Proof'),
    getMessage: (notification) => {
      const message = get(notification, 'attributes.data.message')
      const [, decision = ''] = Object.entries(ProofsStatusLabels).find(([key, value]) => message.includes(key))
      return [
        {
          label: i18n.t('Decision'),
          value: decision
        }
      ]
    }
  },
  [NOTIFICATION_TYPES.PROOF_DECISION_STATUS_CHANGED]: {
    iconClass: 'fa-files',
    iconColorClass: 'text-purple-500',
    title: i18n.t('Proof status has been changed'),
    entityType: EntityTargetTypes.FILE_PROOFS,
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityItem_path: '/projects/{projectId}/file-proofs/{entityId}',
    entityId_path: 'attributes.data.notifiable_target_id',
    entityPath: `/projects/{projectId}/file-proofs/list`,
    openLabel: i18n.t('Open Proof'),
    getMessage: (notification) => {
      const message = get(notification, 'attributes.data.message')
      const [, newStatus = ''] = Object.entries(ProofsStatusLabels).find(([key, value]) => message.includes(key))
      return [
        {
          label: i18n.t('New status'),
          value: newStatus
        }
      ]
    }
  },
  [NOTIFICATION_TYPES.PROJECT_DISCUSSION]: {
    iconClass: 'fa-comments-alt',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Posted by'),
    title: i18n.t('Comment On Project'),
    entityType: 'project',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'attributes.project_id',
    entityPath: `/projects/{entityId}/discussion`,
    openLabel: i18n.t('Open Discussion'),
    getMessage: (notification) => {
      return get(notification, 'attributes.data.message')
    }
  },
  [NOTIFICATION_TYPES.PROJECT_DISCUSSION_MENTION]: {
    iconClass: 'fa-comments-alt',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Mentioned by'),
    title: i18n.t('@mention in project discussion'),
    entityType: 'project',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'attributes.project_id',
    entityPath: `/projects/{entityId}/discussion`,
    openLabel: i18n.t('Open Discussion'),
    getMessage: (notification) => {
      return get(notification, 'attributes.data.message')
    }
  },
  [NOTIFICATION_TYPES.PROJECT_DISCUSSION_REACTION]: {
    iconClass: 'fa-comments-alt',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Posted by'),
    title: i18n.t('Reactions On Comment'),
    entityType: 'project',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'attributes.project_id',
    entityPath: `/projects/{entityId}/discussion`,
    openLabel: i18n.t('Open Discussion'),
    getMessage: (notification) => {
      const reactions = get(notification, 'attributes.data.reactions', [])
      return getNotificationsReactionsMessage(reactions)
    }
  },
  [NOTIFICATION_TYPES.PROJECT_DESCRIPTION_MENTION]: {
    iconClass: 'fa-inbox',
    iconColorClass: 'text-purple-500',
    userLabel: i18n.t('Mentioned by'),
    title: i18n.t('@mention in project description'),
    entityType: 'project',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'attributes.project_id',
    entityPath: `/projects/{entityId}`,
    openLabel: i18n.t('Open Project'),
    getMessage: (notification) => {
      return i18n.t('You were mentioned in this project description')
    }
  },
  [NOTIFICATION_TYPES.PROJECT_NOTE_MENTION]: {
    iconClass: 'fa-sticky-note',
    iconColorClass: 'text-gray-500',
    userLabel: i18n.t('Mentioned by'),
    title: i18n.t('@mention in project note'),
    entityType: 'project_note_mention',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'attributes.data.notifiable_target_id',
    entityPath: `/projects/{projectId}/notes/{entityId}`,
    openLabel: i18n.t('Open Note'),
    getMessage: (notification) => {
      return i18n.t('You were mentioned in this project note')
    }
  },
  [NOTIFICATION_TYPES.TASK]: {
    iconClass: 'fa-badge-check',
    iconColorClass: 'text-yellow-500',
    userLabel: i18n.t('Assigned by'),
    title: i18n.t('Task Assignment'),
    entityType: 'task',
    entityDescription_path: 'relationships.notificationTask.attributes.name',
    entityId_path: 'relationships.notificationTask.id',
    entityPath: `/tasks/{entityId}`,
    openLabel: i18n.t('Open Task'),
    getMessage: (notification) => {
      return getTaskMessage(notification)
    }
  },
  [NOTIFICATION_TYPES.TASK_DESCRIPTION_MENTION]: {
    iconClass: 'fa-badge-check',
    iconColorClass: 'text-yellow-500',
    userLabel: i18n.t('Mentioned by'),
    title: i18n.t('@mention in task description'),
    entityType: 'task',
    entityDescription_path: 'relationships.notificationTask.attributes.name',
    entityId_path: 'relationships.notificationTask.id',
    entityPath: `/tasks/{entityId}`,
    openLabel: i18n.t('Open Task'),
    getMessage: (notification) => {
      return i18n.t('You were mentioned in this task description')
    }
  },
  [NOTIFICATION_TYPES.TASK_DISCUSSION]: {
    iconClass: 'fa-comments-alt',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Posted by'),
    title: i18n.t('Comment On Task'),
    entityType: 'task',
    entityDescription_path: 'relationships.notificationTask.attributes.name',
    entityId_path: 'relationships.notificationTask.id',
    entityPath: `/tasks/{entityId}`,
    openLabel: i18n.t('Open Discussion'),
    getMessage: (notification) => {
      return get(notification, 'attributes.data.message')
    }
  },
  [NOTIFICATION_TYPES.TASK_DISCUSSION_MENTION]: {
    iconClass: 'fa-comments-alt',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Mentioned by'),
    title: i18n.t('@mention in task discussion'),
    entityType: 'task',
    entityDescription_path: 'relationships.notificationTask.attributes.name',
    entityId_path: 'relationships.notificationTask.id',
    entityPath: `/tasks/{entityId}`,
    openLabel: i18n.t('Open Discussion'),
    getMessage: (notification) => {
      return get(notification, 'attributes.data.message')
    }
  },
  [NOTIFICATION_TYPES.TASK_DISCUSSION_REACTION]: {
    iconClass: 'fa-comments-alt',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Posted by'),
    title: i18n.t('Reactions On Comment'),
    entityType: 'task',
    entityDescription_path: 'relationships.notificationTask.attributes.name',
    entityId_path: 'relationships.notificationTask.id',
    entityPath: `/tasks/{entityId}`,
    openLabel: i18n.t('Open Discussion'),
    getMessage: (notification) => {
      const reactions = get(notification, 'attributes.data.reactions', [])
      return getNotificationsReactionsMessage(reactions)
    }
  },
  [NOTIFICATION_TYPES.TASK_UPDATE]: {
    iconClass: 'fa-badge-check',
    iconColorClass: 'text-yellow-500',
    userLabel: i18n.t('Updated by'),
    title: i18n.t('Task Update'),
    entityType: 'task',
    entityDescription_path: 'relationships.notificationTask.attributes.name',
    entityId_path: 'relationships.notificationTask.id',
    entityPath: `/tasks/{entityId}`,
    openLabel: i18n.t('Open Task'),
    getMessage: (notification) => {
      return getTaskUpdateMessage(notification)
    }
  },
  [NOTIFICATION_TYPES.PAYMENT]: {
    iconClass: 'fa-money-bill',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Requested by'),
    title: i18n.t('Payment Request'),
    entityType: 'payment',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'relationships.notificationPayment.id',
    entityPath: `/payments/{entityId}/invoice`,
    openLabel: i18n.t('Open Payment'),
    getMessage: (notification) => {
      return getPaymentMessage(notification)
    }
  },
  [NOTIFICATION_TYPES.PAYMENT_SUCCESS]: {
    iconClass: 'fa-money-bill',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Paid by'),
    title: i18n.t('Payment Success'),
    entityType: 'payment',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'relationships.notificationPayment.id',
    entityPath: `/payments/{entityId}/invoice`,
    openLabel: i18n.t('Open Payment'),
    getMessage: (notification) => {
      return getPaymentMessage(notification)
    }
  },
  [NOTIFICATION_TYPES.SUBSCRIPTION_SUCCEEDED]: {
    iconClass: 'fa-money-bill',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Paid By'),
    title: i18n.t('Recurring Payment Success'),
    entityType: 'payment',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'relationships.notificationPayment.id',
    entityPath: `/payments/{entityId}/details`,
    openLabel: i18n.t('Open Recurring Payment'),
    getMessage: (notification) => {
      return getRecurringPaymentMessage(notification)
    }
  },
  [NOTIFICATION_TYPES.SUBSCRIPTION_FAILED]: {
    iconClass: 'fa-money-bill',
    iconColorClass: 'text-red-500',
    userLabel: i18n.t('Paid By'),
    title: i18n.t('Recurring Payment Failed'),
    entityType: 'payment',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'relationships.notificationPayment.id',
    entityPath: `/payments/{entityId}/details`,
    openLabel: i18n.t('Open Recurring Payment'),
    getMessage: (notification) => {
      return getRecurringPaymentMessage(notification)
    }
  },
  [NOTIFICATION_TYPES.SUBSCRIPTION_CANCELED]: {
    iconClass: 'fa-money-bill',
    iconColorClass: 'text-red-500',
    userLabel: i18n.t('Cancelled by'),
    title: i18n.t('Recurring Payment Canceled'),
    entityType: 'payment',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'relationships.notificationPayment.id',
    entityPath: `/payments/{entityId}/details`,
    openLabel: i18n.t('Open Recurring Payment'),
    getMessage: (notification) => {
      return getRecurringPaymentMessage(notification)
    }
  },
  [NOTIFICATION_TYPES.SUBSCRIPTION_REQUESTED]: {
    iconClass: 'fa-money-bill',
    iconColorClass: 'text-green-500',
    userLabel: i18n.t('Requested by'),
    title: i18n.t('Recurring Payment Requested'),
    entityType: 'payment',
    entityDescription_path: 'relationships.notificationProject.attributes.name',
    entityId_path: 'relationships.notificationPayment.id',
    entityPath: `/payments/{entityId}/details`,
    openLabel: i18n.t('Open Recurring Payment'),
    getMessage: (notification) => {
      return getRecurringPaymentMessage(notification)
    }
  }
}

export const notificationStatuses = {
  Read: 'read',
  Unread: 'unread',
  Pinned: 'pinned',
}
