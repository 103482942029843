<template>
  <div class="relative lg:inline-block text-left mt-2 lg:mt-0 ">
    <BaseDropdown
      :options="[]"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
      show-empty
    >
      <TopFilterChip
        :isActive="hasActiveOptions"
        :prependLabel="$t('Options')"
        :label="label"
      />
      <template #custom-options>
        <div
          v-if="availableFilters.includes('overdueTasks')"
          class="flex items-center space-x-2 pl-5 pr-8 py-2"
        >
        
          <span class="text-gray-500 text-sm">
            {{ $t('Overdue') }}
            <BaseIconTooltip
              :content="$t('Only show overdue tasks?')"
            />
          </span>
          <BaseSwitch
            v-model="model.overdueTasks"
            @update:modelValue="emit('show-overdue-changed', $event)"
          />
        </div>
        <div
          v-if="availableFilters.includes('dateOnly')"
          class="flex items-center space-x-2 pl-5 pr-8 py-2"
        >
        
          <span class="text-gray-500 text-sm">
            {{ $t('Date only') }}
            <BaseIconTooltip
              :content="$t('Only show tasks with a date?')"
            />
          </span>
          <BaseSwitch
            v-model="model.showDateOnly"
            @update:modelValue="emit('show-date-only-changed', $event)"
          />
        </div>
        <div
          v-if="availableFilters.includes('toggleDetailsView')"
          class="flex items-center space-x-2 pl-5 pr-8 py-2"
        >
          <span class="text-gray-500 text-sm">
            {{ $t('Subtasks') }}
            <BaseIconTooltip
              :content="$t('Show subtasks?')"
            />
          </span>
          <BaseSwitch
            v-model="model.showDetailView"
            @update:modelValue="emit('show-detail-view-changed', $event)"
          />
        </div>
        <div
          v-if="availableFilters.includes('toggleFutureRecurrences')"
          class="flex items-center space-x-2 pl-5 pr-8 py-2"
        >
        
          <span class="text-gray-500 text-sm">
            {{ $t('Recurrences') }}
            <BaseIconTooltip
              :content="$t('Show recurrences?')"
            />
          </span>
          <BaseSwitch
            v-model="model.showRecurrences"
            @update:modelValue="emit('show-recurrences-changed', $event)"
          />
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n';
import { computed, ref, watch } from 'vue';
import TopFilterChip from '@/modules/filters/components/TopFilterChip.vue';

const props = defineProps({
  availableFilters: {
    type: Array,
    default: () => []
  },
  defaultValues: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits([
  'show-overdue-changed',
  'show-detail-view-changed',
  'show-date-only-changed',
  'show-recurrences-changed'
])

const model = ref< {[key: string]: boolean }>({
  showDateOnly: false,
  overdueTasks: false,
  showDetailView: false,
  showRecurrences: false,
})

const totalOptionsAvailable = computed(() => {
  let count = 0
  if (props.availableFilters.includes('overdueTasks')) {
    count++
  }
  if (props.availableFilters.includes('dateOnly')) {
    count++
  }
  if (props.availableFilters.includes('toggleDetailsView')) {
    count++
  }
  if (props.availableFilters.includes('toggleFutureRecurrences')) {
    count++
  }
  return count
})

const activeOptionsCount = computed(() => {
  let count = 0
  if (model.value.showDateOnly && props.availableFilters.includes('dateOnly')) {
    count++
  }
  if (model.value.overdueTasks && props.availableFilters.includes('overdueTasks')) {
    count++
  }
  if (model.value.showDetailView && props.availableFilters.includes('toggleDetailsView')) {
    count++
  }
  if (model.value.showRecurrences && props.availableFilters.includes('toggleFutureRecurrences')) {
    count++
  }
  return count
})

const hasActiveOptions = computed(() => {
  return activeOptionsCount.value > 0
})

const label = computed(() => {
  return `${activeOptionsCount.value}/${totalOptionsAvailable.value}`
}) 

function syncModel() { 
  model.value = {
    showDateOnly: props.defaultValues.showDateOnly || false,
    overdueTasks: props.defaultValues.overdueTasks || false,
    showDetailView: props.defaultValues.showDetailView || false,
    showRecurrences: props.defaultValues.showRecurrences || false,
  }
}

watch(() => props.defaultValues, syncModel, {
  deep: true,
  immediate: true
})
</script>
