<template>
  <BaseNoDataSection
    :title="$t('Could not load embed data')"
    iconClass="fa-file-code"
    :canCreate="true"
  >
    <template #can-create-section>
      <div>{{ $t('Check the embed settings or try to add it again.') }}</div>
      <div v-if="can($actions.CREATE_PROJECT_EMBEDS)">
        <router-link
          :to="`/projects/${this.$route.params.id}/resources/list`"
          class="hover:text-primary-500"
        >
          {{ $t('See all project resources') }}
          <i class="fa-solid fa-arrow-right" aria-hidden="true" />
        </router-link>
      </div>
    </template>    
  </BaseNoDataSection>
</template>
