<template>
  <BaseSelect
    v-bind="$attrs"
    v-model="model"
    :options="currencyOptions"
    :filterable="true"
  />
</template>
<script>
import { ALL_COMMON_CURRENCIES } from "@/modules/payments/enum/paymentEnums.js";

/**
 * By default ALL_COMMON_CURRENCIES are of type
 * {
 *  "symbol": "$",
 * 	"name": "US Dollar",
 *  "symbol_native": "$",
 *  "decimal_digits": 2,
 *  "rounding": 0,
 *  "code": "USD",
 *  "name_plural": "US dollars"
 * }
 */
export default {
  props: {
    modelValue: [String, Number, Object],
    default: () => null
  },
  data() {
    return {
      currencyOptions: ALL_COMMON_CURRENCIES.map((currency, i) => {
        return {
          id: i + 1,
          value: currency.code,
          symbol: currency.symbol,
          label: `${currency.code} (${currency.symbol})`
        }
      })
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)

        let raw = value;
        if (typeof raw !== 'object') {
          raw = this.currencyOptions.find(x => x.value === value)
        }
        this.$emit('raw-change', raw)
      }
    }
  },
  mounted() {
    if (!this.modelValue) {
      return
    }

    const raw = this.currencyOptions.find(x => x.value === this.modelValue)

    this.$emit('raw-change', raw)
  }
}
</script>
