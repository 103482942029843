<template>
  <div
    v-for="i in count"
    :key="i"
    class="relative bg-white p-4 border border-gray-200 rounded-lg group shadow space-y-1.5 overflow-x-hidden"
  >
    <div
      v-for="column of displayColumns"
      :key="column.prop"
      class="text-xs text-gray-400"
      :class="{
        'absolute top-3 right-4': column.field === 'options'
      }"
    >
      <div class="animate-pulse">
        <div class="flex space-x-2">
          <div
            v-if="column.showCardLabel"
            class="bg-gray-100 rounded-md h-5 w-20"
          />
          <div
            :class="[
              column.cardClass,
              getLoadingClasses(column)
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
  count: {
    type: Number,
    default: 5
  }
})

const columns = computed(() => {
  return props.params.columns || []
})

const displayColumns = computed(() => {
  const ignoredColumns = ['add-custom-field']
  return columns.value.filter((c: any) => !c.customField && !ignoredColumns.includes(c.field))
})

function isImage(column: any) {
  const imageProps = ['image', 'avatar', 'allocations']
  return imageProps.some(prop => column?.field?.includes(prop))
}

function isStatus(column: any) {
  return column?.field?.includes('status')
}

function isCompleted(column: any) {
  return column?.field?.includes('options')
}

function isOptions(column: any) {
  return column?.field?.includes('options')
}

function getLoadingClasses(column: any) {
  if (isImage(column)) {
    return 'bg-gray-100 rounded-md h-10 w-10'
  }

  if (isStatus(column)) {
    return 'bg-gray-100 rounded-md h-5 w-16'
  }

  if (isCompleted(column)) {
    return 'bg-gray-100 rounded-full h-7 w-7'
  }

  if (isOptions(column)) {
    return 'bg-gray-100 rounded-md h-7 w-10'
  }

  return 'bg-gray-100 rounded w-20 h-5 min-w-[20px]'
}
</script>
