export enum ProofProgresses {
  NOTIFIED = 'notified',
  OPENED = 'opened',
  COMMENTS = 'commented',
  DECISION = 'completed',
}

export enum ProofsStatuses {
  CHANGES_REQUIRED = 'changes_required',
  APPROVED_WITH_CHANGES = 'approved_with_changes',
  APPROVED = 'approved',
  NOT_RELEVANT = 'not_relevant',
  IN_PROGRESS = 'in_progress',
  PROCESSING = 'processing',
  UPLOADING = 'uploading',
  FAILED = 'failed',
}

export interface Progress {
  opened: boolean;
  notified: boolean;
  commented: boolean;
  completed: boolean;
}

export interface ProofEntity {
  id: number;
  project_id: number | string;
  name: string;
  progress: Progress;
  uuid: string;
  status: string;
  approved_at: string | null;
  created_by: number;
  created_at: string;
  updated_by: number;
  reviewer_ids: (number | string)[];
  notify_users_id: (number | string)[];
  thumbnail_link: string;
  public_link: string;
  decision_status: string;
  proof_id: string;
  decision_made: number;
  decision_total: number;
}






