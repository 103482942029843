<template>
  <BaseCardsView
    :target="EntityTargetTypes.TIME_ENTRIES"
    :loading="timeEntriesLoading"
    dataGetterPath="time/currentTimeEntries"
    groupedDataGetter="time/groupedTimeEntries"
    columnsGetter="time/timeEntryTableColumns"
    :getQuickFilterText="getQuickFilterText"
  >
    <template #no-data>
      <NoTimeEntries
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
    </template>
  </BaseCardsView>
</template>
<script lang="ts" setup>
// Components
import NoTimeEntries from "@/modules/time/components/NoTimeEntries.vue"

// Libs
import { computed, onMounted } from "vue";
import { get } from "lodash-es"

// Utils
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'

// Composables
import { useStore } from "vuex";

const store = useStore()

const timeEntriesLoading = computed(() => {
  return store.state.time.timeEntriesLoading
})

function getQuickFilterText(timeEntry: any) {
  const groupName = get(timeEntry, 'relationships.group.attributes.name', '')
  const userName = get(timeEntry, 'relationships.user.attributes.name', '')
  const projectName = get(timeEntry, 'relationships.project.attributes.name', '')
  const taskName = get(timeEntry, 'relationships.task.attributes.name', '')

  return `${groupName} ${userName} ${projectName} ${taskName}`
}

async function loadData() {
  store.dispatch('time/getAllTimeEntries')
}

onMounted(() => {
  loadData()
})
</script>
