<template>
  <div class="flex items-center">
    <div class="text-gray-700 text-sm font-bold mr-2">
      {{ label }}
    </div>
    <UserChips
      :users="filteredUsers"
      :displayMaxCount="displayMaxCount"
      :actions="[UserPreviewActions.View]"
    />
    <div
      v-if="canEditFields && can(actions.ADD_PROJECT_PEOPLE) || isProjectSaving"
      class="shrink-0 text-gray-400 hover:text-gray-500 cursor-pointer text-xs ml-2"
      @click="onTriggerEdit"
    >
      {{ actionLabel }}
    </div>

    <ProjectUsersDialog
      v-if="showProjectUsersDialog"
      v-model="showProjectUsersDialog"
      :project="project"
      :type="type"
      @close="showProjectUsersDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import UserChips from "@/components/common/UserChips.vue";
import ProjectUsersDialog from "@/modules/projects/components/ProjectUsersDialog.vue";

// Utils
import { computed, PropType, ref } from 'vue';
import {
  UserPreviewActions
} from "@/modules/users/util/userUtils";
import { UserTypes, filterUsersByType } from "@/modules/users/util/userUtils";

import Data = API.Data;
import User = App.Domains.Users.Models.User;
import Project = App.Domains.Projects.Models.Project;

// Composables
import { useAuth } from "@/modules/auth/composables/useAuth";
import useCan from "@/modules/common/composables/useCan.js";
import useLayout from "@/modules/common/composables/useLayout.js";
import i18n from "@/i18n";

const { currentUser } = useAuth()
const {
  can,
  actions
} = useCan()

const {
  useNewLayout
} = useLayout()

const props = defineProps({
  project: {
    type: Object as PropType<Data<Project>>,
    default: () => ({}),
  },
  type: {
    type: String as PropType<UserTypes>,
    default: UserTypes.All,
  },
  canEditFields: {
    type: Boolean,
    default: true,
  },
  isProjectSaving: {
    type: Boolean,
    default: false,
  },
})

const showProjectUsersDialog = ref(false)

const projectUsers = computed<Data<User>[]>(() => {
  if (!props.project?.id) {
    return [
      {
        id: currentUser.value.id,
        attributes: {
          ...currentUser.value,
        }
      }
    ]
  }

  return props.project?.relationships?.users || []
})

const filteredUsers = computed(() => {
  return filterUsersByType(projectUsers.value, props.type)
})


const displayMaxCount = computed(() => {
  if (useNewLayout.value) {
    return 5
  }

  return 3
})

const label = computed(() => {
  if (props.type === UserTypes.Creators) {
    return i18n.t('Creators')
  }

  if (props.type === UserTypes.Collaborators) {
    return i18n.t('Collaborators')
  }

  return i18n.t('People')
})

const actionLabel = computed(() => {
  if (props.type === UserTypes.Creators) {
    return i18n.t('+ Add/Remove Creators')
  }

  if (props.type === UserTypes.Collaborators) {
    return i18n.t('+ Add/Remove Collaborators')
  }

  return i18n.t('+ Add/Remove People')
})

function onTriggerEdit() {
  showProjectUsersDialog.value = true
}
</script>
