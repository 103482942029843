<template>
  <div class="w-full relative base-date-picker">
    <div
      class="absolute top-1/2 -translate-y-1/2 left-1 z-10 pointer-events-none date-picker-value"
      :class="{
        'has-value': hasValue
      }"
    >
      {{ formattedValue }}
    </div>
    <el-date-picker
      v-model="date"
      :key="type"
      :clearable="clearable"
      :type="type"
      :range-separator="rangeSeparator"
      :placeholder="placeholder"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :class="$attrs.class"
      :editable="false"
      :disabled="disabled"
      popper-class="date-picker-popper"
      @change="$emit('change')"
    />
  </div>
</template>
<script>
import {
  getDateWithoutTime,
  DEFAULT_DATE_FORMAT
} from "@/modules/common/utils/dateUtils.js";

export default {
  props: {
    modelValue: {
      type: [Object, String],
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    startPlaceholder: {
      type: String,
      default: ''
    },
    endPlaceholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    remapRange: {
      type: Object,
      default: () => ({
        start: 'start',
        end: 'end',
      })
    },
    type: {
      type: String,
      default: 'date' // year | month | date | dates | datetime | week | datetimerange | daterange | monthrange
    },
    rangeSeparator: {
      type: String,
      default: '→'
    }
  },
  computed: {
    dateFormat() {
      return this.$settings('date_format') || DEFAULT_DATE_FORMAT
    },
    isRange() {
      const rangeTypes = ['daterange', 'datetimerange', 'monthrange']

      return rangeTypes.includes(this.type)
    },
    formattedValue() {
      if (this.isRange) {
        const start = this.getDisplayValue(this.date[0])
        const end = this.getDisplayValue(this.date[1])

        if (!this.hasValue) {
          return this.placeholder || this.$t('Choose date range...')
        }

        return `${start} ${this.rangeSeparator} ${end}`
      }

      return this.getDisplayValue(this.modelValue)
    },
    hasValue() {
      if (this.isRange) {
        return this.date[0] || this.date[1]
      }
      
      return !!this.date
    },
    date: {
      get() {
        if (this.isRange) {
          const start = getDateWithoutTime(this.modelValue?.[this.remapRange.start])
          const end = getDateWithoutTime(this.modelValue?.[this.remapRange.end])
          return [start, end]
        }

        return getDateWithoutTime(this.modelValue)
      },
      set(value) {
        if (this.isRange) {
          
          const start = value?.[0]
          const end = value?.[1]

          const modelValue = {
            [this.remapRange.start]: getDateWithoutTime(start),
            [this.remapRange.end]: getDateWithoutTime(end),
          }
          
          this.$emit('update:modelValue', modelValue)
          return;
        }

        this.$emit('update:modelValue', getDateWithoutTime(value))

        if (this.handleFormChange) {
          this.handleFormChange(value) 
        }
      }
    },
  },
  methods: {
    getDisplayValue(value) {
      if (value) {
        return this.$formatDate(value, this.dateFormat)
      }
      
      return this.placeholder || '- -'
    },
    async setInputWidth() {
      await this.$nextTick()
      
      const input = this.$el?.querySelector('.el-input__wrapper input')

      if (!input) {
        return
      }

      input.style.width = `${(this.formattedValue?.length || 0) - 1.5}ch`
    }
  },
  watch: {
    formattedValue: {
      immediate: true,
      handler() {
        this.setInputWidth()
      }
    }
  },
  mounted() {
    this.setInputWidth()
  },
}
</script>
