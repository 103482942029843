import i18n from "@/i18n";
import store from "@/store";
import { capitalize } from "lodash-es";
import {
  groupIdsToName,
  projectStatusIdToName,
  projectToolIdsToName,
  userIdsToName,
  getDisplayValuesArray,
  getProjectName
} from "@/modules/ai/utils/aiUtils";
import { entityTypes } from "@/modules/common/enum/entityTypes";

import {
  AiFunctions,
  AiFunctionDefinition,
  DisplayProps,
  BindingDefinition,
} from "@/modules/ai/types/aiTypes";
import { formatDate } from "@/modules/common/utils/dateUtils";

import { FilterBuilder } from "@/modules/ai/filters/FilterBuilder";
import { FilterTypes } from "@/components/table/tableUtils";
import { FilterEngine } from "@/modules/ai/filters/FilterEngine";

// #region Add or Update Project

interface ProjectModel extends Record<any, any> {
  id?: string
  name: string
  description: string
  privacy: string
  status_id: string
  user_ids: string[]
  group_ids: string[]
  tool_ids: string[]
  start_date: string
  deadline: string
}

const formattedModelBinding: Record<string, BindingDefinition> = {
  'Id': {
    label: i18n.t('Name'),
    property: 'id',
    type: entityTypes.Project,
    required: true,
  },
  'Project name': {
    label: i18n.t('Name'),
    property: 'name',
    type: 'raw',
  },
  'Description': {
    label: i18n.t('Description'),
    property: 'description',
    type: 'raw'
  },
  'Privacy': {
    label: i18n.t('Privacy'),
    property: 'privacy',
    type: 'capitalize'
  },
  'Status': {
    label: i18n.t('Status'),
    property: 'status_id',
    type: entityTypes.ProjectStatus,
  },
  'People': {
    label: i18n.t('People'),
    property: 'user_ids',
    type: entityTypes.User,
    multiple: true,
  },
  'Groups': {
    label: i18n.t('Groups'),
    property: 'group_ids',
    type: entityTypes.Group,
    multiple: true,
  },
  'Tools': {
    label: i18n.t('Tools'),
    property: 'tool_ids',
    type: 'project_tool',
    multiple: true
  },
  'Start date': {
    label: i18n.t('Start Date'),
    property: 'start_date',
    type: 'date',
  },
  'Deadline': {
    label: i18n.t('Deadline'),
    property: 'deadline',
    type: 'date'
  }
}

export const addOrUpdateProject: AiFunctionDefinition = {
  name: AiFunctions.AddOrUpdateProject,
  entity: entityTypes.Project,
  requiresUserConfirmation: true,
  actionDisplayName: (isEditAction: boolean) => {
    return isEditAction
      ? i18n.t('Update Project')
      : i18n.t('Create Project')
  },
  formattedModelBinding,
  iconClass: 'far fa-inbox text-purple-500'
}

// #endregion

// #region Create project from template

interface CreateProjectFromTemplateModel extends Record<any, any> {
  id: string
  template_id: string
}

function projectFromTemplateToHumanReadable(model: CreateProjectFromTemplateModel): { label: string, displayValue: string }[] {
  const displayProps: DisplayProps = {
    template_id: {
      label: i18n.t('Name'),
      transformer: getProjectName
    },
  }

  return getDisplayValuesArray(model, displayProps)
}


export const createProjectFromTemplate: AiFunctionDefinition = {
  name: AiFunctions.CreateProjectFromTemplate,
  entity: entityTypes.Project,
  requiresUserConfirmation: true,
  modelToHumanReadable: projectFromTemplateToHumanReadable,
  actionDisplayName: (isEditAction: boolean) => {
    return i18n.t('Create Project From Template')
  },
  iconClass: 'far fa-inbox text-purple-500'
}

// #endregion

// #region Get Projects

interface ProjectsSearchModel extends Record<any, any> {
  id: number
  status_id: number
  is_template: number
  name: string
  created_at: string
  date: string
  deadline: string
  privacy: string
}

export function getMappedProjects() {
  // @ts-ignore
  const projects = store.state.projects.allProjects || []
  return projects.map((project: any) => ({
    id: project.id,
    name: project.attributes.name,
    created_at: project.attributes.created_at,
    start_date: project.attributes.start_date,
    deadline: project.attributes.deadline,
    status_id: project.attributes.status_id,
    privacy: project.attributes.privacy,
    is_template: project.attributes.is_template,
  }));
}

export function getProjects(params: ProjectsSearchModel) {
  const {
    id,
    name,
    created_at,
    date,
    is_template,
    deadline,
    status_id,
    privacy
  } = params

  console.log('get all projects', params)

  const allProjects = getMappedProjects()

  let filterBuilder = new FilterBuilder();
  let dynamicFilters = filterBuilder
    .addFilter('name', FilterTypes.Text, name)
    .addFilter('privacy', FilterTypes.Text, privacy)
    .addFilter('id', FilterTypes.Numeric, id)
    .addFilter('status_id', FilterTypes.Numeric, status_id)
    .addFilter('is_template', FilterTypes.Boolean, is_template)
    .addFilter('start_date', FilterTypes.DateRange, date)
    .addFilter('deadline', FilterTypes.DateRange, deadline)
    .addFilter('created_at', FilterTypes.DateRange, created_at)
    .build();

  let filteredProjects = FilterEngine.filter(allProjects, dynamicFilters);

  if (filteredProjects.length > 10) {
    return `There are ${filteredProjects.length} projects. Please narrow your search.`;
  }

  console.log('filteredProjects', filteredProjects)
  return JSON.stringify(filteredProjects);
}

// #endregion

// #region Get Project Users

interface ProjectUsersSearchModel extends Record<any, any> {
  id: number
  project_id: number
  user_id: string
}

function getProjectsUsersBinding() {
  // @ts-ignore
  return store.state.projects.projectUsersBinding;
}

export function getProjectUsers(params: ProjectUsersSearchModel) {
  const {
    project_id,
    user_id,
    id
  } = params

  const binding = getProjectsUsersBinding();
  let filterBuilder = new FilterBuilder();
  let dynamicFilters = filterBuilder
    .addFilter('project_id', FilterTypes.Numeric, project_id)
    .addFilter('user_id', FilterTypes.Numeric, user_id)
    .build();

  let filteredProjects = FilterEngine.filter(binding, dynamicFilters);

  console.log('filteredProjectUsers', {
    filteredProjectUsers: filteredProjects,
    binding,
  });

  if (filteredProjects.length > 10) {
    return `There are ${filteredProjects.length} projects. Please narrow your search.`;
  }

  return JSON.stringify(filteredProjects);
}

// #endregion

// #region Get Project Groups

interface ProjectGroupsSearchModel extends Record<any, any> {
  id: number
  project_id: number
  group_id: string
}

function getProjectsGroupsBinding() {
  // @ts-ignore
  return store.state.projects.projectGroupsBinding;
}

export function getProjectGroups(params: ProjectGroupsSearchModel) {
  const {
    project_id,
    group_id,
    id
  } = params;

  const bindings = getProjectsGroupsBinding();
  let filterBuilder = new FilterBuilder();
  let dynamicFilters = filterBuilder
    .addFilter('project_id', FilterTypes.Numeric, project_id)
    .addFilter('group_id', FilterTypes.Numeric, group_id)
    .build();

  let filteredProjects = FilterEngine.filter(bindings, dynamicFilters);

  if (filteredProjects.length > 10) {
    return `There are ${filteredProjects.length} projects. Please narrow your search.`;
  }

  return JSON.stringify(filteredProjects);
}

// #endregion
