<template>
  <ProofsConfirmDialog
    v-if="showReviewerDialog"
    v-bind="reviewerDialogProps"
    @confirm="addReviewer"
    @extra="viewReadOnly"
    @cancel="goToProofsList"
  />
  <ProofsConfirmDialog
    v-else-if="showCookieDialog"
    v-bind="cookieDialogProps"
    @confirm="cookieAction(true)"
    @cancel="cookieAction"
  />
  <div
    v-if="!showReviewerDialog && !showCookieDialog"
    v-loading="loading"
    class="flex-col-grow w-full h-full"
    :class="{
      'rounded-md shadow': !$useNewLayout
    }"
  >
    <div v-if="!cookieEnabled" />
    <NoEmbedData v-else-if="!embedUrl && !loading" class="h-full flex items-center">
      {{ $t('Could not load the proof') }}
    </NoEmbedData>
    <div
      v-else
      class="flex-col-grow max-h-full"
    >
      <iframe
        class="rounded-md m-auto shadow grow w-full"
        :class="{
          'rounded-md shadow': !$useNewLayout
        }"
        :src="embedUrl"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        @load="iframeLoading = false"
      />
    </div>
  </div>
</template>
<script>
import NoEmbedData from '@/modules/resources/components/NoEmbedData.vue'
import { cookieTest } from '@/modules/common/utils/cookieUtils.js'
import ProofsConfirmDialog from '@/modules/file-proofs/components/ProofsConfirmDialog.vue'

export default {
  components: {
    ProofsConfirmDialog,
    NoEmbedData,
  },
  data() {
    return {
      showCookieDialog: false,
      showReviewerDialog: false,
      iframeLoading: true,
      embedUrl: '',
      custom_styles: `<style>
        html, body {
          margin: 0;
          height: calc(100% - 2px);
        }

        iframe {
          width: 100%;
          height: 100%;
        }
      </style>`,
      cookieEnabled: null
    }
  },
  watch: {
    publicEmbedUrl: {
      async handler(val) {
        if (this.embedUrl || !val) {
          return
        }
        await this.checkIfIsReviewer()
      },
      immediate: true,
    },
    embedUrl() {
      this.displayCookieDialog()
    },
    cookieChecked() {
      this.displayCookieDialog()
    },
  },
  computed: {
    cookieChecked() {
      return this.cookieEnabled !== null
    },
    proofId() {
      return this.$route.params?.proofId
    },
    proofs() {
      return this.$store.state.proofs.proofs?.data || []
    },
    currentProof() {
      return this.proofs.find(proof => String(proof?.id) === String(this.proofId))
    },
    publicEmbedUrl() {
      return this.currentProof?.attributes?.public_link || ''
    },
    loading() {
      return !this.embedUrl || this.iframeLoading
    },
    projectId() {
      return this.$route?.params?.id
    },
    reviewerDialogProps() {
      return {
        title: this.$t('You’re not a reviewer of this proof.'),
        confirmText: this.$t('Yes'),
        extraText: this.$t('View as read only'),
        extraTooltip: this.$t('This will let you view the proof as read only. You won’t be added to the reviewers list so you won’t be able to comment or make decisions.'),
        description: this.$t('Would you like to be added to the reviewers list so you can access the proof, review, comment & make decisions? This process might take up to 10 seconds.'),
      }
    },
    cookieDialogProps() {
      return {
        title: this.$t('Open in a new tab'),
        description: this.$t('This proof requires 3rd party cookies to be enabled in your browser settings to open in the app. However, you currently have this setting disabled. Therefore, to view this proof please click the button below to open it in a new tab.'),
        confirmText: this.$t('Open in new tab'),
      }
    },
  },
  methods: {
    goToProofsList() {
      this.$router.push(`/projects/${this.projectId}/file-proofs/list`)
    },
    async reviewerProofUrl() {
      return await this.$store.dispatch('proofs/getProofReviewUrl', this.proofId)
    },
    async addReviewer() {
      this.showReviewerDialog = false
      await this.$store.dispatch('proofs/addAsReviewer', this.proofId)
      this.embedUrl = await this.reviewerProofUrl()
    },
    async viewReadOnly() {
      this.showReviewerDialog = false
      this.embedUrl = this.publicEmbedUrl
    },
    cookieAction(confirmed) {
      this.showCookieDialog = false
      if (confirmed === true) {
        window.open(this.embedUrl, '_blank')
      }
      this.goToProofsList()
    },
    async checkIfIsReviewer() {
      const isReviewer = this.currentProof?.attributes?.reviewer_ids?.some(id => String(id) === String(this.$user?.id))
      if (isReviewer) {
        this.embedUrl = await this.reviewerProofUrl()
        return
      }

      this.showReviewerDialog = true
    },
    async displayCookieDialog() {
      if (this.cookieEnabled || !this.cookieChecked || !this.embedUrl) {
        return
      }

      this.showCookieDialog = true
    },
    async checkHasCookiesEnabled() {
      this.cookieEnabled = await cookieTest()
    }
  },
  created() {
    this.checkHasCookiesEnabled()
  }
}
</script>

<style scoped lang="scss">
:deep(.el-loading-mask) {
  @apply opacity-70 bg-transparent;
}
</style>
