<template>
  <div
    :class="{
      '-mx-4 sm:-mx-8 bg-gray-100 sm:h-[calc(100vh-140px)] overflow-y-auto': $useNewLayout,
      'py-4 px-4 sm:px-8': $useNewLayout && hasData,
    }"
  >
    <div
      v-if="loading"
      class="w-full grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
    >
      <EntityLoadingCards
        :params="{
          columns: cardColumns
        }"
        :count="10"
      />
    </div>
    <InfiniteScroll
      v-else
      :load-next="loadNextPage"
      direction="down"
      loaderClass="mt-6"
    >
      <div class="space-y-8">
        <div
          v-for="(key) of groupedEntries.keys()"
          :key="key"
        >
          <GroupByTitle
            :target="target"
            :columns="cardColumns"
            :data="groupedEntries.get(key)"
            :keyValue="key"
            collapsable
            :initCollapsed="!!groupByProp"
            @toggle-collapsed="isCollapsed => isCollapsed && triggerVisibilityCheck()"
          >
            <div class="w-full grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              <EntityCard
                v-for="entity of groupedEntries.get(key)"
                :key="entity.id"
                :params="{
                  data: entity,
                  columns: cardColumns
                }"
              />
            </div>
          </GroupByTitle>
        </div>
      </div>
    </InfiniteScroll>
    <slot
      v-if="!hasData"
      name="no-data"
    />
  </div>
</template>
<script setup lang="ts">
// Components
import EntityCard from "@/components/table/EntityCard.vue";
import GroupByTitle from "@/modules/filters/components/GroupByTitle.vue";
import InfiniteScroll from "@/components/common/InfiniteScroll.vue";
import EntityLoadingCards from "@/components/table/EntityLoadingCards.vue";

// Libs
import { PropType, computed, nextTick, ref, watch } from "vue";

// Helpers
import { TableColumn, columnBuilder } from '@/components/table/tableUtils'
import { agGridColumnMapper } from '@/components/table/tableUtils'

// Composables
import useSortedAndFilteredData from "@/modules/common/composables/useSortedAndFilteredData";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const props = defineProps({
  target: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  dataGetterPath: {
    type: String,
    required: true,
  },
  groupedDataGetter: {
    type: String,
    default: '',
  },
  columnsGetter: {
    type: String,
    default: '',
  },
  getQuickFilterText: {
    type: Function as PropType<(entity: any) => string>,
    default: (entity: any): string => entity?.attributes?.name,
  },
  extraFilters: {
    type: Array,
    default: () => [],
  },
  ignoredColumns: {
    type: Array,
    default: null,
  },
})

const store = useStore()
const route = useRoute()
const { sortedAndFilteredData } = useSortedAndFilteredData({
  target: props.target,
  extraFilters: props.extraFilters,
  dataGetterPath: props.dataGetterPath,
  columnsGetter: props.columnsGetter,
  getQuickFilterText: props.getQuickFilterText,
})

const groupByProp = computed(() => {
  return store.getters['filters/targetLocalFilters'](props.target)?.groupBy?.[0]
})

const groupedEntries = ref<Map<any, any[]>>(new Map())
const perPage = ref(25)
const currentPage = ref(1)
const infiniteScrollState = ref<any>(null)

const displayCount = computed(() => {
  return perPage.value * currentPage.value
})

const displayData = computed(() => {
  return sortedAndFilteredData.value.slice(0, displayCount.value)
})

const groupedData = computed(() => {
  if (!props.groupedDataGetter) {
    const map = new Map()
    map.set('default', displayData.value)
    return map
  }

  return store.getters[props.groupedDataGetter](displayData.value)
})

const canDisplayMore = computed(() => {
  return displayCount.value < sortedAndFilteredData.value.length
})

const hasData = computed(() => {
  return props.loading || sortedAndFilteredData.value.length
})

const visibleColumns = computed(() => {
  let columns = store.getters[props.columnsGetter] || []

  columns = columnBuilder.filterDisabledColumns(columns)

  columns = columnBuilder.remapDynamicProperties(columns)

  return columns
})

const cardColumns = computed(() => {
  if (!props.ignoredColumns?.length) {
    return visibleColumns.value.map(agGridColumnMapper)
  }

  return visibleColumns.value
    .filter((column: TableColumn) => !props.ignoredColumns.includes(column.prop || column.field as string))
    .map(agGridColumnMapper)
})

const triggeredVsibilityCheck = ref(false)


async function loadNextPage($state: any) {
  if (props.loading) {
    return
  }

  if (!infiniteScrollState.value) {
    infiniteScrollState.value = $state
  }

  currentPage.value++

  await nextTick()

  if (groupByProp.value && !triggeredVsibilityCheck.value) {
    triggerVisibilityCheck()
    triggeredVsibilityCheck.value = true
  }

  if (!canDisplayMore.value) {
    infiniteScrollState.value?.complete()
  }
}

function triggerVisibilityCheck() {
  infiniteScrollState.value?.checkVisibility()
}

watch(() => route.query?.filters, triggerVisibilityCheck)

async function resetState() {
  await nextTick()

  currentPage.value = 1
  infiniteScrollState.value?.reset()
}

watch(() => sortedAndFilteredData.value, () => {
  resetState()
})

async function syncGroupedData() {
  await nextTick()

  groupedEntries.value = groupedData.value
}

watch(() => groupedData.value, async () => {
  await nextTick()
  if (!route.path.includes('card')) {
    return
  }

  syncGroupedData()
})
</script>
