<template>
  <div
    class="bg-white"
    :class="{
      'shadow rounded-lg sm:px-10 mt-4 py-6 px-4': !light
    }"
  >
    <div>
      <div
        class="gap-3 justify-center"
        :class="{
          'grid grid-cols-2': !isRegister
        }"
      >
        <div class="col-span-2 sm:col-span-1">
          <a :href="getSocialLink(socialProviders.GOOGLE)"
             class="w-full inline-flex justify-center items-center px-4 py-2 border border-gray-200 hover:border-red-500 rounded-md bg-white hover:bg-red-500 text-sm font-medium text-gray-400 hover:text-white group"
          >
            <i class="fab fa-google text-gray-500 group-hover:text-white text-base mr-2"></i>
            {{ getButtonText(socialProviders.GOOGLE) }}
          </a>
        </div>

        <div
          v-if="!isRegister"
          class="col-span-2 sm:col-span-1"
        >
          <a :href="getSocialLink(socialProviders.FACEBOOK)"
             class="w-full inline-flex justify-center items-center px-4 py-2 border border-gray-200 hover:border-blue-900 rounded-md bg-white hover:bg-blue-900 text-sm font-medium text-gray-400 hover:text-white group"
          >
            <i class="fab fa-facebook-f text-gray-500 group-hover:text-white text-base mr-2"></i>
            {{ getButtonText(socialProviders.FACEBOOK) }}
          </a>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import config from "@/modules/common/config.js";
import store from "@/store/index.js";
import { startCase } from "lodash-es";

export default {
  props: {
    isRegister: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      socialProviders: {
        GOOGLE: 'google',
        FACEBOOK: 'facebook',
      }
    }
  },
  methods: {
    getSocialLink(provider) {
      const templateToShare = store.state.templates.templateToShare
      const socialLink = new URL(`${config.ROOT_URL}/auth/login/${provider}`)

      const returnUrl = `https://${config.getTenantHost()}`
      socialLink.searchParams.set('returnUrl', returnUrl)

      if (templateToShare) {
        socialLink.searchParams.append('share_template', templateToShare)
      }
      return socialLink.href
    },
    getButtonText(provider) {
      provider = startCase(provider)
      if (this.isRegister) {
        return this.$t(`Register with ${provider}`)
      }
      return this.$t(`Sign in with ${provider}`)
    }
  }
}
</script>
