<template>
  <div class="w-full flex items-center justify-center">
    <i :class="iconClass" />
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const iconClass = computed(() => {
  return props.params.iconClass
})
</script>
