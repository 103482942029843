<template>
  <BaseBadge
    v-if="status"
    :color="status?.attributes?.color"
    class="my-0"
  >
    {{ status?.attributes?.name }}
  </BaseBadge>
  <span v-else>- -</span>
</template>
<script>
export default {
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({
        target: 'project',
      }),
    },
  },
  computed: {
    taskStatuses() {
      return this.$store.getters['tasks/orderedStatuses'] || []
    },
    projectStatuses() {
      return this.$store.getters['projects/orderedStatuses'] || []
    },
    status_id() {
      return this.row?.attributes?.status_id
    },
    isProjectOrTemplateTarget() {
      return [
        'project',
        'projects',
        'template',
        'templates'
      ].includes(this.params.target)
    },
    status() {
      if (this.isProjectOrTemplateTarget) {
        return this.projectStatuses.find(status => status?.id?.toString() === this.status_id?.toString())
      }

      return this.taskStatuses.find(status => status?.id?.toString() === this.status_id?.toString())
    },
  },
}
</script>
