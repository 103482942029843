<template>
  <HtmlEditor
    v-model="newComment"
    v-model:json="commentJSON"
    :char-limit="MessageCharLimit"
    :projectId="projectId"
    :placeholder="editorPlaceholder"
    :cleanupFilesOnDestroy="true"
    :autofocus="false"
    :isSendingComment="isSendingComment"
    :infoText="editorInfoText"
    :commands-ids="[entityTypes.Reference]"
    layout="box"
    @send="sendComment"
  />
</template>
<script>
import i18n from "@/i18n.js"
import { debounce } from 'lodash-es'
import { MessageCharLimit } from "@/modules/projects/utils/discussionUtils.js";
import { getFileNodes, getMentionNodes } from "@/components/html/util/tipTapUtils.js";
import { entityTypes } from '@/modules/common/enum/entityTypes'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: 'html-comment-input'
    },
    placeholder: {
      type: String,
      default: ''
    },
    usersTyping: {
      type: Array,
      default: () => [],
    },
    sendAction: {
      type: Function,
    },
    isConnectedToChannel: {
      type: Boolean,
      default: false,
    },
    notifyIsTyping: {
      type: Function,
    },
    infoText: {
      type: String,
      default: ''
    },
    projectId: {
      type: [String, Number],
      default: null
    }
  },
  emits: ['send'],
  data() {
    return {
      entityTypes,
      newComment: '',
      commentJSON: {},
      MessageCharLimit,
      isSendingComment: false,
    }
  },
  computed: {
    editorInfoText() {
      if (this.usersTyping?.length) {
        return this.usersTyping.map(user => `${user.name} ${i18n.t('is typing...')}`).join(`, `)
      }

      return this.infoText
    },
    editorPlaceholder() {
      if (this.placeholder) {
        return this.placeholder
      }

      if (this.$isMobileDevice) {
        return i18n.t('Enter your comment...')
      }

      return i18n.t('Enter your comment, type @ to mention someone or type / for options…')
    }
  },
  methods: {
    async sendComment(message) {
      try {
        if (!message) {
          return
        }

        if (!this.sendAction || typeof this.sendAction !== 'function') {
          return
        }

        this.isSendingComment = true

        const mentionNodes = getMentionNodes(this.commentJSON)
        const userIds = mentionNodes
          .map(node => +node.attrs.id)
          .filter(id => id !== undefined)

        await this.sendAction({
          message,
          notifiable_user_ids: userIds,
        })
        this.newComment = ''

        const fileNodes = getFileNodes(this.commentJSON)
        if (fileNodes.length) {
          this.$success(i18n.tc('files added to project', { count: fileNodes.length}))
        }
      }
      catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Comment could not be sent, please try again.'))
        throw err
      }
      finally {
        this.isSendingComment = false
      }
    }
  },
  mounted() {
    if (this.notifyIsTyping) {
      this.debouncedNotifyIsTyping = debounce(this.notifyIsTyping, 300)
    }
  },
  watch: {
    newComment(value) {
      if (!this.isConnectedToChannel) {
        return
      }
      if (this.debouncedNotifyIsTyping) {
        this.debouncedNotifyIsTyping(value !== '')
      }
    }
  }
}
</script>
