<template>
  <BaseInlineTextareaEdit
    v-bind="$attrs"
    class="leading-6 text-gray-500 text-base bulk-create-tasks-input flex-1 h-full"
    :placeholder="$t('Enter up to 100 task names here. Add 1 task name per line. When you submit all tasks will be created.')"
    scrollable
  />
</template>
<script>
import BaseInlineTextareaEdit from "@/components/form/BaseInlineTextareaEdit.vue";

export default {
  components: { BaseInlineTextareaEdit }
}
</script>
<style lang="scss">
.bulk-create-tasks-input {
  textarea {
    @apply h-full;
  }
}
</style>
