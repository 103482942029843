import i18n from '@/i18n'
import { computed, ComputedRef } from 'vue'
import Data = API.Data;
import { ProofEntity, ProofProgresses, ProofsStatuses } from "@/modules/file-proofs/utils/proofModels";

export const ProofProgressLabels = {
  [ProofProgresses.NOTIFIED]: i18n.t('Sent'),
  [ProofProgresses.OPENED]: i18n.t('Opened'),
  [ProofProgresses.COMMENTS]: i18n.t('Comments made'),
  [ProofProgresses.DECISION]: i18n.t('Decision made'),
}

export const ProofsStatusLabels = {
  [ProofsStatuses.CHANGES_REQUIRED]: i18n.t('Changes required'),
  [ProofsStatuses.APPROVED_WITH_CHANGES]: i18n.t('Approved with changes'),
  [ProofsStatuses.APPROVED]: i18n.t('Approved'),
  [ProofsStatuses.NOT_RELEVANT]: i18n.t('Not relevant'),
  [ProofsStatuses.IN_PROGRESS]: i18n.t('In progress'),
  [ProofsStatuses.PROCESSING]: i18n.t('Processing'),
  [ProofsStatuses.UPLOADING]: i18n.t('Uploading'),
  [ProofsStatuses.FAILED]: i18n.t('Failed'),
}

export interface ProofStatusEntity {
  order: number
  color: string
  icon: string
  name: string
  value: ProofsStatuses
}

export const proofStatuses: ComputedRef<Data<ProofStatusEntity>[]> = computed(() => [
  {
    id: 1,
    attributes: {
      order: 5,
      color: '#ff605c',
      icon: 'fa-refresh',
      name: ProofsStatusLabels[ProofsStatuses.CHANGES_REQUIRED],
      value: ProofsStatuses.CHANGES_REQUIRED
    }
  },
  {
    id: 2,
    attributes: {
      order: 6,
      color: '#2edbb0',
      icon: 'fa-check-circle',
      value: ProofsStatuses.APPROVED_WITH_CHANGES,
      name: ProofsStatusLabels[ProofsStatuses.APPROVED_WITH_CHANGES]
    }
  },
  {
    id: 3,
    attributes: {
      order: 7,
      color: '#2edbb0',
      icon: 'fa-check-circle',
      name: ProofsStatusLabels[ProofsStatuses.APPROVED],
      value: ProofsStatuses.APPROVED
    }
  },
  {
    id: 4,
    attributes: {
      order: 4,
      color: '#39454d',
      icon: 'fa-circle-o',
      name: ProofsStatusLabels[ProofsStatuses.NOT_RELEVANT],
      value: ProofsStatuses.NOT_RELEVANT
    }
  },
  {
    id: 4,
    attributes: {
      order: 1,
      color: '#9CA3AF',
      icon: 'fa-solid fa-spinner animate-spin',
      name: ProofsStatusLabels[ProofsStatuses.PROCESSING],
      value: ProofsStatuses.PROCESSING
    }
  },
  {
    id: 5,
    attributes: {
      order: 3,
      color: '#fab63a',
      icon: 'fa-clock-o',
      name: ProofsStatusLabels[ProofsStatuses.IN_PROGRESS],
      value: ProofsStatuses.IN_PROGRESS
    }
  },
  {
    id: 6,
    attributes: {
      order: 2,
      color: '#9CA3AF',
      icon: 'fa-upload',
      name: ProofsStatusLabels[ProofsStatuses.UPLOADING],
      value: ProofsStatuses.UPLOADING
    }
  },
  {
    id: 7,
    attributes: {
      order: 7,
      color: '#ff605c',
      icon: 'fa-circle-exclamation',
      name: ProofsStatusLabels[ProofsStatuses.FAILED],
      value: ProofsStatuses.FAILED
    }
  },
])

export function setProofStatus(p) {
  if (p.attributes.decision_status) {
    return p
  }
  const { status } = p.attributes
  if ([ProofsStatuses.FAILED, ProofsStatuses.UPLOADING].includes(status)) {
    p.attributes.decision_status = status
    return p
  }
  p.attributes.decision_status = ProofsStatuses.PROCESSING;
  return p
}

export function getProofStatus(status: string) {
  return proofStatuses.value.find(s => s.attributes?.value === status)
}

interface ProofModel extends ProofEntity {
  file_id: string
  file: any
}

export function proofModelToFormData(payload: ProofModel) {
  const formData = new FormData()

  formData.append('name', payload.name)
  formData.append('project_id', payload.project_id as string)

  if(payload?.file?.raw) formData.append('file', payload.file.raw)
  if(payload?.file_id) formData.append('file_id', payload.file_id)
  payload.reviewer_ids.forEach(id => formData.append('reviewer_ids[]', id as string))
  payload.notify_users_id.forEach(id => formData.append('notify_users_id[]', id as string))

  return formData
}
