// Libs
import { computed, nextTick } from "vue";
import { useStore } from "vuex";
import { ActionChoiceToFunction, ActionChoices, AiFunctions, ChatMessage, ChatMessageTypes } from "@/modules/ai/types/aiTypes";
import AuthService from "@/modules/auth/services/AuthService";
import config from "@/modules/common/config";

import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import { error } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";

import { getSystemMessage } from "@/modules/ai/utils/aiActionUtils";
import { functions } from "@/modules/ai/composables/useChatbox";

import { Activities, trackPostHog } from "@/modules/common/utils/trackingUtils";

const CONTENT_DONE = '[DONE]'

export function useActionChat(aiModel: string = 'gpt-4o') { // 'gpt-3.5-turbo' | 'gpt-4-turbo-preview'
  const store = useStore();

  function addChatMessage(chatMessage: ChatMessage) {
    store.commit('ai/addActionModeMessage', chatMessage);
  }

  const messages = computed(() => {
    return store.state.ai.actionModeMessages || []
  })

  const selectedAction = computed<ActionChoices>({
    get() {
      return store.state.ai.actionModeAction
    },
    set(value: ActionChoices) {
      store.commit('ai/setActionModeAction', value)
    }
  })

  const awaitingResponse = computed({
    get() {
      return store.state.ai.awaitingResponse
    },
    set(value: boolean) {
      store.commit('ai/setAwaitingResponse', value)
    }
  })

  async function scrollToBottom() {
    await nextTick()
    const el = document.getElementById('action-discussion-bottom');
    if (el) {
      el.scrollIntoView();
    }
  }

  function getMessageHistory() {
    return messages.value
      .filter((message: ChatMessage) => [
          ChatMessageTypes.User,
          ChatMessageTypes.UserHidden,
          ChatMessageTypes.Assistant,
          ChatMessageTypes.AssistantHidden,
          ChatMessageTypes.UserConfirmation,
        ].includes(message.type)
      )
      .map((message: ChatMessage) => {
        const role = [ChatMessageTypes.User, ChatMessageTypes.UserHidden].includes(message.type)
          ? ChatMessageTypes.User
          : ChatMessageTypes.Assistant

        return {
          role,
          content: message.message
        }
      })
  }

  let requestController = null

  function parseFunctionCall(message: string, jsonModel: any) {
    const actionChoice = selectedAction.value as ActionChoices
    const functionName = ActionChoiceToFunction[actionChoice] as AiFunctions
    const functionDefinition = functions[functionName]

    const isEditAction = [
      ActionChoices.UpdateProject,
      ActionChoices.UpdateTasks,
    ].includes(actionChoice)

    if (isEditAction) {
      const nameProps: Partial<Record<ActionChoices, string>> = {
        [ActionChoices.UpdateProject]: 'Project name',
        [ActionChoices.UpdateTasks]: 'Task name',
      }

      const nameProp = nameProps[actionChoice]

      if (!nameProp) {
        throw ({
          message: 'Name prop not found',
          actionChoice,
          nameProps,
        })
      }

      if (Array.isArray(jsonModel)) {
        jsonModel.forEach((model: any) => {
          model['Id'] = model[nameProp]
          delete model[nameProp]
        })
      }
      else {
        jsonModel['Id'] = jsonModel[nameProp]
        delete jsonModel[nameProp]
      }
    }

    addChatMessage({
      type: ChatMessageTypes.UserConfirmation,
      message,
      data: {
        functionName,
        actionDisplayName: functionDefinition.actionDisplayName?.(isEditAction),
        actionChoice,
        initialModel: jsonModel
      },
      date: new Date()
    })
  }


  function getJsonModel(generatedContent: string) {
    try {
      return JSON.parse(generatedContent)
    }
    catch (err) { }

    const isJson = generatedContent.startsWith('```json') || generatedContent.startsWith('[') || generatedContent.startsWith('{')
    if (!isJson) {
      return null
    }

    if (generatedContent.startsWith('```json')) {
      generatedContent = generatedContent.replace('```json', '').replace('```', '')
    }

    try {
      return JSON.parse(generatedContent)
    }
    catch (err) {
      return {
        invalidJson: true
      }
    }
  }

  function processMessage(message: string) {
    let jsonModel: any = getJsonModel(message)

    // If we can't parse the JSON, we assume it's a simple text message
    if (!jsonModel)  {
      addChatMessage({
        type: ChatMessageTypes.Assistant,
        message,
        date: new Date(),
      })

      return
    }

    // AI tries to reply with a JSON (wrapped using markdown between ```json... ```) but it's invalid
    if (jsonModel.invalidJson) {
      addChatMessage({
        type: ChatMessageTypes.AssistantHidden,
        message,
        date: new Date(),
      })

      sendRequest('Try again, only include the JSON in your reply', ChatMessageTypes.UserHidden)
      return
    }

    parseFunctionCall(message, jsonModel)
  }

  function getNewContent(event: EventSourceMessage) {
    try {
      if (event.data === CONTENT_DONE) {
        return CONTENT_DONE
      }

      const data = JSON.parse(event.data)
      const content = data?.choices[0]?.delta?.content || ''

      return content
    } catch (err) {
      console.log('Error parsing data from BE', err)
      console.warn(err)
    }
  }

  async function sendRequest(promptMarkdown: string, messageType = ChatMessageTypes.User) {
    promptMarkdown = promptMarkdown.trim()

    if (!promptMarkdown) {
      error(i18n.t('Please enter a prompt'))
      return
    }

    let responseMessage = ''

    try {
      const systemMessage = getSystemMessage(selectedAction.value)

      let messages = [
        {
          role: ChatMessageTypes.System,
          content: systemMessage,
        }
      ]

      addChatMessage({
        type: messageType,
        message: promptMarkdown,
        date: new Date(),
      })

      scrollToBottom()

      const messageHistory = getMessageHistory()

      messages = messages.concat(messageHistory)

      const apiUrl = `${config.getApiHost()}/gpt-prompt`
      const token = AuthService.getToken();
      requestController = new AbortController();

      awaitingResponse.value = true

      trackPostHog(Activities.AiActionMessageSent)

      await fetchEventSource(apiUrl, {
        method: 'POST',
        body: JSON.stringify({
          model: aiModel,
          messages,
          stream: true,
        }),
        signal: requestController.signal,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${token}`
        },
        onmessage: async (event) => {
          let newContent = getNewContent(event)
          if (!newContent) {
            return
          }

          if (newContent === CONTENT_DONE) {
            awaitingResponse.value = false

            processMessage(responseMessage)
            scrollToBottom()
            return
          }

          responseMessage += newContent
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  return {
    addChatMessage,
    sendRequest,
    scrollToBottom,
    messages,
    selectedAction,
    awaitingResponse,
  }
}
