<template>
  <BaseNoDataSection
    :title="$t('No AppSumo Codes submitted')"
    iconClass="fa-tags"
    :canCreate="true"
  >
    <template #can-create-section>
      <div>{{ $t('You can add new codes using the form below') }} </div>
    </template>
  </BaseNoDataSection>
</template>
<script setup />
