<template>
  <li>
    <div class="flex items-center space-x-4">
      <div class="shrink-0">
        <BaseLogo :entity="group"/>
      </div>
      <div class="flex-1 min-w-0">
        <p class="font-bold text-gray-700 truncate">
          {{ group?.attributes?.name }}
        </p>
      </div>
      
      <BaseDropdown
        :options="groupOptions"
        :label="$t('Options')"
        trigger="hover"
        button-size="sm"
        divide-items
      />

    </div>
  </li>
</template>
<script>
// Helpers
import apiCache from '@/modules/common/utils/apiCache';

export default {
  name: 'ProjectGroup',
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    groupOptions() {
      return [
        {
          label: this.$t('Remove from project'),
          requiresPermissionTo: this.$actions.REMOVE_PROJECT_GROUPS,
          action: () => this.removeGroup(),
        }
      ]
    },
    projectGroupIds() {
      return this.project?.attributes?.group_ids || []
    }
  },
  methods: {
    async removeGroup() {
      const newGroupIds = this.projectGroupIds.filter(id => +id !== +this.group.id) || []
      const data = {
        ...this.project?.attributes,
        group_ids: newGroupIds
      }

      delete data.custom_fields
      await this.$store.dispatch('projects/editProject', {
        data,
        project: this.project,
      })

      // We sync groups to display the correct projects count in the list view
      await this.$store.dispatch('groups/syncGroups', [ this.group.id ])
      apiCache.removeForEntity('groups')
    },
  },
}
</script>
