<template>
  <BaseCardsView
    :target="EntityTargetTypes.ALLOCATED_TIME"
    :loading="allocatedTimeLoading"
    dataGetterPath="time/currentAllocatedTime"
    groupedDataGetter="time/groupedAllocatedTime"
    columnsGetter="time/allocatedTimeTableColumns"
    :getQuickFilterText="getQuickFilterText"
  >
    <template #no-data>
      <NoAllocatedTime
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
    </template>
  </BaseCardsView>
</template>
<script lang="ts" setup>
// Components
import NoAllocatedTime from "@/modules/time/components/NoAllocatedTime.vue"

// Libs
import { computed, onMounted } from "vue";
import { get } from "lodash-es"

// Utils
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'

// Composables
import { useStore } from "vuex";

const store = useStore()

const allocatedTimeLoading = computed(() => {
  return store.state.time.allocatedTimeLoading
})

async function loadData() {
  store.dispatch('time/getAllAllocatedTime')
}

function getQuickFilterText(allocatedTime: any) {
  const groupName = get(allocatedTime, 'relationships.group.attributes.name', '')
  const userName = get(allocatedTime, 'relationships.user.attributes.name', '')
  const projectName = get(allocatedTime, 'relationships.project.attributes.name', '')
  const taskName = get(allocatedTime, 'relationships.task.attributes.name', '')

  return `${groupName} ${userName} ${projectName} ${taskName}`
}

onMounted(() => {
  loadData()
})
</script>
