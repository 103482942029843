import store from '@/store';
import { customBrandingKeys } from "@/modules/accounts/utils/accountBrandingUtils";
import { transformToRestifyArray } from "@/modules/common/utils/dataUtils";

export function getSetting(key, defaultValue) {
  if (customBrandingKeys.includes(key) && store.state.accounts?.customBranding?.hasOwnProperty(key)) {
    return store.state.accounts.customBranding[key] || defaultValue
  }
  
  const setting = store.getters['accounts/getSettingByKey'](key)
  if (!setting) {
    return defaultValue
  }

  return store.getters['accounts/getSettingByKey'](key)?.attributes?.value
}

export function getAccountDefaultViews() {
  let accountDefaultViews = getSetting('default_view') || []
  if (typeof accountDefaultViews === 'string') {
    try {
      accountDefaultViews = JSON.parse(accountDefaultViews)
    } catch (err) {
      accountDefaultViews = []
    }
  }
  return transformToRestifyArray(accountDefaultViews)
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$settings = getSetting
  }
}
