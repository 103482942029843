<template>
  <div>
    <i
      v-if="isProjectClosed"
      :title="tooltipContent"
      class="cursor-pointer fas fa-lock mr-2 text-red-600"
    />
    <i
      v-if="showIcon"
      class="fas fa-inbox text-purple-500 mr-2"
    />

    <router-link :to="`/projects/${project?.id}`">
      {{ project?.attributes?.name || '- -' }}
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    project() {
      return this.params.data?.relationships?.project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    tooltipContent() {
      if (!this.params?.entity_type) {
        return this.$t('This project is completed. In order to make changes, please reopen it first.')
      }

      return this.$tc('cannot change entity of completed project', {
        entity_type: this.params?.entity_type
      })
    }
  }
}
</script>
