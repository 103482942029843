<template>
  <p>
    <span v-if="resultLabel">
      {{ resultLabel }}
    </span>
    <router-link
      class="ml-2 underline"
      :to="actionResult.url"
      @click="closeAiDialog"
    >
      {{ urlLabel }}
    </router-link>

    <div
      v-if="showStartNewAction"
      class="mt-2"
    >
      {{ $t('Or, ') }}
      <span
        class="underline text-primary-500 cursor-pointer font-semibold"
        @click="startNewAction"
      >
      {{ $t('click here to start a new action.') }}
      </span>
    </div>
  </p>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { PropType, computed } from "vue";
import {
  ActionResult
} from "@/modules/ai/types/aiTypes";

// Composables
import { useStore } from "vuex";
import { useActionChat } from "@/modules/ai/composables/useActionChat";

const store = useStore()

const {
  scrollToBottom,
} = useActionChat()

const props = defineProps({
  actionResult: {
    type: Object as PropType<ActionResult>,
    default: '',
  },
  showStartNewAction: {
    type: Boolean,
    default: true,
  },
})

const actionLabelFormatters: Record<string, any> = {
  task: {
    create: (result: ActionResult) => {
      return i18n.t('Task created succcessfully.')
    },
    update: (result: ActionResult) => {
      return i18n.t('Task updated succcessfully.')
    },
    bulkCreate: (result: ActionResult) => {
      return i18n.t('Tasks created succcessfully.')
    },
    bulkEdit: (result: ActionResult) => {
      return i18n.t('Tasks updated succcessfully.')
    },
  },
  group: {
    create: (result: ActionResult) => {
      return i18n.t('Group created succcessfully.')
    },
    update: (result: ActionResult) => {
      return i18n.t('Group updated succcessfully.')
    },
  },
  project: {
    create: (result: ActionResult) => {
      return i18n.t('Project created succcessfully.')
    },
    update: (result: ActionResult) => {
      return i18n.t('Project updated succcessfully.')
    },
  },
  note: {
    create: (result: ActionResult) => {
      return i18n.t('Note created succcessfully.')
    },
    update: (result: ActionResult) => {
      return i18n.t('Note updated succcessfully.')
    },
  },
}

const resultLabel = computed(() => {
  if (!props.actionResult || !props.actionResult.action || !props.actionResult.entity) {
    return null
  }

  return actionLabelFormatters[props.actionResult.entity]?.[props.actionResult.action]?.(props.actionResult)
})

const urlLabel = computed(() => {
  if (props.actionResult.urlLabel) {
    return props.actionResult.urlLabel
  }

  return i18n.tc(`click to open entity`, {
    entityType: props.actionResult.entity,
    entityName: props.actionResult.name
  })
})

function closeAiDialog() {
  store.commit('closeGlobalDialogs')
}

function startNewAction() {
  store.commit('ai/pushActionChoice')
  scrollToBottom()
}
</script>
