<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-2xl"
    @close="$emit('close')"
  >
    <FolderForm
      :folder="folder"
      :isDialogForm="true"
      @save="$emit('save')"
      @cancel="$emit('close')"
    />
  </BaseDialogNew>
</template>
<script>
import FolderForm from "@/modules/files/components/FolderForm.vue";

export default {
  name: 'FileDialog',
  props: {
    folder: {
      type: Object,
      default: () => null
    },
  },
  inheritAttrs: false,
  components: {
    FolderForm,
  },
  emits: ['save', 'close'],
}
</script>
