import { ICellRendererParams } from "@ag-grid-community/core";
import { computed, Ref } from "vue";
import { get } from "lodash-es";
import { defaultPlaceholder } from "@/utils/global";

export default function(params: Ref<ICellRendererParams>, defaultValue?: any) {
  const row = computed(() => {
    return params.value?.data || params.value?.node?.allLeafChildren?.[0]?.data
  })

  const colDef = computed(() => {
    if (params.value.node?.group) {
      // @ts-ignore
      return params.value.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === params.value.node?.field) || params.value.colDef
    }

    return params.value.colDef
  })

  const columnParams = computed(() => {
    return colDef.value?.params
  })

  const value = computed(() => {
    const field = colDef.value?.field || colDef.value?.prop

    if(!field) {
      return row.value
    }

    const value = get(row.value, field)

    if (typeof columnParams.value?.valueFormatter === 'function') {
      return columnParams.value?.valueFormatter(value)
    }

    return value || defaultValue || defaultPlaceholder
  })

  return {
    params,
    row,
    colDef,
    columnParams,
    value,
  }
}
