<template>
  <BaseTableCell :params="params">
    <div class="flex items-center -space-x-1 min-w-max groups-list">
      <router-link
        v-for="group in sortedGroups"
        :to="`/groups/${group.id}`"
        :key="group.id"
        class="flex items-center space-x-2 no-underline"
      >
        <BaseLogo
          :entity="group"
          size="sm"
        />
        <span class="allocation-title hidden">
          {{ group?.attributes?.name }}
        </span>
      </router-link>
      <span v-if="groups?.length === 0" class="text-xs no-groups">
        {{ $t('No groups') }}  
      </span>
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import { get, orderBy } from 'lodash-es'

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
  isCard: {
    type: Boolean,
    default: false
  }
})

const row = computed<any>(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const groups = computed(() => {
  if (typeof columnParams.value?.getGroups === 'function') {
    return columnParams.value?.getGroups(row.value)
  }

  const groupsList = get(row.value, colDef.value.field, [])

  return groupsList
})

const sortedGroups = computed(() => {
  return orderBy(groups.value, 'id')
})
</script>
