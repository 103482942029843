export const CONTACT_LINK = 'https://kb.project.co/p/ZjE9eaviL44J7o/Delete-your-account'
export const HELP_LINK = 'https://kb.project.co/'

export function getContactLink(user, tenant) {
  const companyName = tenant?.attributes?.name;
  const companyUrl = tenant?.attributes?.fqdn || window.location.host;

  const params = new URLSearchParams({
    'tally-open': 'woG2BP',
    'tally-layout': 'modal',
    'tally-width': '600',
    'tally-emoji-text': '👋',
    'tally-emoji-animation': 'wave',
    'first': user.first_name,
    'last': user.last_name,
    'email': user.email,
    'org': companyName,
    'orgurl': companyUrl
  });
  return `#${params.toString()}`
}

export const tutorialLinks = {
  'custom-domain': 'https://kb.project.co/p/09uj7bKNGo94Fd/Custom-Domain',
  'custom-emails': 'https://kb.project.co/p/t-yFQ7KwbOLeLc/Custom-Emails',
  'custom-branding': 'https://kb.project.co/p/XAFmq_bhA0dmxR/Custom-Branding',
  'stripe': 'https://kb.project.co/p/-fGkcHFnCMlErx/Stripe',
  'paypal': 'https://kb.project.co/p/LBl53PXpMXqkTE/Paypal',
  'invoice-info': 'https://kb.project.co/p/uGCRBi6CzHXUHe/Invoice-Info',
  'project-statuses': 'https://kb.project.co/p/h5o_CkMLg7R2iu/Project-Status-account-level',
  'task-statuses': 'https://kb.project.co/p/2fDr7dUCU0L0Au/Task-Status-account-level',
  'import': 'https://kb.project.co/p/TO9LvH0n8GprqR/Import-Data',
  'import-tasks': 'https://kb.project.co/p/TO9LvH0n8GprqR/Import-Data',
  'export': 'https://kb.project.co/p/MLPNggwEXuEuhw/Export-Data',
  'custom-fields-account': 'https://kb.project.co/p/O8UWesMnRqUmDj/Custom-Fields-account-level',
  'custom-fields-project': 'https://kb.project.co/p/xqUJElmrfa3U7L/Custom-fields-Project-level',
  'default-views': 'https://kb.project.co/p/KOsSR3koSjFqS6/User-Settings',
  'page-settings': 'https://kb.project.co/p/wy3DxtorSpcHAX/Saved-page-settings',
  'embed-issues': 'https://kb.project.co/p/jJfHg6FFURf1ON/Embed-issues',
  'groups': 'https://kb.project.co/p/kDtYkXM6VW652O/Groups',
  'project-tools': 'https://kb.project.co/p/CAg1xCuG7Bu1r5/Project-Tools',
  'file-proofs': 'https://kb.project.co/p/kTLGpWW4Oi-cV4/File-proofing',
  'stripe-tax-setup': 'https://kb.project.co/p/821q5QqW5Vsmm_/Basic-Settings#4a51e9ba',
  'simulating-roles': 'https://kb.project.co/p/BGCY1DQsYteEy3/Simulating-roles-and-permissions',
  'roles-and-permissions': 'https://kb.project.co/p/dsPxr48lAZrmZ7/Roles-and-Permissions',
  'public-api': 'https://kb.project.co/p/eNlQFFzfJQZYaz/Public-API',
  'ai-tokens': 'https://kb.project.co/p/ltpNP48N6E0EmI/How-AI-tokens-work',
  'ai-create-chat': 'https://kb.project.co/p/rBD-MKjS7L0dDR/Assistant-Create-Mode',
  'ai-action-chat': 'https://kb.project.co/p/o-SBJ1zDzefn71/Assistant-Action-Mode',
}
