<template>
  <div
    v-if="limitedFeatureProgress"
    class="col-span-full"
  >
    <div class="flex flex-col">
      <div class="flex w-full justify-between text-gray-700 text-sm">
        <div class="font-medium">
          {{ limitedFeatureProgress.title }}
        </div>
        <div
          v-html="progressText"
        />
      </div>

      <ProgressBar
        class="w-full"
        :value="progressPercentage"
      />

      <div class="text-xs text-gray-700 -mt-2">
        {{ limitedFeatureProgress.subtitle }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'

// Helpers
import i18n from "@/i18n.js"
import { humanReadableSize } from '@/modules/common/utils/fileUtils';

// Composables
import { useStore } from 'vuex'
import {
  useAccountLimits
} from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from '@/modules/common/composables/useCan';
import { secondsToFormattedTime } from '@/modules/common/utils/timeUtils';

const props = defineProps({
  feature: {
    type: String as PropType<AccountPermissions>,
    required: true,
  },
})

const store = useStore()

const isTrialPeriod = computed(() => {
  return store.getters['accounts/isTrialPeriod']
})

const {
  accountLimits,
  isFreePlan,
} = useAccountLimits()

type LimitedFeatureProgress = {
  title: string,
  subtitle: string,
  available: number,
  used: number,
  getFormattedText: (used: number, available: number) => string,
  showIf?: () => boolean,
}

type AccountPermissionsProgressMap = Partial<Record<AccountPermissions, LimitedFeatureProgress>>

function getFormattedStorage(value: number) {
  return humanReadableSize(
    value,
    /* decimals */ 2,
    /* preferMu */ 'GB'
  )
}

const AccountPermissionsProgress = computed<AccountPermissionsProgressMap>(() => {
  const map : AccountPermissionsProgressMap = {
    [AccountPermissions.CreatorSeats]: {
      title: i18n.t('Creator users subscription and bonus seats'),
      subtitle: i18n.t(`All active & pending people who have a 'Creator' role and above.`),
      available: accountLimits.value.seats.available,
      used: accountLimits.value.seats.used,
      getFormattedText(used: number, available: number): string {
        let currentSeatCount: number | string = accountLimits.value.seats.available
        if (isTrialPeriod.value || !currentSeatCount) {
          currentSeatCount = i18n.t('unlimited')
        }

        return i18n.t('seats used', {
          minSeatCount: used,
          currentSeatCount
        })
      },
    },
    [AccountPermissions.CollaboratorsSeats]: {
      title: i18n.t('Collaborator seats'),
      subtitle: i18n.t(`All active & pending people who have a 'Collaborator' or 'Collaborator Plus' role.`),
      available: accountLimits.value.collaboratorsSeats.available,
      used: accountLimits.value.collaboratorsSeats.used,
      getFormattedText(used: number, available: number): string {
        let currentSeatCount: number | string = accountLimits.value.collaboratorsSeats.available
        if (!currentSeatCount) {
          currentSeatCount = i18n.t('unlimited')
        }

        return i18n.t('seats used', {
          minSeatCount: used,
          currentSeatCount
        })
      },
      showIf() {
        return accountLimits.value.collaboratorsSeats.available > 0
      }
    },
    [AccountPermissions.Storage]: {
      title: i18n.t('Storage subscription and bonus storage'),
      subtitle: i18n.t('A calculation of the size of all undeleted files in your account.'),
      available: accountLimits.value.storage.available,
      used: accountLimits.value.storage.used,
      getFormattedText(used: number, available: number): string {
        const usedFormatted = getFormattedStorage(used)
        const availableFormatted = getFormattedStorage(available)

        return i18n.t('storage used', {
          used: usedFormatted,
          available: availableFormatted,
        })
      },
    },
    [AccountPermissions.AiAssistant]: {
      title: i18n.t('AI token usage'),
      subtitle: i18n.t('AI tokens used during the current month'),
      available: accountLimits.value.aiTokens.available,
      used: accountLimits.value.aiTokens.used,
      getFormattedText(used: number, available: number): string {
        // @ts-ignore
        let formatter = Intl.NumberFormat(i18n.locale, { notation: 'compact' });
        const usedFormatted = formatter.format(used)
        const availableFormatted = formatter.format(available)

        return i18n.t('storage used', {
          used: usedFormatted,
          available: availableFormatted,
        })
      },
    },
    [AccountPermissions.FileProofing]: {
      title: i18n.t('Proofs usage'),
      subtitle: i18n.t('Proofs usage during the current month'),
      available: accountLimits.value.proofs.available,
      used: accountLimits.value.proofs.used,
      getFormattedText(used: number, available: number): string {
        // @ts-ignore
        let formatter = Intl.NumberFormat(i18n.locale, { notation: 'compact' });
        const usedFormatted = formatter.format(used)
        const availableFormatted = formatter.format(available)

        return i18n.t('storage used', {
          used: usedFormatted,
          available: availableFormatted,
        })
      },
    },
    [AccountPermissions.ActiveProjects]: {
      title: i18n.t('Active projects'),
      subtitle: i18n.t(`Current projects with a status other than 'completed'.`),
      available: accountLimits.value.projects.available || 0,
      used: accountLimits.value.projects.used,
      getFormattedText(used: number, available: number): string {
        return i18n.t('active projects used', {
          currentActiveProjects: used,
          maxActiveProjects: available,
        })
      },
      showIf() {
        return accountLimits.value.projects.available > 0
      }
    },
    [AccountPermissions.SpeechToText]: {
      title: i18n.t('Speech to text'),
      subtitle: i18n.t(`Time usage during the current month (HH:mm:ss).`),
      available: accountLimits.value.speechToText.available,
      used: accountLimits.value.speechToText.used,
      getFormattedText(used: number, available: number): string {
        // Tranform hours to seconds
        const minutesUsed = Math.ceil(used * 3600)
        const minutesAvailable = Math.ceil(available * 3600)

        return i18n.t('storage used', {
          used: secondsToFormattedTime(minutesUsed),
          available: secondsToFormattedTime(minutesAvailable),
        })
      },
    }
  }

  return map
})

const limitedFeatureProgress = computed(() => {
  const feature = AccountPermissionsProgress.value[props.feature]
  if (!feature || (feature.showIf && !feature.showIf())) {
    return null
  }

  return feature
})

const progressPercentage = computed(() => {
  if (!limitedFeatureProgress.value) {
    return 0
  }

  return (limitedFeatureProgress.value.used / limitedFeatureProgress.value.available) * 100
})

const progressText = computed(() => {
  if (!limitedFeatureProgress.value) {
    return ''
  }

  return limitedFeatureProgress.value.getFormattedText(
    limitedFeatureProgress.value.used,
    limitedFeatureProgress.value.available
  )
})
</script>
