<template>
  <BaseDropdown
    :options="availableOptions"
    :slim="slim"
    divide-items
    @action="onAction"
  >
    <template #default>
      <slot />
    </template>
  </BaseDropdown>
</template>
<script>
import { MenuButton, MenuItem } from "@headlessui/vue";
import { isUserArchived } from "@/modules/users/util/userUtils";
import i18n from '@/i18n'

export default {
  components: {
    MenuItem,
    MenuButton,
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    slim: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isCurrentUserRoleGreaterOrEqual() {
      const userToEditRole = this.get(this.user, 'attributes.role[0]')
      return this.$store.getters['users/isCurrentUserRoleGreaterOrEqual'](userToEditRole)
    },
    isSameUser() {
      return this.$user?.id?.toString() === this.user?.id?.toString()
    },
    options() {
       return [
        {
          label: i18n.t('View Person'),
          path: `/users/${this.user.id}/view`,
          enabled: () =>  !isUserArchived(this.user)
        },
         {
           label: i18n.t('Resend Invitation'),
           action: 'resendInvitation',
           enabled: () => this.user?.attributes?.status === 'pending'
         },
        {
          label: i18n.t('Archive Person'),
          action: 'archiveUser',
          requiresPermissionTo: this.$actions.REMOVE_PEOPLE_FROM_ANY_GROUP,
          enabled: () => {
            const isNotCurrentUser = this.$user?.id?.toString() !== this.user?.id?.toString()
            return isNotCurrentUser && !isUserArchived(this.user) && this.isCurrentUserRoleGreaterOrEqual
          }
        },
         {
           label: i18n.t('Restore Person'),
           action: 'restoreUser',
           requiresPermissionTo: this.$actions.ASSIGN_ROLES_TO_PEOPLE,
           enabled: () => {
            return this.isCurrentUserRoleGreaterOrEqual && isUserArchived(this.user)
          },
         },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.user)
    },
    async archiveUser() {
      try {
        const userName = this.user?.attributes?.name
        const isUserBillable = this.isRoleGreaterOrEqual(this.user?.attributes?.role[0], this.$roles.CREATOR)
        const confirmed = await this.$deleteConfirm({
          title: i18n.t(`Archive ${userName}`),
          description: `
          <span>If you archive ${userName}</span>
          <ul>
           <li>All their data such as comments, tasks and time will remain in the account.</li>
           <li>You won’t be able to associate any new data with this user.</li>
           <li>They won’t be able to login or access the account.</li>
           ${isUserBillable ? '<li>They won’t count as a paid user any longer for billing purposes.</li>' : ''}
          </ul>
          <span>Are you sure you want to archive ${userName}?</span>`,
          buttonText: i18n.t('Archive')
        })
        if (!confirmed) {
          return
        }
        await this.$store.dispatch('users/deleteUser', this.user.id)
        this.$success(i18n.t('Person archived successfully. You can still recover the person from the Archived tab.'))
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not archive the person.'))
      }
    },
    async resendInvitation() {
      try {
        await this.$store.dispatch('users/resendInvitation', this.user.id)
        this.$success(i18n.t('User invitation was sent.'))
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not send the user invitation.'))
      }
    },
    async restoreUser() {
      try {
        await this.$store.dispatch('users/restoreUser', this.user.id)
        this.$success(i18n.t('Person restored successfully. They should now have access to the system.'))
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not restore the person.'))
      }
    }
  }
}
</script>
