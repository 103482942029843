import i18n from "@/i18n.js"

export const PlanTypes = {
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
}

export const PlanFrequency = {
  Month: 'month',
  Year: 'year'
}

export const Plans = {
  PDC_Free: 'PDC-Free-V2',
  PDC_PAID: 'PDC-Paid-V2',
  PDC_PAID_PRO: 'PDC-Pro',
}

export type Benefit = {
  icon: string,
  text: string,
  comingSoon?: boolean,
  bold?: boolean,
}

type Plan = {
  [key: string]: {
    title: string,
    benefits: Benefit[],
  }
}

const FreePlan = {
  title: i18n.t('Free'),
  cssClass: 'plan-title-free',
  benefits: [
    {
      icon: 'fa-check',
      text: i18n.t('Up to 3 Creator users'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Up to 10 Collaborator users'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Up to 10 active projects'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Unlimited tools per project'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('3GB storage total'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Unlimited collaborative notes'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Unlimited tasks'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Real-time discussions'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Accept one time payments'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Track allocated & actual time'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Embed 3rd party tools'),
    },
    {
      icon: 'fa-check',
      text: i18n.t('Desktop, iOS and Android apps'),
    },
  ]
}

const PaidPlan = {
  title: i18n.t('Pro'),
  cssClass: 'plan-title',
  benefits: [
    {
      icon: 'fa-check',
      text: i18n.t('Everything in Free plus...'),
      bold: true,
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Pay per Creator seat'),
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Invite unlimited Collaborators'),
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Unlimited active projects'),
    },

    {
      icon: 'fa-plus',
      text: i18n.t('Custom branding - Add your logo'),
      link: 'https://kb.project.co/p/XAFmq_bhA0dmxR/Custom-Branding',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Custom domain - Access from your domain'),
      link: 'https://kb.project.co/p/09uj7bKNGo94Fd/Custom-Domain',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Custom emails - Send from your email'),
      link: 'https://kb.project.co/p/t-yFQ7KwbOLeLc/Custom-Emails',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('File storage + 50GB storage/creator'),
      link: 'https://kb.project.co/p/79oh0Jqblu25K5/Files',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('AI assistant + 100,000 tokens/creator/month'),
      link: 'https://kb.project.co/p/tKB6FjY4sWbBT1/AI-Assistant',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Live task timer'),
      link: 'https://kb.project.co/p/j3YTbsauiLlO7l/Tasks',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Recurring payments'),
      link: 'https://kb.project.co/p/BsowoHyIpcbrcT/Payments',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Webhooks'),
      link: 'https://kb.project.co/p/ydPFE-9h4aUM38/Webhooks',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Priority support'),
      link: 'https://kb.project.co/p/RoQZbzGuVSLp4M/Priority-support',
    },
    {
      icon: 'fa-plus',
      text: i18n.t('Satisfaction guarantee'),
      link: 'https://kb.project.co/p/XkCBsxYZIXW0xX/Satisfaction-guarantee',
    },
  ]
}
export const PlanDetails: Plan = {
  [Plans.PDC_Free]: FreePlan,
  [Plans.PDC_PAID]: PaidPlan,
  [Plans.PDC_PAID_PRO]: PaidPlan,
}

export const AppSumoPlanName = 'PDC-AppSumo-Lifetime'
