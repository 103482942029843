<template>
  <div
    v-if="$useNewLayout"
    class="space-y-5"
  >
    <DashboardHeaderNew />
    <DashboardWidgets />
  </div>
  <div v-else>
    <div class="w-full rounded-lg bg-white border-gray-200 border mb-6 shadow">
      <h2 class="sr-only" id="profile-overview-title">{{ $t('Profile Overview') }}</h2>
      <WelcomeHeader />
      <WelcomeSubheader />
    </div>
    <WelcomeCards />
  </div>
</template>
<script lang="ts" setup>
// Components
import WelcomeHeader from "@/modules/dashboard/components/WelcomeHeader.vue";
import WelcomeSubheader from "@/modules/dashboard/components/WelcomeSubheader.vue";
import WelcomeCards from "@/modules/dashboard/components/WelcomeCards.vue";

// New Layout Components
import DashboardHeaderNew from "@/modules/dashboard/components/DashboardHeaderNew.vue";
import DashboardWidgets from "@/modules/dashboard/components/DashboardWidgets.vue";

// Composables
import { useRoute, useRouter } from "vue-router";
import { nextTick, onMounted } from "vue";

const [
  route,
  router,
] = [
  useRoute(),
  useRouter(),
]

async function checkNewAccount() {
  await nextTick()
  if (!route.query.newAccount) {
    return
  }

  router.push(`/onboarding`)
}

onMounted(() => {
  checkNewAccount()
})
</script>
