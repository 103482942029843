<template>
  <ElCollapseItem
    :name="step.key"
    class="getting-started-item group p-6"
  >
    <template #title>
      <div>
        <dt>
          <div
            class="flex w-full items-start justify-between text-left text-gray-900 hover:text-gray-900"
            aria-controls="faq-4"
            aria-expanded="false"
          >
            <span class="text-base font-semibold leading-7">
              <i
                class="align-middle text-2xl w-8 mr-2 -mt-1"
                :class="{
                  'fa-regular fa-circle-check text-emerald-500': step.completed,
                  [`fa-light ${step.iconClass} text-gray-400`]: !step.completed
                }"
                aria-hidden="true"
              />
              {{ step.title }}
            </span>
          </div>
        </dt>
        <dd
          v-if="step.completed"
        >
          <p class="text-sm text-gray-500">
            {{ step.completedDescription }}

            <router-link
              v-if="step.completedLink"
              :to="step.completedLink.to"
              class="underline"
            >
              {{ step.completedLink.label }}
            </router-link>
          </p>
        </dd>
      </div>
    </template>

    <dd
      class="mt-6"
      :class="{
        'sm:grid sm:grid-cols-3': step.videoId,
      }"
    >
      <p class="col-span-2 pr-4 text-sm text-gray-500">
        {{ step.description }}

        <span
          v-if="step.videoId"
          class="block mt-2"
        >
          {{  $t(`Watch the video to learn more.`) }}
          <i class="fa-solid fa-arrow-right ml-1" aria-hidden="true" />
        </span>
      </p>

      <WistiaVideoEmbed
        v-if="step.videoId"
        :videoId="step.videoId"
      />
    </dd>
    <div
      v-if="step.action && !step.completed"
      class="flex items-center space-x-2 mt-2"
    >
      <router-link
        v-if="step.action?.to"
        :to="step.action.to"
        class="block w-fit rounded-md bg-primary-500 px-3 py-2 mr-1 text-sm text-white"
      >
        {{ step.action?.label }}
      </router-link>
      <button
        v-else-if="step.action?.handler"
        type="button"
        class="block rounded-md bg-primary-500 px-3 py-2 mr-1 text-sm text-white"
        @click.stop="step.action.handler"
      >
        {{ step.action?.label }}
      </button>

      <button
        v-if="step.action?.allowMarkCompleted"
        type="button"
        class="rounded-md bg-white px-3 py-2 transition-all text-sm font-semibold text-gray-500 border border-gray-200"
        @click.stop="markStepCompleted"
      >
        {{ $t(`Mark as completed`)}}
      </button>
    </div>
  </ElCollapseItem>
</template>
<script lang="ts" setup>
import { PropType } from 'vue';

import { ElCollapseItem } from 'element-plus';
import WistiaVideoEmbed from '@/components/common/WistiaVideoEmbed.vue';

import {
  GettingStartedStep,
  useGettingStarted
} from '@/modules/accounts/composables/useGettingStarted';

const {
  setStepCompleted
} = useGettingStarted();

const props = defineProps({
  step: {
    type: Object as PropType<GettingStartedStep>,
    required: true
  }
})

const emit = defineEmits(['step-completed']);

async function markStepCompleted() {
  await setStepCompleted(props.step.key);
  emit('step-completed', props.step);
}
</script>
<style lang="scss">
.getting-started-item {
  .el-collapse-item__header {
    @apply flex items-center justify-between;
  }

  .el-collapse-item__arrow.is-active {
    @apply rotate-90;
  }

  &.is-disabled {
    .el-collapse-item__header {
      @apply cursor-default;

      .el-collapse-item__arrow {
        @apply hidden;
      }
    }
  }
}
</style>
