<template>
  <div
    :class="{
      'opacity-50': !task?.id
    }"
  >
    <div class="flex items-center text-gray-400 space-x-2 mt-2">
      <i class="fa fa-message w-3" />
      <span>
        {{ $t('comments count', { count: commentCount }) }}
      </span>
      <span>-</span>
      <span
        class="underline"
        :class="{
          'cursor-not-allowed opacity-50': disabled,
          'cursor-pointer hover:text-gray-500': !disabled
        }"
        @click="!disabled && (showConversation = !showConversation)"
      >
        {{ toggleConversationLabel }}
      </span>
    </div>
    <div
      v-show="showConversation"
      class="task-discussion mt-2"
    >
      <div class="sm:mt-px sm:pt-1 sm:col-span-6">
        <div
          v-if="!disabled"
          id="task-discussion-comment-section"
          class="discussion-comment-section form-comment-input w-full mb-3"
        >
          <div class="max-w-4xl m-auto align-middle bg-white md:rounded-lg">
            <HtmlCommentInput
              :sendAction="sendComment"
              :isConnectedToChannel="isConnectedToChannel"
              :notifyIsTyping="notifyIsTyping"
              :projectId="projectId"
              :usersTyping="usersTyping"
              :infoText="$t('A comment notification will be sent to all assigned people and all followers.')"
            />
          </div>
          <div
            v-if="!isConnectedToChannel"
            class="w-full text-center text-sm bg-white p-2z-20 rounded"
          >
            <span>
              {{ $t("Not connected. Real time events may not work. ") }}
            </span>
            <span
              class="underline cursor-pointer"
              @click="tryJoinChannel(true)"
            >
              {{ $t("Retry connection" )}}
            </span>
          </div>
        </div>

        <InfiniteScroll
          :key="`retry_count_${retryCount}`"
          :load-next="loadNextPage"
          direction="down"
          scrollable-el-selector=".task-discussion-comment"
          :scrollableContainerSelector="inModalDialog ? '#task-edit-dialog .dialog-container' : ''"
        >
          <template #spinner>
            <LoadingComment />
          </template>

          <div
            ref="conversationTarget"
            class="max-w-4xl m-auto align-middle bg-white space-y-6"
            :class="{
              'mb-32 sm:mb-10 mt-6': !isEmptyConversation
            }"
          >
            <DiscussionComment
              v-for="discussion of discussions"
              :key="discussion.id"
              :discussion="discussion"
              :task-id="taskId"
              :project-id="projectId"
              entity-type="task"
              editable
              :readonly="disabled"
              @update="updateComment"
              @delete="deleteComment"
            />
          </div>
        </InfiniteScroll>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { useElementVisibility } from '@vueuse/core'

// Components
import InfiniteScroll from "@/components/common/InfiniteScroll.vue";
import DiscussionComment from "@/modules/common/components/DiscussionComment.vue";
import HtmlCommentInput from "@/components/form/HtmlCommentInput.vue"

// Helpers
import PusherUtils from "@/modules/common/utils/pusherUtils"
import LoadingComment from "@/modules/common/components/LoadingComment.vue";
import { useDiscussion } from "@/modules/projects/composables/useDiscussion.js";
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    InfiniteScroll,
    LoadingComment,
    HtmlCommentInput,
    DiscussionComment,
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inModalDialog: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const taskId = props.task?.id

    if (!taskId) {
      return
    }

    const conversationTarget = ref(null)
    const conversationVisible = useElementVisibility(conversationTarget)
    const { width } = useWindowSize()

    const {
      usersTyping,
      retryCount,
      isConnectedToChannel,
      listenChannel,
      triggerChannel,
      tryJoinChannel,
      notifyIsTyping,
    } = useDiscussion({ taskId })

    return {
      conversationTarget,
      conversationVisible,
      usersTyping,
      retryCount,
      isConnectedToChannel,
      listenChannel,
      triggerChannel,
      tryJoinChannel,
      notifyIsTyping,
      width
    }
  },
  data() {
    return {
      isCommentHistoryLoaded: false,
      page: 1,
      notificationsRead: false,
      showConversation: false
    }
  },
  computed: {
    taskId() {
      return this.task?.id
    },
    projectId() {
      return this.task?.attributes?.project_id
    },
    discussions() {
      return this.$store.state.discussions.taskDiscussion
    },
    loading() {
      return this.$store.state.discussions.taskDiscussionLoading
    },
    isEmptyConversation() {
      return !this.loading && !this.discussions.length
    },
    commentCount() {
      return this.task?.attributes?.comment_count || 0
    },
    toggleConversationLabel() {
      if (this.showConversation) {
        return this.$t('Hide conversation')
      }

      if (!this.commentCount) {
        return this.$t('Start the conversation')
      }

      return this.$t('Show conversation')
    }
  },
  methods: {
    async loadNextPage($state) {
      if (this.loading || !this.task?.id) {
        return;
      }

      try {
        const result = await this.$store.dispatch('discussions/getTaskDiscussion', {
          taskId: this.taskId,
          page: this.page
        })

        if (!this.isCommentHistoryLoaded) {
          this.isCommentHistoryLoaded = true
        }

        $state.loaded()

        if (result.meta.current_page === result.meta.last_page) {
          $state.complete()
        }
        else {
          this.page++
        }
      }
      catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not load more comments'))
        throw err
      }
    },
    async sendComment({ message, notifiable_user_ids }) {
      const newAddedComment = await this.$store.dispatch('discussions/createTaskDiscussionComment', {
        taskId: this.task.id,
        message,
        notifiable_user_ids,
        isConnectedToChannel: this.isConnectedToChannel
      })
      await this.$nextTick()
      this.task.attributes.comment_count++
      this.scrollToNewestComment()
      this.triggerChannel?.trigger(PusherUtils.COMMENT_ADDED_EVENT, newAddedComment)
    },
    async updateComment({ comment, message, notifiable_user_ids}) {
      try {
        const updatedComment = await this.$store.dispatch('discussions/updateTaskDiscussionComment', {
          message,
          notifiable_user_ids,
          commentId: comment.id,
        })
        this.triggerChannel?.trigger(PusherUtils.COMMENT_UPDATED_EVENT, updatedComment)
      }
      catch (err) {
        this.$error(this.$t('Could not update comment, please try again.'))
      }
    },
    async deleteComment(comment) {
      await this.$store.dispatch('discussions/deleteTaskDiscussionComment', {
        commentId: comment.id
      })

      this.triggerChannel?.trigger(PusherUtils.COMMENT_DELETED_EVENT, comment.id)
    },
    scrollToNewestComment() {
      const firstEl = document.querySelector('.task-discussion-comment')
      if (firstEl) {
        firstEl.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })
      }
    },
    initChannelEvents() {
      this.page = 1
      this.listenChannel?.on(PusherUtils.COMMENT_ADDED_EVENT, (comment) => {
        this.$store.commit('discussions/addCommentToTaskDiscussion', comment)
        this.task.attributes.comment_count++
        this.scrollToNewestComment()
      })

      this.listenChannel?.on(PusherUtils.COMMENT_DELETED_EVENT, id => {
        this.$store.commit('discussions/removeCommentFromTaskDiscussion', id)
      })

      this.listenChannel?.on(PusherUtils.COMMENT_UPDATED_EVENT, updatedComment => {
        this.$store.commit('discussions/updateTaskDiscussionComment', updatedComment)
      })
    },
    markNotificationsRead() {
      if (this.notificationsRead || !this.taskId) {
        return
      }
      this.notificationsRead = true
      this.$store.dispatch('notifications/markEntityNotificationsRead', {
        notifiable_target_type: 'task_discussion',
        notifiable_target_id: this.taskId,
      })
    },
    async checkShowState() {
      await this.$nextTick()

      if (this.width < 768)  {
        return
      }

      this.showConversation = this.commentCount > 0
    }
  },
  watch: {
    conversationVisible: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          return
        }
        this.markNotificationsRead()
      }
    },
  },
  mounted() {
    this.initChannelEvents()
    this.checkShowState()
  },
}
</script>
<style lang="scss">
.task-discussion {
  .editor-box-menu {
    @apply shadow-none;
  }
}
</style>
