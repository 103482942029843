<template>
  <div
    v-if="canNote(NoteActions.PIN)"
    class="group flex"
    :class="{
      'cursor-not-allowed': disabled,
      'cursor-pointer': !disabled
    }"
    @click.stop="onTogglePin"
  >
    <BaseTooltip
      :content="isPinned ? $t('Unpin') : $t('Pin')"
      :disabled="disabled"
    >
    <span class="flex items-center">
      <i
        v-if="iconAlways || isPinned"
        :class="{
          'text-primary-500': isPinned,
          'text-gray-400': !isPinned,
          'group-hover:-translate-y-0.5 group-hover:text-gray-400': isPinned && !disabled,
          'group-hover:text-primary-500': !isPinned && !disabled,
        }"
        class="fas fa-thumbtack mr-2"
      />
      <slot />
    </span>
    </BaseTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from "vuex";
import { canNote, NoteActions } from "@/modules/projects/utils/noteUtils";

const store = useStore()

const props = defineProps({
  note: {
    type: Object,
    default: null
  },
  iconAlways: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})

const isPinned = computed({
  get() {
    return props.note?.attributes?.pinned
  },
  async set(pinned) {
    await store.dispatch('projects/pinNote', {
      pinned,
      id: props.note.id,
    })
  },
})

function onTogglePin() {
  if (props.disabled) {
    return
  }

  isPinned.value = !isPinned.value
}
</script>

