<template>
  <div>
    <div
      class="text-sm text-gray-400 hover:bg-gray-100 p-1"
      :class="{
        'cursor-pointer': !$attrs.disabled,
        'cursor-not-allowed': $attrs.disabled
      }"
      @click="!$attrs.disabled && (formVisible = true)"
    >
      {{ humanReadableText || $t('Edit Settings') }}
    </div>
    <BaseDialogNew
      v-model="formVisible"
      class="w-full max-w-lg"
    >
      <RecurrenceForm
        :title="title"
        :init-model="recurrenceModel"
        @cancel="formVisible = false"
        @confirm="updateModel"
      />
    </BaseDialogNew>
  
  </div>
</template>
<script>
import RecurrenceForm from "@/modules/common/components/RecurrenceForm.vue";
import { getRRuleFromString, rruleToString } from "@/modules/common/utils/recurrenceUtils";

export default {
  components: {
    RecurrenceForm,
  },
  props: {
    modelValue: {
      // String encoding returned by RRule.toString()
      type: String,
      required: true,
    },
    recurrenceDate: {
      type: Date,
      default: () => null
    },
    humanReadableText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formVisible: false,
    };
  },
  computed: {
    recurrenceModel() {
      return {
        string: this.modelValue,
        text: this.humanReadableText,
      };
    },
  },
  methods: {
    updateModel({ rrule, recurrenceDate }) {
      this.$emit("update:modelValue", rruleToString(rrule));
      this.$emit("update:recurrenceDate", recurrenceDate);
      this.$emit("update:humanReadableText", rrule.toText())

      this.formVisible = false;
    },
    tryInitRRule() {
      if (!this.modelValue) {
        return;
      }

      try {
        const rrule = getRRuleFromString(this.modelValue)
        this.$emit("update:humanReadableText", rrule.toText())
      }
      catch (err) {
        this.$error(`${this.$t("Invalid recurrence")} '${this.modelValue}'`);
      }
    }
  },
  mounted() {
    this.tryInitRRule()
  }
};
</script>
