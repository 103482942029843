<template>
  <div class="w-full relative">
    <div class="p-6">
      <div
        v-if="!recommendations"
        class="flex flex-wrap gap-2"
      >
        <TemplateCategoryChip
          v-for="category of categories"
          :key="category.id"
          :category="category"
          :selected-category="selectedCategory"
          @click="selectedCategory = category"
        />
      </div>
      <h3
        v-if="recommendations"
        class="text-2xl font-bold mb-2"
      >
        {{ $t('Recommended templates for you') }}
      </h3>
      <div
        class="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        :class="{
          'mt-4': !recommendations
        }"
      >
        <template v-if="templatesLoading">
          <EntityLoadingCards
            :params="{
              columns: displayColumns
            }"
            :count="5"
          />
        </template>
        <template v-else>
          <TemplateGalleryCard
            v-for="template of filteredTemplates"
            :key="template.id"
            :template="template"
          />
        </template>
      </div>
      
      <div
        v-if="recommendations"
        class="pt-2"
      >
        <div
          class="text-sm bold text-primary-500 cursor-pointer"
          @click="triggerEntityCreate(entityTypes.TemplateGallery)"
        >
          {{ $t('See all templates in our template gallery') }}
          <i class="fa-solid fa-arrow-right ml-2" />
        </div>
      </div>
    </div>
    <div
      v-if="!recommendations"
      class="px-6 py-4 mt-6 border-t border-gray-200 sticky w-full bottom-0 bg-white z-10"
    >
      <div class="flex justify-end">
        <BaseButton
          @click="$emit('close')"
        >
          {{ $t('Close Template Gallery') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Utils
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import useEntityCrud from "@/modules/common/composables/useEntityCrud"

// Components
import TemplateGalleryCard from '@/modules/templateGallery/components/TemplateGalleryCard.vue'
import TemplateCategoryChip from '@/modules/templateGallery/components/TemplateCategoryChip.vue'
import EntityLoadingCards from "@/components/table/EntityLoadingCards.vue";

import Data = API.Data;
import TemplateCategory = App.Domains.Categories.Models.TemplateCategory;
import GalleryTemplate = App.Domains.Templates.Models.GalleryTemplate

const store = useStore()

const {
  triggerEntityCreate,
  entityTypes
} = useEntityCrud()

const props = defineProps({
  recommendations: {
    type: Boolean,
    default: false,
  },
})

const allCategory: Data<TemplateCategory> = {
  id: 'all',
  attributes: {
    name: 'All Templates',
    icon: 'fa fa-stars',
  }
}

const projectColumns = computed(() => {
  return store.getters['projects/tableColumns']
})


const displayColumns = computed(() => {
  const ignoredColumns = [
    'attributes.order',
    'attributes.completed',
    'attributes.deadline',
    'attributes.created_at'
  ]

  return projectColumns.value.filter((c: any) => {
    const isAlwaysHidden = c.hide && c.keepHidden

    return !ignoredColumns.includes(c.field) && !isAlwaysHidden
  })
})

const categories = computed(() => {
  const customCategories = store.state.templateGallery.categories || []
  return [allCategory, ...customCategories]
})

const selectedCategory = ref<Data<TemplateCategory>>(allCategory)

const templates = computed(() => {
  return store.state.templateGallery.templates || []
})

const templatesLoading = computed(() => {
  return store.state.templateGallery.templatesLoading
})

const recommendedTemplates = computed(() => {
  return store.getters['templateGallery/recommendedTemplates']
})

const filteredTemplates = computed(() => {
  if (props.recommendations) {
    return recommendedTemplates.value.slice(0, 4)
  }

  if (!selectedCategory.value || selectedCategory.value?.id === 'all') {
    return templates.value
  }

  return templates.value.filter((template: Data<GalleryTemplate>) => {
    const categoryIds = template.relationships?.categories.map((category: Data<TemplateCategory>) => {
      return category.id
    }) || []

    return categoryIds.includes(selectedCategory.value.id)
  })
})
</script>
