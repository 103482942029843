<template>
  <div
    class="px-6 py-4 flex-wrap flex gap-2 sm:gap-4 bg-white border-gray-200"
    :class="{
      'rounded-lg shadow border': !$useNewLayout,
      'border-b sm:sticky sm:top-0 rounded-b-lg sm:rounded-b-none z-10': $useNewLayout,
    }"
  >
    <BaseButton
      v-if="isUnread"
      :loading="markAsReadLoading"
      :has-focus="false"
      @click="markAllProjectNotificationsAsRead"
    >
      <i class="fa-solid fa-square-check mr-2"/>
      {{ $t('Mark Project As Read') }}
    </BaseButton>

    <BaseTooltip
      :disabled="!showRead"
      :content="$t('Display read notifications as well as unread notifications for this project.')">
      <BaseButton
        v-if="isUnread"
        :disabled="markAsReadLoading"
        :has-focus="false"
        variant="white"
        @click="triggerShowReadNotifications"
      >
        <i class="fas fa-bookmark mr-2"/>
        {{ showRead ? $t('Show Read') : $t('Hide Read') }}
      </BaseButton>
    </BaseTooltip>

    <router-link :to="`/projects/${projectId}`">
      <BaseButton
        :has-focus="false"
        variant="white"
      >
        <i class="fas fa-inbox mr-2"/>
        {{ $t('Open Project') }}
      </BaseButton>
    </router-link>
    <BaseTooltip
      :disabled="isPinned"
      :content="$t('Pin this project to the top of your sidebar.')">
      <BaseButton
        :has-focus="false"
        :variant="isPinned ? 'primary' : 'white'"
        @click="togglePinned"
      >
        <i class="fas fa-thumbtack mr-2"/>
        {{ isPinned ? $t('Unpin Project') : $t('Pin Project') }}
      </BaseButton>
    </BaseTooltip>
  </div>
</template>
<script>
import { notificationStatuses } from '@/modules/accounts/utils/notificationUtils.js'

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
    projectNotificationStatus: {
      type: String,
      default: notificationStatuses.Unread,
    },
  },
  data() {
    return {
      markAsReadLoading: false,
    }
  },
  computed: {
    isPinned() {
      return this.notification?.is_pinned
    },
    isUnread() {
      return this.notificationFilters?.status === notificationStatuses.Unread
    },
    showRead() {
      return this.projectNotificationStatus === notificationStatuses.Unread
    },
    projectId() {
      return this.notification.id
    },
    notificationFilters() {
      return this.$route.query
    },
  },
  methods: {
    async togglePinned() {
	    this.notification.is_pinned = !this.isPinned

	    await this.$store.dispatch('notifications/setProjectPinned', {
	      projectIds: [this.projectId],
	      is_pinned: this.isPinned,
	    })
    },
    async triggerShowReadNotifications() {
      const status = this.projectNotificationStatus === notificationStatuses.Unread
        ? notificationStatuses.Read
        : notificationStatuses.Unread

      this.$emit('update-status', status)
    },
    async markAllProjectNotificationsAsRead() {
      this.markAsReadLoading = true

      await this.$store.dispatch('notifications/markAllProjectNotificationsAsRead', this.projectId)

      await this.$store.dispatch('notifications/getNotifications', {
        filters: {
          ...this.notificationFilters
        },
        silent: true,
      })

      this.markAsReadLoading = false
    },
  },
}
</script>
