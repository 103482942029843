<template>
  <component
    :to="path"
    :is="limitReached || !path ? 'div' : 'router-link'"
  >
    <slot :onClick="onClick">
      <div
        v-if="flat"
        class="flex items-center space-x-1 h-full"
        @click="onClick"
      >
        <slot name="button-content" />
        <span v-if="label">
          {{ label }}
        </span>
        <i
          v-if="limitReached"
          class="fas fa-crown text-yellow-500 text-xs"
        />
      </div>
      <BaseButton
        v-else
        :class="$attrs.class"
        :variant="$attrs.variant"
        :hoverVariant="$attrs.hoverVariant"
        :disabled="$attrs.disabled"
        :loading="$attrs.loading"
        @click="onClick"
      >
        <slot name="button-content" />
        {{ label }}

        <i
          v-if="limitReached"
          class="fas fa-crown text-yellow-500 ml-2"
        />
      </BaseButton>
    </slot>
  </component>
</template>
<script lang="ts" setup>
import {
  useAccountLimits,
} from "@/modules/auth/composables/useAccountLimits";
import { AccountPermissions } from '@/modules/common/composables/useCan';
import { PropType, computed } from "vue";

const props = defineProps({
  feature: {
    type: String as PropType<AccountPermissions>,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  path: {
    type: String,
    default: ''
  },
  flat: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits([
  'click'
])

const {
  openUpgradeDialog,
  hasReachedLimit
} = useAccountLimits()

const limitReached = computed(() => {
  return hasReachedLimit(props.feature)
})


function onClick(event: Event) {
  if (!props.path) {
    event.stopPropagation()
    event.stopImmediatePropagation()
  }

  if (limitReached.value) {
    return openUpgradeDialog(props.feature)
  }

  if (props.path) {
    return
  }

  emit('click')
}
</script>
