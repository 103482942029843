<template>
  <div
    ref="root"
    class="relative bg-white p-4 rounded-lg group space-y-2 overflow-x-hidden items-center entity-card w-full h-fit"
    :class="{
      'border border-gray-200': $useNewLayout,
      'shadow': !$useNewLayout,
    }"
    @click="$emit('click')"
  >
    <div
      v-if="coverImageSrc"
      class="rounded-t-lg overflow-hidden -m-4"
    >
      <img
        :src="coverImageSrc"
        class="w-full object-cover object-top h-[70px]"
      />
    </div>
    <div
      v-for="column of displayColumns"
      :key="column.field"
      class="text-xs text-gray-400 flex space-x-1"
      :class="{
        'absolute top-2 right-4': column.field === 'options'
      }"
    >
      <span v-if="column.showCardLabel">
        <template v-if="column.headerName === $t('Project')">
          <i class="fa-solid fa-inbox" />
        </template>
        <template v-else-if="column.headerName === $t('Task')">
          <i class="fa-solid fa-badge-check" />
        </template>
        <template v-else-if="column.headerName === $t('Group')">
          <i class="fa-solid fa-ball-pile" />
        </template>
        <template v-else-if="column.headerName === $t('Person')">
          <i class="fa-solid fa-user" />
        </template>
        <template v-else-if="['attributes.date', 'attributes.start_date'].includes(column.field)">
          <i class="fa-solid fa-calendar-days" />
        </template>
        <span v-else class="font-bold">
          {{ column.headerName }}:
        </span>
      </span>
      
      <template v-if="column.field === 'attributes.name'">
        <h3 class="text-lg leading-6 font-bold text-gray-900">
          <router-link
            :to="path"
            class="focus:outline-none"
          >
            <span class="absolute inset-0 drag-handle" aria-hidden="true" />
            {{ project?.attributes?.name }}
          </router-link>
        </h3>
      </template>
      <template v-else-if="column.field === 'attributes.start_date'">
        <div class="flex items-center space-x-1">
          <FormattedDate
            :column="column"
            :row="project"
            :params="column.params"
            emptyValuePlaceholder="N/A"
          />

          <span class="mx-1">→</span>

          <FormattedDate
            :column="deadlineColumn"
            :row="project"
            :params="column.params"
            emptyValuePlaceholder="N/A"
          />
        </div>
      </template>
      <div
        v-else-if="column.field === 'attributes.image'"
        class="outline outline-white rounded-lg"
        :class="{
          [column.cardClass]: column.cardClass,
          '-mt-4': coverImageSrc
        }"
      >
        <component
          :is="tableCellComponents[column.type]"
          v-bind="column.cardProps"
          :params="getComponentParams(column)"
          :is-card="true"
          :imageSrc="projectImageSrc"
          dimClasses="h-12 w-12"
          logoSize="md"
        />
      </div>
      <div
        v-else-if="column.type && column.type !== 'custom'"
        :class="column.cardClass"
      >
        <component
          :is="tableCellComponents[column.type]"
          v-bind="column.cardProps"
          :params="getComponentParams(column)"
          :is-card="true"
        />
      </div>
      <template v-else>
        {{ get(project, column.field)}}
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Components
import FormattedDate from "@/components/table/FormattedDate.vue";
import { tableCellComponents } from "@/components/table/cells/tableCellComponents";

// Utils
import { computed, onMounted, ref } from "vue";
import { get } from 'lodash-es'
import { useStore } from "vuex";
import {
  KANBAN_DRAG_DELAY_MOBILE
} from "@/modules/common/utils/kanbanUtils";

import { sleep } from "@/modules/common/utils/commonUtils"
import { agGridColumnMapper } from '@/components/table/tableUtils'
import { columnBuilder } from '@/components/table/tableUtils'

const store = useStore()

const props = defineProps({
  project: {
    type: Object,
    default: () => ({})
  },
  link: {
    type: String,
    default: ''
  },
  hideLink: {
    type: Boolean,
    default: false,
  },
})

function getComponentParams(column: any) {
  return {
    data: props.project,
    colDef: column
  }
}

const isTemplate = computed(() => {
  return props.project?.attributes?.is_template
})

const path = computed(() => {
  if (isTemplate.value) {
    return `/templates/${props.project?.id}`
  }

  return `/projects/${props.project?.id}`
})

const columns = computed(() => {
  let cols = (store.getters['projects/tableColumns'] || [])
  
  cols = columnBuilder.filterDisabledColumns(cols)

  cols = columnBuilder.remapDynamicProperties(cols)

  return cols.map(agGridColumnMapper)
})

const hiddenColumns = computed(() => {
  return store.getters['filters/targetLocalFilters']('projects').columnsToHide?.columns || []
})

const unsplashCoverParams: {[key: string]: string} = {
  q: '80',
  auto: 'format',
  w: '400',
  h: '100',
  fit: 'crop',
  crop: 'edges'
}

const coverImageSrc = computed(() => {
  const coverImage = props.project?.attributes?.cover_image
  if (!coverImage) {
    return null
  }

  if (!coverImage?.includes('unsplash')) {
    return coverImage
  }

  const url = new URL(coverImage)
  const urlParams = new URLSearchParams()

  for (const key in unsplashCoverParams) {
    urlParams.set(key, unsplashCoverParams[key])
  }

  url.search = `?${urlParams.toString()}`

  return url.toString()
})

const unsplashImageParams: { [key: string]: string } = {
  q: '80',
  auto: 'format',
  w: '80',
  h: '80',
  fit: 'crop',
  crop: 'edges'
}

const projectImageSrc = computed(() => {
  const image = props.project?.attributes?.image
  if (!image) {
    return null
  }

  if (!image.includes('unsplash')) {
    return image
  }

  const url = new URL(image)
  const urlParams = new URLSearchParams()

  for (const key in unsplashImageParams) {
    urlParams.set(key, unsplashImageParams[key])
  }

  url.search = `?${urlParams.toString()}`

  return url.toString()
})

const displayColumns = computed(() => {
  const ignoredColumns = [
    'attributes.order',
    'attributes.completed',
    'attributes.deadline',
    'attributes.created_at'
  ]

  return columns.value.filter((c: any) => {
    const isAlwaysHidden = c.hide && c.keepHidden
    const isCurrentlyHidden = hiddenColumns.value.includes(c.field)

    return !ignoredColumns.includes(c.field) && !isAlwaysHidden && !isCurrentlyHidden && hasValue(c)
  })
})

const imageColumn = computed(() => {
  return columns.value.find((c: any) => c.field === 'attributes.image')
})

const deadlineColumn = computed(() => {
  return columns.value.find((c: any) => c.field === 'attributes.deadline')
})

function hasValue(column: any): boolean {
  if (column.customField) {
    return props.project.attributes?.custom_fields?.[column?.customField?.attributes?.key]
  }

  if (column.field === 'attributes.date' && props.project.type === 'tasks') {
    return props.project?.attributes?.date_type !== 'no date'
  }


  if (['options', 'attributes.image', 'attributes.total'].includes(column.field)) {
    return true
  }

  const value = get(props.project, column.field)

  if (Array.isArray(value)) {
    return value.length > 0
  }

  return !!value
}

const root = ref<HTMLElement | null>(null)

function preventTouchDefault() {
  const dragHandle = root.value?.querySelector('.drag-handle')
  if (!dragHandle) {
    return
  }

  dragHandle.addEventListener('touchmove', async (e) => {
    await sleep(KANBAN_DRAG_DELAY_MOBILE)
    e.preventDefault()
  })
}

onMounted(() => {
  preventTouchDefault()
})
</script>
