<template>
  <div ref="dropZoneRef" class="relative">
    <div v-show="isOverDropZone || isDropzone">
      <div
        class="drag-container text-gray-500 cursor-pointer"
        :class="{
          'text-gray-900': isOverDropZone
        }"
        @click="onDrop()"
      >
        {{ dragAndDropText }}
      </div>
    </div>
    <div v-show="!isOverDropZone && !isDropzone">
      <ProjectCellNew
        v-if="isProjectNode"
        :params="params"
      />
      <template v-else-if="isFolderNode">
        <i class="fas fa-folder text-gray-500 mr-2" />
        <span>
          {{ folder?.attributes?.name }}
        </span>
      </template>
      <div
        v-else-if="isFileNode"
        class="flex space-x-2 items-center cursor-pointer"
        @click="showPreviewDialog = true"
      >
        <BaseFilePreview
          :file="file.attributes"
          :thumbnail="true"
          size="sm"
          class="object-cover text-center"
        />
        <span>
          {{ file?.attributes?.name || '- -' }}
        </span>
      </div>
      <FilePreviewDialog
        v-model="showPreviewDialog"
        :file="file"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
// Utils
import { computed, ref } from "vue"
import { get } from "lodash-es";
import { checkFileSizeLimitExceeded } from '@/modules/common/utils/fileUtils';
import i18n from "@/i18n";

// Components
import ProjectCellNew from "@/components/table/cells/ProjectCellNew.vue"

// Composables
import { useDropZone } from '@vueuse/core'
import useEntityCrud, { EntityTypes } from "@/modules/common/composables/useEntityCrud"
import { useStore } from "vuex"

const store = useStore()

const {
  entityTypes,
  triggerEntityCreate
} = useEntityCrud()

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const showPreviewDialog = ref(false)

const isFileNode = computed(() => {
  return props.params?.value?.startsWith('media_')
})

const isFolderNode = computed(() => {
  return props.params?.value?.startsWith('folders_')
})

const isProjectNode = computed(() => {
  return props.params?.value?.startsWith('projects_')
})

const isDropzone = computed(() => {
  return props.params?.value?.startsWith('dropzone')
})

const file = computed(() => {
  return props.params?.data || props.params.node?.allLeafChildren?.[0]?.data
})

const folder = computed(() => {
  if (isFolderNode.value) {
    return props.params?.data
  }

  return get(file.value, 'relationships.folder')
})

const project = computed(() => {
  return get(file.value || folder.value, 'relationships.project')
})

const isInsideProject = computed(() => {
  return !!store.getters.project_id
})

const dropZoneRef = ref<HTMLDivElement>()
const { isOverDropZone } = useDropZone(dropZoneRef, onDrop)

const dragAndDropText = computed(() => {
  if (isOverDropZone.value) {
    return i18n.t(`Drop files here to upload...`)
  }

  if (isInsideProject.value) {
    return i18n.t(`Drag & drop files here or over folders to upload...`)
  }

  return i18n.t(`Drag & drop files here, over folders or projects to upload...`)
})

function onDrop(files: File[] | null = null) {
  const filesToUpload = (files || []).map(file => {
    return {
      ...file,
      name: file.name,
      size: file.size,
      sizeLimitExceeded: checkFileSizeLimitExceeded(file),
      raw: file
    }
  })
  
  triggerEntityCreate(entityTypes.File as EntityTypes, {
    folder: folder.value,
    project: project.value,
    files: filesToUpload,
  })
}

</script>
