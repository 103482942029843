<template>
  <BaseInlineInput>
    <template #default="{ triggerSave }">
      <div class="relative inline-input">
        <div class="absolute top-1/2 -translate-y-1/2 left-1/2 w-full -translate-x-1/2 opacity-0 -mt-4">
          <slot
            :onChange="triggerSave"
          />
        </div>
        <span class="activator">
          <slot
            name="activator"
            :onChange="triggerSave"
           />
        </span>
      </div>
    </template>
  </BaseInlineInput>
</template>
<script setup />
