export function getMaxZIndex(selector = '') {
  const selectorAll = `body ${selector || '*'}`
  const allElements = document.querySelectorAll(selectorAll);

  let max = 0;

  for (let el of allElements) {
    const zIndex = parseFloat(window.getComputedStyle(el).zIndex)

    if (!Number.isNaN(zIndex) && zIndex > max) {
      max = zIndex
    }
  }

  return max;
}
