import { UserTypes } from "@/modules/users/util/userUtils"
import i18n from "@/i18n";
import { encodeFilters } from "@/modules/common/utils/filterUtils";
import { computed } from "vue";

import Data = API.Data
import User = App.Domains.Users.Models.User
import Group = App.Domains.Groups.Models.Group

import useCan from "@/modules/common/composables/useCan";
import { getSetting } from "@/plugins/settingsPlugin";
const {
  can,
  canRole,
  canUser,
  actions,
  isRoleGreaterOrEqual,
  roles
} = useCan()

export enum Widgets {
  PinnedItems = "PinnedItemsWidget",
  Projects = "ProjectsWidget",
  Tasks = "TasksWidget",
  Time = "TimeWidget",
  Payments = "PaymentsWidget",
  Files = "FilesWidget",
}

export type DashboardWidgetType = {
  name: string;
  component: Widgets;
  order: number;
  icon?: string;
  description?: string;
  filters?: string;
  showIf?: boolean | ((data: any) => boolean);
  displayMaxCount?: number;
}

export type WidgetUI = {
  listPath: string
  listLabel: string
  entityCreate: string | object | null | Function
  footerKey: string
}

export type WidgetData = {
  target: string;
  dataGetterPath: string;
  columnsGetter?: string;
  groupedDataGetter?: string;
  extraFilters?: any[];
  loading: boolean;
}

export type DashboardModel = {
  id?: string | number | null,
  scope: UserTypes,
  widgets: DashboardWidgetType[],
  user_id?: number | null,
  role_ids?: number[] | null,
}

export enum WidgetContextPages {
  HomeDashboard = 'home-dashboard',
  UserProfile = 'user-profile',
  GroupProfile = 'group-profile',
}

export type WidgetsContext = {
  page: WidgetContextPages,
  user?: ReturnType<typeof computed<Data<User>>>,
  group?: ReturnType<typeof computed<Data<Group>>>,
}

type EmptyPlaceholdersMap = Record<WidgetContextPages, Partial<Record<Widgets, string>>>

export function getEmptyPlaceholders(): EmptyPlaceholdersMap {
  return {
    [WidgetContextPages.HomeDashboard]: {
      [Widgets.PinnedItems]: i18n.t(`You have no pinned items.`),
      [Widgets.Projects]: i18n.t(`You do not have any active projects.`),
      [Widgets.Tasks]: i18n.t(`You do not have any active tasks.`),
      [Widgets.Time]: i18n.t(`You are not assigned to any time entries.`),
      [Widgets.Payments]: i18n.t(`There are no payments assigned to a group you're part of.`),
      [Widgets.Files]: i18n.t(`There are no files on projects you have been invited to.`),
    },
    [WidgetContextPages.UserProfile]: {
      [Widgets.PinnedItems]: i18n.t(`No pinned items.`),
      [Widgets.Projects]: i18n.t(`This user is not invited to any active projects.`),
      [Widgets.Tasks]: i18n.t(`This user does not have any active tasks.`),
      [Widgets.Time]: i18n.t(`No time entries attached to this user.`),
      [Widgets.Files]: i18n.t(`This user hasn't uploaded any files.`),
    },
    [WidgetContextPages.GroupProfile]: {
      [Widgets.Projects]: i18n.t(`This group is not assigned to any active projects.`),
      [Widgets.Tasks]: i18n.t(`This group does not have any active tasks.`),
      [Widgets.Time]: i18n.t(`No time entries attached to this group.`),
      [Widgets.Payments]: i18n.t(`This group has no pending payments.`),
    },
  }
}

export const emptyPlaceholderIcons: Record<Widgets, string> = {
  [Widgets.PinnedItems]: 'fa-thumbtack',
  [Widgets.Projects]: 'fa-inbox',
  [Widgets.Tasks]: 'fa-badge-check',
  [Widgets.Time]: 'fa-stopwatch',
  [Widgets.Payments]: 'fa-money-bill',
  [Widgets.Files]: 'fa-file',
}

export const widgetInfoTexts: Record<Widgets, string> = {
  [Widgets.PinnedItems]: i18n.t(`This section will display your pinned items.`),
  [Widgets.Projects]: i18n.t(`This section will display all projects you have access to.`),
  [Widgets.Tasks]: i18n.t(`This section will display all tasks you have access to.`),
  [Widgets.Time]: i18n.t(`This section will display all time entries you are assigned to.`),
  [Widgets.Payments]: i18n.t(`This section will display all payments assigned to a group you are part of.`),
  [Widgets.Files]: i18n.t(`This section will display all files you have access to.`),
}

export const displayWidgetConditions: Partial<Record<Widgets, (scope?: UserTypes) => boolean>> = {
  [Widgets.Time]: (scope?: UserTypes) => {
    if (scope === UserTypes.Collaborators) {
      return canRole(roles.COLLABORATOR, actions.VIEW_TIME_ENTRIES)
    }

    return can(actions.VIEW_TIME_ENTRIES)
  },
  [Widgets.Payments]: (scope?: UserTypes) => {
    if (scope === UserTypes.Collaborators) {
      return getSetting('show_payments_card_to_collaborators', true)
    }

    if (isRoleGreaterOrEqual(roles.CREATOR)) {
      return true
    }

    return getSetting('show_payments_card_to_collaborators', true)
  }
}

export const systemDefaultDashboard: Data<DashboardModel> = {
  id: '',
  attributes: {
    scope: UserTypes.All,
    widgets: [
      // TODO: Uncomment after implementing pinned items
      // {
      //   order: 1,
      //   component: Widgets.PinnedItems, 
      // },
      {
        name: i18n.t('Projects'),
        order: 2,
        component: Widgets.Projects,
        description: i18n.t('This section will display all projects you have access to.'),
        icon: '',
        filters: JSON.stringify({
          filters: encodeFilters([
            {
              key: 'closed',
              value: {
                show: false
              }
            },
            {
              key: 'sorts',
              value: {
                column: 'created_at',
                order: 'desc'
              }
            }
          ]),
        }),
      },
      {
        name: i18n.t('Tasks'),
        order: 3,
        component: Widgets.Tasks,
        description: i18n.t('This section will display all tasks you have access to.'),
        icon: '',
        filters: JSON.stringify({
          filters: encodeFilters([
            {
              key: 'closed',
              value: {
                show: false
              }
            },
            {
              key: 'sorts',
              value: {
                column: 'sortable_date',
                order: 'asc'
              }
            },
            {
              key: 'date-only',
              value: {
                show: false
              }
            },
            {
              key: 'my-tasks',
              value: {
                show: true
              }
            }
          ]),
        }),
      },
      {
        name: i18n.t('Time'),
        order: 4,
        component: Widgets.Time,
        description: i18n.t('This section will display all time entries you are assigned to.'),
        icon: '',
        filters: JSON.stringify({
          filters: encodeFilters([
            {
              key: 'my-time',
              value: {
                show: true
              }
            },
            {
              key: 'sorts',
              value: {
                column: 'date',
                order: 'desc'
              }
            }
          ])
        })
      },
      {
        name: i18n.t('Payments'),
        order: 5,
        component: Widgets.Payments,
        description: i18n.t("This section will display all payments assigned to a group you are part of."),
        icon: '',
        filters: JSON.stringify({
          filters: encodeFilters([
            {
              key: 'my-payments',
              value: {
                show: true
              }
            },
            {
              key: 'sorts',
              value: {
                column: 'created_at',
                order: 'desc'
              }
            }
          ])
        })
      },
      {
        name: i18n.t('Files'),
        order: 6,
        component: Widgets.Files,
        description: i18n.t("This section will display all files you have access to."),
        icon: '',
        filters: JSON.stringify({
          filters: encodeFilters([
            {
              key: 'sorts',
              value: {
                column: 'created_at',
                order: 'desc'
              }
            }
          ])
        })
      }
    ]
  }
}

const scopedSystemDefaultWidgetFilters: Partial<Record<UserTypes, Partial<Record<Widgets, string>>>> = {
  [UserTypes.Collaborators]: {
    [Widgets.Tasks]: JSON.stringify({
      filters: encodeFilters([
        {
          key: 'closed',
          value: {
            show: false
          }
        },
        {
          key: 'sorts',
          value: {
            column: 'sortable_date',
            order: 'asc'
          }
        },
        {
          key: 'date-only',
          value: {
            show: false
          }
        },
        {
          key: 'my-tasks',
          value: {
            show: false
          }
        }
      ]),
    })
  }
}

export function applyScopedSystemDefaultWidgetFilters(widgets: DashboardWidgetType[], scope: UserTypes): DashboardWidgetType[] {
  return widgets.map(widget => {
    const customFilters = scopedSystemDefaultWidgetFilters[scope]?.[widget.component]

    if (customFilters) {
      return {
        ...widget,
        filters: customFilters
      }
    }

    return widget
  })
}


export const userWidgets: DashboardWidgetType[] = [
  // TODO: Uncomment after implementing pinned items
  // {
  //   order: 1,
  //   component: Widgets.PinnedItems, 
  // },
  {
    name: i18n.t('Projects'),
    order: 2,
    component: Widgets.Projects,
  },
  {
    name: i18n.t('Tasks'),
    order: 3,
    component: Widgets.Tasks, 
  },
  {
    name: i18n.t('Time'),
    order: 4,
    component: Widgets.Time,
    showIf(user: Data<User>) {
      return displayWidgetConditions[Widgets.Time]?.() && canUser(user, actions.VIEW_TIME_ENTRIES)
    }
  },
  {
    name: i18n.t('Files'),
    order: 5,
    component: Widgets.Files,
  }
]

export function getUserWidgets(user: Data<User>): DashboardWidgetType[] {
  const firstName = user?.attributes?.first_name
  
  const filterColumns: Partial<Record<Widgets, string>> = {
    [Widgets.Projects]: 'user_ids',
    [Widgets.Tasks]: 'allocated_ids',
    [Widgets.Time]: 'user_ids',
    [Widgets.Files]: 'created_by',
  }

  const sortColumns: Partial<Record<Widgets, string>> = {
    [Widgets.Projects]: 'created_at',
    [Widgets.Tasks]: 'sortable_date',
    [Widgets.Time]: 'date',
    [Widgets.Files]: 'created_at',
  }

  return userWidgets.filter(widget => {
    if (typeof widget.showIf === 'function') {
      return widget.showIf(user)
    }

    return widget.showIf !== false
  }).map(widget => {
    const filtersAndSorts = []
    const localFilters = {}

    if (filterColumns[widget.component]) {
      filtersAndSorts.push({
        key: 'filters',
        value: [{
          name: i18n.t('People'),
          column: filterColumns[widget.component],
          query: [user?.id],
          displayValue: user?.attributes?.name || user?.attributes?.email
        }]
      })
    }

    if (sortColumns[widget.component]) {
      filtersAndSorts.push({
        key: 'sorts',
        value: [{
          name: i18n.t('Date'),
          column: sortColumns[widget.component],
          direction: 'desc',
        }]
      })
    }

    const filters = {
      filters: encodeFilters(filtersAndSorts),
      localFilters: encodeFilters(localFilters),
    }

    return {
      ...widget,
      name: firstName
        ? i18n.tc('users data', {
          firstName,
          dataType: widget.name,
        })
        : widget.name,
      filters: JSON.stringify(filters),
    }
  })
}

export const groupWidgets: DashboardWidgetType[] = [
    {
      name: i18n.t('Projects'),
      order: 2,
      component: Widgets.Projects,
    },
    {
      name: i18n.t('Tasks'),
      order: 3,
      component: Widgets.Tasks, 
    },
    {
      name: i18n.t('Time'),
      order: 4,
      component: Widgets.Time, 
      showIf(group: Data<Group>) {
        if (!displayWidgetConditions[Widgets.Time]) {
          return true
        }
        return displayWidgetConditions[Widgets.Time]()
      }
    },
    {
      name: i18n.t('Payments'),
      order: 5,
      component: Widgets.Payments,
      showIf(group: Data<Group>) {
        if (!displayWidgetConditions[Widgets.Payments]) {
          return true
        }

        return displayWidgetConditions[Widgets.Payments]()
      }
    }
]

export function getGroupWidgets(group: Data<Group>): DashboardWidgetType[] {
  const filterColumns: Partial<Record<Widgets, string>> = {
    [Widgets.Projects]: 'group_ids',
    [Widgets.Tasks]: 'group_ids',
    [Widgets.Time]: 'group_ids',
    [Widgets.Payments]: 'group_ids',
  }

  const sortColumns: Partial<Record<Widgets, string>> = {
    [Widgets.Projects]: 'created_at',
    [Widgets.Tasks]: 'sortable_date',
    [Widgets.Time]: 'date',
    [Widgets.Payments]: 'created_at',
  }

  return groupWidgets.filter(widget => {
    if (typeof widget.showIf === 'function') {
      return widget.showIf(group)
    }

    return widget.showIf !== false
  }).map(widget => {
    const filtersAndSorts = []
    const localFilters = {}

    if (filterColumns[widget.component]) {
      filtersAndSorts.push({
        key: 'filters',
        value: [{
          name: i18n.t('Groups'),
          column: filterColumns[widget.component],
          query: [group?.id],
          displayValue: group?.attributes?.name
        }]
      })
    }

    if (sortColumns[widget.component]) {
      filtersAndSorts.push({
        key: 'sorts',
        value: [{
          name: i18n.t('Date'),
          column: sortColumns[widget.component],
          direction: 'desc',
        }]
      })
    }

    const filters = {
      filters: encodeFilters(filtersAndSorts),
      localFilters: encodeFilters(localFilters),
    }

    return {
      ...widget,
      filters: JSON.stringify(filters),
    }
  })
}

export function applyWidgetPermissionsCheck(widgetList: DashboardWidgetType[], scope?: UserTypes): DashboardWidgetType[] {
  return widgetList.filter(widget => {
    if (!displayWidgetConditions[widget.component]) {
      return true
    }
    return displayWidgetConditions[widget.component]?.(scope)
  })
}
