<template>
  <div class="w-full flex flex-col h-full align-middle">
    <TopFilters
      :columns="customFieldColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search Custom Fields...')"
    >
      <template #action v-if="canCreateCustomFields">
        <BaseButton
          @click="triggerCreateCustomField()"
        >
          {{ $t("+ New Custom Field") }}
        </BaseButton>
      </template>
      <template #extra-filters>
        <CompletedFilter
          :default-filter="true"
          :title="$t('Show disabled custom fields?')"
          :label="$t('Disabled')"
          @change="disabledFilterChanged"
        />
      </template>
    </TopFilters>

    <DataSyncingIndicator
      v-if="customFields.length && customFieldsLoading"
    />

    <CustomFieldsTable
      :data="customFields"
      :loading="customFieldsLoading"
      class="flex-1"
    />
  </div>
</template>

<script>
// Components
import CustomFieldsTable from "@/modules/accounts/components/CustomFieldsTable.vue";
import TopFilters from "@/modules/filters/components/TopFilters.vue";
import CompletedFilter from "@/modules/filters/components/CompletedFilter.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue";

// Utils
import { triggerCreateCustomField } from "@/modules/accounts/utils/modelUtils"
import { orderBy } from "lodash-es"

export default {
  components: {
    CustomFieldsTable,
    TopFilters,
    CompletedFilter,
    DataSyncingIndicator,
  },
  data() {
    return {
      availableFilters: ['sort', 'group', 'filter'],
    };
  },
  computed: {
    customFields() {
      const customFields = this.$store.getters['accounts/orderedCustomFields']() || []
      return orderBy(customFields, ['attributes.entity_type', 'attributes.order'])
    },
    customFieldColumns() {
      const columns = this.$store.getters['accounts/customFieldActiveColumns'] || []
      return columns.filter(c => {
        if (typeof c.disabled === 'function') {
          return !c.disabled()
        }

        return !c.disabled
      })
    },
    customFieldsLoading() {
      if (this.$store.getters.project_id) {
        return this.$store.state.projects.currentProjectLoading
      }

      return this.$store.state.accounts.customFieldsLoading;
    },
    project_id() {
      return this.$store.getters.project_id
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
    canCreateCustomFields() {
      const hasPermissions = this.can(this.$actions.CREATE_CUSTOM_FIELDS) || (this.project_id && this.can(this.$actions.CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS))
      return hasPermissions && !this.isCurrentProjectClosed
    }
  },
  methods: {
    disabledFilterChanged(value) {
      this.$store.commit('accounts/setShowDisabledCustomFields', value)
    },
    triggerCreateCustomField,
  },
};
</script>
