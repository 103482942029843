import tippy from 'tippy.js'
import { VueRenderer } from '@tiptap/vue-3'
import CustomMention from './customMentionExtension.js'
import MentionList from '@/components/html/extensions/components/MentionList.vue'
import store from "@/store/index.js";

function searchUser(user, query) {
  const { name, email } = user.attributes
  let queryLower = query.toLowerCase()
  return name.toLowerCase().startsWith(queryLower) || email.toLowerCase().startsWith(queryLower)
}

const MentionExtension = {
  items: async ({ query, editor }) => {
    let project = store.state.projects?.currentProject;
    
    if (!project)  {
      const project_id = editor?.options?.editorProps?.projectId

      project = await store.dispatch('projects/getProjectById', {
        id: project_id,
        returnEntity: true,
        allowCache: true,
        related: 'users'
      })
    }

    const projectUsers = project?.relationships?.users || []
    return projectUsers.filter(user => searchUser(user, query)).slice(0, 50)
  },

  render: () => {
    let component
    let popup

    return {
      onStart: props => {
        function hidePopup() {
          popup[0]?.hide()
        }

        const projectId = props.editor?.options?.editorProps?.projectId
        
        component = new VueRenderer(MentionList, {
          props: {
            ...props,
            projectId,
            hidePopup: hidePopup,
          },
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component?.updateProps(props)

        popup?.[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.[0].hide()

          return true
        }

        return component?.ref?.onKeyDown(props)
      },

      onExit() {
        popup?.[0].destroy()
        component?.destroy()
      },
    }
  },
}

export default CustomMention.configure({
  HTMLAttributes: {
    class: 'mention',
  },
  suggestion: MentionExtension,
})
