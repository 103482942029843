<template>
  <div class="relative lg:inline-block text-left mt-2 lg:mt-0 ">
    <BaseDropdown
      :options="filterOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
    >
      <div class="relative lg:inline-block text-left w-full">
        <BaseButton
          variant="white-secondary"
          class="group"
          block
        >
          <div
            v-if="$useNewLayout"
            class="mr-1"
          >
            {{ $t('Subtasks') }}:
          </div>
          <i
            v-else
            class="fas fa-diagram-subtask mr-2"
          />
          <span
            class="group-hover:text-white"
            :class="{
              'text-primary-500': showDetails,
              'text-gray-500': !showDetails,
            }"
          >
            {{ showDetails ? $t('Yes') : $t('No') }}
          </span>
        </BaseButton>
      </div>
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ title || $t('Show subtasks detail?') }}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    columns: {
      type: Array,
      default: () => []
    },
    defaultFilter: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showDetails: this.defaultFilter || false,
    }
  },
  computed: {
    filterOptions() {
      return [
        {
          label: this.$t('No'),
          action: () => this.setValue(false),
          variant: !this.showDetails ? 'active' : ''
        },
        {
          label: this.$t('Yes'),
          action: () => this.setValue(true),
          variant: this.showDetails ? 'active' : ''
        }
      ]
    }
  },
  methods: {
    setValue(value) {
      this.showDetails = value
      this.$emit('change', this.showDetails)
    }
  },
  watch: {
    defaultFilter() {
      this.showDetails = this.defaultFilter || false
    }
  }
}
</script>
