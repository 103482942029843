<template>
  <div
    class="relative col-span-8 md:col-span-5 xl:col-span-6 md:flex-col-grow"
  >
    <div
      v-if="note"
      class="px-6 py-2 flex-wrap flex items-center gap-4 bg-white"
      :class="{
        'rounded-lg shadow': !$useNewLayout,
        'border-b border-t sticky top-0': $useNewLayout,
      }"
    >
      <NotePrivacyInlineSelect
        v-if="canNote(NoteActions.CHANGE_VISIBILITY)"
        v-model="noteVisibility"
        :disabled="isCurrentProjectClosed"
        class="inline-flex items-center cursor-pointer"
      />
      <PinProjectNote
        :note="note"
        :disabled="isCurrentProjectClosed"
        class="text-xs text-gray-400 flex"
        icon-always
      >
        <div class="flex gap-1">
          <span>{{ note?.attributes?.pinned ? $t('Pinned') : $t('Not pinned') }}</span>
          <span class="hidden sm:block md:hidden lg:block">{{ $t('to project dashboard') }}</span>
        </div>
      </PinProjectNote>
      <button
        v-if="canNote(NoteActions.DELETE) && !isCurrentProjectClosed"
        v-loading="deletingNote"
        class="text-xs text-gray-400 hover:text-red-500 flex items-center gap-2"
        @click="deleteNote"
      >
        <i class="far fa-trash" />
        <span>{{ $t('Delete note') }}</span>
      </button>
    </div>
    <div
      v-if="note"
      class="md:flex-col-grow md:h-20 bg-white"
      :class="{
        'rounded-lg shadow border border-gray-200 mt-2': !$useNewLayout,
      }"
    >
      <div class="p-4 sm:p-8 pt-12 lg:pt-8 overflow-auto min-h-[300px] mt-1">
        <CollaborativeHtmlEditor
          id="project-note-area2"
          ref="htmlEditor"
          v-model="notes"
          v-model:title="title"
          v-model:mentions="mentions"
          :key="noteId"
          :enforceTitle="true"
          :projectId="projectId"
          :readonly="disableEdit"
          :real-time="true"
          :real-time-doc-url="apiUrl"
          :realTimeValue="note?.attributes?.description_collaboration"
          real-time-doc-field="description_collaboration"
          @blur="debouncedSave"
        >
          <template #loading>
            <ProjectNoteLoading />
          </template>
        </CollaborativeHtmlEditor>
      </div>
    </div>
    <div v-else>
      <ProjectNoteLoading />
    </div>
  </div>
  <ProjectNoteSetTitleDialog
    v-model="titleEditDialogVisible"
    @ok="title = $event"
  />
</template>
<script setup lang="ts">
import { debounce } from 'lodash-es'
// Components
import axios from 'axios'
import PinProjectNote from '@/modules/projects/components/projectNotes/PinProjectNote.vue'
import { computed, onMounted, ref } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import i18n from "@/i18n";
import { $deleteConfirm } from "@/components/common/dialog/modalPlugin";
import { useStore } from "vuex";
import { error, success } from "@/components/common/NotificationPlugin";
import ProjectNoteLoading from "@/modules/projects/components/ProjectNoteLoading.vue";
import ProjectNoteSetTitleDialog from "@/modules/projects/components/ProjectNoteSetTitleDialog.vue";
import NotePrivacyInlineSelect from "@/modules/projects/components/projectNotes/NotePrivacyInlineSelect.vue";
import { canNote, NoteActions, NotesVisibility } from "@/modules/projects/utils/noteUtils";

const route = useRoute()
const store = useStore()
const router = useRouter()

const props = defineProps({
  noteId: {
    type: String,
  },
})

const titleEditDialogVisible = ref(false)

const loading = computed(() => store.state.projects.notesLoading.current)
const note = computed(() => store.state.projects.currentProjectNote)
const projectId = computed(() => route.params.id)
const apiUrl = computed(() => `${axios.defaults.baseURL}/restify/project-notes/${props.noteId}`)

const isCurrentProjectClosed = computed(() => {
  return store.getters['projects/isCurrentProjectClosed']
})

const noteVisibility = computed({
  get() {
    return note.value?.attributes?.visibility
  },
  async set(value: NotesVisibility) {
    await store.dispatch('projects/changeNoteVisibility', {
      value,
      id: note.value.id,
    })
  },
})

const notes = computed({
  get() {
    return note.value?.attributes?.notes
  },
  set(value) {
    store.commit('projects/updateProjectNote', { prop: 'attributes.notes', value })
  },
})

const title = computed({
  get() {
    return note.value?.attributes?.name
  },
  set(value) {
    if (loading.value) {
      return
    }
    const oldTitle = note.value?.attributes?.name
    let title = value
    if (!value?.trim()) {
      title = oldTitle || 'Untitled'
    }
    store.commit('projects/updateProjectNote', { prop: 'attributes.name', value: title })
  },
})

const mentions = computed({
  get() {
    return note.value?.attributes?.notifiable_user_ids || []
  },
  set(value) {
    store.commit('projects/updateProjectNote', { prop: 'attributes.notifiable_user_ids', value })
  },
})

const debouncedSave = ref()

const htmlEditor = ref()

const disableEdit = computed(() => {
  return isCurrentProjectClosed.value || !canNote(NoteActions.EDIT, noteVisibility.value) || noteDeleted.value
})

async function onSave() {
  if (disableEdit.value) {
    return
  }

  const value = htmlEditor.value?.getHTML()
  if (value) {
    store.commit('projects/updateProjectNote', { prop: 'attributes.notes', value })
    store.commit('projects/updateProjectNote', { prop: 'attributes.name', value: title.value })
  }

  try {
    await store.dispatch('projects/saveCurrentProjectNote')
  } catch(err) {
    error(i18n.t('Could not save note'))
    console.error(err)
  }
}
const deletingNote = ref(false)
const noteDeleted = ref(false)
async function deleteNote() {
  const confirmed = await $deleteConfirm({
    title: i18n.t('Delete Note'),
    description: i18n.t('Are you sure? This will permanently remove the note.'),
  })

  if (!confirmed) {
    return
  }

  try {
    deletingNote.value = true
    await store.dispatch('projects/removeProjectNote', { note: note.value })
    success(i18n.t('The note was successfully deleted'))
    router.replace({
      path: `/projects/${projectId.value}/notes`,
      query: {
        ...route.query
      }
    })
    noteDeleted.value = true
  }
  catch(e: any) {
    if (e.handled) {
      return
    }
    error(i18n.t('Could not delete note'))
  }
  finally {
    deletingNote.value = false
  }
}

async function fetchProject() {
  await store.dispatch('projects/getProjectById', {
    id: projectId.value,
    forceFetch: true,
    silent: true,
  })
}

async function saveDataAndFetchProject(shouldFetchProject = false) {
  await onSave()
  if (shouldFetchProject) {
    await fetchProject()
  }
}

onMounted(() => {
  debouncedSave.value = debounce(onSave, 2000)
})

onBeforeRouteLeave((to) => {
  const shouldFetchProject = ['Project View', 'Template View'].includes(to.name as string)
  saveDataAndFetchProject(shouldFetchProject)
})

defineExpose({
  onSave,
})
</script>
<style>
.project-notes .prose [data-type="emoji"] {
  @apply text-2xl;
}
</style>
