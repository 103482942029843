import { Editor, Extension, Range } from '@tiptap/core'
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion'
import { PluginKey } from 'prosemirror-state'
import suggestionList from "@/components/html/suggestion"
import { SuggestionItem } from "@/components/html/types/suggestionTypes";

export const SlashMenuPluginKey = new PluginKey('slashMenu')

export interface CommandsExtensionOptions {
  suggestion: Omit<SuggestionOptions, 'editor'>
}


const CommandsExtension = Extension.create<CommandsExtensionOptions>({
  name: 'slashMenu',
  addOptions() {
    return {
      suggestion: {
        char: '/',
        pluginKey: SlashMenuPluginKey,
        command: ({ editor, range, props }: { editor: Editor, range: Range, props: any }) => {
          props.command({ editor, range, ...props })
        },
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})

export default (commandsList: string[] = []) => {
  return CommandsExtension.configure({
    suggestion: {
      items: ({ query }: { editor: Editor, query: string }) => {
        if (commandsList.length) {
          return suggestionList
            .items({ query })
            .filter((suggestion: SuggestionItem) => commandsList.includes(suggestion?.id as string))
        }
        return suggestionList.items({ query })
      },
      render: suggestionList.render,
    }
  })
}
