<template>
  <el-popover
    :visible="autoShow ? popoverVisible : undefined"
    width="450"
    trigger="click"
    placement="bottom"
    popper-class="avatar-picker-popover"
    v-bind="$attrs"
    @show="tryFocusInput"
  >
    <template #reference>
      <slot name="activator" :avatar="avatar">
        <BaseAvatar
          v-if="avatar || fileUploadPreview"
          class="inline-block"
          :avatar="avatar || fileUploadPreview"
          :size="size"
        />
        <div v-else>
          <slot name="empty">
            <div
              class="cursor-pointer transition ease-in-out duration-150 rounded-md border-gray-300 text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-100 text-sm px-3 py-2 leading-5 inline-flex whitespace-nowrap"
              :class="labelClasses"
            >
              {{ placeholder || $t("+ Add an image") }}
            </div>
          </slot>
        </div>
      </slot>
    </template>

    <el-tabs
      v-model="activeTabName"
      @tab-click="onTabClicked"
      class="avatar-picker-tabs"
    >
      <el-tab-pane
        v-if="!disabledTabs['group']"
        :label="$t('Group')"
        name="group"
      >
        <GroupSelect
          v-model="group_id"
          :excluded-options="(opt) => !opt.attributes.image"
          :placeholder="$t('Choose Group Image...')"
          @raw-change="onGroupChange($event)"
        />
        <div class="text-gray-400 mt-3 text-center text-xs">
          <div>{{ $t("Choose a group logo to use.") }}</div>
        </div>
        <div
          class="mt-12 mb-16  flex w-24 h-24 justify-center align-center mx-auto"
          @click="onGroupChange(group)"
        >
          <BaseLogo
            v-if="group"
            :entity="group"
            size="full"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="!disabledTabs['upload']"
        :label="$t('Upload')"
        name="upload"
      >
        <label :for="uploadId" class="cursor-pointer text-sm text-gray-400 hover:text-gray-500 ml-auto w-full relative rounded-md mt-1 sm:mt-0 sm:col-span-3">
          <BaseFileUpload
            v-model="fileUploadModel"
            v-model:preview="fileUploadPreview"
            @update:modelValue="onImageUpload"
            :placeholder="$t('Click To Upload')"
            :id="uploadId"
            :accept="acceptUploadType"
          >
            <template #activator>
              <div class="p-1 pt-0 text-center flex flex-col items-center">
                <div class="rounded-md bg-primary-500 text-white text-base p-2 max-w-sm w-full mt-5 mb-2">
                  {{$t('Choose an image')}}
                </div>
                <div class="text-gray-400 mt-3 text-center text-xs">
                  <div>{{ $t("Upload your own image. Max file size is 5MB.") }}</div>
                </div>
              </div>
            </template>
          </BaseFileUpload>
          <img
            v-if="fileUploadPreview"
            :src="fileUploadPreview"
            class="m-auto mt-3 h-24 w-24 rounded-md object-cover cursor-pointer flex-1"
          />
        </label>
      </el-tab-pane>
      <el-tab-pane
        v-if="!disabledTabs['emoji']"
        :label="$t('Emoji')"
        name="emoji"
      >
        <VuemojiPicker
          v-if="activeTabName === 'emoji'"
          :is-dark="false"
          @emojiClick="onEmojiChanged"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="!disabledTabs['unsplash'] && isUnsplashConfigured"
        :label="$t('Unsplash')"
        name="unsplash"
      >
        <UnsplashPhotoSearch
          :photoSize="unsplashPhotoSize"
          @photo-clicked="onUnsplashPhotoChanged"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="!disabledTabs['clearbit']"
        :label="$t('Homepage')"
        name="clearbit"
      >
        <ClearbitLogoSearch
          @select-logo="onImageUpload"
          @select-url="onSelectUrl"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="!disabledTabs['url']"
        :label="$t('URL')"
        name="url"
      >
        <UrlImageSearch
          @select-image="onImageUpload"
          @select-url="onSelectUrl"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="!disabledTabs['remove'] && modelValue"
        :label="$t('Remove')"
        name="remove"
        class="remove-avatar-tab border-white border-b-2 cursor-pointer text-gray-400"
      />
    </el-tabs>
  </el-popover>
</template>
<script>

// Components
import { ElTabs, ElTabPane, ElPopover } from 'element-plus'
import { VuemojiPicker } from 'vuemoji-picker'
import GroupSelect from "@/components/selects/GroupSelect.vue"
import UnsplashPhotoSearch from "@/components/common/UnsplashPhotoSearch.vue"
import ClearbitLogoSearch from "@/components/common/ClearbitLogoSearch.vue"
import UrlImageSearch from "@/components/common/UrlImageSearch.vue"

// Helpers
import { encodeEmoji } from "@/modules/common/utils/emojiUtils"
import { isUnsplashConfigured } from "@/modules/common/utils/unsplashUtils"
import { bytesToMegaBytes } from '@/modules/common/utils/fileUtils'
import { readURL } from "@/modules/common/utils/imageUtils.js";
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";

export default {
  name: 'BaseAvatarPicker',
  components: {
    ElTabs,
    ElTabPane,
    GroupSelect,
    VuemojiPicker,
    ElPopover,
    UnsplashPhotoSearch,
    ClearbitLogoSearch,
    UrlImageSearch
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'lg'
    },
    maxImageSizeMb: {
      type: Number,
      default: 5
    },
    unsplashPhotoSize: {
      type: String,
      default: 'thumb' // full|raw|regular|small|thumb
    },
    disabledOptions: {
      type: Array,
      default: () => []
    },
    preprocessUnsplashUrl: {
      type: Function,
      default: (url) => {
        return url.replace('fit=max', 'fit=crop&ar=1:1').replace('crop=entropy&', '')
      }
    },
    shouldUploadImage: {
      type: Boolean,
      default: true,
    },
    labelClasses: {
      type: String,
    },
    uploadFunction: {
      type: Function,
    },
    acceptUploadType: {
      type: String,
      default: 'image/*'
    },
    returnBase64: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default :''
    },
    autoShow: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const {
      checkWillPassStorageLimit
    } = useAccountLimits()

    return {
      checkWillPassStorageLimit
    }
  },
  data() {
    return {
      popoverVisible: false,
      activeTabName: null, // group|upload|emoji|unsplash
      prevActiveTabName: null,
      fileUploadModel: '',
      fileUploadPreview: '',
      group_id: null,
      group: null,
      uploadId: `file-upload-${new Date().getTime()}`
    }
  },
  computed: {
    avatar: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    isUnsplashConfigured() {
      return isUnsplashConfigured()
    },
    disabledTabs() {
      const disabledTabs = {}
      for (const option of this.disabledOptions) {
        disabledTabs[option] = true
      }

      return disabledTabs
    }
  },
  methods: {
    onGroupChange(group) {
      this.group = group
      this.avatar = group?.attributes?.image || ''
      this.popoverVisible = false
    },
    async onImageUpload(image) {
      if (this.returnBase64) {
        this.avatar = await readURL(image)
        return
      }

      const imageSizeInMb = bytesToMegaBytes(image.size);
      if (imageSizeInMb > this.maxImageSizeMb) {
        this.$error(this.$t("Maximum file size exceeded"))
        return
      }
      this.popoverVisible = false
      if (!this.shouldUploadImage) {
        return
      }
      await this.triggerUpload(image)
    },
    async uploadImage() {
      if (!this.fileUploadModel) {
        return null
      }
      try {
        await this.triggerUpload(this.fileUploadModel)
      } catch (err) {
        console.warn('Error uploading the avatar')
      }
    },
    willPassStorageLimit(image) {
      const size = image?.size || 0
      return this.checkWillPassStorageLimit(size)
    },
    async triggerUpload(image) {
      if (this.willPassStorageLimit(image)) {
        return
      }

      if (this.uploadFunction) {
        this.avatar = await this.uploadFunction(image)
        return
      }
      this.avatar = await this.$store.dispatch('files/uploadImage', image)
    },
    onSelectUrl(url) {
      this.avatar = url
      this.popoverVisible = false
    },
    onEmojiChanged(emoji) {
      this.avatar = encodeEmoji(emoji.unicode)
      this.popoverVisible = false
    },
    onUnsplashPhotoChanged(photo) {
      this.avatar = this.preprocessUnsplashUrl(photo.urls[this.unsplashPhotoSize])
      this.popoverVisible = false
    },
    onRemoveClicked() {
      this.avatar = ''
      this.fileUploadModel = ''
      this.fileUploadPreview = ''
      this.popoverVisible = false
    },
    async tryFocusInput() {
      if (!['emoji', 'unsplash'].includes(this.activeTabName)) {
        return;
      }

      await this.$nextTick()

      const activePane = document.querySelector(`.avatar-picker-tabs #pane-${this.activeTabName}`)
      let input
      if (this.activeTabName === 'emoji') {
        input = activePane?.querySelector('emoji-picker')?.shadowRoot?.querySelector('input')
        input?.classList?.add('form-input')
      }
      else {
        input =  activePane?.querySelector('input')
      }
      if (!input) {
        return
      }
      
      input.focus()
    },
    async onTabClicked(tab) {
      if (tab.props.name === 'remove') {
        this.avatar = ''
        this.fileUploadModel = ''
        this.fileUploadPreview = ''
        this.popoverVisible = false
        await this.$nextTick()
        this.activeTabName = this.prevActiveTabName
        this.group_id = null

        return
      }
      
      this.prevActiveTabName = tab.props.name
      this.tryFocusInput()
    },
    initActiveTab() {
      const tabs = ['group', 'upload', 'emoji', 'unsplash', 'clearbit', 'url']
      for (const tab of tabs) {
        if (!this.disabledTabs[tab]) {
          this.activeTabName = tab;
          this.prevActiveTabName = tab;
          return
        }
      }
    },
    async open() {
      this.popoverVisible = true
      
      await this.$nextTick()
      this.tryFocusInput()
    }
  },
  mounted() {
    this.initActiveTab()

    this.autoShow && this.open()
  }
}
</script>
<style lang="scss">

.avatar-picker-popover {

  min-height: 350px;
  max-width: 97vw;

  @media (min-width: 1024px) {
    min-width: 500px !important;
  }

  #tab-remove {
    @apply text-gray-400;
  }

  .el-tabs__item {
    padding: 0 15px;
  }
  
  .el-tabs__item.is-active, .el-tabs__item:hover {
    @apply text-primary-500;
  }

  .el-tabs__active-bar {
    @apply bg-primary-500;
  }
}

</style>
