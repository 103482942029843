<template>
  <div class="space-x-3 flex items-center">
    <ProjectLogoNew
      v-if="task?.relationships?.project"
      :project="task?.relationships?.project"
    />
    <div
      class="space-x-3 flex items-center"
      :class="{
        'cursor-pointer': canOpen
      }"
      @click.stop="onClick"
    >
      <div class="text-sm text-gray-500 line-clamp-1">
        {{ task.attributes.name }}
      </div>
      <Status
        :row="task"
        :params="{
          target: 'tasks',
        }"
      />
    </div>

    <TaskDialog
      v-if="task?.id"
      v-show="showTaskDialog"
      v-model="showTaskDialog"
      :taskId="task?.id"
      @close="showTaskDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import Status from "@/components/table/Status.vue";
import ProjectLogoNew from "@/modules/projects/components/ProjectLogoNew.vue";

// Utils
import { PropType, computed, ref } from 'vue';

// Composables
import useCan from "@/modules/common/composables/useCan.js";

import Data = API.Data
import Task = App.Domains.Tasks.Models.Task;

const { can, actions } = useCan()

const props = defineProps({
  task: {
    type: Object as PropType<Data<Task>>,
    required: true
  }
})

const canOpen = computed(() => {
  return can(actions.OPEN_TASKS)
})

const showTaskDialog = ref(false)

function onClick() {
  if (!canOpen.value) {
    return
  }

  showTaskDialog.value = true
}
</script>
