import Image from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import ResizableNodeTemplate from './components/ResizableNodeTemplate.vue';

export default Image.extend({
  name: 'resizableImage',

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: 'auto',
        renderHTML: (attributes) => {
          return {
            width: attributes.width
          };
        }
      },
      height: {
        default: 'auto',
        renderHTML: (attributes) => {
          return {
            height: attributes.height
          };
        }
      },
      isDraggable: {
        default: true,
        renderHTML: (attributes) => {
          return {};
        }
      },
      uuid: {
        default: '',
      }
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ResizableNodeTemplate);
  }
});
