<template>
  <template v-if="!task?.id || !showTimeFields">
    <BaseTooltip
      :content="$t('You can track time after creating the task')"
      :disabled="Boolean(task?.id)"
      class="col-span-full grid grid-cols-10"
    >
      <label
        class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1"
        :class="{
          'opacity-50': !task?.id
        }"
      >
        <span>
          <i class="fa-regular fa-stopwatch mr-2 w-4" />
          <span>{{ $t('Time Tracking') }}</span>
        </span>
      </label>
      <div class="col-span-6">
        <div
          class="p-1 text-sm text-gray-400"
          :class="{
            'cursor-not-allowed': disabled,
            'cursor-pointer hover:bg-gray-100': !disabled
          }"
          @click="!disabled && (showTimeFields = true)"
        >
          {{ $t(`+ Allocate & record time`) }}
        </div>
      </div>
    </BaseTooltip>
  </template>
  <template v-else>
    <label
      class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1"
    >
      <span class="inline">
        <i class="fa-regular fa-calendar-plus mr-2 w-4" aria-hidden="true"/>
        <span>{{ $t('Allocated Time') }}</span>
      </span>
    </label>
    <div class="col-span-6">
      <div
        role="button"
        class="p-1 text-sm text-gray-400"
        :class="{
          'cursor-not-allowed': disabled,
          'cursor-pointer hover:bg-gray-100': !disabled
        }"
        @click="onOpenTimeAllocation"
      >
        {{ minutesToFormattedTime(timeAllocation.attributes?.allocated_minutes || 0) }}
      </div>
    </div>

    <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
      <span class="inline">
        <i class="fa-regular fa-stopwatch mr-2 w-4" aria-hidden="true"/>
        <span>{{ $t('Actual Time') }}</span>
      </span>
    </label>

    <div class="col-span-6">
      <div
        v-if="timeEntries?.task_id"
        role="button"
        class="p-1 text-sm text-gray-400"
        :class="{
          'cursor-not-allowed': disabled,
          'cursor-pointer hover:bg-gray-100': !disabled
        }"
        @click="onOpenTimeEntries"
      >
        {{ minutesToFormattedTime(allocatedMinutes) }}
      </div>
      <div
        v-else
        class="w-12 text-sm h-5 mt-1 animate-pulse bg-gray-200 rounded"
      />
    </div>
  </template>
  <div class="hidden">
    <TaskTimeTrackingDialog
      v-model="showActualTimeDialog"
      :task="task"
      @close="showActualTimeDialog = false"
    />

    <AllocatedTimeDialog
      v-model="showAllocationTimeDialog"
      :allocated-time="timeAllocation"
      :task-scoped="true"
      @save="addTimeAllocation"
      @close="showAllocationTimeDialog = false"
    >
      <template #description>
        {{ $t('Allocate the time you expect to spend on this task.') }}
      </template>
    </AllocatedTimeDialog>
  </div>
</template>
<script lang="ts" setup>
import { get } from 'lodash-es'
import { PropType, ref, computed, watch } from 'vue'
import { minutesToFormattedTime } from '@/modules/common/utils/timeUtils'
import AllocatedTimeDialog from '@/modules/time/components/AllocatedTimeDialog.vue'
import TaskTimeTrackingDialog from '@/modules/tasks/components/TaskTimeTrackingDialog.vue'
import { useStore } from 'vuex'
import useCan from '@/modules/common/composables/useCan'

const { can, actions } = useCan()

const store = useStore()

const props = defineProps({
  task: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})

const showTimeFields = ref<boolean>(false)
const showActualTimeDialog = ref(<boolean>false)
const showAllocationTimeDialog = ref(<boolean>false)

const timeAllocation = ref({
  allocated_minutes: 0,
  task_id: null,
  project_id: null,
  group_id: null,
  user_id: null,
} as Record<string, any>)

const timeEntries = computed(() => {
  return store.state.tasks.taskTimeEntries;
})

const hasTimeEntities = computed(() => {
  if (!props.task?.id) {
    return false
  }
  return timeEntries.value?.data?.length || props.task.relationships?.timeAllocation?.id
})

watch(() => hasTimeEntities.value, (value: boolean) => {
  showTimeFields.value = value
}, { immediate: true })

const allocatedMinutes = computed(() => {
  return timeEntries.value?.data?.reduce((acc: number, entry: Record<string, any>) => {
    return acc + entry.attributes.worked_minutes
  }, 0) || 0
})

function onOpenTimeAllocation() {
  if (props.disabled || !can(actions.EDIT_ALLOCATED_TIME_ON_PROJECTS)) {
    return
  }

  showAllocationTimeDialog.value = true
}

function onOpenTimeEntries() {
  if (props.disabled || !can(actions.EDIT_TIME_ENTRIES)) {
    return
  }

  showActualTimeDialog.value = true
}

async function addTimeAllocation(allocatedMinutes: Record<string, any>) {
  timeAllocation.value = allocatedMinutes
  showAllocationTimeDialog.value = false
}

function setDefaultData(task: Record<string, any>) {
  const taskTimeAllocation = get(task, 'relationships.timeAllocation', false)

  if (!taskTimeAllocation) {
    timeAllocation.value = {
      attributes: {
        allocated_minutes: 0,
        task_id: task.id,
        project_id: task?.attributes?.project_id,
      }
    }

    return
  }

  timeAllocation.value = {
    id: taskTimeAllocation.id,
    ...taskTimeAllocation,
  }
}

watch(() => props.task, (value: Record<string, any>) => {
  if (!value?.id) {
    return
  }
  setDefaultData(value)
  store.dispatch('tasks/getTaskTimeEntries', value.id)
}, { immediate: true })
</script>
