<template>
  <BaseEntityForm
    width-class="max-w-3xl"
    @submit="onSubmit"
  >
    <template #title>
      <BaseFormHeader
        :title="$t('Export Data')"
      >
        <template #subtitle>
          <p
            class="text-sm mt-2"
            :class="{
              'text-gray-500': !$useNewLayout
            }"
          >
            {{ $t('Export your data to CSV file using the form below.') }}
            <BaseTutorialLink
              name="export"
            />
          </p>
        </template>
      </BaseFormHeader>
    </template>
    <template #default="{ meta, errors }">
      <BaseInput
        :modelValue="model.entity_type"
        :name="$t('Data Type')"
        :label="$t('Data Type')"
        layout="horizontal"
        rules="required"
        id="entity_type"
        class="mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <BaseSelect
            v-model="model.entity_type"
            :options="entityTypeOptions"
            :return-object="false"
            :placeholder="$t('Choose data type...')"
            @update:modelValue="onEntityTypeChanged"
          />
        </div>
      </BaseInput>

      <BaseInput
        v-if="model.entity_type"
        :modelValue="model.type"
        :name="$t('Options')"
        :label="$t('Options')"
        layout="horizontal"
        rules="required"
        id="options"
        class="mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <BaseSelect
            v-model="model.type"
            :options="exportTypeOptions"
            :return-object="false"
            :placeholder="$t('Choose options...')"
          />
        </div>
      </BaseInput>


      <BaseInput
        v-if="isExportWithRange"
        :modelValue="model.date_range"
        :name="$t('Date Range')"
        :label="$t('Date Range')"
        layout="horizontal"
        rules="required"
        id="date_range"
        class="mt-1 sm:mt-0 md:col-span-2"
      >
        <div class="sm:mt-0 sm:col-span-4">
          <BaseDatePicker
            v-model="model.date_range"
            type="daterange"
            :placeholder="$t('Choose date range...')"
          />
        </div>
      </BaseInput>
    </template>
    <template #actions=" { meta, errors }">
      <BaseButton
        variant="white"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        :disabled="!meta.valid"
        type="submit"
        class="ml-2"
      >
        {{ $t('Export Data') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
  <BaseEntityForm
    :title="$t('Exported Files')"
    :subtitle="$t('After exporting your data files will show below. Click on a link to download the file.')"
    width-class="max-w-3xl mt-6"
    slim
  >
    <template
      v-if="$useNewLayout"
      #title
    >
      <div class="max-w-3xl mx-auto rounded-lg">
        <h3 class="text-2xl font-bold text-gray-900">
          {{ $t('Exported Files') }}
        </h3>
        <p class="text-sm text-gray-500 mt-2">
          {{ $t('After exporting your data files will show below. Click on a link to download the file.') }}
        </p>
      </div>
    </template>
    <template #default>
      <NoExports
        v-if="!exportEntries?.length && !loading"
      />
      <div
        v-else
        class="divide-y divide-gray-200"
      >
        <div
          v-loading="loading"
          class="divide-y divide-gray-200 sm:space-y-5 p-6 min-h-[200px]"
        >
          <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start">
              <label for="data-type" class="block text-sm font-medium text-gray-700">
                {{ $t('Exports') }}
                <BaseTooltip
                  :content="$t('Reload exports')"
                >
                  <i
                    class="fa fa-rotate ml-1 cursor-pointer"
                    @click="getExportEntries"
                  />
                </BaseTooltip>
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-3 space-y-2 text-sm font-medium text-gray-500">
                <div
                  v-for="exportEntry in exportEntries"
                  :key="exportEntry.id"
                  class="block hover:bg-gray-50 rounded-md p-0.5"
                  :class="{
                    'cursor-pointer hover:underline': exportEntry.attributes.status === exportStatuses.Successful,
                    'cursor-not-allowed': exportEntry.attributes.status !== exportStatuses.Successful,
                  }"
                  @click="downloadExport(exportEntry)"
                >
                  <BaseTooltip
                    :content="capitalize(exportEntry.attributes.status)"
                  >
                    <div class="inline-flex justify-between items-center w-full">
                      <div>
                          <i
                            class="fa-solid fa-file-csv mr-2"
                            :class="{
                              'text-orange-500': exportEntry.attributes.status === exportStatuses.Pending,
                              'text-green-500': exportEntry.attributes.status === exportStatuses.Successful,
                              'text-red-500': exportEntry.attributes.status === exportStatuses.Failed,
                            }"
                          />
                        <span>{{ exportEntry.attributes.file_name }}</span>
                      </div>
                      <div class="font-light no-underline">
                        {{ formatExportDateTime(exportEntry) }}
                      </div>
                    </div>
                  </BaseTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseEntityForm>
</template>
<script setup>
// Libs
import { ref, computed, onBeforeMount, reactive } from 'vue'
import i18n from "@/i18n.js";

// Components
import NoExports from "@/modules/accounts/components/NoExports.vue";

// Utils
import {
  exportTypes,
  exportEntityTypes,
  exportStatuses,
  entityTypeOptions
} from "@/modules/accounts/utils/exportUtils.js";
import { formatDate } from "@/modules/common/utils/dateUtils";
import { error, success } from "@/components/common/NotificationPlugin/index.js"
import { capitalize } from 'lodash-es'

// Composables
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const model = reactive({
  entity_type: exportEntityTypes.Projects,
  type: exportTypes.All,
  date_range: null
})

const exportTypeOptions = computed(() => {
  const selectedEntityType = entityTypeOptions.find(option => option.value === model.entity_type);

  const options = [
    {
      label: `${i18n.t('All')} ${selectedEntityType?.label}`,
      value: exportTypes.All,
    },
    {
      label: i18n.t('Created within a date range'),
      value: exportTypes.CreatedRange,
    },
    {
      label: i18n.t('Completed within a date range'),
      value: exportTypes.CompletedRange,
      renderIf: () => {
        return [exportEntityTypes.Tasks, exportEntityTypes.Projects].includes(model.entity_type)
      },
    },
  ]

  return options.filter(option => !option.renderIf || option.renderIf())
})

const isExportWithRange = computed(() => {
  return [
    exportTypes.CreatedRange,
    exportTypes.CompletedRange
  ].includes(model.type)
})

function onEntityTypeChanged() {
  model.type = exportTypes.All
  model.date_range = null
}

async function onSubmit() {
  try {
    const data = {
      entity_type: model.entity_type,
      type: model.type,
    }

    if (isExportWithRange.value) {
      data.start_date = model.date_range.start instanceof Date
        ? model.date_range.start.toISOString().substr(0, 10)
        : model.date_range.start
      data.end_date = model.date_range.end instanceof Date
        ? model.date_range.end.toISOString().substr(0, 10)
        : model.date_range.end
    }

    await store.dispatch('accounts/triggerExport', { data })
    success(i18n.t('Preparing your data. We will send you an email when your data is ready to download.'))
  }
  catch(err) {
    if (err.handled) {
      return
    }

    error(i18n.t('An error occurred while exporting data.'))
  }
}

function downloadExport(exportEntry) {
  if (exportEntry.attributes.status !== exportStatuses.Successful) {
    return
  }

  window.open(exportEntry.attributes.file_path, '_blank')
}

function onCancel() {
  router.go(-1)
}

const loading = ref(false)

const exportEntries = computed(() => {
  return store.state.accounts.exportEntries || []
})

async function getExportEntries() {
  loading.value = true
  await store.dispatch('accounts/getExportEntries')
  loading.value = false
}

onBeforeMount(() => {
  getExportEntries()
})

function formatExportDateTime(exportEntry) {
  return formatDate(
    exportEntry.attributes.created_at,
    null,
    /* lowerCaseMeridians */ true,
    /* withTime */ true
  )
}
</script>
