<template>
  <bubble-menu
    :editor="editor"
    :tippy-options="{ duration: 100 }"
    :should-show="showBubbleMenu"
    class="bg-white shadow rounded px-1 py-0.5 flex flex-col bubble-menu min-w-[240px] border border-gray-200"
  >
    <div v-if="showLinkInput || isLinkActive" class="h-10">
      <BaseInput
        ref="linkInput"
        v-model="extra.link"
        :placeholder="$t('Insert link here...')"
        class="link-input"
        @keydown.enter.stop="onLinkEnter"
      />
    </div>
    <div class="flex justify-center">
      <div
        v-for="command in menuCommands"
        :key="command.name"
        :title="command.name"
        class="flex items-center hover:bg-gray-100 rounded-md"
      >
        <template v-if="command.name === Commands.Convert.name">
          <ConvertMenuSelect :editor="editor">
            <template #prefix="{nodeName}">
              <span class="text-gray-700">
                {{ nodeName }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </template>
          </ConvertMenuSelect>
        </template>
          <HighlightColorSelect
            v-else-if="command.name === Commands.Highlight.name"
            :editor="editor"
          />
        <template v-else>
          <button
            class="w-9 h-9 rounded-md"
            :class="{ 'bg-gray-200': editor.isActive(command.name) }"
            @click="runCommand(command)"
          >
            <i :class="command.icon" class="text-gray-700"></i>
          </button>
        </template>
      </div>
    </div>
  </bubble-menu>
</template>
<script>
import { BubbleMenu } from '@tiptap/vue-3'
import suggestion from "@/components/html/suggestion";
import CommandItem from "@/components/html/extensions/components/CommandItem.vue";
import { Commands } from "@/components/html/util/tipTapUtils.js";
import BaseSelect from "@/components/form/BaseSelect.vue";
import HighlightColorSelect from '@/components/html/HighlightColorSelect.vue'
import isCustomNodeSelected from "@/components/html/util/isCustomNodeSelected";
import isTextSelected from "@/components/html/util/isTextSelected";
import ConvertMenuSelect from "@/components/html/ConvertMenuSelect.vue";

export default {
  name: 'EditorBubbleMenu',
  components: {
    ConvertMenuSelect,
    HighlightColorSelect,
    BaseSelect,
    BubbleMenu,
    CommandItem,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Commands,
      showLinkInput: false,
      extra: {
        link: '',
      },
    }
  },
  computed: {
    menuCommands() {
      const options = [
        this.Commands.Bold,
        this.Commands.Italic,
        this.Commands.Strike,
        this.Commands.Highlight,
        this.Commands.Link,
      ]
      const isTableOrImage = ['Image', 'Table'].includes(this.currentNodeName)

      if (isTableOrImage) {
        return options
      }
      options.unshift(this.Commands.Convert)

      return options
    },
    isLinkActive() {
      return this.editor.isActive(this.Commands.Link.name)
    },
    hasSelection() {
      const { selection } = this.editor?.state
      return selection?.to !== selection?.from
    },
    getConvertOptions() {
      return suggestion
        .items({ query: '' })
        .filter(item => {
          const isConvertEnabled = !item.disableConvert
          if (item.title === 'Embed') {
            return this.isLinkActive
          }
          if (item.enabled !== undefined) {
            return isConvertEnabled && item.enabled(this.editor)
          }
          return isConvertEnabled
        })
        .map(item => {
          return {
            ...item,
            label: item.title,
          }
        })
    },
    currentNodeName() {
      let nodeType = 'Convert'
      if (!this.editor) {
        return nodeType
      }
      const options = this.getConvertOptions
      options.forEach(option => {
        if (option.isActive(this.editor)) {
          nodeType = option.title
        }
      })
      return nodeType
    },
  },
  methods: {
    showBubbleMenu({ state, from, to, view }) {
      if (this.readonly || !view) {
        return false
      }
      const domAtPos = view.domAtPos(from || 0).node
      const nodeDOM = view.nodeDOM(from || 0)
      const node = nodeDOM || domAtPos

      if (isCustomNodeSelected(this.editor, node)) {
        return false
      }

      return isTextSelected({ editor: this.editor })
    },
    prepareEditor() {
      return this.editor.chain().focus()
    },
    focusSelection() {
      this.prepareEditor().run()
    },
    onConvertMenuChange() {
      this.focusSelection()
    },
    onConvertCommand(option) {
      this.focusSelection()

      const range = this.editor?.state?.selection?.ranges[0]
      let src = null
      if (this.isLinkActive) {
        src = this.editor.getAttributes('link')?.href
      }
      const command = option?.command

      if (!command) {
        return
      }

      command({
        editor: this.editor,
        range,
        src
      })
    },
    runCommand(command) {
      const params = {}
      if (command.name === this.Commands.Link.name) {
        this.runLinkCommand()
        return
      } else if (command.name === this.Commands.Convert.name) {
        this.runConvertCommand()
        return
      }
      const editor = this.prepareEditor()

      editor[command.action].call(params).run()
    },
    async runLinkCommand() {
      this.showLinkInput = !this.showLinkInput
      if (this.showLinkInput) {
        await this.$nextTick()
        this.$refs?.linkInput?.focus()
      } else {
        const editor = this.prepareEditor()
        editor.unsetLink().run()
        this.extra.link = ''
      }
    },
    async runConvertCommand() {
      this.prepareEditor()
    },
    async onLinkEnter() {
      this.showLinkInput = false
      let href = this.extra.link
      const editor = this.prepareEditor()

      if (!href.startsWith('http')) {
        this.extra.link = `https://${href}`
        href = this.extra.link
      }

      if (href) {
        editor.setLink({ href }).run()
      } else {
        editor.unsetLink().run()
      }
    },
    setInputLink() {
      if (this.isLinkActive) {
        const link = this.editor.getAttributes('link')?.href
        this.extra.link = link
        this.showLinkInput = true
      } else if (!this.isLinkActive && this.extra.link) {
        this.extra.link = ''
        this.showLinkInput = false
      } else {
        this.showLinkInput = false
      }
    },
    initEvents() {
      this.editor.on('selectionUpdate', () => {
        this.setInputLink()
      })
    }
  },
  mounted() {
    this.initEvents()
  },
}
</script>
<style lang="scss">
.bubble-menu {
  .link-input .form-input {
    @apply p-1 sm:p-2
  }
}

.convert-select {
  @apply h-9;
  .el-input__wrapper,
  .el-input.is-focus .el-input__wrapper {
    box-shadow: none !important;
    border: none !important;
    @apply hover:bg-gray-100 h-9;

    .el-input__inner {
      @apply invisible;
    }
  }
  .el-input__wrapper input {
    @apply w-0;
  }
}

.convert-select-dropdown {
  z-index: 9999 !important;

  &.el-select-dropdown .el-select-dropdown__list {
    .el-select-dropdown__item {
      @apply my-2 px-0 hover:bg-primary-500 hover:text-white focus:bg-primary-500;
    }
    .el-select-dropdown__item .result-item:hover,
    .el-select-dropdown__item .result-item.is-selected,
    .el-select-dropdown__item.hover {
      @apply bg-primary-500 text-white;
    }

    .el-select-dropdown__item .result-item:hover,
    .el-select-dropdown__item .result-item.is-selected {
      * {
        @apply text-white;
      }
    }
  }
}
</style>
