<template>
  <div class="align-middle overflow-hidden">
    <GroupForm
      class="mx-auto"
      :group="group"
      @cancel="$router.go(-1)"
      @save="$router.go(-1)"
    />
  </div>
  <GroupUsers
    v-if="!$useNewLayout"
    :group="group"
  />
</template>
<script>
import GroupForm from "@/modules/groups/components/GroupForm.vue";
import GroupUsers from "@/modules/groups/components/GroupUsers.vue";

export default {
  components: {
    GroupForm,
    GroupUsers,
  },
  computed: {
    group() {
      return this.$store.state.groups.currentGroup || {}
    },
  },
}
</script>
