<template>
  <div>
    <BaseSelect
      ref="select"
      v-model="model"
      :options="statuses"
      :loading="statusesLoading"
      :placeholder="placeholder"
      :return-object="false"
      :persistent="false"
      :inline="$attrs.inline"
      :disabled="$attrs.disabled"
      :filterable="filterable"
      valueKey="attributes.value"
      clearable
      labelKey="attributes.name"
      popper-class="task-status-select"
      class="task-status-select"
      :class="$attrs.class"
    >
      <template #prefix="{ selected }">
        <slot name="prefix">
          <div class="flex items-center gap-1.5">
            <i class="fa"
               :class="selected?.attributes?.icon"
               :style="{ color: selected?.attributes?.color }"
            />
            <span class="capitalize">{{ selected?.attributes?.name }}</span>
          </div>
        </slot>
      </template>
      <template #default="{option}">
        <div class="flex items-center gap-1.5">
          <i class="fa"
             :class="option?.attributes?.icon"
             :style="{ color: option?.attributes?.color }"
          />
          <span class="capitalize">{{ option?.attributes?.name }}</span>
        </div>
      </template>
    </BaseSelect>
  </div>
</template>
<script>
import i18n from "@/i18n.js";
import TaskStatusesDialog from "@/modules/accounts/components/TaskStatusesDialog.vue";
import EditStatusOption from "@/components/common/EditStatusOption.vue";

export default {
  components: {
    EditStatusOption,
    TaskStatusesDialog,
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: i18n.t('Filter proofs by status...'),
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    statuses() {
      return this.$store.getters['proofs/orderedStatuses'] || []
    },
    defaultStatus() {
      return this.statuses[0]
    },
    statusesLoading() {
      return this.$store.state.proofs.statuses?.loading || false
    },
  },
  methods: {
    async getAllStatuses() {
      this.$store.dispatch('proofs/getAllStatuses')
    },
  },
  watch: {
    defaultStatus: {
      immediate: true,
      handler(value) {
        if (value && value.id && !this.model) {
          this.model = value.value
        }
      }
    }
  }
}
</script>
