<template>
  <div class="mt-2 sm:grid sm:gap-4 sm:items-start sm:grid-cols-4">
    <div class="text-gray-700 flex flex-col">
      <span class="text-sm font-medium leading-5">
        {{ $t('Google Calendar') }}
      </span>
      <div
        v-if="isConnected"
        class="text-xs text-gray-500"
      >
        <template v-if="savedCalendar">
          <i class="fa-solid fa-circle text-green-500 mr-1" />
          {{ $t('Connected') }}
        </template>
        <template v-else>
          <i class="fa-solid fa-circle text-orange-500 mr-1" />
          {{ $t('No calendar selected') }}
        </template>
      </div>
      <div
        v-else
        class="text-xs text-gray-500"
      >
        <i class="fa-solid fa-circle text-red-500 mr-1" />
        {{ $t('Not connected') }}
      </div>
    </div>
    <span
      class="col-span-2 underline cursor-pointer text-primary-500 text-sm"
      @click="showDialog = true"
    >
      {{ $t('Manage your Google Calendar integration') }}
    </span>
    <BaseDialogNew
      v-model="showDialog"
      class="max-w-2xl"
    >
      <BaseEntityForm
        v-loading="loadingCalendars"
        :title="$t(`Google Calendar`)"
        :subtitle="$t(`Sync tasks to your Google Calendar. Tasks that are assigned to you and have a date will be synced. Sync is 1-way so any changes will be synced with Google Calendar but changes made in Google Calendar won't sync back.`)"
        :isDialogForm="true"
        width-class="max-w-2xl"
      >
        <template #default="{ meta, errors }">
          <BaseInput
            v-if="isConnected"
            :label="$t('Calendar to sync tasks to')"
            class="col-span-6 md:col-span-3"
          >
            <BaseSelect
              v-model="selectedCalendar"
              :placeholder="$t('Calendar to sync tasks to')"
              :options="calendarsOptions"
              :returnObject="false"
              filterable
              id="timezone"
            />
          </BaseInput>
          <div
            v-if="!isConnected && redirectUrl"
            class="flex justify-center flex-col"
          >
            <p class="text-gray-500">
              {{
                $t('You are not connected to your Google account. Click the button below to connect.')
              }}
            </p>
          </div>
        </template>
        <template #actions="{ meta, errors }">
          <template v-if="isConnected">
            <BaseButton
              :loading="revokingAccess"
              variant="white"
              hover-variant="danger"
              class="mr-2 whitespace-nowrap"
              @click="revokeAccess"
            >
              {{ $t('Revoke access') }}
            </BaseButton>
            <BaseButton
              :loading="saving"
              class="ml-2"
              @click="onSave"
            >
              {{ $t('Save & Close') }}
            </BaseButton>
          </template>
          <BaseButton
            v-if="!isConnected && redirectUrl"
            class="mb-2 shadow-none"
            @click="redirectToGoogleCalendar"
          >
            {{ $t('Connect') }}
          </BaseButton>
        </template>
      </BaseEntityForm>
    </BaseDialogNew>
  </div>
</template>
<script lang="ts" setup>
// Utils
import i18n from "@/i18n";
import { computed, onBeforeMount, ref } from "vue";
import { success } from "@/components/common/NotificationPlugin";
import { Calendar, CalendarList } from "@/modules/common/commonTypes.d";

// Composables
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router'
const store = useStore();

const [ route, router ] = [useRoute(), useRouter()]

const saving = ref(false)
const revokingAccess = ref(false)
const loadingCalendars = ref(false)
const calendars = ref<Calendar[]>([]);
const isConnected = ref(false);
const redirectUrl = ref<string | null>(null);
const selectedCalendar = ref<string | null>(null);
const savedCalendar = ref<string | null>(null);
const showDialog = ref(false);

const calendarsOptions = computed(() => {
  return calendars.value.map((calendar) => {
    return {
      label: calendar.calendar_summary,
      value: calendar.calendar_id,
    };
  });
})

async function loadCalendars() {
  loadingCalendars.value = true;

  try {
    const response: {
      data: CalendarList
    } = await store.dispatch('accounts/getGoogleCalendars')

    calendars.value = response.data.calendars;
    isConnected.value = response.data.isEnabled;
    redirectUrl.value = response.data?.url || null;
    selectedCalendar.value = response.data?.selectedCalendar;
    savedCalendar.value = response.data?.selectedCalendar;
  }
  finally {
    loadingCalendars.value = false;
  }
}

function redirectToGoogleCalendar() {
  if (!redirectUrl.value) {
    return;
  }

  window.location.href = redirectUrl.value;
}

function handleGoogleCalendar() {
  if (!route.query['google-api']) {
    return;
  }

  showDialog.value = true;

  const queryParams = { ...route.query };
  delete queryParams['google-api'];
  router.replace({ query: queryParams });
}

async function onSave() {
  saving.value = true
  try {
    await store.dispatch('accounts/saveGoogleCalendar', {
      calendarId: selectedCalendar.value,
    })

    success(i18n.t(`The calendar was added successfully.`));
    savedCalendar.value = selectedCalendar.value;
    
    showDialog.value = false;
  }
  finally {
    saving.value = false;
  }
}

async function revokeAccess() {
  revokingAccess.value = true
  try {
    await store.dispatch('accounts/removeGoogleCalendar');
    await loadCalendars();
    success(i18n.t(`Google Calendar access has been revoked`));
  }
  finally {
    revokingAccess.value = false;
  }
}

onBeforeMount(() => {
  loadCalendars()
  handleGoogleCalendar()
})
</script>
