import store from '@/store'
import { getSetting } from "@/plugins/settingsPlugin";
import { nextTick, ref } from 'vue'

const defaultLogo = '/img/logo-dark-full.png'
const transparentPng = '/img/transparent.png'
export const logoDarkFull = ref(transparentPng)

function getDefaultBgStyle() {
  const useNewLayout = store.state.dashboard.useNewLayout
  return useNewLayout
    ? `#301151`
    : `url('/img/background-image.jpg')`
}

export async function setDefaultBrandingStyles() {
  await nextTick()
  logoDarkFull.value = defaultLogo
  document.documentElement.style.setProperty('--body-background', getDefaultBgStyle());
}

export const customBrandingKeys = [
  'enable_branding',
  'header_logo',
  'favicon',
  'login_page_logo',
  'email_logo',
  'background_color',
  'breadcrumb_color',
  'background_image',
  'action_button_color',
  'action_button_hover_color',
  'shadow',
  'primary_color_tools',
  'account_name'
]

export function applyCustomBackground(background, isUrl) {
  const bodyStyles = document.body.style;

  if (!background) {
    bodyStyles.removeProperty('--body-background')
    return
  }

  if (isUrl) {
    background = `url(${background})`
  }

  bodyStyles.setProperty('--body-background', background);
}

export function applyCustomBreadCrumbColor(color) {
  const bodyStyles = document.body.style;

  if (!color) {
    bodyStyles.removeProperty('--breadcrumb-color')
    return
  }
  bodyStyles.setProperty('--breadcrumb-color', color)
}

export function applyCustomPrimaryColor(action_button_color) {
  if (!action_button_color) {
    return
  }

  const bodyStyles = document.body.style;

  bodyStyles.setProperty('--primary-50', `${action_button_color}1A`)
  bodyStyles.setProperty('--primary-100', `${action_button_color}33`)
  bodyStyles.setProperty('--primary-200', `${action_button_color}66`)
  bodyStyles.setProperty('--primary-300', `${action_button_color}99`)
  bodyStyles.setProperty('--primary-400', `${action_button_color}CC`)
  bodyStyles.setProperty('--primary-500', `${action_button_color}FF`)
  bodyStyles.setProperty('--primary-600', `${action_button_color}FF`)
  bodyStyles.setProperty('--primary-700', `${action_button_color}FF`)
  bodyStyles.setProperty('--primary-800', `${action_button_color}FF`)
  bodyStyles.setProperty('--primary-900', `${action_button_color}FF`)
}

export function toggleShadow(displayShadow) {
  const styleId = 'intercom-css'
  const disableShadowCss = document.getElementById(styleId)

  if (displayShadow) {
    disableShadowCss?.remove()
    return;
  }

  const css = `
    .shadow-xs,
    .shadow-sm,
    .shadow,
    .shadow-md,
    .shadow-lg,
    .shadow-xl,
    .shadow-2xl,
    .shadow-inner,
    .shadow-outline {
      box-shadow: none;
    }
  `;

  applyCssString(css, styleId)
}


export function applyPrimaryColorTools(applyStyling) {
  const styleId = 'tool-cards-css'
  const toolCardsCss = document.getElementById(styleId)

  if (!applyStyling) {
    toolCardsCss?.remove()
    return;
  }

  const css = `
  .empty-section .tool-icon, .tool-card .tool-icon {
    color: var(--primary-500) !important;
  }

  .tool-card:hover .tool-chevron {
    color: var(--primary-500) !important;
  }
  `;

  applyCssString(css, styleId)
}

export function clearBranding() {
  // Revert background
  const bodyStyles = document.body.style;
  bodyStyles.removeProperty('--body-background')

  // Revert breadcrumb color
  bodyStyles.removeProperty('--breadcrumb-color')

  // Revert primary colors
  bodyStyles.removeProperty('--primary-50')
  bodyStyles.removeProperty('--primary-100')
  bodyStyles.removeProperty('--primary-200')
  bodyStyles.removeProperty('--primary-300')
  bodyStyles.removeProperty('--primary-400')
  bodyStyles.removeProperty('--primary-500')
  bodyStyles.removeProperty('--primary-600')
  bodyStyles.removeProperty('--primary-700')
  bodyStyles.removeProperty('--primary-800')
  bodyStyles.removeProperty('--primary-900')

  // Revert shadow if disabled
  toggleShadow(true)

  // Revert primary color tools
  applyPrimaryColorTools(false)
}

function applyCssString(cssString, styleId) {
  // Already applied
  if (document.getElementById(styleId)) {
    return
  }

  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');

  style.setAttribute('id', styleId)
  style.type = 'text/css';

  if (style.styleSheet){
    // Required for IE8 and below
    style.styleSheet.cssText = cssString;
  } else {
    style.appendChild(document.createTextNode(cssString));
  }

  head.appendChild(style);
}

export function updateFavicons(value) {
  const iconLinks = document.head.querySelectorAll('link[rel=icon], link[rel="alternate icon"]') || []
  for (const el of iconLinks) {
    el.setAttribute('href', value)
  }
}

export function tryApplyCustomBranding(tenantSettings) {
  if (!tenantSettings) {
    return
  }

  const background_color = tenantSettings.find(x => x.key === 'background_color')
  const background_image = tenantSettings.find(x => x.key === 'background_image')
  const primary_color = tenantSettings.find(x => x.key === 'action_button_color')
  const display_shadows = tenantSettings.find(x => x.key === 'shadow')
  const favicon = tenantSettings.find(x => x.key === 'favicon')

  if (background_image?.value) {
    applyCustomBackground(background_image.value, true)
  }
  else if (background_color?.value) {
    applyCustomBackground(background_color.value)
  }

  if (primary_color) {
    applyCustomPrimaryColor(primary_color.value)
  }

  if (display_shadows) {
    toggleShadow(display_shadows.value)
  }

  if (favicon) {
    updateFavicons(favicon.value)
  }
}

export function getHexWithoutAlpha(hex) {
  return hex.slice(0, 7)
}

export function getHeaderLogo() {
  const isCustomBrandingEnabled = store.getters['accounts/isCustomBrandingEnabled']
  const tempBranding = store.state.accounts.tempBranding
  const useNewLayout = store.state.dashboard.useNewLayout

  const defaultLogo = useNewLayout
    ? '/img/logo-light-full.png'
    : '/img/logo.png'

  if (tempBranding) {
    return tempBranding.enable_branding
      ? (tempBranding.header_logo || defaultLogo)
      : defaultLogo
  }

  if (!isCustomBrandingEnabled) {
    return defaultLogo
  }

  return getSetting('header_logo') || defaultLogo
}

export function getCssVariable(variableName) {
  const style = getComputedStyle(document.body)
  return style.getPropertyValue(variableName)
}
