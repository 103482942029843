import { createRouter, createWebHistory } from 'vue-router'
import authRoutes from '@/modules/auth/routes.js'
import dashboardRoutes from '@/modules/dashboard/routes.js'

const routes = [
  ...authRoutes,
  ...dashboardRoutes,
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.path === from.path) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { left: 0, top: 0 };
  },
})

router.afterEach((to, from) => {
  if (to.path === from.path) {
    return
  }

  if (window.gtag) {
    window.gtag('set', 'page_path', to.fullPath);
    window.gtag('event', 'page_view');
    window.posthog?.capture('$pageview')
  }

  document.querySelector('.router-view-wrapper')?.scrollTo(0, 0)
})

export default router
