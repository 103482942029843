<template>
  <BaseForm
    @submit="onNext"
  >
    <template #default="{ handleSubmit, meta, errors }">
      <div class="space-y-4">
        <h2 class="text-gray-900 text-3xl sm:text-4xl font-extrabold">
          {{ i18n.t(`Invite people`) }}
        </h2>
        <p class="text-gray-500 leading-7 text-lg">
          {{ i18n.t(`Invite people from your team to collaborate with you. Project.co is a collaborative tool that helps you organize your work so it works best when used with others.`)}}
        </p>
      </div>
      <div>
        <div class="relative mt-2 flex items-center">
          <BaseInput
            v-model="emailAddress1"
            @input="onInput(0, emailAddress1)"
            type="text"
            name="email-address-1"
            id="email-address-1"
            rules="email"
            class="w-full text-gray-500 placeholder:text-gray-400 leading-6 shadow-none text-base"
            inputClass="p-4"
            :placeholder="$t('Email address...')"
          />
        </div>
        <div class="relative mt-2 flex items-center">
          <BaseInput
            v-model="emailAddress2"
            @input="onInput(1, emailAddress2)"
            type="text"
            name="email-address-2"
            id="email-address-2"
            rules="email"
            class="w-full text-gray-500 placeholder:text-gray-400 leading-6 shadow-none text-base"
            inputClass="p-4"
            :placeholder="$t('Email address...')"
          />
        </div>
        <div class="relative mt-2 flex items-center">
          <BaseInput
            v-model="emailAddress3"
            @input="onInput(2, emailAddress3)"
            type="text"
            name="email-address-3"
            id="email-address-3"
            rules="email"
            class="w-full text-gray-500 placeholder:text-gray-400 leading-6 shadow-none text-base"
            inputClass="p-4"
            :placeholder="$t('Email address...')"
          />
        </div>
      </div>
      <div>
        <p
          class="text-gray-400 leading-7 text-sm mt-4"
        >
          <i class="fa-solid fa-info-circle mr-1 text-[#5720b460]" aria-hidden="true" />
          {{ $t(`You can invite an unlimited number of people on the free plan.`) }}
        </p>
        <div class="flex items-center space-x-4 mt-4">
          <router-link
            to="/onboarding/create-tasks"
            class="rounded-md bg-white px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-gray-500 border border-gray-200"
          >
            <i class="fa-solid fa-arrow-left mr-2" aria-hidden="true" />
            {{ $t(`Back`)  }}
          </router-link>
          <button
            type="submit"
            class="rounded-md body-background px-6 py-4 text-sm font-semibold text-white"
            :class="{
              'opacity-80 cursor-not-allowed': !meta.valid || !emails.length,
              'transition-all hover:px-8': meta.valid && emails.length
            }"
            :disabled="!meta.valid || !emails.length"
          >
            {{ i18n.t('Next') }}
            <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
          </button>
          <div
            class="text-[#301151] text-xs underline ml-4 opacity-60 hover:opacity-100 cursor-pointer"
            @click="onSkip"
          >
            {{ $t(`Or, skip this step`) }}
          </div>
        </div>
      </div>
    </template>
  </BaseForm>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import i18n from '@/i18n'
import { validateEmail } from '@/plugins/veeValidate';

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const onboardingModel = computed(() => {
  return store.state.accounts.onboardingModel
})

const inviteUsers = computed(() => {
  return onboardingModel.value.inviteUsers || []
})

const emailAddress1 = ref(inviteUsers.value?.[0] || '');
const emailAddress2 = ref(inviteUsers.value?.[1] || '');
const emailAddress3 = ref(inviteUsers.value?.[2] || '');

const emails = computed(() => {
  return [
    emailAddress1.value,
    emailAddress2.value,
    emailAddress3.value
  ].filter(email => email)
})

const validEmails = computed(() => {
  return emails.value.filter(email => validateEmail(email) === true)
})

watch(() => validEmails.value, (validEmails) => {
  store.commit('accounts/setOnboardingModel', {
    ...onboardingModel.value,
    inviteUsers: validEmails
  })
})

function onNext() {
  router.push('/onboarding/setting-up-account')
}

function onInput(index: number, email: string) { 
  const inviteUsers = onboardingModel.value.inviteUsers || []

  inviteUsers[index] = email

  store.commit('accounts/setOnboardingModel', {
    ...onboardingModel.value,
    inviteUsers
  })

}

function onSkip() {
  emailAddress1.value = ''
  emailAddress2.value = ''
  emailAddress3.value = ''

  onNext()
}
</script>
