<template>
  <BaseNoDataSection
    :slim="slim"
    :title="$t('Start the conversation')"
    iconClass="fa-comments-alt"
    :canCreate="true"
  >
    <template #can-create-section>
      <div>{{ $t('Chat & share files with your project team in real-time.') }}</div>
      <div>{{ message }}</div>
    </template>
  </BaseNoDataSection>
</template>
<script>
export default {
  props: {
    entityType: {
      type: String,
      default: 'project'
    }
  },
  computed: {
    message() {
      if (this.entityType === 'project') {
        return this.$t('Simply type a comment below to get started.')
      }
      
      return this.$t('Simply type a comment above to get started.')
    },
    slim() {
      return this.entityType === 'task'
    }
  }
}
</script>
