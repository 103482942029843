<template>
  <ProjectInlineDetails
    :currentProject="project"
    :loading="projectLoading"
  />
</template>
<script setup>
  import ProjectInlineDetails from '@/modules/projects/components/ProjectInlineDetails.vue'
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'

  const store = useStore()
  const route = useRoute()

  const project = computed(() => {
    return store.state.projects?.currentProject || {}
  })

  const projectLoading = computed(() => {
    const projectId = route.params.id
    if (projectId && !project.value?.id) {
      return true
    }
    return store.state.projects.currentProjectLoading
  })
  </script>
