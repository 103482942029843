<template>
  <div class="flex flex-col h-full">
    <EmbedDialog
      v-show="showEmbedCreateDialog"
      v-model="showEmbedCreateDialog"
      key="embed-create"
      @close="redirectToList"
      @save="onResourceCreated"
    />
    <ProjectLinkDialog
      v-model="showLinkCreateDialog"
      v-show="showLinkCreateDialog"
      :defaultPinned="false"
      key="link-edit"
      @close="showLinkCreateDialog = false"
      @save="onResourceCreated"
    />
    <ProjectReferenceDialog
      v-model="showReferenceCreateDialog"
      v-show="showReferenceCreateDialog"
      :defaultPinned="false"
      key="reference-edit"
      @close="showReferenceCreateDialog = false"
      @save="onResourceCreated"
    />

    <TopFilters
      :columns="resourceActiveColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search resources...')"
    >
      <template
        v-if="!isCurrentProjectClosed && can($actions.CREATE_PROJECT_EMBEDS)"
        #action
      >
        <RequiresPermissionTo
          :action="$actions.CREATE_PROJECT_EMBEDS"
          class="relative lg:inline-block text-left sm:w-full mt-2 lg:mt-0 lg:w-min"
        >
            <BaseDropdown
              :options="createOptions"
              block
              trigger="hover"
            >
              <BaseButton class="block">
                {{ $t('+ New Resource') }}
              </BaseButton>
            </BaseDropdown>
        </RequiresPermissionTo>
      </template>
    </TopFilters>

    <DataSyncingIndicator
      v-if="resources.length && $store.state.resources.resourcesLoading"
      dataView
    />

    <slot>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
    </slot>
  </div>
</template>
<script>
// Components
import EmbedDialog from "@/modules/resources/components/EmbedDialog.vue";
import ProjectLinkDialog from "@/modules/projects/components/ProjectLinkDialog.vue";
import ProjectReferenceDialog from "@/modules/projects/components/ProjectReferenceDialog.vue";

import TopFilters from "@/modules/filters/components/TopFilters.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"

import useListFetch from "@/modules/common/composables/useListFetch.js";


export default {
  components: {
    EmbedDialog,
    ProjectLinkDialog,
    ProjectReferenceDialog,
    BaseButton,
    TopFilters,

    DataSyncingIndicator
  },
  setup() {
    const { fetchData: getResources } = useListFetch({
      action: 'resources/getResources',
      immediate: true
    })

    return {
      getResources
    }
  },
  data() {
    return {
      showEmbedCreateDialog: false,
      showLinkCreateDialog: false,
      showReferenceCreateDialog: false
    }
  },
  computed: {
    createOptions() {
      return [
        {
          label: this.$t('Embed URL'),
          action: () => { this.showEmbedCreateDialog = true }
        },
        {
          label: this.$t('Link'),
          action: () => { this.showLinkCreateDialog = true }
        },
        {
          label: this.$t('Reference'),
          action: () => { this.showReferenceCreateDialog = true }
        }
      ]
    },
    currentEmbed() {
      return this.$store.state.resources.currentEmbed
    },
    resourceActiveColumns() {
      return this.$store.getters['resources/activeColumns'] || []
    },
    resources() {
      return this.$store.state.resources.resources?.data || []
    },
    availableFilters() {
      return ['filter', 'sort', 'group', 'columns', 'other']
    },
    addEmbedTrigger() {
      return this.$store.state.resources.addEmbedTrigger
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    closeDialogs() {
      this.showEmbedCreateDialog = false
      this.showLinkCreateDialog = false
      this.showReferenceCreateDialog = false
    },
    onResourceCreated() {
      this.closeDialogs()
      this.getResources()
      this.reloadProject()
    },
    onEmbedEdit() {
      this.redirectToList()
      this.getResources()
      this.reloadProject()
    },
    redirectToList() {
      this.showEmbedCreateDialog = false
      this.showEditDialog = false
      let query = {
        ...this.$route.query
      }
      delete query.embedId
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
    async reloadProject() {
      if (!this.$store.getters.project_id) {
        return
      }
      await this.$store.dispatch('projects/getProjectById', {
        id: this.$store.getters.project_id,
        forceFetch: true
      })
    }
  },
  watch: {
    '$route.query.embedId': {
      immediate: true,
      async handler(embedId) {
        if (!embedId) {
          return
        }
        await this.$store.dispatch('resources/getEmbedById', { id: embedId })
        this.showEditDialog = true
      }
    },
    addEmbedTrigger() {
      this.showEmbedCreateDialog = true
    },
  }
}
</script>
