import axios from 'axios'
import { systemDefaultDashboard } from "@/modules/dashboard/utils/widgetUtils"
import { getRoleId } from "@/modules/users/util/userUtils"

const state = () => ({
  useNewLayout: true,
  sidebarVisible: true,
  dashboards: []
})

const mutations = {
  setSidebarVisible(state, value) {
    state.sidebarVisible = value
  },
  setUseNewLayout(state, value) {
    state.useNewLayout = value
  },
  setDashboards(state, value) {
    state.dashboards = value
  }
}

const actions = {
  async changeLayout({ commit }, useNewLayout) {
    commit('setUseNewLayout', useNewLayout)
    await axios.put('/restify/profile', {
      new_layout: useNewLayout
    })
  },
  async getDashboards({ commit }) {
    const { data: dashboards } = await axios.get('/restify/dashboards')
    commit('setDashboards', dashboards)
  },
  async updateDashboard({ dispatch }, model) {
    let data
    if (model.id) {
      data = await axios.put(`/restify/dashboards/${model.id}`, model)
    }
    else {
      data = await axios.post('/restify/dashboards', model)
    }
    
    dispatch('getDashboards')
    return data
  },
  async deleteDashboard({ dispatch }, id) {
    await axios.delete(`/restify/dashboards/${id}`)
    dispatch('getDashboards')
  }
}

const getters = {
  activeDashboard: (state, getters, rootState, rootGetters) => {
    const currentUser = rootState.auth.user

    const matchingUser = state.dashboards.find(d => d.attributes.user_id == currentUser.id)

    if (matchingUser) {
      return matchingUser
    }

    const currentRoleId = getRoleId(rootGetters['users/currentRole'])

    const matchingRole = state.dashboards.find(d => {
      const role_ids = (d.attributes.role_ids || []).map(r_id => +r_id)

      return role_ids.includes(currentRoleId)
    })

    if (matchingRole) {
      return matchingRole
    }

    return systemDefaultDashboard
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
