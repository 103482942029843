import i18n from '@/i18n';

export const defaultPlaceholder = '- -'

export const globalNotifications = {
  daily_tasks_unsubscribe: {
    success: i18n.t('You have been successfully unsubscribed from the daily tasks email.'),
    failed: i18n.t('Unsubscribe from the daily tasks email failed!')
  }
}

export function scrollToElement(selector) {
  const element = document.querySelector(selector);
  if (!element) {
    return
  }
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
  })
}

export function scrollToBottom(selector) {
  const element = document.querySelector(selector);
  if (!element) {
    return
  }
  element.scrollTo({
    top: element.scrollHeight,
    behavior: 'smooth',
  })
}

export function isExternalLink(link) {
  if (!link) {
    return false
  }
  return link?.startsWith('http') || link?.startsWith('www')
}
