<template>
  <div class="flex flex-col h-full">
    <CreateProofDialog
      v-show="showCreateDialog && !$route.query.proofId"
      v-model="showCreateDialog"
      key="proof-create"
      @close="redirectToList"
      @save="onProofCreate"
    />
    <TopFilters
      :columns="proofColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search proofs...')"
    >
      <template
        v-if="can($actions.CREATE_PROJECT_FILE_PROOFS)"
        #action
      >
        <LimitedFeature
          :feature="AccountPermissions.FileProofing"
          :label="$t('New Proof')"
          @click="showCreateDialog = true"
        />
      </template>
    </TopFilters>

    <DataSyncingIndicator
      v-if="$store.state.proofs.loading.getAll"
      dataView
    />
    <slot>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
    </slot>
  </div>
</template>
<script>
import TopFilters from "@/modules/filters/components/TopFilters.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"
import CreateProofDialog from "@/modules/file-proofs/components/CreateProofDialog.vue";
import { AccountPermissions } from '@/modules/common/composables/useCan';

export default {
  components: {
    CreateProofDialog,
    BaseButton,
    TopFilters,
    DataSyncingIndicator
  },
  data() {
    return {
      showCreateDialog: false,
      AccountPermissions,
    }
  },
  computed: {
    proofQueryAction() {
      if (this.$route?.fullPath.includes('/file-proofs/list')) {
        return this.$route?.query?.edit || this.$route?.query?.['create-from-file']
      }
      return ''
    },
    currentProof() {
      return this.$store.state.proofs.currentProof || {}
    },
    proofColumns() {
      return this.$store.getters['proofs/activeColumns'] || []
    },
    proofs() {
      return this.$store.state.proofs.proofs?.data || []
    },
    availableFilters() {
      return ['filter', 'sort', 'group', 'columns', 'other']
    },
    addProofTrigger() {
      return this.$store.state.proofs.addProofTrigger
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    onProofCreate() {
      this.showCreateDialog = false
    },
    redirectToList() {
      this.showCreateDialog = false
      const query = {
        ...this.$route.query,
        edit: undefined,
      }
      delete query.proofId
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
  },
  watch: {
    addProofTrigger() {
      this.showCreateDialog = true
    },
    proofQueryAction: {
      handler(queryId) {
        if (queryId) {
          this.showCreateDialog = true
        }
      },
      immediate: true,
    },
  },
}
</script>
