<template>
  <div class="flex -space-x-1 min-w-max allocations-list">
    <router-link
      v-for="allocation in sortedAllocations"
      :to="`/users/${allocation.id}`"
      :key="allocation.id"
      class="flex items-center space-x-2 no-underline"
    >
      <BaseLogo
        :entity="allocation"
        logo-key="attributes.avatar"
        :size="isCard ? 'sm' : 'md'"
      />
      <span class="allocation-title hidden">
        {{ allocation?.attributes?.name }}
      </span>
    </router-link>
    <span v-if="allocations?.length === 0" class="text-xs no-allocations flex items-center">
      {{ $t('No assignees') }}  
    </span>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  allocations: {
    type: Array,
    default: () => [],
  },
  size: {
    type: String,
    default: 'md'
  },
  isCard: {
    type: Boolean,
    default: false
  },
})

const sortedAllocations = computed(() => {
  return [...props.allocations].sort((x, y) => x.id - y.id)
})
</script>
