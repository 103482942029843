<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-4xl font-extrabold">
      {{ $t(`Location settings`) }}
    </h2>
    <p class="text-gray-500 leading-7 text-lg">
      {{ $t(`Set your language, timezone and preferred date / calendar settings. This makes sure that everything displays in the formats that work for you.`) }}
    </p>

    <LocalisationSettings
      ref="localisationSettingsForm"
      inputLayout="vertical"
      :hasActions="false"
      lightForm
      isDialogForm
      slim
      silentSync
    />

    <div class="flex space-x-2 items-center mt-4">
      <button
        type="button"
        class="rounded-md body-background px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-white"
        @click="onSubmit"
      >
        {{ $t(`Next`) }}
        <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, ref } from "vue";

import LocalisationSettings from "@/modules/accounts/pages/localisation-settings.vue";

import { useRouter } from "vue-router";

const router = useRouter()

const localisationSettingsForm = ref()

async function onSubmit() {
  localisationSettingsForm.value?.onSubmit()

  await nextTick()
  router.push(`/account-setup-wizard/project-statuses`)
}
</script>
