<template>
  <BaseDialog
    v-model="visible"
    :hasBodyMargin="false"
    :appendToBody="true"
    size="lg"
    @close="emit('close')"
  >

    <BaseEntityForm
      :title="$t('Add To')"
      :subtitle="$t('Save this as a project description, task description or note description.')"
      :isDialogForm="true"
      @submit="onSubmit"
    >
      <template #default="{ meta, errors }">
        <div class="mt-2 space-y-6 sm:space-y-5">
          <BaseInput
            :modelValue="model.project_id"
            :name="$t('Project')"
            :label="$t('Project')"
            layout="horizontal"
            rules="required"
            id="project"
            class="mt-1 sm:mt-0 md:col-span-2"
          >
            <div class="sm:mt-0 sm:col-span-4">
              <ProjectSelect
                v-model="model.project_id"
                :url-params="{
                  related: 'tools,notes',
                }"
                :initialValue="currentProject"
                :placeholder="$t('Choose project...')"
                @raw-change="onProjectChange"
              />
            </div>
          </BaseInput>

          <BaseInput
            v-if="model.project_id"
            :modelValue="model.type"
            :name="$t('Save As')"
            :label="$t('Save As')"
            layout="horizontal"
            rules="required"
            id="tool"
            class="mt-1 sm:mt-0 md:col-span-2"
          >
            <div class="sm:mt-0 sm:col-span-4">
              <BaseSelect
                v-model="model.type"
                :disabled="!model.project_id"
                :options="typeOptions"
                :placeholder="$t('Choose where to save...')"
                :return-object="false"
                @update:modelValue="onTypeChange"
              />
            </div>
          </BaseInput>

          <BaseInput
            v-if="mustSelectEntity"
            :modelValue="model.item_id"
            :name="entitySelectProps.name"
            :label="entitySelectProps.label"
            layout="horizontal"
            rules="required"
            id="entity_select_id"
            class="mt-1 sm:mt-0 md:col-span-2"
          >
            <div class="sm:mt-0 sm:col-span-4">
              <EntitySelect
                v-model="model.item_id"
                :disabled="!model.project_id || !selectedType"
                :url="entitySelectProps?.url"
                :url-params="{
                  project_id: model.project_id,
                }"
                :labelKey="entitySelectProps?.labelKey || undefined"
                :placeholder="entitySelectProps.placeholder"
              />
            </div>
          </BaseInput>
          <BaseInput
            v-if="mustSetEntityName"
            v-model="model.newEntityName"
            :name="$t('Name')"
            :label="$t('Name')"
            :placeholder="entityNameProps?.placeholder"
            layout="horizontal"
          />
        </div>
        <div
          contenteditable="false"
          translate="no"
          tabindex="0"
          class="prose max-w-none leading-6 prose-p:my-4 prose-ul:my-4 prose-ol:my-4 prose-li:my-0 prose-p:mt-2 prose-p:mb-0 prose-ul:mt-2 prose-ul:mb-0 prose-ol:mt-2 prose-ol:mb-0 focus:outline-none text-sm rounded-md text-gray-500 bg-gray-50 border border-gray-200 px-2 py-1"
        >
          <p v-html="messageHtml" />
        </div>
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          variant="white"
          @click="emit('cancel')"
        >
          {{ $t(`Cancel`) }}
        </BaseButton>
        <BaseButton
          :disabled="!meta.valid"
          :loading="loading"
          class="ml-2"
          type="submit"
        >
          {{ $t(`Save`) }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialog>
</template>
<script lang="ts" setup>
import i18n from "@/i18n";
import { computed, reactive, ref } from "vue";
import { taskDateTypes, visibilityTypes } from '@/modules/tasks/utils/modelUtils'
import { success, error } from "@/components/common/NotificationPlugin";
import { getSetting } from "@/plugins/settingsPlugin";
import { ActionResult, ChatMessage, ChatMessageTypes } from "@/modules/ai/types/aiTypes";
import { Activities, trackPostHog } from "@/modules/common/utils/trackingUtils";

// Components
import EntitySelect from "@/components/form/EntitySelect.vue";

// Composables
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'
import useCan from '@/modules/common/composables/useCan'

//
enum AddToTypes {
  ProjectDescription = 'project_description',
  ExistingTaskDescription = 'existing_task_description',
  NewTaskDescription = 'new_task_description',
  NewNoteDescription = 'new_note_description',
}

const props = defineProps({
  messageHtml: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['cancel', 'close', 'update:modelValue'])

const store = useStore()
const route = useRoute()

const { can, actions } = useCan();

const visible = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  }
})

const selectedType = computed(() => {
  return typeOptions.value.find((option) => {
    return option.value === model.type
  })
})

const currentProject = computed(() => {
  return store.state.projects.currentProject
})

const model = reactive({
  project_id: store.getters.project_id || null,
  type: AddToTypes.ProjectDescription,
  item_id: undefined as number | undefined,
  newEntityName: ''
})

const loading = ref(false)

function addChatMessage(message: ChatMessage) {
  store.commit('ai/addCreateModeMessage', message)
}

async function onSubmit() {
  console.log('Saving...', {
    model,
    description: props.messageHtml,
  })

  let actionResult: ActionResult | null = null

  try {
    loading.value = true

    switch(model.type) {
      case AddToTypes.ProjectDescription: {
        const updatedProject = await store.dispatch('projects/overwriteProjectDescription', {
          id: model.project_id,
          description: props.messageHtml,
        })

        success(i18n.t('Project description saved successfully.'))

        actionResult = {
          action: 'update',
          entity: 'project',
          name: updatedProject?.attributes?.name,
          id: model.project_id,
          url: `/projects/${model.project_id}`
        }

        break
      }
      case AddToTypes.ExistingTaskDescription: {
        const updatedTask = await store.dispatch('tasks/overwriteTaskDescription', {
          id: model.item_id,
          description: props.messageHtml,
        })

        success(i18n.t('Task description saved successfully.'))

        actionResult = {
          action: 'update',
          entity: 'task',
          name: updatedTask?.attributes?.name,
          id: model.item_id,
          url: `/tasks/${model.item_id}`
        }
        break
      }
      case AddToTypes.NewTaskDescription: {
        let visibility = can(actions.CHANGE_TASK_VISIBILITY)
          ? visibilityTypes.CREATORS_ONLY
          : visibilityTypes.CREATORS_AND_COLLABORATORS

        const defaultVisibility = getSetting('default_task_privacy')
        if (can(actions.CHANGE_TASK_VISIBILITY) && defaultVisibility) {
          visibility = defaultVisibility
        }

        const newTask = await store.dispatch('tasks/createTask', {
          data: {
            notes: props.messageHtml,
            name: model.newEntityName,
            project_id: model.project_id,
            visibility,
            date_type: taskDateTypes.NO_DATE,
            status_id: getSetting('default_task_status'),
          }
        })

        actionResult = {
          action: 'create',
          entity: 'task',
          name: model.newEntityName,
          url: `/tasks/${newTask.id}`
        }
        break
      }
      case AddToTypes.NewNoteDescription: {
        const notes = `<h1>${model.newEntityName}</h1>${props.messageHtml}`
        const newNote = await store.dispatch('projects/createNewProjectNote', {
          project_id: model.project_id,
          name: model.newEntityName,
          notes,
        })

        success(i18n.t('Note created successfully.'))

        actionResult = {
          action: 'create',
          entity: 'note',
          name: model.newEntityName,
          url: `/projects/${model.project_id}/notes/${newNote.id}`
        }
        break
      }
      default:
        throw new Error('Unknown type', model.type)
    }

    if (actionResult) {
      addChatMessage({
        type: ChatMessageTypes.AssistantLocal,
        data: {
          actionResult,
        },
        date: new Date(),
      })
    }

    const activities = {
      [AddToTypes.ProjectDescription]: Activities.AiCreateAddToProject,
      [AddToTypes.ExistingTaskDescription]: Activities.AiCreateAddToExistingTask,
      [AddToTypes.NewTaskDescription]: Activities.AiCreateAddToNewTask,
      [AddToTypes.NewNoteDescription]: Activities.AiCreateAddToNote,
    }

    trackPostHog(activities[model.type])

    emit('close')
  }
  catch (err: any) {
    console.log('Error saving', err)
    if (err.handled) {
      return
    }

    error(i18n.t('Something failed. Please try again later.'))
  }
  finally {
    loading.value = false
  }
}


function onProjectChange() {
  model.type = AddToTypes.ProjectDescription
  model.item_id = undefined
  model.newEntityName = ''
}

function onTypeChange() {
  model.item_id = undefined
  model.newEntityName = ''
}


const mustSelectEntity = computed(() => {
  return [
    AddToTypes.ExistingTaskDescription,
  ].includes(model.type)
})

const mustSetEntityName = computed(() => {
  return [
    AddToTypes.NewTaskDescription,
    AddToTypes.NewNoteDescription,
  ].includes(model.type)
})

const entitySelectProps = computed(() => {
  if (model.type === AddToTypes.ExistingTaskDescription) {
    return {
      label: i18n.t('Task'),
      name: i18n.t('Task'),
      placeholder: i18n.t('Choose task...'),
      url: `/restify/tasks`,
      labelKey: 'attributes.name',
    }
  }

  return {
    label: i18n.t('Note'),
    name: i18n.t('Note'),
    placeholder: i18n.t('Choose project note...'),
    url: `/restify/project-notes`,
  }
})


const entityNameProps = computed(() => {
  if (model.type === AddToTypes.NewTaskDescription) {
    return {
      placeholder: i18n.t('Enter task name...'),
    }
  }

  return {
    placeholder: i18n.t('Enter note name...'),
  }
})


const typeOptions = computed(() => {
  const options = [
    {
      label: i18n.t('Project Description'),
      value: AddToTypes.ProjectDescription,
    },
    {
      label: i18n.t('Existing Task Description'),
      value: AddToTypes.ExistingTaskDescription,
    },
    {
      label: i18n.t('New Task Description'),
      value: AddToTypes.NewTaskDescription,
    },
    {
      label: i18n.t('New Note'),
      value: AddToTypes.NewNoteDescription,
    },
  ]

  return options
})
</script>
