import { Node } from '@tiptap/core'

import { DOMParser } from 'prosemirror-model'

export default Node.create({
  name: 'html',
  inline: false,
  attrs: {},
  group: 'block',
  draggable: true,
  parseDOM: [],

  // @ts-ignore TODO add module types here
  addCommands() {
    return {
      insertHTML: (value: string) => ({ tr, state, dispatch }: any) => {
        const html = value
        insertHtml({ html, tr, state, dispatch })
      }
    }
  }
})

export function insertHtml({ html, tr, state, dispatch, from }: any) {
  const { selection } = state
  const element = document.createElement('div')
  element.innerHTML = html
  const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
  const fromPosition = from || selection.anchor
  const transaction = tr.insert(fromPosition, slice.content)
  dispatch(transaction)
  return transaction
}
