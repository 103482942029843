<template>
   <BaseEntityForm
    :isDialogForm="true"
    width-class="max-w-2xl"
    @submit="onSubmit"
  >
    <template #title>
      <BaseFormHeader
        :isDialogForm="true"
      >
        <template #title>
          <h3 class="text-2xl font-bold">
            {{ link?.id ? $t('Edit') : $t("Create") }}
            <span
              v-if="linkName"
              class="capitalize"
            >
              {{ linkName }}
            </span>
            {{ $t('Link')}}
          </h3>
        </template>
        <template
          v-if="!embed?.id"
          #subtitle
        >
          <p
            class="text-sm mt-2"
            :class="{
              'text-gray-500': !$useNewLayout,
            }"
          >
            {{ linkDescription || $t('Link to another web page, URL or file.') }}
          </p>
        </template>
      </BaseFormHeader>
    </template>
    <template #default="{ meta, errors }">
      <BaseInput
        :modelValue="model.image"
        :name="$t('Image')"
        :label="$t('Image')"
        id="image"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      >
        <BaseAvatarPicker
          v-model="model.image"
          size="md"
          :disabled-options="['group', 'unsplash']"
          :placeholder="$t('+ Add an image (optional)')"
        />
      </BaseInput>

      <BaseInput
        v-model="model.name"
        :label="$t('Name')"
        :placeholder="$t('Enter Link name...')"
        :errorMessage="errors[$t('Name')]"
        :name="$t('Name')"
        layout="horizontal"
        rules="required"
        id="name"
      />

      <BaseInput
        v-model="model.link"
        :name="$t('URL')"
        :label="$t('URL')"
        :placeholder="$t('Enter Link URL...')"
        rules="required|urlWithProtocol"
        id="link"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      >
        <template
          v-if="selectedType?.tutorial_link"
          #after-input
        >
          <div class="text-gray-400 text-xs basis-full">
            {{ selectedType?.tutorial_link }}
            <BaseTutorialLink
              name="project-tools"
            >
              {{ $t('More info') }}
            </BaseTutorialLink>.
          </div>
        </template>
      </BaseInput>

      <BaseInput
        v-model="model.description"
        :name="$t('Description')"
        :label="$t('Description')"
        :placeholder="$t('Enter Description (optional)...')"
        rules="max:55"
        id="description"
        class="w-full mt-1 sm:mt-0 md:col-span-2"
        layout="horizontal"
      />

      <BaseRoundedCheckbox
        v-if="!defaultPinned"
        v-model="model.pinned"
        :name="$t('Pinned')"
        :label="$t('Pinned')"
        size="sm"
        inputClass="sm:col-span-3"
      />
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$emit('cancel')"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        :disabled="!meta.valid"
        :loading="loading"
        type="submit"
        class="ml-2"
      >
        {{ $t('Save') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script setup>
import { cloneDeep } from 'lodash-es'
import { ref, computed, watch } from 'vue'
import i18n from "@/i18n.js";
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { success, error } from '@/components/common/NotificationPlugin';
import { projectLinkTypes } from "@/modules/resources/utils/projectLinkUtils"
import { projectLinkTools } from "@/modules/projects/utils/toolUtils"

const [store, router, route] = [useStore(), useRouter(), useRoute()]

const props = defineProps({
  link: {
    type: Object,
    default: () => null
  },
  defaultLinkType: {
    type: String,
    default: ''
  },
  defaultPinned: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['save', 'cancel'])

const loading = ref(false)

const model = ref({
  project_id: route.params.id,
  type: props.defaultLinkType,
  name: '',
  link: '',
  image: '',
  description: '',
  pinned: props.defaultPinned
})

const selectedType = computed(() => {
  return projectLinkTypes.find(t => t.value === model.value.type)
})

const linkType = computed(() => {
  return props.link?.attributes?.type || props.defaultLinkType
})

const linkName = computed(() => {
  return projectLinkTools.find(t => t.value === linkType.value)?.name || ''
})

const linkDescription = computed(() => {
  return projectLinkTools.find(t => t.value === linkType.value)?.description || ''
})

watch(() => props.link, (value) => {
  if (!value?.id) {
    return
  }
  
  const project_id = value.attributes.project_id || route.params.id

  model.value = {
    ...value.attributes,
    project_id,
    pinned: !!Number(value.attributes.pinned || 0)
  }
  
}, { immediate: true })


watch(() => props.defaultLinkType, (value) => {
  if (!value) {
    return
  }

  const image = projectLinkTypes.find(t => t.value === value)?.image || ''

  model.value.type = value
  model.value.image = image
}, { immediate: true })

async function onSubmit(emitSave = true) {
  try {
    loading.value = true;
    const data = cloneDeep(model.value);

    if (!data.type) {
      data.type = 'link'
    }

    let link;
    if (props.link?.id) {
      link = await editLink(data)
    }
    else {
      link = await createLink(data)
    }

    const message = props.link?.id
      ? i18n.t("Link updated successfully")
      : i18n.t("Link created successfully");

    success(message)

    if (emitSave) {
      emit("save", link);
    } else {
      router.go(-1);
    }
  } catch (err) {
    if (err.handled) {
      return false;
    }

    const message = props.link?.id
      ? i18n.t("Could not update link")
      : i18n.t("Could not create link");

    error(message);
    return false;
  } finally {
    loading.value = false;
  }
}

async function createLink(data) {
  const result = await store.dispatch("projects/createProjectLink", { data });
  return result.data;
}

async function editLink(data) {
  const result = await store.dispatch("projects/editProjectLink", {
    linkId: props.link?.id,
    data,
  });

  return result.data;
}
</script>
