<template>
  <div class="max-w-full mx-auto rounded-lg bg-white border-gray-200 border shadow">
    <h2 class="sr-only" id="profile-overview-title">{{ $t('Profile Overview') }}</h2>
    <UserPreviewCard :user="user">
      <div class="relative lg:inline-block text-left m-auto pt-2">
        <BaseDropdown
          :options="userOptions"
          :label="$t('Options')"
          trigger="hover"
          class="m-auto"
          button-size="md"
          divide-items
        />
      </div>
    </UserPreviewCard>
  </div>

  <div class="block mt-6">
    <div class="m-auto max-w-3xl rounded-lg border border-gray-200 shadow overflow-hidden sm:overflow-x-scroll">
      <BaseTable
        :data="userProjects"
        :loading="projectsLoading"
        :loading-row-count="5"
        :columns="projectColumns"
        class="shadow"
      >
        <template #[`attributes.image`]="{row}">
      <span class="inline-block relative">
        <BaseLogo :entity="row"/>
      </span>
        </template>
        <template #[`attributes.name`]="{row}">
          <router-link :to="`/projects/${row.id}`">
          <span class="font-bold sm:font-normal text-lg sm:text-sm text-gray-900 sm:text-gray-500">
            {{ row.attributes?.name }}
          </span>
          </router-link>
        </template>
      </BaseTable>
      <nav
        v-if="projectsMeta?.total > totalProjectsToShow"
        class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg"
        aria-label="Pagination"
      >
        <div class="flex-1 flex justify-center">
          <router-link
            :to="{
              path: '/projects/list',
              query: userProjectsFilter
            }"
            class="relative inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">

            {{ $t('Show all') }} {{ `${user?.attributes?.name}'s` }} {{ $t('projects') }}
          </router-link>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { isUserArchived } from "@/modules/users/util/userUtils";
import { userProjectsFilter, userTasksFilter, userTimeFilter } from "@/modules/users/util/filters.js";
import i18n from "@/i18n.js";
import UserPreviewCard from "@/modules/users/components/UserPreviewCard.vue";

export default {
  components: {
    UserPreviewCard,
  },
  data() {
    return {
      totalProjectsToShow: 5,
    }
  },
  computed: {
    user() {
      return this.$store.state.users.currentUser || {}
    },
    userOptions() {
      return [
        {
          label: this.$t('Edit Person'),
          enabled: () => this.isCurrentUserRoleGreaterOrEqual,
          path: `/users/${this.user?.id}/edit`
        },
        {
          label: this.$t('View Projects'),
          path: {
            path: '/projects/list',
            query: {
              filters: this.userProjectsFilter,
            }
          }
        },
        {
          label: this.$t('View Tasks'),
          path: {
            path: '/tasks/list',
            query: {
              filters: this.userTasksFilter
            }
          }
        },
        {
          label: this.$t('View Time'),
          path: {
            path: '/time/actual',
            query: {
              filters: this.userTimeFilter
            }
          },
          requiresPermissionTo: this.$actions.VIEW_TIME_ENTRIES
        },
        {
          label: this.$t('Archive User'),
          variant: 'danger',
          enabled: () => {
            const isNotCurrentUser = this.$user?.id?.toString() !== this.user?.id?.toString()
            return isNotCurrentUser && !isUserArchived(this.user) && this.isCurrentUserRoleGreaterOrEqual
          },
          action: this.archiveUser
        },
        {
          label: this.$t('Restore User'),
          variant: 'danger',
          enabled: () => {
            return this.isCurrentUserRoleGreaterOrEqual && isUserArchived(this.user)
          },
          action: this.restoreUser
        },
      ]
    },
    userProjectsFilter() {
      return userProjectsFilter(this.user);
    },
    userTasksFilter() {
      return userTasksFilter(this.user);
    },
    userTimeFilter() {
      return userTimeFilter(this.user);
    },
    isCurrentUserRoleGreaterOrEqual() {
      const userToEditRole = this.get(this.user, 'attributes.role[0]')
      return this.$store.getters['users/isCurrentUserRoleGreaterOrEqual'](userToEditRole)
    },
    userProjects() {
      return this.$store.state.projects.projects.data || []
    },
    projectsMeta() {
      return this.$store.state.projects.projects.meta || {}
    },
    projectsLoading() {
      return this.$store.state.projects.projectsLoading
    },
    projectColumns() {
      return [
        {
          name: i18n.t('Img'),
          prop: 'attributes.image',
          class: 'w-24',
        },
        {
          name: i18n.t('Project Name'),
          prop: 'attributes.name',
        },
        {
          name: i18n.t('Status'),
          prop: 'relationships.status.attributes.name',
          component: 'Status',
        }
      ]
    }
  },
  methods: {
    async archiveUser() {
      try {
        const user = this.user
        const userName = user?.attributes?.name
        const isUserBillable = this.isRoleGreaterOrEqual(user?.attributes?.role[0], this.$roles.CREATOR)
        const confirmed = await this.$deleteConfirm({
          title: this.$t(`Archive ${userName}`),
          description: `
          <span>If you archive ${userName}</span>
          <ul>
           <li>All their data such as comments, tasks and time will remain in the account.</li>
           <li>You won’t be able to associate any new data with this user.</li>
           <li>They won’t be able to login or access the account.</li>
           ${isUserBillable ? '<li>They won’t count as a paid user any longer for billing purposes.</li>' : ''}
          </ul>
          <span>Are you sure you want to archive ${userName}?</span>`,
          buttonText: this.$t('Archive')
        })

        if (!confirmed) {
          return
        }
        await this.$store.dispatch('users/deleteUser', user.id)
        this.$success(this.$t('Person archived successfully. You can still recover the person from the Archived tab.'))

        this.$router.push('/users/list')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not archive the person.'))
      }
    },
    async restoreUser() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t("Restore User"),
        description: this.$t("Are you sure? This action will restore the user and give them access to the system"),
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch("users/restoreUser", this.user.id);
        this.$router.push('/users/list')
      } catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(this.$t("Cannot restore the user"));
        throw err;
      }
    },
    async getUserProjects() {
      await this.$store.dispatch('projects/getProjects', {
        filters: this.userProjectsFilter,
        perPage: this.totalProjectsToShow,
      })
    }
  },
  async created() {
    await this.getUserProjects()
  },
  watch: {
    userProjectsFilter() {
      this.getUserProjects()
    }
  }
}
</script>
