<template>
  <div class="table-inline-edit-input w-full">
    <div class="flex items-center relative group base-inline-date-picker w-full">
      <i
        v-if="displayIcon"
        class="far fa-calendar-alt mr-0.5"
      />
      <XCircleIcon
        v-if="clearable && model && !$attrs.disabled"
        class="circle-remove hidden h-4 w-4 cursor-pointer text-red-300 hover:text-red-500 absolute -left-3 bg-white rounded-full z-10"
        aria-hidden="true"
        @click.stop="model = null"
      />
      <BaseDatePicker
        v-model="model"
        v-focus
        :type="type"
        :disabled="$attrs.disabled"
        :placeholder="placeholder"
        class="inline inline-date-picker disabled-focus"
        @change="saveValue"
      />
    </div>
  </div>
</template>
<script lang="ts">
// Components
// @ts-ignore
import { XCircleIcon } from '@heroicons/vue/outline'

// Utils
import { PropType, defineComponent } from "vue";
import { ICellEditorParams } from "@ag-grid-community/core";
import { stopCellEditing } from "@/components/table/tableUtils";

export default defineComponent({
  components: {
    XCircleIcon
  },
  props: {
    params: {
      type: Object as PropType<ICellEditorParams<any>>,
      default: () => ({})
    },
  },
  data() {
    return {
      model: this.params.value
    }
  },
  computed: {
    cellEditorParams() {
      return this.params?.colDef?.cellEditorParams || {}
    },
    clearable() {
      return this.cellEditorParams.clearable || false
    },
    displayIcon() {
      return this.cellEditorParams.displayIcon || false
    },
    type() {
      return this.cellEditorParams.type || 'date'
    },
    placeholder() {
      return this.cellEditorParams?.placeholder || '- -'
    },
  },
  methods: {
    getValue() {
      return this.model
    },
    saveValue() {
      stopCellEditing(this.params)
    }
  }
})
</script>
