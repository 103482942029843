<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-4xl relative"
    @close="$emit('close')"
  >
    <div class="bg-white rounded-lg h-full">
      <BaseFormHeader
        :title="$tc('invite people to project', { projectName: currentProject?.attributes?.name })"
        :isDialogForm="true"
      />
      <ProjectPeopleForm
        in-modal
        :projectId="projectId"
        @close="$emit('close')"
      />
    </div>
  </BaseDialogNew>
</template>
<script>
import ProjectPeopleForm from "@/modules/projects/components/ProjectPeopleForm.vue"

export default {
  name: 'ProjectPeopleDialog',
  components: {
    ProjectPeopleForm
  },
  props: {
    projectId: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject
    }
  }
}
</script>
