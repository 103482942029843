import store from "@/store";
import { aiStreamedMessage } from '@/modules/ai/utils/aiUtils';
import { StepperStep } from "@/modules/common/commonTypes";

export enum OnboardingSteps {
  NameYourProject = 'name-your-project',
  AddProjectTools = 'add-project-tools',
  CreateTasks = 'create-tasks',
  InvitePeople = 'invite-people',
  SettingUpAccount = 'setting-up-account',
  Congratulations = 'congratulations', 
}

export const projectCoverImage = `https://images.unsplash.com/photo-1637825891028-564f672aa42c?q=80&auto=format&w=1200&h=150&fit=crop&crop=edges`
export const projectImage = `https://images.unsplash.com/photo-1637825891028-564f672aa42c?q=80&amp;auto=format&amp;w=160&amp;h=160&amp;fit=crop&amp;crop=edges`
export const lottieAnimationLink = `https://lottie.host/f0be69e7-d025-45f1-a30e-88845a728c02/J1zvVy1zPM.json`

export type WebappTransform = {
  x: number,
  y: number,
  scale: number
}

export interface ProjectOnboardingStep extends StepperStep {
  name: OnboardingSteps,
  webappTransform: WebappTransform,
}

export const projectOnboardingSteps: ProjectOnboardingStep[] = [
  {
    key: OnboardingSteps.NameYourProject,
    name: OnboardingSteps.NameYourProject,
    webappTransform: {
      x: 20,
      y: 27,
      scale: 1
    }
  },
  {
    key: OnboardingSteps.AddProjectTools,
    name: OnboardingSteps.AddProjectTools,
    webappTransform: {
      x: 20,
      y: 10,
      scale: 1
    }
  },
  {
    key: OnboardingSteps.CreateTasks,
    name: OnboardingSteps.CreateTasks,
    webappTransform: {
      x: 5,
      y: 20,
      scale: 1
    }
  },
  // Removed temporarily
  // {
  //   key: OnboardingSteps.InvitePeople,
  //   name: OnboardingSteps.InvitePeople,
  //   webappTransform: {
  //     x: 20,
  //     y: 15,
  //     scale: 1
  //   }
  // },
  {
    key: OnboardingSteps.SettingUpAccount,
    name: OnboardingSteps.SettingUpAccount,
    webappTransform: {
      x: 20,
      y: 15,
      scale: 1
    },
    hidden: true
  },
  {
    key: OnboardingSteps.Congratulations,
    name: OnboardingSteps.Congratulations,
    webappTransform: {
      x: 20,
      y: 15,
      scale: 1
    }
  }
]

export async function generateProjectDescription(projectName: string) {
  if (!projectName) {
    return
  }

  let description = ''

  const body = {
    topic: projectName,
  }

  const events = {
    onNewContent: (content: string) => {
      if (!description) {
        description = content
      }
      else {
        description += content
      }

      store.commit('accounts/setOnboardingProjectDescription', description)
    }
  }

  await aiStreamedMessage(body, events, 'generate-description')
}
