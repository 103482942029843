<template>
  <li class="animate-pulse py-4 px-6 cursor-pointer">
    <div class="flex items-center space-x-4">
      <div class="w-6 h-6 bg-gray-100"></div>
      <div class="flex-1 flex flex-col truncate space-y-1">
        <div class="bg-gray-100 h-5 w-1/2 mt-1"></div>
        <div class="bg-gray-100 h-3 w-1/3"></div>
        <div class="bg-gray-100 h-3 w-1/3"></div>
        <div class="bg-gray-100 h-3 w-1/3"></div>
      </div>
    </div>
  </li>
</template>
