<template>
  <div
    class="m-auto align-middle overflow-hidden"
    :class="{
      'px-8 py-6 max-w-4xl border border-gray-200 rounded-lg bg-white shadow': !$useNewLayout,
    }"
  >
    <zapier-app-directory
      :key="$route.fullPath"
      app="projectco"
      link-target="new-tab"
      theme="light"
      :zaplimit.prop='10'
      zapstyle="row"
      create-without-template="show"
      use-this-zap="show"
    />
  </div>
</template>
<script setup />
