import axios from "axios";
import { orderBy } from 'lodash-es'
import i18n from "@/i18n.js";
import apiCache from "@/modules/common/utils/apiCache.js";
import { error } from '@/components/common/NotificationPlugin';

import { getGroupColumns, groupFields } from "@/modules/groups/utils/groupTableUtils"
import { getFormData } from "@/modules/common/utils/formDataUtils.js";
import { parseInvoiceDetails } from "@/modules/payments/utils/paymentUtils.js";
import { getCustomFieldValuesStringified } from "@/modules/accounts/utils/modelUtils";
import { transformToRestifyArray } from "@/modules/common/utils/dataUtils";
import { syncEntityInArray, EntityChangeEvent, getEntityArrayForWorker } from "@/modules/common/utils/entityUtils";
import { groupUsersMapWorker, groupProjectsMapWorker } from "@/modules/groups/utils/groupRelationshipsUtils";

import { columnBuilder } from '@/components/table/tableUtils'
import { Activities, trackActivity } from "@/modules/common/utils/trackingUtils";

const state = () => ({
  groups: {
    data: [],
  },
  allGroups: [],
  allGroupsLoaded: false,
  userGroupsBinding: [],
  groupUsersBindingMap: {
    // groupId: { user_ids: [], users: []}
  },
  groupProjectsBindingMap: {
    // groupId: { project_ids: [], projects: []}
  },
  groupsLoading: true,
  currentGroupLoading: false,
  currentGroup: null,
  addGroupTrigger: 1
})

const mutations = {
  setGroups(state, value) {
    state.groups = value
  },
  setAllGroups(state, value) {
    state.allGroups = value
    state.allGroupsLoaded = true
  },
  setAllGroupsLoaded(state, value) {
    state.allGroupsLoaded = value
  },
  setUserGroupsBinding(state, value) {
    state.userGroupsBinding = value
  },
  setGroupUsersBindingMap(state, value) {
    state.groupUsersBindingMap = value
  },
  setGroupProjectsBindingMap(state, value) {
    state.groupProjectsBindingMap = value
  },
  addGroup(state, group) {
    syncEntityInArray(group, state.allGroups, EntityChangeEvent.Create)
  },
  updateGroup(state, group) {
    syncEntityInArray(group, state.allGroups, EntityChangeEvent.Update)

    if (String(group.id) !== String(state.currentGroup?.id)) {
      return
    }

    state.currentGroup = {
      ...state.currentGroup,
      ...group,
      attributes: {
        ...state.currentGroup.attributes,
        ...group.attributes
      },
      relationships: {
        ...state.currentGroup.relationships,
        ...group.relationships
      }
    }
  },
  deleteGroup(state, id) {
    syncEntityInArray({ id }, state.allGroups, EntityChangeEvent.Delete)
  },
  setGroupsLoading(state, value) {
    state.groupsLoading = value
  },
  setCurrentGroupLoading(state, value) {
    state.currentGroupLoading = value
  },
  setCurrentGroup(state, value) {
    state.currentGroup = value
  },
  triggerAddGroup(state) {
    state.addGroupTrigger++
  },
}

const actions = {
  async computeGroupUsersBinding({ commit, state, rootState }, value) {
    const bindingArray = getEntityArrayForWorker(value || state.userGroupsBinding || [])
    commit('setUserGroupsBinding', bindingArray)

    const allUsers = getEntityArrayForWorker(rootState.users.allUsers || [])
    if (!allUsers.length || !bindingArray.length) {
      return
    }

    const bindingMap = await groupUsersMapWorker(bindingArray, allUsers)

    commit('setGroupUsersBindingMap', bindingMap)
  },
  async computeGroupProjectsBinding({ commit, state, rootState }, value) {
    const bindingArray = getEntityArrayForWorker(value || rootState.projects.projectGroupsBinding || [])
    const allProjects = getEntityArrayForWorker(rootState.projects.allProjects || [])

    if (!allProjects.length || !bindingArray.length) {
      return
    }

    const bindingMap = await groupProjectsMapWorker(bindingArray, allProjects)

    commit('setGroupProjectsBindingMap', bindingMap)
  },
  async getGroups({ commit, rootState }, filters) {
    try {
      if (filters?.shouldReset === true) {
        commit('setGroups', { data: [] })
      }
      filters = filters || {...rootState.route?.query }
      commit('setGroupsLoading', true)
      const groups = await apiCache.getRequest('/restify/groups', {
        params: {
          ...filters,
        }
      })
      commit('setGroups', groups)
    } finally {
      commit('setGroupsLoading', false)
    }
  },
  async getAllGroups({ state, commit, dispatch }) {
    if (state.allGroupsLoaded) {
      return
    }

    try {
      commit('setGroupsLoading', true)

      const entities = ['groups', 'user_groups_binding']
      const {
        data: {
          groups,
          user_groups_binding
        }
      } = await axios.get('/data', {
        params: {
          entities: `[${entities.join('|')}]`,
          groupFields: `[${groupFields.join('|')}]`,
        }
      })

      const allGroups = transformToRestifyArray(groups, { type: 'groups' });

      commit('setAllGroups', allGroups)

      dispatch('computeGroupUsersBinding', user_groups_binding)
      dispatch('users/computeUserGroupsBinding', user_groups_binding, { root: true })

      dispatch('computeGroupProjectsBinding', null)
      dispatch('projects/computeProjectGroupsBinding', null, { root: true })
    }
    finally {
      commit('setGroupsLoading', false)
    }
  },
  async getGroupById({ commit, state }, { id, returnEntity = false, silent = false }) {
    try {
      if (!silent) {
        commit('setCurrentGroupLoading', true)
      }
      const { data } = await axios.get(`/restify/groups/${id}`, {
        params: {
          related: 'users,projects'
        }
      })
      data.attributes.invoice_details = parseInvoiceDetails(data.attributes.invoice_details)

      if (returnEntity) {
        return data
      }

      commit('setCurrentGroup', data)

      return data

    } finally {
      if (!silent) {
        commit('setCurrentGroupLoading', false)
      }
    }
  },
  async createGroup({ commit }, data) {
    const formData = getFormData(data, ['name', 'image', 'invoice_details'], /* allowEmpty */ true)
    formData.append("custom_fields", getCustomFieldValuesStringified(data.custom_fields))

    const response = await axios.post(`/restify/groups`, formData)

    if (response?.data?.id) {
      commit('addGroup', response.data)
    }

    trackActivity(Activities.GroupCreated, response.data)

    return response
  },
  async editGroup({ commit, dispatch }, { groupId, data }) {
    const formData = getFormData(data, ['name', 'image', 'invoice_details'], /* allowEmpty */ true)
    formData.append("custom_fields", getCustomFieldValuesStringified(data.custom_fields))

    const result = await axios.post(`/restify/groups/${groupId}`, formData)
    commit('updateGroup', result.data)

    return result
  },
  async deleteGroup({ dispatch, commit }, groupId) {
    await axios.delete(`/restify/groups/${groupId}`)
    commit('deleteGroup', groupId)
  },
  async addUsersToGroup({ dispatch, commit }, { groupId, users, silent = false }) {
    await axios.post(`/restify/groups/${groupId}/attach/users`, {
      users,
    })
    const updatedGroup = await dispatch('getGroupById', { id: groupId, silent })

    commit('updateGroup', updatedGroup)
  },
  async removeUsersFromGroup({ dispatch, commit }, { groupId, users, silent = false }) {
    try {
      await axios.post(`/restify/groups/${groupId}/detach/users`, {
        users,
      })
      const updatedGroup = await dispatch('getGroupById', { id: groupId, silent })

      commit('updateGroup', updatedGroup)
    } catch (err) {
      if (err.handled) {
        return
      }
      error(i18n.t(`Could not remove the person from the group`))
    }
  },
  async syncGroups({ dispatch, commit }, groupIds) {
    const promises = groupIds.map(id => dispatch('getGroupById', { id, silent: true, returnEntity: true }))
    const updatedGroups = await Promise.all(promises)

    updatedGroups.forEach(group => commit('updateGroup', group))
  }
}

const getters = {
  activeColumns: () => {
    const { mainColumns, extraColumns } = getGroupColumns()

    let columns = mainColumns

    columnBuilder.addCustomFieldColumns(columns, 'group')

    columnBuilder.addCustomColumns(columns, extraColumns)

    return columns
  },
  tableColumns: (state, getters) => {
    return getters.activeColumns.filter(c => c.visibleInTable !== false)
  },
  groupsData: (state, getters, rootState) => {
    const allGroups = state.allGroups || []

    return allGroups.map(group => {
      const user_ids = state.groupUsersBindingMap[group.id]?.user_ids || []
      const users = group?.relationships?.users || state.groupUsersBindingMap[group.id]?.users || []

      const project_ids = state.groupProjectsBindingMap[group.id]?.project_ids || []
      const projects = group?.relationships?.projects || state.groupProjectsBindingMap[group.id]?.projects || []

      return {
        ...group,
        attributes: {
          ...group.attributes,
          users_count: group.attributes.users_count || users.length,
          user_ids,
          projects_count: group.attributes.projects_count || projects.length,
          project_ids,
        },
        relationships: {
          ...group.relationships,
          users,
          projects
        }
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
