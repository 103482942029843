import i18n from "@/i18n"
import store from "@/store/index.js";
import { ColumnTypes, HeaderTypes } from '@/components/table/cells/tableCellComponents'
import { humanReadableSize } from "@/modules/common/utils/fileUtils";
import { TableColumn, comparatorTypesMap, ComparatorTypes, doesFilterPassTypesMap, FilterTypes, getDefaultOptionsColumn, getEntityImageColumn } from "@/components/table/tableUtils";
import { ValueFormatterParams } from "@ag-grid-community/core";
import { customFieldAppliesToOptions } from "@/modules/accounts/utils/modelUtils";
import useCan from "@/modules/common/composables/useCan";
import { formatDate } from "@/modules/common/utils/dateUtils";

const { can, actions } = useCan()

function getCompareDate(value: any, node: any, isDescending: boolean) {
  if (node?.data) {
    return Date.parse(node.data?.attributes?.created_at || value)
  }

  return (node.allLeafChildren || []).reduce((acc: any, child: any) => {
    const childDate = Date.parse(child.data?.attributes?.created_at || value)
    if (!acc) {
      acc = childDate
      return acc
    }

    if (isDescending && childDate > acc) {
      acc = childDate
    }
    else if (!isDescending && childDate < acc) {
      acc = childDate
    }

    return acc
  }, null)
}

export function getFileColumns(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      name: i18n.t('Type'),
      prop: 'attributes.mime_type_label',
      component: ColumnTypes.FilePreview,
      params: {
        previewSize: 'md',
      },
      sortProp: 'mime_type',
      sortable: true,
      showCardLabel: false,
      enableRowGroup: true,
      filterBy: {
        prop: 'mime_type',
        component: 'FileTypeSelect',
        format(selected: any) {
          return selected.value
        },
        displayFormat(selected: any) {
          return selected.label
        },
        doesFilterPass(row: any, filterValue: any) {
          return filterValue.includes(row.attributes.mime_type)
        },
      },
      hide: true,
      keepHidden: true,
    },
    {
      name: i18n.t('File Name'),
      prop: 'attributes.name',
      component: ColumnTypes.FilePreview,
      params: {
        isNameCell: true
      },
      sortProp: 'name',
      sortable: true,
      required: true,
      minWidth: 180,
      filterBy: {
        prop: 'name',
        type: 'text',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.Text]('attributes.name'),
      },
      cardClass: 'font-bold text-gray-900 text-base leading-5',
      hide: true,
      keepHidden: true,
    },
    {
      name: i18n.t('Folder'),
      prop: 'relationships.folder.attributes.name',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value || '- -'
      },
      sortProp: 'folder',
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.RelationshipName]('relationships.folder.attributes.name'),
      showCardLabel: true,
      enableRowGroup: true,
      minWidth: 180,
      filterBy: {
        prop: 'folder_ids',
        component: 'FolderSelect',
        props: {
          multiple: true
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.folder_id'),
      },
      hide: true,
      keepHidden: true,
    },
    {
      name: i18n.t('Project'),
      prop: 'attributes.project_id',
      component: ColumnTypes.Project,
      params: {
        entity_type: i18n.t('file')
      },
      sortProp: 'project',
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.RelationshipName]('relationships.project.attributes.name'),
      showCardLabel: true,
      enableRowGroup: true,
      minWidth: 180,
      filterBy: {
        prop: 'project_ids',
        component: 'ProjectSelect',
        props: {
          multiple: true
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.project_id'),
      },
      disabled: () => {
        return !!store.getters.project_id
      },
      hide: true,
      keepHidden: true,
    },
    {
      name: i18n.t('Task'),
      prop: 'relationships.task.id',
      component: ColumnTypes.Task,
      params: {
        async getTask(row: any) {
          if (row?.attributes?.model_type !== 'task') {
            return null
          }
          
          if (row?.relationships?.task || row.attemptedLoad) {
            return row.relationships.task
          }

          if (!row?.relationships) {
            row.relationships = {}
          }

          row.attemptedLoad = true
          
          // Handle file -> *completed* task binding because completed tasks are not loaded globally
          row.relationships.task = await store.dispatch('tasks/getTaskById', {
            id: row.attributes.model_id,
            returnEntity: true,
            silentError: true,
            skipRelated: true
          })
          
          // Update file in the main array such that this request is only made once
          store.commit('files/updateFile', row)

          return row.relationships.task
        },
        hideEmpty: (row: any)  => {
          return row?.type !== 'media'
        }
      },
      showCardLabel: true,
      sortProp: 'task',
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.Text]('relationships.task.attributes.name'),
      enableRowGroup: true,
      minWidth: 180,
      filterBy: {
        prop: 'task_ids',
        component: 'TaskSelect',
        props: {
          multiple: true
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('relationships.task.id'),
      },
      cardClass: 'inline-flex'
    },
    {
      name: i18n.t('Size'),
      prop: 'attributes.size',
      showCardLabel: true,
      sortProp: 'size',
      sortable: true,
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        const a = Number(valueA || 0)
        const b = Number(valueB || 0)
    
        return a - b
      },
      required: true,
      aggFunc: 'sum',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data?.id === -1) {
          return ''
        }

        return humanReadableSize(params.value)
      },
      filterBy: {
        prop: 'size',
        type: 'range-slider',
        displayFormat(value: any) {
          return `${humanReadableSize(value?.min)} - ${humanReadableSize(value?.max)}`
        },
        props: {
          formatTooltip(value: any) {
            return humanReadableSize(value);
          },
          range: () => {
            return store.getters[`files/dataPropRange`]('attributes.size')
          }
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.NumericRange]('attributes.size'),
      },
      cardClass: 'inline-flex'
    },
    {
      name: i18n.t('Uploaded At'),
      prop: 'attributes.created_at',
      showCardLabel: true,
      sortProp: 'created_at',
      sortable: true,
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        let dateA = getCompareDate(valueA, nodeA, isDescending)
        let dateB = getCompareDate(valueB, nodeB, isDescending)
  
        if (isNaN(dateA)) {
          dateA = 0
        }
  
        if (isNaN(dateB)) {
          dateB = 0
        }
  
        if (dateA < dateB) {
          return -1
        }
  
        if (dateA > dateB) {
          return 1
        }
  
        return 0
      },
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) => {
        if (!['folders', 'media'].includes(params.data?.type)) {
          return ''
        }

        return formatDate(params.value,
          null,
          /* lowerCaseMeridians */ true,
          /* withTime */ true
        )
      },
      enableRowGroup: true,
      required: true,
      filterBy: {
        prop: 'created_at',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.DateRange]('attributes.created_at'),
      },
    },
    {
      name: i18n.t('By'),
      prop: 'attributes.created_by',
      component: ColumnTypes.UserList,
      params: {
        getUsers(row: any) {
          return row?.type === 'media' && row?.relationships?.creator
            ? [row.relationships.creator]
            : []
        },
        getPlaceholder(row: any) {
          return ''
        }
      },
      sortable: true,
      sortProp: 'created_by',
      comparator: comparatorTypesMap[ComparatorTypes.RelationshipName]('relationships.creator.attributes.name'),
      showCardLabel: true,
      minWidth: 57,
      width: 57,
      maxWidth: 57,
      required: true,
      filterBy: {
        prop: 'created_by',
        component: 'UserSelect',
        props: {
          multiple: true,
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.created_by')
      },
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn()
  }

  const addCustomFieldColumn: TableColumn = {
    headerComponent: HeaderTypes.AddCustomField,
    prop: 'add-custom-field',
    headerComponentParams: {
      defaultAppliesTo: customFieldAppliesToOptions.FILE,
      tooltip: i18n.t('Add a new custom field for all files across the account')
    },
    valueFormatter(params: ValueFormatterParams) {
      return ''
    },
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    disabled: () => {
      return !can(actions.CREATE_CUSTOM_FIELDS) || store.getters['projects/isCurrentProjectClosed']
    },
    showInChooseColumns: false
  }

  const extraColumns: TableColumn[] = [addCustomFieldColumn, optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}

export const fileFields = [
  'id',
  'name',
  'file_name',
  'created_at',
  'created_by',
  'size',
  'mime_type',
  'custom_fields',
  'project_id',
  'model_type',
  'model_id',
  'folder_id',
  'uuid'
]

export const folderFields = [
  'id',
  'name',
  'created_at',
  'created_by',
  'parent_folder_id',
  'project_id',
  'order'
]
