<template>
  <FilesTable
    :data="files"
    :loading="isLoading"
    class="flex-1"
  />
</template>
<script lang="ts" setup>
// Components
import FilesTable from "@/modules/files/components/FilesTable.vue";

// Utils
import { computed, ref, watch } from "vue";

// Composables
import { useStore } from "vuex";
const store = useStore()

const loading = computed(() => {
  return store.state.files.filesLoading ||
    store.state.projects.projectsLoading ||
    store.state.groups.tasksLoading
})

const isLoading = ref(true)

watch(() => loading.value, (value) => {
  isLoading.value = value
}, { immediate: true })

const files = computed(() => {
  if (isLoading.value) {
    return []
  }
  
  return store.getters['files/currentTreeData'] || []
})
</script>
