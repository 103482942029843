<template>
  <div>
    <BaseTooltip
      v-for="option of options"
      :key="option.label"
      :content="$t(`Some required fields (*) don't have a value`)"
      :disabled="!isOptionDisabled(option) || isSaving"
      :class="option.class"
    >
      <BaseButton
        :variant="option.variant"
        :disabled="isOptionDisabled(option)"
        :loading="isSaving && chosenAction === option.onAfterCreate"
        @click="onAction(option)"
      >
        {{ option.label }}
      </BaseButton>
    </BaseTooltip>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: [String, Number],
      default: '',
    },
    isBulkCreate: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    chosenAction: {
      type: String,
      default: null,
    },
  },
  computed: {
    options() {
      const options = [
        {
          label: this.$t('Cancel'),
          action: 'cancel',
          variant: 'white',
          class: 'mr-auto',
          alwaysEnabled: true,
        },
        {
          label: this.$t('Create & New'),
          action: 'createAndNew',
          onAfterCreate: 'new',
          variant: 'white',
        },
        {
          label: this.$t('Create & Continue'),
          action: 'createAndContinue',
          variant: 'white',
          onAfterCreate: 'continue',
          renderIf: () => !this.isBulkCreate,
        },
        {
          label: this.$t('Create & Close'),
          variant: 'primary',
          action: 'createAndClose',
          onAfterCreate: 'close',
        },
      ]

      return options.filter(option => !option.renderIf || option.renderIf())
    },
  },
  methods: {
    onAction(option) {
      if (this[option.action]) {
        this[option.action]()
        return
      }

      if (option.path) {
        this.$router.push(option.path)
        return
      }

      this.$emit(option.action, this.task)
    },
    isOptionDisabled(option) {
      if (this.isSaving) {
        return true
      }

      if (option.alwaysEnabled) {
        return false
      }

      return this.disabled
    }
  },
};
</script>
