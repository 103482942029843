<template>
  <BasePopoverInput
    :disabled="previewType === UserPreviewTypes.Disabled"
  >
    <template #reference>
      <div class="flex space-x-2 items-center">
        <BaseLogo
          :entity="user"
          :size="size"
          :class="$attrs.logoClass"
          :disableTooltip="$attrs.disableTooltip"
          :rounded="$attrs.rounded"
          logo-key="attributes.avatar"
        />
        <slot name="after" />
      </div>
    </template>
    <UserPreviewCard
      :user="user"
      :showGroups="showGroups"
      :type="previewType"
    >
      <div
        v-if="actions?.length"
        class="flex justify-between space-x-2"
      >
        <router-link
          v-if="actions.includes(UserPreviewActions.View)"
          :to="`/users/${user.id}/view`"
          class="w-full whitespace-nowrap"
        >
          <BaseButton
            variant="primary"
            size="sm"
            block
          >
            {{ $t('View Profile') }}
          </BaseButton>
        </router-link>
        <BaseButton
          v-if="actions.includes(UserPreviewActions.Remove)"
          variant="danger"
          size="sm"
          block
          @click="emit('remove')"
        >
          {{ $t('Remove') }}
        </BaseButton>
      </div>
    </UserPreviewCard>
  </BasePopoverInput>
</template>
<script lang="ts" setup>
// Components
import UserPreviewCard from "@/modules/users/components/UserPreviewCard.vue";

// Utils
import { PropType } from "vue";
import {
  UserPreviewTypes,
  UserPreviewActions
} from "@/modules/users/util/userUtils";

const props = defineProps({
  user: {
    type: Object,
    default: () => ({})
  },
  size: {
    type: String,
    default: 'sm'
  },
  previewType: {
    type: String as PropType<UserPreviewTypes>,
    default: UserPreviewTypes.Inline
  },
  actions: {
    type: Array as PropType<Array<UserPreviewActions>>,
    default: () => [
      UserPreviewActions.View,
      UserPreviewActions.Remove
    ]
  },
  showGroups: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'remove'
])
</script>
