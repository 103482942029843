<template>
  <div>
    <div
      class="space-x-3 flex items-center"
    >
      <ProjectLogoNew
        v-if="file?.relationships?.project"
        :project="file?.relationships?.project"
      />
      <div
        @click="showPreviewDialog = true"
        class="space-x-3 flex items-center"
      >
        <BaseFilePreview
          :file="file.attributes"
          :thumbnail="true"
          size="md"
          class="cursor-pointer object-cover mx-auto text-center"
        />
        <div class="line-clamp-1 text-sm text-gray-500 cursor-pointer">
          {{ displayedName }}
        </div>
      </div>
    </div>
    <FilePreviewDialog
      v-if="showPreviewDialog"
      v-model="showPreviewDialog"
      :file="file"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import ProjectLogoNew from "@/modules/projects/components/ProjectLogoNew.vue";

// Utils
import { PropType, computed, ref } from 'vue';
import { humanReadableSize } from '@/modules/common/utils/fileUtils';
import Data = API.Data
import Media = App.Domains.Media.Models.Media;

const props = defineProps({
  file: {
    type: Object as PropType<Data<Media>>,
    required: true
  }
})

const showPreviewDialog = ref(false)

const fileSize = computed(() => {
  return humanReadableSize(props.file.attributes.size || 0)
})

const displayedName = computed(() => {
  const name = props.file?.attributes?.name || '- -'
  return `${name} (${fileSize.value})`
})
</script>
