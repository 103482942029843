<template>
  <div class="relative lg:inline-block text-left mt-2 lg:mt-0">
    <BaseDropdown
      :options="dropdownOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
    >
      <TopFilterChip
        v-loading="filterPresetsLoading"
        :isActive="activeView?.title !== 'Default'"
        :prependLabel="isAnyViewActive ? $t('Settings') : ''"
        :label="activeView?.title || $t('Save View')"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ $t("Page Settings") }}
            <BaseTutorialLink
              name="page-settings"
              class="text-gray-400 hover:text-gray-500"
            >
              <i class="fa-solid fa-circle-question ml-1" />
            </BaseTutorialLink>
          </span>
        </div>
      </template>
      <template #option="{ option }">
        <template v-if="option.filterPreset">
          <FilterPresetListItem
            :filterPreset="option.filterPreset"
            @set-active="setActivePreset(option.filterPreset.id, option.filterPreset.attributes.filter)"
          />
        </template>
        <template v-else>
          <i
            v-if="option.icon"
            :class="option.icon"
          />
          <span>{{ option.label }}</span>
        </template>
      </template>
    </BaseDropdown>

    <FilterPresetsDialog
      v-model="showFilterPresetDialog"
      v-show="showFilterPresetDialog"
      ref="presetDialog"
      :loading="loading"
      :target="target"
      :view_type="view_type"
      @save="saveFilterPreset"
      @close="showFilterPresetDialog = false"
    />
  </div>
</template>
<script>
import i18n from "@/i18n"

// Components
import FilterPresetsDialog from "@/modules/filters/components/FilterPresetsDialog.vue";
import FilterPresetListItem from "@/modules/filters/components/FilterPresetListItem.vue";
import TopFilterChip from "@/modules/filters/components/TopFilterChip.vue";

// Helpers
import {
  PEOPLE_OPTIONS,
  PROJECT_OPTIONS,
  getSystemDefaultEntityFilters,
  isFilterPresetActive,
  getFilterPresetTitle,
} from "@/modules/common/utils/filterUtils"

export default {
  components: {
    TopFilterChip,
    FilterPresetsDialog,
    FilterPresetListItem
  },
  props: {
    target: {
      type: String,
      required: true
    },
    view_type: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      showFilterPresetDialog: false,
    }
  },
  computed: {
    filterPresetsLoading() {
      return this.$store.state.filters.filterPresetsLoading
    },
    project_id() {
      return this.$store.getters.project_id
    },
    sortedFilterPresets() {
      return this.$store.getters['filters/sortedDefaultTargetViewTypeFilterPresets'](this.target, this.view_type, this.project_id) || []
    },
    sortedFilterPresetOptions() {
      return this.sortedFilterPresets.map(filterPreset => ({
        filterPreset,
        action: () => this.setActivePreset(filterPreset.id, filterPreset.attributes.filter),
        variant: this.$route.query.cv === filterPreset.id && isFilterPresetActive(filterPreset.attributes.filter) ? 'active' : ''
      }))
    },
    dropdownOptions() {
      return [
        {
          label: i18n.t('Save Page Settings'),
          action: this.triggerSavePreset,
          icon: 'fas fa-plus-square mr-2 text-primary-500'
        },
        ...this.sortedFilterPresetOptions,
        {
          label: i18n.t('Default Page Settings'),
          action: () => this.setActivePreset('default', this.systemDefaultFilter),
          variant: this.isSystemDefaultFilterActive ? 'active' : ''
        }
      ]
    },
    systemDefaultFilter() {
      return getSystemDefaultEntityFilters(this.target, this.view_type)
    },
    isSystemDefaultFilterActive() {
      return (!this.$route.query.cv || this.$route.query.cv === 'default') && isFilterPresetActive(this.systemDefaultFilter)
    },
    activeView() {
      if (this.isSystemDefaultFilterActive) {
        return  {
          title: i18n.t('Default'),
          filter: this.systemDefaultFilter
        }
      }

      const activePreset = this.sortedFilterPresets.find(preset => isFilterPresetActive(preset.attributes.filter))
      
      if (!activePreset) {
        return null
      }

      return {
        title: getFilterPresetTitle(activePreset),
        filter: activePreset.attributes.filter
      }      
    },
    isAnyViewActive() {
      return !!this.activeView
    }
  },
  methods: {
    getFilterPresetModel(model) {
      const apply_to_projects = !this.project_id
        ? PROJECT_OPTIONS.NONE 
        : model.apply_to_projects
      
      const project_ids = apply_to_projects === PROJECT_OPTIONS.SPECIFIC
        ? [ this.project_id ]
        : null

      const user_ids = model.apply_to_users === PEOPLE_OPTIONS.SPECIFIC
        ? [ this.$user.id ]
        : null

      const filterValue = {
        ...this.$route.query,
      }
      delete filterValue.page
      delete filterValue.start
      delete filterValue.end

      const filter = JSON.stringify(filterValue)

      return {
        target: this.target,
        view_type: this.view_type,
        apply_to_users: model.apply_to_users,
        user_ids,
        apply_to_projects,
        project_ids,
        filter,
      }
    },
    triggerSavePreset() {
      if (!this.can(this.$actions.MANAGE_ALL_USERS_VIEWS) && !this.project_id) {
        const model = {
          apply_to_users: PEOPLE_OPTIONS.SPECIFIC,
          apply_to_projects: PROJECT_OPTIONS.SPECIFIC,
        }
        
        this.saveFilterPreset(model)
        return
      }

      this.showFilterPresetDialog = true
    },
    async saveFilterPreset(model) {
      try {
        this.loading = true

        const filterPreset = this.getFilterPresetModel(model)
        const result = await this.$store.dispatch('filters/createOrUpdateFilterPreset', filterPreset)

        this.showFilterPresetDialog = false

        const query = {
          ...this.$route.query,
          cv: result.id
        }

        this.$router.replace({ query })

        if (!result) {
          return
        }

        this.$success(i18n.t('View saved successfully!'))
        this.$emit('save', result)
      }
      catch (err) {
        this.$error(i18n.t('Could not save view'))
      }
      finally {
        this.loading = false
      }
    },
    setActivePreset(customView, filters) {
      if (typeof filters === 'string') {
        filters = JSON.parse(filters)
      }

      const query = {
        ...this.$route.query,
        ...filters,
        cv: customView
      }

      if (!filters.filters) {
        delete query.filters
      }
      if (!filters.localFilters) {
        delete query.localFilters
      }

      this.$router.replace({
        path: this.$route.path,
        query,
      })
    },
  }
}
</script>
<style lang="scss">
.custom-views-btn {
  .el-loading-mask {
    @apply flex justify-center;
    .el-loading-spinner {
      @apply w-5 h-5
    }
  }
}
</style>
