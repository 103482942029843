<template>
  <router-view
    :key="isTemplateContext"
  />
</template>
<script>
export default {
  methods: {
    async toggleTemplateContext() {
      await this.$nextTick()

      const isTemplateContext = this.$route?.path?.includes('/templates')

      this.$store.commit('setIsTemplateContext', isTemplateContext)
    }
  },
  computed: {
    isTemplateContext() {
      return this.$store.state.isTemplateContext
    }
  },
  watch: {
    $route: {
      handler() {
        this.toggleTemplateContext()
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch('projects/getAllTools')
  },
}
</script>
