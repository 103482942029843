<template>
  <div>
    <BaseTooltip
      :content="tooltip"
      :disabled="!tooltip"
    >
      <label :class="$attrs.class" class="text-sm font-medium text-gray-700 cursor-pointer sm:grid sm:items-start sm:grid-cols-4">
        <div v-if="!labelAfter" class="sm:col-span-1">
          <slot name="label">
            {{ label }}
          </slot>
        </div>
        <div
          class="relative bg-white border-2 rounded-full border-gray-300 rounded-checkbox"
          :class="[sizeClass, label && !labelAfter ? 'ml-1': '', $attrs.inputClass || 'sm:col-span-4']"
        >
          <input
            type="checkbox"
            :disabled="$attrs.disabled"
            :checked="modelValue"
            class="opacity-0 absolute"
            :class="{
            'cursor-not-allowed text-gray-300 bg-gray-200': $attrs.disabled,
            'cursor-pointer': !$attrs.disabled,
            [sizeClass]: true
          }"
            @change="$evt => $emit('update:modelValue', $evt.target.checked)"
          />
          <svg
            v-if="modelValue === true"
            class="fill-current bg-primary-500 border-2 rounded-full border-primary-500 text-white p-1 flex shrink-0 justify-center items-center -ml-0.5 -mt-0.5"
            :class="sizeClass"
            viewBox="0 0 20 20"
          >
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        </div>
        <div v-if="labelAfter"  class="sm:col-span-1">
          <slot name="label">
            {{ label }}
          </slot>
        </div>
      </label>
    </BaseTooltip>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: ''
    },
    labelAfter: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    tooltipChecked: {
      type: String,
      default: ''
    },
    tooltipUnchecked: {
      type: String,
      default: ''
    }
  },
  computed: {
    sizeClass() {
      if (this.size === 'sm') {
        return 'w-5 h-5'  
      }

      return 'w-7 h-7'
    },
    tooltip() {
      if (this.modelValue) {
        return this.tooltipChecked
      }

      return this.tooltipUnchecked
    }
  }
}
</script>
<style lang="scss">
.rounded-checkbox {
  margin-bottom: -5px !important;
}
</style>
