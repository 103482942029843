export function formModelToBody(model) {
  const body = {}
  body.name = model.name
  body.entity_type = model.entity_type  
  body.custom_field_type_id = model.custom_field_type_id
  body.options = {}
  body.allow_filter_by = model.allow_filter_by
  body.allow_group_by = model.allow_group_by
  body.allow_sort_by = model.allow_sort_by
  body.disabled = model.disabled || false

  if (model.project_id) {
    body.project_id = model.project_id
  }

  body.options = model.options.map((opt, i) => ({
    order: i + 1,
    label: opt.label,
    value: opt.value
  }))

  if (model.required) {
    body.rules = "required"
  }
  else {
    body.rules = ""
  }

  return body;
}
