<template>
  <img
    v-bind="$attrs"
    :src="logoSrc"
    :class="{
      'w-12': isDefaultLogo
    }"
  />
</template>
<script>
const defaultLogoSrc = '/img/logo.png'
export default {
  name: 'BaseAppLogo',
  props: {
    logoSrc: {
      type: String,
      default: defaultLogoSrc
    }
  },
  computed: {
    isDefaultLogo() {
      return this.logoSrc === defaultLogoSrc
    }
  }
}
</script>
