<template>
  <BaseNoDataSection
    :title="$t('No Payments Found')"
    iconClass="fa-money-bill"
    :canCreate="!isCurrentProjectClosed && can($actions.CREATE_PAYMENTS)"
  >
    <template #can-create-section>
      <div>{{ $t('Payments help you get paid as projects progress.') }}</div>
      <div>{{ $t('Clients can download invoices & pay via card or Paypal.') }}</div>
      <div
        class="hover:text-primary-500 cursor-pointer"
        @click="$store.commit('payments/triggerAddPayment')"
      >
        {{ $t('Create a new payment request') }}
        <i class="fa-solid fa-arrow-right" aria-hidden="true" />
      </div>
    </template>
  </BaseNoDataSection>
</template>
<script>
export default {
  computed: {
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
}
</script>
