import i18n from "@/i18n";

export function getFlag(country: string) {
  return `https://flagicons.lipis.dev/flags/4x3/${country}.svg`
}

export type LanguageOption = {
  label: string,
  value: string,
  flag: string,
}

export const sttLanguageOptions: LanguageOption[] = [
  {
    label: i18n.t('English'),
    value: 'en',
    flag: getFlag('gb'),
  },
  {
    label: i18n.t('English (US)'),
    value: 'en-US',
    flag: getFlag('us'),
  },
  {
    label: i18n.t('English (AU)'),
    value: 'en-AU',
    flag: getFlag('au'),
  },
  {
    label: i18n.t('English (GB)'),
    value: 'en-GB',
    flag: getFlag('gb'),
  },
  {
    label: i18n.t('English (NZ)'),
    value: 'en-NZ',
    flag: getFlag('nz'),
  },
  {
    label: i18n.t('English (IN)'),
    value: 'en-IN',
    flag: getFlag('in'),
  },
  {
    label: i18n.t('French'),
    value: 'fr',
    flag: getFlag('fr'),
  },
  {
    label: i18n.t('French (CA)'),
    value: 'fr-CA',
    flag: getFlag('ca'),
  },
  {
    label: i18n.t('German'),
    value: 'de',
    flag: getFlag('de'),
  },
  {
    label: i18n.t('Italian'),
    value: 'it',
    flag: getFlag('it'),
  },
  {
    label: i18n.t('Spanish'),
    value: 'es',
    flag: getFlag('es'),
  },
  {
    label: i18n.t('Spanish (Latin America)'),
    value: 'es-419',
    flag: getFlag('es'),
  },
  {
    label: i18n.t('Dutch'),
    value: 'nl',
    flag: getFlag('nl'),
  },
  {
    label: i18n.t('Flemish'),
    value: 'nl-BE',
    flag: getFlag('be'),
  },
  {
    label: i18n.t('Czech'),
    value: 'cs',
    flag: getFlag('cz'),
  },
  {
    label: i18n.t('Danish'),
    value: 'da',
    flag: getFlag('dk'),
  },
  {
    label: i18n.t('Danish (DK)'),
    value: 'da-DK',
    flag: getFlag('dk'),
  },
  {
    label: i18n.t('Greek'),
    value: 'el',
    flag: getFlag('gr'),
  },
  {
    label: i18n.t('Hindi'),
    value: 'hi',
    flag: getFlag('in'),
  },
  {
    label: i18n.t('Hindi (Latn)'),
    value: 'hi-Latn',
    flag: getFlag('in'),
  },
  {
    label: i18n.t('Indonesian'),
    value: 'id',
    flag: getFlag('id'),
  },
  {
    label: i18n.t('Korean'),
    value: 'ko',
    flag: getFlag('kr'),
  },
  {
    label: i18n.t('Norwegian'),
    value: 'no',
    flag: getFlag('no'),
  },
  {
    label: i18n.t('Polish'),
    value: 'pl',
    flag: getFlag('pl'),
  },
  {
    label: i18n.t('Portuguese'),
    value: 'pt',
    flag: getFlag('pt'),
  },
  {
    label: i18n.t('Portuguese (BR)'),
    value: 'pt-BR',
    flag: getFlag('br'),
  },
  {
    label: i18n.t('Russian'),
    value: 'ru',
    flag: getFlag('ru'),
  },
  {
    label: i18n.t('Swedish'),
    value: 'sv',
    flag: getFlag('se'),
  },
  {
    label: i18n.t('Turkish'),
    value: 'tr',
    flag: getFlag('tr'),
  },
  {
    label: i18n.t('Ukrainian'),
    value: 'uk',
    flag: getFlag('ua'),
  },
]
