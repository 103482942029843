<template>
  <BaseEntityForm
    width-class="max-w-3xl"
    :key="formKey"
    @submit="onSubmit"
  >
    <template
      #title
      v-if="title"
    >
      <BaseFormHeader
        :title="title"
      >
        <template #subtitle>
          <p
            class="text-sm mt-2"
            :class="{
              'text-gray-500': !$useNewLayout
            }"
          >
            {{ description }}
            <BaseTutorialLink
              v-if="tutorial"
              :name="tutorial"
            />
          </p>
        </template>
      </BaseFormHeader>
    </template>
    <template #default="{ meta, errors }">
      <slot :model="model" />
    </template>
    <template
      #actions="{ meta, errors }"
      v-if="displayActions"
    >
      <slot name="extra-actions" />
      <slot name="actions">
        <BaseButton
          variant="white"
          @click="$router.push('/account-settings')"
        >
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          :disabled="!meta.valid || disabled"
          type="submit"
          class="ml-2"
        >
          {{ $t('Save') }}
        </BaseButton>
      </slot>
    </template>
  </BaseEntityForm>
</template>
<script>
import axios from 'axios'
import i18n from '@/i18n'

export default {
  props: {
    title: String,
    description: String,
    fields: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayActions: {
      type: Boolean,
      default: true
    },
    onSave: {
      type: Function,
      default: null
    },
    tutorial: {
      type: String,
      default: ''
    },
    silentSync: {
      type: Boolean,
      default: false
    },
  },
  data() {
    let model = {}
    this.fields.forEach(field => {
      model[field] = ''
    })
    return {
      loading: false,
      formKey: 1,
      model,
    }
  },
  computed: {
    defaultSettings() {
      let settings = {}
      this.fields.forEach(key => {
        settings[key] = this.$settings(key)
      })
      return settings
    },
  },
  methods: {
    getUpdateRequests() {
      let promises = []
      for (let key in this.model) {
        let newValue = this.model[key]
        let oldValue = this.defaultSettings[key]
        const setting = this.$store.getters['accounts/getSettingByKey'](key)
        if (newValue === oldValue || !setting) {
          continue
        }

        let data = new FormData()
        data.append('key', key)
        data.append('value', newValue || '')
        
        const request = axios.post(`/restify/organization-settings/${setting.id}`, data)
        promises.push(request)
      }
      return promises
    },
    async syncSettings(updatedSettings) {
      // Old way of syncing settings, currently has caching issues
      // await this.$store.dispatch("accounts/getOrganizationSettings")

      // We can sync new settings manually without making a new request
      for (const updatedSetting of updatedSettings) {
        this.$store.commit('accounts/updateOrganizationSetting', updatedSetting)
      }
    },
    async onSubmit() {
      try {
        this.loading = true
        const updateRequests = this.getUpdateRequests()
        const responses = await Promise.all(updateRequests)

        const updatedSettings = responses.map(response => response.data)
        await this.syncSettings(updatedSettings)

        this.formKey++
        if (this.onSave && typeof this.onSave === 'function') {
          this.onSave()
        }

        if (!this.silentSync) {
          this.$success(i18n.t('Settings updated.'))
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not update the settings.'))
      } finally {
        this.loading = false
      }
    },
    resetModel() {
      if (!this.defaultSettings) {
        return
      }

      this.model = {
        ...this.model,
        ...this.defaultSettings,
      }
    },
    resetAndSave() {
      for (let field of this.fields) {
        this.model[field] = null
      }

      this.onSubmit()
    }
  },
  watch: {
    defaultSettings: {
      immediate: true,
      deep: true,
      handler(value) {
        this.resetModel()
      }
    }
  }
}
</script>
