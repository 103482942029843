<template>
  <EntitySelectNew
    v-bind="$attrs"
    ref="entitySelect"
    :placeholder="$t('Select groups...')"
    :options="options"
    :loading="loading"
  />
</template>
<script lang="ts" setup>
// Components
import EntitySelectNew from "@/components/form/EntitySelectNew.vue";

// Utils
import { computed, ref } from "vue";

import Data = API.Data;
import Group = App.Domains.Groups.Models.Group;

// Composables
import { useStore } from "vuex";
const store = useStore()

const emit = defineEmits([
  'raw-change'
])

const groups = computed<Data<Group>[]>(() => {
  return store.getters['groups/groupsData'] || []
})

const options = computed(() => {
  return groups.value
})

const loading = computed(() => {
  return store.state.users.usersLoading
})

const entitySelect = ref<any>(null)

defineExpose({
  setQuery: () => {
    entitySelect.value?.setQuery()
  }
})
</script>
