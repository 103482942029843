import GroupsLayout from '@/modules/groups/layout/GroupsLayout.vue'
import GroupsList from "@/modules/groups/pages/group-list.vue";
import GroupCards from "@/modules/groups/pages/group-cards.vue";
import AddGroup from "@/modules/groups/pages/add-group.vue";
import EditGroup from "@/modules/groups/pages/edit-group.vue";
import ViewGroup from "@/modules/groups/pages/view-group.vue";
import GroupDetailsLayout from "@/modules/groups/layout/GroupDetailsLayout.vue";

import { CREATE_GROUPS } from '@/modules/common/enum/actionsEnum';

export default [
  {
    path: '/groups',
    redirect: '/groups/list',
    name: 'Groups Layout',
    component: GroupsLayout,
    children: [
      {
        path: '/groups/list',
        name: 'Groups List',
        component: GroupsList,
        meta: {
          isViewType: true,
        },
      },
      {
        path: '/groups/card',
        name: 'Group Cards',
        component: GroupCards,
        meta: {
          isViewType: true,
        },
      },
    ]
  },
  {
    path: '/groups/add',
    name: 'New Group',
    component: AddGroup,
    meta: {
      requiresPermissionTo: CREATE_GROUPS
    }
  },
  {
    path: '/groups/:id',
    name: 'Group Details',
    component: GroupDetailsLayout,
    meta: {
      hideBreadCrumb: true,
    },
    redirect: to => {
      return `${to.fullPath}/view`
    },
    children: [
      {
        path: 'view',
        name: 'View Group',
        component: ViewGroup,
      },
      {
        path: 'edit',
        name: 'Edit Group',
        component: EditGroup,
      },
    ]
  },
]
