import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { filterEntities, sortEntities } from "@/modules/common/utils/entityUtils";
import { EntityTargetTypes } from "@/modules/common/utils/filterUtils"

type TargetKeys = keyof typeof EntityTargetTypes;
type TargetValues = typeof EntityTargetTypes[TargetKeys];

/**
 * Used to sort and filter data FE-side
 */
export type SortAndFilterParams = {
  target: TargetValues;
  extraFilters?: any[];
  dataGetterPath: string;
  columnsGetter?: string;
  getQuickFilterText?: (entity: any) => string;
  matchCondition?: (entity: any) => boolean;
  filtersAndSorts?: ReturnType<typeof computed> | null;
}

export default function useSortedAndFilteredData({
   target,
   extraFilters = [],
   dataGetterPath,
   columnsGetter,
   getQuickFilterText,
   matchCondition,
   filtersAndSorts = null
  }: SortAndFilterParams) {
  const route = useRoute()
  const store = useStore()

  const searchTerm = computed(() => {
    return (route?.query?.search || '').toString().toLowerCase()
  })

  const apiFilters = computed(() => {
    return filtersAndSorts?.value || store.getters['filters/targetApiFilters'](target) || []
  })

  const sortArray = computed(() => {
    const sorts = apiFilters.value.find((filter: any) => filter.key === 'sorts')?.value || []

    if (sorts && !Array.isArray(sorts)) {
      return [sorts]
    }

    return sorts
  })

  const entityColumns = computed(() => {
    columnsGetter = columnsGetter || `${target}/activeColumns`

    return store.getters[columnsGetter] || []
  })

  const sortedAndFilteredData = computed(() => {
    const data = store.getters[dataGetterPath] || []

    const filterParams = {
      data,
      entityColumns: entityColumns.value,
      allFilters: apiFilters.value,
      extraFilters,
      extraCondition: (entity: any) => {
        if (!getQuickFilterText) {
          return true
        }

        const entityQuickFilterValue = getQuickFilterText(entity)
        const matchesQuickFilter = entityQuickFilterValue.toLowerCase().includes(searchTerm.value)

        if (!matchCondition) {
          return matchesQuickFilter
        }

        return matchCondition(entity) && matchesQuickFilter
      }
    }

    const filteredData = filterEntities(filterParams)

    const sortedData = sortEntities({
      data: filteredData,
      entityColumns: entityColumns.value,
      sortArray: sortArray.value,
    })

    return sortedData
  })

  return {
    sortedAndFilteredData,
    entityColumns
  }
}
