<template>
  <BaseTableCell :params="params">
    <div
      v-if="isNameCell"
      class="cursor-pointer"
      @click="showPreviewDialog = true"
    >
      {{ row?.attributes?.name || '- -' }}
    </div>
    <BaseFilePreview
      v-else
      :file="row.attributes"
      :thumbnail="true"
      :size="previewSize"
      class="cursor-pointer object-cover mx-auto text-center"
      @click="showPreviewDialog = true"
    />

    <FilePreviewDialog
      v-model="showPreviewDialog"
      :file="row"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const showPreviewDialog = ref(false)

const row = computed(() => {
  return props.params?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const isNameCell = computed(() => {
  return columnParams.value?.isNameCell
})

const previewSize = computed(() => {
  return columnParams.value?.previewSize || 'sm'
})
</script>
