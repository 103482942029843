<template>
  <BaseDialogNew
    v-model="visible"
    class="w-full max-w-2xl"
  >
    <DataImportForm
      :importType="importType"
      :project="project"
      :isDialogForm="true"
    />
  </BaseDialogNew>
</template>
<script lang="ts" setup>
import { PropType, computed } from "vue";
import DataImportForm from "@/modules/accounts/components/DataImportForm.vue";
import { importEntityTypes } from "@/modules/accounts/utils/importUtils";

import Data = API.Data;
import Project = App.Domains.Projects.Models.Project;

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  importType: {
    type: String as PropType<importEntityTypes>,
    default: ''
  },
  project: {
    type: [Object, null] as PropType<Data<Project> | null>,
    default: null
  }
})

const emit = defineEmits(['update:modelValue'])

const visible = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  }
})
</script>
