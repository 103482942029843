<template>
  <div class="flex flex-col flex-1">
    <ProjectsTable
      :data="data"
      :loading="isLoading"
      class="flex-1"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import ProjectsTable from "@/modules/projects/components/ProjectsTable.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore()

const loading = computed(() => {
  return store.state.projects.projectsLoading ||
    store.state.groups.groupsLoading
})

const isLoading = ref(true)

watch(() => loading.value, (value) => {
  isLoading.value = value
}, { immediate: true })

const data = computed(() => {
  if (isLoading.value) {
    return []
  }

  return store.getters['projects/projectsOrTemplatesData']
})
</script>
