<template>
  <img
    v-if="showCardImage"
    :src="src"
    :alt="alt"
    class="kanban-image"
  />
</template>
<script>
import { isImage, createFileURL } from '@/modules/common/utils/fileUtils'
import { orderBy } from 'lodash'
export default {
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    entityFiles() {
      const media = this.entity?.relationships?.media || []
      return orderBy(media, 'id')
    },
    firstImageUpload() {
      return this.entityFiles.find(media => isImage(media.attributes))
    },
    src() {
      const previewUrl = this.get(this.entity, 'relationships.preview')
      if (previewUrl) {
        return previewUrl
      }

      if (this.firstImageUpload?.attributes) {
        return createFileURL(this.firstImageUpload.attributes) || ''
      }

      return ''
    },
    alt() {
      return this.entity?.attributes?.name
    },
    showCardImage() {
      let showImageSetting = this.$settings('show_card_image')
      if (showImageSetting === undefined) {
        showImageSetting = true
      }

      return showImageSetting === true && this.src
    },
  },
}
</script>
<style scoped>
.kanban-image {
  @apply -ml-4 -mt-4 rounded-t-lg mb-3;
  width: calc(100% + 32px);
  max-width: none;
}
</style>
