<template>
  <div
    class="md:flex-col-grow items-center"
  >
    <TopFilters
      :availableFilters="availableFilters"
      :columns="notesColumns"
      :searchPlaceholder="$t('Search notes...')"
    >
      <template
        v-if="isRoleGreaterOrEqual($roles.CREATOR) && !isCurrentProjectClosed"
        #action
      >
        <BaseButton
          :label="$t('New Note')"
          block
          @click="addNewNote"
        />
      </template>
    </TopFilters>
    <router-view />
  </div>
</template>

<script setup lang="ts">
// Components
import TopFilters from '@/modules/filters/components/TopFilters.vue'

// Utils
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from "vue-router";
import i18n from "@/i18n";
import { success } from "@/components/common/NotificationPlugin";
import { notesMainPath } from "@/modules/projects/utils/noteUtils";

const store = useStore()
const route = useRoute()
const router = useRouter()

const availableFilters = ref(['filter', 'sort'])

const notesColumns = computed(() => store.state.projects.notesColumns)
const projectId = computed(() => route.params.id)

const isCurrentProjectClosed = computed(() => {
  return store.getters['projects/isCurrentProjectClosed']
})

async function addNewNote() {
  const note = await store.dispatch('projects/createNewProjectNote', { project_id: projectId.value })
  success(i18n.t('Note added successfully'))
  if (note.id) {
    await router.push(`/${notesMainPath.value}/${projectId.value}/notes/${note.id}`)
  }
}
</script>
