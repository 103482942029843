<template>
  <div
    class="flex items-center px-2 py-3"
    :class="{
      'border-b border-gray-200': $useNewLayout,
    }"
  >
    <div class="shrink-0 text-lg font-medium text-gray-300 hover:text-gray-500 w-4">
      <i v-if="canReorder && canNote(NoteActions.REORDER)" class="fas fa-grip-dots cursor-move rotate-90" />
    </div>
    <div
      :key="isCurrentNote"
      class="px-2 cursor-pointer grow w-8 note-list-item rounded hover:bg-gray-50"
      @click="goToNote"
    >
      <div class="flex items-center space-x-4">
        <div class="flex-1 truncate">
          <p class="flex items-center text-sm text-gray-500 truncate">
            <PinProjectNote :note="note" />
            <span class="text-base font-bold text-gray-700">
            {{ $t(noteName) }}
          </span>
          </p>
          <div
            v-if="canNote(NoteActions.CHANGE_VISIBILITY)"
            class="text-gray-400 text-xs flex gap-1 items-center mt-1"
          >
            <i v-if="isNoteVisibleToCreatorsOnly" class="fa-solid fa-lock mr-1" />
            <span class="first-letter:uppercase">{{ noteVisibilityLabel }}</span>
          </div>
          <p class="text-xs text-gray-400 truncate mt-1">
            {{ formattedDate }}
          </p>
        </div>
        <div class="shrink-0" v-if="isCurrentNote">
          <i class="far fa-angle-right text-xl leading-none text-primary-500"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { get } from 'lodash-es'
import { useRoute, useRouter } from 'vue-router'
import { getNoteName } from '@/modules/projects/utils/stripNotesHTML'
import PinProjectNote from '@/modules/projects/components/projectNotes/PinProjectNote.vue'
import { formatDate } from "@/modules/common/utils/dateUtils";
import {
  canNote,
  NoteActions,
  notePrivacyOptions,
  notesMainPath,
  NotesVisibility
} from "@/modules/projects/utils/noteUtils";

const route = useRoute()
const store = useStore()
const router = useRouter()

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
  canReorder: {
    type: Boolean,
    default: false,
  }
})

const formattedDate = computed(() => {
  const date = get(props.note, 'attributes.created_at' || '')
  return formatDate(
    new Date(date),
    null,
    /* lowerCaseMeridians */ true,
    /* withTime */ true,
  )
})

const projectId = computed(() => route.params.id)

const noteName = computed(() => {
  return getNoteName(props.note?.attributes?.notes)
})

const noteVisibility = computed(() => {
  return props.note?.attributes?.visibility || ''
})

const isNoteVisibleToCreatorsOnly = computed(() => {
  return noteVisibility.value === NotesVisibility.CREATORS_EDIT_COLLABORATORS_RESTRICT
})

const noteVisibilityLabel = computed(() => {
  const label = notePrivacyOptions.value.find(o => o.value === noteVisibility.value)?.label
  return label?.split('&')[1]?.trim() || ''
})

const currentProjectNote = computed(() => store.state.projects.currentProjectNote)

const isCurrentNote = computed(() => String(currentProjectNote.value?.id) === String(props.note.id))

function goToNote() {
  router.push({
    path: `/${notesMainPath.value}/${projectId.value}/notes/${props.note.id}`,
    query: route.query
  })
}
</script>
