<template>
  <BaseTableCell :params="params">
    <div
      :class="{
        'cursor-pointer': hasClickAction
      }"
      @click="onClick"
    >
      {{ textValue }}
    </div>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import { get } from 'lodash-es'

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const textValue = computed(() => {
  const text = get(row.value, colDef.value.field)

  if (typeof columnParams.value?.valueFormatter === 'function') {
    return columnParams.value?.valueFormatter(text)
  }

  return text || '- -'
})

const hasClickAction = computed(() => {
  return typeof columnParams.value?.onClick === 'function'
})

function onClick() {
  if (!hasClickAction.value) {
    return
  }

  columnParams.value?.onClick(row.value)
}
</script>
