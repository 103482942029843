<template>
  <TasksMainLayout />
</template>
<script>
import TasksMainLayout from "@/modules/tasks/layout/TasksMainLayout.vue";

export default {
  components: {
    TasksMainLayout
  },
}
</script>
