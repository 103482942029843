<template>
  <div
    class="relative bg-white p-4 rounded-lg group space-y-1.5 overflow-x-hidden items-center entity-card w-full h-fit"
    :class="{
      'border border-gray-200': $useNewLayout,
      'shadow': !$useNewLayout,
    }"
    @click="$emit('click')"
  >
    <CardImage
      :entity="task"
    />

    <div
      v-for="column of displayColumns"
      :key="column.field"
      class="text-xs text-gray-400 flex space-x-1 items-center"
      :class="{
        'absolute top-2 right-4': column.field === 'options',
      }"
    >
      <span
        v-if="column.showCardLabel"
      >
        <template v-if="column.headerName === $t('Project')">
          <i
            :class="{
              [column.cardIconClass]: column.cardIconClass
            }"
            class="fa-solid fa-inbox"
          />
        </template>
        <template v-else-if="column.headerName === $t('Task')">
          <i class="fa-solid fa-badge-check" />
        </template>
        <template v-else-if="column.headerName === $t('Group')">
          <i class="fa-solid fa-ball-pile" />
        </template>
        <template v-else-if="column.headerName === $t('Person')">
          <i class="fa-solid fa-user" />
        </template>
        <template v-else-if="column.headerName === $t('Date')">
          <i class="fa-solid fa-calendar-days" />
        </template>
        <span v-else class="font-bold">
          {{ column.headerName }}:&nbsp;
        </span>
      </span>
      <div
        v-if="getRendererType(column) && getRendererType(column) !== 'custom'"
        :class="column.cardClass"
      >
        <component
          :is="tableCellComponents[getRendererType(column)]"
          v-bind="column.cardProps"
          :params="getComponentParams(column)"
          :is-card="true"
        />
      </div>
      <template v-else>
        {{ getFormattedValue(column) }}
      </template>
    </div>

    <TaskStats
      :task="task"
      :isCard="true"
      class="absolute bottom-4 right-4"
    />
  </div>
</template>
<script>
import {
  KANBAN_DRAG_DELAY_MOBILE
} from "@/modules/common/utils/kanbanUtils";
import { sleep } from "@/modules/common/utils/commonUtils"
import { get } from 'lodash-es'
import { agGridColumnMapper } from '@/components/table/tableUtils'
import { columnBuilder } from '@/components/table/tableUtils'
import { EntityChangeEvent } from "@/modules/common/utils/entityUtils";
import { tableCellComponents } from "@/components/table/cells/tableCellComponents";

import CardImage from "@/components/common/CardImage.vue";
import TaskStats from '@/modules/tasks/components/TaskStats.vue';

export default {
  components: {
    CardImage,
    TaskStats,
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    link: {
      type: String
    },
    hideLink: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      tableCellComponents,
    }
  },
  data() {
    return {
      isHovering: false,
    }
  },
  computed: {
    taskVisibleColumns() {
      let columns = this.$store.getters['tasks/tableColumns'] || []
      
      columns = columnBuilder.filterDisabledColumns(columns)

      columns = columnBuilder.remapDynamicProperties(columns)

      return columns
    },
    hiddenColumns() {
      return this.$store.getters['filters/targetLocalFilters']('tasks').columnsToHide?.columns || []
    },
    taskCardColumns() {
      const ignoredColumns = ['attributes.order', 'attributes.completed', 'attributes.visibility', 'attributes.group_ids']

      return this.taskVisibleColumns
        .filter(column => !ignoredColumns.includes(column.prop || column.field))
        .map(agGridColumnMapper)
    },
    displayColumns() {
      return this.taskCardColumns.filter((c) => !this.hiddenColumns.includes(c.field) && this.columnHasValue(c))
    },
    taskSyncTrigger() {
      return this.$store.state.tasks.taskSyncTriggers[this.task.id]
    }
  },
  methods: {
    preventTouchDefault() {
      const dragHandle = this.$el?.querySelector('.drag-handle')
      if (!dragHandle) {
        return
      }

      dragHandle.addEventListener('touchmove', async (e) => {
        await sleep(KANBAN_DRAG_DELAY_MOBILE)
        e.preventDefault()
      })
    },
    getRendererType(column) {
      if (column.cellRendererParams?.innerRenderer) {
        return column.cellRendererParams.innerRenderer
      }

      return column.type || ''
    },
    getComponentParams(column) {
      return {
        data: this.task,
        columns: this.taskCardColumns,
        colDef: column
      }
    },
    getFormattedValue(column) {
      const value = this.getColumnValue(column)

      if (column.valueFormatter) {
        return column.valueFormatter({
          data: this.task,
          value,
        })
      }

      return value
    },
    getColumnValue(column) {
      if (column.valueGetter) {
        return column.valueGetter({ data: this.task })
      }

      return get(this.task, column.field)
    },
    columnHasValue(column) {
      if (column.customField) {
        return this.task.attributes?.custom_fields?.[column?.customField?.attributes?.key]
      }

      if (column.field === 'attributes.date') {
        return this.task?.attributes?.date_type !== 'no date'
      }


      if (['options', 'attributes.image', 'attributes.total'].includes(column.field)) {
        return true
      }

      const value = this.getColumnValue(column)

      if (Array.isArray(value)) {
        return value.length > 0
      }

      return value !== undefined && value !== null && value !== ''
    }
  },
  mounted() {
    this.preventTouchDefault()
  },
  watch: {
    taskSyncTrigger: {
      handler(syncTrigger) {
        if (!syncTrigger) {
          return
        }

        if (syncTrigger?.event === EntityChangeEvent.Delete) {
          this.$emit('deleted')
          return
        }

        this.$emit('sync-task', syncTrigger.task)
      },
      deep: true
    },
  }
}
</script>
