import ProjectsList from '@/modules/projects/pages/projects-list.vue'
import ProjectCards from '@/modules/projects/pages/project-cards.vue'
import ProjectDetails from '@/modules/projects/pages/project-details.vue'
import ProjectPeople from '@/modules/projects/pages/project-people.vue'
import ProjectsLayout from "@/modules/projects/layout/ProjectsLayout.vue";
import ProjectDetailsLayout from "@/modules/projects/layout/ProjectDetailsLayout.vue";
import ProjectModuleLayout from "@/modules/projects/layout/ProjectModuleLayout.vue";
import ProjectKanbanLayout from "@/modules/projects/layout/ProjectKanbanLayout.vue";
import TasksModuleLayout from "@/modules/tasks/layout/TasksModuleLayout.vue";
import TasksLayout from "@/modules/tasks/layout/TasksLayout.vue";
import TasksKanbanLayout from "@/modules/tasks/layout/TasksKanbanLayout.vue";
import TasksList from "@/modules/tasks/pages/tasks-list.vue";
import TaskCards from '@/modules/tasks/pages/tasks-cards.vue';
import TasksCalendar from "@/modules/tasks/pages/tasks-calendar.vue";
import TasksScheduler from "@/modules/tasks/pages/tasks-scheduler.vue";
import TasksKanban from "@/modules/tasks/pages/tasks-kanban.vue";
import FilesModuleLayout from "@/modules/files/layout/FilesModuleLayout.vue";
import FilesLayout from "@/modules/files/layout/FilesLayout.vue";
import FilesList from "@/modules/files/pages/files-list.vue";
import FilesCards from "@/modules/files/pages/files-cards.vue";
import ProjectEmbed from "@/modules/projects/pages/project-embed.vue"
import PaymentsLayout from "@/modules/payments/layout/PaymentsLayout.vue";
import PaymentsList from "@/modules/payments/pages/payments-list.vue";
import PaymentsCards from "@/modules/payments/pages/payments-cards.vue";
import TimeModuleLayout from "@/modules/time/layout/TimeModuleLayout.vue"
import AllocatedTimeLayout from "@/modules/time/layout/AllocatedTimeLayout.vue";
import TimeListAllocated from "@/modules/time/pages/time-list-allocated.vue";
import TimeCardsAllocated from "@/modules/time/pages/time-cards-allocated.vue";
import CustomFields from "@/modules/accounts/pages/custom-fields.vue";

import ResourcesModuleLayout from "@/modules/resources/layout/ResourcesModuleLayout.vue";
import ResourcesLayout from "@/modules/resources/layout/ResourcesLayout.vue";
import ResourcesList from "@/modules/resources/pages/resources-list.vue";

import {
  ACCESS_TEMPLATES_LIST,
  ACCESS_TEMPLATES_CARD,
  CREATE_PROJECT_TEMPLATES,
  EDIT_PROJECT_TEMPLATES,
  CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS,
  ACCESS_TASKS_KANBAN,
} from '@/modules/common/enum/actionsEnum'
import ProjectNoteManage from "@/modules/projects/pages/project-note-manage.vue";
import ProjectNoteLayout from '@/modules/projects/layout/ProjectNoteLayout.vue'
import FileProofsModuleLayout from '@/modules/file-proofs/layout/FileProofsModuleLayout.vue'
import FileProofsLayout from '@/modules/file-proofs/layout/FileProofsLayout.vue'
import FilesProofList from '@/modules/file-proofs/pages/file-proofs-list.vue'

export default [
  {
    path: '/templates',
    redirect: '/templates/list',
    name: 'Templates',
    component: ProjectModuleLayout,
    children: [
      {
        path: '/templates',
        redirect: '/templates/list',
        name: 'Templates Layout',
        component: ProjectsLayout,
        children: [
          {
            path: '/templates/list',
            name: 'Templates List',
            component: ProjectsList,
            meta: {
              requiresPermissionTo: ACCESS_TEMPLATES_LIST,
              isViewType: true,
            }
          },
        ]
      },
      {
        path: '/templates',
        name: 'Template Cards Layout',
        component: ProjectKanbanLayout,
        meta: {
          hideBreadCrumb: true,
        },
        children: [
          {
            path: 'card',
            name: 'Template Cards',
            component: ProjectCards,
            meta: {
              requiresPermissionTo: ACCESS_TEMPLATES_CARD,
              isViewType: true,
            }
          },
        ]
      },
      {
        path: '/templates/add',
        name: 'New Template',
        component: ProjectDetails,
        meta: {
          requiresPermissionTo: CREATE_PROJECT_TEMPLATES
        }
      },
      {
        path: '/templates/:id',
        name: 'Template Details',
        component: ProjectDetailsLayout,
        redirect: to => {
          return to.fullPath
        },
        children: [
          {
            path: '',
            name: 'Template View',
            meta: {
              hideBreadCrumb: true,
            },
            component: ProjectDetails,
          },
          {
            name: 'Template Notes Layout',
            path: 'notes',
            component: ProjectNoteLayout,
            meta: {
              isProjectTool: true
            },
            children: [
              {
                path: ':noteId?',
                name: 'Template Notes',
                component: ProjectNoteManage,
              },
            ]
          },
          {
            path: 'people',
            name: 'Template People',
            component: ProjectPeople,
          },
          {
            path: 'tasks',
            name: 'Template Tasks Module Layout',
            component: TasksModuleLayout,
            meta: {
              isProjectTool: true,
            },
            children: [
              {
                path: '',
                name: 'Template Tasks Layout',
                component: TasksLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'Template Tasks List',
                    component: TasksList,
                    meta: {
                      isViewType: true,
                    },
                  },
                  {
                    path: 'card',
                    name: 'Template Task Cards',
                    component: TaskCards,
                    meta: {
                      isViewType: true,
                    }
                  },
                  {
                    path: 'calendar',
                    name: 'Template Tasks Calendar',
                    component: TasksCalendar,
                    meta: {
                      isViewType: true,
                    },
                  },
                  {
                    path: 'scheduler',
                    name: 'Template Tasks Scheduler',
                    component: TasksScheduler,
                    meta: {
                      isViewType: true,
                    },
                  },
                ]
              },
              {
                path: 'kanban',
                name: 'Template Tasks Kanban Layout',
                component: TasksKanbanLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: '',
                    name: 'Template Tasks Kanban',
                    component: TasksKanban,
                    meta: {
                      requiresPermissionTo: ACCESS_TASKS_KANBAN,
                      isViewType: true,
                    }
                  },
                ]
              },
            ]
          },
          {
            path: 'files',
            name: 'Template Files',
            component: FilesModuleLayout,
            meta: {
              isProjectTool: true,
            },
            children: [
              {
                path: '',
                name: 'Template Files Layout',
                component: FilesLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'Template Files List',
                    component: FilesList,
                    meta: {
                      isViewType: true,
                    }
                  },
                  {
                    path: 'card',
                    name: 'Template Files Cards',
                    component: FilesCards,
                    meta: {
                      isViewType: true,
                    }
                  },
                ]
              },
            ]
          },
          {
            path: 'file-proofs',
            name: 'Template File Proofs Module Layout',
            component: FileProofsModuleLayout,
            meta: {
              hideBreadCrumb: true,
            },
            children: [
              {
                path: '',
                name: 'Template File Proofs Layout',
                component: FileProofsLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'Template File Proofs',
                    component: FilesProofList,
                  },
                ]
              },
            ]
          },
          {
            path: 'payments',
            name: 'Template Payments',
            component: PaymentsLayout,
            redirect: to => {
              return `${to.fullPath}/list`
            },
            meta: {
              isProjectTool: true,
            },
            children: [
              {
                path: 'list',
                name: 'Template Payments List',
                meta: {
                  isViewType: true,
                },
                component: PaymentsList,
              },
              {
                path: 'card',
                name: 'Template Payments Cards',
                meta: {
                  isViewType: true,
                },
                component: PaymentsCards,
              },
            ]
          },
          {
            path: 'time',
            name: 'Template Time Module Layout',
            component: TimeModuleLayout,
            meta: {
              isProjectTool: true,
            },
            children: [
              {
                path: 'allocated',
                name: 'Template Allocated Time Layout',
                component: AllocatedTimeLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                redirect: to => {
                  return `${to.fullPath}/list`
                },
                children: [
                  {
                    path: 'list',
                    name: 'Allocated Template Time',
                    component: TimeListAllocated,
                    meta: {
                      isViewType: true
                    }
                  },
                  {
                    path: 'card',
                    name: 'Allocated Template Time Cards',
                    component: TimeCardsAllocated,
                    meta: {
                      isViewType: true
                    }
                  },
                ]
              },
            ]
          },
          {
            path: 'custom-fields',
            name: 'Template Custom Fields',
            component: CustomFields,
            meta: {
              requiresPermissionTo: CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS,
            }
          },
          {
            path: 'resources',
            name: 'Template Resources',
            component: ResourcesModuleLayout,
            meta: {
              isProjectTool: true,
            },
            children: [
              {
                path: '',
                name: 'Template Resources Layout',
                component: ResourcesLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'Template Resources List',
                    component: ResourcesList,
                    meta: {
                      hideBreadCrumb: true,
                    },
                  },
                ]
              },
            ]
          },
          {
            path: 'embeds/:embedId',
            name: 'Template Embed',
            component: ProjectEmbed,
            meta: {
              isProjectTool: true
            },
          },
        ]
      },
    ]
  },
]
