import { CURRENCIES } from "@/modules/payments/enum/paymentEnums.js";
const defaultLocale = 'en-US'
import store from '@/store'

export function formatPrice(value, options = {}) {
  if (value === null || value === undefined) {
    return '- -'
  }

  let formatter
  let locale = options.locale || defaultLocale
  if (this?.$store) {
    locale = this.$store.state?.auth?.user?.active_locale || defaultLocale
  }
  
  if (options.currencyDisplay === false || (!options.currencyCode && options.ignoreDefaultCurrency)) {
    formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits: options.maximumFractionDigits || 2,
      minimumFractionDigits: options.minimumFractionDigits !== undefined ? options.minimumFractionDigits : 2,
    })
  } else {
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: options.currencyCode || CURRENCIES.EUR,
      maximumFractionDigits: options.maximumFractionDigits || 2,
      minimumFractionDigits: options.minimumFractionDigits !== undefined ? options.minimumFractionDigits : 2,
    })
  }

  return formatter.format(value)
}

export function getCurrencyCodeSymbol(currencyCode, options = {}) {
  let locale = options.locale || defaultLocale
  if (store) {
    locale = store.state?.auth?.user?.active_locale
  }

  let defaultValue = 0
  
  return defaultValue.toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode || CURRENCIES.EUR,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).replace(/\d/g, '').trim();
}

export function getCurrencyWithSymbol(currencyCode, options = {}) {
  const symbol = getCurrencyCodeSymbol(currencyCode, options)
  return `${currencyCode} (${symbol})`
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$formatPrice = formatPrice
  }
}
