<template>
  <BaseDropdown
    v-if="options?.length"
    :options="options"
    class="items-center"
    trigger="hover"
    @action="emit($event)"
  >
    <button
      type="button"
      class="w-6 h-6 opacity-70 hover:opacity-100 focus:none hover:bg-gray-200 rounded-md"
      :class="{
        'text-gray-900': $useNewLayout,
        'text-white': !$useNewLayout
      }"
      id="options-menu"
      aria-expanded="true"
      aria-haspopup="true"
    >
      <i class="fas fa-ellipsis "/>
    </button>
    <template #option="{ option }">
      <div
        class="flex justify-start items-center text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        :class="option.class"
      >
        <i v-if="option.icon" :class="option.icon" />
        <span>{{ option.label }}</span>

        <BaseTooltip
          v-if="option.requireCreatorAdminRole"
          :content="i18n.t('Edit the columns that show in this kanban view.')"
        >
          <i class="fas fa-info-circle relative ml-2 text-gray-300 hover:text-gray-500 cursor-pointer" />
        </BaseTooltip>
      </div>
    </template>
  </BaseDropdown>
</template>
<script setup>
import { computed } from 'vue'
import { entityTypes } from "@/modules/projects/composables/useKanbanGroups.js";
import i18n from "@/i18n.js"
import useCan from "@/modules/common/composables/useCan";

const { can, actions } = useCan();

const emit = defineEmits([
  'add-new-entity',
  'edit-columns',
])

const props = defineProps({
  entity: {
    type: String,
    default: 'project'
  },
  selectedField: {
    type: Object,
    required: true
  }
})

const editCustomFieldOption = {
  icon: 'fa-solid fa-objects-column mr-2 text-gray-400',
  requireCreatorAdminRole: true,
  label: i18n.t('Edit kanban columns'),
  requiresPermissionTo: props.selectedField?.isProjectScoped
    ? actions.EDIT_CUSTOM_FIELDS_INSIDE_PROJECTS
    : actions.EDIT_CUSTOM_FIELDS,
  action: 'edit-columns'
}

const editStatusOption = {
  icon: 'fa-solid fa-objects-column mr-2 text-gray-400',
  requireCreatorAdminRole: true,
  label: i18n.t('Edit kanban columns'),
  requiresPermissionTo: props.entity === entityTypes.Project
    ? actions.EDIT_PROJECT_STATUSES
    : actions.EDIT_TASK_STATUSES,
  action: 'edit-columns'
}

const editColumnsOption = computed(() => {
  if (props.selectedField?.groupByProp?.includes('custom_fields')) {
    return editCustomFieldOption
  }

  return editStatusOption
})

const projectOptions = computed(() => {
  return [
    {
      icon: 'fa-solid fa-square-plus mr-2 text-primary-500',
      label: i18n.t('Add new project here'),
      requiresPermissionTo: actions.CREATE_PROJECTS,
      action: 'add-new-entity'
    },
    editColumnsOption.value
  ]
})

const taskOptions = computed(() => {
  return [
    {
      icon: 'fa-solid fa-square-plus mr-2 text-primary-500',
      label: i18n.t('Add new task here'),
      requiresPermissionTo: actions.CREATE_TASKS,
      action: 'add-new-entity'
    },
    editColumnsOption.value
  ]
})

const options = computed(() => {
  let options = []
  if (props.entity === entityTypes.Project) {
    options = projectOptions.value
  }

  if (props.entity === entityTypes.Task) {
    options = taskOptions.value
  }

  return options.filter(x => {
    if (x.enabled) {
      return x.enabled()
    }
    return !x.requiresPermissionTo || can(x.requiresPermissionTo)
  })
})
</script>
