<template>
  <BaseDialog
    v-model="visible"
    v-show="visible"
    @close="cancelChange"
  >
    <BaseEntityForm
      :title="$t('Create Starred Page')"
      :subtitle="$t(`Save this page along with all its current settings. This is a great way to save pages along with the settings you use regularly.`)"
      width-class="max-w-2xl"
      :isDialogForm="true"
      @submit="saveTitle"
    >
      <template #default="{ meta, errors }">
        <BaseInput
          v-model="title"
          v-focus
          :label="$t('Title')"
          @keydown.enter.stop="saveTitle"
          :placeholder="$t('Please set a title for your starred page...')"
          layout="horizontal"
        />
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          variant="white"
          @click="cancelChange"
        >
          {{ $t("Cancel") }}
        </BaseButton>
        <BaseButton
          type="submit"
          class="ml-2"
        >
          {{ $t("OK") }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialog>
</template>
<script>
export default {
  name: 'StarredPageSetTitleDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    initTitle: {
      type: String,
      default: ''
    }
  },
  emits: ['ok', 'cancel', 'update:modelValue'],
  data() {
    return {
      title: this.initTitle
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
  methods: {
    cancelChange() {
      this.visible = false
      this.title = this.initTitle
      this.$emit('cancel')
    },
    saveTitle() {
      this.visible = false
      this.$emit('ok', this.title)
    }
  },
  watch: {
    initTitle: {
      immediate: true,
      handler() {
        this.title = this.initTitle
      }
    }
  }
}
</script>
