<template>
  <template v-if="component">
    <div class="flex gap-1" v-if="range">
      <component
        v-model="rangeValue.min"
        :is="component"
        v-bind="$attrs"
        :clearable="clearable"
        :label="`${$t('Min')} ${column.name}`"
        :placeholder="`${$t('Enter Min')} ${column.name}...`"
        minutes-input
        @blur="validateRange('min')"
        @raw-change="$emit('raw-change', $event)"
      />
      <component
        v-model="rangeValue.max"
        :is="component"
        v-bind="$attrs"
        :clearable="clearable"
        :label="`${$t('Max')} ${column.name}`"
        :placeholder="`${$t('Enter Max')} ${column.name}...`"
        minutes-input
        @blur="validateRange('max')"
        @raw-change="$emit('raw-change', $event)"
      />
    </div>
    <component
      v-else
      v-model="value"
      v-bind="$attrs"
      :is="component"
      :clearable="clearable"
      minutes-input
      @raw-change="$emit('raw-change', $event)"
    />
  </template>

  <BaseInput
    v-else-if="type === 'text'"
    v-model="value"
    :inline-errors="true"
    :clearable="clearable"
    :placeholder="textPlaceholder"
  />
  <Slider
    v-else-if="type === 'range-slider'"
    v-model="value"
    v-bind="$attrs"
    :minValue="rangeMinMax?.min"
    :maxValue="rangeMinMax?.max"
    class="px-3 mb-2"
  />
  <BaseDatePicker
    v-else-if="type === 'date'"
    v-model="value"
    :clearable="clearable"
  />
  <BaseDatePicker
    v-else-if="type === 'date-range'"
    v-model="value"
    type="daterange"
    :clearable="clearable"
    :remap-range="{
      start: 'min',
      end: 'max'
    }"
  />
  <BaseSelect
    v-else-if="isStatusOrRoleSelect"
    v-model="value"
    v-bind="$attrs"
    :options="mappedSelectOptions"
    value-key="id"
    :select-with-color="type !== 'UserRoleSelect'"
    :clearable="clearable"
    :placeholder="statusRoleSelectPlaceholder"
    capitalize
  />
  <span v-else>
    ERR: NOT DEFINED {{ type }}
    <pre>{{ column }}</pre>
  </span>
</template>
<script>
import UserSelect from "@/components/selects/UserSelect.vue";
import ProjectSelect from "@/components/selects/ProjectSelect.vue";
import FolderSelect from "@/components/selects/FolderSelect.vue";
import TaskSelect from "@/components/selects/TaskSelect.vue";
import GroupSelect from "@/components/selects/GroupSelect.vue";
import CurrencySelect from "@/components/selects/CurrencySelect.vue";
import EmbedTypeSelect from "@/modules/resources/components/EmbedTypeSelect.vue";
import ResourceTypeSelect from "@/modules/resources/components/ResourceTypeSelect.vue";
import FileTypeSelect from "@/modules/resources/components/FileTypeSelect.vue";
import EntityTypeSelect from "@/modules/accounts/components/EntityTypeSelect.vue";
import CustomFieldTypeSelect from "@/components/selects/CustomFieldTypeSelect.vue";
import NotificationTypeSelect from "@/modules/accounts/components/NotificationTypeSelect.vue";
import CustomFieldInput from "@/modules/common/components/CustomFieldInput.vue";
import { ALL_CURRENCIES } from '@/modules/payments/enum/paymentEnums';
import TimePicker from "@/components/form/TimePicker.vue";
import Slider from "@/components/form/Slider.vue";
import TaskPrivacySelect from "@/modules/tasks/components/TaskPrivacySelect.vue";
import ProjectPrivacySelect from "@/modules/projects/components/ProjectPrivacySelect.vue";
import NotePinnedSelect from '@/modules/projects/components/projectNotes/NotePinnedSelect.vue'
import ProofStatusSelect from '@/modules/file-proofs/components/ProofStatusSelect.vue'

export default {
  components: {
    NotePinnedSelect,
    UserSelect,
    ProjectSelect,
    FolderSelect,
    TaskSelect,
    GroupSelect,
    CurrencySelect,
    TimePicker,
    EmbedTypeSelect,
    ResourceTypeSelect,
    FileTypeSelect,
    EntityTypeSelect,
    ProofStatusSelect,
    CustomFieldTypeSelect,
    NotificationTypeSelect,
    CustomFieldInput,
    Slider,
    TaskPrivacySelect,
    ProjectPrivacySelect,
  },
  props: {
    modelValue: {
      type: [String, Object, Date, Number]
    },
    column: {
      type: Object,
      required: true
    },
    target: {
      type: String,
      default: ''
    },
    range: {
      type: [Object, Function],
      default: null
    },
  },
  emits: ['update:modelValue', 'raw-change'],
  data() {
    return {
      inputVisible: false,
      rangeValue: {
        min: this.modelValue?.min || null,
        max: this.modelValue?.max || null
      },
      currencyOptions: ALL_CURRENCIES.map((x, i) => ({
        id: i,
        label: x,
        value: x
      }))
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        if (this.type === 'range-slider' && value?.min === this.rangeMinMax?.min && value?.max === this.rangeMinMax?.max) {
          this.$emit('update:modelValue', null)
          return;
        }
        this.$emit('update:modelValue', value)
      }
    },
    filterBy() {
      return this.column.filterBy
    },
    type() {
      return this.filterBy.type
    },
    isStatusOrRoleSelect() {
      return [
        'ProjectStatusSelect',
        'TaskStatusSelect',
        'ProofStatusSelect',
        'PaymentStatusSelect',
        'UserRoleSelect'
      ].includes(this.type)
    },
    component() {
      return this.filterBy.component
    },
    range() {
      return this.filterBy.range
    },
    rangeMinMax() {
      const range = typeof this.range === 'function'
        ? this.range()
        : this.range

      if (range.min > range.max) {
        range.min = range.max
      }

      return range
    },
    selectOptions() {
      switch(this.type) {
        case 'TaskStatusSelect':
          return this.$store.getters['tasks/orderedStatuses'] || []
        case 'ProofStatusSelect':
          return this.$store.getters['proofs/orderedStatuses'] || []
        case 'ProjectStatusSelect':
          return this.$store.getters['projects/orderedStatuses'] || []
        case 'PaymentStatusSelect':
          return this.$store.state.payments.statuses.data || []
        case 'UserRoleSelect':
          return this.$store.state.users.roles.data || []
        default:
          return []
      }
    },
    mappedSelectOptions() {
      return this.selectOptions.map(x => ({
        id: x.id,
        value: x.attributes.color || x.id,
        label: x.attributes.name
      }))
    },
    clearable() {
      return this.$attrs.clearable !== undefined ? this.$attrs.clearable : true
    },
    textPlaceholder() {
      return `${this.$t("Enter")} ${this.column.name}...`
    },
    statusRoleSelectPlaceholder() {
      return `${this.$t("Select")} ${this.column.name}...`
    }
  },
  methods: {
    validateRange(changedProp) {
      if (
        [null, ''].includes(this.rangeValue?.min) || [null, ''].includes(this.rangeValue?.max)
        ||
        isNaN(this.rangeValue?.min) || isNaN(this.rangeValue?.max)
      ) {
        return
      }

      if (changedProp === 'max' && +this.rangeValue.max < +this.rangeValue.min) {
        this.value = {
          ...this.value,
          max: this.rangeValue.min
        }
        this.$error('Max value cannot be smaller than Min value')
        return
      }

      if (changedProp === 'min' && +this.rangeValue.min > +this.rangeValue.max) {
        this.value = {
          ...this.value,
          min: this.rangeValue.max
        }
        this.$error('Min value cannot be greater than Max value')
        return
      }
    }
  },
  watch: {
    rangeValue: {
      deep: true,
      handler(newValue) {
        this.value = newValue
      }
    },
    modelValue() {
      this.rangeValue = this.modelValue
    },
  }
}
</script>
