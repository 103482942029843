<template>
  <draggable
    v-loading="loading"
    :modelValue="modelValue"
    :animation="200"
    :move="checkMove"
    tag="ul"
    class="-mt-6 divide-y divide-gray-200"
    ghost-class="ghost-card"
    handle=".fa-grip-dots"
    group="statuses"
    item-key="id"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #item="{ element }">
      <li class="py-4 px-6">
        <div class="flex items-center space-x-5">
          <div class="shrink-0 font-medium text-lg text-gray-300 hover:text-gray-500">
            <i
              v-if="canOrderElement(element)"
              class="fa fa-grip-dots cursor-move"
            />
            <span v-else class="ml-3.5"></span>
          </div>
          <div class="hidden sm:inline-block shrink-0 w-5 text-center">
            <div v-if="element?.attributes?.color"
                 :style="{backgroundColor: element?.attributes?.color}"
                 class="w-4 h-4 rounded mr-1">
            </div>
          </div>
          <div class="flex-1 min-w-0">
            <p class="font-bold text-gray-900 truncate capitalize">
              {{ element.attributes?.name }}
            </p>
          </div>
          <BaseDropdown
            :options="getDropdownOptions(element)"
            :label="$t('Options')"
            trigger="hover"
          />
        </div>
      </li>
    </template>
  </draggable>
</template>
<script>

import draggable from "vuedraggable/src/vuedraggable"

export default {
  components: {
    draggable
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
  },
  computed: {
    maxOrder() {
      return this.modelValue.reduce((a, b) => {
        return Math.max(a, b.attributes.order)
      }, 0)
    },
  },
  emits: ['update:modelValue'],
  methods: {
    getDropdownOptions(element) {
      return [
        {
          label: this.$t('Edit status'),
          enabled: () => {
            return this.canEditElement(element)
          },
          action: () => this.$emit('edit', element)
        },
        {
          label: this.$t('Remove this status'),
          enabled: () => {
            return this.canDeleteElement(element)
          },
          action: () => this.$emit('delete', element)
        }
      ]
    },
    isFirstOrLastElement(element) {
      return [1, this.maxOrder].includes(element.attributes.order)
    },
    isLastElement(element) {
      return element.attributes.order === this.maxOrder
    },
    canOrderElement(element) {
      if (element.attributes.readonly) {
        return false
      }
      return !this.isFirstOrLastElement(element)
    },
    canEditElement(element) {
      return !element.attributes.readonly
    },
    canDeleteElement(element) {
      if (element.attributes.readonly) {
        return false
      }
      return !this.isFirstOrLastElement(element)
    },
    checkMove(evt) {
      const draggedElement = evt.draggedContext.element
      const relatedElement = evt.relatedContext.element
      return this.canOrderElement(relatedElement) && this.canOrderElement(draggedElement)
    },
  }
}
</script>
<style>
</style>