<template>
  <main class="p-2 sm:p-4 body-background h-full">
    <div class="bg-white rounded-lg h-full overflow-y-auto md:overflow-hidden">
      <div class="md:grid md:grid-cols-2 h-full">

        <!--LEFT SECTION: START-->
        <div class="col-span-1 p-4 sm:p-12 md:overflow-y-auto">
          <BaseAppLogo
            :logoSrc="logoSrc"
            class="h-8"
          />

          <!--ACTION: START-->
          <div class="mt-14 md:mt-20">
            <div class="space-y-4">
              
              <h1 class="text-gray-900 text-3xl sm:text-4xl font-extrabold">
                {{
                  $tc('register welcome message', {
                    firstName: model.first_name
                  })
                }}&nbsp;👋
              </h1>
              <p class="text-gray-500 leading-7 text-lg">
                {{ $t('Complete your account set up.') }}
              </p>

              <BaseForm
                @submit="onSubmitForm"
              >
                <template #default="{ meta, errors, handleSubmit }">
                  <div class="rounded-lg">
                    <label
                      for="register_account_name"
                      class="block text-sm font-medium leading-6 text-gray-700"
                    >
                      {{ $t(`Account name`) }}
                      <BaseIconTooltip
                        :content="$t(`Your company name or a name to identify your account.`)"
                      />
                    </label>
                    <div class="relative flex items-center">
                      <BaseInput
                        v-model="model.account_name"
                        :name="$t('Account name')"
                        :placeholder="$t('Account name')"
                        rules="required"
                        id="register_account_name"
                        class="w-full text-base shadow-none"
                        @update:modelValue="onAccountNameChanged"
                      />
                    </div>
                    <div
                      v-if="!showAccountURLInput"
                      class="text-xs text-gray-400 pr-2 mt-1 ml-auto col-span-6 basis-full"
                    >
                      {{ $tc('register account url', { accountUrl }) }}
                      <span
                        class="ml-2 text-primary-500 cursor-pointer font-semibold"
                        @click="showAccountURLInput = true"
                      >
                        {{  $t('Edit')  }}
                      </span>
                    </div>
                    
                    <template v-if="showAccountURLInput">
                      <label
                        for="register_account_url"
                        class="block text-sm font-medium leading-6 text-gray-700 mt-4"
                      >
                        {{ $t(`Account URL`) }}
                        <BaseIconTooltip
                          :content="$t(`This will be your account url after you finish your registration. You will use this account url to sign in into your account or invite other people.`)"
                        />
                      </label>
                      <div class="relative flex items-center">
                        <BaseInput
                          v-model="model.subdomain"
                          :name="$t('Account URL')"
                          :placeholder="$t('Account URL')"
                          :show-error-icon="false"
                          rules="required|alpha_dash"
                          id="register_account_url"
                          class="w-full text-base shadow-none"
                        >
                          <template #after-input>
                            <span
                              class="inline-flex items-center p-2 sm:p-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                              {{ domain }}
                            </span>
                          </template>
                        </BaseInput>
                      </div>
                    </template>
                    <OnboardingInput
                      v-for="question of onboardingQuestions"
                      v-model="model.onboarding.questions[question.key]"
                      v-model:otherInputModelValue="model.onboarding.questions[`${question.key}_other`]"
                      :key="question.key"
                      :question="question"
                    />
                    <VueRecaptcha
                      ref="vueRecaptcha"
                      @verify="recaptchaVerified"
                    />
                  </div>

                  <button
                    type="submit"
                    class="mt-4 rounded-md body-background px-6 py-4 text-sm font-semibold text-white"
                    :class="{
                      'opacity-80 cursor-not-allowed': !meta.valid,
                      'hover:px-8 transition-all': meta.valid
                    }"
                    :disabled="!meta.valid || loading"
                  >
                    <template v-if="loading">
                      <LoadingIcon />
                    </template>
                    <template v-else>
                      {{ $t('Submit') }}
                      <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
                    </template>
                  </button>
                </template>
              </BaseForm>
            </div>
          </div>
          <!--ACTION: END-->
        </div>
        <!--LEFT SECTION: END-->

        <!--RIGHT SECTION: START-->
        <div class="hidden md:block col-span-1 bg-gray-200 overflow-hidden">
          
          <section class="relative isolate overflow-hidden px-32 mt-60">
            <div class="mx-auto max-w-2xl lg:max-w-4xl">
              <img
                class="mx-auto h-10"
                src="/img/register_postre.png"
                alt=""
              >
              <figure class="mt-6">
                <blockquote class="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                  <p>“It allows me to manage the tasks with the graphic design team at the same time that I can manage a workflow with the clients in a very simple and effective way.”</p>
                </blockquote>
                <figcaption class="mt-6">
                  <img
                    class="mx-auto h-16 w-16 rounded-full"
                    src="/img/register_postre_founder.jpg"
                    alt=""
                  >
                  <div class="mt-4 flex items-center justify-center space-x-3 text-base">
                    <div class="font-semibold text-gray-900">Flor López Pezé</div>
                    <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" class="fill-gray-900">
                      <circle cx="1" cy="1" r="1"></circle>
                    </svg>
                    <div class="text-gray-600">Founder of Postre</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </section>
          

        </div>
        <!--RIGHT SECTION: END-->
      </div>
    </div>
  </main>
</template>
<script>
// Components
import SocialLinks from '@/modules/auth/components/SocialLinks.vue'
import AppSumoCodesInput from  '@/modules/common/components/AppSumoCodesInput.vue'
import OnboardingInput from '@/modules/auth/components/OnboardingInput.vue'
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";

// Utils
import { get, kebabCase, merge } from "lodash-es";
import config from "@/modules/common/config.js";
import {
  registerCampaigns,
  applyCampaignBranding,
  onboardingQuestions,
  OnboardingQuestionKeys
} from "@/modules/auth/utils/registerUtils"
import { logoDarkFull } from '@/modules/accounts/utils/accountBrandingUtils'
import VueRecaptcha from '@/modules/dashboard/components/VueRecaptcha.vue';

export default {
  components: {
    VueRecaptcha,
    SocialLinks,
    AppSumoCodesInput,
    OnboardingInput,
    LoadingIcon,
  },
  data() {
    return {
      loading: false,
      recaptcha: null,
      model: {
        account_name: '',
        subdomain: '',
        onboarding: {
          questions: {},
        }
      },
      domain: `.v2.${config.host}`,
      showAccountURLInput: false,
      onboardingQuestions,
    }
  },
  computed: {
    registerModel() {
      return this.$store.state.auth.registerModel || {}
    },
    logoSrc() {
      return logoDarkFull.value
    },
    campaignName() {
      return this.model.campaign || this.$route.query.campaign
    },
    activeCampaign() {
      return registerCampaigns.find(campaign => campaign.name === this.campaignName)
    },
    accountUrl() {
      return `${this.model.subdomain || ''}${this.domain}`
    },
    isSocialRegister() {
      return this.model.provider
    },
  },
  methods: {
    recaptchaVerified(response) {
      this.recaptcha = response;
      this.onSubmit();
    },
    async onSubmitForm() {
      await this.$refs.vueRecaptcha.execute();
    },
    async onSubmit() {
      try {

        if (this.model.email.endsWith('.ru')) {
          return
        }

        this.$store.commit('auth/setRegisterModel', this.model)

        const query = this.$route.query
        this.loading = true

        const questions = {}
        for (const k of Object.values(OnboardingQuestionKeys)) {
          questions[k] = this.model.onboarding.questions[k]

          if (questions[k] === 'Other' && this.model.onboarding.questions[`${k}_other`]) {
            questions[k] += (' - ' + this.model.onboarding.questions[`${k}_other`])
          }
        }

        const data = {
          ...this.model,
          onboarding: {
            questions,
          },
          'g-recaptcha-response': this.recaptcha,
        }

        await this.$store.dispatch('auth/register', {
          data,
          params: query,
        })
      }
      catch(err) {
        const emailAlreadyTaken = get(err, 'response.data.errors.email[0]') === `The email has already been taken.`
        if (err?.response?.status === 422 && emailAlreadyTaken) {
          this.$router.push('/register')
        }
      }
      finally {
        this.loading = false
        this.$refs.vueRecaptcha.reset();
      }
    },
    checkActiveCampaign() {
      if (!this.activeCampaign) {
        return
      }

      this.model.campaign = this.activeCampaign.name

      applyCampaignBranding(this.activeCampaign)
    },
    initModel() {
      this.model = merge(this.model, this.registerModel)
      this.initOnboardingModel()
    },
    initOnboardingModel() {
      this.onboardingQuestions.forEach(question => {
        if (this.model.onboarding.questions[question.key]) {
          return
        }

        this.model.onboarding.questions[question.key] = ''
      })
    },
    onAccountNameChanged() {
      this.model.subdomain = kebabCase(this.model.account_name)
    },
  },
  beforeMount() {
    this.initModel()
    this.checkActiveCampaign()
  }
}
</script>
