<template>
  <EntitySelectNew
    v-bind="$attrs"
    ref="entitySelect"
    fallbackLabelKey="attributes.email"
    logoKey="attributes.avatar"
    :placeholder="$t('Select users...')"
    :options="options"
    :loading="loading"
  >
    <template
      v-if="!loading"
      #custom-options
    >
      <slot name="custom-options" />
    </template>
    <template #option-after="{ option }">
      <UserRoleBadge
        :user="option"
        size="xs"
        class="ml-2"
      />
      <UserStatus
        :row="option"
        :column="{ prop: 'attributes.status' }"
        :show-if-active="false"
        class="ml-2"
      />
    </template>
  </EntitySelectNew>
</template>
<script lang="ts" setup>
// Components
import EntitySelectNew from "@/components/form/EntitySelectNew.vue";
import UserStatus from "@/components/table/UserStatus.vue";
import UserRoleBadge from "@/components/common/UserRoleBadge.vue";

// Utils
import { computed, PropType, ref } from "vue";
import {
  isUserArchived,
  UserTypes,
  filterUsersByType
} from "@/modules/users/util/userUtils";

import Data = API.Data;
import User = App.Domains.Users.Models.User;
import Group = App.Domains.Groups.Models.Group;

// Composables
import { useStore } from "vuex";
const store = useStore()

const props = defineProps({
  includeArchived: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<UserTypes>,
    default: UserTypes.All,
  },
  filterGroupIds: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
})

const users = computed<Data<User>[]>(() => {
  let userList: Data<User>[] = store.getters['users/usersWithGroups'] || []

  userList = filterUsersByType(userList, props.type)

  if (props.filterGroupIds.length) {
    userList = userList.filter((user) => {
      return user.relationships?.groups?.some((group: Data<Group>) => props.filterGroupIds.includes(+group.id))
    })
  }

  if (!props.includeArchived) {
    userList = userList.filter((user) => !isUserArchived(user))
  }

  return userList
})

const options = computed(() => {
  return users.value
})

const loading = computed(() => {
  return store.state.users.usersLoading
})

const entitySelect = ref<any>(null)

defineExpose({
  setQuery: () => {
    entitySelect.value?.setQuery()
  }
})
</script>
