<template>
  <div v-if="!$route.query.loginToken">
    <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
      <div class="mb-4">
        <img
          class="m-auto mb-2 h-10"
          :src="logoSrc"
          alt="Project.co"
        >
        <BasePageTitle>
          {{ $t('Sign in to your account') }}
        </BasePageTitle>
      </div>

      <VeeForm v-slot="{ handleSubmit, errors, meta }">
        <form @submit.prevent="handleSubmit(onSubmit)"
              class="space-y-4"
        >
          <BaseInput
            v-model="model.email"
            :label="$t('Email address')"
            :placeholder="$t('Email address')"
            :name="$t('Email address')"
            layout="horizontal"
            rules="required|email"
            id="email"
            type="email"
            autocomplete="email"
          />
          <BaseInput
            v-model="model.password"
            :label="$t('Password')"
            :placeholder="$t('Password')"
            :name="$t('Password')"
            layout="horizontal"
            rules="required"
            id="password"
            type="password"
            autocomplete="current-password"
          />

          <div>
            <BaseButton
              :disabled="!meta.valid && !autofilled"
              type="submit"
              block
              :loading="loading"
            >
              {{ $t('Sign in') }}
            </BaseButton>
          </div>

          <div class="flex items-center justify-center mt-4">
            <div class="text-sm">
              <router-link to="/forgot-password" class="font-medium text-primary-500 hover:text-primary-600">
                {{ $t('Forgot your password?') }}
              </router-link>
            </div>
          </div>
        </form>
      </VeeForm>
    </div>

    <SocialLinks />
  </div>
</template>
<script>
import config, { isRootApi } from '@/modules/common/config.js'
import AuthService from '@/modules/auth/services/AuthService.js'
import SocialLinks from '@/modules/auth/components/SocialLinks.vue'

import {
  logoDarkFull,
} from '@/modules/accounts/utils/accountBrandingUtils.js'

import {
  sleep,
  inputHasAutofill
} from "@/modules/common/utils/commonUtils"

export default {
  components: {
    SocialLinks
  },
  data() {
    return {
      registerLink: `${config.ROOT_DOMAIN_URL}/register`,
      loading: false,
      model: {
        email: '',
        password: '',
        rememberMe: false,
      },
      socialProviders: {
        GOOGLE: 'google',
        FACEBOOK: 'facebook',
      },
      currentOrganization: '',
      autofilled: false
    }
  },
  computed: {
    isGlobalLogin() {
      if (import.meta.env.DEV) {
        return false
      }
      return isRootApi()
    },
    organizations() {
      return this.$store.state.auth.organizations || []
    },
    customLogo() {
      return this.$settings('login_page_logo')
    },
    logoSrc() {
      return this.customLogo || logoDarkFull.value
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        await this.$store.dispatch('auth/login', this.model)

        if (!this.isGlobalLogin) {

          if (this.$route.query.from) {
           await this.$router.push(this.$route.query.from)
           return
          }

          await this.$router.push('/welcome')
          return;
        }

        await this.handleOrganizationSelect()

      } finally {
        this.loading = false
      }
    },
    async handleOrganizationSelect() {
      AuthService.tryRedirectToSubdomain()
      await this.$router.push('/select-organization')
    },
    getSocialLink(provider) {
      return `${config.ROOT_URL}/auth/login/${provider}`
    },
    setCurrentOrganization() {
      this.currentOrganization = config.getOrganizationName()
    },
    async checkAutofill() {
      await sleep(1000)

      const inputSelectors = ['#email input', '#password input']

      for (const selector of inputSelectors) {
        if (!inputHasAutofill(document.querySelector(selector))) {
          return
        }
      }

      this.autofilled = true
    }
  },
  async mounted() {
    this.setCurrentOrganization()
    this.checkAutofill()
  }
}
</script>
