import { useWebWorkerFn } from '@vueuse/core'
import store from "@/store/index.js";

export function getTimeEntryWithRelationships(
  timeEntry: any,
  allGroups: any[],
  allProjects: any[],
  allUsers: any[],
  allTasks: any[],
) {
  
  allGroups = allGroups || store.state.groups.allGroups || []
  allProjects = allProjects || store.state.projects.allProjects || []
  allUsers = allUsers || store.state.users.allUsers || []
  allTasks = allTasks || store.state.tasks.allTasks || []

  const group = allGroups.find(group => group.id == timeEntry.attributes.group_id)
  const project = allProjects.find(project => project.id == timeEntry.attributes.project_id)
  const user = allUsers.find(user => user.id == timeEntry.attributes.user_id)
  const task = allTasks.find(task => task.id == timeEntry.attributes.task_id)

  return {
    ...timeEntry,
    relationships: {
      ...timeEntry.relationships,
      group,
      project,
      user,
      task,
    }
  }
}

function computeRelationships(
  allTimeEntries: any[],
  allGroups: any[],
  allProjects: any[],
  allUsers: any[],
  allTasks: any[],
) {

  return allTimeEntries.map(timeEntry => {
    const group = allGroups.find(group => group.id == timeEntry.attributes.group_id)
    const project = allProjects.find(project => project.id == timeEntry.attributes.project_id)
    const user = allUsers.find(user => user.id == timeEntry.attributes.user_id)
    const task = allTasks.find(task => task.id == timeEntry.attributes.task_id)

    return {
      ...timeEntry,
      relationships: {
        ...timeEntry.relationships,
        group,
        project,
        user,
        task,
      }
    }
  })
}

export const { workerFn: timeEntriesWithRelationshipsWorker } = useWebWorkerFn(computeRelationships)
