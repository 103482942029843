import { GridApi, IRowNode, RefreshCellsParams, RowDragEvent } from "@ag-grid-community/core";
import { ref } from "vue";
import store from "@/store/index.js";
import { error } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";

import useCan from "@/modules/common/composables/useCan"
const { can, actions } = useCan()

export const potentialParent = ref<IRowNode<any> | null>(null);
export function onRowDragLeave(event: RowDragEvent) {
  setPotentialParentForNode(event.api, null);
}

function isValidMoveEvent(event: RowDragEvent) {
  if (event.node?.key?.startsWith('projects')) {
    return false
  }

  if (event.node?.key?.startsWith('folders') && !can(actions.EDIT_PROJECT_FOLDERS)) {
    return false
  }

  if (event.node?.key?.startsWith('media') && !can(actions.RENAME_PROJECT_FILES)) {
    return false
  }

  return true
}

export function onRowDragMove(event: RowDragEvent) {
  if (!isValidMoveEvent(event)) {
    return
  }

  setPotentialParentForNode(event.api, event.overNode);
}

function processFolderUpdateModel(folder: any, newParentFolderId: number | string | null, updatedFolders: any[]) {
  const updateModel = {
    id: folder.id,
    parent_folder_id: newParentFolderId,
    skipDbUpdate: folder.attributes.parent_folder_id == newParentFolderId,
    updated: {
      ...folder,
      attributes: {
        ...folder.attributes,
        parent_folder_id: newParentFolderId
      }
    }
  }

  updatedFolders.push(updateModel)
}

function processFileUpdateModel(file: any, newFolderId: number | string | null, updatedFiles: any[]) {
  const updateModel = {
    id: file.id,
    folder_id: newFolderId,
    skipDbUpdate: file.attributes.folder_id == newFolderId,
    updated: {
      ...file,
      attributes: {
        ...file.attributes,
        folder_id: newFolderId
      }
    }
  }

  updatedFiles.push(updateModel)
}

export async function onRowDragEnd(event: RowDragEvent) {
  if (!potentialParent.value) {
    return;
  }
  
  let targetProjectId
  if (!potentialParent.value.key) {
    targetProjectId = null
  }
  else {
    targetProjectId = potentialParent.value.key?.startsWith('projects')
      ? Number(potentialParent.value.key.replace('projects_', ''))
      : potentialParent.value.data.attributes.project_id
  }

  if (targetProjectId && event.node.data.attributes.project_id != targetProjectId) {
    error(i18n.t('Cannot move files or folders between projects.'))
    setPotentialParentForNode(event.api, null);
    return
  }

  const movingData = event.node.data;
  // Take new parent path from parent, if data is missing, means it's the root node,
  // which has no data.
  const newParentPath = potentialParent.value.data
    ? potentialParent.value.data.path
    : potentialParent.value.key?.startsWith('projects')
      ? [potentialParent.value.key]
      : [];

  const needToChangeParent = !arePathsEqual(
    newParentPath,
    movingData.path
  );

  // Check we are not moving a folder into a child folder
  const invalidMode = isSelectionParentOfTarget(event.node, potentialParent.value as IRowNode<any>);
  if (invalidMode || !needToChangeParent) {
    setPotentialParentForNode(event.api, null);
    return
  }

  const updatedRows: any[] = [];

  // Recursive function for updating root node and all children
  moveToPath(newParentPath, event.node, updatedRows);

  const updatedFiles: { id: number, folder_id: number | null, updated: any}[] = []
  const updatedFolders:  { id: number, parent_folder_id: number | null, updated: any}[] = []

  updatedRows.forEach(row => {
    const parentFolderPath = row.path[row.path.length - 2]
    const newFolderId = parentFolderPath?.startsWith('folders')
      ? Number(parentFolderPath.replace('folders_', ''))
      : null
      
    if (row.type === 'folders') {
      processFolderUpdateModel(row, newFolderId, updatedFolders)
    }
    else {
      processFileUpdateModel(row, newFolderId, updatedFiles)
    }
  })

  event.api.applyTransaction({
    update: updatedRows,
  });
  event.api.clearFocusedCell();
  // Clear node to highlight
  setPotentialParentForNode(event.api, null);


  if (updatedFolders.length) {
    await store.dispatch('files/bulkUpdateFolders', updatedFolders)
  }

  if (updatedFiles.length) {
    store.dispatch('files/bulkUpdateFiles', updatedFiles)
  }
}

// Helpers
function arePathsEqual(path1: string[], path2: string[]) {
  if (path1.length !== path2.length) {
    return false;
  }

  for (let i = 0; i < path1.length; i++) {
    if (path1[i] !== path2[i]) {
      return false;
    }
  }

  return true;
}

function isSelectionParentOfTarget(
  selectedNode: IRowNode<any>,
  targetNode: IRowNode<any>
) {
  const children = selectedNode.childrenAfterGroup || [];

  for (let i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) return true;
    isSelectionParentOfTarget(children[i], targetNode);
  }

  return false;
}

function moveToPath(newParentPath: string[], node: IRowNode<any>, allUpdatedNodes: any[]) {
  // Last part of the file path is the file key
  const oldPath = node.data.path;
  const fileKey = oldPath[oldPath.length - 1];
  const newChildPath = newParentPath.slice();

  newChildPath.push(fileKey);
  node.data.path = newChildPath;
  allUpdatedNodes.push(node.data);

  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function (childNode) {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
}

function getValidParentNode(node?: IRowNode | null) {
  // If over folder or project, we take the immediate row
  if (node?.key?.startsWith('folders') || node?.key?.startsWith('projects')) {
    return node
  }

  // If over a file, we take the parent row if it's a folder or project
  if (node?.parent?.key?.startsWith('folders') || node?.parent?.key?.startsWith('projects')) {
    return node.parent
  }

  return null
}

function setPotentialParentForNode(
  api: GridApi,
  overNode?: IRowNode | null
) {
  const newPotentialParent: IRowNode | null = getValidParentNode(overNode)
  const alreadySelected = potentialParent.value === newPotentialParent;
  if (alreadySelected) {
    return
  }

  // We refresh the previous selection (if it exists) to clear
  // the highlighted and then the new selection.
  const rowsToRefresh = [];
  if (potentialParent.value) {
    rowsToRefresh.push(potentialParent.value);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent.value = newPotentialParent;
  refreshRows(api, rowsToRefresh);
};

function refreshRows(api: GridApi, rowsToRefresh: any[]) {
  const params: RefreshCellsParams = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};
