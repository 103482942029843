import i18n from "@/i18n";
import { AccountPermissions } from "@/modules/common/composables/useCan";

export const PaymentTypes = {
  OneTime: 'one-time',
  Recurring: 'recurring',
}
export const paymentTypeOptions = [
  {
    label: i18n.t('One Time Payment'),
    value: PaymentTypes.OneTime,
  },
  {
    label: i18n.t('Recurring Payment'),
    value: PaymentTypes.Recurring,
    limitedFeature: AccountPermissions.RecurringPayments
  }
]
