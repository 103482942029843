<template>
  <BaseEntityForm
    :title="$t('Simulate roles & permissions')"
    width-class="max-w-3xl"
  >
    <template #default="{ meta, errors }">
      <div class="text-sm text-gray-500 space-y-4">
        <div
          v-for="paragraph in paragraphs"
          :key="paragraph"
        >
          {{ paragraph }}
        </div>

        <BaseInput
          :label="$t('Role to simulate')"
          :placeholder="$t('Choose role...')"
          :name="$t('Role to simulate')"
          layout="horizontal"
          id="role-to-simulate"
        >
          <UserRoleSelect
            v-model="roleModel"
            v-focus
            class="w-full"
            :excludedOptions="[rolesEnum.CREATOR_ADMIN]"
            @raw-change="roleObject = $event"
          />
        </BaseInput>

        <div>
          <span class="font-bold">
            {{ $t('Top tip: ') }}
          </span>
          &nbsp;
          <span>
            {{ $t('Before you begin, check the privacy setting on your projects and tasks so you know what you can expect to see when you change roles.')  }}
          </span>
        </div>
        <div>
          <BaseTutorialLink
            name="simulating-roles"
          >
            <span class="text-primary-500 underline">
              {{ $t(`Read more about role simulation here`) }}
            </span>
          </BaseTutorialLink>
        </div>
        <div>
          <BaseTutorialLink
            name="roles-and-permissions"
          >
            <span class="text-primary-500 underline">
              {{ $t(`Read more about user roles and permissions here`) }}
            </span>
          </BaseTutorialLink>
        </div>
      </div>
    </template>
    <template #actions="{ meta, errors }">
      <BaseButton
        variant="white"
        @click="$router.go(-1)"
      >
        {{ $t('Cancel') }}
      </BaseButton>
      <BaseButton
        class="ml-2"
        :disabled="!roleModel"
        @click="onSimulate"
      >
        {{ $t('Simulate') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script lang="ts" setup>
// Components
import UserRoleSelect from "@/components/selects/UserRoleSelect.vue";

// Utils
import i18n from "@/i18n.js"
import { computed, nextTick, ref, onBeforeMount } from "vue"
import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";

// Composables
import { useStore } from 'vuex'

const store = useStore()

const paragraphs = [
  i18n.t(`Switch to a different role temporarily so you can see how things look for users with different roles.`),
  i18n.t(`When you choose a role to simulate your page will refresh and you'll see things as if you had that role.`),
  i18n.t(`You'll see a banner at the top of the page to remind you which role you are simulating and it will also give you the ability to stop the simulation and revert to your Creator Admin role at any time.`),
  i18n.t(`This is great for testing what each role has access to do within your account without the need to sign up with different user accounts.`), 
]

const roleModel = ref(null)
const roleObject = ref<any>(null)

const role = computed(() => {
  return roleObject.value?.attributes
})

async function onSimulate() {
  if (!role.value) {
    return
  }

  store.commit('users/setSimulateRole', role.value)
  await nextTick()
  window.location.href = '/welcome'
}

onBeforeMount(async () => {
  await store.dispatch('users/getRoles')
})
</script>
