<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <BaseDialog v-bind="$attrs"
              :hasBodyMargin="false"
              :appendToBody="true"
              size="lg"
              @close="$emit('close', variant)"
  >
    <div class="px-5 py-6">
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full"
           :class="{
              'bg-green-100': variant === 'primary',
              'bg-red-100': variant === 'error',
           }"
      >
        <CheckIcon v-if="variant === 'primary'" class="h-6 w-6 text-green-600" aria-hidden="true"/>
        <XIcon v-else class="h-6 w-6 text-red-600" aria-hidden="true"/>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ title }}
        </h3>
        <div class="mt-2">
          <p class="text-sm"
             :class="{
                'text-gray-500': variant === 'primary',
                'text-red-500': variant === 'error',
             }"
          >
            {{ displayedMessage }}
          </p>
        </div>
      </div>
      <div class="mt-5 sm:mt-6 mb-4 flex justify-center">
        <BaseButton
          :variant="variant"
          @click="$emit('close', variant)"
        >
          <template v-if="variant === 'primary'">
            {{ $t('Back to payments') }}
          </template>
          <template v-if="variant === 'error'">
            {{ $t('Retry payment') }}
          </template>
        </BaseButton>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { DialogTitle } from '@headlessui/vue'
import { CheckIcon, XIcon } from '@heroicons/vue/outline'
import BaseButton from "@/components/common/BaseButton.vue";
import i18n from "@/i18n";

export default {
  name: 'PaymentConfirmationDialog',
  components: {
    BaseButton,
    DialogTitle,
    CheckIcon,
    XIcon,
  },
  props: {
    variant: {
      type: String,
      default: 'primary', // primary|error
    },
    message: {
      type: String,
      default: ''
    }
  },
  emits: ['save', 'close'],
  computed: {
    title() {
      if (this.variant === 'primary') {
        return i18n.t('Payment successful')
      }
      return i18n.t('Payment failed')
    },
    displayedMessage() {
      if (!this.message && this.variant === 'primary') {
        return i18n.t('Payment was made successfully!')
      }
      return this.message
    }
  }
}
</script>