import { groupBy } from 'lodash-es'
import { decodeEmoji } from "@/modules/common/utils/emojiUtils.js";
import i18n from "@/i18n.js";

export function getNotificationsReactionsMessage(reactions) {
  const groupedReactions = groupBy(reactions, 'emoji')
  let message = ``
  for (let reaction in groupedReactions) {
    const groupReactions = groupedReactions[reaction]
    const usersList = groupReactions.map(r => r?.user_details?.name)
    const userListString = getPrettyUserList(usersList)
    
    const emoji = decodeEmoji(reaction)
    message += `${userListString} <span class="text-gray-400">${i18n.t('reacted with')}</span> ${emoji} <br>`
  }
  
  return message
}

function getPrettyUserList(users, limit = 5) {
  const difference = users.length - limit
  users.slice(limit)
  const joinedUsers = users.join(', ')
  
  if (difference < 1) {
    return joinedUsers
  } else if (difference === 1) {
    return `${joinedUsers} ${i18n.t('and 1 other')}`
  } else {
    return `${joinedUsers} ${i18n.t(`and ${difference} others`)}`
  }
}
