<template>
  <div class="resource-cell">
    <GroupByTitle
      v-if="!noTasks && !noGroups"
      target="tasks"
      :data="tasks"
      :show-column-name="false"
      is-calendar-resource
      class="max-w-full min-w-full truncate cell-group-by"
      projectNameProp="relationships.project.attributes.name"
      allocationsProp="relationships.allocations"
    />
    <!-- Intentional no $t because it throws a strange error -->
    <div v-else-if="noGroups" class="font-medium">
      No group by selected
    </div>
    <div v-else class="font-medium">
      No tasks found
    </div>
  </div>
</template>
<script>
import store from "@/store/index.js";
import { i18n } from "@/i18n.js";
import router from "@/router/index.js";
import globalPlugins from "@/plugins/globalPlugins.js";
import globalComponents from "@/plugins/globalComponents.js";
import GroupByTitle from "@/modules/filters/components/GroupByTitle.vue";

export default {
  components: {
    GroupByTitle,
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  computed: {
    tasks() {
      return this.resource?.extendedProps?.tasks || []
    },
    noTasks() {
      return this.resource?.title === 'default'
    },
    noGroups() {
      return this.resource?.title === 'no-groups'
    }
  },
  beforeCreate() {
    /**
     * This is a hack to make sure the resource component uses our plugins.
     * The custom slots from full calendar don't share the app context and therefore hve no store, router etc by default. 
     */
    this.$.appContext.app
      .use(store)
      .use(router)
      .use(i18n)
      .use(globalPlugins)
      .use(globalComponents)
  }
}
</script>
<style lang="scss">
.resource-cell {
  @apply flex h-full items-center;
  .group-title {
    @apply text-base my-0 font-medium;
  }
  
  .no-groups,
  .no-allocations {
    @apply text-base;
  }

  .groups-list,
  .allocations-list {
    @apply flex flex-col space-y-2 space-x-0;
  }
  .group-title,
  .allocation-title {
    @apply flex;
  }
  .group-by-text {
    @apply flex-1;
  }
  
  .cell-group-by {
    @apply bg-transparent shadow-none border-none;
  }
}
</style>
