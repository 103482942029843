<template>
  <BaseForm
    @submit="onNext"
  >
    <template #default="{ handleSubmit, meta, errors }">
      <div class="space-y-4">
        <h2 class="text-gray-900 text-3xl sm:text-4xl font-extrabold">
          {{ i18n.t(`Add your tasks`) }}
        </h2>
        <p class="text-gray-500 leading-7 text-lg">
          {{ i18n.t(`Break your project down into easily managed tasks. Add 3 as a starting point.`)}}
        </p>
      </div>
      <div>
        <div class="relative mt-2 flex items-center">
          <BaseInput
            v-model="taskName1"
            @input="onInput(1, taskName1)"
            type="text"
            :name="$t('first task name')"
            :id="$t('first task name')"
            rules="required"
            class="w-full text-gray-500 placeholder:text-gray-400 leading-6 shadow-none text-base"
            inputClass="p-4"
            :placeholder="$t('Task name...')"
          />
        </div>
        <div class="relative mt-2 flex items-center">
          <BaseInput
            v-model="taskName2"
            @input="onInput(2, taskName2)"
            type="text"
            name="task-name-2"
            id="task-name-2"
            class="w-full text-gray-500 placeholder:text-gray-400 leading-6 shadow-none text-base"
            inputClass="p-4"
            :placeholder="$t('Task name...')"
          />
        </div>
        <div class="relative mt-2 flex items-center">
          <BaseInput
            v-model="taskName3"
            @input="onInput(3, taskName3)"
            type="text"
            name="task-name-3"
            id="task-name-3"
            class="w-full text-gray-500 placeholder:text-gray-400 leading-6 shadow-none text-base"
            inputClass="p-4"
            :placeholder="$t('Task name...')"
          />
        </div>
      </div>
      <p
        class="text-gray-400 leading-7 text-sm mt-4"
      >
        <i class="fa-solid fa-info-circle mr-1 text-[#5720b460]" aria-hidden="true" />
        {{ $t(`Note: You can edit these tasks from the tasks page of the project.`) }}
      </p>
      <div class="flex space-x-4 items-center mt-4">
        <router-link
          to="/onboarding/add-project-tools"
          class="rounded-md bg-white px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-gray-500 border border-gray-200"
        >
          <i class="fa-solid fa-arrow-left mr-2" aria-hidden="true"></i>
          {{ $t(`Back`)  }}
        </router-link>
        <button
          type="submit"
          class="rounded-md body-background px-6 py-4 text-sm font-semibold text-white"
          :class="{
            'opacity-80 cursor-not-allowed': !meta.valid,
            'transition-all hover:px-8': meta.valid
          }"
          :disabled="!meta.valid"
        >
          {{ i18n.t('Next') }}
          <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
        </button>
      </div>
    </template>
  </BaseForm>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import i18n from '@/i18n'
import { taskDateTypes } from '@/modules/tasks/utils/modelUtils';
import { getDateWithoutTime } from '@/modules/common/utils/dateUtils';

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const onboardingModel = computed({
  get() {
    return store.state.accounts.onboardingModel
  },
  set(value) {
    store.commit('accounts/setOnboardingModel', value)
  }
})

const tasksToAdd = computed(() => {
  return onboardingModel.value.tasksToAdd || {}
})

const taskName1 = ref(tasksToAdd.value?.[1]?.name || '');
const taskName2 = ref(tasksToAdd.value?.[2]?.name || '');
const taskName3 = ref(tasksToAdd.value?.[3]?.name || '');

function onNext() {
  router.push('/onboarding/setting-up-account')
}

function onInput(index: number, name: string) {

  const start_date = new Date()
  start_date.setDate(start_date.getDate() + (index - 1))

  const end_date = new Date(start_date)
  end_date.setDate(end_date.getDate() + 1)

  onboardingModel.value = {
    ...onboardingModel.value,
    tasksToAdd: {
      ...onboardingModel.value.tasksToAdd,
      [index]: {
        name,
        date_type: taskDateTypes.DATE_RANGE,
        start_date: getDateWithoutTime(start_date),
        end_date: getDateWithoutTime(end_date),
      }
    }
  }
}
</script>
