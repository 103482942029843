<template>
  <div
    ref="root"
    class="relative bg-white p-4 border rounded-lg group overflow-x-hidden items-center entity-card w-full h-fit kanban-card cursor-pointer"
    @click="openTemplateDialog"
  >
    <div
      v-if="coverImageSrc"
      class="rounded-t-lg overflow-hidden -mt-4 -mx-4 relative"
    >
      <img
        :src="coverImageSrc"
        class="w-full object-cover object-center h-[100px]"
      />
    </div>
    <div class="space-y-2 mt-4">
      <div
        class="font-bold text-gray-900 text-xl leading-5 inline-flex"
      >
        {{ props.template.attributes.name }}
      </div>
      <div
        class="text-sm line-clamp-3 text-gray-500"
        v-html="text"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType, computed } from 'vue'
import useEntityCrud from "@/modules/common/composables/useEntityCrud"

import Data = API.Data;
import GalleryTemplate = App.Domains.Templates.Models.GalleryTemplate

const {
  triggerEntityCreate,
  entityTypes
} = useEntityCrud()

const props = defineProps({
  template: {
    type: Object as PropType<Data<GalleryTemplate>>,
    required: true
  }
})

const coverImageSrc = computed(() => {
  return props.template.attributes.featured_image
})

const text = computed(() => {
  return props.template.attributes.featured_text
})

function openTemplateDialog() {
  triggerEntityCreate(entityTypes.TemplateDialog, {
    template: props.template
  })
}
</script>
