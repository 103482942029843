<template>
  <div
    v-if="!isMobileDevice && $useNewLayout"
    class="flex whitespace-nowrap"
  >
    <EntityViewTypesTabs
      v-if="currentEntityAvailableViewTypes.length"
      :viewTypes="currentEntityAvailableViewTypes"
      :withBorder="withBorder"
      :class="{
        'max-w-fit': currentEntityAvailableViewTypes.length && currentAvailableViewTypes.length
      }"
    />

    <div
      v-if="currentEntityAvailableViewTypes.length && currentAvailableViewTypes.length"
      class="mx-4 border-l border-gray-200"
    />

    <EntityViewTypesTabs
      v-if="currentAvailableViewTypes.length"
      :viewTypes="currentAvailableViewTypes"
      :withBorder="withBorder"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import EntityViewTypesTabs from '@/modules/common/components/EntityViewTypesTabs.vue'

// Composables
import { useEntityViewTypes } from "@/modules/dashboard/utils/useEntityViewTypes"
import useMobileUtils from "@/modules/common/composables/useMobileUtils"

const props = defineProps({
  withBorder: {
    type: Boolean,
    default: true,
  },
})

const {
  currentAvailableViewTypes,
  currentEntityAvailableViewTypes
} = useEntityViewTypes()

const {
  isMobileDevice,
} = useMobileUtils()
</script>
