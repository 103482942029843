import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji'
import emojiSuggestions from "./emojiSuggestions.js";

const EmojiExtension = Emoji.configure({
  emojis: gitHubEmojis,
  enableEmoticons: true,
  suggestion: emojiSuggestions,
})

export default EmojiExtension
