<template>
  <ErrorAlert
    v-if="!isSubscriptionValid"
    :dismissable="false"
  >
    <p v-html="subscriptionInvalidMessage" />
    
    <p>
      {{ $t(`You won't be able to use any features until the subscription is activated.`) }}
      <router-link
        v-if="isCreatorAdmin"
        to="/account-settings/billing"
      >
        <span class="underline">
          {{ $t('Click here to choose a billing plan.') }}
        </span>
      </router-link>
      <template v-else>
        {{ $t('Contact the account owner for more details.') }}
      </template>
    </p>
  </ErrorAlert>
</template>
<script>
import ErrorAlert from "@/components/common/ErrorAlert.vue";

export default {
  components: {
    ErrorAlert,
  },
  computed: {
    subscriptionInvalidMessage() {
      return this.$store.getters['accounts/subscriptionInvalidMessage']
    },
    isCreatorAdmin() {
      return this.isRoleGreaterOrEqual(this.$roles.CREATOR_ADMIN)
    },
  }
}
</script>
