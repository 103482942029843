<template>
  <li>
    <div class="flex items-center space-x-4">
      <div class="shrink-0">
        <BaseLogo :entity="group"/>
      </div>
      <div class="flex-1 min-w-0">
        <p class="font-bold text-gray-700 truncate">
          {{ group?.attributes?.name }}
        </p>
      </div>
      
      <BaseDropdown
        :options="groupOptions"
        :label="$t('Options')"
        trigger="hover"
        button-size="sm"
        divide-items
      />

    </div>
  </li>
</template>
<script lang="ts" setup>
import i18n from '@/i18n';
import { computed, PropType } from 'vue'
import actionsEnum from "@/modules/common/enum/actionsEnum";

import Data = API.Data;
import Group = App.Domains.Groups.Models.Group;

const props = defineProps({
  group: {
    type: Object as PropType<Data<Group>>,
    default: () => ({})
  },
})

const emit = defineEmits(['remove'])

const groupOptions = computed(() => {
  return [
    {
      label: i18n.t('Remove group'),
      requiresPermissionTo: actionsEnum.REMOVE_PEOPLE_FROM_ANY_GROUP,
      action: () => {
        emit('remove', props.group)
      },
    }
  ]
})
</script>
