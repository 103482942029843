<template>
  <el-select
    ref="select"
    v-model="selected"
    :placeholder="selected ? ' ' : placeholder"
    class="base-select cursor-pointer sm:col-span-4"
    :class="{
      'filterable-select': filterable,
      'inline-select': inline,
      'w-full': !inline && !$attrs.class?.includes('w-'),
      'multiple': multiple
    }"
    :multiple="multiple"
    :clearable="clearable"
    :filterable="filterable"
    :valueKey="valueKey"
    :popper-class="`base-select-popper ${popperClass}`"
  >
    <template #prefix>
      <slot name="prefix" :selected="selected">
        <template v-if="!filterable && selected">
          <div
            v-if="multiple"
            class="flex gap-2 items-center whitespace-nowrap w-full flex-wrap mt-2 mb-1"
          >
            <template
              v-for="selectedOption of selected"
              :key="selectedOption.value"
            >
              <div
                class="flex items-center border border-gray-300 rounded p-1 px-2"
              >
                <div
                  v-if="selectWithColor"
                  class="w-3 h-3 rounded-md mr-2"
                  :style="{
                    backgroundColor: selectedOption.value,
                  }"
                />
                <div
                  class="text-xs"
                  :class="{
                    'capitalize': capitalize,
                    'text-gray-400': inline,
                    'text-gray-900': !inline,
                  }"
                >
                  {{ selectedOption.label }}
                </div>
                <XCircleIcon
                  class="circle-remove h-4 w-4 text-red-200 hover:text-red-400 cursor-pointer ml-2"
                  aria-hidden="true"
                  @click.stop="removeOption(selectedOption)"
                />
              </div>
            </template>
          </div>
          <div
            v-else
            :class="{
              'text-gray-900': !inline,
              'text-gray-400 text-xs': inline,
            }"
            class="flex items-center h-full"
          >
            <div
              v-if="selectWithColor"
              class="w-3 h-3 rounded-md mr-2"
              :style="{ background: selected.value }"
            />
            <div :class="{ 'capitalize': capitalize }">
              {{ displayLabel }}
            </div>
          </div>
        </template>
      </slot>
    </template>
    <template #empty>
      <slot name="empty"></slot>
    </template>
    <el-option
      v-for="(option, index) in selectOptions"
      :key="option.value"
      :value="option"
      :label="option.label"
      :disabled="option.disabled"
      :class="optionClass"
    >
      <slot :option="option" :index="index">
        <div class="flex items-center" @enter.stop="selected = option">
          <div
            v-if="selectWithColor"
            class="w-4 h-4 rounded-full mr-2 border-2 border-white"
            :style="{ backgroundColor: option.value }"
          />
          <div :class="{ 'capitalize': capitalize }">
            {{ option.label }}
          </div>
        </div>
      </slot>
    </el-option>
    <slot name="after-options"></slot>
  </el-select>
</template>

<script>
import { ElSelect, ElOption } from "element-plus";
import { XCircleIcon } from "@heroicons/vue/outline";
import { isEqual } from "lodash-es";

export default {
  components: {
    ElSelect,
    ElOption,
    XCircleIcon,
  },
  props: {
    modelValue: {
      type: [Object, String, Number],
      default: () => undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    excludedOptions: {
      type: Array,
      default: () => [],
    },
    selectWithColor: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false,
    },
    popperClass: {
      type: String,
      default: ''
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    optionClass: {
      type: String,
      default: 'hover:bg-primary-500 hover:text-white'
    },
    labelPrefix: {
      type: String,
      default: ''
    }
  },
  computed: {
    selectOptions() {
      if (!this.options) {
        return []
      }
      if (!this.excludedOptions || !this.excludedOptions?.length) {
        return this.options;
      }

      return this.options.filter(
        (option) =>
          this.excludedOptions.findIndex(
            (excludedOption) => excludedOption?.value === option.value || excludedOption === option.value
          ) === -1
      );
    },
    selected: {
      get() {
        let options = this.options || []
        if (!this.returnObject) {
          return options.find(option => this.get(option, this.valueKey) === this.modelValue)
        }

        if (this.multiple) {
          if (this.modelValue && typeof this.modelValue[0] !== 'object') {
            return this.modelValue.map(x => {
              return options.find(opt => opt.id === x) 
            })
          }
          return this.modelValue
        }

        if (this.modelValue && typeof this.modelValue !== 'object' || Array.isArray(this.modelValue)) {
          return options.find(opt => {
            if (opt.id === this.modelValue) {
              return true
            }

            if (Array.isArray(this.modelValue) && isEqual(this.modelValue, opt.value)) {
              return true
            }

            if (typeof opt.id !== typeof this.modelValue) {
              return opt.value === this.modelValue
            }

            return false
          })
        }

        return this.modelValue        
      },
      set(newSelected) {
        if (this.multiple && !newSelected?.length) {
          this.$emit('update:modelValue', null)
          return
        }

        if (this.returnObject) {
          this.$emit('update:modelValue', newSelected)
          this.$emit('update:objectValue', newSelected)
          return
        }

        const value = this.get(newSelected, this.valueKey)
        this.$emit('update:modelValue', value)
      },
    },
    displayLabel() {
      if (this.labelPrefix) {
        return `${this.labelPrefix} ${this.selected?.label || ''}`
      }

      return this.selected?.label || ''
    }
  },
  methods: {
    removeOption(option) {
      
      let key = this.valueKey || 'value'
      this.selected = this.selected.filter((x) => x[key] !== option[key]);
    },
    async tryScrollToBottom(newValue, oldValue) {
      const shouldScroll =
        this.multiple && this.$el && newValue?.length > oldValue?.length;

      if (!shouldScroll) {
        return;
      }

      await this.$nextTick();

      const prefix = this.$el.querySelector(".el-input__prefix");
      prefix.scrollTo(0, prefix.scrollHeight);
    },
    syncModel() {
      if (this.selected === undefined) {
        return
      }
      if (this.returnObject) {
        this.$emit('update:modelValue', this.selected)
        this.$emit('update:objectValue', this.selected)
        return
      }
      const value = this.get(this.selected, this.valueKey)
      
      if (value === this.modelValue) {
        return
      }
      this.$emit('update:modelValue', value)
    },
    blur() {
      this.$refs.select.blur();
    }
  },
  watch: {
    selected(newValue, oldValue) {
      this.tryScrollToBottom(newValue, oldValue);
    },
  },
  created() {
    this.syncModel()
  }
};
</script>
<style lang="scss">
.base-select {
  &.shadow-none {
    input, .el-input__wrapper {
      box-shadow: none !important;
    }
  }

  &.text-base { 
    input {
      font-size: 1rem/* 16px */;
      line-height: 1.5rem/* 24px */;
    }
  }

  &.two-line {
    min-height: 80px;
    .el-input__wrapper {
      min-height: 80px;
    }
  }

  .el-input {
    line-height: initial;
  }

  .el-input__prefix {
    left: 13px;

    overflow-y: auto;
    overflow-x: hidden;

    > div {
      display: initial !important;
    }
  }

  .el-select__tags {
    height: 100%;
    display: none;
  }

  &:not(.filterable-select) {
    .el-input__inner {
      color: transparent !important;
    }
  }
}
</style>
