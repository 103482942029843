<template>
  <div
    class="relative inline-input custom-field-inline-input table-inline-edit-input"
    :class="{
      'is-disabled': $attrs.disabled
    }"
  >
    <CustomFieldInput
      v-if="isSelectFieldType"
      v-model="model"
      @update:modelValue="saveValue"
      v-focus
      :custom-field="customField"
      :disabled="$attrs.disabled"
      :hideLabel="true"
      :inline="true"
      :class="$attrs.class"
      :filterable="true"
    >
      <template #select-prefix>
        <CustomFieldValue
          :model="customFieldValueModel"
          :custom-field="customField"
          show-label-icon
        />
      </template>
    </CustomFieldInput>
    <div
      v-else
      class="flex w-full custom-field-value p-1"
      :class="{
        'cursor-pointer': !$attrs.disabled,
      }"
    >
      <!-- NUMERIC/PERCENT CF -->
      <BasePopoverInput
        v-if="isNumericType"
        :disabled="$attrs.disabled"
        :autoShow="true"
        popoverVisibility="focus"
      >
        <template #reference>
          <CustomFieldValue
            :model="customFieldValueModel"
            :custom-field="customField"
            show-label-icon
          />
        </template>
        <BaseInlineTextEdit
          v-model="model"
          v-focus
          type="number"
          :placeholder="placeholder"
          class="form-input"
        />
      </BasePopoverInput>
      <!-- TEXTAREA CF -->
      <div
        v-else-if="customFieldType === customFieldTypes.Textarea"
        class="whitespace-nowrap overflow-hidden overflow-ellipsis"
      >
        <BasePopoverInput
          :disabled="$attrs.disabled && !model"
          :autoShow="true"
          popoverVisibility="focus"
        >
          <template #reference>
            <CustomFieldValue
              :model="customFieldValueModel"
              :custom-field="customField"
              :max-chars="20"
              show-label-icon
            />
          </template>
          <div class="p-1 w-60">
            <BaseInlineTextareaEdit
              v-model="model"
              v-focus
              :name="customField.attributes.name"
              :label="customField.attributes.name"
              class="form-input resize-none"
              :placeholder="placeholder"
              :disabled="$attrs.disabled"
              rows="5"
            />
          </div>
        </BasePopoverInput>
      </div>
      <!-- TEXT CF -->
      <BaseInlineTextEdit
        v-else-if="customFieldType === customFieldTypes.Text"
        v-model="model"
        :placeholder="placeholder"
        :disabled="$attrs.disabled"
        autoFocus
        class="bg-transparent"
      />

      <!-- CURRENCY CF -->
      <BasePopoverInput
        v-else-if="customFieldType === customFieldTypes.Currency"
        focusElementSelector=".currency-value-input"
        :disabled="$attrs.disabled"
        :autoShow="true"
        popoverVisibility="focus"
      >
        <template #reference>
          <CustomFieldValue
            :model="customFieldValueModel"
            :custom-field="customField"
            show-label-icon
          />
        </template>
        <CurrencyInlineInput
          v-model="model"
          :currencyOptions="customField.attributes.options"
        />
      </BasePopoverInput>
      
       <!-- DATE CF -->
      <BaseDatePicker
        v-else-if="customFieldType === customFieldTypes.Date"
        v-model="model"
        v-focus
        :disabled="$attrs.disabled"
        :placeholder="placeholder"
        class="inline inline-date-picker disabled-focus"
      />

      <div v-else>
        ERR: NOT DEFINED
      </div>
    </div>
    <XCircleIcon
      v-if="model && !$attrs.disabled && !isRequired"
      class="rounded-full circle-remove hidden ml-1 h-4 w-4 cursor-pointer text-red-300 hover:text-red-500 absolute top-1/2 -translate-y-1/2 -left-2 bg-white z-40 overflow-visible"
      aria-hidden="true"
      @click="model = null"
    />
  </div>
</template>
<script lang="ts">
// Components
import CustomFieldInput from "@/modules/common/components/CustomFieldInput.vue";
import CustomFieldValue from "@/modules/common/components/CustomFieldValue.vue";
import CurrencyInlineInput from "@/modules/common/components/CurrencyInlineInput.vue";
// @ts-ignore
import { XCircleIcon } from '@heroicons/vue/outline'

// Utils
import { customFieldTypes } from "@/modules/accounts/utils/modelUtils";
import { ICellEditorParams } from "@ag-grid-community/core";
import { PropType, defineComponent } from "vue";
import { TableColumn, stopCellEditing } from "@/components/table/tableUtils";
import { set } from "lodash-es";

export default defineComponent({
  components: {
    CustomFieldInput,
    CustomFieldValue,
    CurrencyInlineInput,
    XCircleIcon,
  },
  props: {
    params: {
      type: Object as PropType<ICellEditorParams<any>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: null,
      modelInitialised: false,
      placeholder: '- -',
      customFieldTypes,
    }
  },
  computed: {
    row() {
      if (!this.params?.data?.attributes?.custom_fields) {
        set(this.params?.data, 'attributes.custom_fields', {})    
      }

      return this.params?.data
    },
    customField() {
      const colDef = this.params.column.getColDef() as TableColumn
      return colDef.customField
    },
    customFieldValues() {
      const values = this.row.attributes?.custom_fields

      if (typeof values === 'string') {
        return JSON.parse(values)
      }

      return values
    },
    customFieldKey() {
      return this.customField.attributes.key
    },
    customFieldType() {
      return this.customField.attributes.custom_field_type
    },
    customFieldValueModel() {
      return {
        attributes: {
          custom_fields: {
            ...this.customFieldValues,
            [this.customFieldKey]: this.model
          }
        }
      }
    },
    isRequired() {
      return this.customField.attributes.rules?.includes('required')
    },
    isNumericType() {
      const numericTypes = [
        customFieldTypes.Numeric,
        customFieldTypes.Percent,
      ]

      return numericTypes.includes(this.customFieldType)
    },
    isSelectFieldType() {
      const selectFieldTypes = [
        customFieldTypes.Select,
        customFieldTypes.Multiselect,
        customFieldTypes.SelectColor,
      ];
      return selectFieldTypes.includes(this.customFieldType);
    },
    isMultiselect() {
      return this.customFieldType === customFieldTypes.Multiselect
    }
  },
  methods: {
    async initModel() {
      this.model = this.customFieldValues[this.customFieldKey]

      await this.$nextTick()
      this.modelInitialised = true
    },
    getValue() {
      return this.model
    },
    saveValue() {
      if (!this.modelInitialised || this.customFieldType === customFieldTypes.Multiselect) {
        return
      }
      
      stopCellEditing(this.params)
    },
    async tryFocusMultiselect() {
      if (!this.isMultiselect) {
        return
      }

      await this.$nextTick()

      const elInput = this.$el.querySelector('input.el-input__inner')
      elInput?.focus()
    }
  },
  created() {
    this.initModel()
  },
  mounted() {
    this.tryFocusMultiselect()
  }
})
</script>
