<template>
  <div
    class="align-middle overflow-auto flex-col-grow h-full
      print:max-w-full print:rounded-none print:min-h-screen
      print:-mt-10 print:shadow-none print:border-none
    "
    :class="{
      'bg-white border border-gray-200 shadow max-w-3xl m-auto rounded-lg': !$useNewLayout,
      'bg-gray-100 py-6': $useNewLayout,
    }"
  >
    <div
      v-loading="invoiceLoading"
      class="w-full py-6 print:pt-2 max-w-3xl mx-auto"
      :class="{
        'border border-gray-200 bg-white': $useNewLayout
      }"
    >
      <div
        ref="pdf-root"
        class="px-10"
      >
        <div>
          <p class="mt-2 text-4xl font-extrabold sm:text-5xl">
            {{ $t('Invoice') }}
            <PaymentStatus :status="payment?.payment_status"/>
          </p>
          <div class="flex justify-between">
            <div>
              <p class="mt-4 text-base text-gray-500">{{ $t('Project:') }} {{ project?.name || '- -' }}</p>
              <p class="mt-2 text-base text-gray-500">{{ $t('Invoice #:') }} {{ invoice?.invoice_number || '- -' }}</p>
              <p class="mt-2 text-base text-gray-500">{{ $t('Payment Ref:') }} {{ invoice?.payment_reference || '- -' }}</p>
              <p class="mt-2 text-base text-gray-500">{{ $t('Payment Date:') }} {{ $formatDate(invoice?.payment_date) }}</p>
            </div>
            <div id="invoice-logo-container">
              <img
                v-if="invoiceInfo?.invoice_logo"
                :src="invoiceInfo?.invoice_logo"
                id="invoice-logo"
                alt="company logo"
                class="h-8"
              >
            </div>
          </div>
        </div>
        <section
          aria-labelledby="order-heading"
          class="mt-10 print:mt-6 border-t border-gray-200"
        >
          <h2 id="order-heading" class="sr-only">{{ $t('Your order') }}</h2>
          <h3 class="sr-only">{{ $t('Items') }}</h3>
          <div>
            <h3 class="sr-only">{{ $t('Your information') }}</h3>
            <h4 class="sr-only">{{ $t('Addresses') }}</h4>
            <dl class="grid grid-cols-1 md:grid-cols-3 gap-6 text-sm py-6 print:py-6">
              <div>
                <dt class="font-bold text-gray-900">{{ $t('Payment From') }}</dt>
                <dd class="mt-2 text-gray-700 word-break">
                  <pre
                    v-html="paymentFrom"
                    class="mt-2 text-sm text-gray-700 font-sans block"
                  />
                </dd>
              </div>
              <div>
                <dt class="font-bold text-gray-900">{{ $t('Payment To') }}</dt>
                <dd class="mt-2 text-gray-700">
                  <address class="not-italic">
                    <span v-if="paymentTo.street_address_1" class="block">
                      {{ paymentTo.street_address_1 }}
                    </span>
                    <span v-if="paymentTo.street_address_2" class="block">
                      {{ paymentTo.street_address_2 }}
                    </span>
                    <span v-if="paymentTo.city" class="block">
                      {{ paymentTo.city }}
                    </span>
                    <span v-if="paymentTo.region" class="block">
                      {{ paymentTo.region }}
                    </span>
                    <span v-if="paymentTo.post_code" class="block">
                      {{ paymentTo.post_code }}
                    </span>
                    <span v-if="paymentTo.country" class="block">
                      {{ paymentTo.country }}
                    </span>
                  </address>
                </dd>
              </div>
              <div>
                <dt class="font-bold text-gray-900">{{ $t('Payment Method') }}</dt>
                <dd class="mt-2 text-gray-700">
                  <address class="not-italic">
                    <address class="not-italic">
                      <span
                        v-if="paymentMethod?.provider"
                        class="capitalize block"
                      >
                        {{ paymentMethod?.provider }}
                      </span>
                      <span
                        v-if="paymentMethod?.card_brand"
                        class="capitalize block"
                      >
                        {{ paymentMethod?.card_brand }}
                      </span>
                      <span
                        v-if="paymentMethod?.last_four"
                        class="capitalize block"
                      >
                        <span aria-hidden="true">•••• </span>
                        <span class="sr-only">{{ $t('Ending in ') }}</span>
                        {{ paymentMethod?.last_four }}
                      </span>
                    </address>
                  </address>
                </dd>
              </div>
            </dl>
            <div class="py-6 print:py-4 border-t border-gray-200 flex space-x-6">
              <div class="flex-auto flex flex-col">
                <div>
                  <h4 class="font-bold text-gray-900">
                    {{ $t('Item Description') }}
                  </h4>
                  <p class="mt-2 text-sm text-gray-600">
                    {{ payment?.description || '- -' }}
                  </p>
                </div>
              </div>
            </div>
            <h3 class="sr-only">{{ $t('Summary') }}</h3>
            <dl v-if="payment"
                class="space-y-6 border-t border-gray-200 text-sm pt-6 print:pt-4 print:space-y-3">
              <div class="flex justify-between">
                <dt class="font-bold text-gray-900">{{ $t('Subtotal') }}</dt>
                <dd class="text-gray-700">
                  {{ $formatPrice(payment?.amount, { currencyCode: payment?.currency, }) }}
                </dd>
              </div>
              <div class="flex justify-between">
                <dt class="font-bold text-gray-900">{{ $t('Tax') }} {{ taxPercentage }}</dt>
                <dd class="text-gray-700">
                  {{
                    $formatPrice(taxAmount, {
                      currencyCode: payment?.currency
                    })
                  }}
                </dd>
              </div>
              <div class="flex justify-between">
                <dt class="font-bold text-gray-900">{{ $t('Total') }}</dt>
                <dd class="text-gray-700">
                  {{ $formatPrice(payment?.total_payment, { currencyCode: payment?.currency, }) }}
                </dd>
              </div>
              <div class="flex justify-between">
                <dt class="font-bold text-gray-900">{{ $t('Amount Paid') }}</dt>
                <dd class="text-gray-700">
                  {{ $formatPrice(paidAmount, { currencyCode: payment?.currency, }) }}
                </dd>
              </div>
              <div class="flex justify-between">
                <dt class="font-bold text-gray-900">{{ $t('Balance Due') }}</dt>
                <dd class="text-gray-700">
                  {{ $formatPrice(balance, { currencyCode: payment?.currency, }) }}
                </dd>
              </div>

              <pre
                v-if="invoiceInfo?.invoice_footer"
                v-html="invoiceInfo?.invoice_footer"
                class="text-sm text-gray-600 font-sans block border-t border-gray-200 pt-6"
              />
            </dl>
          </div>
        </section>

      </div>

      <div
        class="flex justify-end space-x-2 px-10 mt-6"
        :class="{
          'px-10': !$useNewLayout,
        }"
      >
        <BaseButton
          :loading="isPDFGenerating"
          @click="downloadPDF"
        >
          {{ $t('Download PDF') }}
        </BaseButton>

        <PaymentOptionsDropdown
          v-if="canMakePayment"
          :payment="transformedPayment"
          :makePaymentOnly="true"
          @payment-success="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PaymentOptionsDropdown from "@/modules/payments/components/PaymentOptionsDropdown.vue";

import { PAYMENT_STATUSES } from "@/modules/payments/enum/paymentEnums.js";
import { parseInvoiceDetails } from "@/modules/payments/utils/paymentUtils.js";

import { loadJsPdf } from "@/modules/common/utils/pdfUtils.js"
import PaymentStatus from "@/modules/payments/components/PaymentStatus.vue";

export default {
  components: {
    PaymentStatus,
    PaymentOptionsDropdown
  },
  data() {
    return {
      PAYMENT_STATUSES,
      isPDFGenerating: false,
    }
  },
  computed: {
    invoiceObject() {
      return this.$store.state.payments.currentPaymentInvoice
    },
    invoice() {
      return this.invoiceObject?.attributes
    },
    currentPayment() {
      return this.$store?.state.payments?.currentPayment
    },
    payment() {
      return this.currentPayment?.payment
    },
    paymentMethod() {
      return this.invoice?.payment_method || {}
    },
    invoiceLoading() {
      return this.$store.state.payments.currentPaymentInvoiceLoading
    },
    taxAmount() {
      const tax = this.payment?.tax_rate / 100
      return tax * (this.payment?.amount || 0)
    },
    taxPercentage() {
      if (this.taxAmount) {
        const taxPercentage = Math.round(this.taxAmount / this.invoice?.paid_subtotal * 100)
        return `${taxPercentage}%`
      }
      return `${this.payment?.tax_rate}%`
    },
    subTotal() {
      return (+this.invoice?.paid_subtotal || 0) / 100
    },
    paidAmount() {
      return (this.invoice?.paid_amount || 0) / 100
    },
    balance() {
      const isRecurring = this.payment.is_recurring
      if (!isRecurring) {
        return this.payment?.total_payment - this.paidAmount || 0
      }
      if (this.paidAmount > this.payment?.total_payment) {
        // In this case the paid amount includes tax on top of the total payment
        return this.payment?.total_payment - this.subTotal
      }
      // In this case there is no tax or included in the payment
      return this.payment?.total_payment - this.paidAmount
    },
    paymentTo() {
      return {
        city: this.$settings('city'),
        region: this.$settings('region'),
        country: this.$settings('country'),
        street_address_1: this.$settings('street_address_1'),
        street_address_2: this.$settings('street_address_2'),
        post_code: this.$settings('post_code'),
      }
    },
    invoiceInfo() {
      return {
        invoice_logo: this.$settings('invoice_logo'),
        invoice_footer: this.$settings('invoice_footer'),
      }
    },
    paymentFrom() {
      return parseInvoiceDetails(this.currentPayment?.payment_group?.invoice_details)
    },
    project() {
      return this.currentPayment?.payment_project || {}
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    hasPaymentMethodsConfigured() {
      return this.$store.getters['accounts/hasPaymentMethodsConfigured']
    },
    canMakePayment() {
      return this.payment?.payment_status === PAYMENT_STATUSES.UNPAID && !this.isProjectClosed && this.hasPaymentMethodsConfigured
    },
    transformedPayment() {
      return {
        id: this.payment?.id,
        attributes: this.payment,
        relationships: {
          project: {
            id: this.project?.id,
            attributes: this.project,
          },
          group: {
            id: this.currentPayment?.payment_group?.id,
            attributes: this.currentPayment?.payment_group,
          }
        }
      }
    }
  },
  methods: {
    async getData() {
      const promises = [
        this.$store.dispatch('payments/getPaymentDetailsById', this.$route.params.paymentId),
      ]
      if (this.$route.query.invoiceId) {
        promises.push(this.$store.dispatch('payments/getInvoiceById', this.$route.query.invoiceId))
      } else {
        promises.push(this.$store.dispatch('payments/getPaymentInvoiceById', this.$route.params.paymentId))
      }
      await Promise.all(promises)
    },
    pdfLogoProcessing(post = false) {
      const logo = this.$el.querySelector('#invoice-logo')

      if (post) {
        logo?.classList.remove('mt-6')
      }
      else {
        logo?.classList.add('mt-6')
      }
    },
    async downloadPDF() {
      try {
        this.isPDFGenerating = true
        const element = this.$refs['pdf-root']

        const doc = new jspdf.jsPDF({
          orientation: 'p',
          unit: 'px',
          format: 'a4',
          hotfixes: ['px_scaling']
        })

        const details = [this.project?.name || '', this.invoice?.invoice_number || '']
        .filter(x => !!x)
        .join('_')

        this.pdfLogoProcessing()

        const filename = `Invoice_${details}.pdf`

        doc.html(element, {
          callback: (doc) => {
            this.isPDFGenerating = false

            this.pdfLogoProcessing(true)

            doc.save(filename)
          },
        })
      }
      catch (err) {
        this.isPDFGenerating = false
        this.pdfLogoProcessing(true)
      }
    }
  },
  async created() {
    await this.getData()
    loadJsPdf()
  }
}
</script>
