
// Options Components
import GroupOptionsDropdown from "@/modules/groups/components/GroupOptionsDropdown.vue";
import TaskOptionsDropdown from "@/modules/tasks/components/TaskOptionsDropdown.vue";
import ProjectOptionsDropdown from "@/modules/projects/components/ProjectOptionsDropdown.vue"
import UserOptionsDropdown from "@/modules/users/components/UserOptionsDropdown.vue";
import FileOptionsDropdown from "@/modules/files/components/FileOptionsDropdown.vue";
import FolderOptionsDropdown from "@/modules/files/components/FolderOptionsDropdown.vue";
import PaymentOptionsDropdown from "@/modules/payments/components/PaymentOptionsDropdown.vue";
import TimeEntryOptionsDropdown from "@/modules/time/components/TimeEntryOptionsDropdown.vue";
import AllocatedTimeOptionsDropdown from "@/modules/time/components/AllocatedTimeOptionsDropdown.vue";
import CustomFieldOptionsDropdown from "@/modules/accounts/components/CustomFieldOptionsDropdown.vue";
import ResourceOptionsDropdown from "@/modules/resources/components/ResourceOptionsDropdown.vue";
import ProofOptionsDropdown from "@/modules/file-proofs/components/ProofOptionsDropdown.vue";

import store from "@/store/index.js";
import { get } from "lodash-es";

export const entityDropdowns: {[key: string]: any} = {
  groups: {
    component: GroupOptionsDropdown,
    props(entity: any) {
      return {
        group: entity
      }
    }
  },
  tasks: {
    component: TaskOptionsDropdown,
    props(entity: any) {
      return {
        task: entity
      }
    }
  },
  proofs: {
    component: ProofOptionsDropdown,
    props(entity: any) {
      return {
        proof: entity
      }
    }
  },
  projects: {
    component: ProjectOptionsDropdown,
    props(entity: any) {
      return {
        project: entity
      }
    }
  },
  users: {
    component: UserOptionsDropdown,
    props(entity: any) {
      return {
        user: entity
      }
    }
  },
  media: {
    component: FileOptionsDropdown,
    props(entity: any) {
      return {
        file: entity
      }
    }
  },
  folders: {
    component: FolderOptionsDropdown,
    props(entity: any) {
      return {
        folder: entity
      }
    }
  },
  payments: {
    component: PaymentOptionsDropdown,
    props(entity: any) {
      return {
        payment: entity
      }
    }
  },
  time_entries: {
    component: TimeEntryOptionsDropdown,
    props(entity: any) {
      return {
        timeEntry: entity
      }
    }
  },
  time_allocations: {
    component: AllocatedTimeOptionsDropdown,
    props(entity: any) {
      return {
        allocatedTime: entity
      }
    }
  },
  available_custom_fields: {
    component: CustomFieldOptionsDropdown,
    props(entity: any) {
      return {
        customField: entity
      }
    }
  },
  project_embeds: {
    component: ResourceOptionsDropdown,
    props(entity: any) {
      return {
        toolResource: entity
      }
    }
  },
  project_links: {
    component: ResourceOptionsDropdown,
    props(entity: any) {
      return {
        toolResource: entity
      }
    }
  },
  project_references: {
    component: ResourceOptionsDropdown,
    props(entity: any) {
      return {
        toolResource: entity
      }
    }
  }
}

const genericCompactMapper = (entity: any) => ({
  id: entity.id,
  attributes: {
    name: entity.attributes.name || '',
    title: entity.attributes.title || '',
  }
})

const entityTypeMappers: { [key: string]: Function } = {
  users: (entity: any) => ({
    id: entity.id,
    attributes: {
      name: entity.attributes.name || '',
      first_name: entity.attributes.first_name || '',
      last_name: entity.attributes.last_name || '',
      email: entity.attributes.email || '',
    }
  })
}

export function getEntityCompactValue(entity: any) {
  if (!entity?.id || !entity?.attributes) {
    return entity
  }

  if (entityTypeMappers[entity.type]) {
    return entityTypeMappers[entity.type](entity)
  }

  return genericCompactMapper(entity)
}

type EntityFilterParams = {
  data: any[];
  entityColumns: any[];
  allFilters: any[];
  extraFilters: any[];
  extraCondition?: Function;
}

export function filterEntities({ data, entityColumns, allFilters, extraFilters, extraCondition }: EntityFilterParams) {
  const filterArray = allFilters.find(f => f.key == 'filters')?.value || []

  return data.filter(entity => {
    if (extraCondition && !extraCondition(entity)) {
      return false
    }

    // Check filters in top section
    for (const filter of filterArray) {
      const colDef = entityColumns.find(c => c.filterBy?.prop === filter.column)
      const filterByValue = filter.query

      if (!colDef?.filterBy?.doesFilterPass) {
        console.error(`Column ${colDef?.field} does not have a doesFilterPass function. Sort column: '${filter.column}'.`, colDef)
        return false
      }

      if (!colDef.filterBy.doesFilterPass(entity, filterByValue)) {
        return false
      }
    }

    // Check extra filters (my/completed)
    for (const filter of extraFilters) {
      const filterValue = allFilters.find((f) => f.key === filter.key)?.value
      if (!filter.doesFilterPass(entity, filterValue)) {
        return false
      }
    }

    return true
  })
}

function basicSort(entity1: any, entity2: any, prop: string) {
  const valueA = get(entity1, prop)
  const valueB = get(entity2, prop)

  if (valueA < valueB) {
    return -1
  }

  if (valueA > valueB) {
    return 1
  }

  return 0
}

export function groupEntitiesToMap(entities: any, groupByKey: string): Map<any, any[]> {
  const map = new Map()

  for (const entity of entities) {
    const groupByValue = get(entity, groupByKey)
    if (!map.has(groupByValue)) {
      map.set(groupByValue, [])
    }

    map.get(groupByValue).push(entity)
  }

  if (!map.size) {
    map.set('default', [])
  }

  return map
}

type EntitySortParams = {
  data: any[];
  entityColumns: any[];
  sortArray: any[];
}

export function sortEntities({ data, entityColumns, sortArray }: EntitySortParams) {
  const sortFunctions: Function[] = []
  const sortOrders: string[] = []

  for (const sort of sortArray) {
    const colDef = entityColumns.find((c: any) => c.sortProp === sort.column)
    sortOrders.push(sort.order)

    if (!colDef) {
      console.error(`Sort column '${sort.column}' not found`)
      continue
    }

    const entityProp = (colDef.field || colDef.prop)

    if (colDef.comparator) {
      sortFunctions.push((entity1: any, entity2: any) => {
        const valueA = get(entity1, entityProp)
        const valueB = get(entity2, entityProp)

        const nodeA = {
          data: entity1,
        }

        const nodeB = {
          data: entity2,
        }

        return colDef.comparator(valueA, valueB, nodeA, nodeB)
      })
    }
    else {
      sortFunctions.push((entity1: any, entity2: any) => {
        return basicSort(entity1, entity2, entityProp)
      })
    }
  }

  const sortedEntities = [...data].sort((entity1: any, entity2: any) => {
    for (let i = 0; i < sortFunctions.length; i++) {
      let sortProp = sortFunctions[i]

      const sortResult = sortProp(entity1, entity2)

      if (!sortResult) {
        continue
      }

      return sortResult * (sortOrders[i] === 'asc' ? 1 : -1)
    }

    return 0
  })

  return sortedEntities
}

export enum EntityChangeEvent {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

const entityChangeHandlers: { [key in EntityChangeEvent]: Function } = {
  [EntityChangeEvent.Create]: (entity: any, array: any[]) => {
    const index = array.findIndex(e => e?.id == entity?.id && (!entity?.type || e?.type == entity?.type))

    if (index !== -1) {
      return entityChangeHandlers[EntityChangeEvent.Update](entity, array)
    }

    array.unshift(entity)

    return entity
  },
  [EntityChangeEvent.Update]: (entity: any, array: any[]) => {
    const index = array.findIndex(e => e?.id == entity?.id && (!entity?.type || e?.type == entity?.type))

    if (index === -1) {
      return entity
    }

    array[index] = {
      ...array[index],
      ...entity,
      attributes: {
        ...(array[index].attributes || {}),
        ...(entity.attributes || {})
      },
      relationships: {
        ...(array[index].relationships || {}),
        ...(entity.relationships || {})
      }
    }

    return array[index]
  },
  [EntityChangeEvent.Delete]: (entity: any, array: any[]) => {
    const index = array.findIndex(e => e?.id == entity?.id && (!entity?.type || e?.type == entity?.type))

    if (index === -1) {
      return entity
    }

    array.splice(index, 1)

    return entity
  }
}

export function syncEntityInArray(entity: any, array: any[], action: EntityChangeEvent = EntityChangeEvent.Update) {
  const updatedEntity = entityChangeHandlers[action](entity, array)

  if (!updatedEntity?.id) {
    return
  }

  store.commit('triggerGridDataChanged', { id: updatedEntity.id, entity: updatedEntity, action })
}

const entityChangeMapHandlers: { [key in EntityChangeEvent]: Function } = {
  [EntityChangeEvent.Create]: (entity: any, map: Map<number | string, any>) => {
    const id = Number(entity.id)
    if (map.has(id)) {
      return entityChangeMapHandlers[EntityChangeEvent.Update](entity, map)
    }

    map.set(id, entity)

    return entity
  },
  [EntityChangeEvent.Update]: (entity: any, map: Map<number | string, any>) => {
    const id = Number(entity.id)
    if (!map.has(id)) {
      return entity
    }

    const currentEntity = map.get(id)
    map.set(id, {
      ...currentEntity,
      ...entity,
      attributes: {
        ...(currentEntity.attributes || {}),
        ...(entity.attributes || {})
      },
      relationships: {
        ...(currentEntity.relationships || {}),
        ...(entity.relationships || {})
      }
    })

    return map.get(id)
  },
  [EntityChangeEvent.Delete]: (entity: any, map: Map<number | string, any>) => {
    const id = Number(entity.id)
    if (!map.has(id)) {
      return entity
    }

    map.delete(id)

    return entity
  }
}

export function syncEntityInMap(entity: any, map: Map<any, any[]>, action: EntityChangeEvent = EntityChangeEvent.Update) {
  const updatedEntity = entityChangeMapHandlers[action](entity, map)

  if (!updatedEntity?.id) {
    return
  }

  store.commit('triggerGridDataChanged', { id: updatedEntity.id, entity: updatedEntity, action })
}

export function getEntityArrayForWorker(entities: any[] | Map<any, any[]>) {
  if (entities instanceof Map) {
    entities = Array.from(entities.entries())
  }

  return JSON.parse(JSON.stringify(entities))
}
