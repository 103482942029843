// Disable text selection with shift + click
export function disableShiftClickSelection () {
  ['keyup', 'keydown'].forEach((event) => {
    window.addEventListener(event, (e) => {
      document.onselectstart =  () => {
        if (e.target.classList.contains('ProseMirror')) {
          // Ignore if selection happens inside html editor
          return true
        }
        return !(e.key === "Shift" && e.shiftKey);
      }
    });
  })
}

