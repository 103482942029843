<template>
  <div class="flex">
    <div class="grow text-gray-900 text-2xl sm:text-4xl font-extrabold">{{ welcomeMessage }}</div>
    <BaseTooltip
      :content="$t('Edit dashboard')"
    >
      <router-link
        class="flex-none h-fit rounded-md bg-white border border-gray-200 px-4 py-2 text-xs font-medium text-gray-400 hover:text-gray-500 hover:bg-gray-50"
        to="/edit-dashboards"
      >
        <i class="fa-solid fa-grid-2-plus mr-1" />
      </router-link>
    </BaseTooltip>
  </div>
  <div class="grid grid-cols-2 gap-x-4 md:flex md:gap-x-8 gap-y-2 text-sm text-gray-500">
    <component
      v-for="item of availableSubheaderItems"
      :is="item.action ? 'div' : 'router-link'"
      :key="item.icon"
      :to="item.path"
      class="cursor-pointer"
      :class="{
        [`hover:${item.color}`]: item.color,
      }"
      @click="item.action && item.action()"
    >
      <i
        class="mr-1"
        :class="{
          [item.color]: item.color,
          [item.icon]: item.icon,
        }"
      />
      <span>{{ item.label }}</span>
      <BaseNewFeatureChip
        v-if="item.isNewFeature"
        class="ml-2"
      />
    </component>
  </div>
</template>
<script lang="ts" setup>
// Utils
import i18n from '@/i18n'
import { computed } from 'vue'
import { notificationStatuses } from '@/modules/accounts/utils/notificationUtils'
import { encodeFilters } from '@/modules/common/utils/filterUtils';

// Composables
import { useStore } from 'vuex'
import { useAuth } from "@/modules/auth/composables/useAuth";
import useCan, { AccountPermissions } from "@/modules/common/composables/useCan";
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";

const store = useStore()
const { currentUser } = useAuth()
const { can, actions } = useCan()
const { hasReachedLimit } = useAccountLimits()

const welcomeMessage = computed(() => {
  const hour = new Date().getHours()
  const firstName = currentUser.value?.first_name

  if (hour < 12) {
    return i18n.tc('good morning', {
      firstName
    })
  } 

  if (hour >= 12 && hour <= 18) {
    return i18n.tc('good afternoon', {
      firstName
    })
  }
  return i18n.tc('good evening', {
    firstName
  })
})

const stats = computed(() => {
  return store.state.accounts.stats
})

const active_projects_count = computed(() => {
  return stats.value?.active_projects_count || 0
})

const overdue_tasks_count = computed(() => {
  return stats.value?.overdue_tasks_count || 0
})

const unread_comments_count = computed(() => {
  return stats.value?.unread_comments_count || 0
})

const unpaid_payments_count = computed(() => {
  return stats.value?.unpaid_payments_count || 0
})


const myActiveProjectsPath = computed(() => {
  const viewOptions = store.getters['users/defaultTargetViewOptions']('projects')
  const view_type = viewOptions?.view_type || 'list'

  const filters = encodeFilters([
    {
      key: 'sorts',
      value: {
        column: 'created_at',
        order: 'desc'
      }
    },
    {
      key: 'my-projects',
      value: {
        show: true
      }
    },
    {
      key: 'closed',
      value: {
        show: false
      }
    },
  ])

  return {
    path: `/projects/${view_type}`,
    query: {
      filters
    }
  }
})

const myOverdueTasksPath = computed(() => {
  const viewOptions = store.getters['users/defaultTargetViewOptions']('tasks')
  const view_type = viewOptions?.view_type || 'list'

  const filters = encodeFilters([
    {
      key: 'sorts',
      value: {
        column: 'sortable_date',
        order: 'asc'
      }
    },
    {
      key: 'my-tasks',
      value: {
        show: true
      }
    },
    {
      key: 'overdue-tasks',
      value: {
        show: true
      }
    },
    {
      key: 'completed',
      value: {
        show: false
      }
    }
  ])

  return {
    path: `/tasks/${view_type}`,
    query: {
      filters
    }
  }
})

const unpaidPaymentsPath = computed(() => {
  return {
    path: '/payments/list',
    query: {
      filters: encodeFilters([
        {
          key: 'sorts',
          value: {
            column: 'created_at',
            order: 'desc'
          }
        },
        {
          key: 'filters',
          value: [
            {
              column: 'payment_status',
              query: 'unpaid',
              name: i18n.tc('Status'),
              displayValue: {
                hasColor: true,
                id: 'unpaid',
                label: i18n.t('Unpaid'),
                value: 'rgb(239, 68, 68)'
              }
            }
          ]
        }
      ])
    }
  }
})

const subheaderItems = computed(() => {
  return [
    {
      icon: 'fa-solid fa-inbox',
      color: 'text-purple-500',
      path: myActiveProjectsPath.value,
      label: i18n.tc('active projects count', {
        count: active_projects_count.value
      })
    },
    {
      icon: 'fa-solid fa-badge-check',
      color: 'text-yellow-500',
      path: myOverdueTasksPath.value,
      label: i18n.tc('overdue tasks count', {
        count: overdue_tasks_count.value
      })
    },
    {
      icon: 'fa-solid fa-money-bill',
      color: 'text-green-500',
      path: unpaidPaymentsPath.value,
      label: i18n.tc('unpaid payments count', {
        count: unpaid_payments_count.value
      })
    },
    {
      icon: 'fa-solid fa-bell',
      color: 'text-gray-500',
      path: `/inbox?status=${notificationStatuses.Unread}`,
      label: i18n.tc('unread notifications count', {
        count: unread_comments_count.value
      })
    },
    {
      icon: 'fa-solid fa-circle-dashed',
      color: 'ai-icon',
      isNewFeature: true,
      action() {
        store.commit('ai/triggerAiChatDialog')
      },
      enabled() {
        return can(actions.USE_AI_ASSISTANT) && !hasReachedLimit(AccountPermissions.AiAssistant)
      },
      label: i18n.t('AI Assistant')
    },
  ]
})


const availableSubheaderItems = computed(() => {
  return subheaderItems.value.filter(x => {
    if (x.enabled) {
      return x.enabled()
    }

    return true
  })
})
</script>
