<template>
  <NodeViewWrapper
      :as="'span'"
      :draggable="isDraggable"
      :data-drag-handle="isDraggable"
      class="editor-file py-1 rounded cursor-pointer w-full flex items-center space-x-1 group hover:bg-gray-50"
  >
    <span>
      <img v-if="icon" :src="icon" :alt="name" class="w-7 h-auto my-0">
    </span>
    <a
        :href="url"
        target="_blank"
        class="underline w-full whitespace-nowrap"
        @click.prevent=""
    >
      {{ name }}
    </a>
  </NodeViewWrapper>
</template>
<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import TaskLoadingCard from "@/modules/tasks/components/TaskLoadingCard.vue";
import TaskCard from "@/modules/tasks/components/TaskCard.vue";
import TaskTextRow from "@/modules/tasks/components/TaskTextRow.vue";

export default {
  name: 'ExternalFileTemplate',
  components: {
    TaskCard,
    TaskTextRow,
    NodeViewWrapper,
    TaskLoadingCard,
  },
  props: nodeViewProps,
  computed: {
    url() {
      return this.node?.attrs?.url
    },
    icon() {
      return this.node?.attrs?.icon
    },
    name() {
      return this.node?.attrs?.name
    },
    size() {
      return this.node?.attrs?.size
    },
    isDraggable() {
      return this.node?.attrs?.isDraggable
    },
    isInline() {
      return this.node?.attrs?.inline
    },
  },
}
</script>
