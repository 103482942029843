<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-4xl font-extrabold">
      {{ $t(`View types`) }}
    </h2>
      <p class="text-gray-500 leading-7 text-lg">
        {{ $t(`Set the view you would like each page to start on. For example, you might want tasks to always open on the Kanban view. Everyone will see these views by default but if anyone wants to set their own personal default views they can do this in their user settings.`) }}
      </p>

      <DefaultViewSettings
        :key="$i18n.locale"
        level="account"
        class="col-span-6"
        inputLayout="vertical"
        lightForm
        @user-views-changed="setDefaultViews($event)"
      />
      <div class="flex space-x-2 items-center mt-4">
        <router-link
          to="/account-setup-wizard/account-defaults"
          class="rounded-md bg-white px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-gray-500 border border-gray-200"
        >
          <i class="fa-solid fa-arrow-left mr-2" aria-hidden="true"></i>
          {{ $t(`Back`)  }}
        </router-link>
        <button
          type="button"
          class="rounded-md body-background px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-white"
          @click="onSubmit"
        >
          {{ $t(`Next`) }}
          <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
</template>
<script lang="ts" setup>
import { nextTick, ref } from 'vue';

import DefaultViewSettings from "@/modules/accounts/components/DefaultViewSettings.vue";

import { getSetting } from "@/plugins/settingsPlugin";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter()
const store = useStore()

const defaultViews = ref('')

function setDefaultViews(views: any) {
  defaultViews.value = JSON.stringify(views)
}

async function onSubmit() {
  const key = 'default_view'
  let newValue = defaultViews.value
  let oldValue = getSetting(key)
  const setting = store.getters['accounts/getSettingByKey'](key)

  if (newValue && newValue !== oldValue && setting) {

    store.dispatch('accounts/updateOrganizationSetting', {
      setting,
      data: {
        key,
        value: newValue
      }
    })
  }

  await nextTick()
  router.push(`/account-setup-wizard/trial-custom-branding`)
}
</script>
