<template>
  <VeeForm
    ref="veeForm"
    #default="{ handleSubmit, errors, meta }"
    class="relative"
    :class="{
      'bg-white rounded-lg mx-auto': !$useNewLayout,
      [widthClass]: !$useNewLayout
    }"
  >
    <slot name="title">
      <BaseFormHeader
        v-if="title"
        :title="title"
        :subtitle="subtitle"
        :isDialogForm="isDialogForm"
      />
    </slot>
    
    <form @submit.prevent="handleSubmit(onSubmit)">
      <div
        class="space-y-6 sm:space-y-5 m-auto"
        :class="{
          'rounded-lg bg-gray-50 border border-gray-200': $useNewLayout && !isDialogForm,
          [widthClass]: $useNewLayout,
          'p-6': !slim
        }"
      >
        <slot
          :errors="errors"
          :meta="meta"
        />
      </div>
      <div
        v-if="hasActions"
        class="py-4 m-auto"
        :class="{
          'border-t border-gray-200': !$useNewLayout || isDialogForm,
          'mt-2 rounded-b-lg': !$useNewLayout,
          'px-6': !$useNewLayout || isDialogForm,
          'sticky bottom-0 bg-white': stickyActions,
          [widthClass]: $useNewLayout,
        }"
      >
        <div class="flex justify-end">
          <slot
            name="actions"
            :errors="errors"
            :meta="meta"
          />
        </div>
      </div>
    </form>
  </VeeForm>
</template>
<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  widthClass: {
    type: String,
    default: 'w-full'
  },
  isDialogForm: {
    type: Boolean,
    default: false
  },
  slim: {
    type: Boolean,
    default: false
  },
  hasActions: {
    type: Boolean,
    default: true
  },
  stickyActions: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['submit'])

function onSubmit(emitSave: boolean, options: any) {
  emit('submit', emitSave, options)
}

const veeForm = ref()

function resetForm() {
  veeForm.value?.resetForm?.()
}

defineExpose({
  resetForm
})
</script>
