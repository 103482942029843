<template>
  <BaseInlineInput>
    <template #default="{ triggerSave }">
      <div
        class="relative inline-input custom-field-inline-input"
        :class="{
          'is-disabled': $attrs.disabled,
          'is-date-type': isDateType
        }"
      >
        <CustomFieldInput
          v-if="isSelectFieldType"
          v-model="model"
          @update:modelValue="triggerSave && triggerSave()"
          :custom-field="customField"
          :disabled="$attrs.disabled"
          :hideLabel="true"
          :inline="true"
          :class="$attrs.class"
        >
          <template #select-prefix>
            <CustomFieldValue
              :model="customFieldValueModel"
              :custom-field="customField"
              :show-label="showLabel"
              show-label-icon
            />
          </template>
        </CustomFieldInput>
        <div
          v-else
          class="flex w-full custom-field-value"
          :class="{
            'cursor-pointer': !$attrs.disabled,
            'p-1': !isDateType
          }"
        >
          <!-- NUMERIC/PERCENT CF -->
          <BasePopoverInput
            v-if="isNumericType"
            :disabled="$attrs.disabled"
            popoverVisibility="focus"
          >
            <template #reference>
              <CustomFieldValue
                :model="customFieldValueModel"
                :custom-field="customField"
                :show-label="showLabel"
                show-label-icon
              />
            </template>
            <BaseInlineTextEdit
              v-model="model"
              v-focus
              type="number"
              :placeholder="placeholder"
              class="form-input"
            />
          </BasePopoverInput>
          <!-- TEXTAREA CF -->
          <div
            v-else-if="customFieldType === customFieldTypes.Textarea"
            class="whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            <BasePopoverInput
              :disabled="$attrs.disabled && !model"
              popoverVisibility="focus"
            >
              <template #reference>
                <CustomFieldValue
                  :model="customFieldValueModel"
                  :custom-field="customField"
                  :max-chars="20"
                  :show-label="showLabel"
                  show-label-icon
                />
              </template>
              <div class="p-1 w-60">
                <BaseInlineTextareaEdit
                  v-model="model"
                  v-focus
                  :name="customField.attributes.name"
                  :label="customField.attributes.name"
                  class="form-input resize-none"
                  :placeholder="placeholder"
                  :disabled="$attrs.disabled"
                  rows="5"
                />
              </div>
            </BasePopoverInput>
          </div>
          <!-- TEXT CF -->
          <BasePopoverInput
            v-else-if="customFieldType === customFieldTypes.Text"
            :disabled="$attrs.disabled && !model"
            popoverVisibility="focus"
          >
            <template #reference>
              <CustomFieldValue
                :model="customFieldValueModel"
                :custom-field="customField"
                :max-chars="20"
                :show-label="showLabel"
                show-label-icon
              />
            </template>
            <BaseInlineTextEdit
              v-model="model"
              class="form-input"
              :placeholder="placeholder"
              :disabled="$attrs.disabled"
            />
          </BasePopoverInput>

          <!-- CURRENCY CF -->
          <BasePopoverInput
            v-else-if="customFieldType === customFieldTypes.Currency"
            focusElementSelector=".currency-value-input"
            :disabled="$attrs.disabled"
            popoverVisibility="focus"
          >
            <template #reference>
              <CustomFieldValue
                :model="customFieldValueModel"
                :custom-field="customField"
                :show-label="showLabel"
                show-label-icon
              />
            </template>
            <CurrencyInlineInput
              v-model="model"
              :currencyOptions="customField.attributes.options"
            />
          </BasePopoverInput>
          
          <!-- DATE CF -->
          <div
            v-else-if="isDateType"
            class="inline-flex items-center date-picker-input"
          >
            <template v-if="showLabel">
              <i class="far fa-sparkles mr-0.5" />
              <span class="custom-field-value-label">{{ customField.attributes.name }}:</span>
              <span v-if="isRequired && !model" class="text-red-500">*</span>
            </template>
            <BaseDatePicker
              v-model="model"
              :disabled="$attrs.disabled"
              placeholder="- -"
              class="inline inline-date-picker disabled-focus custom-field-value-display-value"
              @change="triggerSave && triggerSave()"
            />
          </div>

          <div v-else>
            ERR: NOT DEFINED
          </div>
        </div>
        <XCircleIcon
          v-if="model && !$attrs.disabled && !isRequired"
          class="circle-remove hidden ml-1 h-4 w-4 cursor-pointer text-red-300 hover:text-red-500 absolute top-1/2 -translate-y-1/2 -left-1 bg-white z-40 overflow-visible"
          aria-hidden="true"
          @click="model = null; triggerSave && triggerSave()"
        />
      </div>
    </template>
  </BaseInlineInput>
</template>
<script>
import CustomFieldInput from "@/modules/common/components/CustomFieldInput.vue";
import CustomFieldValue from "@/modules/common/components/CustomFieldValue.vue";
import CurrencyInlineInput from "@/modules/common/components/CurrencyInlineInput.vue";
import { customFieldTypes } from "@/modules/accounts/utils/modelUtils";
import { XCircleIcon } from '@heroicons/vue/outline'

export default {
  components: {
    CustomFieldInput,
    CustomFieldValue,
    CurrencyInlineInput,
    XCircleIcon
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    customField: {
      type: Object,
      required: true,
    },
    entityModel: {
      type: Object,
      default: () => ({}),
    },
    showLabel: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      customFieldTypes
    }
  },
  computed: {
    model: {
      get() {
        return this.customFieldValues?.[this.customFieldKey]
      },
      set(value) {
        const modelValue = {
          ...this.modelValue,
          [this.customFieldKey]: value,
        };
        this.$emit("update:modelValue", modelValue);
      }
    },
    customFieldType() {
      return this.customField.attributes.custom_field_type
    },
    isInputType() {
      const inputTypes = [
        customFieldTypes.Text,
        customFieldTypes.Textarea,
        customFieldTypes.Numeric,
        customFieldTypes.Percent,
        customFieldTypes.Currency,
      ]
      return inputTypes.includes(this.customFieldType)
    },
    isNumericType() {
      const numericTypes = [
        customFieldTypes.Numeric,
        customFieldTypes.Percent,
      ]

      return numericTypes.includes(this.customFieldType)
    },
    isSelectFieldType() {
      const selectFieldTypes = [
        customFieldTypes.Select,
        customFieldTypes.Multiselect,
        customFieldTypes.SelectColor,
      ];
      return selectFieldTypes.includes(this.customFieldType);
    },
    isDateType() {
      return this.customFieldType === customFieldTypes.Date
    },
    customFieldValues() {
      const customFieldValues = this.modelValue
      if (typeof customFieldValues === 'string') {
        return JSON.parse(customFieldValues)
      }
      return customFieldValues
    },
    customFieldKey() {
      return this.customField.attributes.key
    },
    placeholder() {
      const action = this.isSelectFieldType || this.isDateType
        ? this.$t("Select")
        : this.$t("Enter");

      return `${action} ${this.customField.attributes.name}...`;
    },
    customFieldValueModel() {
      return {
        attributes: {
          custom_fields: this.modelValue
        }
      }
    },
    isRequired() {
      return this.customField.attributes.rules?.includes('required')
    },
  }
};
</script>
