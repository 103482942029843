<template>
  <Tooltip v-if="tooltip" :title="tooltip" :shortcut="tooltipShortcut">
    <Button
        v-bind="$attrs"
        :active="active"
        :class="buttonClass"
        :variant="variant"
        :buttonSize="buttonSize"
    >
      <slot></slot>
    </Button>
  </Tooltip>
  <Button
      v-else
      v-bind="$attrs"
      :active="active"
      :class="buttonClass"
      :variant="variant"
      :buttonSize="buttonSize"
  >
    <slot></slot>
  </Button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Tooltip from "@/components/html/components/tooltip/Tooltip.vue";
import Button from "@/components/html/components/button/Button.vue";
const props = defineProps({
  active: Boolean,
  tooltip: String,
  tooltipShortcut: Array,
  buttonSize: {
    type: String,
    default: 'icon',
  },
  variant: {
    type: String,
    default: 'ghost',
  },
})

const buttonClass = computed(() => {
  let padding = 'px-2'
  if (props.buttonSize === 'iconSmall') {
    padding = '!px-1 !min-w-0'
  }
  return [`gap-1 min-w-[2rem] ${padding} !w-auto`].filter(Boolean).join(' ')
})
</script>
