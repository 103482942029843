<template>
  <div class="h-full max-w-2xl mx-auto overflow-hidden overflow-y-auto bg-white rounded-lg relative ai-chat">
    <div class="bg-[linear-gradient(60deg,#541FAD,#E928A1,#FF9A0B)] text-white px-6 py-3">
      <div class="flex justify-between">
        <h3 class="text-2xl font-bold">
          {{ $t('AI Assistant') }}
        </h3>
        <AiModeSwitch
          v-model="chatMode"
        />

      </div>
      <div class="text-sm mt-2 text-white flex">
        <span>
          {{
            chatMode === ChatModes.Create
              ? $t('Ask the assistant to help you create something new.')
              : $t('Ask the assistant to perform actions in your account.')
          }}
        </span>
        <BaseTutorialLink
          v-if="chatMode === ChatModes.Create"
          name="ai-create-chat"
          class="underline ml-1"
        >
          {{ $t('Learn more') }}
        </BaseTutorialLink>

        <BaseTutorialLink
          v-else
          name="ai-action-chat"
          class="underline ml-1"
        >
          {{ $t('Learn more') }}
        </BaseTutorialLink>
        
        <div class="ml-auto">
          <BaseTooltip
            :content="clearChatTooltip"
          >
            <i
              class="fa-solid fa-arrow-rotate-left cursor-pointer ml-2 opacity-60 hover:opacity-100"
              @click="clearConversation"
            />
          </BaseTooltip>
        </div>
      </div>
    </div>
    <AiCreateChat
      v-show="chatMode === ChatModes.Create"
      :key="`create_${chatKey}`"
      @send-to-action-mode="onSendToAction"
    />
    <AiActionChat
      v-show="chatMode === ChatModes.Action"
      :key="`action_${chatKey}`"
      :createPrompt="createPrompt"
    />
  </div>
</template>
<script setup lang="ts">
// Libs
import i18n from "@/i18n";
import { computed, provide, ref } from "vue";

// Components
import AiCreateChat from "@/modules/ai/components/AiCreateChat.vue";
import AiActionChat from "@/modules/ai/components/AiActionChat.vue";
import AiModeSwitch from "@/modules/ai/components/AiModeSwitch.vue";

// Helpers
import { 
  ChatModes,
} from "@/modules/ai/types/aiTypes";
import { ChatContext } from "@/modules/common/commonTypes.d";
import { Activities, trackPostHog } from "@/modules/common/utils/trackingUtils";

// Composables
import { useStore } from "vuex";
import BaseTutorialLink from "@/components/form/BaseTutorialLink.vue";

const store = useStore();

const chatKey = computed(() => {
  return store.state.ai.aiChatDialogTrigger;
})

const createPrompt = ref('')

const chatMode = ref(ChatModes.Action)

function onSendToAction(messageHtml: string) {
  createPrompt.value = messageHtml
  chatMode.value = ChatModes.Action
  trackPostHog(Activities.AiCreateSendToAction)
}

const clearChatTooltip = computed(() => {
  return chatMode.value === ChatModes.Create
    ? i18n.t('Start Over')
    : i18n.t('New Action')
})

function clearConversation() {
  if (chatMode.value === ChatModes.Create) {
    store.dispatch('ai/clearCreateChat')
    return
  }

  store.dispatch('ai/clearActionChat')
}

provide('context', ChatContext.AiChat)
</script>
<style lang="scss">
.ai-chat {
  .editor-box-menu {
    @apply rounded-t-none;

    > div:first-child {
      @apply rounded-t-none;
    }
  }
}
</style>
