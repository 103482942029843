import UserDetailsLayout from '@/modules/users/layout/UserDetailsLayout.vue'
import UsersLayout from '@/modules/users/layout/UsersLayout.vue'
import UserList from "@/modules/users/pages/user-list.vue";
import UserCards from "@/modules/users/pages/user-cards.vue";
import AddUser from "@/modules/users/pages/add-user.vue";
import EditUser from "@/modules/users/pages/edit-user.vue";
import ViewUser from "@/modules/users/pages/view-user.vue";
import { INVITE_NEW_PEOPLE } from '@/modules/common/enum/actionsEnum';
import { ApiFilterKeys } from "@/modules/common/commonTypes.d";

export default [
  {
    path: '/users',
    redirect: '/users/list',
    name: 'People',
    component: UsersLayout,
    children: [
      {
        path: '/users/list',
        name: 'User List',
        meta: {
          useFilter: ApiFilterKeys.ArchivedUsers,
          isViewType: true,
        },
        component: UserList,
      },
      {
        path: '/users/card',
        name: 'User Cards',
        meta: {
          useFilter: ApiFilterKeys.ArchivedUsers,
          isViewType: true,
        },
        component: UserCards,
      },
      {
        path: '/users/add',
        name: 'Invite People',
        component: AddUser,
        meta: {
          requiresPermissionTo: INVITE_NEW_PEOPLE
        }
      },
      {
        path: '/users/:id',
        name: 'Person Details',
        component: UserDetailsLayout,
        meta: {
          hideBreadCrumb: true,
        },
        redirect: to => {
          return `${to.fullPath}/view`
        },
        children: [
          {
            path: 'view',
            name: 'View Person',
            component: ViewUser,
          },
          {
            path: 'edit',
            name: 'Edit Person',
            component: EditUser,
          },
        ]
      },
    ]
  },
]
