<template>
  <div class="mt-4">
    <label
      for="business-focus"
      class="block text-sm font-medium leading-6 text-gray-700"
    >
      {{ question.label }}
      <BaseIconTooltip
        v-if="question.infoText"
        :content="question.infoText"
      />
    </label>
    <div class="relative flex items-center">
      <BaseInput
        v-model="model"
        :name="question.label"
        :placeholder="placeholder"
        :rules="question.validationRules"
        :multiLineLabel="question.key === OnboardingQuestionKeys.WhereHeard"
        class="w-full text-base shadow-none"
      >
        <template
          #default
          v-if="showSelect || showExtraInput"
        >
          <BaseSelect
            v-model="model"
            :placeholder="placeholder"
            :options="question.options"
            :return-object="false"
            class="w-full text-base shadow-none"
          />
          <input
            v-if="showExtraInput"
            v-model="extraInputModel"
            v-focus
            class="mt-4 form-input text-base shadow-none"
            :placeholder="extraInputPlaceholder"
            :rules="question.validationRules"
          />
        </template>
      </BaseInput>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType, computed } from 'vue'
import {
  OnboardingQuestion,
  OnboardingQuestionType,
  OnboardingQuestionKeys
} from "@/modules/auth/utils/registerUtils"
import i18n from '@/i18n'

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  otherInputModelValue: {
    type: String,
    default: ''
  },
  question: {
    type: Object as PropType<OnboardingQuestion>,
    required: true,
  }
})

const emit = defineEmits([
  'update:modelValue',
  'update:otherInputModelValue'
])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value: string) {
    emit('update:modelValue', value)
  }
})

const extraInputModel = computed({
  get() {
    return props.otherInputModelValue
  },
  set(value: string) {
    emit('update:otherInputModelValue', value)
  }
})

const showSelect = computed(() => {
  return [
    OnboardingQuestionType.Select,
    OnboardingQuestionType.SelectText,
  ].includes(props.question.type)
})

const showExtraInput = computed(() => {
  return props.question.type === OnboardingQuestionType.SelectText && model.value === 'Other'
})

const placeholder = computed(() => {
  return props.question.placeholder
})

const extraInputPlaceholder = computed(() => {
  return i18n.tc('enter your placeholder', {
    label: props.question.placeholder.toLowerCase()
  })
})
</script>
