<template>
  <BaseDataTable
    :data="data"
    :meta="meta"
    :columns="tableColumns"
    :extraFilters="extraFilters"
    :loading="loading"
  >
    <template #no-data>
      <NoUsers />
    </template>
  </BaseDataTable>
</template>
<script lang="ts" setup>
// Components
import NoUsers from "@/modules/users/components/NoUsers.vue";

// Utils
import { computed } from 'vue'
import { extraFilters } from "@/modules/users/util/userTableUtils"

// Composables
import { useStore } from "vuex"
const store = useStore()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
})

const tableColumns = computed(() => {
  return store.getters['users/tableColumns'] || []
})

const meta = computed(() => {
  return store.state.users.users.meta || {}
})
</script>
