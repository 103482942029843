<template>
 <main class="p-2 sm:p-4 body-background h-full">
  <div class="bg-white rounded-lg h-full overflow-y-auto md:overflow-hidden">
    <div class="md:grid md:grid-cols-2 h-full">

      <!--LEFT SECTION: START-->
      <div class="col-span-1 p-4 sm:p-12 md:overflow-y-auto">
       <BaseAppLogo
        :logoSrc="logoDarkFull"
        class="h-8"
       />

        <!--ACTION: START-->
        <div class="w-full mt-14 md:mt-20">
          <div class="space-y-4">
            <Stepper
              :modelValue="stepperIndex"
              :steps="projectOnboardingSteps"
            />
            
            <router-view />
          </div>
        </div>
        <!--ACTION: END-->
      </div>
      <!--LEFT SECTION: END-->

      <!--RIGHT SECTION: START-->
      <div class="col-span-1 overflow-hidden relative hidden md:block">
        <Vue3Lottie
          v-if="currentStepKey === OnboardingSteps.Congratulations"
          :animationLink="lottieAnimationLink"
          background="transparent"
          :loop="false"
          style="position: absolute; z-index: 100;"
        />

        <Webapp 
          :transform="webappTransform"
          :currentStepKey="currentStepKey"
        />
      </div>
      <!--RIGHT SECTION: END-->
    </div>
  </div>
</main>
</template>
<script lang="ts" setup>
import {
  computed,
  onMounted,
  onBeforeMount,
  watch
} from 'vue';

// Components
import Stepper from '@/components/common/Stepper.vue'
import Webapp from '@/modules/dashboard/components/onboarding/Webapp.vue'

import { Vue3Lottie } from 'vue3-lottie'

// Utils
import {
  OnboardingSteps,
  ProjectOnboardingStep,
  projectOnboardingSteps,
  lottieAnimationLink,
} from '@/modules/accounts/utils/onboardingUtils'
import { logoDarkFull } from '@/modules/accounts/utils/accountBrandingUtils'

import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import usePageUtils from "@/modules/common/composables/usePageUtils";

const store = useStore()
const [router, route] = [useRouter(), useRoute()]
const {
  formattedPageTitle,
  setTitle
} = usePageUtils()

const currentStepKey = computed<OnboardingSteps>(() => {
  return route.path.split('/').pop() as OnboardingSteps
})

const stepperIndex = computed(() => {
  const idx = projectOnboardingSteps.findIndex(step => step.key === currentStepKey.value)

  // We treat the last 2 steps as the same step
  return Math.min(idx, projectOnboardingSteps.length - 2)
})

const currentStep = computed<ProjectOnboardingStep>(() => {
  return projectOnboardingSteps.find(step => step.key === currentStepKey.value)!
})

const webappTransform = computed(() => {
  return currentStep.value!.webappTransform
});

async function checkOnboardingCompleted() {
  if (!store.state.accounts.onboardingModel?.completed) {
    return
  }

  // Ensure onboarding can only be completed once to avoid duplicate data
  await router.replace(`/welcome`)
}

onBeforeMount(async () => {
  await checkOnboardingCompleted()
})

onMounted(async () => {
  await store.dispatch('accounts/getConfiguration')
  store.dispatch('projects/getAllTools')
  store.dispatch('users/getRoles')
  store.dispatch('accounts/getPlans')
})

watch(() => formattedPageTitle.value, setTitle, {
  immediate: true
})
</script>
