import i18n from "@/i18n"

export enum importEntryStatuses {
  Successful = 'successful',
  Failed = 'failed',
  Incomplete = 'incomplete',
  Pending = 'pending',
  PendingDelete = 'pending_delete',
  Deleted = 'deleted',
  FailedDelete = 'failed_delete',
}

export enum importEntityTypes {
  Groups = 'groups',
  People = 'people',
  Projects = 'projects',
  Tasks = 'tasks',
  // Payments: 'payments',
  // Time: 'time',
}

export type ImportFinishedEvent = {
  import_type: importEntityTypes
  import_status: importEntryStatuses
}

export const importTypeOptions = [
  {
    label: i18n.t('Groups'),
    value: importEntityTypes.Groups,
  },
  {
    label: i18n.t('People'),
    value: importEntityTypes.People,
  },
  {
    label: i18n.t('Projects'),
    value: importEntityTypes.Projects,
  },
  {
    label: i18n.t('Tasks'),
    value: importEntityTypes.Tasks,
  },
  // {
  //   label: i18n.t('Payments'),
  //   value: importEntityTypes.Payments,
  // },
  // {
  //   label: i18n.t('Time'),
  //   value: importEntityTypes.Time,
  // },
]
