<template>
  <li class="flex">
    <BaseDropdown
      :options="dropdownOptions"
      :disabled="!dropdownOptions.length"
      divide-items
      show-empty
      trigger="hover"
      class="inline-flex"
    >
      <div class="flex items-center">
        <i class="fa-solid fa-caret-right breadcrumb-color opacity-80 hover:opacity-100 text-base" />

        <router-link
          :to="redirectRoute"
          :title="routeName"
          class="ml-4 text-sm font-medium truncate max-w-[350px] breadcrumb-color opacity-80 hover:opacity-100"
        >
          {{ displayedName }}
        </router-link>

        <i
          v-if="dropdownOptions.length"
          class="fa-solid fa-square-chevron-down ml-2 breadcrumb-color opacity-80 hover:opacity-100"
        />
      </div>
    </BaseDropdown>
    <template v-if="isLastItem">
      <BaseTooltip
        :content="isPageStarred ? $t('Unstar Page') : $t('Star Page')"
        class="inline-flex items-center ml-4"
      >
        <i
          class="fas fa-star text-xs cursor-pointer block"
          :class="{
            'text-yellow-500': isPageStarred,
            'text-yellow-200/50 hover:text-yellow-500': !isPageStarred
          }"
          @click="toggleStarredPage"
        />
      </BaseTooltip>
      <StarredPageSetTitleDialog
        v-model="showStarredPageSetTitleDialog"
        :init-title="newStarredPageTitle"
        @ok="starPage($event)"
      />
    </template>
  </li>
</template>
<script lang="ts" setup>
// Components
import StarredPageSetTitleDialog from "@/modules/accounts/components/StarredPageSetTitleDialog.vue"

// Utils
import { computed, ref } from "vue";
import i18n from "@/i18n";
import { success } from "@/components/common/NotificationPlugin";
import {
  getRouteName,
  isCurrentPageStarred,
} from "@/modules/accounts/utils/pageUtils"

// Composables
import { useStore } from 'vuex'
import { useRouter, useRoute } from "vue-router";
import { useNavigationCards } from "@/modules/dashboard/utils/useNavigationCards"
import { useEntityViewTypes } from "@/modules/dashboard/utils/useEntityViewTypes"
import { useProjectTools } from "@/modules/projects/utils/useProjectTools"

const store = useStore()
const router = useRouter()
const route = useRoute()

const { availableCards } = useNavigationCards()
const {
  currentAvailableViewTypes,
  currentEntityAvailableViewTypes
} = useEntityViewTypes()

const props = defineProps({
  matchedRoute: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  isLastItem: {
    type: Boolean,
    default: false
  },
  formattedRouteFullName: {
    type: String,
    default: ''
  }
})

const redirectRoute = computed(() => {
  if (isMainRoute.value || isViewType.value || isProjectTool.value) {
    const optionPath = dropdownOptions.value.find((option: any) => 
      option.isActive
    )?.path

    if (optionPath) {
      return optionPath
    }
  }

  const path = props.matchedRoute.path

  if (path.endsWith('/projects')) {
    const viewOptions = store.getters['users/defaultTargetViewOptions']('projects')
    return `/projects/${viewOptions.view_type}`
  }

  if (path.endsWith('/templates')) {
    const viewOptions = store.getters['users/defaultTargetViewOptions']('templates')
    return `/templates/${viewOptions.view_type}`
  }

  if (path.endsWith('/tasks')) {
    const viewOptions = store.getters['users/defaultTargetViewOptions']('tasks')
    return `/tasks/${viewOptions.view_type}`
  }

  return router.resolve({
    name: props.matchedRoute.name,
    params: route.params,
  })
})

const routeName = computed(() => {
  return getRouteName(props.matchedRoute)
})

const isMainRoute = computed(() => {
  return props.index === 0
})

const mainNavigationOptions = computed(() => {
  return availableCards.value.map((card) => {
    return {
      ...card,
      label: card.title,
      icon: `fas ${card.iconClass} text-gray-400 group-hover:text-gray-500`,
      iconClass: ''
    }
  })
})

const isViewType = computed(() => {
  return props.matchedRoute.meta.isViewType
})

const viewTypeOptions = computed(() => {
  return currentAvailableViewTypes.value.map((viewType) => {
    return {
      ...viewType,
      label: viewType.name,
      icon: `fas ${viewType.icon} text-gray-400 group-hover:text-gray-500`,
    }
  })
})

const isEntityTypeSelect = computed(() => {
  return props.matchedRoute.meta.isEntityTypeSelect
})

const entityTypeOptions = computed(() => {
  return currentEntityAvailableViewTypes.value.map((viewType) => {
    return {
      ...viewType,
      label: viewType.name,
      icon: `fas ${viewType.icon} text-gray-400 group-hover:text-gray-500`,
    }
  })
})

const isProjectTool = computed(() => {
  return props.matchedRoute.meta.isProjectTool
})

const { projectToolNavigationOptions } = useProjectTools()

const dropdownOptions = computed(() => {
  if (isMainRoute.value) {
    return mainNavigationOptions.value
  }

  if (isViewType.value) {
    return viewTypeOptions.value
  }

  if (isProjectTool.value) {
    return projectToolNavigationOptions.value
  }

  if (isEntityTypeSelect.value) {
    return entityTypeOptions.value
  }

  return []
})

const isPageStarred = computed(() => {
  return isCurrentPageStarred()
})

const showStarredPageSetTitleDialog = ref(false)
const newStarredPageTitle = ref('')


function getStarredPageDefaultTitle() {
  let title = routeName.value
  
  if (title.includes('Account Inbox')) {
    return title.replace('Account Inbox', displayedName.value)
  }
  
  return title
}

async function toggleStarredPage() {
  const { fullPath } = route
  if (!isPageStarred.value) {
    newStarredPageTitle.value = getStarredPageDefaultTitle()
    showStarredPageSetTitleDialog.value = true
  } else {
    await store.dispatch('accounts/deleteStarredPageByLink', fullPath)
    success(i18n.t('Starred page removed'))
  }
}

const displayedName = computed(() => {
  if (isMainRoute.value || isViewType.value || isProjectTool.value || isEntityTypeSelect.value) {
    return dropdownOptions.value.find((option: any) => 
      option.isActive
    )?.label || routeName.value
  }

  return routeName.value
})

async function starPage(pageTitle: string) {
  await store.dispatch('accounts/createStarredPage', {
    title: pageTitle,
    link: route.fullPath,
  })
  success(i18n.t('Page starred'))
}
</script>
