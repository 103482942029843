<template>
  <BaseDataTable
    :data="data"
    :masterDetail="masterDetail"
    :getDetailRowData="getDetailRowData"
    :customIsRowMaster="customIsRowMaster"
    :detail-data-path="detailDataPath"
    :columns="tableColumns"
    :loading="loading"
    :extraFilters="extraFilters"
    :enableInputRow="enableInputRow"
    :defaultInputRowModel="defaultInputRowModel"
    :rowDragEntireRow="enableRowDrag"
    :onRowDragEnter="onRowDragEnter"
    :onRowDragMove="onRowDragMove"
    :onRowDragEnd="onRowDragEnd"
    :getRowDragText="getRowDragText"
    :getDetailRowDragText="(params: any, dragItemCount: number) => getRowDragText(params, dragItemCount, true)"
    @first-data-rendered="onFirstDataRendered"
  >
    <template #no-data>
      <NoTasks />
    </template>
  </BaseDataTable>
</template>
<script lang="ts" setup>
// Components
import NoTasks from "@/modules/tasks/components/NoTasks.vue";

// Utils
import { computed, ref } from 'vue'
import { getExtraFilters, getSubtasks } from "@/modules/tasks/utils/taskTableUtils"
import { taskDateTypes, visibilityTypes } from '@/modules/tasks/utils/modelUtils'
import { getSetting } from "@/plugins/settingsPlugin";
import useMobileUtils from "@/modules/common/composables/useMobileUtils"
import { FirstDataRenderedEvent } from "@ag-grid-community/core";

// Composables
import { useStore } from "vuex"
import { useTaskDragging } from "@/modules/tasks/utils/useTaskDragging"
import useCan from "@/modules/common/composables/useCan.js";
import { GetDetailRowDataParams } from "@ag-grid-community/core";
import {
  completedTasksFilters
} from "@/modules/tasks/utils/taskTableUtils"

const store = useStore()
const { can, actions } = useCan()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  masterDetail: {
    type: Boolean,
    default: false
  },
})

const {
  allowReordering,
  allowParentChange,
  onRowDragEnter,
  onRowDragMove,
  onRowDragEnd,
  getRowDragText,
} = useTaskDragging()
const { isMobileDevice } = useMobileUtils()

const enableRowDrag = computed(() => {
  return (allowReordering.value || allowParentChange.value) && !isMobileDevice.value
})

const extraFilters = computed(() => {
  return getExtraFilters()
})

const tableColumns = computed(() => {
  return store.getters['tasks/tableColumns'] || []
})

const defaultTaskStatus = computed(() => {
  return store.getters['tasks/defaultTaskStatus']
})

const isInsideClosedProject = computed(() => {
  return store.getters['projects/isCurrentProjectClosed']
})

const enableInputRow = computed(() => {
  return !isInsideClosedProject.value && can(actions.CREATE_TASKS)
})

const defaultInputRowModel = computed(() => {
  let visibility = can(actions.CHANGE_TASK_VISIBILITY)
    ? visibilityTypes.CREATORS_ONLY
    : visibilityTypes.CREATORS_AND_COLLABORATORS
    
  const defaultVisibility = getSetting('default_task_privacy')
  if (can(actions.CHANGE_TASK_VISIBILITY) && defaultVisibility) {
    visibility = defaultVisibility
  }

  return {
    id: -1,
    attributes: {
      name: '',
      status_id: defaultTaskStatus.value?.id,
      date_type: taskDateTypes.NO_DATE,
      project_id: store.getters.project_id || null,
      visibility,
    },
    relationships: {
      project: store.state.projects.currentProject || null,
    }
  }
})

const gridApi = ref()
function onFirstDataRendered(params: FirstDataRenderedEvent) {
  gridApi.value = params.api
}

const detailDataPath = 'attributes.subtask_ids'

function getDetailRowData(params: GetDetailRowDataParams) {
  const subtasks = getSubtasks(params.data)
  params.successCallback(subtasks)
}

const apiFilters = computed(() => {
  return store.getters['filters/targetApiFilters']('tasks') || []
})

const isInsideProject = computed(() => {
  return !!store.getters.project_id
})

const isTaskClosedFn = computed(() => {
  return store.getters['tasks/isTaskClosed']
})

const showGlobalCompletedTasks = computed(() => {
  return apiFilters.value?.find((filter: any) => filter.key === 'global-completed-tasks')?.value !== completedTasksFilters.No
})

const showCompletedTasks = computed(() => {
  return apiFilters.value.find((filter: any) => filter.key === 'completed')?.value?.show
})

const completedTasksVisibile = computed(() => {
  if (isInsideProject.value) {
    return showCompletedTasks.value
  }

  return showGlobalCompletedTasks.value
})

function customIsRowMaster(task: any) {
  const subtasks = getSubtasks(task)
  if (subtasks.length === 0) {
    return false
  }

  if (completedTasksVisibile.value) {
    return true
  }

  const hasActiveTasks = subtasks.some((subtask: any) => !isTaskClosedFn.value(subtask))

  return hasActiveTasks
}
</script>
