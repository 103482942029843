import i18n from "@/i18n";
import store from "@/store";

import { entityTypes } from "@/modules/common/enum/entityTypes";
import {
  getDisplayValuesArray,
  userIdsToName,
} from "@/modules/ai/utils/aiUtils";

import {
  AiFunctionDefinition,
  AiFunctions,
  DisplayProps,
} from "@/modules/ai/types/aiTypes";

import { FilterBuilder } from "@/modules/ai/filters/FilterBuilder";
import { FilterTypes } from "@/components/table/tableUtils";
import { FilterEngine } from "@/modules/ai/filters/FilterEngine";

// #region Add or Update Group

interface GroupModel extends Record<any, any> {
  id: string
  name: string
  user_ids: string[]
}

function modelToHumanReadable(model: GroupModel) {
  const displayProps: DisplayProps = {
    name: {
      label: i18n.t('Name'),
    },
    user_ids: {
      label: i18n.t('Users'),
      transformer: userIdsToName
    },
  }

  return getDisplayValuesArray(model, displayProps)
}

export const addOrUpdateGroup: AiFunctionDefinition = {
  name: AiFunctions.AddOrUpdateGroup,
  entity: entityTypes.Group,
  requiresUserConfirmation: true,
  modelToHumanReadable,
  actionDisplayName: (isEditAction: boolean) => {
    return isEditAction ? i18n.t('Update Group') : i18n.t('Create Group')
  },
  iconClass: 'far fa-ball-pile text-green-500'
}

// #endregion

// #region Get Groups

interface GroupsSearchModel extends Record<any, any> {
  id?: number
  name: string
}

function getMappedGroups() {
  // @ts-ignore
  return store.state.groups.allGroups.map((group: any) => ({
    id: group.id,
    name: group.attributes.name,
  }));
}

export function getGroups({name, id}: GroupsSearchModel) {
  // @ts-ignore
  const all = getMappedGroups();

  let filterBuilder = new FilterBuilder();
  let dynamicFilters = filterBuilder
    .addFilter('name', FilterTypes.Text, name)
    .addFilter('id', FilterTypes.Numeric, id)
    .build();

  let filteredGroups = FilterEngine.filter(all, dynamicFilters);

  if (filteredGroups.length > 10) {
    return `There are ${filteredGroups.length} groups. Please narrow your search.`;
  }

  return JSON.stringify(filteredGroups);
}

// #endregion
