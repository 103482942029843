<template>
  <router-link
    v-if="percentageCompleted < 100 && can($actions.MANAGE_ACCOUNT_SETTINGS)"
    id="sidebar-getting-started"
    to="/getting-started"
  >
    <div class="px-6 py-4 opacity-60 hover:opacity-100 hover:bg-white/10 cursor-pointer text-white text-xs space-y-1 mb-4 transition ease-in-out duration-1000">
      <span>{{ $t(`Getting Started`) }} -  {{ $tc('percentage complete', { percentage: percentageCompleted }) }}</span>

      <ProgressBar
        rounded
      />
    </div>
  </router-link>
</template>
<script lang="ts" setup>
import ProgressBar from '@/modules/accounts/components/getting-started/ProgressBar.vue'
import {
  useGettingStarted
} from '@/modules/accounts/composables/useGettingStarted';

const {
  percentageCompleted,
} = useGettingStarted()
</script>
