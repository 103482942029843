<template>
  <BaseAlert
    v-if="simulateRole"
    :dismissable="false"
    variant="warning"
    class="mb-4 border border-yellow-500"
  >
    {{
      $tc('simulating role message', {
        role: simulateRole.name
      })
    }}
    <span
      class="underline cursor-pointer"
      @click="onRevertRole"
    >
      {{ $t(`Click here to switch back to your Creator Admin role.`) }}
    </span>
  </BaseAlert>
</template>
<script lang="ts" setup>
import { computed, nextTick } from "vue"
import { useStore } from 'vuex'

const store = useStore()

const simulateRole = computed({
  get() {
    return store.state.users.simulateRole
  },
  set(value) {
    store.commit('users/setSimulateRole', value)
  }
})

async function onRevertRole() {
  simulateRole.value = null
  await nextTick()
  window.location.href = '/welcome'
}
</script>
