<template>
  <div
    v-if="notificationTypeProperties"
    class="bg-white border border-gray-200 overflow-hidden flex flex-col mb-6 transition ease-in-out duration-150"
    :class="{
      'rounded-lg shadow': !$useNewLayout,
    }"
  >
    <div
      class="border-b border-gray-200"
      :class="{
        'body-background breadcrumb-color': $useNewLayout,
      }"
    >
      <p class="px-6 py-2 flex items-center space-x-2"
         :class="{
          'border-t-[8px] border-green-500': isRead,
        }"
      >
        <i
          class="fal text-xl"
          :class="{
            [iconClass]: true,
            'breadcrumb-color': $useNewLayout,
          }"
        />
        <span
          class="flex-none font-bold text-sm"
          :class="{
            'text-gray-700': !$useNewLayout,
          }"
        >
          {{ title }}
        </span>
        <span
          class="text-sm truncate"
          :class="{
            'text-gray-500': !$useNewLayout,
          }"
        >
          <router-link
            :to="entityPath"
            :class="{
              'underline': $useNewLayout,
              'hover:underline text-primary-500': !$useNewLayout,
            }"
          >
           {{ entityDescription }}
          </router-link>
        </span>
        <span
          v-if="isRead"
          class="text-sm !ml-auto"
          :class="{
            'text-gray-500': !$useNewLayout,
          }"
        >
          <i
            class="fa-solid fa-circle-check mr-1"
            :class="{
              'text-green-500': !$useNewLayout,
            }"
          />
          {{ $t('Read') }}
        </span>
      </p>
    </div>
    <template v-if="isReactionOrComment && discussionComment">
      <div
        class="py-4 px-6 text-sm text-gray-500 flex-grow relative"
        :class="{
          'discussion-comment': isDiscussionNotification
        }"
      >
        <DiscussionComment
          :project-id="projectId"
          :task-id="taskId"
          :discussion="discussionComment"
          :level="discussionComment.attributes?.parent_id ? 2 : 1"
          :entity-type="discussionComment.attributes?.discussionable_type"
          :append-thread-to-body="true"
          :try-open-thread-dialog-on-mount="false"
          isInsideNotificationCard
          class="mb-2"
          ref="discussionComment"
        />
      </div>
    </template>

    <template v-else>
      <div class="py-4 px-6">
        <div>
          <p
            v-if="entityItemPath"
            class="text-sm text-gray-500 truncate"
          >
            <span class="font-bold text-gray-700 mr-1">
              {{ entityTypeLabel }}:
            </span>
            <router-link
              :to="entityItemPath"
              class="text-primary-500 hover:underline"
            >
              {{ notificationTargetDescription }}
            </router-link>
          </p>
          <p v-if="creatorFullName && userLabel" class="text-sm text-gray-500 truncate">
            <span class="font-bold text-gray-700 mr-1">
              {{ userLabel }}:
            </span>
            {{ creatorFullName }}
          </p>
          <p class="text-sm text-gray-500 truncate">
            <span class="font-bold text-gray-700 mr-1">
              {{ $t('Time') }}:
            </span>
            {{ formattedDate }}
          </p>
          <span
            v-if="typeof message === 'string'"
            v-html="message"
            class="text-sm text-gray-500"
          />
          <div
            v-else-if="Array.isArray(message)"
            class="flex flex-col"
          >
            <p
              v-for="val of message"
              :key="val.label"
              class="text-sm text-gray-500 truncate"
            >
              <span
                class="font-bold text-gray-700 mr-1 capitalize"
                v-html="val.label + ':'"
              />
              <span
                :class="val.class"
                v-html="val.value"
              />
            </p>
          </div>

        </div>
      </div>
    </template>

    <div class="py-2 px-6 flex-wrap border-t border-gray-200 flex gap-2 sm:gap-4">
      <BaseButton
        v-if="!isRead"
        :has-focus="false"
        variant="white"
        @click="markAsRead"
      >
        <i class="fa-solid fa-square-check mr-2"/>
        {{ $t('Mark As Read') }}
      </BaseButton>

      <BaseButton
        :has-focus="false"
        variant="white"
        @click="openTaskOrDiscussion"
      >
        <i
          :class="notificationTypeProperties.iconClass"
          class="fas mr-2"
        />
        <router-link
          v-if="isRedirectEntity"
          :to="entityButtonRoute"
        >
          {{ openLabel }}
        </router-link>
        <span v-else>{{ openLabel }}</span>
      </BaseButton>

      <BaseButton
        :has-focus="false"
        :variant="isPinned ? 'primary' : 'white'"
        @click="togglePinned"
      >
        <i class="fas fa-thumbtack mr-2"/>
        {{ isPinned ? $t('Unpin Notification') : $t('Pin Notification') }}
      </BaseButton>

    </div>

  </div>
</template>
<script>
import { get } from 'lodash-es'
import {
  getNotificationCreator,
  NOTIFICATION_TYPES,
  notificationTypeProperties,
} from '@/modules/accounts/utils/notificationUtils'
import UserLogo from '@/components/common/UserLogo.vue'
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'

export default {
  components: {
    UserLogo,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      discussionComment: null,
    }
  },
  computed: {
    isCustomBrandingEnabled() {
      return this.$store.getters['accounts/isCustomBrandingEnabled']
    },
    isRead() {
      return !!get(this.notification, 'attributes.read_at')
    },
    isPinned() {
      return get(this.notification, 'attributes.is_pinned')
    },
    isDiscussionNotification() {
      return [
        NOTIFICATION_TYPES.PROJECT_DISCUSSION,
        NOTIFICATION_TYPES.PROJECT_DISCUSSION_MENTION,
        NOTIFICATION_TYPES.TASK_DISCUSSION,
        NOTIFICATION_TYPES.TASK_DISCUSSION_MENTION,
      ].includes(this.notificationType)
    },
    isReactionNotification() {
      return [
        NOTIFICATION_TYPES.TASK_DISCUSSION_REACTION,
        NOTIFICATION_TYPES.PROJECT_DISCUSSION_REACTION,
      ].includes(this.notificationType)
    },
    isRedirectEntity() {
      return [
        NOTIFICATION_TYPES.PROJECT,
        NOTIFICATION_TYPES.PROJECT_NOTE_MENTION,
        NOTIFICATION_TYPES.PROJECT_DESCRIPTION_MENTION,
        NOTIFICATION_TYPES.PROOF_CREATED,
        NOTIFICATION_TYPES.PROOF_DECISION_STATUS_CHANGED,
        NOTIFICATION_TYPES.PROOF_REVIEW_DECISION_STATUS_CHANGED,
        NOTIFICATION_TYPES.PAYMENT,
        NOTIFICATION_TYPES.PAYMENT_SUCCESS,
        NOTIFICATION_TYPES.SUBSCRIPTION_SUCCEEDED,
        NOTIFICATION_TYPES.SUBSCRIPTION_FAILED,
        NOTIFICATION_TYPES.SUBSCRIPTION_REQUESTED,
        NOTIFICATION_TYPES.SUBSCRIPTION_CANCELED,
      ].includes(this.notificationType)
    },
    isReactionOrComment() {
      return this.isReactionNotification || this.isDiscussionNotification
    },
    discussionId() {
      const { discussion_id, resource_id } = this.notification?.attributes?.data || {}
      return discussion_id || resource_id
    },
    formattedDate() {
      const date = get(this.notification, 'attributes.data.date')
      const formattedDate = this.$formatDate(
        new Date(date),
        null,
        /* lowerCaseMeridians */ true,
        /* withTime */ true,
      )

      return formattedDate
    },
    creatorUser() {
      return getNotificationCreator(this.notification)
    },
    creatorFullName() {
      if ([NOTIFICATION_TYPES.SUBSCRIPTION_FAILED, NOTIFICATION_TYPES.SUBSCRIPTION_SUCCEEDED].includes(this.notificationType)) {
        return get(this.notification, 'attributes.data.paidBy')
      }
      if (NOTIFICATION_TYPES.SUBSCRIPTION_CANCELED === this.notificationType) {
        return get(this.notification, 'attributes.data.cancelledBy')
      }
      if (NOTIFICATION_TYPES.PROOF_REVIEW_DECISION_STATUS_CHANGED === this.notificationType) {
        return this.getProofReviewerName()
      }
      return this.creatorUser?.attributes?.name || ''
    },
    projectId() {
      return get(this.notification, 'attributes.project_id')
    },
    taskId() {
      if (this.discussionComment?.attributes?.discussionable_type === 'task') {
        return this.discussionComment.attributes.discussionable_id
      }

      return null
    },
    entityDescription() {
      return get(this.notification, this.notificationTypeProperties.entityDescription_path)
    },
    notificationType() {
      return get(this.notification, 'attributes.data.notifiable_target_type') || ''
    },
    notificationTargetDescription() {
      return get(this.notification, 'attributes.data.notifiable_target_description') || ''
    },
    notificationTypeProperties() {
      return notificationTypeProperties[this.notificationType]
    },
    userLabel() {
      return this.notificationTypeProperties.userLabel
    },
    title() {
      return `${this.notificationTypeProperties.title}:`
    },
    message() {
      return this.notificationTypeProperties.getMessage(this.notification)
    },
    openLabel() {
      return this.notificationTypeProperties.openLabel
    },
    entityId() {
      return get(this.notification, this.notificationTypeProperties.entityId_path)
    },
    entityType() {
      return this.notificationTypeProperties.entityType
    },
    entityTypeLabel() {
      if (this.entityType === EntityTargetTypes.FILE_PROOFS) {
        return this.$t('Proof')
      }
      return ''
    },
    entityPath() {
      let path = this.notificationTypeProperties.entityPath
        .replace('{projectId}', this.projectId)
        .replace('{entityId}', this.entityId)

      if (this.isReactionOrComment && this.discussionComment?.attributes?.parent_id) {
        const threadId = this.discussionComment?.attributes?.parent_id
        path += `?threadId=${threadId}`
      }
      return path
    },
    entityItemPath() {
      const itemPath = this.notificationTypeProperties?.entityItem_path
      if(!itemPath) {
        return ''
      }
      return itemPath.replace('{projectId}', this.projectId)
        .replace('{entityId}', this.entityId)
    },
    entityButtonRoute() {
      if (this.entityType === EntityTargetTypes.FILE_PROOFS) {
        return this.entityItemPath
      }
      return this.entityPath
    },
    iconClass() {
      const icon = this.notificationTypeProperties.iconClass
      let color = this.notificationTypeProperties.iconColorClass

      if (this.isCustomBrandingEnabled) {
        color = 'text-primary-500'
      }

      return `${icon} ${color}`
    },
  },
  methods: {
    getProofReviewerName() {
      const { first_name = '', last_name = '', id = null } = this.notification?.attributes?.data?.reviewer || {}
      const fullName = `${first_name} ${last_name}`
      if (!id) {
        return `${fullName} (${this.$t('external user')})`
      }
      return fullName
    },
    async getDiscussionComment() {
      if (!this.isReactionOrComment || !this.discussionId) {
        return
      }
      try {
        this.discussionComment = await this.$store.dispatch('discussions/getDiscussionById', this.discussionId)
      } catch (err) {
        this.discussionComment = {}
      }
    },
    openTaskOrDiscussion() {
      if (this.entityType.includes(NOTIFICATION_TYPES.TASK)) {
        return this.openTask(this.entityId)
      }

      if (this.discussionId) {
        this.$emit('open-discussion', this.notification, this.discussionId)
      }
    },
    openTask(taskId) {
      this.$store.dispatch('triggerEntityCreate', {
        entityType: 'task',
        entityCreateParams: {
          taskId,
        },
      })
    },
    async markAsRead() {
      this.notification.attributes.read_at = new Date()
      await this.$store.dispatch('notifications/markNotificationAsRead', {
        notificationId: this.notification.id,
        projectId: this.projectId,
      })
    },
    async togglePinned() {
      const is_pinned = !this.notification?.attributes?.is_pinned
      this.notification.attributes.is_pinned = is_pinned

      await this.$store.dispatch('notifications/setNotificationsPinned', {
        notificationId: this.notification.id,
        projectId: this.projectId,
        is_pinned,
      })
    },
  },
  mounted() {
    this.getDiscussionComment()
  },
  watch: {
    discussionId() {
      this.getDiscussionComment()
    },
    'notification.discussion'(value) {
      if (!value) {
        return
      }
      this.discussionComment = value
    },
  },
}
</script>
