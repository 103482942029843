import { cancelTopProgress } from '@/modules/common/progressbar.js';
import { error } from '@/components/common/NotificationPlugin';
import store from "@/store"
import i18n, { defaultLocale } from "@/i18n.js";
import config  from "@/modules/common/config.js";
import axios from "axios";

import  { setEnvUrl } from '@/modules/common/apiConfig'
import { setDefaultBrandingStyles } from '@/modules/accounts/utils/accountBrandingUtils'
import useMobileUtils from "@/modules/common/composables/useMobileUtils"
import { isFileProofingEnabled } from '@/modules/projects/utils/toolUtils';

const loginRoute = {
  path: '/login'
};

function redirectToLogin(next, to) {
  cancelTopProgress();
  return next({
    path: loginRoute.path,
    query: {
      from: to.fullPath
    }
  });
}

// eslint-disable-next-line no-unused-vars
let subdomainValid = false

async function isOrganizationValid() {
  // Root login
  if (config.isRootApi()) {
    config.replaceOrganizationInHost()
    setEnvUrl()
    setDefaultBrandingStyles()
    await i18n.setLocale(defaultLocale)
    return true
  }

  // Organization already configured
  if (subdomainValid) {
    return true
  }

  try {
    const { data } = await axios.get(`${config.ROOT_API_URL}/tenant`, {
      params: {
        host: config.getTenantHost(),
      }
    })
    store.commit('auth/setCurrentTenant', data)

    const brandingSettings = (data.tenant_settings || []).map(setting =>
      ({
        attributes: {
          ...setting
        }
      })
    )
    try {
      await i18n.setLocale(data.locale || defaultLocale)
    } catch (err) {
      console.error(err)
    }
    store.commit('accounts/setCustomBranding', brandingSettings)

    config.replaceOrganizationInHost(data.subdomain)
    setEnvUrl()

    subdomainValid = true
  } catch (err) {
    subdomainValid = false
  }

  setDefaultBrandingStyles()
  return subdomainValid
}

function userCanAccessRoute(route) {
  for (const match of route.matched) {
    if (!match.meta?.requiresPermissionTo) {
      continue
    }

    const canAccessRoute = store.getters['users/can'](match.meta?.requiresPermissionTo)
    if (!canAccessRoute) {
      return false
    }
  }

  return true
}

const calendarSchedulerPaths = [
  '/calendar',
  '/scheduler'
]

/**
 * Middleware to check if user has the correct right to access a certain page.
 * Checks are performed based on route meta fields `meta: { requiresAuth: true, requiresPermissionTo?: {action_name} }`.
 * @param {object} router Vue router instance
 */
export default function authMiddleware(router) {
  router.beforeEach(async (to, from, next) => {
    const skipOrganizationCheck = to.matched.some(record => record.meta.skipOrganizationCheck)
    if (!skipOrganizationCheck) {
      const isValidOrganization = await isOrganizationValid()
      if (!isValidOrganization) {
        return next('/no-organization')
      }
    }

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (!requiresAuth) {
      return next();
    }

    const url = new URL(window.location.href)
    const token = localStorage.getItem('token') || url.searchParams.get('loginToken');
    if (!token) {
      return redirectToLogin(next, to);
    }

    const isRouteAccesible = userCanAccessRoute(to)
    if (!isRouteAccesible) {
      error(i18n.t("Permission denied"))
      return next(from)
    }

    if (to.path.includes('/file-proofs') && !isFileProofingEnabled()) {
      error(i18n.t("File proofing is no longer supported"))
      return next(from)
    }

    const { isMobileDevice } = useMobileUtils()

    if (!isMobileDevice.value) {
      return next()
    }

    if (calendarSchedulerPaths.some(r => to.path.includes(r))) {
      error(i18n.t("Calendar/scheduler are not available on mobile devices, redirecting to cards.."))

      return next(to.path.replace('calendar', 'card').replace('scheduler', 'card'))
    }

    if (to.path.includes('/resources') || to.path.includes('/file-proofs')) {
      // TODO: Temp, we don't have card views for these pages yet so we still display Ag Grid list views on mobile
      return next()
    }

    const hasCardView = [
      'tasks',
      'templates',
      'projects',
      'users',
      'groups',
      'payments',
      'files',
      'time',
    ].some(r => to.path.includes(r))

    if (to.path.includes('/list') && hasCardView) {
      return next(to.path.replace('list', 'card'))
    }

    return next();
  });
}
