<template>
  <div class="py-4 px-6 cursor-pointer">
    <div class="flex gap-2 mb-1">
      <div class="w-5 h-5 bg-gray-100" />
      <div class="bg-gray-100 h-5 w-1/2"></div>
    </div>
    <div class="bg-gray-100 h-3 w-1/3 mb-1"></div>
    <div class="bg-gray-100 h-3 w-1/3"></div>
  </div>
</template>
