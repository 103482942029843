<template>
 <div
    class="flex items-center w-full rounded-md border border-gray-300 text-gray-500 py-0.5 px-2 text-sm font-medium cursor-pointer"
    :class="{
      'border-primary-500 text-primary-500 bg-primary-50': isActive,
      'hover:bg-gray-100': !isActive,
    }"
  >
    <span>
      {{ displayLabel }}
    </span>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  prependLabel: {
    type: String,
    default: '',
  }
})

const displayLabel = computed(() => {
  if (!props.prependLabel) {
    return props.label
  }

  return `${props.prependLabel}: ${props.label}`
})
</script>
