<template>
   <BaseNoDataSection
    :title="$t('No Past Imports')"
    iconClass="fa-file-csv"
    :canCreate="can($actions.MANAGE_ACCOUNT_SETTINGS)"
  >
    <template #can-create-section>
      <div>{{ $t('Import data into your account.') }}</div>
      <div>{{ $t('Data can be imported to different entities using the data type select above.') }}</div>
      <div>{{ $t('Your past imports will show here.')  }}</div>

      <BaseTutorialLink
        name="import"
        class="hover:text-primary-500"
      >
        <div>{{ $t('Find out more about importing data here.') }}</div>
      </BaseTutorialLink>
    </template>
  </BaseNoDataSection>
</template>
