<template>
  <component
    v-if="attributes?.name"
    class="py-2 flex gap-2 items-center my-2"
    :is="attributes?.public_link ? 'router-link' : 'div'"
    :to="proofRoute"
  >
    <div class="w-12 flex-center shrink-0">
      <i
        v-if="proofLoading"
        class="fas fa-circle-notch animate-spin data-syncing-indicator"
      />
      <img
        v-else
        :src="attributes?.thumbnail_link || DefaultUnsplashPortraitPhoto"
        class="h-8 object-cover rounded"
        alt="image"
      />
    </div>
    <div class="flex justify-between w-full">
      <div>
        <div class="whitespace-nowrap truncate">
          {{ attributes.name }}
        </div>
        <div
          v-if="attributes?.version"
          class="px-2 py-px bg-gray-100 rounded text-xs font-bold text-gray-500 text-center w-min"
        >
          {{ $t('V') + attributes?.version }}
        </div>
      </div>
      <div
        v-if="commentsCount"
        class="text-sm text-gray-400"
      >
        <i class="fa-sharp fa-solid fa-comment-dots"></i>
        <span class="ml-1 tracking-[4px]">{{ commentsCount }}</span>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, PropType, toRef } from "vue";
import { ICellRendererParams } from "@ag-grid-community/core";
import useCellProps from "@/components/table/composables/useCellProps";
import { useStore } from "vuex";
import { DefaultUnsplashPortraitPhoto } from "@/modules/common/utils/unsplashUtils";
import { ProofsStatuses } from "@/modules/file-proofs/utils/proofModels";
import { sumBy } from "lodash-es";

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams>,
    required: true
  },
})

const { value, row, columnParams, colDef } = useCellProps(toRef(props, 'params'))

const store = useStore()

const storeProof = computed(() => {
  return store.state.proofs.proofs.data?.find(proof => String(proof.id) === String(row.value?.id))
})

const proofLoading = computed(() => {
  const proofLoading = store.state.proofs.loading?.[row.value?.id]
  const proofStatus = storeProof?.value?.attributes?.decission_status
  const isUploadingOrProcessing = proofStatus === ProofsStatuses.UPLOADING || proofStatus === ProofsStatuses.PROCESSING
  return proofLoading?.get || proofLoading?.edit || isUploadingOrProcessing
})

const attributes = computed(() => {
  return storeProof?.value?.attributes || row.value?.attributes || {}
})

const commentsCount = computed(() => {
  return sumBy(storeProof?.value?.relationships?.reviewers || [], 'attributes.comments')
})

const proofRoute = computed(() => {
  return {
    name: 'Proof Embed',
    params: {
      proofId: row.value?.id,
    }
  }
})
</script>
