<template>
  <div class="flex flex-col h-full">
    <TimeEntryDialog
      v-show="showCreateTimeEntryDialog && !$route.query.timeEntryId"
      v-model="showCreateTimeEntryDialog"
      key="time-entry-create"
      @close="redirectToList"
      @save="onTimeEntryCreate"
    />
    <TimeEntryDialog
      v-show="showEditTimeEntryDialog && $route.query.timeEntryId"
      v-model="showEditTimeEntryDialog"
      :timeEntry="currentTimeEntry"
      :title="$t(`Edit ${currentTimeEntry?.attributes?.name}`)"
      key="time-entry-edit"
      @close="redirectToList"
      @save="onTimeEntryEdit"
    />
    <TopFilters
      :columns="topFilterColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search actual time...')"
    >
      <template
        v-if="!isCurrentProjectClosed && hasActions"
        #action
      >
        <RequiresPermissionTo :action="$actions.EDIT_ALLOCATED_TIME_ON_PROJECTS">
          <BaseButton
            v-if="isAllocatedViewActive || isAllViewActive"
            block
            @click="showCreateAllocatedTimeDialog = true"
          >
            {{ $t('Allocate Time') }}
          </BaseButton>
        </RequiresPermissionTo>
        <RequiresPermissionTo :action="$actions.CREATE_TIME_ENTRIES">
          <BaseButton
            v-if="isActualViewActive || isAllViewActive"
            :class="{
              'lg:ml-4 mt-2 lg:mt-0': isAllViewActive
            }"
            block
            @click="showCreateTimeEntryDialog = true"
          >
            {{ $t('New Time Entry') }}
          </BaseButton>
        </RequiresPermissionTo>
      </template>

      <TimeViewTypes
        v-if="$isMobileDevice && !isTemplatePath"
      />

    </TopFilters>

    <DataSyncingIndicator
      v-if="shouldDisplaySyncIndicator"
      dataView
    />
    <slot>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component
            :is="Component"
            @save="onTimeEntryEdit"
          />
        </transition>
      </router-view>
    </slot>
  </div>
</template>
<script>
import TopFilters from "@/modules/filters/components/TopFilters.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import TimeEntryDialog from "@/modules/time/components/TimeEntryDialog.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"
import TimeViewTypes from "@/modules/time/components/TimeViewTypes.vue";

export default {
  components: {
    TimeEntryDialog,
    BaseButton,
    TopFilters,
    DataSyncingIndicator,
    TimeViewTypes,
  },
  data() {
    return {
      showCreateTimeEntryDialog: false,
      showEditTimeEntryDialog: false,
    }
  },
  computed: {
    isActualViewActive() {
      return true
    },
    isAllocatedViewActive() {
      return false
    },
    isAllViewActive() {
      return false
    },
    hasActions() {
      return this.can(this.$actions.CREATE_TIME_ENTRIES)
    },
    availableFilters() {
      const filters = ['filter', 'sort', 'group', 'columns']
      
      if (this.$route.path.includes('list')) {
        filters.push(...['chart', 'other', 'totalRow'])
      }
      return filters
    },
    topFilterColumns() {
      return this.timeEntryColumns
    },
    shouldDisplaySyncIndicator() {
      return this.timeEntries.length && this.$store.state.time.timeEntriesLoading
    },
    // Time entries
    timeEntries() {
      return this.$store.state.time.timeEntires?.data || []
    },
    currentTimeEntry() {
      return this.$store.state.time.currentTimeEntry || {}
    },
    timeEntryColumns() {
      return this.$store.getters['time/timeEntryActiveColumns'] || []
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
  },
  methods: {
    onTimeEntryCreate() {
      this.showCreateTimeEntryDialog = false
    },
    onTimeEntryEdit() {
      this.redirectToList()
    },
    redirectToList() {
      this.showCreateTimeEntryDialog = false
      this.showEditTimeEntryDialog = false

      const query = {
        ...this.$route.query
      }
      delete query.timeEntryId
      this.$router.push({
        path: this.$route.path,
        query
      })
    },
  },
  watch: {
    '$route.query.timeEntryId': {
      immediate: true,
      async handler(timeEntryId) {
        if (!timeEntryId || !this.can(this.$actions.EDIT_TIME_ENTRIES)) {
          this.redirectToList()
          return
        }
        await this.$store.dispatch('time/getTimeEntryById', { id: timeEntryId })
        this.showEditTimeEntryDialog = true
      }
    },
  }
}
</script>
