import i18n from "@/i18n"
import store from "@/store/index.js";
import { ColumnTypes } from '@/components/table/cells/tableCellComponents'
import { success, error } from "@/components/common/NotificationPlugin/index.js";

import { $deleteConfirm } from '@/components/common/dialog/modalPlugin'
import { triggerEditCustomField } from "@/modules/accounts/utils/modelUtils"
import { capitalize } from "lodash-es";
import { getDefaultOptionsColumn, TableColumn } from "@/components/table/tableUtils";
import { ValueFormatterParams } from "@ag-grid-community/core";

export function getCustomFieldColumns(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      name: i18n.t('Reorder'),
      prop: 'attributes.order',
      cellRenderer: ColumnTypes.Draggable,
      minWidth: 70,
      maxWidth: 70,
      valueFormatter(params: ValueFormatterParams) {
        return ''
      },
      extendedCellClass: 'justify-center',
      cardClass: 'hidden',
    },
    {
      name: i18n.t("Enabled"),
      prop: "attributes.is_disabled",
      component: ColumnTypes.Checkbox,
      minWidth: 80,
      maxWidth: 80,
      params: {
        getValue(row: any) {
          return !row?.attributes?.is_disabled
        },
        onChange: confirmToggleDisabled,
        tooltipChecked: i18n.t('Disable Custom Field'),
        tooltipUnchecked: i18n.t('Enable Custom Field'),
      },
    },
    {
      name: i18n.t("Name"),
      prop: "attributes.name",
      flex: 1,
      params: {
        onClick: triggerEditCustomField
      },
      sortable: true,
      sortProp: 'name',
      filterBy: {
        prop: 'name',
        type: 'text'
      },
      cardClass: 'font-bold text-gray-900 text-base leading-5',
    },
    {
      name: i18n.t("Applies to"),
      prop: "attributes.entity_type",
      sortable: true,
      sortProp: 'entity_type',
      valueFormatter(params: ValueFormatterParams) {
        const specialCases: {[key: string]: string} = {
          'media': i18n.t('File'),
          'time_entry': i18n.t('Time Entry'),
        }
        
        return specialCases[params.value] || capitalize(params.value)
      },
      showCardLabel: true,
      hide: true,
      filterBy: {
        prop: 'entity_type',
        component: 'EntityTypeSelect',
        displayFormat: 'capitalize'
      },
    },
    {
      name: i18n.t("Field type"),
      prop: "attributes.custom_field_type",
      valueFormatter(params: ValueFormatterParams) {
        return capitalize(params.value)
      },
      showCardLabel: true,
      filterBy: {
        prop: 'custom_field_type',
        component: 'CustomFieldTypeSelect',
        displayFormat: 'capitalize'
      },
    },
    {
      name: i18n.t("Rules"),
      prop: "attributes.rules",
      valueFormatter(params: ValueFormatterParams) {
        return capitalize(params.value)
      },
      showCardLabel: true,
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn(),
    disabled: () => {
      return store.getters['projects/isCurrentProjectClosed']
    }
  }

  const extraColumns: TableColumn[] = [ optionsColumn ]

  return {
    mainColumns,
    extraColumns
  }
}

async function confirmToggleDisabled(value: boolean, customField: any) {
  const isDisabled = customField?.attributes?.is_disabled
  if (isDisabled) {
    // Need to re-enable without confirmation
    await store.dispatch("accounts/toggleCustomFieldDisabled", {
      customField,
      disable: false
    });
    return
  }

  const confirmed = await $deleteConfirm({
    title: i18n.t("Disable Custom Field"),
    description: i18n.t(
      "If you disable this custom field it will be hidden from view in the application.  Its data will not be deleted. If you re-enable the custom field it will be visible again and all data will reappear."
    ),
    buttonText: i18n.t("Disable")
  });

  if (!confirmed) {
    return;
  }

  try {
    await store.dispatch("accounts/toggleCustomFieldDisabled", {
      customField,
      disable: true
    });
    success(i18n.t("Custom Field disabled successfully"));
  } catch (err: any) {
    if (err.handled) {
      return;
    }
    console.log(err)
    error(i18n.t("Cannot disable custom field"));
    throw err;
  }
}
