import i18n from "@/i18n"
import store from "@/store/index.js";
import { ColumnTypes, HeaderTypes } from '@/components/table/cells/tableCellComponents'

import { customFieldAppliesToOptions } from "@/modules/accounts/utils/modelUtils"
import { CREATE_CUSTOM_FIELDS } from "@/modules/common/enum/actionsEnum";
import { getDefaultOptionsColumn, getEntityImageColumn, TableColumn } from "@/components/table/tableUtils";
import { GetQuickFilterTextParams, ValueFormatterParams } from "@ag-grid-community/core";
import { orderBy } from "lodash-es";
import { isUserArchived } from "@/modules/users/util/userUtils";


export function getUserColumns(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      ...getEntityImageColumn()
    },
    {
      name: i18n.t('Name'),
      prop: 'attributes.name',
      sortable: true,
      sortProp: 'name',
      comparator(a, b, nodeA, nodeB) {
        const userA = nodeA.data
        const userB = nodeB.data

        const valueA = [userA.attributes?.first_name, userA.attributes?.last_name].filter(Boolean).join(', ') || userA.attributes?.email || ''
        const valueB = [userB.attributes?.first_name, userB.attributes?.last_name].filter(Boolean).join(', ') || userB.attributes?.email || ''

        return valueA.localeCompare(valueB)
      },
      required: true,
      getQuickFilterText(params: GetQuickFilterTextParams<any>) {
        const row = params.data

        const name = [row.attributes?.first_name, row.attributes?.last_name].filter(Boolean).join(', ')

        return name || row.attributes?.email || ''
      },
      flex: 1,
      minWidth: 180,
      component: ColumnTypes.Link,
      params: {
        getDescription(row: any) {
          return row.attributes?.name || row.attributes?.email || '- -'
        },
        getLink(row: any) {
          if (isUserArchived(row)) {
            return null
          }

          return `/users/${row.id}`
        }
      },
      filterBy: {
        prop: 'name',
        type: 'text',
        doesFilterPass: (row: any, filterValue: string) => {
          const value = [row.attributes?.first_name, row.attributes?.last_name].filter(Boolean).join(', ') || row.attributes?.email || ''

          return value?.toLowerCase().includes(filterValue?.toLowerCase())
        }
      },
      cardClass: 'font-bold text-gray-900 text-base leading-5 inline-flex'
    },
    {
      name: i18n.t('Role'),
      prop: 'attributes.role',
      sortable: true,
      sortProp: 'role',
      comparator(valueA: string, valueB: string) {
        const userRoles = store.state.users.roles?.data || []
        const roleRankA = userRoles.find((role: any) => role.attributes.name === valueA?.[0])?.attributes?.rank || 0
        const roleRankB = userRoles.find((role: any) => role.attributes.name === valueB?.[0])?.attributes?.rank || 0

        return roleRankA - roleRankB
      },
      showCardLabel: true,
      enableRowGroup: true,
      component: ColumnTypes.UserRole,
      filterBy: {
        prop: 'role_ids',
        type: 'UserRoleSelect',
        format: 'formatIdsArray',
        displayFormat: 'formatLabelsArray',
        props: {
          multiple: true
        },
        doesFilterPass: (row: any, filterValue: string[]) => {
          const userRoles = store.state.users.roles?.data || []
          const roleName = row.attributes.role?.[0]
          const userRoleId = userRoles.find((role: any) => role.attributes.name === roleName)?.id

          return filterValue.some(roleId => roleId == userRoleId)
        }
      },
    },
    {
      name: i18n.t('Status'),
      prop: 'attributes.status',
      sortable: true,
      sortProp: 'status',
      showCardLabel: true,
      component: ColumnTypes.UserStatus,
      cardClass: 'inline-flex'
    },
    {
      name: i18n.t('Created'),
      prop: 'attributes.created_at',
      showCardLabel: true,
      sortable: true,
      sortProp: 'created_at',
      component: ColumnTypes.Date,
      filterBy: {
        prop: 'created_at',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy',
        doesFilterPass: (row: any, filterValue: { min: string, max: string }) => {
          const createdAt = Date.parse(row.attributes?.created_at)
          const startDate = Date.parse(filterValue.min)
          const endDate = Date.parse(filterValue.max)

          return createdAt >= startDate && createdAt <= endDate
        }
      },
    },
    {
      name: i18n.t('Groups'),
      prop: 'attributes.group_ids',
      showCardLabel: true,
      enableRowGroup: true,
      sortable: true,
      sortProp: 'group',
      comparator: (groupsA: any, groupsB: any, nodeA: any, nodeB: any) => {
        const rowA = nodeA?.data || nodeA?.allLeafChildren?.[0]?.data
        const rowB = nodeB?.data || nodeB?.allLeafChildren?.[0]?.data

        groupsA = rowA?.relationships?.groups || []
        groupsB = rowB?.relationships?.groups || []

        const groupsStringA = orderBy(groupsA || [], 'id').map((group: any) => group?.attributes?.name || '').join(', ')
        const groupsStringB = orderBy(groupsB || [], 'id').map((group: any) => group?.attributes?.name || '').join(', ')

        return groupsStringA.localeCompare(groupsStringB);
      },
      params: {
        getGroups(row: any) {
          return row?.relationships?.groups || []
        },
      },
      component: ColumnTypes.GroupList,
      filterBy: {
        prop: 'group_ids',
        component: 'GroupSelect',
        props: {
          multiple: true
        },
        doesFilterPass: (row: any, filterValue: (string | number)[]) => {
          const groupIds = row.attributes?.group_ids
          if (!groupIds?.length) {
            return false
          }

          return groupIds.some((groupId: string | number) => filterValue.some(id => id == groupId))
        },
      },
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn(),
  }

  const addCustomFieldColumn: TableColumn = {
    headerComponent: HeaderTypes.AddCustomField,
    prop: 'add-custom-field',
    headerComponentParams: () => {
      return {
        defaultAppliesTo: customFieldAppliesToOptions.USER,
        tooltip: i18n.t('Add a new custom field for all people across the account')
      }
    },
    valueFormatter(params: ValueFormatterParams) {
      return ''
    },
    class: 'w-1 text-center',
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    disabled: () => {
      if (!store.getters['users/can'](CREATE_CUSTOM_FIELDS)) {
        return true
      }
     
      return false
    },
    showInChooseColumns: false
  }


  const extraColumns: TableColumn[] = [addCustomFieldColumn, optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}

export const extraFilters = [
  {
    key: 'archived-users',
    doesFilterPass: (row: any, filterValue: { show: boolean }) => {
      if (filterValue?.show) {
        return isUserArchived(row)
      }
      return !isUserArchived(row)
    },
  }
]

export const userFields = [
  'id',
  'first_name',
  'last_name',
  'email',
  'avatar',
  'custom_fields',
  'status',
  'created_at',
  // + properties always loaded by default: 'role', 'archived_at'
]
