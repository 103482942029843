<template>
  <div
    v-loading="loading"
    class="relative"
  >
    <div class="bg-white mb-5">
      <div class="text-gray-900 text-xl sm:text-4xl font-extrabold mb-3 flex items-center">
        <div class="sm:hidden">
          <GroupLogo
            :group="group"
            size="md"
          />
        </div>
        <div class="ml-2 sm:ml-0 line-clamp-1">
          {{ group?.attributes?.name }}
        </div>

        <router-link
          :to="`/groups/${group?.id}/edit`"
          class="sm:hidden ml-auto"
        >
          <BaseButton
            variant="white"
            class="shadow-none"
          >
            {{ $t('Edit Group') }}
          </BaseButton>
        </router-link>
      </div>
      <div class="flex gap-3 px-6 py-4 border border-gray-200 rounded-lg">
        <div class="flex-none hidden sm:block">
          <GroupLogo
            :group="group"
            size="xxl"
          />
        </div>

        <div class="grow space-y-1 text-sm text-gray-500">

          <div class="flex flex-wrap items-center gap-2">
            <div class="font-bold text-gray-900">
              {{ $t('People') }}
            </div>
            <UserChips
              v-if="groupUsers.length"
              :users="groupUsers"
            />
            <div
              class="shrink-0 text-gray-400 hover:text-gray-500 cursor-pointer text-xs"
              @click="showUsersDialog = true"
            >
              {{ $t('+ Add/Remove People') }}
            </div>
          </div>
          <div
            v-for="customField in customFields"
            class="flex items-center space-x-2"
          >
            <div class="font-bold text-gray-900">
              {{ customField.attributes?.name }}
            </div>
            <div class="text-gray-500">
              <CustomFieldValue
                :customField="customField"
                :model="group"
              />
            </div>
          </div>
        </div>

        <router-link
          :to="`/groups/${group?.id}/edit`"
          class="hidden sm:block"
        >
          <BaseButton
            variant="white"
            class="shadow-none whitespace-nowrap"
          >
            {{ $t('Edit Group') }}
          </BaseButton>
        </router-link>
        <BaseTooltip
          v-if="can($actions.DELETE_GROUPS)"
          :content="hasUsers ? $t('Cannot delete group with users') : ''"
        >
          <BaseButton
            variant="white"
            hoverVariant="danger"
            class="shadow-none whitespace-nowrap"
            :disabled="hasUsers"
            @click="confirmDelete"
          >
            {{ $t('Delete') }}
          </BaseButton>
        </BaseTooltip>
      </div>
    </div>
    <DashboardWidgets
      v-if="group?.id"
    />
    <GroupUsersDialog
      v-if="showUsersDialog && group?.id"
      v-model="showUsersDialog"
      :group="group"
      @close="showUsersDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import UserChips from "@/components/common/UserChips.vue";
import GroupLogo from "@/components/common/GroupLogo.vue";
import CustomFieldValue from "@/modules/common/components/CustomFieldValue.vue";
import DashboardWidgets from "@/modules/dashboard/components/DashboardWidgets.vue";
import GroupUsersDialog from "@/modules/groups/components/GroupUsersDialog.vue";

// Utils
import i18n from "@/i18n";
import { computed, provide, ref } from "vue";
import { WidgetsContext, WidgetContextPages } from "@/modules/dashboard/utils/widgetUtils";
import { $deleteConfirm } from '@/components/common/dialog/modalPlugin'
import { error, success } from "@/components/common/NotificationPlugin";

import Data = API.Data
import User = App.Domains.Users.Models.User
import Group = App.Domains.Groups.Models.Group

// Composables
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore()
const router = useRouter()

const group = computed<Data<Group>>(() => {
  return store.state.groups.currentGroup || {}
})

const loading = computed(() => {
  return store.state.groups.currentGroupLoading
})

const groupUsers = computed<Data<User>[]>(() => {
  return group.value?.relationships?.users || []
})

const customFields = computed(() => {
  return store.getters["accounts/entityFormCustomFields"]('group')
})

const widgetsContext: WidgetsContext = {
  page: WidgetContextPages.GroupProfile,
  group,
}

provide('context', widgetsContext)

const showUsersDialog = ref(false)

const hasUsers = computed(() => {
  return groupUsers.value.length > 0
})

async function confirmDelete() {
  const confirmed = await $deleteConfirm({
    title: i18n.t("Delete Group"),
    description: i18n.t("Are you sure? This will permanently delete the group"),
  });

  if (!confirmed) {
    return;
  }

  try {
    await store.dispatch("groups/deleteGroup", group.value?.id);
    success(i18n.t("Group deleted successfully"));
    router.push(`/groups/list`);
  } catch (err: any) {
    if (err.handled) {
      return;
    }
    error(i18n.t("Cannot delete the group"));
    throw err;
  }
}

</script>
