<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <div class="max-w-3xl m-auto align-middle bg-white">
      <div class="w-full border-b border-gray-200 p-6">
        <h3 class="text-2xl font-bold">
          {{ $t('Share Template: ') }} {{ template?.attributes?.name }}
        </h3>

        <p class="text-sm text-gray-500 mt-2">
          {{
            `Copy the URL below to share this template. When someone else uses this link it will copy the template to their Project.co account. Note: The person who opens this link needs to have a Creator Plus or Creator Admin role.`
          }}
        </p>
      </div>

      <div class="sm:space-y-5 p-6">
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <div class="w-0 flex-1 flex items-center">
            <LinkIcon class="flex-shrink-0 h-5 w-5 text-gray-400"/>
            <span class="ml-2 flex-1 w-0 truncate text-gray-600">
               {{ shareUrl }}
            </span>
          </div>
          <div class="ml-4 flex-shrink-0">
            <BaseButton @click="copyLink">
              {{ $t('Copy') }}
            </BaseButton>
          </div>
        </li>
      </div>
    </div>

    <div class="px-6 py-4 border-t border-gray-200 sticky w-full bottom-0 bg-white z-10">
      <div class="flex justify-end">
        <BaseButton
          variant="white"
          @click="$emit('close')"
        >
          {{ $t('Close') }}
        </BaseButton>
      </div>
    </div>
  </BaseDialog>
</template>
<script>
import { LinkIcon } from '@zhuowenli/vue-feather-icons'
import config from "@/modules/common/config.js";
import i18n from "@/i18n";

export default {
  components: {
    LinkIcon
  },
  props: {
    template: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    shareUrl() {
      return `${config.ROOT_DOMAIN_URL}?share_template=${this.template.attributes?.share_link_identifier}`;
    }
  },
  methods: {
    copyLink() {
      const message = i18n.t('Share link copied')
      this.$copyToClipboard(this.shareUrl, message)
    }
  }
}
</script>
