import i18n from '@/i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'
import useCan from '@/modules/common/composables/useCan'

export enum TrialFeatureKeys {
  // Standalone
  AiAssistant = 'has_ai_assistant',
  GanttView = 'has_gantt_chart',
  LiveTaskTimer = 'has_task_timer',
  DocumentProofing = 'has_document_proofing',
  RecurringPayments = 'has_recurring_payments',
  SpeechToText = 'has_speech_to_text',
  ImportData = 'has_csv_box_project_import',

  // Branding
  CustomBranding = 'has_custom_branding',
  CustomEmail = 'has_custom_email',
  CustomDomain = 'has_custom_domain',

  // API & Webhooks
  PublicAPI = 'has_public_api',
  Webhooks = 'has_webhooks',
}

export enum TrialFeatureStatuses {
  NotStarted = 'not_started',
  Started = 'started',
  Ended = 'ended',
}

export type TrialFeatureState = {
  key: TrialFeatureKeys;
  status: TrialFeatureStatuses;
  started_at?: string | null;
  expires_at?: string | null;
}

export type TrialFeature = {
  key: TrialFeatureKeys;
  additionalKeys?: TrialFeatureKeys[];
  title: string;
  description: string;
  iconClass: string;
  state: TrialFeatureState;
  videoId?: string;
}

export const defaultTrialStatusesMap: Record<TrialFeatureKeys, TrialFeatureState> = {
  [TrialFeatureKeys.AiAssistant]: {
    key: TrialFeatureKeys.AiAssistant,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.GanttView]: {
    key: TrialFeatureKeys.GanttView,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.LiveTaskTimer]: {
    key: TrialFeatureKeys.LiveTaskTimer,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.DocumentProofing]: {
    key: TrialFeatureKeys.DocumentProofing,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.RecurringPayments]: {
    key: TrialFeatureKeys.RecurringPayments,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.SpeechToText]: {
    key: TrialFeatureKeys.SpeechToText,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.ImportData]: {
    key: TrialFeatureKeys.ImportData,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  // Branding
  [TrialFeatureKeys.CustomBranding]: {
    key: TrialFeatureKeys.CustomBranding,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.CustomEmail]: {
    key: TrialFeatureKeys.CustomEmail,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.CustomDomain]: {
    key: TrialFeatureKeys.CustomDomain,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  // API & Webhooks
  [TrialFeatureKeys.PublicAPI]: {
    key: TrialFeatureKeys.PublicAPI,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
  [TrialFeatureKeys.Webhooks]: {
    key: TrialFeatureKeys.Webhooks,
    status: TrialFeatureStatuses.NotStarted,
    started_at: null,
    expires_at: null,
  },
}

export function useTrialManager() {
  const store = useStore()

  const {
    getAccountPermission,
    hasAccountPermission
  } = useCan()

  async function getTrialFeaturesState() {
    await store.dispatch('accounts/getTrialFeaturesState')
  }

  async function startFeaturesTrial(features: TrialFeatureKeys[]) {
    await store.dispatch('accounts/enableTrialFeatures', {
      features,
    })
  }

  const statuses = computed<Record<TrialFeatureKeys, TrialFeatureState>>(() => {
    const trialFeaturesState: TrialFeatureState[] = store.state.accounts.trialFeaturesState || []

    const trialFeaturesStateMap = trialFeaturesState.reduce((acc, status) => {
      acc[status.key] = status
      return acc
    }, {} as Record<TrialFeatureKeys, TrialFeatureState>)

    return trialFeaturesState.length ? trialFeaturesStateMap : defaultTrialStatusesMap
  })

  function displayFeatureFilter(feature: TrialFeature) {
    const permission = getAccountPermission(feature.key)
    const hasPermission = hasAccountPermission(feature.key)

    return !hasPermission || permission?.expired_at
  }

  const trialFeatures = computed<TrialFeature[]>(() => {
    const features = [
      {
        key: TrialFeatureKeys.AiAssistant,
        title: i18n.t(`AI Assistant`),
        description: i18n.t(`The AI Assistant help you create ideas and then turn those ideas into projects and tasks. You get the full power of GPT-4 to speed up and expand the way you work.`),
        iconClass: 'fa-stars group-hover:text-yellow-500',
        state: statuses.value[TrialFeatureKeys.AiAssistant],
        videoId: 'klev54ptwk',
      },
      {
        key: TrialFeatureKeys.SpeechToText,
        title: i18n.t(`Speech to Text`),
        description: i18n.t(`Speech-to-text lets you speak discussion comments and AI Assistant instructions. You can activate speech-to-text by clicking the microphone button. Then, when you speak your voice is converted into text and added to the comment area ready to be posted.`),
        iconClass: 'fa-microphone group-hover:text-blue-500',
        state: statuses.value[TrialFeatureKeys.SpeechToText],
        videoId: 'sn8w1kzypu',
      },
      {
        key: TrialFeatureKeys.ImportData,
        title: i18n.t(`Import Data`),
        description: i18n.t(`Import your data from a CSV file. This is a great way to get started quickly by importing your existing groups, people, projects and tasks into Project.co.`),
        iconClass: 'fa-file-export group-hover:text-gray-900',
        state: statuses.value[TrialFeatureKeys.ImportData],
        videoId: 'nhmzosd8vs',
      },
      {
        key: TrialFeatureKeys.CustomBranding,
        title: i18n.t(`Custom branding`),
        description: i18n.t(`Make your account feel like your very own custom built tool by adding your company colours and logo. This will make sure that everyone you invite gets a branded experience throughout.`),
        iconClass: 'fa-swatchbook group-hover:text-primary-500',
        state: statuses.value[TrialFeatureKeys.CustomBranding],
        videoId: 'nkqck9e1pq',
      },
      // Removed until it's in production
      // {
      //   key: TrialFeatureKeys.GanttView,
      //   title: i18n.t(`Gantt View`),
      //   description: i18n.t(`Manage your tasks on a timeline along with dependencies. This means that each task can connect to each other task so if one task moves the other tasks will move as well.`),
      //   iconClass: 'fa-bars-staggered group-hover:text-emerald-500',
      //   state: statuses.value[TrialFeatureKeys.GanttView],
      //   videoId: 'ze5hzrqc1x',
      // },
      {
        key: TrialFeatureKeys.LiveTaskTimer,
        title: i18n.t(`Live Task Timer`),
        description: i18n.t(`Click to record time as you work on projects and tasks. The live task timer will keep track of your time and record it against the correct project and task.`),
        iconClass: 'fa-stopwatch group-hover:text-pink-500',
        state: statuses.value[TrialFeatureKeys.LiveTaskTimer],
        videoId: '66qvmwm1cx',
      },
      // PC-1519 Discontinued support, 1st August 2024
      // {
      //   key: TrialFeatureKeys.DocumentProofing,
      //   title: i18n.t(`Document Proofing`),
      //   description: i18n.t(`Review and annotate documents so you can collect high quality feedback on virtually any file type. Get approvals from the right people on any file.`),
      //   iconClass: 'fa-files group-hover:text-purple-500',
      //   state: statuses.value[TrialFeatureKeys.DocumentProofing],
      //   videoId: '3voftqid9b',
      // },
      {
        key: TrialFeatureKeys.RecurringPayments,
        title: i18n.t(`Recurring Payments`),
        description: i18n.t(`Set up recurring payments in addition to one-off payments. With recurring payments you set the amount and frequency so you can get paid on a recurring basis from your clients.`),
        iconClass: 'fa-money-bill group-hover:text-emerald-500',
        state: statuses.value[TrialFeatureKeys.RecurringPayments],
        videoId: '7pdbxiauhr ',
      },
      // Removed until they are both in production
      // {
      //   key: TrialFeatureKeys.PublicAPI,
      //   additionalKeys: [TrialFeatureKeys.Webhooks],
      //   title: i18n.t(`Public API & Webhooks`),
      //   description: i18n.t(`Get access to the Public API and Webhooks so you can connect Project.co with external tools. You'll have full API access so you can create and manage projects, tasks, people and more.`),
      //   iconClass: 'fa-webhook group-hover:text-gray-500',
      //   state: statuses.value[TrialFeatureKeys.PublicAPI],
      //   videoId: 'ze5hzrqc1x', // TODO: still to add when public api is released production + video ready
      // }
    ]

    return features.filter(displayFeatureFilter)
  })

  return {
    getTrialFeaturesState,
    trialFeatures,
    startFeaturesTrial,
  }
}
