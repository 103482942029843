<template>
  <div class="w-full relative">
    <div class="p-6">
      <div class="md:grid md:grid-cols-2 md:gap-10">
        <pre
          class="text-gray-500 whitespace-pre-wrap font-sans w-full basis-1/2 text-base"
          v-html="template.attributes.description"
        />
        <div>
          <el-carousel
            height="700px"
            :interval="4000"
            class="w-full"
            indicator-position="outside"
          >
            <el-carousel-item
              v-for="(img, i) of template.attributes.images"
              :key="img"
            >
              <el-image
                :src="img"
                :preview-src-list="template.attributes.images"
                :initial-index="i"
                preview-teleported
                hide-on-click-modal
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="px-6 py-4 mt-6 border-t border-gray-200 sticky w-full bottom-0 bg-white z-10">
      <div class="flex justify-end">
        <BaseButton
            variant="white"
            @click="$emit('close')">
            {{ $t('Close') }}
          </BaseButton>
          <BaseTooltip
            :content="$t(`Copy this template into your account so you can edit it and use it.`)"
          >
            <BaseButton
              class="ml-2"
              :loading="loading"
              @click="importTemplate"
            >
              {{ $t('Copy template') }}
            </BaseButton>
          </BaseTooltip>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { error } from '@/components/common/NotificationPlugin';
import i18n from '@/i18n';
import { ElCarousel, ElCarouselItem, ElImage } from 'element-plus'
import { PropType, ref } from 'vue';
import { useStore } from 'vuex'

import Data = API.Data;
import GalleryTemplate = App.Domains.Templates.Models.GalleryTemplate

const store = useStore()

const props = defineProps({
  template: {
    type: Object as PropType<Data<GalleryTemplate>>,
    default: () => null
  },
})

const loading = ref(false)

async function importTemplate() {
  try {
    loading.value = true
    const shareIdentifier = props.template.attributes.share_link_identifier
    await store.dispatch('templates/copySharedTemplate', shareIdentifier)
  }
  catch (e) {
    error(i18n.t('Could not import template'))
    console.log(e)
  }
  finally {
    loading.value = false
  }

}
</script>
