import { useWebWorkerFn } from '@vueuse/core'
import store from "@/store/index.js";

export function getAllocatedTimeWithRelationships(
  allocatedTime: any,
  allGroups: any[],
  allProjects: any[],
  allUsers: any[],
  allTasks: any[],
) {
  allGroups = allGroups || store.state.groups.allGroups || []
  allProjects = allProjects || store.state.projects.allProjects || []
  allUsers = allUsers || store.state.users.allUsers || []
  allTasks = allTasks || store.state.tasks.allTasks || []

  const group = allGroups.find(group => group.id == allocatedTime.attributes.group_id)
  const project = allProjects.find(project => project.id == allocatedTime.attributes.project_id)
  const user = allUsers.find(user => user.id == allocatedTime.attributes.user_id)
  const task = allTasks.find(task => task.id == allocatedTime.attributes.task_id)

  return {
    ...allocatedTime,
    relationships: {
      ...allocatedTime.relationships,
      group,
      project,
      user,
      task,
    }
  }
}


function computeRelationships(
  allAllocatedTime: any[],
  allGroups: any[],
  allProjects: any[],
  allUsers: any[],
  allTasks: any[],
) {
  return allAllocatedTime.map(allocatedTime => {
    const group = allGroups.find(group => group.id == allocatedTime.attributes.group_id)
    const project = allProjects.find(project => project.id == allocatedTime.attributes.project_id)
    const user = allUsers.find(user => user.id == allocatedTime.attributes.user_id)
    const task = allTasks.find(task => task.id == allocatedTime.attributes.task_id)

    return {
      ...allocatedTime,
      relationships: {
        ...allocatedTime.relationships,
        group,
        project,
        user,
        task,
      }
    }
  })
}

export const { workerFn: allocatedTimeWithRelationshipsWorker } = useWebWorkerFn(computeRelationships)
