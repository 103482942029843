<template>
  <BaseDataTable
    :data="data"
    :meta="meta"
    :columns="tableColumns"
    :extraFilters="extraFilters"
    :loading="loading"
  >
    <template #no-data>
      <NoTemplates
        v-if="isTemplatePath"
      />
      <NoProjects
        v-else
      />
    </template>
  </BaseDataTable>
</template>
<script lang="ts" setup>
// Utils
import { computed, PropType } from 'vue'
import { extraFilters } from '@/modules/projects/utils/projectTableUtils'

// Components
import NoProjects from "@/modules/projects/components/NoProjects.vue";
import NoTemplates from "@/modules/templates/components/NoTemplates.vue";

// Composables
import { useStore } from "vuex"

const store = useStore()

const props = defineProps({
  data: {
    type: Array as PropType<any>,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const tableColumns = computed(() => {
  return store.getters['projects/tableColumns'] || []
})

const meta = computed(() => {
  return store.state.projects.projects.meta || {}
})

const isTemplatePath = computed(() => {
  return store.getters['templates/isTemplatePath']
})
</script>
