<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="max-w-2xl"
  >
    <BaseEntityForm
      :title="$t('Search')"
      :subtitle="$t(`Search for anything across your account.`)"
      :isDialogForm="true"
      width-class="max-w-2xl"
      @submit="onSearch"
    >
      <template #default="{ meta, errors }">
        <BaseInput
          v-model="searchTerm"
          v-focus
          :label="$t('Search term')"
          :placeholder="$t('Search for anything...')"
          layout="horizontal"
        />
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          variant="white"
          @click="emit('close')"
        >
          {{ $t("Cancel") }}
        </BaseButton>
        <BaseButton
          type="submit"
          class="ml-2"
        >
          {{ $t("Search") }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()

const searchTerm = ref('')

const emit = defineEmits(['close'])

function onSearch() {
  if (!searchTerm.value) {
    return
  }

  router.push({
    path: `/search`,
    query: {
      keyword: searchTerm.value,
    },
  })

  emit('close')
}
</script>
