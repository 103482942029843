export function loadJsPdf() {
  if (window.jspdf) {
    return
  }

  const scriptUrls = [
    'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js',
    'https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js'
  ]

  for (const src of scriptUrls) {
    const script = document.createElement('script')

    script.setAttribute('src', src)

    document.head.appendChild(script)
  }
}
