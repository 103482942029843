<template>
  <ProofUserCellLoading v-if="!value" />
  <div
    v-else
    class="flex items-center gap-2 my-1"
  >
    <UserLogo
      size="sm"
      class="rounded-lg cursor-pointer shrink-0"
      :user="userEntity"
      :preview-type="userPreviewType"
      :actions="[UserPreviewActions.View]"
    />

    <div class="flex-1 min-w-0 justify-between flex items-center">
      <div class="flex items-center gap-2">
        <p
          v-if="!isOwnerColumn"
          :title="userTooltip"
          class="font-medium text-gray-500 truncate"
        >
          {{ userName }}
        </p>
        <p
          v-if="isProofOwner && !isOwnerColumn"
          class="font-semibold text-gray-500 truncate uppercase text-[10px] px-1 border border-gray-300 rounded bg-gray-100 h-min"
        >
          {{ $t('Owner') }}
        </p>
      </div>
      <div
        v-if="userComments"
        class="text-sm text-gray-400"
      >
        <i class="fa-sharp fa-solid fa-comment-dots"></i>
        <span class="ml-1 tracking-[4px]">{{ userComments }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, toRef } from "vue";
import { ICellRendererParams } from "@ag-grid-community/core";
import useCellProps from "@/components/table/composables/useCellProps";
import { get } from "lodash-es";
import { useStore } from "vuex";
import ProofUserCellLoading from "@/modules/file-proofs/components/ProofUserCellLoading.vue";
import UserLogo from "@/components/common/UserLogo.vue";
import { UserPreviewActions, UserPreviewTypes } from "@/modules/users/util/userUtils";
import { i18n } from "@/i18n";

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams>,
    required: true
  },
})

const { value, row, colDef } = useCellProps(toRef(props, 'params'))

const store = useStore()

const isOwnerColumn = computed(() => {
  return colDef.value?.prop?.includes('creator')
})

const userEntity = computed(() => {
  return value.value?.relationships?.user || value.value
})

const userName = computed(() => {
  const { first_name = '', last_name = '', email = '' } = userEntity.value?.attributes || {}
  const fullName = [first_name, last_name].filter(Boolean).join(' ')
  return fullName || email
})

const proofId = computed(() => {
  return row.value?.parentEntityId
})

const proofs = computed(() => {
  return store.state.proofs.proofs?.data || []
})

const currentProof = computed(() => {
  return proofs.value.find(proof => String(proof?.id) === String(proofId.value));
})

const isProofOwner = computed(() => {
  return String(currentProof.value?.attributes?.created_by) === String(internUserId.value)
})

const internUserId = computed(() => {
  return value.value?.attributes?.user_id
})

const userPreviewType = computed(() => {
  if (isOwnerColumn.value || internUserId.value) {
    return UserPreviewTypes.Inline
  }
  return UserPreviewTypes.Disabled
})

const userComments = computed(() => {
  return get(row.value, 'attributes.comments', '')
})

const userTooltip = computed(() => {
  return !internUserId
    ? i18n.t('External user:') + ' ' + userEntity.value?.attributes?.email
    : ''
})
</script>
