<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-2xl relative"
    @close="$emit('close')"
  >
    <BaseEntityForm
      :title="$t('Add/Remove People')"
      :isDialogForm="true"
      width-class="max-w-2xl"
    >
      <template #default="{ meta, errors }">
        <div class="flex space-x-2 w-full relative">
          <div class="w-full">
            <UserSelectNew
              v-model="addUserIds"
              :excludedOptions="groupUserIds"
              :createEntityParams="{
                enable: true,
                entityType: 'user',
                label: $t('Invite New People'),
              }"
              multiple
            />
          </div>
          <BaseButton
            :disabled="!addUserIds?.length"
            :loading="addingUsers"
            @click="inviteUsers"
          >
            <div class="flex items-center space-x-2">
              <i class="fal fa-plus" />
              <span class="hidden sm:inline">{{ $t('Add') }}</span>
            </div>
          </BaseButton>
        </div>
        <div class="flow-root mt-6">
          <ul
            class="divide-y divide-gray-200"
          >
            <GroupUser
              v-for="user in groupUsers"
              :key="user.id"
              :user="user"
              class="py-2"
              @remove="removeUser"
            />
          </ul>
        </div>
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          @click="$emit('close')"
        >
          {{ $t("I'm done adding people - Close") }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
// Components
import UserSelectNew from '@/components/selects/UserSelectNew.vue'
import GroupUser from '@/modules/groups/components/GroupUser.vue'

// Utils
import i18n from '@/i18n';
import { PropType, computed, ref } from "vue";
import { error } from '@/components/common/NotificationPlugin';
import apiCache from '@/modules/common/utils/apiCache';

import Data = API.Data;
import User = App.Domains.Users.Models.User;
import Group = App.Domains.Groups.Models.Group;

// Composables
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  group: {
    type: Object as PropType<Data<Group>>,
    default: () => ({}),
  },
})

const addUserIds = ref<number[]>([])
const addingUsers = ref<boolean>(false)

async function inviteUsers() {
  const newUserIds = addUserIds.value.filter(id => !groupUserIds.value.includes(+id))
      
  if (!newUserIds.length) {
    error(i18n.t('Selected users are already part of the group'))
    return
  }

  addingUsers.value = true

  try {
    await store.dispatch('groups/addUsersToGroup', {
      groupId: props.group?.id,
      users: newUserIds,
      silent: true,
    })

    apiCache.removeForEntity('users')
    addUserIds.value = []
  }
  catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not add users to the group'))
  }
  finally {
    addingUsers.value = false
  }
}

async function removeUser(user: Data<User>) {
  try {
    await store.dispatch('groups/removeUsersFromGroup', {
      groupId: props.group?.id,
      users: [user.id],
      silent: true,
    })

    addUserIds.value = []
    apiCache.removeForEntity('groups')
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('Could not remove user from the group'))
  }
}

const groupUsers = computed<Data<User>[]>(() => {
  return props.group?.relationships?.users || []
})

const groupUserIds = computed<number[]>(() => {
  return groupUsers.value.map((user) => +user.id)
})
</script>
