import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import UploadingFileTemplate from "@/components/html/extensions/components/UploadingFileTemplate.vue";

export default Node.create({
  name: 'uploadingFile',

  group: 'block',

  selectable: true,
  
  draggable: true,
  
  addAttributes() {
    return {
      id: {
        default: null,
      },
      uuid: {
        default: null,
      },
      name: {
        default: null,
      },
      url: {
        default: null,
      },
      type: {
        default: null,
      },
      fullScale: {
        default: false,
      },
      isDraggable: {
        default: true,
        renderHTML: (attributes) => {
          return {};
        }
      },
    }
  },

  parseHTML() {
    return [{
      tag: 'uploading-file',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['uploading-file', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(UploadingFileTemplate);
  }
});
