<template>
 <p class="text-5xl text-center mb-2">🚀</p>
  <h2 class="text-3xl font-bold leading-10 text-gray-900 text-center">
    {{ welcomeMessage }}
  </h2>
  <p class="mx-auto max-w-md text-base text-center text-gray-500">
    {{ $t(`We help you manage your projects & get visibility of all your work in one place. Lets get started.`) }}
  </p>
</template>
<script lang="ts" setup>
import i18n from "@/i18n";
import { computed } from "vue";
import { useAuth } from "@/modules/auth/composables/useAuth";

const { currentUser } = useAuth()

const welcomeMessage = computed(() => {
  return i18n.t('welcome to projectco', { name: currentUser.value?.first_name })
})
</script>
