<template>
  <div v-if="resource">
    <BaseDropdown
      :options="availableOptions"
      :slim="slim"
      :auto-open="true"
      divide-items
      @action="onAction"
    >
      <template #default>
        <slot />
      </template>
    </BaseDropdown>

    <ProjectLinkDialog
      v-if="resource.type === projectResourceTypes.ProjectLink"
      v-model="showEditDialog"
      v-show="showEditDialog"
      :link="resource"
      key="link-edit"
      @close="showEditDialog = false"
      @save="onResourceEdited"
    />
    <EmbedDialog
      v-else-if="resource.type === projectResourceTypes.ProjectEmbeds"
      v-model="showEditDialog"
      v-show="showEditDialog"
      :embed="resource"
      key="embed-edit"
      @close="showEditDialog = false"
      @save="onResourceEdited"
    />
    <ProjectReferenceDialog
      v-else-if="resource.type === projectResourceTypes.ProjectReferences"
      v-model="showEditDialog"
      v-show="showEditDialog"
      :reference="resource"
      key="reference-edit"
      @close="showEditDialog = false"
      @save="onResourceEdited"
    />
  </div>
</template>
<script>
// Utils
import {
  projectResourceTypes,
  getResourceUrl,
  getResourcePath,
} from "@/modules/resources/utils/projectResourceUtils.js";
import i18n from '@/i18n'

// Components
import EmbedDialog from "@/modules/resources/components/EmbedDialog.vue";
import ProjectLinkDialog from "@/modules/projects/components/ProjectLinkDialog.vue";
import ProjectReferenceDialog from "@/modules/projects/components/ProjectReferenceDialog.vue";

export default {
  components: {
    EmbedDialog,
    ProjectLinkDialog,
    ProjectReferenceDialog,
  },
  props: {
    toolResource: {
      type: Object,
      default: () => ({})
    },
    slim: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      projectResourceTypes,
      showEditDialog: false,
    }
  },
  computed: {
    project() {
      return this.$store.state.projects.currentProject
    },
    embeds() {
      return this.project?.relationships?.embeds || []
    },
    links() {
      return this.project?.relationships?.links || []
    },
    references() {
      return this.project?.relationships?.references || []
    },
    resource() {
      const resourceTypes = {
        [projectResourceTypes.ProjectEmbeds]: this.embeds,
        [projectResourceTypes.ProjectReferences]: this.references,
        [projectResourceTypes.ProjectLink]: this.links
      }
      return resourceTypes[this.toolResource?.type]?.find(r => r.id?.toString() === this.toolResource?.id?.toString())
    },
    resourcePath() {
      return getResourcePath(this.resource, this.$route.path)
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed'](this.project)
    },
    options() {
      return [
        {
          label: i18n.t('Open'),
          path: typeof this.resourcePath === 'string'
            ? this.resourcePath
            : this.resourcePath?.href,
          target: this.resourcePath?.target
        },
        {
          label: i18n.t('Edit'),
          action: () => this.showEditDialog = true,
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.EDIT_PROJECT_EMBEDS)
          },
        },
        {
          label: this.resource?.attributes?.pinned ? i18n.t('Unpin') : i18n.t('Pin to project dashboard'),
          action: 'toggleResourcePinned',
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.EDIT_PROJECT_EMBEDS)
          },
        },
        {
          label: i18n.t('Open in new tab'),
          action: 'openInNewTab',
        },
        {
          label: i18n.t('Delete'),
          action: 'confirmDelete',
          enabled: () => {
            return !this.isProjectClosed && this.can(this.$actions.DELETE_PROJECT_EMBEDS)
          },
        }
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    },
    embeds() {
      return this.project?.relationships?.embeds || []
    },
    links() {
      return this.project?.relationships?.links || []
    },
    references() {
      return this.project?.relationships?.references || []
    },
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      this.$emit(action, this.resource)
    },
    openInNewTab() {
      const url = getResourceUrl(this.resource)
      window.open(url, '_blank')
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Delete Resource'),
        description: i18n.t('Are you sure? This will permanently remove the resource.')
      });

      if (!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch('resources/deleteResource', { resource: this.resource });
        this.onResourceEdited()
        this.$store.dispatch('resources/getResources')
      }
      catch (err) {
        if (err.handled) {
          return;
        }
        this.$error(i18n.t('Cannot delete resource'));
        throw err
      }
    },
    async toggleResourcePinned() {
      try {
        await this.$store.dispatch('resources/setResourcePinned', {
          resource: this.resource,
          value: !Number(this.resource.attributes?.pinned || 0)
        })

        this.onResourceEdited()

        this.$success(i18n.t("Resource updated"))
      }
      catch (err) {
        this.$error(i18n.t("Could not update resource"))
      }
    },
    async onResourceEdited() {
      this.showEditDialog = false
      await this.$store.dispatch('projects/getProjectById', {
        id: this.resource.attributes.project_id,
        forceFetch: true,
        silent: true
      })
    },
  }
}
</script>
