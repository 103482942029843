<template>
  <div>
    <section aria-labelledby="quick-links-title">
      <h2 class="sr-only" id="quick-links-title">Quick links</h2>
      <div class="w-full grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        <div
          v-for="setting in filteredSettings"
          :key="setting.path"
          :class="{
            'opacity-50': !isSubscriptionValid && setting.path !== `/account-settings/billing`,
            'shadow': !$useNewLayout
          }"
          class="relative bg-white p-6 border border-gray-200 rounded-lg group"
        >
          <span class="rounded-lg inline-flex sm:m-0 p-0 text-gray-500 ring-4 ring-white">
            <div class="mr-4">
              <i
                v-if="setting.icon"
                :class="setting.icon"
                class="text-3xl"
                aria-hidden="true"
              />

              <img
                v-else-if="setting.image"
                :src="setting.image"
                class="w-14 h-14 rounded-md"
              />
            </div>
            <h3 class="text-xl font-bold text-gray-900 sm:mt-1">
              <component
                :is="isLimitReached(setting) ? 'span' : 'router-link'"
                :to="setting.path"
                class="focus:outline-none cursor-pointer"
                @click="isLimitReached(setting) ? openUpgradeDialog(setting.limitedFeature) : null"
              >
                <!-- Extend touch target to entire panel -->
                <span class="absolute inset-0" aria-hidden="true"></span>
                {{ setting.name }}
                <i
                  v-if="isLimitReached(setting)"
                  class="fas fa-crown text-yellow-500 ml-2"
                />
              </component>
            </h3>
          </span>
          <div class="border-b border-gray-200 my-4 -mx-6  hidden sm:block" />
          <p class="text-sm text-gray-500">
            {{ setting.description }}
          </p>
          <span
            class="pointer-events-none absolute top-4 right-5 text-gray-300 group-hover:text-gray-500 origin-center rotate-45"
            aria-hidden="true">
            <span class="group-hover:opacity-0 absolute"><i class="fas fa-chevron-up"></i></span>
            <span class="opacity-0 group-hover:opacity-100"><i class="fas fa-chevron-up"></i></span>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { useAccountLimits } from '@/modules/auth/composables/useAccountLimits'
import { AccountPermissions } from '@/modules/common/composables/useCan';

export default {
  setup() {
    const {
      hasReachedLimit,
      openUpgradeDialog
    } = useAccountLimits()

    return {
      hasReachedLimit,
      openUpgradeDialog
    }
  },
  computed: {
    subscription() {
      return this.$store.state.accounts.subscription || {}
    },
    isAppSumoSubscription() {
      return this.$store.getters['accounts/isAppSumoSubscription']
    },
    settings() {
      return [
        {
          path: '/getting-started',
          name: this.$t('Getting Started'),
          description: this.$t('A checklist containing all the things you need to do to set your account up for success.'),
          icon: 'fal fa-rocket',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/account-settings/account',
          name: this.$t('Account Settings'),
          description: this.$t('Set your account name and other basic settings for your account.'),
          icon: 'fal fa-building',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/account-settings/plan-limits',
          name: this.$t('Plan Limits'),
          description: this.$t('See your plan limits based on your current plan and number of available seats.'),
          icon: 'fal fa-bars-progress',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/account-settings/trial-manager',
          name: this.$t('Trial Manager'),
          description: this.$t(`Test out features your account doesn't have access to at the moment.`),
          icon: 'fa-light fa-business-time',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/account-settings/billing',
          name: this.$t('Plans & Billing'),
          description: this.$t('View & edit your plan as well as edit your payment details & view past invoices.'),
          icon: 'fal fa-credit-card',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/account-settings/code-stacking',
          name: this.$t('Code Stacking'),
          description: this.$t('Enter more codes to stack on to your plan.'),
          icon: 'fa-light fa-tags',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS,
          enabled: () => {
            return this.isAppSumoSubscription
          }
        },
        {
          path: '/account-settings/localisation',
          name: this.$t('Localisation'),
          description: this.$t('Set your account timezone, week start day and working days.'),
          icon: 'fal fa-globe-europe',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/account-settings/invoice-info',
          name: this.$t('Invoice Info'),
          description: this.$t('Specify the information that will be displayed on paid invoices.'),
          icon: 'fal fa-file-invoice',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/account-settings/project-statuses',
          name: this.$t('Project Status'),
          description: this.$t('Create & edit statuses for all projects.'),
          icon: 'fal fa-inbox',
          requiresPermissionTo: this.$actions.EDIT_PROJECT_STATUSES
        },
        {
          path: '/account-settings/task-statuses',
          name: this.$t('Task Status'),
          description: this.$t('Create & edit statuses for all tasks.'),
          icon: 'fal fa-badge-check',
          requiresPermissionTo: this.$actions.EDIT_TASK_STATUSES
        },
        {
          path: '/account-settings/custom-fields',
          name: this.$t('Custom Fields'),
          description: this.$t('Manage custom fields for projects, tasks, people & groups.'),
          icon: 'fal fa-stars',
          requiresPermissionTo: this.$actions.CREATE_CUSTOM_FIELDS
        },
        {
          path: '/account-settings/import',
          name: this.$t('Import Data'),
          description: this.$t('Import your data.'),
          icon: 'fal fa-file-export',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS,
          limitedFeature: AccountPermissions.CsvImporter,
        },
        {
          path: '/account-settings/export',
          name: this.$t('Export Data'),
          description: this.$t('Export your data to CSV file'),
          icon: 'fa-light fa-file-csv',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS
        },
        {
          path: '/account-settings/branding',
          name: this.$t('Custom Branding'),
          description: this.$t('Brand your account to make it your own.'),
          icon: 'fa-light fa-swatchbook',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS,
          requireAgencyPlan: !this.subscription.canUseCustomBranding,
          limitedFeature: AccountPermissions.CustomBranding,
        },
        {
          path: '/account-settings/custom-emails',
          name: this.$t('Custom Emails'),
          description: this.$t('Set up emails to come from your address.'),
          icon: 'fa-light fa-envelopes',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS,
          requireAgencyPlan: !this.subscription.canUseCustomBranding,
          limitedFeature: AccountPermissions.CustomEmails,
        },
        {
          path: '/account-settings/custom-domain',
          name: this.$t('Custom Domain'),
          description: this.$t('Access your account from your own domain.'),
          icon: 'fa-light fa-arrow-up-right-from-square',
          requiresPermissionTo: this.$actions.MANAGE_ACCOUNT_SETTINGS,
          requireAgencyPlan: !this.subscription.canUseCustomBranding,
          limitedFeature: AccountPermissions.CustomDomain,
        },
      ]
    },
    filteredSettings() {
      return this.settings.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }
        return !x.requiresPermissionTo || this.can(x.requiresPermissionTo)
      })
    }
  },
  methods: {
    isLimitReached(setting) {
      if (!setting.limitedFeature) {
        return false
      }

      return this.hasReachedLimit(setting.limitedFeature)
    }
  }
}
</script>
