<template>
  <BaseDialog
    v-show="modelValue"
    :modelValue="modelValue"
    :hasBodyMargin="false"
    :appendToBody="true"
    size="lg"
    @close="$emit('update:modelValue', false)"
  >
    <div class="w-full border-b border-gray-200 p-6">
      <slot name="title">
        <h3 class="text-2xl font-bold">{{ $t('Rename File') }}</h3>
      </slot>
    </div>
    <VeeForm
      #default="{ errors, meta }"
      class="divide-y divide-gray-200 file-rename-form"
      @submit="onSubmit"
    >
      <div class="divide-y divide-gray-200 sm:space-y-5 p-6">
        <div class="space-y-6 sm:space-y-5">
          <BaseInput
            v-model="model.name"
            v-focus
            :label="$t('File Name')"
            :placeholder="$t('Enter file name...')"
            :errorMessage="errors[$t('File Name')]"
            :name="$t('File Name')"
            layout="horizontal"
            rules="required"
            id="name"
          />
          
          <BaseInput
            :label="$t('Folder')"
            :name="$t('Folder')"
            layout="horizontal"
            id="folder"
            class="w-full mt-1 sm:mt-0 md:col-span-2"
          >
            <div class="sm:mt-0 sm:col-span-4">
              <FolderSelect
                v-model="model.folder_id"
                :disabled="!model.project_id"
                :projectId="model.project_id"
                :placeholder="$t('Choose a folder (optional)...')"
                :initialValue="file?.relationships?.folder"
              />
            </div>
          </BaseInput>
        </div>
      </div>

      <div class="px-6 py-4 mt-2 border-t border-gray-200">
        <div class="flex justify-end">
          <BaseButton
            variant="white"
            @click="$emit('update:modelValue', false)">
            {{ $t('Cancel') }}
          </BaseButton>
          <BaseButton
            :loading="loading"
            :disabled="!meta.valid"
            type="submit"
            class="ml-2"
          >
            {{ $t('Save') }}
          </BaseButton>
        </div>
      </div>
    </VeeForm>
  </BaseDialog>
</template>
<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import i18n from "@/i18n";
import { error, success } from "@/components/common/NotificationPlugin";
import FolderSelect from '@/components/selects/FolderSelect.vue'

const store = useStore()

const props = defineProps({
  file: {
    type: Object,
    default: () => ({})
  },
  modelValue: {
    type: Boolean,
    default: false,
  }
})
const model = ref({
  name: props.file?.attributes?.name || '',
  folder_id: props.file?.attributes?.folder_id || null,
  project_id: props.file?.attributes?.project_id || null,
})

watch(() => props.file, newValue => {
  model.value.name = newValue?.attributes?.name || ''
}, { deep: true })
const loading = ref(false)

const emit = defineEmits(['save'])

async function onSubmit() {
  try {
    loading.value = true
    await store.dispatch('files/updateFile', {
      id: props.file.id,
      name: model.value.name,
      folder_id: model.value.folder_id || null,
    })
    success(i18n.t('File updated'))
    emit('save', model.value.name)
    model.value.name = ''
  } catch (err) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not update file'))
  } finally {
    loading.value = false
  }
}
</script>
