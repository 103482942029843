import mustache from 'mustache'

import config_common from '@/modules/ai/templates/config_common.txt?raw'

// Common
import { getOrganizationConfig } from '@/modules/ai/functions/config'
import { ActionChoices } from '@/modules/ai/types/aiTypes'

// Projects
import system_projects_create from '@/modules/ai/templates/system_projects_create.txt?raw'
import system_projects_edit from '@/modules/ai/templates/system_projects_edit.txt?raw'
import { Privacy } from '@/modules/projects/utils/projectHelpers'

// Tasks
import system_tasks_create from '@/modules/ai/templates/system_tasks_create.txt?raw'
import system_tasks_edit from '@/modules/ai/templates/system_tasks_edit.txt?raw'
import { visibilityTypes } from '@/modules/tasks/utils/modelUtils';
import { taskDateTypes } from "@/modules/tasks/utils/modelUtils.js";

function organizationConfig() {
  return JSON.parse(getOrganizationConfig())
}

function getCommonModel() {
  const orgConfig = organizationConfig()
  return {
    currentUser: orgConfig.currentUser.name,
    currentProject: orgConfig.currentProject?.name || 'no project opened',
    today: new Date().toISOString().substring(0, 10),
  }
}

function getMainTemplate(action: ActionChoices) {
  switch (action) {
    case ActionChoices.CreateProject:
      return system_projects_create
    case ActionChoices.UpdateProject:
      return system_projects_edit
    case ActionChoices.CreateTasks:
      return system_tasks_create
    case ActionChoices.UpdateTasks:
      return system_tasks_edit
    default:
      throw new Error('Action not supported')
  }
}


function extendProjectModel(model: any, orgConfig: any) {
  return {
    ...model,
    projectPrivacyOptions: [
      Privacy.Account,
      Privacy.Private,
      Privacy.Project
    ].join(', '),
    projectStatusOptions: orgConfig.projectStatuses.map((s: any) => s.name).join(', '),
    projectToolOptions: orgConfig.tools.map((t: any) => t.name).join(', '),
    defaultProjectPrivacy: orgConfig.defaultProjectPrivacy,
    defaultProjectStatus: orgConfig.defaultProjectStatus.name,
    defaultProjectStartDate: new Date().toISOString().substring(0, 10),
  }
}

function extendTaskModel(model: any, orgConfig: any) {
  return {
    ...model,
    taskPrivacyOptions: [
      visibilityTypes.CREATORS_ONLY,
      visibilityTypes.CREATORS_AND_COLLABORATORS
    ].join(', '),
    taskDateTypes: [
      taskDateTypes.NO_DATE,
      taskDateTypes.SINGLE_DATE,
      taskDateTypes.DATE_RANGE,
    ].join(', '),
    taskStatusOptions: orgConfig.taskStatuses.map((s: any) => s.name).join(', '),
    defaultTaskPrivacy: orgConfig.defaultTaskVisibility,
    defaultTaskStatus: orgConfig.defaultTaskStatus.name,
  }
}

function extendModel(model: any, action: ActionChoices) {
  const orgConfig = organizationConfig()

  switch(action) {
    case ActionChoices.CreateProject:
      return extendProjectModel(model, orgConfig)
    case ActionChoices.UpdateProject:
      return extendProjectModel(model, orgConfig)
    case ActionChoices.CreateTasks:
      return extendTaskModel(model, orgConfig)
    case ActionChoices.UpdateTasks:
      return extendTaskModel(model, orgConfig)
    default:
      throw new Error('Action not supported')
  }
}

export function getSystemMessage(action: ActionChoices) {
  const commonConfig = mustache.render(config_common, getCommonModel())
  let model: any = {
    commonConfig
  }

  const template = getMainTemplate(action)

  model = extendModel(model, action)

  return mustache.render(template, model)
}

