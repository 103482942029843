<template>
 <div :title="disableTooltip ? '' : name">
   <BaseAvatar
    v-if="logo"
    :avatar="logo"
    :size="size"
    :class="$attrs.class"
    :rounded="rounded"
   />
   <div v-else
    class="uppercase text-white flex items-center justify-center tracking-wider leading-6 font-normal initial-avatar"
    :class="[imageColor, {
      'h-5 w-5 text-xs': size === 'xs',
      'h-8 w-8 text-xs': size === 'sm',
      'h-10 w-10 text-sm': size === 'md',
      'h-14 w-14 text-base': size === 'lg',
      'h-20 w-20 text-lg': size === 'xl',
      'h-24 w-24 text-xl': size === 'xxl',
      'h-full w-full': size === 'full',
      [$attrs.class]: $attrs.class,
      [`rounded-${rounded}`]: true,
    }]"
   >
     {{ shortName }}
   </div>
 </div>
</template>
<script>
import { getShortString, getStringColor } from "@/modules/projects/utils/projectHelpers.js";
import config from "@/modules/common/config.js";

export default {
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'md'
    },
    logoKey: {
      type: String,
      default: 'attributes.image'
    },
    nameKey: {
      type: String,
      default: 'attributes.name'
    },
    disableTooltip: {
      type: Boolean
    },
    imageSrc: {
      type: String,
      default: null
    },
    rounded: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    name() {
      const fallbackKey = 'attributes.email'
      return this.get(this.entity, this.nameKey) || this.get(this.entity, fallbackKey) || ''
    },
    logo() {
      if (this.imageSrc) {
        return this.imageSrc
      }

      const logo = this.get(this.entity, this.logoKey)
      // workaround because api returns storage string instead of null when avatar is missing
      if (logo === `${config.ROOT_URL}/storage/`) {
        return ''
      }
      return logo
    },
    shortName() {
      return getShortString(this.name)
    },
    imageColor() {
      return getStringColor(this.name)
    }
  }
}
</script>
<style>
</style>
