<template>
  <BaseNoDataSection
    :title="$t('No search results found.')"
    iconClass="fa-search"
    :canCreate="true"
  >
    <template #can-create-section>
      <div>{{ $t('Please try again.') }} </div>
    </template>
  </BaseNoDataSection>
</template>
