<template>
  <div>
    <BaseDropdown
      :options="availableOptions"
      :slim="slim"
      :auto-open="true"
      divide-items
    >
      <template #default>
        <slot />
      </template>
    </BaseDropdown>
  </div>
</template>
<script lang="ts" setup>
import { PropType, computed } from "vue";
import { useStore } from "vuex";
import { $deleteConfirm } from '@/components/common/dialog/modalPlugin'
import useEntityCrud, { EntityTypes } from "@/modules/common/composables/useEntityCrud"
import i18n from "@/i18n";
import { error, success } from '@/components/common/NotificationPlugin/index.js'
import { IRowNode } from "@ag-grid-community/core";
import useCan from "@/modules/common/composables/useCan"
const { can, actions } = useCan()

const {
  entityTypes,
  triggerEntityCreate
} = useEntityCrud()

const store = useStore()

const props = defineProps({
  folder: {
    type: Object,
    default: () => ({})
  },
  node: {
    type: Object as PropType<IRowNode>,
    default: () => ({}),
  },
  slim: {
    type: Boolean,
    default: false,
  }
})

const project = computed(() => {
  return props.folder?.relationships?.project
})

const isProjectClosed = computed(() => {
  return store.getters['projects/isProjectClosed'](project.value)
})

const isEmpty = computed(() => {
  return !props.node.allChildrenCount
})

function editFolder() {
  triggerEntityCreate(entityTypes.Folder as EntityTypes, {
    folder: props.folder,
  })
}

function uploadFiles() {
  triggerEntityCreate(entityTypes.File as EntityTypes, {
    folder: props.folder,
  })
}

function addSubfolder() {
  const model = {
    attributes: {
      project_id: project.value?.id,
      parent_folder_id: props.folder.id,
    },
    relationships: {
      project: project.value,
      parentFolder: props.folder,
    }
  }
  triggerEntityCreate(entityTypes.Folder as EntityTypes, {
    folder: model,
  })
}

async function confirmDeleteFolder(withContents = false) {
  const title = withContents
    ? i18n.t('Delete folder & contents')
    : i18n.t('Delete folder')

  const description = withContents
    ? i18n.t('Are you sure? This will permanently delete the folder, subfolders & files.')
    : i18n.t('Are you sure? This will permanently delete the folder.')

  const confirmed = await $deleteConfirm({
    title,
    description
  })

  if (!confirmed) {
    return
  }

  try {
    await store.dispatch('files/deleteFolder', { folder: props.folder, withContents })
    success(i18n.t('Folder deleted successfully'))
  }
  catch (err: any) {
    if (err.handled) {
      return;
    }
    error(i18n.t('Cannot delete folder'));
    throw err
  }
}

function downloadFolder() {
  const file_ids =  (props.node.allLeafChildren || [])
    .filter(node => node.data.type === 'media')
    .map(node => node.data.id)

  if (!file_ids.length) {
    error(i18n.t('Folder is empty'))
    return
  }

  const folderName = props.folder.attributes.name

  store.dispatch('files/downloadZip', {
    folder_id: props.folder.id,
    folderName,
  })
}

const options = [
  {
    label: i18n.t('Upload Files'),
    action: uploadFiles,
    enabled: () => {
      return !isProjectClosed.value && can(actions.CREATE_PROJECT_FILES)
    },
  },
  {
    label: i18n.t('Add Subfolder'),
    action: addSubfolder,
    enabled: () => {
      return !isProjectClosed.value && can(actions.CREATE_PROJECT_FOLDERS)
    },
  },
  {
    label: i18n.t('Edit Folder'),
    action: editFolder,
    enabled: () => {
      return !isProjectClosed.value && can(actions.EDIT_PROJECT_FOLDERS)
    },
  },
  {
    label: i18n.t('Download as archive'),
    action: downloadFolder,
  },
  {
    label: i18n.t('Delete Folder'),
    action: () => confirmDeleteFolder(),
    disabled: !isEmpty.value,
    tooltip: isEmpty.value
      ? ''
      : i18n.t('The folder must be empty before you can delete it. Please drag files out of the folder first.'),
    enabled: () => {
      return !isProjectClosed.value && can(actions.DELETE_PROJECT_FOLDERS)
    },
  },
  {
    label: i18n.t('Delete Folder & all its contents'),
    action: () => confirmDeleteFolder(true),
    icon: 'fas fa-triangle-exclamation text-red-500 group-hover:text-white',
    variant: 'danger',
    tooltip: i18n.t('Folder, subfolders & files will be deleted permanently'),
    enabled: () => {
      return !isProjectClosed.value && can(actions.DELETE_PROJECT_FOLDERS)
    },
  },
]

const availableOptions = computed(() => {
  return options.filter((option: any) => {
    if (option.enabled) {
      return option.enabled()
    }
    return !option.requiresPermissionTo || store.getters['permissions/can'](option.requiresPermissionTo)
  })
})
</script>
