import store from '@/store';
import i18n from '@/i18n';
import { getProjectImages } from "@/modules/projects/utils/projectHelpers";
import { ChatMessageTypes } from '@/modules/ai/types/aiTypes';
import { Activities, trackPostHog } from "@/modules/common/utils/trackingUtils";

const state = () => ({
  // Common
  aiChatDialogTrigger: 1,
  // Action mode
  threadId: null,
  entityView: null,
  chatMessages: [],
  awaitingResponse: false,
  requiresUserAction: false,
  isExecutingAction: false,
  actionResult: null,
  // Action mode new
  actionModeAction: null,
  actionModeMessages: [],
  actionModeHistory: [],
  // Create mode
  createModeHistory: [],
  createModeMessages: [],
  showConversationPrompt: false,
})

const mutations = {
  createEntityView(state, data) {
    state.entityView = data
  },
  setThreadId(state, data) {
    state.threadId = data
  },
  addChatMessage(state, data) {
    state.chatMessages.push(data)
  },
  clearChatMessages(state) {
    state.chatMessages = []
  },
  setAwaitingResponse(state, value) {
    state.awaitingResponse = value
  },
  setRequiresUserAction(state, value) {
    state.requiresUserAction = value
  },
  setIsExecutingAction(state, value) {
    state.isExecutingAction = value
  },
  triggerAiChatDialog(state) {
    state.aiChatDialogTrigger++
  },
  setActionResult(state, data) {
    state.actionResult = data
  },
  // Action mode
  setActionModeAction: (state, action) => {
    state.actionModeAction = action
  },
  addActionModeMessage(state, data) {
    state.actionModeMessages.push(data)
  },
  setActionModeMessages(state, data) {
    state.actionModeMessages = data
  },
  setActionModeHistory(state, data) {
    state.actionModeHistory = data
  },
  pushActionChoice(state) {
    if (state.actionModeMessages[state.actionModeMessages.length - 1]?.type === ChatMessageTypes.ActionChoice) {
      return
    }

    state.actionModeMessages.push({
      type: ChatMessageTypes.ActionChoice,
      date: new Date(),
      data: {}
    })
  },
  // Create mode
  addCreateModeMessage(state, data) {
    state.createModeMessages.push(data)
  },
  setCreateModeMessages(state, data) {
    state.createModeMessages = data
  },
  setCreateModeHistory(state, data) {
    state.createModeHistory = data
  },
  updateCreateMessageText(state, textMarkdown) {
    state.createModeMessages[state.createModeMessages.length - 1].message = textMarkdown
  },
  updateCreateMessageIsStreaming(state, isStreaming) {
    state.createModeMessages[state.createModeMessages.length - 1].isStreaming = isStreaming
  },
  setShowConversationPrompt(state, value) {
    state.showConversationPrompt = value
  },
}

const actions = {
  async create_entity_view({commit, dispatch}, data){
    commit('createEntityView', data);
  },
  async add_or_update_project({ rootState, dispatch, commit }, model) {
    if (!model.id) {
      const {
        image,
        cover_image
      } = await getProjectImages()

      model.image = image
      model.cover_image = cover_image
    }

    let project

    if (model.id) {
      project =  await store.dispatch('projects/updateProject', {
          projectId: model.id,
          model
      })
      trackPostHog(Activities.AiActionProjectUpdated)
    } else {
      project = await store.dispatch('projects/createProject', model)
      trackPostHog(Activities.AiActionProjectCreated)
    }

    if (model.tool_ids) {
      model.tool_ids = model.tool_ids.map(Number)

      rootState.projects.tools.data
        ?.filter(tool => model.tool_ids.includes(+tool.id))
        ?.map(tool => {
          dispatch('projects/addProjectTool', {
            tool,
            projectId: project.id,
          }, {root: true})
        });
    }

    if (model.user_ids) {
      dispatch('projects/addProjectUsers', {
        users: model.user_ids,
        projectId: project.id,
      }, {root: true})
    }

    const actionResult = {
      action: model.id ? 'update' : 'create',
      entity: 'project',
      name: project.attributes.name,
      id: project.id,
      url: '/projects/' + project.id
    }
    
    commit('setActionResult', actionResult)
    return actionResult
  },
  async add_or_update_group({ commit, dispatch }, model) {
    const userIds = model.user_ids || [];

    const { data: group } = model.id
      ? await store.dispatch('groups/editGroup', {
        groupId: id,
        data: model,
      })
      : await store.dispatch('groups/createGroup', model);

    if (userIds?.length) {
      await dispatch('groups/addUsersToGroup', {
        users: userIds,
        groupId: group.id,
      }, {root: true})
    }

    const actionResult = {
      action: model.id ? 'update' : 'create',
      entity: 'group',
      name: group.attributes.name,
      id: group.id,
      url: `/groups/${group.id}`
    }

    commit('setActionResult', actionResult)

    return actionResult
  },
  async add_or_update_task({ commit }, model) {
    const task = model.id
      ? await store.dispatch('tasks/updateTask', {
        taskId: model.id,
        model: model,
      })
      : await store.dispatch('tasks/createTask', {
        data: model
      });

    const actionResult = {
      action: model.id ? 'update' : 'create',
      entity: 'task',
      name: task.attributes.name,
      id: task.id,
      url: '/tasks/' + task.id
    }

    commit('setActionResult', actionResult)

    return actionResult
  },
  async add_bulk_tasks({ commit }, model) {
    const taskModels = model

    console.log('add_bulk_tasks - taskModels', { taskModels })

    const bulkTasks = await store.dispatch('tasks/bulkCreateTasks', {
      taskModels
    })

    trackPostHog(Activities.AiActionTasksCreated, {
      count: bulkTasks.length
    })

    console.log('bulkTasks', {bulkTasks})

    const actionResult = {
      entity: 'task',
      names: bulkTasks.map(task => task.name || task.attributes?.name),
      ids: bulkTasks.map(task => task.id),
      action: 'bulkCreate',
      urls: bulkTasks.map(task => `/tasks/${task.id}`),
      url: `/projects/${bulkTasks[0].project_id}/tasks/list`,
      urlLabel: i18n.t('Click here to view them')
    }

    commit('setActionResult', actionResult)
    return actionResult
  },
  async edit_bulk_tasks({ commit }, model) {
    const taskModels = model

    console.log('edit_bulk_tasks - taskModels', { taskModels })

    const bulkTasks = await store.dispatch('tasks/updateTasks', {
      taskModels,
      syncWithServer: true
    })

    trackPostHog(Activities.AiActionTasksUpdated, {
      count: bulkTasks.length
    })

    console.log('bulkTasks - result', { bulkTasks })

    const actionResult = {
      entity: 'task',
      names: bulkTasks.map(task => task.name || task.attributes?.name),
      ids: bulkTasks.map(task => task.id),
      action: 'bulkEdit',
      urls: bulkTasks.map(task => `/tasks/${task.id}`),
      url: `/projects/${bulkTasks[0].attributes.project_id}/tasks/list`,
      urlLabel: i18n.t('Click here to view them')
    }

    commit('setActionResult', actionResult)
    return actionResult

  },
  async create_project_from_template({ commit }, model) {
    const templateId = model.template_id

    const project = await store.dispatch('templates/createProjectFromTemplate', {
      templateId,
    })

    const actionResult = {
      action: 'create',
      entity: 'project',
      id: project.id,
      name: project.attributes.name,
      url: `/projects/${project.id}`
    }

    commit('setActionResult', actionResult)
    return actionResult
  },
  // Create mode
  startNewCreateConversation({ commit, state }) {
    commit('addCreateModeMessage', {
      type: ChatMessageTypes.System,
      message: i18n.t('Conversation ended. You can start a new one by typing a message.'),
      variant: 'info',
    })

    commit('setCreateModeHistory', [...state.createModeHistory, ...state.createModeMessages])
    commit('setCreateModeMessages', [])
  },
  clearCreateChat({ commit }) {
    commit('setCreateModeHistory', [])
    commit('setCreateModeMessages', [])
    commit('setShowConversationPrompt', false)
  },
  // Action mode
  resetActionMode({ state, commit }) {
    commit('setActionModeHistory', [...state.actionModeHistory, ...state.actionModeMessages])
    commit('setActionModeMessages', [])
    commit('pushActionChoice')
  },
  clearActionChat({ commit }) {
    commit('setActionModeHistory', [])
    commit('setActionModeMessages', [])
    commit('pushActionChoice')

    commit('setAwaitingResponse', false)
    commit('setRequiresUserAction', false)
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
