<template>
  <base-tooltip :content="tip">
    <base-button
      :disabled="disabled"
      :variant="isRunning ? 'danger': 'white'"
      :class="{
        'focus:border-transparent': isRunning,
        'focus:border-gray-200': !isRunning,
      }"
      class="focus:outline-0 focus:ring-0"
      :size="size"
      @click="triggerTimerAction()"
    >

      <div class="flex items-center space-x-2 isRunningClass">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             aria-hidden="true">
          <circle cx="12" cy="12" r="10"/>
          <polyline
            points="12 6 12 12"
            :class=" {
                'clock-running-minute-hand': isRunning
            }"
          />
          <polyline
            points="12 12 16 14"
            :class="{
              'clock-running-hour-hand': isRunning
            }"
          />
        </svg>
        <span>
          {{ actionLabel }}
        </span>
      </div>
    </base-button>
  </base-tooltip>
</template>
<script setup>

const props = defineProps({
  tip: String,
  actionLabel: String,
  isRunning: Boolean,
  disabled: Boolean,
  size: {
    type: String,
    default: 'xs'
  }
})

const emit = defineEmits(['triggerTimerAction'])

function triggerTimerAction() {
  emit('triggerTimerAction')
}

</script>
<style lang="scss">
@keyframes rotateThreeSixty {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clock-running-minute-hand {
  animation: rotateThreeSixty 10s infinite linear;
  transform-origin: 50% 50%;
}

.clock-running-hour-hand {
  animation: rotateThreeSixty 360s infinite linear;
  transform-origin: 50% 50%;
}
</style>
