<template>
  <div class="flex text-gray-500 text-sm group">
    <div class="mr-4 flex-shrink-0">
      <div class="inline-block h-10 w-10 sm:h-14 sm:w-14 rounded-md mt-1 bg-gray-100"></div>
    </div>
    <div class="space-y-2 w-full">
      <div class="space-x-3 bg-gray-100 h-4 w-1/2 rounded"></div>
      <div class="bg-gray-100 w-full text-gray-100 rounded h-10"></div>

      <div class="flex bg-gray-100 rounded h-5 w-1/3"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingComment',
}
</script>