import DefaultCell from "@/components/table/cells/DefaultCell.vue"
import FormattedDateCell from "@/components/table/cells/FormattedDateCell.vue"
import EntityLogoCell from "@/components/table/cells/EntityLogoCell.vue"
import EntityActionsCell from "@/components/table/cells/EntityActionsCell.vue"
import LinkCell from "@/components/table/cells/LinkCell.vue"
import CustomFieldCell from "@/components/table/cells/CustomFieldCell.vue"
import StatusCell from "@/components/table/cells/StatusCell.vue"
import ProjectCellNew from "@/components/table/cells/ProjectCellNew.vue"
import TaskCell from "@/components/table/cells/TaskCell.vue"
import UserListCell from "@/components/table/cells/UserListCell.vue"
import GroupListCell from "@/components/table/cells/GroupListCell.vue"
import TaskDescriptionCell from "@/components/table/cells/TaskDescriptionCell.vue"
import CheckboxCell from "@/components/table/cells/CheckboxCell.vue"
import UserStatusCell from "@/components/table/cells/UserStatusCell.vue"
import UserRoleCell from "@/components/table/cells/UserRoleCell.vue"
import FilePreviewCell from "@/components/table/cells/FilePreviewCell.vue"
import PaymentStatusCell from "@/components/table/cells/PaymentStatusCell.vue"
import FormattedPriceCell from "@/components/table/cells/FormattedPriceCell.vue"
import FormattedTimeCell from "@/components/table/cells/FormattedTimeCell.vue"
import ProjectResourceCell from "@/components/table/cells/ProjectResourceCell.vue"
import DraggableCell from "@/components/table/cells/DraggableCell.vue"
import EntityCardCell from "@/components/table/cells/EntityCardCell.vue"
import TimerCell from "@/components/table/cells/TimerCell.vue"
import TimeRunningClockCell from "@/components/table/cells/TimeRunningClockCell.vue"
import ProofNameCell from "@/modules/file-proofs/components/ProofNameCell.vue";
import ProofProgress from "@/modules/file-proofs/components/ProofProgress.vue";
import ProofUserCell from "@/modules/file-proofs/components/ProofUserCell.vue";
import ProofStatusCell from "@/modules/file-proofs/components/ProofStatusCell.vue";

export const tableCellComponents: any = {
  DefaultCell,
  FormattedDateCell,
  EntityLogoCell,
  LinkCell,
  CustomFieldCell,
  EntityActionsCell,
  StatusCell,
  ProjectCellNew,
  TaskCell,
  UserListCell,
  GroupListCell,
  TaskDescriptionCell,
  CheckboxCell,
  UserStatusCell,
  UserRoleCell,
  FilePreviewCell,
  PaymentStatusCell,
  FormattedPriceCell,
  FormattedTimeCell,
  ProjectResourceCell,
  DraggableCell,
  EntityCardCell,
  TimerCell,
  TimeRunningClockCell,
  ProofNameCell,
  ProofProgress,
  ProofUserCell,
  ProofStatusCell
}

export enum ColumnTypes {
  Default = 'DefaultCell',
  Custom = 'custom',
  EntityActions ='EntityActionsCell',
  CustomField = 'CustomFieldCell',
  Link = 'LinkCell',
  EntityLogo = 'EntityLogoCell',
  Status = 'StatusCell',
  Date = 'FormattedDateCell',
  Project = 'ProjectCellNew',
  Task = 'TaskCell',
  UserList = 'UserListCell',
  GroupList = 'GroupListCell',
  TaskDescription = 'TaskDescriptionCell',
  Checkbox = 'CheckboxCell',
  UserStatus = 'UserStatusCell',
  UserRole = 'UserRoleCell',
  FilePreview = 'FilePreviewCell',
  PaymentStatus = 'PaymentStatusCell',
  FormattedPrice = 'FormattedPriceCell',
  FormattedTime = 'FormattedTimeCell',
  ProjectResource = 'ProjectResourceCell',
  Draggable = 'DraggableCell',
  EntityCard = 'EntityCardCell',
  Timer = 'TimerCell',
  TimeRunningClock = 'TimeRunningClockCell',
  ProofName = 'ProofNameCell',
  ProofProgress = 'ProofProgress',
  ProofUser = 'ProofUserCell',
  ProofStatus = 'ProofStatusCell'
}

export enum HeaderTypes {
  AddCustomField = 'AddCustomFieldHeader',
  EditStatuses = 'EditStatusesHeader'
}
