<template>
  <div class="discussion-comment-container">
    <div
      class="flex text-gray-500 text-sm relative discussion-comment"
      :class="{
        [`${entityType}-discussion-comment`]: true,
        'is-own-comment': isOwnComment,
        'flex-row-reverse': shouldAlignRight,
        'is-edit-mode': isInEditMode,
        'use-new-layout': $useNewLayout,
        'is-inside-notification-card': isInsideNotificationCard,
      }"
    >
      <div v-if="discussion.id"
        class="flex"
        :class="{
          'flex-row-reverse': shouldAlignRight,
          'max-w-2xl': !isInsideNotificationCard
        }"
      >
        <div
          class="shrink-0"
          :class="{
            'ml-4': shouldAlignRight,
            'mr-4': !shouldAlignRight,
            'pt-6': !isInsideNotificationCard,
            'pt-1': isInsideNotificationCard,
          }"
        >
          <BaseLogo
            :entity="creator"
            logo-key="attributes.avatar"
            size="md"
          />
        </div>
        <div class="overflow-x-hidden flex-1">
          <div class="flex flex-col space-x-0 sm:space-x-3 sm:flex-row mr-8">
            <div class="inline font-bold text-gray-700 text-base">{{ creatorName }}</div>
            <div class="inline font-bold text-xs text-gray-400 pt-1">
              <span>{{ formattedDate }}</span>
              <span
                v-if="wasEdited"
                class="text-xs text-gray-400"
              >
            ({{ $t('edited') }})
          </span>
            </div>
            <ul
              v-if="hasCommentControls"
              class="invisible discussion-controls bg-white text-gray-400 text-base px-1 flex items-center"
              :class="{
                'left-0 !-ml-0': !shouldAlignRight,
                'right-0': shouldAlignRight,
              }"
            >
              <EmojiPickerPopover
                v-if="!readonly"
                @change="onEmojiPick"
              >
                <li
                  :title="$t('Add a reaction')"
                  class="inline px-2 hover:text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer"
                >
                  <i class="fa-regular fa-face-smile" />
                </li>
              </EmojiPickerPopover>
              <li
                v-if="level === 1 && !readonly"
                :title="$t('Reply in thread')"
                class="inline px-2 hover:text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer"
                @click="openThreadDialog"
              >
                <i class="fa-regular fa-message-lines" />
              </li>
              <li
                v-if="actionOptions.length > 0"
                :title="$t('Comment options')"
                class="inline px-2 text-gray-400 hover:text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer select-none -mt-0.5"
              >
                <BaseDropdown
                  :append-to-body="false"
                  :options="actionOptions"
                  class="inline-flex items-center"
                  @action="onAction"
                >
                  <i class="fa-regular fa-ellipsis-vertical" />
                </BaseDropdown>
              </li>
            </ul>
          </div>

          <template v-if="isInEditMode">
            <HtmlEditor
              v-model="editModel"
              v-model:mentions="mentions"
              :projectId="computedProjectId"
              :char-limit="MessageCharLimit"
              :commands-ids="[entityTypes.Reference]"
              :placeholder="editorPlaceholder"
              layout="box"
              :id="`html-comment-input-edit-${discussion.id}`"
            >
              <template #footer-right>
                <BaseButton
                  variant="white"
                  size="xs"
                  @click="cancelEdit"
                >
                  {{ $t('Cancel') }}
                </BaseButton>
                <BaseButton
                  size="xs"
                  @click="saveOrEnterEdit"
                >
                  {{ $t('Save') }}
                </BaseButton>
              </template>
            </HtmlEditor>
          </template>

          <template v-else>
            <HtmlEditor
              :modelValue="message"
              :char-limit="MessageCharLimit"
              readonly
              :contentClass="editorContentClass"
            />
          </template>

          <div
            v-if="discussion.attributes?.thread_count && level === 1"
            class="flex -space-x-1 relative z-0 overflow-hidden mt-2"
            @click="openThreadDialog"
          >
            <UserLogo
              v-for="user in get(discussion, 'attributes.reply_users', [])"
              :key="user.id"
              :user="{
                ...user,
                attributes: { ...user }
              }"
              :previewType="UserPreviewTypes.Disabled"
              size="xs"
              class="relative inline-block h-6 w-6 ring-2 ring-white rounded-full"
            />

            <button
              :title="$t('Reply in thread')"
              type="button"
              class="px-4 pt-1 text-xs font-medium text-primary-500 cursor-pointer"
            >
              {{ $tc('reply count', { count: discussion.attributes?.thread_count }) }}
              <i class="fa-solid fa-arrow-right ml-1" />
            </button>
          </div>
          <CommentReactions
            ref="commentReactions"
            class="mt-1"
            :reactions="reactions"
            :discussion-id="discussion.id"
            :readonly="readonly"
          />

        </div>
      </div>
      <template v-else>
        <p class="inline font-medium text-sm pt-1">
          {{ $t('This comment was deleted or is missing') }}
        </p>
      </template>
    </div>

    <CommentThread
      v-if="showThreadDialog"
      v-model="showThreadDialog"
      :discussion="discussion"
      :entityType="entityType"
      :entityId="entityId"
      :default-project-id="projectId"
      :appendToBody="appendThreadToBody"
      :readonly="readonly"
    />
  </div>
</template>
<script>
// Components
import UserLogo from "@/components/common/UserLogo.vue";
import ReadMore from "@/components/common/ReadMore.vue";
import CommentReactions from "@/modules/common/components/CommentReactions.vue";
import CommentThread from "@/modules/common/components/CommentThread.vue";
import EmojiPickerPopover from "@/components/selects/EmojiPickerPopover.vue";

// Utils
import { MessageCharLimit } from "@/modules/projects/utils/discussionUtils.js";
import { encodeEmoji } from "@/modules/common/utils/emojiUtils.js";
import { entityTypes } from '@/modules/common/enum/entityTypes'
import { UserPreviewTypes } from "@/modules/users/util/userUtils";

const CommentActions = {
  Edit: 'edit',
  Delete: 'delete',
}
export default {
  name: 'DiscussionComment',
  components: {
    UserLogo,
    ReadMore,
    CommentThread,
    CommentReactions,
    EmojiPickerPopover,
  },
  props: {
    discussion: {
      type: Object,
      required: true
    },
    entityType: {
      type: String,
      default: 'project'
    },
    editable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 1,
    },
    projectId: {
      type: [String, Number],
    },
    taskId: {
      type: [String, Number],
    },
    appendThreadToBody: {
      type: Boolean,
      default: false,
    },
    tryOpenThreadDialogOnMount: {
      type: Boolean,
      default: true,
    },
    isInsideNotificationCard: {
      type: Boolean,
      default: false,
    },
    isThreadParent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update', 'delete'],
  data() {
    return {
      entityTypes,
      MessageCharLimit,
      showThreadDialog: false,
      isInEditMode: false,
      editModel: '',
      mentions: [],
      UserPreviewTypes
    }
  },
  computed: {
    shouldAlignRight() {
      return this.isOwnComment && !(this.isInsideNotificationCard || this.isThreadParent)
    },
    editorContentClass() {
      const base = 'text-sm prose-p:mt-2 prose-p:mb-0 prose-ul:mt-2 prose-ul:mb-0 prose-ol:mt-2 prose-ol:mb-0 rounded-md'
      if (this.isInsideNotificationCard) {
        return `${base} text-gray-500`
      }

      if (this.isOwnComment) {
        return this.$useNewLayout
          ? `${base} breadcrumb-color body-background px-3 py-1`
          : `${base} text-white bg-primary-500 px-3 py-1`
      }

      return `${base} text-gray-500 bg-gray-50 border border-gray-200 px-3 py-1`
    },
    computedProjectId() {
      return this.projectId || this.$route.params.id || this.$route.query.projectId
    },
    computedTaskId() {
      return this.taskId || this.$route.query.taskId
    },
    entityId() {
      return this.computedTaskId || this.computedProjectId
    },
    actionOptions() {
      const options = [
        {
          label: this.$t('Edit'),
          action: CommentActions.Edit,
          enabled: () => this.canEdit
        },
        {
          label: this.$t('Delete'),
          action: CommentActions.Delete,
          enabled: () => this.canEdit
        },
      ]
      return options.filter(option => {
        if (option.enabled) {
          return option.enabled()
        }
        return true
      })
    },
    hasCommentControls() {
      return this.actionOptions.length || !this.readonly || (this.level === 1 && !this.readonly)
    },
    canEdit() {
      return !this.readonly && this.editable && this.isOwnComment && this.can(this.$actions.EDIT_PROJECT_DISCUSSION_COMMENTS)
    },
    creator() {
      return this.discussion?.relationships?.creator
    },
    creatorName() {
      return this.creator?.attributes?.name
    },
    dateToDisplay() {
      return this.discussion.attributes?.updated_at || this.discussion.attributes?.created_at
    },
    formattedDate() {
      return this.$formatDate(
        new Date(this.dateToDisplay),
        null,
        /* lowerCaseMeridians */ true,
        /* withTime */ true,
      )
    },
    wasEdited() {
      if (!this.editable || !this.discussion.attributes?.created_at || !this.discussion.attributes?.updated_at) {
        return false
      }
      return this.discussion.attributes?.created_at !== this.discussion.attributes?.updated_at
    },
    message() {
      return this.discussion.attributes?.message
    },
    isOwnComment() {
      return this.creator?.id?.toString() === this.$user.id.toString()
    },
    reactions() {
      return this.discussion?.relationships?.reactions || []
    },
    editorPlaceholder() {
      if (this.$isMobileDevice) {
        return this.$t('Enter your comment...')
      }

      return this.$t('Enter your comment, type @ to mention someone or type / for options…')
    }
  },
  methods: {
    onAction(action) {
      if (action === CommentActions.Edit) {
        this.saveOrEnterEdit();
      } else if (action === CommentActions.Delete) {
        this.confirmDelete();
      }
    },
    async onEmojiPick(emoji) {
      const encodedEmoji = encodeEmoji(emoji.unicode)
      await this?.$refs.commentReactions.onReactionClick(encodedEmoji)
    },
    async openThreadDialog() {
      if (this.level !== 1) {
        return
      }
      this.showThreadDialog = !this.showThreadDialog
      if (!this.showThreadDialog) {
        return
      }
      await this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          projectId: this.computedProjectId,
          taskId: this.taskId,
          threadId: this.discussion.id,
        }
      })
    },
    cancelEdit() {
      this.isInEditMode = false;
      this.editModel = '';
    },
    saveOrEnterEdit() {
      if (!this.isInEditMode) {
        this.editModel = this.message
        this.isInEditMode = true
        return
      }

      this.$emit('update', {
        comment: this.discussion,
        message: this.editModel,
        notifiable_user_ids: this.mentions,
      })

      this.isInEditMode = false
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete Comment'),
        description: this.$t('Are you sure? This will permanently remove the comment')
      });

      if (!confirmed) {
        return;
      }

      this.$emit('delete', this.discussion)
    },
    checkIfIsThread() {
      const { threadId } = this.$route.query
      if (threadId?.toString() === this.discussion.id?.toString()) {
        this.openThreadDialog()
      }
    }
  },
  mounted() {
    if (!this.tryOpenThreadDialogOnMount) {
      return
    }

    this.checkIfIsThread()
  }
}
</script>
<style lang="scss">
.discussion-comment {
  .ProseMirror {
    @apply ml-0;
    & > * {
      @apply ml-0;
    }
  }
  p span[data-type=emoji] {
    line-height: 0;
    @apply text-lg;
  }

  &:hover {
    .discussion-controls {
      @apply  visible;
    }
  }

  .prose-p\:mt-2 :is(:where(p):not(:where([class~="not-prose"] *)):first-of-type) {
    @apply mt-0;
  }

  &.is-own-comment:not(.is-edit-mode):not(.is-inside-notification-card) {

    :where(code):not(:where([class~="not-prose"] *)),
    :where(blockquote):not(:where([class~="not-prose"] *)) {
      --tw-prose-code: theme('colors.gray.100');
    }
    .editor-mention {
      @apply text-white underline;
    }
    a.link-extension {
      @apply text-white hover:bg-gray-50/10;
    }

    .editor-reference {
      @apply hover:bg-gray-50/10;
    }

    .editor-file {
      @apply hover:bg-gray-50/10;
      a {
        @apply text-white;
      }
    }

    table tr td {
      @apply text-white;
    }

    .prose {
      :where(strong):not(:where([class~="not-prose"] *)) {
        @apply text-white;
      }

      :where(ol > li):not(:where([class~="not-prose"] *))::marker {
        @apply text-white;
      }

      :where(ul > li):not(:where([class~="not-prose"] *))::marker {
        @apply text-white;
      }
    }

    &.use-new-layout {
      .editor-mention,
      a.link-extension,
      .editor-file a,
      table tr td,
      .prose :where(strong):not(:where([class~="not-prose"] *)),
      .prose :where(ol > li):not(:where([class~="not-prose"] *))::marker,
      .prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
        color: var(--breadcrumb-color);
      }
    }
  }
}
</style>
