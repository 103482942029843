<template>
  <el-select
    ref="entitySelectRef"
    v-model="model"
    @update:modelValue="onUpdate"
    :filterable="filterable"
    :placeholder="placeholder"
    :clearable="clearable"
    :multiple="multiple"
    :collapse-tags="multiple"
    class="w-full relative"
  >
    <el-option
      v-if="loading"
      :value="false"
      disabled
    >
      <div class="flex justify-center w-full">
        <LoadingIcon  size="xs" />
      </div>
    </el-option>
    <template v-else>
      <slot name="custom-options" />
      <el-option
        v-if="createEntityParams?.enable && createEntityParams?.infoText"
        :value="false"
        disabled
        class="menu-info-section -mt-2"
      >
        <div class="flex items-center py-2">
          <span class="text-xs text-gray-400">
            {{ createEntityParams?.infoText }}
            <BaseTutorialLink
              :name="createEntityParams?.entityType + 's'"
            >
              <span>{{ $t('Learn more.') }}</span>
            </BaseTutorialLink>
          </span>
        </div>
      </el-option>
      <el-option
        v-if="createEntityParams?.enable"
        :value="false"
        class="create-entity-option"
        disabled
      >
        <div
          class="flex items-center py-1 cursor-pointer w-full h-full"
          @click.stop.prevent="onTriggerEntityCreate"
        >
          <div
            class="rounded-md uppercase bg-white flex items-center justify-center tracking-wider leading-6 font-normal text-primary-500 h-8 w-8 text-xs"
            tabindex="0"
          >
            <i class="fa-solid fa-plus" />
          </div>
          <span
            class="ml-2 truncate text-white"
            v-html="createEntityParams?.label || $tc('create new entity', { entity: createEntityParams?.entityType })"
          />
        </div>
      </el-option>
      <template  
        v-for="option of orderedOptions"
        :key="get(option, valueKey)"
      >
        <slot
          name="option"
          :option="option"
        >
          <el-option
            :label="getOptionLabel(option)"
            :value="getOptionValue(option)"
            class="flex space-x-2"
          >
            <BaseLogo
              v-if="logoKey"
              :entity="option"
              :logoKey="logoKey"
              size="sm"
            />
            <span>{{ getOptionLabel(option) }}</span>
            <slot
              name="option-after"
              :option="option"
            />
          </el-option>
        </slot>
      </template>
    </template>
  </el-select>
</template>
<script lang="ts" setup>
// Components
import { ElSelect, ElOption } from "element-plus";
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";

// Utils
import { computed, PropType, ref } from "vue";
import { get, orderBy } from "lodash-es";
import { EntitySelectCreateParams } from "@/modules/common/commonTypes";

// Composables
import useEntityCrud from "@/modules/common/composables/useEntityCrud"
import { sleep } from "@/modules/common/utils/commonUtils";

const {
  triggerEntityCreate
} = useEntityCrud()

const props = defineProps({
  modelValue: {
    type: [String, Number, null] as PropType<string | number | boolean | unknown[] | Record<string, any> | undefined>,
    default: null,
  },
  options: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  valueKey: {
    type: String,
    default: 'id',
  },
  labelKey: {
    type: String,
    default: 'attributes.name',
  },
  fallbackLabelKey: {
    type: String,
    default: '',
  },
  logoKey: {
    type: String,
    default: 'attributes.image',
  },
  placeholder: {
    type: String,
    default: 'Select...',
  },
  filterable: {
    type: Boolean,
    default: true,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  sortFunction: {
    type: Function as PropType<Function | null>,
    default: null,
  },
  createEntityParams: {
    type: Object as PropType<EntitySelectCreateParams | null>,
    default: null,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  excludedOptions: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  initQuery: {
    type: String,
    default: '',
  },
})

const emit = defineEmits([
  'update:modelValue',
  'raw-change'
])

const model = computed({
  get() { 
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value) 
  },
})

function onUpdate(value: any) {
  const option = props.options.find((option: any) => get(option, props.valueKey) === value)
  emit('raw-change', option)
}

const filteredOptions = computed(() => {
  if (!props.excludedOptions?.length) {
    return props.options
  }

  return props.options.filter((option) => {
    return !props.excludedOptions.includes(get(option, props.valueKey))
  })
})

const orderedOptions = computed(() => {
  if (props.sortFunction) {
    return props.sortFunction(filteredOptions.value)
  }

  // Newest first by default
  return orderBy(filteredOptions.value, [props.valueKey], ['desc'])
})

function getOptionLabel(option: any) {
  return get(option, props.labelKey) || get(option, props.fallbackLabelKey) || ''
}

function getOptionValue(option: any) {
  return get(option, props.valueKey)
}

const entitySelectRef = ref<InstanceType<typeof ElSelect>>()

async function onTriggerEntityCreate() {
  const events = {
    created(entity: any) {
      if (props.multiple && Array.isArray(entity)) {
        model.value = entity.map((e: any) => getOptionValue(e))
      }
      else {
        model.value = getOptionValue(entity)
      }

      props.createEntityParams?.createdCallback?.(entity)
    },
    closed: props.createEntityParams!.dialogClosedCallback,
  }

  triggerEntityCreate(
    props.createEntityParams!.entityType,
    props.createEntityParams?.createParams,
    events
  )

  await sleep(100)

  entitySelectRef.value?.blur()
}

async function setQuery() {
  if (!props.initQuery) {
    return
  }

  await sleep(0)

  const input = entitySelectRef.value?.$el.querySelector('input.el-input__inner') as HTMLInputElement

  input.value = props.initQuery
  input.dispatchEvent(new Event('input', { bubbles: true }))
}

defineExpose({
  setQuery,
})
</script>
