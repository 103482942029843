<template>
  <div class="flex text-gray-500 text-sm relative">
    <div class="flex w-full">
      <div class="shrink-0 mr-4 w-10">
        <i class="fa-solid fa-circle-dashed ai-icon text-3xl" />
      </div>
      <div class="overflow-x-hidden flex-1">
        <div class="flex flex-col space-x-0 sm:space-x-3 sm:flex-row">
          <div class="inline font-bold text-gray-700 text-base -mt-1">
            {{ $t('AI Assistant') }}
          </div>
          <div class="inline font-bold text-xs text-gray-400">
            <span>{{ formattedDate }}</span>
          </div>
        </div>
        <div
          contenteditable="false"
          translate="no"
          tabindex="0"
          class="prose max-w-none leading-6 prose-p:my-4 prose-ul:my-4 prose-ol:my-4 prose-li:my-0 prose-p:mt-2 prose-p:mb-0 prose-ul:mt-2 prose-ul:mb-0 prose-ol:mt-2 prose-ol:mb-0 focus:outline-none text-sm rounded-md text-gray-500"
        >
          {{ message.message || $t('What would you like to do?') }}
        </div>
        
        <div class="grid grid-cols-2 gap-2 text-gray-500 mt-2">
          <BaseButton
            v-for="action in actions"
            :key="action.type"
            :variant="message.data?.actionChoice === action.type ? 'primary' : 'white'"
            :disabled="!!message.data?.actionChoice"
            class="shadow-none"
            block
            @click="beginAction(action.type)"
          >
            <i
              v-if="action.iconClass"
              class="far mr-1"
              :class="{
                [action.iconClass]: action.iconClass,
                'text-white': message.data?.actionChoice === action.type,
              }"
              aria-hidden="true"
            />

            {{ action.label }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n';
import { ActionChoices, ChatMessage, ChatMessageTypes } from '@/modules/ai/types/aiTypes';
import { useActionChat } from "@/modules/ai/composables/useActionChat";
import { PropType, computed } from 'vue';
import { formatDate } from '@/modules/common/utils/dateUtils';

const props = defineProps({
  message: {
    type: Object as PropType<ChatMessage>,
    default: () => ({  }),
    required: true
  }
})

const {
  messages,
  addChatMessage,
  selectedAction,
} = useActionChat()


const actions = [
  {
    label: i18n.t('Create Project'),
    type: ActionChoices.CreateProject,
    iconClass: 'fa-regular fa-inbox text-purple-500',
    message: i18n.t('Tell me about the project you want to create. I need a project name along with any other information you can provide.'),
  },
  {
    label: i18n.t('Update Project'),
    type: ActionChoices.UpdateProject,
    iconClass: 'fa-regular fa-inbox text-purple-500',
    message: i18n.t('Please provide the name of the project you would like to update and information about what you would like to change.'),
  },
  {
    label: i18n.t('Create Tasks'),
    type: ActionChoices.CreateTasks,
    iconClass: 'fa-regular fa-badge-check text-yellow-500',
    message: i18n.t('Tell me about the tasks you want to create. I need the task names, the project they should be added to along with any other information you can provide.'),
  },
  {
    label: i18n.t('Update Tasks'),
    type: ActionChoices.UpdateTasks,
    iconClass: 'fa-regular fa-badge-check text-yellow-500',
    message: i18n.t('Please provide the name of the task you would like to update and information about what you would like to change.'),
  },
  // {
  //   label: i18n.t('Create Multiple Tasks'),
  //   type: ActionChoices.CreateMultipleTasks,
  //   iconClass: 'fa-regular fa-badge-check text-yellow-500',
  //   message: i18n.t('Please provide names for your new tasks and the project they should be added to.'),
  // },
]

function beginAction(actionChoice: ActionChoices) {
  selectedAction.value = actionChoice
  props.message.data.actionChoice = actionChoice

  const messagToShow = actions.find(action => action.type === actionChoice)?.message

  addChatMessage({
    type: ChatMessageTypes.Assistant,
    message: messagToShow || i18n.t('What would you like to do?'),
    date: new Date(),
  })
}

const formattedDate = computed(() => {
  return formatDate(props.message.date, null, true, true)
})
</script>
