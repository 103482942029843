import { $deleteConfirm } from "@/components/common/dialog/modalPlugin";
import i18n from "@/i18n";
import store from '@/store';
import { error } from "@/components/common/NotificationPlugin";
import Data = API.Data;
import Media = App.Domains.Media.Models.Media;

export function useFileActions() {
  async function deleteFileFromTable(file: Data<Media>) {
    const confirmed = await $deleteConfirm({
      title: i18n.t('Delete File'),
      description: i18n.t('Are you sure? This will permanently remove the file.')
    });

    if (!confirmed) {
      return;
    }

    try {
      await store.dispatch('files/deleteFile', { file });

      if (!file.attributes.folder_id) {
        return
      }

      const refreshGridPayload = {
        rowNodeIds: [`folders_${file.attributes.folder_id}`],
        columns: ['options'],
        force: true,
      }
      store.commit('triggerGridCellRefresh', refreshGridPayload, { root: true })
    } catch (err: any) {
      if (err.handled) {
        return;
      }
      error(i18n.t('Cannot delete file'));
      throw err
    }
  }

  return {
    deleteFileFromTable,
  }
}
