<template>
  <div class="relative lg:inline-block text-left mt-2 lg:mt-0 group">
    <BaseDropdown
      :options="columnOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
    >
      <TopFilterChip
        :isActive="columnsToHide?.length"
        :prependLabel="$t('Fields')"
        :label="label"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ $t('Choose fields to display') }}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import TopFilterChip from "@/modules/filters/components/TopFilterChip.vue";
export default {
  components: {
    TopFilterChip
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    defaultFilter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      columnsToHide: this.defaultFilter.columns || []
    }
  },
  computed: {
    columnsInDropdown() {
      const exceptions = ['options']
      return this.columns.filter(c => {
        const showInChooseColumns = typeof c.showInChooseColumns === 'function'
          ? c.showInChooseColumns({ rootState: this.$store.state })
          : c.showInChooseColumns !== false
    
        return !exceptions.includes(c.prop) && !c.required && showInChooseColumns && !c.keepHidden
      })
    },
    columnOptions() {
      return this.columnsInDropdown.map(column => ({
        label: column.tableHeader || column.name,
        action: () => this.toggleColumnVisibility(column),
        variant: this.columnsToHide.findIndex(c => c === column.prop) === -1 ? 'active' : '',
      }))
    },
    label() {
      if (!this.columnsToHide?.length) {
        return this.$t('All')
      }

      const visible = this.columnsInDropdown.length - this.columnsToHide?.length
      const total = this.columnsInDropdown.length

      return `${visible}/${total}`
    }
  },
  methods: {
    toggleColumnVisibility(column) {
      const columnIndex = this.columnsToHide.findIndex(c => c === column.prop)
      if (columnIndex !== -1) {
        this.columnsToHide.splice(columnIndex, 1)
        column.hide = false
      } else {
        this.columnsToHide.push(column.prop)
        column.hide = true
      }
      this.$emit('change', {
        columns: this.columnsToHide
      })
    },
    initColumns() {
      this.columns.forEach(column => {
        column.hide = this.columnsToHide.includes(column.prop);
      })
    }
  },
  watch: {
    defaultFilter() {
      this.columnsToHide = this.defaultFilter.columns || []
    }
  },
  created() {
    this.initColumns()
  },
}
</script>
