<template>
  <BaseKanbanView
    :target="EntityTargetTypes.PROJECTS"
    :loading="projectsLoading"
    dataGetterPath="projects/projectsOrTemplatesData"
    groupedDataGetter="projects/groupedProjects"
    columnsGetter="projects/tableColumns"
    :extraFilters="extraFilters"
  >
    <template #default="{ data, groupBy }">
      <div
        v-if="selectedField.options.length !== 0"
        class="max-w-max grid grid-flow-col gap-6"
      >
        <ProjectKanbanColumn
          v-for="(option, index) of selectedField.options"
          :key="`${selectedField.name}_${option.label}`"
          :selectedField="selectedField"
          :option="option"
          :data="data"
          :groupBy="groupBy"
          :index="index"
          @edit-columns="onEditColumns"
        />

        <div
          v-if="canEditColumns"
          class="flex items-center p-3 col-span-1 w-60 lg:w-80 h-[46px] px-4 py-2 rounded-lg bg-white border border-gray-300 text-sm text-gray-400 cursor-pointer mr-6 group"
          @click="onEditColumns"
        >
          <span class="opacity-80 group-hover:opacity-100">
            <i class="fa-solid fa-objects-column mr-1" aria-hidden="true" />
            {{ $t('Add / remove columns') }}
          </span>
        </div>
      </div>
    </template>
    <template #after>
      <ProjectStatusesDialog
        v-if="showEditStatuses"
        v-model="showEditStatuses"
      />

      <CustomFieldDialog
        v-if="showEditCustomField"
        v-model="showEditCustomField"
        :customField="customField"
        @save="showEditCustomField = false"
        @close="showEditCustomField = false"
      />
    </template>
  </BaseKanbanView>
</template>
<script lang="ts" setup>
import { ref, computed } from "vue";

import ProjectKanbanColumn from "@/modules/projects/components/ProjectKanbanColumn.vue"
import ProjectStatusesDialog from "@/modules/accounts/components/ProjectStatusesDialog.vue";
import CustomFieldDialog from "@/modules/accounts/components/CustomFieldDialog.vue";

import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'
import { extraFilters } from '@/modules/projects/utils/projectTableUtils'

import { useKanbanGroups } from "@/modules/projects/composables/useKanbanGroups.js";
import { useStore } from "vuex";
import useCan from "@/modules/common/composables/useCan.js";

const { selectedField } = useKanbanGroups({
  entity: 'project'
})

const {
  can,
  actions
} = useCan()

const store = useStore()

const canEditColumns = computed(() => {
  if (customField.value) {
    return selectedField.value?.isProjectScoped
      ? can(actions.EDIT_CUSTOM_FIELDS_INSIDE_PROJECTS)
      : can(actions.EDIT_CUSTOM_FIELDS)
  }

  return can(actions.EDIT_PROJECT_STATUSES)
})

const projectsLoading = computed(() => {
  return store.state.projects.projectsLoading
})

const customFields = computed(() => {
  return store.getters["accounts/tableColumnCustomFields"]('project') || []
})

const customField = computed(() =>{
  if (!selectedField.value?.groupByProp?.includes('custom_fields')) {
    return
  }

  const customFieldId = selectedField.value.options?.[0]?.prop?.split('_')[1]
  return customFields.value.find((field: any) => {
    return field.id == customFieldId
  })
})

const showEditStatuses = ref(false)
const showEditCustomField = ref(false)

function onEditColumns() {
  if (customField.value) {
    showEditCustomField.value = true
    return
  }

  showEditStatuses.value = true 
}
</script>
