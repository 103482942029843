<template>
  <div class="relative lg:inline-block text-left mt-2 lg:mt-0 ">
    <BaseDropdown
      :options="filterOptions"
      trigger="hover"
      divide-items
      large-items
      class="w-full"
      :disabled="loading"
    >
      <TopFilterChip
        :isActive="completed"
        :prependLabel="$t('Completed')"
        :label="completed ? $t('Yes') : $t('No')"
      />
      <template #menu-header>
        <div class="px-4 py-2 bg-gray-50 text-gray-500 rounded-t-md sticky top-0 border-b border-gray-200">
          <span class="text-sm font-medium truncate">
            {{ title || $t('Show completed projects?') }}
          </span>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>
<script>
import TopFilterChip from "@/modules/filters/components/TopFilterChip.vue";
export default {
  components: {
    TopFilterChip
  },
  props: {
    title: {
      type: String,
    },
    columns: {
      type: Array,
      default: () => []
    },
    defaultFilter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      completed: this.defaultFilter || false,
    }
  },
  computed: {
    filterOptions() {
      return [
        {
          label: this.$t('No'),
          action: () => this.setValue(false),
          variant: !this.completed ? 'active' : ''
        },
        {
          label: this.$t('Yes'),
          action: () => this.setValue(true),
          variant: this.completed ? 'active' : ''
        }
      ]
    }
  },
  methods: {
    setValue(value) {
      this.completed = value
      this.$emit('change', this.completed)
    }
  },
  watch: {
    defaultFilter() {
      this.completed = this.defaultFilter || false
    }
  }
}
</script>
