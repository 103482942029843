<template>
  <div class="col-span-full">
    <FeatureProgressBar
      v-for="feature in visibleFeatures"
      :key="feature"
      :feature="feature"
      class="py-3"
    />
  </div>
</template>
<script setup>
import { computed } from 'vue'
// Components
import FeatureProgressBar from "@/modules/accounts/components/FeatureProgressBar.vue";

// Helpers
import { AccountPermissions } from '@/modules/common/composables/useCan';
import useCan from '@/modules/common/composables/useCan'

const alwaysVisible = [
  AccountPermissions.CreatorSeats,
  AccountPermissions.CollaboratorsSeats,
  AccountPermissions.Storage,
]

const features = [
  AccountPermissions.AiAssistant,
  AccountPermissions.FileProofing,
  AccountPermissions.ActiveProjects,
  AccountPermissions.SpeechToText,
]

const {
  hasAccountPermission
} = useCan()

const visibleFeatures = computed(() => {
  const filteredFeatures = features.filter(feature => {
    return hasAccountPermission(feature)
  })

  return [
    ...alwaysVisible,
    ...filteredFeatures
  ]
})
</script>
