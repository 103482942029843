<template>
  <router-view />
</template>
<script setup>
import { onBeforeMount } from 'vue'

const zapierScriptSrc = 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js'
const zapierStylingHref = 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css'

function importZapierModule() {
  if (document.head.querySelector(`script[src="${zapierScriptSrc}"]`)) {
    return
  }

  const zapierScript = document.createElement('script')
  zapierScript.src = zapierScriptSrc
  zapierScript.type = 'module'
  zapierScript.async = true
  zapierScript.defer = true

  document.head.appendChild(zapierScript)
}

function importZapierStyling() {
  if (document.head.querySelector(`link[href="${zapierStylingHref}"]`)) {
    return
  }

  const zapierCss = document.createElement('link')
  zapierCss.href = zapierStylingHref
  zapierCss.rel = 'stylesheet'
  zapierCss.async = true
  zapierCss.defer = true

  
  document.head.appendChild(zapierCss)
}

onBeforeMount(() => {
  importZapierModule()
  importZapierStyling()
})
</script>
