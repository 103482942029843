<template>
  <div class="relative bg-white border border-gray-200 rounded-lg project-tool-card tool-card">
    <div class="px-4 py-3">
      <div class="flex space-x-2 items-center">
        <span
          :style="{ color }"
          class="ring-4 ring-white tool-icon"
        >
          <BaseAvatar
            v-if="image"
            :avatar="image"
          />
          <i
            v-else
            :class="icon"
            class="text-3xl"
          />
        </span>
        <button
          v-if="canAccessTool"
          class="focus:outline-none flex space-x-2"
          :disabled="!canAccessTool"
          :title="subtitle"
          @click="toolClicked"
        >
          <h3 class="text-lg font-bold leading-5">
            <span class="absolute inset-0" aria-hidden="true"></span>
            <span class="line-clamp-2">
              {{ title }}
            </span>
          </h3>
        </button>
        <div
          v-else
          class="cursor-pointer"
          @click="unaccessibleToolClicked"
        >
          <h3 class="text-lg font-bold">
            <span class="absolute inset-0" aria-hidden="true"></span>
            {{ title }}
          </h3>
        </div>

        <AccessControlIndicator
          v-if="hiddenForCollaborators && !isNoteTool"
          :tooltip="$t(`This tool can't be seen by people with a Collaborator role`)"
          class="ml-1"
        />
        <AccessControlIndicator
          v-else-if="isNoteTool && isNoteVisibleToCreatorsOnly"
          :tooltip="$t(`This note is only visible to people with a Creator role. It can't be seen by people with a Collaborator role.`)"
          class="ml-1"
          icon="fa-lock"
        />
      </div>
      <span
        v-if="isRemoveToolsEnabled"
        class="absolute top-0 right-2 -mt-3 cursor-pointer bg-white text-lg font-medium"
        :class="{
          'opacity-50': !canRemoveTool,
        }"
        @click.stop="confirmRemoveTool"
      >
        <BaseTooltip
          :content="cannotRemoveReason"
          :disabled="canRemoveTool"
        >
          <i class="fa-solid fa-circle-minus text-red-500" />
        </BaseTooltip>
      </span>
      <span
        v-else-if="!isRemoveToolsEnabled && can($actions.EDIT_PROJECT_TOOLS) && !isCurrentProjectClosed"
        class="absolute top-0 right-2 -mt-3 px-2 bg-white/80 text-lg font-medium text-gray-300 hover:text-gray-400"
      >
        <i class="hidden fa-solid fa-grip-dots cursor-move" />
      </span>
      <span
        v-if="editable && !isRemoveToolsEnabled && can($actions.EDIT_PROJECT_TOOLS) && !isCurrentProjectClosed"
        class="absolute top-0 right-8 -mt-3 px-2 bg-white text-gray-300 hover:text-gray-400"
        @click.stop="triggerEdit"
      >
        <i class="hidden fa-solid fa-edit text-sm cursor-pointer" />
      </span>
    </div>

    <div
      v-if="toolDescription"
      class="border-t border-gray-200 px-4 py-3"
    >
      <div class="flex items-center">
        <div class="w-full text-sm text-gray-500 line-clamp-2">
          <div
            v-html="toolDescription"
            class="project-tool-description text-sm text-gray-500"
            :class="{ 'whitespace-pre': isNoteTool }"
          />
        </div>
      </div>
    </div>
    <ProjectLinkDialog
      v-model="showLinkDialog"
      v-show="showLinkDialog"
      :link="resource"
      key="link-edit"
      @close="showLinkDialog = false"
      @save="showLinkDialog = false; onToolEdited()"
    />
    <EmbedDialog
      v-model="showEmbedDialog"
      v-show="showEmbedDialog"
      :embed="resource"
      key="embed-edit"
      @close="showEmbedDialog = false"
      @save="showEmbedDialog = false; onToolEdited()"
    />
    <ProjectReferenceDialog
      v-model="showReferenceDialog"
      v-show="showReferenceDialog"
      :reference="resource"
      key="reference-edit"
      @close="showReferenceDialog = false"
      @save="showReferenceDialog = false; onToolEdited()"
    />
  </div>
</template>
<script>
// Helpers
import { toolsEnum } from '@/modules/projects/utils/toolUtils.js'
import { getEmbedIcon, getEmbedImage } from '@/modules/resources/utils/embedUtils'
import { getProjectLinkImage } from '@/modules/resources/utils/projectLinkUtils'
import { getNoteName, stripNoteHTML } from '@/modules/projects/utils/stripNotesHTML.js'
import { getCompletedTasksFilterAppliedQuery, getMyTasksFilterAppliedQuery } from '@/modules/tasks/utils/modelUtils'
import { getReferencePath } from '@/components/html/util/referenceUtils.js'

// Components
import EmbedDialog from '@/modules/resources/components/EmbedDialog.vue'
import ProjectLinkDialog from '@/modules/projects/components/ProjectLinkDialog.vue'
import ProjectReferenceDialog from '@/modules/projects/components/ProjectReferenceDialog.vue'
import { NotesVisibility } from '@/modules/projects/utils/noteUtils'

export default {
  components: {
    EmbedDialog,
    ProjectLinkDialog,
    ProjectReferenceDialog,
  },
  props: {
    tool: {
      type: Object,
      default: () => ({})
    },
    isRemoveToolsEnabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showLinkDialog: false,
      showEmbedDialog: false,
      showReferenceDialog: false,
    }
  },
  computed: {
    projectId() {
      return this.$route.params.id
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
    toolOptions() {
      return this.tool?.pivots?.options
    },
    isNoteTool() {
      return this.toolOptions?.entity_type === 'note'
    },
    isLinkTool() {
      return this.toolOptions?.entity_type === 'link'
    },
    isEmbedTool() {
      return this.toolOptions?.entity_type === 'embed'
    },
    isReferenceTool() {
      return this.toolOptions?.entity_type === 'reference'
    },
    isResourcesTool() {
      return this.tool.attributes?.name === toolsEnum.EMBEDS && !this.resource
    },
    isFileReference() {
      return this.isReferenceTool && this.toolOptions?.referenceable_type === 'media'
    },
    editable() {
      return this.isLinkTool || this.isEmbedTool || this.isReferenceTool
    },
    resource() {
      return this.tool?.resource
    },
    toolName() {
      return this.tool.attributes?.name
    },
    isNoteVisibleToCreatorsOnly() {
      const { visibility = '' } = this.tool?.resource?.attributes || {}
      return visibility === NotesVisibility.CREATORS_EDIT_COLLABORATORS_RESTRICT
    },
    title() {
      if (this.resource) {
        return this.$t(this.resource.attributes?.name || this.resource.attributes?.title)
      }

      if (this.isNoteTool) {
        return getNoteName(this.toolOptions?.notes)
      }
      if (this.isLinkTool || this.isReferenceTool) {
        return this.toolOptions.name
      }
      if (this.toolOptions?.entity_type === 'embed') {
        return this.subtitle || this.toolOptions?.name
      }

      if (this.isResourcesTool) {
        return this.$t('Resources')
      }

      return this.$t(this.toolName)
    },
    subtitle() {
      return this.tool.pivots?.options?.title || ''
    },
    toolDescription() {
      if (this.resource && this.resource.attributes?.description) {
        return this.resource.attributes?.description
      }
      if (this.toolOptions?.description) {
        return stripNoteHTML(this.toolOptions?.description) || ''
      }
      if (this.isNoteTool) {
        return stripNoteHTML(this.toolOptions?.notes) || ''
      }
      if (this.isReferenceTool) {
        return this.$t('This is a reference within the application. Click to open it.')
      }
      if (this.isResourcesTool) {
        return this.$t('View links to all the important resources for this project.')
      }
      if (this.isLinkTool) {
        return this.$t('This is an external link. Click to open it in a new browser tab.')
      }
      if (this.toolOptions?.entity_type === 'embed') {
        return this.toolOptions?.description || this.$t('This is an embed. Click to open it inside this project.')
      }
      return this.$t(this.tool?.attributes?.description)
    },
    image() {
      if (this.resource && this.resource.attributes?.image) {
        return this.resource.attributes?.image
      }

      if (this.toolOptions?.image) {
        return this.toolOptions?.image
      }

      if (this.toolOptions?.entity_type === 'embed') {
        return getEmbedImage(this.toolOptions?.type)
      }

      if (this.toolOptions?.entity_type === 'link') {
        return getProjectLinkImage(this.toolOptions?.type)
      }

      return '';
    },
    icon() {
      if (this.toolOptions?.entity_type === 'embed') {
        return getEmbedIcon(this.toolOptions?.type)
      }

      if (this.isResourcesTool) {
        return 'fal fa-rectangle-list'
      }

      const icon = this.tool?.attributes?.image?.toString() || ''
      return icon.replaceAll('far', 'fal')
    },
    color() {
      if (this.isResourcesTool) {
        return '#3B82F6'
      }

      if (this.isReferenceTool) {
        return '#6B7280'
      }

      const color = this.tool?.attributes?.color || ''
      return color.toLowerCase()
    },
    path() {
      const basePath = this.isTemplateOpened
        ? `/templates/${this.projectId}`
        : `/projects/${this.projectId}`
      const name = this.tool?.attributes?.name


      const paths = {
        [toolsEnum.TASKS]: () => {
          const viewOptions = this.$store.getters['users/defaultTargetViewOptions']('tasks', /* inside_project */  true)
          let query = getMyTasksFilterAppliedQuery(this.projectId, true)

          if (this.isCurrentProjectClosed) {
            query = getCompletedTasksFilterAppliedQuery(this.projectId, /* inside_project */ true)
          }

          return {
            path: `${basePath}/tasks/${viewOptions.view_type}`,
            query
          }
        },
        [toolsEnum.FILES]: () => {
          return `${basePath}/files/list`
        },
        [toolsEnum.FILE_PROOFING]: () => {
          return `${basePath}/file-proofs/list`
        },
        [toolsEnum.PAYMENTS]: () => {
          return `${basePath}/payments/list`
        },
        [toolsEnum.TIME]: () => {
          const timePage = this.isTemplateOpened
            ? 'allocated'
            : 'actual'

          return `${basePath}/time/${timePage}`
        },
        [toolsEnum.NOTE]: () => {
          return `${basePath}/notes/${this.toolOptions?.entity_id}`
        },
        [toolsEnum.NOTES]: () => {
          return `${basePath}/notes`
        },
        [toolsEnum.DISCUSSIONS]: () => {
          return `${basePath}/discussion`
        },
        [toolsEnum.PEOPLE]: () => {
          return `${basePath}/people`
        },
        [toolsEnum.EMBEDS]: () => {
          if (this.toolOptions?.entity_type === 'embed') {
            return `${basePath}/embeds/${this.resource?.id}`
          }
          return `${basePath}/resources/list`
        },
        [toolsEnum.LINK]: () => {
          return this.toolOptions?.link
        },
        [toolsEnum.REFERENCE]: () => {
          return getReferencePath(this.toolOptions)
        }
      }

      if (!paths[name]) {
        return `${basePath}/tools/${this.tool.id}`
      }

      return paths[name]()
    },
    isTemplateOpened() {
      return this.$store.getters['templates/isTemplateOpened']
    },
    canAccessTool() {
      if (this.toolName !== toolsEnum.DISCUSSIONS) {
        return true
      }
      return !this.isTemplateOpened
    },
    project() {
      return this.$store.state.projects.currentProject
    },
    projectNotes() {
      return this.project?.relationships?.notes || []
    },
    canRemoveTool() {
      if (this.toolName === toolsEnum.PEOPLE) {
        return false
      }

      if (this.toolName === toolsEnum.NOTES) {
        return !this.projectNotes?.length
      }

      return true
    },
    cannotRemoveReason() {
      if (this.toolName === toolsEnum.NOTES) {
        return this.$t('Please delete the notes within this tool before removing it.')
      }

      return this.$t('Tool cannot be removed')
    },
    hiddenForCollaborators() {
      const { name } = this.tool.attributes

      const isHidden = {
        [toolsEnum.TIME]: !this.canRole(this.$roles.COLLABORATOR, this.$actions.VIEW_TIME_ENTRIES),
      }

      return isHidden[name] || false
    }
  },
  methods: {
    toolClicked() {
      if (this.isLinkTool || this.isFileReference) {
        window.open(this.path, '_blank')
      } else {
        this.$router.push(this.path)
      }
    },
    unaccessibleToolClicked() {
      if (this.toolName === toolsEnum.DISCUSSIONS) {
        this.$error(this.$t('Cannot add comments for template discussion'))
      }
    },
    async confirmRemoveTool() {
      if (!this.canRemoveTool) {
        return
      }

      const confirmed = await this.$deleteConfirm({
        title: this.$t('Remove tool'),
        description: this.$t('confirm remove project tool', {
          toolName: this.$t(this.toolName)
        }),
        buttonText: this.$t('Remove')
      });

      if (!confirmed) {
        return;
      }

      this.removeTool()
    },
    async removeTool() {
      try {
        this.loading = true
        await this.$store.dispatch('projects/removeProjectTool', {
          tool: this.tool,
          projectId: this.projectId,
        })
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not remove the tool from the project'))
      } finally {
        this.loading = false
      }
    },
    triggerEdit() {
      if (this.isLinkTool) {
        this.showLinkDialog = true
        return
      }

      if (this.isEmbedTool) {
        this.showEmbedDialog = true
        return
      }

      if (this.isReferenceTool) {
        this.showReferenceDialog = true
      }
    },
    async onToolEdited() {
      await this.$store.dispatch('projects/getProjectById', {
        id: this.projectId,
        forceFetch: true,
        silent: true
      })
    },
  }
}
</script>
<style lang="scss">
.project-tool-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.project-tool-card:hover {
  .fa-grip-dots, .fa-edit {
    @apply inline;
  }
}
</style>
