<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-3xl sm:text-4xl font-extrabold">
      {{ i18n.t(`Creating your project`) }}
    </h2>
    <div class="text-gray-500 leading-7 text-lg space-y-2">
      <div class="flex space-x-2 items-center">
        <LoadingIcon
          v-if="savingProject"
          size="xs"
        />
        <i
          v-else-if="operationIndex < 0"
          class="fa-solid fa-circle w-4 text-sm text-gray-300"
        />
        <i
          v-else
          class="fa-solid fa-circle-check w-4 text-base text-primary-500"
        />
        <span>{{ $t('Creating project...')  }}</span>
      </div>
      <div
        class="flex space-x-2 items-center"
      >
        <LoadingIcon
          v-if="addingProjectTools"
          size="xs"
        />
        <i
          v-else-if="operationIndex < 1"
          class="fa-solid fa-circle w-4 text-sm text-gray-300"
        />
        <i
          v-else
          class="fa-solid fa-circle-check w-4 text-base text-primary-500"
        />
        <span>{{ $t('Setting up tools...')  }}</span>
      </div>

      <div
        class="flex space-x-2 items-center"
      >
        <LoadingIcon
          v-if="savingTasks"
          size="xs"
        />
        <i
          v-else-if="operationIndex < 2"
          class="fa-solid fa-circle w-4 text-sm text-gray-300"
        />
        <i
          v-else
          class="fa-solid fa-circle-check w-4 text-base text-primary-500"
        />
        <span>{{ $t('Creating tasks...')  }}</span>
      </div>

      <div
        v-if="onboardingModel.inviteUsers?.length"
        class="flex space-x-2 items-center"
      >
        <LoadingIcon
          v-if="invitingUsers"
          size="xs"
        />
        <i
          v-else-if="operationIndex < 3"
          class="fa-solid fa-circle w-4 text-sm text-gray-300"
        />
        <i
          v-else
          class="fa-solid fa-circle-check w-4 text-base text-primary-500"
        />
        <span>{{ $t('Inviting people...')  }}</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import { computed, onMounted, ref } from 'vue';
import { getSetting } from "@/plugins/settingsPlugin";
import { Privacy } from "@/modules/projects/utils/projectHelpers"
import {
  projectImage,
  projectCoverImage,
} from '@/modules/accounts/utils/onboardingUtils'
import { error } from '@/components/common/NotificationPlugin';
import { visibilityTypes } from '@/modules/tasks/utils/modelUtils';
import { rolesEnum } from '@/modules/common/utils/isRoleGreaterOrEqual';
import { trackActivity, Activities } from "@/modules/common/utils/trackingUtils";

import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";

import { useStore } from 'vuex'
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const { currentUser } = useAuth()

const onboardingModel = computed(() => {
  return store.state.accounts.onboardingModel
})

const projectId = computed(() => {
  return onboardingModel.value.projectId
})

// Create project
const savingProject = ref(true)
async function createProject() {
  try {
    savingProject.value = true
    const projectName = onboardingModel.value.projectName
    const projectDescription = onboardingModel.value.projectDescription

    const model = {
      name: projectName,
      description: projectDescription,
      image: projectImage,
      cover_image: projectCoverImage,
      privacy: getSetting('default_project_privacy') || Privacy.Account,
      status_id: getSetting('default_project_status'),
      is_template: false,
      start_date: new Date(),
    }

    const project = await store.dispatch('projects/createProject', model)

    store.commit('accounts/setOnboardingModel', {
      ...onboardingModel.value,
      projectId: project.id,
    })
  }
  catch (err: any) {
    console.log(err)

    if (err.handled) {
      return 
    }
    error(i18n.t('Failed to create project. Please refresh the page and try again.'))
  }
  finally {
    savingProject.value = false
  }
}

// Add tools
const allProjectTools = computed(() => {
  return store.state.projects.tools.data || []
})

const addingProjectTools = ref(false)
async function addProjectTools() {
  if (!projectId.value) {
    return
  }

  try {
    addingProjectTools.value = true
    const selectedTools = onboardingModel.value.projectTools || []
    const toolIds = selectedTools.map((toolValue: string) => {
      const tool = allProjectTools.value.find((tool: any) => tool.attributes?.name === toolValue)
      return tool?.id
    })

    await store.dispatch('projects/addMultipleProjectTools', {
      projectId: projectId.value,
      toolIds,
    })
  }
  catch (err: any) {
    console.log(err)

    if (err.handled) {
      return 
    }
    error(i18n.t('Could not add tools. Please refresh the page and try again.'))
  }
  finally {
    addingProjectTools.value = false
  }
}

// Create tasks
const savingTasks = ref(false)
async function createTasks() {
  if (!projectId.value) {
    return
  }

  try {

    savingTasks.value = true
    const currentUserId = currentUser.value.id

    const taskModels = Object.values(onboardingModel.value.tasksToAdd)
      .filter((task: any) => !!task?.name)
      .map(task => {
        return {
          ...(task || {}),
          project_id: projectId.value,
          visibility: getSetting('default_task_privacy') || visibilityTypes.CREATORS_ONLY,
          status_id: getSetting('default_task_status'),
          follower_ids: [currentUserId],
          allocated_ids: [currentUserId],
        }
      })
    
    await store.dispatch('tasks/bulkCreateTasks', {
      taskModels
    })
  }
  catch (err: any) {
    console.log(err)

    if (err.handled) {
      return 
    }
    error(i18n.t('Could not create tasks. Please refresh the page and try again.'))
  }
  finally {
    savingTasks.value = false
  }
}

// Invite users
const roles = computed(() => {
  return store.getters['users/editableRoles'] || []
})

const roleId = computed(() => {
  return roles.value.find((role: any) => role.attributes.name === rolesEnum.CREATOR_PLUS)?.id
})

const invitingUsers = ref(false)

async function inviteUsers() {
  const emails = onboardingModel.value.inviteUsers || []

  if (!projectId.value || !emails?.length) {
    return
  }

  try {

    const model = {
      emails: emails,
      project_ids: [projectId.value],
      role_id: roleId.value
    }

    await store.dispatch('users/createUser', model)
  }
  catch (err: any) {
    console.log(err)

    if (err.handled) {
      return 
    }

    error(i18n.t('Could not invite users. Please refresh the page and try again.'))
  }
  finally {
    invitingUsers.value = false
  }
}

const operationIndex = ref(0)

async function saveData() {
  if (projectId.value) {
    router.push('/onboarding/congratulations')
    return
  }

  await createProject()
  operationIndex.value++

  await addProjectTools()
  operationIndex.value++

  await createTasks()
  operationIndex.value++

  await inviteUsers()

  store.commit('accounts/setOnboardingModel', {
    ...onboardingModel.value,
    completed: true,
  })

  trackActivity(Activities.CompletedOnboarding, {
    first_name: currentUser.value.first_name,
    last_name: currentUser.value.last_name,
    email: currentUser.value.email,
    account_name: store.state.auth.tenantInfo?.name || '',
    project_id: projectId.value,
  })

  router.push('/onboarding/congratulations')
}

onMounted(() => {
  saveData()
})
</script>
