<template>
  <BasePopoverInput
    :autoShow="true"
    :disableAutofocus="true"
    popperClass="table-inline-edit-input"
  >
    <template #reference>
      <FormattedDateCell
        :params="params"
      />
    </template>
    <div class="grid grid-cols-10 gap-2 items-start">
      <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
        <span class="inline">
          <i class="fa-regular fa-calendar-alt mr-2 w-4" aria-hidden="true" />
          <span>{{ $t('Date Type') }}</span>
          <span v-if="!model.date_type" class="text-red-500">*</span>
        </span>
      </label>
      <div class="col-span-6">
        <TaskDateTypeInlineSelect
          v-model="model.date_type"
          :isTemplateTask="isTemplateTask"
          @update:modelValue="onDateTypeChange"
        />
      </div>

      <template v-if="model.date_type === taskDateTypes.RECURRING">
        <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
          <span class="inline">
            <i class="fa-regular fa-repeat mr-2 w-4" aria-hidden="true" />
            <span>{{ $t('Recurrence') }}</span>
            <span v-if="!model.recurrence" class="text-red-500">*</span>
          </span>
        </label>
        <div class="col-span-6">
          <RecurrenceInput
            v-model="model.recurrence"
            v-model:recurrenceDate="model.recurrence_date"
            v-model:humanReadableText="recurrenceHumanReadableText"
            :title="`${model.name || $t('Task')} ${$t('Recurrence')}`"
            :placeholder="$t('Set task recurrence...')"
            class="mt-1 relative sm:mt-0 sm:col-span-3"
            @update:modelValue="saveValue"
          />
        </div>
      </template>

      <template v-else-if="[
          taskDateTypes.DAYS_FROM_PROJECT_START,
          taskDateTypes.WORKING_DAYS_FROM_PROJECT_START
        ].includes(model.date_type)"
      >

        <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
          <span class="inline">
            <i class="fa-regular fa-calendar-alt mr-2 w-4" aria-hidden="true" />
            <span>{{ $t('Days') }}</span>
            <span
              v-if="
                (model.date_type === taskDateTypes.DAYS_FROM_PROJECT_START && model.days_from_project_start === null)
                ||
                (model.date_type === taskDateTypes.WORKING_DAYS_FROM_PROJECT_START && model.working_days_from_project_start === null)
              "
              class="text-red-500"
            >*</span>
          </span>
        </label>

        <div class="col-span-6">
          <div class="text-sm text-gray-400 cursor-pointer inline">
            <BaseInlineTextEdit
              v-if="model.date_type === taskDateTypes.DAYS_FROM_PROJECT_START"
              v-model="model.days_from_project_start"
              type="number"
              :placeholder="$t('Enter number of days...')"
            />
            <BaseInlineTextEdit
              v-else
              v-model="model.working_days_from_project_start"
              type="number"
              :placeholder="$t('Enter number of days...')"
            />
          </div>
        </div>
      </template>

      <template v-else-if="model.date_type !== taskDateTypes.NO_DATE">
        <label class="text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-wrap col-span-4 mt-1">
          <span class="inline">
            <i class="fa-regular fa-calendar-alt mr-2 w-4" aria-hidden="true" />
            <span>
              {{ model.date_type === taskDateTypes.SINGLE_DATE ? $t('Due Date') : $t('Date') }}
            </span>
            <span v-if="!model.date" class="text-red-500">*</span>
          </span>
        </label>
        <div class="col-span-6">
          <div class="text-sm text-gray-400 cursor-pointer inline">
            <BaseInlineDatePicker
              v-model="model.date"
              :type="model.date_type === taskDateTypes.DATE_RANGE ? 'daterange': 'date'"
              :displayIcon="false"
              :placeholder="$t('Select date...')"
              :start-placeholder="$t('Select start date...')"
              :end-placeholder="$t('Select end date...')"
              @change="saveValue"
            />
          </div>
        </div>
      </template>
    </div>
  </BasePopoverInput>
</template>
<script lang="ts">
// Components
import RecurrenceInput from "@/modules/common/components/RecurrenceInput.vue";
import TaskDateTypeInlineSelect from "@/modules/tasks/components/TaskDateTypeInlineSelect.vue";
import FormattedDateCell from "@/components/table/cells/FormattedDateCell.vue"

// Utils
import { PropType, defineComponent } from "vue";
import { ICellEditorParams } from "@ag-grid-community/core";
import { taskDateTypes } from '@/modules/tasks/utils/modelUtils'
import { getDateWithoutTime } from '@/modules/common/utils/dateUtils'
import { stopCellEditing } from "@/components/table/tableUtils";

export default defineComponent({
  components: {
    RecurrenceInput,
    TaskDateTypeInlineSelect,
    FormattedDateCell
  },
  props: {
    params: {
      type: Object as PropType<ICellEditorParams<any>>,
      default: () => ({})
    }
  },
  data() {
    return {
      model: {
        date: null,
        date_type: null,
        recurrence: null,
        recurrence_date: null,
        days_from_project_start: null,
        working_days_from_project_start: null
      } as any,
      taskDateTypes,
      recurrenceHumanReadableText: ''
    }
  },
  computed: {
    row() {
      return this.params.node.data
    },
    isTemplateTask() {
      if (!this.row?.id) {
        return this.$store.getters['templates/isTemplateOpened']
      }
      return this.row?.relationships?.project?.attributes?.is_template
    }
  },
  methods: {
    initModel() {
      let date = this.row?.attributes?.date
      if (this.row?.attributes?.date_type === taskDateTypes.DATE_RANGE) {
        date = {
          start: this.row?.attributes?.start_date,
          end: this.row?.attributes?.end_date
        }
      }

      this.model = {
        date,
        date_type: this.row?.attributes?.date_type,
        recurrence: this.row?.attributes?.recurrence,
        recurrence_date: this.row?.attributes?.recurrence_date,
        days_from_project_start: this.row?.attributes?.days_from_project_start,
        working_days_from_project_start: this.row?.attributes?.working_days_from_project_start,
      }
    },
    onDateTypeChange(value: string) {
      if (value === taskDateTypes.NO_DATE) {
        this.model.date = null
        this.model.recurrence = null
        this.model.recurrence_date = null
      } else if (value === taskDateTypes.SINGLE_DATE) {
        this.model.date = getDateWithoutTime(new Date())
        this.model.recurrence = null
        this.model.recurrence_date = null
      } else if (value === taskDateTypes.DATE_RANGE) {
        this.model.date = {
          start: getDateWithoutTime(new Date()),
          end: getDateWithoutTime(new Date())
        }
        this.model.recurrence = null
        this.model.recurrence_date = null
      } else if (value === taskDateTypes.RECURRING) {
        // Transform date start from '2022-07-13T09:45:08.675Z' to '20220713T094523Z' format accepted by rrule
        const dtStart = new Date().toISOString().split('.')[0].replace(/-|:/g, '') + 'Z'
        this.model.recurrence = `RRULE:FREQ=DAILY;INTERVAL=1;DTSTART=${dtStart}`
        this.model.recurrence_date = new Date()
      }
    },
    getValue() {
      const start_date = this.model.date_type === taskDateTypes.DATE_RANGE ? this.model.date.start : null
      const end_date = this.model.date_type === taskDateTypes.DATE_RANGE ? this.model.date.end : null
      const date = this.model.date_type === taskDateTypes.SINGLE_DATE ? this.model.date : null

      return {
        ...this.model,
        date,
        start_date,
        end_date
      }
    },
    saveValue() {
      stopCellEditing(this.params)
    },
  },
  created() {
    this.initModel()
  }
})
</script>
