<template>
  <div class="task-users flex flex-wrap items-center whitespace-nowrap gap-y-1 gap-x-2">
    <BaseInlineInput class="w-full">
      <template #default="{ triggerSave }">
        <span
          v-if="!$attrs.disabled"
          class="relative w-full"
        >
          <UserSelect
            v-model="model"
            :initial-value="users"
            :url-params="urlParams"
            allow-entity-create
            addEntityTrigger="projects/triggerProjectPeople"
            :addEntityParams="{ projectId: taskModel.project_id }"
            :addEntityLabel="$t('Add people to project')"
            :placeholder="placeholder"
            :clearable="false"
            :multiple="true"
            class="user-select inline-select"
            :disabled="$attrs.disabled || !taskModel.project_id"
            @raw-change="onRawChange($event, triggerSave)"
          />
          <span
            v-if="model?.length"
            class="absolute left-1 top-1/2 -translate-y-1/2 text-sm text-gray-400"
          >
            {{ placeholder }}
          </span>
        </span>
        <span
          v-else-if="!taskUsers?.length"
          class="p-1 text-gray-400"
        >
          - -
        </span>
        <UserChips
          v-if="taskUsers?.length"
          :users="taskUsers"
          :actions="userActions"
          @remove="(user) => removeUser(user, triggerSave)"
        />
      </template>
    </BaseInlineInput>
  </div>
</template>
<script>
// Components
import UserSelect from "@/components/selects/UserSelect.vue";
import UserLogo from '@/components/common/UserLogo.vue'
import DataSyncingIndicator from '@/components/common/DataSyncingIndicator.vue'
import UserPreviewCard from "@/modules/users/components/UserPreviewCard.vue";
import UserChips from "@/components/common/UserChips.vue";

// Utils
import { debounce } from "lodash-es"
import {
  UserPreviewActions
} from "@/modules/users/util/userUtils";
import { visibilityTypes } from '@/modules/tasks/utils/modelUtils'

export default {
  components: {
    UserSelect,
    UserLogo,
    DataSyncingIndicator,
    UserPreviewCard,
    UserChips,
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    taskModel: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    isValid: {
      type: Boolean,
      default: true
    },
    usersKey: {
      type: String,
      default: "allocations"
    }
  },
  data() {
    return {
      taskUsers: this.users,
      saving: false
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    project_id() {
      return this.taskModel?.project_id || this.task?.attributes?.project_id
    },
    urlParams() {
      const urlParams =  {
        project_id: this.project_id,
        roleGreaterThan: this.$roleRanks.COLLABORATOR,
        allFromProject: true
      }

      if (this.task?.attributes?.visibility === visibilityTypes.CREATORS_ONLY) {
        urlParams.roleGreaterThan = this.$roleRanks.COLLABORATOR_PLUS
      }

      return urlParams
    },
    users() {
      return this.task?.relationships?.[this.usersKey] || []
    },
    usersThatShouldBeRemoved() {
      if (!this.taskUsers?.length) {
        return []
      }

      if (this.taskModel.visibility === visibilityTypes.CREATORS_AND_COLLABORATORS) {
        return []
      }
      
      return this.taskUsers.filter(user =>
          [this.$roles.COLLABORATOR, this.$roles.COLLABORATOR_PLUS].includes(this.get(user, 'attributes.role[0]'))
      )
    },
    userActions() {
      if (this.$attrs.disabled) {
        return [UserPreviewActions.View]
      }

      return [UserPreviewActions.View, UserPreviewActions.Remove]
    },
    hasInconsistencies() {
      return this.usersThatShouldBeRemoved.length
    },
    placeholder() {
      if (this.usersKey === 'allocations') {
        return this.$t('+ Assign people')
      }

      return this.$t('+ Add Followers')
    },
    activatorLabel() {
      if (this.usersKey === 'allocations') {
        return this.$t('+ Assign people')
      }
      
      return this.$t('+ Add followers')
    },
    sortedTaskUsers() {
      return [...this.taskUsers].sort((x, y) => x.id - y.id)
    }
  },
  watch: {
    users: {
      immediate: true,
      handler(value) {
        this.taskUsers = value
      }
    },
    hasInconsistencies: {
      immediate: true,
      handler(value) {
        this.$emit('update:isValid', !value)
      }
    }
  },
  methods: {
    shouldBeRemoved(taskUser) {
      return this.usersThatShouldBeRemoved.find(user => user.id === taskUser.id)
    },
    onRawChange(users, triggerSave) {
      this.taskUsers = [...users]

      if (!this.triggerSaveDebounced) {
        this.triggerSaveDebounced = debounce(triggerSave, 500)
      }
      
      this.triggerSaveDebounced()
    },
    removeUser(user, triggerSave) {
      this.model = this.model.filter(id => id.toString() !== user?.id?.toString())
      this.taskUsers = this.taskUsers.filter(u => u.id?.toString() !== user?.id?.toString())

      triggerSave()
    },
  },
}
</script>
