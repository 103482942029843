import { NotesVisibility } from "@/modules/projects/utils/noteUtils";
import { EntityTypes, EntityCrudParams } from "@/modules/common/composables/useEntityCrud"

export interface SortFilter {
  column: string;
  order: 'asc' | 'desc'
}

export interface Filter {
  column: string;
  displayValue: string;
  name?: string;
  query?: string;
  rawValue?: string;
}

export type LocalFilters = {
  groupBy?: string[]
}

export type AllFilters = {
  filtersAndSorts: ApiFilter[];
  localFilters: LocalFilters;
}

export enum ApiFilterKeys {
  MyProjects = 'my-projects',
  MyTasks = 'my-tasks',
  ArchivedUsers = 'archived-users',
  Completed = 'completed',
  // + Others, to add
}

export interface ApiFilterValue {
  key: ApiFilterKeys;
  value: string | number | boolean | null | any;
}

export interface ApiFilter {
  key: 'filters' | 'sorts'
  value: ApiFilterValue[]
}

export type BreadcrumbsFilterOptions = {
  target: string,
  component: any,
  displayValue: (value: Boolean) => string,
  entityLabel?: string,
  requirePermissionTo?: string,
}

export type BreadcrumbsFiltersMap = {
  [key in ApiFilterKeys]: BreadcrumbsFilterOptions
}

export interface Note {
  project_id: number;
  created_at: string | null;
  description: string | null;
  description_collaboration: string | null;
  image: string | null;
  name: string | null;
  notes: string | null;
  notifiable_user_ids: number[];
  pinned: boolean;
  type: 'note';
  order: number;
  visibility: NotesVisibility;
}

export interface EntitySelectAttributes {
  url: string;
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  urlParams?: any;
  initialValue?: any;
  clearable?: boolean,
}

export type TreeSelectItem = {
  value: string | number
  label: string
  children: TreeSelectItem[]
}

export type EntitySelectCreateParams = {
  enable: boolean;
  entityType: EntityTypes;
  trigger?: string;
  createParams?: EntityCrudParams;
  label?: string;
  createdCallback?: (entity: any) => void;
  dialogClosedCallback?: () => void;
  focusOnDialogClosed?: boolean;
  infoText?: string;
}

export enum ChatContext {
  AiChat = 'ai-chat',
  Discussion = 'discussion',
}

export type Calendar = {
  calendar_summary: string;
  calendar_id: string;
}

export type CalendarList = {
  isEnabled: boolean;
  selectedCalendar: string | null;
  url?: string;
  calendars: Calendar[];
}

export type StepperStep = {
  key: string;
  hidden?: boolean
}
