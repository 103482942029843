<template>
  <BaseAvatarPicker
    v-model="model"
    ref="picker"
    :disabled-options="disabledOptions"
    :autoShow="true"
  >
    <template #activator="{ avatar }">
      <BaseAvatar
        v-if="avatar"
        class="mx-auto"
        :avatar="avatar"
        size="sm"
      />
      <div
        v-else
        class="rounded-md w-8 h-8 cursor-pointer"
      >
        <div class="flex items-center justify-center h-full w-full rounded-md">
          <i class="fa-solid fa-image text-gray-300 text-3xl" />
        </div>
      </div>
    </template>
  </BaseAvatarPicker>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { get } from 'lodash-es';
import { ICellEditorParams } from '@ag-grid-community/core';

export default defineComponent({
  props: {
    params: {
      type: Object as () => ICellEditorParams<any>,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: null,
    }
  },
  computed: {
    row() {
      return this.params?.data
    },
    cellEditorParams() {
      return this.params?.colDef?.cellEditorParams || {}
    },
    disabledOptions() {
      return this.cellEditorParams?.disabledOptions || []
    },
    logoKey() {
      return this.cellEditorParams?.logoKey || 'attributes.image'
    },
  },
  methods: {
    getValue() {
      return this.model
    },
  },
  created() {
    this.model = get(this.row, this.logoKey)
  },
  mounted() {
    this.$refs.picker?.open?.();
  },
})
</script>
