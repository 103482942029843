import Mention from '@tiptap/extension-mention'
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import MentionNodeTemplate from '@/components/html/extensions/components/MentionNodeTemplate.vue';

export default Mention.extend({
  name: 'customMention',
  
  addNodeView() {
    return VueNodeViewRenderer(MentionNodeTemplate);
  }
});
