import DashboardLayout from "@/modules/dashboard/layout/DashboardLayout.vue";
import NotFound from '@/modules/auth/pages/404.vue'
import Welcome from '@/modules/dashboard/pages/welcome.vue'
import EditDashboards from '@/modules/dashboard/pages/edit-dashboards.vue'
import SelectOrganization from '@/modules/dashboard/pages/select-organization.vue'

// Project onboarding + steps
import Onboarding from '@/modules/dashboard/pages/onboarding.vue'
import NameYourProjectStep from '@/modules/dashboard/pages/onboarding/name-your-project.vue'
import AddProjectToolsStep from '@/modules/dashboard/pages/onboarding/add-project-tools.vue'
import CreateTasksStep from '@/modules/dashboard/pages/onboarding/create-tasks.vue'
import InvitePeopleStep from '@/modules/dashboard/pages/onboarding/invite-people.vue'
import SettingUpAccountStep from '@/modules/dashboard/pages/onboarding/setting-up-account.vue'
import CongratulationsStep from '@/modules/dashboard/pages/onboarding/congratulations.vue'

function getAllModuleRoutes() {
  const files = import.meta.globEager('../**/routes.js')
  const exceptions = ['auth', 'dashboard'] // we don't want to include these modules here
  let fileKeys = Object.keys(files)
  fileKeys = fileKeys.filter(pathName => {
    return exceptions.every(exception => !pathName.includes(exception))
  })
  const flatRoutes = []
  fileKeys.forEach(key => {
    flatRoutes.push(...files[key].default)
  })
  return flatRoutes
}


const allModuleRoutes = getAllModuleRoutes()

export default [
  {
    path: '/',
    name: 'Welcome',
    redirect: '/welcome',
    components: {
      default: DashboardLayout
    },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'welcome',
        name: 'Welcome',
        title: 'Welcome',
        description: 'Find the most relevant information for you in the application dashboard.',
        component: Welcome,
      },
      {
        path: 'edit-dashboards',
        name: 'Edit Dashboards',
        title: 'Edit Dashboards',
        component: EditDashboards,
      },
      ...allModuleRoutes,
      {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        component: NotFound
      },
    ]
  },
  {
    path: '/select-organization',
    name: 'Select Organization',
    component: SelectOrganization,
    meta: {
      requiresAuth: true,
      title: 'Select Organization',
    },
  },
  {
    path: '/onboarding',
    redirect: '/onboarding/name-your-project',
    name: 'Project Onboarding',
    title: 'Project Onboardng',
    component: Onboarding,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'name-your-project',
        name: 'Name Your Project',
        component: NameYourProjectStep,
      },
      {
        path: 'add-project-tools',
        name: 'Add Project Tools Step',
        component: AddProjectToolsStep,
      },
      {
        path: 'create-tasks',
        name: 'Create Tasks Step',
        component: CreateTasksStep,
      },
      {
        path: 'invite-people',
        name: 'Invite People Step',
        component: InvitePeopleStep,
      },
      {
        path: 'setting-up-account',
        name: 'Setting Up Account Step',
        component: SettingUpAccountStep,
      },
      {
        path: 'congratulations',
        name: 'Congratulations Step',
        component: CongratulationsStep,
      }
    ]
  },
]
