import i18n from "@/i18n"
import store from "@/store/index.js";
import { ColumnTypes, HeaderTypes } from '@/components/table/cells/tableCellComponents'
import { formatPrice } from "@/plugins/formatPrice";
import { formatPercent } from "@/plugins/formatPercent";
import { get } from "lodash";
import {
  ComparatorTypes,
  comparatorTypesMap,
  doesFilterPassTypesMap,
  FilterTypes,
  getDefaultOptionsColumn,
  TableColumn
} from "@/components/table/tableUtils";
import { GetQuickFilterTextParams, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import { customFieldAppliesToOptions } from "@/modules/accounts/utils/modelUtils";
import useCan from "@/modules/common/composables/useCan";
import { PAYMENT_STATUSES } from "@/modules/payments/enum/paymentEnums";
import Payment = App.Domains.Payments.Models.Payment;
import Data = API.Data;

const { can, actions } = useCan()

function getRowAmount(row: any) {
  return Number(row?.attributes?.amount || 0)
}

function getTaxAmount(row: any) {
  const tax = Number(row?.attributes?.tax_rate || 0) / 100
  return tax * getRowAmount(row)
}

function getTotalAmount(row: any) {
  const rowAmount = getRowAmount(row)
  const taxAmount = getTaxAmount(row)
  return rowAmount + taxAmount
}

export function getPaymentColumns(): { mainColumns: TableColumn[]; extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      name: i18n.t('Created Date'),
      prop: 'attributes.created_at',
      component: ColumnTypes.Date,
      showCardLabel: true,
      sortProp: 'created_at',
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.Date]('attributes.created_at'),
      hide: true,
      keepHidden: true,
      showInChooseColumns: false,
    },
    {
      name: i18n.t('Project'),
      prop: 'relationships.project.attributes.name',
      sortProp: 'project',
      sortable: true,
      component: ColumnTypes.Project,
      params: {
        entity_type: i18n.t('payment')
      },
      flex: 1,
      minWidth: 180,
      enableRowGroup: true,
      showCardLabel: true,
      chartDataType: 'category',
      getQuickFilterText: (params: GetQuickFilterTextParams<any>) => {
        return params.data?.relationships?.project?.attributes?.name
      },
      disabled: () => {
        return !!store.getters.project_id
      },
      filterBy: {
        prop: 'project_ids',
        component: 'ProjectSelect',
        props: {
          multiple: true,
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.project_id')
      },
      extendedCellClass: 'w-full sm:w-1/3',
      cardClass: 'font-bold text-gray-900 text-base leading-5',
      cardIconClass: 'font-bold text-purple-500 text-base leading-5',
      cardOrder: 1
    },
    {
      name: i18n.t('Group'),
      prop: 'relationships.group.attributes.name',
      sortProp: 'group',
      sortable: true,
      flex: 1,
      minWidth: 180,
      component: ColumnTypes.Link,
      params: {
        getLink(row: any) {
          const groupId = get(row, 'relationships.group.id')
          if (!groupId) {
            return ''
          }

          return `/groups/${groupId}`
        },
        getDescription(row: any) {
          return get(row, 'relationships.group.attributes.name') || '- -'
        }
      },
      enableRowGroup: true,
      showCardLabel: true,
      chartDataType: 'category',
      filterBy: {
        prop: 'group_ids',
        component: 'GroupSelect',
        props: {
          multiple: true,
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.group_id')
      },
      extendedCellClass: 'w-full sm:w-1/3',
      cardOrder: 2
    },
    {
      name: i18n.t('Description'),
      prop: 'attributes.description',
      required: true,
      sortProp: 'description',
      sortable: true,
      flex: 1,
      minWidth: 180,
      getQuickFilterText: (params: GetQuickFilterTextParams<any>) => {
        return params.data?.attributes?.description
      },
      filterBy: {
        prop: 'description',
        type: 'text',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.Text]('attributes.description')
      },
      extendedCellClass: 'w-full sm:w-1/3',
      showCardLabel: true,
      cardOrder: 3
    },
    {
      name: i18n.t('Status'),
      prop: 'attributes.payment_status',
      sortProp: 'payment_status',
      sortable: true,
      component: ColumnTypes.PaymentStatus,
      enableRowGroup: true,
      enableWidgetFilter: true,
      chartDataType: 'category',
      valueGetter: (params: ValueGetterParams) => {
        let { payment_status, is_recurring } = params?.data?.attributes || {}
        if (is_recurring) {
          return i18n.t('Recurring')
        }

        payment_status = payment_status || PAYMENT_STATUSES.UNPAID

        if (payment_status === PAYMENT_STATUSES.PAID) {
          return i18n.t('Paid')
        } else if (payment_status === PAYMENT_STATUSES.UNPAID) {
          return i18n.t('Unpaid')
        }
        return payment_status
      },
      filterBy: {
        prop: 'payment_status',
        type: 'PaymentStatusSelect',
        format: 'formatId',
        displayFormat: 'formatColorOption',
        doesFilterPass: (payment, filtervalue) => {
          let { payment_status, is_recurring } = payment?.attributes || {}
          if (is_recurring) {
            return false
          }

          payment_status = payment_status || PAYMENT_STATUSES.UNPAID

          return payment_status === filtervalue
        }
      },
      cardOrder: 7
    },
    {
      name: i18n.t('Currency'),
      prop: 'attributes.currency',
      hide: true,
      keepHidden: true,
      visibleInTable: false,
      showCardLabel: true,
      valueGetter: (params: ValueGetterParams) => {
        return params?.data?.attributes?.currency
      },
      pivot: true,
      filterBy: {
        prop: 'currency',
        component: 'CurrencySelect',
        format: 'formatValue',
        displayFormat: 'formatLabel',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.Select]('attributes.currency')
      },
    },
    {
      name: i18n.t('Amount'),
      prop: 'attributes.amount',
      sortProp: 'amount',
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.Numeric]('attributes.amount'),
      showCardLabel: true,
      chartDataType: 'series',
      aggFunc: 'sum',
      valueGetter: (params: ValueGetterParams) => {
        return getRowAmount(params?.data)
      },
      valueFormatter: (params: ValueFormatterParams) => {
        let value = params.value
        if (params.node?.group && !params.node?.footer) {
          value = get(params.node?.aggData, 'attributes.amount')
        }
        const row = params?.data || params?.node?.childrenAfterFilter?.[0]?.data
        const currencyCode = row?.attributes?.currency
        const isRecurring = row?.attributes?.is_recurring
        if (isRecurring) {
          return '---'
        }
        return formatPrice(value, {
          currencyCode
        })
      },
      filterBy: {
        prop: 'amount',
        type: 'range-slider',
        displayFormat(value: any) {
          return `
            ${formatPrice(value.min, { currencyDisplay: false })}
            -
            ${formatPrice(value.max, { currencyDisplay: false })}
          `
        },
        props: {
          formatTooltip(amount: string | number) {
            return formatPrice(amount, { currencyDisplay: false })
          },
          range: () => {
            return store.getters[`payments/dataPropRange`]('attributes.amount')
          }
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.NumericRange]('attributes.amount'),
      },
      cardOrder: 4
    },
    {
      name: i18n.t('Tax'),
      prop: 'attributes.tax_rate',
      params: {
        getAmount: getTaxAmount
      },
      sortable: true,
      sortProp: 'tax_rate',
      aggFunc: 'sum',
      comparator: comparatorTypesMap[ComparatorTypes.Numeric](getTaxAmount),
      showCardLabel: true,
      chartDataType: 'series',
      valueGetter: (params: ValueGetterParams) => {
        return getTaxAmount(params?.data)
      },
      valueFormatter: (params: ValueFormatterParams) => {
        let value = params.value
        if (params.node?.group && !params.node?.footer) {
          value = get(params.node?.aggData, 'attributes.tax_rate')
        }

        const row = params?.data || params?.node?.childrenAfterFilter?.[0]?.data
        const currencyCode = row?.attributes?.currency
        const isRecurring = row?.attributes?.is_recurring
        if (isRecurring) {
          return '---'
        }
        return formatPrice(value, {
          currencyCode
        })
      },
      filterBy: {
        label: i18n.t('Tax Rate (%)'),
        prop: 'tax_rate',
        type: 'range-slider',
        displayFormat(value: any) {
          return `${formatPercent(value.min)} - ${formatPercent(value.max)}`
        },
        props: {
          formatTooltip(amount: any) {
            return formatPercent(amount)
          },
          range: () => {
            return store.getters[`payments/dataPropRange`]('attributes.tax_rate')
          }
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.NumericRange]('attributes.tax_rate'),
      },
      cardOrder: 5
    },
    {
      name: i18n.t('Total'),
      prop: 'attributes.total',
      valueGetter: (params: ValueGetterParams) => {
        return getTotalAmount(params?.data)
      },
      valueFormatter: (params: ValueFormatterParams) => {
        let value = params.value
        if (params.node?.group && !params.node?.footer) {
          value = get(params.node?.aggData, 'attributes.total')
        }
        const row = params?.data || params?.node?.childrenAfterFilter?.[0]?.data
        const currencyCode = row?.attributes?.currency
        const isRecurring = row?.attributes?.is_recurring
        if (isRecurring) {
          return '---'
        }
        return formatPrice(value, {
          currencyCode
        })
      },
      params: {
        getAmount: getTotalAmount
      },
      aggFunc: 'sum',
      comparator: comparatorTypesMap[ComparatorTypes.Numeric](getTotalAmount),
      showCardLabel: true,
      sortProp: 'total_payment',
      sortable: true,
      filterBy: {
        prop: 'total_payment',
        type: 'range-slider',
        displayFormat(value: any) {
          return `
            ${formatPrice(value.min, { currencyDisplay: false })}
            -
            ${formatPrice(value.max, { currencyDisplay: false })}
          `
        },
        props: {
          formatTooltip(amount: any) {
            return formatPrice(amount, { currencyDisplay: false })
          },
          range: () => {
            return store.getters[`payments/dataPropRange`](getTotalAmount)
          }
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.NumericRange](getTotalAmount),
      },
      cardOrder: 5
    },
    {
      name: i18n.t('View'),
      prop: 'view-invoice',
      component: ColumnTypes.Link,
      params: {
        getLink(row: Data<Payment>) {
          const projectId = store.getters.project_id
          const isTemplatePayment = row?.relationships?.project?.attributes?.is_template

          let basePath = ''
          if (projectId) {
            basePath = isTemplatePayment ? `/templates/${projectId}/payments/` : `/projects/${projectId}/payments/`
          }

          const isRecurring = row?.attributes?.is_recurring
          if (isRecurring) {
            return `${basePath}${row.id}/details`
          }

          return `${basePath}${row.id}/invoice`
        },
        getDescription(row: Data<Payment>) {
          const isRecurring = row?.attributes?.is_recurring
          if (isRecurring) {
            return i18n.t('View Details')
          }
          return i18n.t('View Invoice')
        }
      },
      extendedCellClass: 'underline'
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn()
  }

  const addCustomFieldColumn: TableColumn = {
    headerComponent: HeaderTypes.AddCustomField,
    prop: 'add-custom-field',
    headerComponentParams: {
      defaultAppliesTo: customFieldAppliesToOptions.PAYMENT,
      tooltip: i18n.t('Add a new custom field for all payments across the account')
    },
    params: {
      valueFormatter: () => ''
    },
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    disabled: () => {
      return !can(actions.CREATE_CUSTOM_FIELDS) || store.getters['projects/isCurrentProjectClosed']
    },
    showInChooseColumns: false
  }

  const extraColumns: TableColumn[] = [addCustomFieldColumn, optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}

export const getExtraFilters = () => {
  const filters = [
    {
      key: 'my-payments',
      doesFilterPass: (row: Data<Payment>, filterValue: { show: boolean }) => {
        if (filterValue?.show) {
          const groupIds = store.getters['users/loggedInUserGroupIds'] || []
          const paymentGroupId = row?.attributes?.group_id
          return paymentGroupId && groupIds.includes(+paymentGroupId)
        }

        return true
      },
    }
  ]

  return filters
}


export const paymentFields = [
  'id',
  'project_id',
  'group_id',
  'description',
  'payment_status',
  'payment_provider',
  'currency',
  'is_recurring',
  'amount',
  'tax_rate',
  'total_payment',
  'created_at',
  'created_by',
  'custom_fields',
]
