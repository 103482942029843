<template>
  <li
    :key="isOpenedNotification"
    class="py-4 px-6 cursor-pointer hover:bg-gray-50 notification-list-item transition ease-in-out duration-1000"
    :class="{
      'bg-primary-50 hover:bg-primary-100': isPinned,
    }"
    @click="openedNotification = notification"
  >
    <div class="flex items-center space-x-4">
      <div
        class="shrink-0 flex flex-col justify-around items-center gap-1"
        :class="{
          '-ml-1': isSelectionEnabled,
         }"
      >
        <BaseRoundedCheckbox
          v-if="isSelectionEnabled"
          v-model="isSelected"
          size="sm"
          class="ml-1"
          @click.stop
        />
        <i
          class="fal text-2xl"
          :class="iconClass"
        />
      </div>
      <div class="flex-1">
        <p class="text-sm text-gray-500 truncate">
          <i
            v-if="isPinned"
            class="fas fa-thumbtack text-primary-500 mr-2"
          />
          <span class="text-base font-bold text-gray-900 leading-5 whitespace-pre-wrap">
            {{ title }}
          </span>
        </p>
        <p class="text-xs truncate font-semibold">
          {{ totalsLabel }}:
          <span class="text-gray-400">
            {{ $tc('Notifications', { count: totalsCount }) }}
          </span>
        </p>
        <p class="text-xs truncate font-semibold">
          {{ $t('Last') }}:
          <span class="text-gray-400">
            {{ formattedDate }}
          </span>
        </p>
      </div>
      <div
        v-if="isOpenedNotification"
        class="shrink-0"
      >
        <i class="far fa-angle-right text-3xl text-primary-500"/>
      </div>
    </div>
  </li>
</template>
<script>
import { get } from 'lodash-es'
import {
  notificationTypeProperties,
  NOTIFICATION_TYPES,
  notificationStatuses,
} from '@/modules/accounts/utils/notificationUtils'

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectedNotifications() {
      return this.$store.state.notifications.selectedNotifications || []
    },
    isSelected: {
      get() {
        return this.selectedNotifications.some(x => x.id === this.notification.id)
      },
      set(value) {
        this.$store.commit('notifications/toggleNotificationSelected', this.notification)
      },
    },
    isSelectionEnabled() {
      return this.$store.state.notifications.isNotificationSelectionEnabled
    },
    isPinned() {
      return this.notification?.is_pinned
    },
    isCustomBrandingEnabled() {
      return this.$store.getters['accounts/isCustomBrandingEnabled']
    },
    openedNotification: {
      get() {
        return this.$store.state.notifications.openedNotification
      },
      set(value) {
        this.$store.commit('notifications/setOpenedNotification', value)
      },
    },
    isOpenedNotification() {
      return this.openedNotification?.id === this.notification.id
    },
    statusFilter() {
      return this.$route.query?.status
    },
    unreadNotificationCount() {
      return this.notification.unread_notification_count || 0
    },
    readNotificationCount() {
      return this.notification.read_notification_count || 0
    },
    pinnedNotificationCount() {
      return this.notification.pinned_notification_count || 0
    },
    totalsLabel() {
      const labelMap = {
        [notificationStatuses.Read]: this.$t('Read'),
        [notificationStatuses.Unread]: this.$t('Unread'),
        [notificationStatuses.Pinned]: this.$t('Pinned'),
        default: this.$t('All'),
      }
      return labelMap[this.statusFilter] || labelMap.default
    },
    totalsCount() {
      const allNotificationCount = this.readNotificationCount + this.unreadNotificationCount
      const countMap = {
        [notificationStatuses.Read]: this.readNotificationCount,
        [notificationStatuses.Unread]: this.unreadNotificationCount,
        [notificationStatuses.Pinned]: this.pinnedNotificationCount,
        default: allNotificationCount,
      }
      return countMap[this.statusFilter] || countMap.default
    },
    title() {
      return this.notification.name
    },
    formattedDate() {
      const date = get(this.notification, 'last_notification_date')
      if (!date) {
        return ''
      }

      const formattedDate = this.$formatDate(
        new Date(date),
        null,
        /* lowerCaseMeridians */ true,
        /* withTime */ true,
      )
      return formattedDate
    },
    notificationTypeProperties() {
      return notificationTypeProperties[NOTIFICATION_TYPES.PROJECT]
    },
    iconClass() {
      const icon = this.notificationTypeProperties.iconClass
      let color = this.notificationTypeProperties.iconColorClass

      if (this.isCustomBrandingEnabled) {
        color = 'text-primary-500'
      }

      return `${icon} ${color}`
    },
  },
}
</script>
