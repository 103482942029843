import { isProduction } from '@/modules/common/config'

const fbPixelId = import.meta.env.VITE_FB_PIXEL_ID

export function loadFbPixel() {
  if (!fbPixelId || !isProduction()) {
    return
  }
  try {
    fbq('init', fbPixelId)
    fbq('track', 'PageView')
  } catch(e) {
    console.warn(e)
  }
}
