<template>
  <ElCollapseItem
    :name="feature.key"
    class="trial-feature-item group p-6"
  >
    <template #title>
      <div>
        <dt>
          <div
            class="flex w-full items-start justify-between text-left text-gray-900 hover:text-gray-900"
            aria-controls="faq-4"
            aria-expanded="false"
          >
            <span class="text-base font-semibold leading-7">
              <i
                class="align-middle text-2xl w-8 mr-2 -mt-1 text-gray-400 fa-light"
                :class="{
                  [`${feature.iconClass}`]: true
                }"
                aria-hidden="true"
              />
              {{ feature.title }}
            </span>
          </div>
        </dt>
      </div>
    </template>

    <dd
      class="mt-6"
      :class="{
        'sm:grid sm:grid-cols-3': feature.videoId,
      }"
    >
      <p class="col-span-2 pr-4 text-sm text-gray-500">
        {{ feature.description }}

        <span
          v-if="feature.videoId"
          class="block mt-2"
        >
          {{  $t(`Watch the video to learn more.`) }}
          <i class="fa-solid fa-arrow-right ml-1" aria-hidden="true" />
        </span>
      </p>

      <WistiaVideoEmbed
        v-if="feature.videoId"
        :videoId="feature.videoId"
      />
    </dd>
    <button
      v-if="feature.state.status === TrialFeatureStatuses.NotStarted"
      type="button"
      class="block rounded-md bg-primary-500 px-3 py-2 mt-2 mr-1 text-sm text-white"
      @click="startTrial"
    >
      <LoadingIcon
        v-if="startingTrial"
      />
      <span v-else>{{ $t(`Start 7-day trial`) }}</span>
    </button>

    <BaseAlert
      v-else-if="feature.state.status === TrialFeatureStatuses.Started"
      variant="primary"
      class="mt-2"
      :dismissable="false"
    >
      {{
        $tc(`trial active until`, {
          date: $formatDate(feature.state.expires_at || '')
        })
      }}
    </BaseAlert>

    <BaseAlert
      v-else-if="feature.state.status === TrialFeatureStatuses.Ended"
      variant="primary"
      class="mt-2"
      :dismissable="false"
    >
      {{ $t(`You have already trialled this feature. Upgrade your account to get access to this feature or contact support for help.`) }}
    </BaseAlert>
  </ElCollapseItem>
</template>
<script lang="ts" setup>
import { PropType, ref } from 'vue';
import i18n from '@/i18n';
import { error } from '@/components/common/NotificationPlugin';

import { ElCollapseItem } from 'element-plus';
import WistiaVideoEmbed from '@/components/common/WistiaVideoEmbed.vue';
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";

import {
  TrialFeature,
  TrialFeatureStatuses,
  useTrialManager
} from '@/modules/accounts/composables/useTrialManager';

const {
  startFeaturesTrial
} = useTrialManager();

const props = defineProps({
  feature: {
    type: Object as PropType<TrialFeature>,
    required: true
  }
})

const startingTrial = ref(false);

async function startTrial() {
  try {
    startingTrial.value = true;
    const features = [ props.feature.key,  ...(props.feature.additionalKeys || []) ];
    await startFeaturesTrial(features);
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('An error occurred while starting the trial. Please try again later.'));
  }
  finally {
    startingTrial.value = false;
  }
}
</script>
<style lang="scss">
.trial-feature-item {
  .el-collapse-item__header {
    @apply flex items-center justify-between;
  }

  .el-collapse-item__arrow.is-active {
    @apply rotate-90;
  }

  &.is-disabled {
    .el-collapse-item__header {
      @apply cursor-default;

      .el-collapse-item__arrow {
        @apply hidden;
      }
    }
  }
}
</style>
