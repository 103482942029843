<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-2xl relative"
    @close="$emit('close')"
  >
    <BaseEntityForm
      :title="$t('Add Groups')"
      :subtitle="$t(`Add groups to this project. When you add groups to a project it means you can filter & sort your projects by group.`)"
      :isDialogForm="true"
      width-class="max-w-2xl"
    >
      <template #default="{ meta, errors }">
        <div class="flex space-x-2 w-full relative">
          <div class="w-full">
            <GroupSelectNew
              v-model="addGroupIds"
              :excludedOptions="projectGroupIds"
              :createEntityParams="{
                enable: true,
                entityType: 'group',
                label: $t('Create New Group'),
              }"
              multiple
            />
          </div>
          <BaseButton
            :disabled="!addGroupIds?.length"
            :loading="addingGroups"
            @click="addGroups"
          >
            <div class="flex items-center space-x-2">
              <i class="fa fa-plus" />
              <span class="hidden sm:inline">{{ $t('Add') }}</span>
            </div>
          </BaseButton>
        </div>
        <div class="flow-root mt-6">
          <ul
            class="divide-y divide-gray-200"
          >
            <ProjectGroup
              v-for="group in projectGroups"
              :key="group.id"
              :group="group"
              :project="project"
              class="py-2"
            />
          </ul>
        </div>
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          @click="$emit('close')"
        >
          {{ $t("I'm done adding groups - Close") }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
// Components
import GroupSelectNew from '@/components/selects/GroupSelectNew.vue'
import ProjectGroup from "@/modules/projects/components/ProjectGroup.vue"

// Utils
import i18n from '@/i18n';
import { PropType, computed, ref } from "vue";
import { error } from '@/components/common/NotificationPlugin';
import apiCache from '@/modules/common/utils/apiCache';

import Data = API.Data;
import Project = App.Domains.Projects.Models.Project;
import Group = App.Domains.Groups.Models.Group;

// Composables
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  project: {
    type: Object as PropType<Data<Project>>,
    default: () => ({}),
  },
})

const addGroupIds = ref<number[]>([])
const addingGroups = ref<boolean>(false)

async function addGroups() {
  let newGroupIds = addGroupIds.value.filter(id => !projectGroupIds.value.includes(id))
      
  if (!newGroupIds.length) {
    error(i18n.t('Selected groups are already part of the project'))
    return
  }

  newGroupIds = projectGroupIds.value.concat(newGroupIds)

  addingGroups.value = true

  try {
    await store.dispatch('projects/editProject', {
      project: props.project,
      data: {
        ...props.project?.attributes,
        custom_fields: JSON.stringify(props.project?.attributes?.custom_fields),
        group_ids: newGroupIds,
      }
    })
    
    // We sync groups to display the correct projects count in the list view
    await store.dispatch('groups/syncGroups', newGroupIds)

    addGroupIds.value = []
    apiCache.removeForEntity('groups')
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('Could not add groups to the project'))
  }
  finally {
    addingGroups.value = false
  }
}

const projectGroups = computed<Data<Group>[]>(() => {
  return props.project?.relationships?.groups || []
})

const projectGroupIds = computed<number[]>(() => {
  return projectGroups.value.map((group) => +group.id)
})
</script>
