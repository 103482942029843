import axios from 'axios'
import {
  OnboardingQuestionKeys
} from "@/modules/auth/utils/registerUtils"

const state = () => ({
  templates: [],
  templatesLoading: true,
  categories: [],
  categoriesLoading: false,
  selectedTemplate: null,
  dialogTrigger: 1,
  templateDialogTrigger: 1
})

const mutations = {
  setTemplates(state, value) {
    state.templates = value
    state.templatesLoading = false
  },
  setTemplatesLoading(state, value) {
    state.templatesLoading = value
  },
  setCategories(state, value) {
    state.categories = value
  },
  setCategoriesLoading(state, value) {
    state.categoriesLoading = value
  },
  setSelectedTemplate(state, value) {
    state.selectedTemplate = value
  },
  triggerDialogOpen(state) {
    state.dialogTrigger++
  },
  triggerTemplateDialogOpen(state) {
    state.templateDialogTrigger++
  }
}

const actions = {
  async getTemplates({ state, commit }) {
    try {
      if (state.templates.length) {
        return
      }

      commit('setTemplatesLoading', true)

      const { data: templates } = await axios.get('/restify/templates', {
        params: {
          related: 'categories'
        }
      })
      console.log(templates)
      commit('setTemplates', templates)
    } finally {
      commit('setTemplatesLoading', false)
    }
  }, 
  async getCategories({state, commit }) {
    try {
      if (state.categories.length) {
        return
      }
      commit('setCategoriesLoading', true)

      const { data: categories } = await axios.get('/restify/categories')
      console.log(categories)
      commit('setCategories', categories)
    } finally {
      commit('setCategoriesLoading', false)
    }
  },
}

const getters = {
 recommendedTemplates: (state, getters, rootState, rootGetters) => {
  const onboardingAnswers = rootGetters['auth/onboardingAnswers']
  const businessFocus = onboardingAnswers[OnboardingQuestionKeys.BusinessFocus]

  return state.templates.filter(template => {
    const categoryNames = template.relationships.categories.map(category => category.attributes.name)
    return categoryNames.includes(businessFocus)
  })
 }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
