<template>
  <slot name="activator">
    <span>{{ modelValue?.name || placeholder || $t('+ Add a photo/icon') }}</span>
  </slot>
  <input
    v-bind="$attrs"
    :multiple="multiple"
    name="file-upload"
    type="file"
    class="sr-only"
    @change="onFileChange"
  >
</template>
<script>
import { readURL } from "@/modules/common/utils/imageUtils.js";

export default {
  props: {
    modelValue: {
      type: [Object, String],
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: ''
    },
    preview: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async onFileChange(event) {
      if (this.multiple) {
        this.$emit('update:modelValue', event.target?.files)
        return
      }
      const file = event.target?.files?.[0]
      if (!file) {
        return
      }

      const imageUrl = await readURL(file)
      this.$emit('update:preview', imageUrl)
      this.$emit('update:modelValue', file)
    }
  }
}
</script>
