<template>
  <el-time-picker
    v-model="date"
    :size="size"
    :format="format"
    :placeholder="placeholder || $t('Enter hours...')"
    :default-value="null"
    :disabled="disabled"
  />
</template>
<script>
// Libs
import { ElTimePicker } from 'element-plus'
import { inject } from "vue";

// Helpers
import { getMinutesFromDate, minutesToDate, getTimeDate } from "@/modules/common/utils/dateUtils";

export default {
  inheritAttrs: false,
  components: {
    ElTimePicker
  },
  props: {
    modelValue: {
      type: [Date, Number, String],
      default: 0
    },
    minutes: {
      type: Number,
      default: 0
    },
    seconds: {
      type: Number,
      default: 0
    },
    format: {
      type: String,
      default: 'HH:mm'
    },
    size: {
      type: String,
      default: 'medium'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minutesInput: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update:minutes', 'update:seconds'],
  setup() {
    return {
      handleFormChange: inject('handleChange'),
    }
  },
  computed: {
    date: {
      get() {
        let now
        if (this.minutesInput) {
          now = minutesToDate(this.modelValue)
        }
        else {
          now = getTimeDate(this.modelValue, this.minutes, this.seconds)
        }
        return now;
      },
      set(value) {
        if (this.minutesInput) {
          const minutes = getMinutesFromDate(value)
          this.$emit('update:modelValue', minutes);
          if (this.handleFormChange) {
            this.handleFormChange(minutes) 
          }
        }
        else {
          const hoursValue = value && value.getHours() || 0
          this.$emit('update:modelValue', hoursValue);
          this.$emit('update:minutes', value && value.getMinutes() || 0);
          this.$emit('update:seconds', value && value.getSeconds() || 0);
          if (this.handleFormChange) {
            this.handleFormChange(hoursValue);
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.el-input.el-date-editor.el-date-editor--time {
  width: 100%;

  input.el-input__inner {
    height: 46px;
  }

  .el-input__prefix, .el-input__suffix, .el-input__suffix-inner {
    display: flex;
    align-items: center;
  }
}
</style>
