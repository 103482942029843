<template>
  <BaseSelect
    :options="options"
    :labelPrefix="$t('Group by:')"
    :placeholder="$t('Group by: Choose group by field...')"
    :returnObject="false"
    clearable
  />
</template>
<script lang="ts" setup>
import { TableColumn } from "@/components/table/tableUtils";
import { PropType, computed } from "vue";

const props = defineProps({
  columns: {
    type: Object as PropType<TableColumn[]>,
    required: true,
  },
})

const options = computed(() => {
  return props.columns.map((column) => {
    const name = typeof column.name === 'function'
      ? column.name()
      : column.name

    return {
      value: column.prop,
      label: name,
    }
  })
})
</script>
