<template>
  <div class="sm:flex-col-grow">
    <TopFilters
      :columns="notificationColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search inbox...')"
    >
      <EntityViewTypesSelect
        v-if="$isMobileDevice"
        :viewTypes="viewTypes"
        :title="$t('Change notifications type')"
        icon="fa-bell"
      />
    </TopFilters>
    <router-view />
  </div>
</template>
<script>
// Components
import TopFilters from '@/modules/filters/components/TopFilters.vue'
import EntityViewTypesSelect from '@/modules/common/components/EntityViewTypesSelect.vue'

// Utils
import { debounce } from 'lodash-es'
import { notificationStatuses } from '@/modules/accounts/utils/notificationUtils.js'
import useListFetch from '@/modules/common/composables/useListFetch.js'

export default {
  components: {
    TopFilters,
    EntityViewTypesSelect,
  },
  setup() {
    const {
      fetchData: getNotifications
    } = useListFetch({ action: 'notifications/getNotifications' })

    return {
      getNotifications,
    }
  },
  data() {
    return {
      availableFilters: ['filter'], // , 'group'] TODO: re-dd later
    }
  },
  computed: {
    notificationColumns() {
      return this.$store.state.notifications.notificationColumns
    },
    selectedNotifications: {
      get() {
        return this.$store.state.notifications.selectedNotifications
      },
      set(value) {
        this.$store.commit('notifications/setSelectedNotifications', value)
      },
    },
    viewTypes() {
      return [
        {
          name: this.$t('Unread'),
          icon: 'fa-envelope',
          path: this.getPath({ status: notificationStatuses.Unread }),
          isActive: this.$route.query.status === notificationStatuses.Unread,
        },
        {
          name: this.$t('Read'),
          icon: 'fa-envelope-open',
          path: this.getPath({ status: notificationStatuses.Read }),
          isActive: this.$route.query.status === notificationStatuses.Read,
        },
        {
          name: this.$t('Pinned'),
          icon: 'fa-thumbtack',
          path: this.getPath({ status: notificationStatuses.Pinned }),
          isActive: this.$route.query.status === notificationStatuses.Pinned,
        },
        {
          name: this.$t('All'),
          icon: 'fa-bell',
          path: this.getPath(),
          isActive: !(this.$route.query.status),
        },
      ]
    },
  },
  methods: {
    async resetAndReload() {
      if (this.$route.path !== '/inbox') {
        return
      }

      this.selectedNotifications = []

      const path = this.$route.path
      const query = {
        ...this.$route.query,
      }

      const route = this.$router.resolve({
        path,
        query,
      })

      await this.$router.replace(route)

      this.getNotifications()
    },
    getPath(queryParams) {
      let basePath = '/inbox'
      let query = {
        ...this.$route.query,
      }

      delete query.status

      if (queryParams) {
        query = {
          ...queryParams,
          ...query,
        }
      }

      return {
        path: basePath,
        query,
      }
    },
  },
  watch: {
    '$route.query.status'() {
      this.resetAndReloadDebounced()
    },
  },
  mounted() {
    this.resetAndReloadDebounced = debounce(this.resetAndReload, 50)
  },
}
</script>
