<template>
  <node-view-wrapper as="span">
    <ElPopover
      trigger="click"
      width="auto"
      placement="top"
      @show="onPopoverShow"
    >
      <template #reference>
        <span
          class="cursor-pointer font-medium p-0.5 rounded-sm editor-mention"
          :class="{
            'text-primary-500 bg-primary-50 hover:bg-primary-100': !isCurrentUser,
            'text-yellow-500 bg-yellow-50 hover:bg-yellow-100': isCurrentUser,
          }"
        >
          @{{ node.attrs.label }}
        </span>
      </template>

      <UserPreviewCard
        v-if="user"
        :user="user"
        type="inline"
      >
        <div class="flex justify-center">
          <BaseButton
            @click="navigateToUser"
            variant="primary"
            size="sm"
          >
            {{ $t('View Profile') }}
          </BaseButton>
        </div>
      </UserPreviewCard>
    </ElPopover>
  </node-view-wrapper>
</template>
<script>
import { ElPopover } from 'element-plus'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import UserPreviewCard from "@/modules/users/components/UserPreviewCard.vue";

export default {
  components: {
    ElPopover,
    NodeViewWrapper,
    UserPreviewCard,
  },
  props: nodeViewProps,
  data() {
    return {
      user: {},
    }
  },
  computed: {
    projectUsers() {
      return this.$store.state.projects.currentProject?.relationships?.users || []
    },
    userId() {
      return this.node.attrs?.id
    },
    isCurrentUser() {
      return this.node.attrs?.id?.toString() === this.$user?.id?.toString()
    }
  },
  methods: {
    async navigateToUser() {
      await this.$router.push(`/users/${this.user.id}/view`)
    },
    async onPopoverShow() {
      this.user = this.projectUsers?.find(u => u.id === this.userId)
      this.user = await this.$store.dispatch('users/getUserById', { id: this.userId, returnEntity: true })
    },
  }
}
</script>
