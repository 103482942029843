<template>
  <div class="isolate flex flex-wrap gap-2 overflow-hidden items-center">
    <slot name="label" />
    <div
      v-for="user of displayedUsers"
      :key="user.id"
      class="bg-gray-50 hover:bg-gray-100 text-xs text-gray-400 hover:text-gray-500 p-0.5 pr-2 border border-gray-200 rounded-full cursor-pointer flex items-center space-x-1"
    >
      <UserLogo
        :user="user"
        :actions="actions"
        class="flex items-center"
        size="xs"
        rounded="full"
        @remove="emit('remove', user)"
      >
        <template #after>
          <span>{{ user.attributes.name || user.attributes.email }}</span>
        </template>
      </UserLogo>
    </div>
    <div
      v-if="users.length > displayMaxCount"
      class="bg-gray-50 hover:bg-gray-100 text-xs text-gray-400 hover:text-gray-500 p-0.5 pr-2 border border-gray-200 rounded-full cursor-pointer flex items-center space-x-1"
      @click="showAll = !showAll"
    >
      <span class="ml-1">
        {{ showMoreLabel }}
      </span>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Components
import UserLogo from "@/components/common/UserLogo.vue";

// Utils
import { PropType, computed, ref } from 'vue';
import i18n from "@/i18n";

import Data = API.Data
import User = App.Domains.Users.Models.User

import {
  UserPreviewActions,
} from "@/modules/users/util/userUtils";

const props = defineProps({
  users: {
    type: Array as PropType<Data<User>[]>,
    default: () => []
  },
  actions: {
    type: Array as PropType<UserPreviewActions[]>,
    default: () => [UserPreviewActions.View]
  },
  displayMaxCount: {
    type: Number,
    default: 5
  }
})

const emit = defineEmits(['remove'])

const showAll = ref(false)

const displayedUsers = computed(() => {
  if (showAll.value) {
    return props.users
  }

  return props.users.slice(0, props.displayMaxCount)
})

const showMoreLabel = computed(() => {
  if (showAll.value) {
    return i18n.t('Show less')
  }
  return i18n.tc('plus count others', {
    count: props.users.length - props.displayMaxCount
  })
})
</script>
