<template>
  <main class="p-2 sm:p-4 body-background h-full">
    <div class="bg-white rounded-lg h-full overflow-hidden">
      <div class="md:grid md:grid-cols-2 h-full">

        <!--LEFT SECTION: START-->
        <div class="col-span-1 p-4 sm:p-12 overflow-y-scroll">
          <BaseAppLogo
            :logoSrc="logoSrc"
            class="h-8"
          />

          <!--ACTION: START-->
          <div class="mt-14 md:mt-20">
            <div class="space-y-4">
              
              <h1 class="text-gray-900 text-3xl sm:text-4xl font-extrabold">
                {{ $t('Create your account') }}
              </h1>
              <p class="text-gray-500 leading-7 text-lg">
                {{ $t(`It's time to start managing your work better.`) }}
              </p>

              <SocialLinks
                v-if="allowSocialRegister"
                light
                isRegister
              />

              <div class="relative mt-10">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-200"></div>
                </div>
                <div class="relative flex justify-center text-sm font-medium leading-6">
                  <span class="bg-white px-6 text-gray-400">
                    {{ $t('or') }}
                  </span>
                </div>
              </div>

              <BaseForm
                @submit="onSubmit"
              >
                <template #default="{ handleSubmit, errors, meta }">
                  <div class="grid grid-cols-2 gap-4 mt-2 items-start">
                    <BaseInput
                      v-model="model.first_name"
                      :placeholder="$t('First Name')"
                      :name="$t('First Name')"
                      id="register_first_name"
                      rules="required|max:25"
                      class="w-full text-base shadow-none"
                    />
                    <BaseInput
                      v-model="model.last_name"
                      :placeholder="$t('Last Name')"
                      :name="$t('Last Name')"
                      id="register_last_name"
                      rules="required|max:25"
                      class="w-full text-base shadow-none"
                    />
                  </div>
                  <div class="relative mt-2 flex items-center">
                    <BaseInput
                      v-model="model.email"
                      :placeholder="$t('Email Address')"
                      :name="$t('Email')"
                      id="register_email"
                      rules="required|email"
                      class="w-full text-base shadow-none"
                    />
                  </div>
                  <div class="relative mt-2 flex items-center">
                    <BaseInput
                      v-model="model.password"
                      type="password"
                      :placeholder="$t('Password')"
                      :name="$t('Password')"
                      id="register_password"
                      rules="required|min:8|max:50"
                      class="w-full text-base shadow-none"
                    />
                  </div>
                  <button
                    type="submit"
                    class="mt-4 rounded-md body-background px-6 py-4 text-sm font-semibold text-white"
                    :class="{
                      'opacity-80 cursor-not-allowed': !meta.valid,
                      'hover:px-8 transition-all': meta.valid
                    }"
                    :disabled="!meta.valid"
                  >
                    {{ $t('Create Account') }}
                    <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
                  </button>
                </template>
              </BaseForm>
            
            </div>
          </div>
          <!--ACTION: END-->
        </div>
        <!--LEFT SECTION: END-->

        <!--RIGHT SECTION: START-->
        <div class="hidden md:block col-span-1 bg-gray-200 overflow-hidden">
          
          <section class="relative isolate overflow-hidden px-32 mt-48">
            <div class="mx-auto max-w-2xl lg:max-w-4xl">
              <img class="mx-auto h-10"
              src="/img/register_seedmill.png"
                alt=""
              >
              <figure class="mt-6">
                <blockquote class="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                  <p>“Project.co has simplicity at its heart. This is born out of a strong focus towards project management and very little distraction. The user interface doesn't get in your way and the user experience is uncomplicated and productive.”</p>
                </blockquote>
                <figcaption class="mt-6">
                  <img
                    class="mx-auto h-16 w-16 rounded-full"
                    src="/img/register_seedmill_founder.jpg"
                    alt=""
                  >
                  <div class="mt-4 flex items-center justify-center space-x-3 text-base">
                    <div class="font-semibold text-gray-900">Ray Dale</div>
                    <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" class="fill-gray-900">
                      <circle cx="1" cy="1" r="1"></circle>
                    </svg>
                    <div class="text-gray-600">Co-founder of The SeedMill</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </section>
          

        </div>
        <!--RIGHT SECTION: END-->
      </div>
    </div>
  </main>
</template>
<script>
// Components
import SocialLinks from '@/modules/auth/components/SocialLinks.vue'
import AppSumoCodesInput from  '@/modules/common/components/AppSumoCodesInput.vue'

// Utils
import {
  registerCampaigns,
  applyCampaignBranding,
} from "@/modules/auth/utils/registerUtils"
import { logoDarkFull } from '@/modules/accounts/utils/accountBrandingUtils'
import { merge } from "lodash-es";

export default {
  components: {
    SocialLinks,
    AppSumoCodesInput
  },
  data() {
    const {
      first_name,
      last_name,
      email,
      avatar,
      provider,
      campaign,
    } = this.$route.query

    return {
      loading: false,
      model: {
        first_name,
        last_name,
        email,
        avatar,
        provider,
        password: '',
        campaign: campaign ||'',
      },
    }
  },
  computed: {
    isSocialRegister() {
      return this.$route.query.provider
    },
    logoSrc() {
      return logoDarkFull.value
    },
    activeCampaign() {
      return registerCampaigns.find(campaign => campaign.name === this.model.campaign)
    },
    allowSocialRegister() {
      return !this.isSocialRegister && !this.activeCampaign
    },
    registerModel() {
      return this.$store.state.auth.registerModel || {}
    }
  },
  methods: {
    async onSubmit() {
      this.$store.commit('auth/setRegisterModel', this.model)
      this.$router.push('/register/onboarding')
    },
    async initModel() {
      const registerModel = this.registerModel || {}
      this.model = merge(registerModel, this.model)
      
      await this.$nextTick()

      if (this.isSocialRegister) {
        this.onSubmit()
      }
    },
    checkActiveCampaign() {
      if (!this.activeCampaign) {
        return
      }

      applyCampaignBranding(this.activeCampaign)
    }
  },
  beforeMount() {
    this.initModel()
    this.checkActiveCampaign()
  }
}
</script>
