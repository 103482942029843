// Libs
import axios from "axios";
import { get, set } from 'lodash-es'

// Helpers
import { getAttachUrl, getRemoveUrl, getRemoveModel } from "@/modules/files/utils/urlUtils.js";
import { getFileTypeOptions } from "@/modules/files/utils/filtersUtils";

import { getFileColumns, fileFields, folderFields } from "@/modules/files/utils/fileTableUtils"
import { columnBuilder } from '@/components/table/tableUtils'
import { transformToRestifyArray, entityToRestifyMapper } from "@/modules/common/utils/dataUtils";
import {
  syncEntityInArray,
  EntityChangeEvent,
  getEntityArrayForWorker,
  groupEntitiesToMap
} from "@/modules/common/utils/entityUtils";

import {
  getFileWithRelationshipsAndPath,
  getFolderWithRelationshipsAndPath,
  computeFilesTreeDataWorker,
  computeFoldersTreeWorker
} from '@/modules/files/utils/fileTreeUtils'

const state = () => ({
  files: {
    data: [],
  },
  allFiles: [],
  allFilesLoaded: false,
  uploadProgress: {

  },
  allFolders: [],
  allFoldersLoaded: false,
  filesLoading: true,
  currentFileLoading: false,
  currentFile: null,
  addFileTrigger: 1,
  addFolderTrigger: 1,
  filesTreeData: [],
  foldersTree: [],
  archiveState: {
    name: '',
    status: 'none',
    blob: null
  }
})

const mutations = {
  setFilesTreeData(state, value) {
    state.filesTreeData = value
  },
  setFoldersTree(state, value) {
    state.foldersTree = value
  },
  setFiles(state, value) {
    state.files = value
  },
  setAllFiles(state, value) {
    state.allFiles = value
    state.allFilesLoaded = true
  },
  setAllFilesLoaded(state, value) {
    state.allFilesLoaded = value
  },
  addFile(state, file) {
    syncEntityInArray(file, state.filesTreeData, EntityChangeEvent.Create)
  },
  updateFile(state, file) {
    syncEntityInArray(file, state.filesTreeData, EntityChangeEvent.Update)
  },
  deleteFile(state, id) {
    syncEntityInArray({ id, type: 'media' }, state.filesTreeData, EntityChangeEvent.Delete)
  },
  setFilesLoading(state, value) {
    state.filesLoading = value
  },
  setCurrentFileLoading(state, value) {
    state.currentFileLoading = value
  },
  setCurrentFile(state, value) {
    state.currentFile = value
  },
  triggerAddFile(state) {
    state.addFileTrigger++
  },
  triggerAddFolder(state) {
    state.addFolderTrigger++
  },
  setUploadProgress(state, { id, file, progress }) {
    state.uploadProgress[id] = {
      file,
      progress
    }
  },
  setAllFolders(state, value) {
    state.allFolders = value
    state.allFoldersLoaded = true
  },
  addFolder(state, folder) {
    syncEntityInArray(folder, state.filesTreeData, EntityChangeEvent.Create)
  },
  updateFolder(state, folder) {
    syncEntityInArray(folder, state.filesTreeData, EntityChangeEvent.Update)
  },
  deleteFolder(state, id) {
    syncEntityInArray({ id, type: 'folders' }, state.filesTreeData, EntityChangeEvent.Delete)
  },
  setArchiveState(state, archiveState) {
    state.archiveState = archiveState
  }
}

const actions = {
  addUploadedFiles({ commit }, files) {
    for (const file of files) {
      if (file.model_type === 'task') {
        file.task_id = file.model_id
      }

      let newFile = entityToRestifyMapper(file, { type: 'media' });
      newFile = getFileWithRelationshipsAndPath(newFile)
      commit('addFile', newFile);
    }
  },
  async getFiles({ state, commit, rootGetters, rootState }, filters) {
    try {
      if (filters?.shouldReset === true) {
        commit('setFiles', { data: [] })
      }
      filters = filters || {...rootState.route?.query }
      commit('setFilesLoading', true)
      const files = await axios.get('/restify/media', {
        params: {
          ...filters,
          related: 'target,project',
          project_id: rootGetters.project_id
        }
      })

      const allFiles = getEntityArrayForWorker(files?.data || [])
      const allTasks = getEntityArrayForWorker(rootState.tasks.allTasks || [])
      const allProjects = getEntityArrayForWorker(rootState.projects.allProjects || [])
      const allUsers = getEntityArrayForWorker(rootState.users.allUsers || [])
      const allFolders = getEntityArrayForWorker(state.allFolders || [])

      const treeData = await computeFilesTreeDataWorker(allFiles, allFolders, allProjects, allTasks, allUsers)

      commit('setFiles', {
        data: treeData,
        meta: files.meta
      })
    } finally {
      commit('setFilesLoading', false)
    }
  },
  async getAllFiles({ state, commit, dispatch }) {
    if (state.allFilesLoaded) {
      return
    }

    try {
      commit('setFilesLoading', true)

      const entities = ['files', 'folders']
      const {
        data: {
          files,
          folders
        }
      } = await axios.get('/data', {
        params: {
          entities: `[${entities.join('|')}]`,
          fileFields: `[${fileFields.join('|')}]`,
          folderFields: `[${folderFields.join('|')}]`,
        }
      })

      const allFiles = transformToRestifyArray(files, {
        type: 'media',
        getAttributes: (file) => {
          const mime_type = file.mime_type
          const project_id = file.project_id
          const task_id = file.model_type === 'task' ? file.model_id : null

          let mime_type_label = ''

          for (const option of getFileTypeOptions()) {
            if (option.value.includes(mime_type)) {
              mime_type_label = option.label
              break;
            }
          }

          return {
            ...file,
            mime_type_label,
            project_id,
            task_id,
          }
        },
        getRelationships: (file) => {
          const project_id = file.project_id
          const task_id = file.model_type === 'task' ? file.model_id : null

          return {
            target: {
              project_id,
              task_id
            }
          }
        }
      })

      const allFolders = transformToRestifyArray(folders, { type: 'folders' })

      commit('setAllFiles', allFiles)
      commit('setAllFolders', allFolders)

      dispatch('setFilesTreeData')
    }
    catch (err) {
      console.error('Error fetching files', err)
      commit('setFilesLoading', false)
    }
  },
  async setFilesTreeData({ state, commit, rootState, dispatch }, options = null) {
    if (!state.allFiles.length && !state.allFolders.length) {

      if (state.allFilesLoaded) {
        commit('setFilesLoading', false)
      }

      return
    }
    const tasksLoaded = rootState.tasks.allTasksLoaded
    const completedTasksLoaded = rootState.tasks.completedTasksLoaded

    const relationshipsLoaded = rootState.projects.allProjectsLoaded && (
      options?.bindCompletedTasks
        ? completedTasksLoaded
        : tasksLoaded
    )

    if (!relationshipsLoaded) {
      return
    }

    const tasks = options?.bindCompletedTasks
      ? rootState.tasks.globalCompletedTasks || []
      : rootState.tasks.allTasks || []

    const allFiles = getEntityArrayForWorker(state.allFiles || [])
    const allTasks = getEntityArrayForWorker(tasks)
    const allProjects = getEntityArrayForWorker(rootState.projects.allProjects || [])
    const allUsers = getEntityArrayForWorker(rootState.users.allUsers || [])
    const allFolders = getEntityArrayForWorker(state.allFolders || [])

    const treeData = await computeFilesTreeDataWorker(allFiles, allFolders, allProjects, allTasks, allUsers)
    commit('setFilesTreeData', treeData)
    commit('setFilesLoading', false)
    
    dispatch('setFoldersTreeData')
  },
  async setFoldersTreeData({ state, commit }) {
    const filesTreeData = getEntityArrayForWorker(state.filesTreeData)

    const foldersTreeData = await computeFoldersTreeWorker(filesTreeData)

    commit('setFoldersTree', foldersTreeData)
  },
  async updateFile({ commit }, file) {
    const { data } = await axios.put(`/restify/media/${file.id}`, file)

    let newFile = getFileWithRelationshipsAndPath(data);
    commit('updateFile', newFile)

    return data
  },
  async getFileById({ commit }, { fileId, addToList = false}) {
    const { data } = await axios.get(`/restify/media/${fileId}`, {
      params: {
        related: 'target',
      }
    })

    if (addToList) {
      let newFile = getFileWithRelationshipsAndPath(data);
      commit('addFile', newFile)
    }

    return data
  },
  async uploadFiles({ commit, dispatch }, { data }) {
    try {
      const url = getAttachUrl(data)

      const promises = []

      data.files.forEach(file => {
        const formData = new FormData()
        formData.append('files[]', file.raw)
        if (data.folder_id) {
          formData.append('folder_id', data.folder_id)
        }

        const request = axios.post(url, formData, {
          onUploadProgress: (progressEvent) => {
            let progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

            if (!file.raw.uid) {
              return
            }

            commit('setUploadProgress', {
              id: file.raw.uid,
              file,
              progress
            })
          }
        })

        promises.push(request)
      })

      const uploadResponse = await Promise.all(promises)
      // * if we have custom fields, we need to update the media entity with the custom fields
      if (data.custom_fields) {
        const fileUpdatePromises = []

        for (const uploadEntry of uploadResponse) {
          let file = uploadEntry.data.files_uploaded[0]

          file.custom_fields = data.custom_fields
          fileUpdatePromises.push(dispatch('updateFile', file))
        }

        await Promise.all(fileUpdatePromises)
      } else {

        for (const uploadEntry of uploadResponse) {
          const file = uploadEntry.data.files_uploaded[0];
          if (file.model_type === 'task') {
            file.task_id = file.model_id
          }

          let newFile = entityToRestifyMapper(file, { type: 'media' });
          newFile = getFileWithRelationshipsAndPath(newFile)

          commit('addFile', newFile);
        }
      }

      return uploadResponse
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async uploadImage({ commit }, image) {
    try {
      const url = '/image/upload'
      const formData = new FormData()
      formData.append('image', image)
      const uploadResponse = await axios.post(url, formData, {
        onUploadProgress: progressEvent => {
          let progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          commit('setUploadProgress', { id: image.id, progress })
        }
      })

      return uploadResponse.data;

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async deleteFile({ commit }, { file }) {
    const url = getRemoveUrl(file)
    const formData = getRemoveModel(file)

    await axios.post(url, formData);
    commit('deleteFile', file.id)
  },
  async createFolder({ commit, dispatch }, { data }) {
    const response = await axios.post(`/restify/folders`, data)

    if (response?.data?.id) {
      const newFolder = getFolderWithRelationshipsAndPath(response.data)
      commit('addFolder', newFolder)

      dispatch('setFoldersTreeData')
    }

    return response
  },
  async editFolder({ commit, dispatch }, { folderId, data }) {
    const response = await axios.put(`/restify/folders/${folderId}`, data)

    if (response?.data?.id) {
      const updatedFolder = getFolderWithRelationshipsAndPath(response.data)
      commit('updateFolder', updatedFolder)

      dispatch('setFoldersTreeData')
    }

    return response
  },
  async deleteFolder({ commit, dispatch }, { folder, withContents = false }) {
    let response
    if (withContents) {
      response = await axios.post(`/restify/folders/${folder.id}/actions?action=delete-folder-and-contents`)
    } else {
      response = await axios.delete(`/restify/folders/${folder.id}`)
    }

    if (withContents) {
      response.data.deleted_media_ids.forEach(fileId => {
        commit('deleteFile', fileId)
      })

      response.data.deleted_folder_ids.forEach(folderId => {
        commit('deleteFolder', folderId)
      })
    }
    else {
      commit('deleteFolder', folder.id)
    }

    dispatch('setFoldersTreeData')

    return response
  },
  async getFolderById({}, folderId) {
    const { data } = await axios.get(`/restify/folders/${folderId}`)
    return data
  },
  async bulkUpdateFiles({ commit }, files) {
    const updateFiles = files.filter(file => !file.skipDbUpdate)
      .map(file => {
        const model = { ...file }
        delete model.skipDbUpdate
        delete model.updated

        return model
      })

    if (updateFiles.length) {
      await axios.post(`/restify/media/bulk/update`, updateFiles)
    }

    files.forEach(file => {
      if (!file.updated) {
        return
      }

      const updatedFile = getFileWithRelationshipsAndPath(file.updated)

      commit('updateFile', updatedFile)
    })
  },
  async bulkUpdateFolders({ commit, dispatch }, folders) {
    const updateFolders = folders.filter(folder => !folder.skipDbUpdate)
      .map(folder => {
        const model = { ...folder }
        delete model.skipDbUpdate
        delete model.updated

        return model
      })

    if (updateFolders.length) {
      await axios.post(`/restify/folders/bulk/update`, updateFolders)
    }

    folders.forEach(folder => {
      if (!folder.updated) {
        return
      }

      const updatedFolder = getFolderWithRelationshipsAndPath(folder.updated)

      commit('updateFolder', updatedFolder)
    })

    dispatch('setFoldersTreeData')
  },
  async downloadZip({ commit }, { folder_id, folderName }) {
    const archiveName = `${folderName}.zip`

    commit('setArchiveState', {
      name: archiveName,
      status: 'pending'
    })

    const response = await axios.get('/download-zip', {
      params: {
        folder_id
      },
      responseType: 'blob'
    })

    commit('setArchiveState', {
      name: archiveName,
      status: 'ready',
      blob: response
    })
  }
}

const getters = {
  activeColumns: (state) => {
    const { mainColumns, extraColumns } = getFileColumns()

    let columns = mainColumns

    columnBuilder.addCustomColumns(columns, extraColumns)

    columnBuilder.addCustomFieldColumns(columns, 'media', 'attributes.size', {
      params: {
        hideEmpty: (row) => row?.type !== 'media'
      }
    })

    return columns
  },
  tableColumns: (state, getters) => {
    return getters.activeColumns.filter(c => c.visibleInTable !== false)
  },
  groupedFiles: (state, getters, rootState, rootGetters) => (files = null, groupBy = null) => {
    files = files || state.allFiles || []
    groupBy = groupBy || rootGetters['filters/targetLocalFilters']('files')?.groupBy
    const groupByKey = groupBy?.[0]

    let data = files

    if (groupByKey === 'attributes.created_at') {
      data = data.map(file => {
        const newFileWithDate = file
        return set(newFileWithDate, groupByKey, get(file, groupByKey).substr(0, 10))
      })
    }

    let groupedFiles = new Map()

    if (groupByKey === 'attributes.mime_type') {
      for (const file of data) {
        const mime_type = get(file, groupByKey)

        for (const option of getFileTypeOptions()) {
          if (option.value.includes(mime_type)) {

            if (!groupedFiles.has(option.label)) {
              groupedFiles.set(option.label, [])
            }
            groupedFiles.get(option.label).push(file)
            break;
          }
        }
      }
    }
    else {
      groupedFiles = groupEntitiesToMap(data, groupByKey)
    }
    
    return groupedFiles
  },
  dataPropRange: (state, getters) => (prop) => {
    const files = getters.currentFiles

    const min = Math.min(...files.map(file => typeof prop === 'function'
        ? prop(file)
        : get(file, prop)
      ),
      0
    )

    const max = Math.max(...files.map(file => typeof prop === 'function'
        ? prop(file)
        : get(file, prop)
      ),
      0
    )

    return {
      min,
      max
    }
  },
  projectScopedFiles: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id

    if (!project_id) {
      return []
    }

    return state.allFiles.filter((file) => file.attributes.project_id == project_id)
  },
  currentTreeData: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id
    if (!project_id) {
      return state.filesTreeData
    }

    return state.filesTreeData
      .filter(folderOrFile => folderOrFile.attributes.project_id == project_id)
  },
  currentFiles: (state, getters, rootState, rootGetters) => {
    return getters.currentTreeData.filter(file => file.type === 'media')
  },
  foldersTreeData: (state) => (projectId = null) => {
    if (!projectId) {
      return state.foldersTree || []
    }

    return state.foldersTree?.
      filter((folder) => folder.attributes.project_id == projectId) || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
