<template>
  <BaseDialogNew
    v-model="visible"
    class="w-full max-w-xl"
    @close="$emit('close')"
  >
    <AllocatedTimeForm
      :allocatedTime="allocatedTime"
      :taskScoped="taskScoped"
      :isDialogForm="true"
      @cancel="$emit('close')"
      @save="event => $emit('save', event)"
    />
  </BaseDialogNew>
</template>
<script>
import AllocatedTimeForm from '@/modules/time/components/AllocatedTimeForm.vue'

export default {
  name: 'AllocatedTimeDialog',
  inheritAttrs: false,
  components: {
    AllocatedTimeForm,
  },
  emits: ['save', 'close'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    taskScoped: {
      type: Boolean,
      default: false,
    },
    allocatedTime: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
