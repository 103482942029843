import store from '@/store'
import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";
import actions from "@/modules/common/enum/actionsEnum";
import { get } from "lodash-es";
import Data = API.Data;
import ObjectValues = API.ObjectValues;
import User = App.Domains.Users.Models.User;
import actionsEnum from "@/modules/common/enum/actionsEnum";
import { getUserRole } from '@/modules/users/util/userUtils';

type Role = ObjectValues<typeof rolesEnum>
type Permission = ObjectValues<typeof actions>

export enum AccountPermissions {
  ActiveProjects = 'has_active_projects',
  ProjectTools = 'has_tool_per_project',
  TaskTimer = 'has_task_timer',
  GanttView = 'has_gantt_chart',
  CustomBranding = 'has_custom_branding',
  CustomDomain = 'has_custom_domain',
  CustomEmails = 'has_custom_email',
  PublicApi = 'has_public_api',
  Webhooks = 'has_webhooks',
  CsvImporter = 'has_csv_box_project_import',
  RecurringPayments = 'has_recurring_payments',
  AiAssistant = 'has_ai_assistant',
  FileProofing = 'has_document_proofing',
  Storage = 'storage',
  CreatorSeats = 'creator_seats',
  CollaboratorsSeats = 'collaborator_seats',
  SpeechToText = 'has_speech_to_text',
}

export default function useCan() {
  function getAccountPermission(action: Permission) {
    const storeState: any = store.state
    const subscription = storeState.accounts?.subscription
    const subscriptionPermissions = get(subscription, 'account_permissions', [])
    return subscriptionPermissions.find((p: any) => p.permission === action)
  }

  function hasAccountPermission(action: Permission) {
    const permission = getAccountPermission(action)

    if (!permission) {
      return false
    }

    const { expired_at, setting } = permission
    if (!expired_at) {
      return true
    }

    const isExpired = new Date(expired_at)?.getTime() < new Date()?.getTime()
    if (isExpired) {
      return false
    }

    const limit = +setting
    if (setting === undefined) {
      return true
    }
    // TODO handle limit
    return true
  }

  function hasUserPermission(action: Permission) {
    return store.getters['users/can'](action)
  }

  function can(action: Permission) {
    return hasAccountPermission(action) || hasUserPermission(action)
  }

  function canRole(role: Role, action: Permission) {
    return store.getters['users/canRole'](role, action)
  }

  function canUser(user: Data<User>, action: Permission) {
    const role = getUserRole(user)
    return canRole(role, action)
  }

  function isRoleGreaterOrEqual(role: Role) {
    return store.getters['users/isCurrentUserRoleGreaterOrEqual'](role)
  }

  return {
    can,
    canUser,
    canRole,
    isRoleGreaterOrEqual,
    getAccountPermission,
    hasAccountPermission,
    actions: actionsEnum,
    roles: rolesEnum
  }
}
