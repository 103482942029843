import { computed } from "vue";
import store from "@/store";

import User = App.Domains.Users.Models.User;

export function useAuth() {
  const currentUser = computed<User>(() => {
    // @ts-ignore
    return store.state.auth.user
  })

  const currentTenant = computed(() => {
    return currentUser.value?.tenants?.[0]
  })

  return {
    currentUser,
    currentTenant
  }
}
