<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-3xl sm:text-4xl font-extrabold">
      {{ i18n.t(`Add the tools you need`) }}
    </h2>
    <p class="text-gray-400 leading-7 text-sm">
      <i class="fa-solid fa-stars mr-1 text-[#5720b460]" aria-hidden="true" />
      {{$t( 'Heads up: We created a project description for you using AI.') }}
    </p>
    <p class="text-gray-500 leading-7 text-lg">
      {{ $t(`Tools are at the heart of every project. You can customise each project by only adding the tools you need. Choose a few from the options below to get started.`) }}
    </p>
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
    <div
      v-for="tool of displayedTools"
      :key="tool.value"
      class="flex border border-gray-200 rounded-lg cursor-pointer relative text-base font-semibold hover:bg-gray-50"
      @click="toggleToolSelected(tool.value)"
    >
      <i
        v-if="isToolSelected(tool.value)"
        class="fa-solid fa-circle-check absolute -top-4 -right-2 text-[#5720b4] text-2xl" aria-hidden="true"
      />
      <div
        class="flex-none w-14 text-center bg-emerald-500 text-white text-2xl rounded-l-lg flex items-center justify-center"
        :style="{
          background: tool.color
        }"
      >
        <i
          :class="tool.icon"
          class="text-xl"
        />
      </div>
      <div class="px-4 py-2 overflow-hidden">
        <div class="flex items-center line-clamp-2 text-gray-800">
          {{ tool.name }}
        </div>
        <div class="flex items-center">
          <div class="text-gray-500 line-clamp-2">
            <div
              v-html="tool.description"
              class="text-sm text-gray-500 font-normal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <p
    class="text-gray-400 leading-7 text-sm mt-4"
  >
    <i class="fa-solid fa-info-circle mr-1 text-[#5720b460]" aria-hidden="true" />
    {{ $t(`Note: You can add many other tool types from the project dashboard.`) }}
  </p>
  <div class="flex space-x-4 items-center mt-4">
    <router-link
      to="/onboarding/name-your-project"
      class="rounded-md bg-white px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-gray-500 border border-gray-200"
    >
      <i class="fa-solid fa-arrow-left mr-2" aria-hidden="true"></i>
      {{ $t(`Back`)  }}
    </router-link>
    <router-link
      to="/onboarding/create-tasks"
      class="rounded-md body-background px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-white"
    >
      {{ i18n.t('Next') }}
      <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true"></i>
    </router-link>
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import { coreTools, toolsEnum } from '@/modules/projects/utils/toolUtils'
import { computed, ref } from 'vue'

import { useStore } from 'vuex'
const store =  useStore()

const onboardingModel = computed(() => {
  return store.state.accounts.onboardingModel
})

const projectTools = computed({
  get() {
    return onboardingModel.value.projectTools || []
  },
  set(value: string[]) {
    store.commit('accounts/setOnboardingModel', {
      ...onboardingModel.value,
      projectTools: value,
    })
  },
})

const selectedTools = ref<string[]>(projectTools.value || [])

const displayedTools = computed(() => {
  const displayedEnum = [
    toolsEnum.DISCUSSIONS,
    toolsEnum.TASKS,
    toolsEnum.FILES,
    toolsEnum.NOTES,
    toolsEnum.PAYMENTS,
    toolsEnum.TIME,
  ]

  return coreTools.filter((tool) => displayedEnum.includes(tool.value))
})

function isToolSelected(toolValue: string) {
  return selectedTools.value.includes(toolValue)
}

function toggleToolSelected(toolValue: string) {
  if (isToolSelected(toolValue)) {
    selectedTools.value = selectedTools.value.filter((value) => value !== toolValue)
  } else {
    selectedTools.value = [...selectedTools.value, toolValue]
  }

  projectTools.value = selectedTools.value
}
</script>
