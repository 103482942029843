<template>
  <ResourcesTable
    :data="resources"
    :loading="resourcesLoading"
    class="flex-1"
  />
</template>
<script>
// Components
import ResourcesTable from "@/modules/resources/components/ResourcesTable.vue"

export default {
  components: {
    ResourcesTable,
  },
  computed: {
    resources() {
      return this.$store.state.resources.resources?.data || []
    },
    resourcesLoading() {
      return this.$store.state.resources.resourcesLoading || this.$store.state.projects.currentProjectLoading
    },
  }
}
</script>
