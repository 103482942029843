import { get, set } from 'lodash-es'
export function removeFromListById(list, id) {
  const index = list?.findIndex(p => p.id === id)
  if (index !== -1) {
    list.splice(index, 1)
  }
}

function filterKeyProperties(keyValue) {
  // When using raw array key values like relationships.allocations, we want to include only some fields in the final group by keys.
  const keyProps = ['id', 'attributes.name', 'attributes.avatar', 'attributes.email', 'attributes.image']
  
  if (Array.isArray(keyValue)) {
    keyValue = keyValue.map(item => {
      const mappedItem = {}
      keyProps.forEach(prop => {
        const propValue = get(item, prop)
        if (propValue !== undefined) {
          set(mappedItem, prop, propValue)
        }
      })
      return mappedItem
    })
    return keyValue
  }
  
  return keyValue
}

export function groupByArrayValues(list, groupByKey, isCustomFieldGrouping) {
  const groupedItems = new Map();

  list.forEach(item => {
    let keyValue
    if (isCustomFieldGrouping) {
      const custom_fields = get(item, 'attributes.custom_fields')
      if (typeof custom_fields === 'string') {
        set(item, 'attributes.custom_fields', JSON.parse(custom_fields))
      }

      keyValue = get(item, groupByKey)
    }
    else {
      const value = get(item, groupByKey) || []
      if (typeof value?.[0] === 'object') {
        keyValue = filterKeyProperties(value)
      }
      else {
        keyValue = value
      }
    }
    
    let key = JSON.stringify(keyValue)
    if (!groupedItems.has(key)) {
      groupedItems.set(key, [])
    }

    groupedItems.get(key).push(item)
  })

  return groupedItems
}
