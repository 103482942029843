<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <div class="max-w-3xl m-auto align-middle bg-white border border-gray-200 overflow-hidden rounded-lg shadow">
      <div class="w-full border-b border-gray-200 p-6">
        <slot name="title">
          <h3 class="text-2xl font-bold">{{ $t('Make Payment') }}</h3>
          <p class="text-sm text-gray-500 mt-2">
            {{
              $t(`Pay this invoice using the details below.`)
            }}
          </p>
        </slot>
      </div>
      <VeeForm v-slot="{ handleSubmit, errors, meta }">
        <form class="divide-y divide-gray-200"
              @submit.prevent="handleSubmit(confirmPayment)">
          <div class="sm:space-y-5 p-6">
            <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start mb-4">
              <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {{ $t('Subtotal') }}
              </label>
              <div class="mt-1 ml-2 sm:mt-px sm:pt-1 sm:col-span-3">
                {{ $formatPrice(payment?.attributes?.amount, { currencyCode: payment?.attributes?.currency }) }}
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start mb-4">
              <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {{ $t('Tax @ ') }} {{ $formatPercent(payment?.attributes?.tax_rate) }}
              </label>
              <div class="mt-1 ml-2 sm:mt-px sm:pt-1 sm:col-span-3">
                {{ $formatPrice(tax, { currencyCode: payment?.attributes?.currency }) }}
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start mb-4">
              <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {{ $t('Total') }}
              </label>
              <div class="mt-1 ml-2 sm:mt-px sm:pt-1 sm:col-span-3">
                {{ $formatPrice(total, { currencyCode: payment?.attributes?.currency }) }}
              </div>
            </div>

            <WarningAlert
              v-if="!isPaypalEnabled && !isStripeEnabled"
              :dismissable="false"
              class="border-yellow-700 border max-w-4xl m-auto mb-4"
            >
              {{ $t('No payment methods configured. Please configure') }}
              
              <router-link
                to="/integrations/stripe-setup"
                class="font-medium underline text-yellow-700 hover:text-yellow-600"
              >
                {{ $t('Stripe') }}
              </router-link>

              {{ $t(' or ') }}

              <router-link
                to="/integrations/paypal"
                class="font-medium underline text-yellow-700 hover:text-yellow-600"
              >
                {{ $t('Paypal') }}
              </router-link>

              {{ $t(' to accept online payments.') }}
            </WarningAlert>

            <template v-if="isPaypalEnabled && isStripeEnabled">
              <h3 class="text-lg font-bold mt-6 mb-2">{{ $t('Choose payment method') }}</h3>
              
              <div class="flex justify-center rounded-md mt-4 mx-auto">
                <div class="relative self-center bg-gray-200 rounded-lg p-0.5 flex">
                  <button
                    type="button"
                    class="relative w-1/2 border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium  whitespace-nowrap focus:outline-none sm:w-auto sm:px-8"
                    :class="{
                      'text-gray-900': paymentMethod !== PaymentMethods.Stripe,
                      'bg-primary-500 text-white' : paymentMethod === PaymentMethods.Stripe 
                    }"
                    @click="paymentMethod = PaymentMethods.Stripe"
                  >
                    <span>{{ $t('Pay by Card') }}</span>
                  </button>
                  <button
                    type="button"
                    class="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8"
                    :class="{
                      'text-gray-900': paymentMethod !== PaymentMethods.Paypal,
                      'bg-primary-500 text-white' : paymentMethod === PaymentMethods.Paypal 
                    }"
                    @click="paymentMethod = PaymentMethods.Paypal"
                  >
                    <span>{{ $t('Pay using Paypal') }}</span>
                  </button>
                </div>
              </div>
            </template>


            <CreditCard
              v-if="isStripeEnabled"
              v-show="paymentMethod === PaymentMethods.Stripe"
              ref="creditCard"
              :model="model"
              :validationCardMessage="validationCardMessage"
              @valid-changed="onValidCardChanged"
            />
            
            <div
              v-if="isPaypalEnabled"
              v-show="paymentMethod === PaymentMethods.Paypal"
              ref="paypalButtonsContainer"
            />
          </div>

          <div class="px-6 py-4 mt-6 border-t border-gray-200">
            <div class="flex justify-end">
              <BaseButton
                variant="white"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </BaseButton>
              <BaseButton
                v-if="isStripeEnabled"
                :loading="loading"
                :disabled="invalidCard"
                type="submit"
                class="ml-2"
              >
                {{ $t('Make Payment') }}
              </BaseButton>
            </div>
          </div>
        </form>
      </VeeForm>
    </div>
  </BaseDialog>
</template>
<script>
import CreditCard from "@/components/form/CreditCard.vue";
import { loadPaypalSDK } from "@/modules/accounts/utils/paypalUtils"
import { sleep } from "@/modules/common/utils/commonUtils"
import i18n from "@/i18n";

const PaymentMethods = {
  Stripe: 'Stripe',
  Paypal: 'Paypal'
}

export default {
  name: 'MakePaymentDialog',
  inheritAttrs: false,
  components: {
    CreditCard,
  },
  emits: ['paid-with-stripe', 'paid-with-paypal', 'close'],
  props: {
    title: String,
    payment: {
      type: Object,
      default: () => ({})
    },
    paymentErrors: String
  },
  data() {
    return {
      loading: false,
      invalidCard: true,
      validationCardMessage: '',
      paymentMethod: PaymentMethods.Stripe,
      PaymentMethods,
    }
  },
  computed: {
    paypalPaymentDetails() {
      return this.$store.getters['accounts/paypalPaymentDetails']
    },
    isPaypalEnabled() {
      return this.$store.getters['accounts/isPaypalEnabled']
    },
    isStripeEnabled() {
      return this.$store.getters['accounts/isStripeEnabled']
    },
    currencyCode() {
      return (this.payment?.attributes?.currency || 'usd').toUpperCase()
    },
    tax() {
      const { amount, total_payment } = this.payment?.attributes || {}
      return total_payment - amount || 0
    },
    total() {
      return this.payment?.attributes?.total_payment || 0
    }
  },
  watch: {
    isStripeEnabled: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.paymentMethod = PaymentMethods.Paypal
        }
      }
    }
  },
  methods: {
    async confirmPayment() {
      try {
        this.loading = true
        const intentResponse = await this.$store.dispatch('payments/chargePayment', this.payment)
        const paymentData = {
          clientSecret: intentResponse.intent_secret,
          fullName: this.$user.name,
        }
        let result = await this.$refs.creditCard.confirmPaymentIntent(paymentData)
        if (result.error) {
          this.invalidCard = true
          this.validationCardMessage = result?.error?.message || i18n.t('Could not process your card payment. Please try again.')
          this.$emit('payment-failed')
        } else {
          this.validationCardMessage = ''
          
          // We wait for Stripe hook to update invoice data on the BE
          await sleep(3000)

          this.$emit('paid-with-stripe')
        }
        this.$emit('update:paymentErrors', this.validationCardMessage)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not process your card payment. Please try again.'))
      } finally {
        this.loading = false
      }
    },
    onValidCardChanged(state) {
      this.$nextTick(() => {
        this.invalidCard = !state
      })
    },
    async initPaypalButtons() {
      if (!this.isPaypalEnabled) {
        return;
      }

      await loadPaypalSDK(this.paypalPaymentDetails?.attributes?.merchant_id, this.currencyCode)

      paypal.Buttons({
        style: {
          layout: 'vertical',
          color:  'gold',
          shape:  'rect',
          label:  'paypal'
        },
        createOrder: (data, actions)  => {
          // Set up the transaction
          return actions.order.create({
            intent: 'capture',
            purchase_units: [{
              reference_id: `pdc_paypal_payment_${this.payment?.id}`,
              description: this.payment?.attributes?.description || '',
              amount: {
                currency_code: this.currencyCode,
                value: this.total
              }
            }]
          });
        },
        onApprove: (data, actions) => {
          const onCapture = (details) => this.$emit('paid-with-paypal', details)

          return actions.order.capture().then((details) => {
            onCapture(details)
          });
        },
        onCancel: (data) => {
          // Show a cancel page, or return to cart
        }
      }).render(this.$refs.paypalButtonsContainer);
    }
  },
  async mounted() {
    await this.$store.dispatch('accounts/getPaymentDetails')

    this.initPaypalButtons()
  }
}
</script>
