import { encodeFilters } from "@/modules/common/utils/filterUtils";

export function newestProjectsSort() {
  return encodeFilters([
    {
      key: "sorts",
      value: {
        column: "created_at",
        order: "desc"
      }
    },
  ])
}

export function defaultProjectSelectFilters() {
  return encodeFilters([
    {
      key: "sorts",
      value: {
        column: "created_at",
        order: "desc"
      }
    },
    {
      key: 'closed',
      value: {
        show: false
      }
    },
  ])
}

export const DEFAULT_PROJECTS_FILTER = newestProjectsSort()
