import { GenericFilter } from "@/modules/ai/filters/GenericFilter";
import { FilterTypes } from "@/components/table/tableUtils";

export class FilterBuilder {
  filters: GenericFilter[];

  constructor() {
    this.filters = [];
  }

  addFilter(property: string, comparisonType: FilterTypes, value: any) {
    if (value !== undefined && value !== null) {
      this.filters.push(new GenericFilter(property, comparisonType, value));
    }
    return this;
  }

  addCustomFilter(filter: GenericFilter) {
    this.filters.push(filter);

    return this;
  }

  build() {
    return this.filters;
  }
}
