<template>
  <div>
    <BaseSelect
      ref="select"
      v-model="model"
      :options="statuses"
      :loading="statusesLoading"
      :placeholder="placeholder"
      :return-object="false"
      :persistent="false"
      :inline="$attrs.inline"
      :disabled="$attrs.disabled"
      valueKey="id"
      :filterable="filterable"
      popper-class="project-status-select"
      class="project-status-select"
      :class="{
        [$attrs.class]: $attrs.class
      }"
    >
      <template
        v-if="!hidePrefix"
        #prefix="{ selected }"
      >
        <slot name="prefix">
          <div class="flex justify-center items-center h-full">
            <div
              v-if="selected?.attributes?.color"
              :style="{backgroundColor: selected?.attributes?.color}"
              class="w-3 h-3 rounded-md mr-2"
            />
          </div>
        </slot>
      </template>
      <template #default="{option}">
        <div
          class="w-4 h-4 rounded-full mr-2 border-2 border-white"
          :style="{backgroundColor: option?.attributes?.color}"
        />
        <span class="capitalize">{{ option?.attributes?.name }}</span>
      </template>

      <template #after-options>
        <RequiresPermissionTo :action="$actions.EDIT_PROJECT_STATUSES">
          <EditStatusOption
            :entity="$t('Project')"
            @click="onEditStatuses"
          />
        </RequiresPermissionTo>
      </template>
    </BaseSelect>

    <ProjectStatusesDialog
      v-show="showStatusEditDialog"
      v-model="showStatusEditDialog"
    />
  </div>
</template>
<script>
import { capitalize } from 'lodash-es';
import i18n from "@/i18n.js";
import EditStatusOption from "@/components/common/EditStatusOption.vue";
import ProjectStatusesDialog from "@/modules/accounts/components/ProjectStatusesDialog.vue";
import { getSetting } from "@/plugins/settingsPlugin";

export default {
  components: {
    EditStatusOption,
    ProjectStatusesDialog,
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: i18n.t('Enter project status...'),
    },
    hidePrefix: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showStatusEditDialog: false,
    }
  },
  computed: {
    model: {
      get() {
        return +this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', +value)
      }
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    statuses() {
      const statuses = this.$store.getters['projects/orderedStatuses'] || []
      const canCloseProjects = this.can(this.$actions.CLOSE_PROJECTS)

      return statuses
        .map((status, i) => {
          return {
            ...status,
            label: capitalize(status?.attributes?.name),
            disabled: (i == statuses.length - 1) && (!canCloseProjects || this.isTemplatePath)
          }
        })
        .filter(status => !status.disabled)
    },
    defaultStatus() {
      return this.$store.getters['projects/defaultProjectStatus']
    },
    statusesLoading() {
      return this.$store.state.projects.statuses.statusesLoading || false
    },
  },
  methods: {
    onEditStatuses() {
      this.showStatusEditDialog = true;
      this.$refs.select?.blur();
    },
    syncStatus() {
      const value = this.model
      if (!value) {
        this.$emit('raw-change', value)
      }
      else {
        const rawValue = this.statuses.find(x => x.id?.toString() === value.toString())
        this.$emit('raw-change', rawValue)
      }
    }
  },
  watch: {
    defaultStatus: {
      immediate: true,
      handler(value) {
        if (value && value.id && !this.model) {
          this.model = value.id?.toString()
        }
      }
    },
    statuses() {
      this.syncStatus()
    },
    model: {
      immediate: true,
      handler(value) {
        this.syncStatus()
      }
    }
  }
}
</script>
<style lang="scss">
.project-status-select {

  .el-select-dropdown__list .el-select-dropdown__item.is-disabled {
    @apply border-t border-gray-200;

    &.hover {
      @apply bg-transparent;
    }
  }

  .fa-info-circle,
  .info-text {
    @apply bottom-0;
  }
}
</style>
