import i18n from "@/i18n";
import { applyCustomBackground } from "@/modules/accounts/utils/accountBrandingUtils.js"
import { trackRegister } from "@/modules/common/utils/trackingUtils";

export const registerCampaigns = [
  {
    name: 'january_2023',
    background: {
      color: '#581c87',
      image: 'https://s3.eu-west-2.amazonaws.com/project.co/assets/PDC-January-Promo-Register-Back.png',
    },
    text: i18n.t("Thank you for being part of our January 2023 special offer campaign. Complete your details below to create your account. Once you're in, we'll send you an email with details about how to upgrade and get the special offer discount.")
  }
]

export function applyCampaignBranding(campaign: any) {
  if (campaign.background?.color) {
    document.body.style.backgroundColor = campaign.background.color
  }

  if (campaign.background?.image) {
    applyCustomBackground(campaign.background.image, /* isUrl */ true)
  }
}

export enum OnboardingQuestionType {
  Select = 'select',
  SelectText = 'select-text',
  Text = 'text',
}

export type OnboardinQuestionOption = {
  value: string
  label: string
}

export type OnboardingQuestion = {
  key: string
  label: string
  placeholder: string
  infoText: string
  type: OnboardingQuestionType
  options?: OnboardinQuestionOption[]
  validationRules?: string
}

export const OnboardingQuestionKeys: {[key: string]: string} = {
  BusinessFocus: 'business_focus',
  CurrentTools: 'current_tools',
  CompanySize: 'company_size',
  WhereHeard: 'where_heard',
}

export const onboardingQuestions: OnboardingQuestion[] = [
  {
    key: OnboardingQuestionKeys.BusinessFocus,
    label: i18n.t('What is your business focus?'),
    placeholder: i18n.t('Business Focus'),
    infoText: i18n.t(`Your answer will enable us to suggest templates and provide onboarding support.`),
    type: OnboardingQuestionType.SelectText,
    options: [
      {
        value: 'Animation',
        label: i18n.t('Animation'),
      },
      {
        value: 'Creative Agency',
        label: i18n.t('Creative Agency'),
      },
      {
        value: 'Design',
        label: i18n.t('Design'),
      },
      {
        value: 'Development',
        label: i18n.t('Development'),
      },
      {
        value: 'Financial Services',
        label: i18n.t('Financial Services'),
      },
      {
        value: 'HR',
        label: i18n.t('HR'),
      },
      {
        value: 'IT',
        label: i18n.t('IT'),
      },
      {
        value: 'Marketing',
        label: i18n.t('Marketing'),
      },
      {
        value: 'Operations',
        label: i18n.t('Operations'),
      },
      {
        value: 'Personal use',
        label: i18n.t('Personal use'),
      },
      {
        value: 'Professional Services',
        label: i18n.t('Professional Services'),
      },
      {
        value: 'Sales',
        label: i18n.t('Sales'),
      },
      {
        value: 'Other',
        label: i18n.t('Other'),
      },
    ],
    validationRules: 'required',
  },
  {
    key: OnboardingQuestionKeys.CurrentTools,
    label: i18n.t('Do you use an existing project management tool?'),
    placeholder: i18n.t('Current Tools'),
    infoText: i18n.t(`Your answer will enable us to provide migration information & support.`),
    type: OnboardingQuestionType.SelectText,
    options: [
      {
        value: 'Asana',
        label: i18n.t('Asana'),
      },
      {
        value: 'Basecamp',
        label: i18n.t('Basecamp'),
      },
      {
        value: 'ClickUp',
        label: i18n.t('ClickUp'),
      },
      {
        value: 'Trello',
        label: i18n.t('Trello'),
      },
      {
        value: 'None',
        label: i18n.t('None'),
      },
      {
        value: 'Other',
        label: i18n.t('Other'),
      },
    ],
    validationRules: 'required',
  },
  {
    key: OnboardingQuestionKeys.CompanySize,
    label: i18n.t('What is your company size?'),
    placeholder: i18n.t('Company Size'),
    infoText: i18n.t(`Your answer will enable us to provide the best support for you.`),
    type: OnboardingQuestionType.Select,
    options: [
      {
        value: '1',
        label: i18n.t('Just me'),
      },
      {
        value: '2-5',
        label: i18n.t('2-5'),
      },
      {
        value: '6-10',
        label: i18n.t('6-10'),
      },
      {
        value: '11-25',
        label: i18n.t('11-25'),
      },
      {
        value: '26-50',
        label: i18n.t('26-50'),
      },
      {
        value: '51-200',
        label: i18n.t('51-200'),
      },
      {
        value: '201-500',
        label: i18n.t('201-500'),
      },
      {
        value: '500+',
        label: i18n.t('500+'),
      },
    ],
    validationRules: 'required',
  },
  {
    key: OnboardingQuestionKeys.WhereHeard,
    label: i18n.t('Where did you hear about us?'),
    placeholder: i18n.t('Where did you hear about us?'),
    infoText: i18n.t(`Your answer will help us focus our marketing in the right areas.`),
    type: OnboardingQuestionType.Text,
    validationRules: 'required',
  },
]

const orttoQuestionRemapping: { [key: string]: string } = {
  [OnboardingQuestionKeys.BusinessFocus]: 'str:cm:business-focus',
  [OnboardingQuestionKeys.CurrentTools]: 'str:cm:current-tools',
  [OnboardingQuestionKeys.CompanySize]: 'str:cm:company-size',
  [OnboardingQuestionKeys.WhereHeard]: 'str:cm:where-did-you-hear',
}

export function trackNewRegister(user: any, tenantInfo: any) {
  const account_name = tenantInfo?.name
  const onboardingAnswers = tenantInfo?.onboardings?.[0] || {}
  const onboarding = []

  for (const k of Object.values(OnboardingQuestionKeys)) {
    onboarding.push({
      key: orttoQuestionRemapping[k],
      answer: onboardingAnswers[k],
    })
  }

  const trackData = {
    ...user,
    account_name,
    onboarding,
  }
  
  console.log('trackData', trackData)
  trackRegister(trackData)
}
