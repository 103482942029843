<template>
  <EntitySelect
    v-bind="$attrs"
    url="/restify/projects"
    labelKey="attributes.name"
    valueKey="id"
    :placeholder="$attrs.placeholder|| $t('Select project...')"
    :url-params="{
      is_template: isTemplate,
      filters: defaultProjectSelectFilters,
      ...urlParams,
    }"
  >
    <template #option="{ row }">
      <div class="flex items-center py-1">
        <BaseLogo :entity="row.originalValue" size="sm" />
        <span class="ml-2 truncate">{{ row.label || row.value }}</span>
      </div>
    </template>
  </EntitySelect>
</template>
<script>
import EntitySelect from "@/components/form/EntitySelect.vue";
import { defaultProjectSelectFilters } from "@/modules/projects/utils/filters.js";

export default {
  components: {
    EntitySelect,
  },
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    },
    urlParams: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    defaultProjectSelectFilters() {
      return defaultProjectSelectFilters()
    }
  }
}
</script>
