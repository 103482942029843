<template>
  <div v-show="hasUniqueReactions" class="flex relative z-0 overflow-hidden space-x-1">
    <template v-for="(data, reaction)  in groupedReactions"
              :key="reaction">
      <ElPopover trigger="hover">
        <template #reference>
          <div
            :class="{
             'bg-primary-50 hover:bg-primary-100': isOwnReaction(reaction)
            }"
            class="inline-flex items-center space-x-1 py-0.5 px-2 rounded-md bg-gray-50 hover:bg-gray-100 border border-gray-200 hover:border-gray-300 cursor-pointer"
            @click="onReactionClick(reaction)"
          >
            <span>
              {{ decodeEmoji(reaction) }}
            </span>
            <span class="text-xs">
              {{ data.length }}
            </span>
          </div>
        </template>
        <div>
          <div class="text-3xl text-center">
            {{ decodeEmoji(reaction) }}
          </div>
          <div class="text-left flex flex-wrap reaction-users word-break">
            <span>{{ getUserNamesList(data) }}</span>
            <span class="text-gray-400 ml-1">{{ $t('reacted with ') }} {{ decodeEmoji(reaction) }}</span>
          </div>
        </div>
      </ElPopover>
    </template>
    <EmojiPickerPopover
      v-if="hasUniqueReactions && !readonly"
      @change="onEmojiChange"
    >
      <div class="inline py-0.5 px-2 text-gray-400 hover:text-gray-600 rounded-md bg-gray-50 hover:bg-gray-100 border border-gray-200 hover:border-gray-300 cursor-pointer">
        <i class="fa-regular fa-face-smile-plus"></i>
      </div>
    </EmojiPickerPopover>
  </div>
</template>
<script>
import { groupBy, uniqBy } from 'lodash-es'
import { ElPopover } from 'element-plus'

import EmojiPickerPopover from "@/components/selects/EmojiPickerPopover.vue";
import { decodeEmoji, encodeEmoji } from "@/modules/common/utils/emojiUtils.js";
import {
  discussionListenChannel,
  discussionTriggerChannel
} from "@/modules/projects/utils/discussionUtils.js";
import PusherUtils from "@/modules/common/utils/pusherUtils.js";

export default {
  components: {
    ElPopover,
    EmojiPickerPopover
  },
  props: {
    reactions: {
      type: Array,
      default: () => [],
    },
    discussionId: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localReactions: []
    }
  },
  computed: {
    uniqueReactions() {
      return uniqBy(this.localReactions, 'id')
    },
    hasUniqueReactions() {
      return this.uniqueReactions?.length > 0
    },
    groupedReactions() {
      return groupBy(this.uniqueReactions, 'attributes.reaction')
    },
  },
  methods: {
    decodeEmoji,
    isOwnReaction(reaction) {
      return this.getOwnReaction(reaction) !== undefined
    },
    getOwnReaction(reaction) {
      const currentUserId = +this.$user.id
      return this.uniqueReactions.find(r => r.attributes.reaction === reaction && r.attributes.created_by === currentUserId)
    },
    onEmojiChange(emoji) {
      const encodedEmoji = encodeEmoji(emoji.unicode)
      this.onReactionClick(encodedEmoji)
    },
    async addReaction(reaction) {
      const reactionObject = {
        id: new Date().getTime(),
        attributes: {
          created_by: +this.$user.id,
          creator_name: this.$user.name,
          reaction,
          discussion_id: this.discussionId,
        }
      }
      this.addLocalReaction(reactionObject)

      const data = await this.$store.dispatch('discussions/addCommentReaction', {
        reaction: reaction,
        discussion_id: this.discussionId,
      })

      reactionObject.id = data.id

      discussionTriggerChannel.value?.trigger(PusherUtils.REACTION_ADDED_EVENT, data)
    },
    async onReactionClick(reaction) {
      if (this.readonly) {
        return
      }

      const reactionObject = this.getOwnReaction(reaction)

      if (reactionObject) {
        this.removeLocalReaction(reactionObject.id)
        await this.$store.dispatch('discussions/removeCommentReaction', reactionObject.id)
        discussionTriggerChannel.value?.trigger(PusherUtils.REACTION_REMOVED_EVENT, reactionObject)

      } else {
        await this.addReaction(reaction)
      }
    },
    removeLocalReaction(reactionId) {
      const reactionIndex = this.localReactions.findIndex(r => r.id?.toString() === reactionId?.toString())
      if (reactionIndex === -1) {
        return
      }
      this.localReactions.splice(reactionIndex, 1)
    },
    addLocalReaction(reactionObject) {
      if (!reactionObject) {
        return
      }
      if (reactionObject?.attributes?.discussion_id !== this.discussionId) {
        return
      }
      this.localReactions.push(reactionObject)
    },
    getUserNamesList(reactions) {
      const names = reactions.map(r => r?.attributes?.creator_name)
      return names.join(', ')
    },
    initPusherEvents() {
      if (!discussionListenChannel.value) {
        return
      }
      discussionListenChannel.value?.on(PusherUtils.REACTION_ADDED_EVENT, data => {
        this.addLocalReaction(data)
      })
      discussionListenChannel.value?.on(PusherUtils.REACTION_REMOVED_EVENT, data => {
        this.removeLocalReaction(data.id)
      })
    },
  },
  watch: {
    reactions: {
      immediate: true,
      handler(value) {
        this.localReactions = value
      }
    }
  },
  mounted() {
    this.initPusherEvents()
  }
}
</script>
