<template>
  <BaseTableCell :params="params">
    <BaseRoundedCheckbox
      :model-value="value"
      @update:modelValue="onChange"
      class="hidden sm:block w-6 mx-auto"
      :tooltipChecked="columnParams?.tooltipChecked"
      :tooltipUnchecked="columnParams?.tooltipUnchecked"
      :disabled="disabled"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import { get } from 'lodash-es'

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const value = computed(() => {
  if (typeof columnParams.value?.getValue === 'function') {
    return columnParams.value.getValue(row.value)
  }


  return get(row.value, colDef.value.field)
})

const disabled = computed(() => {
  if (typeof columnParams.value?.isDisabled === 'function') {
    return columnParams.value.isDisabled(row.value)
  }

  return columnParams.value?.isDisabled
})

function onChange(value: boolean) {
  if (typeof columnParams.value?.onChange === 'function') {
    return columnParams.value?.onChange(value, row.value)
  }
}
</script>
