// Custom Headers
import AddCustomFieldHeader from '@/components/table/headers/AddCustomFieldHeader.vue';
import EditCustomFieldHeader from '@/components/table/headers/EditCustomFieldHeader.vue';
import EditStatusesHeader from '@/components/table/headers/EditStatusesHeader.vue';
import IconHeader from '@/components/table/headers/IconHeader.vue';

// Custom Cells
import TaskDescriptionCell from "@/components/table/cells/TaskDescriptionCell.vue"
import DraggableCell from "@/components/table/cells/DraggableCell.vue"
import FileNodePreviewCell from "@/components/table/cells/FileNodePreviewCell.vue"
import ProofNameCell from "@/modules/file-proofs/components/ProofNameCell.vue";

// Inline Editing
import ProjectStatusEditCell from '@/components/table/inline-edit/ProjectStatusEditCell.vue'
import DateEditCell from '@/components/table/inline-edit/DateEditCell.vue'
import CustomFieldEditCell from '@/components/table/inline-edit/CustomFieldEditCell.vue'
import AvatarEditCell from '@/components/table/inline-edit/AvatarEditCell.vue'
import TaskStatusEditCell from '@/components/table/inline-edit/TaskStatusEditCell.vue'
import UserListEditCell from '@/components/table/inline-edit/UserListEditCell.vue'
import TaskDateEditCell from '@/components/table/inline-edit/TaskDateEditCell.vue'
import RelationshipEditCell from '@/components/table/inline-edit/RelationshipEditCell.vue'

export const frameworkComponents = {
  AddCustomFieldHeader,
  EditCustomFieldHeader,
  EditStatusesHeader,
  DraggableCell,
  IconHeader,
  TaskDescriptionCell,
  ProofNameCell,
  DateEditCell,
  ProjectStatusEditCell,
  CustomFieldEditCell,
  AvatarEditCell,
  TaskStatusEditCell,
  UserListEditCell,
  TaskDateEditCell,
  RelationshipEditCell,
  FileNodePreviewCell
}

export enum EditableCellTypes {
  ProjectStatus = 'ProjectStatusEditCell',
  Date = 'DateEditCell',
  CustomField = 'CustomFieldEditCell',
  AvatarEdit = 'AvatarEditCell',
  TaskStatus = 'TaskStatusEditCell',
  UserList = 'UserListEditCell',
  TaskDate = 'TaskDateEditCell',
  Relationship = 'RelationshipEditCell',
}
