<template>
  <div v-loading="loading">
    <BaseInput
      v-model="query"
      @update:modelValue="searchDebounced && searchDebounced()"
      :placeholder="$t('Search image on Unsplash...')"
      name="Unsplash search"
    />
    <div
      v-if="!query && !photos?.length"
      class="text-gray-400 text-xs space-y-3 mt-3 text-center"
    >
      {{ $t("Find a free stock photo from Unsplash..") }}
    </div>
    <div
      v-if="photos?.length"
      class="mt-2 grid grid-cols-4 flex-wrap gap-2 w-full place-items-center"
    >
      <div
        v-for="photo of photos"
        :key="photo.id" class="flex-1">
        <img
          :src="photo.urls['thumb']"
          class="w-28 h-20 rounded-md object-cover cursor-pointer flex-1"
          @click="onPhotoClicked(photo)"
        />
        <a
          :href="getUserLink(photo)"
          :title="photo.user?.name"
          target="_blank"
          rel="noopener"
          class="w-28 block mt-0.5 text-xs text-gray-400 truncate">
          {{ $t('by') }} <span v-if="photo.user?.name" class="underline">{{ photo.user.name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash-es"
import { getUnsplashClient, searchUnsplashPhotos } from "@/modules/common/utils/unsplashUtils";

export default {
  props: {
    photoSize: {
      type: String,
      default: 'thumb' // full|raw|regular|small|thumb
    }
  },
  data() {
    return {
      query: '',
      loading: false,
      photos: [],
      page: 1,
      per_page: 12
    }
  },
  methods: {
    async search() {
      try {
        this.loading = true

        const response = await searchUnsplashPhotos({
          query: this.query,
          page: this.page,
          per_page: this.per_page
        })

        this.photos = response.data.results
        this.unsplashTotalPages = response.data.total_pages
      }
      catch (err) {
        console.log(err)
      }
      finally {
        this.loading = false
      }
    },
    getUserLink(photo) {
      const link = photo.user?.links?.html
      return `${link}?utm_source=project.co&utm_medium=referral`
    },
    onPhotoClicked(photo) {
      this.$emit('photo-clicked', photo)
      getUnsplashClient().get(photo.links?.download_location)
    }
  },
  mounted() {
    this.searchDebounced = debounce(this.search, 300)
  }
}
</script>
