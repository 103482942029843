<template>
  <div>
    <TaskDialog
      v-model="showCreateTaskDialog"
      v-show="showCreateTaskDialog"
      v-bind="entityCreateParams"
      :key="`global-task-create_${addTaskTrigger}`"
      @close="showCreateTaskDialog = false; onClose()"
      @save="onCreate($event)"
    />
    <ProjectDialog
      v-model="showCreateProjectDialog"
      v-show="showCreateProjectDialog"
      v-bind="entityCreateParams"
      key="global-project-create"
      @close="showCreateProjectDialog = false; onClose()"
      @save="showCreateProjectDialog = false; onCreate($event)"
    />
    <AddUserDialog
      v-show="showCreateUserDialog"
      v-model="showCreateUserDialog"
      v-bind="entityCreateParams"
      key="global-user-create"
      @close="showCreateUserDialog = false; onClose()"
      @save="showCreateUserDialog = false; onCreate($event)"
    />

    <ProjectPeopleDialog
      v-if="entityCreateParams?.projectId"
      v-show="showProjectPeopleDialog"
      v-model="showProjectPeopleDialog"
      v-bind="entityCreateParams"
      key="global-project-people"
      @close="showProjectPeopleDialog = false; onClose()"
      @save="showProjectPeopleDialog = false; onCreate($event)"
    />
    <AddGroupDialog
      v-show="showCreateGroupDialog"
      v-model="showCreateGroupDialog"
      v-bind="entityCreateParams"
      key="global-group-create"
      @close="showCreateGroupDialog = false; onClose()"
      @save="showCreateGroupDialog = false; onCreate($event)"
    />
    <CustomFieldDialog
      v-if="showCreateCustomFieldDialog"
      v-model="showCreateCustomFieldDialog"
      v-bind="entityCreateParams"
      :key="`global-custom-field-create-${JSON.stringify(entityCreateParams)}`"
      @close="showCreateCustomFieldDialog = false; onClose()"
      @save="showCreateCustomFieldDialog = false; onCreate($event)"
    />

    <AddReferenceDialog
      v-if="showCreateReferenceDialog"
      v-model="showCreateReferenceDialog"
      v-bind="entityCreateParams"
      key="global-reference-create"
      @close="showCreateReferenceDialog = false; onClose()"
      @save="showCreateReferenceDialog = false; onCreate($event)"
    />

    <TimeEntryDialog
      v-if="showCreateTimeRecordingEntryDialog"
      v-model="showCreateTimeRecordingEntryDialog"
      v-bind="entityCreateParams"
      @close="showCreateTimeRecordingEntryDialog = false; onClose()"
      @save="showCreateTimeRecordingEntryDialog = false; onCreate($event)"
    />

    <FolderDialog
      v-if="showCreateFolderDialog"
      v-model="showCreateFolderDialog"
      v-bind="entityCreateParams"
      @close="showCreateFolderDialog = false; onClose()"
      @save="showCreateFolderDialog = false; onCreate($event)"
    />

    <FileDialog
      v-if="showCreateFileDialog"
      v-model="showCreateFileDialog"
      v-bind="entityCreateParams"
      @close="showCreateFileDialog = false; onClose()"
      @save="showCreateFileDialog = false; onCreate($event)"
    />

    <StarredPagesDialog
      v-if="showStarredPagesDialog"
      v-model="showStarredPagesDialog"
      @close="showStarredPagesDialog = false; onClose()"
    />

    <UpgradeAccountDialog
      v-if="showUpgradeAccountDialog"
      v-model="showUpgradeAccountDialog"
      v-bind="entityCreateParams"
      @close="showUpgradeAccountDialog = false; onClose()"
    />

    <CreateProjectFromTemplateDialog
      v-if="showCreateProjectFromTemplateDialog"
      v-model="showCreateProjectFromTemplateDialog"
      v-bind="entityCreateParams"
      @close="showCreateProjectFromTemplateDialog = false; onClose()"
      @save="showCreateProjectFromTemplateDialog = false; onCreate($event)"
    />

    <CompleteProjectDialog
      v-if="showCompleteProjectDialog"
      v-model="showCompleteProjectDialog"
      v-bind="entityCreateParams"
      @close="showCompleteProjectDialog = false; onClose()"
      @save="showCompleteProjectDialog = false; onCreate($event)"
    />

    <TemplateGalleryDialog
      v-if="showTemplateGalleryDialog"
      v-model="showTemplateGalleryDialog"
      v-bind="entityCreateParams"
      @close="showTemplateGalleryDialog = false; onClose()"
      @save="showTemplateGalleryDialog = false; onCreate($event)"
    />

    <TemplateViewDialog
      v-if="showTemplateDialog"
      v-model="showTemplateDialog"
      v-bind="entityCreateParams"
      @close="showTemplateDialog = false; onClose()"
      @save="showTemplateDialog = false; onCreate($event)"
    />

    <GlobalSearchDialog
      v-if="showGlobalSearchDialog"
      v-model="showGlobalSearchDialog"
      v-bind="entityCreateParams"
      @close="showGlobalSearchDialog = false; onClose()"
    />

    <AiChatDialog
      v-show="showAIChatDialog"
      v-model="showAIChatDialog"
      @close="showAIChatDialog = false; onClose()"
    />

    <DataImportDialog
      v-if="showDataImportDialog"
      v-model="showDataImportDialog"
      v-bind="entityCreateParams"
      @close="showDataImportDialog = false; onClose()"
    />
  </div>
</template>
<script>
import ProjectDialog from "@/modules/projects/components/ProjectDialog.vue";
import AddGroupDialog from "@/modules/groups/components/AddGroupDialog.vue";
import AddUserDialog from "@/modules/users/components/AddUserDialog.vue";
import ProjectPeopleDialog from "@/modules/projects/components/ProjectPeopleDialog.vue";
import CustomFieldDialog from "@/modules/accounts/components/CustomFieldDialog.vue";
import UpgradeAccountDialog from "@/modules/accounts/components/UpgradeAccountDialog.vue";
import AddReferenceDialog from "@/modules/projects/components/AddReferenceDialog.vue";
import TimeEntryDialog from "@/modules/time/components/TimeEntryDialog.vue"
import FolderDialog from "@/modules/files/components/FolderDialog.vue";
import FileDialog from "@/modules/files/components/FileDialog.vue";
import StarredPagesDialog from "@/modules/accounts/components/StarredPagesDialog.vue";
import CreateProjectFromTemplateDialog from "@/modules/projects/components/CreateProjectFromTemplateDialog.vue";
import CompleteProjectDialog from "@/modules/projects/components/CompleteProjectDialog.vue";
import TemplateGalleryDialog from "@/modules/templateGallery/components/TemplateGalleryDialog.vue";
import TemplateViewDialog from "@/modules/templateGallery/components/TemplateViewDialog.vue";
import GlobalSearchDialog from "@/modules/accounts/components/GlobalSearchDialog.vue";
import AiChatDialog from "@/modules/ai/components/AiChatDialog.vue";
import DataImportDialog from "@/modules/accounts/components/DataImportDialog.vue";

export default {
  components: {
    ProjectDialog,
    AddGroupDialog,
    AddUserDialog,
    ProjectPeopleDialog,
    CustomFieldDialog,
    UpgradeAccountDialog,
    AddReferenceDialog,
    TimeEntryDialog,
    FolderDialog,
    FileDialog,
    StarredPagesDialog,
    CreateProjectFromTemplateDialog,
    CompleteProjectDialog,
    TemplateGalleryDialog,
    TemplateViewDialog,
    GlobalSearchDialog,
    AiChatDialog,
    DataImportDialog,
  },
  data() {
    return {
      model: null,
      showCreateTaskDialog: false,
      showCreateProjectDialog: false,
      showCreateGroupDialog: false,
      showCreateUserDialog: false,
      showProjectPeopleDialog: false,
      showCreateCustomFieldDialog: false,
      showCreateReferenceDialog: false,
      showCreateTimeRecordingEntryDialog: false,
      showCreateFolderDialog: false,
      showCreateFileDialog: false,
      showStarredPagesDialog: false,
      showCreateProjectFromTemplateDialog: false,
      showCompleteProjectDialog: false,
      showUpgradeAccountDialog: false,
      showTemplateGalleryDialog: false,
      showTemplateDialog: false,
      showGlobalSearchDialog: false,
      showAIChatDialog: false,
      showDataImportDialog: false,
    }
  },
  computed: {
    addTaskTrigger() {
      return this.$store.state.tasks.addTaskTrigger
    },
    addProjectTrigger() {
      return this.$store.state.projects.addProjectTrigger
    },
    addReferenceTrigger() {
      return this.$store.state.projects.addReferenceTrigger
    },
    addGroupTrigger() {
      return this.$store.state.groups.addGroupTrigger
    },
    addUserTrigger() {
      return this.$store.state.users.addUserTrigger
    },
    projectPeopleTrigger() {
      return this.$store.state.projects.projectPeopleTrigger
    },
    addCustomFieldTrigger() {
      return this.$store.state.accounts.addCustomFieldTrigger
    },
    entityCreatedCallback() {
      return this.$store.state.entityCreatedCallback
    },
    entityDialogClosedCallback() {
      return this.$store.state.entityDialogClosedCallback
    },
    entityCreateParams() {
      return this.$store.state.entityCreateParams
    },
    addTimeEntryTrigger() {
      return this.$store.state.time.addTimeEntryTrigger
    },
    addTimeEntryRecordingTrigger() {
      return this.$store.state.time.addTimeEntryRecordingTrigger
    },
    addFolderTrigger() {
      return this.$store.state.files.addFolderTrigger
    },
    addFileTrigger() {
      return this.$store.state.files.addFileTrigger
    },
    openStarredPagesTrigger() {
      return this.$store.state.accounts.openStarredPagesTrigger
    },
    createProjectFromTemplateTrigger() {
      return this.$store.state.projects.createProjectFromTemplateTrigger
    },
    completeProjectTrigger() {
      return this.$store.state.projects.completeProjectTrigger
    },
    upgradeAcccountTrigger() {
      return this.$store.state.accounts.upgradeAcccountTrigger
    },
    templateGalleryTrigger() {
      return this.$store.state.templateGallery.dialogTrigger
    },
    templateDialogTrigger() {
      return this.$store.state.templateGallery.templateDialogTrigger
    },
    globalSearchTrigger() {
      return this.$store.state.accounts.globalSearchTrigger
    },
    aiChatDialogTrigger() {
      return this.$store.state.ai.aiChatDialogTrigger
    },
    closeDialogsTrigger() {
      return this.$store.state.closeDialogsTrigger
    },
    dataImportTrigger() {
      return this.$store.state.accounts.dataImportTrigger
    },
  },
  methods: {
    async onClose() {
      if (!this.entityDialogClosedCallback) {
        return
      }

      await this.entityDialogClosedCallback(this.model)
      this.model = null
      this.resetGlobalModel()
    },
    async onCreate(model) {
      if (!this.entityCreatedCallback) {
        return
      }

      this.model = model

      await this.entityCreatedCallback(model)
      this.resetGlobalModel()
    },
    resetGlobalModel() {
      this.$store.commit('setEntityDialogClosedCallback', null)
      this.$store.commit('setEntityCreatedCallback', null)
      this.$store.commit('setEntityCreateParams', null)
    },
    closeDialogs() {
      this.showCreateTaskDialog = false
      this.showCreateProjectDialog = false
      this.showCreateGroupDialog = false
      this.showCreateUserDialog = false
      this.showProjectPeopleDialog = false
      this.showCreateCustomFieldDialog = false
      this.showCreateReferenceDialog = false
      this.showCreateTimeRecordingEntryDialog = false
      this.showCreateFolderDialog = false
      this.showStarredPagesDialog = false
      this.showCreateFileDialog = false
      this.showCreateProjectFromTemplateDialog = false
      this.showCompleteProjectDialog = false
      this.showUpgradeAccountDialog = false
      this.showTemplateGalleryDialog = false
      this.showTemplateDialog = false
      this.showGlobalSearchDialog = false
      this.showAIChatDialog = false
      this.showDataImportDialog = false

      this.onClose()
    }
  },
  watch: {
    addTaskTrigger() {
      this.showCreateTaskDialog = true
    },
    addProjectTrigger() {
      this.showCreateProjectDialog = true
    },
    addReferenceTrigger() {
      this.showCreateReferenceDialog = true
    },
    addGroupTrigger() {
      this.showCreateGroupDialog = true
    },
    addUserTrigger() {
      this.showCreateUserDialog = true
    },
    projectPeopleTrigger() {
      if (!this.entityCreateParams?.projectId) {
        throw ({
          message: 'Project id is required for adding people',
          context: {
            entityCreateParams: this.entityCreateParams,
            attrs: this.$attrs,
          }
        })
      }

      this.showProjectPeopleDialog = true
    },
    addCustomFieldTrigger() {
      this.showCreateCustomFieldDialog = true
    },
    addTimeEntryTrigger() {
      this.showCreateTimeRecordingEntryDialog = true
    },
    addTimeEntryRecordingTrigger() {
      this.showCreateTimeRecordingEntryDialog = true
    },
    addFolderTrigger() {
      this.showCreateFolderDialog = true
    },
    addFileTrigger() {
      this.showCreateFileDialog = true
    },
    openStarredPagesTrigger() {
      this.showStarredPagesDialog = true
    },
    createProjectFromTemplateTrigger() {
      this.showCreateProjectFromTemplateDialog = true
    },
    completeProjectTrigger() {
      this.showCompleteProjectDialog = true
    },
    upgradeAcccountTrigger() {
      this.showUpgradeAccountDialog = true
    },
    templateGalleryTrigger() {
      this.showTemplateGalleryDialog = true
    },
    templateDialogTrigger() {
      this.showTemplateDialog = true
    },
    globalSearchTrigger() {
      this.showGlobalSearchDialog = true
    },
    aiChatDialogTrigger() {
      this.showAIChatDialog = true
    },
    dataImportTrigger() {
      this.showDataImportDialog = true
    },
    '$route.path'() {
      this.closeDialogs()
    },
    closeDialogsTrigger() {
      this.closeDialogs()
    }
  }
}
</script>
