import i18n from "@/i18n"
import store from "@/store/index.js";
import { ColumnTypes } from '@/components/table/cells/tableCellComponents'
import {
  DELETE_TIME_ENTRIES,
  EDIT_ALLOCATED_TIME_ON_PROJECTS
} from "@/modules/common/enum/actionsEnum";
import { ComparatorTypes, comparatorTypesMap, getDefaultOptionsColumn, doesFilterPassTypesMap, FilterTypes, TableColumn } from "@/components/table/tableUtils";
import { GetQuickFilterTextParams, ValueFormatterParams, ValueParserParams } from "@ag-grid-community/core";
import parseISO from "date-fns/parseISO";
import { minutesToFormattedTime } from "@/modules/common/utils/timeUtils";

export function getAllocatedTimeColumns(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      name: i18n.t('Group'),
      prop: 'relationships.group.attributes.name',
      component: ColumnTypes.Link,
      flex: 1,
      minWidth: 180,
      params: {
        entityPath: 'relationships.group',
      },
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data?.relationships?.group?.attributes.name
      },
      showCardLabel: true,
      sortable: true,
      sortProp: 'group',
      enableRowGroup: true,
      chartDataType: 'category',
      filterBy: {
        prop: 'group_ids',
        component: 'GroupSelect',
        props: {
          multiple: true,
          urlParams: {
            hasTimeAllocations: true
          }
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.group_id')
      },
    },
    {
      name: i18n.t('Person'),
      prop: 'relationships.user.attributes.name',
      component: ColumnTypes.Link,
      flex: 1,
      minWidth: 180,
      params: {
        entityPath: 'relationships.user'
      },
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data?.relationships?.user?.attributes.name
      },
      showCardLabel: true,
      sortable: true,
      sortProp: 'user',
      chartDataType: 'category',
      filterBy: {
        prop: 'user_ids',
        component: 'UserSelect',
        props: {
          multiple: true,
          urlParams: {
            hasTimeAllocations: true
          }
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.user_id')
      },
      required: true,
      enableRowGroup: true,
    },
    {
      name: i18n.t('Project'),
      prop: 'relationships.project.attributes.name',
      component: ColumnTypes.Project,
      flex: 1,
      minWidth: 180,
      params: {
        entity_type: i18n.t('time allocation')
      },
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.RelationshipName]('relationships.project.attributes.name'),
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data?.relationships?.project?.attributes.name
      },
      showCardLabel: true,
      sortProp: 'project',
      chartDataType: 'category',
      filterBy: {
        prop: 'project_ids',
        component: 'ProjectSelect',
        props: {
          multiple: true
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.project_id')
      },
      enableRowGroup: true,
      disabled: () => {
        return !!store.getters.project_id
      }
    },
    {
      name: i18n.t('Task'),
      prop: 'relationships.task.attributes.name',
      component: ColumnTypes.Task,
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.data?.relationships?.task?.attributes.name
      },
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.RelationshipName]('relationships.task.attributes.name'),
      flex: 1,
      minWidth: 180,
      showCardLabel: true,
      sortProp: 'task',
      chartDataType: 'category',
      filterBy: {
        prop: 'task_ids',
        component: 'TaskSelect',
        props: {
          multiple: true
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.task_id')
      },
      enableRowGroup: true,
    },
    {
      name: i18n.t('Allocated'),
      prop: 'attributes.allocated_minutes',
      showCardLabel: true,
      sortable: true,
      sortProp: 'allocated_minutes',
      comparator: comparatorTypesMap[ComparatorTypes.Numeric]('attributes.allocated_minutes'),
      valueFormatter: (params: ValueFormatterParams) => {
        return minutesToFormattedTime(params.value)?.toString()
      },
      aggFunc: 'sum',
      valueParser: (params: ValueParserParams) => {
        const value = params?.newValue
        if (!value) {
          return
        }
        return parseISO(value).getTime()
      },
      chartOrder: 2,
      chartDataType: 'series',
      enableRowGroup: true,
      required: true,
      // TODO: maybe add
      // filterBy: {
      //   prop: 'allocated_minutes',
      //   type: 'range-slider',
      //   displayFormat: 'formatTimeSpentRange',
      //   props: {
      //     formatTooltip(minutes) {
      //       return formatTimeSpent(minutes);
      //     }
      //   }
      // },
    },
    {
      name: i18n.t('Actual'),
      prop: 'attributes.actual_time',
      showCardLabel: true,
      sortable: true,
      sortProp: 'actual_time',
      comparator: comparatorTypesMap[ComparatorTypes.Numeric]('attributes.actual_time'),
      valueFormatter: (params: ValueFormatterParams) => {
        return minutesToFormattedTime(params.value)?.toString()
      },
      aggFunc: 'sum',
      valueParser: (params: ValueParserParams) => {
        const value = params?.newValue
        if (!value) {
          return
        }
        return parseISO(value).getTime()
      },
      chartOrder: 1,
      chartDataType: 'series',
      enableRowGroup: true,
      required: true,
      // TODO: maybe add
      // filterBy: {
      //   prop: 'actual_time',
      //   type: 'range-slider',
      //   displayFormat: 'formatTimeSpentRange',
      //   props: {
      //     formatTooltip(minutes) {
      //       return formatTimeSpent(minutes);
      //     }
      //   }
      // },
    },
    {
      name: i18n.t('Created Date'),
      prop: 'attributes.created_at',
      showCardLabel: true,
      sortProp: 'created_at',
      sortable: true,
      comparator: comparatorTypesMap[ComparatorTypes.Date]('attributes.created_at'),
      component: ColumnTypes.Date,
      filterBy: {
        prop: 'created_at',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy',
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.DateRange]('attributes.created_at')
      },
      hide: true,
      keepHidden: true
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn(),
    disabled() {
      if (!store.getters['users/can'](EDIT_ALLOCATED_TIME_ON_PROJECTS) && !store.getters['users/can'](DELETE_TIME_ENTRIES)) {
        return true
      }

      return false
    }
  }

  const extraColumns: TableColumn[] = [optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}

export const allocatedTimeFields = [
  'id',
  'group_id',
  'project_id',
  'task_id',
  'user_id',
  'actual_time',
  'allocated_minutes',
  'created_at'
]
