<template>
  <BaseNoDataSection
    :title="$t('No Notifications Found')"
    iconClass="fa-bell"
    :canCreate="true"
  >
    <template #can-create-section>
      <div>{{ $t('Whenever notifications are created for projects or tasks you are part of you\'ll be able to see them here.') }}</div>
    </template>
  </BaseNoDataSection>
</template>
