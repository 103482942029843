import { onMounted, ref } from 'vue'
import PusherUtils from "@/modules/common/utils/pusherUtils"

export default function useProofChannel(requestId: string) {

  let channel= ref(null)
  const channelName = `proof-request-id-${requestId}`

  async function joinChannel() {
    channel.value = PusherUtils.echo().private(channelName)

    channel.value?.on(PusherUtils.NOTIFICATION_CREATED_EVENT, (data) => {
      if (data?.proof_download_url) {
        window.open(data?.proof_download_url, '_blank')
      }
      PusherUtils.echo().leave(channelName)
    })

  }

  async function leaveChannel() {
    PusherUtils.echo().leave(channelName)
  }

  async function tryJoinChannel() {
    try {
      await leaveChannel()
      await joinChannel()
    }
    catch (err) {
      console.warn('tryJoinChannel error', err)
    }
  }

  tryJoinChannel()

  return {
    channel,
    tryJoinChannel,
  }
}
