<template>
  <BaseEntityForm
    width-class="max-w-3xl"
    :stickyActions="false"
    :isDialogForm="isDialogForm"
  >
    <template #title>
      <BaseFormHeader
        :title="formTitle"
        :isDialogForm="isDialogForm"
      >
        <template #subtitle>
          <p
            class="text-sm mt-2"
            :class="{
              'text-gray-500': !$useNewLayout
            }"
          >
            {{ formSubtitle }}
            <BaseTutorialLink
              :name="tutorialName"
            />.
          </p>
        </template>
      </BaseFormHeader>
    </template>
    <template #default="{ meta, errors }">
      <UpgradePlanWarning
        v-if="!can($actions.CSV_BOX_IMPORT)"
        :message="$t(' to import data from other sources.')"
      />
      <div class="space-y-6 sm:space-y-5">
        <BaseInput
          v-if="!importType"
          :modelValue="model.entity_type"
          :name="$t('Data Type')"
          :label="$t('Data Type')"
          layout="horizontal"
          rules="required"
          id="entity_type"
          class="mt-1 sm:mt-0 md:col-span-2"
        >
          <div class="sm:mt-0 sm:col-span-4">
            <BaseSelect
              v-model="model.entity_type"
              :options="importTypeOptions"
              :return-object="false"
              :placeholder="$t('Choose data type...')"
              :disabled="!can($actions.CSV_BOX_IMPORT)"
            />
            <WarningAlert
              v-if="model.entity_type === importEntityTypes.People"
              class="border border-yellow-500 col-span-full mt-2"
              :dismissable="false"
            >
              <span>
                {{
                  $tc('max users to import warning', {
                    maxCount: maxCreatorsToImport,
                    maxCollaboratorsCount: maxCollaboratorsToImport
                  })
                }}
              </span>

              <router-link to="/account-settings/billing">
                <span class="font-medium underline text-yellow-700 hover:text-yellow-600 ml-1">
                  {{ isFreePlan ? $t('Upgrade your plan') : $t('Add more user seats to your plan') }}
                </span>
              </router-link>
              {{ $t(' to invite more people with a Creator role.') }}

              {{ $t('All new people will receive an invitation email.') }}
            </WarningAlert>
          </div>
        </BaseInput>

        <BaseInput
          v-if="project"
          :label="$t('Project')"
          :placeholder="$t('Project')"
          :name="$t('Project')"
          class="col-span-6"
          layout="horizontal"
          :info-text="$t(`Imported data will be added to this project.`)"
        >
          <ProjectSelect
            v-model="model.project_id"
            :initialValue="project"
            disabled
          />  
        </BaseInput>

        <BaseInput
          :label="$t('Import Template')"
          :placeholder="$t('Import Template')"
          :name="$t('Import Template')"
          class="col-span-6"
          layout="horizontal"
          :info-text="$tc('csv template for', { entity: selectedImportName })"
        >
          <a
            :href="`/import-templates/${model.entity_type}-sample.csv`"
            :download="`${model.entity_type}-sample.csv`"
            class="text-sm text-primary-500 underline font-medium"
          >
            <i class="fa-solid fa-file-csv text-gray-400 text-base mr-1" />
            {{ $tc('download csv template for', { entity: selectedImportName }) }}
          </a>
        </BaseInput>

        <BaseInput
          :label="$t('Overwrite Data')"
          :placeholder="$t('Overwrite Data')"
          :name="$t('Overwrite Data')"
          class="col-span-6"
          layout="horizontal"
          :info-text="$t('Overwrite duplicate matching entries.')"
        >
          <BaseSwitch
            v-model="model.overwrite_data"
            :disabled="!can($actions.CSV_BOX_IMPORT)"
          />
        </BaseInput>
        <BaseInput
          v-if="[importEntityTypes.Tasks].includes(model.entity_type) && !model.project_id"
          :label="$t('Auto Create Projects')"
          :placeholder="$t('Auto Create Projects')"
          :name="$t('Auto Create Projects')"
          class="col-span-6"
          layout="horizontal"
          :info-text="$t(`Automatically create projects for rows where no matching project is found.`)"
        >
          <BaseSwitch
            v-model="model.auto_create_projects"
            :disabled="!can($actions.CSV_BOX_IMPORT)"
          />
        </BaseInput>
        <BaseInput
          v-if="[
            importEntityTypes.Tasks,
            importEntityTypes.Projects,
            importEntityTypes.People,
          ].includes(model.entity_type)"
          :label="$t('Auto Create Groups')"
          :placeholder="$t('Auto Create Groups')"
          :name="$t('Auto Create Groups')"
          class="col-span-6"
          layout="horizontal"
          :info-text="$t(`Automatically create groups for rows where no matching group is found.`)"
        >
          <BaseSwitch
            v-model="model.auto_create_groups"
            :disabled="!can($actions.CSV_BOX_IMPORT)"
          />
        </BaseInput>
        <BaseInput
          v-if="[
            importEntityTypes.Tasks,
            importEntityTypes.Projects,
          ].includes(model.entity_type)"
          :label="$t('Auto Invite People')"
          :placeholder="$t('Auto Invite People')"
          :name="$t('Auto Invite People')"
          class="col-span-6"
          layout="horizontal"
          :info-text="$t(`Automatically invite people for rows where no matching person is found.`)"
        >
          <BaseSwitch
            v-model="model.auto_invite_people"
            :disabled="!can($actions.CSV_BOX_IMPORT)"
          />
        </BaseInput>
      </div>
    </template>
    <template #actions="{ meta, errors}">
      <DataImporter
        ref="dataImporter"
        class="hidden"
        :entityType="model.entity_type"
        :importOptions="model"
      />
      <BaseButton
        :disabled="!can($actions.CSV_BOX_IMPORT)"
        @click="() => $refs.dataImporter?.$el?.querySelector('button')?.click()"
      >
        {{ $t('Begin Import') }}
      </BaseButton>
    </template>
  </BaseEntityForm>
</template>
<script lang="ts" setup>
import i18n from '@/i18n';
import { ref, computed, PropType } from 'vue'
import {
  importEntityTypes,
  importTypeOptions,
} from '@/modules/accounts/utils/importUtils';

import DataImporter from "@/components/common/DataImporter.vue";
import UpgradePlanWarning from "@/modules/accounts/components/UpgradePlanWarning.vue";
import ProjectSelect from '@/components/selects/ProjectSelect.vue'

import { useRoute } from 'vue-router'
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";

import Data = API.Data;
import Project = App.Domains.Projects.Models.Project;


const route = useRoute()
const {
  accountLimits,
  isFreePlan
} = useAccountLimits()

const props = defineProps({
  importType: {
    type: String as PropType<importEntityTypes>,
    default: ''
  },
  project: {
    type: [Object, null] as PropType<Data<Project> | null>,
    default: null
  },
  isDialogForm: {
    type: Boolean,
    default: false
  }
})

const model = ref({
  entity_type: (props.importType || route.query?.entityType || importEntityTypes.Groups) as importEntityTypes,
  overwrite_data: true,
  auto_create_projects: true,
  auto_create_groups: true,
  auto_invite_people: true,
  project_id: props.project?.id
})

const maxCreatorsToImport = computed(() => {
  return accountLimits.value.seats.available - accountLimits.value.seats.used
})

const maxCollaboratorsToImport = computed(() => {
  if (!accountLimits.value.collaboratorSeats.available) {
    return i18n.t('unlimited')
  }

  return accountLimits.value.collaboratorSeats.available - accountLimits.value.collaboratorSeats.used
})

const selectedOption = computed(() => {
  return importTypeOptions.find(option => option.value === model.value.entity_type)
})

const selectedImportName = computed(() => {
  return selectedOption.value?.label || model.value.entity_type
})

const formTitle = computed(() => {
  if (!props.importType) {
    return i18n.t('Import Data')
  }

  switch(props.importType) {
    case importEntityTypes.Tasks:
      return i18n.tc('import data type title', {
        dataType: i18n.t('Tasks')
      })
    case importEntityTypes.Projects:
      return i18n.tc('import data type title', {
        dataType: i18n.t('Projects')
      })
    case importEntityTypes.People:
      return i18n.tc('import data type title', {
        dataType: i18n.t('People')
      })
    case importEntityTypes.Groups:
      return i18n.tc('import data type title', {
        dataType: i18n.t('Groups')
      })
    default:
      return i18n.t('Import Data')
  }
})

const formSubtitle = computed(() =>  {
  if (!props.importType) {
    return i18n.t('Import your data.')
  }

  switch(props.importType) {
    case importEntityTypes.Tasks:
      return i18n.tc('import your data type', {
        dataType: i18n.t('tasks')
      })
    case importEntityTypes.Projects:
      return i18n.tc('import your data type', {
        dataType: i18n.t('projects')
      })
    case importEntityTypes.People:
      return i18n.tc('import your data type', {
        dataType: i18n.t('people')
      })
    case importEntityTypes.Groups:
      return i18n.tc('import your data type', {
        dataType: i18n.t('groups')
      })
    default:
      return i18n.t('Import your data.')
  }
})


const tutorialName = computed(() => {
  switch(model.value.entity_type) {
    case importEntityTypes.Tasks:
      return 'import-tasks'
    default:
      return 'import'
  }
})
</script>
