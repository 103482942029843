import { computed } from "vue";
import store from "@/store";
import { isProduction } from '@/modules/common/config'

export enum BetaFeatures {
  RecurringPayments = 'stripeV2',
  FileProofing = 'ziflow',
  SimulateRoles = 'simulateRoles',
  GoogleCalendar = 'googleCalendar',
  OutlookCalendar = 'outlookCalendar',
}

const wyzowlTenantId = 3
const demoTenantId = 6

export function useAccountFeatures() {

  const tenantFeatures = computed(() => {
    // @ts-ignore
    return store.state.accounts.tenantFeatures || {}
  })

  const currentTenant = computed(() => {
    return store.getters['auth/currentTenant']
  })

  const currentTenantId = computed(() => {
    return +currentTenant.value?.id
  })

  function hasFeature(feature: BetaFeatures) {
    return tenantFeatures.value[feature] === true
  }

  const isWyzowlOrDemoAccount = computed(() => {
    return [wyzowlTenantId, demoTenantId].includes(currentTenantId.value)
  })

  const isProductionEnv = computed(() => {
    return isProduction()
  })

  return {
    hasFeature,
    isProductionEnv,
    isWyzowlOrDemoAccount
  }
}
