// Libs
import { computed, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";

// Helpers
import {
  AiFunctions,
  AiFunctionDefinition,
  ChatMessage,
  PromptResponse,
  ToolCall,
  ToolResponse,
  ChatMessageTypes
} from "@/modules/ai/types/aiTypes";

// Functions
import {
  getOrganizationConfig
} from "@/modules/ai/functions/config";

import {
  addOrUpdateTask,
  addBulkTasks,
  getTasks,
  editBulkTasks,
} from "@/modules/ai/functions/tasks";

import {
  addOrUpdateProject,
  createProjectFromTemplate,
  getProjects,
  getProjectUsers,
  getProjectGroups,
} from "@/modules/ai/functions/projects";

import {
  getUsers
} from "@/modules/ai/functions/users";

import {
  getGroups,
  addOrUpdateGroup
} from "@/modules/ai/functions/groups";

import {
  createEntityView
} from "@/modules/ai/functions/common";

import {
  getCustomFields
} from "@/modules/ai/functions/customFields";

export const functions: Record<AiFunctions, AiFunctionDefinition> = {
  [AiFunctions.GetTasks]: getTasks,
  [AiFunctions.GetProjects]: getProjects,
  [AiFunctions.GetProjectUsers]: getProjectUsers,
  [AiFunctions.GetProjectGroups]: getProjectGroups,
  [AiFunctions.GetUsers]: getUsers,
  [AiFunctions.GetGroups]: getGroups,
  [AiFunctions.GetCustomFields]: getCustomFields,
  [AiFunctions.GetOrganizationConfig]: getOrganizationConfig,

  [AiFunctions.AddOrUpdateProject]: addOrUpdateProject,
  [AiFunctions.AddOrUpdateTask]: addOrUpdateTask,
  [AiFunctions.AddBulkTasks]: addBulkTasks,
  [AiFunctions.EditBulkTasks]: editBulkTasks,
  [AiFunctions.AddOrUpdateGroup]: addOrUpdateGroup,
  [AiFunctions.CreateProjectFromTemplate]: createProjectFromTemplate,
  [AiFunctions.CreateEntityView]: createEntityView,
}

export function useChatbox() {

  const threadId = computed({
    get() {
      return store.state.ai.threadId
    },
    set(value: string) {
      store.commit('ai/setThreadId', value)
    }
  })

  const awaitingResponse = computed({
    get() {
      return store.state.ai.awaitingResponse
    },
    set(value: boolean) {
      store.commit('ai/setAwaitingResponse', value)
    }
  })

  const requiresUserAction = computed({
    get() {
      return store.state.ai.requiresUserAction
    },
    set(value: boolean) {
      store.commit('ai/setRequiresUserAction', value)
    }
  })

  const isExecutingAction = computed({
    get() {
      return store.state.ai.isExecutingAction
    },
    set(value) {
      store.commit('ai/setIsExecutingAction', value)
    }
  })

  const store = useStore();

  function addChatMessage(message: ChatMessage) {
    store.commit('ai/addChatMessage', message);
  }

  async function sendRequest(promptMarkdown: string, promptHtml: string) {
    try {
      promptMarkdown = promptMarkdown.trim()

      if (!promptMarkdown) {
        return
      }

      addChatMessage({
        type: ChatMessageTypes.User,
        message: promptHtml,
        date: new Date(),
      })

      const data = await apiCall<PromptResponse>({
        thread_id: threadId.value,
        messages: {
          role: "user",
          content: promptMarkdown
        },
      }, 'gpt-prompt2');

      console.log('PROMPT RESPONSE', data)
      await parseMessages(data);
    } catch (err) {
      console.log(err)
    }
  }

  async function parseMessages(response: PromptResponse) {
    const messages = response.messages.data;
    const lastMessage = messages[0];
    const lastMessageText = lastMessage.content[0].text.value;
    const isLinkGenerated = lastMessageText?.startsWith('Link generated');
    const isEntityUpdated = lastMessageText?.startsWith('Entity updated');

    console.log('last message', lastMessageText)

    if (lastMessage?.role !== 'user' && !isLinkGenerated && !isEntityUpdated) {
      addChatMessage({
        type: ChatMessageTypes.Assistant,
        message: lastMessageText,
        date: new Date(),
      })
    }

    if (isLinkGenerated) {
      addChatMessage({
        type: ChatMessageTypes.Assistant,
        link: {
          entity: store.state.ai.entityView.entity,
          url: store.state.ai.entityView.link
        },
        message: `Action complete! Here is the link to your `,
        date: new Date(),
      })
    }

    if (isEntityUpdated) {
      addChatMessage({
        type: ChatMessageTypes.Assistant,
        data: {
          actionResult: store.state.ai.actionResult
        },
        date: new Date(),
      })
    }

    if (response.thread.status === 'requires_action') {
      await parseFunctionCall(response);
    }
  }

  async function parseFunctionCall(data: PromptResponse) {
    let toolResponses: ToolResponse = {
      thread_id: data.thread.thread_id,
      run_id: data.thread.id,
      parameters: {
        tool_outputs: []
      }
    }

    const toolCalls = data.thread.required_action.submit_tool_outputs.tool_calls;

    console.log('require tool calls', toolCalls.map((toolCall) => ({
      name: toolCall.function.name,
      arguments: JSON.parse(toolCall.function.arguments)
    })))

    let shouldSubmitToolResponse = false
    for (const toolCall of toolCalls) {
      const functionName = toolCall.function.name as AiFunctions;
      // If requires user confirmation, add as chat message with actions
      if (functions[functionName].requiresUserConfirmation) {
        addChatMessage({
          type: ChatMessageTypes.UserConfirmation,
          data: {
            tool_call_id: toolCall.id,
            thread_id: data.thread.thread_id,
            run_id: data.thread.id,
            functionName,
            arguments: toolCall.function.arguments
          },
          date: new Date()
        })
      }
      // Else run function call in the background and submit tool response
      else {
        shouldSubmitToolResponse = true
        const toolOutput = await getFunctionCallResult(toolCall);
        toolResponses.parameters.tool_outputs.push(
          toolOutput
        );
      }
    }

    if (!shouldSubmitToolResponse) {
      return;
    }

    await submitToolResponse(toolResponses);
  }

  async function getFunctionCallResult(toolCall: ToolCall) {
    const fnName = toolCall.function.name as AiFunctions;
    const fn = functions[fnName] as Function

    const args = JSON.parse(toolCall.function.arguments);
    const result = String(await fn(args));

    return {
      tool_call_id: toolCall.id,
      output: result,
    };
  }

  async function submitToolResponse(toolResponses: ToolResponse) {
    const response = await apiCall<PromptResponse>(toolResponses, 'gpt-submit-tool-response');
    console.log('Submit tool response', {
      requested: toolResponses,
      response
    })

    parseMessages(response);
  }

  async function createThread() {
    const response = await apiCall<{thread_id: string}>({}, 'gpt-init');
    threadId.value = response.thread_id;
  }

  async function apiCall<T>(data: Record<any, any>, endpoint: string): Promise<T> {
    if (endpoint !== 'gpt-init') {
      awaitingResponse.value = true;
    }
    
    try {
      const result = await axios.post(`/${endpoint}`, data) as Promise<T>;
      return result;
    }
    finally {
      awaitingResponse.value = false;
    }
  }

  return {
    addChatMessage,
    sendRequest,
    submitToolResponse,
    createThread,
    awaitingResponse,
    requiresUserAction,
    isExecutingAction,
    threadId,
  }
}
