<template>
  <SettingsForm
    ref="settingsForm"
    :fields="[
      'custom_domain'
    ]"
    :title="$t('Custom Domain')"
    :description="$t(`Access your account from your own domain.`)"
    tutorial="custom-domain"
    :disabled="!can($actions.CUSTOM_DOMAIN)"
  >
    <template #default="{ model }">
      <UpgradePlanWarning
        v-if="!can($actions.CUSTOM_DOMAIN)"
        :message="$t(' to access your account from a different domain.')"
      />

      <BaseInput
        v-model="model.custom_domain"
        v-focus
        :label="$t('Custom Domain')"
        :placeholder="$t('e.g. subdomain.example.com or example.com')"
        :name="$t('Custom Domain')"
        :disabled="!subscription?.canUseCustomBranding || hasValue"
        class="col-span-6"
        layout="horizontal"
        rules="hostname"
        id="custom_domain"
      />
      <div
        v-if="hasValue"
        class="col-span-full border-t pt-6"
      >
        <p class="text-gray-500 text-xs text-center">
          {{ $t('Head over to your DNS provider and add the following DNS records to start using your custom domain. Please keep in mind that while it usually takes a few hours, DNS propagation can still take up to 72 hours to complete.')}}
        </p>

        <div class="w-full mt-6 border-b border-gray-200">
          <BaseTable
            :columns="tableColumns"
            :data="tableData"
          >

            <template #name="{ row }">
              <div
                class="break-all cursor-pointer"
                @click="$copyToClipboard(row.name)"
              >
                <label class="sm:hidden">{{ $t('Name (click to copy)') }}</label>
                <BaseTooltip
                  :content="$t('Click to Copy')"
                >
                  <span class="block font-bold text-gray-700">{{ row.name }}</span>
                </BaseTooltip>
              </div>
            </template>

            <template #type="{ row }">
              <div class="mt-2 sm:mt-0">
                <label class="sm:hidden">{{ $t('Type ') }}</label>
                <span class="block font-bold text-gray-700">{{ row.type }}</span>
              </div>
            </template>

            <template #value="{ row }">
              <div
                class="break-all cursor-pointer mt-2 sm:mt-0"
                @click="$copyToClipboard(row.value)"
              >

                <label class="sm:hidden">{{ $t('Alias of (click to copy)') }}</label>
                <BaseTooltip
                  :content="$t('Click to Copy')"
                >
                  <span class="block font-bold text-gray-700">{{ row.value }}</span>
                </BaseTooltip>
              </div>
            </template>
          </BaseTable>
        </div>
      </div>

      <div
        v-if="hasValue"
        class="col-span-full mt-2"
      >
        <p class="text-gray-500 text-xs text-center mb-2">
          {{ $t('If you use Cloudflare please set your Proxy Status to DNS Only for this record.') }}
        </p>
        <p class="text-gray-500 text-xs text-center">
          {{ $t('Once complete you will be able to access your account from both your default domain at ')}}
          <a
            :href="projectCoUrl"
            target="_blank"
            class="font-bold text-primary-500"
          >
            {{ projectCoUrl }}
          </a>

          {{ $t('and your custom domain at ') }}

          <a
            :href="customDomainUrl"
            target="_blank"
            class="font-bold text-primary-500"
          >
            {{ customDomainUrl }}
          </a>
        </p>
        <!-- <div class="flex flex-wrap justify-end gap-2 pt-4 mt-4 bg-white sticky bottom-0 border-t w-full whitespace-nowrap">
          <BaseButton
            variant="white"
            hover-variant="danger"
            class="mr-2 whitespace-nowrap"
            @click="confirmDelete"
          >
            {{ $t('Delete Domain') }}
          </BaseButton>
        </div> -->
      </div>
    </template>
    <template
      v-if="hasValue"
      #actions
    >
      <BaseButton
        variant="white"
        hover-variant="danger"
        class="mr-2 whitespace-nowrap"
        @click="confirmDelete"
      >
        {{ $t('Delete Domain') }}
      </BaseButton>
    </template>
  </SettingsForm>
</template>
<script>
import SettingsForm from "@/modules/accounts/components/SettingsForm.vue";
import config from "@/modules/common/config.js";
import UpgradePlanWarning from "@/modules/accounts/components/UpgradePlanWarning.vue";

const aliasTarget = `secure.${config.ROOT_DOMAIN_HOST}`

export default {
  components: {
    UpgradePlanWarning,
    SettingsForm,
  },
  data() {
    return {
      tableColumns: [
        {
          name: this.$t('Name'),
          prop: 'name',
        },
        {
          name: this.$t('Type'),
          prop: 'type',
        },
        {
          name: this.$t('Alias of'),
          prop: 'value',
        },
      ]
    }
  },
  computed: {
    subscription() {
      return this.$store.state.accounts.subscription || {}
    },
    settingValue() {
      return this.$settings('custom_domain')
    },
    tableData() {
      if (this.settingValue?.split('.').length > 2) {
        return [
          {
            name: this.settingValue,
            type: 'CNAME',
            value: aliasTarget
          },
        ]
      }

      return [
        {
          name: this.settingValue,
          type: 'A',
          value: import.meta.env.VITE_BRAND_SSL_A_RECORD_IP
        },
      ]
    },
    currentTenant() {
      return this.$store.getters['auth/currentTenant']
    },
    projectCoUrl() {
      const subdomain = this.currentTenant?.attributes?.subdomain || ''
      return `https://${subdomain}.${config.ROOT_DOMAIN_HOST}`
    },
    customDomainUrl() {
      return `https://${this.settingValue}`
    },
    hasValue() {
      return !!this.settingValue
    }
  },
  methods: {
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t("Delete Custom Domain"),
        description: this.$t(
          "Are you sure? This will permanently delete the custom domain."
        ),
      });

      if (!confirmed) {
        return;
      }

      this.$refs.settingsForm?.resetAndSave()
    }
  }
}
</script>
