import i18n from "@/i18n";

const MIME_TYPES = {
  IMAGE: [
    'image/heic-sequence',
    'image/heif',
    'image/heif heif',
    'image/heic heic',
    'image/heic',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/webp',
    'image/tiff',
    'image/svg+xml',
    'image/gif',
    'image/vnd.microsoft.icon',
    'image/pjpeg',
    'image/x-tiff',
    'image/x-icon',
    'image/mov',
  ],
  VIDEO: [
    'application/x-troff-msvideo',
    'video/avi',
    'video/msvideo',
    'video/x-quicktime',
    'video/avi',
    'video/mp4',
    'application/mp4',
    'video/ogg',
    'video/x-ms-wmv',
    'video/x-ms-asf',
    'video/x-flv',
    'application/x-mpegURL',
    'video/MP2T',
    'video/3gpp',
    'video/x-msvideo',
    'video/mp1s',
    'video/mp2p',
    'application/vnd.rn-realmedia',
    'video/x-matroska',
    'application/x-pn-mpg',
    'video/jpm',
    'video/mj2',
    'video/mpeg',
    'video/mpv',
    'video/x-m4v',
    'video/quicktime',
    'video/ms-asf',
    'video/x-quicktime',
    'video/mpeg',
    'video/x-mpeg',
    'video/webm',
  ],
  AUDIO: [
    'audio/mpeg-3',
    'audio/wav',
    'audio/flac',
    'audio/x-wav',
    'audio/x-wav-11khz',
    'audio/x-wav-6khz',
    'audio/mpegurl',
    'audio/mpegurl',
    'audio/vnd.wave',
    'audio/wave',
    'audio/x-pn-wav',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/x-scpls',
    'audio/wav',
    'audio/aac',
    'audio/mp4a-latm',
    'audio/mpeg3',
    'audio/x-mpeg-3',
    'audio/wav',
    'audio/aiff',
    'audio/x-midi',
    'audio/x-wav',
  ]
  ,
  PRESENTATION: [
    'application/vnd.ms-powerpoint',
    'application/mspowerpoint',
    'application/powerpoint',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.presentation',
  ],
  SPREADSHEET: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/excel',
    'application/vnd.ms-excel',
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.oasis.opendocument.spreadsheet',
  ]
  ,
  WORD_DOCUMENT: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    'application/doc',
    'application/ms-doc',
    'application/vnd.oasis.opendocument.text,',
  ]
  ,
  PDF: [
    'application/pdf',
  ],
  TEXT: [
    'text/x-php',
    'text/rtf',
    'application/rtf',
    'text/plain',
  ],
  OTHER: [
    'application/x-empty',
    'application/octet-stream',
    '',
    null
  ]
}

export function getFileTypeOptions() {
  return [
    {
      label: i18n.t("Image"),
      value: MIME_TYPES.IMAGE
    },
    {
      label: i18n.t("Video"),
      value: MIME_TYPES.VIDEO
    },
    {
      label: i18n.t("Audio"),
      value: MIME_TYPES.AUDIO
    },
    {
      label: i18n.t("PDF"),
      value: MIME_TYPES.PDF
    },
    {
      label: i18n.t("Spreadsheet"),
      value: MIME_TYPES.SPREADSHEET
    },
    {
      label: i18n.t("Word Document"),
      value: MIME_TYPES.WORD_DOCUMENT
    },
    {
      label: i18n.t("Presentation"),
      value: MIME_TYPES.PRESENTATION
    },
    {
      label: i18n.t("Text"),
      value: MIME_TYPES.TEXT
    },
    {
      label: i18n.t("Other"),
      value: MIME_TYPES.OTHER
    },
  ]
}
