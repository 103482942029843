<template>
  <div class="mx-auto max-w-2xl pb-8">
    <GettingStartedHeader />

    <GettingStartedChecklist />

    <dl class="mt-10 divide-y divide-gray-900/10 border border-gray-200 rounded-lg">
      <div v-if="false /* Keep hidden until video is ready */">
        <WatchDemoCard />
      </div>

      <div class="grid grid-cols-2 divide-x divide-gray-200">
        <div class="col-span-1">
          <KnowledgeBaseCard />
        </div>
        <div class="col-span-1">
          <ContactSupportCard />
        </div>
      </div>
    </dl>
  </div>
</template>
<script lang="ts" setup>
import { onBeforeMount } from "vue";

import GettingStartedHeader from "@/modules/accounts/components/getting-started/GettingStartedHeader.vue";
import GettingStartedChecklist from "@/modules/accounts/components/getting-started/GettingStartedChecklist.vue";
import KnowledgeBaseCard from "@/modules/accounts/components/getting-started/KnowledgeBaseCard.vue";
import ContactSupportCard from "@/modules/accounts/components/getting-started/ContactSupportCard.vue";
import WatchDemoCard from "@/modules/accounts/components/getting-started/WatchDemoCard.vue";

import { useGettingStarted } from "@/modules/accounts/composables/useGettingStarted";

const {
  getCompletionState,
} = useGettingStarted()

onBeforeMount(() => {
  getCompletionState()
})
</script>
