<template>
  <div
    class="inline-flex items-center px-4 py-2 rounded-full text-sm border border-gray-200 whitespace-nowrap cursor-pointer"
    :class="{
      'text-gray-500 bg-gray-50': !isSelected,
      'text-white bg-primary-500': isSelected,
    }"
    @click="$emit('click', category)"
  >
    <i
      class="mr-2"
      :class="{
        [category.attributes.icon]: category.attributes.icon,
      }"
    />
    {{ category.attributes.name  }}
  </div>
</template>
<script lang="ts" setup>
import { PropType, computed } from 'vue';
import Data = API.Data;
import TemplateCategory = App.Domains.Categories.Models.TemplateCategory;

const props = defineProps({
  category: {
    type: Object as PropType<Data<TemplateCategory>>,
    required: true,
  },
  selectedCategory: {
    type: Object as PropType<Data<TemplateCategory>>,
  },
})

const isSelected = computed(() => {
  return props.category.id === props.selectedCategory?.id
})
</script>
