import axios from "axios";
import i18n from "@/i18n.js";
import store from "@/store/index.js";
import apiCache from "@/modules/common/utils/apiCache.js";
import { sortBy, get, cloneDeep } from 'lodash-es'
import { success, error } from '@/components/common/NotificationPlugin';
import { sleep } from "@/modules/common/utils/commonUtils";

import {
  getTaskColumns,
  taskFields,
  onCellValueChanged,
  isPropEditable,
  completedTasksFilters
} from "@/modules/tasks/utils/taskTableUtils"
import { processDuplicateTask, taskModelToFormData } from "@/modules/tasks/utils/formModelUtils";
import { groupTasksByDate, taskDateTypes } from "@/modules/tasks/utils/modelUtils";
import { groupByArrayValues, removeFromListById } from "@/modules/common/utils/listUtils.js";
import { transformToRestifyArray, entityToRestifyMapper } from "@/modules/common/utils/dataUtils";
import {
  syncEntityInArray,
  syncEntityInMap,
  EntityChangeEvent,
  getEntityArrayForWorker,
  groupEntitiesToMap
} from "@/modules/common/utils/entityUtils";

import {
  tasksWithRelationshipsWorker,
  completedTasksWithRelationshipsWorker,
  computeActiveTasksCompletedChildrenWorker
} from '@/modules/tasks/utils/taskRelationshipUtils'

import { columnBuilder } from '@/components/table/tableUtils'
import router from "@/router";
import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";
import useCan from "@/modules/common/composables/useCan";
import { Activities, trackActivity } from "@/modules/common/utils/trackingUtils";
import { getSetting } from "@/plugins/settingsPlugin";
import { nextTick } from "vue";
const { isRoleGreaterOrEqual } = useCan()

const state = () => ({
  tasks: {
    data: [],
  },
  // Active tasks
  allTasks: [],
  allTasksMap: new Map(),
  tasksLoading: true,
  allTasksLoaded: false,
  // Template tasks
  templateTasks: [],

  // Completed tasks
  projectCompletedTasks: [],
  globalCompletedTasks: [],
  completedTasksLoading: false,
  completedTasksLoaded: false,
  allTimeCompletedLoaded: false,
  projectCompletedTasksLoaded: {}, // { project_id: true | false }

  taskTimeEntries: {
    task_id: null,
    data: [],
  },
  statuses: {
    data: [],
  },
  statusesLoading: false,
  currentTaskLoading: false,
  currentTask: null,
  addTaskTrigger: 1,
  createDialogClosedTrigger: 1,
  globalTaskCreatedTrigger: 1,
  completedTaskToggle: 1,
  projectTasksToggle: 1,
  taskSyncTriggers: {},
  newTask: null,
  taskGroupsBinding: [],
  userScopedOrders: {}, // { task_id: order }
  subtasksChangedTrigger: 1,
})

const mutations = {
  setTasks(state, value) {
    state.tasks = value
  },

  // Active tasks
  setAllTasks(state, value) {
    state.allTasks = value
    state.allTasksLoaded = true
  },
  setAllTasksMap(state, value) {
    state.allTasksMap = new Map([...state.allTasksMap, ...value])
  },
  addTasks(state, value) {
    const distinctTasks = state.allTasks.filter(x => !value.find(y => y.id == x.id))
    state.allTasks = [...distinctTasks, ...value]
    state.allTasksLoaded = true
  },
  setTasksLoading(state, value) {
    state.tasksLoading = value
  },
  setAllTasksLoaded(state, value) {
    state.allTasksLoaded = value
  },

  // Completed tasks
  addProjectCompletedTasks(state, value) {
    const distinctTasks = state.projectCompletedTasks.filter(x => !value.find(y => y.id == x.id))
    state.projectCompletedTasks = [...distinctTasks, ...value]
  },
  setProjectCompletedTasksLoaded(state, project_id) {
    state.projectCompletedTasksLoaded[project_id] = true
  },
  setGlobalCompletedTasks(state, value) {
    state.globalCompletedTasks = value
    state.completedTasksLoaded = true
  },
  setCompletedTasksLoading(state, value) {
    state.completedTasksLoading = value
  },
  setCompletedTasksLoaded(state, value) {
    state.completedTasksLoaded = value
  },
  setAllTimeCompletedLoaded(state, value) {
    state.allTimeCompletedLoaded = value
  },

  // Template tasks
  addTemplateTasks(state, value) {
    const distinctTasks = state.templateTasks.filter(x => !value.find(y => y.id == x.id))
    state.templateTasks = [...distinctTasks, ...value]
  },

  //
  setTaskGroupsBinding(state, value) {
    state.taskGroupsBinding = value
  },
  addTask(state, { task, syncArray = null }) {
    if (!task) {
      return
    }

    state.newTask = task

    syncArray = syncArray || state.allTasks || []

    syncEntityInArray(task, syncArray, EntityChangeEvent.Create)
    syncEntityInMap(task, state.allTasksMap, EntityChangeEvent.Create)
  },
  updateTask(state, { task, syncArray = null }) {
    syncArray = syncArray || state.allTasks || []

    syncEntityInArray(task, syncArray)
    syncEntityInMap(task, state.allTasksMap)

    if (String(task.id)?.toString() !== String(state.currentTask?.id)) {
      return
    }

    state.currentTask = {
      ...state.currentTask,
      ...task,
      attributes: {
        ...state.currentTask.attributes,
        ...task.attributes
      },
      relationships: {
        ...state.currentTask.relationships,
        ...task.relationships
      }
    }
  },
  deleteTask(state, { task, syncArray = null }) {
    syncArray = syncArray || state.allTasks || []

    syncEntityInArray(task, syncArray, EntityChangeEvent.Delete)
    syncEntityInMap(task, state.allTasksMap, EntityChangeEvent.Delete)
  },
  updateUserScopedOrders(state, value) {
    state.userScopedOrders = {
      ...state.userScopedOrders,
      ...value
    }
  },
  setAllStatuses(state, value) {
    state.statuses = value
  },
  deleteStatus(state, id) {
    removeFromListById(state.statuses.data, id)
  },
  setStatusesLoading(state, value) {
    state.statusesLoading = value
  },
  setCurrentTaskLoading(state, value) {
    state.currentTaskLoading = value
  },
  setCurrentTask(state, value) {
    state.currentTask = value
  },
  setTaskTimeEntries(state, value) {
    state.taskTimeEntries = value
  },
  triggerCompletedTaskToggle(state) {
    state.completedTaskToggle++
  },
  triggerProjectTasksToggle(state) {
    state.projectTasksToggle++
  },
  triggerAddTask(state) {
    state.addTaskTrigger++
  },
  triggerCreateDialogClosed(state) {
    state.createDialogClosedTrigger++
  },
  triggerGlobalTaskCreated(state) {
    state.globalTaskCreatedTrigger++
  },
  triggerTaskSync(state, { task, deleted = false}) {
    const updatedTask = state.allTasksMap.get(Number(task.id)) || task

    const changeCount = (state.taskSyncTriggers[task.id]?.count || 0) + 1
    if (deleted) {
      state.taskSyncTriggers[task.id] = {
        event:  EntityChangeEvent.Delete,
        task: updatedTask,
        count: changeCount
      }
      return
    }

    state.taskSyncTriggers[task.id] = {
      event: EntityChangeEvent.Update,
      task: updatedTask,
      count: changeCount
    }
  },

  setTaskAllocations(state, { taskId, allocations }) {
    const taskIdx = state.tasks.data.findIndex(x => x.id === taskId)
    if (taskIdx === -1) {
      return
    }

    state.tasks.data[taskIdx].relationships.allocations = allocations
  },
  setTaskFollowers(state, { taskId, followers }) {
    const taskIdx = state.tasks.data.findIndex(x => x.id === taskId)
    if (taskIdx === -1) {
      return
    }

    state.tasks.data[taskIdx].relationships.followers = followers
  },
  triggerSubtasksChanged(state) {
    state.subtasksChangedTrigger++
  }
}

export const listRelatedFields = 'status,groups,allocations,preview,project[id|name|status_id|image|privacy|is_template|created_by]' // Temp add |privacy

function allRelatedFields() {
  let relatedFields = 'status,groups,allocations,project,media,parentTask,tasks,followers'
  if (!isRoleGreaterOrEqual(rolesEnum.CREATOR)) {
    return relatedFields
  }
  return relatedFields += ',timeAllocation'
}

function getRelatedFields(rootState, rootGetters) {
  let relatedFields = listRelatedFields
  let isSchedulerPath = rootState?.route.path?.includes('scheduler')
  let isCalendarPath = rootState?.route.path?.includes('calendar')

  if (isSchedulerPath || isCalendarPath) {
    const groupBy = rootGetters['filters/targetLocalFilters']('tasks')?.groupBy
    relatedFields = 'project[id|name|status_id|image|privacy]' // Temp add |privacy
    if (groupBy?.relatedProp && isSchedulerPath) {
      relatedFields+= `,${groupBy.relatedProp}`
    }
  }

  let isListPath = rootState?.route.path?.includes('list')
  if (isListPath) {
    const groupBy = rootGetters['filters/targetLocalFilters']('tasks')?.groupBy

    if (groupBy?.prop === 'relationships.parentTask.attributes.name') {
      relatedFields += ',parentTask[id|name|status_id]'
    }
  }

  return relatedFields
}

function getSyncArray({ task, storeState, getters, rootGetters}) {
  if (task?.relationships?.project?.attributes?.is_template) {
    return storeState.templateTasks
  }

  if (getters.isTaskClosed(task)) {
    return storeState.globalCompletedTasks
  }

  return storeState.allTasks
}

const actions = {
  async getTasks({ commit, rootGetters, rootState }, filters) {
    let tasks = null
    try {

      if (filters?.shouldReset === true || filters?.calendar) {
        commit('setTasks', { data: [] })
      }

      filters = filters || { ...rootState.route?.query }

      let relatedFields = getRelatedFields(rootState, rootGetters)

      let isSchedulerPath = rootState?.route.path?.includes('scheduler')
      let isCalendarPath = rootState?.route.path?.includes('calendar')

      if (isSchedulerPath || isCalendarPath) {
        filters.perPage = 500
        filters.page = 1
      }
      commit('setTasksLoading', true)

      tasks = await apiCache.getRequest('/restify/tasks', {
        params: {
          ...filters,
          related: relatedFields,
          project_id: rootGetters.project_id
        }
      })

      commit('setTasks', tasks)
    } catch(err) {
      console.log('tasks fetch err', err, typeof err)
    } finally {
      commit('setTasksLoading', false)
    }
  },
  addTaskToArray({ state, getters, rootGetters, commit }, task) {
    const syncArray = getSyncArray({
      task,
      storeState: state,
      getters,
      rootGetters
    })

    commit('addTask', { task, syncArray })

    if (getters.isTaskClosed(task)) {
      commit('addTask', { task, syncArray: state.projectCompletedTasks })
    }
  },
  updateTaskInArray({ state, getters, rootGetters, commit, dispatch }, task) {
    const syncArray = getSyncArray({
      task,
      storeState: state,
      getters,
      rootGetters
    })

    commit('updateTask', { task, syncArray })
    commit('triggerTaskSync', { task })
  },
  deleteTaskFromArray({ state, getters, rootGetters, commit }, task) {
    const syncArray = getSyncArray({
      task,
      storeState: state,
      getters,
      rootGetters
    })

    commit('deleteTask', { task, syncArray })

    if (getters.isTaskClosed(task)) {
      commit('deleteTask', { task, syncArray: state.projectCompletedTasks })
    }
  },
  async getCompletedTasks({ state, commit, dispatch }, {
    project_id,
    startDate,
    endDate,
  }) {
    if (project_id && state.projectCompletedTasksLoaded[project_id]) {
      return
    }

    const loadingAllTime = !project_id && !startDate && !endDate
    if (!project_id && state.allTimeCompletedLoaded) {
      return
    }

    try {
      commit('setCompletedTasksLoading', true)
      if (!project_id) {
        commit('setCompletedTasksLoaded', false)
      }

      const { data } = await axios.get('/tasks-data', {
        params: {
          project_id,
          startDate,
          endDate,
          status: 'completed',
          taskFields: `[${taskFields.join('|')}]`,
        }
      })

      const tasks = transformToRestifyArray(data.tasks, {
        type: 'tasks',
      })

      if (project_id) {
        const tasksWithRelationships = await dispatch('setCompletedTasksRelationships', { completedTasks: tasks, projectScoped: true })
        commit('addProjectCompletedTasks', tasksWithRelationships)
        commit('setProjectCompletedTasksLoaded', project_id)
      }
      else {
        const tasksWithRelationships = await dispatch('setCompletedTasksRelationships', { completedTasks: tasks })
        commit('setGlobalCompletedTasks', tasksWithRelationships)
        commit('setCompletedTasksLoaded', true)
        if (loadingAllTime) {
          commit('setAllTimeCompletedLoaded', true)
        }
      }

      // Also rebind files relationships in case some belong to completed tasks
      dispatch('files/setFilesTreeData', { bindCompletedTasks: true }, { root: true })
    } catch(err) {
      console.log('completed tasks fetch err', err)
    } finally {
      commit('setCompletedTasksLoading', false)
    }
  },
  async setCompletedTasksRelationships({ state, dispatch, rootState, commit }, options) {
    const { completedTasks, projectScoped } = options || { completedTasks: null, projectScoped: false }

    let tasks = completedTasks
    if (!tasks) {
      tasks = projectScoped
        ? state.projectCompletedTasks
        : state.globalCompletedTasks
    }

    tasks = getEntityArrayForWorker(tasks || [])

    if (!tasks.length) {
      if (state.completedTasksLoaded) {
        commit('setCompletedTasksLoading', false)
      }

      return tasks
    }

    const relationshipsLoaded = rootState.groups.allGroupsLoaded
      && rootState.users.allUsersLoaded
      && rootState.projects.allProjectsLoaded
      && rootState.files.allFilesLoaded
      && state.allTasksLoaded

    if (!relationshipsLoaded) {
      return tasks
    }

    const allGroups = getEntityArrayForWorker(rootState.groups.allGroups || [])
    const allUsers = getEntityArrayForWorker(rootState.users.allUsers || [])
    const allProjects = getEntityArrayForWorker(rootState.projects.allProjects || [])
    const allFiles = getEntityArrayForWorker(rootState.files.allFiles || [])
    const userScopedOrders = getEntityArrayForWorker(state.userScopedOrders)
    const activeTasks = getEntityArrayForWorker(state.allTasks || [])
    const tasksMap = getEntityArrayForWorker(state.allTasksMap)

    const {
      projectTasks,
      templateTasks,
      allTasksMap
    } = await completedTasksWithRelationshipsWorker(
      tasks,
      allGroups,
      allUsers,
      allProjects,
      allFiles,
      userScopedOrders,
      /* bindCompletedTasks */ true,
      activeTasks,
      tasksMap
    )

    commit('setAllTasksMap', allTasksMap)
    // Compute subtasks for active tasks that have completed children
    const completedTasksMap = await computeActiveTasksCompletedChildrenWorker(activeTasks, projectTasks)
    for (let taskId in completedTasksMap) {
      const parent = state.allTasks.find(task => task.id == taskId)
      if (!parent) {
        continue
      }

      if (!parent.attributes?.subtask_ids) {
        parent.attributes.subtask_ids = []
      }

      parent.attributes.subtask_ids.push(...completedTasksMap[taskId])
      parent.attributes.subtasks_count = parent.attributes.subtask_ids.length

      dispatch('updateTaskInArray', parent)
    }

    commit('addTemplateTasks', templateTasks)

    if (completedTasks) {
      return projectTasks
    }

    if (projectScoped) {
      commit('addProjectCompletedTasks', projectTasks)
      commit('setProjectCompletedTasksLoaded', rootState.projects.currentProjectId)
      return projectTasks
    }
    else {
      commit('setGlobalCompletedTasks', projectTasks)
      commit('setCompletedTasksLoading', false)
    }

    return projectTasks
  },
  async setTasksRelationships({ state, dispatch, rootState, commit }) {
    const tasks = getEntityArrayForWorker(state.allTasks || [])

    if (!tasks.length) {
      if (state.allTasksLoaded) {
        commit('setTasksLoading', false)
      }

      return
    }

    const allGroups = getEntityArrayForWorker(rootState.groups.allGroups || [])
    const allUsers = getEntityArrayForWorker(rootState.users.allUsers || [])
    const allProjects = getEntityArrayForWorker(rootState.projects.allProjects || [])
    const allFiles = getEntityArrayForWorker(rootState.files.allFiles || [])
    const userScopedOrders = getEntityArrayForWorker(state.userScopedOrders)
    const tasksMap = getEntityArrayForWorker(state.allTasksMap)

    const {
      projectTasks,
      templateTasks,
      allTasksMap
    } = await tasksWithRelationshipsWorker(
      tasks,
      allGroups,
      allUsers,
      allProjects,
      allFiles,
      userScopedOrders,
      false,
      [],
      tasksMap
    )

    commit('setAllTasks', projectTasks)
    commit('addTemplateTasks', templateTasks)
    commit('setAllTasksMap', allTasksMap)
    commit('setTasksLoading', false)
    commit('setAllTasksLoaded', true)

    dispatch('setCompletedTasksRelationships')
    dispatch('setCompletedTasksRelationships', {
      projectScoped: true
    })
  },
  async getAllStatuses({ commit, state }, forceFetch = false) {
    try {
      if (!forceFetch && state.statuses?.data?.length > 0) {
        return
      }
      commit('setStatusesLoading', true)
      const statuses = await apiCache.getRequest('/restify/task-statuses')
      commit('setAllStatuses', statuses)
    } finally {
      commit('setStatusesLoading', false)
    }
  },
  async deleteStatus({ commit }, statusId) {
    await axios.delete(`/restify/task-statuses/${statusId}`);
    commit('deleteStatus', statusId)
  },
  async getTaskTimeEntries({ commit, state }, id) {
    if (!id) {
      return
    }
    try {
      if(state.taskTimeEntries?.task_id !== id) {
        commit('setTaskTimeEntries', {
          data: [],
          task_id: null,
        })
      }
      const { data } = await axios.get('/restify/time-entries', {
        params: {
          task_id: id,
          related: 'group[id|name],user[id|name]',
        },
      })

      commit('setTaskTimeEntries', {
        data,
        task_id: id,
      })
    } catch (err) {
      console.warn('Could not fetch time entries', err)
    }
  },
  async getTaskById({ commit, state }, {
    id,
    silent = false,
    returnEntity = false,
    withPreview = false,
    silentError = false,
    skipRelated = false
  }) {
    try {

      if (returnEntity) {
        let related = skipRelated ? '' : allRelatedFields()
        if (!skipRelated && withPreview) {
          related += ',preview'
        }
        const { data } = await axios.get(`/restify/tasks/${id}`, {
          params: {
            related,
          }
        })

        const subtask_ids = data?.relationships?.tasks?.map(task => task.id) || []
        return {
          ...data,
          attributes: {
            ...(data?.attributes || {}),
            subtask_ids,
          },
          relationships: {
            ...(data?.relationships || {}),
          }
        }
      }

      if (state.currentTask?.id?.toString() !== id.toString()) {
        const task = state.allTasks.find(p => String(p.id) === String(id))

        if (task) {
          // We set this temporarily and then fetch the project again to get the full data
          const currentTask = state.currentTask

          commit('setCurrentTask', {
            ...task,
            relationships: {
              ...(task?.relationships || {}),
              ...(currentTask?.relationships || {}),
            }
          })
        }
      }

      if (!silent) {
        commit('setCurrentTaskLoading', true)
      }

      const { data } = await axios.get(`/restify/tasks/${id}`, {
        params: {
          related: allRelatedFields(),
        }
      })
      const subtask_ids = data?.relationships?.tasks?.map(task => task.id) || []
      commit('setCurrentTask', {
        ...data,
        attributes: {
          ...(data?.attributes || {}),
          subtask_ids,
        },
        relationships: {
          ...(data?.relationships || {}),
        }
      })

      return data
    } catch (err) {
      if (err.status === 404 && !silentError) {
        await router.push('/404')
        return
      }
      if (err.handled || silentError) {
        return
      }
      throw err
    } finally {
      commit('setCurrentTaskLoading', false)
    }
  },
  async createTask({ dispatch }, { data, setAsCurrent = false }) {
    const formData = taskModelToFormData(data);
    const response = await axios.post(`/restify/tasks`, formData)
    const taskId = response?.data?.id;

    if (!taskId) {
      return response?.data
    }

    const newTask = await dispatch('getTaskById', { id: taskId, returnEntity: !setAsCurrent })
    dispatch('addTaskToArray', newTask)
    trackActivity(Activities.TaskCreated, newTask)
    return newTask
  },
  async bulkCreateTasks({ dispatch, commit }, { taskModels }) {
    const response = await axios.post(`/restify/tasks/bulk`, taskModels)

    const tasksResponse = transformToRestifyArray(response.data, {
      type: 'tasks',
      getRelationships(task) {
        return {
          followers: transformToRestifyArray(task.followers || []),
          allocations: transformToRestifyArray(task.allocations || []),
          groups: transformToRestifyArray(task.groups || []),
          project: entityToRestifyMapper(task.project || {}),
        }
      }
    })

    tasksResponse.reverse()?.forEach(task => {
      trackActivity(Activities.TaskCreated, task)
      dispatch('addTaskToArray', task)
    })
    commit('triggerProjectTasksToggle')

    return response?.data
  },
  async duplicateTask({ dispatch }, data) {
    const formData = processDuplicateTask(data);
    const response = await axios.post(`/restify/tasks`, formData)
    const taskId = response?.data?.id;

    if (!taskId) {
      return
    }

    const newTask = await dispatch('getTaskById', { id: taskId })

    dispatch('addTaskToArray', newTask)

    return response?.data
  },
  async duplicateTask({ dispatch, commit }, { taskId }) {
    const { data } = await axios.post(`/restify/tasks/${taskId}/actions?action=duplicate-task`)
    const newTaskIds = data.created_task_ids || []

    for (const id of newTaskIds) {
      const newTask = await dispatch('getTaskById', {
        id,
        returnEntity: true
      })

      dispatch('addTaskToArray', {
        ...newTask,
        attributes: {
          ...newTask.attributes,
          subtask_ids: (newTask.relationships?.tasks || []).map(task => Number(task.id))
        }
      })

      const fileIds = newTask.relationships?.media?.map(file => file.id) || []

      fileIds.forEach(fileId => {
        dispatch('files/getFileById', {
          fileId,
          addToList: true
        }, { root: true })
      })
    }

    if (newTaskIds.length > 1) {
      await nextTick()
      commit('triggerSubtasksChanged')
    }

    return {
      id: newTaskIds[newTaskIds.length - 1]
    }
  },
  async createQuickTask({ dispatch }, data) {
    const response = await axios.post(`/restify/tasks?related=${listRelatedFields}`, data)
    const taskId = response?.data?.id;

    if (!taskId) {
      return
    }

    dispatch('addTaskToArray', response.data )

    return response?.data
  },
  async editTask({ commit, rootState, rootGetters, dispatch, getters }, { taskId, data, silent = false }) {
    const formData = taskModelToFormData(data, true);
    formData.delete('description_collaboration')

    // TODO: Temp logging https://projectco-workspace.slack.com/archives/C022U73HXFV/p1690870661964079
    if (data.date_type === taskDateTypes.SINGLE_DATE && !formData.get('date')) {
      const formToLog = {}
      for (let [key, value] of formData.entries()) {
        formToLog[key] = value
      }
      throw ({
        error: 'Trying to save single date task with no date',
        task: {
          id: taskId,
          model: data,
          formData: formToLog
        }
      })
    }

    const response = await axios.post(`/restify/tasks/${taskId}`, formData, {
      params: {
        related: getRelatedFields(rootState, rootGetters)
      }
    })

    if (silent) {
      return response?.data
    }

    let updatedTask = response?.data

    updatedTask = await dispatch('getTaskById', {
      id: taskId,
      returnEntity: true
    })

    dispatch('updateTaskInArray', updatedTask)

    return updatedTask
  },
  async updateTask({ dispatch, rootState, rootGetters }, { taskId, model, syncInArray = true }) {
    const data = cloneDeep(model)
    delete data.description_collaboration
    const response = await axios.patch(`/restify/tasks/${taskId}`, data, {
      params: {
        related: getRelatedFields(rootState, rootGetters)
      }
    })

    if (syncInArray && response?.data) {
      dispatch('updateTaskInArray', response.data)
    }
    return response?.data
  },
  async overwriteTaskDescription({ dispatch, rootState, rootGetters }, { id, description, syncInArray = true }) {
    const data = {
      notes: description,
      description_collaboration: null
    }

    const response = await axios.patch(`/restify/tasks/${id}`, data, {
      params: {
        related: getRelatedFields(rootState, rootGetters)
      }
    })

    if (syncInArray && response?.data) {
      dispatch('updateTaskInArray', response.data)
    }
    return response?.data
  },
  async updateTaskRelationships({ dispatch }, { taskId, data }) {

    await dispatch('changeTaskFollowers', {
      taskId,
      followers: data.follower_ids?.map(id => ({ id })),
      skipModelUpdate: true
    })

    await dispatch('changeTaskAllocations', {
      taskId,
      allocations: data.allocated_ids?.map(id => ({ id })),
      skipModelUpdate: true
    })

    await dispatch('changeTaskGroups', {
      taskId,
      group_ids: data.group_ids,
    })

  },
  async deleteTask({ commit, dispatch }, task) {
    await axios.delete(`/restify/tasks/${task.id}`);
    dispatch('deleteTaskFromArray', task)
    dispatch('syncParentRelationship', {
      childTask: task,
      previousParentId: task.attributes.parent_id
    })
    commit('triggerTaskSync', { task, deleted: true })
  },
  async toggleTaskCompleted({ state, commit, getters, dispatch }, { task, setCompleted }) {
    const emptyStatus = {
      attributes: {}
    }

    const initialStatus = task.relationships.status
    try {
      const completedStatus = getters.orderedStatuses[getters.orderedStatuses.length - 1] || emptyStatus
      const activeStatus = getters.orderedStatuses[0] || emptyStatus
      const newStatus = setCompleted ? completedStatus : activeStatus

      const isRecurringTaskCompleted = task.attributes.date_type === taskDateTypes.RECURRING && setCompleted;

      const result = await axios.post(`/restify/tasks/${task.id}/actions?action=change-task-status`, {
        status_id: newStatus?.id,
      })

      if (isRecurringTaskCompleted) {
        if (result.data.id.toString() !== task.id.toString()) {
          success(i18n.t('Next Task Recurrence created successfully'))

          const newRecurrenceTask = await dispatch('getTaskById', {
            id: result.data.id,
            returnEntity: true
          })

          dispatch('addTaskToArray', newRecurrenceTask)
        }
        else {
          success(i18n.t('Task Recurrence ended!'))
        }
      }
      else {
        success(i18n.t('Task updated!'))
      }

      let updatedTask
      if (setCompleted) {
        // Task closed: remove it from active array and add it to completed arrays
        updatedTask = state.allTasks.find(x => x.id == task.id) || task
        updatedTask = JSON.parse(JSON.stringify(updatedTask))

        commit('deleteTask', { task, syncArray: state.allTasks })

        updatedTask.attributes.status_id = newStatus.id
        updatedTask.relationships.status = newStatus

        if (updatedTask.attributes.is_recurrence_source) {
          updatedTask.attributes.is_recurrence_source = false
        }

        dispatch('addTaskToArray', updatedTask)
      }
      else {
        // Task reopened: remove it from completed arrays and add task to active array
        updatedTask = state.projectCompletedTasks.find(x => x.id == task.id)
        if (!updatedTask) {
          updatedTask = state.globalCompletedTasks.find(x => x.id == task.id) || task
        }

        updatedTask = JSON.parse(JSON.stringify(updatedTask))
        dispatch('deleteTaskFromArray', task)

        updatedTask.attributes.status_id = newStatus.id
        updatedTask.relationships.status = newStatus

        commit('addTask', { task: updatedTask, syncArray: state.allTasks })
      }

      commit('triggerCompletedTaskToggle')

      dispatch('updateTaskInArray', updatedTask)

      return result.data
    } catch (err) {
      task.attributes.status_id = initialStatus.id
      task.relationships.status = initialStatus
      if (err.handled) {
        return
      }
      error(i18n.t('Could not update the task status'))
    }
  },

  handleTaskCompleted({ state, dispatch, getters, commit }, { task }) {
    const emptyStatus = {
      attributes: {}
    }

    const completedStatus = getters.orderedStatuses[getters.orderedStatuses.length - 1] || emptyStatus

    let updatedTask = null

     // Task closed: remove it from active array and add it to completed arrays
     updatedTask = state.allTasks.find(x => x.id == task.id) || task
     updatedTask = JSON.parse(JSON.stringify(updatedTask))

     commit('deleteTask', { task, syncArray: state.allTasks })

     updatedTask.attributes.status_id = completedStatus.id
     updatedTask.relationships.status = completedStatus

     if (updatedTask.attributes.is_recurrence_source) {
      updatedTask.attributes.is_recurrence_source = false
    }

     dispatch('addTaskToArray', updatedTask)
  },
  async updateTasks({ dispatch }, { taskModels, syncWithServer = false }) {
    let updateResult = await axios.post(`restify/tasks/bulk/update`, taskModels)
  
    // TEMP because BE doesn't reply with the updated tasks

    if (!syncWithServer) {
      return updateResult
    }

    updateResult = []
    for (let task of taskModels) {
      const updatedTask = await dispatch('getTaskById', { id: task.id, returnEntity: true })
      dispatch('updateTaskInArray', updatedTask)
      updateResult.push(updatedTask)
    }

    return updateResult
  },
  async updateUserScopedOrders({ commit }, newOrdersObject) {
    commit('updateUserScopedOrders', newOrdersObject)
  },
  async uploadFileToTask({ commit }, { taskId, file }) {
    const formData = new FormData()
    formData.append('files[]', file)

    const url = `/restify/tasks/${taskId}/actions?action=attach-task-file`

    const { data } = await axios.post(url, formData, {
      onUploadProgress: progressEvent => {
        let progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        commit('files/setUploadProgress', { id: file.id, progress }, { root: true})
      }
    })

    store.dispatch('files/addUploadedFiles', data.files_uploaded);
    // Temporary work around because API returns the project here and not the uploaded file
    const uploadedFiles = get(data, 'files_uploaded', [])
    return get(uploadedFiles, `[${uploadedFiles.length - 1}]`, {})
  },
  async addSubTasks({ dispatch }, { taskId, tasks }) {
    try {
      const requests = tasks.map(task => {
        return dispatch('updateTask', {
          taskId: task?.id,
          model: {
            parent_id: taskId,
          }
        })
      })

      await Promise.all(requests)

      tasks.forEach(subTask => {
        dispatch('syncParentRelationship', {
          childTask: subTask,
          newParentId: taskId,
          previousParentId: subTask?.attributes?.parent_id
        })
      })

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async removeSubTasks({ dispatch, commit }, { tasks }) {
    try {
      const requests = tasks.map(task => {
        return dispatch('updateTask', {
          taskId: task?.id,
          model: {
            parent_id: null,
          }
        })
      })
      await Promise.all(requests)

      tasks.forEach(subTask => {
        dispatch('syncParentRelationship', {
          childTask: subTask,
          newParentId: null,
          previousParentId: subTask?.attributes?.parent_id
        })
      })

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async syncParentRelationship({ state, dispatch, commit }, {
    childTask,
    previousParentId,
    newParentId
  }) {

    const lookupArray = [...state.allTasks, ...state.templateTasks]

    if (previousParentId) {
      const previousParent = lookupArray.find(x => x.id == previousParentId)

      if (previousParent) {
        const prevParentChildTasks = (previousParent.attributes?.subtask_ids || []).filter(id => id != childTask.id)

        previousParent.attributes.subtask_ids = prevParentChildTasks
        previousParent.attributes.subtasks_count = prevParentChildTasks.length

        dispatch('updateTaskInArray', previousParent)
      }
    }

    if (newParentId) {
      if (previousParentId) {
        await sleep(100)
      }
      const newParent = lookupArray.find(x => x.id == newParentId)
      const childTaskFromList = lookupArray.find(x => x.id == childTask.id)

      if (newParent) {
        const newParentChildTasks = [...(newParent.attributes.subtask_ids || []), childTaskFromList.id]

        newParent.attributes.subtask_ids = newParentChildTasks

        newParent.attributes.subtasks_count = newParentChildTasks.length

        dispatch('updateTaskInArray', newParent)
      }
    }

    commit('triggerSubtasksChanged')
  },
  async changeTaskFollowers({ commit }, { taskId, followers, skipModelUpdate = false }) {
    try {
      if (!followers) {
        followers = []
      }

      const follower_ids = followers?.map(x => +x.id)
      await axios.post(`/restify/tasks/${taskId}/actions?action=attach-followers-task`, {
        follower_ids
      })

      if (skipModelUpdate) {
        return
      }

      commit('setTaskFollowers', {
        taskId,
        followers
      })
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async changeTaskGroups({ commit }, { taskId, group_ids }) {
    try {
      await axios.post(`/restify/tasks/${taskId}/actions?action=attach-group-action`, {
        group_ids
      })
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async changeTaskStatus({ getters, dispatch }, { task, status_id }) {
    try {
      const isReopeningTask = getters.isTaskClosed(task)
      const isClosingTask =  status_id == getters.completedStatus?.id

      if (isClosingTask) {
        dispatch('toggleTaskCompleted', { task, setCompleted: true })
        return;
      }

      if (isReopeningTask) {
        dispatch('toggleTaskCompleted', { task, setCompleted: false })
        return;
      }

      const response = await axios.post(`/restify/tasks/${task.id}/actions?action=change-task-status`, {
        status_id,
      })

      const updatedTask = {
        id: response?.data.id,
        attributes: {
          ...response?.data
        }
      }

      dispatch('updateTaskInArray', updatedTask)

      if (task.id != response?.data.id) {
        const newUpdatedTask = {
          id: task.id,
          attributes: {
            status_id
          }
        }

        dispatch('updateTaskInArray', newUpdatedTask)
      }

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async changeTaskAllocations({ commit }, { taskId, allocations, skipModelUpdate = false }) {
    try {
      if (!allocations) {
        allocations = []
      }
      const allocated_ids = allocations.map(x => +x.id)
      await axios.post(`/restify/tasks/${taskId}/actions?action=set-task-allocation`, {
        allocated_ids
      })

      if (skipModelUpdate) {
        return
      }

      commit('setTaskAllocations', {
        taskId,
        allocations
      })

    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async reorderTaskStatuses({}, { statuses }) {
    const orderedStatuses = statuses.map((status, index) => {
      const order = index + 1
      status.attributes.order = order
      return {
        order,
        id: +status.id,
      }
    })
    .slice(1, statuses.length - 1)

    await axios.post(`restify/task-statuses/bulk/update`, orderedStatuses)
  },

  async getProjectScopedCustomFields({} , { project_id }) {
    try {
      const customFields = await axios.get('/restify/available-custom-fields', {
        params: {
          perPage: 100,
          project_id,
        }
      })
      return (customFields?.data || []).filter(customField => customField.attributes?.entity_type === 'task')
    }
    catch (err) {
      return []
    }
  }
}

const getters = {
  totalTasks: state => {
    return state.tasks?.meta?.total || 0
  },
  activeColumns: () => {
    const { mainColumns, extraColumns } = getTaskColumns()

    let columns = mainColumns

    columnBuilder.addCustomFieldColumns(columns, 'task', 'attributes.sortable_date', { onCellValueChanged, isPropEditable })

    columnBuilder.addCustomColumns(columns, extraColumns)

    return columns
  },
  tableColumns: (state, getters) => {
    return getters.activeColumns.filter(c => c.visibleInTable !== false)
  },
  orderedStatuses: state => {
    const statuses = (state.statuses?.data || []).map(status => ({
      ...status,
      id: Number(status.id),
    }))

    return sortBy(statuses, 'attributes.order')
  },
  defaultTaskStatus: (state, getters) => {
    const statuses = getters.orderedStatuses
    const defaultStatusId = +getSetting('default_task_status')
    const fallbackStatus = statuses[0]
    if (defaultStatusId) {
      return statuses.find(s => s.id === defaultStatusId) || fallbackStatus
    }
    return fallbackStatus
  },
  completedStatus: (state, getters) => {
    return getters.orderedStatuses[getters.orderedStatuses.length - 1]
  },
  isTaskClosed: (state, getters) => (task) => {
    return (task?.status_id || task?.attributes?.status_id)?.toString() === getters.completedStatus?.id?.toString()
  },
  statusByName: state => name => {
    return state.statuses?.data?.find(s => s?.attributes?.name === name)
  },
  getStatusColor: state => statusId => {
    return state.statuses?.data?.find(s => s?.id?.toString() === statusId?.toString())?.attributes?.color || 'rgb(107, 114, 128)'
  },
  currentSort: (state, getters, rootState, rootGetters) => {
    const apiFilters = rootGetters['filters/targetApiFilters']('tasks') || []
    return apiFilters.find(f => f.key === 'sorts')?.value?.column || ''
  },
  displayedTasks: (state, getters, rootState, rootGetters) => {
    // Includes active + completed template tasks
    if (rootState.isTemplateContext) {
      return state.templateTasks || []
    }

    const project_id = rootGetters.project_id
    // Includes active + project scoped completed
    if (project_id) {
      return [...state.allTasks, ...state.projectCompletedTasks]
    }

    const tasksApiFilters = (rootGetters['filters/targetApiFilters']('tasks') || [])
    const showGlobalCompletedTasks = tasksApiFilters.find((filter) => filter.key === 'global-completed-tasks')?.value

    // Ouside project we return active + global completed based on active filter
    // So we need to remove duplicate completed loaded at the project level, if any
    const activeTasks = !Object.keys(state.projectCompletedTasksLoaded).length
      ? state.allTasks
      : state.allTasks.filter((task) => {
        return !getters.isTaskClosed(task)
      })

    if (showGlobalCompletedTasks && showGlobalCompletedTasks !== completedTasksFilters.No) {
      return [...activeTasks, ...state.globalCompletedTasks]
    }

    return activeTasks
  },
  currentTasks: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id
    if (project_id) {
      return getters.displayedTasks.filter((task) => task.attributes.project_id == project_id)
    }

    return getters.displayedTasks
  },
  groupedTasks: (state, getters, rootState, rootGetters) => (tasks = null, groupBy = null) => {

    let allTasks = []
    const project_id = rootGetters.project_id

    if (tasks) {
      allTasks = tasks
    }
    else {
      allTasks = project_id
      ? getters.projectScopedTasks
      : getters.displayedTasks
    }

    groupBy = groupBy || rootGetters['filters/targetLocalFilters']('tasks')?.groupBy
    const groupByColumn = groupBy?.[0];
    let groupByKey = groupByColumn
    const isCustomFieldGrouping = (groupByKey || '').startsWith('attributes.custom_fields')

    // TODO: is this needed?
    // if (getters.currentSort === 'order') {
    //   allTasks = sortBy(allTasks, 'attributes.order')
    // }
    const arrayValueKeys = ['relationships.groups', 'relationships.allocations', 'attributes.group_ids', 'attributes.allocated_ids']
    let groupedTasks
    if (groupByKey === 'attributes.sortable_date') {
      groupedTasks = groupTasksByDate(allTasks)
    }
    else if (arrayValueKeys.includes(groupByKey) || isCustomFieldGrouping) {
      if (groupByColumn?.customField?.attributes?.custom_field_type === 'select-color') {
        groupByKey += '.value'
      }

      groupedTasks = groupByArrayValues(allTasks, groupByKey, isCustomFieldGrouping)
    }
    else {
      groupedTasks = groupEntitiesToMap(allTasks, groupByKey)
    }

    return groupedTasks
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
