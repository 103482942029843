<template>
  <el-slider
    v-model="model"
    v-bind="$attrs"
    :min="min"
    :max="max"
    :range="range"
    :show-stops="false"
    :marks="marks"
  />
</template>
<script>
import { ElSlider } from 'element-plus'

export default {
  components: {
    ElSlider
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    minValue: {
      type: [Number, String],
      default: 0
    },
    maxValue: {
      type: [Number, String],
      default: 100
    },
    range: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        if (this.range && this.modelValue) {
          return [this.modelValue.min, this.modelValue.max]
        }
        return this.modelValue
      },
      set(value) {
        let modelValue = value
        if (this.range && value) {
          modelValue = {
            min: value[0],
            max: value[1]
          }
        }
        this.$emit('update:modelValue', modelValue)
      }
    },
    min() {
      return +this.minValue
    },
    max() {
      return +this.maxValue
    },
    marks() {
      return {
        [this.min]: this.formatMark(this.min),
        [this.max]: this.formatMark(this.max),
      }
    }
  },
  methods: {
    formatMark(value) {
      if (this.$attrs.formatTooltip) {
        return this.$attrs.formatTooltip(value)
      }

      return value
    }
  }
}
</script>
<style lang="scss">
.el-slider {
  .el-slider__bar {
    @apply bg-primary-500;
  }

  .el-slider__button {
    @apply border-primary-500;
  }

  .el-slider__marks-text {
    @apply whitespace-nowrap;
  }
}
</style>
