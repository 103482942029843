const localStorageOrganization = localStorage.getItem('default-organization')
const defaultOrganization = localStorageOrganization || 'cd' // 'cd' | 'demo' | 'wyzowl'
localStorage.setItem('default-organization', defaultOrganization)

const developHost = 'pdcv2.co'
const productionHost = 'project.co'
const iFramelyKey= '3c271753641cbf24913007'
const iFramelyApiUrl = `https://iframe.ly/api`

export function isProduction() {
  return import.meta.env.VITE_APP_ENVIRONMENT === 'production'
}

function isDevelopment() {
  return import.meta.env.VITE_APP_ENVIRONMENT === 'development'
}

function isLocalEnv() {
  return window.location.host.startsWith('localhost')
}

const localStorageHost = localStorage.getItem('default-host')
const host = localStorageHost || (isProduction()
    ? productionHost
    : developHost
)
localStorage.setItem('default-host', host)

let BASE_URL = `https://{organization}.api.${host}`
let ORGANIZATION_NAME = ''

export function isExceptionDomain(domain = '') {
  return ['', 'v2', 'pdcv2', 'project'].includes(domain)
}

function replaceOrganizationInHost(organizationName) {
  if (!organizationName || isExceptionDomain(organizationName)) {
    BASE_URL = BASE_URL.replace('{organization}.', '')
    return
  }

  ORGANIZATION_NAME = organizationName

  BASE_URL = BASE_URL.replace('{organization}', organizationName)
}

const ROOT_DOMAIN_HOST = `v2.${host}`
const ROOT_API_URL = `https://api.${host}/api`
const ROOT_URL = `https://api.${host}`
const ROOT_DOMAIN_URL = `https://${ROOT_DOMAIN_HOST}`

function getHostQuery() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('host')
}

function isDeployPreview() {
  return window.location.host.endsWith('projectco.netlify.app')
}

function getTenantHost() {
  if (isLocalEnv() || isDeployPreview()) {
    const organization = getHostQuery() || defaultOrganization
    const urlHost = `${organization}.v2.${host}`
    return urlHost
  }

  return window.location.host
}

function getOrganizationName() {
  return ORGANIZATION_NAME
}

function getBaseUrl() {
  return BASE_URL
}

function getApiHost() {
  return `${BASE_URL}/api`
}

export function getRealTimeHost() {
  // Uncomment the line below if you want to test real-time server locally
  // return ws://127.0.0.1:1234
  return `wss://realtimeapi.v2.${host}`
}

export function belongsToSubdomain(tenants) {
  if (!ORGANIZATION_NAME) {
    // Global login
    return true
  }
  return tenants.some(t => t.subdomain === ORGANIZATION_NAME)
}

export function isRootApi() {
  return window.location.host === ROOT_DOMAIN_HOST
}

export function isCustomDomain() {
  return !window.location.host.includes(host)
}

export const enableRealTimeCollaboration = import.meta.env.VITE_ENABLE_REAL_TIME_COLLABORATION === 'true'
export const enableConfigurationEndpoint = import.meta.env.VITE_ENABLE_CONFIGURATION_ENDPOINT === 'true'

export default {
  host,
  ROOT_URL,
  ROOT_API_URL,
  ROOT_DOMAIN_HOST,
  ROOT_DOMAIN_URL,
  getBaseUrl,
  getApiHost,
  getTenantHost,
  getOrganizationName,
  replaceOrganizationInHost,
  isRootApi,
  isProduction,
  isCustomDomain,
  iFramelyKey,
  iFramelyApiUrl,
  enableRealTimeCollaboration,
};
