<template>
  <div
    class="grid grid-cols-6 h-full"
    :class="{
      '-mx-4 sm:-mx-8 border-t border-gray-200': $useNewLayout,
      'gap-6': !$useNewLayout,
    }"
  >
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 relative">
      <div
        :class="{
          'sticky top-[92px]': !$useNewLayout,
        }">
        <div
          v-if="newNotificationReceived"
          class="absolute -top-2 right-6"
        >
          <base-button
            :loading="refreshLoading"
            size="xxs"
            class="rounded-2xl border-white"
            @click="refreshNotifications"
          >
            {{ $t('New notification - click to refresh') }}
          </base-button>
        </div>
        <NotificationsList/>
      </div>
    </div>

    <div
      class="col-span-6 sm:col-span-3 lg:col-span-4 w-full relative inbox-right-section"
      :class="{
        'bg-gray-100 sm:border-l border-gray-200 sm:max-h-[calc(100vh-140px)] overflow-y-auto rounded-br-lg': $useNewLayout
      }"
    >
      <NotificationCardActions
        v-if="openedNotification"
        :notification="openedNotification"
        :project-notification-status="projectNotificationStatus"
        @update-status="onUpdateStatus"
      />
      <div
        v-if="projectNotificationsLoading"
        :class="{
          'sm:px-4': $useNewLayout
        }"
      >
        <LoadingNotificationCard
          v-for="i in placeholderCount"
          :key="i"
          :class="{
            'rounded-lg': $useNewLayout
          }"
        />
      </div>
      <div
        v-if="!projectNotificationsLoading && openedNotification"
        class="w-full mt-6"
        :class="{
          'sm:px-4': $useNewLayout
        }"
      >
        <div v-if="!filteredProjectNotifications.length">
          <NotificationNoData
            @refresh="refreshNotifications"
          />
        </div>
        <template v-else>
          <NotificationCard
            v-for="notification in filteredProjectNotifications"
            :key="notification.id"
            :notification="notification"
            :class="{
              'rounded-lg': $useNewLayout
            }"
            @open-discussion="tryOpenDiscussion"
          />
          <div ref="target"></div>
          <div
            v-if="filteredProjectNotifications.length >= perPage"
            class="flex justify-center items-center"
            role="button"
            @click="viewMore"
          >
            <base-button
              :loading="viewMoreLoading"
              variant="primary-link"
            />
          </div>
        </template>
      </div>
    </div>

    <BaseDialog
      v-if="showDiscussionDialog"
      v-model="showDiscussionDialog"
      size="xlg"
      @close="onCloseDiscussionDialog"
    >
      <div class="w-full border-b border-gray-200 p-6">
        <h3 class="text-2xl font-bold">{{ $t('Project Discussion') }}</h3>
      </div>
      <div class="sm:mx-6">
        <ProjectDiscussionForm
          :modal-layout="true"
        />
      </div>
      <div class="px-6 py-4 border-t border-gray-200">
        <div class="flex justify-end">
          <button
            type="button"
            class="bg-white py-2 px-4 border border-gray-200 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
            @click="onCloseDiscussionDialog"
          >
            {{ $t('Close') }}
          </button>
        </div>
      </div>
    </BaseDialog>

  </div>
</template>
<script>
import { ref } from 'vue'
import { useElementVisibility } from '@vueuse/core'
import { sleep } from '@/modules/common/utils/commonUtils'
import NotificationCard from '@/modules/accounts/components/NotificationCard.vue'
import NotificationsList from '@/modules/accounts/components/NotificationsList.vue'
import { notificationStatuses } from '@/modules/accounts/utils/notificationUtils.js'
import NotificationCardActions from '@/modules/accounts/components/NotificationCardActions.vue'
import ProjectDiscussionForm from '@/modules/projects/components/calendar/ProjectDiscussionForm.vue'
import LoadingNotificationCard from '@/modules/accounts/components/notifications/LoadingNotificationCard.vue'
import NotificationNoData from '@/modules/accounts/components/NotificationNoData.vue'

const DefaultPerPage = 25
export default {
  components: {
    NotificationNoData,
    NotificationCard,
    NotificationsList,
    ProjectDiscussionForm,
    NotificationCardActions,
    LoadingNotificationCard,
  },
  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)
    return {
      target,
      targetIsVisible,
    }
  },
  data() {
    return {
      placeholderCount: 2,
      projectNotificationStatus: notificationStatuses.Unread,
      perPage: DefaultPerPage,
      viewMoreLoading: false,
      refreshLoading: false,
      showDiscussionDialog: false,
      discussionId: null,
      notificationToEdit: null,
    }
  },
  computed: {
    filteredProjectNotifications() {
      let notifications = []

      if (this.projectNotificationStatus === notificationStatuses.Unread) {
        notifications = this.projectNotifications.filter(projectNotification => !projectNotification.attributes.read_at || projectNotification.attributes.is_pinned)
      } else if (this.projectNotificationStatus === notificationStatuses.Pinned) {
        notifications = this.projectNotifications.filter(projectNotification => projectNotification.attributes.is_pinned)
      } else if (this.showUnreadGroupStatus || !this.projectNotificationStatus) {
        notifications = this.projectNotifications.sort((a, b) => !!(a.attributes.read_at) < !!(b.attributes.read_at) ? -1 : 1)
      } else {
        notifications = this.projectNotifications.filter(projectNotification => projectNotification.attributes.read_at || projectNotification.attributes.is_pinned)
      }

      return notifications.slice(0, this.perPage).sort((a, b) => !!(a.attributes.is_pinned) > !!(b.attributes.is_pinned) ? -1 : 1)
    },
    projectNotifications() {
      return this.$store.state.notifications.projectNotifications?.data || []
    },
    projectNotificationsLoading() {
      return this.$store.state.notifications.projectNotificationsLoading
    },
    openedNotification() {
      return this.$store.state.notifications.openedNotification
    },
    notificationFilters() {
      return this.$route.query
    },
    showUnreadGroupStatus() {
      return this.notificationGroupStatus === notificationStatuses.Unread
    },
    notificationGroupStatus() {
      return this.notificationFilters?.status
    },
    newNotificationReceived() {
      return this.$store.state.notifications.newNotificationReceived
    },
  },
  methods: {
    onUpdateStatus(status) {
      this.projectNotificationStatus = status
      this.resetPagination()
    },
    resetPagination() {
      this.perPage = DefaultPerPage
    },
    async viewMore() {
      this.viewMoreLoading = true

      await sleep(300)

      this.viewMoreLoading = false
      this.perPage += DefaultPerPage
    },
    async tryGetProjectNotifications(notification) {
      const filters = { ...this.notificationFilters }
      // * we should get all notification, the status filter is implemented locally
      delete filters.status
      await this.$store.dispatch('notifications/getProjectNotifications', {
        projectId: notification.id,
        filters,
      })
    },
    async refreshNotifications() {
      try {
        this.refreshLoading = true
        await this.$store.dispatch('notifications/getNotifications', {
          filters:  {...this.notificationFilters },
        })
      } catch (err) {
        console.warn(err)
      } finally {
        this.refreshLoading = false
      }
    },
    setDefaultStatus() {
      this.projectNotificationStatus = this.notificationGroupStatus
    },
    async tryOpenDiscussion(notification, discussionId) {
      const { project_id } = notification.attributes
      this.discussionId = discussionId
      this.notificationToEdit = notification

      await this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          projectId: project_id,
        },
      })

      this.showDiscussionDialog = true
    },
    async onCloseDiscussionDialog() {
      this.showDiscussionDialog = false

      await this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          projectId: undefined,
        },
      })

      if (!this.discussionId) {
        return
      }

      this.notificationToEdit['discussion'] = await this.$store.dispatch('discussions/getDiscussionById', this.discussionId)
    },
  },
  watch: {
    targetIsVisible(value) {
      if (!value) {
        return
      }
      this.viewMore()
    },
    notificationGroupStatus: {
      immediate: true,
      handler(value) {
        this.setDefaultStatus()
      }
    },
    openedNotification: {
      handler(value) {
        if (!value?.id) {
          return
        }
        this.tryGetProjectNotifications(value)
      },
    },
  },
}
</script>
