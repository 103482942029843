import axios from 'axios';
import store from "@/store";
import config from "@/modules/common/config.js";
import apiCache from "@/modules/common/utils/apiCache.js";

export default {

  setToken(token) {
    localStorage.setItem('token', token);
  },

  getToken() {
    return localStorage.getItem('token');
  },

  async login(data, params) {
    localStorage.removeItem('loginTracked')
    let result = await axios.post('/login', data, {
      params,
    });
    let { token } = result.data;
    /* This caused a bug for some users, removed temporarily
      if (!belongsToSubdomain(tenants)) {
       error(i18n.t(`The provided user does not belong to ${config.getOrganizationName()} organization`))
       return
      }
    */
    this.setToken(token);
    return result.data;
  },

  async getProfile() {
    let result = await axios.get('/restify/profile', {
      params: {
        related: 'groups,tenants'
      }
    });
    return result.data;
  },

  async getTenants() {
    return await axios.get('/user/list-tenants');
  },

  async updateProfile(data) {
    return axios.post(`/restify/profile`, data);
  },

  tryRedirectToSubdomain(routeQuery = {}) {
    const organizations = store.state.auth.organizations || []
    if (organizations.length === 1) {
      const token = this.getToken()
      const templateToShare = store.state.templates.templateToShare
      const firstOrganization = organizations[0]
      const fqdn = firstOrganization?.fqdn || firstOrganization?.attributes?.fqdn
      let fullUrl = `${fqdn}?loginToken=${token}`

      if (routeQuery?.newAccount) {
        fullUrl += `&newAccount=true`
      }
      if (templateToShare) {
        fullUrl += `&share_template=${templateToShare}`
      }
      const ap3Cookie = ap3c?.getCookie()
      if (ap3Cookie) {
        fullUrl += `&ap3c=${ap3Cookie}`
      }
      window.location = fullUrl
      return;
    }
  },

  async register(data, params) {
    const requestData = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      account_name: data.account_name,
      campaign: data.campaign,
      subdomain: data.subdomain,
      avatar: data.avatar || undefined,
      password: data.password,
      password_confirmation: data.password,
      provider: data.provider,
      'g-recaptcha-response': data['g-recaptcha-response'],
      onboarding: {
        ...(data.onboarding || {})
      }
    }

    if (data.provider) {
      delete requestData.password
      delete requestData.password_confirmation
    }
    const requestParams = {
      from: params.from,
      token: params.invitation,
    }
    let result = await axios.post(`${config.ROOT_API_URL}/register`, requestData, {
      params: requestParams,
    });
    let { plainTextToken } = result;
    this.setToken(plainTextToken);
    return result;
  },

  async logout() {
    const token = this.getToken();
    store.reset({
      self: true,
      modules: {
        route: {
          self: false
        },
        accounts: {
          self: false
        },
      }
    })

    apiCache.clear()
    localStorage.removeItem('token')
    localStorage.removeItem('loginTracked')

    await axios.post('/logout', {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    delete axios.defaults.headers.common['Authorization'];

    if (import.meta.env.DEV) {
      return
    }

    const redirectUrl = config.isCustomDomain()
      ? `https://${window.location.host}/login`
      : `${config.ROOT_DOMAIN_URL}/login`

    window.location.href = redirectUrl
    localStorage.removeItem('vuex')
  },

  async resetPassword(data) {
    return axios.post('/resetPassword', data);
  },

  async confirmInvitation(token, data) {
    return axios.post(`/validate/invitation/${token}`, data);
  },

  async forgotPassword(data) {
    return axios.post('/forgotPassword', data);
  },
}
