import { useStore } from 'vuex'
import { ref, onUnmounted, onMounted, computed } from 'vue'
import PusherUtils from "@/modules/common/utils/pusherUtils"
import { isRootApi } from "@/modules/common/config.js";

export default function useInitPusher(channelType) {
  let store = useStore()

  let channel = ref(null)
  let isConnectedToChannel = ref(false)
  let channelName = computed(async () => {
    if (channelType === 'current-user') {
      let userId = store.state.auth.user?.id
      let tenantId = store.getters['auth/currentTenant']?.id

      if (!tenantId) {
        await store.dispatch('auth/getProfile')
        tenantId = store.getters['auth/currentTenant']?.id
      }

      const userChannel = PusherUtils.getUserChannel(userId, tenantId)
      return userChannel
    }

    return ''
  })

  async function joinChannel() {
    if (isRootApi()) {
      return
    }
    channel.value = PusherUtils.echo().private(await channelName.value)

    channel.value.on(PusherUtils.PUSHER_SUBSCRIPTION_ERROR_EVENT, () => {
      isConnectedToChannel.value = false
    })

    channel.value.on(PusherUtils.PUSHER_SUBSCRIPTION_SUCCEEDED_EVENT, () => {
      isConnectedToChannel.value = true
    })
  }

  async function leaveChannel() {
    if (isRootApi()) {
      return
    }
    PusherUtils.echo().leave(await channelName.value)
  }

  async function tryJoinChannel() {
    try {
      await leaveChannel()
      await joinChannel()
    }
    catch (err) {
      isConnectedToChannel.value = false
    }
  }

  onMounted(async () => {
    if (!store.getters['accounts/isValidSubscription']) {
      return
    }

    await tryJoinChannel()
  })

  onUnmounted(async () => {
    await leaveChannel()
  })

  return {
    channel,
    isConnectedToChannel
  }
}
