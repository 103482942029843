<template>
  <div
    v-loading="loading"
    class="relative"
  >
    <div class="bg-white mb-5">
      <div class="text-gray-900 text-xl sm:text-4xl font-extrabold mb-3 flex items-center">
        <div class="sm:hidden">
          <UserLogo
            :user="user"
            size="md"
          />
        </div>
        <div class="ml-2 sm:ml-0 line-clamp-1">
          {{ user?.attributes?.name || user?.attributes?.email }}
        </div>

        <router-link
          :to="`/users/${user?.id}/edit`"
          class="sm:hidden ml-auto"
        >
          <BaseButton
            variant="white"
            class="shadow-none whitespace-nowrap"
          >
            {{ $t('Edit User') }}
          </BaseButton>
        </router-link>
      </div>
      <div class="flex gap-3 px-6 py-4 border border-gray-200 rounded-lg">
        <div class="flex-none hidden sm:block">
          <UserLogo
            :user="user"
            size="xxl"
          />
        </div>

        <div class="grow space-y-1 text-sm text-gray-500">
          <div class="flex flex-wrap items-center gap-2">
            <div class="font-bold text-gray-900">
              {{ $t('Groups') }}
            </div>
            <GroupChips
              v-if="userGroups.length"
              :groups="userGroups"
            />
            <div
              class="text-gray-400 hover:text-gray-500 cursor-pointer text-xs"
              @click="showGroupsDialog = true"
            >
              {{ $t('+ Add/Remove Groups') }}
            </div>
          </div>
          
          <div class="flex items-center space-x-2">
            <div class="font-bold text-gray-900">
              {{ $t('Role') }}
            </div>
            <div class="text-gray-500">
              {{ user?.attributes?.role?.[0] }}
            </div>
            <UserRoleBadge
              :user="user"
            />
          </div>
          <div class="flex items-center space-x-2">
            <div class="font-bold text-gray-900 whitespace-nowrap">
              {{ $t('Email Address') }}
            </div>
            <div class="text-gray-500">
              {{ user?.attributes?.email }}
            </div>
          </div>
          <div
            v-for="customField in customFields"
            class="flex items-center space-x-2"
          >
            <div class="font-bold text-gray-900">
              {{ customField.attributes?.name }}
            </div>
            <div class="text-gray-500">
              <CustomFieldValue
                :customField="customField"
                :model="user"
              />
            </div>
          </div>
        </div>

        <router-link
          :to="`/users/${user?.id}/edit`"
          class="hidden sm:block"
        >
          <BaseButton
            variant="white"
            class="shadow-none whitespace-nowrap"
          >
            {{ $t('Edit User') }}
          </BaseButton>
        </router-link>
      </div>
    </div>
    <DashboardWidgets
      v-if="user?.id"
    />
    <UserGroupsDialog
      v-if="showGroupsDialog && user?.id"
      v-model="showGroupsDialog"
      :user="user"
      @close="showGroupsDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import UserLogo from "@/components/common/UserLogo.vue";
import GroupChips from "@/components/common/GroupChips.vue";
import CustomFieldValue from "@/modules/common/components/CustomFieldValue.vue";
import UserRoleBadge from "@/components/common/UserRoleBadge.vue";
import DashboardWidgets from "@/modules/dashboard/components/DashboardWidgets.vue";
import UserGroupsDialog from "@/modules/users/components/UserGroupsDialog.vue";

// Utils
import { computed, provide, ref } from "vue";
import { WidgetsContext, WidgetContextPages } from "@/modules/dashboard/utils/widgetUtils";

import Data = API.Data
import User = App.Domains.Users.Models.User
import Group = App.Domains.Groups.Models.Group

// Composables
import { useStore } from "vuex";

const store = useStore()

const user = computed<Data<User>>(() => {
  return store.state.users.currentUser || {}
})

const loading = computed(() => {
  return store.state.users.currentUserLoading
})

const userGroups = computed<Data<Group>[]>(() => {
  return user.value?.relationships?.groups || []
})

const customFields = computed(() => {
  return store.getters["accounts/entityFormCustomFields"]('user')
})

const widgetsContext: WidgetsContext = {
  page: WidgetContextPages.UserProfile,
  user
}

provide('context', widgetsContext)

const showGroupsDialog = ref(false)
</script>
