import PaymentDetailsLayout from '@/modules/payments/layout/PaymentDetailsLayout.vue'
import PaymentsLayout from '@/modules/payments/layout/PaymentsLayout.vue'
import PaymentsList from "@/modules/payments/pages/payments-list.vue";
import PaymentsCards from "@/modules/payments/pages/payments-cards.vue";
import PaymentInvoice from "@/modules/payments/pages/payment-invoice.vue";
import PaymentsAdd from "@/modules/payments/pages/payments-add.vue";
import PaymentDetails from "@/modules/payments/pages/payment-details.vue";

import { CREATE_PAYMENTS } from '@/modules/common/enum/actionsEnum';

export default [
  {
    path: '/payments',
    redirect: '/payments/list',
    name: 'All Payments',
    component: PaymentsLayout,
    children: [
      {
        path: '/payments/list',
        name: 'All Payments List',
        component: PaymentsList,
        meta: {
          isViewType: true,
        },
      },
      {
        path: '/payments/card',
        name: 'All Payments Cards',
        component: PaymentsCards,
        meta: {
          isViewType: true,
        },
      },
    ],
  },
  {
    path: '/payments/add',
    name: 'New Payment',
    component: PaymentsAdd,
    meta: {
      requiresPermissionTo: CREATE_PAYMENTS
    }
  },
  {
    path: '/payments/:paymentId',
    name: 'All Payment Details',
    component: PaymentDetailsLayout,
    meta: {
      hideBreadCrumb: true,
    },
    redirect: to => {
      return `${to.fullPath}/invoice`
    },
    children: [
      {
        path: 'invoice',
        name: 'Invoice',
        component: PaymentInvoice,
      },
      {
        path: 'details',
        name: 'Details',
        component: PaymentDetails,
      },
    ]
  },
]
