import { cloneDeep, isEqual, sortBy } from 'lodash-es'
import { proofModelToFormData, proofStatuses, setProofStatus } from '@/modules/file-proofs/utils/fileProofsUtils'
import { columnBuilder } from "@/components/table/tableUtils";
import { getProofColumn } from "@/modules/file-proofs/utils/proofsTableUtils";
import axios from 'axios'
import store from '@/store'
import { nextTick } from 'vue'


const state = () => ({
  statuses: {
    data: [],
    loading: false,
  },
  proofs: {
    data: [],
  },
  loading: {
    getAll: false,
  },
})

const mutations = {
  setStatusesLoading(state, value) {
    state.statuses.loading = value
  },
  setProofs(state, value) {
    state.proofs = value
  },
  setAllStatuses(state, value) {
    state.statuses = value
  },
  deleteProof(state, proofId) {
    state.proofs.data = state.proofs.data.filter(p => String(p.id) !== String(proofId))
  },
  setProofLoading(state, { proofId, type, value }) {
    if(!state.loading[proofId]) state.loading[proofId] = {}
    state.loading[proofId][type] = value
  }
}

const actions = {
  async getProofs({ commit, rootGetters, rootState, state }, filters) {
    try {

      if (filters?.shouldReset === true) {
        commit('setProofs', { data: [] })
      }

      filters = filters || { ...rootState.route?.query }

      state.loading.getAll = true

      const { data } = await axios.get('/restify/proofs', {
        params: {
          ...filters,
          related: 'reviewers.user,creator',
          project_id: rootGetters.project_id,
          perPage: 500,
        }
      })

      commit('setProofs', {
        data: data.map(p => setProofStatus(p))
      })
    } catch(err) {
      console.log('proofs fetch err', err, typeof err)
    } finally {
      state.loading.getAll = false
    }
  },
  async getProofById({ commit, state }, proofId) {
    if (state.loading?.[proofId]?.get) {
      return
    }
    try {
      commit('setProofLoading', { proofId, type: 'get', value: true })

      const proofs = cloneDeep(state.proofs?.data || [])
      const index = proofs.findIndex(p => String(p.id) === String(proofId))
      const initialReviewerIds = proofs[index]?.attributes?.reviewer_ids || []

      let { data } = await axios.get(`/restify/proofs/${proofId}`, {
        params: {
          related: 'reviewers.user,creator',
        }
      })

      if (data?.attributes) {
        data = setProofStatus(data)
      }

      if (!proofs?.length) {
        store.dispatch('projects/getProjectById', { silent: true })
      }

      if (index === -1) {
        proofs.unshift(data)
        commit('setProofs', { data: proofs })
        return data
      }

      proofs[index] = data;
      commit('setProofs', { data: proofs })

      const sameReviewers = isEqual(initialReviewerIds, data?.attributes?.reviewer_ids)

      if (!sameReviewers) {
        await nextTick()
        window.mainGridApi?.resetRowHeights()
      }

      return data
    } catch(err) {
      console.log('proof fetch err', err, typeof err)
    } finally {
      commit('setProofLoading', { proofId, type: 'get', value: false })
      commit('setProofLoading', { proofId, type: 'edit', value: false })
    }
  },
  async createProof({ commit, state, dispatch }, payload) {
    const formData = proofModelToFormData(payload)

    let { data } = await axios.post('/restify/proofs', formData)

    commit('setProofLoading', { proofId: data.id, type: 'edit', value: true })

    if (data?.attributes) {
      data = setProofStatus(data)
    }

    let creator = store.state?.auth?.user || {}
    if(creator?.id) {
      creator = {
        id: creator.id,
        attributes: creator
      }
    }

    const proofs = cloneDeep(state.proofs?.data || [])
    if (!proofs?.length) {
      store.dispatch('projects/getProjectById', { silent: true })
    }

    proofs.unshift({
      ...data,
      relationships: { creator }
    })

    commit('setProofs', { data: proofs })
    
    dispatch('accounts/syncSubscriptionStats', { },  { root: true })
    return data
  },
  async updateProof({ commit }, payload) {
    commit('setProofLoading', { proofId: payload.id, type: 'edit', value: true })
    await axios.put(`/restify/proofs/${payload.id}`, payload)
  },
  async deleteProof({ commit, dispatch }, proofId) {
    await axios.delete(`/restify/proofs/${proofId}`);
    commit('deleteProof', proofId)

    dispatch('accounts/syncSubscriptionStats', { },  { root: true })
  },
  async addAsReviewer({ commit }, proofId) {
    await axios.post(`/restify/proofs/${proofId}/actions?action=add-user-as-reviewer`)
    commit('setProofLoading', { proofId, type: 'edit', value: true })
  },
  async getProofReviewUrl({ commit }, proofId) {
    const { url } = await axios.get(`/restify/proofs/${proofId}/getters/get-proof-review-url`);
    return url
  },
  async downloadCommentsPdf({}, proofId) {
    return await axios.post(`/restify/proofs/${proofId}/actions?action=download-proof-comments`);
  }
}

const getters = {
  orderedStatuses: state => {
    const statuses = proofStatuses.value
    return sortBy(statuses, 'attributes.order')
  },
  activeColumns: () => {
    const { mainColumns, extraColumns } = getProofColumn()

    let columns = mainColumns

    columnBuilder.addCustomColumns(columns, extraColumns)

    return columns
  },
  tableColumns: (state, getters) => {
    return getters.activeColumns.filter(c => c.visibleInTable !== false)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
