<template>
  <div
    v-show="chartFilter?.length"
    v-loading="chartsLoading"
    id="chart-container"
    class="min-h-[500px] h-full mb-6 bg-white rounded-lg overflow-hidden hidden md:block"
  />
</template>
<script lang="ts" setup>

import { minutesToFormattedTime } from "@/modules/common/utils/timeUtils";
import { computed, nextTick, PropType, ref, watch } from "vue";
import { ChartType, GridApi } from "@ag-grid-community/core";
import { useRoute } from "vue-router";
import { getEntityTarget } from "@/modules/common/utils/filterUtils"
import { useStore } from "vuex";
import { TableColumn } from "@/components/table/tableUtils";
import { orderBy } from "lodash-es";
import { formatPrice } from "@/plugins/formatPrice";
import { CreateRangeChartParams } from "@ag-grid-community/core/dist/cjs/es5/interfaces/IChartService";

const props = defineProps({
  gridApi: {
    type: Object as PropType<GridApi>,
  },
  title: {
    type: String,
  },
  columns: {
    type: Array as PropType<TableColumn[]>,
    default: () => [],
  },
  formatter: {
    type: String as PropType<'time' | 'currency'>,
    default: 'time',
  },
  defaultChartType: {
    type: String as PropType<ChartType>,
    default: 'groupedColumn',
  },
  hasTotalsFooter: {
    type: Boolean,
    default: false,
  }
})

const route = useRoute()
const store = useStore()

const localFilters = computed(() => {
  const target = getEntityTarget(route)
  return store.getters['filters/targetLocalFilters'](target)
})

const chartFilter = computed(() => {
  return localFilters.value.chart
})

const chartsLoading = ref(true)

function timeTooltipRenderer(params: any) {
  let value = params.datum[params.yKey]
  let title = params.title || params.yName;
  const { hours, minutes } = minutesToFormattedTime(value, true)
  let formattedTime = `${hours} hours`
  if (minutes > 0) {
    formattedTime += ` ${minutes} minutes`
  }
  return `${title}: ${formattedTime}`;
}

function formatNumber(value: string) {
  let price = formatPrice(value)
  return price.substring(1, price.length);
}

function currencyTooltipRenderer(params: any) {
  const key = params.yKey || params.angleKey
  const labelKey = params.calloutLabelKey
  let value = params.datum[key]
  let title = params.title || params.yName || params.datum[labelKey];
  return `${title} : ${formatNumber(value)}`
}

function defaultTooltipRenderer(params: any) {
  let value = params.datum[params.yKey]
  let title = params.title || params.yName;
  return `${title} : ${value}`
}

watch(() => [
  route.query.filters,
  route.query.search,
  chartFilter.value,
  props.gridApi,
], renderChartContainer)

const seriesColumns = computed(() => {
  let columns = props.columns
    .filter(c => c.chartDataType !== 'category' && c.chartDataType)

  columns = orderBy(columns, 'chartOrder')
  return columns.map(c => c.prop)
})

function timeFormatter(params: any) {
  const time = minutesToFormattedTime(params.value)
  const hours = +time.split(':')[0]
  return `${hours}h`
}

function currencyFormatter(params: any) {
  return formatNumber(params.value)
}

async function renderChartContainer() {
  await nextTick()
  const chartContainer = document.querySelector('#chart-container') as any
  if (!chartContainer) {
    return
  }

  chartContainer.innerHTML = ''
  if (!chartFilter.value || !props.gridApi) {
    return
  }
  const filterField = chartFilter.value[0]
  const lastRowIndex = props.gridApi?.getDisplayedRowCount() || 1
  chartsLoading.value = false

  const tooltip = {
    renderer(params: any) {
      if (props.formatter === 'time') {
        return timeTooltipRenderer(params)
      } else if (props.formatter === 'currency') {
        return currencyTooltipRenderer(params)
      }
      return defaultTooltipRenderer(params)
    },
    enabled: true,
  }
  const rowEndIndex = props.hasTotalsFooter && lastRowIndex > 1
    ? lastRowIndex - 2
    : lastRowIndex - 1

  const chartParams: CreateRangeChartParams = {
    chartThemeName: 'projectCo',
    cellRange: {
      rowStartIndex: 0,
      rowEndIndex,
      columns: [filterField, ...seriesColumns.value],
    },
    aggFunc: 'sum',
    chartType: props.defaultChartType,
    chartContainer,
    chartThemeOverrides: {
      common: {
        axes: {
          number: {
            label: {
              formatter(params: any) {
                if (props.formatter === 'time') {
                  return timeFormatter(params)
                } else if (props.formatter === 'currency') {
                  return currencyFormatter(params)
                }
                return params.value
              }
            }
          }
        },
        title: {
          enabled: true,
          text: props.title,
        },
      },
      pie: {},
      column: {
        series: {
          tooltip,
        },
      }
    }
  }
  props.gridApi?.createRangeChart(chartParams);
}
</script>
<style lang="scss">
.ag-ltr .ag-chart-tool-panel-button-enable .ag-chart-menu-close {
  @apply hidden;
}

.ag-chart-tooltip {
  @apply px-2 py-1 font-sans text-sm bg-white shadow;
}
</style>
