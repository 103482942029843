<template>
  <DashboardWidget
    :title="$t('Pinned')"
    :items="sortedAndFilteredData"
    :loading="loading"
    :emptyPlaceholder="$t(`You have no pinned items.`)"
    footerKey="showing active projects"
    icon="fa-solid fa-thumbtack"
    colspan="col-span-full"
  >
    <template #item="{ item: pinnedItem }">
      <pre>{{ pinnedItem  }}</pre>
    </template>
  </DashboardWidget>
</template>
<script lang="ts" setup>
// Components
import DashboardWidget from "@/modules/dashboard/components/widgets/DashboardWidget.vue";

// Utils
import { computed } from 'vue'

// Composables
import { useStore } from 'vuex'
import useSortedAndFilteredData from "@/modules/common/composables/useSortedAndFilteredData";

const store = useStore()

// const { sortedAndFilteredData } = useSortedAndFilteredData({
//   target: 'projects',
//   dataGetterPath: 'projects/projectsOrTemplatesData',
//   filtersAndSorts: myActiveProjectsFilter,
//   getQuickFilterText: (project: Data<Project>) => project.attributes.name,
//   extraFilters
// })

const sortedAndFilteredData = computed(() => {
  return []
})

const loading = computed(() => {
  return false
})
</script>
