<template>
  <ProjectsMainLayout />
</template>
<script>
import ProjectsMainLayout from "@/modules/projects/layout/ProjectsMainLayout.vue"

export default {
  components: {
    ProjectsMainLayout
  },
}
</script>
