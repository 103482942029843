import ProjectsList from './pages/projects-list.vue'
import ProjectsKanban from './pages/projects-kanban.vue'
import ProjectCards from './pages/project-cards.vue'
import ProjectsCalendar from "@/modules/projects/pages/projects-calendar.vue"
import ProjectsScheduler from "@/modules/projects/pages/projects-scheduler.vue"
import ProjectDetails from './pages/project-details.vue'
import ProjectDiscussion from './pages/project-discussion.vue'
import ProjectPeople from './pages/project-people.vue'
import ProjectsLayout from "@/modules/projects/layout/ProjectsLayout.vue";
import ProjectDetailsLayout from "@/modules/projects/layout/ProjectDetailsLayout.vue";
import ProjectKanbanLayout from "@/modules/projects/layout/ProjectKanbanLayout.vue";
import ProjectModuleLayout from "@/modules/projects/layout/ProjectModuleLayout.vue";
import TasksModuleLayout from "@/modules/tasks/layout/TasksModuleLayout.vue";
import TasksLayout from "@/modules/tasks/layout/TasksLayout.vue";
import TasksKanbanLayout from "@/modules/tasks/layout/TasksKanbanLayout.vue";
import TasksList from "@/modules/tasks/pages/tasks-list.vue";
import TaskCards from '@/modules/tasks/pages/tasks-cards.vue'
import TasksKanban from "@/modules/tasks/pages/tasks-kanban.vue";
import TasksCalendar from "@/modules/tasks/pages/tasks-calendar.vue";
import TasksScheduler from "@/modules/tasks/pages/tasks-scheduler.vue";
import FilesModuleLayout from "@/modules/files/layout/FilesModuleLayout.vue";
import FilesLayout from "@/modules/files/layout/FilesLayout.vue";
import FilesList from "@/modules/files/pages/files-list.vue";
import FilesCards from "@/modules/files/pages/files-cards.vue";
import ProjectEmbed from "@/modules/projects/pages/project-embed.vue"
import PaymentsLayout from "@/modules/payments/layout/PaymentsLayout.vue";
import PaymentsList from "@/modules/payments/pages/payments-list.vue";
import PaymentsCards from "@/modules/payments/pages/payments-cards.vue";
import PaymentDetailsLayout from "@/modules/payments/layout/PaymentDetailsLayout.vue";
import PaymentInvoice from "@/modules/payments/pages/payment-invoice.vue";
import TimeModuleLayout from "@/modules/time/layout/TimeModuleLayout.vue"
import TimeLayout from "@/modules/time/layout/TimeLayout.vue"
import TimeEntriesLayout from "@/modules/time/layout/TimeEntriesLayout.vue";
import AllocatedTimeLayout from "@/modules/time/layout/AllocatedTimeLayout.vue";
import TimeListActual from "@/modules/time/pages/time-list-actual.vue";
import TimeCardsActual from "@/modules/time/pages/time-cards-actual.vue";
import TimeListAllocated from "@/modules/time/pages/time-list-allocated.vue";
import TimeCardsAllocated from "@/modules/time/pages/time-cards-allocated.vue";
import TimeListAll from "@/modules/time/pages/time-list-all.vue";
import CustomFields from "@/modules/accounts/pages/custom-fields.vue";
import ResourcesModuleLayout from "@/modules/resources/layout/ResourcesModuleLayout.vue";
import ResourcesLayout from "@/modules/resources/layout/ResourcesLayout.vue";
import ResourcesList from "@/modules/resources/pages/resources-list.vue";

import {
  ACCESS_PROJECTS_KANBAN,
  ACCESS_PROJECTS_LIST,
  ACCESS_PROJECTS_CARD,
  ACCESS_TASKS_CALENDAR,
  ACCESS_TASKS_KANBAN,
  ACCESS_TASKS_LIST,
  ACCESS_TASKS_SCHEDULER,
  CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS,
  CREATE_PROJECTS,
  VIEW_TIME_ENTRIES,
  ACCESS_PROJECTS_CALENDAR
} from '@/modules/common/enum/actionsEnum'
import ProjectNoteManage from "@/modules/projects/pages/project-note-manage.vue";
import ProjectNoteLayout from '@/modules/projects/layout/ProjectNoteLayout.vue'
import PaymentDetails from "@/modules/payments/pages/payment-details.vue";
import FileProofsModuleLayout from '@/modules/file-proofs/layout/FileProofsModuleLayout.vue'
import FileProofsLayout from '@/modules/file-proofs/layout/FileProofsLayout.vue'
import FilesProofList from '@/modules/file-proofs/pages/file-proofs-list.vue'
import ProofEmbed from '@/modules/file-proofs/pages/proof-embed.vue'
import { ApiFilterKeys } from "@/modules/common/commonTypes.d";

export default [
  {
    path: '/projects',
    redirect: '/projects/list',
    name: 'Projects',
    component: ProjectModuleLayout,
    children: [
      {
        path: '/projects',
        redirect: '/projects/list',
        name: 'Projects Layout',
        component: ProjectsLayout,
        children: [
          {
            path: '/projects/list',
            name: 'Projects List',
            meta: {
              requiresPermissionTo: ACCESS_PROJECTS_LIST,
              isViewType: true,
              useFilter: ApiFilterKeys.MyProjects,
            },
            component: ProjectsList,
          },
          {
            path: 'calendar',
            name: 'Projects Calendar',
            component: ProjectsCalendar,
            meta: {
              requiresPermissionTo: ACCESS_PROJECTS_CALENDAR,
              isViewType: true,
              useFilter: ApiFilterKeys.MyProjects,
            }
          },
          {
            path: 'scheduler',
            name: 'Projects Scheduler',
            component: ProjectsScheduler,
            meta: {
              requiresPermissionTo: ACCESS_PROJECTS_CALENDAR,
              isViewType: true,
              useFilter: ApiFilterKeys.MyProjects,
            }
          },
        ]
      },
      {
        path: '/projects',
        name: 'Project Kanban Layout',
        component: ProjectKanbanLayout,
        meta: {
          hideBreadCrumb: true,
        },
        children: [
          {
            path: 'card',
            name: 'Project Cards',
            component: ProjectCards,
            meta: {
              requiresPermissionTo: ACCESS_PROJECTS_CARD,
              isViewType: true,
              useFilter: ApiFilterKeys.MyProjects,
            }
          },
          {
            path: 'kanban',
            name: 'Projects Kanban',
            component: ProjectsKanban,
            meta: {
              requiresPermissionTo: ACCESS_PROJECTS_KANBAN,
              isViewType: true,
              useFilter: ApiFilterKeys.MyProjects,
            }
          },
        ]
      },
      {
        path: '/projects/add',
        name: 'New Project',
        component: ProjectDetails,
        meta: {
          requiresPermissionTo: CREATE_PROJECTS
        }
      },
      {
        path: '/projects/:id',
        name: 'Project Details',
        component: ProjectDetailsLayout,
        redirect: to => {
          return to.fullPath
        },
        children: [
          {
            path: '',
            name: 'Project View',
            meta: {
              hideBreadCrumb: true,
            },
            component: ProjectDetails,
          },
          {
            name: 'Project Notes Layout',
            path: 'notes',
            component: ProjectNoteLayout,
            meta: {
              isProjectTool: true
            },
            children: [
              {
                path: ':noteId?',
                name: 'Project Notes',
                component: ProjectNoteManage,
              },
            ]
          },
          {
            path: 'people',
            name: 'Project People',
            component: ProjectPeople,
          },
          {
            path: 'discussion',
            name: 'Discussion',
            component: ProjectDiscussion,
            meta: {
              isProjectTool: true
            }
          },
          {
            path: 'tasks',
            name: 'Project Tasks Module Layout',
            component: TasksModuleLayout,
            meta: {
              isProjectTool: true
            },
            children: [
              {
                path: '',
                name: 'Project Tasks Layout',
                component: TasksLayout,
                redirect: to => {
                  return `${to.fullPath}/list`
                },
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'Tasks List',
                    component: TasksList,
                    meta: {
                      requiresPermissionTo: ACCESS_TASKS_LIST,
                      isViewType: true,
                      useFilter: ApiFilterKeys.MyTasks,
                    }
                  },
                  {
                    path: 'card',
                    name: 'Project Task Cards',
                    component: TaskCards,
                    meta: {
                      requiresPermissionTo: ACCESS_TASKS_LIST,
                      isViewType: true,
                      useFilter: ApiFilterKeys.MyTasks,
                    }
                  },
                  {
                    path: 'calendar',
                    name: 'Tasks Calendar',
                    component: TasksCalendar,
                    meta: {
                      requiresPermissionTo: ACCESS_TASKS_CALENDAR,
                      isViewType: true,
                      useFilter: ApiFilterKeys.MyTasks,
                    }
                  },
                  {
                    path: 'scheduler',
                    name: 'Tasks Scheduler',
                    component: TasksScheduler,
                    meta: {
                      requiresPermissionTo: ACCESS_TASKS_SCHEDULER,
                      isViewType: true,
                      useFilter: ApiFilterKeys.MyTasks,
                    }
                  },
                ],
              },
              {
                path: 'kanban',
                name: 'Project Tasks Kanban Layout',
                component: TasksKanbanLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: '',
                    name: 'Tasks Kanban',
                    component: TasksKanban,
                    meta: {
                      requiresPermissionTo: ACCESS_TASKS_KANBAN,
                      isViewType: true,
                      useFilter: ApiFilterKeys.MyTasks,
                    }
                  },
                ]
              },
            ]
          },
          {
            path: 'files',
            name: 'Project Files',
            component: FilesModuleLayout,
            meta: {
              isProjectTool: true
            },
            children: [
              {
                path: '',
                name: 'Project Files Layout',
                component: FilesLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'Files List',
                    component: FilesList,
                    meta: {
                      isViewType: true,
                    }
                  },
                  {
                    path: 'card',
                    name: 'Project Files Cards',
                    component: FilesCards,
                    meta: {
                      isViewType: true,
                    }
                  },
                ]
              },
            ]
          },
          {
            path: 'file-proofs',
            name: 'File Proofs',
            redirect: to => {
              return `${to.fullPath}/list`
            },
            component: FileProofsModuleLayout,
            meta: {
              isProjectTool: true
            },
            children: [
              {
                path: '',
                name: 'Project File Proofs Layout',
                redirect: to => {
                  return `${to.fullPath}/list`
                },
                component: FileProofsLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'Project File Proofs',
                    component: FilesProofList,
                    meta: {
                      hideBreadCrumb: true,
                    },
                  },
                ]
              },
              {
                path: ':proofId',
                name: 'Proof Embed',
                component: ProofEmbed,
                meta: {
                  isProjectTool: true
                },
              },
            ]
          },
          {
            path: 'payments',
            name: 'Payments',
            component: PaymentsLayout,
            meta: {
              isProjectTool: true
            },
            redirect: to => {
              return `${to.fullPath}/list`
            },
            children: [
              {
                path: 'list',
                name: 'Payments List',
                meta: {
                  isViewType: true,
                },
                component: PaymentsList,
              },
              {
                path: 'card',
                name: 'Payments Cards',
                meta: {
                  isViewType: true,
                },
                component: PaymentsCards,
              },
            ]
          },
          {
            path: 'payments/:paymentId',
            name: 'Project Payment Details',
            component: PaymentDetailsLayout,
            meta: {
              isProjectTool: true
            },
            redirect: to => {
              return `${to.fullPath}/invoice`
            },
            children: [
              {
                path: 'invoice',
                name: 'Payment Invoice',
                component: PaymentInvoice,
              },
              {
                path: 'details',
                name: 'Payment Details',
                component: PaymentDetails,
              },
            ]
          },
          {
            path: 'time',
            name: 'Project Time Module Layout',
            meta: {
              isProjectTool: true
            },
            component: TimeModuleLayout,
            children: [
              {
                path: '',
                name: 'Project Time Layout',
                component: TimeLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'all',
                    name: 'All Project Time',
                    component: TimeListAll,
                    meta: {
                      requiresPermissionTo: VIEW_TIME_ENTRIES,
                      isViewType: true
                    }
                  },
                ]
              },
              {
                path: 'allocated',
                name: 'Project Allocated Time Layout',
                component: AllocatedTimeLayout,
                meta: {
                  isEntityTypeSelect: true,
                },
                redirect: to => {
                  return `${to.fullPath}/list`
                },
                children: [
                  {
                    path: 'list',
                    name: 'Project Allocated Time',
                    component: TimeListAllocated,
                    meta: {
                      requiresPermissionTo: VIEW_TIME_ENTRIES,
                      isViewType: true
                    },
                  },
                  {
                    path: 'card',
                    name: 'Project Allocated Time Cards',
                    component: TimeCardsAllocated,
                    meta: {
                      requiresPermissionTo: VIEW_TIME_ENTRIES,
                      isViewType: true
                    },
                  },
                ]
              },
              {
                path: 'actual',
                name: 'Project Time Entries Layout',
                component: TimeEntriesLayout,
                meta: {
                  isEntityTypeSelect: true,
                },
                redirect: to => {
                  return `${to.fullPath}/list`
                },
                children: [
                  {
                    path: 'list',
                    name: 'Project Actual Time',
                    meta: {
                      requiresPermissionTo: VIEW_TIME_ENTRIES,
                      isViewType: true
                    },
                    component: TimeListActual,
                  },
                  {
                    path: 'card',
                    name: 'Project Actual Time Cards',
                    meta: {
                      requiresPermissionTo: VIEW_TIME_ENTRIES,
                      isViewType: true
                    },
                    component: TimeCardsActual,
                  },
                ]
              },
            ]
          },
          {
            path: 'custom-fields',
            name: 'Project Custom Fields',
            component: CustomFields,
            meta: {
              requiresPermissionTo: CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS
            }
          },
          {
            path: 'resources',
            name: 'Project Resources',
            component: ResourcesModuleLayout,
            meta: {
              isProjectTool: true
            },
            children: [
              {
                path: '',
                name: 'Project Resources Layout',
                component: ResourcesLayout,
                meta: {
                  hideBreadCrumb: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'Project Resources List',
                    component: ResourcesList,
                    meta: {
                      hideBreadCrumb: true,
                    },
                  },
                ]
              },
            ]
          },
          {
            path: 'embeds/:embedId',
            name: 'Project Embed',
            component: ProjectEmbed,
            meta: {
              isProjectTool: true
            },
          },
        ]
      },
    ]
  },
]
