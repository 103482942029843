<template>
  <VeeForm
    v-loading="(editProofId && !proofToEdit) || loading"
    :element-loading-text="loadingMessage"
    #default="{ meta }"
    class="divide-y divide-gray-200 file-form"
    @submit="onSubmit"
  >
    <div class="p-6 space-y-6 sm:space-y-5">
      <UpgradePlanWarning
        v-if="exceedProofsUsage"
        :message="$t(' to create proofs.')"
      />

      <div
        v-if="proofToEdit"
        class="w-full flex items-center gap-2 p-1 mb-1 bg-gray-100 rounded-sm"
      >
        <img
          :src="proofToEdit.attributes.thumbnail_link"
          :alt="name"
          class="h-8 w-auto rounded-md"
        >

        <span class="truncate text-gray-500 text-sm">
          {{ proofToEdit.attributes.name }}
        </span>
      </div>

      <BaseDndFileUpload
        v-else-if="!model.file && !createFromFile"
        v-model="model.file"
        :disabled="exceedProofsUsage"
        :renderList="false"
        :multiple="false"
      />
      <UploadFilePreview
        v-else
        :project_id="projectId"
        :existing-file="createFromFile?.attributes"
        :suppress-remove="!!createFromFile?.attributes"
        :fileToUpload="model.file"
        :auto-upload="false"
        @remove="removeFile"
      />

      <div
        v-if="showAcceptedFormats"
        class="!mt-1"
      >
        <span class="text-gray-400 text-xs">{{ $t('Supported File & Media Formats') }}</span>
        <BaseTooltip effect="light">
          <template #content>
            <div class="!max-w-[300px] !max-h-[400px] overflow-scroll">
              {{ proofAcceptFormats }}
            </div>

          </template>
          <i class="fas fa-info-circle text-xs relative ml-2 text-gray-300 hover:text-gray-500 cursor-pointer" />
        </BaseTooltip>
      </div>

      <BaseInput
        v-model="model.name"
        v-focus="100"
        :label="$t('Name')"
        :placeholder="$t('Enter file proof name...')"
        :name="$t('Name')"
        :disabled="exceedProofsUsage"
        rules="required"
        id="name"
      />

      <BaseInput
        :model-value="model.reviewer_ids"
        :label="$t('Reviewers')"
        :name="$t('Reviewers')"
        rules="required"
      >
        <div class="mt-1">
          <UserSelect
            v-model="model.reviewer_ids"
            url=""
            multiple
            :initial-value="initialReviewers"
            :disabled="!projectId || exceedProofsUsage"
            :data="currentProjectUsers"
            :placeholder="$t('Choose people to review')"
            @raw-change="onRawChange($event)"
          />
        </div>
      </BaseInput>

      <BaseInput
        :modelValue="model.notify_users_id"
        :label="$t('Notify People')"
        :name="$t('Notify People')"
        :info-text="$t(`Add people who should be notified when reviewers make proof decisions and when the proof status changes.`)"
      >
        <div class="mt-1 sm:mt-0 sm:col-span-3">
          <UserSelect
            v-model="model.notify_users_id"
            url=""
            multiple
            :initial-value="initialNotifyUsers"
            :disabled="!projectId || exceedProofsUsage"
            :data="currentProjectUsers"
            :placeholder="$t('Choose people to notify')"
          />
        </div>
        <button
          v-if="model.reviewer_ids?.length"
          type="button"
          class="text-primary-500 font-semibold text-xs cursor-pointer hover:unnderline disabled:text-gray-500 disabled:cursor-not-allowed"
          @click="notifyUsersFromReviewers"
        >
          {{ $t('Copy from reviewers') }}
        </button>
      </BaseInput>

      <div class="text-sm text-gray-500">
        {{ $t('When you click "Create Proof" the file will be uploaded and the reviewers will be notified. Anyone else who has access to this project will also be able to open the proof and review it but they won\'t be notified.') }}
      </div>
    </div>

    <div class="px-6 py-4 order-t border-gray-200">
      <div class="flex justify-end">
        <BaseButton
          variant="white"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :disabled="!canUploadFiles(meta)"
          type="submit"
          class="ml-2"
        >
          {{ editProofId ? $t('Save') : $t('Create Proof') }}
        </BaseButton>
      </div>
    </div>
  </VeeForm>
</template>
<script>
// Components
import ProjectSelect from '@/components/selects/ProjectSelect.vue'
import UploadFilePreview from '@/modules/common/components/UploadFilePreview.vue'
import UserSelect from '@/components/selects/UserSelect.vue'
import { cloneDeep } from 'lodash-es'
import UpgradePlanWarning from '@/modules/accounts/components/UpgradePlanWarning.vue'
import { useAccountLimits } from '@/modules/auth/composables/useAccountLimits'
import proofAcceptFormats from '@/modules/file-proofs/utils/proofAcceptFormats.json'

export default {
  name: 'FileProofForm',
  components: {
    UpgradePlanWarning,
    UserSelect,
    ProjectSelect,
    UploadFilePreview,
  },
  setup() {
    const { accountLimits } = useAccountLimits()
    return {
      accountLimits,
    }
  },
  emits: ['save', 'cancel'],
  data() {
    return {
      showAcceptedFormats: false,
      loading: false,
      reviewers: [],
      notifyUsers: [],
      model: {
        name: '',
        file: null,
        reviewer_ids: [],
        notify_users_id: [],
      },
    }
  },
  watch: {
    proofToEdit: {
      handler(proof) {
        if (!proof) {
          return
        }
        this.model = {
          name: proof?.attributes?.name,
          file: proof?.attributes?.file,
          reviewer_ids: proof?.attributes?.reviewer_ids,
          notify_users_id: proof?.attributes?.notify_users_id,
        }
      },
      immediate: true,
    },
  },
  computed: {
    proofAcceptFormats() {
      return proofAcceptFormats.map(format => `.${format}`).join(', ')
    },
    loadingMessage() {
      if (this.editProofId && !this.proofToEdit) {
        return this.$t('Loading proof...')
      }
      return this.editProofId ? this.$t('Saving proof...') : this.$t('Creating proof... This might take up to 30 seconds.')
    },
    currentProjectUsers() {
      return this.$store.state.projects.currentProject?.relationships?.users || []
    },
    exceedProofsUsage() {
      return !this.editProofId && (this.accountLimits.proofs.used >= this.accountLimits.proofs.available)
    },
    initialReviewers() {
      return this.proofToEdit?.attributes?.reviewer_ids
        .map(id => this.getProjectUser(id)) || []
    },
    initialNotifyUsers() {
      if (this.notifyUsers?.length) {
        return this.notifyUsers
      }
      return this.proofToEdit?.attributes?.notify_users_id
        .map(id => this.getProjectUser(id)) || []
    },
    editProofId() {
      if (this.$route?.fullPath.includes('/file-proofs/list')) {
        return this.$route?.query?.edit
      }
      return ''
    },
    proofToEdit() {
      return this.proofs.find(proof => proof?.id === this.editProofId)
    },
    proofs() {
      return this.$store.state.proofs.proofs?.data || []
    },
    projectId() {
      const route = this.$route?.fullPath
      if (route.includes('projects')) {
        return this.$route?.params?.id
      }
      return null
    },
    createFromFile() {
      const fileId = this.$route?.query?.['create-from-file']
      if (!fileId) {
        return null
      }
      const file = this.$store.state.files.allFiles?.find(file => String(file.id) === String(fileId)) || null
      if (!file) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            'create-from-file': undefined,
          },
        })
        return null
      }
      return file
    },
  },
  methods: {
    getProjectUser(id) {
      return this.currentProjectUsers.find(user => String(user.id) === String(id))
    },
    onRawChange(value) {
      this.reviewers = value
    },
    removeFile() {
      if (!this.createFromFile?.attributes) {
        this.model.file = null
        return
      }
      this.$router.replace({
        query: {
          ...this.$route.query,
          'create-from-file': undefined,
        },
      })
    },
    onSubmit() {
      if (this.editProofId) {
        this.updateProof()
      } else {
        this.createProof()
      }
    },
    async updateProof() {
      try {
        this.loading = true

        await this.$store.dispatch('proofs/updateProof', {
          id: this.proofToEdit.id,
          ...this.model,
        })

        this.$emit('save')
      } catch(e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    async createProof() {
      try {
        this.loading = true

        const model = cloneDeep(this.model)

        if (this.createFromFile?.attributes) {
          model.file_id = this.createFromFile.id
          model.file = undefined
        }

        await this.$store.dispatch('proofs/createProof', {
          ...model,
          project_id: this.projectId,
        })

        this.showAcceptedFormats = false

        this.removeFile()

        this.$emit('save')
      } catch(e) {
        console.warn(e)
        this.showAcceptedFormats = true
      } finally {
        this.loading = false
      }
    },
    onCancel() {
      this.$emit('cancel')
    },
    canUploadFiles(meta) {
      if (this.editProofId || this.createFromFile) {
        return meta?.valid
      }
      return meta?.valid && this.model.file
    },
    notifyUsersFromReviewers() {
      this.notifyUsers = cloneDeep(this.reviewers)
      this.model.notify_users_id = this.model.reviewer_ids
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.el-loading-mask) {
  @apply opacity-70;
  .el-loading-spinner {
    @apply flex-col items-center;
    p {
      @apply text-primary-500 mt-4 font-medium;
    }
  }
}
</style>
