<template>
  <div>
    <div
      class="max-h-[500px] overflow-x-auto"
      :class="{
        'p-6': chatMessages.length,
      }"
    >
      <div class="space-y-6">
        <template
          v-for="chatMessage in chatMessages"
          :key="chatMessage.date"
        >
          <AiUserMessage
            v-if="chatMessage.type === ChatMessageTypes.User"
            :message="chatMessage"
          />
          <AiSystemMessage
            v-else-if="chatMessage.type === ChatMessageTypes.System"
            :message="chatMessage"
          />
          <AiActionChoice
            v-else-if="chatMessage.type === ChatMessageTypes.ActionChoice"
            :message="chatMessage"
          />
          <AiChatMessage
            v-else
            :message="chatMessage"
          />
        </template>
        <AiConversationPrompt
          v-if="showConversationPrompt"
          @start-new="startNewConversation"
          @continue="showConversationPrompt = false"
        />
        <AiThinkingBubble
          v-if="awaitingResponse"
        />
      </div>
      <div id="action-discussion-bottom" />
    </div>
    <HtmlEditor
      v-if="lastMessage?.type !== ChatMessageTypes.ActionChoice && !showConversationPrompt"
      v-model="prompt"
      :placeholder="$t(`What would you like the AI Assistant to do?`)"
      layout="box"
      :readonly="disableNewMessages"
      :slashMenu="false"
      :showFormattingControls="false"
      :disabledPlugins="['mention', 'file-upload', 'link', 'emoji', 'reference']"
      @send="sendMessage"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import AiUserMessage from "@/modules/ai/components/AiUserMessage.vue";
import AiChatMessage from "@/modules/ai/components/AiChatMessage.vue";
import AiActionChoice from "@/modules/ai/components/AiActionChoice.vue";
import AiThinkingBubble from "@/modules/ai/components/AiThinkingBubble.vue";
import AiSystemMessage from "@/modules/ai/components/AiSystemMessage.vue";
import AiConversationPrompt from "@/modules/ai/components/AiConversationPrompt.vue";

// Utils
import { computed, onMounted, ref, watch } from 'vue';
import i18n from "@/i18n";
import {
  ChatMessageTypes,
 } from '@/modules/ai/types/aiTypes';
 import showdown from 'showdown'

// Composables
import { useStore } from 'vuex';
import { useActionChat } from "@/modules/ai/composables/useActionChat";

const props = defineProps({
  createPrompt: {
    type: String,
    default: '',
  },
})

const {
  addChatMessage,
  sendRequest,
  scrollToBottom,
  messages,
  awaitingResponse,
} = useActionChat()

const store = useStore();

const showConversationPrompt = ref(false)

const history = computed(() => {
  return store.state.ai.actionModeHistory
})

const chatMessages = computed(() => {
  return [
    ...history.value,
    ...messages.value,
  ].filter((message) => {
    return ![
      ChatMessageTypes.UserHidden,
      ChatMessageTypes.AssistantHidden,
    ].includes(message.type)
  })
})

const requiresUserAction = computed(() => {
  return store.state.ai.requiresUserAction
})

const disableNewMessages = computed(() => {
  return awaitingResponse.value || requiresUserAction.value || showConversationPrompt.value;
})

const htmlConverter = new showdown.Converter({
  tables: true,
})

const prompt = ref('')

watch(() => props.createPrompt, (value) => {
  if (value) {
    prompt.value = value
  }
})

function sendMessage() {
  let promptHtml = prompt.value?.trim()
  if (!promptHtml) {
    return;
  }

  const promptMarkdown = htmlConverter.makeMarkdown(prompt.value)

  sendRequest(promptMarkdown);

  prompt.value = '';
}

const lastMessage = computed(() => {
  return chatMessages.value[chatMessages.value.length - 1]
})

function initConversation() {
  if (lastMessage.value?.type === ChatMessageTypes.ActionChoice || requiresUserAction.value) {
    return
  }

  store.dispatch('ai/resetActionMode')
}

async function startNewConversation() {
  showConversationPrompt.value = false;

  addChatMessage({
    type: ChatMessageTypes.System,
    message: i18n.t('Conversation ended. You can start a new one by typing a message.'),
    date: new Date(),
  })
}

watch(() => [
  messages.value.length,
  ],
  scrollToBottom,
  { immediate: true }
)

onMounted(async () => {
  scrollToBottom()
  initConversation()
})
</script>
