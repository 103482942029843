<template>
  <TasksCalendar is-scheduler/>
</template>
<script>
import TasksCalendar from "@/modules/tasks/components/calendar/TasksCalendar.vue";

export default {
  components: {
    TasksCalendar,
  }
}
</script>
