<template>
    <div
      :title="statusTooltip"
      class="flex items-center gap-1.5 h-full"
    >
      <i class="fa"
         :class="proofStatusAttrs?.icon"
         :style="{ color: proofStatusAttrs?.color }"
      />
      <div class="text-xs">{{ proofStatusAttrs?.name }}</div>
    </div>
</template>

<script setup lang="ts">
import { computed, PropType, toRef } from "vue";
import { ICellRendererParams } from "@ag-grid-community/core";
import useCellProps from "@/components/table/composables/useCellProps";
import { getProofStatus } from "@/modules/file-proofs/utils/fileProofsUtils";
import BaseTooltip from "@/components/common/tooltips/BaseTooltip.vue";
import { i18n } from "@/i18n";
import { ProofsStatuses } from "@/modules/file-proofs/utils/proofModels";

const props = defineProps({
  params: {
    type: Object as PropType<ICellRendererParams>,
    required: true
  },
})

const { value } = useCellProps(toRef(props, 'params'))

const proofStatus = computed(() => {
  return value.value || ProofsStatuses.PROCESSING
})

const proofStatusAttrs = computed(() => {
  return getProofStatus(proofStatus.value)?.attributes || {}
})

const statusTooltip = computed(() => {
  return proofStatus === ProofsStatuses.FAILED
    ? i18n.t('This proof failed and isn\'t accessible. It won\'t count towards your file proof usage. You can delete this proof and then upload the file again.')
    : ''
})
</script>
