<template>
  <div class="align-middle overflow-hidden">
    <AddUserForm
      class="mx-auto"
      @save="$router.push('/users/list')"
      @cancel="$router.push('/users/list')"
    />
  </div>
</template>
<script>
import AddUserForm from "@/modules/users/components/AddUserForm.vue";

export default {
  components: {
    AddUserForm,
  },
}
</script>
