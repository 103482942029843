<template>
  <BaseTableCell :params="params">
    <div>
      <div
        v-if="task?.id"
        class="cursor-pointer"
        @click="openTask"
      >
        <i
          v-if="childRow"
          class="fas fa-badge-check text-yellow-500 mr-1"
        />
        <span>{{ description }}</span>
      </div>
      <span
        v-else
        v-loading="loading"
      >
        {{ description }}
      </span>
    </div>
  </BaseTableCell>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from "vue"
import { useStore } from "vuex";

const store = useStore()

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data
})

const childRow = computed(() => {
  if (row.value) {
    return null
  }

  return props.params?.node?.allLeafChildren?.[0]?.data
})

const actualRowData = computed(() => {
  return row.value || childRow.value
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const loading = ref(false)
const task = ref<any>(null)

const description = computed(() => {
  if (task.value?.attributes?.name) {
    return task.value?.attributes?.name
  }

  if (columnParams.value?.hideEmpty?.(actualRowData.value)) {
    return ''
  }

  return '- -'
})

async function tryInitTask() {
  if (!(columnParams.value?.getTask instanceof Function)) {
    task.value = (actualRowData.value)?.relationships?.task || {}
    return
  }

  try {
    loading.value = true
    task.value = await columnParams.value?.getTask(actualRowData.value)
  }
  finally {
    loading.value = false
  }
}

function openTask() {
  store.dispatch('triggerEntityCreate', {
    entityType: 'task',
    entityCreateParams: {
      taskId: task.value?.id
    },
  })
}

onMounted(() => {
  tryInitTask()
})
</script>
