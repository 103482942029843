<template>
  <img
    v-if="isImage"
    :src="src"
    :alt="file.name"
    class="rounded-md"
    :style="embed ? 'max-height: 80vh; width: auto; height: auto; margin: auto;' : ''"
    :class="[previewClass,
      { 'py-4': !thumbnail }
    ]"
  >
  <iframe
    v-else-if="embed && isPreviewable"
    :src="src"
    :title="file.name"
    class="w-full h-full"
    allowfullscreen
    allow="autoplay"
  />
  <iframe
    v-else-if="embed && isDocPreviewable"
    :src="officePreviewUrl"
    class="w-full h-full"
    allowfullscreen
  />
  <div
    v-else
    class="icon-preview max-h-[120px]"
    :class="[icon, previewClass, embed ? 'my-5' : '']"
  />
</template>
<script>
// Helpers
import * as FileUtils from "@/modules/common/utils/fileUtils";

export default {
  name: 'BaseFilePreview',
  props: {
    file: {
      type: [File, Object],
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    embed: {
      type: Boolean,
      default: false
    },
    cleanup: {
      type: Boolean,
      default: true
    },
    thumbnail: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isImage() {
      return FileUtils.isImage(this.file)
    },
    isPreviewable() {
      return FileUtils.isPreviewable(this.file)
    },
    isDocPreviewable() {
      return FileUtils.isDoc(this.file)
    },
    officePreviewUrl() {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${this.src}`
    },
    icon() {
      return FileUtils.getFileIcon(this.file)
    },
    src() {
      return FileUtils.createFileURL(this.file, this.thumbnail)
    },
    previewClass() {
      return {
        'my-auto': true,
        'w-5 h-5': this.size === 'sm',
        'w-8 h-8': this.size === 'md',
        'w-10 h-10': this.size === 'lg',
        'w-full h-full': this.size === 'full',
        'mx-auto': this.embed
      }
    }
  },
  unmounted() {
    if (this.cleanup) {
      FileUtils.revokeFileURL(this.file);
    }
  }
}
</script>
