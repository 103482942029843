<template>
  <BaseSelect
    :modelValue="currentNodeName"
    :options="convertOptions"
    :append-to-body="false"
    value-key="title"
    filterable
    class="convert-select"
    popper-class="convert-select-dropdown"
    @visible-change="onConvertMenuChange"
    @update:modelValue="onConvertCommand"
  >
    <template #prefix>
      <slot name="prefix" :node-name="currentNodeName"></slot>
    </template>
    <template #default="{ option, index }">
      <CommandItem
        :item="option"
        :index="index"
        :selected-index="option.title === currentNodeName ? index : -1"
        size="sm"
      />
    </template>
  </BaseSelect>
</template>
<script>
import { getConvertOptions } from "@/components/html/suggestion";
export default {
  props: {
    editor: {
      type: Object,
      required: true,
    }
  },
  computed: {
    convertOptions() {
      return getConvertOptions(this.editor)
    },
    currentNodeName() {
      let nodeType = 'Convert'
      if (!this.editor) {
        return nodeType
      }
      const options = this.convertOptions
      options.forEach(option => {
        if (option.isActive(this.editor)) {
          nodeType = option.title
        }
      })
      return nodeType
    }
  },
  methods: {
    prepareEditor() {
      return this.editor.chain().focus()
    },
    focusSelection() {
      this.prepareEditor().run()
    },
    onConvertMenuChange() {
      this.focusSelection()
    },
    onConvertCommand(option) {
      this.focusSelection()

      const range = this.editor?.state?.selection?.ranges[0]
      let src = null
      if (this.isLinkActive) {
        src = this.editor.getAttributes('link')?.href
      }
      const command = option?.command

      if (!command) {
        return
      }

      command({
        editor: this.editor,
        range,
        src
      })
    }
  }
}
</script>
