<template>
  <BaseTooltip
    :content="$t('You can enter time as 1.5 or 1:30 (they both mean 1 hour and 30 minutes).')"
    placement="bottom"
  >
    <input
      v-model="inputModel"
      v-bind="$attrs"
      class="form-input"
      :class="$attrs.class"
      @blur="onBlur"
    />
 </BaseTooltip>
</template>
<script>
// Helpers
import { minutesToFormattedTime, timeStringToMinutes } from "@/modules/common/utils/timeUtils"

export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Date, Number, String],
      default: 0
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputModel: ''
    }
  },
  methods: {
    async onBlur() {
      const newValue = timeStringToMinutes(this.inputModel)

      this.$emit('update:modelValue', newValue)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.inputModel = minutesToFormattedTime(value)
      }
    }
  }
}
</script>
