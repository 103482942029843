import { defineRule, configure } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import i18n from '@/i18n';
import { minutesToFormattedTime } from '@/modules/common/utils/timeUtils';
import embedUrlTypes from "@/modules/resources/utils/embedTypes.json";
import embedCodeTypes from "@/modules/resources/utils/embedCodeTypes.json";

const embedTypes = [...embedUrlTypes, ...embedCodeTypes]

const customEmbedTypeValidation = {
  'airtable': (value) => {
    if (!value) {
      return false
    }
    
    const prefix = 'https://airtable.com/embed/'
    if (value.startsWith(prefix)) {
      return true
    }
    
    const chunks = value.replace('https://', '').replace('http://', '').split('/').filter(x => !!x)

    return chunks[0] === 'airtable.com' && chunks.length === 2
  }
}

function isEmbedUrlValid(embedType, value) {
  const [functionName, compareValuesString] = embedType.url_validation.split('::')
  const compareValues = compareValuesString.split('|')

  for (let compareValue of compareValues) {
    if (value[functionName](compareValue)) {
      return true
    }
  }

  return false
}

export function validateUrlWithProtocol(value) {
  if (!/^https:(?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|\/\/www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|\/\/\www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(value)) {
    return i18n.t('This field must be a valid url with https protocol. e.g. https://example.com')
  }
  return true;
}

export function validateUrl(value) {
  if (!/(^$)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(value)) {
    return i18n.t('This field must be a valid url');
  }
  return true;
}

export function validateEmail(value) {
  if (!value || /(^$)|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
    return true
  }
  return i18n.t('This field must be a valid email');
}

export default {
  install() {
    configure({
      generateMessage: localize({
        en,
      })
    })
    Object.keys(AllRules).forEach(rule => {
      const validationRule = AllRules[rule]
      if (typeof validationRule === 'function') {
        defineRule(rule, validationRule);
      }
    });

    defineRule('email', validateEmail);

    defineRule('hostname', value => {
      if (!value || /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/.test(value)) {
        return true
      }

      return i18n.t('This field must be a valid hostname, e.g. subdomain.example.com or example.com.');
    });

    defineRule('url', validateUrl);

    defineRule('urlWithProtocol', validateUrlWithProtocol);

    defineRule('embedUrl', (value, [ embedType ]) => {
      if (typeof embedType === 'string' && embedType !== 'anything_by_code') {
        embedType = embedTypes.find(x => x.value === embedType)
      }

      const urlValidation = validateUrlWithProtocol(value)
      
      if (urlValidation !== true || !embedType?.url_validation) {
        return urlValidation
      }

      if (!embedType) {
        return true
      }

      let isValid = false
      if (embedType.url_validation === 'custom') {
        isValid = customEmbedTypeValidation[embedType.value](value)
      }
      else {
        isValid = isEmbedUrlValid(embedType, value)
      }


      if (!isValid) {
        return i18n.t(`This doesn't look like the correct ${embedType.label} URL.`)
      }

      return true
    });

    defineRule('min_minutes', (value, [ minValue ]) => {
      if (value >= minValue) {
        return true
      }

      const minValueFormatted = minutesToFormattedTime(minValue)
      return `${i18n.t('The value cannot be smaller than')} ${minValueFormatted}`
    })


    defineRule('max_minutes', (value, [ maxValue ]) => {
      if (value <= maxValue) {
        return true
      }

      const maxValueFormatted = minutesToFormattedTime(maxValue)
      return `${i18n.t('The value cannot be greater than')} ${maxValueFormatted}`
    })

    defineRule('max_rows', (value, [ maxValue, message ]) => {
      const rowCount = value?.split('\n').length || 0
      if (rowCount <= maxValue) {
        return true
      }

      return message || `${i18n.t('The number of rows cannot be greater than')} ${maxValue}.`
    })
  }
}
