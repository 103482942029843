<template>
  <div
    class="w-60 lg:w-80 col-span-1 h-full"
    :class="`col-start-${index + 1}`"
  >
    <div
      class="flex group top-0 z-10"
    >
      <h3
        class="flex-1 my-0 font-bold capitalize truncate"
        :class="{
          'leading-6 text-gray-900': $useNewLayout,
          'text-white': !$useNewLayout,
        }"
      >
        <i
          v-if="option.color"
          class="fa-solid fa-circle mr-2 pt-1"
          aria-hidden="true"
          :style="{ color: option.color }"
        />
        {{ option.label }}
      </h3>
      <KanbanColumnOptions
        entity="task"
        :selectedField="selectedField"
        @add-new-entity="showCreateTask = true"
        @edit-columns="$emit('edit-columns')"
      />
    </div>
    <template v-if="can($actions.CREATE_TASKS)">
      <div
        v-if="insideProjectId"
        class="mt-2"
      >
        <BaseInput
          v-model="newTaskName"
          :placeholder="$t(`Add new task here`)"
          :loading="creatingTask"
          inputClass="border-gray-200"
          @keyup.enter.stop="createTask"
        />
      </div>
      <div
        v-else
        class="flex items-center p-3 col-span-1 w-60 lg:w-80 h-[46px] px-4 py-2 rounded-lg bg-white border border-gray-200 text-sm text-gray-400 cursor-pointer mt-2 group"
        @click="showCreateTask = true"
      >
        <span class="opacity-80 group-hover:opacity-100">
          <i class="fa-solid fa-plus mr-1" aria-hidden="true" />
          {{ $t('Add new task here') }}
        </span>
      </div>
    </template>
    <draggable
      v-model="tasks"
      @update:modelValue="tryUpdateOrder"
      :animation="200"
      :delay="dragDelay"
      ghost-class="ghost-card"
      class="min-h-col pdc-pk-col h-full mt-2"
      :group="`tasks_${groupBy?.value ||''}`"
      item-key="id"
      tag="div"
      :move="checkMove"
      :disabled="!can($actions.EDIT_TASKS)"
      @change="onDragChange"
      @end="onDragEnd"
    >
      <template #item="{ element }">
        <TaskCard
          :task="element"
          class="kanban-card"
          @sync-task="$event => onSyncTask($event)"
          @deleted="onTaskDeleted(element)"
        />
      </template>

      <template #footer>
        <div
          v-if="tasksLoading && !tasks?.length"
          class="space-y-3"
        >
          <EntityLoadingCards
            :params="{
              columns: cardLoadingColumns
            }"
            :count="2"
          />
        </div>
        <BaseButton
          v-else-if="canDisplayMore"
          class="mb-8 mt-6"
          block
          @click="currentPage++"
        >
          {{ $t('Show more...') }}
        </BaseButton>
        <div
          v-else-if="!tasks?.length"
          class="text-center text-gray-400 text-xs"
        >
          {{ $t('No tasks') }}
        </div>
      </template>
    </draggable>
    <TaskDialog
      v-show="showCreateTask"
      v-model="showCreateTask"
      :task="taskTemplate"
      :redirectAfterSave="false"
      @close="showCreateTask = false"
    />
  </div>
</template>
<script>
// Components
import draggable from "vuedraggable/src/vuedraggable"
import TaskCard from "@/modules/tasks/components/TaskCard.vue";
import KanbanColumnOptions from "@/modules/common/components/KanbanColumnOptions.vue"
import EntityLoadingCards from "@/components/table/EntityLoadingCards.vue";

// Helpers
import { columnBuilder } from '@/components/table/tableUtils'
import {
  KANBAN_DRAG_DELAY_MOBILE,
  KANBAN_DRAG_DELAY_ACTIVE_MAX_WIDTH,
  updateKanbanEntity,
  updateKanbanEntititesOrder,
  getColumnOptionValuePath,
  getTaskTemplate
} from "@/modules/common/utils/kanbanUtils";
import { taskDateTypes, visibilityTypes } from '@/modules/tasks/utils/modelUtils'
import { getSetting } from "@/plugins/settingsPlugin";
import { useWindowSize } from '@vueuse/core'
import { get } from "lodash-es"
import { computed } from "vue";

export default {
  components: {
    draggable,
    TaskCard,
    KanbanColumnOptions,
    EntityLoadingCards,
  },
  props: {
    selectedField: {
      type: Object,
      required: true
    },
    option: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    data: {
      type: Array,
      default: () => []
    },
    groupBy: {
      type: Object,
      default: () => ({
        prop: '',
        value: ''
      })
    }
  },
  setup(props) {
    const { width } = useWindowSize()

    const valuePath = computed(() => {
      return getColumnOptionValuePath(props.option)
    })

    return {
      valuePath,
      width,
    }
  },
  data() {
    return {
      tasks: [],
      dragDelay: 0,
      perPage: 10,
      currentPage: 1,
      // Create task
      newTaskName: '',
      creatingTask: false,
      showCreateTask: false,
      sameGroupHovering: false,
      dragEnabled: true,
    }
  },
  computed: {
    taskTemplate() {
      const template = {
        attributes: {
          ...this.option.createParams
        }
      }

      return getTaskTemplate(template, this.groupBy, this.data?.[0])
    },
    insideProjectId() {
      return this.$store.getters.project_id
    },
    filteredData() {
      return this.data.filter(task => {
        return get(task, `attributes.${this.valuePath}`, '') == (this.option.value || '')
      })
    },
    apiFilters() {
      return this.$store.getters['filters/targetApiFilters']('tasks') || []
    },
    displayCount() {
      return this.perPage * this.currentPage
    },
    displayTasks() {
      return this.filteredData.slice(0, this.displayCount)
    },
    canDisplayMore() {
      return this.displayCount < this.filteredData.length
    },
    visibleColumns() {
      let columns = this.$store.getters['tasks/tableColumns'] || []

      columns = columnBuilder.filterDisabledColumns(columns)

      columns = columnBuilder.remapDynamicProperties(columns)

      return columns
    },
    cardLoadingColumns() {
      const ignoredColumns = ['attributes.order', 'attributes.completed', 'relationships.allocations']
      return this.visibleColumns.filter(column => !ignoredColumns.includes(column.prop))
        .map(column => ({
          ...column,
          field: column.prop
        }))
    },
    currentSort() {
      let currentSort = this.apiFilters.find(filter => filter.key === 'sorts')?.value || []

      if (!Array.isArray(currentSort)) {
        currentSort = [currentSort]
      }

      return currentSort
    },
    allowReordering() {
      return this.currentSort?.length === 1 && this.currentSort[0].column === 'order' && this.currentSort[0].order === 'asc'
    },
    newTask() {
      return this.$store.state.tasks.newTask || []
    },
    tasksLoading() {
      return this.$store.state.tasks.tasksLoading
    },
    isTaskClosed() {
      return this.$store.getters['tasks/isTaskClosed']
    }
  },
  methods: {
    async createTask() {
      if (!this.newTaskName) {
        return
      }
      
      let visibility = this.can(this.$actions.CHANGE_TASK_VISIBILITY)
        ? visibilityTypes.CREATORS_ONLY
        : visibilityTypes.CREATORS_AND_COLLABORATORS

      const defaultVisibility = getSetting('default_task_privacy')
      if (this.can(this.$actions.CHANGE_TASK_VISIBILITY) && defaultVisibility) {
        visibility = defaultVisibility
      }

      const data = {
        ...(this.taskTemplate.attributes || {}),
        name: this.newTaskName,
        visibility,
        date_type: taskDateTypes.NO_DATE,
        project_id: this.insideProjectId
      }

      this.creatingTask = true

      try {
        const newTask = await this.$store.dispatch('tasks/createTask', { data })
        this.newTaskName = ''
      }
      catch(err) {
        console.error('Error creating task', err)
        this.$error(this.$t(`Could not create task`))
      }
      finally {
        this.creatingTask = false
      }
    },
    onTaskCreate(newTask) {
      this.showCreateTask = false
      this.tasks = [newTask, ...this.tasks]
      this.tryUpdateOrder()
    },
    setDragDelay(width) {
      if (width < KANBAN_DRAG_DELAY_ACTIVE_MAX_WIDTH) {
        this.dragDelay = KANBAN_DRAG_DELAY_MOBILE
      }
      else {
        this.dragDelay = 0
      }
    },
    checkMove(evt) {
      if (this.selectedField.groupByProp === 'relationships.status.attributes.name') {
        return true
      }

      const draggedElement = evt.draggedContext.element

      if (this.isTaskClosed(draggedElement)) {
        this.dragEnabled = false
        return false
      }

      return true;
    },
    onDragEnd() {
      if (this.dragEnabled) {
        return
      }

      this.$error('Completed tasks cannot be edited')
      this.dragEnabled = true
    },
    async tryUpdateOrder() {
      if (!this.allowReordering) {
        return
      }

      await updateKanbanEntititesOrder('tasks', this.tasks, this.index)
    },
    async onDragChange(evt) {
      if (!evt.added) {
        return
      }

      await updateKanbanEntity(evt.added.element, 'task', this.option, this.selectedField)
    },
    async onSyncTask(updatedTask) {
      this.tasks = this.tasks.map(t => {
        if (t.id?.toString() !== updatedTask.id?.toString()) {
          return t
        }

        return updatedTask
      })
    },
    onTaskDeleted(task) {
      this.tasks = this.tasks.filter(t => t.id?.toString() !== task.id?.toString())
    },
    async syncTasks() {
      await this.$nextTick()
      this.tasks = this.displayTasks
    }
  },
  watch: {
    displayTasks: {
      immediate: true,
      deep: true,
      async handler(tasks) {
        await this.$nextTick()

        if (!this.$route.path.includes('kanban')) {
          return
        }

        this.syncTasks()
      }
    },
    width(value) {
      this.setDragDelay(value)
    },
    newTask(newTask) {
      const colValue = get(this.option.createParams, this.valuePath, '').toString()
      const newTaskValue = get(newTask, `attributes.${this.valuePath}`, '').toString()

      const colValueMatches = newTaskValue ===  colValue
      const taskExists = this.tasks.findIndex(task => task.id === newTask.id) !== -1

      if (!colValueMatches || taskExists) {
        return
      }

      this.onTaskCreate(newTask)
    },
  },
  mounted() {
    this.setDragDelay(this.width)
  }
}
</script>
