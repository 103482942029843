<template>
  <div
    class="relative h-full"
    :class="{
      'max-w-6xl m-auto rounded-lg bg-white': !$useNewLayout,
      'min-h-[calc(100vh-40px)]': $useNewLayout
    }"
  >
    <TaskInlineDetails
      :currentTask="task"
      :loading="taskLoading"
    />
  </div>
</template>
<script setup>
import TaskInlineDetails from '@/modules/tasks/components/TaskInlineDetails.vue'
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const task = computed(() => {
  return store.state.tasks?.currentTask || {}
})

const taskLoading = computed(() => {
  return store.state.tasks.currentTaskLoading
})

const taskId = computed(() => {
  return route.params.id
})

watch(taskId, (value) => {
  if (!value || !route.path.startsWith('/tasks')) {
    store.commit('tasks/setCurrentTask', null)
    return    
  }

  store.dispatch('tasks/getTaskById', { id: value })
}, { immediate: true })
</script>
