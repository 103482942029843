<template>
  <BaseDialogNew
    v-model="model"
    class="w-full max-w-4xl file-preview-dialog"
  >
    <div class="max-w-4xl m-auto align-middle bg-white border border-gray-200 overflow-hidden rounded-lg shadow h-full">
      <div class="flex justify-between w-full border-b border-gray-200 p-6">
        <h3 class="text-2xl font-bold flex my-0">
          <BaseFilePreview
            class="mr-2"
            :file="file.attributes"
            :thumbnail="true"
            size="md"
          />
          {{ file.attributes.name }}
        </h3>
      </div>
      <div ref="el" class="file-preview grid place-items-center w-full h-full">
        <div v-if="isFullscreen" class="fixed bottom-[5px] right-[5px]">
          <BaseTooltip :content="$t('Exit Full Screen')">
            <BaseButton variant="primary" @click="exit" size="xs">
              <i class="fa-regular fa-compress"></i>
            </BaseButton>
          </BaseTooltip>
        </div>
        <BaseFilePreview
          :key="filePreviewKey"
          :file="file.attributes"
          size="full"
          embed
        />
      </div>
    </div>
    <div
      class="col-span-full px-6 py-4 border-t border-gray-200 sticky bottom-0 bg-white w-full flex justify-between rounded-b-lg h-fit mt-auto"
    >
      <div class="flex space-x-2">
        <BaseButton
          v-if="canDelete"
          variant="white"
          hover-variant="danger"
          @click="confirmDelete">
          <i class="fa-regular fa-trash mr-2"></i>
          <span>{{$t('Delete')}}</span>
        </BaseButton>
        <BaseButton
          @click="enter"
          variant="white-secondary"
        >
          <i class="fa-regular fa-expand mr-2"></i>
          <span>{{ $t('Full Screen') }}</span>
        </BaseButton>
        <BaseButton
          @click="downloadFile"
          variant="white-secondary"
        >
          <i class="fa-regular fa-cloud-arrow-down mr-2"></i>
          <span>{{ $t('Download File') }}</span>
        </BaseButton>
      </div>

      <BaseButton
        @click="model = false"
        variant="primary"
      >
        <span>{{ $t('Close Dialog') }}</span>
      </BaseButton>
    </div>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
import * as FileUtils from "@/modules/common/utils/fileUtils";
import { useFullscreen } from '@vueuse/core'
import { computed, PropType, ref, watch } from "vue";
import BaseButton from "@/components/common/BaseButton.vue";
import store from "@/store";
import { $deleteConfirm } from "@/components/common/dialog/modalPlugin";
import i18n from "@/i18n";
import { error } from "@/components/common/NotificationPlugin";
import useCan from "@/modules/common/composables/useCan";
import { useFileActions } from "@/modules/files/composables/useFileActions";
import Media = App.Domains.Media.Models.Media;
import Data = API.Data;


const el = ref<HTMLElement | null>(null)
const { isFullscreen, enter, exit, toggle } = useFullscreen(el)

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  file: {
    type: Object as PropType<Data<Media>>,
    required: true
  }
})

const filePreviewKey = ref(0)
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  }
})

const project = computed(() => {
  return props.file?.relationships?.project
})
const isProjectClosed = computed(() => {
  return store.getters['projects/isProjectClosed'](project.value)
})

const { can, actions } = useCan()
const canDelete = computed(() => {
  return !isProjectClosed.value && can(actions.DELETE_PROJECT_FILES)
})


const { deleteFileFromTable } = useFileActions()
async function confirmDelete() {
  await deleteFileFromTable(props.file)
}

watch(() => model.value, () => {
  filePreviewKey.value++
})

function downloadFile() {
  const url = FileUtils.getFileDownloadUrl(props.file.attributes)
  window.open(url, '_blank')
}
</script>
<style lang="scss">
.file-preview-dialog {
  .file-preview {
    min-height: max(calc(100vh - 250px), 400px);
  }
}
</style>
