<template>
  <BaseCardsView
    :target="EntityTargetTypes.FILES"
    :loading="filesLoading"
    dataGetterPath="files/currentFiles"
    groupedDataGetter="files/groupedFiles"
    columnsGetter="files/tableColumns"
  >
    <template #no-data>
      <NoFiles
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
    </template>
  </BaseCardsView>
</template>
<script lang="ts" setup>
// Components
import NoFiles from "@/modules/files/components/NoFiles.vue";

// Libs
import { computed } from "vue";

// Utils
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'

// Composables
import { useStore } from "vuex";

const store = useStore()

const filesLoading = computed(() => {
  return store.state.files.filesLoading
})
</script>
