<template>
  <BaseSelect
    v-model="model"
    :options="privacyOptions"
    :disabled="$attrs.disabled"
    :return-object="false"
    filterable
    class="project-privacy-select"
  />
</template>
<script>
import { Privacy } from "@/modules/projects/utils/projectHelpers"

export default {
  props: {
    modelValue: {
      type: [String, Object],
      default: '',
    },
  },
  data() {
    return {
      showStatusEditDialog: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
     privacyOptions() {
      return [
        {
          label: this.$t('Account - Visible to all creators & invited collaborators'),
          value: Privacy.Account,
        },
        {
          label: this.$t('Project - Only visible to people who are invited to the project'),
          value: Privacy.Project,
        },
        {
          label: this.$t('Private - Only visible to me'),
          value: Privacy.Private,
        }
      ]
    },
  },
}
</script>
<style lang="scss">
.project-privacy-select.base-select {
  .el-input {
    @apply grid;
  }
  .el-input__wrapper {
    @apply col-span-full overflow-ellipsis overflow-hidden;
  }
}
</style>
