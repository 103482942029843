<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-4xl font-extrabold">
      {{ $t(`Trial custom branding`) }}
    </h2>
      <p class="text-gray-500 leading-7 text-lg">
        {{ $t(`Custom branding lets you add your logo & brand colours to your account. This makes it feel like your own custom built tool.`) }}
      </p>

      <div
        v-if="!canUseCustomBranding"
        class="border border-primary-500 bg-primary-50 p-6 rounded-lg text-gray-500 text-base"
      >
        <div>
          {{ $t(`Would you like to start a free 7-day trial of custom branding? This will let you add your logo & brand colours to your account. After 7 days you will need to upgrade to the Pro plan to keep the custom branding settings. If you don't upgrade to Pro then your account will revert to the Project.co branding automatically.`)  }}
        </div>
        <div class="flex items-center space-x-2 mt-4">
          <button
            type="button"
            class="block rounded-md bg-primary-500 px-3 py-2 text-sm text-white"
            @click="startTrial"
          >
            <LoadingIcon
              v-if="startingTrial"
            />
            <span v-else>{{ $t(`Start 7-day trial`) }}</span>
          </button>
          <router-link
            to="/account-setup-wizard/setup-completed"
            class="rounded-md bg-white px-3 py-2 transition-all text-sm font-semibold text-gray-500 border border-gray-200"
          >
            {{ $t(`Continue without trial`) }}
            <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
          </router-link>
        </div>
      </div>

      <Branding
        ref="brandingForm"
        lightForm
        :hasActions="false"
        isDialogForm
        slim
        silentSync
      />

      <div class="flex space-x-2 items-center mt-4">
        <router-link
          to="/account-setup-wizard/view-types"
          class="rounded-md bg-white px-6 py-4 hover:px-8 transition-all text-sm font-semibold text-gray-500 border border-gray-200"
        >
          <i class="fa-solid fa-arrow-left mr-2" aria-hidden="true"></i>
          {{ $t(`Back`) }}
        </router-link>
        <button
          type="button"
          class="rounded-md body-background px-6 py-4 text-sm font-semibold text-white"
          :class="{
            'opacity-80 cursor-not-allowed': !canUseCustomBranding,
            'transition-all hover:px-8': canUseCustomBranding
          }"
          :disabled="!canUseCustomBranding"
          @click.stop="onSubmit"
        >
          {{ $t(`Next`) }}
          <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, nextTick, computed } from 'vue'
import i18n from '@/i18n'
import { error } from '@/components/common/NotificationPlugin';

import Branding from "@/modules/accounts/pages/branding.vue";
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue";

import { useRouter } from "vue-router";
import useCan from "@/modules/common/composables/useCan"

import {
  TrialFeatureKeys,
  useTrialManager
} from '@/modules/accounts/composables/useTrialManager';

const {
  startFeaturesTrial
} = useTrialManager()

const router = useRouter()
const {
  can,
  actions
} = useCan()

const brandingForm = ref()

async function onSubmit() {
  brandingForm.value?.onSubmit()

  await nextTick()
  router.push(`/account-setup-wizard/setup-completed`)
}

const canUseCustomBranding = computed(() => {
  return can(actions.CUSTOM_BRANDING)
})

const startingTrial = ref(false);

async function startTrial() {
  try {
    startingTrial.value = true
    
    const features = [
      TrialFeatureKeys.CustomBranding,
    ];

    await startFeaturesTrial(features);
  }
  catch (err: any) {
    if (err.handled) {
      return
    }

    error(i18n.t('An error occurred while starting the trial. Please try again later.'));
  }
  finally {
    startingTrial.value = false
  }
}
</script>
