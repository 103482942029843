<template>
  <BaseNoDataSection
    :title="$t('No Starred Pages Found')"
    iconClass="fa-star"
    :canCreate="true"
  >
    <template #can-create-section>
      <div>{{ $t('Star pages to get back to them quickly at any time.') }}</div>
    </template>
  </BaseNoDataSection>
</template>
