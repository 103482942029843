import i18n from "@/i18n"
import store from "@/store/index.js";
import { get, orderBy, set, uniq } from "lodash-es";
import {
  ComparatorTypes,
  comparatorTypesMap,
  doesFilterPassTypesMap,
  FilterTypes,
  TableColumn,
  getDefaultOptionsColumn
} from '@/components/table/tableUtils'

import { getTaskDueDate, taskDateTypes, visibilityTypes } from "@/modules/tasks/utils/modelUtils";
import { customFieldAppliesToOptions } from "@/modules/accounts/utils/modelUtils"
import {
  CREATE_CUSTOM_FIELDS,
  CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS,
  DELETE_TASKS,
  EDIT_TASKS,
  OPEN_TASKS,
} from "@/modules/common/enum/actionsEnum";
import { ColumnTypes, HeaderTypes } from '@/components/table/cells/tableCellComponents'
import { isTaskClosed } from '@/modules/tasks/utils/modelUtils'
import {
  Column,
  EditableCallbackParams,
  GetQuickFilterTextParams,
  ICellRendererParams,
  NewValueParams,
  ValueFormatterParams,
  ValueSetterParams
} from "@ag-grid-community/core";
import { EditableCellTypes } from "@/components/table/cells/tableFrameworkComponents";
import { syncEntityInArray } from "@/modules/common/utils/entityUtils";
import { defaultProjectSelectFilters } from "@/modules/projects/utils/filters.js";
import { error, success } from "@/components/common/NotificationPlugin";
import { nextTick } from "vue";
import { roleRanksEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";
import { EntitySelectAttributes } from "@/modules/common/commonTypes";

type HierarchiesMap = { [key: string | number]: (string | number)[] }

export function getHierarchy(task: any, allTasks: any[], hierarchies: HierarchiesMap) {
  if (!task) {
    return []
  }

  if (!task.parent_id) {
    hierarchies[task.id] = [task.id]
    return hierarchies[task.id]
  }

  const parentTask = allTasks.find(pt => pt.id == task.parent_id)
  const parentPath = getHierarchy(parentTask, allTasks, hierarchies)

  hierarchies[parentTask.id] = parentPath
  hierarchies[task.id] = [...parentPath, task.id]

  return hierarchies[task.id]
}

export function isPropEditable(params: EditableCallbackParams): boolean {
  const row = params.data
  const isTaskClosed = store.getters['tasks/isTaskClosed'](row)

  return !isTaskClosed && store.getters['users/can'](EDIT_TASKS)
}

function isValidCreateModel(data: any) {
  const requiredFields = ['name', 'project_id', 'status_id']

  return !requiredFields.some(field => !data[field])
}

async function tryCreateTask(event: NewValueParams, task: any, data: any) {
  if (!isValidCreateModel(data)) {
    return;
  }

  try {
    task.isSaving = true
    await store.dispatch('tasks/createTask', { data })

    success(i18n.t('Task created successfully'))  

    store.commit('triggerGridDataInputRowReset')
    await nextTick()
    event.api.startEditingCell?.({
      rowIndex: 0,
      colKey: 'attributes.name',
      rowPinned: 'top',
    })
  }
  catch (e) {
    console.error(e)
    error(i18n.t('Could not create task!'))
  }
  finally {
    task.isSaving = false
  }
}

function changeTaskStatus(event: NewValueParams, task: any) {
  const newStatus = event.newValue
  const completedStatus = store.getters['tasks/completedStatus']

  if (newStatus == completedStatus?.id) {
    store.dispatch('tasks/toggleTaskCompleted', { task, setCompleted: true })
    return;
  }

  store.dispatch('tasks/changeTaskStatus', {
    task,
    status_id: event.newValue,
  })
}

export async function onCellValueChanged(event: NewValueParams) {
  const task = event.data
  const data = {
    ...task.attributes
  }

  if (task.id === -1) {
    tryCreateTask(event, task, data)
    return
  }

  if (event.colDef?.field === 'attributes.status_id') {
    changeTaskStatus(event, task)
    return
  }

  const syncPropsLocally = ['attributes.allocated_ids', 'attributes.project_id']

  // Sync props locally to improve UI responsiveness
  if (syncPropsLocally.includes(event.colDef?.field || '')) {
    syncEntityInArray(task, store.state.tasks.allTasks)
  }

  store.dispatch('tasks/editTask', {
    taskId: task.id,
    data,
  })
}

export function getTaskColumns(): { mainColumns: TableColumn[], extraColumns: TableColumn[] } {
  const mainColumns: TableColumn[] = [
    {
      name: i18n.t('Drag & Drop'),
      prop: 'attributes.order',
      sortProp: 'order',
      sortable: () => {
        // @ts-ignore
        return !store.state.route.path.includes('card')
      },
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
        const isInsideProject = !!store.getters.project_id
        const orderA = isInsideProject
          ? nodeA.data?.attributes?.order
          : nodeA.data?.attributes?.global_order

        const orderB = isInsideProject
          ? nodeB.data?.attributes?.order
          : nodeB.data?.attributes?.global_order
        
        return Number(orderA || 0) - Number(orderB || 0)
      },
      group: false,
      showInChooseColumns: false,
      hide: true,
      keepHidden: true,
      enableWidgetSort: false
    },
    // used with autoGroupColumnDef
    {
      name: i18n.t('Task Name'),
      prop: 'attributes.name',
      sortProp: 'name',
      sortable: true,
      isMainColumn: true,
      getQuickFilterText: (params: GetQuickFilterTextParams<any>) => {
        const task = params.data
        const subTasks = getSubtasks(task)

        const filterText = [task?.attributes?.name || '', ...subTasks.map((t: any) => t?.attributes?.name)].join(', ')

        return filterText
      },
      filterBy: {
        prop: 'name',
        type: 'text',
        doesFilterPass(row: any, filterValue: string) {
          const task = row
          const subTasks = getSubtasks(task)

          const filterText = [task?.attributes?.name || '', ...subTasks.map((t: any) => t?.attributes?.name)].join(', ')
          return filterText.toLowerCase().includes(filterValue.toLowerCase())
        }
      },
      required: true,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: ColumnTypes.TaskDescription,
      },
      group: false,
      flex: 1,
      minWidth: 180,
      cardClass: 'font-bold text-gray-900 text-base leading-5',
      extendedHeaderClass: 'pl-10',
      extendedCellClass: 'task-name-cell whitespace-nowrap truncate',
      editable: isPropEditable,
      onCellValueChanged,
    },
    {
      name: i18n.t('Project'),
      prop: 'attributes.project_id',
      sortable: true,
      sortProp: 'project',
      component: ColumnTypes.Project,
      params: {
        entity_type: i18n.t('task')
      },
      relatedProp: 'project',
      showCardLabel: true,
      flex: 1,
      minWidth: 200,
      getQuickFilterText: (params: GetQuickFilterTextParams<any>) => {
        const task = params.data
        
        return task.relationships?.project?.attributes?.name
      },
      comparator: comparatorTypesMap[ComparatorTypes.RelationshipName]('relationships.project.attributes.name'),
      filterBy: {
        prop: 'project_ids',
        component: 'ProjectSelect',
        props: {
          multiple: true
        },
        doesFilterPass: doesFilterPassTypesMap[FilterTypes.RelationshipId]('attributes.project_id'),
      },
      enableRowGroup: true,
      extendedCellClass: 'w-full sm:w-1/2',
      disabled: () => {
        return !!store.getters.project_id
      },
      editable: isPropEditable,
      cellEditor: EditableCellTypes.Relationship,
      cellEditorParams: {
        getSelectAttributes(row: any): EntitySelectAttributes {
          const model: EntitySelectAttributes = {
            url: '/restify/projects',
            labelKey: 'attributes.name',
            valueKey: 'id',
            placeholder: i18n.t('Select project...'),
            urlParams: {
              is_template: get(row, 'relationships.project.attributes.is_template', false),
              filters: defaultProjectSelectFilters(),
            },
            initialValue: get(row, 'relationships.project'),
            clearable: false,
          }

          return model
        },
        isCancelBeforeStart(row: any) {
          if (row.attributes?.parent_id || row.attributes?.subtask_ids?.length) {
            error(`Tasks that have sub tasks or have a parent task can't be moved to another project.`)
            return true
          }

          return false
        }
      },
      valueSetter: (params: ValueSetterParams) => {
        const { relationshipId, relationshipObject } = params.newValue

        if (get(params.data, 'attributes.project_id') == relationshipId) {
          return false
        }
        
        set(params.data, 'attributes.project_id', relationshipId)
        set(params.data, 'relationships.project', relationshipObject)

        return true
      },
      onCellValueChanged,
    },
    {
      name: i18n.t('Date'),
      prop: 'attributes.sortable_date',
      sortable: true,
      sortProp: 'sortable_date',
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
  
        let dateA = Date.parse(getTaskDueDate(nodeA.data) || valueA)
        let dateB = Date.parse(getTaskDueDate(nodeB.data) || valueB)
  
        if (isNaN(dateA)) {
          dateA = 0
        }
  
        if (isNaN(dateB)) {
          dateB = 0
        }
  
        if (dateA < dateB) {
          return -1
        }
  
        if (dateA > dateB) {
          return 1
        }
  
        return 0
      },
      showCardLabel: true,
      group: true,
      filterBy: {
        prop: 'date',
        type: 'date-range',
        format: 'formatDateRangeValue::yyyy-MM-dd',
        displayFormat: 'formatDateRange::dd/MM/yy',
        doesFilterPass(row: any, filterValue: { min: string, max: string }) {
          if (row.attributes.date_type == taskDateTypes.NO_DATE) {
            return false
          }

          if (row.attributes.date_type == taskDateTypes.RECURRING && store.state.isCalendarView) {
            return true
          }
  
          const minDate = Date.parse(filterValue.min)
          const maxDate = Date.parse(filterValue.max)
          const taskDate = Date.parse(row.attributes.sortable_date)
  
          if (row.attributes.date_type == taskDateTypes.DATE_RANGE) {
            const taskStartDate = Date.parse(row.attributes.start_date)
            const taskEndDate = Date.parse(row.attributes.end_date)
  
            return taskStartDate <= maxDate && taskEndDate >= minDate
          }
  
          return taskDate >= minDate && taskDate <= maxDate
        }
      },
      component: ColumnTypes.Date,
      extendedCellClass: 'whitespace-nowrap',
      params: {
        getValue(row: any) {
  
          switch(row.attributes.date_type) {
            case taskDateTypes.NO_DATE:
              return ''
            case taskDateTypes.SINGLE_DATE:
              return row.attributes.date
            case taskDateTypes.DATE_RANGE:
              return {
                start_date: row.attributes.start_date,
                end_date: row.attributes.end_date, 
              }
            case taskDateTypes.RECURRING:
              return {
                recurring: true,
                date: row.attributes.recurrence_date
              }
            case taskDateTypes.DAYS_FROM_PROJECT_START:
              return `F_${i18n.tc('days from project start', parseInt(row.attributes.days_from_project_start) )}`
            case taskDateTypes.WORKING_DAYS_FROM_PROJECT_START:
              return `F_${i18n.tc('working days from project start', parseInt(row.attributes.working_days_from_project_start) )}`
            default:
              return ''
          }
        }
      },
      disabled: () => {
        return store.state.route.path.includes('/calendar') || store.state.route.path.includes('/scheduler')
      },
      editable: isPropEditable,
      cellEditor: EditableCellTypes.TaskDate,
      valueSetter: (params: ValueSetterParams<any>) => {
        const task = params.data

        let hasChanged = false
        for (const prop in params.newValue) {
          if (params.newValue[prop] != task.attributes[prop]) {
            task.attributes[prop] = params.newValue[prop]
            hasChanged = true
          }
        }

        task.attributes = {
          ...task.attributes,
          ...params.newValue
        }

        return hasChanged
      },
      onCellValueChanged,
    },
    {
      name: i18n.t('Status'),
      headerComponent: HeaderTypes.EditStatuses,
      headerComponentParams: {
        target: 'tasks',
        enableSorting: true,
      },
      prop: 'attributes.status_id',
      sortProp: 'status',
      component: ColumnTypes.Status,
      sortable: true,
      comparator: (statusId1: any, statusId2: any) => {
        const statuses = store.getters['tasks/orderedStatuses'] || []
  
        const status1 = statuses.find((status: any) => status.id == statusId1)
        const status2 = statuses.find((status: any) => status.id == statusId2)
  
        return (status1?.attributes?.order || 0) - (status2?.attributes?.order || 0)
      },
      filterBy: {
        prop: 'status_ids',
        type: 'TaskStatusSelect',
        format: 'formatIdsArray',
        displayFormat: 'formatColorOptions',
        props: {
          multiple: true
        },
        doesFilterPass(row: any, filterValue: (string | number)[]) {
          return filterValue.some(id => id == row.attributes.status_id)
        }
      },
      enableRowGroup: true,
      enableWidgetFilter: true,
      relatedProp: 'status',
      editable: isPropEditable,
      cellEditor: EditableCellTypes.TaskStatus,
      onCellValueChanged,
    },
    {
      name: i18n.t('Assigned'),
      prop: 'attributes.allocated_ids',
      sortable: true,
      sortProp: 'allocations',
      comparator:  (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
        const usersA = nodeA.data?.relationships?.allocations || []
        const usersB = nodeB.data?.relationships?.allocations || []

        const usersStringA = orderBy(usersA || [], 'id').map((user: any) => user.attributes.name).join(', ')
        const usersStringB = orderBy(usersB || [], 'id').map((user: any) => user.attributes.name).join(', ')
  
        return usersStringA.localeCompare(usersStringB);
      },
      component: ColumnTypes.UserList,
      params: {
        getUsers(row: any) {
          return row?.relationships?.allocations || []
        },
        canRemoveUsers() {
          return store.getters['users/can'](EDIT_TASKS)
        },
        removeUser(row: any, user: any, params: ICellRendererParams<any>) {
          const oldValue = [...row.attributes.allocated_ids || []]
          row.attributes.allocated_ids = row.attributes.allocated_ids.filter((id: any) => id != user.id)
          row.relationships.allocations = row.relationships.allocations.filter((u: any) => u.id != user.id)
          
          const column = params.column as Column
          onCellValueChanged({
            data: row,
            oldValue,
            newValue: row.attributes.allocated_ids,
            column,
            colDef: column.getColDef(),
            node: params.node,
            api: params.api,
            columnApi: params.columnApi,
            context: params.context,
          })
        }
      },
      cardClass: 'flex items-center',
      filterBy: {
        prop: 'allocated_ids',
        component: 'UserSelect',
        props: {
          multiple: true,
          urlParams: {
            hasAllocation: true
          }
        },
        doesFilterPass(row: any, filterValue: (string | number)[]) {
          if (!row.attributes.allocated_ids?.length) {
            return false
          }
  
          return row.attributes.allocated_ids.some((user_id: any) => filterValue.some(id => id == user_id))
        }
      },
      relatedProp: 'allocations',
      enableRowGroup: true,
      editable: isPropEditable,
      cellEditor: EditableCellTypes.UserList,
      cellEditorParams: {
        onRawChange(row: any, users: any[]) {
          set(row, 'attributes.allocated_ids', users.map((user: any) => user.id))
          set(row, 'relationships.allocations', users)
          if (row.id === -1) {
            return
          }

          // @ts-ignore
          syncEntityInArray(row, store.state.tasks.allTasks)
        },
        isCancelBeforeStart(row: any) {
          if (!row?.attributes?.project_id) {
            error(i18n.t('Please select a project first'))
            return true
          }
    
          return false
        },
        getSelectUrlParams(row: any) {
          const urlParams =  {
            project_id: row?.attributes?.project_id,
            roleGreaterThan: roleRanksEnum.COLLABORATOR,
            allFromProject: true
          }

          if (row?.attributes?.visibility === visibilityTypes.CREATORS_ONLY) {
            urlParams.roleGreaterThan = roleRanksEnum.COLLABORATOR_PLUS
          }

          return urlParams
        }
      },
      onCellValueChanged,
    },
    {
      name: i18n.t('Groups'),
      prop: 'attributes.group_ids',
      relatedProp: 'groups',
      component: ColumnTypes.GroupList,
      sortProp: 'groups',
      sortable: true,
      comparator: (groupsA: any, groupsB: any, nodeA: any, nodeB: any) => {
        const rowA = nodeA?.data || nodeA?.allLeafChildren?.[0]?.data
        const rowB = nodeB?.data || nodeB?.allLeafChildren?.[0]?.data

        groupsA = rowA?.relationships?.groups || []
        groupsB = rowB?.relationships?.groups || []

        const groupsStringA = orderBy(groupsA || [], 'id').map((group: any) => group?.attributes?.name || '').join(', ')
        const groupsStringB = orderBy(groupsB || [], 'id').map((group: any) => group?.attributes?.name || '').join(', ')

        return groupsStringA.localeCompare(groupsStringB);
      },
      params: {
        getGroups(row: any) {
          return row?.relationships?.groups || []
        },
      },
      enableRowGroup: true,
      filterBy: {
        prop: 'group_ids',
        component: 'GroupSelect',
        props: {
          multiple: true,
          urlParams: {
            withTasks: true
          }
        },
        doesFilterPass(row: any, filterValue: (string | number)[]) {
          if (!row.attributes.group_ids?.length) {
            return false
          }
  
          return row.attributes.group_ids.some((group_id: any) => filterValue.some(id => id == group_id))
        }
      },
      hide: true,
      keepHidden: true,
    },
    {
      name: i18n.t('Overdue'),
      prop: 'overdue-tasks',
      showInChooseColumns: false,
      visibleInTable: false,
    },
    {
      name: i18n.t('Parent Task'),
      prop: 'relationships.parentTask.attributes.name',
      filterBy: {
        prop: 'parent_ids',
        component: 'TaskSelect',
        props: {
          multiple: true,
          urlParams: {
            hasChildTasks: true,
          }
        },
        doesFilterPass(row: any, filterValue: (string | number)[]) {
          if (!row.attributes.parent_id) {
            return false
          }
  
          return filterValue.some(id => id == row.attributes.parent_id)
        }
      },
      showInChooseColumns: false,
      hide: true,
      keepHidden: true,
    },
    {
      name: i18n.t('Privacy'),
      group: false,
      showInChooseColumns: false,
      prop: 'attributes.visibility',
      hide: true,
      keepHidden: true,
      filterBy: {
        prop: 'visibility',
        component: 'TaskPrivacySelect',
        displayFormat: 'capitalize',
        doesFilterPass(row: any, filterValue: any) {
          return row.attributes.visibility == filterValue
        }
      }
    },
  ]

  const optionsColumn: TableColumn = {
    ...getDefaultOptionsColumn(),
    disabled: () => {
      if (!store.getters['users/can'](OPEN_TASKS) && !store.getters['users/can'](DELETE_TASKS)) {
        return true
      }
      
      return false
    },
  }

  const addCustomFieldColumn: TableColumn = {
    headerComponent: HeaderTypes.AddCustomField,
    prop: 'add-custom-field',
    headerComponentParams: () => {
      const tooltip = getCustomFieldTooltip()
  
      return {
        defaultAppliesTo: customFieldAppliesToOptions.TASK,
        tooltip
      }
    },
    valueFormatter(params: ValueFormatterParams) {
      return ''
    },
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    class: 'w-1 text-center',
    disabled: () => {
      const canCreateCustomFields = store.getters['users/can'](CREATE_CUSTOM_FIELDS)
        || (store.getters.project_id && store.getters['users/can'](CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS))

      if (!canCreateCustomFields || store.getters['projects/isCurrentProjectClosed']) {
        return true
      }
     
      return false
    },
    showInChooseColumns: false
  }

  const extraColumns = [addCustomFieldColumn, optionsColumn]

  return {
    mainColumns,
    extraColumns
  }
}


function getCustomFieldTooltip() {
  if (!store.getters.project_id) {
    return i18n.t('Add a new custom field for all tasks across the account') 
  }

  if (store.getters['templates/isTemplatePath']) {
    return i18n.t('Add a new custom field for tasks within this template only')    
  }

  return i18n.t('Add a new custom field for tasks within this project only')  
}

export const getExtraFilters = () => {
  const filters = [
    {
      key: 'my-tasks',
      doesFilterPass: (row: any, filterValue: { show: boolean }) => {
        if (filterValue?.show) {
          // @ts-ignore
          return (row.attributes.allocated_ids || []).some((id: number | string) => id == store.state.auth.user.id)
        }

        return true
      },
    },
    {
      key: 'overdue-tasks',
      doesFilterPass: (row: any, filterValue: { show: boolean }) => {
        if (!filterValue?.show) {
          return true
        }

        if (!row?.attributes?.sortable_date || isTaskClosed(row)) {
          return false
        }

        const date = Date.parse(row.attributes.sortable_date)
        const today = Date.parse(new Date().toISOString())

        return date < today
      },
    },
    {
      key: 'date-only',
      doesFilterPass: (row: any, filterValue: { show: boolean }) => {
        if (filterValue?.show) {
          return row?.attributes.date_type !== taskDateTypes.NO_DATE
        }

        return true
      },
    }
  ]


  if (store.getters.project_id) {
    filters.push({
      key: 'completed',
      doesFilterPass: (row: any, filterValue: { show: boolean }) => {
        if (filterValue?.show) {
          return true
        }

        return !isTaskClosed(row)
      }
    })
  }

  return filters
}

export enum completedTasksFilters {
  No = 'no',
  Last90Days = 'last 90 days',
  Last180Days = 'last 180 days',
  LastYear = 'last year',
  AllTime = 'all time',
}

export const completedTasksFilterOptions = [
  {
    label: i18n.t('No'),
    value: completedTasksFilters.No,
  },
  {
    label: i18n.t('Last 90 Days'),
    value: completedTasksFilters.Last90Days,
  },
  {
    label: i18n.t('Last 180 Days'),
    value: completedTasksFilters.Last180Days,
  },
  {
    label: i18n.t('Last Year'),
    value: completedTasksFilters.LastYear,
  },
  {
    label: i18n.t('All Time'),
    value: completedTasksFilters.AllTime,
  },
]

export function getSubtasks(task: any) {
  // @ts-ignore
  const allTasksMap = store.state.tasks.allTasksMap || new Map()
  const subtasks: any[] = []
  const subtask_ids = uniq<number | string>(task.attributes.subtask_ids || [])
  subtask_ids.forEach((id: number | string) => {
    id = Number(id)
    const subtask = allTasksMap.get(id)
    if (subtask) {
      subtasks.push(subtask)
    }
  })

  return subtasks
}

export const taskFields = [
  'id',
  'name',
  'project_id',
  'status_id',
  'date_type',
  'date',
  'start_date',
  'end_date',
  'sortable_date',
  'recurrence',
  'visibility',
  'recurrence_date',
  'allocated_ids',
  'custom_fields',
  'parent_id',
  'is_recurrence_source',
  'days_from_project_start',
  'working_days_from_project_start',
  'order',
  'group_ids',
  // Loaded by default: 'comment_count'
]
