<template>
  <BaseDialogNew
    v-model="visible"
    class="w-full max-w-2xl"
  >
    <BaseEntityForm
      :title="$t('Starred Pages')"
      :isDialogForm="true"
      slim
      width-class="max-w-2xl"
    >
      <template #default>
        <StarredPagesList />
      </template>
      <template #actions>
        <BaseButton
          variant="white"
          @click="visible = false"
        >
          {{ $t('Close') }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialogNew>
</template>
<script lang="ts" setup>
import StarredPagesList from "@/modules/accounts/components/StarredPagesList.vue";
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const visible = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>
