<template>
  <dt>
    <div class="flex w-full items-start justify-between text-left text-gray-900 p-6 pb-0">
      <span class="text-base font-semibold leading-7">
        <i class="fa-light fa-comments-alt align-middle text-xl text-gray-400 group-hover:text-primary-500 mr-2 -mt-1" aria-hidden="true" />
        {{ $t('Contact support') }}
      </span>
    </div>
  </dt>
  <dd class="p-6 pt-0">
    <p class="text-sm text-gray-500">
      {{ $t(`If you can't find the answer in the knowledge base, ask our team.`) }}
    </p>
    <a
      :href="contactLink"
      target="_blank"
      class="text-sm text-gray-400 hover:text-primary-500 underline"
    >
      <i class="fa-regular fa-arrow-up-right-from-square mr-1 mt-2" aria-hidden="true" />
      {{ $t('Contact support') }}
    </a>
  </dd>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { getContactLink } from "@/modules/common/utils/linkUtils";
import { useAuth } from "@/modules/auth/composables/useAuth";

const { currentUser, currentTenant } = useAuth()

const contactLink = computed(() => {
  return getContactLink(currentUser.value, currentTenant.value)
})
</script>
