<template>
  <div v-bind="$attrs">
    <label v-if="label"
           class="mb-2 block text-sm leading-5 text-gray-900 select-none cursor-pointer">
      {{ $t(label) }}
      <span
        v-if="labelInfo"
        class="block text-xs text-gray-400 flex items-center mb-2"
      >
        <info-icon size="1.3x" class="text-black mr-2" />
        {{ labelInfo }}
      </span>
    </label>
    <div
      :class="{
        'bg-gray-200': falseValue,
        'bg-primary-500': trueValue && !disabled,
        'bg-gray-300 cursor-not-allowed focus:shadow-none': disabled
      }"
      class="relative flex shrink-0 h-6 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
      role="checkbox"
      tabindex="0"
      @click="triggerToggle"
      :aria-checked="modelValue"
      @keydown.space.prevent="triggerToggle"
      @blur="$emit('blur', $event)"
    >
      <span
        aria-hidden="true"
        :class="{
          'translate-x-8': trueValue,
          'translate-x-0':  falseValue
        }"
        class="inline-block h-5 w-5 rounded-full bg-white shadow transition ease-in-out duration-200"
      />
    </div>
  </div>
</template>
<script>
import { InfoIcon } from '@zhuowenli/vue-feather-icons'

export default {
  name: 'base-switch',
  components: {
    InfoIcon
  },
  emits: ['update:modelValue', 'blur'],
  props: {
    modelValue: {
      type: [Array, Boolean, String, Number],
      default: false,
    },
    labelInfo: {
      type: String,
      default: '',
    },
    disabled: {
      type: [Boolean, String],
      description: 'Whether switch is disabled',
    },
    label: {
      type: [String],
      default: '',
    },
    valueType: {
      type: [String],
      validator: (value) => {
        let acceptedValues = ['boolean', 'string']
        return acceptedValues.indexOf(value) !== -1
      },
      default: 'boolean',
    },
  },
  computed: {
    falseValue() {
      return this.valueType === 'string' ? this.modelValue === 'false' : !this.modelValue
    },
    trueValue() {
      return this.valueType === 'string' ? this.modelValue === 'true' : this.modelValue
    }
  },
  methods: {
    triggerToggle() {
      if (this.disabled) {
        return
      }

      if (this.valueType === 'boolean') {
        this.$emit('update:modelValue', !this.modelValue)
      } else {
        this.$emit('update:modelValue', this.modelValue.toString() === 'true' ? 'false' : 'true')
      }
    },
  },
}
</script>
