<template>
  <div
    class="bg-white w-full border border-gray-200 md:rounded-lg editor-box-menu"
    :class="{
      'shadow': !$useNewLayout,
    }"
  >
    <div
      v-if="showFormattingControls"
      class="bg-gray-50 px-2 pt-1 rounded-t-lg text-sm text-gray-500 border-b border-gray-200"
    >
      <button
        type="button"
        class="editor-box-btn"
        :class="{'btn-active': isActive(Commands.Bold)}"
        @click="runCommand(Commands.Bold)"
      >
        <i :class="Commands.Bold.icon"></i>
      </button>
      <button
        type="button"
        class="editor-box-btn"
        :class="{'btn-active': isActive(Commands.Italic)}"
        @click="runCommand(Commands.Italic)"
      >
        <i :class="Commands.Italic.icon"></i>
      </button>
      <button
        type="button"
        class="editor-box-btn"
        :class="{'btn-active': isActive(Commands.Underline)}"
        @click="runCommand(Commands.Underline)"
      >
        <i :class="Commands.Underline.icon"></i>
      </button>
      <template v-if="!disabledPlugins.includes('link')">
        <div class="inline border-l border-gray-200 mx-2" />
        <ElPopover
          trigger="click"
          ref="linkPopover"
          :width="300"
          placement="top"
          @show="focusOnLinkInput"
        >
          <template #reference>
            <button
              type="button"
              :class="{'btn-active': isActive(Commands.Link)}"
              class="editor-box-btn">
              <i class="fa-solid fa-link"></i>
            </button>
          </template>
          <BaseInput
            ref="linkInput"
            v-model="model.link"
            :placeholder="$t('Insert link here...')"
            @keydown.enter.stop="onLinkEnter"
          />

        </ElPopover>
        <div class="inline border-l border-gray-200 mx-2" />
      </template>
      <button
        type="button"
        class="editor-box-btn"
        :class="{'btn-active': isActive(Commands.OrderedList)}"
        @click="runCommand(Commands.OrderedList)"
      >
        <i :class="Commands.OrderedList.icon"></i>
      </button>
      <button
        type="button"
        class="editor-box-btn"
        :class="{'btn-active': isActive(Commands.BulletList)}"
        @click="runCommand(Commands.BulletList)"
      >
        <i :class="Commands.BulletList.icon"></i>
      </button>
      <div class="inline border-l border-gray-200 mx-2"></div>
      <button
        type="button"
        class="editor-box-btn"
        :class="{'btn-active': isActive(Commands.CodeBlock)}"
        @click="runCommand(Commands.CodeBlock)"
      >
        <i :class="Commands.CodeBlock.icon"></i>
      </button>
      <div class="inline border-l border-gray-200 mx-2" />
    </div>

    <div class="px-4 sm:py-2 max-h-[30vh] overflow-y-auto">
      <span class="text-gray-400 text-sm">
        <slot />
      </span>
    </div>

    <div
      class="bg-gray-white text-base flex items-center rounded-b-lg text-gray-500 border-t border-gray-200"
      :class="{
        'px-2 py-0': context !== ChatContext.AiChat,
        'p-2': context === ChatContext.AiChat,
      }"
    >
      <BaseTooltip
        :content="$t('Add an emoji')"
      >
        <EmojiPickerPopover
          v-if="!disabledPlugins.includes('emoji')"
          placement="top"
          @change="onEmojiChanged"
        />
      </BaseTooltip>

      <BaseTooltip
        :content="$t('Mention someone')"
      >
        <button
          v-if="!disabledPlugins.includes('mention')"
          type="button"
          class="p-2 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
          @click="onMentionClick"
        >
          <i class="fa-regular fa-at"></i>
        </button>
      </BaseTooltip>

      <template v-if="!disabledPlugins.includes('file-upload')">
        <BaseTooltip
          :content="$t('Upload a file')"
        >
          <label
            v-if="!disabledPlugins.includes('file-upload')"
            :for="uploadId" class="p-2 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
          >
            <BaseFileUpload
              :id="uploadId"
              multiple
              @change="onFilesChange"
            >
              <template #activator>
                <span>
                  <i class="fa-regular fa-arrow-up-from-bracket"></i>
                </span>
              </template>
            </BaseFileUpload>
          </label>
        </BaseTooltip>
        <DropboxFilePickerButton :editor="editor"/>
        <GoogleDriveFilePickerButton :editor="editor"/>
        <OneDriveFilePickerButton
          v-if="false  /* TODO Enable when BE is ready & the issues with one drive are solved on microsoft's side (https://binarcode.atlassian.net/browse/PC-1429)*/"
          :editor="editor"
        />
      </template>

      <BaseTooltip
        :content="$t('Reference something')"
      >
        <button
          v-if="!disabledPlugins.includes('reference') && refferenceButton"
          type="button"
          class="p-2 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
          @click="triggerReferenceCreation({ editor })"
        >
          <i class="fa-regular fa-file-export"></i>
        </button>
      </BaseTooltip>

      <DeepgramSpeechToText
        v-if="isSpeechToTextEnabled"
        ref="speechToText"
        class="flex items-center"
        @text-added="onSpeechToTextAdded"
      />


      <div class="flex-1 flex justify-end items-center space-x-2">
        <div
          v-if="editor && charLimit > 0 && charLimitReached"
          class="text-xs text-gray-500"
        >
          {{ currentCharCount }}/{{ charLimit }} {{ $t('characters') }}
        </div>
        <slot name="footer-right" :save-disabled="saveDisabled">
          <button
            v-loading="isSendingComment"
            type="button"
            :disabled="saveDisabled"
            :class="{'btn-disabled': saveDisabled}"
            class="py-1 px-3 mr-1 rounded-md bg-primary-500 hover:bg-primary-600 text-white cursor-pointer"
            @click="onSend"
          >
            <i class="fa-solid fa-paper-plane-top"></i>
          </button>
        </slot>
      </div>
    </div>

    <slot
      v-if="infoText"
      name="info-text"
    >
      <div class="bg-gray-50 border-t border-gray-200 px-4 py-1 text-xs text-gray-400 rounded-b-lg hidden sm:block">
        {{ infoText }}
      </div>
    </slot>
  </div>
</template>
<script>
import { ElPopover } from 'element-plus'
import { onKeyDown } from '@vueuse/core'
import { Commands } from "@/components/html/util/tipTapUtils.js";
import { filesToUpload, FileUploadStatus, uploadDroppedFiles } from "@/components/html/util/uploadUtils.js";
import EmojiPickerPopover from "@/components/selects/EmojiPickerPopover.vue";
import DeepgramSpeechToText from '@/components/form/DeepgramSpeechToText.vue'
import { triggerReferenceCreation } from '@/components/html/suggestion'
import DropboxFilePickerButton from "@/components/html/extensions/components/DropboxFilePickerButton.vue";
import GoogleDriveFilePickerButton from "@/components/html/extensions/components/GoogleDriveFilePickerButton.vue";
import OneDriveFilePickerButton from "@/components/html/extensions/components/OneDriveFilePickerButton.vue";
import { ChatContext } from "@/modules/common/commonTypes.d";
import { inject } from 'vue'

export default {
  components: {
    OneDriveFilePickerButton,
    DropboxFilePickerButton,
    GoogleDriveFilePickerButton,
    EmojiPickerPopover,
    ElPopover,
    DeepgramSpeechToText,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    charLimit: {
      type: Number,
      default: -1,
    },
    infoText: {
      type: String,
      default: ''
    },
    isSendingComment: {
      type: Boolean,
      default: false
    },
    refferenceButton: {
      type: Boolean,
      default: true,
    },
    disabledPlugins: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showFormattingControls: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const context = inject('context', ChatContext.Discussion)

    return {
      context,
      ChatContext,
    }
  },
  data() {
    return {
      Commands,
      model: {
        link: '',
      },
      uploadId: `upload-${new Date().getTime()}`
    }
  },
  computed: {
    commands() {
      return [
        Commands.Bold,
        Commands.Italic,
        Commands.Underline,
        Commands.OrderedList,
        Commands.BulletList,
        Commands.CodeBlock,
      ]
    },
    currentCharCount() {
      return this.editor?.storage?.characterCount?.characters() || 0
    },
    charLimitReached() {
      return this.currentCharCount === this.charLimit
    },
    isUploading() {
      return Object.values(filesToUpload).some(file => file.status === FileUploadStatus.Uploading)
    },
    isEmptyText() {
      let html = this.editor?.getHTML() || ''
      html = html.replaceAll('<p></p>', '')
      return html === ''
    },
    saveDisabled() {
      return this.isEmptyText || this.isUploading || this.isSendingComment || this.readonly
    },
    isSpeechToTextEnabled() {
      return this.can(this.$actions.USE_SPEECH_TO_TEXT) && !this.disabledPlugins.includes('speech-to-text')
    },
  },
  methods: {
    triggerReferenceCreation,
    prepareEditor() {
      return this.editor.chain().focus()
    },
    runCommand(command) {
      const editor = this.prepareEditor()
      editor[command.action]?.call()?.run()
    },
    isActive(command) {
      return this.editor.isActive(command.name)
    },
    onSend(event) {
      event?.stopImmediatePropagation()
      if (this.isEmptyText) {
        this.$error(this.$t(`Cannot send an empty message`))
        return
      }
      if (this.isUploading) {
        this.$error(this.$t(`Please wait until the files are uploaded before sending a message`))
        return
      }
      if (this.isSpeechToTextEnabled) {
        this.$refs.speechToText?.disconnect()
      }

      this.$emit('send', this.editor.getHTML())
    },
    onFilesChange(event) {
      uploadDroppedFiles({
        event,
        editor: this.editor,
        view: this.editor.view,
        fullScale: false,
      })
    },
    onEmojiChanged(emoji) {
      const shortCode = emoji?.emoji?.shortcodes[0] || ''
      this.prepareEditor().setEmoji(shortCode).run()
    },
    onMentionClick() {
      this.prepareEditor().insertContent('@').run()
    },
    onSpeechToTextAdded(text) {
      if (this.readonly) {
        return
      }

      this.prepareEditor().insertContent(text).run()
    },
    async onLinkEnter() {
      let href = this.model.link
      const editor = this.prepareEditor()

      if (href && !href.startsWith('http')) {
        this.model.link = `https://${href}`
        href = this.model.link
      }

      if (href) {
        editor.setLink({ href }).run()
      } else {
        editor.unsetLink().run()
      }
      this.$refs?.linkPopover?.hide();
    },
    async focusOnLinkInput() {
      await this.$nextTick();
      this.$refs?.linkInput?.focus();
    },
    setInputLink() {
      const isLinkActive = this.isActive(Commands.Link)
      if (isLinkActive) {
        const link = this.editor.getAttributes('link')?.href
        this.model.link = link
      } else if (!isLinkActive && this.model.link) {
        this.model.link = ''
      }
    },
    initEvents() {
      onKeyDown('Enter', async (event) => {
        if (!this.editor.isFocused) {
          return
        }
        if (event.metaKey || event.ctrlKey) {
          this.onSend(event)
        }
      })
      this.editor.on('selectionUpdate', () => {
        this.setInputLink()
      })
    }
  },
  mounted() {
    this.initEvents()
  }
}
</script>
<style lang="scss" scoped>
.editor-box-btn {
  @apply px-2 py-1 my-1 hover:text-gray-700 cursor-pointer
}

.btn-active {
  @apply rounded-md bg-gray-200;
}
</style>
