import store from "@/store";
import { getSetting } from "@/plugins/settingsPlugin";
import { Privacy } from "@/modules/projects/utils/projectHelpers";
import { visibilityTypes } from '@/modules/tasks/utils/modelUtils';

import { coreTools, toolsEnum } from '@/modules/projects/utils/toolUtils.js'

import Data = API.Data
import ProjectStatus = App.Domains.Statuses.Models.ProjectStatus
import Tool = App.Domains.Tools.Models.Tool
import TaskStatus = App.Domains.TaskStatuses.Models.TaskStatus
import User = App.Domains.Users.Models.User
import Group = App.Domains.Groups.Models.Group
import Project = App.Domains.Projects.Models.Project

type CurrentUser = User & {
  groups: Data<Group>[]
}

function mapStatus(status: Data<ProjectStatus> | Data<TaskStatus>) {
  return {
    id: status.id,
    name: status.attributes.name,
  }
}

function mapGroup(group: Data<Group>) {
  return {
    id: group.id,
    name: group.attributes.name,
  }
}

function mapCurrentUser(user: CurrentUser) {
  return  {
    id: user.id,
    name: user.name,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    status: user.status,
    groups: user.groups.map(mapGroup)
  }
}

function mapTools(tool: Data<Tool>) {
  return {
    id: tool.id,
    name: tool.attributes.name,
  }
}

function mapProject(project: Data<Project>) {
  if (!project) {
    return null
  }

  return {
    id: project.id,
    name: project.attributes.name,
  }
}

function preprocessTools(tools: Data<Tool>[]): Data<Tool>[] {
  const excludedTools = [
    toolsEnum.PEOPLE,
    toolsEnum.LINK,
    toolsEnum.REFERENCE,
    toolsEnum.NOTE,
    toolsEnum.NOTES,
  ]

  return tools.filter((tool) => !excludedTools.includes(tool.attributes.name)).map(tool => {
    const name = coreTools.find(coreTool => coreTool.value === tool.attributes.name)?.name || tool.attributes.name
    return {
      ...tool,
      attributes: {
        ...tool.attributes,
        name,
      }
    }
  })
}

export function getOrganizationConfig() {
  // @ts-ignore
  const currentUser = store.state.auth.user;
  // Projects
  // @ts-ignore
  const currentProject = store.state.projects.currentProject;
  // @ts-ignore
  const tools = store.state.projects.tools.data;
  const projectStatuses = store.getters['projects/orderedStatuses'];
  const defaultProjectStatus = store.getters['projects/defaultProjectStatus'];
  const defaultProjectPrivacy = getSetting('default_project_privacy') || Privacy.Account;
  // Tasks
  const taskStatuses = store.getters['tasks/orderedStatuses'];
  const defaultTaskStatus = store.getters['tasks/defaultTaskStatus'];
  const defaultTaskVisibility = getSetting('default_task_privacy') || visibilityTypes.CREATORS_ONLY;

  const config = {
    currentUser: mapCurrentUser(currentUser),
    // Projects
    currentProject: mapProject(currentProject),
    tools: preprocessTools(tools).map(mapTools),
    projectStatuses: projectStatuses.map(mapStatus),
    defaultProjectStatus: mapStatus(defaultProjectStatus),
    defaultProjectPrivacy,
    // Tasks
    taskStatuses: taskStatuses.map(mapStatus),
    defaultTaskStatus: mapStatus(defaultTaskStatus),
    defaultTaskVisibility,
    today: new Date().toISOString().substring(0, 10)
  }
  
  return JSON.stringify(config)
}
