const url = "https://check.docproof.co/index.html";
const origin = new URL(url).origin

export function cookieTest() {
  return new Promise((resolve, reject) => {
    const messageHandler = (event) => {
      // check for trusted origins here
      try {
        if (event.origin !== origin) {
          return;
        }
        const data = JSON.parse(event.data);
        resolve(data["result"]);
        window.removeEventListener("message", messageHandler);
        document.body.removeChild(frame);
      } catch (err) {
        reject(err)
      }
    };

    window.addEventListener("message", messageHandler);
    const frame = document.createElement("iframe");
    frame.src = url;
    frame.sandbox = "allow-scripts allow-same-origin";
    frame.style = `display:none`;
    frame.onload = () => {
      frame.contentWindow.postMessage(
        JSON.stringify({ test: "cookie" }),
        "*"
      );
    };
    document.body.appendChild(frame);
  })
}
