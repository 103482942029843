<template>
  <ProfileForm />
</template>
<script>

import ProfileForm from "@/modules/accounts/components/ProfileForm.vue";

export default {
  components: {
    ProfileForm,
  }
}
</script>
