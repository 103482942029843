<template>
  <SettingsForm
    ref="settingsForm"
    :fields="fields"
    :title="lightForm ? '' : $t('Manage account settings')"
    :description="lightForm ? '' : $t(`These are your basic account settings. Enter the relevant details to set up your account.`)"
  >
    <template #default="{ model }">
      <BaseInput
        v-if="fields.includes('account_name')"
        v-model="model.account_name"
        v-focus
        :label="$t('Account Name')"
        :placeholder="$t('Account Name')"
        :name="$t('Account Name')"
        class="col-span-6 shadow-none"
        :layout="inputLayout"
        rules="required|max:50"
        id="name"
        :info-text="$t(`When Custom Branding is active this will be used for page titles instead of 'Project.co'.`)"
      />


      <BaseInput
        v-if="fields.includes('default_project_status')"
        :label="$t('Default project status')"
        :name="$t('Default project status')"
        :info-text="$t('This will determine what project status new projects have.')"
        class="col-span-6 shadow-none"
        :layout="inputLayout"
      >
        <ProjectStatusSelect
          v-model="model.default_project_status"
          :placeholder="$t('Default project status')"
          :filterable="true"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('default_project_privacy')"
        :label="$t('Default project privacy')"
        :name="$t('Default project privacy')"
        :info-text="$t('This will determine what privacy new projects have.')"
        class="col-span-6 shadow-none"
        :layout="inputLayout"
      >
        <ProjectPrivacySelect
          v-model="model.default_project_privacy"
          :placeholder="$t('Default project privacy')"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('default_task_status')"
        :label="$t('Default task status')"
        :name="$t('Default task status')"
        :info-text="$t('This will determine what task status new tasks have.')"
        class="col-span-6 shadow-none"
        :layout="inputLayout"
      >
        <TaskStatusSelect
          v-model="model.default_task_status"
          :placeholder="$t('Default task status')"
          :filterable="true"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('default_task_privacy')"
        :label="$t('Default task privacy')"
        :name="$t('Default task privacy')"
        :info-text="$t('This will determine what privacy new tasks have.')"
        class="col-span-6 shadow-none"
        :layout="inputLayout"
      >
        <TaskPrivacySelect
          v-model="model.default_task_privacy"
          :placeholder="$t('Default task privacy')"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('default_note_privacy')"
        :label="$t('Default note privacy')"
        :name="$t('Default note privacy')"
        :info-text="$t('This will determine what privacy new notes have.')"
        class="col-span-6 shadow-none"
        :layout="inputLayout"
      >
        <NotePrivacySelect
          v-model="model.default_note_privacy"
          :placeholder="$t('Default note privacy')"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('default_payment_currency')"
        :label="$t('Default currency')"
        :name="$t('Default currency')"
        :info-text="$t('This will determine what currency new payments have.')"
        class="col-span-6"
        :layout="inputLayout"
      >
        <CurrencySelect
          v-model="model.default_payment_currency"
          :placeholder="$t('Default payment currency')"
          :return-object="false"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('default_payment_auto_tax')"
        :label="$t('Default auto tax')"
        :name="$t('Default auto tax')"
        :info-text="$t('This will determine auto tax for new recurring payments.')"
        class="col-span-6"
        :layout="inputLayout"
      >
        <BaseSwitch
          v-model="model.default_payment_auto_tax"
        />
      </BaseInput>


      <BaseInput
        v-if="fields.includes('show_payments_card_to_collaborators')"
        :label="$useNewLayout
          ? $t('Payments item')
          : $t('Payments card')
        "
        :name="$useNewLayout
          ? $t('Payments item')
          : $t('Payments card')
        "
        :info-text="
          $useNewLayout
          ? $t(`This will determine if the payments item can be seen by Collaborators in their sidebar. If this is OFF then they will still be able to see payments within projects they have access to but they won't be able to access the global payments page to see all their payments in one place. Heads up: The Templates & Time sidebar items are always hidden from Collaborators.`)
          : $t('This will determine if the payments card can be seen by Collaborators on the welcome dashboard. If this is OFF then they will still be able to see payments within projects they have access to.')
        "
        class="col-span-6"
        :layout="inputLayout"
      >
        <BaseSwitch
          v-model="model.show_payments_card_to_collaborators"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('unsplash_collection_id')"
        v-model="model.unsplash_collection_id"
        :label="$t('Unsplash Collection ID')"
        :name="$t('Unsplash Collection ID')"
        :placeholder="$t('Unsplash Collection ID')"
        :info-text="$t(`Enter the ID of an Unsplash collection. This collection will be used for the auto project and cover images.://unsplash.com/collections/1234567/my-collection, your collection id is '1234567'.`)"
        class="col-span-6"
        :layout="inputLayout"
      />

      <BaseInput
        v-if="fields.includes('auto_project_image')"
        :label="$t('Auto project image')"
        :name="$t('Auto project image')"
        :info-text="$t('When turned on, it will automatically add a project image to each project upon creation.')"
        class="col-span-6"
        :layout="inputLayout"
      >
        <BaseSwitch
          v-model="model.auto_project_image"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('auto_cover_image')"
        :label="$t('Auto cover image')"
        :name="$t('Auto cover image')"
        :info-text="$t('When turned on, it will automatically add a project cover image to each project upon creation.')"
        class="col-span-6"
        :layout="inputLayout"
      >
        <BaseSwitch
          v-model="model.auto_cover_image"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('show_card_image')"
        :label="$t('Card cover images')"
        :placeholder="$t('Card cover images')"
        :name="$t('Card cover images')"
        :info-text="$t('Allow images to be used as task card covers on the board. The first uploaded image will be used as the cover.')"
        class="col-span-6"
        :layout="inputLayout"
      >
        <BaseSwitch
          v-model="model.show_card_image"
        />
      </BaseInput>

      <BaseInput
        v-if="fields.includes('show_task_checkbox')"
        :label="$t('Task checkbox')"
        :placeholder="$t('Task checkbox')"
        :name="$t('Task checkbox')"
        :info-text="$t('Display a checkbox in task lists to quickly mark them as complete or reopen them.')"
        class="col-span-6"
        :layout="inputLayout"
      >
        <BaseSwitch
          v-model="model.show_task_checkbox"
        />
      </BaseInput>

      <DefaultViewSettings
        v-if="!lightForm"
        :key="$i18n.locale"
        level="account"
        class="col-span-6"
        @user-views-changed="setDefaultViews($event, model)"
      />

      <div
        v-if="!lightForm"
        class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start col-span-full border-t pt-6"
      >
        <label class="block text-sm font-medium text-gray-700">
          {{ $t('Request deletion') }}
        </label>
        <div class="sm:mt-px sm:pt-1 sm:col-span-3">
          <p class="text-sm text-gray-500">
            {{
              $t(`Request for us to delete your account and all data associated with it from our database. Click the button below to request this.`)
            }}
          </p>

          <BaseButton
            :href="CONTACT_LINK"
            tag="a"
            target="_blank"
            rel="noopener"
            variant="danger"
            class="mt-2"
          >
            {{ $t('Request Deletion') }}
          </BaseButton>
        </div>
      </div>
    </template>
    <template
      v-if="!lightForm"
      #extra-actions
    >
      <ManagePlanButton class="mr-2" />
    </template>
  </SettingsForm>
</template>
<script>
import SettingsForm from "@/modules/accounts/components/SettingsForm.vue";
import CustomBrandingForm from "@/modules/accounts/components/CustomBrandingForm.vue";
import SubscriptionDetails from "@/modules/accounts/components/SubscriptionDetails.vue";
import ProjectStatusSelect from "@/modules/projects/components/ProjectStatusSelect.vue";
import ProjectPrivacySelect from "@/modules/projects/components/ProjectPrivacySelect.vue";
import TaskPrivacySelect from "@/modules/tasks/components/TaskPrivacySelect.vue";
import TaskStatusSelect from "@/modules/tasks/components/TaskStatusSelect.vue";
import NotePrivacySelect from "@/modules/projects/components/projectNotes/NotePrivacySelect.vue";
import CurrencySelect from "@/components/selects/CurrencySelect.vue";
import DefaultViewSettings from "@/modules/accounts/components/DefaultViewSettings.vue";

// Utils
import { CONTACT_LINK } from "@/modules/common/utils/linkUtils"
import ManagePlanButton from "@/modules/accounts/components/ManagePlanButton.vue";

const defaultFields = [
  'account_name',
  'show_card_image',
  'default_project_status',
  'default_project_privacy',
  'default_task_status',
  'default_task_privacy',
  'default_note_privacy',
  'default_payment_currency',
  'default_payment_auto_tax',
  'show_payments_card_to_collaborators',
  'unsplash_collection_id',
  'default_view',
  'auto_project_image',
  'auto_cover_image',
  'show_task_checkbox'
]

export default {
  components: {
    ManagePlanButton,
    DefaultViewSettings,
    CurrencySelect,
    NotePrivacySelect,
    TaskStatusSelect,
    TaskPrivacySelect,
    ProjectPrivacySelect,
    ProjectStatusSelect,
    SettingsForm,
    CustomBrandingForm,
    SubscriptionDetails,
  },
  props: {
    customFields: {
      type: Object,
      default: () => []
    },
    lightForm: {
      type: Boolean,
      default: false
    },
    inputLayout: {
      type: String,
      default: 'horizontal'
    }
  },
  data() {
    return {
      CONTACT_LINK,
      default_project_status: 'active',
    }
  },
  computed: {
    fields() {
      if (this.customFields?.length) {
        return this.customFields
      }

      return defaultFields
    }
  },
  methods: {
    setDefaultViews(views, model) {
      model.default_view = JSON.stringify(views)
    },
    async onSubmit() {
      await this.$refs.settingsForm?.onSubmit()
    }
  },
  async created() {
    await this.$store.dispatch('auth/getTenantDetails')
    await this.$store.dispatch("accounts/getPlans")
  },
  beforeRouteLeave() {
    this.$refs.settingsForm?.resetModel()
  }
}
</script>
