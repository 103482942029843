<template>
  <BaseCardsView
    :target="target"
    :loading="projectsLoading"
    dataGetterPath="projects/projectsOrTemplatesData"
    :groupedDataGetter="groupedDataGetter"
    columnsGetter="projects/tableColumns"
    :ignoredColumns="ignoredColumns"
    :extraFilters="extraFilters"
  >
    <template #no-data>
      <NoTemplates
        v-if="isTemplatePath"
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
      <NoProjects
        v-else
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
    </template>
  </BaseCardsView>
</template>
<script lang="ts" setup>
// Components
import NoProjects from "@/modules/projects/components/NoProjects.vue";
import NoTemplates from "@/modules/templates/components/NoTemplates.vue"

// Libs
import { computed } from "vue";

// Utils
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'
import { extraFilters } from '@/modules/projects/utils/projectTableUtils'

// Composables
import { useStore } from "vuex";

const store = useStore()

const projectsLoading = computed(() => {
  return store.state.projects.projectsLoading
})

const isTemplatePath = computed(() => {
  return store.getters['templates/isTemplatePath']
})

const groupedDataGetter = computed(() => {
  if (isTemplatePath.value) {
    return 'templates/groupedTemplates'
  }

  return 'projects/groupedProjects'
})

const target = computed(() => {
  if (isTemplatePath.value) {
    return EntityTargetTypes.TEMPLATES
  }

  return EntityTargetTypes.PROJECTS
})

const ignoredColumns = ['attributes.order', 'attributes.completed', 'attributes.visibility']
</script>
