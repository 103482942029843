<template>
  <div class="items">
    <button
      class="item"
      :class="{ 'is-selected': index === selectedIndex }"
      v-for="(item, index) in items"
      :key="index"
      @click="selectItem(index)"
    >
      <img 
        v-if="item.fallbackImage"
        :src="item.fallbackImage"
        align="absmiddle"
      >
      
      <template v-else>
        {{ item.emoji }}
      </template>
      <span>:{{ item.name }}:</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },

    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ name: item.name })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.items {
  @apply p-0.5 relative shadow overflow-hidden bg-white;
}

.item {
  @apply flex items-center hover:bg-gray-100 py-1 w-full space-x-2 px-2 rounded space-x-1;

  &.is-selected {
    @apply bg-gray-100;
  }

  img {
    @apply w-5 h-5;
  }
}
</style>
