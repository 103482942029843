import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import ExternalFileTemplate from "@/components/html/extensions/components/ExternalFileTemplate.vue";

export default Node.create({
    name: 'externalFile',

    group: 'inline',

    inline: true,

    selectable: true,

    atom: true,

    draggable: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            name: {
                default: null,
            },
            url: {
                default: null,
            },
            icon: {
                default: null,
            },
            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            inline: {
                default: false,
            },
        }
    },

    parseHTML() {
        return [{
            tag: 'external-file',
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return ['external-file', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(ExternalFileTemplate);
    },
    addCommands() {
        return {
            setExternalFile: (options) => ({ tr, dispatch }) => {
                const { selection } = tr
                const node = this.type.create(options)

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return true
            },
            toggleExternalFile: () => ({ commands }) => {
                return commands.toggleWrap(this.name)
            },
            unsetExternalFile: () => ({ commands }) => {
                return commands.lift(this.name)
            },
        }
    }
});
