<template>
  <SettingsForm
    ref="settingsForm"
    :fields="[
      'custom_email_address',
      'custom_sender_name'
    ]"
    :title="$t('Custom Emails')"
    :description="$t(`Set up emails to come from your address.`)"
    tutorial="custom-emails"
    :onSave="getPostmarkDomains"
    :disabled="!can($actions.CUSTOM_EMAIL)"
  >
    <template #default="{ model }">
      <UpgradePlanWarning
        v-if="!can($actions.CUSTOM_EMAIL)"
        :message="$t(' to set up custom emails.')"
      />

      <BaseInput
        v-model="model.custom_sender_name"
        v-focus
        :label="$t('Custom Sender Name')"
        :placeholder="$t('Enter Custom Sender Name...')"
        :name="$t('Custom Sender Name')"
        :disabled="!can($actions.CUSTOM_BRANDING) || isDomainConfigured"
        class="col-span-6"
        layout="horizontal"
        id="custom_sender_name"
      />

      <BaseInput
        v-model="model.custom_email_address"
        :label="$t('Custom Email Address')"
        :placeholder="$t('Enter Custom Email Address...')"
        :name="$t('Custom Email Address')"
        :disabled="!can($actions.CUSTOM_BRANDING) || isDomainConfigured"
        class="col-span-6"
        layout="horizontal"
        rules="email"
        id="custom_email_address"
      />

      <template
        v-if="isDomainConfigured"
      >
        <div class="border-t border-gray-200 pt-6">
          <p class="text-gray-500 text-xs text-center">
            {{ $t('Head over to your DNS provider and add DKIM and Return-Path DNS records to verify your domain and ensure effective delivery.') }}
          </p>
          <div class="w-full mt-6 border-b border-gray-200">
            <BaseTable
              :columns="tableColumns"
              :data="tableData"
            >
              <template #entry_name="{ row }">
                <div class="whitespace-nowrap">
                  <i
                    class="fa mr-2"
                    :class="{
                      'text-green-500 fa-circle-check': row.verified,
                      'text-gray-300 fa-circle-xmark': !row.verified,
                    }"
                  />
                  <span class="font-bold text-gray-700">{{ row.entry_name }}</span>
                  <span class="sm:block uppercase text-xs flex-1">
                    ({{ row.verified ? $t('Active') : $t('Inactive') }})
                  </span>
                </div>
              </template>
              <template #hostname="{ row }">
                <div
                  class="break-all cursor-pointer mt-2 sm:mt-0"
                  @click="$copyToClipboard(row.hostname)"
                >

                  <label class="sm:hidden">{{ $t('Hostname (click to copy)') }}</label>
                  <BaseTooltip
                    :content="$t('Click to Copy')"
                  >
                    <span class="block font-bold text-gray-700">{{ row.hostname }}</span>
                  </BaseTooltip>
                </div>
              </template>
              <template #type="{ row }">
                <div class="mt-2 sm:mt-0">
                  <label class="sm:hidden">{{ $t('Type ') }}</label>
                  <span class="block font-bold text-gray-700"> {{ row.type }}</span>
                </div>
              </template>
              <template #value="{ row }">
                <div
                  class="break-all cursor-pointer mt-2 sm:mt-0"
                  @click="$copyToClipboard(row.value)"
                >
                  <label class="sm:hidden">{{ $t('Value (click to copy)') }}</label>
                  <BaseTooltip
                    :content="$t('Click to Copy')"
                  >
                    <span class="block font-bold text-gray-700">{{ row.value }}</span>
                  </BaseTooltip>
                </div>
              </template>
            </BaseTable>
          </div>
        </div>
      </template>
    </template>
    <template
      v-if="isDomainConfigured"
      #actions
    >
      <div class="flex space-x-2">
        <BaseButton
          variant="white"
          @click="verifyDkim"
        >
          {{ $t('Verify DKIM') }}
        </BaseButton>

        <BaseButton
          variant="white"
          @click="verifyReturnPath"
        >
          {{ $t('Verify Return-Path') }}
        </BaseButton>
        <BaseButton
          variant="white"
          hoverVariant="danger"
          @click="confirmDelete"
        >
          {{ $t('Delete Domain') }}
        </BaseButton>
      </div>
    </template>
  </SettingsForm>
</template>
<script>
import SettingsForm from "@/modules/accounts/components/SettingsForm.vue";
import UpgradePlanWarning from "@/modules/accounts/components/UpgradePlanWarning.vue";

export default {
  components: {
    UpgradePlanWarning,
    SettingsForm,
  },
  data() {
    return {
      tableColumns: [
        {
          name: '',
          prop: 'entry_name',
          class: 'w-1'
        },
        {
          name: this.$t('Hostname'),
          prop: 'hostname',
          class: 'w-1/2'
        },
        {
          name: this.$t('Type'),
          prop: 'type',
          class: 'w-1'
        },
        {
          name: this.$t('Add this value'),
          prop: 'value',
          class: 'w-1/2'
        },
      ]
    }
  },
  computed: {
    customPostmarkDomain() {
      return (this.$store.state.accounts.postmarkDomains?.data?.[0] || {}).attributes
    },
    isDomainConfigured() {
      return this.customPostmarkDomain?.domain_id
    },
    subscription() {
      return this.$store.state.accounts.subscription || {}
    },
    tableData() {
      return [
        {
          entry_name: 'DKIM',
          type: 'TXT',
          hostname: this.customPostmarkDomain.dkim_pending_host,
          verified: this.customPostmarkDomain.dkim_verified,
          value: this.customPostmarkDomain.dkim_pending_text_value
        },
        {
          entry_name: 'Return-Path',
          type: 'CNAME',
          hostname: this.customPostmarkDomain.return_path_domain,
          verified: this.customPostmarkDomain.return_path_domain_verified,
          value: this.customPostmarkDomain.return_path_domain_cname_value
        }
      ]
    }
  },
  methods: {
    async getPostmarkDomains() {
      await this.$store.dispatch('accounts/getPostmarkDomains')
    },
    async verifyDkim() {
      try {
        const domainId = this.customPostmarkDomain.domain_id

        const result = await this.$store.dispatch('accounts/verifyPostmarkDkim', {
          domainId
        })

        if (!result.is_valid) {
          this.$error(this.$t('DKIM verification failed, please make sure your DNS settings are set correctly and try again in a few minutes..'))
          return
        }

        this.$success(this.$t('DKIM has been configured correctly!'))

        this.getPostmarkDomains()
      } catch (err) {
        this.$error('Something went wrong, could not verify DKIM status..')
      }
    },
    async verifyReturnPath() {
      try {
        const domainId = this.customPostmarkDomain.domain_id

        const result = await this.$store.dispatch('accounts/verifyPostmarkReturnPath', {
          domainId
        })

        if (!result.is_valid) {
          this.$error(this.$t('Return-Path verification failed, please make sure your DNS settings are set correctly and try again in a few minutes..'))
          return
        }

        this.$success(this.$t('Return-Path has been configured correctly!'))

        this.getPostmarkDomains()
      } catch (err) {
        this.$error('Something went wrong, could not verify Return-Path status..')
      }
    },
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t("Delete Email Domain"),
        description: this.$t(
          "Are you sure? This will permanently delete this custom email domain."
        ),
      });

      if (!confirmed) {
        return;
      }

      const domainId = this.customPostmarkDomain.domain_id

      await this.$store.dispatch('accounts/deletePostmarkDomain', {
        domainId
      })


      this.$store.dispatch("accounts/getOrganizationSettings")
      this.getPostmarkDomains()
    }
  },
  mounted() {
    this.getPostmarkDomains()
  }
}
</script>
