<template>
  <BaseTableCell :params="params">
    <div class="inline-flex">
      <BaseTooltip
        v-if="isProjectClosed"
        :content="tooltipContent"
      >
        <i class="cursor-pointer fas fa-lock mr-2 text-red-600" />
      </BaseTooltip>
      <router-link
        v-if="project?.id"
        :to="path"
      >
        <i
          v-if="childRow"
          class="fas fa-inbox text-purple-500 mr-1"
        />
        <span>{{ project?.attributes?.name || '- -' }}</span>
      </router-link>
      <div v-else>
        {{ '- -' }}
      </div>
    </div>
  </BaseTableCell>
</template>
<script lang="ts">
import { ICellRendererParams } from '@ag-grid-community/core'
import { PropType } from 'vue'

export default {
  props: {
    params: {
      type: Object as PropType<ICellRendererParams<any>>,
      default: () => ({})
    }
  },
  computed: {
    childRow() {
      return this.params?.node?.allLeafChildren?.[0]?.data
    },
    row() {
      return this.params?.data || this.childRow
    },
    project() {
      return (this.row?.project || this.row?.relationships?.project) || {}
    },
    isProjectClosed() {
      // TODO: Remove Temp remap
      const restifyProject = {
        id: this.project?.id,
        attributes: this.project?.attributes || {
          ...this.project
        }
      }
      return this.$store.getters['projects/isProjectClosed'](restifyProject)
    },
    isTemplate() {
      return this.project?.attributes?.is_template
    },
    path() {
      if (this.isTemplate) {
        return `/templates/${this.project?.id}`
      }

      return `/projects/${this.project?.id}`
    },
    entityType() {
      return this.row.type
    },
    tooltipContent() {
      if (this.entityType === 'projects') {
        return this.$t('This project is completed. In order to make changes, please reopen it first.')
      }

      return this.$tc('cannot change entity of completed project', {
        entity_type: this.entityType
      })
    }
  },
}
</script>
