<template>
  <div
    class="flex border border-gray-200 rounded-lg cursor-pointer space-x-3 relative project-tool-card tool-card"
    :class="{
      'cursor-pointer': canAccessTool,
    }"
    @click="toolClicked"
  >
    <div
      class="flex-none w-14 text-center bg-emerald-500 text-white text-2xl rounded-l-lg flex items-center justify-center"
      :style="{
        background: image
          ? 'white'
          : color
      }"
      :class="{
        'border-r border-gray-200': image,
      }"
    >
      <BaseAvatar
        v-if="image"
        :avatar="image"
      />
      <i
        v-else
        :class="icon"
        class="text-3xl"
      />
    </div>
    <div class="px-4 py-2 overflow-hidden">
      <div class="flex items-center text-gray-900 text-lg font-bold">
        <div class="line-clamp-2">
          {{ title }}
        </div>
        <AccessControlIndicator
          v-if="hiddenForCollaborators && !isNoteTool"
          :tooltip="$t(`This tool can't be seen by people with a Collaborator role`)"
          class="ml-1"
        />
        <AccessControlIndicator
          v-else-if="isNoteTool && isNoteVisibleToCreatorsOnly"
          :tooltip="$t(`This note is only visible to people with a Creator role. It can't be seen by people with a Collaborator role.`)"
          class="ml-1"
          icon="fa-lock"
        />
      </div>
      <div class="text-gray-500 text-sm">
        <div class="flex items-center">
          <div class="text-sm text-gray-500 line-clamp-3">
            <div
              v-html="toolDescription"
              class="project-tool-description text-sm text-gray-500"
              :class="{ 'whitespace-pre': isNoteTool }"
            />
          </div>
        </div>
      </div>
    </div>
    <span
      v-if="isRemoveToolsEnabled"
      class="absolute top-0 right-2 -mt-3 cursor-pointer bg-white text-lg font-medium"
      :class="{
        'opacity-50': !canRemoveTool,
      }"
      @click.stop="confirmRemoveTool"
    >
      <BaseTooltip
        :content="cannotRemoveReason"
        :disabled="canRemoveTool"
      >
        <i class="fa-solid fa-circle-minus text-red-500" />
      </BaseTooltip>
    </span>
    <span
      v-else-if="!isRemoveToolsEnabled && can($actions.EDIT_PROJECT_TOOLS) && !isCurrentProjectClosed"
      class="absolute top-0 right-2 -mt-3 px-2 bg-white/80 text-lg font-medium text-gray-300 hover:text-gray-400"
    >
      <i class="hidden fa-solid fa-grip-dots cursor-move" />
    </span>
    <span
      v-if="editable && !isRemoveToolsEnabled && can($actions.EDIT_PROJECT_TOOLS) && !isCurrentProjectClosed"
      class="absolute top-0 right-8 -mt-3 px-2 bg-white text-gray-300 hover:text-gray-400"
      @click.stop="triggerEdit"
    >
      <i class="hidden fa-solid fa-edit text-sm cursor-pointer" />
    </span>

    <ProjectLinkDialog
      v-model="showLinkDialog"
      v-show="showLinkDialog"
      :link="resource"
      key="link-edit"
      @close="showLinkDialog = false"
      @save="showLinkDialog = false; onToolEdited()"
    />
    <EmbedDialog
      v-model="showEmbedDialog"
      v-show="showEmbedDialog"
      :embed="resource"
      key="embed-edit"
      @close="showEmbedDialog = false"
      @save="showEmbedDialog = false; onToolEdited()"
    />
    <ProjectReferenceDialog
      v-model="showReferenceDialog"
      v-show="showReferenceDialog"
      :reference="resource"
      key="reference-edit"
      @close="showReferenceDialog = false"
      @save="showReferenceDialog = false; onToolEdited()"
    />
  </div>
</template>
<script>
import i18n from '@/i18n'

// Helpers
import { toolsEnum } from '@/modules/projects/utils/toolUtils.js'
import { getEmbedIcon, getEmbedImage } from '@/modules/resources/utils/embedUtils'
import { getProjectLinkImage } from '@/modules/resources/utils/projectLinkUtils'
import { getNoteName, stripNoteHTML } from '@/modules/projects/utils/stripNotesHTML.js'
import { getCompletedTasksFilterAppliedQuery, getMyTasksFilterAppliedQuery } from '@/modules/tasks/utils/modelUtils'
import { getReferencePath } from '@/components/html/util/referenceUtils.js'

// Components
import EmbedDialog from '@/modules/resources/components/EmbedDialog.vue'
import ProjectLinkDialog from '@/modules/projects/components/ProjectLinkDialog.vue'
import ProjectReferenceDialog from '@/modules/projects/components/ProjectReferenceDialog.vue'
import { NotesVisibility } from '@/modules/projects/utils/noteUtils'

export default {
  components: {
    EmbedDialog,
    ProjectLinkDialog,
    ProjectReferenceDialog,
  },
  props: {
    tool: {
      type: Object,
      default: () => ({})
    },
    isRemoveToolsEnabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showLinkDialog: false,
      showEmbedDialog: false,
      showReferenceDialog: false,
    }
  },
  computed: {
    projectId() {
      return this.$route.params.id
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
    toolOptions() {
      return this.tool?.pivots?.options
    },
    isNoteTool() {
      return this.toolOptions?.entity_type === 'note'
    },
    isLinkTool() {
      return this.toolOptions?.entity_type === 'link'
    },
    isEmbedTool() {
      return this.toolOptions?.entity_type === 'embed'
    },
    isReferenceTool() {
      return this.toolOptions?.entity_type === 'reference'
    },
    isResourcesTool() {
      return this.tool.attributes?.name === toolsEnum.EMBEDS && !this.resource
    },
    isFileReference() {
      return this.isReferenceTool && this.toolOptions?.referenceable_type === 'media'
    },
    editable() {
      return this.isLinkTool || this.isEmbedTool || this.isReferenceTool
    },
    resource() {
      return this.tool?.resource
    },
    toolName() {
      return this.tool.attributes?.name
    },
    isNoteVisibleToCreatorsOnly() {
      const { visibility = '' } = this.tool?.resource?.attributes || {}
      return visibility === NotesVisibility.CREATORS_EDIT_COLLABORATORS_RESTRICT
    },
    title() {
      if (this.resource) {
        return i18n.t(this.resource.attributes?.name || this.resource.attributes?.title)
      }

      if (this.isNoteTool) {
        return getNoteName(this.toolOptions?.notes)
      }
      if (this.isLinkTool || this.isReferenceTool) {
        return this.toolOptions.name
      }
      if (this.toolOptions?.entity_type === 'embed') {
        return this.subtitle || this.toolOptions?.name
      }

      if (this.isResourcesTool) {
        return i18n.t('Resources')
      }

      return i18n.t(this.toolName)
    },
    subtitle() {
      return this.tool.pivots?.options?.title || ''
    },
    toolDescription() {
      if (this.resource && this.resource.attributes?.description) {
        return this.resource.attributes?.description
      }
      if (this.toolOptions?.description) {
        return stripNoteHTML(this.toolOptions?.description) || ''
      }
      if (this.isNoteTool) {
        return stripNoteHTML(this.toolOptions?.notes) || ''
      }
      if (this.isReferenceTool) {
        return i18n.t('This is a reference within the application. Click to open it.')
      }
      if (this.isResourcesTool) {
        return i18n.t('View links to all the important resources for this project.')
      }
      if (this.isLinkTool) {
        return i18n.t('This is an external link. Click to open it in a new browser tab.')
      }
      if (this.toolOptions?.entity_type === 'embed') {
        return this.toolOptions?.description || i18n.t('This is an embed. Click to open it inside this project.')
      }
      return i18n.t(this.tool?.attributes?.description)
    },
    image() {
      if (this.resource && this.resource.attributes?.image) {
        return this.resource.attributes?.image
      }

      if (this.toolOptions?.image) {
        return this.toolOptions?.image
      }

      if (this.toolOptions?.entity_type === 'embed') {
        return getEmbedImage(this.toolOptions?.type)
      }

      if (this.toolOptions?.entity_type === 'link') {
        return getProjectLinkImage(this.toolOptions?.type)
      }

      return '';
    },
    icon() {
      if (this.toolOptions?.entity_type === 'embed') {
        return getEmbedIcon(this.toolOptions?.type)
      }

      if (this.isResourcesTool) {
        return 'fal fa-rectangle-list'
      }

      const icon = this.tool?.attributes?.image?.toString() || ''
      return icon.replaceAll('far', 'fal')
    },
    color() {
      if (this.isResourcesTool) {
        return '#3B82F6'
      }

      if (this.isReferenceTool) {
        return '#6B7280'
      }

      const color = this.tool?.attributes?.color || ''
      return color.toLowerCase()
    },
    path() {
      const basePath = this.isTemplateOpened
        ? `/templates/${this.projectId}`
        : `/projects/${this.projectId}`
      const name = this.tool?.attributes?.name


      const paths = {
        [toolsEnum.TASKS]: () => {
          const viewOptions = this.$store.getters['users/defaultTargetViewOptions']('tasks', /* inside_project */  true)
          let query = getMyTasksFilterAppliedQuery(this.projectId, true)

          if (this.isCurrentProjectClosed) {
            query = getCompletedTasksFilterAppliedQuery(this.projectId, /* inside_project */ true)
          }

          return {
            path: `${basePath}/tasks/${viewOptions.view_type}`,
            query
          }
        },
        [toolsEnum.FILES]: () => {
          return `${basePath}/files/list`
        },
        [toolsEnum.FILE_PROOFING]: () => {
          return `${basePath}/file-proofs/list`
        },
        [toolsEnum.PAYMENTS]: () => {
          return `${basePath}/payments/list`
        },
        [toolsEnum.TIME]: () => {
          const timePage = this.isTemplateOpened
            ? 'allocated'
            : 'actual'

          return `${basePath}/time/${timePage}`
        },
        [toolsEnum.NOTE]: () => {
          return `${basePath}/notes/${this.toolOptions?.entity_id}`
        },
        [toolsEnum.NOTES]: () => {
          return `${basePath}/notes`
        },
        [toolsEnum.DISCUSSIONS]: () => {
          return `${basePath}/discussion`
        },
        [toolsEnum.PEOPLE]: () => {
          return `${basePath}/people`
        },
        [toolsEnum.EMBEDS]: () => {
          if (this.toolOptions?.entity_type === 'embed') {
            return `${basePath}/embeds/${this.resource?.id}`
          }
          return `${basePath}/resources/list`
        },
        [toolsEnum.LINK]: () => {
          return this.toolOptions?.link
        },
        [toolsEnum.REFERENCE]: () => {
          return getReferencePath(this.toolOptions)
        }
      }

      if (!paths[name]) {
        return `${basePath}/tools/${this.tool.id}`
      }

      return paths[name]()
    },
    isTemplateOpened() {
      return this.$store.getters['templates/isTemplateOpened']
    },
    canAccessTool() {
      if (this.toolName !== toolsEnum.DISCUSSIONS) {
        return true
      }
      return !this.isTemplateOpened
    },
    project() {
      return this.$store.state.projects.currentProject
    },
    projectNotes() {
      return this.project?.relationships?.notes || []
    },
    canRemoveTool() {
      if (this.toolName === toolsEnum.PEOPLE) {
        return false
      }

      if (this.toolName === toolsEnum.NOTES) {
        return !this.projectNotes?.length
      }

      return true
    },
    cannotRemoveReason() {
      if (this.toolName === toolsEnum.NOTES) {
        return i18n.t('Please delete the notes within this tool before removing it.')
      }

      return i18n.t('Tool cannot be removed')
    },
    hiddenForCollaborators() {
      const { name } = this.tool.attributes

      const isHidden = {
        [toolsEnum.TIME]: !this.canRole(this.$roles.COLLABORATOR, this.$actions.VIEW_TIME_ENTRIES),
      }

      return isHidden[name] || false
    }
  },
  methods: {
    toolClicked() {
      if (!this.canAccessTool) {
        return
      }

      if (this.isLinkTool || this.isFileReference) {
        window.open(this.path, '_blank')
      } else {
        this.$router.push(this.path)
      }
    },
    unaccessibleToolClicked() {
      if (this.toolName === toolsEnum.DISCUSSIONS) {
        this.$error(i18n.t('Cannot add comments for template discussion'))
      }
    },
    async confirmRemoveTool() {
      if (!this.canRemoveTool) {
        return
      }

      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Remove tool'),
        description: i18n.t('confirm remove project tool', {
          toolName: i18n.t(this.toolName)
        }),
        buttonText: i18n.t('Remove')
      });

      if (!confirmed) {
        return;
      }

      this.removeTool()
    },
    async removeTool() {
      try {
        this.loading = true
        await this.$store.dispatch('projects/removeProjectTool', {
          tool: this.tool,
          projectId: this.projectId,
        })
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not remove the tool from the project'))
      } finally {
        this.loading = false
      }
    },
    triggerEdit() {
      if (this.isLinkTool) {
        this.showLinkDialog = true
        return
      }

      if (this.isEmbedTool) {
        this.showEmbedDialog = true
        return
      }

      if (this.isReferenceTool) {
        this.showReferenceDialog = true
      }
    },
    async onToolEdited() {
      await this.$store.dispatch('projects/getProjectById', {
        id: this.projectId,
        forceFetch: true,
        silent: true
      })
    },
  }
}
</script>
<style lang="scss">
.project-tool-card:hover {
  .fa-grip-dots, .fa-edit {
    @apply inline;
  }
}
</style>
