<template>
    <div
      class="grid place-items-center w-full h-full bg-white"
      v-loading="isRootApi && organizations.length === 1"
    >
      <div class="flex justify-center">
        <div
          v-if="showOrganizationList"
          class="grid grid-cols-1 gap-6 place-items-center py-10 px-5 bg-white border rounded-lg shadow"
        >
          <template v-if="organizations.length">
            <div class="text-center mb-3 text-md -mt-3">
              {{ $t("Please select an organization") }}
            </div>
            <a
              v-for="organization of organizations"
              :key="organization.id"
              :href="organizationLink(organization)"
              class="block w-96 bg-white p-6 border border-gray-200 rounded-lg group shadow capitalize text-center hover:font-semibold hover:border-gray-300"
            >
              {{ organization.name || organization?.attributes?.name }}
            </a>
          </template>
          <div
            v-else
            class="text-center"
          >
            {{ $t("It seems that you are not part of any organization") }}
            <div>
              {{ $t("Click") }}
              <router-link to="/register">
                <span class="text-blue-500 underline">{{ $t("here") }}</span>
              </router-link>
              {{ $t("to add yours") }}
            </div>
          </div>
          <BaseButton
            variant="primary-link"
            @click="logout"
          >
            {{ $t('Or, click here to log out') }}
          </BaseButton>
        </div>
      </div>
    </div>
</template>
<script>
import AuthService from '@/modules/auth/services/AuthService';
import { isRootApi } from "@/modules/common/config.js";

export default {
  data() {
    return {
      isRootApi: isRootApi(),
    }
  },
  computed: {
    showOrganizationList() {
      if (!this.isRootApi) {
        return true
      }
      return this.organizations.length > 1
    },
    user() {
      return this.$store.state.auth.user
    },
    organizations() {
      return this.$store.state.auth.organizations || []
    },
  },
  methods: {
    organizationLink(organization) {
      const token = AuthService.getToken()
      const fqdn = organization?.fqdn || organization?.attributes?.fqdn
      let url = `${fqdn}?loginToken=${token}`
      const templateToShare = this.$store.state.templates.templateToShare
      if (templateToShare) {
        url += `&share_template=${templateToShare}`
      }
      const ap3Cookie = ap3c?.getCookie()
      if (ap3Cookie) {
        url += `&ap3c=${ap3Cookie}`
      }
      return url
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  async created() {
    await this.$store.dispatch('auth/getProfile')
  },
}
</script>
