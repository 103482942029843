import { onMounted, onUnmounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import PusherUtils from "@/modules/common/utils/pusherUtils.js";
import { discussionListenChannel, discussionTriggerChannel } from "@/modules/projects/utils/discussionUtils.js";

export function useDiscussion({ projectId, taskId }) {
  const store = useStore()
  const retryCount = ref(0)
  const isConnectedToChannel = ref(true)

  const triggerChannel = ref(null)
  const listenChannel = ref(null)

  const activeUsers = ref([])

  let isTypingTimeout = null

  const pusherChannel = computed(() => {
    if (projectId) {
      return PusherUtils.getDiscussionChannel('project', projectId)
    } else if (taskId) {
      return PusherUtils.getDiscussionChannel('task', taskId)
    }
    return null
  })

  const usersTyping = computed(() => {
    const usersTyping = []
    const currentUser = store.state.auth?.user

    for (const userId in activeUsers.value) {
      if (userId !== currentUser.id && activeUsers.value[userId]?.isTyping) {
        usersTyping.push(activeUsers.value[userId].user)
      }
    }

    return usersTyping
  })


  function leaveChannel() {
    discussionListenChannel.value = null
    discussionTriggerChannel.value = null

    if (isConnectedToChannel.value) {
      triggerChannel.value?.trigger(PusherUtils.USER_IS_TYPING_EVENT, {
        isTyping: false,
        user: store.state.auth?.user
      })
    }
    PusherUtils.echo().leave(pusherChannel.value)
  }

  function tryJoinChannel(refreshComments = false) {
    try {
      leaveChannel()
      joinChannel()
    } catch (err) {
      isConnectedToChannel.value = false
    }

    if (!refreshComments) {
      return
    }
    retryCount.value++
  }

  function joinChannel() {
    listenChannel.value = PusherUtils.echo().private(pusherChannel.value)
    discussionListenChannel.value = listenChannel.value
    
    listenChannel.value.on(PusherUtils.PUSHER_SUBSCRIPTION_ERROR_EVENT, () => {
      isConnectedToChannel.value = false
    })

    listenChannel.value.on(PusherUtils.PUSHER_SUBSCRIPTION_SUCCEEDED_EVENT, () => {
      triggerChannel.value = listenChannel.value.pusher.channels.channels[`private-${pusherChannel.value}`]
      discussionTriggerChannel.value = triggerChannel.value
      isConnectedToChannel.value = true
    })

    listenChannel.value.on(PusherUtils.USER_IS_TYPING_EVENT, ({ isTyping, user }) => {
      activeUsers.value[user.id] = { isTyping, user }
    })
  }

  function notifyIsTyping(value) {
    triggerChannel.value?.trigger(PusherUtils.USER_IS_TYPING_EVENT, {
      isTyping: value,
      user: this.$user
    })

    if (!value) {
      return
    }

    isTypingTimeout && clearTimeout(isTypingTimeout)

    isTypingTimeout = setTimeout(() => {
      triggerChannel.value?.trigger(PusherUtils.USER_IS_TYPING_EVENT, {
        isTyping: false,
        user: store.state.auth?.user
      })
    }, PusherUtils.IS_TYPING_NOTIFICATION_TIMEOUT_MS)
  }

  onMounted(async () => {
    tryJoinChannel()
    document.body.classList.add('overflow-x-hidden')
  })


  onUnmounted(() => {
    leaveChannel()
    document.body.classList.remove('overflow-x-hidden')
  })

  return {
    retryCount,
    usersTyping,
    listenChannel,
    triggerChannel,
    isConnectedToChannel,
    tryJoinChannel,
    notifyIsTyping,
  }
}
