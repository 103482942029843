import i18n from "@/i18n"
import store from "@/store/index.js"; 
import { ColumnTypes } from '@/components/table/cells/tableCellComponents'
import { capitalize } from "lodash-es";

import { success, error } from "@/components/common/NotificationPlugin/index.js";

import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual";
import { EDIT_PROJECT_EMBEDS } from "@/modules/common/enum/actionsEnum";
import { getDefaultOptionsColumn, TableColumn } from "@/components/table/tableUtils";

export const resourceColumns = [
  {
    name: i18n.t('Type'),
    tableHeader: i18n.t('Image'),
    prop: 'attributes.type',
    component: ColumnTypes.ProjectResource,
    params: {
      isImage: true
    },
    sortProp: 'type',
    minWidth: 80,
    maxWidth: 80,
    enableRowGroup: true,
    filterBy: {
      prop: 'type',
      component: 'ResourceTypeSelect',
      displayFormat(value: string) {
        return capitalize(value?.replaceAll('_', ' ') || '')
      }
    }
  },
  {
    name: i18n.t('Name'),
    prop: 'attributes.name',
    component: ColumnTypes.ProjectResource,
    flex: 3,
    sortProp: 'name',
    required: true,
    filterBy: {
      prop: 'name',
      type: 'text'
    },
  },
  {
    name: i18n.t('Description'),
    prop: 'attributes.description',
    flex: 3,
  },
  {
    name: i18n.t('Created Date'),
    prop: 'attributes.created_at',
    flex: 2,
    showCardLabel: true,
    sortProp: 'created_at',
    component: ColumnTypes.Date,
    filterBy: {
      prop: 'created_at',
      type: 'date-range',
      format: 'formatDateRangeValue::yyyy-MM-dd',
      displayFormat: 'formatDateRange::dd/MM/yy'
    }
  },
  {
    name: i18n.t('Pinned'),
    prop: 'attributes.pinned',
    component: ColumnTypes.Checkbox,
    minWidth: 80,
    maxWidth: 80,
    params: {
      getValue(row: any) {
        return !!row?.attributes?.pinned 
      },
      onChange: onResourcePinnedToggle,
      isDisabled: () => {
        if (!store.getters['users/can'](EDIT_PROJECT_EMBEDS) || store.getters['projects/isCurrentProjectClosed']) {
          return true
        }
        
        return false
      }
    },
  },
]

export const optionsColumn: TableColumn = {
  ...getDefaultOptionsColumn(),
  disabled: () => {
    return !store.getters['users/isCurrentUserRoleGreaterOrEqual'](rolesEnum.CREATOR)
  }
}

async function onResourcePinnedToggle(value: boolean, resource: any) {
  try {
    await store.dispatch('resources/setResourcePinned', {
      resource,
      value
    })

    store.dispatch('projects/getProjectById', {
      id: resource.attributes.project_id,
      silent: true,
      forceFetch: true
    })
    
    success(i18n.t("Resource updated"))
  }
  catch (err) {
    error(i18n.t("Could not update resource"))
  }
}
