// Libs
import i18n from "@/i18n.js"
import { computed } from "vue"

// Utils
import { toolsEnum, coreTools } from '@/modules/projects/utils/toolUtils.js'
import {
  getCompletedTasksFilterAppliedQuery,
  getMyTasksFilterAppliedQuery
} from '@/modules/tasks/utils/modelUtils'
import { getEmbedIcon } from '@/modules/resources/utils/embedUtils'
import { getReferencePath } from '@/components/html/util/referenceUtils.js'

// Composables
import { useStore } from 'vuex'
import { useRoute } from "vue-router"
import useCan from "@/modules/common/composables/useCan";

export function useProjectTools() {
  const store = useStore()
  const { can, actions } = useCan()
  const route = useRoute()

  const orderedProjectTools = computed(() => {
    return store.getters['projects/orderedProjectTools']
  })
  
  const currentProject = computed(() => {
    return store.state.projects.currentProject
  })
  
  const projectId = computed(() => {
    return currentProject.value?.id
  })

  const isTemplateOpened = computed(() => {
    return store.getters['templates/isTemplateOpened']
  })

  const isCurrentProjectClosed = computed(() => {
    return store.getters['projects/isCurrentProjectClosed']
  })

  const notes = computed(() => {
    return currentProject.value?.relationships?.notes || []
  })

  const embeds = computed(() => {
    return currentProject.value?.relationships?.embeds || []
  })

  const links = computed(() => {
    return currentProject.value?.relationships?.links || []
  })

  const references = computed(() => {
    return currentProject.value?.relationships?.references || []
  })

  const resourceTypes = computed(() => {
    return {
      [toolsEnum.EMBEDS]: embeds.value,
      [toolsEnum.NOTE]: notes.value,
      [toolsEnum.REFERENCE]: references.value,
      [toolsEnum.LINK]: links.value,
    }
  })

  function getToolPath(tool: any) {
    const basePath = isTemplateOpened.value
      ? `/templates/${projectId.value}`
      : `/projects/${projectId.value}`
    const name = tool?.attributes?.name
    
    const toolOptions = tool?.pivots?.options
    const resource = tool?.resource
    const paths = {
      [toolsEnum.TASKS]: () => {
        const viewOptions = store.getters['users/defaultTargetViewOptions']('tasks', /* inside_project */  true)
        let query = getMyTasksFilterAppliedQuery(projectId.value, true)

        if (isCurrentProjectClosed.value) {
          query = getCompletedTasksFilterAppliedQuery(projectId.value, /* inside_project */ true)
        }

        return {
          path: `${basePath}/tasks/${viewOptions.view_type}`,
          query
        }
      },
      [toolsEnum.FILES]: () => {
        return `${basePath}/files/list`
      },
      [toolsEnum.PAYMENTS]: () => {
        return `${basePath}/payments/list`
      },
      [toolsEnum.TIME]: () => {
        const timePage = isTemplateOpened.value
          ? 'allocated'
          : 'actual'

        return `${basePath}/time/${timePage}`
      },
      [toolsEnum.NOTE]: () => {
        return `${basePath}/notes/${toolOptions?.entity_id}`
      },
      [toolsEnum.NOTES]: () => {
        return `${basePath}/notes`
      },
      [toolsEnum.DISCUSSIONS]: () => {
        return `${basePath}/discussion`
      },
      [toolsEnum.PEOPLE]: () => {
        return `${basePath}/people`
      },
      [toolsEnum.EMBEDS]: () => {
        if (toolOptions?.entity_type === 'embed') {
          return `${basePath}/embeds/${resource?.id}`
        }
        return `${basePath}/resources/list`
      },
      [toolsEnum.FILE_PROOFING]: () => {
        return `${basePath}/file-proofs/list`
      },
      [toolsEnum.LINK]: () => {
        return toolOptions?.link
      },
      [toolsEnum.REFERENCE]: () => {
        return getReferencePath(toolOptions)
      }
    }

    if (!paths[name]) {
      return `${basePath}/tools/${tool.id}`
    }

    return paths[name]()
  }

  const canViewByType = computed(() => {
    return {
      [toolsEnum.PEOPLE]: false,
      [toolsEnum.TIME]: can(actions.VIEW_TIME_ENTRIES),
      [toolsEnum.DISCUSSIONS]: !isTemplateOpened.value,
    }
  })

  function getResourceById(tool: any) {
    const resources = resourceTypes.value?.[tool?.attributes?.name]
    if(!resources) return null
    return resources?.find((r: any) => String(r.id) === String(tool?.pivots?.options?.entity_id)) || null
  }

  function getToolIcon(tool: any) {
    const toolOptions = tool?.pivots?.options
    const isResourcesTool = tool?.attributes?.name === toolsEnum.EMBEDS && !tool.resource

    if (toolOptions?.entity_type === 'embed') {
      return getEmbedIcon(toolOptions?.type)
    }

    if (isResourcesTool) {
      return 'fal fa-rectangle-list'
    }

    return tool?.attributes?.image?.toString() || ''
  }

  const projectToolNavigationOptions = computed(() => {
    const filteredTools = orderedProjectTools.value.filter((tool: any) => {
      tool.resource = getResourceById(tool)

      const name = tool.attributes?.name

      if (name === toolsEnum.REFERENCE) {
        return true
      }

      if (canViewByType.value.hasOwnProperty(name) && !canViewByType.value[name]) {
        return false
      }

      if (tool.resource) {
        return tool.resource?.attributes?.pinned || false
      }

      return true
    })

    let displayedItems = filteredTools

    if (filteredTools.length > 10) {
      displayedItems = filteredTools.slice(0, 9)

      displayedItems.push({
        icon: 'fas fa-plus text-gray-400 group-hover:text-gray-500',
        label: i18n.tc('count more', { count: filteredTools.length - 9 }),
        path: `/projects/${projectId.value}`,
        value: 'more'
      })
    }

    return displayedItems.map((tool: any) => {
      if (tool.value === 'more') {
        return tool
      }

      const toolDefinition = coreTools.find((t: any) => t.value === tool.attributes?.name)
      const name = toolDefinition?.name || i18n.t(tool.attributes?.name)

      const icon = getToolIcon(tool).replace(/fal|far|fa-light/ig, 'fas')
      let toolValue = toolDefinition?.value || tool.attributes?.name || ''

      if (toolValue === toolsEnum.EMBEDS) {
        toolValue = 'Resources'
      }
      
      const label = tool?.resource?.attributes?.name || name
      const path = getToolPath(tool)
      const isActive = route.path.includes(`/${toolValue.toLowerCase()}`)
      return {
        ...tool.attributes,
        ...toolDefinition,
        isActive,
        icon: `${icon} text-gray-400 group-hover:text-gray-500`,
        target: name === toolsEnum.LINK ? '_blank' : null,
        path,
        label,
      }
    })
  })

  return {
    projectToolNavigationOptions
  }
}
