import AccountSettingsLayout from "@/modules/accounts/layout/AccountSettingsLayout.vue";
import AccountSettings from "@/modules/accounts/pages/accounts-settings.vue";
import IntegrationsLayout from "@/modules/accounts/layout/IntegrationsLayout.vue";
import Integrations from "@/modules/accounts/pages/integrations/integrations.vue";
import ProjectStatuses from "@/modules/accounts/pages/project-statuses.vue";
import TaskStatuses from "@/modules/accounts/pages/task-statuses.vue";
import CustomFields from "@/modules/accounts/pages/custom-fields.vue";
import StripeSetup from "@/modules/accounts/pages/integrations/stripe-setup.vue";
import PublicApi from "@/modules/accounts/pages/integrations/public-api.vue";
import Paypal from "@/modules/accounts/pages/integrations/paypal.vue";
import Webhooks from "@/modules/accounts/pages/integrations/webhooks.vue";
import Localisation from "@/modules/accounts/pages/localisation-settings.vue";
import Account from "@/modules/accounts/pages/account.vue";
import Billing from "@/modules/accounts/pages/billing.vue";
import CodeStacking from "@/modules/accounts/pages/code-stacking.vue";
import InvoiceInfo from "@/modules/accounts/pages/invoice-info.vue";
import Search from "@/modules/accounts/pages/search.vue";
import Profile from "@/modules/accounts/pages/profile.vue";
import Import from "@/modules/accounts/pages/import.vue";
import Export from "@/modules/accounts/pages/export.vue";
import Zapier from "@/modules/accounts/pages/integrations/zapier.vue";
import InboxLayout from "@/modules/accounts/layout/InboxLayout.vue";
import Inbox from "@/modules/accounts/pages/inbox.vue";
import Branding from "@/modules/accounts/pages/branding.vue";
import CustomEmails from "@/modules/accounts/pages/custom-emails.vue";
import CustomDomain from "@/modules/accounts/pages/custom-domain.vue";
import SimulateRoles from "@/modules/accounts/pages/simulate-roles.vue";

import GettingStarted from '@/modules/accounts/pages/getting-started.vue'
import TrialManager from '@/modules/accounts/pages/trial-manager.vue'
// Account setup wizard + steps
import AccountSetupWizard from '@/modules/accounts/pages/account-setup-wizard.vue'
import SetupLocalisationSettings from '@/modules/accounts/pages/setup-wizard/setup-localisation-settings.vue'
import SetupProjectStatuses from '@/modules/accounts/pages/setup-wizard/setup-project-statuses.vue'
import SetupTaskStatuses from '@/modules/accounts/pages/setup-wizard/setup-task-statuses.vue'
import SetupAccountDefaults from '@/modules/accounts/pages/setup-wizard/setup-account-defaults.vue'
import SetupViewTypes from '@/modules/accounts/pages/setup-wizard/setup-view-types.vue'
import TrialCustomBranding from '@/modules/accounts/pages/setup-wizard/trial-custom-branding.vue'
import SetupCompleted from '@/modules/accounts/pages/setup-wizard/setup-completed.vue'

import {
  MANAGE_ACCOUNT_SETTINGS,
  SIMULATE_ROLES,
  CREATE_API_KEYS,
} from "@/modules/common/enum/actionsEnum"
import PlanLimits from "@/modules/accounts/pages/plan-limits.vue";

export default [
  {
    path: 'profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/account-settings',
    name: 'Settings',
    component: AccountSettingsLayout,
    redirect: to => {
      return to.fullPath
    },
    meta: {
      requiresPermissionTo: MANAGE_ACCOUNT_SETTINGS
    },
    children: [
      {
        path: '',
        name: 'Account Settings',
        meta: {
          hideBreadCrumb: true,
        },
        component: AccountSettings,
      },
      {
        path: 'account',
        name: 'Account',
        component: Account,
      },
      {
        path: 'plan-limits',
        name: 'Plan Limits',
        component: PlanLimits,
      },
      {
        path: 'billing',
        name: 'Billing',
        component: Billing,
      },
      {
        path: 'code-stacking',
        name: 'Code Stacking',
        component: CodeStacking,
      },
      {
        path: 'project-statuses',
        name: 'Project Statuses',
        component: ProjectStatuses,
      },
      {
        path: 'task-statuses',
        name: 'Task Statuses',
        component: TaskStatuses,
      },
      {
        path: 'custom-fields',
        name: 'Custom Fields',
        component: CustomFields,
      },
      {
        path: 'import',
        name: 'Import Data',
        component: Import,
      },
      {
        path: 'export',
        name: 'Export Data',
        component: Export,
      },
      {
        path: 'invoice-info',
        name: 'Invoice Info',
        component: InvoiceInfo,
      },
      {
        path: 'localisation',
        name: 'Localisation',
        component: Localisation,
      },
      {
        path: 'branding',
        name: 'Branding',
        component: Branding,
      },
      {
        path: 'custom-emails',
        name: 'Custom Emails',
        component: CustomEmails,
      },
      {
        path: 'custom-domain',
        name: 'Custom Domain',
        component: CustomDomain,
      },
      {
        path: 'trial-manager',
        name: 'Trial Manager',
        title: 'Trial Manager',
        component: TrialManager,
      },
    ]
  },
  {
    path: 'getting-started',
    name: 'Getting Started',
    title: 'Getting Started',
    component: GettingStarted,
    meta: {
      requiresPermissionTo: MANAGE_ACCOUNT_SETTINGS
    }
  },
  {
    path: 'account-setup-wizard',
    name: 'Account Setup Wizard',
    title: 'Account Setup Wizard',
    redirect: '/account-setup-wizard/location-settings',
    component: AccountSetupWizard,
    meta: {
      requiresPermissionTo: MANAGE_ACCOUNT_SETTINGS
    },
    children: [
      {
        path: 'location-settings',
        name: 'Location Settings',
        title: 'Location Settings',
        component: SetupLocalisationSettings,
      },
      {
        path: 'project-statuses',
        name: 'Setup Project Statuses',
        title: 'Setup Project Statuses',
        component: SetupProjectStatuses,
      },
      {
        path: 'task-statuses',
        name: 'Setup Task Statuses',
        title: 'Setup Task Statuses',
        component: SetupTaskStatuses,
      },
      {
        path: 'account-defaults',
        name: 'Setup Account Defaults',
        title: 'Setup Account Defaults',
        component: SetupAccountDefaults,
      },
      {
        path: 'view-types',
        name: 'Setup View Types',
        title: 'Setup  View Types',
        component: SetupViewTypes,
      },
      {
        path: 'trial-custom-branding',
        name: 'Trial Custom Branding',
        title: 'Trial Custom Branding',
        component: TrialCustomBranding,
      },
      {
        path: 'setup-completed',
        name: 'Setup Completed',
        title: 'Setup Completed',
        component: SetupCompleted,
      },
    ]
  },
  {
    path: '/simulate-roles',
    name: 'Simulate Roles',
    component: SimulateRoles,
    meta: {
      requiresPermissionTo: SIMULATE_ROLES
    },
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: IntegrationsLayout,
    redirect: to => {
      return to.fullPath
    },
    meta: {
      requiresPermissionTo: MANAGE_ACCOUNT_SETTINGS
    },
    children: [
      {
        path: '',
        name: 'App Integrations',
        meta: {
          hideBreadCrumb: true,
        },
        component: Integrations,
      },
      {
        path: 'stripe-setup',
        name: 'Stripe Setup',
        component: StripeSetup,
      },
      {
        path: 'paypal',
        name: 'Paypal',
        component: Paypal,
      },
      {
        path: 'webhooks',
        name: 'Webhooks',
        component: Webhooks,
      },
      {
        path: 'zapier',
        name: 'Zapier App Directory',
        component: Zapier,
      },
      {
        path: 'public-api',
        name: 'Public API',
        component: PublicApi,
        meta: {
          requiresPermissionTo: CREATE_API_KEYS
        }
      },
    ]
  },
  {
    path: 'inbox',
    component: InboxLayout,
    name: 'Inbox',
    children: [
      {
        path: '',
        name: 'Account Inbox',
        component: Inbox,
        meta: {
          isViewType: true
        }
      },
    ]
  },
  {
    path: 'search',
    name: 'Search Results',
    component: Search,
  },
]
