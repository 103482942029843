<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <div class="align-middle overflow-hidden">
      <AddUserForm
        :defaultValues="defaultValues"
        :isDialogForm="true"
        @cancel="$emit('close')"
        @save="$emit('save', $event)"
      />
    </div>
  </BaseDialog>
</template>
<script>
import AddUserForm from "@/modules/users/components/AddUserForm.vue";

export default {
  name: 'AddUserDialog',
  inheritAttrs: false,
  components: {
    AddUserForm,
  },
  emits: ['save', 'close'],
  props: {
    title: String,
    task: {
      type: Object,
      default: () => ({})
    },
    defaultValues: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
