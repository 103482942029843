<template>
  <BaseDataTable
    :data="proofs"
    :loading="loading"
    :masterDetail="true"
    :columns="tableColumns"
    :detail-column-defs="detailColumnDefs"
    detailDataPath="relationships.reviewers"
  >
    <template #no-data>
      <NoProofs />
    </template>
  </BaseDataTable>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import NoProofs from "@/modules/file-proofs/components/NoProofs.vue";
import { useStore } from "vuex";
import { ColumnTypes } from "@/components/table/cells/tableCellComponents";

const store = useStore()

const proofs = computed(() => {
  return store.state.proofs.proofs.data
})

const loading = computed(() => {
  return store.state.proofs.loading.getAll
})

const tableColumns = computed(() => {
  return store.getters['proofs/tableColumns'] || []
})

const detailColumnDefs = computed(() => {
  return [
    {
      prop: '',
      autoHeight: true,
      component: ColumnTypes.ProofUser,
      minWidth: 400,
      colId: 'attributes.name'
    },
    {
      width: 110,
      component: ColumnTypes.ProofProgress,
      prop: 'attributes.progress',
      colId: 'attributes.progress'
    },
    {
      minWidth: 160,
      component: ColumnTypes.ProofStatus,
      prop: 'attributes.decision_status',
      colId: 'attributes.decision_status'
    }
  ]
})
</script>
