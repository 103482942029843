<template>
  <div class="discussion-comment-container">
    <div class="flex text-gray-500 text-sm relative">
      <div class="flex">
        <div class="shrink-0 mr-4">
          <div class="base-avatar h-10 w-10 text-4xl cursor-pointer">
            <BaseLogo
              :entity="$user"
              logo-key="avatar"
              name-key="first_name"
              size="md"
              disable-tooltip
            />
          </div>
        </div>
        <div class="overflow-x-hidden flex-1">
          <div class="flex flex-col space-x-0 sm:space-x-3 sm:flex-row">
            <div class="inline font-bold text-gray-700 text-base -mt-1">
              {{ $user.name }}
            </div>
            <div class="inline font-bold text-xs text-gray-400">
              <span>{{ formattedDate }}</span>
            </div>
          </div>
          <div
            contenteditable="false" translate="no" tabindex="0"
            class="prose max-w-none leading-6 prose-p:my-4 prose-ul:my-4 prose-ol:my-4 prose-li:my-0 prose-p:mt-2 prose-p:mb-0 prose-ul:mt-2 prose-ul:mb-0 prose-ol:mt-2 prose-ol:mb-0 focus:outline-none text-sm rounded-md text-gray-500"
          >
            <p v-html="htmlMessage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { formatDate } from "@/modules/common/utils/dateUtils";
import { ChatMessage } from "@/modules/ai/types/aiTypes";
import showdown from 'showdown'

const props = defineProps({
  message: {
    type: Object as PropType<ChatMessage>,
    default: '',
  },
})

const htmlConverter = new showdown.Converter({
  tables: true,
})

const messageText = computed(() => {
  return props.message.message || ''
})

const htmlMessage = computed(() => {
  return htmlConverter.makeHtml(messageText.value)
})

const formattedDate = computed(() => {
  return formatDate(props.message.date, null, true, true)
})

</script>
