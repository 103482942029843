<template>
  <BaseNoDataSection
    v-loading="loading"
    :title="$t('No Templates Found')"
    iconClass="fa-layer-group"
    :canCreate="can($actions.CREATE_PROJECT_TEMPLATES)"
  >
    <template #can-create-section>
      <div>{{ $t('A template is a reusable project that includes people, data & tools.')}}</div>
      <div>{{ $t('Templates save you time when setting up new projects.')}}</div>
      <div>{{ $t('Create templates for projects you create regularly.')}}</div>
      <div>
        <router-link
          to="/templates/add"
          class="hover:text-primary-500"
        >
          {{ $t('Create a new template') }}
          <i class="fa-solid fa-arrow-right" aria-hidden="true" />
        </router-link>
      </div>
      <div
        class="hover:text-primary-500 cursor-pointer"
        @click="triggerEntityCreate(entityTypes.TemplateGallery)"
      >
        {{ $t('Or, view the template gallery for some inspiration') }}
        <i class="fa-solid fa-arrow-right" aria-hidden="true" />
      </div>
    </template>
  </BaseNoDataSection>
  
  <div
    v-if="hasRecommendedTemplates && can($actions.CREATE_PROJECT_TEMPLATES)"
    class="mt-2"
  >
    <TemplateGallery
      recommendations
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import TemplateGallery from "@/modules/templateGallery/components/TemplateGallery.vue";

// Utils
import { computed } from 'vue'
import { useStore } from 'vuex'
import useEntityCrud from "@/modules/common/composables/useEntityCrud"

const {
  entityTypes,
  triggerEntityCreate
} = useEntityCrud()

const store = useStore()

const hasRecommendedTemplates = computed(() => {
  return store.getters['templateGallery/recommendedTemplates'].length > 0
})
</script>
