<template>
  <EntitySelect
    url="/restify/groups"
    labelKey="attributes.name"
    valueKey="id"
    :placeholder="$attrs.placeholder|| $t('Select group...')"
    :createInfoText="infoText"
    v-bind="$attrs"
  >
    <template #prefix>
      <slot name="prefix" />
    </template>

    <template #option="{ row }">
      <div class="flex items-center py-1">
        <BaseLogo :entity="row.originalValue" size="sm"/>
        <span class="ml-2 truncate">{{ row.label || row.value }}</span>
      </div>
    </template>
  </EntitySelect>
</template>
<script>
import EntitySelect from "@/components/form/EntitySelect.vue";

export default {
  components: {
    EntitySelect,
  },
  props: {
    showInfoText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    infoText() {
      if (!this.showInfoText) {
        return null
      }

      return this.$t('Create groups for every client company, internal department or team. People with a Collaborator or Collaborator Plus role can only see their group and can invite people to it.')
    }
  }
}
</script>
