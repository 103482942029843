<template>
  <div class="inline-flex items-center">
    <ProjectDatesTooltip
      :project="project"
      :dates="{ start_date, deadline, completed_at }"
      class="cursor-pointer inline-flex"
    >
      <div class="flex items-center">
        <BaseInlineDatePicker
          v-model="start_date_model"
          :placeholder="$t('Not Started')"
          :disabled="$attrs.disabled"
          clearable
          class="disabled-focus"
        />

        <div class="ml-4 mr-2">→</div>

        <BaseInlineDatePicker
          v-model="deadline_model"
          :placeholder="$t('No Deadline')"
          :disabled="$attrs.disabled"
          clearable
          class="disabled-focus"
        />
      </div>
    </ProjectDatesTooltip>
  </div>
</template>
<script>
import ProjectDatesTooltip from "@/modules/projects/components/ProjectDatesTooltip.vue";
import { XCircleIcon } from '@heroicons/vue/outline'

export default {
  components: {
    ProjectDatesTooltip,
    XCircleIcon
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    start_date: {
      type: [String, Date, Object],
      default: ''
    },
    deadline: {
      type: [String, Date, Object],
      default: ''
    },
    completed_at: {
      type: [String, Date, Object],
      default: ''
    },
  },
  data() {
    return {
      isInEditMode: false,
    };
  },
  computed: {
    start_date_model: {
      get() {
        return this.start_date;
      },
      set(value) {
        this.$emit("update:start_date", value);
      },
    },
    deadline_model: {
      get() {
        return this.deadline;
      },
      set(value) {
        this.$emit("update:deadline", value);
      },
    },
  },
};
</script>
<style lang="scss">
.group-scope:hover {
  .circle-remove {
    @apply inline;
  }
}
</style>
