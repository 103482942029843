<template>
  <BaseSelect
    v-model="model"
    :options="selectOptions"
    :placeholder="$t('Select Resource Type...')"
    :return-object="returnObject"
  >
    <template #prefix="{ selected }">
      <div class="flex items-center">
        <template v-if="selected">
          <img
            v-if="selected.image"
            :src="selected.image"
            class="mr-2 w-6 h-6"
          />
          <i
            v-else-if="selected.icon"
            :class="selected.icon" 
            class="mr-2 w-6"
            :style="{
              color: selected.color
            }"
          />
          <div class="text-gray-700">
            {{ selected.label }}
          </div>
        </template>
      </div>
    </template>
    <template #default="{ option }">
      <div class="flex items-center">
        <img
          v-if="option.image"
          :src="option.image"
          class="mr-2 w-6 h-6"
        />
        <i
          v-else-if="option.icon"
          :class="option.icon" 
          class="mr-2 w-6"
          :style="{
            color: option.color
          }"
        />
        <div>
          {{ option.label }}
        </div>
      </div>
    </template>
  </BaseSelect>
</template>
<script>
import { embedTypes } from "@/modules/resources/utils/embedUtils"
import { projectLinkTypes } from "@/modules/resources/utils/projectLinkUtils"
import { orderBy } from "lodash-es"

export default {
  name: "ResourceTypeSelect",
  props: {
    modelValue: {
      type: [Object, String],
      required: true,
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      genericOptions: [
        {
          value: 'reference',
          label: this.$t('Reference'),
          icon: 'fal fa-link',
          color: "#3B82F6",
        },
        {
          value: 'link',
          label: this.$t('Link'),
          icon: "fal fa-arrow-up-right-from-square",
          color: "#6B7280",
        }
      ]
    }
  },
  computed: {
    selectOptions() {
      return orderBy([
        ...embedTypes,
        ...projectLinkTypes,
        ...this.genericOptions
      ], 'label')
    },
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
};
</script>
