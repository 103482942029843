import App from './App.vue'
import store from './store'
import router from './router'
import { sync } from 'vuex-router-sync'
import { flare } from "@flareapp/flare-client";
// import { registerSW } from 'virtual:pwa-register'
import authMiddleware from "@/modules/auth/middleware/authMiddleware";
import subscriptionMiddleware from "@/modules/auth/middleware/subscriptionMiddleware";
import filtersMiddleware from "@/modules/auth/middleware/filtersMiddleware";
import createAppWithPlugins from "@/plugins/createAppWithPlugins.js";
import { isProduction } from '@/modules/common/config'

authMiddleware(router)
subscriptionMiddleware(router)
filtersMiddleware(router)
sync(store, router)

// Only enable Flare in production, we don't want to waste your quota while you're developing:

if (isProduction()) {
  function errorHandler(error) {
    // Ignored status codes that are actually handled by the application (e.g. validations which display error messages to the user)
    const ignoredStatusCodes = [400, 401, 403, 422, 429]
    const statusCode = error?.response?.status
    
    if (ignoredStatusCodes.includes(statusCode)) {
      return false
    }

    // Ignored strings are errors from imported libraries that we have no control over
    const ignoredStrings = [
      'domFromPos', // tiptap
      'Applying a mismatched transaction', // produktly
      'An attempt was made to use an object that is not, or is no longer, usable', // produktly
    ]

    if (ignoredStrings.some(str => error?.message?.includes(str))) {
      return false
    }
  }

  flare.beforeEvaluate(errorHandler)

  flare.beforeSubmit(errorHandler)

  flare.light()
}

const app = createAppWithPlugins(App)
// registerSW({ immediate: true })

window.postMessage({
  devtoolsEnabled: true,
  vueDetected: true
}, '*')

app.config.unwrapInjectedRef = true
app.mount('#app')

import './modules/common/apiConfig'
import './assets/index.scss'
import 'element-plus/theme-chalk/base.css'
import 'element-plus/theme-chalk/el-popper.css'
import 'element-plus/theme-chalk/el-carousel.css'
import 'element-plus/theme-chalk/el-carousel-item.css'
import 'element-plus/theme-chalk/el-image.css'
import 'element-plus/theme-chalk/el-image-viewer.css'
