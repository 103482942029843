<template>
  <div class="space-y-4">
    <h2 class="text-gray-900 text-3xl sm:text-4xl font-extrabold">
      {{ i18n.t(`Name your first project`) }}
    </h2>
    <p class="text-gray-500 leading-7 text-lg">
      {{ i18n.t(`Woooo hooo!! 🥳 🎉 You've created your account. What's next?`)}}
    </p>
    <p class="text-gray-500 leading-7 text-lg sm:hidden">
      {{ i18n.t(`Let's get you started by creating your first project.`)}}
    </p>
    <p class="text-gray-500 leading-7 text-lg hidden sm:block">
      {{ i18n.t(`Well... the most important part of Project.co is 'projects'. So, we'd like to take you through a quick step-by-step to help you create your first project.`)}}
    </p>
    <p class="text-gray-500 leading-7 text-lg">
      {{ i18n.t(`Give your project a descriptive name, like Brand Video or Lead Tracking, or simply To-Do List. It could be anything you’re working on currently.`)}}
    </p>
  </div>
  <div class="relative mt-1 flex items-center">
    <BaseInput
      v-model="projectName"
      name="project-name"
      id="project-name"
      class="w-full text-gray-500 placeholder:text-gray-400 leading-6 shadow-none text-base"
      inputClass="p-4"
      :placeholder="i18n.t('Mission to Mars')"
    />
  </div>
  <button
    type="button"
    class="mt-4 rounded-md body-background px-6 py-4 text-sm font-semibold text-white"
    :class="{
      'opacity-80 cursor-not-allowed': !projectName,
      'transition-all hover:px-8': projectName
    }"
    :disabled="!projectName"
    @click="onNext"
  >
    {{ i18n.t('Next') }}
    <i class="fa-solid fa-arrow-right ml-2" aria-hidden="true" />
  </button>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import i18n from '@/i18n'
import {
  generateProjectDescription,
} from '@/modules/accounts/utils/onboardingUtils'

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const onboardingModel = computed(() => {
  return store.state.accounts.onboardingModel
})

const projectName = computed({
  get() {
    return onboardingModel.value.projectName || ''
  },
  set(value: string) {
    store.commit('accounts/setOnboardingModel', {
      ...onboardingModel.value,
      projectName: value
    })
  }
})

const projectDescription = computed(() => {
  return onboardingModel.value.projectDescription
})

async function onNext() {
  if (!projectDescription.value) {
    generateProjectDescription(projectName.value)
  }

  router.push('/onboarding/add-project-tools')
}
</script>
