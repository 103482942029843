<template>
  <div v-if="availableOptions.length || showEmpty">
    <el-dropdown
      ref="dropdown"
      type="primary"
      :popper-class="`custom-dropdown-popper ${$attrs.customPopperClass} ${divideItems ? 'divide-items' : ''}`"
      :placement="placement"
      :trigger="trigger"
      :disabled="$attrs.disabled"
      :split-button="splitButton"
      :class="$attrs.class"
      @visible-change="onVisibleChanged"
    >
      <slot>
        <BaseButton
          variant="white"
          :size="buttonSize"
        >
          <div class="flex items-center">
            <span v-if="label">{{ label }}</span>
            <i
              v-else
              class="fas fa-ellipsis-h"
              :class="{ 'mt-1 ml-2': !slim }"
            />
            <i v-if="!slim" class="fas fa-chevron-down mt-1 ml-2 opacity-50" />
          </div>
        </BaseButton>
      </slot>
    <template #dropdown>
      <el-dropdown-menu
        class="py-1 max-h-[500px] overflow-auto"
      >
        <slot name="menu-header" />
        <template
          v-for="(option, index) of availableOptions"
          :key="option.action"
        >
          <BaseTooltip
            :content="option.tooltip"
            :disabled="!option.tooltip"
          >
            <el-dropdown-item
              :disabled="option.disabled"
              :variant="option.variant"
              :class="{
                'first-option': index === 0,
              }"
              @click="onOptionSelected(option, $event)"
            >
              <component
                :is="getComponent(option)"
                v-bind="getOptionAttributes(option)"
                class="flex items-center group"
                :class="{
                  'px-4 py-2': !largeItems,
                  'px-4 py-3': largeItems,
                  'flex-row-reverse reverse-option': option?.reverse,
                }"
              >
                <slot name="option" :option="option">
                  <i
                    v-if="option.variant === 'active'"
                    class="fas opacity-60 fa-check"
                  />
                  <i
                    v-if="option.icon"
                    :class="option.icon"
                  />
                  <div v-html="option.label" />
                  <BaseNewFeatureChip
                    v-if="option.isNewFeature"
                    class="ml-2"
                  />
                  <i
                    v-if="isOptionLimitReached(option)"
                    class="fas fa-crown text-yellow-500 ml-2"
                  />
                </slot>
              </component>
            </el-dropdown-item>
          </BaseTooltip>
        </template>
        <slot name="custom-options" />
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  </div>
</template>

<script>
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus'
import {
  useAccountLimits,
} from "@/modules/auth/composables/useAccountLimits";

export default {
  name: 'BaseDropdown',
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String
    },
    slim: {
      type: Boolean,
      default: false
    },
    trigger: {
      type: String,
      default: 'click' // hover/click/contextmenu
    },
    splitButton: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom-end'
    },
    buttonSize: {
      type: String,
      default: 'sm'
    },
    divideItems: {
      type: Boolean,
      default: false
    },
    largeItems: {
      type: Boolean,
      default: false
    },
    autoOpen: {
      type: Boolean,
      default: false
    },
    showEmpty: {
      type: Boolean,
      default: false
    }
  },
  emits: ['action', 'option-selected', 'update:is-visible'],
  setup() {
    const {
      openUpgradeDialog,
      hasReachedLimit
    } = useAccountLimits()

    return {
      openUpgradeDialog,
      hasReachedLimit
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  computed: {
    availableOptions() {
      return this.options.filter(option => {
        if (option.enabled && typeof option.enabled === 'function') {
          return option.enabled()
        }

        return !option.requiresPermissionTo || this.can(option.requiresPermissionTo)
      })
    }
  },
  methods: {
    isOptionLimitReached(option) {
      if (!option.limitedFeature) {
        return false
      }

      return this.hasReachedLimit(option.limitedFeature)
    },
    getComponent(option) {
      if (!option.path || this.isOptionLimitReached(option)) {
        return 'div'
      }

      if (option.target) {
        return 'a'
      }

      return 'router-link'
    },
    getOptionAttributes(option) {
      const attrs = { }

      if (!option.path) {
        return attrs
      }

      if (option.target) {
        attrs.target = option.target
        attrs.href = option.path
      }
      else {
        attrs.to = option.path
      }

      return attrs
    },
    onOptionSelected(option, event) {
      if (this.isOptionLimitReached(option)) {
        return this.openUpgradeDialog(option.limitedFeature)
      }

      // Path is handled by router-link / anchor tag
      if (option.path || option.disabled) {
        return
      }

      if (option.action) {

        if (typeof option.action === 'function') {
          return option.action(event)
        }

        return this.$emit('action', option.action)
      }

      this.$emit('option-selected', option)
    },
    onVisibleChanged(value) {
      this.isVisible = value
      this.$emit('update:is-visible', value)
    },
    async showDropdown() {
      this.isVisible = true

      await this.$nextTick()

      this.open()
    },
    open() {
      this.$refs.dropdown?.handleOpen()
    },
  },
  mounted() {
    if (this.autoOpen) {
      this.showDropdown()
    }
  }
};
</script>
<style lang="scss">

.custom-dropdown-popper {
  margin-top: -10px !important;
  transition: none !important;

  @apply rounded-md shadow-lg bg-white;


  .el-dropdown-menu__item {
    @apply whitespace-nowrap block px-0 py-0 text-sm text-gray-700;

    .reverse-option {
      justify-content: start;
    }

    &:not(.is-disabled) {
      @apply hover:bg-gray-100 hover:text-gray-900 cursor-pointer;

      &[variant=danger] {
        @apply text-red-500 hover:bg-red-500 hover:text-white;
      }

      &[variant=active] {
        @apply bg-primary-500 text-white;
      }
    }

    i {
      @apply w-5;
    }
  }

  &.divide-items {
    .el-dropdown-menu {
      .el-dropdown-menu__item:not(.first-option) {
        @apply border-t border-gray-200;
      }
    }
  }

  .el-tooltip__trigger:last-of-type {
    .el-dropdown-menu__item {
      @apply rounded-b-md;
    }
  }

  .el-scrollbar__view {
    @apply rounded-md shadow-lg bg-white;
  }

  .el-dropdown-menu {
    @apply min-w-[160px] w-full rounded-md shadow-lg bg-white py-0;
  }
  .el-popper__arrow {
    display: none !important;
  }
}
</style>
