<template>
  <div class="grid sm:grid-cols-2 gap-6 pb-4 sm:pb-6">
    <component
      v-for="widget in orderedWidgets"
      :key="widget.component"
      :is="widgetComponents[widget.component]"
      :widget="widget"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import PinnedItemsWidget from "@/modules/dashboard/components/widgets/PinnedItemsWidget.vue"
import ProjectsWidget from "@/modules/dashboard/components/widgets/ProjectsWidget.vue"
import TasksWidget from "@/modules/dashboard/components/widgets/TasksWidget.vue"
import TimeWidget from "@/modules/dashboard/components/widgets/TimeWidget.vue"
import PaymentsWidget from "@/modules/dashboard/components/widgets/PaymentsWidget.vue"
import FilesWidget from "@/modules/dashboard/components/widgets/FilesWidget.vue"

// Utils
import { computed, inject } from "vue"
import { orderBy } from "lodash-es"
import {
  WidgetsContext,
  DashboardWidgetType,
  Widgets,
  getUserWidgets,
  getGroupWidgets,
  WidgetContextPages,
  DashboardModel,
  applyWidgetPermissionsCheck,
  applyScopedSystemDefaultWidgetFilters,
} from "@/modules/dashboard/utils/widgetUtils"
import { UserTypes } from "@/modules/users/util/userUtils"
import Data = API.Data

// Composables
import { useStore } from "vuex"

const store = useStore()

const widgetComponents: Partial<Record<Widgets, any>> = {
  [Widgets.PinnedItems]: PinnedItemsWidget,
  [Widgets.Projects]: ProjectsWidget,
  [Widgets.Tasks]: TasksWidget,
  [Widgets.Time]: TimeWidget,
  [Widgets.Payments]: PaymentsWidget,
  [Widgets.Files]: FilesWidget,
}

const context = inject<WidgetsContext>('context', {
  page: WidgetContextPages.HomeDashboard,
})

const activeDashboard = computed<Data<DashboardModel>>(() => {
  return store.getters['dashboard/activeDashboard']
})

const widgets = computed<DashboardWidgetType[]>(() => {
  let widgetsList = []

  if (context?.page === WidgetContextPages.GroupProfile) {
    widgetsList = getGroupWidgets(context?.group!.value)
  }
  else if (context?.page === WidgetContextPages.UserProfile) {
    widgetsList = getUserWidgets(context?.user!.value)
  }
  else {
    widgetsList = applyWidgetPermissionsCheck(activeDashboard.value?.attributes?.widgets || [])
    if (!activeDashboard.value?.id) {
      const scope = store.getters['users/isCollaboratorUser']
        ? UserTypes.Collaborators
        : UserTypes.Creators

      widgetsList = applyScopedSystemDefaultWidgetFilters(widgetsList, scope)
    }
  }

  return widgetsList.filter((widget: DashboardWidgetType) => {
    return widgetComponents[widget.component]
  })
})

const isSubscriptionValid = computed(() => {
  return store.getters['accounts/isValidSubscription']
})

const orderedWidgets = computed(() => {
  if (!isSubscriptionValid.value) {
    return []
  }

  return orderBy(widgets.value, 'order')
})

</script>
