// Libs
import axios from "axios";
import { groupBy } from 'lodash-es'

// Helpers
import { removeFromListById } from "@/modules/common/utils/listUtils.js";
import config from "@/modules/common/config.js";
import { projectResourceTypes } from "@/modules/resources/utils/projectResourceUtils.js";

import { resourceColumns, optionsColumn } from "@/modules/resources/utils/resourceTableUtils"
import { columnBuilder } from '@/components/table/tableUtils'

const state = () => ({
  resources: {
    data: [],
  },
  resourcesLoading: false,
  currentEmbedLoading: false,
  currentEmbed: null,
  resourceColumns,
  addEmbedTrigger: 1
})

const mutations = {
  setResources(state, value) {
    state.resources = value
  },
  deleteResource(state, id) {
    removeFromListById(state.resources.data, id)
  },
  setResourcesLoading(state, value) {
    state.resourcesLoading = value
  },
  setCurrentEmbedLoading(state, value) {
    state.currentEmbedLoading = value
  },
  setCurrentEmbed(state, value) {
    state.currentEmbed = value
  },
  triggerAddEmbed(state) {
    state.addEmbedTrigger++
  },
}

const actions = {
  async getResources({ commit, rootGetters, rootState }, filters) {
    try {
      if (filters?.shouldReset === true) {
        commit('setResources', { data: [] })
      }
      filters = filters || {...rootState.route?.query }

      delete filters.shouldReset
      commit('setResourcesLoading', true)
      const resources = await axios.get('/project-resources', {
        params: {
          ...filters,
          project_id: rootGetters.project_id
        }
      })
      commit('setResources', resources)
    } finally {
      commit('setResourcesLoading', false)
    }
  },
  async createEmbed({}, { data }) {
    try {
      const url = `/restify/project-embeds`
      return await axios.post(url, data)
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async editEmbed({}, { embedId, data }) {
    try {
      const url = `/restify/project-embeds/${embedId}`
      return await axios.put(url, data)
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async deleteResource({ commit }, { resource }) {
    try {
      
      const urls = {
        [projectResourceTypes.ProjectLink]: `/restify/project-links/${resource.id}`,
        [projectResourceTypes.ProjectReferences]: `/restify/project-references/${resource.id}`,
        [projectResourceTypes.ProjectEmbeds]: `/restify/project-embeds/${resource.id}`,
      }

      await axios.delete(urls[resource.type]);
      commit('deleteResource', resource.id)
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async getEmbedById({ state, commit }, { id, forceFetch }) {
    try {
      commit('setCurrentEmbedLoading', true)
      const resource = state.resources.data.find(p => p?.type === projectResourceTypes.ProjectEmbeds && p?.id?.toString() === id?.toString())
      if (resource && !forceFetch) {
        commit('setCurrentEmbed', resource)
      } else {
        const { data } = await axios.get(`/restify/project-embeds/${id}`)
        commit('setCurrentEmbed', data)
      }
    } finally {
      commit('setCurrentEmbedLoading', false)
    }
    
  },
  async setResourcePinned({ commit }, { resource, value }) {
    try {
      const urls = {
        [projectResourceTypes.ProjectLink]: `/restify/project-links/${resource.id}/actions?action=change-link-pinned`,
        [projectResourceTypes.ProjectReferences]: `/restify/project-references/${resource.id}/actions?action=change-reference-pinned`,
        [projectResourceTypes.ProjectEmbeds]: `/restify/project-embeds/${resource.id}/actions?action=change-embed-pinned`,
      }

      const result = await axios.post(urls[resource.type], {
        pinned: value
      })
      resource.attributes.pinned = value
      return result
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async convertUrlToEmbed({ }, { src, ...otherOptions }) {
    const result = await axios.get(`${config.iFramelyApiUrl}/iframely`, {
      params: {
        url: src,
        api_key: config.iFramelyKey,
        omit_script: 1,
        ...otherOptions,
      }
    })
    if (result.error)  {
      result.error = result.error.replaceAll('Iframely', 'us')
    }
    return result
  }
}

const getters = {
  activeColumns: (state) => {
    
    let columns = [...state.resourceColumns]

    columnBuilder.addCustomColumns(columns, [optionsColumn])

    return columns
  },
  tableColumns: (state, getters) => {
    return getters.activeColumns.filter(c => c.visibleInTable !== false)
  },
  groupedResources: (state, getters, rootState, rootGetters) => {
    const groupByKey = rootGetters['filters/targetLocalFilters']('resources')?.groupBy?.prop
    const groupedResources = groupBy(state.resources.data, groupByKey)
    if (Object.keys(groupedResources).length === 0) {
      groupedResources.default = []
    }

    if (groupByKey !== 'attributes.type') {
      return groupedResources 
    }

    const groupResourceWithLabels = {}

    for (const group in groupedResources) {
      const groupLabel = group
      groupResourceWithLabels[groupLabel] = groupedResources[group]
    }

    return groupResourceWithLabels
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
