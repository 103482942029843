<template>
  <BaseSelect
    :options="options"
    :labelPrefix="$t('Sort by:')"
    :placeholder="$t('Sort by: Choose sort by field...')"
    :returnObject="false"
  />
</template>
<script lang="ts" setup>
import { TableColumn } from "@/components/table/tableUtils";
import i18n from "@/i18n";
import { PropType, computed } from "vue";

const props = defineProps({
  columns: {
    type: Object as PropType<TableColumn[]>,
    required: true,
  },
})

const options = computed(() => {
  const opts: { value: string, label: string } [] = []

  props.columns.forEach(c => {
    const name = typeof c.name === 'function'
      ? c.name()
      : c.name

    opts.push({
      value: `${c.sortProp}|desc`,
      label: `${name} - ${i18n.t('Descending')}`
    })

    opts.push({
      value: `${c.sortProp}|asc`,
      label: `${name} - ${i18n.t('Ascending')}`
    })
  })
  
  return opts
})
</script>
