<template>
  <div>
    <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
      <div class="mb-4">
        <img
          class="m-auto mb-2 h-10"
          :src="logoSrc"
          alt="Project.co"
        >
        <BasePageTitle>
          {{$t('Reset password')}}
        </BasePageTitle>
        <p class="mt-2 text-center text-sm text-gray-600 max-w">
          {{$t('Or')}}
          <router-link to="/login" class="font-medium text-primary-500 hover:text-primary-600">
            {{$t('sign in into your account')}}
          </router-link>
        </p>
      </div>
      <VeeForm v-slot="{ handleSubmit, errors, meta }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="space-y-4"
        >
          <BaseInput
            v-model="model.email"
            :label="$t('Email address')"
            :placeholder="$t('Email address')"
            :name="$t('Email address')"
            layout="horizontal"
            rules="required|email"
            id="email"
            type="email"
          />

          <div>
            <BaseButton
              :disabled="!meta.valid"
              type="submit"
              block
              :loading="loading"
            >
              {{ $t('Reset Password') }}
            </BaseButton>
          </div>
        </form>
      </VeeForm>
    </div>
  </div>
</template>
<script>
import AuthService from '@/modules/auth/services/AuthService.js';
import { logoDarkFull } from '@/modules/accounts/utils/accountBrandingUtils'

export default {
  data() {
    return {
      loading: false,
      model: {
        email: '',
      },
    };
  },
  computed: {
    customLogo() {
      return this.$settings('login_page_logo')
    },
    logoSrc() {
      return this.customLogo || logoDarkFull.value
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        await AuthService.forgotPassword(this.model)
        this.$success(this.$t(`We've sent you an email with further instructions!`))
      } catch (e) {
        if (e.handled) {
          return
        }
        this.$error(this.$t('Something went wrong please try again.'))
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
