<template>
  <div
    ref="containerRef"
    class="nuvo-importer-container"
    :data-uuid="importerUUID"
  >
    <NuvoImporter
      :key="entityType"
      :settings="settings"
      :licenseKey="licenseKey"
      :onResults="onResults"
      :columnHooks="columnHooks"
    />
  </div>
</template>
<script lang="ts" setup>
import { PropType, computed, ref } from 'vue';
import i18n from '@/i18n';
import {
  NuvoImporter,
  SettingsAPI,
  ResultValues,
  ErrorValues,
  ImportLogs,
  RejectSubmitResult,
  PassSubmitResult,
} from 'nuvo-vuejs'

import {
  importEntityTypes,
  importEntryStatuses,
} from '@/modules/accounts/utils/importUtils';

import {
  uuid
} from "@/modules/common/utils/commonUtils";

import {
  PostProcessFunction,
  CompleteFunction,
  useDataImporter,
  CustomFieldImport,
  EntityImportOptions,
} from '@/modules/accounts/composables/useDataImporter';

const {
  entityImporters,
  defaultImporterSettings,
  startImport,
  getImportEntries,
  handleImportFinished
} = useDataImporter()

// @ts-ignore
const licenseKey = import.meta.env.VITE_NUVO_LICENSE_KEY

const importerUUID = ref(uuid())

const containerRef = ref<HTMLElement | null>(null)

const props = defineProps({
  entityType: {
    type: String as PropType<importEntityTypes>,
    required: true
  },
  importOptions: {
    type: Object as PropType<EntityImportOptions>,
    default: () => ({})
  }
})

const entityImporter = computed(() => {
  return entityImporters.value[props.entityType]
})

const settings = computed<SettingsAPI>(() => {
  return {
    ...defaultImporterSettings,
    ...entityImporter.value.settings,
  } as SettingsAPI
})

const postProcessing = computed<PostProcessFunction | undefined>(() => {
  return entityImporter.value.postProcessing
})

const columnHooks = computed(() => {
  return entityImporter.value.columnHooks || {}
})

async function onResults(
  results: ResultValues,
  errors: ErrorValues,
  complete: CompleteFunction,
  logs: ImportLogs
) {

  if (importerUUID.value !== containerRef.value?.dataset?.uuid) {
    return
  }

  // Post process results
  if (postProcessing.value) {
    const {
      updatedResults,
      updatedErrors,
      rejected
    } = postProcessing.value(results, errors, complete, logs)

    if (rejected) {
      return
    }

    results = updatedResults
    errors = updatedErrors
  }

  if (errors.length || !results.length) {
    complete(new RejectSubmitResult(
      i18n.t(`Error importing data`),
      i18n.t(`Something went wrong while starting the import. Please try again.`)
    ))

    return
  }

  const custom_fields: CustomFieldImport[] = (logs?.columns?.addedColumns || []).map((column) => {
    return {
      key: column.key,
      name: column.label,
      type: column.columnType,
    }
  })

  try {
    await startImport({
      import_type: props.entityType,
      data: results,
      options: {
        ...props.importOptions,
        custom_fields,
      }
    })

    complete(new PassSubmitResult({
      title: i18n.t(`Import completed successfully`),
      text: i18n.t(`Your data has been imported successfully.`),
      successfulRecords: results.length,
      failedRecords: errors.length,
      imageUrl: '',
      duration: 10000,
    }))

    handleImportFinished({
      import_type: props.entityType,
      import_status: importEntryStatuses.Successful
    })

    getImportEntries()
  }
  catch (error: any) {
    // TODO: Update logic if BE changes error response format
    const errObject = error.response?.data?.errors || {}
    const errors =  []

    for (let key in errObject) {
      let err = errObject[key]?.[0]

      const regex = /data\.(\d+)\.(\w+)/
      const match = err.match(regex)

      if (match) {
        const index = parseInt(match[1]) + 1
        err = `Row ${index}: ${err.replace(/data\.(\d+)\./, '')}`
      }

      errors.push(err)
    }

    if (!errors.length && error.response?.data?.message) {
      errors.push(error.response.data.message)
    }

    const errorMessage = errors.length
      ? `<p class="whitespace-pre">${errors.join(`\n`)}</p>`
      : i18n.t(`Something went wrong while starting the import. Please try again.`)

    complete(new RejectSubmitResult(
      i18n.t(`Error importing data`),
      errorMessage
    ))
  }
}
</script>
