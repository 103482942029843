<template>
  <BaseDialog
    v-model="visible"
    v-show="visible"
  >
    <div class="w-full border-b border-gray-200 p-6">
      <h3 class="text-2xl font-bold">
        {{ $t("Edit Note Title") }}
      </h3>
    </div>
    <div class="p-6">
      <BaseInput
        v-model="title"
        v-focus
        :label="$t('Note title')"
        @keydown.enter.stop="saveTitle"
        :placeholder="$t('Please choose a title for your note')"
        layout="horizontal"
      />
    </div>
     <div class="px-6 py-4 border-t border-gray-200">
      <div class="flex justify-end">
        <BaseButton
          variant="white"
          @click="cancelChange"
        >
          {{ $t("Cancel") }}
        </BaseButton>
        <BaseButton
          type="submit"
          class="ml-2"
          @click="saveTitle"
        >
          {{ $t("OK") }}
        </BaseButton>
      </div>
    </div>
  </BaseDialog>
</template>
<script>
import i18n from "@/i18n";
export default {
  name: 'ProjectNoteSetTitleDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    }
  },
  emits: ['ok', 'cancel', 'update:modelValue'],
  data() {
    return {
      title: ''
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    currentProjectNote() {
      return this.$store.state.projects.currentProjectNote
    },
    initTitle() {
      return this.currentProjectNote?.attributes?.name
    }
  },
  methods: {
    cancelChange() {
      this.visible = false
      this.title = this.initTitle
      this.$emit('cancel')
    },
    saveTitle() {
      this.visible = false

      if (this.create) {
        this.$store.commit('projects/setCurrentProjectNote', {
         attributes: {
           title: this.title
         }
        })
        this.$router.push({ name: 'Project Notes' })
        return;
      }
      this.$emit('ok', this.title)
    }
  },
  watch: {
    initTitle: {
      immediate: true,
      handler() {
        if (this.initTitle === i18n.t('Untitled')) {
          return
        }

        this.title = this.initTitle
      }
    }
  }
}
</script>
