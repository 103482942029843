<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-3xl"
    @close="$emit('close')"
  >
    <PaymentForm
      :payment="payment"
      :isDialogForm="true"
      @cancel="$emit('close')"
      @save="$emit('save', $event)"
    />
  </BaseDialogNew>
</template>
<script>
import PaymentForm from "@/modules/payments/components/PaymentForm.vue";

export default {
  name: 'PaymentDialog',
  inheritAttrs: false,
  components: {
    PaymentForm,
  },
  emits: ['save', 'close'],
  props: {
    payment: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    title() {
      if (this.payment.id) {
        return this.$t('Edit Payment Request')
      }

      return this.$t('New Payment Request')
    }
  }
}
</script>
