<template>
  <footer class="bg-white border-gray-200 border-t shadow fixed print:hidden bottom-0 w-full block md:hidden px-6 md:pb-6 app-footer">
    <div class="relative flex justify-between py-4">
      <!--HOME: START-->
      <div class="flex">
        <div class="shrink-0 flex items-center text-2xl text-gray-500">
          <router-link to="/welcome">
            <i class="fas fa-home"></i>
          </router-link>
        </div>
      </div>
      <!--HOME: END-->

      <!--PROJECTS: START-->
      <div class="flex">
        <div class="shrink-0 flex items-center text-2xl text-gray-500">
          <router-link to="/projects">
            <i class="fas fa-inbox"></i>
          </router-link>
        </div>
      </div>

      <CreateNewMenu
        allow-star
      />

      <!--TASKS: START-->
      <div class="flex">
        <div class="shrink-0 flex items-center text-2xl text-gray-500">
          <router-link to="/tasks">
            <i class="fas fa-badge-check"></i>
          </router-link>
        </div>
      </div>
      <!--TASKS: END-->

      <!--PROFILE: START-->
       <div class="flex">
        <div
          class="shrink-0 flex items-center text-2xl text-gray-500"
          @click="openStarredPages"
        >
          <i class="fas fa-star" />
        </div>
      </div>
      <!--PROFILE: END-->
    </div>
  </footer>
</template>
<script>
import CreateNewMenu from "@/modules/common/components/CreateNewMenu.vue"

export default {
  components: {
    CreateNewMenu
  },
  methods: {
    openStarredPages() {
      this.$store.commit('accounts/triggerOpenStarredPages')
    }
  }
}
</script>
