<template>
  <BaseCardsView
    :target="EntityTargetTypes.PAYMENTS"
    :loading="paymentsLoading"
    dataGetterPath="payments/currentPayments"
    groupedDataGetter="payments/groupedPayments"
    columnsGetter="payments/tableColumns"
    :getQuickFilterText="(payment: any) => payment?.attributes?.description"
  >
    <template #no-data>
      <NoPayments
        class="bg-white"
        :class="{
          'border-gray-200 border shadow': !$useNewLayout
        }"
      />
    </template>
  </BaseCardsView>
</template>
<script lang="ts" setup>
// Components
import NoPayments from "@/modules/payments/components/NoPayments.vue";

// Libs
import { computed } from "vue";

// Utils
import { EntityTargetTypes } from '@/modules/common/utils/filterUtils'

// Composables
import { useStore } from "vuex";

const store = useStore()

const paymentsLoading = computed(() => {
  return store.state.payments.paymentsLoading
})
</script>
