<template>
  <BaseDropdown
    :options="availableOptions"
    buttonSize="xs"
    slim
    @action="onAction"
  />
</template>
<script>

export default {
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    openedNotification: {
      get() {
        return this.$store.state.notifications.openedNotification
      },
      set(value) {
        this.$store.commit('notifications/setOpenedNotification', value)
      },
    },
    selectedNotifications: {
      get() {
        return this.$store.state.notifications.selectedNotifications
      },
      set(value) {
        this.$store.commit('notifications/setSelectedNotifications', value)
      },
    },
    hasSelection() {
      return this.selectedNotifications?.length > 0
    },
    hasUnpinnedSelections() {
      return this.selectedNotifications?.some(x => !x.is_pinned)
    },
    hasUnreadSelections() {
      return this.selectedNotifications?.some(x => x.unread_notification_count)
    },
    options() {
      const markAsPinnedState = {
        label: this.hasUnpinnedSelections
          ? this.$t('Pin Selected')
          : this.$t('Unpin Selected')
      }

      return [
        {
          label: markAsPinnedState.label,
          action: 'toggleSelectionPinned',
          enabled: () => {
            return this.hasSelection
          },
        },
        {
          label: this.$t('Mark Selected as Read'),
          action: 'markSelectionRead',
          enabled: () => {
            return this.hasUnreadSelections
          },
        },
        {
          label: this.$t('Clear Selection'),
          action: 'clearSelection',
          enabled: () => {
            return this.hasSelection
          },
        },
        {
          label: this.$t('Select All'),
          action: 'selectAll',
        },
      ]
    },
    availableOptions() {
      return this.options.filter(x => {
        if (x.enabled) {
          return x.enabled()
        }

        return true
      })
    },
    notificationFilters() {
      return this.$route.query
    },
  },
  methods: {
    onAction(action) {
      if (this[action]) {
        this[action]()
        return
      }

      throw new Error('Action not defined ' + action)
    },
    async markSelectionRead() {
      try {
        this.$emit('processing-bulk-action', true)

        const projectIds = this.selectedNotifications.map(notification => notification.id)

        for (const projectId of projectIds) {
          await this.$store.dispatch('notifications/markAllProjectNotificationsAsRead', projectId)
        }

        await this.$store.dispatch('notifications/getNotifications', {
          filters: {
            ...this.notificationFilters
          },
          silent: true,
        })

      }
      catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not complete the operation. Please try again later.'))
      }
      finally {
        this.$emit('processing-bulk-action', false)
      }
    },
    async toggleSelectionPinned() {
      const is_pinned = this.hasUnpinnedSelections

      this.selectedNotifications.forEach(notification => {
        notification.is_pinned = is_pinned
      })

      const projectIds = this.selectedNotifications.map(notification => notification.id)

      await this.$store.dispatch('notifications/setProjectPinned', {
        projectIds,
        is_pinned,
      })
    },
    selectAll() {
      this.selectedNotifications = this.notifications
    },
    clearSelection() {
      this.selectedNotifications = []
    },
  },
}
</script>
