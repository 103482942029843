// Libs
import i18n from "@/i18n.js"
import { computed } from "vue"

// Helpers
import { getMyTasksFilterAppliedQuery } from '@/modules/tasks/utils/modelUtils';
import { rolesEnum } from "@/modules/common/utils/isRoleGreaterOrEqual.js";
import { getSetting } from "@/plugins/settingsPlugin";

// Composables
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import useCan from "@/modules/common/composables/useCan";
import useLayout from "@/modules/common/composables/useLayout";

export function useNavigationCards() {
  const store = useStore()
  const route = useRoute()
  const { can, canRole, actions, isRoleGreaterOrEqual } = useCan();
  const {
    useNewLayout
  } = useLayout()

  const projectsPath = computed(() => {
    const viewOptions = store.getters['users/defaultTargetViewOptions']('projects')
    return `/projects/${viewOptions.view_type}`
  })

  const templatesPath = computed(() => {
    const viewOptions = store.getters['users/defaultTargetViewOptions']('templates')
    return `/templates/${viewOptions.view_type}`
  })

  const tasksPath = computed(() => {
    const viewOptions = store.getters['users/defaultTargetViewOptions']('tasks')
    return {
      path: `/tasks/${viewOptions.view_type}`,
      query: getMyTasksFilterAppliedQuery()
    }
  })
  
  const canCollaboratorsViewPayments = computed(() => {
    return getSetting('show_payments_card_to_collaborators', true)
  })

  const canViewPayments = computed(() => {
    if (isRoleGreaterOrEqual(rolesEnum.CREATOR)) {
      return true
    }

    return canCollaboratorsViewPayments.value
  })

  const cards = computed(() => {
    return [
      {
        title: i18n.t('Dashboard'),
        id: 'sidebar-dashboard',
        description: i18n.t('The welcome page is a great place to start.'),
        iconClass: 'fa-house',
        colorClass: 'text-gray-500',
        path: '/welcome',
        isActive: route.path.startsWith('/welcome') || route.path.startsWith('/edit-dashboards'),
        enabled() {
          return useNewLayout.value
        }
      },
      {
        title: i18n.t('Projects'),
        id: 'sidebar-projects',
        description: i18n.t('Every project is a set of tools to help you organise your work.'),
        iconClass: 'fa-inbox',
        colorClass: 'text-purple-500',
        path: projectsPath.value,
        isActive: route.path.startsWith('/projects'),
        createRoute: can(actions.CREATE_PROJECTS) ? '/projects/add' : null,
      },
      {
        title: i18n.t('Templates'),
        id: 'sidebar-templates',
        description: i18n.t('Start new projects without setting things up from scratch.'),
        iconClass: 'fa-layer-group',
        colorClass: 'text-pink-500',
        path: templatesPath.value,
        requiresPermissionTo: actions.ACCESS_TEMPLATES_LIST,
        isActive: route.path.startsWith('/templates'),
        displayAclIndicator() {
          return !canRole(rolesEnum.COLLABORATOR, actions.ACCESS_TEMPLATES_LIST)
        },
        createRoute: can(actions.CREATE_PROJECT_TEMPLATES) ? '/templates/add' : null,
      },
      {
        title: i18n.t('Tasks'),
        id: 'sidebar-tasks',
        description: i18n.t('Manage the things you need to do to progress your projects.'),
        iconClass: 'fa-badge-check',
        colorClass: 'text-yellow-500',
        path: tasksPath.value,
        isActive: route.path.startsWith('/tasks'),
        createRoute: can(actions.CREATE_TASKS) ? '/tasks/add' : null,
      },
      {
        title: i18n.t('People'),
        id: 'sidebar-people',
        description: i18n.t('Invite people so you can collaborate and get things done.'),
        iconClass: 'fa-users',
        colorClass: 'text-red-500',
        path: '/users',
        isActive: route.path.startsWith('/users'),
        enabled() {
          // TODO: TEMP
          return can(actions.CREATE_PROJECTS) || (store.state.auth?.user?.groups || []).length
        },
        createRoute: can(actions.INVITE_NEW_PEOPLE) ? '/users/add' : null,
      },
      {
        title: i18n.t('Groups'),
        id: 'sidebar-groups',
        description: i18n.t('Group people with a common interest such as a company, team or department.'),
        iconClass: 'fa-ball-pile',
        colorClass: 'text-green-500',
        path: '/groups',
        isActive: route.path.startsWith('/groups'),
        enabled() {
          // TODO: TEMP
          return can(actions.CREATE_PROJECTS) || (store.state.auth?.user?.groups || []).length
        },
        createRoute: can(actions.CREATE_GROUPS) ? '/groups/add' : null,
      },
      {
        title: i18n.t('Files'),
        id: 'sidebar-files',
        description: i18n.t("View files that have been uploaded to projects you're part of."),
        iconClass: 'fa-file',
        colorClass: 'text-blue-500',
        path: '/files',
        isActive: route.path.startsWith('/files'),
        createRoute: can(actions.CREATE_PROJECT_FILES) ? '/files/add' : null,
      },
      {
        title: i18n.t('Payments'),
        id: 'sidebar-payments',
        description: i18n.t("View payment requests and invoices that are on projects you're part of."),
        iconClass: 'fa-money-bill',
        colorClass: 'text-green-500',
        path: '/payments',
        enabled() {
          return canViewPayments.value
        },
        isActive: route.path.startsWith('/payments'),
        displayAclIndicator() {
          return !canCollaboratorsViewPayments.value
        },
        createRoute: can(actions.CREATE_PAYMENTS) ? '/payments/add' : null,
      },
      {
        title: i18n.t('Time'),
        id: 'sidebar-time',
        description: i18n.t('Allocate and record time to see where time is being spent on projects.'),
        iconClass: 'fa-stopwatch',
        colorClass: 'text-pink-500',
        path: '/time/actual',
        requiresPermissionTo: actions.VIEW_TIME_ENTRIES,
        isActive: route.path.startsWith('/time'),
        displayAclIndicator() {
          return !canRole(rolesEnum.COLLABORATOR, actions.VIEW_TIME_ENTRIES)
        },
        createRoute: can(actions.CREATE_TIME_ENTRIES) ? '/time/add' : null,
      },
    ]
  })

  const availableCards = computed(() => {
    return cards.value.filter((card) => {
      if (card.enabled) {
        return card.enabled()
      }

      return !card.requiresPermissionTo || can(card.requiresPermissionTo)
    })
  })
  
  return {
    cards,
    availableCards,
  }
}
