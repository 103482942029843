<template>
  <div>
    <slot :triggerSave="triggerSave" :triggerDescriptionUpdate="triggerDescriptionUpdate" />
  </div>
</template>
<script setup>
import { inject } from "vue"
const triggerSave = inject('triggerSave', null)
const triggerDescriptionUpdate = inject('triggerDescriptionUpdate', null)
</script>
