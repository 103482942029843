<template>
  <BaseTableCell :params="params">
    <component
      :is="link ? 'router-link' : 'span'"
      :to="link"
      @click.stop
    >
      <span
        :class="{
          [columnParams?.linkClass]: link,
          'text-gray-400 hover:text-gray-500': row?.id === -1 && !description,
        }"
      >
        {{ description || placeholder }}
      </span>
    </component>
  </BaseTableCell>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import { get } from 'lodash-es'
import { useRoute } from 'vue-router'

const route = useRoute()

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const row = computed(() => {
  return props.params?.data || props.params?.node?.allLeafChildren?.[0]?.data
})

const colDef = computed(() => {
  if (props.params.node?.group) {
    return props.params.api?.columnModel?.columnDefs?.find((cd: any) => cd.field === props.params.node?.field) || props.params.colDef
  }

  return props.params.colDef
})

const columnParams = computed(() => {
  return colDef.value?.params
})

const entity = computed(() => {
  if (columnParams.value?.entityPath) {
    return get(row.value, columnParams.value.entityPath)
  }
  return row.value
})

const link = computed(() => {
  if (entity.value?.id === -1) {
    return ''
  }

  if (columnParams.value?.getLink) {
    return columnParams.value.getLink(row.value, route)
  }

  if (!entity.value) {
    return ''
  }

  return `/${entity.value.type}/${entity.value.id}`
})

const description = computed(() => {
  if (columnParams.value?.getDescription) {
    return columnParams.value?.getDescription(row.value)
  }

  if (!entity.value) {
    return ''
  }

  return get(row.value, colDef.value.field, '')?.toString()
})

const defaultPlaceholder = '- -'
const placeholder = computed(() => {
  if (typeof columnParams.value?.getPlaceholder === 'function') {
    return columnParams.value.getPlaceholder(row.value) || defaultPlaceholder
  }

  return defaultPlaceholder
})
</script>
