<template>
  <BaseSelect
    v-model="model"
    :options="entityTypeOptions"
    :placeholder="$t('Select entity type to which it applies...')"
    :return-object="returnObject"
  />
</template>
<script>
import { entityTypeOptions } from "@/modules/accounts/utils/modelUtils"

export default {
  name: "EntityTypeSelect",
  props: {
    modelValue: {
      type: [Object, String],
      required: true,
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      entityTypeOptions
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
};
</script>
<style>
</style>
