<template>
  <BaseNoDataSection
    :title="$t('No Notifications Found')"
    iconClass="fa-bell"
    :canCreate="true"
  >
    <template #can-create-section>
      <div>{{ $t(`No notifications for selected project and filters.`) }}</div>
      <div>{{ $t(`Click below to refresh or change the filters.`) }}</div>
      <BaseButton
        size="sm"
        class="mt-2"
        @click="refreshNotifications"
      >
        <i class="fa fa-refresh mx-2" aria-hidden="true"></i>
        {{ $t('Refresh') }}
      </BaseButton>
    </template>
  </BaseNoDataSection>
</template>
<script setup>
const emit = defineEmits(['refresh'])

function refreshNotifications() {
  emit('refresh')
}
</script>
