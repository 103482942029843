<template>
  <DashboardWidget
    :widget="widget"
    :ui="ui"
    :widgetData="widgetData"
  >
    <template #item="{ item: timeEntry }">
      <TimeWidgetEntry
        :timeEntry="timeEntry"
      />
    </template>
  </DashboardWidget>
</template>
<script lang="ts" setup>
// Components
import DashboardWidget from "@/modules/dashboard/components/widgets/DashboardWidget.vue";
import TimeWidgetEntry from "@/modules/dashboard/components/widgets/TimeWidgetEntry.vue";

// Utils
import i18n from "@/i18n";
import { PropType, computed, onBeforeMount } from 'vue'
import { DashboardWidgetType, WidgetUI, WidgetData } from "@/modules/dashboard/utils/widgetUtils"
import { getExtraFilters } from "@/modules/time/utils/timeEntryTableUtils"

// Composables
import { useStore } from 'vuex'
import useCan from "@/modules/common/composables/useCan"
const { can, actions } = useCan()

const store = useStore()

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidgetType>,
    required: true
  }
})

const ui = computed<WidgetUI>(() => {
  return {
    listPath: '/time/actual',
    listLabel: i18n.t('Go to time'),
    entityCreate: can(actions.CREATE_TIME_ENTRIES) ? '/time/add' : '',
    footerKey: 'showing time entries'
  }
})

const widgetData = computed<WidgetData>(() => {
  return {
    target: 'time-entries',
    dataGetterPath: 'time/currentTimeEntries',
    columnsGetter: 'time/timeEntryTableColumns',
    groupedDataGetter: 'time/groupedTimeEntries',
    loading: store.state.time.timeEntriesLoading,
    extraFilters: getExtraFilters()
  }
})

onBeforeMount(async () => {
  await store.dispatch('time/getAllTimeEntries')
})
</script>
