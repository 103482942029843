<template>
  <div>
    <h3
      v-if="!lightForm"
      class="text-xl font-bold mt-10"
    >
      {{ $t('Default views') }}
      <BaseTutorialLink
        name="default-views"
        class="text-gray-300 hover:text-gray-500 text-sm ml-1"
      >
        <i class="fa-solid fa-circle-question" />
      </BaseTutorialLink>
    </h3>

    <p
      v-if="!lightForm"
      class="text-sm text-gray-500 mt-2"
    >
      <template v-if="level === DefaultViewLevelTypes.User">
        {{ $t(`The settings below determine which view type you go to when you visit each entity. Set your preferred view type so you see things just how you like them.`) }}
      </template>
      <template v-else>
        {{ $t(`The settings below determine which view type you go to when you visit each entity. These settings apply to all account users unless they change their preferences from their profile page.`) }}
      </template>
    </p>

    <div class="space-y-6 sm:space-y-5 mt-6">

      <UserDefaultViewItem
        v-for="(defaultView, i) of defaultViewsModel"
        :key="`${defaultView.target}_${defaultView.inside_project}`"
        :defaultView="defaultView"
        :level="level"
        :layout="inputLayout"
        :showIcon="!lightForm"
        @view-changed="onChange(i, $event.prop, $event.value)"
      />
    </div>
  </div>
</template>
<script>
import UserDefaultViewItem from "@/modules/accounts/components/UserDefaultViewItem.vue"
import { USER_PROFILE_DEFAULT_VIEW_OPTIONS } from "@/modules/users/util/userUtils"
import { capitalize } from "lodash-es";
import { getAccountDefaultViews, getSetting } from "@/plugins/settingsPlugin";
import { DefaultViewLevelTypes } from "@/modules/common/enum/viewTypeLevels";

export default {
  components: {
    UserDefaultViewItem
  },
  props: {
    level: {
      type: String,
      default: DefaultViewLevelTypes.User, // user or account
    },
    lightForm: {
      type: Boolean,
      default: false,
    },
    inputLayout: {
      type: String,
      default: 'horizontal',
    }
  },
  data() {
    return {
      DefaultViewLevelTypes,
      defaultViewsModel: [],
    }
  },
  computed: {
    defaultViews() {
      const userDefaultViews = this.$store.state.users.defaultViews || []
      let accountDefaultViews = getAccountDefaultViews()
      return this.level === DefaultViewLevelTypes.User ? userDefaultViews : accountDefaultViews
    },
  },
  methods: {
    setUserDefaultViewsModel() {
      const defaultViewsModel = [];

      for (let target in USER_PROFILE_DEFAULT_VIEW_OPTIONS) {
        const values = this.getDefaultViewsValues(target)
        defaultViewsModel.push(...values)
      }

      this.defaultViewsModel = defaultViewsModel;
    },
    onChange(index, prop, value) {
      this.defaultViewsModel[index][prop] = value
      this.$emit('user-views-changed', this.defaultViewsModel)
    },
    getDefaultViewsValues(target) {
      const targetOptions = USER_PROFILE_DEFAULT_VIEW_OPTIONS[target];

      const groupOptions = targetOptions.view_groups.map(view_group => ({
        value: view_group,
        label: this.$t(capitalize(view_group))
      }))

      const viewTypeOptions = targetOptions.view_types.filter(view_type => {
        return !view_type.requiresPermissionTo || this.can(view_type.requiresPermissionTo)
      }).map(view_type => ({
        value: view_type.type,
        label: view_type.label
      }))

      if (!viewTypeOptions.length) {
        return []
      }

      const targetLabel = this.$t(capitalize(target))

      const defaultGlobalViewValue = this.getDefaultViewValue(target, /* inside_project */ false, targetOptions)
      let globalLabel = targetLabel

      if (targetOptions.inside_project_switch) {
        const globalSpanClasses = this.inputLayout === 'horizontal' ? 'sm:block sm:ml-6': 'ml-1'
        const globalSpan = `<span class='${globalSpanClasses}'>(${ this.$t('All Projects') })</span>`
        globalLabel = `${targetLabel}${globalSpan}`
      }

      const defaultGlobalView = {
        icon: targetOptions.icon,
        label: globalLabel,
        target,
        inside_project: false,
        groupOptions,
        viewTypeOptions,
        ...defaultGlobalViewValue
      }

      if (!targetOptions.inside_project_switch) {
        return [ defaultGlobalView ]
      }

      const defaultInsideProjectsViewValue = this.getDefaultViewValue(target, /* inside_project */ true, targetOptions)
      const projectScopedSpanClasses = this.inputLayout === 'horizontal' ? 'sm:block sm:ml-6': 'ml-1'
      const projectScopedSpan = `<span class='${projectScopedSpanClasses}'>(${ this.$t('Inside Projects') })</span>`
      const insideProjectsLabel = `${targetLabel}${projectScopedSpan}`;

      const insideProjectsView = {
        ...defaultGlobalView,
        label: insideProjectsLabel,
        inside_project: true,
        ...defaultInsideProjectsViewValue
      }

      return [ defaultGlobalView, insideProjectsView ]
    },
    getDefaultViewValue(target, inside_project, targetOptions) {
      const defaultView = this.defaultViews.find(view => view.attributes.target === target && view.attributes.inside_project == inside_project)
      if (!defaultView) {
        return targetOptions.default
      }

      return {
        id: defaultView.id,
        view_group: defaultView.attributes.view_group,
        view_type: defaultView.attributes.view_type,
      }
    },
  },
  watch: {
    defaultViews: {
      immediate: true,
      handler() {
        this.setUserDefaultViewsModel()
        this.$emit('user-views-changed', this.defaultViewsModel)
      }
    }
  },
}
</script>
