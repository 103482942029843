import { getCssVariable, getHexWithoutAlpha } from "@/modules/accounts/utils/accountBrandingUtils";
import { colors } from "@/components/html/util/collaborationColors";

function getCustomChartThemes() {
  let primaryColor = getCssVariable('--primary-500').trim()
  let primarySecondaryColor = getCssVariable('--primary-300').trim()

  const chartColors: string[] = [primaryColor, primarySecondaryColor, ...colors]
  const highlightStyle = {
    item: {
      fill: primarySecondaryColor,
      stroke: primarySecondaryColor,
      strokeWidth: 4,
    },
    series: {
      dimOpacity: 0.2,
      strokeWidth: 2,
    },
  }


  return {
    projectCo: {
      baseTheme: 'ag-pastel',
      palette: {
        fills: chartColors,
        strokes: chartColors
      },
      overrides: {
        pie: {
          series: {
            highlightStyle,
          }
        },
        column: {
          series: {
            highlightStyle,
          }
        },
      }
    }
  }
}

export function getChartOptions() {
  return {
    enableCharts: true,
    popupParent: document.body,
    getChartToolbarItems: () => ['chartDownload'],
    customChartThemes: getCustomChartThemes(),
    chartThemes: ['projectCo', 'ag-pastel', 'ag-vivid'],
  }
}
