import axios from "axios";
import i18n from "@/i18n.js";
import { get } from "lodash-es";
import { getPaymentStatusColor, PAYMENT_STATUSES } from "@/modules/payments/enum/paymentEnums.js";
import { getPaymentColumns, paymentFields } from "@/modules/payments/utils/paymentTableUtils"
import { columnBuilder } from '@/components/table/tableUtils'
import { transformToRestifyArray } from "@/modules/common/utils/dataUtils";
import {
  syncEntityInArray,
  EntityChangeEvent,
  getEntityArrayForWorker,
  groupEntitiesToMap,
} from "@/modules/common/utils/entityUtils";
import { paymentsWithRelationshipsWorker, getPaymentWithRelationships } from '@/modules/payments/utils/paymentRelationshipUtils'
import { Activities, trackActivity } from "@/modules/common/utils/trackingUtils";import {
  PaymentProviders
} from "@/modules/payments/enum/paymentEnums.js";

const state = () => ({
  payments: {
    data: [],
  },
  allPayments: [],
  allPaymentsLoaded: false,
  statuses: {
    data: [
      {
        id: PAYMENT_STATUSES.PAID,
        attributes: {
          name: i18n.t('Paid'),
          value: PAYMENT_STATUSES.PAID,
          color: getPaymentStatusColor(PAYMENT_STATUSES.PAID),
        }
      },
      {
        id: PAYMENT_STATUSES.UNPAID,
        attributes: {
          name: i18n.t('Unpaid'),
          value: PAYMENT_STATUSES.UNPAID,
          color: getPaymentStatusColor(PAYMENT_STATUSES.UNPAID),
        }
      },
    ]
  },
  paymentsLoading: true,
  currentPaymentLoading: false,
  currentPaymentInvoiceLoading: false,
  currentPayment: null,
  currentPaymentInvoice: null,
  addPaymentTrigger: 1
})

const mutations = {
  setPayments(state, value) {
    state.payments = value
  },
  setAllPayments(state, value) {
    state.allPayments = value
    state.allPaymentsLoaded = true
  },
  setAllPaymentsLoaded(state, value) {
    state.allPaymentsLoaded = value
  },
  addPayment(state, payment) {
    syncEntityInArray(payment, state.allPayments, EntityChangeEvent.Create)
  },
  updatePayment(state, payment) {
    syncEntityInArray(payment, state.allPayments, EntityChangeEvent.Update)

    if (String(payment.id) !== String(state.currentPayment?.id)) {
      return
    }

    state.currentPayment = {
      ...state.currentPayment,
      ...payment,
      attributes: {
        ...state.currentPayment.attributes,
        ...payment.attributes
      },
      relationships: {
        ...state.currentPayment.relationships,
        ...payment.relationships
      }
    }
  },
  deletePayment(state, id) {
    syncEntityInArray({ id }, state.allPayments, EntityChangeEvent.Delete)
  },
  setPaymentsLoading(state, value) {
    state.paymentsLoading = value
  },
  setCurrentPaymentLoading(state, value) {
    state.currentPaymentLoading = value
  },
  setCurrentPayment(state, value) {
    state.currentPayment = value
  },
  setCurrentPaymentInvoiceLoading(state, value) {
    state.currentPaymentInvoiceLoading = value
  },
  setCurrentPaymentInvoice(state, value) {
    state.currentPaymentInvoice = value
  },
  triggerAddPayment(state) {
    state.addPaymentTrigger++
  },
}

const actions = {
  async getPayments({ commit, rootGetters, rootState }, filters) {
    try {
      if (filters?.shouldReset === true) {
        commit('setPayments', { data: [] })
      }
      filters = filters || { ...rootState.route?.query }
      commit('setPaymentsLoading', true)
      const payments = await axios.get('/restify/payments', {
        params: {
          ...filters,
          related: 'group,project',
          project_id: rootGetters.project_id
        }
      })
      commit('setPayments', payments)
    } finally {
      commit('setPaymentsLoading', false)
    }
  },
  async getAllPayments({ state, commit, dispatch }) {
    if (state.allPaymentsLoaded) {
      return
    }

    try {
      commit('setPaymentsLoading', true)

      const entities = ['payments']
      const { data: { payments } } = await axios.get('/data', {
        params: {
          entities: `[${entities.join('|')}]`,
          paymentFields: `[${paymentFields.join('|')}]`,
        }
      })

      const allPayments = transformToRestifyArray(payments, { type: 'payments' })

      commit('setAllPayments', allPayments)
      dispatch('setPaymentsRelationships')
    } catch (err) {
      console.error('Error fetching payments', err)
      commit('setPaymentsLoading', false)
    }
  },
  async setPaymentsRelationships({ state, commit, rootState }) {
    const allPayments = getEntityArrayForWorker(state.allPayments || [])

    if (!allPayments.length) {
      if (state.allPaymentsLoaded) {
        commit('setPaymentsLoading', false)
      }
      return
    }

    const allGroups = getEntityArrayForWorker(rootState.groups.allGroups || [])
    const allProjects = getEntityArrayForWorker(rootState.projects.allProjects || [])

    if (!rootState.groups.allGroupsLoaded || !rootState.projects.allProjectsLoaded) {
      return
    }

    const paymentsWithRelationships = await paymentsWithRelationshipsWorker(
      allPayments,
      allGroups,
      allProjects,
    )

    commit('setAllPayments', paymentsWithRelationships)
    commit('setPaymentsLoading', false)
  },
  async getPaymentDetailsById({ commit }, id) {
    try {
      commit('setCurrentPaymentLoading', true)

      const { data } = await axios.get(`/payment/${id}`)

      commit('setCurrentPayment', data)
    } finally {
      commit('setCurrentPaymentLoading', false)
    }
  },
  async getPaymentById({ commit }, { id, returnEntity = false}) {
    try {
      commit('setCurrentPaymentLoading', true)

      const { data } = await axios.get(`/restify/payments/${id}`, {
        params: {
          related: 'group,project',
        }
      })
      
      if (returnEntity) {
        return data
      }

      commit('setCurrentPayment', data)
    } finally {
      commit('setCurrentPaymentLoading', false)
    }
  },
  async getPaymentInvoiceById({ commit, state }, paymentId) {
    try {
      commit('setCurrentPaymentInvoiceLoading', true)
      const { data } = await axios.get(`/restify/payment-invoices`, {
        params: {
          payment_id: paymentId,
        }
      })
      commit('setCurrentPaymentInvoice', data[0])
    } finally {
      commit('setCurrentPaymentInvoiceLoading', false)
    }
  },
  async getInvoiceById({ commit, state }, invoiceId) {
    try {
      commit('setCurrentPaymentInvoiceLoading', true)
      const { data } = await axios.get(`/restify/payment-invoices/${invoiceId}`)
      commit('setCurrentPaymentInvoice', data)
    } finally {
      commit('setCurrentPaymentInvoiceLoading', false)
    }
  },
  async getInvoicesForPayment({ commit, state }, paymentId) {
    const { data } = await axios.get(`/restify/payment-invoices`, {
      params: {
        payment_id: paymentId,
      }
    })
    return data
  },
  async getPaymentCheckoutSession({ commit, state }, payment) {
    const data = await axios.post(`/payment/${payment.id}/create-recurring-checkout-session`, {
      success_url: window.location.href,
    })
    return data.url
  },
  async getPaymentBillingPortalSession({ commit, state }, payment) {
    const { data } = await axios.post(`/restify/payments/${payment.id}/actions?action=get-billing-portal`, {
      returnUrl: window.location.href,
    })
    return data
  },
  async createPayment({ commit }, data) {
    const response = await axios.post(`/restify/payments`, data)
    const id = response?.data?.id

    if (id) {
      const payment = getPaymentWithRelationships(response.data)
      commit('addPayment', payment)
    }

    trackActivity(Activities.PaymentCreated, response.data)
    return response?.data
  },
  async editPayment({ commit, dispatch }, data) {
    if (!data?.is_recurring) {
      delete data.stripe_price
    }      
    const response = await axios.put(`/restify/payments/${data.id}`, data)

    if (response?.data?.id) {
      const payment = getPaymentWithRelationships(response.data)
      commit('updatePayment', payment)
    }

    return response
  },
  async markPaid({ commit }, { payment, invoice_details }) {
    await axios.post(`/restify/payments/${payment.id}/actions?action=mark-as-paid`, {
      invoice_details
    })

    const paymentProvider = invoice_details?.payment_method || PaymentProviders.Manual

    const updatedPayment = payment
    updatedPayment.attributes.payment_status = PAYMENT_STATUSES.PAID
    updatedPayment.attributes.payment_provider = paymentProvider
    commit('updatePayment', updatedPayment)
  },
  async markUnpaid({ commit }, { payment }) {
    await axios.post(`/restify/payments/${payment.id}/actions?action=mark-as-unpaid`)

    const updatedPayment = payment
    updatedPayment.attributes.payment_status = PAYMENT_STATUSES.UNPAID
    updatedPayment.attributes.payment_provider = null
    commit('updatePayment', updatedPayment)
  },
  async chargePayment({}, payment) {
    return await axios.post(`/charge/${payment.id}`, {})
  },
  async deletePayment({ commit }, id) {
    await axios.delete(`/restify/payments/${id}`)
    commit('deletePayment', id)
  }
}

const getters = {
  activeColumns: (state) => {
    const { mainColumns, extraColumns } = getPaymentColumns()

    let columns = mainColumns

    columnBuilder.addCustomColumns(columns, extraColumns)

    columnBuilder.addCustomFieldColumns(columns, 'payment', 'attributes.payment_status')

    return columns
  },
  tableColumns: (state, getters) => {
    return getters.activeColumns.filter(c => c.visibleInTable !== false)
  },
  groupedPayments: (state, getters, rootState, rootGetters) => (payments = null, groupBy = null) => {
    payments = payments || state.allPayments || []

    groupBy = groupBy || rootGetters['filters/targetLocalFilters']('payments')?.groupBy
    const groupByKey = groupBy?.[0]
    const groupedPayments = groupEntitiesToMap(payments, groupByKey)

    return groupedPayments
  },
  dataPropRange: (state, getters) => (prop) => {
    const payments = getters.currentPayments

    const min = Math.min(...payments.map(payment => typeof prop === 'function'
        ? prop(payment)
        : get(payment, prop)
      ),
      0
    )

    const max = Math.max(...payments.map(payment => typeof prop === 'function'
        ? prop(payment)
        : get(payment, prop)
      ),
      0
    )

    return {
      min,
      max
    }
  },
  projectScopedPayments: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id

    if (!project_id) {
      return []
    }

    return state.allPayments.filter((payment) => payment.attributes.project_id == project_id)
  },
  currentPayments: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id

    const payments = project_id
      ? getters.projectScopedPayments || []
      : state.allPayments || []

    return payments
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
