<template>
  <div class="flex justify-between items-center text-sm cursor-pointer w-full">
    <div>{{ title }}</div>
    <i
      v-if="canDelete"
      class="fas fa-times-circle ml-auto mr-0 opacity-50 hover:opacity-80"
      @click.stop="confirmDelete"
    />
  </div>
</template>
<script>
import i18n from "@/i18n"
import {
  isFilterPresetActive,
  PEOPLE_OPTIONS,
  getFilterPresetTitle
} from "@/modules/common/utils/filterUtils"

export default {
  props: {
    filterPreset: {
      type: Object,
      required: true
    }
  },
  computed: {
    filterPresetAttributes() {
      return this.filterPreset.attributes
    },
    title() {
      return getFilterPresetTitle(this.filterPreset)
    },
    canDelete() {
      return this.filterPresetAttributes.apply_to_users !== PEOPLE_OPTIONS.ALL || this.can(this.$actions.MANAGE_ALL_USERS_VIEWS)
    }
  },
  methods: {
    async confirmDelete() {
      const confirmed = await this.$deleteConfirm({
        title: i18n.t('Delete Saved View'),
        description: i18n.t('Are you sure? This will permanently remove this saved view.')
      });

      if(!confirmed) {
        return;
      }

      try {
        await this.$store.dispatch('filters/deleteFilterPreset', this.filterPreset.id)
        this.$success(i18n.t('View deleted successfully!'))
      }
      catch (err) {
        this.$error(i18n.t('Could not delete view'))
      }
    }
  }
}
</script>
