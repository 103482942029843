<template>
  <h2 class="text-center text-2xl sm:text-3xl font-extrabold text-gray-900">
    <slot></slot>
  </h2>
</template>
<script>
export default {
  name: 'BasePageTitle'
}
</script>
