import store from "@/store";
import {
  decodeFilters,
  encodeFilters,
  DEFAULT_TARGET_LIST_FILTERS
} from "@/modules/common/utils/filterUtils";
import { getProjectColumns } from "@/modules/projects/utils/projectTableUtils";
import { getTaskColumns } from "@/modules/tasks/utils/taskTableUtils";
import { getPaymentColumns } from "@/modules/payments/utils/paymentTableUtils";
import { getGroupColumns } from "@/modules/groups/utils/groupTableUtils";
import { getFileColumns } from "@/modules/files/utils/fileTableUtils";
import { getUserColumns } from "@/modules/users/util/userTableUtils";
import { TableColumn } from "@/components/table/tableUtils";
import { formatDate } from "@/modules/common/utils/dateUtils";

import Data = API.Data
import ProjectStatus = App.Domains.Statuses.Models.ProjectStatus
import Group = App.Domains.Groups.Models.Group
import User = App.Domains.Users.Models.User
import Task = App.Domains.Tasks.Models.Task
import Project = App.Domains.Projects.Models.Project

// #region Create Entity Views

interface EntityViewModel extends Record<any, any> {
  id: string
  view: string // kanban, scheduler, calendar, card, list
  entity: string // projects, tasks, payments, templates, groups, files, users
  sorts: string // column sort
  filterBy: any[]
  groupBy: string[]
}

type FilterType = {
  column: string
  query: string | Record<string, string | number>
  name?: string
  displayValue?: string | object
  rawValue: string | object | Array<object>
}

export function createEntityView(params: EntityViewModel) {
  const {
    entity,
    view,
    sorts,
    filterBy,
    groupBy
  } = params;

  let systemDefault: any = DEFAULT_TARGET_LIST_FILTERS[entity];

  if (typeof systemDefault === 'function') {
    systemDefault = systemDefault(view);
  }
  
  const decodedSystemDefaultFilters = systemDefault?.filters
    ? decodeFilters(systemDefault.filters)
    : null
  const decodedSystemDefaultLocalFilters = systemDefault?.localFilters
    ? decodeFilters(systemDefault.localFilters)
    : null

  let filters = [];
  let localFilters = {};

  if (sorts) {
    filters.push({
      key: "sorts",
      value: sorts
    });
  }
  else if (decodedSystemDefaultFilters) {
    const sort = decodedSystemDefaultFilters.find((f: any) => f.key === 'sorts');
    if (sort) {
      filters.push(sort);
    }
  }

  if (filterBy) {
    filters.push({
      key: "filters",
      value: getFilter(filterBy, entity)
    });
  }
  else if (decodedSystemDefaultFilters) {
    const filter = decodedSystemDefaultFilters.find((f: any) => f.key === 'filters');
    if (filter) {
      filters.push(filter);
    }
  }

  if (groupBy) {
    localFilters = {
      groupBy
    }
  }
  else if (decodedSystemDefaultLocalFilters) {
    localFilters = decodedSystemDefaultLocalFilters;
  }

  const viewType = view || 'list';

  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('filters', encodeFilters(filters));
  urlSearchParams.set('localFilters', encodeFilters(localFilters));

  const link = `/${entity}/${viewType}?${urlSearchParams.toString()}`;

  store.dispatch('ai/create_entity_view', {
    entity,
    link
  });

  return true;
}

const entityFilters: Record<string, string> = {
  group_ids: 'groups',
  user_ids: 'users',
  allocated_ids: 'users',
  parent_ids: 'tasks',
  project_id: 'projects',
  project_ids: 'projects',
}

const entityToLabelTransformer: Record<string, Function> = {
  groups: (ids: number[])  => {
    // @ts-ignore
    const groups =  store.state.groups.allGroups || []
    const filteredGroups = groups.filter((g: Data<any>) => ids.includes(+g.id))

    const displayValue = filteredGroups.map((g: Data<Group>) => g.attributes.name).join(', ');
    return {
      rawValue: filteredGroups.map((g: Data<Group>) => ({
        id: g.id,
        attributes: {
          name: g.attributes.name,
        }
      })),
      displayValue
    }
  },
  users: (ids: number[]) => {
    // @ts-ignore
    const users = store.state.users.allUsers || []
    const filteredUsers = users.filter((u: Data<any>) => ids.includes(+u.id))
    const displayValue = filteredUsers.map((u: Data<User>) => u.attributes.name).join(', ');
    return {
      rawValue: filteredUsers.map((u: Data<User>) => ({
        id: u.id,
        attributes: {
          name: u.attributes.name,
        }
      })),
      displayValue
    }
  },
  tasks: (ids: number[]) => {
    // @ts-ignore
    const tasks = store.state.tasks.allTasks || []
    const filteredTasks = tasks.filter((t: Data<any>) => ids.includes(+t.id))
    const displayValue = filteredTasks.map((t: Data<Task>) => t.attributes.name).join(', ');
    return {
      rawValue: filteredTasks.map((t: Data<Task>) => ({
        id: t.id,
        attributes: {
          name: t.attributes.name,
        }
      })),
      displayValue
    }
  },
  projects: (ids: number[]) => {
    // @ts-ignore
    const projects = store.state.projects.allProjects || []
    const filteredProjects = projects.filter((p: Data<any>) => ids.includes(+p.id))
    const displayValue = filteredProjects.map((p: Data<Project>) => p.attributes.name).join(', ');
    return {
      rawValue: filteredProjects.map((p: Data<Project>) => ({
        id: p.id,
        attributes: {
          name: p.attributes.name,
        }
      })),
      displayValue
    }
  }
}

function getEntitiesLabelAndRaw(entity: string, ids: (string | number)[] | (string | number)) {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  ids = (ids || []).map(Number);
  return entityToLabelTransformer[entity]?.(ids) || ids.join(', ');
}

function getDisplayAndRawValues(entity: string, filterProp: string, query: string | Array<string | number>) {
  if (filterProp.startsWith('status')) {
    const statuses: Data<ProjectStatus>[] = store.getters[`${entity}/orderedStatuses`];
    const status_ids = (query as Array<string | number>).map(Number)

    const displayValue = statuses.filter(s => status_ids.includes(+s.id)).map(s => ({
      id: s.id,
      value: s.attributes.color,
      label: s?.attributes?.name,
      hasColor: true
    }));

    return {
      rawValue: displayValue,
      displayValue
    }
  }

  if (query.hasOwnProperty('min') || query.hasOwnProperty('max')) {
    const displayValue = Object.values(query)
    .filter(x => !!x)
    .map(x => {
      if (filterProp.includes('date') || filterProp.includes('deadline')) {
        return formatDate(x as string)
      }
      return x
    })
    .join(' - ')

    return {
      rawValue: query,
      displayValue
    }
  }

  if (entityFilters[filterProp]) {
    const entityType = entityFilters[filterProp];

    return getEntitiesLabelAndRaw(entityType, query as string[])
  }
  
  const displayValue = Array.isArray(query)
    ? query.join(', ')
    : query.toString()

  return {
    rawValue: query,
    displayValue
  }
}

function getFilter(filters: Array<FilterType & {query: string}>, entity: string) {
  // Show me all the tasks in list mode, by name "ss" and from "2023-11-01" to "2023-12-01"
  // Hi, show me the tasks in list mode, filtered by name "asdasd" and should be date minim november 2023 and maxim december 2023
  const newFilters: FilterType[] = [];

  const columns = getColumns(entity);

  filters.forEach((filter) => {
    const { column: filterProp, query } = filter;

    const tableColumn = columns?.mainColumns?.find((col: TableColumn) => {
      const filterBy = typeof col.filterBy === 'function'
        ? col.filterBy()
        : col.filterBy;

      return filterBy?.prop === filterProp;
    })

    if (!tableColumn) {
      return;
    }

    const {
      rawValue,
      displayValue
    } = getDisplayAndRawValues(entity, filterProp, query);

    const viewFilter: FilterType = {
      name: typeof tableColumn.name === 'function'
        ? tableColumn.name()
        : tableColumn.name,
      column: filterProp,
      query,
      rawValue,
      displayValue,
    };

    newFilters.push(viewFilter);
  });

  return newFilters;
}

function getColumns(entity: string) {
  const mapping = {
    'projects': getProjectColumns,
    'tasks': getTaskColumns,
    'payments': getPaymentColumns,
    'templates': getProjectColumns,
    'groups': getGroupColumns,
    'files': getFileColumns,
    'users': getUserColumns,
  }

  // @ts-ignore
  return mapping[entity]?.() || [];
}

// #endregion
