import { computed } from 'vue'
import { useStore } from 'vuex'

export default function useLayout() {
  const store = useStore()

  const useNewLayout = computed({
    get() {
      return store.state.dashboard.useNewLayout
    },
    set(useNewLayout) {
      store.dispatch('dashboard/changeLayout', useNewLayout)
    },
  })

  const sidebarVisible = computed({
    get() {
      return store.state.dashboard.sidebarVisible
    },
    set(sidebarVisible) {
      store.commit('dashboard/setSidebarVisible', sidebarVisible)
    },
  })

  return {
    useNewLayout,
    sidebarVisible,
  }
}
