<template>
  <router-view class="h-full" />
</template>
<script>
export default {
  methods: {
    getProofs() {
      this.$store.dispatch('proofs/getProofs')
    },
  },
  mounted() {
    this.getProofs()
  }
}
</script>
