import store from "@/store";
import { FilterBuilder } from "@/modules/ai/filters/FilterBuilder";
import { FilterTypes } from "@/components/table/tableUtils";
import { FilterEngine } from "@/modules/ai/filters/FilterEngine";

// #region Get Custom Fields

interface CustomFieldsSearchModel extends Record<any, any> {
  id: number
  entity_type: string
  name: string
}


function getMappedCustomFields() {
  // @ts-ignore
  return store.state.accounts.customFields.data.map((customField: any) => ({
    id: customField.id,
    name: customField.attributes.name,
    entity_type: customField.attributes.entity_type,
  }));
}

export function getCustomFields({entity_type, name, id}: CustomFieldsSearchModel) {
  const all = getMappedCustomFields();

  let filterBuilder = new FilterBuilder();
  let dynamicFilters = filterBuilder
    .addFilter('name', FilterTypes.Text, name)
    .addFilter('entity_type', FilterTypes.Text, entity_type)
    .addFilter('id', FilterTypes.Numeric, id)
    .build();

  let filteredCustomFields = FilterEngine.filter(all, dynamicFilters);

  if (filteredCustomFields.length > 10) {
    return `There are ${filteredCustomFields.length} custom fields. Please narrow your search.`;
  }

  return JSON.stringify(filteredCustomFields);
}

// #endregion
