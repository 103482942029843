<template>
  <div
    v-show="showField"
    class="flex custom-field-value"
  >
    <span
      v-show="showLabel"
      class="mr-1.5 custom-field-value-label"
    >
      <i
        v-if="showLabelIcon"
        class="far fa-sparkles mr-0.5"
      />
      <span>{{ customField.attributes.name }}</span>
      <span v-if="isRequired && !hasValue" class="text-red-500">*</span>
      <span class="colon">:</span>
    </span>

    <div class="custom-field-value-display-value">
      <div v-if="customFieldType === 'currency'">
        {{ selectedValue }}
      </div>
      <div v-else-if="selectedValue.hasColor" class="flex whitespace-nowrap">
        <div
          class="w-3 h-3 rounded-md mr-1 my-auto"
          :style="{
            backgroundColor: selectedValue?.value,
          }"
        />
        <span v-html="selectedValue?.label || placeholder" />
      </div>
      <div
        v-else
        v-html="displayValue"
        class="whitespace-nowrap text-left"
      />
    </div>
  </div>
</template>
<script>
import { customFieldTypes, customFieldValueDisplayFormat } from '@/modules/accounts/utils/modelUtils';

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    customField: {
      type: Object,
      required: true,
    },
    hideNullValue: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    showLabelIcon: {
      type: Boolean,
      default: false
    },
    maxChars: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: '- -'
    }
  },
  computed: {
    showField() {
      return !this.hideNullValue || this.hasValue
    },
    customFieldType() {
      return this.customField.attributes?.custom_field_type
    },
    customFieldValues() {
      const customFieldValues = this.model?.custom_fields || this.model?.attributes?.custom_fields;
      if (typeof customFieldValues === 'string') {
        return JSON.parse(customFieldValues)
      }
      return customFieldValues
    },
    selectedValue() {
      if (!this.hasValue) {
        return this.placeholder
      }

      const value = this.customFieldValues[this.customField.attributes?.key];

      return customFieldValueDisplayFormat(this.customField, this.customFieldType, value) || this.placeholder
    },
    hasValue() {
      if (!this.customFieldValues?.[this.customField.attributes?.key]) {
        return false
      }

      return true
    },
    isTextType() {
      const textyTypes = [
        customFieldTypes.Text,
        customFieldTypes.Textarea,
      ]
      return textyTypes.includes(this.customFieldType)
    },
    displayValue() {

      if (!this.isTextType || !this.maxChars || this.selectedValue?.length < this.maxChars) {
        return this.selectedValue
      }

      return `${(this.selectedValue || '').substring(0, this.maxChars)}...`
    },
    isRequired() {
      return this.customField.attributes.rules?.includes('required')
    },
  },
};
</script>
