<template>
  <BaseEntityForm
    :title="title"
    :subtitle="subtitle"
    :width-class="isDialogForm || !$useNewLayout ? 'max-w-full' : 'max-w-xl'"
    :isDialogForm="isDialogForm"
    @submit="onSubmit"
  >
    <template #default="{ errors, meta }">
      <div class="max-w-xl mx-auto">
        <template v-if="!taskScoped">
          <BaseInput
            v-if="!$store.getters.project_id"
            v-focus="!model.project_id"
            :modelValue="model.project_id"
            :name="$t('Project')"
            :label="$t('Project')"
            :layout="inputsLayout"
            rules="required"
            id="project"
            class="mt-1 sm:mt-0 md:col-span-2"
          >
            <div class="sm:mt-0 sm:col-span-4">
              <ProjectSelect
                v-model="model.project_id"
                :initialValue="model.project"
                :placeholder="$t('Choose project...')"
                @change="onProjectChange"
              />
            </div>
          </BaseInput>

          <template v-if="model.project_id">
            <BaseInput
              :modelValue="model.allocationLevel"
              :label="$t('Level')"
              :layout="inputsLayout"
              rules="required"
              class="mt-1 sm:mt-0 md:col-span-2"
            >
              <BaseSelect
                v-model="model.allocationLevel"
                :options="allocationLevelOptions"
                :disabled="!model.project_id"
                :return-object="false"
                :placeholder="$t('Choose a level to allocate time to')"
              />
            </BaseInput>
            <BaseInput
              v-if="model.allocationLevel === timeAllocationLevels.GROUP"
              :modelValue="model.group_id"
              :name="$t('Group')"
              :label="$t('Group')"
              :errorMessage="errors[$t('Group')]"
              rules="required"
              :layout="inputsLayout"
              id="group"
              class="mt-1 sm:mt-0 md:col-span-2"
            >
              <div class="sm:mt-0 sm:col-span-4">
                <GroupSelect
                  v-model="model.group_id"
                  :initialValue="model.group"
                  :urlParams="{
                  hasUsersWithRoleGreaterThan: $roleRanks.COLLABORATOR_PLUS,
                  project_id: model.project_id,
                }"
                  :placeholder="$t('Choose group...')"
                  @change="onGroupChange"
                />
              </div>
            </BaseInput>
            <BaseInput
              v-if="model.allocationLevel === timeAllocationLevels.PERSON"
              :modelValue="model.user_id"
              :name="$t('Person')"
              :label="$t('Person')"
              :errorMessage="errors[$t('Person')]"
              rules="required"
              :layout="inputsLayout"
              id="person"
              class="mt-1 sm:mt-0 md:col-span-2"
            >
              <div class="sm:mt-0 sm:col-span-4">
                <UserSelect
                  v-model="model.user_id"
                  :initialValue="model.user"
                  :urlParams="{
                  roleGreaterThan: $roleRanks.COLLABORATOR_PLUS,
                  project_id: model.project_id,
                }"
                  :placeholder="$t('Choose Person...')"
                  @change="onUserChange"
                />
              </div>
            </BaseInput>
            <BaseInput
              v-if="model.allocationLevel === timeAllocationLevels.TASK"
              :modelValue="model.task_id"
              :name="$t('Task')"
              :label="$t('Task')"
              :errorMessage="errors[$t('Task')]"
              rules="required"
              :layout="inputsLayout"
              id="task"
              class="mt-1 sm:mt-0 md:col-span-2"
            >
              <div class="sm:mt-0 sm:col-span-4">
                <TaskSelect
                  v-model="model.task_id"
                  :initialValue="model.task"
                  :urlParams="{
                  project_id: model.project_id,
                }"
                  :placeholder="$t('Choose Task...')"
                  @change="onTaskChange"
                />
              </div>
            </BaseInput>
          </template>
        </template>
        <BaseInput
          v-if="model.project_id"
          v-focus="!!allocatedTime?.id || !!model?.id"
          :modelValue="model.allocated_minutes"
          :name="$t('Time')"
          :label="$t('Time')"
          rules="required|min_minutes:1"
          :layout="inputsLayout"
          id="hours"
          class="mt-1 sm:mt-0 md:col-span-2"
        >
          <TimeInput
            v-model="model.allocated_minutes"
            :placeholder="$t('Choose how much time to allocate...')"
          />
        </BaseInput>
      </div>
    </template>

      <template #actions="{ errors, meta }">
        <BaseButton variant="white" @click="$emit('cancel')">
          {{ $t('Cancel') }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          :disabled="!meta.valid"
          type="submit"
          class="ml-2"
        >
          {{ allocatedTimeModel?.id ? $t('Save') : $t('Allocate Time') }}
        </BaseButton>
      </template>
  </BaseEntityForm>
</template>
<script>
// Libs
import { cloneDeep } from 'lodash-es'

// Components
import ProjectSelect from '@/components/selects/ProjectSelect.vue'
import UserSelect from '@/components/selects/UserSelect.vue'
import GroupSelect from '@/components/selects/GroupSelect.vue'
import TaskSelect from '@/components/selects/TaskSelect.vue'
import TimeInput from '@/components/form/TimeInput.vue'

// Utils
import { allocatedTimeToFormModel } from '@/modules/time/utils/formModelUtils.js'
import { timeAllocationLevels } from '@/modules/time/utils/modelUtils.js'

export default {
  name: 'AllocatedTimeForm',
  components: {
    TimeInput,
    ProjectSelect,
    UserSelect,
    GroupSelect,
    TaskSelect,
  },
  props: {
    allocatedTime: {
      type: Object,
      default: () => ({}),
    },
    // * Is true when the form is used to allocate time to a task directly
    taskScoped: {
      type: Boolean,
      default: false,
    },
    // * Is true when the form is used to allocate time from time entry modal
    timeEntryScoped: {
      type: Boolean,
      default: false,
    },
    timeEntry: {
      type: Object,
      default: () => ({}),
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isDialogForm: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['save', 'cancel'],
  data() {
    return {
      loading: false,
      allocatedTimeModel: this.allocatedTime,
      model: {
        allocationLevel: timeAllocationLevels.TASK,
        project_id: null,
        group_id: null,
        user_id: null,
        task_id: null,
        allocated_minutes: null,
      },
      savedAllocatedTime: {},
      timeAllocationLevels,
      allocationLevelOptions: [
        {
          label: this.$t('Group'),
          value: timeAllocationLevels.GROUP,
        },
        {
          label: this.$t('Person'),
          value: timeAllocationLevels.PERSON,
        },
        {
          label: this.$t('Task'),
          value: timeAllocationLevels.TASK,
        },
      ],
    }
  },
  computed: {
    title() { 
      if (!this.showTitle) {
        return ''
      }

      return this.allocatedTimeModel?.id
        ? this.$t('Edit Allocated Time')
        : this.$t('Allocate Time')
    },
    subtitle() {
      if (!this.showTitle) {
        return ''
      }

      return this.allocatedTimeModel?.id
        ? ''
        : this.$t('Allocate the time you expect to spend on this project. Time can be allocated at Group, Person and Task level.')
    },
    projectId() {
      return this.$route.path.includes('projects')
        ? this.$route?.params?.id
        : this.$store.getters.project_id || null
    },
    timePickerDisabled() {
      if (!this.model.project_id || !this.model.allocationLevel) {
        return true
      }
      if (this.model.allocationLevel === timeAllocationLevels.GROUP && !this.model.group_id) {
        return true
      }
      if (this.model.allocationLevel === timeAllocationLevels.PERSON && !this.model.user_id) {
        return true
      }
      if (this.model.allocationLevel === timeAllocationLevels.TASK && !this.model.task_id) {
        return true
      }

      return false
    },
    inputsLayout() {
      return this.timeEntryScoped ? 'vertical' : 'horizontal'
    },
  },
  methods: {
    async onSubmit(emitSave = true) {
      try {
        this.loading = true
        const data = cloneDeep(this.model)
        if (!data.project_id) {
          data.project_id = this.projectId
        }
        if (this.allocatedTimeModel?.id) {
          const response = await this.$store.dispatch('time/editAllocatedTime', {
            allocatedTimeId: this.allocatedTimeModel.id,
            data,
          })
          this.$success('Allocated time updated successfully')
          if (emitSave) {
            this.$emit('save', response)
          }
        } else {
          this.savedAllocatedTime = await this.$store.dispatch(
            'time/createAllocatedTime',
            { data },
          )
          this.$success('Allocated time created successfully')
          if (emitSave) {
            this.$emit('save', this.savedAllocatedTime)
          } else {
            await this.redirectToSavedAllocatedTime()
          }
        }
      } catch (err) {
        if (err.handled) {
          return false
        }
        this.$error(this.$t('Could not allocate time'))
        return false
      } finally {
        this.loading = false
      }
      return true
    },
    async redirectToSavedAllocatedTime() {
      await this.$store.dispatch('time/getAllocatedTimeById', {
        id: this.savedAllocatedTime?.id,
      })
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          taskId: this.savedAllocatedTime?.id,
        },
      })
    },
    onProjectChange() {
      this.model.group_id = null
      this.model.task_id = null
      this.model.user_id = null
    },
    initModel(allocatedTime) {
      this.allocatedTimeModel = allocatedTime

      if (allocatedTime?.attributes) {
        this.model = allocatedTimeToFormModel(allocatedTime)
      }

      this.model.project_id = this.model.project_id || this.projectId
    },
    async onGroupChange(group_id) {
      await this.checkExistingAllocation('group', { group_id })
    },
    async onUserChange(user_id) {
      await this.checkExistingAllocation('person', { user_id })
    },
    async onTaskChange(task_id) {
      await this.checkExistingAllocation('task', { task_id })
    },
    async checkExistingAllocation(entityType, params, showWarning = true) {
      const existingAllocations = await this.$store.dispatch('time/getEntityAllocatedTime', {
        ...params,
        project_id: this.model.project_id,
      })

      if (!existingAllocations.length) {
        return
      }

      this.initModel(existingAllocations[0])

      if (!showWarning) {
        return
      }

      this.$error(this.$t(`Time allocation for this ${entityType} already exists. Please edit instead`))
    },
    // * This is used when the form is opened from a time entry modal
    initModelFromTimeEntry(timeEntry) {
      this.model = {
        ...this.model,
        ...timeEntry.attributes,
        ...timeEntry.relationships,
      }

      this.checkExistingAllocation('task', {
        task_id: this.model.task_id,
      }, false)
    },
  },
  watch: {
    allocatedTime: {
      immediate: true,
      handler(value) {
        this.initModel(value)
      },
    },
    timeEntry: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return
        }
        this.initModelFromTimeEntry(value)
      },
    },
  },
}
</script>
