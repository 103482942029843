<template>
  <span>
    <BaseInlineInput>
      <template #default="{ triggerSave }">
        <div
          class="flex items-center relative inline-select-wrapper"
          :class="{
            'is-disabled': $attrs.disabled
          }"
        >
          <template v-if="showIcon">
            <i
              class="far wrap mr-1"
              :class="{
                'fa-inbox': !isTemplate,
                'fa-layer-group': isTemplate,
              }"
              aria-hidden="true"
            /><span v-if="required && !modelValue" class="text-red-500">*</span>
          </template>
          <BasePopoverInput
            trigger="hover"
            placement="top"
            :show-after="500"
            :disabled="!modelValue"
          >
            <template #reference>
              <div class="flex items-center group">
                <ProjectSelect
                  v-bind="$attrs"
                  :modelValue="modelValue"
                  :isTemplate="isTemplate"
                  :return-object="false"
                  :clearable="false"
                  :placeholder="placeholder"
                  class="project-inline-select"
                  :allow-entity-create="can($actions.CREATE_PROJECTS)"
                  :addEntityParams="{
                    redirectAfterSave: false
                  }"
                  @update:modelValue="onUpdateModelValue($event, triggerSave)"
                  @raw-change="selectedOption = $event; searchTerm = ''"
                />
                <i
                  v-if="showActionIcon"
                  class="fa-solid fa-arrow-right group-hover:text-primary-500 ml-1" aria-hidden="true"
                />
              </div>
            </template>
            <ProjectPreviewCard
              v-if="selectedOption"
              :project="selectedOption"
            />
          </BasePopoverInput>
          <XCircleIcon 
            v-if="clearable && modelValue"
            class="circle-remove hidden ml-1 h-4 w-4 cursor-pointer text-red-300 hover:text-red-500 absolute bottom-0 -left-2 bg-white z-40 overflow-visible"
            aria-hidden="true"
            @click.stop="$emit('update:modelValue', null)"
          />
        </div>
      </template>
    </BaseInlineInput>
  </span>
</template>
<script>
import ProjectSelect from "@/components/selects/ProjectSelect.vue";
import ProjectPreviewCard from "@/modules/projects/components/ProjectPreviewCard.vue";
import { XCircleIcon } from '@heroicons/vue/outline'
import i18n from "@/i18n"

export default {
  components: {
    ProjectSelect,
    XCircleIcon,
    ProjectPreviewCard
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: i18n.t('Select a project....')
    },
    clearable: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showActionIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: this.$attrs.initialValue
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.resizeInput()
      }
    }
  },
  methods: {
    async resizeInput() {
      await this.$nextTick()

      const input = this.$el?.querySelector('.el-input__inner')
      if (!input) {
        return;
      }

      requestAnimationFrame(() => {
        input.style.width = `${Math.max(input.value.length, 15)}ch`
      })
    },
    onUpdateModelValue(value, triggerSave) {
      this.$emit('update:modelValue', value)
      triggerSave && triggerSave()
    }
  },
}
</script>
