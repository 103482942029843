<template>
  <EntitySelectNew
    v-bind="$attrs"
    ref="entitySelect"
    :placeholder="$t('Select tasks...')"
    :options="options"
    :loading="loading"
    @raw-change="emit('raw-change', $event)"
  />
</template>
<script lang="ts" setup>
// Components
import EntitySelectNew from "@/components/form/EntitySelectNew.vue";

// Utils
import { PropType, computed, ref } from "vue";

import Data = API.Data;
import Task = App.Domains.Tasks.Models.Task;

// Composables
import { useStore } from "vuex";
const store = useStore()

const props = defineProps({
  project_id: {
    type: [Number, String] as PropType<number | string> | null,
    default: null
  }
})

const emit = defineEmits([
  'raw-change'
])

const tasks = computed<Data<Task>[]>(() => {
  return (store.state.tasks.allTasks || [])
})

const options = computed(() => {
  if (!props.project_id) {
    return tasks.value
  }
  return tasks.value.filter((task) => task.attributes.project_id == props.project_id)
})

const loading = computed(() => {
  return store.state.tasks.tasksLoading
})

const entitySelect = ref<any>(null)

defineExpose({
  setQuery: () => {
    entitySelect.value?.setQuery()
  }
})
</script>
