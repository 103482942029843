export const MANAGE_ACCOUNT_SETTINGS = 'manage_account_settings'
export const ACCESS_HELP_CENTER = 'access_help_center'
export const SEE_TRIAL_PERIOD = 'see_trial_period'
export const EDIT_TASK_STATUSES = 'edit_task_statuses'
export const EDIT_PROJECT_STATUSES = 'edit_project_statuses'
export const ACCESS_PROJECTS_LIST = 'access_projects_list'
export const ACCESS_PROJECTS_CARD = 'access_projects_card'
export const ACCESS_PROJECTS_KANBAN = 'access_projects_kanban'
export const ACCESS_PROJECTS_CALENDAR = 'access_projects_calendar'
export const ACCESS_TEMPLATES_LIST = 'access_templates_list'
export const ACCESS_TEMPLATES_CARD = 'access_templates_card'
export const CREATE_PROJECTS = 'create_projects'
export const EDIT_PROJECTS = 'edit_projects'
export const DUPLICATE_PROJECTS = 'duplicate_projects'
export const CLOSE_PROJECTS = 'close_projects'
export const DELETE_PROJECTS = 'delete_projects'
export const ADD_PROJECT_TOOLS = 'add_project_tools'
export const EDIT_PROJECT_TOOLS = 'edit_project_tools'
export const DELETE_PROJECT_TOOLS = 'delete_project_tools'
export const VIEW_THEIR_PROJECTS_ONLY = 'view_their_projects_only'
export const VIEW_ALL_ACCOUNT_VISIBLE_PROJECTS = 'view_all_account_visible_projects'
export const ADD_PROJECT_PEOPLE = 'add_project_people'
export const CHANGE_PROJECT_SETTINGS_FOR_ANY_USER = 'change_project_settings_for_any_user'
export const REMOVE_PROJECT_GROUPS = 'remove_project_groups'
export const REMOVE_PEOPLE_FROM_THEIR_GROUP = 'remove_people_from_their_group'
export const REMOVE_PEOPLE_FROM_ANY_GROUP = 'remove_people_from_any_group'
export const EDIT_NOTIFICATION_STATUS_FOR_PEOPLE_FROM_THEIR_GROUP = 'edit_notification_status_for_people_from_their_group'
export const EDIT_NOTIFICATION_STATUS_FOR_PEOPLE_FROM_ANY_GROUP = 'edit_notification_status_for_people_from_any_group'
export const EDIT_PROJECT_DISCUSSION_COMMENTS = 'edit_project_discussion_comments'
export const DELETE_PROJECT_DISCUSSION_COMMENTS = 'delete_project_discussion_comments'
export const CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS = 'create_custom_fields_inside_projects'
export const EDIT_CUSTOM_FIELDS_INSIDE_PROJECTS = 'edit_custom_fields_inside_projects'
export const DELETE_CUSTOM_FIELDS_INSIDE_PROJECTS = 'delete_custom_fields_inside_projects'
export const CREATE_CUSTOM_FIELDS = 'create_custom_fields'
export const EDIT_CUSTOM_FIELDS = 'edit_custom_fields'
export const DELETE_CUSTOM_FIELDS = 'delete_custom_fields'
export const CREATE_NOTE_REFERENCES = 'create_note_references'
export const CHANGE_PROJECT_PRIVACY = 'change_project_privacy'
export const CREATE_PROJECT_FILES = 'create_project_files'
export const RENAME_PROJECT_FILES = 'rename_project_files'
export const DELETE_PROJECT_FILES = 'delete_project_files'
export const CREATE_PROJECT_FILE_PROOFS = 'create_project_file_proofs'
export const DELETE_PROJECT_FILE_PROOFS = 'delete_project_file_proofs'
export const CREATE_PROJECT_FOLDERS = 'create_project_folders'
export const EDIT_PROJECT_FOLDERS = 'edit_project_folders'
export const DELETE_PROJECT_FOLDERS = 'delete_project_folders'
export const CREATE_PROJECT_EMBEDS = 'create_project_embeds'
export const EDIT_PROJECT_EMBEDS = 'edit_project_embeds'
export const DELETE_PROJECT_EMBEDS = 'delete_project_embeds'
export const CREATE_PROJECT_TEMPLATES = 'create_project_templates'
export const EDIT_PROJECT_TEMPLATES = 'edit_project_templates'
export const VIEW_AND_USE_PROJECT_TEMPLATES = 'view_and_use_project_templates'
export const ACCESS_TASKS_LIST = 'access_tasks_list'
export const ACCESS_TASKS_CALENDAR = 'access_tasks_calendar'
export const ACCESS_TASKS_SCHEDULER = 'access_tasks_scheduler'
export const ACCESS_TASKS_KANBAN = 'access_tasks_kanban'
export const CREATE_TASKS = 'create_tasks'
export const VIEW_TASK_EVENTS_IN_TASK_VIEWS = 'view_task_events_in_task_views'
export const VIEW_ALL_TASKS = 'view_all_tasks'
export const OPEN_TASKS = 'open_tasks'
export const EDIT_TASKS = 'edit_tasks'
export const CHANGE_TASK_VISIBILITY = 'change_task_visibility'
export const DELETE_TASKS = 'delete_tasks'
export const INVITE_NEW_PEOPLE = 'invite_new_people'
export const ASSIGN_PEOPLE_TO_THEIR_GROUP_ONLY = 'assign_people_to_their_group_only'
export const ASSIGN_PEOPLE_TO_ANY_GROUP = 'assign_people_to_any_group'
export const ASSIGN_ROLES_TO_PEOPLE = 'assign_roles_to_people'
export const VIEW_ARCHIVED_USERS = 'view_archived_users'
export const HAVE_TASKS_ALLOCATED_TO_THEM = 'have_tasks_allocated_to_them'
export const EDIT_CUSTOM_FIELD_VALUES_FOR_USERS = 'edit_custom_field_values_for_users'
export const CREATE_GROUPS = 'create_groups'
export const VIEW_THEIR_OWN_GROUP = 'view_their_own_group'
export const VIEW_ALL_GROUPS = 'view_all_groups'
export const EDIT_THEIR_OWN_GROUP = 'edit_their_own_group'
export const EDIT_ALL_GROUPS = 'edit_all_groups'
export const DELETE_GROUPS = 'delete_groups'
export const BELONG_TO_MAX_ONE_GROUP = 'belong_to_max_one_group'
export const BELONG_TO_MULTIPLE_GROUPS = 'belong_to_multiple_groups'
export const VIEW_TIME_ENTRIES = 'view_time_entries'
export const CREATE_TIME_ENTRIES = 'create_time_entries'
export const EDIT_TIME_ENTRIES = 'edit_time_entries'
export const DELETE_TIME_ENTRIES = 'delete_time_entries'
export const CREATE_TIME_ENTRIES_TIMER = 'create_time_entries_timer'
export const EDIT_TIME_ENTRIES_TIMER = 'edit_time_entries_timer'
export const DELETE_TIME_ENTRIES_TIMER = 'delete_time_entries_timer'
export const BE_ALLOCATED_TO_TIME_ENTRIES = 'be_allocated_to_time_entries'
export const CREATE_PAYMENTS = 'create_payments'
export const EDIT_PAYMENTS = 'edit_payments'
export const DELETE_PAYMENTS = 'delete_payments'
export const EDIT_ALLOCATED_TIME_ON_PROJECTS = 'edit_allocated_time_on_projects'
export const MANAGE_ALL_USERS_VIEWS = 'manage_all_users_views'
export const VIEW_INTEGRATIONS = 'view_integrations'
export const CREATE_WEBHOOKS = 'create_webhooks'
export const CREATE_API_KEYS = 'create_api_keys'
export const DOCUMENT_PROOFING = 'has_document_proofing'
export const RECURRING_PAYMENTS = 'has_recurring_payments'
export const TASK_TIMERS = 'has_task_timer'
export const GANTT_CHART = 'has_gantt_chart'
export const PUBLIC_API = 'has_public_api'
export const WEB_HOOKS = 'has_webhooks'
export const CUSTOM_BRANDING = 'has_custom_branding'
export const CUSTOM_DOMAIN = 'has_custom_domain'
export const CUSTOM_EMAIL = 'has_custom_email'
export const AI_ASSISTANT = 'has_ai_assistant'
export const CSV_BOX_IMPORT = 'has_csv_box_project_import'
export const SIMULATE_ROLES = 'simulate_roles'
export const EDIT_ALL_DASHBOARDS = 'edit_all_dashboards'
export const USE_SPEECH_TO_TEXT = 'use_speech_to_text'
export const USE_AI_ASSISTANT = 'use_ai_assistant'

export default {
  MANAGE_ACCOUNT_SETTINGS,
  ACCESS_HELP_CENTER,
  SEE_TRIAL_PERIOD,
  ACCESS_PROJECTS_LIST,
  ACCESS_PROJECTS_CARD,
  ACCESS_PROJECTS_KANBAN,
  ACCESS_PROJECTS_CALENDAR,
  ACCESS_TEMPLATES_LIST,
  ACCESS_TEMPLATES_CARD,
  CREATE_PROJECTS,
  EDIT_PROJECTS,
  DUPLICATE_PROJECTS,
  CLOSE_PROJECTS,
  DELETE_PROJECTS,
  ADD_PROJECT_TOOLS,
  EDIT_PROJECT_TOOLS,
  DELETE_PROJECT_TOOLS,
  VIEW_THEIR_PROJECTS_ONLY,
  VIEW_ALL_ACCOUNT_VISIBLE_PROJECTS,
  ADD_PROJECT_PEOPLE,
  CHANGE_PROJECT_SETTINGS_FOR_ANY_USER,
  REMOVE_PROJECT_GROUPS,
  REMOVE_PEOPLE_FROM_THEIR_GROUP,
  REMOVE_PEOPLE_FROM_ANY_GROUP,
  EDIT_NOTIFICATION_STATUS_FOR_PEOPLE_FROM_THEIR_GROUP,
  EDIT_NOTIFICATION_STATUS_FOR_PEOPLE_FROM_ANY_GROUP,
  EDIT_PROJECT_DISCUSSION_COMMENTS,
  DELETE_PROJECT_DISCUSSION_COMMENTS,
  CREATE_CUSTOM_FIELDS_INSIDE_PROJECTS,
  EDIT_CUSTOM_FIELDS_INSIDE_PROJECTS,
  DELETE_CUSTOM_FIELDS_INSIDE_PROJECTS,
  CREATE_CUSTOM_FIELDS,
  EDIT_CUSTOM_FIELDS,
  DELETE_CUSTOM_FIELDS,
  CREATE_NOTE_REFERENCES,
  CHANGE_PROJECT_PRIVACY,
  CREATE_PROJECT_FILES,
  RENAME_PROJECT_FILES,
  DELETE_PROJECT_FILES,
  CREATE_PROJECT_FILE_PROOFS,
  DELETE_PROJECT_FILE_PROOFS,
  CREATE_PROJECT_FOLDERS,
  EDIT_PROJECT_FOLDERS,
  DELETE_PROJECT_FOLDERS,
  CREATE_PROJECT_EMBEDS,
  EDIT_PROJECT_EMBEDS,
  DELETE_PROJECT_EMBEDS,
  CREATE_PROJECT_TEMPLATES,
  EDIT_PROJECT_TEMPLATES,
  VIEW_AND_USE_PROJECT_TEMPLATES,
  ACCESS_TASKS_LIST,
  ACCESS_TASKS_CALENDAR,
  ACCESS_TASKS_SCHEDULER,
  ACCESS_TASKS_KANBAN,
  CREATE_TASKS,
  VIEW_TASK_EVENTS_IN_TASK_VIEWS,
  VIEW_ALL_TASKS,
  OPEN_TASKS,
  EDIT_TASKS,
  CHANGE_TASK_VISIBILITY,
  DELETE_TASKS,
  INVITE_NEW_PEOPLE,
  ASSIGN_PEOPLE_TO_THEIR_GROUP_ONLY,
  ASSIGN_PEOPLE_TO_ANY_GROUP,
  ASSIGN_ROLES_TO_PEOPLE,
  VIEW_ARCHIVED_USERS,
  HAVE_TASKS_ALLOCATED_TO_THEM,
  EDIT_CUSTOM_FIELD_VALUES_FOR_USERS,
  CREATE_GROUPS,
  VIEW_THEIR_OWN_GROUP,
  VIEW_ALL_GROUPS,
  EDIT_THEIR_OWN_GROUP,
  EDIT_ALL_GROUPS,
  DELETE_GROUPS,
  BELONG_TO_MAX_ONE_GROUP,
  BELONG_TO_MULTIPLE_GROUPS,
  VIEW_TIME_ENTRIES,
  CREATE_TIME_ENTRIES,
  EDIT_TIME_ENTRIES,
  DELETE_TIME_ENTRIES,
  CREATE_TIME_ENTRIES_TIMER,
  EDIT_TIME_ENTRIES_TIMER,
  DELETE_TIME_ENTRIES_TIMER,
  BE_ALLOCATED_TO_TIME_ENTRIES,
  CREATE_PAYMENTS,
  EDIT_PAYMENTS,
  DELETE_PAYMENTS,
  EDIT_ALLOCATED_TIME_ON_PROJECTS,
  EDIT_TASK_STATUSES,
  EDIT_PROJECT_STATUSES,
  MANAGE_ALL_USERS_VIEWS,
  VIEW_INTEGRATIONS,
  CREATE_WEBHOOKS,
  CREATE_API_KEYS,
  SIMULATE_ROLES,
  EDIT_ALL_DASHBOARDS,
  USE_SPEECH_TO_TEXT,
  USE_AI_ASSISTANT,

  // Subscription based actions
  DOCUMENT_PROOFING,
  RECURRING_PAYMENTS,
  TASK_TIMERS,
  GANTT_CHART,
  PUBLIC_API,
  WEB_HOOKS,
  CUSTOM_BRANDING,
  CUSTOM_EMAIL,
  CUSTOM_DOMAIN,
  AI_ASSISTANT,
  CSV_BOX_IMPORT,
}
