<template>
  <BaseDialogNew
    v-bind="$attrs"
    class="w-full max-w-2xl relative ai-chat-dialog"
    :showClose="false"
    @close="onDialogClose"
  >
    <AiChat
      ref="aiChatRef"
    />
  </BaseDialogNew>
</template>
<script setup lang="ts">
// Components
import AiChat from "@/modules/ai/components/AiChat.vue";

// Composables
import { useStore } from 'vuex'
const store = useStore()

function onDialogClose() {
  store.commit('accounts/triggerCloseSpeechToText')
}
</script>

