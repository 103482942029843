<template>
  <div class="border border-gray-200 rounded-md bg-white shadow py-2 px-1 w-[320px] max-h-[320px] overflow-y-auto">
    <template
      v-if="filteredItems.length"
      v-for="(item, index) in filteredItems"
      :key="item.title"
    >
      <div
        v-if="shouldShowCategory(index)"
        class="px-2 text-xs font-bold uppercase text-gray-500 py-1"
      >
        {{ item.category }}
      </div>
      <BaseAvatarPicker
        v-if="item.title === $t('Image')"
        ref="avatarPicker"
        v-model="image"
        :append-to-body="false"
        :disabled-options="['group', 'emoji', 'unsplash', 'clearbit']"
        :upload-function="uploadImage"
        @update:modelValue="onImageUpload"
      >
        <template #activator="{ avatar }">
          <CommandItem
            :item="item"
            :index="index"
            :selectedIndex="selectedIndex"
          />
        </template>
      </BaseAvatarPicker>

      <EmojiPickerPopover
        v-else-if="item.title === $t('Emoji')"
        ref="emojiPicker"
        @change="onEmojiChanged"
      >
        <CommandItem
          :item="item"
          :index="index"
          :selectedIndex="selectedIndex"
        />

      </EmojiPickerPopover>

      <LinkPopover
        v-else-if="item.title === $t('Link')"
        ref="linkPopover"
        @change="onLinkEnter"
      >
        <CommandItem
          :item="item"
          :index="index"
          :selectedIndex="selectedIndex"
        />
      </LinkPopover>

      <DropboxFilePickerButton
        v-else-if="item.title === $t('Dropbox')"
        :editor="editor"
        @select="onDropboxFileSelect"
      >
        <CommandItem
          :item="item"
          :index="index"
          :selectedIndex="selectedIndex"
        />
      </DropboxFilePickerButton>

      <GoogleDriveFilePickerButton
        v-else-if="item.title === $t('Google Drive')"
        :editor="editor"
        @select="onGoogleDriveFileSelect"
      >
        <CommandItem
          :item="item"
          :index="index"
          :selectedIndex="selectedIndex"
        />
      </GoogleDriveFilePickerButton>

      <CommandItem
        v-else
        :item="item"
        :index="index"
        :selectedIndex="selectedIndex"
        @click="selectItem(index)"
      />
    </template>
    <div v-else class="result-item text-sm text-gray-700">
      {{ $t('No results') }}
    </div>

    <EditorEmbedDialogForm
      v-if="showEmbedDialog"
      v-model="showEmbedDialog"
      @cancel="showEmbedDialog = false"
      @submit="onEmbedSubmit"
    />
  </div>
</template>

<script lang="ts">
import BaseAvatarPicker from "@/components/common/BaseAvatarPicker.vue";
import CommandItem from "@/components/html/extensions/components/CommandItem.vue";
import EditorEmbedDialogForm from "@/components/html/extensions/components/EditorEmbedDialogForm.vue";
import store from "@/store/index.js";
import EmojiPickerPopover from "@/components/selects/EmojiPickerPopover.vue";
import { Commands } from "@/components/html/util/tipTapUtils.js";
import LinkPopover from "@/components/selects/LinkPopover.vue";
import * as FileUtils from "@/modules/common/utils/fileUtils";
import { defineComponent, PropType } from "vue";
import { SuggestionItem } from "@/components/html/types/suggestionTypes";
import { Editor } from "@tiptap/core";
import { EmojiClickEventDetail } from "vuemoji-picker";
import DropboxFilePickerButton from "@/components/html/extensions/components/DropboxFilePickerButton.vue";
import GoogleDriveFilePickerButton from "@/components/html/extensions/components/GoogleDriveFilePickerButton.vue";

const Keys = {
  Up: 'ArrowUp',
  Down: 'ArrowDown',
  Enter: 'Enter',
}
export default defineComponent({
  components: {
    GoogleDriveFilePickerButton,
    DropboxFilePickerButton,
    LinkPopover,
    CommandItem,
    BaseAvatarPicker,
    EmojiPickerPopover,
    EditorEmbedDialogForm,
  },
  props: {
    items: {
      type: Array as PropType<SuggestionItem[]>,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
    editor: {
      type: Object as PropType<Editor>,
      default: () => ({})
    },
  },

  data() {
    return {
      Commands,
      selectedIndex: 0,
      image: null,
      showEmbedDialog: false,
      uploadId: `upload-${new Date().getTime()}`
    }
  },

  computed: {
    filteredItems() {
      return this.items.filter(item => {
        if (item.disableInsert === true) {
          return false
        }
        if (item.enabled) {
          return item.enabled(this.editor)
        }
        return true
      })
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
    async selectedIndex() {
      await this.scrollToSelectedItem()
    }
  },
  methods: {
    onKeyDown({ event }: { event: KeyboardEvent }) {
      if (event.key === Keys.Up) {
        this.upHandler()
        return true
      }

      if (event.key === Keys.Down) {
        this.downHandler()
        return true
      }

      if (event.key === Keys.Enter) {
        this.enterHandler()
        return true
      }

      return false
    },
    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },
    enterHandler() {
      this.selectItem(this.selectedIndex)
    },
    async scrollToSelectedItem() {
      await this.$nextTick();
      const selectedItem = this.$el.querySelector('.result-item.is-selected');
      if (!selectedItem) {
        return
      }
      selectedItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    },
    findItem(title: string) {
      return this.items.find(i => i.title === this.$t(title))
    },
    async uploadImage(file: File) {
      const projectId = this.$store.state.projects?.currentProject?.id
      const noteId = this.$route?.params?.noteId
      let uploadedFile: any = {}
      if (projectId) {
        uploadedFile = await store.dispatch('projects/uploadFileToProject', { projectId, noteId, file })
        return FileUtils.createFileURL({
          uuid: uploadedFile.uuid,
        })
      }
      return store.dispatch('files/uploadImage', file)
    },
    onImageUpload(value: string) {
      if (!value) {
        return
      }
      this.$success(this.$t('This image has also been added to the files page for this project (visible to creators only).'))

      const imageItem = this.findItem('Image')
      if (!imageItem) {
        return
      }
      this.command({
        ...imageItem,
        src: value,
      })
    },
    triggerFileInput() {
      let input = document.createElement('input');
      input.type = 'file';
      input.setAttribute('multiple', 'true')
      input.onchange = (event: Event) => {
        this.onFilesChange(event)
      }
      input.click();
    },
    onDropboxFileSelect() {
      const item = this.items.find(i => i.title === this.$t('Dropbox'))
      this.command(item)
    },
    onGoogleDriveFileSelect() {
      const item = this.items.find(i => i.title === this.$t('Google Drive'))
      this.command(item)
    },
    onFilesChange(event: Event) {
      const item = this.items.find(i => i.title === this.$t('File'))
      this.command({
        ...item,
        event,
      })
    },
    onEmojiChanged(emojiEvent: EmojiClickEventDetail) {
      const shortCodes: string[] = emojiEvent.emoji?.shortcodes || []
      const shortCode = shortCodes[0] || ''
      const item = this.items.find(i => i.title === this.$t('Emoji'))

      this.command({
        ...item,
        shortCode,
      })
    },
    onLinkEnter(link: string) {
      const item = this.items.find(i => i.title === this.$t('Link'))

      this.command({
        ...item,
        link,
      })
    },
    onEmbedSubmit({ src }: { src: string }) {
      this.showEmbedDialog = false
      const embedItem = this.items.find(i => i.title === this.$t('Embed'))
      if (!embedItem) {
        return
      }
      this.command({
        ...embedItem,
        src,
      })
    },
    shouldShowCategory(index: number) {
      const item = this.items[index]
      const previousItem = this.items[index - 1]
      return item?.category !== previousItem?.category;
    },
    selectItem(index: number) {
      const item = this.filteredItems[index]
      const needsRoleUpgrade = item?.needsRoleUpgrade && typeof item?.needsRoleUpgrade === 'function'
        ? item.needsRoleUpgrade()
        : item.needsRoleUpgrade

      if (needsRoleUpgrade) {
        return
      }

      if (item.title === this.$t('Image')) {
        this.$refs?.avatarPicker[0]?.open();
        return
      }
      if (item.title === this.$t('File')) {
        this.triggerFileInput()
      }
      if (item.title === this.$t('Emoji')) {
        this.$refs?.emojiPicker[0]?.open();
        return
      }
      if (item.title === this.$t('Link')) {
        this.$refs?.linkPopover[0]?.open();
        return
      }
      if (item.title === this.$t('Embed')) {
        this.showEmbedDialog = true
        return
      }
      if (item) {
        this.command(item)
      }
    },
  },
})
</script>
<style scoped>
.result-item {
  @apply flex items-center hover:bg-gray-100 py-1 w-full space-x-2 px-2 rounded;
}

.result-item.is-selected {
  @apply bg-gray-100;
}
</style>
