<template>
  <div class="fixed w-full z-10 top-0 print:hidden body-background pb-2 z-[1000]">
    <!--HEADER: START-->
    <header class="top-0 w-full z-10">
      <div class="w-full px-4 sm:px-6">
        <div class="relative flex justify-between">
          <!--MAIN LOGO: START-->
          <div class="hidden sm:flex pr-4 md:pr-4">
            <div class="shrink-0 flex items-center">
              <router-link to="/welcome">
                <img
                  class="block h-8 w-auto"
                  :src="logoSrc"
                  alt="Project.co"
                >
              </router-link>
            </div>
          </div>
          <!--MAIN LOGO: END-->

          <div class="flex my-auto h-10 mr-4">
            <BaseButton
              variant="white"
              type="button"
              class="group h-full rounded-none rounded-l-md shadow-none focus:border-gray-200 focus-visible:ring-2 focus:shadow-none"
              block
              :aria-label="$t('Go back')"
              :disabled="!hasBackRoute"
              @click="$router.go(-1)"
            >
              <i
                class="fa-solid fa-chevron-left"
                :class="{
                  'text-gray-400': hasBackRoute,
                  'text-gray-200': !hasBackRoute
                }"
              />
            </BaseButton>
            <BaseButton
              variant="white"
              type="button"
              class="group h-full rounded-none rounded-r-md shadow-none -ml-px outline-none focus:border-gray-200 focus-visible:ring-2 focus:shadow-none"
              block
              :aria-label="$t('Go forward')"
              :disabled="!hasForwardRoute"
              @click="$router.go(+1)"
            >
              <i
                class="fa-solid fa-chevron-right"
                :class="{
                  'text-gray-400': hasForwardRoute,
                  'text-gray-200': !hasForwardRoute
                }"
              />
            </BaseButton>
          </div>

          <!--SEARCH: START-->
          <div class="min-w-0 flex-1 ">
            <div class="flex items-center py-3">
              <div class="w-full">
                <label for="search" class="sr-only">{{ $t('Search for anything...') }}</label>
                <div class="relative">
                  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <i class="far fa-search text-gray-400"></i>
                  </div>
                  <input v-model="searchQuery"
                         :placeholder="$t('Search for anything...')"
                         id="search"
                         name="search"
                         class="block w-full h-10 bg-white border border-gray-200 overflow-hidden rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                         type="search"
                         @search="onSearch"
                         @keydown.enter="onSearch"
                  >
                </div>
              </div>
            </div>
          </div>
          <!--SEARCH: END-->
          <!-- QUICK LINKS  -->
          <div class="hidden sm:flex space-x-4 mx-4">
            <BaseButton
              class="px-4 py-3 items-center justify-center my-3 focus:border-gray-200 focus-visible:ring-2 shadow-none"
              variant="white"
              :aria-label="$t('Starred pages button')"
              @click="openStarredPages"
            >
              <i
                class="fas fa-star text-gray-300 group-hover:text-yellow-500 cursor-pointer transition ease-in-out duration-150"
              />
            </BaseButton>
            <RequiresPermissionTo :action="$actions.CREATE_TIME_ENTRIES_TIMER">
              <BaseButton
                :aria-label="$t('Record time')"
                class="relative px-4 py-3 items-center justify-center my-3 focus:border-gray-200 focus-visible:ring-2 shadow-none"
                variant="white"
                @click="$store.commit('time/triggerAddTimeRecordingEntry')"
              >
                <div
                  v-if="time_entry_recorded_count > 0"
                  class="notification-badge"
                >
                  {{ time_entry_recorded_count }}
                </div>
                <i
                  class="fas fa-stopwatch text-gray-300 group-hover:text-yellow-500 cursor-pointer transition ease-in-out duration-150"></i>
              </BaseButton>
            </RequiresPermissionTo>
            <router-link :to="inboxLink" tabindex="-1">
              <BaseButton
                class="relative px-4 py-3 items-center justify-center my-3 focus:border-gray-200 focus-visible:ring-2 shadow-none"
                variant="white"
              >
                <div
                  v-if="unread_comments_count > 0"
                  class="notification-badge"
                >
                  {{ unread_comments_count }}
                </div>
                <i
                  class="fas fa-bell text-gray-300 group-hover:text-green-500 cursor-pointer transition ease-in-out duration-150"></i>
              </BaseButton>
            </router-link>
          </div>
          <!--CREATE NEW BUTTON: START-->
          <CreateNewMenu trigger="hover">
            <template #activator>
              <div class="hidden md:flex">
                <div id="menu">
                  <BaseButton
                    class="mt-3 !border-gray-200 shadow-none h-10"
                    variant="primary"
                    id="options-menu"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    +&nbsp;<span class="hidden md:block">{{ $t('Create New') }}</span>
                  </BaseButton>
                </div>
              </div>
            </template>
          </CreateNewMenu>
          <!--PROFILE BUTTON: END-->
        </div>
      </div>
    </header>
    <!--HEADER: END-->

    <DashboardBreadcrumbs/>
  </div>
</template>
<script lang="ts">
import DashboardBreadcrumbs from '@/modules/dashboard/components/DashboardBreadcrumbs.vue'
import CreateNewMenu from '@/modules/common/components/CreateNewMenu.vue'
import { desktopAppUtils } from '@/modules/common/utils/desktopAppUtils.js'
import { getHeaderLogo } from '@/modules/accounts/utils/accountBrandingUtils'
import { defineComponent } from "vue";
import { notificationStatuses } from '@/modules/accounts/utils/notificationUtils'

export default defineComponent({
  components: {
    DashboardBreadcrumbs,
    CreateNewMenu,
  },
  data() {
    return {
      inboxLink: `/inbox?status=${notificationStatuses.Unread}`,
      searchQuery: this.$route.query.keyword || '',
    }
  },
  computed: {
    stats() {
      return this.$store.state.accounts.stats
    },
    starred_pages_count() {
      return this.stats?.starred_pages_count || 0
    },
    unread_comments_count() {
      return this.stats?.unread_comments_count || 0
    },
    time_entry_recorded_count() {
      return this.$store.state.time.stats.time_entry_recorded_count || 0
    },
    hasBackRoute() {
      return this.$route.path && !!window.history?.state?.back
    },
    hasForwardRoute() {
      return this.$route.path && !!window.history?.state?.forward
    },
    subscription() {
      return this.$store.state.accounts.subscription || {}
    },
    isCustomBrandingEnabled() {
      return this.$store.getters['accounts/isCustomBrandingEnabled']
    },
    tempBranding() {
      return this.$store.state.accounts.tempBranding
    },
    logoSrc() {
      return getHeaderLogo()
    },
  },
  methods: {
    onSearch() {
      this.$router.push({
        path: `/search`,
        query: {
          keyword: this.searchQuery,
        },
      })
    },
    resetQuery() {
      this.searchQuery = ''
    },
    getRecordedTimeEntriesSummary() {
      this.$store.dispatch("time/getRecordedTimeEntriesSummary")
    },
    openStarredPages() {
      this.$store.commit('accounts/triggerOpenStarredPages')
    }
  },
  created() {
    this.getRecordedTimeEntriesSummary()
  },
  watch: {
    '$route.fullPath'(newPath, oldPath) {
      if (oldPath.includes('/search') && !newPath.includes('/search')) {
        this.resetQuery()
      }
    },
    unread_comments_count(newValue) {
      desktopAppUtils.setBadgeCount(newValue)
    },
  },
})
</script>
<style lang="scss">
.notification-badge {
  @apply absolute -top-2 -left-2 min-w-[20px] rounded-full bg-red-500 py-0.5 px-1 text-xs text-white leading-4;
}
</style>
