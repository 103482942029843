<template>
  <div class="credit-card">
    <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start mb-4 flex items-center">
      <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {{ $t('Card details') }}
      </label>
      <div class="ml-2 sm:mt-px sm:col-span-3 form-input card-input"
           id="cardNumber">
      </div>
      <slot />
      <template v-if="cardErrors">
        <span class="sm:col-span-1"></span>
        <span class="flex-1 max-w-full text-sm leading-none text-red-600 sm:col-span-3 ml-2">
          {{ cardErrors }}
        </span>
      </template>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    validationCardMessage: '',
    useGlobalKey: Boolean,
  },
  data() {
    return {
      stripe: null,
      elements: null,
      cardErrors: null,
      card: null,
      elementStyles: {
        base: {
          color: 'rgb(17, 24, 39)',
          lineHeight: '1.5',
          fontWeight: 'normal',
          fontSize: '14px',
          '::placeholder': {
            color: '#a3a3a3',
            fontWeight: '500',
            fontSize: '16px',
          },
        },
        invalid: {
          color: 'rgb(220, 38, 38)',
        },
      },
      elementClasses: {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      },
      cardElements: [],
      focusStates: false,
    }
  },
  computed: {
    stripePK() {
      if (this.useGlobalKey) {
        return import.meta.env.VITE_STRIPE_KEY
      }
      return this.$store.getters['accounts/getStripeKey']
    }
  },
  methods: {
    async confirmPaymentIntent(data) {
      let { clientSecret, fullName } = data
      let { paymentIntent, error } = await this.stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: fullName
          },
        }
      });
      return {
        paymentIntent: paymentIntent,
        error: error
      }
    },
    async loadStripeScript() {
      return new Promise((resolve, reject) => {
        if (window.Stripe) {
          resolve()
          return
        }
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3'
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
      })
    },
    setupStripe() {
      this.stripe = window.Stripe(this.stripePK);
      this.elements = this.stripe.elements();
      
      this.card = this.elements.create('card', {
        style: this.elementStyles,
        classes: this.elementClasses,
      });
      this.card.mount('#cardNumber');
      this.initCardEvents()
    },
    initCardEvents() {
      this.card.on('focus', () => {
        this.focusStates = true
      })
      this.card.on('blur', () => {
        this.focusStates = false
        if (this.cardErrors) {
          return
        }
        this.cardErrors = this.validationCardMessage
      })
      this.card.on('change', (event) => {
        if (event.complete) {
          const { value } = event
          let postalCode = ''
          if (value && value.postalCode) {
            postalCode = value.postalCode
          }
          if (postalCode && postalCode.length < 4) {
            return
          }
          this.$emit('valid-changed', true);
        } else if (event.empty || event.error) {
          this.$emit('valid-changed', false);
        }
      })
      this.card.addEventListener('change', (event) => {
        this.cardErrors = event.error ? event.error.message : ''
      });
    },
    async createPaymentMethod() {
      const { paymentMethod, error } = await this.stripe.createPaymentMethod('card', this.card);

      return {
        paymentMethod,
        error,
      }
    }
  },
  async mounted() {
    await this.loadStripeScript()
    this.setupStripe()
  },
  beforeDestroy() {
    this.card.unmount();
    this.card.destroy();
    window.Stripe.instance = null;
    window.Stripe.elements = null;
    window.Stripe.createToken = null;
  },
  watch: {
    validationCardMessage: {
      immediate: true,
      handler(value) {
        if (this.cardErrors) {
          return
        }
        this.cardErrors = value
      }
    }
  }
};
</script>
<style>
.card-input {
  min-height: 40px;
}
</style>