<template>
  <div
    class="w-60 lg:w-80 col-span-1 flex flex-col"
    :class="`col-start-${index + 1}`"
  >
    <div
      class="flex group z-10"
    >
      <h3
        class="flex-1 my-0 font-bold capitalize truncate"
        :class="{
          'leading-6 text-gray-900': $useNewLayout,
          'text-white': !$useNewLayout,
        }"
      >
        <i
          v-if="option.color"
          class="fa-solid fa-circle mr-2 pt-1"
          aria-hidden="true"
          :style="{ color: option.color }"
        />
        {{ option.label }}
      </h3>
      <KanbanColumnOptions
        entity="project"
        :selectedField="selectedField"
        @add-new-entity="showCreateProject = true"
        @edit-columns="$emit('edit-columns')"
      />
    </div>
    <draggable
      v-model="projects"
      @update:modelValue="tryUpdateOrder"
      :animation="200"
      :delay="dragDelay"
      ghost-class="ghost-card"
      class="min-h-col pdc-pk-col h-full mt-2"
      :group="`projects_${groupBy?.value ||''}`"
      item-key="id"
      tag="div"
      :move="checkMove"
      :disabled="!can($actions.EDIT_PROJECTS)"
      @change="onDragChange"
      @end="onDragEnd"
    >
      <template #item="{ element }">
        <ProjectCard
          :project="element"
          class="kanban-card"
        />
      </template>

      <template #footer>
        <div
          v-if="projectsLoading && !projects?.length"
          class="space-y-3"
          :class="{
            'mt-3': projects?.length
          }"
        >
          <EntityLoadingCards
            :params="{
              columns: cardLoadingColumns
            }"
            :count="2"
          />
        </div>
        <BaseButton
          v-else-if="canDisplayMore"
          class="mb-8 mt-3"
          block
          @click="currentPage++"
        >
          {{ $t('Show more...') }}
        </BaseButton>
        <div
          v-else-if="!projects?.length"
          class="text-center text-gray-400 text-xs"
        >
          {{ $t('No projects') }}
        </div>
      </template>
    </draggable>

    <ProjectDialog
      v-show="showCreateProject"
      v-model="showCreateProject"
      :project="projectTemplate"
      :redirectAfterSave="false"
      @save="onProjectCreate"
      @close="showCreateProject = false"
    />
  </div>
</template>
<script>
// Components
import draggable from "vuedraggable/src/vuedraggable"
import ProjectCard from "@/modules/projects/components/ProjectCard.vue";
import ProjectDialog from "@/modules/projects/components/ProjectDialog.vue";
import KanbanColumnOptions from "@/modules/common/components/KanbanColumnOptions.vue"
import EntityLoadingCards from "@/components/table/EntityLoadingCards.vue";

// Helpers
import { columnBuilder } from '@/components/table/tableUtils'
import {
  KANBAN_DRAG_DELAY_MOBILE,
  KANBAN_DRAG_DELAY_ACTIVE_MAX_WIDTH,
  updateKanbanEntity,
  updateKanbanEntititesOrder,
  getColumnOptionValuePath,
  getProjectTemplate
} from "@/modules/common/utils/kanbanUtils";
import { useWindowSize } from '@vueuse/core'
import { get } from "lodash-es"
import { computed } from "vue";
import { getSetting } from "@/plugins/settingsPlugin";

export default {
  components: {
    draggable,
    ProjectCard,
    ProjectDialog,
    KanbanColumnOptions,
    EntityLoadingCards,
  },
  props: {
    selectedField: {
      type: Object,
      required: true
    },
    option: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    data: {
      type: Array,
      default: () => []
    },
    groupBy: {
      type: Object,
      default: () => ({
        prop: '',
        value: ''
      })
    }
  },
  setup(props) {
    const { width } = useWindowSize()

    const valuePath = computed(() => {
      return getColumnOptionValuePath(props.option)
    })

    return {
      valuePath,
      width,
    }
  },
  data() {
    return {
      projects: [],
      showStatusEditDialog: false,
      dragDelay: 0,
      perPage: 10,
      currentPage: 1,
      // Create project
      creatingProject: false,
      showCreateProject: false,
      dragEnabled: true,
      showEditCustomFieldDialog: false,
    }
  },
  computed: {
    projectTemplate() {
      const template = {
        attributes: {
          ...this.option.createParams
        }
      }

      return getProjectTemplate(template, this.groupBy, this.data?.[0])
    },
    apiFilters() {
      return this.$store.getters['filters/targetApiFilters']('projects') || []
    },
    filteredData() {
      return this.data.filter(project => {
        return get(project, `attributes.${this.valuePath}`, '') == (this.option.value || '')
      })
    },
    displayCount() {
      return this.perPage * this.currentPage
    },
    displayProjects() {
      return this.filteredData.slice(0, this.displayCount)
    },
    canDisplayMore() {
      return this.displayCount < this.filteredData.length
    },
    currentSort() {
      let currentSort = this.apiFilters.find(filter => filter.key === 'sorts')?.value || []
      if (!Array.isArray(currentSort)) {
        currentSort = [currentSort]
      }
      return currentSort
    },
    allowReordering() {
      return this.currentSort?.length === 1 && this.currentSort[0].column === 'order' && this.currentSort[0].order === 'asc'
    },
    project_id() {
      return this.$store.getters.project_id
    },
    visibleColumns() {
      let columns = this.$store.getters['projects/tableColumns'] || []

      columns = columnBuilder.filterDisabledColumns(columns)

      columns = columnBuilder.remapDynamicProperties(columns)

      return columns
    },
    cardLoadingColumns() {
      const ignoredColumns = ['attributes.order', 'attributes.completed', 'attributes.created_at']
      return this.visibleColumns.filter(column => !ignoredColumns.includes(column.prop))
        .map(column => ({
          ...column,
          field: column.prop
        }))
    },
    projectsLoading() {
      return this.$store.state.projects.projectsLoading
    },
    isProjectClosed() {
      return this.$store.getters['projects/isProjectClosed']
    },
    orderedStatuses() {
      return this.$store.getters['projects/orderedStatuses']
    },
    completedStatus() {
      return this.orderedStatuses[this.orderedStatuses.length - 1]
    }
  },
  methods: {
    onProjectCreate(newProject) {
      this.showCreateProject = false
      this.projects = [newProject, ...this.projects]
      this.tryUpdateOrder()
    },
    setDragDelay(width) {
      if (width < KANBAN_DRAG_DELAY_ACTIVE_MAX_WIDTH) {
        this.dragDelay = KANBAN_DRAG_DELAY_MOBILE
      }
      else {
        this.dragDelay = 0
      }
    },
    checkMove(evt) {
      if (this.selectedField.groupByProp === 'relationships.status.attributes.name') {
        return true
      }

      const draggedElement = evt.draggedContext.element

      if (this.isProjectClosed(draggedElement)) {
        this.dragEnabled = false
        return false
      }

      return true;
    },
    async tryUpdateOrder() {
      if (!this.allowReordering) {
        return
      }
      await updateKanbanEntititesOrder('projects', this.projects, this.index)
    },
    onDragEnd() {
      if (this.dragEnabled) {
        return
      }

      this.$error('Completed projects cannot be edited')
      this.dragEnabled = true
    },
    async onDragChange(evt) {
      if (!evt.added) {
        return
      }
      
      const project = evt.added.element
      const isStatusChange = this.option?.prop == 'status_id'
      const isCompletedStatusTarget = this.option?.value == this.completedStatus?.id

      if (isStatusChange && isCompletedStatusTarget) {
        this.$store.commit('setEntityCreateParams', {
          project,
        })

        this.$store.commit('projects/triggerCompleteProject')

        // Revert UI to previous status until user confirms
        this.syncProjects()

        return false
      }

      await updateKanbanEntity(project, 'project', this.option, this.selectedField)
    },
    async syncProjects() {
      await this.$nextTick()
      this.projects = this.displayProjects
    }
  },
  watch: {
    displayProjects: {
      immediate: true,
      deep: true,
      async handler(projects) {
        await this.$nextTick()

        if (!this.$route.path.includes('kanban')) {
          return
        }

        this.syncProjects()
      }
    },
    width(value) {
     this.setDragDelay(value)
    },
  },
  mounted() {
    this.setDragDelay(this.width)
  }
}
</script>
