import i18n from "@/i18n.js";

export function getGroupFilter(group, extraFilters = []) {
  return [
    {
      key: "filters",
      value: [
        {
          name: i18n.t('Group'),
          column: "group_ids",
          query: [ group?.id ],
          displayValue: group?.attributes?.name
        }
      ],
    },
    ...extraFilters
  ]
}
