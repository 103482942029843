import en from 'date-fns/locale/en-US'
import { parseISO, format } from 'date-fns'
import { format as dateFnsTzFormat, utcToZonedTime } from "date-fns-tz"
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import i18n from "@/i18n.js";
import { getSetting } from "@/plugins/settingsPlugin.js";
import { minutesToFormattedTime } from "@/modules/common/utils/timeUtils"
import timezones from "@/modules/accounts/utils/timezones.json"
const localeMap = {
  en,
  es,
  fr,
}
export function getMinutesFromDate(date) {
  if (!date) {
    return 0;
  }

  return (date.getHours() || 0) * 60 + (date.getMinutes() || 0)
}

export function getTimeDate(hours, minutes, seconds) {
  const now = new Date()

  now.setHours(hours)
  now.setMinutes(minutes)
  now.setSeconds(seconds)

  return now
}

export function minutesToDate(minutes) {
  return getTimeDate(0, minutes, 0)
}

export const DEFAULT_DATE_FORMAT = 'dd/MM/yy'

export function formatToTimezone({ date, timezone = null, dateFormat = null }) {
  timezone = timezone || timezones.find(tz => tz.value === getSetting('timezone'))?.utc?.[0]
  dateFormat = dateFormat || `${DEFAULT_DATE_FORMAT} 'at' hh:mma`

  const timeInTimezone = utcToZonedTime(date, timezone)

  return dateFnsTzFormat(timeInTimezone, dateFormat, getDateFnsConfig())
}

export function formatDate(date, dateFormat, lowerCaseMeridians = false, withTime = false) {
  try {
    if (!dateFormat) {
      dateFormat = getSetting('date_format') || DEFAULT_DATE_FORMAT
    }

    if (!date) {
      return '- -'
    }

    if (withTime) {
      const result = formatToTimezone({
        date,
        dateFormat: `${dateFormat} 'at' hh:mma`
      })

      if (lowerCaseMeridians) {
        return result.replace('AM', 'am').replace('PM', 'pm')
      }

      return result
    }

    date = getDateWithoutTime(date)

    let dateToFormat = date
    if (typeof date === 'string') {
      dateToFormat = parseISO(date)
    }
    if (!dateToFormat.getTime || isNaN(dateToFormat.getTime())) {
      return '- -'
    }

    return format(dateToFormat, dateFormat, getDateFnsConfig())
  }
  catch (err) {
    return '<Invalid time value>'
  }
}

function getDateFnsConfig() {
  const locale = i18n.getDateFnsLocale()
  return {
    locale,
  }
}

export function formatTimeSpent(minutes) {
  return minutesToFormattedTime(minutes)
}

export function parseDateFromQuery(date) {
  let dateObject = null
  try {
    dateObject = parseISO(date)
  } catch (err) {
    console.warn('Could not parse the provided date')
  }
  return dateObject
}

export const getWeekDays = () => [
  {
    label: i18n.t('Monday'),
    value: 1,
    id: 1,
  },
  {
    label: i18n.t('Tuesday'),
    value: 2,
    id: 2
  },
  {
    label: i18n.t('Wednesday'),
    value: 3,
    id: 3
  },
  {
    label: i18n.t('Thursday'),
    value: 4,
    id: 4
  },
  {
    label: i18n.t('Friday'),
    value: 5,
    id: 5
  },
  {
    label: i18n.t('Saturday'),
    value: 6,
    id: 6
  },
  {
    label: i18n.t('Sunday'),
    value: 7,
    id: 7
  }
]

export function getUnixTimestamp(date) {
  try {
    const unix_timestamp = Math.floor(new Date(date).getTime() / 1000)
    return unix_timestamp
  }
  catch { }

  return date
}

export function getDateWithoutTime(date) {
  if (!date) {
    return date
  }

  if (date instanceof Date) {
    const [year, month, day] = [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getDate().toString().padStart(2, '0'),
    ]

    date = `${year}-${month}-${day}`
  }

  return date.substring(0, 10)
}

export function isDateBetween(date = '', interval) {
  if (!date) {
    return true
  }
  const createdAt = new Date(date).getTime()
  const min = new Date(interval?.min)?.getTime() || 0
  const max = new Date(interval?.max)?.getTime() || 0
  if (min && max) {
    return createdAt >= min && createdAt <= max
  }
  return min ? createdAt >= min : createdAt <= max
}
