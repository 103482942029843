<template>
    <div
      :class="{
        'cursor-pointer': url,
      }"
      class="w-full flex items-center space-x-1 mb-1 h-8 group hover:bg-gray-50 rounded-sm relative"
      @click="showPreviewDialog = true"
    >
    <template v-if="fileWithAttributes?.attributes && url">
      <BaseFilePreview
        :file="fileWithAttributes?.attributes"
        :thumbnail="true"
        size="md"
        class="object-cover text-center"
        :class="{
          'w-auto': isImage,
        }"
      />
      <span class="truncate text-gray-500 text-sm w-full">
        {{ name }}
        <span v-if="humanReadableSize">
          ({{ humanReadableSize }})
        </span>
      </span>
      <FilePreviewDialog
        v-model="showPreviewDialog"
        :file="fileWithAttributes"
      />
    </template>
    <template v-else>
      <BaseTooltip
        v-if="sizeLimitExceeded"
        :content="limitExceededMessage"
      >
        <i class="fa fa-circle-exclamation border-2 border-gray-100 rounded-full text-red-500 cursor-pointer text-xl leading-5" />
      </BaseTooltip>
      <BaseTooltip
        v-else-if="fileUploaded"
        :content="$t('File uploaded successfully')"
      >
        <i class="fa fa-circle-check border-2 border-gray-100 rounded-full text-primary-500 cursor-pointer text-xl leading-5" />
      </BaseTooltip>
      <ElProgress
        v-else
        :width="24"
        :stroke-width="3"
        color="var(--primary-500)"
        :percentage="progress"
        :show-text="false"
        type="circle"
      />
      <span class="truncate text-gray-500 text-sm">
        {{ name }}
        <span v-if="humanReadableSize">
          ({{ humanReadableSize }})
        </span>
      </span>
    </template>
    <div
      v-if="!disabled"
      class="hidden group-hover:flex flex-1 justify-end absolute right-2"
    >
      <BaseDropdown
        :append-to-body="true"
        :options="moreActions"
      >
        <div
          class="file-actions-dropdown inline py-1 px-2 hover:text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer select-none"
          @click.stop
        >
          <i class="fa-regular fa-ellipsis-vertical" />
        </div>
      </BaseDropdown>
    </div>
  </div>
</template>
<script>
import { ElProgress } from 'element-plus'
import * as FileUtils from "@/modules/common/utils/fileUtils.js";
import { humanReadableSize, createFileURL } from '@/modules/common/utils/fileUtils';
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";

export default {
  name: 'UploadFilePreview',
  components: {
    ElProgress,
  },
  props: {
    existingFile: {
      type: Object,
      default: () => null,
    },
    fileToUpload: {
      type: Object,
      default: () => null,
    },
    project_id: {
      type: [String, Number],
      default: null,
    },
    task_id: {
      type: [String, Number],
      default: null,
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    suppressRemove: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const {
      checkWillPassStorageLimit
    } = useAccountLimits()

    return {
      checkWillPassStorageLimit
    }
  },
  data() {
    return {
      uploadedFile: this.existingFile,
      showPreviewDialog: false,
    }
  },
  computed: {
    fileUrl() {
      if (this.uploadedFile.url) {
        return this.uploadedFile.url
      }

      return createFileURL(this.uploadedFile)
    },
    file() {
      if (this.uploadedFile) {
        return {
          id: this.uploadedFile.id,
          url: this.uploadedFile.url || this.fileUrl,
          name: this.uploadedFile.name || this.uploadedFile.file_name || '',
          file: this.uploadedFile
        }
      }

      return {
        id: this.fileToUpload.uid,
        name: this.fileToUpload.name || this.fileToUpload.file_name || '',
        file: this.fileToUpload,
      }
    },
    fileWithAttributes() {
      if (this.uploadedFile) {
        return {
          id: this.uploadedFile.id,
          attributes: {
            ...this.uploadedFile
          }
        }
      }
      return {
        id: this.fileToUpload.uid,
        attributes: {
          ...this.file
        }
      }
    },
    fileId() {
      return this.file.id
    },
    fileProgress() {
      return this.$store.state.files.uploadProgress[this.fileId]
    },
    progress() {
      return this.fileProgress?.progress || 0
    },
    isImage() {
      return FileUtils.isImage(this.file.file)
    },
    name() {
      return this.file.name
    },
    icon() {
      return FileUtils.getFileIcon(this.file.file)
    },
    url() {
      return this.file.url
    },
    moreActions() {
      return [
        {
          label: this.$t('Delete'),
          action: this.removeFile,
        },
      ]
    },
    humanReadableSize() {
      if (!this.file?.file?.size) {
        return ''
      }

      return humanReadableSize(
        this.file?.file?.size,
        /* decimals */ 2,
      )
    },
    limitExceededMessage() {
      return FileUtils.uploadSizeExceededMessage
    },
    sizeLimitExceeded() {
      return this.fileToUpload?.sizeLimitExceeded
    },
    fileUploaded() {
      return this.fileToUpload?.uploaded
    }
  },
  mounted() {
    this.checkFileSizeLimitExceeded()
    this.tryTriggerFileUpload()
  },
  methods: {
    checkFileSizeLimitExceeded() {
      // Already uploaded - no need to check
      if (this.uploadedFile) {
        return
      }

      FileUtils.checkFileSizeLimitExceeded(this.fileToUpload)
    },
    tryTriggerFileUpload() {
      if (this.uploadedFile || !this.autoUpload || this.sizeLimitExceeded) {
        return
      }

      this.triggerFileUpload()
    },
    willPassStorageLimit() {
      return this.checkWillPassStorageLimit(this.fileToUpload?.size)
    },
    async triggerFileUpload() {
      if (this.sizeLimitExceeded) {
        this.$error(this.limitExceededMessage)
        return;
      }

      if (this.willPassStorageLimit()) {
        return
      }

      let uploadedFile = {}
      const file = this.fileToUpload.raw

      file.id = this.file.id
      if (this.task_id) {
        uploadedFile = await this.$store.dispatch('tasks/uploadFileToTask', {
          taskId: this.task_id,
          file
        })
      }
      else if (this.project_id) {
        uploadedFile = await this.$store.dispatch('projects/uploadFileToProject', {
          projectId: this.project_id,
          file
        })
      }

      this.uploadedFile = {
        ...uploadedFile,
        url: FileUtils.createFileURL(uploadedFile)
      }
    },
    async removeFile() {
      this.$emit('remove')

      if (this.suppressRemove) {
        return
      }

      if (!this.uploadedFile) {
        return
      }


      const file = await this.$store.dispatch('files/getFileById', { fileId: this.fileId })
      if (!file) {
        return
      }

      await this.$store.dispatch('files/deleteFile', { file })
    },
  }
}
</script>
