import { useWebWorkerFn } from '@vueuse/core'

function computeProjectUsersMap(
  bindingArray: any[],
  allUsers: any[],
) {

  const map: {[key: string | number]: {
    user_ids: (string | number)[],
    users: any[]
  }} = {}

  bindingArray.forEach(b => {
    const user = allUsers.find(u => u.id == b.user_id)
    if (!user) {
      console.warn('User not found for project binding', b)
      return
    }

    if (!map[b.project_id]) {
      map[b.project_id] = {
        user_ids: [],
        users: []
      }
    }

    map[b.project_id].user_ids.push(b.user_id)
    map[b.project_id].users.push(user)
  })

  return map
}

export const { workerFn: projectUsersMapWorker } = useWebWorkerFn(computeProjectUsersMap)

function computeProjectGroupsMap(
  bindingArray: any[],
  allGroups: any[],
) {
  const map: {[key: string | number]: {
    group_ids: (string | number)[],
    groups: any[]
  }} = {}

  bindingArray.forEach(b => {
    const group = allGroups.find(g => g.id == b.group_id)
    if (!group) {
      console.warn('Group not found for project binding', b)
      return
    }

    if (!map[b.project_id]) {
      map[b.project_id] = {
        group_ids: [],
        groups: []
      }
    }

    map[b.project_id].group_ids.push(b.group_id)
    map[b.project_id].groups.push(group)
  })

  return map
}

export const { workerFn: projectGroupsMapWorker } = useWebWorkerFn(computeProjectGroupsMap)
