<template>
  <FormItem
    ref="formItem"
    v-bind="allAttributes"
    @clear-click="clearInput"
  >
    <!--Pass through all slots to FormItem-->
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>

    <template
      #default="{
        handleBlur, 
        inputValue,
        errorMessage,
        handleChange,
        hasSuffix, 
      }"
    >
      <slot>
        <input
          v-bind="$attrs"
          :disabled="$attrs.disabled || loading"
          :value="inputValue"
          :type="type"
          :key="key"
          :name="name || label"
          :class="{
            'form-input-error': errorMessage,
            'pl-8': $slots.prefix,
            'pr-8': hasSuffix,
            'pr-12': hasSuffix && type === 'number',
            'bg-gray-100 cursor-not-allowed': $attrs.disabled,
            'cursor-not-allowed bg-gray-100 focus:shadow-none focus:border-transparent': $attrs.readonly !== undefined,
            'flex-1': layout === 'horizontal',
            'rounded-r-none': $slots['after-input'],
            [$attrs.inputClass]: $attrs.inputClass,
          }"
          ref="input"
          class="form-input"
          @blur="onBlur(handleBlur)"
          @input="onInput($event, handleChange)"
          @keydown.enter="onEnter"
        />
        <slot name="after-input"></slot>
      </slot>
    </template>
    
  </FormItem>
</template>
<script>
import { XCircleIcon } from "@zhuowenli/vue-feather-icons";
import FormItem from "@/components/form/FormItem.vue";

export default {
  inheritAttrs: false,
  components: {
    FormItem,
    XCircleIcon,
  },
  props: {
    key: {
      type: String,
      default: '0'
    },
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number, Array, Object],
      default: '',
    },
    type: {
      type: [String, Number],
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    layout: {
      type: String,
      default: 'vertical'
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue', 'enter'],
  computed: {
    allAttributes() {
      return {
        ...this.$attrs,
        ...this.$props,
        inputWrapperClass: this.$slots['after-input'] ? 'flex' : '',
      }
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    onBlur(handleBlur) {
      handleBlur && handleBlur()
    },
    onInput(evt, handleChange) {
      this.$emit('update:modelValue', evt.target.value || null)
      handleChange && handleChange(evt)
    },
    clearInput() {
      this.$emit('update:modelValue', '')
    },
    onEnter(event) {
      this.$emit('enter', event)
    },
    validate() {
      this.$refs.formItem.validate()
    }
  },
}
</script>
