<template>
  <span
    v-if="typeof displayValue === 'string'"
    v-html="displayValue"
  />
  <div v-else-if="displayValue?.hasColor" class="flex whitespace-nowrap ml-1">
    <div
      class="w-4 h-4 rounded-full my-auto border border-white"
      :style="{
        backgroundColor: displayValue?.value,
      }"
    />
    <span class="ml-1 capitalize">
      {{ displayValue?.label }}<span v-if="!isLastValue">,</span>
    </span>
  </div>
  <span v-else class="ml-1 capitalize">
    {{ displayValue?.label }}<span v-if="!isLastValue">,</span>
  </span>
</template>
<script>
export default {
  props: {
    displayValue: {
      type: [Object, String],
      required: true,
    },
    isLastValue: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
