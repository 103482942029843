<template>
  <SettingsForm
    ref="settingsForm"
    :fields="fields"
    :title="lightForm ? '' : $t('Localisation')"
    :description="lightForm ? '' : $t('Set your account timezone, week start day and working days. This will determine the default localisation options for your account.')"
  >
    <template #default="{ model }">
      <div
        :class="{
          'grid grid-cols-6 gap-4': !lightForm,
          'space-y-4': lightForm
        }"
      >
        <BaseInput
          :label="$t('Language')"
          :info-text="$t('Settings language select tip')"
          class="col-span-6 md:col-span-3"
        >
          <LanguageSelect
            v-model="model.locale"
            id="locale"
          />
        </BaseInput>

        <BaseInput
          :label="$t('Timezone')"
          class="col-span-6 md:col-span-3"
        >
          <BaseSelect
            v-model="model.timezone"
            :placeholder="$t('Timezone')"
            :options="timezoneOptions"
            :returnObject="false"
            :clearable="true"
            filterable
            id="timezone"
          />
          <p class="text-xs text-gray-400">
            {{ $t('Current time:') }} {{ getTimeInTimezone(model.timezone, model.date_format) }}
          </p>
        </BaseInput>

        <BaseInput
          :label="$t('Date Format')"
          class="col-span-6 md:col-span-3"
        >
          <BaseSelect
            v-model="model.date_format"
            :placeholder="$t('Date Format')"
            :options="generateDateOptions(dateFormatOptions)"
            :returnObject="false"
            id="date_format"
          />
        </BaseInput>

        <BaseInput
          :label="$t('Week Start Day')"
          class="col-span-6 md:col-span-3"
        >
          <BaseSelect
            :modelValue="+model.week_start_day"
            :placeholder="$t('Week Start Day')"
            :options="weekDays"
            :returnObject="false"
            filterable
            id="week_start_day"
            @update:modelValue="model.week_start_day = $event"
          />
        </BaseInput>

        <BaseInput
          :label="$t('Working Days')"
          class="col-span-6 md:col-span-3"
        >
          <BaseSelect
            :modelValue="getWorkingDays(model)"
            :placeholder="$t('Working Days')"
            :options="weekDays"
            multiple
            id="working_days"
            :class="{
              'two-line': !lightForm
            }"
            @update:modelValue="onWorkingDaysChange(model, $event)"
          />
        </BaseInput>
      </div>
    </template>
  </SettingsForm>
</template>
<script>

import SettingsForm from "@/modules/accounts/components/SettingsForm.vue";
import BaseSelect from "@/components/form/BaseSelect.vue";
import { getWeekDays } from "@/modules/common/utils/dateUtils.js";
import { formatToTimezone } from "@/modules/common/utils/dateUtils.js";
import timezones from "@/modules/accounts/utils/timezones.json"
import LanguageSelect from "@/components/common/LanguageSelect.vue";

export default {
  components: {
    BaseSelect,
    SettingsForm,
    LanguageSelect,
  },
  props: {
    lightForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        'date_format',
        'week_start_day',
        'working_days',
        'timezone',
        'locale'
      ],
      dateFormatOptions: [
        'MM/dd/yyyy',
        'dd/MM/yyyy',
        'MM-dd-yyyy',
        'dd-MM-yyyy',
        'MMM do yyyy',
        'MMMM do yyyy',
      ],
      timeInterval: null,
      currentTime: new Date()
    }
  },
  computed: {
    weekDays() {
      return getWeekDays()
    },
    timezoneOptions() {
      return timezones
        .map(timezone => {
          return {
            id: timezone.value,
            label: timezone.text,
            value: timezone.value,
          }
        })
    },
  },
  methods: {
    getTimeInTimezone(timezone, dateFormat) {
      const tz = timezones.find(t => t.value === timezone)?.utc?.[0]
      return formatToTimezone({
        date: this.currentTime.toISOString(),
        timezone: tz,
        dateFormat: `${dateFormat} 'at' hh:mma`
      })
    },
    generateDateOptions(options) {
      const date = new Date()
      return options.map(option => {
        return {
          id: option,
          value: option,
          label: this.$formatDate(date, option),
        }
      })
    },
    getWorkingDays(model) {
      try {
        return JSON.parse(model?.working_days).map(x => +x)
      } catch (err) {
        return []
      }
    },
    onWorkingDaysChange(model, value) {
      model.working_days = JSON.stringify(value?.map(day => day.value));
    },
    startCurrentTimeInterval() {
      this.timeInterval = setInterval(() => {
        this.currentTime = new Date()
      }, 1000);
    },
    clearCurrentTimeInterval() {
      clearInterval(this.timeInterval)
    },
    async onSubmit() {
      await this.$refs.settingsForm?.onSubmit()
    }
  },
  mounted() {
    this.startCurrentTimeInterval()
  },
  unmounted() {
    this.clearCurrentTimeInterval()
  },
}
</script>
