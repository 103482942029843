<template>
  <BaseTableCell :params="params">
    <TimeRunningClock
      :disabled="!authorizedToEdit"
      :tip="toolTip"
      :action-label="isRecording ? $t('Stop') : $t('Start')"
      :is-running="!!isRecording"
      @triggerTimerAction="triggerTimerAction"
    />
  </BaseTableCell>
</template>
<script lang="ts" setup>
// Components
import TimeRunningClock from '@/modules/time/components/TimeRunningClock.vue'

// Utils
import i18n from '@/i18n'
import { computed } from "vue"
import { TimerStatusTypes } from '@/modules/time/utils/timeEntryUtils'
import { EDIT_TIME_ENTRIES } from '@/modules/common/enum/actionsEnum'

// Composables
import { useStore } from 'vuex'
import useCan from "@/modules/common/composables/useCan";
const store = useStore()

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const emit = defineEmits(['save'])

const row = computed(() => {
  return props.params?.data
})

const isRecording = computed(() => {
  return row.value.attributes.status === TimerStatusTypes.Recording
})

const isProjectClosed = computed(() => {
  const project = row.value.relationships?.project
  if (!project) {
    return true
  }
  return store.getters['projects/isProjectClosed'](project)
})

const canEdit = computed(() => {
  return store.getters['users/can'](EDIT_TIME_ENTRIES)
})

const { can, actions } = useCan()
const hasTimerFeature = computed(() => can(actions.TASK_TIMERS))

const authorizedToEdit = computed(() => {
  const canEditEntry = isRecording.value || !isProjectClosed.value || !canEdit.value
  return canEditEntry && hasTimerFeature.value
})

const toolTip = computed(() => {
  if (!hasTimerFeature.value) {
    return i18n.t('Upgrade your plan in order to use timers.')
  }
  if (isRecording.value) {
    return i18n.t('Stop Time Recording')
  }
  return i18n.t('Start Time Recording')

})

async function triggerTimerAction() {
  let data: any = {}

  if (isRecording.value) {
    data = await store.dispatch('time/stopTimer', row.value.id)
  } else {
    data = await store.dispatch('time/startTimer', row.value.id)
  }
  row.value.attributes.status = data.status
  row.value.attributes.worked_minutes = data.worked_minutes

  emit('save')
}
</script>
