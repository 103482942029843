<template>
  <span class="isolate inline-flex rounded-md">
    <button
      type="button"
      class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 focus:z-10 hover:opacity-100"
      :class="{
        'opacity-100': chatMode === ChatModes.Create,
        'opacity-60': chatMode !== ChatModes.Create,
      }"
      @click="chatMode = ChatModes.Create"
    >
      
      {{ $t('Create') }}
    </button>
    <button
      type="button"
      class="relative inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 ml-px hover:opacity-100"
      :class="{
        'opacity-100': chatMode === ChatModes.Action,
        'opacity-60': chatMode !== ChatModes.Action,
      }"
      @click="chatMode = ChatModes.Action"
    >
      {{ $t('Action') }}
    </button>
  </span>
</template>
<script lang="ts" setup>
import { ChatModes } from '@/modules/ai/types/aiTypes';
import { PropType, computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String as PropType<ChatModes>,
    default: ChatModes.Action,
  },
})

const emit = defineEmits(['update:modelValue'])

const chatMode = computed<ChatModes>({
  get() {
    return props.modelValue
  },
  set(value: ChatModes) {
    emit('update:modelValue', value)
  }
})
</script>
