<template>
  <div class="discussion-comment-container">
    <div class="flex text-gray-500 text-sm relative">
      <div class="flex">
        <div class="shrink-0 mr-4 w-10">
          <i class="fa-solid fa-circle-dashed ai-icon text-3xl" />
        </div>
        <div class="overflow-x-hidden flex-1">
          <div class="flex flex-col space-x-0 sm:space-x-3 sm:flex-row">
            <div class="inline font-bold text-gray-700 text-base -mt-1">
              {{ $t('AI Assistant') }}
            </div>
            <div class="inline font-bold text-xs text-gray-400">
              <span>{{ formattedDate }}</span>
            </div>
          </div>
            <AiChatAction
              v-if="requiresConfirmation"
              :message="message"
            />
            <div
              v-else
              contenteditable="false"
              translate="no"
              tabindex="0"
              class="prose max-w-none leading-6 prose-p:my-4 prose-ul:my-4 prose-ol:my-4 prose-li:my-0 prose-p:mt-2 prose-p:mb-0 prose-ul:mt-2 prose-ul:mb-0 prose-ol:mt-2 prose-ol:mb-0 focus:outline-none text-sm rounded-md text-gray-500"
            >
              <AiChatActionResult
                v-if="actionResult"
                :actionResult="actionResult"
              />

              <p v-else>
                <span v-html="htmlMessage" />
                <span v-if="message.link">
                  <router-link :to="message.link.url">{{ $t(message.link.entity) }}</router-link>
                </span>
              </p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Libs
import {
  computed,
  PropType,
  ref,
} from "vue";

// Helpers
import { formatDate } from "@/modules/common/utils/dateUtils";
import { ChatMessage, ChatMessageTypes, ActionResult } from "@/modules/ai/types/aiTypes";
import showdown from 'showdown'

// Components
import AiChatAction from "@/modules/ai/components/AiChatAction.vue";
import AiChatActionResult from "@/modules/ai/components/AiChatActionResult.vue";

const props = defineProps({
  message: {
    type: Object as PropType<ChatMessage>,
    default: () => ({  }),
    required: true
  }
})

const messageText = computed(() => {
  return props.message.message || ''
})

const htmlConverter = new showdown.Converter({
  tables: true,
})

const htmlMessage = computed(() => {
  return htmlConverter.makeHtml(messageText.value)
})

const formattedDate = computed(() => {
  return formatDate(props.message.date, null, true, true)
})

const requiresConfirmation = computed(() => {
  return props.message.type === ChatMessageTypes.UserConfirmation
})

const actionResult = ref<ActionResult | null>(props.message?.data?.actionResult || null)
</script>
