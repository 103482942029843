<template>
  <div class="flex-1 min-w-4xl border border-gray-200 rounded-lg h-fit body-background overflow-hidden">
    <div class="flex items-center body-background breadcrumb-color text-xl font-extrabold border-b border-gray-200 px-6 py-2 space-x-3">
      <div class="flex-1">
        <i
          v-if="widget.icon"
          class="fa-solid text-xl align-middle mr-2 opacity-60"
          :class="{
            [widget.icon]: widget.icon,
          }"
        />
        <span>{{ widget.name }}</span>
      </div>
      <span
        class="breadcrumb-color opacity-60 hover:text-red-500 hover:opacity-100 text-base cursor-pointer"
        @click="emit('remove')"
      >
        <i class="fa-solid fa-circle-xmark" />
      </span>
      <span
        class="breadcrumb-color opacity-60 hover:opacity-100 text-base cursor-move"
      >
        <i class="fa-solid fa-up-down-left-right" />
      </span>
    </div>

    <div class="px-3 py-2 sm:px-6 sm:py-4 bg-gray-50 space-y-2 sm:space-y-3">
      <div class="text-sm text-gray-400">
        {{ widgetInfoTexts[widget.component] }}
      </div>

      <SortByOptionsSelect
        v-if="sortByOptions.length"
        v-model="sortBy"
        :columns="sortByOptions"
      />
      <FilterByOptionsSelect
        v-if="filterByOptions.length"
        v-model="filterBy"
        v-model:filterValue="filterValue"
        :columns="filterByOptions"
      />
      <GroupByOptionsSelect
        v-if="groupByColumns.length"
        v-model="groupBy"
        :columns="groupByColumns"
      />
      <CustomFilterOptions
        v-model="customFiltersModel"
        :widget="widget"
        :scope="scope"
      />
      <ElInput
        v-model="displayMaxCount"
        type="number"
        class="input-with-prepend"
      >
        <template #prepend>
          <div class="text-gray-900">
            {{ $t('Max items displayed: ') }}
          </div>
        </template>
      </ElInput>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Components
import GroupByOptionsSelect from "@/modules/dashboard/components/editable/GroupByOptionsSelect.vue"
import SortByOptionsSelect from "@/modules/dashboard/components/editable/SortByOptionsSelect.vue"
import FilterByOptionsSelect from "@/modules/dashboard/components/editable/FilterByOptionsSelect.vue"
import CustomFilterOptions from "@/modules/dashboard/components/editable/CustomFilterOptions.vue"
import { ElInput } from "element-plus";

// Utils
import { PropType, computed, ref, watch, onMounted } from 'vue';
import {
  DashboardWidgetType, Widgets,
  widgetInfoTexts
 } from "@/modules/dashboard/utils/widgetUtils"
import { EntityTargetTypes, decodeFilters, encodeFilters } from '@/modules/common/utils/filterUtils'
import { TableColumn } from '@/components/table/tableUtils';
import { ApiFilter, SortFilter } from "@/modules/common/commonTypes";
import { UserTypes } from "@/modules/users/util/userUtils";

// Composables
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidgetType>,
    required: true,
  },
  scope: {
    type: String as PropType<UserTypes>,
    required: true,
  },
})

const emit = defineEmits(['filters-changed', 'remove', 'update:displayMaxCount'])

const displayMaxCount = computed({
  get() {
    return props.widget.displayMaxCount || 5
  },
  set(value) {
    if (value < 1) {
      value = 1
    }
    emit('update:displayMaxCount', value)
  }
})

const entityTargets: Partial<Record<Widgets, string>> = {
  [Widgets.Projects]: EntityTargetTypes.PROJECTS,
  [Widgets.Tasks]: EntityTargetTypes.TASKS,
  [Widgets.Time]: EntityTargetTypes.TIME_ENTRIES,
  [Widgets.Payments]: EntityTargetTypes.PAYMENTS,
  [Widgets.Files]: EntityTargetTypes.FILES,
}

const columnGetters = {
  [EntityTargetTypes.TIME_ENTRIES]: `time/timeEntryTableColumns`
}

const entityTarget = computed(() => {
  return entityTargets[props.widget.component] || ''
})

const columnsGetter = computed(() => {
  return columnGetters[entityTarget.value] || `${entityTarget.value}/tableColumns`
})

const entityColumns = computed<TableColumn[]>(() => {
  return store.getters[columnsGetter.value] || []
})

const groupByColumns = computed(() => {
  return entityColumns.value.filter((column) => (column.group || column.enableRowGroup))
})

const groupBy = ref<string | null>()

const sortByOptions = computed(() => {
  return entityColumns.value.filter((column) => column.sortable && (column.enableWidgetSort !== false))
})

const sortBy = ref<string | null>()

const sortByModel = computed(() => {
  const [
    column,
    order
  ] = sortBy.value?.split('|') || []

  return {
    column,
    order,
  }
})

const filterByOptions = computed(() => {
  return entityColumns.value.filter((column) => column.enableWidgetFilter && column.filterBy)
})

const filterBy = ref<string | undefined>()
const filterValue = ref<any | undefined>()

function getFilterByOptions(column?: TableColumn) {
  if (!column) {
    return null
  }

  if (typeof column.filterBy === 'function') {
    return column.filterBy()
  }

  return column.filterBy
}

const filterBySelectedColumn = computed(() => {
  return entityColumns.value.find((column) => {
    return getFilterByOptions(column)?.prop === filterBy.value
  })
})

const filterByModel = computed(() => {
  if (!filterBy.value || !filterValue.value) {
    return null
  }
  
  const name = typeof filterBySelectedColumn.value?.name === 'function'
    ? filterBySelectedColumn.value?.name()
    : filterBySelectedColumn.value?.name
  const query = Array.isArray(filterValue.value)
    ? filterValue.value.map((x: any) => x.id)
    : filterValue.value?.id

  const displayValue = Array.isArray(filterValue.value)
    ? filterValue.value.map((v: any) => {
      return {
        ...v,
        hasColor: v.value?.startsWith('#')
      }
    })
    : {
      ...filterValue.value,
      hasColor: filterValue.value?.value?.startsWith('#') || filterValue.value?.value?.startsWith('rgb')
    }

  return {
    column: filterBy.value,
    name,
    query,
    rawValue: filterValue.value,
    displayValue
  }
})


const persistentFilters: Partial<Record<Widgets, any>> = {
  [Widgets.Projects]: {
    filters: [
      {
        key: 'closed',
        value: {
          show: false
        }
      },
    ]
  },
  [Widgets.Tasks]: {
    filters: [
      {
        key: 'completed',
        value: {
          show: false
        }
      },
    ]
  },
  [Widgets.Time]: {
    filters: [
      {
        key: 'my-time',
        value: {
          show: true
        }
      },
    ]
  },
  [Widgets.Payments]: {
    filters: [
      {
        key: 'my-payments',
        value: {
          show: true
        }
      },
    ]
  },
}

const customFiltersModel = ref<any>({})

const customFilters = computed(() => {
  const filters = []
  for (const k in customFiltersModel.value) {
    filters.push({
      key: k,
      value: {
        show: customFiltersModel.value[k]
      }
    })
  }

  return filters
})

const filtersObject = computed(() => {
  const filtersArray = [
    ...persistentFilters[props.widget.component]?.filters || [],
    ...customFilters.value || []
  ]

  if (sortByModel.value?.column) {
    filtersArray.push({
      key: 'sorts',
      value: sortByModel.value
    })
  }

  if (filterByModel.value) {
    filtersArray.push({
      key: 'filters',
      value: [ filterByModel.value ]
    })
  }

  return {
    filters: filtersArray,
    localFilters: {
      groupBy: [ groupBy.value ]
    }
  }
})

const encodedFilters = computed(() => {
  return {
    filters: encodeFilters(filtersObject.value.filters),
    localFilters: encodeFilters(filtersObject.value.localFilters),
  }
})

const stringifiedFilters = computed(() => {
  return JSON.stringify(encodedFilters.value)
})

watch(() => stringifiedFilters.value, (value) => {
  emit('filters-changed', value)
})

watch(() => props.widget.filters, (value) => {
  initSortsAndFilters()
})

function resetModel() {
  sortBy.value = null
  filterBy.value = ''
  filterValue.value = null
  groupBy.value = null
}

function initSortsAndFilters() {
  if (!props.widget.filters) {
    resetModel()
    return
  }

  const {
    filters,
    localFilters
  } = JSON.parse(props.widget.filters)
  
  const decodedFilters = decodeFilters(filters, [])
  const decodedLocalFilters = decodeFilters(localFilters, {})
  
  const sortByDecoded = decodedFilters.find((x: ApiFilter) => x.key === 'sorts')?.value as SortFilter
  const filterByDecoded = decodedFilters.find((x: ApiFilter) => x.key === 'filters')?.value?.[0]
  const groupByDecoded = decodedLocalFilters?.groupBy?.[0]
  
  if (sortByDecoded) {
    sortBy.value = `${sortByDecoded.column}|${sortByDecoded.order}`
  }
  else {
    sortBy.value = null
  }

  if (filterByDecoded) {
    filterBy.value = filterByDecoded.column
    filterValue.value = filterByDecoded.rawValue
  }
  else {
    filterBy.value = ''
    filterValue.value = null
  }

  if (groupByDecoded) {
    groupBy.value = groupByDecoded
  }
  else {
    groupBy.value = null
  }
}

onMounted(() => {
  initSortsAndFilters()
  emit('filters-changed', stringifiedFilters.value)
})
</script>
<style lang="scss">
.el-input.input-with-prepend {
  .el-input-group__prepend {
    @apply border-none bg-white rounded-md pl-4 rounded-r-none pr-1;

    +.el-input__wrapper {
      @apply border-l-0 rounded-l-none border-l-transparent pl-0 pr-0;
    }
  }
}
</style>
