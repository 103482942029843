<template>
  <div class="table-inline-edit-input">
    <EntitySelect
      v-model="model.relationshipId"
      v-focus
      v-bind="selectAttributes"
      class="entity-inline-select"
      @raw-change="onRawChange"
    >
      <template #option="{ row }">
        <div class="flex items-center py-1">
          <BaseLogo :entity="row.originalValue" size="sm" />
          <span class="ml-2 truncate">{{ row.label || row.value }}</span>
        </div>
      </template>
    </EntitySelect>
  </div>
</template>
<script lang="ts">
// Components
import EntitySelect from "@/components/form/EntitySelect.vue";

// Utils
import { PropType, defineComponent } from "vue";
import { ICellEditorParams } from "@ag-grid-community/core";
import { stopCellEditing } from "@/components/table/tableUtils";
import { EntitySelectAttributes } from "@/modules/common/commonTypes";

export default defineComponent({
  components: {
    EntitySelect
  },
  props: {
    params: {
      type: Object as PropType<ICellEditorParams<any>>,
      default: () => ({})
    },
  },
  data() {
    return {
      model: {
        relationshipId: null,
        relationshipObject: null
      }
    }
  },
  computed: {
    dataRow() {
      return this.params?.node?.data
    },
    selectAttributes(): EntitySelectAttributes {
      return this.params?.colDef?.cellEditorParams?.getSelectAttributes(this.params.node?.data) || {}
    },
    colDef() {
      return this.params.colDef
    },
    cellEditorParams() {
      return this.colDef?.cellEditorParams || {}
    },
  },
  methods: {
    initModel() {
      this.model.relationshipId = this.params.value
      this.model.relationshipObject = this.selectAttributes.initialValue
    },
    getValue() {
      return this.model
    },
    isCancelBeforeStart() {
      return this.cellEditorParams?.isCancelBeforeStart?.(this.dataRow) || false
    },
    onRawChange(value: any) {
      this.model.relationshipObject = value
      stopCellEditing(this.params)
    }
  },
  created() {
    this.initModel()
  }
})
</script>
