<template>
  <div
    v-loading="loading"
  >
    <div
      class="md:p-6 lg:flex gap-10 max-w-6xl m-auto"
      :class="{
        'p-6': inModal,
        'p-4': !inModal
      }"
    >
      <div class="w-full mx-auto mb-4 lg:mb-0">
        <div
          :class="{
            'bg-white border border-gray-200 overflow-hidden rounded-lg shadow': !inModal
          }"
        >
          <div
            class="mb-6"
            :class="{
              'px-6 py-4 border-b border-gray-200': !inModal
            }"
          >
            <div>
              <h2 class="font-bold text-gray-700 text-base truncate">{{ $t('Add Groups') }}</h2>
              <p class="text-sm text-gray-500 mb-2">
                {{ $t('Add groups to your project so you can filter your projects by group. You can also add the selected groups to tasks & payments that are part of this project.') }}</p>

              <label class="block text-sm font-medium text-gray-700 sr-only">
                {{ $t('Choose Group') }}
              </label>
              <div class="flex space-x-2 w-full relative">
                <div class="w-full">
                  <GroupSelect
                    v-model="model.group_ids"
                    :placeholder="$t('Choose Group')"
                    :row-filter="filterGroups"
                    multiple
                    rules="required"
                    :allow-entity-create="can($actions.CREATE_GROUPS)"
                  />
                </div>
                <BaseButton
                  :disabled="!model.group_ids?.length"
                  :loading="addingGroups"
                  @click="addGroups"
                >
                  <div class="flex items-center space-x-2">
                    <i class="fa fa-plus" />
                    <span class="hidden sm:inline">{{ $t('Add') }}</span>
                  </div>
                </BaseButton>
              </div>
            </div>
          </div>

          <div class="flow-root mt-6">
            <ul
              class="-mt-6"
              :class="{
                'divide-y divide-gray-200': !inModal
              }"
            >
              <ProjectGroup
                v-for="group in projectGroups"
                :key="group.id"
                :group="group"
                :project="project"
                :class="{
                  'px-6': !inModal
                }"
                class="py-2"
              />
            </ul>
          </div>
        </div>

      </div>
      <div class="w-full mx-auto">
        <div
          :class="{
            'bg-white border border-gray-200 overflow-hidden rounded-lg shadow': !inModal
          }"
        >
          <div
            :class="{
              'px-6 py-4 border-b border-gray-200': !inModal
            }"
          >
            <div>
              <h2 class="font-bold text-gray-700 text-base truncate">{{ $t('Add People') }}</h2>
              <p class="text-sm text-gray-500 mb-2">
                {{ $t(`Add people to your project. When you add people they will get a project invite. They will also be notified about any project updates based on their notification settings.`) }}
              </p>
              <label class="block text-sm font-medium text-gray-700 sr-only">
                {{ $t('Add people') }}
              </label>
              <div class="flex space-x-2 w-full relative">
                <div class="w-full">
                  <UserSelect
                    v-model="model.user_ids"
                    :placeholder="$t('Choose People')"
                    :row-filter="filterUsers"
                    multiple
                    :url-params="filterPeopleFromSelectedGroups ? {
                      filters: userSelectFilter,
                      related: 'groups'
                    } : {
                      related: 'groups'
                    }"
                    rules="required"
                    class="basis-full"
                    allow-entity-create
                    :addEntityLabel="$t('Invite New People')"
                    :entityCreatedCallback="onNewUsersInvited"
                    :focusOnEntityDialogClosed="false"
                  >
                  <template #custom-options>
                    <div class="flex items-center space-x-2 pl-5 pr-8 pb-2">
                      <BaseSwitch
                        v-model="filterPeopleFromSelectedGroups"
                      />
                      <span class="text-gray-500 text-sm">
                        {{ $t('Only show people from added groups') }}
                      </span>
                    </div>
                  </template>
                  </UserSelect>
                </div>

                <BaseButton
                  :disabled="!model.user_ids?.length"
                  :loading="sendingInvites"
                  @click="inviteUsers"
                >
                  <div class="flex items-center space-x-2">
                    <i class="fal fa-paper-plane-top" />
                    <span class="hidden sm:inline">{{ $t('Invite') }}</span>
                  </div>
                </BaseButton>
              </div>
            </div>
          </div>

          <div class="flow-root mt-6">
            <ul
              class="-mt-6"
              :class="{
                'divide-y divide-gray-200': !inModal
              }"
            >
              <ProjectUser
                v-for="user in projectUsers"
                :key="user.id"
                :user="user"
                :project="project"
                :class="{
                  'px-6': !inModal
                }"
                class="py-2"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
    
    <div class="px-6 py-4 border-t border-gray-200 sticky bottom-0 bg-white w-full rounded-b-lg h-fit mt-auto">
      <div class="flex justify-end">
        <BaseButton
          @click="$emit('close')"
        >
          {{ $t("I'm done adding groups & people - Close") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
// Components
import UserSelect from "@/components/selects/UserSelect.vue";
import GroupSelect from "@/components/selects/GroupSelect.vue";
import ProjectUser from "@/modules/projects/components/ProjectUser.vue"
import ProjectGroup from "@/modules/projects/components/ProjectGroup.vue"

// Helpers
import { userGroupsFilter } from "@/modules/users/util/filters.js";
import apiCache from '@/modules/common/utils/apiCache';

export default {
  name: 'ProjectPeopleForm',
  components: {
    GroupSelect,
    UserSelect,
    ProjectGroup,
    ProjectUser
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {
        group_id: null,
        group_ids: [],
        user_ids: [],
      },
      loading: false,
      sendingInvites: false,
      addingGroups: false,
      focusUsersSelect: true,
      filterPeopleFromSelectedGroups: false
    }
  },
  computed: {
    project() {
      return this.$store.state.projects.currentProject || {}
    },
    projectUsers() {
      return this.project?.relationships?.users || []
    },
    projectUserIds() {
      return this.projectUsers?.map(u => +u.id) || []
    },
    projectGroups() {
      return this.project?.relationships?.groups || []
    },
    projectGroupIds() {
      return this.projectGroups?.map(u => +u.id) || []
    },
    userSelectFilter() {
      return userGroupsFilter(this.projectGroups)
    },
    currentUserGroups() {
      return this.$user?.groups || []
    },
  },
  watch: {
    projectId: {
      immediate: true,
      async handler(value) {
        if (!value || value === this.project?.id) {
          // already loaded
          return
        }

        this.loading = true

        await this.$store.dispatch('projects/getProjectById', { id: value })

        this.loading = false
      }
    }
  },
  methods: {
    resetModel() {
      this.model = {
        user_ids: [],
        group_id: null,
      }
    },
    async getProjectSilently() {
      await this.$store.dispatch('projects/getProjectById', {
        id: this.project.id,
        silent: true
      })
    },
    filterUsers(user) {
      const id = user?.id
      return !this.projectUserIds.includes(+id)
    },
    filterGroups(group) {
      const id = group?.id
      return !this.projectGroupIds?.includes(+id)
    },
    async addGroups() {
      let newGroupIds = this.model.group_ids.filter(id => !this.projectGroupIds.includes(id))
      
      if (!newGroupIds.length) {
        this.$error(this.$t('Selected groups are already part of the project'))
        return
      }

      newGroupIds = this.projectGroupIds.concat(newGroupIds)

      this.addingGroups = true

      try {
        await this.$store.dispatch('projects/editProject', {
          project: this.project,
          data: {
            ...this.project?.attributes,
            custom_fields: JSON.stringify(this.project?.attributes?.custom_fields),
            group_ids: newGroupIds,
          }
        })

        this.resetModel()
        apiCache.removeForEntity('groups')
      }
      catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not add groups to the project'))
      }
      finally {
        this.addingGroups = false
        this.resetModel()
      }
    },
    async inviteUsers() {
      const newUserIds = this.model.user_ids.filter(id => !this.projectUserIds.includes(id))
      
      if (!newUserIds.length) {
        this.$error(this.$t('Selected users are already part of the project'))
        return
      }

      this.sendingInvites = true

      try {
        await this.$store.dispatch('projects/addProjectUsers', {
          users: newUserIds,
          projectId: this.projectId,
        })

        await this.getProjectSilently()
        apiCache.removeForEntity('users')
      }
      catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not add users to the project'))
      }
      finally {
        this.sendingInvites = false
        this.resetModel()
      }
    },
    async onNewUsersInvited() {
      const oldUsersCount = this.projectUserIds.length

      await this.getProjectSilently()
      await this.$nextTick()

      const newUsersCount = this.projectUserIds.length

      return {
        shouldFocusInput: newUsersCount === oldUsersCount
      }
    },
  },
}
</script>
<style>
.project-people .el-select .select-trigger .el-input input.el-input__inner {
  height: 38px !important;
}
</style>
