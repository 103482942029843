import { Node, mergeAttributes } from '@tiptap/core'

export default Node.create({
  name: 'alert',

  content: 'block+',

  group: 'block',

  defining: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'px-4 py-2 my-2 bg-yellow-100 border border-yellow-300 rounded-md not-prose',
      },
    }
  },

  addAttributes() {
    return {
      class: {
        default: this.options.HTMLAttributes.class,
      },
      style: {
        default: this.options.HTMLAttributes.style,
      },
    }
  },

  parseHTML() {
    return [
      { tag: `div[data-type=${this.name}]` },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { 'data-type': this.name }), 0]
  },

  addCommands() {
    return {
      setAlert: () => ({ commands }) => {
        return commands.wrapIn(this.name)
      },
      toggleAlert: () => ({ commands }) => {
        return commands.toggleWrap(this.name)
      },
      unsetAlert: () => ({ commands }) => {
        return commands.lift(this.name)
      },
    }
  },
});
