<template>
  <BaseDataTable
    :data="data"
    :meta="meta"
    :columns="tableColumns"
    :loading="loading"
  >
    <template #no-data>
      <NoGroups />
    </template>
  </BaseDataTable>
</template>
<script lang="ts" setup>
// Utils
import { computed } from 'vue'

// Components
import NoGroups from "@/modules/groups/components/NoGroups.vue";

// Composables
import { useStore } from "vuex"
const store = useStore()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const tableColumns = computed(() => {
  return store.getters['groups/tableColumns'] || []
})

const meta = computed(() => {
  return store.state.groups.groups.meta || {}
})
</script>
