import axios from "axios";
import { groupBy, get } from 'lodash-es'

import { timeEntryFormModelToBulk, preprocessAllocatedTimeModel } from "@/modules/time/utils/formModelUtils.js"

import { getTimeEntryColumns, timeEntryFields } from "@/modules/time/utils/timeEntryTableUtils"
import { getAllocatedTimeColumns, allocatedTimeFields } from "@/modules/time/utils/allocatedTimeTableUtils"
import { columnBuilder } from '@/components/table/tableUtils'
import { transformToRestifyArray, entityToRestifyMapper } from "@/modules/common/utils/dataUtils";
import {
  syncEntityInArray,
  EntityChangeEvent,
  getEntityArrayForWorker,
  groupEntitiesToMap,
} from "@/modules/common/utils/entityUtils";

import { timeEntriesWithRelationshipsWorker, getTimeEntryWithRelationships } from "@/modules/time/utils/timeEntryRelationshipUtils"
import { allocatedTimeWithRelationshipsWorker, getAllocatedTimeWithRelationships } from "@/modules/time/utils/allocatedTimeRelationshipUtils"
import { Activities, trackActivity } from "@/modules/common/utils/trackingUtils";

const CancelToken = axios.CancelToken;
const state = () => ({
  // Time Entries
  timeEntries: {
    data: [],
  },
  allTimeEntries: [],
  allTimeEntriesLoaded: false,
  timeEntriesLoading: true,
  currentTimeEntryLoading: false,
  currentTimeEntry: null,
  addTimeEntryTrigger: 1,
  addTimeEntryRecordingTrigger: 1,
  // Allocate Time
  allocatedTime: {
    data: [],
  },
  allAllocatedTime: [],
  allAllocatedTimeLoaded: false,
  allocatedTimeLoading: true,
  currentAllocatedTimeLoading: false,
  currentAllocatedTime: null,
  addAllocatedTimeTrigger: 1,
  lastRecordedTimeEntries: {
    data: [],
  },
  stats: {
    time_entry_recorded_count: 0,
    deleted_time_entry_count: 0,
  },
})

const mutations = {
  // Time Entries
  setTimeEntries(state, value) {
    state.timeEntries = value
  },
  setLastRecordedTimeEntries(state, value) {
    state.lastRecordedTimeEntries.data = value
  },
  setLastRecordedTimeEntriesCount(state, value) {
    state.stats.time_entry_recorded_count = value
  },
  setAllTimeEntries(state, value) {
    state.allTimeEntries = value
    state.allTimeEntriesLoaded = true
  },
  setAllTimeEntriesLoaded(state, value) {
    state.allTimeEntriesLoaded = value
  },
  addTimeEntry(state, timeEntry) {
    syncEntityInArray(timeEntry, state.allTimeEntries, EntityChangeEvent.Create)
  },
  updateTimeEntry(state, timeEntry) {
    if (String(timeEntry.id) === String(state.currentTimeEntry?.id)) {
      state.currentTimeEntry = {
        ...state.currentTimeEntry,
        ...timeEntry,
        attributes: {
          ...state.currentTimeEntry.attributes,
          ...timeEntry.attributes
        },
        relationships: {
          ...state.currentTimeEntry.relationships,
          ...timeEntry.relationships
        }
      }
    }

    syncEntityInArray(timeEntry, state.allTimeEntries, EntityChangeEvent.Update)
  },
  deleteTimeEntry(state, id) {
    syncEntityInArray({ id, type: 'time_entries' }, state.allTimeEntries, EntityChangeEvent.Delete)
    // TODO: Check another way to do this
    state.stats.deleted_time_entry_count++
  },
  setTimeEntriesLoading(state, value) {
    state.timeEntriesLoading = value
  },
  setCurrentTimeEntryLoading(state, value) {
    state.currentTimeEntryLoading = value
  },
  setCurrentTimeEntry(state, value) {
    state.currentTimeEntry = value
  },
  triggerAddTimeRecordingEntry(state) {
    state.addTimeEntryRecordingTrigger++
  },
  triggerAddTimeEntry(state) {
    state.addTimeEntryTrigger++
  },
  updateTimeEntryRecordedNumber(state, value) {
    state.stats.time_entry_recorded_count += value
  },
  // Allocate Time
  setAllocatedTime(state, value) {
    state.allocatedTime = value
  },
  setAllAllocatedTime(state, value) {
    state.allAllocatedTime = value
    state.allAllocatedTimeLoaded = true
  },
  setAllAllocatedTimeLoaded(state, value) {
    state.allAllocatedTimeLoaded = value
  },
  addAllocatedTime(state, allocatedTime) {
    syncEntityInArray(allocatedTime, state.allAllocatedTime, EntityChangeEvent.Create)
  },
  updateAllocatedTime(state, allocatedTime) {
    syncEntityInArray(allocatedTime, state.allAllocatedTime, EntityChangeEvent.Update)

    if (String(allocatedTime.id) !== String(state.currentAllocatedTime?.id)) {
      return
    }

    state.currentAllocatedTime = {
      ...state.currentAllocatedTime,
      ...allocatedTime,
      attributes: {
        ...state.currentAllocatedTime.attributes,
        ...allocatedTime.attributes
      },
      relationships: {
        ...state.currentAllocatedTime.relationships,
        ...allocatedTime.relationships
      }
    }
  },
  deleteAllocatedTime(state, id) {
    syncEntityInArray({ id, type: 'time_allocations' }, state.allAllocatedTime, EntityChangeEvent.Delete)
  },
  setAllocatedTimeLoading(state, value) {
    state.allocatedTimeLoading = value
  },
  setCurrentAllocatedTimeLoading(state, value) {
    state.currentAllocatedTimeLoading = value
  },
  setCurrentAllocatedTime(state, value) {
    state.currentAllocatedTime = value
  },
  triggerAddAllocatedTime(state) {
    state.addAllocatedTimeTrigger++
  },
}


let cancelGetTimeEntries = null
let cancelGetAllocatedTime = null

const actions = {
  // Time Entries
  async createTimeEntry({ commit, state, getters, dispatch }, { data, isBulkModel = false}) {
    let bulk;
    if (isBulkModel) {
      bulk = data
    } else {
      bulk = timeEntryFormModelToBulk(data)
    }

    const response = await axios.post(`/restify/time-entries/bulk`, bulk)

    response.data?.forEach(timeEntry => {
      let newTimeEntry = entityToRestifyMapper(timeEntry, { type: 'time_entries' })
      newTimeEntry = getTimeEntryWithRelationships(newTimeEntry)
      commit('addTimeEntry', newTimeEntry)

      dispatch('updateTaskAllocatedTime', timeEntry)

      trackActivity(Activities.TimeEntryCreated, newTimeEntry)
    })

    return response?.data
  },
  async updateTaskAllocatedTime({ state, commit }, timeEntry) {
    const { task_id = '', worked_minutes = '' } = timeEntry
    if (!task_id) {
      return
    }
    const timeIndex = state.allAllocatedTime.findIndex(allocatedTime => String(allocatedTime.attributes.task_id) === String(task_id))
    if (timeIndex === -1) {
      return
    }
    state.allAllocatedTime[timeIndex].attributes.actual_time += worked_minutes
    commit('updateAllocatedTime', state.allAllocatedTime[timeIndex])
  },
  async editTimeEntry({ state, dispatch, commit }, { timeEntryId, data, timeEntry = null }) {
    timeEntry = timeEntry || state.currentTimeEntry
    const bulk = timeEntryFormModelToBulk(data)

    // Create newly added entries
    if (bulk.length > 1) {
      dispatch('createTimeEntry', {
        data: bulk.slice(1),
        isBulkModel: true
      });
    }

    // Update current entry
    await axios.put(`/restify/time-entries/${timeEntryId}`, bulk[0]);

    const isSameTask = timeEntry?.attributes?.task_id === bulk[0]?.task_id
    const newTimeValue = bulk[0]?.worked_minutes
    const oldTimeValue = timeEntry?.attributes?.worked_minutes

    if (isSameTask) {
      dispatch('updateTaskAllocatedTime', {
        ...bulk[0],
        worked_minutes: newTimeValue - oldTimeValue
      })
    } else {
      dispatch('updateTaskAllocatedTime', {
        ...bulk[0],
        worked_minutes: newTimeValue
      })
      dispatch('updateTaskAllocatedTime', {
        ...timeEntry.attributes,
        worked_minutes: -oldTimeValue
      })
    }

    await dispatch('getTimeEntryById', { id: timeEntryId, forceFetch: true })

    commit('updateTimeEntry', state.currentTimeEntry)
  },
  async deleteTimeEntry({ commit, dispatch, state }, id) {
    try {
      await axios.delete(`/restify/time-entries/${id}`);
      const timeEntry = state.allTimeEntries.find(timeEntry => String(timeEntry.id) === String(id))
      if (timeEntry?.attributes?.task_id) {
        timeEntry.attributes.worked_minutes = -timeEntry.attributes.worked_minutes
        dispatch('updateTaskAllocatedTime', timeEntry.attributes)
      }
      commit('deleteTimeEntry', id)
    } catch(e) {
      console.warn(e)
    }
  },
  async getTimeEntries({ commit, rootGetters, rootState }, filters) {
    let timeEntries = null
    try {
      if (cancelGetTimeEntries) {
        cancelGetTimeEntries.cancel('Time entries fetching canceled by the user.');
      }
      if (filters?.shouldReset === true) {
        commit('setTimeEntries', { data: [] })
      }
      filters = filters || { ...rootState.route?.query }
      commit('setTimeEntriesLoading', true)

      cancelGetTimeEntries = CancelToken.source();
      timeEntries = await axios.get('/restify/time-entries', {
        cancelToken: cancelGetTimeEntries?.token,
        params: {
          ...filters,
          related: 'group,user,project,task',
          project_id: rootGetters.project_id
        }
      })

      if (timeEntries?.cancelled) {
        return
      }
      commit('setTimeEntries', timeEntries)
    } catch (err) {
      if (err?.status === 403) {
        commit('setTimeEntriesLoading', false)
      }
    } finally {
      if (!timeEntries?.cancelled) {
        commit('setTimeEntriesLoading', false)
      }
    }
  },
  async getAllTimeEntries({ state, commit, dispatch }) {
    if (state.allTimeEntriesLoaded) {
      return
    }

    try {
      commit('setTimeEntriesLoading', true)

      const entities = ['time_entries']
      const { data: { time_entries } } = await axios.get('/data', {
        params: {
          entities: `[${entities.join('|')}]`,
          timeEntryFields: `[${timeEntryFields.join('|')}]`,
        }
      })

      const allTimeEntries = transformToRestifyArray(time_entries, { type: 'time_entries' })

      commit('setAllTimeEntries', allTimeEntries)
      dispatch('setTimeEntriesRelationships')
    } catch (err) {
      console.error('Error fetching time entries', err)
      commit('setTimeEntriesLoading', false)
    }
  },
  async setTimeEntriesRelationships({ state, commit, rootState }) {
    const allTimeEntries = getEntityArrayForWorker(state.allTimeEntries || [])

    if (!allTimeEntries.length) {
      if (state.allTimeEntriesLoaded) {
        commit('setTimeEntriesLoading', false)
      }
      return
    }

    const allGroups = getEntityArrayForWorker(rootState.groups.allGroups || [])
    const allProjects = getEntityArrayForWorker(rootState.projects.allProjects || [])
    const allUsers = getEntityArrayForWorker(rootState.users.allUsers || [])
    const allTasks = getEntityArrayForWorker(rootState.tasks.allTasks || [])

    const relationshipsLoaded = rootState.groups.allGroupsLoaded && rootState.projects.allProjectsLoaded && rootState.users.allUsersLoaded && rootState.tasks.allTasksLoaded

    if (!relationshipsLoaded) {
      return
    }

    const timeEntriesWithRelationships = await timeEntriesWithRelationshipsWorker(
      allTimeEntries,
      allGroups,
      allProjects,
      allUsers,
      allTasks
    )

    commit('setAllTimeEntries', timeEntriesWithRelationships)
    commit('setTimeEntriesLoading', false)
  },
  async getTimeEntryById({ commit }, { id, returnEntity = false }) {
    try {
      commit('setCurrentTimeEntryLoading', true)
      const { data } = await axios.get(`/restify/time-entries/${id}`, {
        params: {
          related: 'group,user,project,task'
        }
      })

      if (returnEntity) {
        return data
      }

      commit('setCurrentTimeEntry', data)
    } finally {
      commit('setCurrentTimeEntryLoading', false)
    }
  },
  // Allocate Time
  async createAllocatedTime({ commit }, { data }) {
    data = preprocessAllocatedTimeModel(data)

    const response = await axios.post(`/restify/time-allocations`, data)

    if (response?.data?.id) {
      let newAllocatedTime = getAllocatedTimeWithRelationships(response.data)
      commit('addAllocatedTime', newAllocatedTime)
    }

    return response?.data
  },
  async editAllocatedTime({ state, commit, dispatch }, { allocatedTimeId, data }) {
    data = preprocessAllocatedTimeModel(data)

    const response = await axios.put(`/restify/time-allocations/${allocatedTimeId}`, data);
    await dispatch('getAllocatedTimeById', { id: allocatedTimeId  });

    commit('updateAllocatedTime', state.currentAllocatedTime)
    return response?.data
  },
  async deleteAllocatedTime({ commit }, id) {
    await axios.delete(`/restify/time-allocations/${id}`);
    commit('deleteAllocatedTime', id)
  },
  async getAllocatedTime({ commit, rootGetters, rootState }, filters) {
    let allocatedTime = null
    try {
      if (cancelGetAllocatedTime) {
        cancelGetAllocatedTime.cancel('Time entries fetching canceled by the user.');
      }
      if (filters?.shouldReset === true) {
        commit('setAllocatedTime', { data: [] })
      }
      filters = filters || { ...rootState.route?.query }
      commit('setAllocatedTimeLoading', true)

      cancelGetAllocatedTime = CancelToken.source();
      allocatedTime = await axios.get('/restify/time-allocations', {
        cancelToken: cancelGetAllocatedTime?.token,
        params: {
          ...filters,
          related: 'group,user,project,task',
          project_id: rootGetters.project_id
        }
      })
      if (allocatedTime?.cancelled) {
        return
      }
      commit('setAllocatedTime', allocatedTime)
    } finally {
      if (!allocatedTime?.cancelled) {
        commit('setAllocatedTimeLoading', false)
      }
    }
  },
  async getAllAllocatedTime({ state, commit, dispatch }) {
    if (state.allAllocatedTimeLoaded) {
      return
    }

    try {
      commit('setAllocatedTimeLoading', true)
      const entities = ['time_allocation']
      const { data: { time_allocation } } = await axios.get('/data', {
        params: {
          entities: `[${entities.join('|')}]`,
          allocatedTimeFields: `[${allocatedTimeFields.join('|')}]`,
        }
      })

      const allAllocatedTime = transformToRestifyArray(time_allocation, { type: 'time_allocations' })

      commit('setAllAllocatedTime', allAllocatedTime)
      dispatch('setAllocatedTimesRelationships')
    }
    catch (err) {
      console.error('Error fetching allocated time', err)
      commit('setAllocatedTimeLoading', false)
    }
  },
  async setAllocatedTimesRelationships({ state, commit, rootState }) {
    const allAllocatedTime = getEntityArrayForWorker(state.allAllocatedTime || [])

    if (!allAllocatedTime.length) {
      if (state.allAllocatedTimeLoaded) {
        commit('setAllocatedTimeLoading', false)
      }
      return
    }

    const allGroups = getEntityArrayForWorker(rootState.groups.allGroups || [])
    const allProjects = getEntityArrayForWorker(rootState.projects.allProjects || [])
    const allUsers = getEntityArrayForWorker(rootState.users.allUsers || [])
    const allTasks = getEntityArrayForWorker(rootState.tasks.allTasks || [])

    const relationshipsLoaded = rootState.groups.allGroupsLoaded && rootState.projects.allProjectsLoaded && rootState.users.allUsersLoaded && rootState.tasks.allTasksLoaded

    if (!relationshipsLoaded) {
      return
    }

    const allocatedTimeWithRelationships = await allocatedTimeWithRelationshipsWorker(
      allAllocatedTime,
      allGroups,
      allProjects,
      allUsers,
      allTasks
    )

    commit('setAllAllocatedTime', allocatedTimeWithRelationships)
    commit('setAllocatedTimeLoading', false)
  },
  async getAllocatedTimeById({ commit }, { id, returnEntity = false }) {
    try {
      commit('setCurrentAllocatedTimeLoading', true)
      const { data } = await axios.get(`/restify/time-allocations/${id}`, {
        params: {
          related: 'group,user,project,task'
        }
      })
      
      if (returnEntity) {
        return data
      }

      commit('setCurrentAllocatedTime', data)
    } finally {
      commit('setCurrentAllocatedTimeLoading', false)
    }
  },
  async getEntityAllocatedTime({}, filters = {
    project_id: undefined,
    group_id: undefined,
    user_id: undefined,
    task_id: undefined
  }) {
    try {
      const allocations = await axios.get(`restify/time-allocations`, {
        params: {
          ...filters
        }
      })
      return allocations.data
    } catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async addTimeEntryTimer({ commit, dispatch }, { model }) {
    const { data } = await axios.post('/restify/time-entries', model)

    data.attributes.timer_started_at = new Date().toISOString()
    await dispatch('startTimer', data.id)

    const newTimeEntry = getTimeEntryWithRelationships(data)
    commit('addTimeEntry', newTimeEntry)

    return newEntry
  },
  async stopTimer({ dispatch, commit }, entryId) {
    const { data } = await axios.post(`/restify/time-entries/${entryId}/actions?action=stop-timer`)

    let updatedTimeEntry = entityToRestifyMapper(data, { type: 'time_entries' })
    updatedTimeEntry = getTimeEntryWithRelationships(updatedTimeEntry)

    commit('updateTimeEntry', updatedTimeEntry)

    dispatch('getRecordedTimeEntriesSummary')
    return data
  },
  async startTimer({ dispatch, commit }, entryId) {
    const { data } = await axios.post(`/restify/time-entries/${entryId}/actions?action=start-timer`)

    let updatedTimeEntry = entityToRestifyMapper(data, { type: 'time_entries' })
    updatedTimeEntry = getTimeEntryWithRelationships(updatedTimeEntry)

    commit('updateTimeEntry', updatedTimeEntry)

    dispatch('getRecordedTimeEntriesSummary')
    return data
  },
  updateTimeEntryRecordedCount({ commit }, value) {
    commit('updateTimeEntryRecordedNumber', value)
  },
  async getRecordedTimeEntriesSummary({ commit }) {
    const entities = '[limited_time_entries|time_entry_recorded_count]'

    const { data } = await axios.get('/data', {
      params: {
        entities,
        timeEntryFields: `[${timeEntryFields.join('|')}]`,
      },
    })

    const { limited_time_entries, time_entry_recorded_count } = data || {
      limited_time_entries: [],
      time_entry_recorded_count: 0,
    }

    // * Temporary way to have group, user links... will be done on get related entries form store
    const entries = limited_time_entries.map(entry => {
      const { group, user, project, task } = entry
      return {
        id: entry.id,
        type: 'time_entries',
        attributes: entry,
        relationships: {
          group: {
            id: entry.group_id,
            attributes: entry.group,
          },
          user: {
            id: entry.user_id,
            attributes: entry.user,
          },
          task: {
            id: entry.task_id,
            attributes: entry.task,
          },
          project: {
            id: entry.project_id,
            attributes: entry.project,
          },
        }
      }
    })

    commit('setLastRecordedTimeEntries', entries)
    commit('setLastRecordedTimeEntriesCount', time_entry_recorded_count)
  },
}

const getters = {
  // Time Entries
  timeEntryActiveColumns: (state) => {
    const { mainColumns, extraColumns } = getTimeEntryColumns()

    let columns = [...mainColumns]

    columnBuilder.addCustomFieldColumns(columns, 'time_entry', 'attributes.date')

    columnBuilder.addCustomColumns(columns, extraColumns)

    return columns
  },
  getAllocatedTimeByTaskId: (state) => (taskId) => {
    return state.allAllocatedTime.data.filter(t => t.task_id === taskId)
  },
  timeEntryTableColumns: (state, getters) => {
    return getters.timeEntryActiveColumns.filter(c => c.visibleInTable !== false)
  },
  groupedTimeEntries: (state, getters, rootState, rootGetters) => (timeEntries = null, groupBy = null) => {
    timeEntries = timeEntries || state.allTimeEntries || []

    groupBy = groupBy || rootGetters['filters/targetLocalFilters']('time-entries')?.groupBy
    const groupByKey = groupBy?.[0]
    const groupedTimeEntries = groupEntitiesToMap(timeEntries, groupByKey)

    return groupedTimeEntries
  },
  timeEntriesDataPropRange: (state, getters) => (prop) => {
    const timeEntries = getters.currentTimeEntries || []

    const min = Math.min(...timeEntries.map(timeEntry => typeof prop === 'function'
        ? prop(timeEntry)
        : get(timeEntry, prop)
      ),
      0
    )

    const max = Math.max(...timeEntries.map(timeEntry => typeof prop === 'function'
        ? prop(timeEntry)
        : get(timeEntry, prop)
      ),
      0
    )

    return {
      min,
      max
    }
  },
  projectScopedTimeEntries: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id
    const allTimeEntries = state.allTimeEntries || []
    return allTimeEntries.filter(timeEntry => timeEntry.attributes.project_id == project_id)
  },
  currentTimeEntries: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id

    const timeEntries = project_id
      ? getters.projectScopedTimeEntries
      : state.allTimeEntries || []

    return timeEntries
  },
  // Allocated Time
  allocatedTimeActiveColumns: (state) => {
    const { mainColumns, extraColumns } = getAllocatedTimeColumns()

    let columns = [...mainColumns]

    columnBuilder.addCustomColumns(columns, extraColumns)

    return columns
  },
  allocatedTimeTableColumns: (state, getters) => {
    return getters.allocatedTimeActiveColumns.filter(c => c.visibleInTable !== false)
  },
  groupedAllocatedTime: (state, getters, rootState, rootGetters) => (allocatedTime = null) => {
    allocatedTime = allocatedTime || state.allAllocatedTime || []

    const groupByKey = rootGetters['filters/targetLocalFilters']('allocated-time')?.groupBy?.[0]
    const groupedAllocatedTime = groupEntitiesToMap(allocatedTime, groupByKey)

    return groupedAllocatedTime
  },
  [`allocated-time-dataPropRange`]: (state) => (prop) => {
    return state.allocatedTime?.meta?.ranges?.[prop]
  },
  projectScopedAllocatedTime: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id
    const allAllocatedTime = state.allAllocatedTime || []
    return allAllocatedTime.filter(allocatedTime => allocatedTime.attributes.project_id == project_id)
  },
  currentAllocatedTime: (state, getters, rootState, rootGetters) => {
    const project_id = rootGetters.project_id
    const allocatedTime = project_id
      ? getters.projectScopedAllocatedTime || []
      : state.allAllocatedTime || []

    return allocatedTime
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
