import { Editor } from '@tiptap/vue-3'

import UploadingFileExtension from "@/components/html/extensions/uploadingFileExtension";
import TaskExtension from "@/components/html/extensions/taskExtension";
import ReferenceExtension from "@/components/html/extensions/referenceExtension";
import AiAssistantExtension from "@/components/html/extensions/aiAssistantExtension";

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!
  }

  const gripColumn = container && container.querySelector && container.querySelector('a.grip-column.selected')
  const gripRow = container && container.querySelector && container.querySelector('a.grip-row.selected')

  if (gripColumn || gripRow) {
    return true
  }

  return false
}

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    UploadingFileExtension.name,
    TaskExtension.name,
    ReferenceExtension.name,
    AiAssistantExtension.name,
  ]

  return customNodes.some(type => editor.isActive(type)) || isTableGripSelected(node)
}

export default isCustomNodeSelected
