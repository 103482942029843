<template>
  <div>
    <div class="text-xs font-medium leading-6 breadcrumb-color opacity-60">{{  $t('Starred Pages')  }}</div>
      <div
        v-if="!starredPages.length"
        class="group text-center bg-white/10 breadcrumb-color opacity-60 hover:opacity-100 rounded-lg p-4 space-y-2 cursor-pointer"
        @click="toggleStarredPage"
      >
        <i class="fa-solid fa-star text-xl" />
        <div
          class="block text-xs"
        >
          {{ $t('Star any page and it will show here. Click to star the current page.') }}
        </div>
      </div>
      <ul
        v-else
        role="list" class="-mx-2"
      >
        <li
          v-for="page of displayedStarredPages"
          :key="page.id"
        >
          <router-link
            :to="page?.attributes?.link"
            class="breadcrumb-color hover:opacity-100 hover:bg-white/10 group flex rounded-md px-2 py-1.5 text-sm leading-5"
            :class="{
              'bg-white/10': page?.attributes?.link === $route.fullPath,
              'opacity-60 hover:opacity-100': page?.attributes?.link !== $route.fullPath,
            }"
            @click="$emit('item-clicked')"
          >
            <span class="line-clamp-1">{{  page?.attributes?.title  }}</span>
          </router-link>
        </li>
        <li>
          <div
            class="breadcrumb-color opacity-60 hover:opacity-100 hover:bg-white/10 group flex rounded-md px-2 py-1.5 text-sm leading-4 cursor-pointer"
            @click="store.commit('accounts/triggerOpenStarredPages')"
          >
            <i class="fa-solid fa-arrow-up-right-from-square mr-2" />
            {{ $t('See all starred pages') }}
          </div>
        </li>
        <li
          v-if="!isPageStarred"
        >
          <div
            class="breadcrumb-color opacity-60 hover:opacity-100 hover:bg-white/10 group flex rounded-md px-2 py-1.5 text-sm leading-4 cursor-pointer"
            @click="toggleStarredPage"
          >
            <i class="fa-solid fa-square-plus mr-2" />
            {{ $t('Star current page') }}
          </div>
        </li>
      </ul>
      <StarredPageSetTitleDialog
        v-model="showStarredPageSetTitleDialog"
        :init-title="newStarredPageTitle"
        @ok="starPage($event)"
      />
  </div>
</template>
<script lang="ts" setup>
// Components
import StarredPageSetTitleDialog from "@/modules/accounts/components/StarredPageSetTitleDialog.vue"

// Utils
import {
  getRouteName,
  isCurrentPageStarred,
} from "@/modules/accounts/utils/pageUtils"
import { computed, ref } from 'vue'
import { orderBy } from 'lodash-es'
import { success } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";

// Composables
import { useStore } from 'vuex'
import {  useRoute } from "vue-router";
import useMobileUtils from "@/modules/common/composables/useMobileUtils"
const { isMobileDevice } = useMobileUtils()

const store = useStore()
const route = useRoute()

const starredPages = computed(() => {
  const pages = store.state.accounts.starredPages.data || []
  return orderBy(pages, 'attributes.order') 
})

const maxDisplayedCount = computed(() => {
  return isMobileDevice.value ? 3 : 5
})

const displayedStarredPages = computed(() => {
  return starredPages.value.slice(0, maxDisplayedCount.value)
})

const showStarredPageSetTitleDialog = ref(false)
const newStarredPageTitle = ref('')

const isPageStarred = computed(() => {
  return isCurrentPageStarred()
})

const routeName = computed(() => {
  return getRouteName()
})

async function toggleStarredPage() {
  const { fullPath } = route
  if (!isPageStarred.value) {
    newStarredPageTitle.value = routeName.value
    showStarredPageSetTitleDialog.value = true
  } else {
    await store.dispatch('accounts/deleteStarredPageByLink', fullPath)
    success(i18n.t('Starred page removed'))
  }
}

async function starPage(pageTitle: string) {
  await store.dispatch('accounts/createStarredPage', {
    title: pageTitle,
    link: route.fullPath,
  })
  success(i18n.t('Page starred'))
}
</script>
