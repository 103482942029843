<template>
  <div class="grid grid-cols-12 gap-2 items-center max-w-xs">
    <BaseInlineInput
      class="col-span-5"
    >
      <template #default="{ triggerSave }">
        <BaseSelect
          v-model="currencyName"
          :options="currencyOptions"
          :return-object="false"
          :placeholder="$t('Currency')"
          class="whitespace-nowrap placeholder-visible"
          inline
          @update:modelValue="triggerSave"
        />
      </template>
    </BaseInlineInput>

    <BaseInlineTextEdit
      v-model="currencyValue"
      type="number"
      class="currency-value-input form-input col-span-7"
      placeholder="- -"
    />
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [Object],
      required: false,
    },
    currencyOptions: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
  },
  computed: {
    currencyName: {
      get() {
        return this.modelValue?.currencyName;
      },
      set(value) {
        if (value === '' || (typeof value === 'object' && !Object.keys(value).length)) {
          value = null
        }
        this.updateModelValue('currencyName', value)
      },
    },
    currencyValue: {
      get() {
        return this.modelValue?.currencyValue;
      },
      set(value) {
        this.updateModelValue('currencyValue', value)
      },
    },
    currencySymbol() {
      if (!this.currencyName) {
        return '(¤)'
      }

      const selectedOption = this.currencyOptions.find(x => x.value === this.currencyName)

      const symbol = selectedOption?.label?.match(/\(([^\)]+)\)/)?.[1] || '(¤)'

      return symbol
    }
  },
  methods: {
    updateModelValue(prop, value) {
      const modelValue = {
        ...this.modelValue,
        [prop]: value
      }
      
      let hasValue = false
      for (const p in modelValue) {
        if (modelValue[p] !== undefined && modelValue[p] !== null ) {
          hasValue = true
          break;
        }
      }

      this.$emit("update:modelValue", hasValue ? modelValue : null);
    },
  }
};
</script>
<style lang="scss">
// .currency-inline-input:hover {
//   .circle-remove {
//     @apply inline;
//   }
// }
</style>
