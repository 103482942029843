<template>
  <ProjectCard
    v-if="entity.type === 'projects'"
    :project="entity"
    @click="$emit('click')"
  />

  <TaskCard
    v-else-if="entity.type === 'tasks'"
    :task="entity"
    @click="$emit('click')"
  />
  <div
    v-else
    class="relative bg-white p-4 rounded-lg group space-y-1.5 overflow-x-hidden items-center entity-card w-full h-fit"
    :class="{
      'border border-gray-200': $useNewLayout,
      'shadow': !$useNewLayout,
    }"
    @click="$emit('click')"
  >
    <div
      v-for="column of displayColumns"
      :key="column.field"
      class="text-xs text-gray-400 flex space-x-1 items-center"
      :class="{
        'absolute top-2 right-4': column.field === 'options',
      }"
    >
      <span
        v-if="column.showCardLabel"
      >
        <template v-if="column.headerName === $t('Project')">
          <i
            :class="{
              [column.cardIconClass]: column.cardIconClass
            }"
            class="fa-solid fa-inbox"
          />
        </template>
        <template v-else-if="column.headerName === $t('Task')">
          <i class="fa-solid fa-badge-check" />
        </template>
        <template v-else-if="column.headerName === $t('Group')">
          <i class="fa-solid fa-ball-pile" />
        </template>
        <template v-else-if="column.headerName === $t('Person')">
          <i class="fa-solid fa-user" />
        </template>
        <template v-else-if="column.headerName === $t('Date')">
          <i class="fa-solid fa-calendar-days" />
        </template>
        <span v-else class="font-bold">
          {{ column.headerName }}:&nbsp;
        </span>
      </span>
      <div
        v-if="getRendererType(column) && getRendererType(column) !== 'custom'"
        :class="column.cardClass"
      >
        <component
          :is="tableCellComponents[getRendererType(column)]"
          v-bind="column.cardProps"
          :params="getComponentParams(column)"
          :is-card="true"
        />
      </div>
      <template v-else>
        {{ getFormattedValue(column) }}
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import ProjectCard from "@/modules/projects/components/ProjectCard.vue";
import TaskCard from "@/modules/tasks/components/TaskCard.vue";

// Utils
import { computed } from 'vue'
import { get, orderBy } from 'lodash-es'
import { tableCellComponents } from "@/components/table/cells/tableCellComponents";
import { getEntityTarget } from "@/modules/common/utils/filterUtils";
import { useStore } from "vuex";
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
})

const entity = computed(() => {
  return props.params.data
})

const columns = computed(() => {
  return orderBy(props.params.columns || [], 'cardOrder')
})

function getRendererType(column: any) {
  if (column.cellRendererParams?.innerRenderer) {
    return column.cellRendererParams.innerRenderer
  }

  return column.type || ''
}

function getComponentParams(column: any) {
  return {
    ...(props.params || {}),
    colDef: column
  }
}

const target = computed(() => {
  return getEntityTarget(route)
})

const hiddenColumns = computed(() => {
  return store.getters['filters/targetLocalFilters'](target.value).columnsToHide?.columns || []
})

const displayColumns = computed(() => {
  const ignoredColumns = ['attributes.order', 'attributes.completed', 'attributes.visibility']

  return columns.value.filter((c: any) => !ignoredColumns.includes(c.field) && !hiddenColumns.value.includes(c.field) && hasValue(c))
})

function getColumnValue(column: any) {
  if (column.valueGetter) {
    return column.valueGetter({ data: entity.value })
  }

  return get(entity.value, column.field)
}

function hasValue(column: any): boolean {
  if (column.customField) {
    return entity.value.attributes?.custom_fields?.[column?.customField?.attributes?.key]
  }

  if (column.field === 'attributes.date' && entity.value.type === 'tasks') {
    return entity.value?.attributes?.date_type !== 'no date'
  }

  if (column.field === 'attributes.name' && entity.value.type === 'users') {
    return true
  }

  if (['options', 'attributes.image', 'attributes.total'].includes(column.field)) {
    return true
  }

  const value = getColumnValue(column)

  if (Array.isArray(value)) {
    return value.length > 0
  }

  return value !== undefined && value !== null && value !== ''
}

function getFormattedValue(column: any) {
  const value = getColumnValue(column)

  if (column.valueFormatter) {
    return column.valueFormatter({
      data: entity.value,
      value,
    })
  }

  return value
}
</script>
<style lang="scss">
.entity-dropdown {
  @apply absolute top-4 right-4;
}
</style>
