<template>
  <BaseTooltip
    :content="tooltip || $t(`This page can't be seen by people with a Collaborator role`)"
    :disabled="$isMobileDevice"
    class="inline"
  >
    <div
      class="text-gray-300 hover:text-gray-500 inline cursor-help relative"
      :class="{
        [$attrs.class]: $attrs.class,
        [`text-${textSize}`]: textSize,
      }"
    >
      <i
        class="fa-solid m-auto"
        :class="icon"
        aria-hidden="true"
      />
    </div>
  </BaseTooltip>
</template>
<script>
export default {
  name: "AccessControlIndicator",
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    icon: {
      String,
      default: 'fa-eye-slash'
    },
    textSize: {
      type: String,
      default: 'sm'
    }
  }
};
</script>
