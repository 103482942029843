import axios from 'axios'
import { get } from 'lodash-es'
import { isRoleGreaterOrEqual } from '@/modules/common/utils/isRoleGreaterOrEqual.js'
import userRolePermissions from '@/modules/common/permissions/userRolePermissions.json'
import { groupByArrayValues } from '@/modules/common/utils/listUtils.js'
import {
  USER_PROFILE_DEFAULT_VIEW_OPTIONS,
  isCreatorRole,
  isCollaboratorRole,
 } from '@/modules/users/util/userUtils'
import apiCache from '@/modules/common/utils/apiCache.js'

import { getUserColumns, userFields } from '@/modules/users/util/userTableUtils'
import { taskFields } from '@/modules/tasks/utils/taskTableUtils'
import { projectFields } from '@/modules/projects/utils/projectTableUtils'
import { groupFields } from "@/modules/groups/utils/groupTableUtils"
import { entityToRestifyMapper, transformToRestifyArray } from '@/modules/common/utils/dataUtils'
import { columnBuilder } from '@/components/table/tableUtils'
import {
  EntityChangeEvent,
  syncEntityInArray,
  getEntityArrayForWorker,
  groupEntitiesToMap,
 } from '@/modules/common/utils/entityUtils'
import { userGroupsMapWorker } from '@/modules/users/util/userRelationshipsUtils'
import { Activities, trackActivity } from "@/modules/common/utils/trackingUtils";
import { getAccountDefaultViews } from "@/plugins/settingsPlugin";

const state = () => ({
  users: {
    data: [],
  },
  allUsers: [],
  allUsersLoaded: false,
  userGroupsBindingMap: {
    // userId: { group_ids: [], groups: []}
  },
  roles: {
    data: [],
  },
  groups: {
    data: [],
  },
  usersLoading: true,
  groupsLoading: false,
  currentUserLoading: false,
  mainDataLoading: false,
  currentUser: null,
  defaultViews: [],
  addUserTrigger: 1,
  simulateRole: null
})

const mutations = {
  setUsers(state, value) {
    state.users = value
  },
  setAllUsers(state, value) {
    state.allUsers = value
    state.allUsersLoaded = true
  },
  setAllUsersLoaded(state, value) {
    state.allUsersLoaded = value
  },
  addUser(state, user) {
    syncEntityInArray(user, state.allUsers, EntityChangeEvent.Create)
  },
  updateUser(state, user) {
    syncEntityInArray(user, state.allUsers, EntityChangeEvent.Update)

    if (String(user.id) !== String(state.currentUser?.id)) {
      return
    }

    state.currentUser = {
      ...state.currentUser,
      ...user,
      attributes: {
        ...state.currentUser.attributes,
        ...user.attributes
      },
      relationships: {
        ...state.currentUser.relationships,
        ...user.relationships
      }
    }
  },
  setRoles(state, value) {
    state.roles = value
  },
  setGroups(state, value) {
    state.groups = value
  },
  setUsersLoading(state, value) {
    state.usersLoading = value
  },
  setGroupsLoading(state, value) {
    state.groupsLoading = value
  },
  setRolesLoading(state, value) {
    state.rolesLoading = value
  },
  setCurrentUserLoading(state, value) {
    state.currentUserLoading = value
  },
  setCurrentUser(state, value) {
    state.currentUser = value
  },
  setDefaultViews(state, value) {
    state.defaultViews = value
  },
  setUserGroupsBindingMap(state, value) {
    state.userGroupsBindingMap = value
  },
  updateUserGroupsBindingMap(state, { userId, value }) {
    state.userGroupsBindingMap[userId] = value
  },
  triggerAddUser(state) {
    state.addUserTrigger++
  },
  setMainDataLoading(state, value) {
    state.mainDataLoading = value
  },
  setSimulateRole(state, value) {
    state.simulateRole = value
  }
}

const actions = {
  async computeUserGroupsBinding({ commit, state, rootState }, value) {
    const bindingArray = getEntityArrayForWorker(value || rootState.groups.userGroupsBinding || [])
    const allGroups = getEntityArrayForWorker(rootState.groups.allGroups || [])

    if (!bindingArray.length || !allGroups.length) {
      return
    }

    const bindingMap = await userGroupsMapWorker(bindingArray, allGroups)
    commit('setUserGroupsBindingMap', bindingMap)
  },
  async getUsers({ commit, rootState }, filters) {
    try {
      if (filters?.shouldReset === true) {
        commit('setUsers', { data: [] })
      }
      filters = filters || {...rootState.route?.query }
      let archived

      if (rootState.route?.path?.includes('/users/list')) {
        archived = false
      }
      else if (rootState.route?.path?.includes('/users/archived')) {
        archived = true
      }

      commit('setUsersLoading', true)
      const users = await apiCache.getRequest('/restify/users', {
        params: {
          ...filters,
          archived,
          related: 'groups',
        }
      })
      commit('setUsers', users)
    } finally {
      commit('setUsersLoading', false)
    }
  },
  async getRoles({ commit, state }) {
    try {
      if (state.roles.data?.length > 0) {
        return
      }
      commit('setRolesLoading', true)
      const roles = await axios.get('/restify/roles')
      commit('setRoles', roles)
    } finally {
      commit('setRolesLoading', false)
    }
  },
  async getGroups({ commit, state }) {
    try {
      if (state.groups.data?.length > 0) {
        return
      }
      commit('setGroupsLoading', true)
      const roles = await apiCache.getRequest('/restify/groups')
      commit('setGroups', roles)
    } finally {
      commit('setGroupsLoading', false)
    }
  },
  async getUserById({ commit, state }, { id, returnEntity = false, includeArchived = false, silent = false }) {
    try {
      if (!silent) {
        commit('setCurrentUserLoading', true)
      }

      const url = includeArchived
        ? `/restify/users/include-archived/${id}`
        : `/restify/users/${id}`

      const { data } = await axios.get(url, {
        params: {
          related: 'groups'
        }
      })

      if (returnEntity) {
        return data
      }

      commit('setCurrentUser', data)
    }
    finally {
      if (!silent) {
        commit('setCurrentUserLoading', false)
      }
    }

    return state.currentUser
  },
  async createUser({ state, rootState, commit, dispatch }, data) {
    const response = await axios.post(`/invitations`, data)
    const createdUsers = []
    response.data.forEach(invitationResponse => {
      const invitation = invitationResponse.invitation
      const user = invitationResponse.user
      const role = (state.roles.data || []).find(role => role.id == invitation.role_id).attributes?.name || ''

      const newUser = entityToRestifyMapper(user, {
        type: 'users',
        getAttributes: (user) => ({
          ...user,
          role: [ role ]
        })
      })

      if (invitation.group_id) {
        commit('updateUserGroupsBindingMap', {
          userId: user.id,
          value: {
            group_ids: [Number(invitation.group_id)],
            groups: rootState.groups.allGroups.filter(g => Number(g.id) === Number(invitation.group_id))
          }
        })
      }

      commit('addUser', newUser)
      createdUsers.push(newUser)
    })
    trackActivity(Activities.UsersInvited, data)
    dispatch('accounts/syncSubscriptionStats', { },  { root: true })
    return createdUsers
  },
  async updateUser({ state, getters, rootState, commit, dispatch }, { user, role, custom_fields, groups, canUpdateGroups, canEditCustomFieldValues }) {
    const roles = getters['editableRoles']
    const oldRoleLabel = get(user, 'attributes.role[0]')
    const oldRole = roles.find(s => s?.attributes?.name === oldRoleLabel)
    const oldRoleId = oldRole?.id

    if (oldRoleId?.toString() !== role?.toString()) {
      await axios.post(`/restify/users/${user.id}/actions?action=change-role`, {
        role,
      })
    }

    if (canUpdateGroups) {
      await dispatch('updateUserGroups', {
        user,
        groups
      })

      commit('updateUserGroupsBindingMap', {
        userId: user.id,
        value: {
          group_ids: groups.map(g_id => Number(g_id)),
          groups: rootState.groups.allGroups.filter(g => groups.includes(String(g.id)))
        }
      })
    }

    if (canEditCustomFieldValues) {
      await axios.post(`/restify/users/${user.id}/actions?action=update-custom-fields`, {
        custom_fields,
      })
    }

    await dispatch('getUserById', { id: user.id })
    commit('updateUser', state.currentUser)
  },

  async deleteUser({ dispatch, commit }, userId) {
    await axios.post(`/restify/users/actions?action=update-archived-user`, {
      archived: true,
      user_id: userId
    })

    commit('updateUser', {
      id: userId,
      attributes: {
        status: 'archived',
        archived_at: new Date()
      }
    })

    dispatch('accounts/syncSubscriptionStats', { },  { root: true })
  },

  async restoreUser({ dispatch, commit }, userId) {
    await axios.post(`/restify/users/actions?action=update-archived-user`, {
      archived: false,
      user_id: userId
    })

    commit('updateUser', {
      id: userId,
      attributes: {
        status: 'active',
        archived_at: null
      }
    })

    dispatch('accounts/syncSubscriptionStats', { },  { root: true })
  },

  async resendInvitation({ dispatch, commit }, id) {
    await axios.post(`/restify/users/${id}/actions?action=resend-invitation`, {})
  },

  async updateUserGroups({ getters }, { user, groups }) {
    await axios.post(`/restify/users/${user.id}/actions?action=update-user-groups`, {
      groups,
    })
  },
  async getDefaultViews({ commit }) {
    try {
      const defaultViews = await axios.get('/restify/default-views', {
        params: {
          perPage: 100
        }
      })
      commit("setDefaultViews", defaultViews.data)
    }
    catch (err) {
      if (err.handled) {
        return;
      }
      throw err
    }
  },
  async updateDefaultViews({ dispatch }, userDefaultViews) {
    try {

      const createModels = []
      const updateModels = []

      userDefaultViews.forEach(view => {
        const viewModel = {
          target: view.target,
          view_group: view.view_group,
          inside_project: view.inside_project,
          view_type: view.view_type
        }
        if (view.id) {
          viewModel.id = view.id
          updateModels.push(viewModel)
        }
        else {
          createModels.push(viewModel)
        }
      })

      if (createModels.length) {
        await axios.post('/restify/default-views/bulk', createModels)
      }

      if (updateModels.length) {
        await axios.post('/restify/default-views/bulk/update', updateModels)
      }

      await dispatch('getDefaultViews')
    }
    catch (err) {
      if (err.handled) {
        return;
      }
      throw err
    }
  },
  async getData({ state, commit, dispatch }) {
    if (state.mainDataLoading) {
      return
    }

    try {
      commit('setMainDataLoading', true)
      commit('tasks/setTasksLoading', true, { root: true })
      commit('projects/setProjectsLoading', true, { root: true })
      commit('users/setUsersLoading', true, { root: true })
      commit('groups/setGroupsLoading', true, { root: true })

      const entities = [
        'tasks',
        'task_groups_binding',
        'projects',
        'project_groups_binding',
        'project_users_binding',
        'users',
        'groups',
        'user_groups_binding',
      ]

      const {
        data: {
          tasks,
          projects,
          users,
          groups,
          // bindings,
          task_groups_binding,
          project_groups_binding,
          project_users_binding,
          user_groups_binding
        }
      } = await axios.get('/data', {
        params: {
          entities: `[${entities.join('|')}]`,
          taskFields: `[${taskFields.join('|')}]`,
          projectFields: `[${projectFields.join('|')}]`,
          userFields: `[${userFields.join('|')}]`,
          groupFields: `[${groupFields.join('|')}]`,
        }
      })

      const allUsers = transformToRestifyArray(users, {
        type: 'users',
        getAttributes(user) {
          return {
            ...user,
            role: [user.role]
          }
        }
      })

      const allProjects = transformToRestifyArray(projects, {
        type: 'projects',
      })

      const allTasks = transformToRestifyArray(tasks, {
        type: 'tasks',
      })

      const allGroups = transformToRestifyArray(groups, {
        type: 'groups',
      })

      commit('groups/setAllGroups', allGroups, { root: true })
      commit('projects/setAllProjects', allProjects, { root: true })
      commit('users/setAllUsers', allUsers, { root: true })
      commit('tasks/addTasks', allTasks, { root: true })

      // Set bindings
      dispatch('projects/computeProjectGroupsBinding', project_groups_binding, { root: true })
      dispatch('projects/computeProjectUsersBinding', project_users_binding, { root: true })
      dispatch('groups/computeGroupProjectsBinding', project_groups_binding, { root: true })
      dispatch('groups/computeGroupUsersBinding', user_groups_binding, { root: true })
      dispatch('users/computeUserGroupsBinding', user_groups_binding, { root: true })

      dispatch('tasks/setTasksRelationships', null, { root: true })
      dispatch('tasks/setCompletedTasksRelationships', null, { root: true })
      dispatch('tasks/setCompletedTasksRelationships', {
        projectScoped: true
      }, { root: true })
      dispatch('files/setFilesTreeData', null, { root: true })
      dispatch('time/setTimeEntriesRelationships', null, { root: true })
      dispatch('time/setAllocatedTimesRelationships', null, { root: true })
      dispatch('payments/setPaymentsRelationships', null, { root: true })

      commit('tasks/setTaskGroupsBinding', task_groups_binding, { root: true })
    } catch (err) {
      console.log('get all user data error', err)
    }
    finally {
      commit('setMainDataLoading', false)
      commit('projects/setProjectsLoading', false, { root: true })
      commit('users/setUsersLoading', false, { root: true })
      commit('groups/setGroupsLoading', false, { root: true })
    }
  }
}

const getters = {
  activeColumns: (state) => {
    const { mainColumns, extraColumns } = getUserColumns()

    let columns = mainColumns

    columnBuilder.addCustomFieldColumns(columns, 'user')

    columnBuilder.addCustomColumns(columns, extraColumns)

    return columns
  },
  tableColumns: (state, getters) => {
    return getters.activeColumns.filter(c => c.visibleInTable !== false)
  },
  editableRoles: (state, getters, rootState) => {
    return state.roles.data?.filter(role => isRoleGreaterOrEqual(getters.currentRole, role?.attributes?.name)) || []
  },
  orderedRoles: (state, getters) => {
    const roles = state.roles.data || []

    return [...roles].sort((r1, r2) => {
      return isRoleGreaterOrEqual(r1?.attributes?.name, r2?.attributes?.name) ? 1 : -1
    })
  },
  actualRole: (state, getters, rootState) => {
    const authenticatedUser = rootState.auth?.user
    return get(authenticatedUser, 'role[0]')
  },
  currentRole: (state, getters, rootState) => {
    if (state.simulateRole) {
      return state.simulateRole.name
    }

    return getters.actualRole
  },
  isCreatorUser: (state, getters, rootState) => {
    return isCreatorRole(getters.currentRole)
  },
  isCollaboratorUser: (state, getters, rootState) => {
    return isCollaboratorRole(getters.currentRole)
  },
  loggedInUserGroupIds: (state, getters, rootState) => {
    const loggedInUser = rootState.auth?.user
    return (get(loggedInUser, 'group_ids') || []).map(Number)
  },
  isCurrentUserRoleGreaterOrEqual: (state, getters, rootState) => roleToCompare => {
    return isRoleGreaterOrEqual(getters.currentRole, roleToCompare)
  },
  hasPermissionsTo(state, getters, rootState) {
    return userRolePermissions[getters.currentRole] || []
  },
  can: (state, getters) => action =>  {
    return getters.hasPermissionsTo.includes(action)
  },
  getUserById: (state) => id => {
    return state.allUsers?.find(u => String(u.id) === String(id)) || null
  },
  canRole: () => (role, action) => {
    const rolePermissions = userRolePermissions[role];
    return rolePermissions.includes(action)
  },
  groupedUsers: (state, getters, rootState, rootGetters) => (users = null) => {
    users = users || state.allUsers || []

    const groupByColumn = rootGetters['filters/targetLocalFilters']('users')?.groupBy?.[0];
    let groupByKey = groupByColumn
    const isCustomFieldGrouping = (groupByKey || '').startsWith('attributes.custom_fields')

    const arrayValueKeys = ['relationships.groups', 'attributes.group_ids']
    let groupedUsers
    if (groupByKey === 'attributes.role') {
      groupByKey = 'attributes.role[0]'
    }

    if (arrayValueKeys.includes(groupByKey) || isCustomFieldGrouping) {
      if (groupByColumn?.customField?.attributes?.custom_field_type === 'select-color') {
        groupByKey += '.value'
      }
      groupedUsers = groupByArrayValues(users, groupByKey, isCustomFieldGrouping)
    }
    else {
      groupedUsers = groupEntitiesToMap(users, groupByKey)
    }

    return groupedUsers
  },
  defaultTargetViewOptions: (state, getters, rootState, rootGetters) => (target, inside_project = false) => {
    let defaultTargetView = state.defaultViews.find(view => view.attributes.target === target && view.attributes.inside_project == inside_project)

    let accountDefaultViews = getAccountDefaultViews()
    if (!defaultTargetView || !defaultTargetView?.attributes?.view_type) {
      defaultTargetView = accountDefaultViews.find(view => view.attributes.target === target && view.attributes.inside_project == inside_project)
    }
    if (defaultTargetView && defaultTargetView?.attributes?.view_type) {
      return {
        view_group: defaultTargetView.attributes.view_group,
        view_type: defaultTargetView.attributes.view_type
      }
    }

    return USER_PROFILE_DEFAULT_VIEW_OPTIONS[target].default
  },
  usersWithGroups: (state, getters, rootState) => {
    const allUsers = state.allUsers || []

    return allUsers.map(user => {
      const group_ids = state.userGroupsBindingMap[user.id]?.group_ids || []
      const groups = state.userGroupsBindingMap[user.id]?.groups || []

      return {
        ...user,
        attributes: {
          ...user.attributes,
          group_ids,
        },
        relationships: {
          ...user.relationships,
          groups
        }
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
