export function stripNoteHTML(html, removeTitle = true) {
  if (!html) {
    return ''
  }

  let div = document.createElement('div');
  div.innerHTML = html;

  if (removeTitle) {
    div = removeFirstH1(div)
  }

  // This is a hack to preserve the new lines, otherwise new lines are stripped
  document.body.appendChild(div)
  const text = removeBlankLines(div.innerText)
  document.body.removeChild(div)

  return text;
}

function removeFirstH1(el) {
  const h1 = el.querySelector('h1');

  if (h1) {
    el.removeChild(h1)
  }

  return el
}

export function getNoteName(html) {
  if (!html) {
    return 'Untitled'
  }

  let div = document.createElement('div');
  div.innerHTML = html;

  const h1 = div.querySelector('h1');

  return h1?.innerText || 'Untitled'
}

function removeBlankLines(text) {
  return text.replace(/(^[ \t]*\n)/gm, "")
}
