import { computed } from 'vue'
import {
  useRoute,
  useRouter,
  RouteLocationMatched
} from 'vue-router'
import { useStore } from 'vuex'
import usePageUtils from "@/modules/common/composables/usePageUtils"
import { useProjectTools } from "@/modules/projects/utils/useProjectTools"

const exceptionRoutes = [
  'Projects Layout',
  'Templates Layout',
  'Tasks Layout',
  'Welcome',
  'Projects',
  'Templates'
]

export default function useBreadcrumbs() {
  const route = useRoute()
  const router = useRouter()
  const store = useStore()

  const {
    getRouteName,
  } = usePageUtils()

  const {
    projectToolNavigationOptions
  } = useProjectTools()

  const matchedRoutes = computed(() => {
    return route.matched.filter(r => {
      const isViewType = r?.meta?.isViewType
      const isFilter = r?.meta?.useFilter
      const isEntityTypeSelect = r?.meta?.isEntityTypeSelect
  
      const prerequisited = [
        !r?.meta?.hideBreadCrumb,
        !exceptionRoutes.includes(r?.name?.toString() || ''),
        !isFilter,
        !isViewType,
        !isEntityTypeSelect,
      ]
  
      return  prerequisited.every(Boolean)
    })
  })

  function getRoutePath(matchedRoute: RouteLocationMatched) {
    const isProjectTool = matchedRoute.meta?.isProjectTool

    if (isProjectTool) {
      const optionPath = projectToolNavigationOptions.value.find((option: any) => 
        option.isActive
      )?.path

      if (optionPath) {
        return optionPath
      }
    }
    return router.resolve({
      name: matchedRoute.name,
      params: route.params,
    })
  }

  function getDisplayedName(matchedRoute: RouteLocationMatched) {
    const isProjectTool = matchedRoute.meta?.isProjectTool
    if (isProjectTool) {
      const optionName = projectToolNavigationOptions.value.find((option: any) => 
        option.isActive
      )?.label

      if (optionName) {
        return optionName
      }
    }

    return getRouteName(matchedRoute)
  }

  function getDropdownOptions(matchedRoute: RouteLocationMatched) {
    const isProjectTool = matchedRoute.meta?.isProjectTool
    if (isProjectTool) {
      return projectToolNavigationOptions.value
    }

    return []
  }

  const parentRoute = computed(() => {
    return matchedRoutes.value[0]
  })

  const childRoutes = computed(() => {
    return matchedRoutes.value.slice(1)
  })

  const isInsideProject = computed(() => {
    return !!store.getters.project_id
  })
  
  const isBreadcrumbContext = computed(() => {
    return isInsideProject.value || route.path.startsWith('/account-settings') || route.path.startsWith('/integrations')
  })

  const showBreadcrumbs = computed(() => {
    return isBreadcrumbContext.value && childRoutes.value.length > 0
  })

  return {
    matchedRoutes,
    parentRoute,
    showBreadcrumbs,
    childRoutes,
    getDisplayedName,
    getRoutePath,
    getDropdownOptions,
  }
}
