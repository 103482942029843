<template>
  <BaseDialogNew
    v-model="visible"
    class="w-full max-w-6xl"
    @close="$emit('close')"
  >
    <div class="w-full border-b border-gray-200 p-6">
      <h3 class="text-2xl font-bold">
        {{ $t('Create New Time Entry On This Task') }}
      </h3>
      <p class="text-sm text-gray-500 mt-2">
        {{ $t('Create a new time entry record to record the time people spend on this task.') }}
      </p>
    </div>
    <TaskTimeTrackingForm
      :task="task"
      @close="$emit('close')"
    />
  </BaseDialogNew>
</template>
<script>
import TaskTimeTrackingForm from '@/modules/tasks/components/TaskTimeTrackingForm.vue'

export default {
  name: 'TimeEntryDialog',
  inheritAttrs: false,
  components: {
    TaskTimeTrackingForm,
  },
  emits: ['close'],
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
