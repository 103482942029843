<template>
  <BaseDialog
    v-bind="$attrs"
    :hasBodyMargin="false"
    overflowVisible
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <BaseEntityForm
      :title="$tc('edit starred page', {
        pageTitle: page?.attributes?.title
      })"
      :isDialogForm="true"
      @submit="onSubmit"
    >
      <template #default="{ meta, errors }">
        <BaseInput
          v-focus
          v-model="model.title"
          :name="$t('Title')"
          :placeholder="$t('Title')"
          :label="$t('Title')"
          layout="horizontal"
          rules="required"
          id="title"
        />
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          variant="white"
          @click="$emit('close')"
        >
          {{ $t("Cancel") }}
        </BaseButton>
        <BaseButton
          :loading="loading"
          :disabled="!meta.valid"
          type="submit"
          class="ml-2"
        >
          {{ $t("Update") }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialog>
</template>
<script>
import i18n from "@/i18n";

export default {
  name: 'StarredPageDialog',
  inheritAttrs: false,
  props: {
    page: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      model: {
        title: '',
      }
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        await this.$store.dispatch('accounts/updateStarredPage', this.model)
        this.$success(i18n.t('Starred page updated'))
        this.$emit('save')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(i18n.t('Could not update starred page'))
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    page: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.model = {
          ...this.model,
          ...value?.attributes,
          id: value.id,
        }
      }
    }
  },
}
</script>
