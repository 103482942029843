<template>
  <div class="mt-4">
    <div class="col-span-full border-t border-gray-200" />

    <h3 class="text-2xl font-bold my-4">
      {{ $t('Payment Info') }}
    </h3>

    <!-- Loading skeleton -->
    <template v-if="loading">
      <div class="animate-pulse">
        <div class="text-sm text-gray-500 mt-2 bg-gray-200 h-16 w-80 rounded" />

        <div class="border-t border-gray-200 my-4" />
        
        <div class="space-y-3 mt-2">
          <div
            v-for="i in 2"
            :key="i"
            class="text-sm text-gray-500 bg-gray-200 h-6 w-full rounded"
          />
        </div>

        <div class="border-t border-gray-200 my-4" />
        
        <div class="space-y-3">
          <div
            v-for="i in 2"
            :key="i"
            class="text-sm text-gray-500 bg-gray-200 h-6 w-full rounded"
          />
        </div>
      </div>
    </template>
    <!-- Actual content  -->
    <template v-else>
      <p
        v-if="upcomingPaymentInfo?.next_payment_attempt"
        class="text-sm text-gray-500 mt-2"
      >
        <div>
          <span class="font-semibold">{{ $t('Period: ') }}</span>
          <span> {{ formattedPeriod }} </span>
        </div>
        <div>
          <span class="font-semibold">{{ $t('Next payment attempt: ') }}</span>
          <span>{{ formattedNextPaymentAttempt }} </span>
        </div>
      </p>
      
      <div class="border-t border-gray-200 my-4" />

      <div class="space-y-3 mt-4">
        <div
          v-for="line of upcomingPaymentInfoLines"
          :key="line.id"
          class="flex"
        >
          
          <label class="text-sm font-medium text-gray-700">
            <span>{{ line.description }}</span>
          </label>
          <div class="ml-auto">
            {{ getFormattedPrice(line?.amount) }}
          </div>
        </div>
      </div>

      <div class="col-span-full border-t border-gray-200 my-4" />

      <div class="space-y-3 mt-2">
        
        <div class="flex">
          <div class="text-sm text-gray-500">
            {{ $t(`Subtotal`) }}
          </div>
          <div class="ml-auto">
            {{ getFormattedPrice(upcomingPaymentInfo?.subtotal) }}
          </div>
        </div>

        <div
          v-if="totalDiscount"
          class="flex"
        >
          <div class="text-sm text-gray-500">
            {{ $t(`Discount`) }}
          </div>
          <div class="ml-auto">
            {{ getFormattedPrice(totalDiscount) }}
          </div>
        </div>

        <div class="flex">
          <div class="text-sm text-gray-500">
            {{ $t(`Tax`) }}
          </div>
          <div class="ml-auto">
            {{ getFormattedPrice(upcomingPaymentInfo?.tax) }}
          </div>
        </div>
        
        <div
          v-if="appliedBalance"
          class="flex"
        >
          <div class="text-sm text-gray-500">
            {{ $t(`Applied balance`) }}
          </div>
          <div class="ml-auto">
            -{{ appliedBalanceFormatted }}
          </div>
        </div>
        
        <div class="flex">
          <div class="text-sm font-medium text-gray-700">
            {{ $t(`Total due today`) }}
          </div>
          <div class="ml-auto">
            {{ getFormattedPrice(finalTotal) }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
// Utils
import { debounce } from "lodash-es"
import { CURRENCIES } from "@/modules/payments/enum/paymentEnums.js";

export default {
  props: {
    plan: {
      type: Object,
      default: () => ({})
    },
    quantity: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      CURRENCIES,
      loading: false,
      upcomingPaymentInfo: {
        period_start: null,
        period_end: null,
        amount_due: null,
        next_payment_attempt: null,
        starting_balance: null,
        ending_balance: null,
        lines: {
          object: 'list',
          data: [],
        }
      }
    }
  },
  computed: {
    upcomingPaymentInfoLines() {
      return this.upcomingPaymentInfo?.lines?.data || []
    },
    upcomingTotal() {
      return this.upcomingPaymentInfo?.total || 0
    },
    appliedBalance() {
      const starting_balance = (this.upcomingPaymentInfo?.starting_balance || 0) * -1

      if (!starting_balance || this.upcomingTotal < 0) {
        return 0
      }

      if (starting_balance > this.upcomingTotal) {
        return this.upcomingTotal
      }

      if (starting_balance < this.upcomingTotal) {
        return starting_balance
      }

      return 0;
    },
    finalTotal() {
      return this.upcomingTotal - this.appliedBalance
    },
    appliedBalanceFormatted() {
      return this.$formatPrice(this.appliedBalance / 100, {
        currencyCode: this.plan?.currency || CURRENCIES.USD
      })
    },
    totalDiscount() {
      return (-1) * (this.upcomingPaymentInfo?.total_discount_amounts || []).reduce((acc, v) => {
        return acc + v.amount
      }, 0)
    },
    formattedPeriod() {
      const start = this.$formatDate(new Date(this.upcomingPaymentInfo?.period_start * 1000))
      const end = this.$formatDate(new Date(this.upcomingPaymentInfo?.period_end * 1000))

      return `${start} → ${end}`
    },
    formattedNextPaymentAttempt() {
      return this.$formatDate(new Date(this.upcomingPaymentInfo?.next_payment_attempt * 1000))
    }
  },
  watch: {
    quantity: {
      immediate: true,
      handler(value) {
        if (!this.getUpcomingPaymentInfoDebounced) {
          this.getUpcomingPaymentInfoDebounced = debounce(this.getUpcomingPaymentInfo, 200)
        }

        this.getUpcomingPaymentInfoDebounced()
      }
    }
  },
  methods: {
    async getUpcomingPaymentInfo() {
      this.loading = true
      this.$emit('is-loading', true)
      
      try {
        this.upcomingPaymentInfo = await this.$store.dispatch('accounts/getUpcomingPaymentInfo', {
          plan_id: this.plan.id,
          quantity: this.quantity
        })
      }
      finally {
        this.loading = false
        this.$emit('is-loading', false)
      }
    },
    getFormattedPrice(value) {
      return this.$formatPrice((value || 0) / 100, {
        currencyCode: this.plan?.currency || CURRENCIES.USD
      })
    }
  }
}
</script>
