<template>
  <BaseTooltip :content="$t('Select file from Dropbox')" :show-after="500">
    <div @click="onClick">
      <slot>
        <button
            class="p-2 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
        >
          <i class="fa-brands fa-dropbox"></i>
        </button>
      </slot>
    </div>
  </BaseTooltip>
</template>
<script>
export default {
  name: 'DropboxFilePickerButton',
  props: {
    editor: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      scriptLoaded: false,
    }
  },
  methods: {
    initScript() {
      if (window.Dropbox) {
        this.scriptLoaded = true
        return
      }
      const script = document.createElement('script');
      script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
      script.id = 'dropboxjs';
      script.setAttribute('data-app-key', import.meta.env.VITE_DROPBOX_APP_KEY);
      script.onload = () => {
        this.scriptLoaded = true
      };
      document.body.appendChild(script);
    },
    prepareEditor() {
      return this.editor
          .chain()
          .focus()
    },
    addFile(file) {
      this.prepareEditor().setExternalFile({
        url: file.link,
        icon: file.icon,
        name: file.name,
        size: file.bytes,
      })
          .run()
    },
    onClick() {
      if (!this.scriptLoaded) {
        setTimeout(this.onClick, 1000)
        return
      }
      window.Dropbox.choose({
        multiselect: true,
        success: (files) => {
          if (!files.length) {
            this.$error('No files selected')
            return
          }
          files.forEach(this.addFile)
          this.$emit('select', files)
        },
        cancel: () => {
        }
      })
    }
  },
  mounted() {
    this.initScript()
  }
}
</script>
