<template>
  <NodeViewWrapper
    as="div"
    :draggable="isDraggable"
    :data-drag-handle="isDraggable"
    :class="{
      'cursor-pointer': url,
    }"
    class="w-full flex items-center space-x-1 mb-4 h-8 group hover:bg-gray-50 rounded-sm editor-file"
    @click="showPreviewDialog = true"
  >
    <template v-if="url">
      <BaseFilePreview
        :file="fileAttributes"
        :thumbnail="true"
        size="md"
        class="object-cover text-center"
        :class="{
          'w-auto': isImage,
        }"
      />
      <a class="underline w-full whitespace-nowrap">
        {{ name }}
      </a>
      <FilePreviewDialog
        v-model="showPreviewDialog"
        :file="file"
      />
    </template>
    <template v-else>
      <ElProgress
        :width="24"
        :stroke-width="3"
        color="var(--primary-500)"
        :percentage="progress"
        :show-text="false"
        type="circle"
      />
      <span>{{ name }}</span>
    </template>
    <div
      v-if="editor?.isEditable"
      class="flex-1 flex justify-end px-2"
    >
      <BaseDropdown
        :append-to-body="true"
        :options="moreActions"
        @action="onAction"
      >
        <div
          class="file-actions-dropdown inline py-1 px-2 hover:text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer select-none"
        >
          <i class="fa-regular fa-ellipsis-vertical" />
        </div>
      </BaseDropdown>
    </div>
  </NodeViewWrapper>
</template>
<script>
import i18n from '@/i18n'
import axios from "axios";
import * as FileUtils from "@/modules/common/utils/fileUtils.js";
import { ElProgress } from 'element-plus'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { appendImageToEditor, filesToUpload, FileUploadStatus } from "@/components/html/util/uploadUtils.js";
import { createFileURL } from "@/modules/common/utils/fileUtils.js";
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";

const FileActions = {
  Delete: 'delete',
}
export default {
  name: 'UploadingFile',
  components: {
    ElProgress,
    NodeViewWrapper,
  },
  props: nodeViewProps,
  setup() {
    const {
      checkWillPassStorageLimit
    } = useAccountLimits()

    return {
      checkWillPassStorageLimit
    }
  },
  data() {
    return {
      url: this.node.attrs.url || null,
      type: this.node.attrs.type || null,
      showPreviewDialog: false,
    }
  },
  computed: {
    fileId() {
      return this.node.attrs.id;
    },
    fileUuid() {
      return this.node.attrs.uuid;
    },
    name() {
      return this.node.attrs.name || 'file';
    },
    fileAttributes() {
      return {
        id: this.fileId,
        name: this.name,
        type: this.type,
        url: this.url,
        uuid: this.fileUuid,
      }
    },
    file() {
      return {
        id: this.fileId,
        attributes: {
          ...this.fileAttributes,
        }
      }
    },
    progress() {
      return this.$store.state.files.uploadProgress[this.fileId]?.progress || 0
    },
    projectId() {
      const route = this.$route?.fullPath
      if (route.includes('projects')) {
        return this.$route?.params?.id
      }
      return this.$route.query.projectId | null
    },
    taskId() {
      return this.$route.query.taskId
    },
    noteId() {
      return this.$route?.params?.noteId
    },
    icon() {
      return FileUtils.getFileIcon({ name: this.name })
    },
    isImage() {
      return FileUtils.isImage({
        type: this.type,
      })
    },
    isDraggable() {
      return this.node?.attrs?.isDraggable
    },
    isFullScale() {
      return this.node?.attrs?.fullScale
    },
    moreActions() {
      return [
        {
          label: i18n.t('Delete'),
          action: FileActions.Delete,
        },
      ]
    }
  },
  methods: {
    openLink(event) {
      const target = event.target
      if (target?.classList?.contains('file-actions-dropdown')) {
        return
      }

      if (!this.url) {
        return
      }
      event.preventDefault()
      window.open(this.url, '_blank')
    },
    willPassStorageLimit() {
      const size = filesToUpload[this.fileId]?.file.size || 0
      return this.checkWillPassStorageLimit(size)
    },
    async uploadFile() {
      const file = filesToUpload[this.fileId]
      const isAlreadyUploaded = [FileUploadStatus.Uploading, FileUploadStatus.Success].includes(file?.status)

      if (!file || isAlreadyUploaded || this.willPassStorageLimit()) {
        return
      }
      try {
        const rawFile = file.file
        this.type = rawFile.type

        file.status = FileUploadStatus.Uploading
        let uploadedFile = {}
        if (this.taskId) {
          uploadedFile = await this.$store.dispatch('tasks/uploadFileToTask', {
            taskId: this.taskId,
            file: rawFile
          })
          this.url = FileUtils.createFileURL(uploadedFile)
        } else if (this.projectId) {
          uploadedFile = await this.$store.dispatch('projects/uploadFileToProject', {
            projectId: this.projectId,
            noteId: this.noteId,
            file: rawFile
          })
          this.url = FileUtils.createFileURL(uploadedFile)
        } else {
          this.url = await this.$store.dispatch('files/uploadImage', rawFile)
        }
        file.status = FileUploadStatus.Success

        if (uploadedFile?.uuid) {
          this.url = FileUtils.createFileURL({
            uuid: uploadedFile.uuid,
          })
        }

        if (this.isFullScale && this.isImage) {
          this.convertToFullScaleImage(this.url, uploadedFile?.uuid)
          return
        }
        this.updateAttributes({
          id: uploadedFile?.id || this.fileId,
          uuid: uploadedFile?.uuid,
          url: this.url,
          type: this.type,
        })

        if (this.noteId) {
          this.$success(i18n.t('This file has also been added to the files page for this project (visible to creators only).'))
        }
      } catch (err) {
        file.status = FileUploadStatus.Error
        this.$error(i18n.t('An error occurred while uploading the file.'))
      }
    },
    convertToFullScaleImage(url, uuid) {
      appendImageToEditor({
        url: url,
        uuid,
        view: this.editor.view,
      })
      this.deleteNode()
    },
    async deleteFile() {
      this.deleteNode();
      const localFile = filesToUpload[this.fileId]
      if (localFile) {
        localFile.status = FileUploadStatus.Error
      }
      const file = await this.$store.dispatch('files/getFileById', { fileId: this.fileId })
      if (!file) {
        return
      }
      await this.$store.dispatch('files/deleteFile', { file })
    },
    async onAction(action) {
      if (action === FileActions.Delete) {
        await this.deleteFile()
      }
    },
    async downloadFile(event) {
      if (this.fileUuid && this.url?.includes('media/download')) {
        this.openLink(event)
        return
      }

      if (!this.fileUuid) {
        window.open(this.url, '_blank')
        return
      }

      if (!this.fileId) {
        return
      }

      const { data } = await axios.get(`/restify/media/${this.fileId}`)
      if (!data) {
        this.$error(i18n.t('Could not download the file'))
        return
      }

      const url = FileUtils.createFileURL(data?.attributes)

      this.updateAttributes({
        uuid: data?.attributes?.uuid,
        url: url,
      })

      window.open(url, '_blank')

    },
    getTransformedUrl() {
      let url = this.url || ''
      if (!this.fileUuid) {
        return
      }
      if (url?.includes('media/download') || !this.fileUuid) {
        return
      }
      this.url = FileUtils.createFileURL({
        uuid: this.fileUuid,
      })
    }
  },
  mounted() {
    this.uploadFile()
    this.getTransformedUrl()
  }
}
</script>
