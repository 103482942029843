<template>
  <BaseDialogNew
    v-bind="$attrs"
    :hasBodyMargin="false"
    :appendToBody="true"
    class="max-w-2xl"
    @close="$emit('close')"
  >
    <EmbedForm
      :embed="embed"
      :single-embed="singleEmbed"
      :project-id="projectId"
      :default-embed-type="defaultEmbedType"
      @cancel="$emit('close')"
      @save="$emit('save', $event)"
    />
  </BaseDialogNew>
</template>
<script>
import EmbedForm from "@/modules/resources/components/EmbedForm.vue";

export default {
  name: 'EmbedDialog',
  components: {
    EmbedForm,
  },
  inheritAttrs: false,
  props: {
    embed: {
      type: Object,
      default: () => null,
    },
    title: {
      type: String,
      default: ''
    },
    singleEmbed: {
      type: Boolean,
      default: false
    },
    defaultEmbedType: {
      type: [String, Object],
      default: () => null
    },
    projectId: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['save', 'close']
}
</script>
