<template>
  <div class="flex flex-col h-full">
    <TaskDialog
      v-show="showTaskDialog"
      v-model="showTaskDialog"
      :taskId="currentTaskId"
      :task="currentTask"
      :is-bulk-create="isBulkCreate"
      :key="mainDialogKey"
      id="task-main-dialog"
      @close="closeDialog"
    />
    <TopFilters
      :columns="taskColumns"
      :availableFilters="availableFilters"
      :searchPlaceholder="$t('Search tasks...')"
    >
      <template
        v-if="!isCurrentProjectClosed && can($actions.CREATE_TASKS)"
        #action
      >
        <BaseDropdown
          :split-button="true"
          :options="createTaskOptions"
          class="create-options-dropdown"
        >
          <BaseButton
            v-if="can($actions.CREATE_TASKS)"
            @click="triggerAddTask"
            block
          >
            {{ $t('New Task') }}
          </BaseButton>
        </BaseDropdown>
      </template>
      
      <EntityViewTypesSelect
        v-if="$isMobileDevice"
        :viewTypes="viewTypes"
      />
     
    </TopFilters>
    
    <DataSyncingIndicator
      v-if="tasks.length && $store.state.tasks.tasksLoading"
      dataView
    />

    <router-view />
  </div>
</template>
<script>
// Components
import TopFilters from "@/modules/filters/components/TopFilters.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import DataSyncingIndicator from "@/components/common/DataSyncingIndicator.vue"
import EntityViewTypesSelect from "@/modules/common/components/EntityViewTypesSelect.vue";
import EntityViewTypesTabs from "@/modules/common/components/EntityViewTypesTabs.vue"
import BaseDropdown from "@/components/common/BaseDropdown.vue";

import {
  importEntityTypes,
} from "@/modules/accounts/utils/importUtils";

export default {
  components: {
    BaseDropdown,
    BaseButton,
    TopFilters,
    DataSyncingIndicator,
    EntityViewTypesSelect,
    EntityViewTypesTabs,
  },
  data() {
    return {
      showTaskDialog: false,
      isBulkCreate: false,
      showQuickCreate: false,
      currentTaskId: null,
      mainDialogKey: 1,
    }
  },
  computed: {
    currentTask: {
      get() {
        return this.$store.state.tasks.currentTask
      },
      set(value) {
        this.$store.commit('tasks/setCurrentTask', value)
      }
    },
    taskColumns() {
      return this.$store.getters['tasks/activeColumns'] || []
    },
    tasks() {
      return this.$store.state.tasks.tasks?.data || []
    },
    isListView() {
      return ['Tasks List', 'All Tasks'].includes(this.$route.name)
    },
    insideProjectId() {
      return this.$store.getters.project_id
    },
    currentProject() {
      if (!this.insideProjectId) {
        return null
      }

      return this.$store.state.projects.currentProject || null
    },
    availableFilters() {
      // views: list, calendar, scheduler, kanban
      const filters = ['overdueTasks', 'filter', 'customFilters']

      if (this.$isMobileDevice || this.$useNewLayout) {
        filters.push('myTasks')
      }

      if (this.insideProjectId) {
        filters.push('completedTasks')
      }
      else {
        filters.push('globalCompletedTasks')
      }

      if (this.$route.path.includes('list')) {
        filters.push(...['sort', 'group', 'columns', 'toggleDetailsView', 'dateOnly'])
      }
      else if (this.$route.path.includes('calendar')) {
        filters.push(...['toggleFutureRecurrences'])
      }
      else if (this.$route.path.includes('scheduler')) {
        filters.push(...['group'])
      }
      else if (this.$route.path.includes('kanban')) {
        filters.push(...['columns', 'sort', 'group', 'kanbanColumns', 'dateOnly'])
      }
      else if (this.$route.path.includes('card')) {
        filters.push(...['columns', 'sort', 'group', 'dateOnly'])
      }

      return filters
    },
    createDialogClosedTrigger() {
      return this.$store.state.tasks.createDialogClosedTrigger
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    },
    viewTypes() {
      const viewTypes = [
        {
          name: this.$t('List View'),
          path: this.getPath('tasks/list'),
          isActive: this.$route.path.includes('/tasks/list'),
          icon: 'fa-list-check',
          showIf: () => {
            return this.can(this.$actions.ACCESS_TASKS_LIST) && !this.$isMobileDevice
          }
        },
        {
          name: this.$t('Cards View'),
          path: this.getPath('tasks/card'),
          isActive: this.$route.path.includes('/tasks/card'),
          icon: 'fa-grid-2',
          showIf: () => {
            return this.can(this.$actions.ACCESS_TASKS_LIST) && this.$isMobileDevice
          }
        },
        {
          name: this.$t('Calendar View'),
          path: this.getPath('tasks/calendar'),
          isActive: this.$route.path.includes('/tasks/calendar'),
          icon: 'fa-calendar-days',
          showIf: () => {
            return this.can(this.$actions.ACCESS_TASKS_CALENDAR) && !this.$isMobileDevice
          }
        },
        {
          name: this.$t('Scheduler View'),
          path: this.getPath('tasks/scheduler'),
          isActive: this.$route.path.includes('/tasks/scheduler'),
          icon: 'fa-chart-simple-horizontal',
          showIf: () => {
            return this.can(this.$actions.ACCESS_TASKS_SCHEDULER) && !this.$isMobileDevice
          }
        },
        {
          name: this.$t('Kanban View'),
          path: this.getPath('tasks/kanban'),
          isActive: this.$route.path.includes('/tasks/kanban'),
          icon: 'fa-objects-column',
          showIf: () => {
            return this.can(this.$actions.ACCESS_TASKS_KANBAN)
          }
        }
      ]

      return viewTypes
    },
    project_id() {
      return this.$store.getters.project_id
    },
    createTaskOptions() {
      const actions = [
        {
          label: this.$t('Add Multiple Tasks'),
          action: () => {
            this.currentTaskId = null;
            this.showTaskDialog = true
            this.isBulkCreate = true
          },
          enabled: true,
        },
        {
          label: this.$t('Import Tasks'),
          action: () => {
            this.$store.commit('setEntityCreateParams', {
              importType: importEntityTypes.Tasks,
              project: this.currentProject
            })

            this.$store.commit('accounts/triggerDataImport')
          },
          enabled: this.can(this.$actions.MANAGE_ACCOUNT_SETTINGS)
        }
      ]
      return actions.filter(a => a.enabled)
    }
  },
  methods: {
    triggerAddTask() {
      this.currentTaskId = null
      this.currentTask = null
      this.showTaskDialog = true
    },
    getPath(pathToAppend) {
      let path = `/${pathToAppend}`

      if (this.project_id) {

        const parentEntity = this.isTemplatePath
          ? 'templates'
          : 'projects'

        path = `/${parentEntity}/${this.project_id}/${pathToAppend}`
      }

      return path
    },
    closeDialog() {
      this.mainDialogKey++
      this.isBulkCreate = false
      this.redirectToList()
    },
    redirectToList() {
      this.showTaskDialog = false
      this.currentTaskId = null
      this.currentTask = null

      let query = {
        ...this.$route.query
      }
      delete query.add
      delete query.taskId
      delete query.addTask
      delete query.start_date
      delete query.end_date

      this.$router.push({
        path: this.$route.path,
        query
      })
    },
  },
  watch: {
    '$route.query.taskId': {
      immediate: true,
      async handler(taskId) {
        if (!taskId || !this.can(this.$actions.OPEN_TASKS)) {
          this.showTaskDialog = false
          return
        }
        this.currentTaskId = taskId
        this.showTaskDialog = true
      }
    },
    '$route.query.addTask': {
      immediate: true,
      async handler(value) {
        if (value) {
          this.showCreateDialog = true
        }
      }
    },
    createDialogClosedTrigger() {
      this.redirectToList()
    },
  }
}
</script>
