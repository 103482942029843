export const desktopAppUtils = {
  isDesktopApp() {
    return window?.todesktop
  },
  getApp() {
    return window?.todesktop?.app
  },
  setBadgeCount(count) {
    this.getApp()?.setBadgeCount(count)
  },
  sendNotification(message) {
    if (!this.isDesktopApp()) {
      return
    }
    new Notification(message, {
      silent: true,
    })
  }
}
