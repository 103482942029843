import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { getSetting } from "@/plugins/settingsPlugin";
import { uniq } from 'lodash-es';
import { getRouteName } from "@/modules/accounts/utils/pageUtils"
import i18n from '@/i18n';

export default function usePageUtils() {
  const route = useRoute()
  const store = useStore()

  const appLoading = computed(() => {
    return store.state.appLoading
  })

  const matchedRoutes = computed(() => {
    const exceptionRoutes = ['Projects Layout', 'Templates Layout', 'Tasks Layout', 'Welcome']
    return route.matched.filter((r) => !exceptionRoutes.includes((r?.name || '')?.toString()) && !r?.meta?.hideBreadCrumb)
  })
  
  const isCustomBrandingEnabled = computed(() => {
    return store.getters['accounts/isCustomBrandingEnabled']
  })

  const pageTitle = computed(() => {
    const defaultPageTitle = 'Project.co'

    if (!isCustomBrandingEnabled.value) {
      return defaultPageTitle
    }

    return getSetting('account_name') || defaultPageTitle
  })

  const formattedRouteFullName = computed(() => {
    let routeNames = []
    for (let i = matchedRoutes.value.length - 1; i >= 0; i--) {
      let routeName = getRouteName(matchedRoutes.value[i])
      if (routeName.includes('Module')) {
        continue
      }

      routeNames.push(routeName)
    }
    routeNames = uniq(routeNames.filter(r => r !== ''))
    return routeNames.join(', ')
  })

  const formattedPageTitle = computed(() => {
    if (appLoading.value) {
      return getSetting('account_name') || i18n.t('Loading...')
    }

    let title = formattedRouteFullName.value
    return title ?
      `${title} - ${pageTitle.value}`
      : pageTitle.value

  })
  
  function setTitle() {
    document.title = formattedPageTitle.value || pageTitle.value
  }

  return {
    getRouteName,
    formattedPageTitle,
    setTitle
  }
}
