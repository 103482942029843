<template>
  <div
    ref="taskDescriptionInput"
    class="task-description"
    :class="{
      'min-h-[46px]': !$attrs.disabled
    }"
  >
    <TaskDescriptionOptions
      v-if="showOptions && canUseAI && (!$attrs.disabled || isSaving)"
      class="py-2"
      @init-default="showOptions = false"
      @init-with-ai="initWithAI"
    />
    <BaseInlineInput
      v-else
    >
      <template #default="{ triggerSave }">
        <CollaborativeHtmlEditor
          ref="htmlEditor"
          v-model="model"
          v-model:mentions="mentions"
          :realTimeValue="task?.attributes?.description_collaboration"
          :initCommand="initEditorCommand"
          :placeholder="placeholder"
          :showPlaceholderOnlyWhenEditable="false"
          :projectId="task?.attributes?.project_id"
          :autofocus="false"
          :readonly="$attrs.disabled"
          :real-time="task?.id !== undefined"
          :real-time-doc-url="apiUrl"
          real-time-doc-field="description_collaboration"
          contentClass="text-gray-500"
          :class="{
            'mt-4 mb-4': !$attrs.disabled || !!modelValue
          }"
          @blur="onBlur($event, triggerSave)"
        />
      </template>
    </BaseInlineInput>
  </div>
</template>
<script>
// Components
import TaskDescriptionOptions from "@/modules/tasks/components/TaskDescriptionOptions.vue";

// Utils
import axios from "axios";
import { enableRealTimeCollaboration } from "@/modules/common/config";
import { EditorInitCommands } from '@/components/html/util/editorUtils'
import { useAccountLimits } from "@/modules/auth/composables/useAccountLimits";
import { computed } from 'vue'
import { AccountPermissions } from "@/modules/common/composables/useCan";

export default {
  components: {
    TaskDescriptionOptions
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: String,
      default: ''
    },
    notifiable_user_ids: {
      type: Array,
      default: () => [],
    },
    isSaving: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const {
      hasReachedLimit,
    } = useAccountLimits()

    const aiTokensLimitReached = computed(() => {
      return hasReachedLimit(AccountPermissions.AiAssistant)
    })
    
    return {
      aiTokensLimitReached,
    }
  },
  data() {
    return {
      focusValue: this.modelValue,
      isFocused: false,
      showOptions: true,
      initEditorCommand: null,
    }
  },
  computed: {
    isBlankDescription() {
      return !this.modelValue?.replaceAll('<p></p>', '')?.trim()
    },
    canUseAI() {
      return !this.aiTokensLimitReached
    },
    apiUrl() {
      return `${axios.defaults.baseURL}/restify/tasks/${this.task?.id}`
    },
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    mentions: {
      get() {
        return this.notifiable_user_ids
      },
      set(value) {
        this.$emit('update:notifiable_user_ids', value)
      }
    },
    isTemplatePath() {
      return this.$store.getters['templates/isTemplatePath']
    },
    placeholder() {
      if (this.$attrs.disabled) {
        return ''
      }

      if (this.$isMobileDevice) {
        return this.$t('Enter task description...')
      }

      return this.$t('Enter task description here or press / for options')
    }
  },
  mounted() {
    addEventListener("click", this.onClickEvent)
  },
  unmounted() {
    removeEventListener("click", this.onClickEvent)
  },
  watch: {
    isBlankDescription: {
      handler(value) {
        this.showOptions = value
      },
      immediate: true,
    },
  },
  methods: {
    initWithAI() {
      this.initEditorCommand = EditorInitCommands.AiAssistant
      this.showOptions = false
    },
    onClickEvent(e) {
      if (!this.$refs?.taskDescriptionInput) {
        return
      }

      if (this.$refs.taskDescriptionInput.contains(e.target)) {
        this.onFocus(e)
        return
      }

      this.isFocused = false;
    },
    onFocus(e) {
      const tippyBox = this.$refs.taskDescriptionInput?.querySelector('.tippy-box')
      const clickedEditorControls = tippyBox && tippyBox.contains(e.target)

      if (this.isFocused || clickedEditorControls) {
        return;
      }

      this.isFocused = true;

      this.focusValue = this.model
    },
    onBlur(e, triggerSave) {
      const clickedEditorControls = !!e.event.relatedTarget
      if (clickedEditorControls) {
        return
      }

      const description = this.$refs.htmlEditor?.getHTML()
      if (description) {
        this.$emit('update:modelValue', description)
      }

      // Value not changed
      if (this.focusValue === this.model) {
        return
      }

      triggerSave && triggerSave()
    }
  },
}
</script>
<style lang="scss">
.task-description {
  .ProseMirror input[type=checkbox] {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    @apply border-2 text-primary-500 w-6 h-6 rounded-2xl align-baseline mt-0 mr-0 mb-1;
  }
}
</style>
