import { useStore } from "vuex";
import { entityTypes } from "@/modules/common/enum/entityTypes.js";
import store from "@/store";

type EntityTypesKey = keyof typeof entityTypes
export type EntityTypes = typeof entityTypes[EntityTypesKey]
export type EntityCrudParams = {
  // + Others depending on entity type / dialog
}

export type EntityCrudEvents = {
  closed?: () => void
  created?: (entity: any) => void
}

export default function useEntityCrud() {
  const store2 = useStore()

  function triggerEntityCreate(entityType: EntityTypes, entityCreateParams?: EntityCrudParams, events?: EntityCrudEvents) {
    (store2 || store).dispatch('triggerEntityCreate', {
      entityType,
      entityCreateParams,
      events
    })
  }

  return {
    entityTypes,
    triggerEntityCreate,
  }
}
