import axios from 'axios'
import i18n from "@/i18n.js";
import { groupByArrayValues } from "@/modules/common/utils/listUtils.js";
import { syncNewProjectData } from "@/modules/projects/utils/projectActionsUtils"
import { error, success } from "@/components/common/NotificationPlugin/index.js";
import { entityTypes } from "@/modules/common/enum/entityTypes.js";
import { taskDateTypes } from '@/modules/tasks/utils/modelUtils'
import { Activities, trackActivity } from "@/modules/common/utils/trackingUtils";
import router from "@/router";
import {
  groupEntitiesToMap,
} from "@/modules/common/utils/entityUtils";

const CancelToken = axios.CancelToken;
const state = () => ({
  templates: {
    data: [],
  },
  templatesLoading: false,
  templateToShare: null,
})

const mutations = {
  setTemplates(state, value) {
    state.templates = value
  },
  setTemplatesLoading(state, value) {
    state.templatesLoading = value
  },
  setTemplateToShare(state, value) {
    state.templateToShare = value
  }
}

let cancelGetTemplates = null

const actions = {
  async getTemplates({ commit, rootGetters, rootState }, filters) {
    let templates = null
    try {
      if (cancelGetTemplates) {
        cancelGetTemplates.cancel('Tasks fetching canceled by the user.');
      }
      if (filters?.shouldReset === true) {
        commit("setTemplates", { data: [] })
      }
      filters = filters || { ...rootState.route?.query }
      commit('setTemplatesLoading', true)

      cancelGetTemplates = CancelToken.source();
      templates = await axios.get('/restify/projects', {
        cancelToken: cancelGetTemplates?.token,
        params: {
          ...filters,
          is_template: true,
          related: 'tools,status',
        }
      })

      if (templates?.cancelled) {
        return
      }

      commit('setTemplates', templates)
    } finally {
      if (!templates?.cancelled) {
        commit('setTemplatesLoading', false)
      }
    }
  },
  async triggerCreateProjectFromTemplate({ rootState, dispatch }, {
    templateId,
    template,
    data,
  }) {
    templateId = templateId || template?.id

    const allTemplateTasks = rootState.tasks.templateTasks || []
    const templateTasks = allTemplateTasks.filter(task => task.attributes?.project_id == templateId)
    
    const relativeDateTypes = [
      taskDateTypes.DAYS_FROM_PROJECT_START,
      taskDateTypes.WORKING_DAYS_FROM_PROJECT_START
    ]

    const hasTasksWithRelativeDates = templateTasks.some(task => {
      return relativeDateTypes.includes(task.attributes?.date_type)
    })
    
    if (!hasTasksWithRelativeDates) {
      return await dispatch('createProjectFromTemplate', {
        ...data,
        templateId,
        redirectToProject: true
      })
    }

    dispatch('triggerEntityCreate', {
      entityType: entityTypes.ProjectFromTemplate,
      entityCreateParams: {
        templateId,
        template,
        extraData: data,
      }
    }, { root: true })
  },
  async createProjectFromTemplate({ commit, dispatch }, data) {
    if (!data.start_date) {
      data.start_date = new Date()
    }

    const project = await axios.post(`/restify/projects/${data.templateId}/actions?action=create-from-template`, data)

    const newProject = await dispatch('projects/getProjectById', { id: project.data.id, returnEntity: true }, { root: true })
    commit('projects/addProject', newProject, { root: true } )

    trackActivity(Activities.TemplateUsed, {
      template_id: data.templateId,
      ...newProject
    })

    if (data.redirectToProject) {
      success(i18n.t(`Project created successfully`))
      await router.push(`/projects/${newProject.id}`)
    }
    
    syncNewProjectData(data.templateId)
    return newProject
  },
  async copySharedTemplate({ commit, dispatch }, shareIdentifier) {
    let isSuccessFull = false
    try {
      await axios.post(`/template/share/${shareIdentifier}`)
      success(i18n.t('The template has been successfully added to your account.'))
      commit('setTemplateToShare', null)
      isSuccessFull = true

      syncNewProjectData(null, true)
    } catch (err) {
      if (err.handled) {
        return
      }
      error(i18n.t(`There has been an error and the template could not be added to your account. Please try again or contact support.`))
    }
    return isSuccessFull
  }
}

const getters = {
  activeColumns: (state, getters, rootState, rootGetters) => {
    return rootGetters['projects/activeColumns']
  },
  isTemplatePath: (state, getters, rootState) => {
    return rootState.isTemplateContext
  },
  templates: (state, getters, rootState) => {
    const allProjects = rootState.projects.allProjects || []

    return allProjects.filter(p => p.attributes?.is_template)
  },
  isTemplateOpened: (state, getters, rootState) => {
    const isTemplateOpened = rootState.projects.currentProject?.attributes?.is_template

    return (getters.isTemplatePath || isTemplateOpened)
  },
  groupedTemplates: (state, getters, rootState, rootGetters) => (templates) => {
    templates = templates || rootGetters['projects/projectsOrTemplatesData']

    const groupByColumn = rootGetters['filters/targetLocalFilters']('templates')?.groupBy?.[0];
    const groupByKey = groupByColumn
    const isCustomFieldGrouping = (groupByKey || '').startsWith('attributes.custom_fields')

    const arrayValueKeys = ['relationships.groups', 'relationships.users', 'attributes.group_ids']
    let groupedTemplates
    if (arrayValueKeys.includes(groupByKey) || isCustomFieldGrouping) {
      groupedTemplates = groupByArrayValues(templates, groupByKey, isCustomFieldGrouping)
    } else {
      groupedTemplates = groupEntitiesToMap(templates, groupByKey)
    }

    if (Object.keys(groupedTemplates).length === 0) {
      groupedTemplates.default = []
    }

    return groupedTemplates
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
