import { error } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import AuthService from "@/modules/auth/services/AuthService";
import { ChatMessage, ChatMessageTypes } from "@/modules/ai/types/aiTypes";
import config from "@/modules/common/config";

import store from "@/store";

import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import { nextTick } from "vue";
import { Activities, trackPostHog } from "@/modules/common/utils/trackingUtils";

function getNewContent(event: EventSourceMessage) {
  try {
    if (event.data === '[DONE]') {
      return '[DONE]'
    }

    const data = JSON.parse(event.data)
    const content = data?.choices[0]?.delta?.content || ''

    return content
  } catch (err) {
    console.log('Error parsing data from BE', err)
    console.warn(err)
  }
}

interface Message {
  role: string
  content: string
}

const SystemMessage: Message = {
  role: "system",
  content: "You are a helpful AI assistant. Answers should be in markdown format."
}

let requestController = null

function addCreateModeMessage(message: ChatMessage) {
  store.commit('ai/addCreateModeMessage', message)
}

async function scrollToBottom() {
  await nextTick()
  const el = document.getElementById('create-discussion-bottom');
  if (el) {
    el.scrollIntoView();
  }
}

function getMessageHistory() {
  // @ts-ignore
  const messages = store.state.ai.createModeMessages || []

  return messages
    .filter((message: ChatMessage) => [
      ChatMessageTypes.User,
      ChatMessageTypes.UserHidden,
      ChatMessageTypes.Assistant,
      ChatMessageTypes.AssistantHidden,
      ChatMessageTypes.UserConfirmation,
    ].includes(message.type)
  )
  .map((message: ChatMessage) => {
    const role = [ChatMessageTypes.User, ChatMessageTypes.UserHidden].includes(message.type)
      ? ChatMessageTypes.User
      : ChatMessageTypes.Assistant

    return {
      role,
      content: message.message
    }
  })
}

export async function sendCreateModeRequest(promptMarkdown: string) {

  promptMarkdown = promptMarkdown.trim()

  let generatedContent = ''

  if (!promptMarkdown) {
    error(i18n.t('Please enter a prompt'))
    return
  }

  try {
    let messages: Message[] = [
      SystemMessage,
    ]
    
    addCreateModeMessage({
      type: ChatMessageTypes.User,
      message: promptMarkdown,
      date: new Date(),
    })

    scrollToBottom()

    const messageHistory = getMessageHistory()

    messages = messages.concat(messageHistory)

    const apiUrl = `${config.getApiHost()}/gpt-prompt`
    const token = AuthService.getToken();
    requestController = new AbortController();

    trackPostHog(Activities.AiCreateMessageSent)

    await fetchEventSource(apiUrl, {
      method: 'POST',
      body: JSON.stringify({
        model: "gpt-4o",
        messages,
        stream: true,
      }),
      signal: requestController.signal,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`
      },
      onmessage: async (event) => {
        let newContent = getNewContent(event)
        if (!newContent) {
          return
        }
        
        if (!generatedContent) {
          generatedContent = newContent
          addCreateModeMessage({
            type: ChatMessageTypes.Assistant,
            message: generatedContent,
            date: new Date(),
            isStreaming: true,
          })
        }
        else {
          if (newContent === '[DONE]') {
            store.commit('ai/updateCreateMessageIsStreaming', false)
          }
          else {
            generatedContent += newContent

            store.commit('ai/updateCreateMessageText', generatedContent)
          }
        }

        scrollToBottom()
      },
      onclose: () => {
        // prompt.value = ''
        // loading.value = false
        // insertGeneratedContent()
      },
    })
  } catch (err) {
    console.log(err)
  }
}
