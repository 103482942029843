import { Node, mergeAttributes } from '@tiptap/core'

export default Node.create({
  name: 'icon',

  inline: true,

  group: 'inline',

  selectable: false,

  addOptions() {
    return {
      HTMLAttributes: {
        class: '',
        style: '',
      },
    }
  },

  addAttributes() {
    return {
      class: {
        default: this.options.HTMLAttributes.class,
      },
      style: {
        default: this.options.HTMLAttributes.style,
      },
    }
  },

  parseHTML() {
    return [
      { tag: `span[data-type=${this.name}]` },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { 'data-type': this.name })]
  },

});
