<template>
  <BaseDialogNew
    v-model="visible"
    class="w-full max-w-xl"
  >
    <BaseEntityForm
      width-class="max-w-xl"
      :isDialogForm="true"
      slim
    >
      <template #title>
        <BaseFormHeader
          :isDialogForm="true"
          :subtitle="$t('Create & edit statuses for all tasks.')"
        >
          <template #title>
            <h3 class="text-2xl font-bold">
              <span
                class="mr-1 underline"
                :class="{
                  'text-green-500': !$useNewLayout,
                  'text-white': $useNewLayout,
                }"
              >
                {{ $t('Account Level') }}
              </span>
              {{ $t('Task Statuses') }}
            </h3>
          </template>
        </BaseFormHeader>
      </template>
      <template #default="{ meta, errors }">
        <TaskStatuses
          class="task-statuses-container"
          :isDialogForm="true"
        />
      </template>
      <template #actions="{ meta, errors }">
        <BaseButton
          variant="white"
          @click="visible = false"
        >
          {{ $t('Close') }}
        </BaseButton>
      </template>
    </BaseEntityForm>
  </BaseDialogNew>
</template>
<script>
import TaskStatuses from "@/modules/accounts/pages/task-statuses.vue";

export default {
  components: {
    TaskStatuses,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
}
</script>
<style scoped>
.task-statuses-container {
  @apply border-none;
}
</style>
